const getTrainingInfo = state => state.training.trainingInfo;
const getTrainingProgress = state => state.training.progress;
const getTrainingStep = state => state.training.step;
const getSubstep = state => state.training.subStep;

export const TrainingSelector = {
    getTrainingInfo,
    getTrainingProgress,
    getTrainingStep,
    getSubstep,
};
