import {faArrowRight, faClockRotateLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Sheet, Stack, Typography} from '@mui/joy';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import JoyUIThemeProvider from '../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';
import {flexCenter} from '../../../../../utils/styles';
import {RevenueSimulatorSelectors} from '../../../store/revenue-simulator.selector';

export const SimulationExpired = () => {
    const {t} = useTranslation('revenueSimulator');
    const simulation = useSelector(RevenueSimulatorSelectors.selectSimulation);
    const isMobileSize = useIsMobileSize();

    return (
        <JoyUIThemeProvider>
            <Box
                sx={{
                    width: '100dvw',
                    minHeight: '100dvh',
                    height: '400px',
                    px: isMobileSize ? 2 : 5,
                    py: 3,
                    bgcolor: 'background.body',
                }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 'xl',
                        p: isMobileSize ? 2 : 3,
                        ...flexCenter,
                    }}
                >
                    <Stack spacing={4} alignItems="center" width="660px">
                        <Box
                            sx={{
                                width: isMobileSize ? '100px' : '200px',
                                height: isMobileSize ? '100px' : '200px',
                                borderRadius: '50%',
                                border: '5px solid',
                                borderColor: 'neutral.outlinedBorder',
                                bgcolor: 'background.body',
                                fontSize: isMobileSize ? '45px' : '90px',
                                color: 'text.icon',
                                ...flexCenter,
                            }}
                        >
                            <FontAwesomeIcon icon={faClockRotateLeft} />
                        </Box>
                        <Stack spacing={1} textAlign="center">
                            <Typography level="h1">{t('simulationExpired.title')}</Typography>
                            <Typography level={isMobileSize ? 'body-md' : 'body-lg'} textColor="text.secondary">
                                <Trans t={t} i18nKey="simulationExpired.description" />
                            </Typography>
                        </Stack>
                        <Button
                            component="a"
                            href={`mailto:${simulation.coachEmail}`}
                            variant="solid"
                            size="lg"
                            color="primary"
                            endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                            fullWidth
                        >
                            {t('contactSection.buttonText')}
                        </Button>
                    </Stack>
                </Sheet>
            </Box>
        </JoyUIThemeProvider>
    );
};
