import {
    faWarning,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Box, FormControl, Input} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {requiredFieldProps} from './helper';
import {FormErrorText} from '../../../../../components/joy-ui/forms/FormErrorText';
import {FormFieldLabel} from '../../../../../components/joy-ui/forms/FormFieldLabel';

export const AdditionalAdministratorFields = function ({control, isLoading, errors}) {
    const {t} = useTranslation('createUser');

    return (
        <>
            <Alert
                sx={{alignItems: 'flex-start', mb: 2}}
                startDecorator={(
                    <Box fontSize="md" color="warning.softColor">
                        <FontAwesomeIcon icon={faWarning} />
                    </Box>
                )}
                variant="soft"
                color="warning"
            >
                <Box>
                    <Trans t={t} i18nKey="createAdministratorNote" components={{bold: <strong />}} />
                </Box>
            </Alert>

            <Controller
                id="confirmAdministratorCreation"
                name="confirmAdministratorCreation"
                control={control}
                render={({field}) => (
                    <FormControl error={!!errors.confirmAdministratorCreation}>
                        <FormFieldLabel label={t('confirmAdministratorCreation')} />
                        <Input
                            type="text"
                            color={errors.confirmAdministratorCreation ? 'danger' : 'neutral'}
                            placeholder={t('confirmAdministratorCreation')}
                            error={!!errors.confirmAdministratorCreation}
                            tabIndex={3}
                            disabled={isLoading}
                            {...field}
                            {...requiredFieldProps}
                        />
                        <FormErrorText errorMessage={errors.confirmAdministratorCreation?.message} />
                    </FormControl>
                )}
            />
        </>
    );
};

AdditionalAdministratorFields.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
};

AdditionalAdministratorFields.defaultProps = {
    isLoading: false,
};
