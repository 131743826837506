import React from 'react';
import Step1 from '../../assets/etape1-guide-de-financement-de-formation-v2.pdf';
import {TrainingViewPdf} from '../general/TrainingViewPdf';

const OpcoViewPdf = () => {
    return (
        <TrainingViewPdf
            file={Step1}
        />
    );
};

export default OpcoViewPdf;
