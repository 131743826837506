import React from 'react';
import {AdminOpportunity} from '../../features/opportunity/components/AdminOpportunity';

const AdminOpportunityList = () => {
    return (
        <AdminOpportunity />
    );
};

export default AdminOpportunityList;
