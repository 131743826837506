import {Tab, Tabs} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useDataTableTabFiltersStyles} from './styles';

const DataTableTabFilters = ({value, config, onChange, isAllDisplayed}) => {
    const styles = useDataTableTabFiltersStyles();

    const {t} = useTranslation('common');

    return (
        <Tabs
            variant="scrollable"
            className={styles.tabs}
            value={value}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            onChange={onChange}
        >
            {isAllDisplayed && <Tab className={styles.tab} label={t('dataTable.tabFilters.all')} value="ALL" />}

            {config.map(tabFilterItem => (
                <Tab
                    key={tabFilterItem.id}
                    className={styles.tab}
                    label={tabFilterItem.label}
                    value={tabFilterItem.id}
                />
            ))}
        </Tabs>
    );
};

DataTableTabFilters.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    config: PropTypes.arrayOf(PropTypes.object).isRequired,
    isAllDisplayed: PropTypes.bool,
};

DataTableTabFilters.defaultProps = {
    isAllDisplayed: true,
};

export default DataTableTabFilters;
