import {combineReducers} from 'redux';

import * as actions from './training.actions';
import {TRAINING_INVOICE_STATES} from '../../../config/constants/trainingConstants';
import {createReducer} from '../../createReducer';

export const trainingReducer = combineReducers({
    invoiceState: createReducer(TRAINING_INVOICE_STATES.LOADING, actions.SET_TRAINING_INVOICE_STATE),
    isEditModeActive: createReducer(false, actions.SET_IS_TRAINING_EDIT_MODE_ACTIVE, true),
    invoice: createReducer(null, actions.STORE_TRAINING_INVOICE, true),
});
