import {Grid, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {useDispatch} from 'react-redux';
import EditButtonSmall from '../../../../../components/buttons/EditButtonSmall';
import {push} from '../../../../../lib/router/connected-router-saga';
import {USER_ROLES, isUserCareOrFreelancer} from '../../../../../utils/user-roles';
import {USER_STATUSES} from '../../../../../v1/app/user/user.constants';
import {
    createContactInfoAddress,
} from '../../../../../v1/components/company/company-profile/EnterpriseInformationForm/util';
import NoInput from '../../../../../v1/components/ui-kit/NoInput/NoInput';
import UserStatus from '../../../../../v1/components/user/UserStatus/UserStatus';
import {ROUTE_PATHS} from '../../../../../v1/navigation/routePaths';

const ProfileInformation = ({user}) => {
    const {t} = useTranslation('users');
    const dispatch = useDispatch();
    const onEditButtonClickHandler = () => {
        dispatch(push(ROUTE_PATHS.MY_PROFILE_EDIT));
    };

    const formattedPhone = user.phone
        ? <NumberFormat value={user.phone} displayType="text" format="## ## ## ## ##" mask="#" />
        : <NoInput />;

    const contactInfo = user?.contactInfo && Object.keys(user?.contactInfo).length
        ? createContactInfoAddress(user?.contactInfo)
        : <NoInput />;
    const isFreelancer = isUserCareOrFreelancer(user);

    return (
        <>
            <Typography sx={{p: 3, pt: 2.5, pb: 1.5}} variant="h5">
                {t('userInformation.title')}
            </Typography>
            {[
                {label: t('userInformation.firstName'), content: user.firstName},
                {label: t('userInformation.lastName'), content: user.lastName},
                {label: t('userInformation.email'), content: user.email},
                {label: t('userInformation.phone'), content: formattedPhone},
                {label: t('userInformation.status'), content: <UserStatus isUserActive={user.active} status={user.status} />},
                ...(isFreelancer ? [{label: t('userInformation.personalAddress'), content: contactInfo}] : []),
            ].map((item, id) => (
                <Grid key={id} container sx={{py: 2, px: 3, borderBottom: 1, borderColor: 'grey.300'}}>
                    <Grid item md={6} xs={12} sx={{color: {xs: 'text.disabled', md: 'text.primary'}}}>
                        {item.label}
                    </Grid>
                    <Grid item md={6} xs={12} sx={{color: {xs: 'elephant.main', md: 'text.primary'}}}>
                        {item.content}
                    </Grid>
                </Grid>
            ))}
            <EditButtonSmall onClick={onEditButtonClickHandler} />
        </>
    );
};

ProfileInformation.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        canSetupCompany: PropTypes.bool,
        role: PropTypes.oneOf(Object.values(USER_ROLES)).isRequired,
        status: PropTypes.oneOf(Object.values(USER_STATUSES)).isRequired,
        contactInfo: PropTypes.object.isRequired,
    }).isRequired,
};

export default ProfileInformation;
