import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {Toast} from '../../../lib/toast';
import {dropzoneErrorCodes} from '../../../utils/constants';

export const SIGNATURE_FILE_MAX_SIZE = {
    SIGNATURE: 10485760, // 10 Mb // TODO Check
};

const style = {
    width: '282px',
};

const mobileStyle = {
    width: '100%',
};

export const SignatureUploadDropZone = ({
    fileTypes,
    maxFiles,
    maxSize,
    onChange,
    sx,
    isDisabled,
    children,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const onDropRejectedHandler = rejectedFiles => {
        if (rejectedFiles.length) {
            const hasTooManyFiles = rejectedFiles[0]?.errors[0]?.code === dropzoneErrorCodes['too-many-files'];
            const isFileToLarge = rejectedFiles[0]?.errors[0]?.code === dropzoneErrorCodes['file-too-large'];

            if (hasTooManyFiles) {
                Toast.error('tooManyFiles');

                return;
            }

            if (isFileToLarge) {
                Toast.error('fileTooLargeCustom', {
                    translationValues: {
                        limit: 10,
                    },
                });
            }
        }
    };

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
    } = useDropzone({
        maxFiles: maxFiles,
        accept: fileTypes,
        maxSize: maxSize,
        disabled: isDisabled,
        onDropRejected: onDropRejectedHandler,
    });

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            onChange(acceptedFiles);
        }
    }, [acceptedFiles, onChange]);

    return (
        <Box sx={sx}>
            {!isMobileSize && (
                <Box
                    {...getRootProps({style})}
                    // sx={{pt: 2, pb: 2, border: error ? '1px dashed #DB100D' : '1px dashed #05A7D0'}}
                >
                    <input {...getInputProps()} disabled={isDisabled} />

                    {children}
                </Box>
            )}
            {isMobileSize && (
                <Box
                    {...getRootProps({mobileStyle})}
                    // sx={{pt: 2, pb: 2, border: error ? '1px dashed #DB100D' : '1px dashed #05A7D0'}}
                >
                    <input {...getInputProps()} disabled={isDisabled} />

                    {children}
                </Box>
            )}
        </Box>
    );
};

SignatureUploadDropZone.propTypes = {
    fileTypes: PropTypes.string.isRequired,
    maxFiles: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    isFileDeleteAvailable: PropTypes.bool,
    isUploadInProgress: PropTypes.bool,
    maxSize: PropTypes.number,
    isInfoTextVisible: PropTypes.bool,
    files: PropTypes.array,
    sx: PropTypes.object,
    error: PropTypes.object,
    textTop: PropTypes.string,
    textBottom: PropTypes.string,
    isDisabled: PropTypes.bool,
};

SignatureUploadDropZone.defaultProps = {
    isFileDeleteAvailable: true,
    isUploadInProgress: false,
    maxSize: 10485760, // 10 MB
    isInfoTextVisible: false,
    files: [],
    sx: {},
    textTop: '',
    textBottom: '',
    error: undefined,
    isDisabled: false,
};
