import debounce from 'lodash.debounce';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SidebarSelectors} from '../../features/sidebar/store/sidebar.selector';
import UsersView from '../../features/user-list/components/UsersView';
import {UsersListActions} from '../../features/user-list/store/userList.actions';
import {selectRouterLocation} from '../../lib/router/connected-router-saga';

const SidebarSearchResult = () => {
    const dispatch = useDispatch();

    const sidebarText = useSelector(SidebarSelectors.selectSearchText);
    const location = useSelector(selectRouterLocation);

    const search = location?.query;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedRunSearch = useCallback(
        debounce(() => {
            dispatch(UsersListActions.getUsersSearch(sidebarText));
        }, 300),
        [sidebarText],
    );

    useEffect(() => {
        debouncedRunSearch();
        return () => {
            debouncedRunSearch.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedRunSearch, search]);

    return (
        <UsersView isSidebarSearch={true} />
    );
};

export default SidebarSearchResult;
