import moment from 'moment/moment';
import {BE_DATE_FORMAT} from '../../../utils/constants';

export const fromCoreUserDTO = userDTO => ({
    id: userDTO.id,
    userId: userDTO.id,
    key: userDTO?.id,
    firstName: userDTO.firstName,
    lastName: userDTO.lastName,
    email: userDTO?.email,
    userFullName: `${userDTO.firstName} ${userDTO.lastName}`,
    fullName: `${userDTO.firstName} ${userDTO.lastName}`,
    role: userDTO.role,
    status: userDTO.status,
    active: !!userDTO.active,

    companyId: userDTO.companies?.[0]?.id,
    companyName: userDTO.companies?.[0]?.name,

    legalForm: userDTO.companies?.[0]?.legalForm ?? '-',
    taxSystem: userDTO.companies?.[0]?.enterpriseInfo?.tax_system ?? '-',

    coachId: userDTO.accountManager?.id ?? '',
    coachFullName: userDTO?.accountManager
        ? `${userDTO.accountManager.firstName} ${userDTO.accountManager.lastName}`
        : '-',
    date: moment(userDTO?.createdAt).format(BE_DATE_FORMAT),
});

export const transformCoreUsersFromDTO = userDTOs => {
    const users = {};

    if (!userDTOs?.users) {
        return users;
    }

    userDTOs.users.forEach(userDTO => {
        users[userDTO.id] = fromCoreUserDTO(userDTO);
    });

    return users;
};
