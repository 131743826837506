import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';
import {Company} from '../../companies/api/companies.dto';

const putCompanySetupStep = ({freelancerId, companyId}) => {
    return hiwayApiInstance
        .put(`/v1/freelancers/${freelancerId}/companies/${companyId}/start-company-setup`)
        .then(result => new Company(result.data));
};

export const WorkshopsApi = {
    putCompanySetupStep,
};
