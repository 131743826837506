import {Box, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const FreelancerOpportunityHeader = () => {
    const {t} = useTranslation('opportunity');

    return (
        <>
            <Box sx={{mb: 1}}>
                <Typography variant="h4">
                    {t('header')}
                </Typography>
            </Box>
            <Box sx={{mb: 2}}>
                <Typography sx={{color: 'rgba(0, 0, 0, 0.38)', fontWeight: 500}}>
                    {t('subheader')}
                </Typography>
            </Box>
        </>
    );
};
