import {Box} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import RouterLink from '../../../../components/joy-ui/router/RouterLink';
import {push, selectRouterLocation} from '../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../lib/router/route-paths';
import {USER_ROLES} from '../../../../utils/user-roles';
import {InvoiceSelector} from '../../../invoicing/store/invoice.selector';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../../user/modules/logged-in-user';

const linkStyles = {
    'cursor': 'pointer',
    '&:hover': {
        textDecoration: 'none',
    },
};

export const ItemWithLink = ({to, sx, children}) => {
    const dispatch = useDispatch();
    const location = useSelector(selectRouterLocation);

    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const finalization = useSelector(InvoiceSelector.selectInvoiceFinalizationResult);

    const onClickHandle = () => {
        // We need to have special modal that asks user if they are sure when leaving invoice creation
        if (location?.pathname === RoutePaths.CREATE_INVOICE && !finalization) {
            dispatch(UiActions.setModalData(ModalsKeys.INVOICE_CREATE_CANCEL_MODAL, {to}));
            dispatch(UiActions.setActiveModal(ModalsKeys.INVOICE_CREATE_CANCEL_MODAL, true));
            return;
        }

        dispatch(push(to));
    };

    if (user?.role === USER_ROLES.ADMINISTRATOR || !user.hasInvoiceAccess) {
        return (
            <RouterLink
                to={to}
                sx={{
                    ...linkStyles,
                    ...sx,
                }}
            >
                {children}
            </RouterLink>
        );
    }

    return (
        <Box
            sx={{
                ...linkStyles,
                ...sx,
            }}
            onClick={onClickHandle}
        >
            {children}
        </Box>
    );
};

ItemWithLink.propTypes = {
    to: PropTypes.string.isRequired,
    sx: PropTypes.object,
};

ItemWithLink.defaultProps = {
    sx: {},
};
