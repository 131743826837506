export const selectResetPasswordFormUsername = state => state.auth.resetPasswordForm.username;

export const selectIsLoadingResetPasswordForm = state => state.auth.resetPasswordForm.isLoading;

export const selectResetPasswordUsernameErrorCode = state => state.auth.resetPasswordForm.usernameErrorCode;

export const selectResetPasswordVerificationCodeErrorCode = state => state
    .auth.resetPasswordForm.verificationCodeErrorCode;

export const selectIsCodeSent = state => state.auth.resetPasswordForm.isCodeSent;
