import {faScaleUnbalanced} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ComparisonTable} from './ComparisonTable';
import Section from '../../../../../../components/joy-ui/sections/Section';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';

export const ComparisonTableSection = () => {
    const {t} = useTranslation('revenueSimulator');
    const isMobileSize = useIsMobileSize();
    return (
        <Section
            title={isMobileSize ? t('comparisonTable.titleMobile') : t('comparisonTable.title')}
            bodyStyles={{overflowX: 'auto'}}
            icon={faScaleUnbalanced}
        >
            <ComparisonTable />
        </Section>
    );
};
