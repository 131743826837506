import {keyframes} from '@emotion/react';
import {Box, Grow, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

// Keyframes for Pac-Man eating animations
const eatAnimation = keyframes`
    0% {
        clip-path: polygon(100% 74%, 44% 48%, 100% 21%);
    }
    25% {
        clip-path: polygon(100% 60%, 44% 48%, 100% 40%);
    }

    50% {
        clip-path: polygon(100% 50%, 44% 48%, 100% 50%);
    }

    75% {
        clip-path: polygon(100% 59%, 44% 48%, 100% 35%);
    }

    100% {
        clip-path: polygon(100% 74%, 44% 48%, 100% 21%);
    }`;


const PacmanMain = ({
    step,
    duration,
    steps,
    containerWidth,
}) => {
    const {t} = useTranslation('bank');

    const [initial, setIsInitial] = useState(true);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const timeoutTime = duration * 1000;

        const timeout = setTimeout(() => {
            setAnimate(false);
        }, timeoutTime);

        if (initial) {
            setIsInitial(false);
        } else {
            setAnimate(true);
        }
        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line
    }, [step]);

    return (
        <Box sx={{
            display: 'flex',
            background: 'white',
            zIndex: 2,
            justifyContent: 'flex-end',
        }}
        >
            <Box sx={{
                width: `${step * (containerWidth / steps) + 18}px`,
                transition: `${duration}s linear`,
                display: 'flex',
                height: '18px',
                justifyContent: 'flex-end',
                zIndex: '20',
                background: 'white',
            }}
            >
                {step === steps && (
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        height: '18px',
                    }}
                    >
                        <Grow
                            in={step === steps}
                            timeout={duration * 1000 + 400}
                            style={{transitionDelay: '1000ms'}}
                        >
                            <Typography sx={{
                                color: 'success_v2.light',
                                fontFamily: 'Barlow',
                                fontSize: theme => theme.typography.pxToRem(16),
                                lineHeight: theme => theme.typography.pxToRem(16),
                                height: theme => theme.typography.pxToRem(16),
                                fontWeight: 600,
                                width: '100%',
                                pl: '18px', // Pacman width
                                letterSpacing: '1.6px',
                                textTransform: 'uppercase',
                                textAlign: 'center',
                            }}
                            >
                                {t('categorisation.pacman.complete')}
                            </Typography>
                        </Grow>
                    </Box>
                )}

                <Box sx={{
                    height: '18px',
                    background: '#ECAC4C',
                    borderRadius: '50%',
                    position: 'relative',
                    width: '18px',
                    // transform: `translate(${step * (containerWidth / steps)}px)`,
                    // transition: `${duration}s linear ${steps === 3 ? 0.015 : 0.01}s`,
                    // width: '18px',
                }}
                >
                    <Box
                        sx={{
                            width: '18px',
                            height: '18px',
                            borderRadius: '50%',
                            clipPath: step !== steps
                                ? 'polygon(100% 74%, 44% 48%, 100% 21%)'
                                : 'polygon(100% 59%, 44% 48%, 100% 35%)',
                            background: 'white',
                            animation: animate ? `${eatAnimation} 0.5s infinite` : 'none',
                        }}
                    />
                </Box>
            </Box>

        </Box>
    );
};

PacmanMain.propTypes = {
    step: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    containerWidth: PropTypes.number.isRequired,
    steps: PropTypes.number,
};

PacmanMain.defaultProps = {
    steps: 3,
};

export const PacmanBackground = ({
    step,
    duration,
    steps,
    containerWidth,
    zIndex,
}) => {
    const {t} = useTranslation('bank');

    return (
        <Box sx={{
            position: 'absolute',
            display: 'flex',
            zIndex: zIndex,
        }}
        >
            <Box sx={{
                width: `${step * (containerWidth / steps)}px`,
                transition: `${duration}s linear`,
                height: '32px',
                background: 'white',
                // animation: animate ? `${move} 2s infinite` : 'none',
                position: 'relative',
                // transform: `translate(${(step) * (550 / 3) - 18}px)`,
            }}
            >
                {step === steps && (
                    <Grow
                        in={step === steps}
                        timeout={duration * 1000 + 400}
                        style={{transitionDelay: '1000ms'}}
                    >
                        <Typography sx={{
                            color: 'success_v2.light',
                            fontFamily: 'Barlow',
                            fontSize: theme => theme.typography.pxToRem(16),
                            lineHeight: theme => theme.typography.pxToRem(16),
                            height: theme => theme.typography.pxToRem(16),
                            fontWeight: 600,
                            letterSpacing: '1.6px',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            position: 'relative',
                            top: '8px',
                        }}
                        >
                            {t('categorisation.pacman.complete')}
                        </Typography>
                    </Grow>
                )}
            </Box>
            <Box sx={{
                position: 'relative',
                top: '9px',
                width: '9px',
                height: '14px',
                background: '#ECAC4C',
                borderRadius: '50%',

            }}
            />
        </Box>
    );
};

PacmanBackground.propTypes = {
    step: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    steps: PropTypes.number,
    zIndex: PropTypes.object.isRequired,
    containerWidth: PropTypes.number.isRequired,
};

PacmanBackground.defaultProps = {
    steps: 3,
};

export default PacmanMain;
