const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
const STORE_USER_SETTINGS = 'STORE_USER_SETTINGS';
const SET_IS_LOADING_USER_SETTINGS = 'SET_IS_LOADING_USER_SETTINGS';
const SET_IS_UPDATING_USER_SETTINGS = 'SET_IS_UPDATING_USER_SETTINGS';

const getUserSettings = () => ({
    type: GET_USER_SETTINGS,
});

const storeUserSettings = userSettings => ({
    type: STORE_USER_SETTINGS,
    payload: userSettings,
});

const setIsLoadingUserSettings = isLoading => ({
    type: SET_IS_LOADING_USER_SETTINGS,
    payload: isLoading,
});

const setIsUpdatingUserSettings = isUpdating => ({
    type: SET_IS_UPDATING_USER_SETTINGS,
    payload: isUpdating,
});

const updateUserSettings = settings => ({
    type: UPDATE_USER_SETTINGS,
    payload: settings,
});

export const SettingsActionTypes = {
    GET_USER_SETTINGS: GET_USER_SETTINGS,
    UPDATE_USER_SETTINGS: UPDATE_USER_SETTINGS,
    STORE_USER_SETTINGS: STORE_USER_SETTINGS,
    SET_IS_LOADING_USER_SETTINGS: SET_IS_LOADING_USER_SETTINGS,
    SET_IS_UPDATING_USER_SETTINGS: SET_IS_UPDATING_USER_SETTINGS,
};

export const SettingsActions = {
    getUserSettings,
    storeUserSettings,
    setIsLoadingUserSettings,
    setIsUpdatingUserSettings,
    updateUserSettings,
};
