export const GET_CONTRACT_DATA = 'GET_CONTRACT_DATA';
export const SET_IS_LOADING_CONTRACT_DATA = 'SET_IS_LOADING_CONTRACT_DATA';
export const STORE_CONTRACT_DOCUMENT_ID = 'STORE_CONTRACT_DOCUMENT_ID';
export const STORE_CONTRACT_SIGNATURE_DATE = 'STORE_CONTRACT_SIGNATURE_DATE';
export const STORE_CONTRACT_DOCUMENT_URL = 'STORE_CONTRACT_DOCUMENT_URL';

export const getContractData = freelancerId => ({
    type: GET_CONTRACT_DATA,
    payload: {
        freelancerId,
    },
});

export const setIsLoadingContractData = isLoading => ({
    type: SET_IS_LOADING_CONTRACT_DATA,
    payload: isLoading,
});

export const storeContractDocumentId = documentId => ({
    type: STORE_CONTRACT_DOCUMENT_ID,
    payload: documentId,
});

export const storeContractSignatureDate = signatureDate => ({
    type: STORE_CONTRACT_SIGNATURE_DATE,
    payload: signatureDate,
});

export const storeContractDocumentUrl = documentUrl => ({
    type: STORE_CONTRACT_DOCUMENT_URL,
    payload: documentUrl,
});
