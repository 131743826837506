import {SvgIcon} from '@mui/material';

export const TableViewIcon = props => (
    <SvgIcon viewBox="0 0 25 24" {...props}>
        <path
            d="M9.64173 20.5C9.14307 20.5 8.71707 20.3234 8.36373 19.9703C8.01057 19.6169 7.83398 19.1909 7.83398 18.6923V9.30775C7.83398 8.80908 8.01057 8.38308 8.36373 8.02975C8.71707 7.67658 9.14307 7.5 9.64173 7.5H19.0262C19.5249 7.5 19.9509 7.67658 20.3042 8.02975C20.6574 8.38308 20.834 8.80908 20.834 9.30775V18.6923C20.834 19.1909 20.6574 19.6169 20.3042 19.9703C19.9509 20.3234 19.5249 20.5 19.0262 20.5H9.64173ZM9.33398 11.327H19.334V9.30775C19.334 9.21792 19.3052 9.14417 19.2475 9.0865C19.1898 9.02883 19.1161 9 19.0262 9H9.64173C9.5519 9 9.47815 9.02883 9.42048 9.0865C9.36282 9.14417 9.33398 9.21792 9.33398 9.30775V11.327ZM13.161 15.173H15.507V12.827H13.161V15.173ZM13.161 19H15.507V16.673H13.161V19ZM9.33398 15.173H11.661V12.827H9.33398V15.173ZM17.007 15.173H19.334V12.827H17.007V15.173ZM9.64173 19H11.661V16.673H9.33398V18.6923C9.33398 18.7821 9.36282 18.8558 9.42048 18.9135C9.47815 18.9712 9.5519 19 9.64173 19ZM17.007 19H19.0262C19.1161 19 19.1898 18.9712 19.2475 18.9135C19.3052 18.8558 19.334 18.7821 19.334 18.6923V16.673H17.007V19ZM5.64173 16.5C5.14307 16.5 4.71707 16.3234 4.36373 15.9703C4.01057 15.6169 3.83398 15.1909 3.83398 14.6923V5.30775C3.83398 4.80908 4.01057 4.38308 4.36373 4.02975C4.71707 3.67658 5.14307 3.5 5.64173 3.5H15.0262C15.5249 3.5 15.9509 3.67658 16.3042 4.02975C16.6574 4.38308 16.834 4.80908 16.834 5.30775V6.5H15.334V5.30775C15.334 5.21792 15.3052 5.14417 15.2475 5.0865C15.1898 5.02883 15.1161 5 15.0262 5H5.64173C5.5519 5 5.47815 5.02883 5.42048 5.0865C5.36282 5.14417 5.33398 5.21792 5.33398 5.30775V14.6923C5.33398 14.7821 5.36282 14.8558 5.42048 14.9135C5.47815 14.9712 5.5519 15 5.64173 15H6.83398V16.5H5.64173Z"
            fill="currentColor"
        />
    </SvgIcon>
);
