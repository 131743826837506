import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import MessageIcon from '@mui/icons-material/Message';
import {Button, Slide, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, generatePath} from 'react-router-dom';
import verifyDocumentsMobile from '../../../../../assets/png/verify-documents-mobile.png';
import verifyDocuments from '../../../../../assets/png/workshopVerifyDocuments.png';
import {ButtonAction} from '../../../../../components/buttons/ButtonAction';
import Banner from '../../../../../layout/ContainerWithBigImage/Banner';
import {importLocaleBundle} from '../../../../../lib/i18next';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import ConfirmationDialog from '../../../../../v1/components/ui-kit/ConfirmationDialog/ConfirmationDialog';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {FreelancerSelectors} from '../../../store/freelancer.selector';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';

importLocaleBundle('freelancerOnboarding');

export const WeVerifyDocuments = () => {
    const {t} = useTranslation('freelancerOnboarding');

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const freelancer = useSelector(FreelancerSelectors.selectAccount);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    if (!isMobileSize) {
        return (
            <>
                <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                    <div>
                        <Banner
                            header={(
                                <Trans t={t} i18nKey="documentsValidated.headlinePending" components={{bold: <Box component="span" sx={{color: '#E84C4C'}} />}} />
                            )}
                            text={t('documentsValidated.textPending')}
                            textSize={21}
                            content={(
                                <Box sx={{mt: 3}}>
                                    <Box>
                                        <Button
                                            startIcon={<DocumentScannerIcon />}
                                            variant="contained"
                                            component={Link}
                                            size="large"
                                            sx={{
                                                py: theme => theme.spacing(1.9),
                                                fontSize: theme => theme.typography.pxToRem(15),
                                                mr: 2,
                                            }}
                                            to={generatePath(`${RoutePaths.DOCUMENTS}`, {
                                                freelancerId: loggedInUser.id,
                                                companyId: freelancer.defaultCompanyId,
                                            })}
                                        >
                                            {t('contractSigning.finalDocuments')}
                                        </Button>

                                        <ButtonAction
                                            startIcon={<MessageIcon />}
                                            variant="outlined"
                                            onClick={() => {
                                                setIsOpen(true);
                                            }}
                                        >
                                            {t('documentsValidated.buttonPending')}
                                        </ButtonAction>
                                    </Box>
                                </Box>
                            )}
                            cardSx={{
                                background: 'linear-gradient(270deg, #E3F2FD 0%, #FFFFFF 55.73%)',
                            }}
                            Illustration={(
                                <Box sx={{mt: 1, position: 'relative', left: '76px'}}>
                                    <img src={verifyDocuments} alt="Verify documents" />
                                </Box>
                              )}
                        />
                    </div>
                </Slide>

                <ConfirmationDialog
                    onCancel={() => setIsOpen(false)}
                    message={t('documentsValidated.confirmMessage')}
                    onConfirm={event => {
                        event.preventDefault();

                        dispatch(OnboardingActions.resetCompanySetupFlow());
                    }}
                    title={t('documentsValidated.confirmTitle')}
                    isOpen={isOpen}
                />
            </>
        );
    }

    return (
        <>
            <Box>
                <div>
                    <Banner
                        header={(
                            <Trans t={t} i18nKey="documentsValidated.headlinePending" components={{bold: <Box component="span" sx={{color: '#E84C4C'}} />}} />
                        )}
                        text={t('documentsValidated.textPending')}
                        textSize={21}
                        content={(
                            <Box sx={{mt: 3}}>
                                <Box>
                                    <Button
                                        startIcon={<DocumentScannerIcon />}
                                        variant="contained"
                                        component={Link}
                                        size="large"
                                        sx={{
                                            py: theme => theme.spacing(1.9),
                                            fontSize: theme => theme.typography.pxToRem(15),
                                            mr: 2,
                                        }}
                                        to={generatePath(`${RoutePaths.DOCUMENTS}`, {
                                            freelancerId: loggedInUser.id,
                                            companyId: freelancer.defaultCompanyId,
                                        })}
                                    >
                                        {t('contractSigning.finalDocuments')}
                                    </Button>

                                    <ButtonAction
                                        sx={{mt: 2}}
                                        startIcon={<MessageIcon />}
                                        variant="outlined"
                                        onClick={() => {
                                            setIsOpen(true);
                                        }}
                                    >
                                        {t('documentsValidated.buttonPedingMobile')}
                                    </ButtonAction>
                                </Box>
                            </Box>
                        )}
                        cardSx={{
                            background: 'linear-gradient(270deg, #E3F2FD 0%, #FFFFFF 55.73%)',
                        }}
                        Illustration={(
                            <Box sx={{mt: 1, width: '100%'}}>
                                <img src={verifyDocumentsMobile} alt="Verify documents" />
                            </Box>
                        )}
                    />
                </div>
            </Box>

            <ConfirmationDialog
                onCancel={() => setIsOpen(false)}
                message={t('documentsValidated.confirmMessage')}
                onConfirm={event => {
                    event.preventDefault();

                    dispatch(OnboardingActions.resetCompanySetupFlow());
                }}
                title={t('documentsValidated.confirmTitle')}
                isOpen={isOpen}
            />
        </>
    );
};
