import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import {Box, Slide, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import DeactivateSubscriptionModal from '../modals/DeactivateSubscriptionModal';

const MobileFakeNewScreenWrapper = ({onClose, onBack, children}) => {
    const {t} = useTranslation('bank');
    return (
        <Slide in={true} direction="left">
            <div>
                <Box sx={{
                    background: 'white',
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        pt: 3,
                        px: 3,
                    }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                cursor: 'pointer',
                            }}
                            onClick={onBack}
                        >
                            <ArrowBackIcon />
                            <Typography sx={{
                                fontSize: theme => theme.typography.pxToRem(22),
                            }}
                            >
                                {t('categorisation.backToSubscription')}
                            </Typography>
                        </Box>

                        <CloseIcon
                            onClick={onClose}
                        />
                    </Box>

                    <Box>
                        {children}
                    </Box>
                </Box>
                <DeactivateSubscriptionModal />
            </div>
        </Slide>
    );
};

MobileFakeNewScreenWrapper.propTypes = {
    onClose: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
};

export default MobileFakeNewScreenWrapper;
