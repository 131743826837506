import {Badge, Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const TabLabel = ({label, count}) => (
    <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}
    >
        {label}
        {count !== 0 && (
            <Badge
                badgeContent={count}
                color="v2Grey300Palette"
                sx={{
                    color: 'white !important',
                    ml: 2.5,
                    fontWeight: 500,
                }}
            />

        )}
    </Box>
);

TabLabel.propTypes = {
    label: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
};

export default TabLabel;
