import AddCardIcon from '@mui/icons-material/AddCard';
import {Box, Paper, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import CreateCard from '../../../../../assets/svg/bank/create-card.svg';

const BankCardsEmptyCase = ({t, setShowCardCreation}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Paper
            elevation={0}
            sx={{
                height: isMobileSize ? '100%' : '80vh',
                p: isMobileSize ? 2 : 0,
                pt: isMobileSize ? 3.5 : 0,
                m: isMobileSize ? 2 : 0,
            }}
        >
            <Box sx={{
                height: isMobileSize ? '100%' : '80vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
            >
                <img src={CreateCard} alt="Create card" />

                <Typography
                    variant="h5"
                    sx={{
                        mt: 2,
                    }}
                >
                    {t('cardCreate.emptyTitle')}
                </Typography>

                <Typography sx={{
                    mt: 1,
                    maxWidth: '455px',
                    textAlign: 'center',
                    fontSize: theme => theme.typography.pxToRem(14),
                }}
                >
                    {t('cardCreate.emptyText')}
                </Typography>

                <Button
                    onClick={() => setShowCardCreation(true)}
                    sx={{
                        mt: 3,
                    }}
                    startIcon={<AddCardIcon />}
                    variant="contained"
                    color="secondary"
                >
                    {t('cardCreate.createCardButton')}
                </Button>
            </Box>
        </Paper>
    );
};

BankCardsEmptyCase.propTypes = {
    t: PropTypes.func.isRequired,
    setShowCardCreation: PropTypes.func.isRequired,
};

export default BankCardsEmptyCase;
