import DownloadIcon from '@mui/icons-material/Download';
import {LoadingButton} from '@mui/lab';
import {Box, Button, IconButton, SvgIcon, Tooltip, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const QuickAccessCard = ({
    icon,
    title,
    buttonIcon,
    buttonText,
    tooltip,
    cta,
    downloadCta,
    isCtaDisabled,
    isDownloadDisabled,
    hasLoading,
    isButtonLoading,
}) => {
    return (
        <Box sx={{
            display: 'flex',
            padding: '10px 16px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px',
            flex: '1 0 0',
            borderRadius: 2,
            backgroundColor: 'v2.light.primary.shades4',
            alignSelf: 'stretch',
        }}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 1,
                alignSelf: 'stretch',
            }}
            >
                <SvgIcon sx={{color: 'v2.light.primary.shades50'}}>{icon}</SvgIcon>
                <Typography sx={{fontWeight: 500}}>{title}</Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
            }}
            >
                {hasLoading
                    ? (
                        <LoadingButton
                            loading={isButtonLoading}
                            loadingPosition="start"
                            variant="outlined"
                            startIcon={buttonIcon}
                            sx={{
                                borderRadius: '6px',
                                height: '30px',
                                fontSize: theme => theme.typography.pxToRem(13),
                                fontWeight: 500,
                            }}
                            onClick={cta}
                            disabled={isCtaDisabled}
                        >
                            <span>{buttonText}</span>
                        </LoadingButton>
                    )
                    : (
                        <Button
                            variant="outlined"
                            startIcon={buttonIcon}
                            sx={{
                                borderRadius: '6px',
                                height: '30px',
                                fontSize: theme => theme.typography.pxToRem(13),
                                fontWeight: 500,
                            }}
                            onClick={cta}
                            disabled={isCtaDisabled}
                        >
                            {buttonText}
                        </Button>
                    )}
                <Tooltip placement="top" title={tooltip}>
                    <IconButton
                        color="primary"
                        sx={{width: '32px', height: '32px'}}
                        onClick={downloadCta}
                        disabled={isDownloadDisabled}
                    >
                        <DownloadIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
};

QuickAccessCard.propTypes = {
    icon: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    buttonIcon: PropTypes.element.isRequired,
    buttonText: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    cta: PropTypes.func.isRequired,
    downloadCta: PropTypes.func.isRequired,
    isCtaDisabled: PropTypes.bool,
    isDownloadDisabled: PropTypes.bool,
    hasLoading: PropTypes.bool,
    isButtonLoading: PropTypes.bool,
};

QuickAccessCard.defaultProps = {
    isCtaDisabled: false,
    isDownloadDisabled: false,
    hasLoading: false,
    isButtonLoading: false,
};

export default QuickAccessCard;
