import {all, call, put, takeLatest} from 'redux-saga/effects';
import * as actions from './availableFreelancers.actions';
import {getAvailableFreelancersRequest} from '../../../../features/user-list/api/user.api';

const getAvailableFreelancers = function* getAvailableFreelancers({payload}) {
    yield put(actions.setIsLoadingAvailableFreelancers(true));

    try {
        const {userId} = payload;

        const freelancers = yield call(getAvailableFreelancersRequest, userId);

        yield put(actions.storeAvailableFreelancers(freelancers));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    } finally {
        yield put(actions.setIsLoadingAvailableFreelancers(false));
    }
};

export const watchAvailableFreelancersSaga = function* watchAvailableFreelancersSaga() {
    yield all([takeLatest(actions.GET_AVAILABLE_FREELANCERS, getAvailableFreelancers)]);
};
