import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {BankCardsCreate} from './create/BankCardsCreate';
import BankCardsDetails from './details/BankCardsDetails';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import ConnectProPage from '../../../components/connect/ConnectProPage';
import {BankSelector} from '../../../store/bank.selector';
import {LANDING_PAGE_MODE} from '../../../utils/constants';
import {BANK_TYPES} from '../../bridge-api/utils/constants';
import {CardsSelector} from '../store/cards.selector';

const BankCards = () => {
    const {t} = useTranslation('bankCards');

    const integrations = useSelector(BankSelector.selectIntegrations);
    const hasHiwayIntegration = integrations.find(integration => integration.type === BANK_TYPES.hiway);
    const cardList = useSelector(CardsSelector.selectList);
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GET_CARD),
    );

    if (integrations?.length === 0 || !hasHiwayIntegration) {
        return (
            <ConnectProPage type={LANDING_PAGE_MODE.CARDS} />
        );
    }

    if (cardList.length > 0 || isLoading) {
        return (
            <BankCardsDetails
                t={t}
                cardList={cardList}
                isLoading={isLoading}
            />
        );
    }

    return (<BankCardsCreate t={t} />);
};

export default BankCards;
