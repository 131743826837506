import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Badge, Box, Divider, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SubscriptionTransaction from '../subscription/SubscriptionTransaction';

const PreviewSubscriptionSidebar = ({
    onClose,
    correspondingTransactions,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('bank');

    return (
        <Box sx={{
            p: 2,
            pt: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        }}
        >
            {!isMobileSize && (
            <Box
                sx={{
                    py: 3,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    cursor: 'pointer',
                }}
                onClick={onClose}
            >
                <ArrowBackIcon />
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(22),
                }}
                >
                    {t('categorisation.subscription.previewTransactions')}
                </Typography>
            </Box>
            )}

            <Box sx={{
                pt: 3,
            }}
            >
                <Box sx={{
                    fontSize: theme => theme.typography.pxToRem(21),
                    fontWeight: 700,
                }}
                >
                    {t('categorisation.subscription.previewBack')}

                    <Badge
                        badgeContent={correspondingTransactions.length}
                        color="v2Grey300Palette"
                        sx={{
                            color: 'white !important',
                            ml: 2.5,
                            fontWeight: 500,
                        }}
                    />
                </Box>
                {correspondingTransactions.map(transaction => {
                    return (
                        <React.Fragment key={transaction?.id}>
                            <SubscriptionTransaction
                                id={transaction?.id}
                            />
                            <Divider sx={{my: 1}} />
                        </React.Fragment>
                    );
                })}
            </Box>
        </Box>
    );
};

PreviewSubscriptionSidebar.propTypes = {
    onClose: PropTypes.func.isRequired,
    correspondingTransactions: PropTypes.array.isRequired,
};

export default PreviewSubscriptionSidebar;
