import EuroIcon from '@mui/icons-material/Euro';
import {Box, Grid, InputAdornment, TextField, Typography, capitalize} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {CityAutocomplete} from '../../../../components/CityAutocomplete';

window.moment = moment;

export const OpportunityInformations = ({control, errors, defaultFieldProps}) => {
    const {t} = useTranslation('opportunity');

    const monthList = moment.months();

    return (
        <Box sx={{mb: 4, mt: 3}}>
            <Typography sx={{
                mb: 1.5,
                fontSize: theme => theme.typography.pxToRem(16),
                fontWeight: 600,
            }}
            >
                {t('submitOpportunity.informationsHeader')}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                    <Controller
                        control={control}
                        name="beforeTaxes"
                        render={({field}) => (
                            <TextField
                                label={t('submitOpportunity.beforeTaxes')}
                                error={!!errors.beforeTaxes}
                                helperText={errors.beforeTaxes?.message ?? t('submitOpportunity.beforeTaxesHelperText')}
                                {...field}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <EuroIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                {...defaultFieldProps}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Controller
                        control={control}
                        name="startMonth"
                        render={({field}) => (
                            <TextField
                                label={t('submitOpportunity.startMonth')}
                                error={!!errors.startMonth}
                                helperText={errors.startMonth?.message}
                                {...field}
                                {...defaultFieldProps}
                                select
                            >
                                {monthList.map(month => {
                                    return (
                                        <MenuItem value={month} key={month}>
                                            {capitalize(month)}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <CityAutocomplete
                        control={control}
                        name="venue"
                        label={t('submitOpportunity.venue')}
                        variant="outlined"
                        componentRestrictions={null}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

OpportunityInformations.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    defaultFieldProps: PropTypes.object.isRequired,
};
