import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const DesktopPopularCategory = ({Icon, name, onClick}) => (
    <Box
        onClick={onClick}
        sx={{
            p: 1,
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            backgroundColor: 'v2.light.primary.shades12',
            borderRadius: 2,
            cursor: 'pointer',
        }}
    >
        <Icon sx={{color: 'v2.blueGray.500'}} />
        <Typography
            component="span"
            sx={{
                fontSize: theme => theme.typography.pxToRem(14),
                lineHeight: theme => theme.typography.pxToRem(18),
                letterSpacing: '0.17px',
                fontWeight: 400,
                color: 'text_v2.secondary',
                flex: '1 0 0',
            }}
        >
            {name}
        </Typography>
    </Box>
);

DesktopPopularCategory.propTypes = {
    Icon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default DesktopPopularCategory;
