import DocumentScannerTwoToneIcon from '@mui/icons-material/DocumentScannerTwoTone';
import PlayLessonIcon from '@mui/icons-material/PlayLesson';
import ScreenSearchDesktopTwoToneIcon from '@mui/icons-material/ScreenSearchDesktopTwoTone';
import {Box, Divider, List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {FreelanceStepper} from './FreelanceStepper';
import JoyUIThemeProvider from '../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {importLocaleBundle} from '../../../../../lib/i18next';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {UserRoles, isUserCare} from '../../../../../utils/user-roles';
import {UserStatuses} from '../../../../../utils/user-statuses';
import {getMyCompany} from '../../../../../v1/app/my-companies/myCompanies.actions';
import {selectMyCompany} from '../../../../../v1/app/my-companies/myCompanies.selectors';
import {resolveRoute} from '../../../../../v1/navigation/resolveRoute';
import {ROUTE_PATHS} from '../../../../../v1/navigation/routePaths';
import {getIsRegistrationWithExistingCompany} from '../../../../../v1/utils';
import {ProfileItemSection} from '../../../../sidebar/components/Common/ProfileItemSection';
import {SidebarSelectors} from '../../../../sidebar/store/sidebar.selector';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {FreelancerSelectors} from '../../../store/freelancer.selector';

importLocaleBundle('freelancerOnboarding');
// ONBOARDING SIDEBAR LOCATION CHANGE - Shorthand to find all places we need to change when adding new item
export const FreelancerOnboardingSidebar = () => {
    const {t} = useTranslation(['common']);
    const isSidebarExpanded = useSelector(SidebarSelectors.selectIsSidebarExpanded);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const user = useSelector(FreelancerSelectors.selectAccount);
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const hasMissionSearch = loggedInUser.status !== UserStatuses.CONTRACT_PENDING
        && loggedInUser?.role === UserRoles.FREELANCER;

    const isExpandedOrMobile = isSidebarExpanded || isMobileSize;

    const dispatch = useDispatch();
    const company = useSelector(selectMyCompany);
    useEffect(() => {
        if (!company && loggedInUser?.defaultCompanyId) {
            dispatch(getMyCompany(loggedInUser.defaultCompanyId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInUser]);
    const registrationType = company?.enterpriseInformation?.registrationType;
    const registrationWithExistingCompany = getIsRegistrationWithExistingCompany(registrationType);

    return (
        <Box sx={{pb: 11}}>
            {!isMobileSize && (
                <JoyUIThemeProvider>
                    <ProfileItemSection
                        user={loggedInUser}
                        isSidebarExpanded={isSidebarExpanded}
                    />
                </JoyUIThemeProvider>
            )}

            {!isExpandedOrMobile && <Divider />}
            {isMobileSize && <Box sx={{mt: 2}} />}

            <FreelanceStepper
                isRegistrationWithExistingCompany={registrationWithExistingCompany}
                loggedInUser={loggedInUser}
            />

            <List sx={{mt: 1, pb: 0}}>
                <Divider />

                {!isUserCare(loggedInUser) && (
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: isExpandedOrMobile ? 'initial' : 'center',
                            px: !isExpandedOrMobile ? 4 : 2.5,
                            mt: 1,
                        }}
                        key="DOCUMENTATION"
                        to={resolveRoute(RoutePaths.DOCUMENTATION)}
                        style={{textDecoration: 'none', color: '#0B2333'}}
                        component={Link || undefined}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: isExpandedOrMobile ? 2 : 'auto',
                                justifyContent: 'center',
                                color: 'v2.blueGray.300',
                            }}
                        >
                            <PlayLessonIcon />
                        </ListItemIcon>

                        <ListItemText
                            primary={t('common:sideMenu.documentation')}
                            primaryTypographyProps={{fontSize: theme => theme.typography.pxToRem(14)}}
                            sx={{
                                display: isExpandedOrMobile ? 'inline' : 'none',
                            }}
                        />

                    </ListItemButton>
                )}

                {hasMissionSearch && (
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: isExpandedOrMobile ? 'initial' : 'center',
                            px: !isExpandedOrMobile ? 4 : 2.5,
                        }}
                        key="MISSION_SEARCH"
                        to={resolveRoute(RoutePaths.CLUSTER)}
                        style={{textDecoration: 'none', color: '#0B2333'}}
                        component={Link || undefined}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: isExpandedOrMobile ? 2 : 'auto',
                                justifyContent: 'center',
                                color: 'v2.blueGray.300',
                            }}
                        >
                            <ScreenSearchDesktopTwoToneIcon />
                        </ListItemIcon>

                        <ListItemText
                            primary={t('common:sideMenu.missionSearch')}
                            primaryTypographyProps={{fontSize: theme => theme.typography.pxToRem(14)}}
                            sx={{
                                display: isExpandedOrMobile ? 'inline' : 'none',
                            }}
                        />

                    </ListItemButton>
                )}
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: isExpandedOrMobile ? 'initial' : 'center',
                        px: !isExpandedOrMobile ? 4 : 2.5,
                    }}
                    key="Documents"
                    to={resolveRoute(ROUTE_PATHS.DOCUMENTS, {companyId: user.defaultCompanyId})}
                    style={{textDecoration: 'none', color: '#0B2333'}}
                    disabled={loggedInUser.status === UserStatuses.CONTRACT_PENDING}
                    component={Link || undefined}
                >

                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: isExpandedOrMobile ? 2 : 'auto',
                            justifyContent: 'center',
                            color: 'v2.blueGray.300',
                        }}
                    >
                        <DocumentScannerTwoToneIcon />
                    </ListItemIcon>

                    <ListItemText
                        primary={t('common:sideMenu.documents')}
                        primaryTypographyProps={{fontSize: theme => theme.typography.pxToRem(14)}}
                        sx={{display: isExpandedOrMobile ? 'inline' : 'none'}}
                    />

                </ListItemButton>
            </List>
        </Box>
    );
};
