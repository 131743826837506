import moment from 'moment';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

export const useCommonValidations = () => {
    const {t} = useTranslation();

    const dateValidation = (config = {dateFieldText: '', minDate: null, maxDate: null}) => Yup.date().required(t('setup:validations.isRequired', {what: t(config.dateFieldText)}))
        .typeError(t('invalidDate'))
        .test('min-date-validation', t('setup:validations.minDate'), (val => {
            return config.minDate ? moment(val).isAfter(config.minDate) : true;
        }))
        .test('max-date-validation', t('setup:validations.maxDate'), (val => {
            return config.maxDate ? moment(val).isBefore(config.maxDate) : true;
        }));

    const dateOfBirthValidation = (dateFieldText = 'setup:dateOfBirth') => dateValidation({dateFieldText, minDate: moment('01/01/1900'), maxDate: moment().add(1, 'days')});

    return {
        dateValidation,
        dateOfBirthValidation,
    };
};
