import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LaptopMacTwoToneIcon from '@mui/icons-material/LaptopMacTwoTone';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Box, IconButton, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ConditionalTooltip} from '../../../../components/tooltip/ConditionalTooltip';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {OpportunitiesActions} from '../../store/opportunity.action';
import RequiredProfile from '../datatable/RequiredProfile';

const MyBid = ({bid, isFirst}) => {
    const {t} = useTranslation('opportunity');

    const dispatch = useDispatch();
    const {opportunityId, accepted, freelancerWonBidding, clientName} = bid;

    const isApplyInProgress = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.APPLY_OPPORTUNITY),
    );

    const failed = accepted && !freelancerWonBidding;
    const canUnapply = !accepted && !freelancerWonBidding;

    const tooltip = failed ? t('freelancerOpportunity.canNotApply') : '';

    const handleUnapply = () => {
        dispatch(OpportunitiesActions.unapply({opportunityId}));
    };

    return (
        <ConditionalTooltip tooltip={tooltip} placement="top">
            <Box sx={{
                display: 'flex',
                py: 1,
                gap: 1,
                alignItems: 'center',
                borderTop: isFirst ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
                backgroundColor: failed ? 'gray_v2.50' : 'transparent',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    flex: '1 0 0',
                    opacity: failed ? 0.5 : 1,
                }}
                >
                    <LaptopMacTwoToneIcon />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                    >
                        <Typography sx={{
                            color: 'v2.blueGray.600',
                            fontWeight: 500,
                            fontSize: theme => theme.typography.pxToRem(13),
                            lineHeight: theme => theme.typography.pxToRem(22),
                            letterSpacing: '0.46px',
                            textTransform: 'uppercase',
                        }}
                        >
                            {clientName}
                        </Typography>
                        <RequiredProfile rowData={bid} isInMyBids />
                    </Box>
                </Box>

                {freelancerWonBidding && (
                    <CheckCircleOutlineIcon
                        sx={{
                            p: 0.5,
                            color: 'v2.light.success.light',
                            fontSize: 32,
                        }}
                    />
                )}

                {failed && (
                    <CancelIcon
                        sx={{
                            p: 0.5,
                            color: 'v2.light.action.active',
                            fontSize: 32,
                        }}
                    />
                )}

                {canUnapply && (
                    <IconButton
                        disabled={isApplyInProgress}
                        size="medium"
                        sx={{
                            p: 0.5,
                            color: 'v2.light.warning.light',
                        }}
                        onClick={handleUnapply}
                    >
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                )}
            </Box>
        </ConditionalTooltip>
    );
};

MyBid.propTypes = {
    bid: PropTypes.any.isRequired,
    isFirst: PropTypes.bool.isRequired,
};

export default React.memo(MyBid);
