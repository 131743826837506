export const capitalizeInitials = fullName => {
    fullName = fullName.trim();

    const parts = fullName.split(' ');

    let initials = '';

    for (const part of parts) {
        if (part) { // Check if the part is not empty
            initials += part.charAt(0).toUpperCase();
        }
    }

    // Check if the string has more than two letters
    if (initials.length > 2) {
        // Return the first two letters of the string
        return initials.slice(0, 2);
    }

    return initials;
};
