import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import InfoIcon from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SendIcon from '@mui/icons-material/Send';
import UploadIcon from '@mui/icons-material/Upload';
import {LoadingButton} from '@mui/lab';
import {Box, Chip, IconButton, Slide, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import UploadDocumentModal from './UploadDocumentModal';
import CapitalDepositDocumentsRefusedSvg from '../../../../../../../assets/svg/bank/capital-deposit-documents-refused.svg';
import {ConditionalTooltip} from '../../../../../../../components/tooltip/ConditionalTooltip';
import BankInformationLayout from '../../../../../../../layout/BankInformationLayout/BankInformationLayout';
import {AnimationActions} from '../../../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../../../animations/utils/constants';
import {BankActions} from '../../../../../../bank/store/bank.action';
import {BankSelector} from '../../../../../../bank/store/bank.selector';
import {CapitalDepositDocumentStatus} from '../../../../../../bank/utils/constants';
import {LoadingSelectors, LoadingTypes} from '../../../../../../loading';
import {UiActions} from '../../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../../ui/utils/constants';
import {CapitalDepositStatus} from '../../../utils/constants';
import {mapCapitalDepositDocType} from '../../../utils/functions';

const DEFAULT_REASON = 'InvalidDocument';

const CapitalDepositDocumentsRefused = ({setForceShowStep}) => {
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const {t} = useTranslation('freelancerOnboarding');
    const {t: tDoc} = useTranslation('documents');

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const isUploading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.UPDATE_REFUSED_DOCUMENTS),
    );

    useEffect(() => {
        if (isUploading === false) {
            setForceShowStep(CapitalDepositStatus.WAITING_FOR_REQUIREMENTS_APPROVAL);
        }
    }, [isUploading, setForceShowStep]);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const deposit = useSelector(BankSelector.selectCapitalDeposit);

    const refusedDocs = deposit.uploadedDocuments.filter(
        ({status}) => status === CapitalDepositDocumentStatus.REFUSED,
    );

    const [updatedDocs, setUpdatedDocs] = useState(refusedDocs);
    const [fileId, setFileId] = useState('');
    const [fileType, setFileType] = useState('');

    const updatedDocsMap = updatedDocs.reduce((res, obj) => ({...res, [obj.integrationId]: obj}), {});

    const handleUploadButtonClick = (integrationId, type) => () => {
        setFileId(integrationId);
        setFileType(type);
        dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_REFUSED_DOCUMENT, true));
    };

    const handleFileSelect = file => {
        const nextUpdatedDocs = updatedDocs.map(doc => {
            return doc.integrationId === fileId
                ? {
                    ...doc,
                    isUpdated: true,
                    file,
                }
                : doc;
        });
        setUpdatedDocs(nextUpdatedDocs);
        setFileId('');
        setFileType('');
    };

    const handleUploadDocuments = () => {
        dispatch(BankActions.updateRefusedDocuments(updatedDocs));
    };

    const isSomeRefusedDocs = updatedDocs.some(({isUpdated}) => !isUpdated);

    return (
        <BankInformationLayout
            header={t('capitalDepositDocumentsRefused.headline')}
            subTitle={t('capitalDepositDocumentsRefused.subtitle')}
        >
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '600px',
                    gap: 2,
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                    }}
                    >
                        <Box>
                            <img src={CapitalDepositDocumentsRefusedSvg} alt="capital deposit documents refused" />
                        </Box>

                        <Typography variant="h4">
                            {t('capitalDepositDocumentsRefused.title')}
                        </Typography>

                        <Typography sx={{color: 'text_v2.secondary'}}>
                            {t('capitalDepositDocumentsRefused.text')}
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        gap: 1,
                        minWidth: '70%',
                    }}
                    >
                        <Typography sx={{
                            fontWeight: 500,
                            color: 'text_v2.secondary',
                            textAlign: 'left',
                        }}
                        >
                            {t('capitalDepositDocumentsRefused.subtitle2')}
                        </Typography>

                        {updatedDocs.map(({integrationId, isUpdated, type, reason}) => {
                            const adjustedDocType = mapCapitalDepositDocType(type);
                            return isMobileSize ? (
                                <Box
                                    key={integrationId}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        backgroundColor: 'v2.light.primary.shades4',
                                        borderRadius: 2,
                                        p: 1,
                                        pl: 2,
                                        flex: '1 0 0',
                                        gap: 1,
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}
                                    >
                                        {tDoc(`documentsDatabaseNames.${adjustedDocType}`)}
                                        {!isUpdated && (
                                            <ConditionalTooltip
                                                tooltip={t(`refuseReasons.${reason ?? DEFAULT_REASON}`)}
                                            >
                                                <InfoIcon sx={{
                                                    color: 'v2.light.info.main',
                                                    fontSize: '22px',
                                                }}
                                                />
                                            </ConditionalTooltip>
                                        )}
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: 1,
                                        flex: '1 0 0',
                                    }}
                                    >
                                        {!isUpdated ? (
                                            <Chip
                                                sx={{
                                                    fontSize: theme => theme.typography.pxToRem(12),
                                                    borderRadius: 4,
                                                    fontWeight: 400,
                                                    letterSpacing: '0.4px',
                                                    height: '20px',
                                                    backgroundColor: 'v2.light.warning.shades4',
                                                    color: 'v2.light.warning.saturated',
                                                }}
                                                size="small"
                                                variant="contained"
                                                label={t('capitalDepositDocumentsRefused.refused')}
                                                icon={<DoNotDisturbOnIcon sx={{color: 'inherit !important'}} />}
                                            />
                                        ) : (
                                            <Chip
                                                sx={{
                                                    fontSize: theme => theme.typography.pxToRem(12),
                                                    borderRadius: 4,
                                                    fontWeight: 400,
                                                    letterSpacing: '0.4px',
                                                    height: '20px',
                                                    backgroundColor: 'v2.light.info.shades4',
                                                    color: 'v2.light.info.main',
                                                }}
                                                size="small"
                                                variant="contained"
                                                label={t('capitalDepositDocumentsRefused.uploaded')}
                                                icon={<CheckIcon sx={{color: 'inherit !important'}} />}
                                            />
                                        )}
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                        }}
                                        >
                                            {isUpdated && (
                                                <IconButton
                                                    size="medium"
                                                    onClick={() => {
                                                        const obj = updatedDocsMap[integrationId];
                                                        const fileURL = window.URL.createObjectURL(obj.file.file);
                                                        const alink = document.createElement('a');
                                                        alink.href = fileURL;
                                                        alink.download = 'uploaded';
                                                        alink.click();
                                                    }}
                                                    sx={{
                                                        p: 1,
                                                        backgroundColor: 'v2.light.primary.shades4',
                                                        borderRadius: 2,
                                                        color: 'text_v2.secondary',
                                                    }}
                                                >
                                                    <OpenInNewIcon />
                                                </IconButton>
                                            )}
                                            <IconButton
                                                size="medium"
                                                onClick={handleUploadButtonClick(integrationId, type)}
                                                sx={{
                                                    p: 1,
                                                    backgroundColor: 'v2.light.primary.shades4',
                                                    borderRadius: 2,
                                                    color: 'text_v2.secondary',
                                                }}
                                            >
                                                <UploadIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <Box
                                    key={integrationId}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: 1,
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: 'v2.light.primary.shades4',
                                        borderRadius: 2,
                                        p: 1,
                                        pl: 2,
                                        gap: 1,
                                        flex: '1 0 0',
                                    }}
                                    >
                                        {tDoc(`documentsDatabaseNames.${adjustedDocType}`)}
                                        {!isUpdated && (
                                            <ConditionalTooltip
                                                tooltip={t(`refuseReasons.${reason ?? DEFAULT_REASON}`)}
                                            >
                                                <InfoIcon sx={{
                                                    color: 'v2.light.info.main',
                                                    fontSize: '22px',
                                                }}
                                                />
                                            </ConditionalTooltip>
                                        )}
                                        {!isUpdated ? (
                                            <Chip
                                                sx={{
                                                    fontSize: theme => theme.typography.pxToRem(12),
                                                    borderRadius: 4,
                                                    fontWeight: 400,
                                                    letterSpacing: '0.4px',
                                                    height: '20px',
                                                    backgroundColor: 'v2.light.warning.shades4',
                                                    color: 'v2.light.warning.saturated',
                                                    ml: 'auto',
                                                }}
                                                size="small"
                                                variant="contained"
                                                label={t('capitalDepositDocumentsRefused.refused')}
                                                icon={<DoNotDisturbOnIcon sx={{color: 'inherit !important'}} />}
                                            />
                                        ) : (
                                            <Chip
                                                sx={{
                                                    fontSize: theme => theme.typography.pxToRem(12),
                                                    borderRadius: 4,
                                                    fontWeight: 400,
                                                    letterSpacing: '0.4px',
                                                    height: '20px',
                                                    backgroundColor: 'v2.light.info.shades4',
                                                    color: 'v2.light.info.main',
                                                    ml: 'auto',
                                                }}
                                                size="small"
                                                variant="contained"
                                                label={t('capitalDepositDocumentsRefused.uploaded')}
                                                icon={<CheckIcon sx={{color: 'inherit !important'}} />}
                                            />
                                        )}
                                    </Box>
                                    {isUpdated && (
                                        <IconButton
                                            size="medium"
                                            onClick={() => {
                                                const obj = updatedDocsMap[integrationId];
                                                const fileURL = window.URL.createObjectURL(obj.file.file);
                                                const alink = document.createElement('a');
                                                alink.href = fileURL;
                                                alink.download = 'uploaded';
                                                alink.click();
                                            }}
                                            sx={{
                                                p: 1,
                                                backgroundColor: 'v2.light.primary.shades4',
                                                borderRadius: 2,
                                                color: 'text_v2.secondary',
                                            }}
                                        >
                                            <OpenInNewIcon />
                                        </IconButton>
                                    )}
                                    <IconButton
                                        size="medium"
                                        onClick={handleUploadButtonClick(integrationId, type)}
                                        sx={{
                                            p: 1,
                                            backgroundColor: 'v2.light.primary.shades4',
                                            borderRadius: 2,
                                            color: 'text_v2.secondary',
                                        }}
                                    >
                                        <UploadIcon />
                                    </IconButton>
                                </Box>
                            );
                        })}
                    </Box>

                    <LoadingButton
                        color="primary"
                        variant="contained"
                        disabled={isSomeRefusedDocs}
                        loading={isUploading}
                        loadingPosition="start"
                        startIcon={<SendIcon />}
                        onClick={handleUploadDocuments}
                        sx={{px: 3}}
                    >
                        {t('capitalDepositDocumentsRefused.button')}
                    </LoadingButton>
                </Box>
            </Slide>

            <UploadDocumentModal onFileSelect={handleFileSelect} fileType={fileType} />
        </BankInformationLayout>
    );
};

CapitalDepositDocumentsRefused.propTypes = {
    setForceShowStep: PropTypes.func.isRequired,
};

export default CapitalDepositDocumentsRefused;
