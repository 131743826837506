import makeStyles from '@mui/styles/makeStyles';

export const usePageHeaderStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    hasMargin: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            marginBottom: theme.spacing(2),
        },
    },
    isCentered: {
        textAlign: 'center',
    },
    title: {
        [theme.breakpoints.down('lg')]: {
            fontSize: theme.typography.pxToRem(34),
        },
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(28),
        },
    },
    titleAndSubTitleContainer: {
        flex: 1,
    },
    subTitle: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
        letterSpacing: '0.1px',
        color: 'rgba(0, 0, 0, 0.54)',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            fontSize: theme.typography.pxToRem(14),
        },
    },
    missingTitle: {
        fontStyle: 'italic',
        color: 'rgba(0, 0, 0, 0.3)',
    },
}));
