import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getClockUtilityClass = slot => {
    return generateUtilityClass('MuiClock', slot);
};

export const clockClasses = generateUtilityClasses('MuiClock', [
    'root',
    'clock',
    'wrapper',
    'squareMask',
    'pin',
    'amButton',
    'pmButton',
]);
