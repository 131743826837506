import makeStyles from '@mui/styles/makeStyles';

export const useDataTableColumnFilterStyles = makeStyles(theme => ({
    tableCell: {
        padding: theme.spacing(1, 3),
    },
    textField: {
        'display': 'inline-flex',
        'fontSize': theme.typography.pxToRem(14),
        '& input': {
            display: 'inline-flex',
            width: '100%',
        },
    },
}));
