import {yupResolver} from '@hookform/resolvers/yup';
import SendIcon from '@mui/icons-material/Send';
import {LoadingButton} from '@mui/lab';
import {Box, Grid, TextField, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Yup from '../../../../lib/yup/yup';
import DropZone from '../../../../v1/components/ui-kit/DropZone';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {TrainingActions} from '../../store/training.action';

const defaultValues = {
    message: '',
    file: [],
};

export const FundingRefused = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('training');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isSaving = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.TRAINING_REJECT_FORM_SUBMIT),
    );

    const validationSchema = Yup.object().shape({
        message: Yup.string().max(100, t('form:validation.stringMaxLength', {length: 100})),
        file: Yup.array()
            .required(t('requiredField'))
            .min(1, t('setup:validations.minFileMessage')),
    });

    const {
        control,
        formState: {errors, isValid, isDirty},
        handleSubmit,
        watch,
        setValue,
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(data => {
        dispatch(TrainingActions.saveRejectedForm(data));
    }, [dispatch]);

    const defaultFieldProps = {
        fullWidth: true,
        variant: 'outlined',
        disabled: isSaving,
    };

    const onDropAccepted = file => {
        setValue('file', file, {shouldValidate: true, shouldDirty: true});
    };

    const onFileDelete = () => {
        setValue('file', [], {shouldValidate: true, shouldDirty: true});
    };

    const file = watch('file');

    return (
        <Box
            sx={{
                background: 'rgba(25, 118, 210, 0.04)',
                borderRadius: '16px',
                px: 3,
                pb: 3,
                pt: 2,
            }}
        >
            <Typography sx={{fontWeight: 600}}>
                {t('fundingApproval.refusedText')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{
                    '>.backgroundColorWhite': {
                        backgroundColor: '#FFF !important',
                    },
                }}
                >
                    <DropZone
                        className="backgroundColorWhite"
                        setIsOpen={true}
                        isMultiple={false}
                        name="file"
                        buttonText={t('fundingApproval.refusedUploadButton')}
                        dropZoneText={t('fundingApproval.refusedUploadText')}
                        acceptFiles="image/jpeg, image/png, image/jpg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        // readableFileExtension="PDF"
                        onDropAccepted={onDropAccepted}
                        files={file}
                        onDeleteFile={onFileDelete}
                        errorMessage={errors.files?.message}
                    />
                </Box>
                <Box sx={{mt: 2}}>
                    <Grid item xs={12} lg={6}>
                        <Controller
                            control={control}
                            name="message"
                            render={({field}) => (
                                <TextField
                                    sx={{background: 'white'}}
                                    multiline
                                    label={t('fundingApproval.refusedMessage')}
                                    error={!!errors.message}
                                    helperText={errors.message?.message}
                                    {...field}
                                    {...defaultFieldProps}
                                />
                            )}
                        />
                    </Grid>
                </Box>
                <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end'}}>
                    <LoadingButton
                        size="medium"
                        sx={{height: '36px'}}
                        color="primary"
                        type="submit"
                        variant="contained"
                        fullWidth={isMobileSize && !(!isValid || !isDirty)}
                        startIcon={<SendIcon />}
                        disabled={!isValid || !isDirty}
                        loading={isSaving}
                    >
                        {t('fundingApproval.refusedButton')}
                    </LoadingButton>
                </Box>
            </form>
        </Box>
    );
};
