import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const LegalStatusAndTaxSystem = ({rowData, translate}) => {
    const typographySx = {
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        color: 'v2.light.text.main',
        textDecoration: 'none',
        fontSize: theme => theme.typography.pxToRem(14),
    };

    if (!rowData?.legalForm) {
        return null;
    }

    if (rowData?.legalForm === '-') {
        return '-';
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 0.5,
        }}
        >

            <Typography sx={{...typographySx, fontWeight: 500}}>
                {translate(`legalStatuses.${rowData?.legalForm}`)}
            </Typography>
            <Typography sx={typographySx}>
                {translate(`taxSystems.${rowData?.taxSystem}`)}
            </Typography>
        </Box>

    );
};

LegalStatusAndTaxSystem.propTypes = {
    rowData: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

export default LegalStatusAndTaxSystem;
