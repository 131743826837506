import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {SuspenseContainer} from '../../components/suspense/Suspense';

/*
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 * TODO: We already have similar layouts, we should definitely extract this
 * and reuse it!
 * +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 */
export const DepositOfAccountsLayout = ({suspenseFallback}) => {
    return (
        <Box display="flex" height="100vh" minWidth="100vw" flexDirection="row">
            <Suspense fallback={suspenseFallback || <SuspenseContainer />}>
                <Outlet />
            </Suspense>
        </Box>
    );
};

DepositOfAccountsLayout.propTypes = {
    suspenseFallback: PropTypes.element.isRequired,
};
