import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getClockPickerUtilityClass = slot => {
    return generateUtilityClass('MuiClockPicker', slot);
};

export const clockPickerClasses = generateUtilityClasses('MuiClockPicker', [
    'root',
    'arrowSwitcher',
]);
