export const SET_IS_LOADING_APP = 'SET_IS_LOADING_APP';
export const SET_SHOULD_RENDER_COMPANY_REGISTERED_OVERLAY = 'SET_SHOULD_RENDER_COMPANY_REGISTERED_OVERLAY';
export const SET_IS_SIDEBAR_SHRUNK = 'SET_IS_SIDEBAR_SHRUNK';
export const SET_IS_MAINTENANCE_MODE_ACTIVE = 'SET_IS_MAINTENANCE_MODE_ACTIVE';
export const SET_COLLAPSED_SIDEBAR_LISTS = 'SET_COLLAPSED_SIDEBAR_LISTS';

export const setIsLoadingApp = isLoadingApp => ({
    type: SET_IS_LOADING_APP,
    payload: isLoadingApp,
});

export const setShouldRenderCompanyRegisteredOverlay = shouldRenderCompanyRegisteredOverlay => ({
    type: SET_SHOULD_RENDER_COMPANY_REGISTERED_OVERLAY,
    payload: shouldRenderCompanyRegisteredOverlay,
});

export const setIsSidebarShrunk = isSidebarShrunk => ({
    type: SET_IS_SIDEBAR_SHRUNK,
    payload: isSidebarShrunk,
});

export const setIsMaintenanceModeActive = isMaintenanceModeActive => ({
    type: SET_IS_MAINTENANCE_MODE_ACTIVE,
    payload: isMaintenanceModeActive,
});

export const setCollapsedSidebarLists = lists => ({
    type: SET_COLLAPSED_SIDEBAR_LISTS,
    payload: lists,
});
