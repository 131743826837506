import {put} from 'redux-saga/effects';
import {OnboardingActions} from './onboarding.action';
import {CapitalDepositInternalSubSteps} from '../../capital-deposit/utils/constants';
import {OnboardingSteps} from '../utils/onboadingStepsConstant';

const goToBankCDIdentityValidation = function* ({progress}) {
    yield put(OnboardingActions.setSubStep(
        CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION,
    ));
    yield put(OnboardingActions.setInternalSubStep(
        CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION,
    ));

    yield put(OnboardingActions.setProgress({
        ...progress,
        [OnboardingSteps.CONTRACT_SIGNING]: {
            ...progress[OnboardingSteps.CONTRACT_SIGNING],
            isCompleted: true,
        },
        [OnboardingSteps.WORKSHOPS]: {
            ...progress[OnboardingSteps.WORKSHOPS],
            isCompleted: true,
        },
        [OnboardingSteps.COMPANY_SETUP]: {
            ...progress[OnboardingSteps.COMPANY_SETUP],
            isCompleted: true,
        },
        [OnboardingSteps.CAPITAL_DEPOSIT]: {
            ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
            subStepProgress: {
                [CapitalDepositInternalSubSteps.BANK_KYC_VALIDATION]: {
                    isCompleted: true,
                },
                [CapitalDepositInternalSubSteps.BANK_SEND_INFORMATION]: {
                    isCompleted: true,
                },
                [CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION]: {
                    isCompleted: false,
                },
                [CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER]: {
                    isCompleted: false,
                },
                [CapitalDepositInternalSubSteps.BANK_CAPITOL_DEPOSIT_VALIDATION]: {
                    isCompleted: false,
                },
            },
        },
    }));
};

const goToBankCDFundTransfer = function* ({progress}) {
    yield put(OnboardingActions.setSubStep(CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER));
    yield put(OnboardingActions.setInternalSubStep(
        CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER,
    ));

    yield put(OnboardingActions.setProgress({
        ...progress,
        [OnboardingSteps.CONTRACT_SIGNING]: {
            ...progress[OnboardingSteps.CONTRACT_SIGNING],
            isCompleted: true,
        },
        [OnboardingSteps.WORKSHOPS]: {
            ...progress[OnboardingSteps.WORKSHOPS],
            isCompleted: true,
        },
        [OnboardingSteps.COMPANY_SETUP]: {
            ...progress[OnboardingSteps.COMPANY_SETUP],
            isCompleted: true,
        },
        [OnboardingSteps.CAPITAL_DEPOSIT]: {
            ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
            subStepProgress: {
                [CapitalDepositInternalSubSteps.BANK_KYC_VALIDATION]: {
                    isCompleted: true,
                },
                [CapitalDepositInternalSubSteps.BANK_SEND_INFORMATION]: {
                    isCompleted: true,
                },
                [CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION]: {
                    isCompleted: true,
                },
                [CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER]: {
                    isCompleted: false,
                },
                [CapitalDepositInternalSubSteps.BANK_CAPITOL_DEPOSIT_VALIDATION]: {
                    isCompleted: false,
                },
            },
        },
    }));
};

const goToBankCDDepositValidation = function* ({progress}) {
    yield put(OnboardingActions.setSubStep(
        CapitalDepositInternalSubSteps.BANK_CAPITOL_DEPOSIT_VALIDATION,
    ));
    yield put(OnboardingActions.setInternalSubStep(
        CapitalDepositInternalSubSteps.BANK_CAPITOL_DEPOSIT_VALIDATION,
    ));

    yield put(OnboardingActions.setProgress({
        ...progress,
        [OnboardingSteps.CONTRACT_SIGNING]: {
            ...progress[OnboardingSteps.CONTRACT_SIGNING],
            isCompleted: true,
        },
        [OnboardingSteps.WORKSHOPS]: {
            ...progress[OnboardingSteps.WORKSHOPS],
            isCompleted: true,
        },
        [OnboardingSteps.COMPANY_SETUP]: {
            ...progress[OnboardingSteps.COMPANY_SETUP],
            isCompleted: true,
        },
        [OnboardingSteps.CAPITAL_DEPOSIT]: {
            ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
            subStepProgress: {
                [CapitalDepositInternalSubSteps.BANK_KYC_VALIDATION]: {
                    isCompleted: true,
                },
                [CapitalDepositInternalSubSteps.BANK_SEND_INFORMATION]: {
                    isCompleted: true,
                },
                [CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION]: {
                    isCompleted: true,
                },
                [CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER]: {
                    isCompleted: true,
                },
                [CapitalDepositInternalSubSteps.BANK_CAPITOL_DEPOSIT_VALIDATION]: {
                    isCompleted: false,
                },
            },
        },
    }));
};

export const onboardingStepSetter = {
    capitalDeposit: {
        goToBankCDIdentityValidation,
        goToBankCDFundTransfer,
        goToBankCDDepositValidation,
    },
};
