import {TransactionCategorizationWarningType} from '../../../util/constants';

export const hasMismatchArticleAmount = categorizationWarnings => {
    if (!categorizationWarnings || categorizationWarnings?.length === 0) {
        return false;
    }

    return categorizationWarnings.includes(TransactionCategorizationWarningType.ArticleAmountMismatch);
};

export const hasMissingArticleData = categorizationWarnings => {
    if (!categorizationWarnings || categorizationWarnings?.length === 0) {
        return false;
    }

    return categorizationWarnings.includes(TransactionCategorizationWarningType.MissingArticleDescription);
};

export const checkIsArticleMissingData = articles => {
    if (!articles) {
        return false;
    }

    let invalidData = false;
    articles.forEach(article => {
        if (
            typeof article?.amount === 'undefined'
            || article?.amount === ''
            || !article?.description
        ) {
            invalidData = true;
        }
    });

    return invalidData;
};
