import React from 'react';
import {FreelancerOpportunity} from '../../features/opportunity/components/FreelancerOpportunity';

const FreelancerOpportunityList = () => {
    return (
        <FreelancerOpportunity />
    );
};

export default FreelancerOpportunityList;
