import {DashboardV3ActionTypes} from './dashboard-v3.action-type';

const {
    FETCH_DATA,
    STORE_DATA,
    REQUEST_REMUNERATION_CALCULATION,
    SET_IS_REMUNERATION_INSTANT_TRANSFER_OPEN,
} = DashboardV3ActionTypes;

const fetchData = () => ({
    type: FETCH_DATA,
});
const storeData = payload => ({
    type: STORE_DATA,
    payload,
});
const requestRemunerationCalculation = payload => ({
    type: REQUEST_REMUNERATION_CALCULATION,
    payload,
});
const setIsRemunerationInstantTransferOpen = payload => ({
    type: SET_IS_REMUNERATION_INSTANT_TRANSFER_OPEN,
    payload,
});

export const DashboardV3Actions = {
    fetchData,
    storeData,
    requestRemunerationCalculation,
    setIsRemunerationInstantTransferOpen,
};
