import React from 'react';
import JoyUIThemeProvider from './JoyUiThemeProvider';

const withJoyUI = WrappedComponent => {
    return function EnhancedComponent(props) {
        return (
            <JoyUIThemeProvider>
                <WrappedComponent {...props} />
            </JoyUIThemeProvider>
        );
    };
};

export default withJoyUI;
