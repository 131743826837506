import PropTypes from 'prop-types';
import React, {useState} from 'react';
import BankCardCreateAddress from './BankCardCreateAddress';
import BankCardSettings from './BankCardSettings';
import BankCardsEmptyCase from '../BankCardsEmptyCase';

export const BankCardsCreate = ({t}) => {
    const [showCardCreation, setShowCardCreation] = useState(false);
    const [cardSettings, setCardSettings] = useState(null);

    if (cardSettings) {
        return (
            <BankCardCreateAddress
                t={t}
                cardSettings={cardSettings}
                setCardSettings={setCardSettings}
            />
        );
    }

    if (showCardCreation) {
        return (
            <BankCardSettings
                t={t}
                setCardSettings={setCardSettings}
                setShowCardCreation={setShowCardCreation}
            />
        );
    }

    return (
        <BankCardsEmptyCase setShowCardCreation={setShowCardCreation} t={t} />
    );
};

BankCardsCreate.propTypes = {
    t: PropTypes.func.isRequired,
};
