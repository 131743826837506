import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {Typography} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ErrPageWrapper} from './common/ErrPageWrapper';
import withJoyUI from '../../../components/joy-ui/joyui-theme-provider/JoyUiThemePRoviderWrapper';
import {push} from '../../../lib/router/connected-router-saga';
import {setShouldRenderCompanyRegisteredOverlay} from '../../../v1/app/app.actions';
import {getConfig} from '../../../v1/config';

const CompanyRegisteredOverlay = () => {
    const {t} = useTranslation('common');
    const dispatch = useDispatch();

    const onSubmit = event => {
        event.preventDefault();
        dispatch(setShouldRenderCompanyRegisteredOverlay(false));
        dispatch(push(getConfig().ROUTE_PATHS.DASHBOARD));
    };

    return (
        <ErrPageWrapper
            onSubmit={onSubmit}
            title={t('companyRegisteredOverlay.message')}
            buttonLabel={t('companyRegisteredOverlay.buttonTitle')}
            buttonIcon={faArrowRight}
            buttonIconPosition="end"
        >
            <Typography
                level="h1"
                sx={{
                    color: 'success.700',
                    textAlign: 'center',
                    fontSize: {wide: '64px', desktop: '56px', mobile: '36px'},
                }}
            >
                {t('companyRegisteredOverlay.title')}
            </Typography>
        </ErrPageWrapper>
    );
};

export default withJoyUI(CompanyRegisteredOverlay);
