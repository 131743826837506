import CheckIcon from '@mui/icons-material/Check';
import {Box, LinearProgress, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {RevenueSimulatorSelectors} from '../../../store/revenue-simulator.selector';
import {useInputSteps} from '../utils/useInputSteps';
import {useStepGroups} from '../utils/useStepGroups';

const useStyles = makeStyles({
    root: {
        backgroundColor: 'transparent;',
    },
    bar: {
        backgroundColor: 'v2.light.primary.main',
        borderTopRightRadius: '2px',
    },
});

const StepGroupProgress = ({group}) => {
    const currentStepIndex = useSelector(RevenueSimulatorSelectors.selectCurrentStepIndex);
    const stepGroups = useStepGroups();
    const inputSteps = useInputSteps();

    const groupNumber = stepGroups.findIndex(g => g.name === group.name) + 1;
    const totalStepsInGroup = group.steps.length;
    const firstGroupStepIndex = inputSteps.indexOf(group.steps[0]);
    const groupCompletedSteps = Math.max(0, currentStepIndex - firstGroupStepIndex);
    const progress = Math.min(groupCompletedSteps, totalStepsInGroup) / totalStepsInGroup;

    const isComplete = progress === 1;
    const isDisabled = currentStepIndex < firstGroupStepIndex;

    const styles = useStyles();
    const {t} = useTranslation('revenueSimulator');

    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                filter: isDisabled ? '' : 'drop-shadow(0px 2px 1px #ddd)',
            }}
        >
            <Box
                sx={{
                    backgroundColor: isDisabled ? 'v2.blueGray.50' : 'white',
                    flex: 1,
                    borderRadius: '8px',
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        p: 1,
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 1,
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: isComplete
                                ? 'v2.light.success.light'
                                : isDisabled
                                    ? 'v2.blueGray.100'
                                    : 'v2.light.primary.main',
                            borderRadius: '50%',
                            width: '24px',
                            height: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            fontSize: theme => theme.typography.pxToRem(12),
                            fontFamily: 'Barlow',
                            fontWeight: 600,
                        }}
                    >
                        {isComplete ? (
                            <CheckIcon
                                sx={{
                                    fontSize: theme => theme.typography.pxToRem(20),
                                }}
                            />
                        ) : (
                            groupNumber
                        )}
                    </Box>
                    <Typography
                        variant="subtitle2_v2"
                        fontWeight="600"
                        color={isComplete ? 'v2.light.success.light' : isDisabled ? 'v2.blueGray.200' : 'text_v2.secondary'}
                    >
                        {t(group.name)}
                    </Typography>
                </Box>
                <LinearProgress
                    variant="determinate"
                    value={progress * 100}
                    classes={{
                        root: styles.root,
                        bar: styles.bar,
                    }}
                    sx={{
                        visibility: isComplete ? 'hidden' : 'visible',
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                    }}
                />
            </Box>
            <Box
                sx={{
                    color: isDisabled ? 'v2.blueGray.50' : 'white',
                    width: 0,
                    height: 0,
                    borderLeft: '12px solid currentColor',
                    borderTop: '24px solid transparent',
                    borderBottom: '24px solid transparent',
                    position: 'relative',
                    zIndex: 1,
                }}
            />
        </Box>
    );
};

StepGroupProgress.propTypes = {
    group: PropTypes.shape({
        steps: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
};

export const ProgressIndicator = () => {
    const stepGroups = useStepGroups();
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1,
                mb: 2,
            }}
        >
            {stepGroups.map(group => (
                <StepGroupProgress group={group} key={group.name} />
            ))}
        </Box>
    );
};
