import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './professionalExpensesForm.actions';
import {Toast} from '../../../../lib/toast';
import {createProfessionalExpenseRequest} from '../../api/providers/expenses/expenses.provider';
import {selectFreelancerAccount} from '../../freelancer/freelancer.selectors';
import {getProfessionalExpenses} from '../professionalExpenses/professionalExpenses.actions';

const submitProfessionalExpensesFormSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingProfessionalExpensesForm(true));

        const freelancerAccount = yield select(selectFreelancerAccount);

        yield call(
            createProfessionalExpenseRequest,
            freelancerAccount.id,
            freelancerAccount.defaultCompanyId,
            payload.formData,
        );

        if (payload.onSuccess && typeof payload.onSuccess === 'function') {
            payload.onSuccess();
        }

        yield put(getProfessionalExpenses(freelancerAccount.id, freelancerAccount.defaultCompanyId));

        Toast.success('expenseProfessionalCreated');
    } catch (error) {
        if (error?.response?.status === 413) {
            Toast.error('fileTooLarge');

            return;
        }

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingProfessionalExpensesForm(false));
    }
};

export const watchProfessionalExpensesFormSagas = function* () {
    yield all([
        takeLatest(actions.SUBMIT_PROFESSIONAL_EXPENSES_FORM, submitProfessionalExpensesFormSaga),
    ]);
};
