import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const ProgressBar = ({
    total,
    nonCategorized,
    isOpenedCategorized,
}) => {
    let percents = Math.round(100 * (total - nonCategorized) / total);

    if (percents < 0) {
        percents = 0;
    }

    if (percents > 100) {
        percents = 100;
    }

    return (
        <Box sx={{
            height: '12px',
            borderRadius: '6px',
            backgroundColor: 'v2.blueGray.50',
            position: 'relative',
        }}
        >
            <Box sx={{
                height: '12px',
                borderRadius: '6px',
                background: 'linear-gradient(90deg, #ECAC4C 0%, #EB8C4A 22.92%, #EA6D4B 44.79%, #E84C4C 67.19%, #0C577C 88.02%)',
                position: 'absolute',
                top: 0,
                left: 0,
                width: isOpenedCategorized ? '100%' : `${percents}%`,
            }}
            />
        </Box>
    );
};

ProgressBar.propTypes = {
    total: PropTypes.number.isRequired,
    nonCategorized: PropTypes.number.isRequired,
    isOpenedCategorized: PropTypes.bool,
};

ProgressBar.defaultProps = {
    isOpenedCategorized: false,
};

export default ProgressBar;
