// TODO: remove after upgrading @mui/system

import {getThemeProps, useTheme} from '@mui/system';
import useEnhancedEffect from '@mui/utils/useEnhancedEffect';
import * as React from 'react';

const useMediaQueryOld = (
    query,
    defaultMatches,
    matchMedia,
    ssrMatchMedia,
    noSsr,
) => {
    const [match, setMatch] = React.useState(() => {
        if (noSsr && matchMedia) {
            return matchMedia(query).matches;
        }
        if (ssrMatchMedia) {
            return ssrMatchMedia(query).matches;
        }

        // Once the component is mounted, we rely on the
        // event listeners to return the correct matches value.
        return defaultMatches;
    });

    useEnhancedEffect(() => {
        if (!matchMedia) {
            return undefined;
        }

        const queryList = matchMedia(query);
        const updateMatch = () => {
            setMatch(queryList.matches);
        };

        updateMatch();
        queryList.addEventListener('change', updateMatch);

        return () => {
            queryList.removeEventListener('change', updateMatch);
        };
    }, [query, matchMedia]);

    return match;
};

export default function useMediaQuery(queryInput, options = {}) {
    const theme = useTheme();
    // Wait for jsdom to support the match media feature.
    // All the browsers MUI support have this built-in.
    // This defensive check is here for simplicity.
    // Most of the time, the match media logic isn't central to people tests.
    const supportMatchMedia = typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';
    const {
        defaultMatches = false,
        matchMedia = supportMatchMedia ? window.matchMedia : null,
        ssrMatchMedia = null,
        noSsr = false,
    } = getThemeProps({name: 'MuiUseMediaQuery', props: options, theme});

    if (process.env.NODE_ENV !== 'production') {
        if (typeof queryInput === 'function' && theme === null) {
            // eslint-disable-next-line no-console
            console.error(
                [
                    'MUI: The `query` argument provided is invalid.',
                    'You are providing a function without a theme in the context.',
                    'One of the parent elements needs to use a ThemeProvider.',
                ].join('\n'),
            );
        }
    }

    let query = typeof queryInput === 'function' ? queryInput(theme) : queryInput;
    query = query.replace(/^@media( ?)/m, '');

    const match = useMediaQueryOld(
        query,
        defaultMatches,
        matchMedia,
        ssrMatchMedia,
        noSsr,
    );

    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useDebugValue({query, match});
    }

    return match;
}
