import makeStyles from '@mui/styles/makeStyles';

export const useDocumentsUploadFormStyles = makeStyles(theme => ({
    container: {
        width: '100%',
    },
    documentUploadStepContainer: {},
    documentUploadStepContent: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(7),
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
        },
    },
    radioLabel: {
        color: '#0B2333',
        fontWeight: 500,
        letterSpacing: '0.1px',
    },
    checkboxLabel: {
        color: '#0B2333',
        fontWeight: 500,
        letterSpacing: '0.1px',
    },
    formLabelContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    formLabelStep: {
        borderRadius: '100%',
        width: 40,
        height: 40,
    },
    formLabel: {
        marginBottom: theme.spacing(2),
        color: '#0B2333',
        fontWeight: 500,
        letterSpacing: '0.1px',
        lineHeight: '26px',
    },
    list: {
        'margin': 0,
        'paddingLeft': theme.spacing(2),
        '& > li': {
            marginBottom: theme.spacing(1),
        },
        '& > li:last-of-type': {
            marginBottom: 0,
        },
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    alertParagraph: {
        '&:first-of-type': {
            marginTop: 0,
        },
        '&:last-of-type': {
            marginBottom: 0,
        },
    },
    hostIdentificationDocumentLabel: {
        marginTop: theme.spacing(3),
        marginBottom: 0,
    },
    fieldset: {
        marginTop: theme.spacing(3),
    },
    hostCertificationContainer: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(4),
    },
}));
