import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useDrop} from 'react-dnd';
import {useTranslation} from 'react-i18next';
import dnd from '../../../../../../../../assets/png/dnd.png';

const CreditDropArea = ({type, onDrop, dep}) => {
    const {t} = useTranslation('bank');

    const [{canDrop, isOver}, drop] = useDrop(() => ({
        // The type (or types) to accept - strings or symbols
        accept: type,
        // Props to collect
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        drop: item => {
            onDrop(item);
        },
    }), [dep]);

    return (
        <div ref={drop}>
            <Box sx={{
                border: isOver ? '1px solid black' : '1px dashed black',
                borderColor: 'primary_v2.main',
                borderRadius: '16px',
                mt: 2,
                width: '100%',
                height: '271px',
                backgroundColor: canDrop ? 'v2.light.primary.shades8' : 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
            }}
            >
                {!canDrop && (
                    <>
                        <img src={dnd} alt="drag and drop" />
                        <Typography sx={{
                            fontWeight: 500,
                        }}
                        >
                            {t(`categorisation.creditDropArea.dragAndDropHere`)}
                        </Typography>
                        <Typography sx={{
                            textAlign: 'center',
                        }}
                        >
                            {t(`categorisation.creditDropArea.moveInvoiceToArea`)}
                        </Typography>
                    </>
                )}
            </Box>
        </div>

    );
};

CreditDropArea.propTypes = {
    type: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
    dep: PropTypes.any.isRequired,
};

export default CreditDropArea;
