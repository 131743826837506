/* eslint-disable no-restricted-globals */
export const transformCategoriesFromDTO = dto => {
    return {
        id: dto.ID,
        slug: dto.slug,
        description: dto.description,
        icon: dto.icon,
        count: dto.count,
        material_symbol: dto.material_symbol,
        metaTitle: dto.meta_title,
        name: dto.name,
        numberArticle: dto.number_article,
        numberFaq: dto.number_faq,
        numberVideo: dto.number_video,
        parent: dto.parent,
        order: dto.order,
    };
};

export const transformListFromDTO = dto => {
    return {
        id: dto.ID,
        slug: dto.slug,
        dateCreated: dto.date_created,
        dateModified: dto.date_modified,
        excerpt: dto.excerpt,
        title: dto.title,
        timeToRead: dto.time_to_read,
        hasVideo: dto.has_video,
        thumbnail: dto.thumbnail,
        videoLength: dto.video_length,
        docsType: dto.docs_type,
        docsParentCategory: dto.docs_parent_category,
        docsParentCategorySlug: dto.docs_parent_category_slug,
        docsSubCategory: dto.docs_sub_category,
        docsSubCategorySlug: dto.docs_sub_category_slug,
    };
};

export const transformDetailsFromDTO = dto => {
    return {
        id: dto.ID,
        slug: dto.slug,
        dateCreated: dto.date_created,
        dateModified: dto.date_modified,
        content: dto.content,
        title: dto.title,
        metaTitle: dto.meta_title,
        timeToRead: dto.time_to_read,
        docsType: dto.docs_type,
        docsParentCategory: dto.docs_parent_category,
        docsParentCategorySlug: dto.docs_parent_category_slug,
        docsSubCategory: dto.docs_sub_category,
        docsSubCategorySlug: dto.docs_sub_category_slug,
    };
};

export const transformSearchResultsFromDTO = dto => {
    return {
        id: dto.ID,
        title: dto.title,
        docsType: dto.docs_type,
        slug: dto.slug,
        docsParentCategory: dto.docs_parent_category,
        docsParentCategorySlug: dto.docs_parent_category_slug,
        docsSubCategory: dto.docs_sub_category,
        docsSubCategorySlug: dto.docs_sub_category_slug,
    };
};
