import {CompaniesActionTypes} from './companies.action-type';

export const CompaniesActions = {
    storeEntities: entities => ({
        type: CompaniesActionTypes.STORE_ENTITIES,
        payload: entities,
    }),
    registrationNotPrepared: isFromUser => ({
        type: CompaniesActionTypes.REGISTRATION_NOT_PREPARED,
        payload: isFromUser,
    }),
};
