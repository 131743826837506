import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import PcLeftCardHeader from './PcLeftCardHeader';

const PcLeftCard = ({handleOpenSubscribeModal}) => {
    const {t} = useTranslation('insurance');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{
            display: 'flex',
            height: '364px',
            padding: isMobileSize ? 3 : '32px 40px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            flex: '1 0 0',
            borderRadius: 4,
            gap: isMobileSize ? 2.5 : null,
            border: '1px solid rgba(0, 0, 143, 0.20)',
            boxShadow: '0px 2px 12px 0px rgba(20, 20, 43, 0.08)',
        }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 2.5,
                alignSelf: 'stretch',
            }}
            >
                <PcLeftCardHeader />
                <Typography sx={{fontSize: theme => theme.typography.pxToRem(isMobileSize ? 18 : 21)}}>
                    {t('tabs.pc.description')}
                </Typography>
            </Box>
            <Button
                variant="contained"
                size="large"
                color="secondary"
                onClick={handleOpenSubscribeModal}
                endIcon={<ArrowForwardIcon />}
                fullWidth
            >
                {t('tabs.pc.cta')}
            </Button>
        </Box>
    );
};

PcLeftCard.propTypes = {
    handleOpenSubscribeModal: PropTypes.func.isRequired,
};

export default PcLeftCard;
