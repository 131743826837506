import makeStyles from '@mui/styles/makeStyles';

export const useButtonWithCircularProgressStyles = makeStyles(() => ({
    submitButtonContainer: {
        position: 'relative',
    },
    submitButton: {
        position: 'relative',
    },
    submitButtonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    fullWidth: {
        width: '100%',
    },
    inline: {
        display: 'inline-flex',
    },
}));
