import {LoadingActionTypes} from './loading.action-type';

export const LoadingActions = {
    setLoading: (loadingType, loadingState) => ({
        type: LoadingActionTypes.SET_LOADING,
        payload: {
            loadingType,
            loadingState,
        },
    }),
};
