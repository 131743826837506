import {
    faWarning,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, FormHelperText, Stack} from '@mui/joy';
import PropTypes from 'prop-types';

export const FormErrorText = ({errorMessage}) => {
    if (!errorMessage) {
        return null;
    }

    return (
        <FormHelperText>
            <Stack alignItems="center" direction="row" spacing={0.5}>
                <Box fontSize="md" color="text.icon">
                    <FontAwesomeIcon icon={faWarning} />
                </Box>
                <Box>{errorMessage}</Box>
            </Stack>
        </FormHelperText>
    );
};

FormErrorText.propTypes = {
    errorMessage: PropTypes.string,
};

FormErrorText.defaultProps = {
    errorMessage: '',
};
