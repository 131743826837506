import {combineReducers} from 'redux';

import {FinancialStatementsActionType} from './financial-statements.action-type';

export const financialStatementsReducer = combineReducers({
    financialStatements: (state = {}, action) => {
        if (action.type === FinancialStatementsActionType.STORE_FINANCIAL_STATEMENTS) {
            return {
                ...state,
                ...{
                    ...action.payload,
                    items: action.payload.items.map(item => {
                        // Companies that do not have annual accounts (yet)
                        if (!item.id) {
                            return {...item, id: item.company.id};
                        }

                        return item;
                    }),
                },
            };
        }

        return state;
    },
});
