export const CardsActionType = {
    GET_CARDS: 'cars/GET_CARDS',
    STORE_LIST: 'cards/STORE_LIST',
    CREATE: 'cards/CREATE',
    UPDATE: 'cards/UPDATE',
    DELETE: 'cards/DELETE',
    GET_COUNTRIES: 'cards/GET_COUNTRIES',
    STORE_COUNTRIES: 'cards/STORE_COUNTRIES',
    ACTIVATE_PHYS_CARD: 'cards/ACTIVATE_PHYS_CARD',
    VIEW_PIN: 'cards/VIEW_PIN',
    LOCK_CARD: 'cards/LOCK_CARD',
    UNLOCK_CARD: 'cards/UNLOCK_CARD',
    SHOW_NUMBERS: 'cards/SHOW_NUMBERS',
};
