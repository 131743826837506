/*
 * This layout will probably be written from scratch,
 * but for now this is the simplest way to reuse old layout
 */

import PropTypes from 'prop-types';
import {useLayoutDimensions} from './utils';
import {AppLayout} from '../AppLayout/AppLayout';

export const AppLayoutV3 = ({suspenseFallback}) => {
    const {MAIN_PADDING_X, MAIN_PADDING_TOP} = useLayoutDimensions();
    return (
        <AppLayout
            mainSx={{
                backgroundColor: 'white',
                paddingTop: MAIN_PADDING_TOP,
                paddingLeft: MAIN_PADDING_X,
                paddingRight: MAIN_PADDING_X,
                /*
                 * The old layout is stuck on overflow: hidden, which breaks the sticky header.
                 * The next 3 rules are a workaround to prevent horizontal scroll but keep the header sticky.
                 */
                minWidth: 0,
                overflow: 'visible',
                overflowX: 'clip',
            }}
            suspenseFallback={suspenseFallback}
        />
    );
};

AppLayoutV3.propTypes = {
    suspenseFallback: PropTypes.element.isRequired,
};
