/* eslint-disable react/prop-types */
import * as React from 'react';
import {WrapperVariantContext} from './WrapperVariantContext';
import {PickersModalDialog} from '../PickersModalDialog';

export const MobileWrapper = props => {
    const {
        children,
        DateInputProps,
        DialogProps,
        onAccept,
        onClear,
        onDismiss,
        onCancel,
        onSetToday,
        open,
        PureDateInputComponent,
        components,
        componentsProps,
        ...other
    } = props;

    return (
        <WrapperVariantContext.Provider value="mobile">
            <PureDateInputComponent
                components={components}
                {...other}
                {...DateInputProps}
            />
            <PickersModalDialog
                DialogProps={DialogProps}
                onAccept={onAccept}
                onClear={onClear}
                onDismiss={onDismiss}
                onCancel={onCancel}
                onSetToday={onSetToday}
                open={open}
                components={components}
                componentsProps={componentsProps}
            >
                {children}
            </PickersModalDialog>
        </WrapperVariantContext.Provider>
    );
};
