export const AllProfessionAndSpecializationValuesFrom = function (data) {
    this.convertedData = data.map(item => {
        const {job_type: jobProfession, job_specializations: allSpecializations} = item;
        return {jobProfession, allSpecializations};
    });
};

export const FreelancerProfessionAndSpecializationValuesFrom = function (data) {
    this.convertedData = data.professions.map(item => {
        const {job_type: jobProfession, job_specializations: specialization} = item;
        return {jobProfession, specialization};
    });
};

export const FreelancerProfessionAndSpecializationValuesTo = function (data) {
    this.professions = data.map(returnedProfession => ({
        job_type: returnedProfession.jobProfession,
        job_specializations: returnedProfession.specialization,
    }));
};
