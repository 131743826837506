import makeStyles from '@mui/styles/makeStyles';

export const useWarningAlertStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        borderRadius: 8,
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            position: 'relative',
        },
    },
    title: {
        color: 'inherit',
        fontSize: theme.typography.pxToRem(16),
        marginBottom: 5,
        fontWeight: theme.typography.fontWeightMedium,
    },
    closeButton: {
        color: '#000',
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            top: 15,
            right: 15,
        },
    },
}));
