import {faArrowLeft, faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {ModalsKeys} from '../../../ui/utils/constants';
import {VatDeclarationSelectors} from '../../store/vat-declaration.selector';
import {getDeclarationPeriodString} from '../../utils';

export const ValidateManualConfirmationModal = ({onConfirm}) => {
    const {t} = useTranslation('vatDeclaration');
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);
    const declarationPeriod = useMemo(() => getDeclarationPeriodString(declaration), [declaration]);
    const isSubmitting = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_VALIDATE_MANUAL),
    );

    return (
        <ModalWrapper
            isV3
            modalKey={ModalsKeys.VAT_DECLARATION_VALIDATE_CONFIRMATION}
            title={<Typography level="h1">{t('manualModeSubmitConfirmationModal.title')}</Typography>}
            isForceOpen={isSubmitting}
            hasShowClose={!isSubmitting}
            buttons={({handleClose}) => (
                <Stack direction="row" spacing={2} width="100%">
                    <Button
                        variant="outlined"
                        color="neutral"
                        size="lg"
                        startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
                        onClick={() => handleClose()}
                        fullWidth
                        disabled={isSubmitting}
                    >
                        {t('manualModeSubmitConfirmationModal.cancel')}
                    </Button>
                    <Button
                        variant="solid"
                        color="success"
                        size="lg"
                        endDecorator={<FontAwesomeIcon icon={faCheck} />}
                        loading={isSubmitting}
                        loadingPosition="end"
                        onClick={onConfirm}
                        fullWidth
                    >
                        {t('manualModeSubmitConfirmationModal.confirm')}
                    </Button>
                </Stack>
            )}
        >
            <Typography level="body-lg" color="text.secondary">
                {t('manualModeSubmitConfirmationModal.description', {declarationPeriod})}
            </Typography>
        </ModalWrapper>
    );
};

ValidateManualConfirmationModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
};

