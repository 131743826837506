import {Alert} from '@mui/joy';
import Typography from '@mui/joy/Typography';
import React from 'react';
import JoyUIThemeProvider from '../joyui-theme-provider/JoyUiThemeProvider';

// TODO Remoe this once it is ready for live
const JoyUiTestComponent = () => {
    return (
        <JoyUIThemeProvider>
            <Alert
                color="warning"
                variant="solid"
            >
                <Typography>
                    This is a Joy UI Alert
                </Typography>
            </Alert>
        </JoyUIThemeProvider>
    );
};

export default JoyUiTestComponent;
