import {yupResolver} from '@hookform/resolvers/yup';
import {Grid, TextField, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import React, {useCallback, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ButtonWithCircularProgress} from '../../../components/buttons/ButtonWithCircularProgress';
import {Checkbox} from '../../../components/checkbox/Checkbox';
import Yup from '../../../lib/yup/yup';
import {USER_ROLES} from '../../../utils/user-roles';
import ReactRouterLink from '../../../v1/components/app/Router/ReactRouterLink';
import {getConfig} from '../../../v1/config';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {SettingsActions} from '../store/settings.actions';
import {SettingsSelectors} from '../store/settings.selectors';

const SettingsView = () => {
    const {t} = useTranslation('users');

    const navigate = useNavigate();

    const isUpdating = useSelector(SettingsSelectors.selectIsUpdatingSettings);
    const settings = useSelector(SettingsSelectors.selectSettings);
    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        language: Yup.string().oneOf(['en', 'fr']).required(t('requiredField')),
        shouldReceiveEmailNotifications: Yup.bool(),
    });

    const {control, handleSubmit, formState, reset, watch} = useForm({
        mode: 'onChange',
        defaultValues: {
            language: settings?.language || 'fr',
            shouldReceiveEmailNotifications: settings?.shouldReceiveEmailNotifications || false,
            shouldReceiveOpportunityNotifications: settings?.shouldReceiveOpportunityNotifications || false,
        },
        resolver: yupResolver(validationSchema),
    });
    const errors = formState.errors;

    useEffect(() => {
        if (!settings) {
            return;
        }

        reset({
            shouldReceiveEmailNotifications: settings?.shouldReceiveEmailNotifications || false,
            shouldReceiveOpportunityNotifications: settings?.shouldReceiveOpportunityNotifications || false,
            language: settings?.language || 'fr',
        });
    }, [reset, settings]);

    const onSubmit = useCallback(data => {
        dispatch(SettingsActions.updateUserSettings(data));
    }, [dispatch]);

    const shouldReceiveEmailNotifications = watch('shouldReceiveEmailNotifications');

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container sx={{px: 3, py: 2, borderBottom: 1, borderColor: 'other_v2.divider'}}>
                <Grid item xs={6} sx={{display: {xs: 'none', md: 'flex'}, flexDirection: 'column', justifyContent: 'center'}}>
                    <Typography variant="subtitle2_v2" color="text_v2.secondary">
                        {t('language')}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        render={({field}) => {
                            return (
                                <TextField
                                    label={t('language')}
                                    fullWidth
                                    error={!!errors.language}
                                    helperText={errors.language?.message}
                                    select
                                    {...field}
                                >
                                    <MenuItem value="en">
                                        English
                                    </MenuItem>
                                    <MenuItem value="fr">
                                        Français
                                    </MenuItem>
                                </TextField>
                            );
                        }}
                        id="language"
                        name="language"
                        variant="outlined"
                        control={control}
                        disabled={isUpdating}
                        select
                        required
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container sx={{px: 3, py: 2, borderBottom: 1, borderColor: 'other_v2.divider'}}>
                <Grid item xs={6} sx={{pr: 3, display: {xs: 'none', md: 'flex'}, flexDirection: 'column', justifyContent: 'center'}}>
                    <Typography variant="subtitle2_v2" color="text_v2.secondary">
                        {t('receiveEmailNotifications')}
                    </Typography>
                </Grid>
                <Grid item xs="auto" md={6} sx={{mx: {xs: 1, md: 0}, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <Controller
                        control={control}
                        name="shouldReceiveEmailNotifications"
                        render={({field}) => (
                            <FormControlLabel
                                sx={{m: 0}}
                                label=""
                                control={<Checkbox sx={{p: 0}} checked={field.value} color="primary" />}
                                onChange={event => field.onChange(event?.target?.checked)}
                                disabled={isUpdating}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs="auto" sx={{maxWidth: 'calc(100% - 40px)', display: {xs: 'flex', md: 'none'}, flexDirection: 'column', justifyContent: 'center'}}>
                    <Typography variant="body2" color="text_v2.secondary">
                        {t('receiveEmailNotifications')}
                    </Typography>
                </Grid>
            </Grid>
            {user.role === USER_ROLES.FREELANCER && (
                <Grid container rowSpacing={1} sx={{px: 3, py: 2, borderBottom: 1, borderColor: 'other_v2.divider'}}>
                    <Grid item xs={6} sx={{pr: 3, display: {xs: 'none', md: 'flex'}, flexDirection: 'column', justifyContent: 'center'}}>
                        <Typography
                            variant="subtitle2_v2"
                            color={shouldReceiveEmailNotifications ? 'text_v2.secondary' : 'v2.light.text.disabled'}
                        >
                            {t('receiveOpportunityNotifications')}
                        </Typography>
                    </Grid>
                    <Grid item xs="auto" md={6} sx={{mx: {xs: 1, md: 0}, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <Controller
                            control={control}
                            name="shouldReceiveOpportunityNotifications"
                            render={({field}) => (
                                <>
                                    <FormControlLabel
                                        sx={{m: 0}}
                                        label=""
                                        control={(
                                            <Checkbox
                                                sx={{p: 0}}
                                                checked={field.value}
                                                color="primary"
                                            />
                                          )}
                                        onChange={event => field.onChange(event?.target?.checked)}
                                        disabled={isUpdating || !shouldReceiveEmailNotifications}
                                    />
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs="auto" sx={{maxWidth: 'calc(100% - 40px)', display: {xs: 'flex', md: 'none'}, flexDirection: 'column', justifyContent: 'center'}}>
                        <Typography variant="body2" color="text_v2.secondary">
                            {t('receiveOpportunityNotifications')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pr: {xs: 0, md: 3}}}>
                        <Typography
                            variant="body2"
                            color={shouldReceiveEmailNotifications ? 'text_v2.secondary' : 'v2.light.text.disabled'}
                        >
                            <Trans
                                t={t}
                                i18nKey="receiveOpportunityNotificationsAdditionalText"
                                components={[
                                    <ReactRouterLink
                                        key={0}
                                        color="primary_v2.main"
                                        to={getConfig().ROUTE_PATHS.MY_PROFILE}
                                    />,
                                ]}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid container columnSpacing={2} sx={{p: 3, display: 'flex', justifyContent: 'flex-end'}}>
                <Grid item xs={6} md="auto">
                    <Button
                        fullWidth
                        variant="contained"
                        color="blueGray"
                        size="large"
                        type="button"
                        onClick={() => {
                            navigate(-1);
                        }}
                        sx={{
                            'boxShadow': 0,
                            '&:hover': {'boxShadow': 0},
                        }}
                    >
                        {t('common:cancel')}
                    </Button>
                </Grid>
                <Grid item xs={6} md="auto" sx={{minWidth: {md: '123px'}}}>
                    <ButtonWithCircularProgress
                        isFullWidth
                        isLoading={isUpdating}
                        buttonProps={{
                            type: 'submit',
                            size: 'large',
                            color: 'secondary',
                            variant: 'contained',
                            disabled: !formState.isValid || isUpdating,
                        }}
                    >
                        {t('common:apply')}
                    </ButtonWithCircularProgress>
                </Grid>
            </Grid>
        </form>
    );
};

export default SettingsView;
