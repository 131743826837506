import {createSelector} from 'reselect';
import {BANK_ONBOARDING_STATUS, VerificationStatus} from '../utils/constants';

const selectIntegrations = state => state.bank.integrations;
const selectIntegrationsWithArchived = state => state.bank.integrationsWithArchived;
const selectIsBankMenuFullyActive = state => state.bank.isBankMenuFullyActive;
const selectQrCodeEvent = state => state.bank.qrCodeEvent;
const selectCapitalDepositError = state => state.bank.capitalDepositError;
const selectCapitalDeposit = state => state.bank.capitalDeposit;

const createSelectBankIntegrations = type => createSelector(
    [selectIntegrations],
    integrations => {
        // TODO Bridge
        return integrations.find(integration => integration.type === type) ?? null;
    },
);

const createSelectBankAccountHolders = () => createSelector(
    [selectIntegrations],
    bankIntegrationDataList => {
        if (!bankIntegrationDataList.length || bankIntegrationDataList.length === 0) {
            return null;
        }
        const bankIntegrationData = bankIntegrationDataList[0];

        if (!bankIntegrationData?.bankAccountHolders || bankIntegrationData?.bankAccountHolders.length === 0) {
            return;
        }

        // Get correct bankAccountHolders data
        return bankIntegrationData.bankAccountHolders.length === 1
            ? bankIntegrationData.bankAccountHolders[0]
            : bankIntegrationData.bankAccountHolders.find(
                integration => integration?.type === 'COMPANY',
            );
    },
);

const createSelectCanAccessBankDashboard = () => createSelector(
    [selectIntegrations],
    bankIntegrationDataList => {
        if (!bankIntegrationDataList
            || !bankIntegrationDataList.length
            || bankIntegrationDataList.length === 0) {
            return false;
        }
        let returnValue = false;
        bankIntegrationDataList.forEach(bankIntegrationData => {
            if (!bankIntegrationData?.bankAccountHolders || bankIntegrationData?.bankAccountHolders.length === 0) {
                return false;
            }

            if (!bankIntegrationData?.isAuthenticated) {
                return false;
            }

            // Get correct bankAccountHolders data
            const bankAccountHolders = bankIntegrationData.bankAccountHolders.length === 1
                ? bankIntegrationData.bankAccountHolders[0]
                : bankIntegrationData.bankAccountHolders.find(
                    integration => integration?.type === 'COMPANY',
                );

            if (bankAccountHolders?.bankAccounts?.[0]
                && bankAccountHolders.onboardingStatus === BANK_ONBOARDING_STATUS.FINALIZED
                && bankAccountHolders.verificationStatus === VerificationStatus.VERIFIED) {
                returnValue = true;
            }
        });

        return returnValue;
    },
);

const createSelectDefaultIntegration = () => createSelector(
    [selectIntegrations],
    integrations => {
        return integrations.find(integration => {
            const bankAccountHolder = integration?.bankAccountHolders.find(holder => holder.type === 'COMPANY');
            const bankAccount = bankAccountHolder?.bankAccounts?.[0];
            return bankAccount?.isDefault;
        });
    },
);

export const BankSelector = {
    selectIntegrations,
    selectIntegrationsWithArchived,
    createSelectBankIntegrations,
    selectIsBankMenuFullyActive,
    selectQrCodeEvent,
    createSelectBankAccountHolders,
    createSelectCanAccessBankDashboard,
    selectCapitalDepositError,
    selectCapitalDeposit,
    createSelectDefaultIntegration,
};
