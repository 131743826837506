export const INVOICE_STATUS = {
    'DRAFT': 'DRAFT',
    'ERROR': 'ERROR',
    'PENDING': 'PENDING', // À payer
    'OVERDUE': 'OVERDUE', // En retard
    'SENT': 'SENT', // Envoyé
    'NOT_SENT': 'NOT_SENT', // Non Envoyé
    'CANCELLED': 'CANCELLED', // Annulée
    'PARTIALLY_PAID': 'PARTIALLY_PAID', // Paiement partiel
    'PAID': 'PAID', // Payé
};

export const INVOICE_TYPE = {
    SERVICE: 'SERVICE',
    EXPENSE: 'EXPENSE',
};

export const INVOICE_TYPES = {
    INVOICE: 'INVOICE',
    CREDIT_NOTE: 'CREDIT_NOTE',
    CANCELLED_INVOICE: 'CANCELLED_INVOICE', // Not used in BE, look to status only
};

export const INVOICE_TABLE_DEFAULT = {
    limit: 10,
    offset: 0,
    sortOrder: 'desc',
    sortColumn: undefined,
    searchQuery: undefined,
};

export const ClientPaymentTerm = {
    'SpecificDatePayment': 'SPECIFIC_DATE_PAYMENT',
    'ImmediatePayment': 'IMMEDIATE_PAYMENT',
    'Days30Net': 'DAYS_30_NET',
    'Days30EndOfMonth': 'DAYS_30_END_OF_MONTH',
    'Days45Net': 'DAYS_45_NET',
    'Days45EndOfMonth': 'DAYS_45_END_OF_MONTH',
    'Days60Net': 'DAYS_60_NET',
    'Days60EndOfMonth': 'DAYS_60_END_OF_MONTH',
};

export const ClientPaymentTermNoEndOfMonth = {
    'SpecificDatePayment': 'SPECIFIC_DATE_PAYMENT',
    'ImmediatePayment': 'IMMEDIATE_PAYMENT',
    'Days30Net': 'DAYS_30_NET',
    'Days45Net': 'DAYS_45_NET',
    'Days60Net': 'DAYS_60_NET',
};

export const ClientPaymentTermEndOfMonth = {
    'SpecificDatePayment': 'SPECIFIC_DATE_PAYMENT',
    'ImmediatePayment': 'IMMEDIATE_PAYMENT',
    'Days30EndOfMonth': 'DAYS_30_END_OF_MONTH',
    'Days45EndOfMonth': 'DAYS_45_END_OF_MONTH',
    'Days60EndOfMonth': 'DAYS_60_END_OF_MONTH',
};

export const ServiceType = {
    SERVICE: 'SERVICE',
    EXPENSE: 'EXPENSE',
};

export const ServiceRateUnit = {
    HOUR: 'HOUR',
    DAY: 'DAY',
    UNIT: 'UNIT',
};

export const invoiceDashboardSidebarType = {
    invoiceDetails: 'invoiceDetails',
    externalInvoice: 'externalInvoice',
};

export const invoiceSidebarType = {
    clients: 'clients',
    services: 'services',
    clientsList: 'clientsList',
    servicesList: 'servicesList',
    invoiceDates: 'invoiceDates',
    serviceDates: 'serviceDates',
};

export const serviceDiscountType = {
    PERCENT: 'PERCENT',
    AMOUNT: 'AMOUNT',
};

export const invoiceResultTypes = {
    CANCEL: 'CANCEL',
    REMIND: 'REMIND',
    SEND: 'SEND',
    RESEND: 'RESEND',
    CANCEL_EXTERNAL: 'CANCEL_EXTERNAL',
};

export const MAXIMUM_NUMBER_OF_SERVICES_PER_INVOICE = 10;

export const docNumberRegexp = /^(?!F\d+$)(?!A\d+$)[A-Z0-9](-?[A-Z0-9]+)+$/i;

export const SERVICE_PERIOD_DATE_FORMAT = 'MM/YYYY';
export const SERVICE_SELECTED_DATE_FORMAT = 'DD/MM/YYYY';

export const OPCO_CLIENT_NAME = 'OPCO ATLAS';

export const INVOICE_IMPORT_RESULT = {
    COPY_SUCCESS: 'COPY_SUCCESS',
    MISSING_DRAFT_ITEM: 'MISSING_DRAFT_ITEM',
    MISSING_IMPORT_ITEM: 'MISSING_IMPORT_ITEM',
};

export const VALUE_ADDED_TAX_OPTIONS = [
    'VAT_0',
    'VAT_8_5',
    'VAT_20',
];

export const PAYMENT_TERMS = {
    IMMEDIATE_PAYMENT: 'IMMEDIATE_PAYMENT',
    SPECIFIC_DATE_PAYMENT: 'SPECIFIC_DATE_PAYMENT',
    DAYS_30_NET: 'DAYS_30_NET',
    DAYS_30_END_OF_MONTH: 'DAYS_30_END_OF_MONTH',
    DAYS_45_NET: 'DAYS_45_NET',
    DAYS_45_END_OF_MONTH: 'DAYS_45_END_OF_MONTH',
    DAYS_60_NET: 'DAYS_60_NET',
    DAYS_60_END_OF_MONTH: 'DAYS_60_END_OF_MONTH',
};

export const VALUE_ADDED_TAX_OPTIONS_MULTIPLIER = {
    'VAT_0': 1,
    'VAT_8_5': 1.085,
    'VAT_20': 1.2,
};

export const DAY_SELECTION_TYPE = {
    FULL: 'FULL',
    HALF: 'HALF',
};
