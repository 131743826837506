import {BankActionTypes} from './bank.action-type';

export const BankActions = {
    createBankAccount: () => ({
        type: BankActionTypes.CREATE_BANK_ACCOUNT,
    }),
    createBankAccountOnboarding: () => ({
        type: BankActionTypes.CREATE_BANK_ACCOUNT_ONBOARDING,
    }),
    finalizeBankAccount: () => ({
        type: BankActionTypes.FINALIZE_BANK_ACCOUNT,
    }),
    getUserIntegrationData: data => ({
        type: BankActionTypes.GET_USER_INTEGRATION_DATA,
        payload: data,
    }),
    storeIntegrationData: data => ({
        type: BankActionTypes.STORE_INTEGRATION_DATA,
        payload: data,
    }),
    storeIntegrationDataWithArchived: data => ({
        type: BankActionTypes.STORE_INTEGRATION_DATA_WITH_ARCHIVED,
        payload: data,
    }),
    isBankMenuFullyActive: data => ({
        type: BankActionTypes.STORE_BANK_MENU_IS_FULLY_ACTIVE,
        payload: data,
    }),
    fillMissingData: () => ({
        type: BankActionTypes.FILL_MISSING_DATA,
    }),
    downloadRib: params => ({
        type: BankActionTypes.DOWNLOAD_RIB,
        payload: params,
    }),
    addUploadFileQrCodeEvent: eventId => ({
        type: BankActionTypes.UPLOAD_FILE_QR_CODE_EVENT,
        payload: eventId,
    }),
    startKyc: () => ({
        type: BankActionTypes.START_KYC,
    }),
    startCapitalDeposit: () => ({
        type: BankActionTypes.START_CAPITAL_DEPOSIT,
    }),
    setCapitalDepositError: error => ({
        type: BankActionTypes.SET_CAPITAL_DEPOSIT_ERROR,
        payload: error,
    }),
    getCapitalDeposit: () => ({
        type: BankActionTypes.GET_CAPITAL_DEPOSIT,
    }),
    storeCapitalDeposit: params => ({
        type: BankActionTypes.STORE_CAPITAL_DEPOSIT,
        payload: params,
    }),
    transitionCapitalDepositStatus: params => ({
        type: BankActionTypes.TRANSITION_CAPITAL_DEPOSIT_STATUS,
        payload: params,
    }),
    closeBankAccount: params => ({
        type: BankActionTypes.CLOSE_BANK_ACCOUNT,
        payload: params,
    }),
    deleteIntegrationAccount: params => ({
        type: BankActionTypes.DELETE_ACCOUNT,
        payload: params,
    }),
    archiveIntegrationAccount: params => ({
        type: BankActionTypes.ARCHIVE_ACCOUNT,
        payload: params,
    }),
    updateRefusedDocuments: data => ({
        type: BankActionTypes.UPDATE_REFUSED_DOCUMENTS,
        payload: data,
    }),
};
