import {Box} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch} from 'react-redux';

export const ItemWithDispatchAction = ({action, sx, children}) => {
    const dispatch = useDispatch();

    const onClickHandle = () => dispatch(action());

    return (
        <Box
            component="div"
            sx={{
                cursor: 'pointer',
                ...sx,
            }}
            onClick={onClickHandle}
        >
            {children}
        </Box>
    );
};

ItemWithDispatchAction.propTypes = {
    action: PropTypes.func.isRequired,
    sx: PropTypes.object,
};

ItemWithDispatchAction.defaultProps = {
    sx: {},
};
