import {all, call, delay, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {v4 as uuidv4} from 'uuid';
import {TransactionsActions} from './transactions.action';
import {TransactionsActionTypes} from './transactions.action-type';
import {TransactionsSelector} from './transactions.selector';
import {Toast} from '../../../../../lib/toast';
import {arrayDiff} from '../../../../../utils/array';
import {Debug} from '../../../../../utils/debug';
import {USER_ROLES} from '../../../../../utils/user-roles';
import {selectUser} from '../../../../../v1/app/user/user.selectors';
import {LoadingActions, LoadingSelectors, LoadingTypes} from '../../../../loading';
import {SseActions} from '../../../../server-side-events/store/sse.actions';
import {SSESelector} from '../../../../server-side-events/store/sse.selector';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {BankApi} from '../../../api/bank.api';
// eslint-disable-next-line import/no-cycle
import {
    bankIntegrationMainCheckFlow,
    getHiwayAccountId,
    setTransactionAlertFlow,
} from '../../../store/bank.loader.saga';
import {BankSelector} from '../../../store/bank.selector';
import {getAnyIntegrationWithAccountId, getIntegrationWithAccountId} from '../../bridge-api/utils/bridge-util';
import {BankAccountStatus} from '../../bridge-api/utils/constants';
import {
    BANK_ACCOUNT_MISSING,
    CATEGORY_MAGIC_SUBSCRIPTION_TYPE,
    LIMIT_COUNT,
    PAYMENT_TYPES_FOR_FILTER,
    TVA_ENUM,
    TransactionSide, TransactionStatus,
} from '../util/constants';

const setNonCategorisedFromExistingList = function* ({ids, transactionsObject}) {
    const oldUncategorizedIds = yield select(TransactionsSelector.selectCategorizationList);

    const newUncategorizedIds = ids.filter(id => {
        const transaction = transactionsObject?.[id];

        if (!transaction) {
            return false;
        }

        return transaction.isCategorized === false
            && (transaction.status !== TransactionStatus.REJECTED && transaction.status !== TransactionStatus.CANCELED);
    });

    if (oldUncategorizedIds && newUncategorizedIds) {
        const uncategorizedIds = [...new Set([...oldUncategorizedIds, ...newUncategorizedIds])];

        yield put(TransactionsActions.setCategorizationList(uncategorizedIds));
    } else if (newUncategorizedIds) {
        yield put(TransactionsActions.setCategorizationList(newUncategorizedIds));
    }
};

const updateNonCategorizedFlow = function* ({params, transaction, response, transactionsData}) {
    // Since we can be on different tab do not update transaction on on that tab
    if (!transaction?.id) {
        return;
    }

    // Do not count upcoming transactions
    if (transaction?.status === TransactionStatus.UPCOMING) {
        return;
    }

    // Update number of non-categorized
    const overview = yield select(TransactionsSelector.selectTransactionTotalOverview);
    let totalUncategorized = overview?.totalUncategorizedTransactions ?? 0;

    const account = overview?.overviewPerAccount.find(account => account.id === params?.accountId);

    if (account?.status === BankAccountStatus.ARCHIVED) {
        return;
    }

    let accountUncategorized = account?.numberOfUncategorizedTransactions ?? 0;

    if (transaction.hasChip !== response.hasChip || transaction?.isFileUpload) {
        let nonCategorizedCount = transactionsData.nonCategorizedCount;
        if (response.hasChip) {
            nonCategorizedCount += 1;
            totalUncategorized += 1;
            accountUncategorized += 1;
        } else {
            nonCategorizedCount -= 1;
            totalUncategorized -= 1;
            accountUncategorized -= 1;
            Toast.success('transactionCategorized');
        }

        yield put(TransactionsActions.storeNonCategorizedCount(nonCategorizedCount));
        if (account) {
            const accountIndex = overview?.overviewPerAccount.findIndex(
                account => account.id === params?.accountId,
            );

            const overviewPerAccountSpread = [...overview?.overviewPerAccount];

            overviewPerAccountSpread.splice(accountIndex, 1, {
                ...overview?.overviewPerAccount[accountIndex],
                numberOfUncategorizedTransactions: accountUncategorized,
            });

            yield put(TransactionsActions.storeTotalOverview({
                ...overview,
                totalUncategorizedTransactions: totalUncategorized,
                overviewPerAccount: overviewPerAccountSpread,
            }));
        } else {
            yield put(TransactionsActions.storeTotalOverview({
                ...overview,
                totalUncategorizedTransactions: totalUncategorized,
            }));
        }
    }
};

const handleAffiliatedTransaction = function* ({params, affiliatedId}) {
    const transactions = yield select(TransactionsSelector.selectTransactions);

    // Call action for swap
    yield put(TransactionsActions.replaceTransaction({
        from: params.id,
        to: affiliatedId,
    }));

    // Put new ID into list at the old one index
    const transactionIds = yield select(TransactionsSelector.selectTransactionIds);
    const transactionIdsIndex = transactionIds.findIndex(value => value === params.id);
    const newTransactionIds = [...transactionIds];
    newTransactionIds.splice(transactionIdsIndex, 1, affiliatedId);

    yield put(TransactionsActions.storeTransactionList({
        ...transactions,
        ids: newTransactionIds,
    }));
};


export const bankGetCategoryFlow = function* () {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CATEGORIES, true));

        const integrationData = yield select(BankSelector.selectIntegrations);
        let accountId = yield call(getIntegrationWithAccountId, integrationData);

        if (!accountId) {
            const integrationsDataWithArchived = yield select(BankSelector.selectIntegrationsWithArchived);
            accountId = yield call(getAnyIntegrationWithAccountId, integrationsDataWithArchived);

            if (!accountId) {
                yield put(LoadingActions.setLoading(LoadingTypes.BANK_CATEGORIES, false));
                return;
            }
        }

        const categories = yield call(BankApi.getBankCategories, {accountId: accountId});

        yield put(TransactionsActions.storeCategoryList(categories.categoriesList));
        yield put(TransactionsActions.storeCategoryObject(categories.categoriesObject));

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CATEGORIES, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});

        if (e?.response?.status === 409 || e?.response?.status === 407) {
            // Call main login flow
            yield call(bankIntegrationMainCheckFlow);
        } else {
            Toast.error('genericError');
        }

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CATEGORIES, false));
    }
};

export const bankGetSourcesFlow = function* () {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_SOURCES, true));

        const integrationData = yield select(BankSelector.selectIntegrations);
        let accountId = yield call(getIntegrationWithAccountId, integrationData);

        if (!accountId) {
            const integrationsDataWithArchived = yield select(BankSelector.selectIntegrationsWithArchived);
            accountId = yield call(getAnyIntegrationWithAccountId, integrationsDataWithArchived);

            if (!accountId) {
                yield put(LoadingActions.setLoading(LoadingTypes.BANK_SOURCES, false));
                return;
            }
        }

        const sources = yield call(
            BankApi.getBankSources,
            {
                params: {side: 'credit'},
                accountId: accountId,
            },
        );

        yield put(TransactionsActions.storeSourcesList(sources.sourcesList));
        yield put(TransactionsActions.storeSourcesObject(sources.sourcesObject));

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_SOURCES, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});

        if (e?.response?.status === 409 || e?.response?.status === 407) {
            // Call main login flow
            yield call(bankIntegrationMainCheckFlow);
        } else {
            Toast.error('genericError');
        }

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_SOURCES, false));
    }
};

const getTransactionListFlow = function* (params, accountId) {
    if (accountId === BANK_ACCOUNT_MISSING) {
        yield put(TransactionsActions.storeTransactionList({
            count: 0,
            maxItems: 0,
            nonCategorizedCount: 0,
            ids: [],
            transactionsObject: {},
        }));

        return;
    }

    if (!accountId) {
        yield put(TransactionsActions.storeTransactionList(null));
        return;
    }

    const hasFilterLoader = params?.searchQuery || params?.filterQuery;
    const loaderType = hasFilterLoader
        ? params.offset > 0
            ? LoadingTypes.BANK_TRANSACTIONS_PAGE_LOADER
            : LoadingTypes.BANK_TRANSACTIONS_FILTERS
        : LoadingTypes.BANK_TRANSACTIONS;

    try {
        yield put(LoadingActions.setLoading(loaderType, true));
        const bankTransactions = yield call(BankApi.getBankTransactions, params, accountId);

        const existingTransactions = yield select(TransactionsSelector.selectTransactions);

        let transactionsObject;
        let ids;
        if (params.offset !== 0) {
            transactionsObject = {
                ...existingTransactions.transactionsObject,
                ...bankTransactions.transactionsObject,
            };
            ids = [...existingTransactions.ids, ...bankTransactions.ids];
            yield put(TransactionsActions.storeTransactionList({
                count: bankTransactions.count,
                maxItems: bankTransactions.maxItems,
                nonCategorizedCount: bankTransactions.nonCategorizedCount,
                ids: ids,
                transactionsObject: transactionsObject,
                accountId,
                hasTransactions: existingTransactions?.hasTransactions ?? parseInt(bankTransactions.maxItems, 10) !== 0,
            }));

            yield call(setNonCategorisedFromExistingList, {ids, transactionsObject});
        } else {
            if (existingTransactions?.transactionsObject) {
                transactionsObject = {
                    ...existingTransactions.transactionsObject,
                    ...bankTransactions.transactionsObject,
                };
            } else {
                transactionsObject = bankTransactions.transactionsObject;
            }

            ids = bankTransactions.ids;

            // Add to transaction list
            yield put(TransactionsActions.storeTransactionList({
                count: bankTransactions.count,
                maxItems: bankTransactions.maxItems,
                nonCategorizedCount: bankTransactions.nonCategorizedCount,
                ids: ids,
                transactionsObject: transactionsObject,
                accountId,
                hasTransactions: existingTransactions?.accountId === accountId
                    ? (
                        existingTransactions?.hasTransactions ?? parseInt(bankTransactions.maxItems, 10) !== 0
                    )
                    : parseInt(bankTransactions.maxItems, 10) !== 0,
            }));
        }

        // Add to list only uncategorized
        yield call(setNonCategorisedFromExistingList, {ids, transactionsObject});

        yield put(LoadingActions.setLoading(loaderType, false));
    } catch (e) {
        Debug.error('bank getTransactionListFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(loaderType, false));
    }
};

const getCombinedTransactionListFlow = function* (params, sentFreelancerId) {
    const hasFilterLoader = params?.searchQuery || params?.filterQuery;
    const loaderType = hasFilterLoader
        ? params.offset > 0
            ? LoadingTypes.BANK_TRANSACTIONS_PAGE_LOADER
            : LoadingTypes.BANK_TRANSACTIONS_FILTERS
        : LoadingTypes.BANK_TRANSACTIONS;

    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    const freelancerId = sentFreelancerId ?? loggedInUser?.id;

    try {
        yield put(LoadingActions.setLoading(loaderType, true));
        const bankTransactions = yield call(BankApi.getCombinedBankTransactions, params, sentFreelancerId);

        const existingTransactions = yield select(TransactionsSelector.selectTransactions);

        let transactionsObject;
        let ids;
        if (params.offset !== 0) {
            transactionsObject = {
                ...existingTransactions.transactionsObject,
                ...bankTransactions.transactionsObject,
            };
            ids = [...existingTransactions.ids, ...bankTransactions.ids];
            yield put(TransactionsActions.storeTransactionList({
                count: bankTransactions.count,
                maxItems: bankTransactions.maxItems,
                nonCategorizedCount: bankTransactions.nonCategorizedCount,
                ids: ids,
                transactionsObject: transactionsObject,
                hasTransactions: existingTransactions?.hasTransactions,
                accountId: existingTransactions?.accountId,
            }));
            // Needed for categorisation feature
            yield put(TransactionsActions.storeCombinedTransactionList(transactionsObject));
        } else {
            let transactionsObject;
            if (existingTransactions?.transactionsObject) {
                transactionsObject = {
                    ...existingTransactions.transactionsObject,
                    ...bankTransactions.transactionsObject,
                };
            } else {
                transactionsObject = bankTransactions.transactionsObject;
            }
            ids = bankTransactions.ids;

            yield put(TransactionsActions.storeTransactionList({
                count: bankTransactions.count,
                maxItems: bankTransactions.maxItems,
                nonCategorizedCount: bankTransactions.nonCategorizedCount,
                ids: ids,
                transactionsObject: transactionsObject,
                hasTransactions: existingTransactions?.hasTransactions,
                accountId: existingTransactions?.accountId,
            }));
            // Needed for categorisation feature
            yield put(TransactionsActions.storeCombinedTransactionList(transactionsObject));

            // We don't need to call API for overview on changing filters
            if (!params?.searchQuery
                && !params?.filterQuery
            ) {
                const totalOverview = yield call(BankApi.getTransactionOverview, freelancerId);

                if (totalOverview) {
                    yield put(TransactionsActions.storeTotalOverview(totalOverview));
                }
            }

            // Add to list only uncategorized
            yield call(setNonCategorisedFromExistingList, {ids, transactionsObject});
        }

        yield put(LoadingActions.setLoading(loaderType, false));
    } catch (e) {
        Debug.error('bank getCombinedTransactionListFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(loaderType, false));
    }
};

// TODO Perhaps instead of this I can
// take selectMatchingSubcription and selectEligibleMatchingSubcription
// and check if they are categorised
const handleAfterSubscriptionChangeFlow = function* () {
    // const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    // const freelancerId = sentFreelancerId ?? loggedInUser?.id;
    //
    // try {
    //     yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION, true));
    //
    //     const totalOverview = yield call(BankApi.getTransactionOverview, freelancerId);
    //
    //     if (totalOverview) {
    //         yield put(TransactionsActions.storeTotalOverview(totalOverview));
    //
    //         yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION, false));
    //     }
    //
    //     const bankTransactions = yield call(BankApi.getCombinedBankTransactions, {}, sentFreelancerId);
    //     const uncategorizedIds = [...new Set([...bankTransactions.ids])];
    //
    //     yield put(TransactionsActions.setCategorizationList(uncategorizedIds));
    // } catch (e) {
    //     Debug.error('bank getCombinedTransactionListFlow', 'Error: ', {e});
    //     Toast.error('genericError');
    //
    //     yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION, false));
    // }
};

export const getBankTransactionFlow = function* (params) {
    if (!params?.doNotShowLoader) {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION, true));
    }

    const transactionsData = yield select(TransactionsSelector.selectTransactions);
    const transaction = transactionsData?.transactionsObject?.[params.id];

    try {
        let accountId;
        if (params?.accountId) {
            accountId = params?.accountId;
        } else {
            accountId = yield call(getHiwayAccountId);
        }

        const response = yield call(BankApi.getTransaction, {
            accountId,
            id: params.id,
        });

        yield put(TransactionsActions.storeTransactionInObject(response));

        yield call(updateNonCategorizedFlow, {params, transaction, response, transactionsData});

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION, false));

        return response;
    } catch (e) {
        // TODO: Use constants once it is added on BE
        if (e?.response?.status === 410) {
            if (e?.response?.data?.message?.info === 'Transaction has been released') {
                const affiliatedId = e?.response?.data?.message?.affiliatedTransactionId;
                yield call(handleAffiliatedTransaction, {params, affiliatedId});

                return;
            }
        }

        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION, false));
    }
};

const saveBankArticlesFlow = function* (params) {
    try {
        const hiwayAccountId = yield call(getHiwayAccountId);
        const accountId = params?.accountId ?? hiwayAccountId;

        const transactionsData = yield select(TransactionsSelector.selectTransactions);
        const transaction = transactionsData.transactionsObject[params.id];

        const putArticles = params.articles.map(article => {
            return {
                ...article,
                amount: article.amount
                    ? parseFloat(article.amount).toFixed(2)
                    : undefined,
                id: undefined,
                tvaAmount: article.tva === TVA_ENUM.TVA_CUSTOM && article.tvaAmount
                    ? parseFloat(article.tvaAmount).toFixed(2) : undefined,
                parentTva: undefined,
                parentMccKey: undefined,
            };
        });

        const response = yield call(BankApi.saveTransaction, {
            accountId,
            id: params.id,
            articles: putArticles,
            ignoreProofDocument: params.ignoreProofDocument,
            type: params?.type,
        });

        const responseArticles = response.articles;

        const updateArticles = params.articles.map((article, index) => {
            article.tvaAmount = responseArticles[index].tvaAmount;
            article.amount = responseArticles[index].amount;
            article.editableVat = responseArticles[index].editableVat;
            article.id = article?.id ?? uuidv4();

            return article;
        });

        // Update article tvaAmount (needed for details)
        yield put(TransactionsActions.storeTransactionInObject(
            {
                ...response,
                hasChip: response.hasChip,
                category: response.category,
                vat: response.vat,
                articles: updateArticles,
            },
        ));

        yield call(updateNonCategorizedFlow, {params, transaction, response, transactionsData});

        if (params?.type) {
            yield put(UiActions.setActiveModal(ModalsKeys.CATEGORIZATION_DEACTIVATE_SUBSCRIPTION, false));

            if (params?.type === CATEGORY_MAGIC_SUBSCRIPTION_TYPE.ALL) {
                yield put(TransactionsActions.refreshTransactionList(true));

                const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
                const user = yield select(selectUser);

                const isAdminInterface = loggedInUser?.role === USER_ROLES.ADMINISTRATOR;

                yield put(TransactionsActions.getTotalOverview(isAdminInterface ? user?.id : undefined));
            }

            // TODO Add for admin
            yield call(handleAfterSubscriptionChangeFlow);
        }
    } catch (e) {
        // TODO: Use constants once it is added on BE
        if (e?.response?.status === 410) {
            if (e?.response?.data?.message?.info === 'Transaction has been released') {
                const affiliatedId = e?.response?.data?.message?.affiliatedTransactionId;
                yield call(handleAffiliatedTransaction, {params, affiliatedId});

                return;
            }
        }

        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_ARTICLES, false));
    }
};

const saveBankTransactionFieldFlow = function* (params) {
    try {
        const hiwayAccountId = yield call(getHiwayAccountId);
        const accountId = params?.accountId ?? hiwayAccountId;

        const transactionsData = yield select(TransactionsSelector.selectTransactions);
        const transaction = transactionsData.transactionsObject[params.id];

        if (params?.additionalData?.isDnd) {
            yield put(LoadingActions.setLoading(
                LoadingTypes.BANK_TRANSACTION_ADDITIONAL_FIELD,
                params?.additionalData?.id,
            ));
        }

        // If we want to save fakeReimbursedTransactions we need to clear other reimbursements
        if (params.fieldKey === 'fakeReimbursedTransactions'
            && !transaction?.reimbursementTransactions?.fakeReimbursedTransactions?.length) {
            // TODO Do not call it if it does not have selected reimbursementTransactions
            yield call(BankApi.saveTransactionField, {
                accountId,
                id: params.id,
                fieldKey: 'reimbursementTransactions',
                fieldValue: [],
            });
        }

        const response = yield call(BankApi.saveTransactionField, {
            accountId,
            id: params.id,
            fieldKey: params.fieldKey,
            fieldValue: params.fieldValue,
        });

        // Show error message if element was removed
        if (params.fieldKey === 'selectedInvoices') {
            const categorizationInfo = yield select(TransactionsSelector.selectCategorizationScreenInfo);
            const details = yield select(
                TransactionsSelector.createSelectTransactionById(categorizationInfo?.transactionId),
            );

            const detailsBestLength = details?.invoices?.bestMatchedInvoices.filter(invoice => invoice.selected);
            const detailsOtherLength = details?.invoices?.allOtherInvoices.filter(invoice => invoice.selected);
            const responseBestLength = response?.invoices?.bestMatchedInvoices.filter(invoice => invoice.selected);
            const responseOtherLength = response?.invoices?.allOtherInvoices.filter(invoice => invoice.selected);

            let unselected = [];
            if (detailsBestLength?.length > responseBestLength?.length) {
                unselected = arrayDiff(
                    detailsBestLength,
                    responseBestLength,
                    'invoiceId',
                );
            }

            if (detailsOtherLength?.length > responseOtherLength?.length) {
                unselected = arrayDiff(
                    detailsOtherLength,
                    responseOtherLength,
                    'invoiceId',
                );
            }

            if (unselected?.length > 0) {
                unselected.forEach(invoice => {
                    Toast.success('invoiceRemoved', {
                        translationValues: {
                            name: invoice?.docNumber,
                        },
                    });
                });
            }
        }


        yield put(TransactionsActions.storeTransactionInObject(response));

        yield call(updateNonCategorizedFlow, {params, transaction, response, transactionsData});

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION_FIELD, false));

        if (params?.additionalData?.isDnd) {
            yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION_ADDITIONAL_FIELD, false));
        }

        if (params?.type === CATEGORY_MAGIC_SUBSCRIPTION_TYPE.ALL) {
            yield put(TransactionsActions.refreshTransactionList(true));

            const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
            const user = yield select(selectUser);

            const isAdminInterface = loggedInUser?.role === USER_ROLES.ADMINISTRATOR;

            yield put(TransactionsActions.getTotalOverview(isAdminInterface ? user?.id : undefined));
        }
    } catch (e) {
        // TODO: Use constants once it is added on BE
        if (e?.response?.status === 410) {
            if (e?.response?.data?.message?.info === 'Transaction has been released') {
                const affiliatedId = e?.response?.data?.message?.affiliatedTransactionId;
                yield call(handleAffiliatedTransaction, {params, affiliatedId});

                return;
            }
        }

        Debug.error('bank', 'Error: ', {e});
        Toast.error(e?.response?.data?.message ?? 'genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION_FIELD, false));

        if (params?.additionalData?.isDnd) {
            yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION_ADDITIONAL_FIELD, false));
        }
    }
};

const uploadTransactionFileFlow = function* (id, files, paramAccountId) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_BANK_FILE, true));

        const hiwayAccountId = yield call(getHiwayAccountId);
        const accountId = paramAccountId ?? hiwayAccountId;

        const SSEEventSource = yield select(SSESelector.selectBankSSEEventSource);

        if (!SSEEventSource?.readyState || SSEEventSource.readyState === EventSource.CLOSED) {
            yield put(SseActions.reconnectBankSSE());
            yield delay(200);
        }

        const response = yield call(BankApi.uploadTransactionFile, {
            accountId,
            id: id,
            files,
        });

        // Put eventId in saga to wait for SSE response
        yield put(TransactionsActions.updateFileUploadLoader({
            transactionId: id,
            eventId: response.pendingEventId,
            inProgress: true,
            accountId: paramAccountId,
        }));

        // TODO Check if this feature is needed
        // Having a lot of issues here regarding categorisation uncategorised count
        // If hasChip false here, then on file upload when calling get it will not enter code that counts it properly
        // const transactionsData = yield select(TransactionsSelector.selectTransactions);
        // const transaction = transactionsData.transactionsObject[id];
        // If file is non-categorized and is missing only documents categorize it before file upload is done
        // if (transaction.hasChip) {
        //     // Check if non-categorized reason is missing file
        //     if (transaction?.categorizationWarnings?.length === 1
        //         && transaction?.categorizationWarnings[0] === 'MISSING_DOCUMENTS'
        //     ) {
        //         let nonCategorizedCount = transactionsData.nonCategorizedCount;
        //         nonCategorizedCount -= 1;
        //
        //         yield put(TransactionsActions.storeTransactionInObject(
        //             {
        //                 ...transaction,
        //                 hasChip: false,
        //                 isFileUpload: true,
        //             },
        //         ));
        //
        //         Toast.success('transactionCategorized');
        //
        //         yield put(TransactionsActions.storeNonCategorizedCount(nonCategorizedCount));
        //     }
        // }

        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_BANK_FILE, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_BANK_FILE, false));
    }
};

const deleteBankFileFlow = function* (fileId, transactionId, paramAccountId) {
    yield put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK_FILE, true));

    try {
        const hiwayAccountId = yield call(getHiwayAccountId);
        const accountId = paramAccountId ?? hiwayAccountId;

        yield call(BankApi.deleteBankFile, {
            accountId,
            fileId,
            transactionId,
        });

        yield call(getBankTransactionFlow, {
            id: transactionId,
            accountId: paramAccountId,
            doNotShowLoader: true,
        });

        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK_FILE, false));
    } catch (e) {
        // TODO: Use constants once it is added on BE
        if (e?.response?.status === 410) {
            if (e?.response?.data?.message?.info === 'Transaction has been released') {
                const affiliatedId = e?.response?.data?.message?.affiliatedTransactionId;
                yield call(handleAffiliatedTransaction, {params: {id: transactionId}, affiliatedId});

                return;
            }
        }

        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK_FILE, false));
    }
};

const countCategorizedInSessionFlow = function* () {
    const transactions = yield select(TransactionsSelector.selectTransactionObject);
    const categorizationListIds = yield select(TransactionsSelector.selectCategorizationList);

    let count = 0;
    categorizationListIds.forEach(id => {
        if (transactions[id]?.isCategorized) {
            count += 1;
        }
    });

    yield put(TransactionsActions.setTotalCategorizationNumberInSession(count));
};

const getMoreUncategorizedTransactionsFlow = function* (params, sentFreelancerId) {
    try {
        const bankTransactions = yield call(BankApi.getCombinedBankTransactions, params, sentFreelancerId);

        // TODO Check if needed to update list
        // const existingTransactions = yield select(TransactionsSelector.selectTransactions);

        // const transactionsObject = {
        //     ...existingTransactions.transactionsObject,
        //     ...bankTransactions.transactionsObject,
        // };
        // const ids = existingTransactions.ids;

        // yield put(TransactionsActions.storeTransactionList({
        //     ...existingTransactions,
        //     ids: ids,
        //     transactionsObject: transactionsObject,
        // }));

        // Add to list only uncategorized
        const oldUncategorizedIds = yield select(TransactionsSelector.selectCategorizationList);
        const uncategorizedIds = [...new Set([...bankTransactions.ids, ...oldUncategorizedIds])];

        yield put(TransactionsActions.setCategorizationList(uncategorizedIds));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');
    }
};

const addToCategorisationListFlow = function* ({id, place}) {
    const list = yield select(TransactionsSelector.selectCategorizationList);

    const hasIndex = list.find(listId => listId === id);

    if (hasIndex) {
        return;
    }

    const newList = [...list];
    newList.splice(place, 0, id);

    yield put(TransactionsActions.setCategorizationList(newList));
};

const addSpecialToCategorisationListFlow = function* ({id}) {
    const list = yield select(TransactionsSelector.selectCategorizationList);

    if (list?.length === 0) {
        return;
    }

    const hasIndex = list.find(listId => listId === id);

    if (hasIndex) {
        return;
    }

    yield put(TransactionsActions.addSpecialToCategorisationList(id));
};

const getMostUsedCategoriesFlow = function* ({type, accountId}) {
    try {
        if (type === TransactionSide.CREDIT) {
            const mostPopular = yield call(BankApi.getBankSources, {
                accountId: accountId,
                params: {
                    onlyTop: true,
                    side: 'credit',
                },
            });
            const debit = yield select(TransactionsSelector.selectMostUsedDebitCategories);

            yield put(TransactionsActions.storeMostUsedCategories({
                debit,
                credit: mostPopular,
            }));
        } else {
            const mostPopular = yield call(BankApi.getBankCategories, {
                accountId: accountId,
                params: {
                    onlyTop: true,
                },
            });
            const credit = yield select(TransactionsSelector.selectMostUsedCreditCategories);

            yield put(TransactionsActions.storeMostUsedCategories({
                debit: mostPopular,
                credit,
            }));
        }
    } catch (e) {
        Debug.error('bank getMostUsedCategoriesFlow', 'Error: ', {e});
        Toast.error('genericError');
    }
};

const refuseProofOfDocumentsFlow = function* (params) {
    const hiwayAccountId = yield call(getHiwayAccountId);
    const accountId = params?.accountId ?? hiwayAccountId;

    try {
        const transactionsData = yield select(TransactionsSelector.selectTransactions);
        const transaction = transactionsData?.transactionsObject?.[params.id];

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        const response = yield call(BankApi.saveTransaction, {
            accountId,
            id: params.id,
            refuseToShareProofDocuments: params?.shouldRefuse,
            type: params?.type,
        });

        // Update article tvaAmount (needed for details)
        yield put(TransactionsActions.storeTransactionInObject(response));

        yield call(updateNonCategorizedFlow, {params, transaction, response, transactionsData});

        yield put(UiActions.setActiveModal(ModalsKeys.CATEGORIZATION_PROOF_OF_DOCUMENTS, false));
        yield put(UiActions.setActiveModal(ModalsKeys.CATEGORIZATION_DEACTIVATE_SUBSCRIPTION, false));

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        if (params?.type === CATEGORY_MAGIC_SUBSCRIPTION_TYPE.ALL) {
            yield put(TransactionsActions.refreshTransactionList(true));

            const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
            const user = yield select(selectUser);

            const isAdminInterface = loggedInUser?.role === USER_ROLES.ADMINISTRATOR;

            yield put(TransactionsActions.getTotalOverview(isAdminInterface ? user?.id : undefined));
        }
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const getTransactionsMatchingSubscriptionFlow = function* ({id, accountId}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        const result = yield call(BankApi.getTransactionsMatchingSubscription, {id, accountId});

        yield put(TransactionsActions.storeTransactionMatchingSubscription(result));

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('bank getTransactionsMatchingSubscriptionFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const getEligibleTransactionsMatchingSubscriptionFlow = function* ({id, accountId}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        const result = yield call(BankApi.getEligibleTransactionsMatchingSubscription, {id, accountId});

        yield put(TransactionsActions.storeEligibleTransactionMatchingSubscription(result));

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('bank getEligibleTransactionsMatchingSubscriptionFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const activateSubScriptionFlow = function* ({id, accountId, type}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        const result = yield call(BankApi.activateSubscription, {id, accountId, type});

        yield put(TransactionsActions.storeTransactionInObject(result));

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        let numberOfAffected = 1;
        if (type === CATEGORY_MAGIC_SUBSCRIPTION_TYPE.ALL) {
            const elligableTransactions = yield select(TransactionsSelector.selectEligibleMatchingSubcription);

            if (elligableTransactions?.length > 0) {
                numberOfAffected = elligableTransactions.length + 1;

                yield put(TransactionsActions.refreshTransactionList(true));

                const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
                const user = yield select(selectUser);

                const isAdminInterface = loggedInUser?.role === USER_ROLES.ADMINISTRATOR;

                yield put(TransactionsActions.getTotalOverview(isAdminInterface ? user?.id : undefined));
            }
        }

        Toast.success('subscriptionActivated', {
            translationValues: {
                number: numberOfAffected, // TODO Adjust this param once BE implements it
            },
        });

        // TODO Add for admin
        yield call(handleAfterSubscriptionChangeFlow);
    } catch (e) {
        Debug.error('bank activateSubScriptionFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const deactivateSubScriptionFlow = function* ({id, accountId, type}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_ARTICLES, true));

        const result = yield call(BankApi.deactivateSubscription, {id, accountId, type});

        yield put(TransactionsActions.storeTransactionInObject(result));

        yield put(UiActions.setActiveModal(ModalsKeys.CATEGORIZATION_DEACTIVATE_SUBSCRIPTION, false));

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_ARTICLES, false));

        let numberOfAffected = 1;
        if (type === CATEGORY_MAGIC_SUBSCRIPTION_TYPE.ALL) {
            const elligableTransactions = yield select(TransactionsSelector.selectEligibleMatchingSubcription);
            const relatedTransactions = yield select(TransactionsSelector.selectMatchingSubcription);

            if (elligableTransactions?.length > 0) {
                numberOfAffected = elligableTransactions.length + 1;

                yield put(TransactionsActions.refreshTransactionList(true));

                const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
                const user = yield select(selectUser);

                const isAdminInterface = loggedInUser?.role === USER_ROLES.ADMINISTRATOR;

                yield put(TransactionsActions.getTotalOverview(isAdminInterface ? user?.id : undefined));
            } else if (relatedTransactions?.length > 0) {
                numberOfAffected = relatedTransactions.length + 1;

                yield put(TransactionsActions.refreshTransactionList(true));

                const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
                const user = yield select(selectUser);

                const isAdminInterface = loggedInUser?.role === USER_ROLES.ADMINISTRATOR;

                yield put(TransactionsActions.getTotalOverview(isAdminInterface ? user?.id : undefined));
            }
        }

        Toast.success('subscriptionActivated', {
            translationValues: {
                number: numberOfAffected, // TODO Adjust this param once BE implements it
            },
        });

        // TODO Add for admin
        yield call(handleAfterSubscriptionChangeFlow);
    } catch (e) {
        Debug.error('bank deactivateSubScriptionFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_ARTICLES, false));
    }
};

const getTotalOverviewFLow = function* (sentFreelancerId) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    const freelancerId = sentFreelancerId ?? loggedInUser?.id;

    try {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION, true));

        const totalOverview = yield call(BankApi.getTransactionOverview, freelancerId);

        if (totalOverview) {
            yield put(TransactionsActions.storeTotalOverview(totalOverview));

            yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION, false));
        }

        const bankTransactions = yield call(BankApi.getCombinedBankTransactions, {}, sentFreelancerId);
        const uncategorizedIds = [...new Set([...bankTransactions.ids])];

        yield put(TransactionsActions.setCategorizationList(uncategorizedIds));
    } catch (e) {
        Debug.error('bank getCombinedTransactionListFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION, false));
    }
};

// Workers
export const getTransactionListLoaderWorker = function* () {
    const transactions = yield select(TransactionsSelector.selectTransactions);

    yield put(LoadingActions.setLoading(LoadingTypes.BANK_GET_INTEGRATIONS, true));
    if (transactions?.length === 0) {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTIONS, true));
    }
};

export const getTransactionListWorker = function* ({payload}) {
    const hiwayAcountId = yield call(getHiwayAccountId);

    const accountId = payload?.accountId ?? hiwayAcountId;

    const params = payload?.params ?? payload;

    const transactionTypes = params?.transactionTypes?.length > 0
        ? params.transactionTypes.flatMap(
            transaction => (transaction === PAYMENT_TYPES_FOR_FILTER['TRANSFER'].type
                ? PAYMENT_TYPES_FOR_FILTER['TRANSFER'].contaningTypesList
                : transaction),
        )
        : undefined;

    yield call(
        getTransactionListFlow,
        {
            sortOrder: 'DESC',
            limit: params?.limit ?? LIMIT_COUNT,
            offset: params?.offset ?? 0,
            searchQuery: params?.search && params?.search !== ''
                ? params.search
                : undefined,
            filterQuery: params && Object.keys(params).length !== 0 ? {
                isNotCategorized: params.isNotCategorized || undefined,
                startDate: params.startDate ? params.startDate.format('MM/DD/YYYY') : undefined,
                endDate: params.endDate ? params.endDate.format('MM/DD/YYYY') : undefined,
                transactionTypes: transactionTypes,
            } : undefined,
        },
        accountId,
    );
};

const loadNextTransactionPageWorker = function* ({payload}) {
    const hiwayAcountId = yield call(getHiwayAccountId);
    const accountId = payload?.accountId ?? hiwayAcountId;

    if (!accountId) {
        return;
    }

    const params = payload?.params ?? payload;

    const oldTransaction = yield select(TransactionsSelector.selectTransactions);

    const length = oldTransaction?.ids?.length;

    const isLoadingOffset = yield select(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_TRANSACTIONS_PAGE_LOADER,
    ));

    if (length >= oldTransaction.count || isLoadingOffset) {
        return;
    }

    yield call(
        getTransactionListFlow,
        {
            sortOrder: 'DESC',
            limit: params?.limit ?? LIMIT_COUNT,
            offset: oldTransaction?.ids?.length ?? 0,
            searchQuery: params?.search && params?.search !== ''
                ? params.search
                : undefined,
            filterQuery: params && Object.keys(params).length !== 0 ? {
                isNotCategorized: params.isNotCategorized || undefined,
                startDate: params.startDate ? params.startDate.format() : undefined,
                endDate: params.endDate ? params.endDate.format() : undefined,
                transactionTypes: params?.transactionTypes?.length > 0
                    ? params.transactionTypes.flatMap(
                        transaction => (transaction === PAYMENT_TYPES_FOR_FILTER['TRANSFER'].type
                            ? PAYMENT_TYPES_FOR_FILTER['TRANSFER'].contaningTypesList
                            : transaction),
                    )
                    : undefined,
            } : undefined,
        },
        accountId,
    );
};

// TODO Perhaps join combined and regular worker
export const getCombinedTransactionListWorker = function* ({payload}) {
    const params = payload?.params ?? payload;

    const transactionTypes = params?.transactionTypes?.length > 0
        ? params.transactionTypes.flatMap(
            transaction => (transaction === PAYMENT_TYPES_FOR_FILTER['TRANSFER'].type
                ? PAYMENT_TYPES_FOR_FILTER['TRANSFER'].contaningTypesList
                : transaction),
        )
        : undefined;

    yield call(
        getCombinedTransactionListFlow,
        {
            sortOrder: 'DESC',
            limit: params?.limit ?? LIMIT_COUNT,
            offset: params?.offset ?? 0,
            searchQuery: params?.search && params?.search !== ''
                ? params.search
                : undefined,
            filterQuery: params && (
                params.archivedOnly
                || params.isNotCategorized
                || params.startDate
                || params.endDate
                || params.onFilterChanged
                || transactionTypes
            ) ? {
                    archivedOnly: params.archivedOnly || undefined,
                    isNotCategorized: params.isNotCategorized || undefined,
                    startDate: params.startDate ? params.startDate.format('MM/DD/YYYY') : undefined,
                    endDate: params.endDate ? params.endDate.format('MM/DD/YYYY') : undefined,
                    transactionTypes: transactionTypes,
                } : undefined,
        },
        payload?.userId,
    );
};

const loadNextCombinedTransactionPageWorker = function* ({payload}) {
    const params = payload?.params ?? payload;

    const oldTransaction = yield select(TransactionsSelector.selectTransactions);

    const length = oldTransaction?.ids?.length;

    const isLoadingOffset = yield select(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_TRANSACTIONS_PAGE_LOADER,
    ));

    if (length >= oldTransaction.count || isLoadingOffset) {
        return;
    }

    yield call(
        getCombinedTransactionListFlow,
        {
            sortOrder: 'DESC',
            limit: params?.limit ?? LIMIT_COUNT,
            offset: oldTransaction?.ids?.length ?? 0,
            searchQuery: params?.search && params?.search !== ''
                ? params.search
                : undefined,
            filterQuery: params && Object.keys(params).length !== 0 ? {
                archivedOnly: params.archivedOnly || undefined,
                isNotCategorized: params.isNotCategorized || undefined,
                startDate: params.startDate ? params.startDate.format() : undefined,
                endDate: params.endDate ? params.endDate.format() : undefined,
                transactionTypes: params?.transactionTypes?.length > 0
                    ? params.transactionTypes.flatMap(
                        transaction => (transaction === PAYMENT_TYPES_FOR_FILTER['TRANSFER'].type
                            ? PAYMENT_TYPES_FOR_FILTER['TRANSFER'].contaningTypesList
                            : transaction),
                    )
                    : undefined,
            } : undefined,
        },
        payload?.userId,
    );
};

const getBankTransactionWorker = function* ({payload}) {
    yield call(getBankTransactionFlow, payload);
};

export const saveBankArticlesWorker = function* ({payload}) {
    const details = yield select(TransactionsSelector.createSelectTransactionById(payload?.id));

    const articleIndex = payload.articles.findIndex(article => !isNaN(parseFloat(article?.amount))
        && !isNaN(parseFloat(article?.tvaAmount))
        && Number(article?.amount) < Number(article?.tvaAmount));

    if (articleIndex !== -1) {
        // If amount is changed, tva might not be valid, but it will be recallculated
        if (details?.articles?.[articleIndex]?.amount === payload.articles?.[articleIndex]?.amount
        && details?.articles?.[articleIndex]?.tvaAmount === payload.articles?.[articleIndex]?.tvaAmount) {
            return;
        }
    }

    yield put(LoadingActions.setLoading(LoadingTypes.BANK_ARTICLES, true));

    yield call(saveBankArticlesFlow, payload);
};

export const saveBankTransactionFieldWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BANK_TRANSACTION_FIELD, true));

    yield call(saveBankTransactionFieldFlow, payload);
};

export const uploadTransactionFileWorker = function* ({payload}) {
    yield call(uploadTransactionFileFlow, payload.id, payload.file, payload?.accountId);
};

const deleteBankFileWorker = function* ({payload}) {
    yield call(deleteBankFileFlow, payload.fileId, payload.transactionId, payload?.accountId);
};

const getMoreUncategorizedTransactionsWorker = function* ({payload}) {
    yield call(getMoreUncategorizedTransactionsFlow, {
        sortOrder: 'DESC',
        limit: payload?.limit ?? LIMIT_COUNT,
        offset: payload?.offset ?? 0,
        filterQuery: {
            isNotCategorized: true,
        },
    },
    payload?.userId);
};

const countCategorizedInSessionWorker = function* () {
    yield call(countCategorizedInSessionFlow);
};

const getMostUsedCategoriesWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BANK_CATEGORIES, true));

    const integrationData = yield select(BankSelector.selectIntegrations);
    const accountId = yield call(getIntegrationWithAccountId, integrationData);

    if (!accountId) {
        yield put(LoadingActions.setLoading(LoadingTypes.BANK_CATEGORIES, false));
        return;
    }

    yield call(getMostUsedCategoriesFlow, {type: payload?.type, accountId});
};

const refuseProofOfDocumentsWorker = function* ({payload}) {
    yield call(refuseProofOfDocumentsFlow, payload);
};

const getTransactionsMatchingSubscriptionWorker = function* ({payload}) {
    yield call(getTransactionsMatchingSubscriptionFlow, payload);
};

const getEligibleTransactionsMatchingSubscriptionWorker = function* ({payload}) {
    yield call(getEligibleTransactionsMatchingSubscriptionFlow, payload);
};

const activateSubScriptionWorker = function* ({payload}) {
    yield call(activateSubScriptionFlow, payload);
};

const deactivateSubScriptionWorker = function* ({payload}) {
    yield call(deactivateSubScriptionFlow, payload);
};

const addToCategorisationListWorker = function* ({payload}) {
    if (payload?.hasSpecialHandlingIfCategorised) {
        yield call(addSpecialToCategorisationListFlow, {id: payload.transactionId});
        return;
    }
    yield call(addToCategorisationListFlow, {id: payload.transactionId, place: 0});
};

const validateTransactionVatWorker = function* ({payload}) {
    try {
        const response = yield call(BankApi.validateTransactionVat, payload);
        yield put(TransactionsActions.storeTransactionInObject(response));
    } catch (error) {
        Debug.error('bank validateTransactionVatWorker', 'Error: ', {error});
        Toast.error('genericError');
    }
};

const getTotalOverviewWorker = function* ({payload}) {
    yield call(getTotalOverviewFLow, payload);
};

export const watchTransactionsSaga = function* () {
    yield all([
        // Transaction list
        takeLatest(TransactionsActionTypes.GET_CATEGORY_LIST, bankGetCategoryFlow),
        takeEvery(TransactionsActionTypes.GET_SOURCES_LIST, bankGetSourcesFlow),
        takeEvery(TransactionsActionTypes.GET_TRANSACTION_LIST, getTransactionListWorker),
        takeEvery(TransactionsActionTypes.LOAD_NEXT_TRANSACTION_PAGE, loadNextTransactionPageWorker),
        takeEvery(TransactionsActionTypes.SET_TRANSACTION_ALERT, setTransactionAlertFlow),
        takeEvery(TransactionsActionTypes.GET_COMBINED_TRANSACTION_LIST, getCombinedTransactionListWorker),
        takeEvery(TransactionsActionTypes.LOAD_NEXT_COMBINED_TRANSACTION_PAGE, loadNextCombinedTransactionPageWorker),
        takeEvery(TransactionsActionTypes.GET_MOST_USED_CATEGORIES, getMostUsedCategoriesWorker),
        takeEvery(TransactionsActionTypes.GET_TOTAL_OVERVEIW, getTotalOverviewWorker),
        // Transaction details
        takeEvery(TransactionsActionTypes.GET_BANK_TRANSACTION, getBankTransactionWorker),
        takeEvery(TransactionsActionTypes.SAVE_BANK_ARTICLES, saveBankArticlesWorker),
        takeEvery(TransactionsActionTypes.SAVE_BANK_TRANSACTION_FIELD, saveBankTransactionFieldWorker),
        takeEvery(TransactionsActionTypes.UPLOAD_TRANSACTION_FILE, uploadTransactionFileWorker),
        takeEvery(TransactionsActionTypes.DELETE_BANK_FILE, deleteBankFileWorker),
        // Categorization
        takeEvery(TransactionsActionTypes.COUNT_CATEGORIZED_IN_SESSION, countCategorizedInSessionWorker),
        takeEvery(TransactionsActionTypes.GET_MORE_UNCATEGORIZED_TRANSACTIONS, getMoreUncategorizedTransactionsWorker),
        takeEvery(TransactionsActionTypes.REFUSE_CATEGORIZATION_PROOF_OF_DOCUMENTS, refuseProofOfDocumentsWorker),
        takeEvery(TransactionsActionTypes.ADD_TO_CATEGORISATION_LIST, addToCategorisationListWorker),
        takeEvery(
            TransactionsActionTypes.GET_TRANSACTIONS_MATCHING_SUBSCRIPTION,
            getTransactionsMatchingSubscriptionWorker,
        ),
        takeEvery(
            TransactionsActionTypes.GET_ELIGIBLE_TRANSACTIONS_MATCHING_SUBSCRIPTION,
            getEligibleTransactionsMatchingSubscriptionWorker,
        ),
        takeEvery(TransactionsActionTypes.ACTIVATE_SUBSCRIPTION, activateSubScriptionWorker),
        takeEvery(TransactionsActionTypes.DEACTIVATE_SUBSCRIPTION, deactivateSubScriptionWorker),
        takeEvery(TransactionsActionTypes.VALIDATE_VAT, validateTransactionVatWorker),
    ]);
};
