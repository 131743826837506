/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {Button} from '@mui/joy';
import {styled, useThemeProps} from '@mui/system';
import {unstable_useForkRef as useForkRef} from '@mui/utils';
import useEnhancedEffect from '@mui/utils/useEnhancedEffect';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';
import {
    getPickersDayUtilityClass,
    pickersDayClasses,
} from './pickersDayClasses';
import {DAY_MARGIN, DAY_SIZE} from '../internals/constants/dimensions';
import {useUtils} from '../internals/hooks/useUtils';

const useUtilityClasses = ownerState => {
    const {
        selected,
        disableMargin,
        disableHighlightToday,
        today,
        disabled,
        outsideCurrentMonth,
        showDaysOutsideCurrentMonth,
        classes,
    } = ownerState;

    const slots = {
        root: [
            'root',
            selected && 'selected',
            disabled && 'disabled',
            !disableMargin && 'dayWithMargin',
            !disableHighlightToday && today && 'today',
            outsideCurrentMonth && showDaysOutsideCurrentMonth && 'dayOutsideMonth',
            outsideCurrentMonth
                && !showDaysOutsideCurrentMonth
                && 'hiddenDaySpacingFiller',
        ],
        hiddenDaySpacingFiller: ['hiddenDaySpacingFiller'],
    };

    return composeClasses(slots, getPickersDayUtilityClass, classes);
};

const styleArg = ({theme, ownerState}) => ({
    'fontSize': '14px',
    'fontStyle': 'normal',
    'fontWeight': theme.fontWeight.md,
    'width': DAY_SIZE,
    'height': DAY_SIZE,
    'borderRadius': '50%',
    'padding': 0,
    // background required here to prevent collides with the other days when animating with transition group
    'backgroundColor': theme.palette.common.white,
    'color': theme.palette.text.secondary,
    '&:hover': {
        backgroundColor: theme.palette.neutral.softHoverBg,
    },
    '&:focus': {
        backgroundColor: theme.palette.neutral.softHoverBg,
        [`&.${pickersDayClasses.selected}`]: {
            willChange: 'background-color',
            backgroundColor: theme.palette.neutral.solidActiveBg,
        },
    },
    [`&.${pickersDayClasses.selected}`]: {
        'color': theme.palette.primary.solidColor,
        'backgroundColor': theme.palette.neutral.solidActiveBg,
        'fontWeight': theme.fontWeight.lg,
        'transition': 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
        '&:hover': {
            willChange: 'background-color',
            backgroundColor: theme.palette.neutral.plainColor,
        },
    },
    [`&.${pickersDayClasses.disabled}`]: {
        color: theme.palette.text.tertiary,
        backgroundColor: theme.palette.common.white,
    },
    ...(!ownerState.disableMargin && {
        margin: `0 ${DAY_MARGIN}px`,
    }),
    ...(ownerState.outsideCurrentMonth
    && ownerState.showDaysOutsideCurrentMonth && {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.common.white,
    }),
    ...(!ownerState.disableHighlightToday
    && ownerState.today && {
        [`&:not(.${pickersDayClasses.selected})`]: {
            border: `1px solid ${theme.palette.neutral.outlinedBorder}`,
        },
    }),
});

const overridesResolver = (props, styles) => {
    const {ownerState} = props;
    return [
        styles.root,
        !ownerState.disableMargin && styles.dayWithMargin,
        !ownerState.disableHighlightToday && ownerState.today && styles.today,
        !ownerState.outsideCurrentMonth
            && ownerState.showDaysOutsideCurrentMonth
            && styles.dayOutsideMonth,
        ownerState.outsideCurrentMonth
            && !ownerState.showDaysOutsideCurrentMonth
            && styles.hiddenDaySpacingFiller,
    ];
};

const PickersDayRoot = styled(Button, {
    name: 'MuiPickersDay',
    slot: 'Root',
    overridesResolver,
})(styleArg);

const PickersDayFiller = styled('div', {
    name: 'MuiPickersDay',
    slot: 'Root',
    overridesResolver,
})(({theme, ownerState}) => ({
    ...styleArg({theme, ownerState}),
    // visibility: 'hidden' does not work here as it hides the element from screen readers as well
    opacity: 0,
    pointerEvents: 'none',
}));

const noop = () => {};

const PickersDayRaw = React.forwardRef((
    inProps,
    forwardedRef,
) => {
    const props = useThemeProps({
        props: inProps,
        name: 'MuiPickersDay',
    });

    const {
        autoFocus = false,
        className,
        day,
        disabled = false,
        disableHighlightToday = false,
        disableMargin = false,
        hidden,
        isAnimating,
        onClick,
        onDaySelect,
        onFocus = noop,
        onBlur = noop,
        onKeyDown = noop,
        onMouseDown,
        outsideCurrentMonth,
        selected = false,
        showDaysOutsideCurrentMonth = false,
        children,
        today: isToday = false,
        ...other
    } = props;
    const ownerState = {
        ...props,
        autoFocus,
        disabled,
        disableHighlightToday,
        disableMargin,
        selected,
        showDaysOutsideCurrentMonth,
        today: isToday,
    };

    const classes = useUtilityClasses(ownerState);

    const utils = useUtils();
    const ref = React.useRef(null);
    const handleRef = useForkRef(ref, forwardedRef);

    // Since this is rendered when a Popper is opened we can't use passive effects.
    // Focusing in passive effects in Popper causes scroll jump.
    useEnhancedEffect(() => {
        if (autoFocus && !disabled && !isAnimating && !outsideCurrentMonth) {
            // ref.current being null would be a bug in MUI
            ref.current.focus();
        }
    }, [autoFocus, disabled, isAnimating, outsideCurrentMonth]);

    // For day outside of current month, move focus from mouseDown to mouseUp
    // Goal: have the onClick ends before sliding to the new month
    const handleMouseDown = event => {
        if (onMouseDown) {
            onMouseDown(event);
        }
        if (outsideCurrentMonth) {
            event.preventDefault();
        }
    };

    const handleClick = event => {
        if (!disabled) {
            onDaySelect(day, 'finish');
        }

        if (outsideCurrentMonth) {
            event.currentTarget.focus();
        }

        if (onClick) {
            onClick(event);
        }
    };

    if (outsideCurrentMonth && !showDaysOutsideCurrentMonth) {
        return (
            <PickersDayFiller
                className={clsx(
                    classes.root,
                    classes.hiddenDaySpacingFiller,
                    className,
                )}
                ownerState={ownerState}
                role={other.role}
            />
        );
    }

    return (
        <PickersDayRoot
            className={clsx(classes.root, className)}
            ownerState={ownerState}
            ref={handleRef}
            centerRipple
            data-mui-test="day"
            disabled={disabled}
            tabIndex={selected ? 0 : -1}
            onKeyDown={event => onKeyDown(event, day)}
            onFocus={event => onFocus(event, day)}
            onBlur={event => onBlur(event, day)}
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            {...other}
        >
            {!children ? utils.format(day, 'dayOfMonth') : children}
        </PickersDayRoot>
    );
});

export const areDayPropsEqual = (prevProps, nextProps) => {
    return (
        prevProps.autoFocus === nextProps.autoFocus
            && prevProps.isAnimating === nextProps.isAnimating
            && prevProps.today === nextProps.today
            && prevProps.disabled === nextProps.disabled
            && prevProps.selected === nextProps.selected
            && prevProps.disableMargin === nextProps.disableMargin
            && prevProps.showDaysOutsideCurrentMonth === nextProps.showDaysOutsideCurrentMonth
            && prevProps.disableHighlightToday === nextProps.disableHighlightToday
            && prevProps.className === nextProps.className
            && prevProps.sx === nextProps.sx
            && prevProps.outsideCurrentMonth === nextProps.outsideCurrentMonth
            && prevProps.onFocus === nextProps.onFocus
            && prevProps.onBlur === nextProps.onBlur
            && prevProps.onDaySelect === nextProps.onDaySelect
    );
};

PickersDayRaw.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // | To update them edit the TypeScript types and run "yarn proptypes"  |
    // ----------------------------------------------------------------------
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,

    /**
     * The date to show.
     */
    day: PropTypes.any.isRequired,

    /**
     * If `true`, renders as disabled.
     * @default false
     */
    disabled: PropTypes.bool,

    /**
     * If `true`, today's date is rendering without highlighting with circle.
     * @default false
     */
    disableHighlightToday: PropTypes.bool,

    /**
     * If `true`, days are rendering without margin. Useful for displaying linked range of days.
     * @default false
     */
    disableMargin: PropTypes.bool,

    isAnimating: PropTypes.bool,
    onBlur: PropTypes.func,
    onDaySelect: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,

    /**
     * If `true`, day is outside of month and will be hidden.
     */
    outsideCurrentMonth: PropTypes.bool.isRequired,

    /**
     * If `true`, renders as selected.
     * @default false
     */
    selected: PropTypes.bool,

    /**
     * If `true`, days that have `outsideCurrentMonth={true}` are displayed.
     * @default false
     */
    showDaysOutsideCurrentMonth: PropTypes.bool,

    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),

    /**
     * If `true`, renders as today date.
     * @default false
     */
    today: PropTypes.bool,
};

/**
 *
 * Demos:
 *
 * - [Date Picker](https://mui.com/x/react-date-pickers/date-picker/)
 *
 * API:
 *
 * - [PickersDay API](https://mui.com/x/api/date-pickers/pickers-day/)
 */
export const PickersDay = React.memo(PickersDayRaw, areDayPropsEqual);
