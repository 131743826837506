import {Box} from '@mui/material';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const NotificationsContainer = () => {
    return (
        <Box
            sx={{
                '& .Toastify__toast-container': {
                    padding: 0,
                },
                '& .Toastify__toast': {
                    padding: 0,
                    minHeight: 48,
                    borderRadius: 3,
                },
                '& .Toastify__toast-body': {
                    padding: 0,
                },
            }}
        >
            <ToastContainer
                position="bottom-right"
                autoClose={6000}
                closeButton={false}
                closeOnClick={false}
                draggable={false}
                hideProgressBar
                newestOnTop
                pauseOnFocusLoss
                pauseOnHover
            />
        </Box>
    );
};
