import PropTypes from 'prop-types';
import React from 'react';
import IrSubscribeModal from './IrSubscribeModal';
import IsSubscribeModal from './IsSubscribeModal';
import {COMPANY_TAX_SYSTEMS} from '../../../../v1/app/company/company.consts';

const SubscribeModal = ({taxSystem, freelancerId, companyId, insuranceAlertOnCloseHandler}) => (
    taxSystem === COMPANY_TAX_SYSTEMS.IS
        ? (
            <IsSubscribeModal
                insuranceAlertOnCloseHandler={insuranceAlertOnCloseHandler}
                freelancerId={freelancerId}
                companyId={companyId}
            />
        ) : (
            <IrSubscribeModal
                insuranceAlertOnCloseHandler={insuranceAlertOnCloseHandler}
                freelancerId={freelancerId}
                companyId={companyId}
            />
        )
);

SubscribeModal.propTypes = {
    taxSystem: PropTypes.string.isRequired,
    freelancerId: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    insuranceAlertOnCloseHandler: PropTypes.func,
};

SubscribeModal.defaultProps = {
    insuranceAlertOnCloseHandler: null,
};

export default SubscribeModal;
