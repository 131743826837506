import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CategoryIcon from '@mui/icons-material/Category';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Alert, Badge, Box, Button, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import FakeReimbursementTransaction from './FakeReimbursementTransaction';
import ReinbursementTransaction from './ReinbursementTransaction';
import ReimbursedInfo from './RembursementInfo';
import {ConditionalTooltip} from '../../../../../../../../components/tooltip/ConditionalTooltip';

export const ReimbursementMainSection = ({
    perfectMatches,
    unpaidAmount,
    onSelectTransaction,
    activeTransaction,
    setActiveTransaction,
}) => {
    return (
        <>
            {perfectMatches.map((transaction, index) => {
                if (transaction?.selected) {
                    return null;
                }
                return (
                    <div
                        key={transaction.id}
                    >
                        <ReinbursementTransaction
                            unpaidAmount={unpaidAmount}
                            transaction={transaction}
                            onSelectTransaction={onSelectTransaction}
                            isTheBest={!index}
                            activeTransaction={activeTransaction}
                            setActiveTransaction={setActiveTransaction}
                            isCategorised={false}
                        />
                    </div>
                );
            })}
        </>
    );
};

ReimbursementMainSection.propTypes = {
    perfectMatches: PropTypes.array.isRequired,
    unpaidAmount: PropTypes.string.isRequired,
    onSelectTransaction: PropTypes.func.isRequired,
    activeTransaction: PropTypes.string.isRequired,
    setActiveTransaction: PropTypes.func.isRequired,
};

export const ReimbursementMainSectionTitle = () => {
    const {t} = useTranslation('bank');

    return (
        <Box sx={{
            display: 'flex',
            gap: 0.5,
            alignItems: 'center',
        }}
        >
            {t('categorisation.rembursement.mainSectionTitle')}

            <ConditionalTooltip
                tooltip={t('categorisation.rembursement.mainSecitonTitleTooltip')}
            >
                <HelpOutlineIcon
                    fontSize="small"
                    sx={{
                        color: 'v2.blueGray.300',
                    }}
                />
            </ConditionalTooltip>
        </Box>
    );
};

export const ReimbursementAdditionalSection = ({
    suggestedMatches,
    unpaidAmount,
    onSelectTransaction,
    activeTransaction,
    setActiveTransaction,
}) => {
    return (
        <>
            {suggestedMatches.map(transaction => {
                if (transaction?.selected) {
                    return null;
                }
                return (
                    <div
                        key={transaction.id}
                    >
                        <ReinbursementTransaction
                            unpaidAmount={unpaidAmount}
                            transaction={transaction}
                            onSelectTransaction={onSelectTransaction}
                            isTheBest={false}
                            activeTransaction={activeTransaction}
                            setActiveTransaction={setActiveTransaction}
                            isCategorised={false}
                        />
                    </div>
                );
            })}
        </>
    );
};

ReimbursementAdditionalSection.propTypes = {
    suggestedMatches: PropTypes.array.isRequired,
    unpaidAmount: PropTypes.string.isRequired,
    onSelectTransaction: PropTypes.func.isRequired,
    activeTransaction: PropTypes.string.isRequired,
    setActiveTransaction: PropTypes.func.isRequired,
};

export const ReimbursementResult = ({
    selectedReimbursementTransactionsObj,
    unpaidAmount,
    onSelectTransaction,
    activeTransaction,
    setActiveTransaction,
    amount,
    details,
    paidAmount,
}) => {
    const {t} = useTranslation('bank');
    const selectedCount = selectedReimbursementTransactionsObj.length;

    return (
        <>
            <ReimbursedInfo
                amount={amount}
                allocated={paidAmount}
                isCategorised={unpaidAmount === 0}
            />

            <Box sx={{
                display: 'flex',
                gap: 1.5,
                alignItems: 'center',
            }}
            >
                <Typography sx={{
                    fontWeight: 500,
                    letterSpacing: '0.1px',
                }}
                >
                    {t('transactions.selectedReimbursements')}
                </Typography>
                <Badge
                    showZero
                    badgeContent={selectedCount}
                    color={!selectedCount ? 'warning_v2' : 'v2PrimaryShades50'}
                    sx={{
                        color: 'white !important',
                        ml: 0.5,
                        fontWeight: 500,
                        fontSize: theme => theme.typography.pxToRem(12),
                    }}
                />
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: selectedCount === 0 ? -1 : 1,
            }}
            >
                {selectedReimbursementTransactionsObj.map((transaction, index) => {
                    if (transaction?.isFakeReimbursement) {
                        return (
                            <FakeReimbursementTransaction
                                details={details}
                                category={transaction?.category}
                                amount={transaction?.reimbursedAmount}
                                hasDelete={true}
                            />
                        );
                    }

                    return (
                        <ReinbursementTransaction
                            key={transaction.id}
                            unpaidAmount={unpaidAmount}
                            transaction={transaction}
                            onSelectTransaction={onSelectTransaction}
                            isTheBest={!index}
                            activeTransaction={activeTransaction}
                            setActiveTransaction={setActiveTransaction}
                            hasDnd={false}
                            isCategorised={false} // TODO Check
                        />
                    );
                })}
            </Box>
        </>
    );
};

ReimbursementResult.propTypes = {
    unpaidAmount: PropTypes.string.isRequired,
    onSelectTransaction: PropTypes.func.isRequired,
    activeTransaction: PropTypes.string.isRequired,
    setActiveTransaction: PropTypes.func.isRequired,
    selectedReimbursementTransactionsObj: PropTypes.array.isRequired,
    amount: PropTypes.string.isRequired,
    details: PropTypes.object.isRequired,
    paidAmount: PropTypes.string.isRequired,
};

export const ReimbursementBottomAlert = ({setIsFakeReimbursementOpen}) => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Alert
            icon={<CategoryIcon />}
            action={isMobileSize ? null : (
                <Button
                    onClick={() => setIsFakeReimbursementOpen(true)}
                    sx={{
                        width: '264px',
                        color: 'v2.light.info.main',
                    }}
                    color="inherit"
                    size="small"
                    endIcon={<ArrowForwardIcon />}
                >
                    {t('categorisation.rembursement.fakeReimbursementButton')}
                </Button>
            )}
            sx={{
                borderRadius: '16px',
                display: 'flex',
                alignItems: 'center',
                fontSize: theme => theme.typography.pxToRem(14),
                mb: isMobileSize ? 2 : 0,
            }}
            variant="outlined"
            severity="info"
        >
            {t('categorisation.rembursement.fakeReimbursementAlertText')}
            {isMobileSize && (
                <Button
                    onClick={() => setIsFakeReimbursementOpen(true)}
                    sx={{
                        width: '264px',
                        color: 'v2.light.info.main',
                        ml: -3,
                    }}
                    color="inherit"
                    size="small"
                    endIcon={<ArrowForwardIcon />}
                >
                    {t('categorisation.rembursement.fakeReimbursementButton')}
                </Button>
            )}
        </Alert>
    );
};

ReimbursementBottomAlert.propTypes = {
    setIsFakeReimbursementOpen: PropTypes.func.isRequired,
};
