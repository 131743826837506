import {useMediaQuery} from '@mui/material';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import AccountStatementsDataTableHeader from './datatable/AccountStatementsDataTableHeader';
import DataTable from '../../../../../v1/components/ui-kit/DataTable/DataTable';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {RibAndDocumentsSelector} from '../store/ribAndDocuments.selector';
import {getBankAccountStatementsDataTableConfig} from '../utils/bank-account-statement-datatable';
import {BANK_ACCOUNT_STATEMENTS_DATA_TABLE_ID, DEFAULT_ACCOUNT_STATEMENTS_ROWS_PER_PAGE} from '../utils/constants';

const BankAccountStatements = () => {
    const {t} = useTranslation('bankRibAndDocuments');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(
            LoadingTypes.GET_BANK_ACCOUNT_STATEMENTS,
        ),
    );

    const columnConfig = useMemo(
        () => getBankAccountStatementsDataTableConfig(t, isMobileSize),
        [t, isMobileSize],
    );

    return (
        <DataTable
            tableId={BANK_ACCOUNT_STATEMENTS_DATA_TABLE_ID}
            // TODO: Sorting does not work if you set defaultSortByKey
            // defaultSortByKey={BANK_ACCOUNT_STATEMENTS_SORT_VALUES_ENUM.openingDate}
            // defaultSortDirection="desc"
            dataSelector={RibAndDocumentsSelector.createBankAccountStatementsSelector()}
            missingDataMessage={t('accountStatementsTable.noStatementsAvailable')}
            columnConfig={columnConfig}
            isLoading={isLoading !== false}
            hasPagination
            defaultRowsPerPage={DEFAULT_ACCOUNT_STATEMENTS_ROWS_PER_PAGE}
            customHeader={<AccountStatementsDataTableHeader />}
        />
    );
};

export default BankAccountStatements;
