import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {FILTER_ANCHORS, IN_HEADER} from '../../util/constants';
import {AccountBalanceFilter} from '../account-balance/AccountBalanceFilter';
import {AccountBalancePopovers} from '../account-balance/AccountBalancePopovers';
import {AccountBalanceScrollHeader} from '../account-balance/AccountBalanceScrollHeader';

const FiltersWrapper = ({
    isSidebarOpened,
    totalLoadedIds,
    topOffset,
    balance,
    filter,
    setFilter,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const scrollFunction = useCallback(debounce(() => {
        if (anchorEl && anchorEl.id) {
            let anchorId = null;
            let nextAnchor = null;

            if (anchorEl.id.includes(FILTER_ANCHORS.DATES)) {
                anchorId = FILTER_ANCHORS.DATES;
            } else if (anchorEl.id.includes(FILTER_ANCHORS.NON_CATEGIROZED)) {
                anchorId = FILTER_ANCHORS.NON_CATEGIROZED;
            } else if (anchorEl.id.includes(FILTER_ANCHORS.TYPE)) {
                anchorId = FILTER_ANCHORS.TYPE;
            }

            // TODO: use Refs
            if (document.body.scrollTop > topOffset || document.documentElement.scrollTop > topOffset) {
                // sticky header
                if (!anchorEl.id.includes(IN_HEADER)) {
                    nextAnchor = document.querySelector(`#${anchorId}-${IN_HEADER}`);
                }
            } else if (anchorEl.id.includes(IN_HEADER)) {
                nextAnchor = document.querySelector(`#${anchorId}`);
            }

            if (nextAnchor) {
                setAnchorEl(nextAnchor);
            }
        }
    }, 100), [anchorEl, topOffset]);

    useEffect(() => {
        window.addEventListener('scroll', scrollFunction);
        return () => {
            window.removeEventListener('scroll', scrollFunction);
        };
        // eslint-disable-next-line
    }, [anchorEl]);

    const accountBalanceFilter = isInHeader => (
        <AccountBalanceFilter
            filter={filter}
            setFilter={setFilter}
            setAnchorEl={setAnchorEl}
            isInHeader={isInHeader}
            anchorEl={anchorEl}
        />
    );

    let isOpenDrPicker = false;
    let isOpenNonCategorized = false;
    let isOpenType = false;

    if (anchorEl && anchorEl.id) {
        if (anchorEl.id.includes(FILTER_ANCHORS.DATES)) {
            isOpenDrPicker = true;
        } else if (anchorEl.id.includes(FILTER_ANCHORS.NON_CATEGIROZED)) {
            isOpenNonCategorized = true;
        } else if (anchorEl.id.includes(FILTER_ANCHORS.TYPE)) {
            isOpenType = true;
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AccountBalanceScrollHeader
                isSidebarOpened={isSidebarOpened}
                totalLoadedIds={totalLoadedIds}
                balance={balance}
                topOffset={topOffset}
                accountBalanceFilter={accountBalanceFilter(true)}
            />

            <AccountBalancePopovers
                isOpenDrPicker={isOpenDrPicker}
                anchorEl={anchorEl}
                handleClose={handleClose}
                isOpenNonCategorized={isOpenNonCategorized}
                isOpenType={isOpenType}
                filter={filter}
                setFilter={setFilter}
            />

            {accountBalanceFilter(false)}
        </>
    );
};

FiltersWrapper.propTypes = {
    isSidebarOpened: PropTypes.bool,
    totalLoadedIds: PropTypes.number,
    topOffset: PropTypes.number.isRequired,
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};

FiltersWrapper.defaultProps = {
    isSidebarOpened: false,
    totalLoadedIds: 0,
};

export default FiltersWrapper;
