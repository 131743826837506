import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import GradingIcon from '@mui/icons-material/Grading';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import {CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {useDocumentStatusStyles} from './styles';
import {DOCUMENT_STATUSES} from '../../../config/constants/documentConstants';

const DocumentStatus = ({status}) => {
    const styles = useDocumentStatusStyles();

    const {t} = useTranslation('documents');

    const {icon, label} = useMemo(() => {
        switch (status) {
            case DOCUMENT_STATUSES.ERROR:
                return {
                    icon: <ErrorIcon className={styles.errorIcon} />,
                    label: t('documentsDatabaseStatuses.ERROR'),
                };

            case DOCUMENT_STATUSES.PENDING_SIGNING:
                return {
                    icon: <HourglassTopIcon className={styles.pendingSigningIcon} />,
                    label: t('documentsDatabaseStatuses.PENDING_SIGNING'),
                };

            case DOCUMENT_STATUSES.PENDING_COMPANY_ID:
                return {
                    icon: <WatchLaterIcon className={styles.pendingCompanyIdIcon} />,
                    label: t('documentsDatabaseStatuses.PENDING_COMPANY_ID'),
                };

            case DOCUMENT_STATUSES.DONE:
                return {
                    icon: <CheckCircleIcon className={styles.doneIcon} />,
                    label: t('documentsDatabaseStatuses.DONE'),
                };

            case DOCUMENT_STATUSES.GENERATING:
                return {
                    icon: <CircularProgress size={18} className={styles.generatingIcon} />,
                    label: t('documentsDatabaseStatuses.GENERATING'),
                };

            case DOCUMENT_STATUSES.SIGNED:
                return {
                    icon: <VerifiedUserIcon className={styles.signedIcon} />,
                    label: t('documentsDatabaseStatuses.SIGNED'),
                };

            case DOCUMENT_STATUSES.GENERATED:
                return {
                    icon: <GradingIcon className={styles.generatedIcon} />,
                    label: t('documentsDatabaseStatuses.GENERATED'),
                };

            default:
                return null;
        }
    }, [status, styles, t]);

    if (!icon || !label) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.iconContainer}>
                {icon}
            </div>

            <div className={styles.label}>
                {label}
            </div>
        </div>
    );
};

DocumentStatus.propTypes = {
    status: PropTypes.oneOf(Object.values(DOCUMENT_STATUSES)).isRequired,
};

export default DocumentStatus;
