export const ANIMATION_TYPE = {
    'MAIN': 'MAIN',
    'SECONDARY': 'SECONDARY',
};

export const BANNER_ANIMATION_DELAY = 400;

export const FORM_ANIMATION_DELAY = 150;

export const FORM_ANIMATION_LENGTH = 300;
