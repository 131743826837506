import DocumentNameCell from './DocumentNameCell/DocumentNameCell';
import DocumentNameCellTrainingApproved from './DocumentNameCell/DocumentNameCellTrainingApproved';
import DocumentStatusCell from './DocumentStatusCell/DocumentStatusCell';
import DocumentStatusCellTraining from './DocumentStatusCell/DocumentStatusCellTraining';
import DocumentRowActions from '../../../features/training/components/general/DocumentRowAction';

export const getDocumentGenerationTableConfig = translate => ([
    {
        key: 'docName',
        title: translate('documentsDatabaseTable.document'),
        isSortable: true,
        width: '40%',
        component: DocumentNameCell,
    },
    {
        key: 'category',
        title: translate('documentsDatabaseTable.category'),
        isSortable: true,
        width: '25%',
        componentProps: {
            formatter: rowData => translate(`documentsDatabaseCategories.${rowData.category}`),
        },
    },
    {
        key: 'status',
        title: translate('documentsDatabaseTable.status'),
        isSortable: true,
        width: '25%',
        component: DocumentStatusCell,
    },
    // {
    //     key: 'actions',
    //     title: translate('documentsDatabaseTable.actions'),
    //     isSortable: false,
    //     additionalCellProps: {
    //         align: 'right',
    //     },
    //     shouldPreserveWhiteSpace: true,
    //     component: DocumentGenerationRowActions,
    // },
]);

export const getDocumentGenerationTrainingTableConfig = translate => ([
    {
        key: 'docName',
        title: translate('documentsDatabaseTable.document'),
        isSortable: false,
        width: '70%',
        component: DocumentNameCell,
    },
    {
        key: 'status',
        title: translate('documentsDatabaseTable.status'),
        isSortable: false,
        width: '30%',
        component: DocumentStatusCellTraining,
    },
]);

export const getDocumentDownloadTrainingTableConfig = translate => ([
    {
        key: 'docName',
        title: translate('documentsDatabaseTable.document'),
        isSortable: false,
        width: '70%',
        component: DocumentNameCell,
    },
    {
        key: 'status',
        title: translate('documentsDatabaseTable.status'),
        isSortable: false,
        width: '30%',
        component: DocumentStatusCellTraining,
    },
    {
        key: 'actions',
        title: translate('documentsDatabaseTable.actions'),
        isSortable: false,
        additionalCellProps: {
            align: 'right',
        },
        shouldPreserveWhiteSpace: true,
        component: DocumentRowActions,
    },
]);

export const getDocumentDownloadTrainingValidatedTableConfig = translate => ([
    {
        key: 'docName',
        title: translate('documentsDatabaseTable.document'),
        isSortable: false,
        width: '70%',
        component: DocumentNameCellTrainingApproved,
    },
    {
        key: 'status',
        title: translate('documentsDatabaseTable.status'),
        isSortable: false,
        width: '30%',
        component: DocumentStatusCellTraining,
    },
    {
        key: 'actions',
        title: translate('documentsDatabaseTable.actions'),
        isSortable: false,
        additionalCellProps: {
            align: 'right',
        },
        shouldPreserveWhiteSpace: true,
        component: DocumentRowActions,
    },
]);
