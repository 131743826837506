import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {REMOTE_JOB_TYPE} from '../../utls/constants';


export const OpportunityDescription = ({control, errors, defaultFieldProps, hasFiles}) => {
    const {t} = useTranslation('opportunity');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{mb: 0, mt: 3}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Box sx={{mb: 3}}>
                    <FormControl component="fieldset">
                        <Controller
                            aria-label="remote"
                            control={control}
                            name="remote"
                            render={({field}) => (
                                <RadioGroup {...field} row sx={{display: 'inline'}}>
                                    <FormLabel sx={{fontWeight: 600, mr: 3, color: '#000000'}}>
                                        Remote:
                                    </FormLabel>

                                    {isMobileSize && <br />}

                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        label={t('submitOpportunity.none')}
                                        value={REMOTE_JOB_TYPE.NONE}
                                    />
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        label={t('submitOpportunity.partial')}
                                        value={REMOTE_JOB_TYPE.PARTIAL}
                                    />
                                    <FormControlLabel
                                        control={<Radio color="primary" />}
                                        label={t('submitOpportunity.full')}
                                        value={REMOTE_JOB_TYPE.FULL}
                                    />
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                </Box>

                <Divider sx={{borderStyle: 'dashed', borderColor: '#BDBDBD'}} />

                <Box sx={{mb: 2, mt: 3}}>
                    <Controller
                        control={control}
                        name="description"
                        render={({field}) => (
                            <TextField
                                label={t('submitOpportunity.description') + (hasFiles ? '' : '*')}
                                error={!!errors.description}
                                helperText={errors.description?.message}
                                multiline
                                rows={6}
                                {...field}
                                {...defaultFieldProps}
                            />
                        )}
                    />
                </Box>
            </Box>
        </Box>
    );
};

OpportunityDescription.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    defaultFieldProps: PropTypes.object.isRequired,
    hasFiles: PropTypes.bool.isRequired,
};
