import Actions from '../components/datatable/Actions';
import ClientName from '../components/datatable/ClientName';
import Description from '../components/datatable/Description';
import Location from '../components/datatable/Location';
import NumberOfBids from '../components/datatable/NumberOfBids';
import OpportunityForMobile from '../components/datatable/OpportunityForMobile';
import OtherOpportunityForMobile from '../components/datatable/OtherOpportunityForMobile';
import Rate from '../components/datatable/Rate';
import RequiredProfile from '../components/datatable/RequiredProfile';

const commonTableConfig = t => {
    return [
        {
            key: 'clientName',
            title: t('element.header.clientName'),
            width: `15%`,
            isSortable: true,
            component: ClientName,
        },
        {
            key: 'createdAt',
            title: t('element.header.date'),
            componentProps: {
                isDate: true,
                dateFormat: 'DD/MM/YYYY',
            },
            width: `10%`,
            isSortable: true,
        },
        {
            key: 'jobType',
            title: t('element.header.profile'),
            width: `15%`,
            component: RequiredProfile,
        },
        {
            key: 'dailyRateHigh',
            title: t('element.header.tjm'),
            shouldTextWrap: false,
            width: `10%`,
            component: Rate,
            isSortable: true,
        },
        {
            key: 'startDate',
            title: t('element.header.mission'),
            width: `10%`,
            componentProps: {
                isDate: true,
                dateFormat: 'MMMM YYYY',
                noInputText: '—',
            },
            isSortable: true,
        },
        {
            key: 'jobLocation',
            title: t('element.header.location'),
            width: `15%`,
            component: Location,
        },
        {
            key: 'missionDescriptionDocId',
            title: t('element.header.files'),
            width: `10%`,
            component: Description,
        },
    ];
};

export const getOpportunityDataTableConfig = (t, isMobileSize) => {
    if (isMobileSize) {
        return [
            {
                key: 'clientName',
                title: '',
                width: `100%`,
                component: OpportunityForMobile,
            },
        ];
    }

    return [
        ...commonTableConfig(t),
        {
            key: 'numberOfBids',
            title: t('element.header.number'),
            width: `10%`,
            component: NumberOfBids,
        },
    ];
};

export const getOtherOpportunityDataTableConfig = (t, isMobileSize) => {
    if (isMobileSize) {
        return [
            {
                key: 'clientName',
                title: '',
                width: `100%`,
                component: OtherOpportunityForMobile,
            },
        ];
    }

    return [
        ...commonTableConfig(t),
        {
            key: 'accepted',
            title: t('element.header.actions'),
            width: `15%`,
            component: Actions,
        },
    ];
};
