import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Slide, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import CapitalDepositWaitingNotarySvg from '../../../../../../../assets/svg/bank/capital-deposit-waiting-notary.svg';
import BankInformationLayout from '../../../../../../../layout/BankInformationLayout/BankInformationLayout';
import {AnimationActions} from '../../../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../../../animations/utils/constants';

const CapitalDepositWaitingNotary = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation('freelancerOnboarding');

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    return (
        <BankInformationLayout
            header={t('capitalDepositWaitingNotary.headline')}
            subTitle={t('capitalDepositWaitingNotary.subtitle')}
            Button={(
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 3,
                }}
                >
                    <Button
                        color="secondary"
                        variant="contained"
                        disabled
                        endIcon={<ArrowForwardIcon />}
                    >
                        {t('capitalValidation.continueButton')}
                    </Button>
                </Box>
            )}
        >
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '600px',
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    >
                        <Box>
                            <img src={CapitalDepositWaitingNotarySvg} alt="capital deposit waiting notary" />
                        </Box>

                        <Typography sx={{mt: 2}} variant="h4">
                            {t('capitalDepositWaitingNotary.title')}
                        </Typography>

                        <Typography sx={{mt: 2, color: 'text_v2.secondary'}}>
                            {t('capitalDepositWaitingNotary.text1')}
                        </Typography>

                        <Typography sx={{mt: 1, color: 'text_v2.secondary'}}>
                            {t('capitalDepositWaitingNotary.text2')}
                        </Typography>
                    </Box>
                </Box>
            </Slide>
        </BankInformationLayout>
    );
};

export default CapitalDepositWaitingNotary;
