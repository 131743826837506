import {CONFIG} from '../config';

export const Debug = {
    debug: (module, message, ...data) => {
        if (CONFIG.NODE_ENVIRONMENT === 'development') {
            // eslint-disable-next-line no-console
            console.debug(`[${module}]: ${message || 'Debug: '}`, ...data);
        }
    },
    warn: (module, message, ...data) => {
        // eslint-disable-next-line no-console
        if (CONFIG.NODE_ENVIRONMENT === 'development') {
            // eslint-disable-next-line no-console
            console.warn(`[${module}]: ${message || 'Warning: '}`, ...data);
        }
    },
    error: (module, message, ...data) => {
        // eslint-disable-next-line no-console
        if (CONFIG.NODE_ENVIRONMENT === 'development') {
            // eslint-disable-next-line no-console
            console.error(`[${module}]: ${message || 'Error: '}`, ...data);
        }
    },
};
