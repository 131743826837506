import makeStyles from '@mui/styles/makeStyles';

export const useDataTableRowActionsStyles = makeStyles(theme => ({
    container: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    actionButton: {
        'padding': theme.spacing(1),
        'margin': theme.spacing(0, 0.5),
        '&:first-child': {
            marginLeft: 0,
        },
        '&:last-child': {
            marginRight: 0,
        },
    },
    menuLink: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(14),
    },
}));
