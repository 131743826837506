import UAParser from 'ua-parser-js';

export const emailRegexp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export const multipleEmailRegexp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})((,(\s?)([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,}))*$/i;

export const checkEmailValidity = email => emailRegexp.test(email);

export const phoneRegexp = /\d{2} \d{2} \d{2} \d{2} \d{2}/;

export const internationalPhoneRegExp = /^\+\d{7,15}$/;

// TODO:HIGH: Extract this to separate file.
export const ARCHIVING_STRATEGIES = {
    PROFESSIONAL_EXPENSES_ARCHIVING_STRATEGY: 'PROFESSIONAL_EXPENSES_ARCHIVING_STRATEGY',
    PERSONAL_EXPENSES_ARCHIVING_STRATEGY: 'PERSONAL_EXPENSES_ARCHIVING_STRATEGY',
    BUSINESS_KM_RECAP_ARCHIVING_STRATEGY: 'BUSINESS_KM_RECAP_ARCHIVING_STRATEGY',
    INVOICES_ARCHIVING_STRATEGY: 'INVOICES_ARCHIVING_STRATEGY',
};

const userAgent = new UAParser();
const browser = userAgent.getBrowser();

export const isMobileSafari = browser.name === 'Mobile Safari';

export const isSafari = browser.name === 'Safari';

export const isSafari14 = (isMobileSafari || isSafari) && parseFloat(browser.version) >= 14.1;

export const getSeparator = (locale, separatorType) => {
    let numberFormat;

    if (Intl && Intl.NumberFormat) {
        numberFormat = Intl.NumberFormat(locale);
    }

    if (numberFormat && numberFormat.hasOwnProperty('formatToParts')) {
        return numberFormat.formatToParts(1000.1)
            .find(part => part.type === separatorType)
            .value;
    }

    if (separatorType === 'thousand') {
        return (1000.1).toLocaleString(locale).substring(1, 2);
    }

    return (1.1).toLocaleString(locale).substring(1, 2);
};

const getNavigatorLanguage = () => ((navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language || navigator.browserLanguage || 'en');

export const getDefaultLocale = () => {
    const navigatorLanguage = getNavigatorLanguage();
    const locale = navigatorLanguage.substring(0, 2).toLowerCase();

    if (locale === 'fr') {
        return locale;
    }

    return 'en';
};

export const numberWithSpaces = x => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
};

export const getNumberSegments = (x, delimiter = '.') => {
    const segments = x.split(delimiter);
    if (segments?.length > 0) {
        return {
            decimal: segments[1],
            integer: segments[0],
        };
    }
    return x;
};

export const currencyToSign = currency => {
    if (currency === 'eur') {
        return '€';
    }

    return '€';
};

export const MAX_SUPPORTED_NUMBER_IN_FORM = 100000000;
