import {LoadingActionTypes} from './loading.action-type';
import {LoadingTypes} from '../utils/loading-types';

const initialState = {
    [LoadingTypes.APPLICATION_DATA]: true,
};

export const loadingReducer = (state = initialState, {type, payload}) => {
    if (type === LoadingActionTypes.SET_LOADING) {
        return {
            ...state,
            [payload.loadingType]: payload.loadingState,
        };
    }

    return state;
};
