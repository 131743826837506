import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AnimationActions} from '../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../animations/store/animations.selector';
import {TrainingActions} from '../../store/training.action';

const styleTypes = {
    NEXT: 'next',
    COMPLETED: 'completed',
    COMPLETED_ACTIVE: 'completedAndActive',
    INACTIVE: 'inactive',
};

const style = {
    stepStyle: {
        height: '40px',
        borderRadius: '8px 0px 0px 8px',
        ml: 2,
        mr: 0,
        display: 'flex',
        alignItems: 'center',
    },
    stepText: {
        whiteSpace: 'nowrap',
    },
    stepNumber: {
        width: 'calc(24px)',
        height: '24px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        mx: 1,
    },
};

style[styleTypes.NEXT] = {
    step: {
        ...style.stepStyle,
        'background': '#FFFFFF',
        'boxShadow': '0px 2px 1px -1px rgba(11, 35, 51, 0.05)',
        'filter': 'drop-shadow(0px 1px 1px rgba(11, 35, 51, 0.05)) drop-shadow(0px 1px 3px rgba(11, 35, 51, 0.07))',
        'cursor': 'pointer',
        '::after': {
            'width': '12px',
            'height': '40px',
            'content': `url("${window.location.origin}/right-triangle.svg")`,
            'position': 'relative',
            'left': '12px',
        },
    },
    number: {
        ...style.stepNumber,
        backgroundColor: '#3779BE',
        color: 'white',
    },
    elevation: 1,
};

style[styleTypes.COMPLETED] = {
    step: {
        ...style.stepStyle,
        'background': '#FFFFFF',
        'color': '#4CAF50',
        'boxShadow': '0px 2px 1px -1px rgba(11, 35, 51, 0.05)',
        'filter': 'drop-shadow(0px 1px 1px rgba(11, 35, 51, 0.05)) drop-shadow(0px 1px 3px rgba(11, 35, 51, 0.07))',
        'cursor': 'pointer',
        '::after': {
            'width': '12px',
            'height': '40px',
            'content': `url("${window.location.origin}/right-triangle.svg")`,
            'position': 'relative',
            'left': '12px',
        },
    },
    number: {
        ...style.stepNumber,
        backgroundColor: '#4CAF50',
        color: 'white',
    },
};

style[styleTypes.COMPLETED_ACTIVE] = {
    step: {
        ...style.stepStyle,
        'background': 'rgba(46, 125, 50, 0.12)',
        'color': '#2E7D32',
        'boxShadow': '0px 2px 1px -1px rgba(11, 35, 51, 0.12)',
        'filter': 'drop-shadow(0px 1px 1px rgba(11, 35, 51, 0.05)) drop-shadow(0px 1px 3px rgba(11, 35, 51, 0.12))',
        'cursor': 'pointer',
        '::after': {
            'width': '12px',
            'height': '40px',
            'content': `url("${window.location.origin}/right-triangle-green.svg")`,
            'opacity': 0.12,
            'position': 'relative',
            'filter': 'drop-shadow(0px 1px 0px rgba(11, 35, 51, 0.5))',
            'left': '12px',
        },
    },
    number: {
        ...style.stepNumber,
        backgroundColor: '#2E7D32',
        color: 'white',
    },
};

style[styleTypes.INACTIVE] = {
    step: {
        ...style.stepStyle,
        'background': `rgba(25, 118, 210, 0.04)`,
        '::after': {
            'filter': 'rgba(11, 35, 51, 0.05)',
            'width': '12px',
            'height': '40px',
            'content': `url("${window.location.origin}/right-triangle-gray.svg")`,
            'position': 'relative',
            'left': '12px',
        },
    },
    number: {
        ...style.stepNumber,
        backgroundColor: 'rgba(25, 118, 210, 0.12)',
        color: '#03A9F4',
    },
};

export const TrainingStep = ({stepNumber, value, isCompleted, isNext, isSelected}) => {
    const {t} = useTranslation('training');
    const dispatch = useDispatch();

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const handleOnClick = () => {
        if (isSelected) {
            return;
        }
        if (isCompleted || isNext) {
            if (isAnimationActive) {
                dispatch(AnimationActions.storeNextDispatch(
                    TrainingActions.setTrainingStep(value),
                ));
                dispatch(AnimationActions.setIsAnimationActive(false));
            } else {
                dispatch(AnimationActions.setIsAnimationActive(true));
                dispatch(TrainingActions.setTrainingStep(value));
            }
        }
    };

    let styleSwticher = style[styleTypes.INACTIVE];
    if (isCompleted && isSelected) {
        styleSwticher = style[styleTypes.COMPLETED_ACTIVE];
    } else if (isCompleted) {
        styleSwticher = style[styleTypes.COMPLETED];
    } else if (isNext) {
        styleSwticher = style[styleTypes.NEXT];
    }

    return (
        <Box
            sx={{...styleSwticher.step, cursor: isSelected ? 'default' : 'pointer'}}
            onClick={handleOnClick}
        >
            <Box>
                <Box sx={styleSwticher.number}>
                    {stepNumber}
                </Box>
            </Box>
            <Box sx={style.stepText}>
                {t(`stepper.${value}`)}
            </Box>
        </Box>
    );
};

TrainingStep.propTypes = {
    stepNumber: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    isCompleted: PropTypes.bool.isRequired,
    isNext: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
};
