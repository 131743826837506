import {combineReducers} from 'redux';
import {assignedFreelancersReducer} from './assignedFreelancers/assignedFreelancers.reducer';
import {assignFreelancersFormReducer} from './assignFreelancersForm/assignFreelancersForm.reducer';
import {availableFreelancersReducer} from './availableFreelancers/availableFreelancers.reducer';
import {changePasswordFormReducer} from './changePasswordForm/changePasswordForm.reducer';
import {coachingInformationReducer} from './coachingInformation/coachingInformation.reducer';
import * as actions from './user.actions';
import {userListReducer} from '../../../features/user-list/store/userList.reducer';
import {LOCATION_CHANGED} from '../../../lib/router/connected-router-saga';
import {createReducer} from '../createReducer';

const singleUserReducer = (state = null, action) => {
    if (action.type === actions.STORE_USER) {
        return action.payload;
    }

    // Reset to initial state when user leaves the view.
    if (action.type === LOCATION_CHANGED && !action.payload.location.pathname.includes(state?.id)) {
        return null;
    }

    return state;
};

const isLoadingUserReducer = (state = true, action) => {
    if (action.type === actions.SET_IS_LOADING_USER) {
        return action.payload;
    }

    // Reset to initial state when user leaves the view.
    if (action.type === LOCATION_CHANGED && !action.payload.location.pathname.includes('/users')) {
        return true;
    }

    return state;
};

export const userReducer = combineReducers({
    assignedFreelancers: assignedFreelancersReducer,
    assignFreelancersForm: assignFreelancersFormReducer,
    coachingInformation: coachingInformationReducer,
    availableFreelancers: availableFreelancersReducer,
    changePasswordForm: changePasswordFormReducer,
    coachAccounts: createReducer([], actions.STORE_COACH_ACCOUNTS),
    isLoadingLoggedInUser: createReducer(true, actions.SET_IS_LOADING_LOGGED_IN_USER, true),
    isLoadingUser: isLoadingUserReducer,
    isUpdatingCanSetupCompany: createReducer(false, actions.SET_IS_UPDATING_CAN_SETUP_COMPANY),
    adminMustEnterDataBeforeSetupCompany: createReducer(false, actions.SET_ADMIN_MUST_ENTER_DATA_BEFORE_SETUP_COMPANY),
    isUpdatingLoggedInUser: createReducer(false, actions.SET_IS_UPDATING_LOGGED_IN_USER),
    isUpdatingUser: createReducer(false, actions.SET_IS_UPDATING_USER),
    isUpdatingUserActiveStatus: createReducer(false, actions.SET_IS_UPDATING_USER_ACTIVE_STATUS),
    loggedInUser: createReducer(null, actions.STORE_LOGGED_IN_USER),
    singleUser: singleUserReducer,
    userList: userListReducer,
});
