import moment from 'moment';
import {all, call, delay, put, select, takeEvery} from 'redux-saga/effects';
import {BeneficiariesActions} from './beneficiaries.actions';
import {BeneficiariesSelector} from './beneficiaries.selector';
import {TransfersActionType} from './transfers.action-type';
import {TransfersActions} from './transfers.actions';
import {TransfersSelector} from './transfers.selector';
import {Toast} from '../../../../../lib/toast';
import {Debug} from '../../../../../utils/debug';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {SseActions} from '../../../../server-side-events/store/sse.actions';
import {SSESelector} from '../../../../server-side-events/store/sse.selector';
import {BankActions} from '../../../store/bank.action';
// eslint-disable-next-line import/no-cycle
import {getHiwayAccountId} from '../../../store/bank.loader.saga';
import {BankSelector} from '../../../store/bank.selector';
import {TransactionsActions} from '../../account-balance/store/transactions.action';
import {TransactionsSelector} from '../../account-balance/store/transactions.selector';
import {TransfersApi} from '../api/transfers.api';

const getRecurringTransfersListFlow = function* (accountId) {
    yield put(LoadingActions.setLoading(LoadingTypes.LOAD_TRANSFERS, true));

    try {
        const response = yield call(TransfersApi.getRecurringTransfersList, {
            accountId,
        });

        yield put(TransfersActions.storeList(response));

        yield put(LoadingActions.setLoading(LoadingTypes.LOAD_TRANSFERS, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.LOAD_TRANSFERS, false));
    }
};

const createTransfersFlow = function* (accountId, data) {
    yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

    try {
        let response;
        if (data.firstExecutionDate) {
            // Recurring
            const firstExecutionDate = moment(data.firstExecutionDate).format('DD/MM/YYYY');
            const lastExecutionDate = data.lastExecutionDate
                ? moment(data.lastExecutionDate).format('DD/MM/YYYY')
                : undefined;

            response = yield call(TransfersApi.createRecurringTransfers, {
                accountId,
                data: {
                    ...data,
                    mccKey: data.mccKey || undefined,
                    description: data.description || undefined,
                    firstExecutionDate: firstExecutionDate,
                    lastExecutionDate: lastExecutionDate,
                    fileList: undefined,
                },
            });
        } else {
            // Single
            response = yield call(TransfersApi.createSingleTransfers, {
                accountId,
                data: {
                    ...data,
                    mccKey: data.mccKey || undefined,
                    description: data.description || undefined,
                    fileList: undefined,
                },
            });
        }

        if (data.fileList?.length > 0 && response.id) {
            yield call(TransfersApi.uploadTransferFile, {
                accountId,
                id: response.id,
                files: data.fileList,
            });
        }

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        if (response.consentUrl) {
            window.location = response.consentUrl;
        }

        Toast.success('genericSuccessSave');
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.error('genericError');
    }
};

const getTransferDetailsFlow = function* (accountId, data) {
    yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

    try {
        const response = yield call(TransfersApi.getTransferDetails, {
            accountId,
            id: data.id,
        });

        yield put(TransfersActions.storeTransfer(response));

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.error('genericError');
    }
};

const updateTransfersFlow = function* (accountId, data) {
    yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

    try {
        const transfer = yield call(TransfersApi.updateTransfers, {
            accountId,
            id: data.id,
            data: {...data, id: undefined, fileList: undefined},
        });

        // Store transfer changes in the list
        const transfers = yield select(TransfersSelector.selectList);
        const oldTransferIndex = transfers.findIndex(oldTransfer => oldTransfer.id === transfer.id);
        const oldTransfer = yield select(TransfersSelector.selectTransfer);

        yield put(TransfersActions.storeTransfer({...transfer, documents: oldTransfer?.documents}));


        const newTransfers = [...transfers];
        newTransfers.splice(oldTransferIndex, 1, transfer);
        yield put(TransfersActions.storeList(newTransfers));

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.success('genericSuccessSave');
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.error('genericError');
    }
};

const cancelTransfersFlow = function* (accountId, data) {
    yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

    try {
        yield call(TransfersApi.cancelTransfers, {
            accountId,
            id: data.id,
        });

        const list = yield select(TransfersSelector.selectList);
        const index = list.findIndex(item => item.id === data.id);
        const newList = [...list];
        newList.splice(index, 1);

        yield put(TransfersActions.storeList(newList));

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        Toast.error('genericError');
    }
};

const uploadTransferFileFlow = function* (id, files, paramAccountId) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_BANK_FILE, true));

        const hiwayAccountId = yield call(getHiwayAccountId);
        const accountId = paramAccountId ?? hiwayAccountId;

        const SSEEventSource = yield select(SSESelector.selectBankSSEEventSource);

        if (!SSEEventSource?.readyState || SSEEventSource.readyState === EventSource.CLOSED) {
            yield put(SseActions.reconnectBankSSE());
            yield delay(200);
        }

        const response = yield call(TransfersApi.uploadTransferFile, {
            accountId,
            id: id,
            files,
        });

        yield put(TransactionsActions.updateFileUploadLoader({
            transactionId: id,
            eventId: response.pendingEventId,
            inProgress: true,
        }));

        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_BANK_FILE, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_BANK_FILE, false));
    }
};

const deleteTransferFileFlow = function* (fileId, transferId, paramAccountId) {
    yield put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK_FILE, true));

    try {
        const hiwayAccountId = yield call(getHiwayAccountId);
        const accountId = paramAccountId ?? hiwayAccountId;

        yield call(TransfersApi.deleteTransferFile, {
            accountId,
            fileId,
            transferId,
        });

        yield call(getTransferDetailsFlow, accountId, {id: transferId});

        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK_FILE, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK_FILE, false));
    }
};

const getRemunerationFlow = function* (accountId) {
    try {
        const remuneration = yield call(TransfersApi.getRemuneration, {
            accountId,
        });

        yield put(TransfersActions.storeRemuneration(remuneration));

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

export const handleSSETransferUploadFileFail = function* (data) {
    try {
        const message = JSON.parse(data.data);

        const event = yield select(TransactionsSelector.createModalFileLoaderSelectorByEventId(message.id));

        yield put(TransactionsActions.updateFileUploadLoader({
            eventId: event.eventId,
            inProgress: false,
        }));

        Toast.error('bankFileUploadError');
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const handleSSETransferUploadFileSuccess = function* (data) {
    try {
        const message = JSON.parse(data.data);

        // Get transaction related to event
        const event = yield select(TransactionsSelector.createModalFileLoaderSelectorByEventId(message.id));

        // Not matching event
        if (!event?.transactionId) {
            // If there is not transfer try QR code event
            handleSSEUploadFileSuccessQRCode(message);
            return;
        }

        // Get new file info
        // TODO It should be event.transferId?
        if (event.transactionId) {
            const accountId = yield call(getHiwayAccountId);

            yield call(getTransferDetailsFlow, accountId, {id: event.transactionId});
        }

        // Set in progress to false
        yield put(TransactionsActions.updateFileUploadLoader({
            eventId: event.eventId,
            inProgress: false,
        }));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const handleSSEUploadFileSuccessQRCode = function* (message) {
    const qrCodeEvent = yield select(BankSelector.selectQrCodeEvent);
    if (!qrCodeEvent) {
        return;
    }

    if (message?.id === qrCodeEvent) {
        yield put(BankActions.addUploadFileQrCodeEvent(null));
        Toast.success('fileAdded');
    }
};

export const getRecurringTransfersListWorker = function* () {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield call(getRecurringTransfersListFlow, accountId);
};

export const startRecurringTransferLoader = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.LOAD_TRANSFERS, true));
};

export const createTransfersWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    if (payload.beneficiaryRadio) {
        const beneficiaries = yield select(BeneficiariesSelector.selectList);

        const beneficiary = beneficiaries.find(item => item.id === payload.beneficiaryRadio);

        payload.iban = beneficiary.iban;
        payload.label = beneficiary.label;
    }

    if (payload.shouldSaveBeneficiary) {
        yield put(BeneficiariesActions.create({
            iban: payload.iban,
            label: payload.label,
            isPersonal: false,
        }));
    }

    delete payload.beneficiaryRadio;
    delete payload.shouldSaveBeneficiary;

    yield call(createTransfersFlow, accountId, payload);
};

export const getTransferDetailsWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield call(getTransferDetailsFlow, accountId, payload);
};

export const updateTransfersWorker = function* ({payload}) {
    const transferData = yield select(TransfersSelector.selectTransfer);
    if (
        !isNaN(parseFloat(transferData?.amount))
        && !isNaN(parseFloat(payload?.tvaAmount))
        && Number(transferData?.amount) < Number(payload?.tvaAmount)
    ) {
        return;
    }

    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield call(updateTransfersFlow, accountId, payload);
};

export const cancelTransfersWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield call(cancelTransfersFlow, accountId, payload);
};

export const uploadTransferFileWorker = function* ({payload}) {
    yield call(uploadTransferFileFlow, payload.id, payload.file, payload?.accountId);
};

const deleteTransferFileWorker = function* ({payload}) {
    yield call(deleteTransferFileFlow, payload.fileId, payload.transferId, payload?.accountId);
};

export const getRemunerationWorker = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield call(
        getRemunerationFlow,
        accountId,
    );
};

export const watchTransfersSaga = function* () {
    yield all([
        takeEvery(TransfersActionType.CREATE, createTransfersWorker),
        takeEvery(TransfersActionType.GET_TRANSFER, getTransferDetailsWorker),
        takeEvery(TransfersActionType.UPDATE, updateTransfersWorker),
        takeEvery(TransfersActionType.CANCEL, cancelTransfersWorker),
        takeEvery(TransfersActionType.UPLOAD_TRANSFER_FILE, uploadTransferFileWorker),
        takeEvery(TransfersActionType.DELETE_TRANSFER_FILE, deleteTransferFileWorker),
    ]);
};
