import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, FormControlLabel, FormGroup} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '../../../../components/checkbox/Checkbox';

export const FundingApprovalDocumentButtons = ({onButtonClick, onCheckboxClick, isChecked, isDisabled}) => {
    const {t} = useTranslation('training');

    return (
        <>
            <Box sx={{
                mb: {
                    xs: 2,
                    md: 0,
                },
            }}
            >
                <FormGroup>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                defaultChecked={isChecked}
                                onClick={onCheckboxClick}
                                disabled={isDisabled}
                            />
                        )}
                        label={t('fundingApproval.documentCheckbox')}
                    />
                </FormGroup>
            </Box>
            <Button
                endIcon={<ArrowForwardIcon />}
                onClick={onButtonClick}
                color="secondary"
                variant="contained"
                disableElevation
                disabled={!isChecked}
            >
                {t('fundingApproval.submit')}
            </Button>
        </>
    );
};

FundingApprovalDocumentButtons.propTypes = {
    onButtonClick: PropTypes.func.isRequired,
    onCheckboxClick: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
};

FundingApprovalDocumentButtons.defaultProps = {
    isDisabled: false,
};
