export const OnboardingActionTypes = {
    SET_STEP: 'freelancer.onboarding/SET_STEP',
    SET_SUB_STEP: 'freelancer.onboarding/SET_SUB_STEP',
    SET_INTERNAL_SUB_STEP: 'freelancer.onboarding/SET_INTERNAL_SUB_STEP',
    SET_PROGRESS: 'freelancer.onboarding/SET_PROGRESS',
    RESET_COMPANY_SETUP_FLOW: 'freelancer.onboarding/RESET_COMPANY_SETUP_FLOW',
    LOAD_COMPANY: 'freelancer.onboarding/LOAD_COMPANY',
    ACTIVATE_COMPANY: 'freelancer.onboard/ACTIVATE_COMPANY',
    SET_ONBOARDING_FINAL_STATUS: 'freelancer.onboard/setOnboardingFinalStatus',
    DETERMINE_STEP_SUBSTEP: 'freelancer.onboard/DETERMINE_STEP_SUBSTEP',
    DETERMINE_CARE_LAST_STEP: 'freelancer.onboard/DETERMINE_CARE_LAST_STEP',
};
