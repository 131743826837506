import {saveAs} from 'file-saver';
import {all, call, delay, put, select, takeEvery} from 'redux-saga/effects';
import {TrainingActions} from './training.action';
import {TrainingActionTypes} from './training.action-type';
import {TrainingSelector} from './training.selector';
import {push} from '../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {
    downloadAsArchiveRequest,
} from '../../../v1/app/api/providers/documents/documents.provider';
import {isMobileSafari, isSafari} from '../../../v1/app/app.helpers';
import {AnimationActions} from '../../animations/store/animations.action';
import {AnimationsSelectors} from '../../animations/store/animations.selector';
import {DatabaseActions} from '../../document/modules/database/store/database.action';
import {loadDocuments} from '../../document/modules/database/store/database.saga';
import {DatabaseSelectors} from '../../document/modules/database/store/database.selector';
import {DocumentContexts} from '../../document/modules/database/utils/constants';
import {LoadingActions, LoadingTypes} from '../../loading';
import {loadSignature} from '../../signature/store/signature.saga';
import {UiActions} from '../../ui/store/ui.action';
import {ModalsKeys} from '../../ui/utils/constants';
import {LoggedInUserActions, LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {TrainingApi} from '../api/training.api';
import {TRAINING_CONSTANTS} from '../utils/constants';

export const getTrainingInfoFlow = function* ({freelancerId, companyId}) {
    const info = yield call(
        TrainingApi.getTrainingInfo, {freelancerId, companyId},
    );

    yield put(TrainingActions.storeTrainingInfo(info));

    return info;
};

export const setTrainingInfoStepFlow = function* ({freelancerId, companyId, status}) {
    const trainingInfo = yield call(
        TrainingApi.updateStatus, {freelancerId, companyId, status},
    );

    if (trainingInfo.status) {
        yield put(TrainingActions.storeTrainingInfo(trainingInfo));
    }

    return trainingInfo;
};

export const saveAtlasNumberFlow = function* ({atlasFileNumber, freelancerId, companyId}) {
    return yield call(
        TrainingApi.updateAtlasNumber, {atlasFileNumber, freelancerId, companyId},
    );
};

export const saveRejectedFormFlow = function* ({message, file, freelancerId, companyId}) {
    try {
        return yield call(
            TrainingApi.saveRejectForm, {message, file, freelancerId, companyId},
        );
    } catch (error) {
        Debug.error('training', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_REJECT_FORM_SUBMIT, false));

        throw new Error(error);
    }
};

const downlodZipFlow = function* (type) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_ZIP_DOWNLOAD, true));

        const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

        const documents = yield select(DatabaseSelectors.selectDocuments);
        const documentIds = Object.values(documents).map(document => document.id);

        const signedUrl = yield call(
            downloadAsArchiveRequest,
            user.id,
            user.defaultCompanyId,
            documentIds,
            type,
        );

        if (!signedUrl) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('The document URL is missing.');
        }

        if ((isSafari || isMobileSafari)) {
            // TODO:HIGH: It's ugly but it works.
            fetch(signedUrl).then(response => {
                return response.blob();
            }).then(blob => {
                const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
                const filename = matchedGroups[2];

                saveAs(blob, decodeURI(filename));
            });

            yield delay(400);
            yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_ZIP_DOWNLOAD, false));

            return;
        }

        window.open(signedUrl, '_blank');

        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_ZIP_DOWNLOAD, false));
    } catch (error) {
        Debug.error('training/downlodZipFlow', 'Error: ', {error});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_ZIP_DOWNLOAD, false));

        throw new Error(error);
    }
};

export const closeTrainingAlertNotificationFlow = function* ({freelancerId, companyId}) {
    try {
        yield call(TrainingApi.closeTrainingAlertNotification, {freelancerId, companyId});
    } catch (error) {
        Debug.error('training/closeTrainingAlertNotificationFlow', 'Error: ', {error});
        Toast.error('genericError');
    }
};

// Workers
export const trainingLoaderSaga = function* () {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.DETERMINE_TRAINING_STEP, true));

        const progress = yield select(TrainingSelector.getTrainingProgress);
        const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

        // Check if user has access
        if (!user || !user.hasTrainingMenu) {
            Toast.info('notEligibleForTraining');

            yield put(push(RoutePaths.DASHBOARD));
            return;
        }

        const trainingInfo = yield call(getTrainingInfoFlow, {freelancerId: user.id, companyId: user.defaultCompanyId});

        // Step 0
        if (trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.PENDING) {
            yield call(trainingStartWorker);
        }

        // Step 1
        if (trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.PENDING
            || trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_APPLICATION) {
            // Set current step
            yield put(
                TrainingActions.setTrainingStep(
                    TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION,
                ),
            );
        }

        // Step 2
        if (trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_ACCOUNT_CREATION) {
            // Set progress info
            yield put(
                TrainingActions.setTrainingProgress(
                    {...progress, [TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION]: {completed: true}},
                ),
            );

            // Set current step
            yield put(
                TrainingActions.setTrainingStep(
                    TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS,
                ),
            );
        }

        // Step 3
        if (trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_SUPPORT_PENDING_SIGNATURE
            || trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_SUPPORT_DOCUMENTS_PAGE
            || trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_SUPPORT_PENDING_FILE_NUMBER) {
            // Set progress info
            yield put(
                TrainingActions.setTrainingProgress(
                    {
                        ...progress,
                        [TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION]: {completed: true},
                        [TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS]: {completed: true},
                    },
                ),
            );

            // Set current step
            yield put(
                TrainingActions.setTrainingStep(
                    TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER,
                ),
            );

            // Set document generation subpage
            if (trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_SUPPORT_PENDING_SIGNATURE) {
                yield put(TrainingActions.setSubStep(TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_GENERATION));
            }

            // Set document download subpage
            if (trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_SUPPORT_DOCUMENTS_PAGE) {
                yield put(TrainingActions.setSubStep(TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_DOWNLOAD));

                // Load step 3 documents
                yield put(DatabaseActions.clearDocuments());
                yield call(loadDocuments, {
                    freelancerId: user.id,
                    companyId: user.defaultCompanyId,
                    context: DocumentContexts.STEP_THREE_TRAINING,
                });
            }

            // Set document view subpage
            if (trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_SUPPORT_PENDING_FILE_NUMBER) {
                yield put(TrainingActions.setSubStep(TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_VIEW));
            }

            yield call(loadSignature);
            yield put(LoadingActions.setLoading(LoadingTypes.DETERMINE_TRAINING_STEP, false));

            // Load training feature again to get potentially regenerated dates
            if (!trainingInfo.startDate) {
                yield delay(600);
                yield select(TrainingSelector.getTrainingProgress);
            }
        }

        // Step 4
        if (trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING
            || trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_REJECTED
            || trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE
            || trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_CHECKLIST) {
            // Set progress info
            yield put(
                TrainingActions.setTrainingProgress(
                    {
                        ...progress,
                        [TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION]: {completed: true},
                        [TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER]: {completed: true},
                        [TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS]: {completed: true},
                    },
                ),
            );

            // Set current step
            yield put(
                TrainingActions.setTrainingStep(
                    TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL,
                ),
            );

            // Set in progress/refused/accepted/document view page
            yield put(TrainingActions.setSubStep(trainingInfo.status));

            // If success screen load documents
            if (trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE) {
                yield put(DatabaseActions.clearDocuments());
                yield call(loadDocuments, {
                    freelancerId: user.id,
                    companyId: user.defaultCompanyId,
                    context: DocumentContexts.STEP_FOUR_TRAINING,
                });
            }
        }

        if (trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_FINALIZATION_PENDING_PAYMENT
            || trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.COMPLETED) {
            // Set progress info
            yield put(
                TrainingActions.setTrainingProgress(
                    {
                        ...progress,
                        [TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION]: {completed: true},
                        [TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER]: {completed: true},
                        [TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS]: {completed: true},
                        [TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL]: {completed: true},
                    },
                ),
            );

            // Set current step
            yield put(
                TrainingActions.setTrainingStep(
                    TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION,
                ),
            );
        }

        yield put(LoadingActions.setLoading(LoadingTypes.DETERMINE_TRAINING_STEP, false));

        // If status is pending signature get documents
        if (trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_SUPPORT_PENDING_SIGNATURE) {
            yield call(getTrainingInfoFlow, {freelancerId: user.id, companyId: user.defaultCompanyId});
            yield put(DatabaseActions.clearDocuments());
            yield call(loadDocuments, {
                freelancerId: user.id,
                companyId: user.defaultCompanyId,
                context: DocumentContexts.STEP_THREE_TRAINING,
            });
        }
    } catch (e) {
        Debug.error('saveAtlasNumberWorker', 'Error: ', {e});

        yield call(errorHandlingWorker, e);

        yield put(LoadingActions.setLoading(LoadingTypes.DETERMINE_TRAINING_STEP, false));
    }
};

const trainingStartWorker = function* () {
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

    // Step 0 to 1
    yield call(setTrainingInfoStepFlow, {
        freelancerId: user.id,
        companyId: user.defaultCompanyId,
        status: TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_APPLICATION,
    });
};

export const trainingSubmitStepWorker = function* ({payload}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_STEP_SUBMIT, true));

        const user = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const progress = yield select(TrainingSelector.getTrainingProgress);

        switch (payload) {
            // Step 1 to 2
            case TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION: {
                if (!progress[TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION].completed) {
                    yield call(setTrainingInfoStepFlow, {
                        freelancerId: user.id,
                        companyId: user.defaultCompanyId,
                        status: TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_ACCOUNT_CREATION,
                    });
                }

                yield put(AnimationActions.setIsAnimationActive(false));

                yield put(AnimationActions.storeNextDispatch(
                    TrainingActions.setTrainingStep(
                        TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS,
                    ),
                ));

                if (!progress[TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION].completed) {
                    // Delay is needed for animation to end
                    yield delay(800);
                    yield put(
                        TrainingActions.setTrainingProgress(
                            {...progress, [TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION]: {completed: true}},
                        ),
                    );
                }

                break;
            }

            // Step 2 to 3
            case TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS: {
                if (!progress[TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS].completed) {
                    yield call(setTrainingInfoStepFlow, {
                        freelancerId: user.id,
                        companyId: user.defaultCompanyId,
                        status: TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_SUPPORT_PENDING_SIGNATURE,
                    });
                }

                yield put(AnimationActions.setIsAnimationActive(false));

                yield put(AnimationActions.storeNextDispatch(
                    TrainingActions.setTrainingStep(
                        TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER,
                    ),
                ));

                if (!progress[TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER].completed) {
                    // Load signature and documents
                    yield call(loadSignature);
                    yield put(DatabaseActions.clearDocuments());
                    yield call(loadDocuments, {
                        freelancerId: user.id,
                        companyId: user.defaultCompanyId,
                        context: DocumentContexts.STEP_THREE_TRAINING,
                    });

                    // Delay to make sure dates will be valid
                    yield delay(500);
                    yield call(getTrainingInfoFlow, {freelancerId: user.id, companyId: user.defaultCompanyId});

                    // Delay for animation to finish before updating step
                    yield delay(300);
                    yield put(
                        TrainingActions.setTrainingProgress(
                            {...progress, [TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS]: {completed: true}},
                        ),
                    );
                }

                break;
            }

            // Step 3 document generation to document signing
            case TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_GENERATION: {
                yield put(AnimationActions.setIsAnimationActive(false));

                yield put(
                    AnimationActions.storeNextDispatch(
                        TrainingActions.setSubStep(TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_SIGNING),
                    ),
                );

                break;
            }

            // Step 3 document signing to document download
            case TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_SIGNING: {
                yield call(setTrainingInfoStepFlow, {
                    freelancerId: user.id,
                    companyId: user.defaultCompanyId,
                    status: TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_SUPPORT_DOCUMENTS_PAGE,
                });

                yield put(
                    AnimationActions.storeNextDispatch(
                        TrainingActions.setSubStep(TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_DOWNLOAD),
                    ),
                );

                yield put(AnimationActions.setIsAnimationActive(false));

                break;
            }

            // Step 3 document download to document view
            case TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_DOWNLOAD: {
                yield call(setTrainingInfoStepFlow, {
                    freelancerId: user.id,
                    companyId: user.defaultCompanyId,
                    status: TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_SUPPORT_PENDING_FILE_NUMBER,
                });

                yield put(
                    AnimationActions.storeNextDispatch(
                        TrainingActions.setSubStep(TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_VIEW),
                    ),
                );

                yield put(AnimationActions.setIsAnimationActive(false));

                break;
            }

            // Step 3 to 4
            case TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER: {
                if (!progress[TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER].completed) {
                    yield call(setTrainingInfoStepFlow, {
                        freelancerId: user.id,
                        companyId: user.defaultCompanyId,
                        status: TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING,
                    });

                    yield put(
                        TrainingActions.setTrainingProgress(
                            {...progress, [TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER]: {completed: true}},
                        ),
                    );
                }

                // TODO Add animation
                yield put(
                    TrainingActions.setTrainingStep(
                        TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL,
                    ),
                );

                // If step is not completed and is in one of non default statuses select them
                const trainingInfo = yield select(TrainingSelector.getTrainingInfo);
                if (trainingInfo.status === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_REJECTED
                    || trainingInfo.status
                    === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE) {
                    yield put(TrainingActions.setSubStep(trainingInfo.status));
                } else {
                    yield put(TrainingActions.setSubStep(TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING));
                }

                break;
            }

            // Step 4 to in progress
            case TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING: {
                yield call(setTrainingInfoStepFlow, {
                    freelancerId: user.id,
                    companyId: user.defaultCompanyId,
                    status: TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING,
                });

                yield put(TrainingActions.setSubStep(TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING));

                break;
            }

            // Step 4 to rejected
            case TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_REJECTED: {
                yield call(setTrainingInfoStepFlow, {
                    freelancerId: user.id,
                    companyId: user.defaultCompanyId,
                    status: TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_REJECTED,
                });

                yield put(TrainingActions.setSubStep(TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_REJECTED));

                break;
            }

            // Step 4 to accepted
            case TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE: {
                yield call(setTrainingInfoStepFlow, {
                    freelancerId: user.id,
                    companyId: user.defaultCompanyId,
                    status: TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE,
                });

                yield put(
                    TrainingActions.setSubStep(
                        TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE,
                    ),
                );

                yield put(DatabaseActions.clearDocuments());
                yield call(loadDocuments, {
                    freelancerId: user.id,
                    companyId: user.defaultCompanyId,
                    context: DocumentContexts.STEP_FOUR_TRAINING,
                });

                break;
            }

            // Step 4 to final document
            case TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_CHECKLIST: {
                yield call(setTrainingInfoStepFlow, {
                    freelancerId: user.id,
                    companyId: user.defaultCompanyId,
                    status: TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_CHECKLIST,
                });

                yield put(
                    AnimationActions.storeNextDispatch(
                        TrainingActions.setSubStep(
                            TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_CHECKLIST,
                        ),
                    ),
                );

                yield put(AnimationActions.setIsAnimationActive(false));

                break;
            }

            // Step 4 to 5
            case TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL: {
                if (!progress[TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL].completed) {
                    yield call(setTrainingInfoStepFlow, {
                        freelancerId: user.id,
                        companyId: user.defaultCompanyId,
                        status: TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_FINALIZATION_PENDING_PAYMENT,
                    });
                }

                yield put(
                    AnimationActions.storeNextDispatch(
                        TrainingActions.setTrainingStep(
                            TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION,
                        ),
                    ),
                );

                yield put(AnimationActions.setIsAnimationActive(false));

                if (!progress[TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL].completed) {
                    yield delay(800);

                    yield put(
                        TrainingActions.setTrainingProgress(
                            {...progress, [TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL]: {completed: true}},
                        ),
                    );
                }

                break;
            }

            // Step 5 to final screen
            case TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_FINALIZATION_PENDING_PAYMENT: {
                yield put(
                    TrainingActions.setTrainingProgress(
                        {...progress, [TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION]: {completed: true}},
                    ),
                );

                yield call(setTrainingInfoStepFlow, {
                    freelancerId: user.id,
                    companyId: user.defaultCompanyId,
                    status: TRAINING_CONSTANTS.TRAINING_STATUS.COMPLETED,
                });

                yield delay(200);

                // Reload user info with new statuses (and with training menu disabled)
                yield put(LoggedInUserActions.loadUser());

                break;
            }
        }
    } catch (e) {
        Debug.error('trainingSubmitStepWorker', 'Error: ', {e});

        yield call(errorHandlingWorker, e);

        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_STEP_SUBMIT, false));
    }

    yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_STEP_SUBMIT, false));
};

export const saveAtlasNumberWorker = function* ({payload}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_STEP_SUBMIT, true));

        const {atlasFileNumber} = payload;

        const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield call(saveAtlasNumberFlow, {
            atlasFileNumber: atlasFileNumber.toString(),
            freelancerId: user.id,
            companyId: user.defaultCompanyId,
        });

        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_STEP_SUBMIT, false));
        yield put(TrainingActions.submitStep(TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER));

        yield put(UiActions.setActiveModal(ModalsKeys.TRAINING_DOSSIER_MODAL, false));
    } catch (e) {
        Debug.error('saveAtlasNumberWorker', 'Error: ', {e});

        yield call(errorHandlingWorker, e);

        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_STEP_SUBMIT, false));
        yield put(UiActions.setActiveModal(ModalsKeys.TRAINING_DOSSIER_MODAL, false));
    }
};

export const saveRejectedFormWorker = function* ({payload}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_REJECT_FORM_SUBMIT, true));

        const {message, file} = payload;

        const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield call(saveRejectedFormFlow, {
            message,
            file,
            freelancerId: user.id,
            companyId: user.defaultCompanyId,
        });

        // Update isAtlasValidationEmailSent flag to show proper screen
        const trainingInfo = yield select(TrainingSelector.getTrainingInfo);
        const newTrainingInfo = {...trainingInfo, isAtlasValidationEmailSent: true};
        yield put(TrainingActions.storeTrainingInfo(newTrainingInfo));

        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_REJECT_FORM_SUBMIT, false));
    } catch (e) {
        Debug.error('saveRejectedFormWorker', 'Error: ', {e});

        yield call(errorHandlingWorker, e);

        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_REJECT_FORM_SUBMIT, false));
    }
};

export const resetDatesWorker = function* () {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_STEP_SUBMIT, true));

        const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield put(
            TrainingActions.setTrainingStep(
                TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER,
            ),
        );

        yield put(
            TrainingActions.setSubStep(
                TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_GENERATION,
            ),
        );

        const trainingInfo = yield call(setTrainingInfoStepFlow, {
            freelancerId: user.id,
            companyId: user.defaultCompanyId,
            status: TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_SUPPORT_PENDING_SIGNATURE,
        });

        yield put(TrainingActions.storeTrainingInfo(trainingInfo));

        yield put(UiActions.setActiveModal(ModalsKeys.TRAINING_DOSSIER_RESET_DATE, false));

        // Refresh dates and documents
        yield put(DatabaseActions.clearDocuments());
        yield call(loadDocuments, {
            freelancerId: user.id,
            companyId: user.defaultCompanyId,
            context: DocumentContexts.STEP_THREE_TRAINING,
        });

        yield put(LoadingActions.setLoading(LoadingTypes.TRAINING_STEP_SUBMIT, false));
    } catch (e) {
        Debug.error('training/resetDatesWorker', 'Error: ', {e});

        yield call(errorHandlingWorker, e);
    }
};

const downlodZipWorker = function* ({payload}) {
    try {
        yield call(downlodZipFlow, payload);
    } catch (e) {
        Debug.error('training/downlodZipWorker', 'Error: ', {e});

        yield call(errorHandlingWorker, e);
    }
};

export const closeTrainingAlertNotificationWorker = function* () {
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);
    yield call(closeTrainingAlertNotificationFlow, {freelancerId: user.id, companyId: user.defaultCompanyId});
};

export const errorHandlingWorker = function* (e) {
    if (e?.message === 'Request failed with status code 409') {
        Toast.info('trainingDocumentGenerationError');
    } else if (e.message === 'Request failed with status code 400'
        && e.response?.data?.error
        && e.response.data.error === 'Freelancer is in NON-ELIGIBLE state and is unable to proceed further') {
        // Handle changed to non-eligible by admin
        Toast.info('notEligibleForTraining');

        yield put(LoggedInUserActions.loadUser());

        yield delay(200);

        yield put(push(RoutePaths.DASHBOARD));
        return;
    } else if (
        (e.message === 'Request failed with status code 400'
            && e.response?.data?.error
            && e.response.data.error.indexOf(`Current status - ${TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_SUPPORT_PENDING_SIGNATURE}`) !== -1)
        || (e.message === 'Request failed with status code 400'
            && e.response?.data?.error
            && e.response.data.error === 'Unable to proceed further until all documents have been signed on step ATLAS_SUPPORT_PENDING_SIGNATURE')
    ) {
        // Handle status change to ATLAS_SUPPORT_PENDING_SIGNATURE by admin
        const progress = yield select(TrainingSelector.getTrainingProgress);

        const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield all([
            put(
                TrainingActions.setTrainingProgress(
                    {...progress,
                        [TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER]: {completed: false},
                        [TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL]: {completed: false},
                        [TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION]: {completed: false}},
                ),
            ),
            put(TrainingActions.setTrainingStep(TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER)),
            put(TrainingActions.setSubStep(TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_GENERATION)),
        ]);

        Toast.info('trainingStatusResetError');

        // Refresh dates and documents
        yield call(getTrainingInfoFlow, {freelancerId: user.id, companyId: user.defaultCompanyId});
        yield put(DatabaseActions.clearDocuments());
        yield call(loadDocuments, {
            freelancerId: user.id,
            companyId: user.defaultCompanyId,
            context: DocumentContexts.STEP_THREE_TRAINING,
        });
    } else if (e.message === 'Request failed with status code 400'
        && e.response?.data?.error
        && e.response.data.error.indexOf(`Current status - ${TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING}`) !== -1) {
        // Handle status change to ATLAS_VALIDATION_PENDING by admin

        const progress = yield select(TrainingSelector.getTrainingProgress);

        yield all([
            put(
                TrainingActions.setTrainingProgress(
                    {...progress,
                        [TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL]: {completed: false},
                        [TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION]: {completed: false}},
                ),
            ),
            put(TrainingActions.setTrainingStep(TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL)),
            put(TrainingActions.setSubStep(TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING)),
        ]);

        Toast.info('trainingStatusResetNumberError');

        const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield delay(500);

        yield call(getTrainingInfoFlow, {freelancerId: user.id, companyId: user.defaultCompanyId});
    } else if (e.message === 'Request failed with status code 400'
        && e.response?.data?.error
        && e.response.data.error
        === 'Unable to update \'atlas_file_number\' field due to is_eligible - false or current status is lower the allowed - ATLAS_SUPPORT_PENDING_FILE_NUMBER') {
        // Since we dont know actual reason for fail we need to check
        const user = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const trainingInfo = yield call(getTrainingInfoFlow, {freelancerId: user.id, companyId: user.defaultCompanyId});

        // Not eligible
        if (!trainingInfo.isEligible) {
            Toast.info('notEligibleForTraining');

            yield put(LoggedInUserActions.loadUser());

            yield delay(200);

            yield put(push(RoutePaths.DASHBOARD));
            return;
        }

        // Handle wrong status
        yield call(trainingLoaderSaga);
    } else {
        Toast.error('genericError');
    }

    const isAnimationActive = yield select(AnimationsSelectors.selectIsAnimationActive);

    if (!isAnimationActive) {
        yield delay(500);
        yield put(AnimationActions.setIsAnimationActive(true));
    }
};

export const watchTrainingSaga = function* () {
    yield all([
        takeEvery(TrainingActionTypes.SUBMIT_STEP, trainingSubmitStepWorker),
        takeEvery(TrainingActionTypes.SAVE_ATLAS_NUMBER, saveAtlasNumberWorker),
        takeEvery(TrainingActionTypes.SAVE_REJECTED_FORM, saveRejectedFormWorker),
        takeEvery(TrainingActionTypes.RESET_DATES, resetDatesWorker),
        takeEvery(TrainingActionTypes.DOWNLOAD_ZIP, downlodZipWorker),
        takeEvery(TrainingActionTypes.CLOSE_TRAINING_ALERT_NOTIFICATION, closeTrainingAlertNotificationWorker),
    ]);
};
