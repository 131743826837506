/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {Button} from '@mui/joy';
import {styled, useThemeProps} from '@mui/system';
import clsx from 'clsx';
import * as React from 'react';
import {getPickersToolbarUtilityClass} from './pickersToolbarClasses';
import {PickersToolbarText} from './PickersToolbarText';

const useUtilityClasses = ownerState => {
    const {classes} = ownerState;
    const slots = {
        root: ['root'],
    };

    return composeClasses(slots, getPickersToolbarUtilityClass, classes);
};

const PickersToolbarButtonRoot = styled(Button, {
    name: 'MuiPickersToolbarButton',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})({
    padding: 0,
    minWidth: 16,
    textTransform: 'none',
});

export const PickersToolbarButton = React.forwardRef(
    (inProps, ref) => {
        const props = useThemeProps({
            props: inProps,
            name: 'MuiPickersToolbarButton',
        });
        const {
            align,
            className,
            selected,
            typographyClassName,
            value,
            variant,
            ...other
        } = props;

        const classes = useUtilityClasses(props);

        return (
            <PickersToolbarButtonRoot
                data-mui-test="toolbar-button"
                variant="plain"
                color="neutral"
                ref={ref}
                className={clsx(className, classes.root)}
                {...other}
            >
                <PickersToolbarText
                    align={align}
                    className={typographyClassName}
                    variant={variant}
                    value={value}
                    selected={selected}
                />
            </PickersToolbarButtonRoot>
        );
    },
);
