import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Badge, Box, Divider, Grow, Typography} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import CreditDropArea from './CreditDropArea';
import {emptyFunction} from '../../../../util/constants';
import {DRAG_ITEMS, SECTION_STATUS} from '../../utils/constants';
import {SelectedSource} from '../categories/SelectedSource';
import CategorisationSection from '../header/CategorisationSection';

const CreditDnDLayerWrapper = ({
    categorySectionTitle,
    mainSectionTitle,
    selectedSectionTitle,
    mainSectionAccordionTitle,
    otherSectionAccordionTitle,
    mainSX,
    hasShownDropArea,
    MainSection,
    AdditionalSection,
    ResultSection,
    BottomAlert,
    ResultAlert,
    onDrop,
    dndDependancy,
    data,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <DndProvider backend={HTML5Backend}>
            <Grow in={true}>
                <div>
                    {/* <ReimbursementDragLayer /> */}

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        mx: 2,
                        mt: 2,
                    }}
                    >
                        <CategorisationSection
                            title={categorySectionTitle}
                            sx={{
                                flex: '2 0 0',
                                p: 2,
                                pt: 1,
                                justifyContent: 'space-between',
                                gap: 1.5,
                                borderRadius: '16px 16px 0 0',
                                mb: 0.3,
                            }}
                            isActive
                        >
                            <SelectedSource
                                selected={data.details.sourceOfRevenue}
                                onOpen={data.handleOpenAllCategories}
                            />
                        </CategorisationSection>

                        <CategorisationSection
                            title={mainSectionTitle}
                            sx={{
                                flex: '2 0 0',
                                p: 2,
                                pt: 1,
                                justifyContent: 'space-between',
                                gap: 1.5,
                                borderRadius: '0 0 16px 16px',
                            }}
                            isActive
                            status={SECTION_STATUS.REQUIRED}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: isMobileSize ? 'column' : 'row',
                                justifyContent: isMobileSize ? 'center' : 'space-between',
                                height: isMobileSize ? '100%' : 'calc(100vh - 250px)',
                            }}
                            >
                                <Box sx={{
                                    width: isMobileSize ? 'auto' : 'calc(100% - 380px)',
                                    overflowY: 'auto',
                                }}
                                >
                                    {data.hasSuggestions ? (
                                        <Accordion
                                            elevation={0}
                                            defaultExpanded={data.numberOfNotSelected?.main > 0}
                                            sx={{
                                                borderRadius: '32px',
                                            }}
                                        >
                                            <AccordionSummary
                                                sx={{
                                                    borderRadius: '32px',
                                                    backgroundColor: 'gray_v2.50',
                                                    fontWeight: 500,
                                                }}
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                {mainSectionAccordionTitle}

                                                {(data?.type === DRAG_ITEMS.INVOICE
                                                    || data?.type === DRAG_ITEMS.REIMBURSEMENT) && (
                                                    <Badge
                                                        badgeContent={data.numberOfNotSelected.main}
                                                        showZero
                                                        color="v2Grey300Palette"
                                                        sx={{
                                                            color: 'white !important',
                                                            ml: 2.5,
                                                            fontWeight: 500,
                                                            mt: 1.5,
                                                            fontSize: theme => theme.typography.pxToRem(12),
                                                        }}
                                                    />
                                                )}
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <Box sx={isMobileSize ? {
                                                    'display': 'flex',
                                                    'flexDirection': 'column',
                                                    'gap': 1,
                                                    'mx': 1 - 2,
                                                } : mainSX || {
                                                    display: 'grid',
                                                    gridTemplateColumns: AdditionalSection
                                                        ? `repeat(3, minmax(min-content, 33%))`
                                                        : 'none',
                                                    gap: 1,
                                                }}
                                                >
                                                    <MainSection {...data} />
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                        : (
                                            <Box sx={isMobileSize ? {
                                                'display': 'flex',
                                                'flexDirection': 'column',
                                                'gap': 1,
                                                'mx': 1 - 2,
                                            } : mainSX || {
                                                display: 'grid',
                                                gridTemplateColumns: AdditionalSection
                                                    ? `repeat(3, minmax(min-content, 33%))`
                                                    : 'none',
                                                gap: 1,
                                            }}
                                            >
                                                <MainSection {...data} />
                                            </Box>
                                        )}

                                    {AdditionalSection && (
                                    <Box sx={{
                                        mt: 2,
                                    }}
                                    >
                                        <Accordion
                                            elevation={0}
                                            defaultExpanded={data.numberOfNotSelected?.main === 0}
                                            sx={{
                                                borderRadius: '32px',
                                            }}
                                        >
                                            <AccordionSummary
                                                sx={{
                                                    borderRadius: '32px',
                                                    backgroundColor: 'gray_v2.50',
                                                    fontWeight: 500,
                                                }}
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                {otherSectionAccordionTitle}

                                                {(data?.type === DRAG_ITEMS.INVOICE
                                                    || data?.type === DRAG_ITEMS.REIMBURSEMENT) && (
                                                    <Badge
                                                        badgeContent={data.numberOfNotSelected.other}
                                                        showZero
                                                        color="v2Grey300Palette"
                                                        sx={{
                                                            color: 'white !important',
                                                            ml: 2.5,
                                                            fontWeight: 500,
                                                            mt: 1.5,
                                                            fontSize: theme => theme.typography.pxToRem(12),
                                                        }}
                                                    />
                                                )}
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <Box sx={isMobileSize ? {
                                                    'display': 'flex',
                                                    'flexDirection': 'column',
                                                    'gap': 1,
                                                    'mx': -1,
                                                } : {
                                                    display: 'grid',
                                                    gridTemplateColumns: `repeat(3, minmax(min-content, 33%))`,
                                                    gap: 1,
                                                }}
                                                >
                                                    <AdditionalSection {...data} />
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                    )}

                                    {BottomAlert && (
                                    <Box sx={{
                                        mt: 2,
                                    }}
                                    >
                                        <BottomAlert
                                            {...data}
                                        />
                                    </Box>
                                    )}
                                </Box>

                                <Divider
                                    sx={{
                                        mx: 2,
                                        flex: '1 0 0',
                                        height: '100%',
                                        color: 'v2.blueGray.100',
                                    }}
                                    orientation="vertical"
                                    flexItem
                                />

                                <Box sx={isMobileSize ? {} : {
                                    height: 'calc(100vh - 250px)',
                                    width: '380px',
                                    overflowY: 'auto',
                                }}
                                >
                                    {ResultAlert && <ResultAlert data={data} />}

                                    <Box sx={{
                                        backgroundColor: 'v2.light.info.shades10',
                                        borderRadius: '16px',
                                        p: 3,
                                        pt: 2,
                                    }}
                                    >
                                        <Typography sx={{
                                            color: 'v2.blueGray.300',
                                            fontSize: theme => theme.typography.pxToRem(12),
                                            fontWeight: 500,
                                            letterSpacing: '1px',
                                            textTransform: 'uppercase',
                                            mb: 2,
                                        }}
                                        >
                                            {selectedSectionTitle}
                                        </Typography>

                                        <ResultSection {...data} />

                                        {hasShownDropArea && !isMobileSize && (
                                            <CreditDropArea
                                                type={data?.type}
                                                onDrop={onDrop}
                                                dep={dndDependancy}
                                            />
                                        )}

                                    </Box>
                                </Box>
                            </Box>
                        </CategorisationSection>
                    </Box>
                </div>
            </Grow>
        </DndProvider>
    );
};

CreditDnDLayerWrapper.propTypes = {
    categorySectionTitle: PropTypes.string.isRequired,
    mainSectionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]).isRequired,
    selectedSectionTitle: PropTypes.string.isRequired,
    mainSectionAccordionTitle: PropTypes.string,
    otherSectionAccordionTitle: PropTypes.string,
    mainSX: PropTypes.object,
    MainSection: PropTypes.func.isRequired,
    AdditionalSection: PropTypes.func.isRequired,
    ResultSection: PropTypes.func.isRequired,
    hasShownDropArea: PropTypes.bool,
    BottomAlert: PropTypes.func.isRequired,
    ResultAlert: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onDrop: PropTypes.func,
    dndDependancy: PropTypes.any,
    data: PropTypes.object.isRequired,
};

CreditDnDLayerWrapper.defaultProps = {
    mainSX: null,
    dndDependancy: 1,
    ResultAlert: null,
    hasShownDropArea: false,
    onDrop: emptyFunction,
    mainSectionAccordionTitle: '',
    otherSectionAccordionTitle: '',
};

export default CreditDnDLayerWrapper;
