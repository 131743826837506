import axios from 'axios';
import {CONFIG} from '../config';

export const documentationApiInstance = axios.create({
    baseURL: CONFIG.DOCUMENTATION.API_BASE_URL,
    auth: {
        username: CONFIG.DOCUMENTATION.USERNAME,
        password: CONFIG.DOCUMENTATION.PASSWORD,
    },
    headers: {
        'Content-Type': 'application/json',
    },
});
