import React from 'react';
import {RoutePaths} from '../../../lib/router/route-paths';
import {appLoaderSaga} from '../../../v1/app/appLoader.sagas';
import {settingsLoaderSaga} from '../../settings/store/settings.sagas';
import {
    getAriaInfoWorker,
    getInvoiceClientsWorker,
    getInvoiceServicesWorker,
    getInvoiceWorker,
} from '../store/invoice.saga';

// eslint-disable-next-line import/no-restricted-paths
const InvoiceScreen = React.lazy(() => import('../../../screens/invoicing/InvoiceScreen'));
// eslint-disable-next-line import/no-restricted-paths
const InvoiceDashboard = React.lazy(() => import('../../../screens/invoicing/InvoicingScreen'));
// eslint-disable-next-line import/no-restricted-paths
const MyClientsScreen = React.lazy(() => import('../../../screens/invoicing/MyClientsScreen'));
// eslint-disable-next-line import/no-restricted-paths
const MyServicesScreen = React.lazy(() => import('../../../screens/invoicing/MyServicesScreen'));

export const InvoicingRoutes = [
    {
        path: RoutePaths.INVOICING,
        element: <InvoiceDashboard />,
        exact: true,
        locationChangeSideEffects: [
            [appLoaderSaga],
            [settingsLoaderSaga],
            [getInvoiceWorker],
            [getAriaInfoWorker],
        ],
    },
    {
        path: RoutePaths.CREATE_INVOICE,
        element: <InvoiceScreen />,
        exact: true,
        locationChangeSideEffects: [
            [appLoaderSaga],
            [settingsLoaderSaga],
            [getAriaInfoWorker],
        ],
    },
    {
        path: RoutePaths.MY_CLIENTS,
        element: <MyClientsScreen />,
        exact: true,
        locationChangeSideEffects: [
            [appLoaderSaga],
            [settingsLoaderSaga],
            [getInvoiceClientsWorker],
            [getAriaInfoWorker],
        ],
    },
    {
        path: RoutePaths.MY_SERVICES,
        element: <MyServicesScreen />,
        exact: true,
        locationChangeSideEffects: [
            [appLoaderSaga],
            [settingsLoaderSaga],
            [getInvoiceServicesWorker],
        ],
    },
];
