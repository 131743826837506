import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

export const PasswordField = React.forwardRef(({adornmentAriaLabel, errors, name, disabled, ...rest}, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    return (
        <TextField
            ref={ref}
            type={isPasswordVisible ? 'text' : 'password'}
            error={!!errors?.[name]}
            helperText={errors?.[name]?.message}
            disabled={disabled}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={adornmentAriaLabel}
                            onClick={() => setIsPasswordVisible(prevState => !prevState)}
                            onMouseDown={event => event.preventDefault()}
                            edge="end"
                            size="large"
                        >
                            {isPasswordVisible ? <VisibilityIcon style={{color: 'rgba(0, 0, 0, 0.87)'}} /> : <VisibilityOffIcon style={{color: 'rgba(0, 0, 0, 0.87)'}} />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...rest}
        />
    );
});

PasswordField.propTypes = {
    adornmentAriaLabel: PropTypes.string,
    ...TextField.propTypes,
};

PasswordField.defaultProps = {
    adornmentAriaLabel: '',
    ...TextField.defaultProps,
};
