import {
    LOCATION_CHANGED,
    LOCATION_CHANGE_SIDE_EFFECTS,
    ROUTER_CALL_HISTORY_METHOD,
} from './connected-router.constants';

export const locationChanged = (location, action, isFirstRender = false) => ({
    type: LOCATION_CHANGED,
    payload: {location, action, isFirstRender},
});

export const locationChangeSideEffects = payload => ({
    type: LOCATION_CHANGE_SIDE_EFFECTS,
    payload,
});

const updateLocation = method => {
    return (...args) => ({
        type: ROUTER_CALL_HISTORY_METHOD,
        payload: {
            method,
            args,
        },
    });
};

/**
 * These actions correspond to the history API.
 * The associated routerMiddleware will capture these events before they get to
 * your reducer and reissue them as the matching function on your history.
 */
export const push = updateLocation('push');
export const replace = updateLocation('replace');
export const go = updateLocation('go');
export const back = updateLocation('back');
export const forward = updateLocation('forward');

export const routerActions = {
    push,
    replace,
    go,
    back,
    forward,
};
