import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {ContractSigningActionTypes} from './contract-signing.action-types';
import {ContractSigningActions} from './contract-signing.actions';
import {UserStatuses} from '../../../../../utils/user-statuses';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {getContractDataFlow, loadContractData} from '../../../../contract/store/contract.saga';
import {DocumentApi} from '../../../../document/api/document.api';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {UserApi} from '../../../../user/api/user.api';
import {LoggedInUserActions, LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {UserStatus} from '../../../../user/utils/constants';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../onboarding/utils/onboadingStepsConstant';
import {WorkshopsInternalSubSteps} from '../../workshops/utils/constants';
import {ContractSigningApi} from '../api/contract-signing.api';
import {ContractSigningInternalSubSteps, ContractSigningSubSteps} from '../utils/constants';

const goToWorkshopStepFlow = function* ({freelancerId}) {
    try {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        if (loggedInUser.status === UserStatuses.CONTRACT_SIGNED) {
            const loggedInUser = yield call(ContractSigningApi.putWorkshopStep, {freelancerId});

            yield put(LoggedInUserActions.storeLoggedInUserAccount(loggedInUser));
        }

        const progress = yield select(OnboardingSelectors.selectProgress);

        yield all([
            put(OnboardingActions.setStep(OnboardingSteps.WORKSHOPS)),
            put(OnboardingActions.setInternalSubStep(WorkshopsInternalSubSteps.INITIAL_SCREEN)),
            put(OnboardingActions.setSubStep(null)),
            put(OnboardingActions.setProgress({
                ...progress,
                [OnboardingSteps.CONTRACT_SIGNING]: {
                    ...progress[OnboardingSteps.CONTRACT_SIGNING],
                    isCompleted: true,
                },
            })),
        ]);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});

        // Handling when admin sets up company before using reaching the last screen of step 1
        if (error?.response?.data?.error_type === 'HTTP_CONFLICT_ERROR') {
            yield put(LoggedInUserActions.loadUser());

            const progress = yield select(OnboardingSelectors.selectProgress);

            yield all([
                put(OnboardingActions.setStep(OnboardingSteps.WORKSHOPS)),
                put(OnboardingActions.setInternalSubStep(WorkshopsInternalSubSteps.INITIAL_SCREEN)),
                put(OnboardingActions.setSubStep(null)),
                put(OnboardingActions.setProgress({
                    ...progress,
                    [OnboardingSteps.CONTRACT_SIGNING]: {
                        ...progress[OnboardingSteps.CONTRACT_SIGNING],
                        isCompleted: true,
                    },
                })),
            ]);
        }
    }
};

const submitPersonalAddressFlow = function* ({formData, isDirty}) {
    try {
        const user = yield select(LoggedInUserSelectors.selectLoggedInUser);
        if (isDirty) {
            const userId = user?.id;
            const contactInfo = yield call(UserApi.putContactInfo, {userId, data: formData});
            yield put(LoggedInUserActions.storeLoggedInUserAccount({...user, contactInfo}));
            yield call(DocumentApi.generateContractDocumentRequest, userId);
            yield call(loadContractData);
        } else {
            // If form is not changed try to load contract data and regenerate if it does not exist
            yield put(LoadingActions.setLoading(LoadingTypes.CONTRACT_DATA, true));

            const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

            yield call(getContractDataFlow, {
                freelancerId: loggedInUser.id,
                shouldRegenerateDocumentIfItDoesNotExist: true,
            });

            yield put(LoadingActions.setLoading(LoadingTypes.CONTRACT_DATA, false));
        }

        const progress = yield select(OnboardingSelectors.selectProgress);

        yield all([
            put(AnimationActions.storeNextDispatch(
                OnboardingActions.setInternalSubStep(ContractSigningInternalSubSteps.DOCUMENT_SIGNING),
            )),
            put(AnimationActions.setIsSubAnimationActive(false)),
            put(OnboardingActions.setSubStep(ContractSigningSubSteps.SIGN_CONTRACT)),
            put(OnboardingActions.setProgress({
                ...progress,
                [OnboardingSteps.CONTRACT_SIGNING]: {
                    ...progress.CONTRACT_SIGNING,
                    subStepProgress: {
                        ...progress.COMPANY_SETUP.subStepProgress,
                        [ContractSigningSubSteps.ENTER_PERSONAL_ADDRESS]: {
                            isCompleted: true,
                        },
                        [ContractSigningSubSteps.SIGN_CONTRACT]: {
                            isCompleted: UserStatus.CONTRACT_SIGNED === user?.status,
                        },
                    },
                },
            })),
        ]);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

const goToWorkshopStepWorker = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.GO_TO_WORKSHOP_STEP, true));

    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(goToWorkshopStepFlow, {freelancerId: loggedInUser.id});

    yield put(LoadingActions.setLoading(LoadingTypes.GO_TO_WORKSHOP_STEP, false));
};

const submitPersonalAddressWorker = function* ({payload}) {
    yield put(ContractSigningActions.setIsLoadingPersonalAddressForm(true));
    const {formData, isDirty} = payload;
    yield call(submitPersonalAddressFlow, {formData, isDirty});
    yield put(ContractSigningActions.setIsLoadingPersonalAddressForm(false));
};

export const contractSigningSaga = function* () {
    yield all([
        takeEvery(ContractSigningActionTypes.GO_TO_WORKSHOP_STEP, goToWorkshopStepWorker),
        takeEvery(ContractSigningActionTypes.SUBMIT_PERSONAL_ADDRESS, submitPersonalAddressWorker),
    ]);
};
