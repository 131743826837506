import {useTheme} from '@mui/joy';

export const useChartColors = () => {
    const theme = useTheme();
    const withHiway = theme.palette.primary.solidBg;
    const currentSituation = theme.palette.neutral.softDisabledColor;
    const arce = theme.palette.tertiary[600];

    return {withHiway, currentSituation, arce};
};
