import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import VirtualCardImage from '../../../../../../assets/svg/bank/virtual-card.svg';
import {CARD_TYPES, CardPhysicalStatus} from '../../util/constants';

export const VirtualCard = ({t, user, card, setSelectedCard}) => {
    const cardNumber = card?.cardMaskedNumber
        ? card.cardMaskedNumber.replaceAll('X', '•')
        : '••••••••••••••••';
    const expieryDate = card?.expiryDate ?? '••/••';

    const numberOne = cardNumber.substring(0, 4);
    const numberTwo = cardNumber.substring(4, 8);
    const numberThree = cardNumber.substring(8, 12);
    const numberFour = cardNumber.substring(12, 16);

    const fullName = `${user.firstName} ${user.lastName}`;

    const status = card?.physicalCard?.status;
    const isProccessing = status === CardPhysicalStatus.PROCESSING;

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    color: '#13181A',
                }}
                onClick={() => {
                    setSelectedCard(CARD_TYPES.VIRTUAL);
                }}
            >
                {isProccessing && (
                    <Box sx={{
                        position: 'absolute',
                        left: 0,
                        top: '-20px',
                        width: '100%',
                        // height: '275px',
                        zIndex: 2,
                        mt: '20px',
                        backdropFilter: isProccessing ? 'blur(5.4908px)' : 'none',
                        background: 'rgba(39, 41, 42, 0.4)',
                        height: '206px',
                        borderRadius: '16px',
                    }}
                    >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            pt: '54.5px',
                        }}
                        >
                            <Box>
                                <Box sx={{
                                    width: '64px',
                                    height: '64px',
                                    backgroundColor: 'primary_v2.main',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                >
                                    <HourglassTopIcon sx={{color: 'white'}} />
                                </Box>
                            </Box>
                            <Box sx={{
                                fontWeight: 500,
                                color: 'white',
                                mt: 1,
                                mb: 2,
                            }}
                            >
                                {t('cardDetails.processingText')}
                            </Box>
                        </Box>
                    </Box>
                )}
                <Box sx={{
                    position: 'relative',
                    userSelect: 'none',
                }}
                >
                    <img style={{height: '205px'}} src={VirtualCardImage} alt="Virtual card" />
                    <Box sx={{
                        position: 'absolute',
                        top: '18px',
                        left: '25px',
                        fontWeight: 500,
                        fontSize: theme => theme.typography.pxToRem(fullName.length <= 22 ? 17.57 : 14),
                        lineHeight: fullName.length <= 22 ? '27.59px' : '19.6px',
                        letterSpacing: '0.11px',
                        maxWidth: '210px',
                        overflowX: 'auto',
                    }}
                    >
                        <Box sx={fullName.length <= 55 ? {} : {
                            'overflow': 'hidden',
                            'textOverflow': 'ellipsis',
                            'display': '-webkit-box',
                            '-webkit-line-clamp': '2',
                            '-webkit-box-orient': 'vertical',
                        }}
                        >
                            {fullName}
                        </Box>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        top: '102px',
                        left: '35px',
                        fontWeight: 400,
                        fontSize: theme => theme.typography.pxToRem(24.1595),
                        lineHeight: '116.7%',
                        letterSpacing: '3px',
                        display: 'flex',
                        gap: 1,
                    }}
                    >
                        <Box>{numberOne}</Box>
                        <Box>{numberTwo}</Box>
                        <Box>{numberThree}</Box>
                        <Box>{numberFour}</Box>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        top: '162px',
                        left: '25px',
                        fontWeight: 400,
                        fontSize: theme => theme.typography.pxToRem(14.57),
                        lineHeight: '22.87px',
                        letterSpacing: '0.09px',
                        display: 'flex',
                        gap: '35px',
                    }}
                    >
                        <Box>{expieryDate}</Box>
                        <Box>CVC •••</Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

VirtualCard.propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    card: PropTypes.object,
    setSelectedCard: PropTypes.func,
};

VirtualCard.defaultProps = {
    setSelectedCard: () => {},
    card: {},
};
