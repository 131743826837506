import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';

const TextEmoji = ({src, alt}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <img
            src={src}
            alt={alt}
            style={{
                display: 'inline-block',
                height: isMobileSize ? '30px' : '46px',
                position: 'absolute',
                marginTop: '8px',
                marginLeft: '4px',
            }}
        />
    );
};

TextEmoji.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default TextEmoji;
