import {Box, Typography} from '@mui/joy';
import PropTypes from 'prop-types';

const InfoRow = ({color, label, value}) => {
    return (
        <Box sx={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
            <Typography sx={{color: 'text.secondary'}} level="body-lg">
                {label}
            </Typography>
            <Typography color={color} sx={{fontWeight: 700}} level="body-lg">
                {value}
            </Typography>
        </Box>
    );
};

InfoRow.defaultProps = {
    color: 'primary',
};

InfoRow.propTypes = {
    color: PropTypes.oneOf(['primary', 'success', 'neutral', 'danger']),
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default InfoRow;
