import makeStyles from '@mui/styles/makeStyles';

export const useConfirmationDialogStyles = makeStyles(theme => ({
    title: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(24),
    },
    paper: {
        padding: theme.spacing(2, 3, 2, 2),
        borderRadius: theme.spacing(2),
        position: 'relative',
    },
    messageLabel: {
        marginBottom: theme.spacing(2),
        color: 'rgba(0, 0, 0, 0.87)',
    },
    dialogActionsSpacing: {
        'marginLeft': theme.spacing(3),
        'padding': 0,
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(3),
        },
    },
}));
