import PersonIcon from '@mui/icons-material/Person';
import {Box, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const Prospect = ({rowData}) => {
    const {pxToRem} = useTheme().typography;

    return (
        <Box sx={{display: 'flex', alignItems: 'center', gap: 1, fontSize: pxToRem(14)}}>
            <PersonIcon sx={{color: 'v2.blueGray.100', fontSize: pxToRem(20)}} />
            <span>
                {rowData.simulationInput.firstName} {rowData.simulationInput.lastName}
            </span>
        </Box>
    );
};

Prospect.propTypes = {
    rowData: PropTypes.object.isRequired,
};
