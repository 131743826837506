import PropTypes from 'prop-types';
import React from 'react';

import TVASelect from './TVASelect';

const TransactionTva = ({
    details,
    isEditable,
    saveTransaction,
    handleOpenArticles,
    isAmountInNewRow,
}) => {
    const articles = details?.articles;
    const defaultArticle = articles?.[0];
    const hasMultipleArticles = articles?.length > 0;

    const tvaAmount = !hasMultipleArticles
        ? Number(defaultArticle?.tvaAmount ?? 0)
        : articles.reduce((sum, article) => sum + Number(article.tvaAmount ?? 0), 0);

    const onChangeTva = value => {
        const article = {...defaultArticle};
        article.tva = value;

        saveTransaction([article]);
    };

    const changeTvaValue = value => {
        if (value === defaultArticle?.tvaAmount) {
            return;
        }

        const article = {...defaultArticle};
        article.tvaAmount = value;

        saveTransaction([article]);
    };

    return (
        <TVASelect
            details={details}
            tva={defaultArticle?.tva}
            tvaAmount={tvaAmount}
            onTvaChange={onChangeTva}
            onTvaAmountChange={changeTvaValue}
            handleOpenArticles={handleOpenArticles}
            isAmountInNewRow={isAmountInNewRow}
            isEditable={isEditable}
        />
    );
};

TransactionTva.propTypes = {
    details: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    saveTransaction: PropTypes.func.isRequired,
    handleOpenArticles: PropTypes.func.isRequired,
    isAmountInNewRow: PropTypes.bool,
};

TransactionTva.defaultProps = {
    isAmountInNewRow: false,
};

export default TransactionTva;
