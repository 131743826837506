import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {TrainingHeadline} from './header/TrainingHeadline';
import {TrainingStage} from './header/TrainingStage';
import {TrainingStepper} from './header/TrainingStepper';

const TrainingHeader = ({progress, step}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    if (!isMobileSize) {
        return (
            <Box sx={{mb: 1.5}}>
                <Box sx={{display: 'flex', flexDirection: 'row', mb: 3, alignItems: 'center'}}>
                    <TrainingHeadline />
                    <Box sx={{ml: 3, overflowX: 'auto'}}>
                        <TrainingStepper progress={progress} step={step} />
                    </Box>
                </Box>
                <TrainingStage step={step} />
            </Box>
        );
    }

    return (
        <Box sx={{py: 2}}>
            <TrainingStepper progress={progress} step={step} />
            <Box sx={{pt: 1, pb: 0.5, px: 2}}>
                <TrainingHeadline />
            </Box>
            <Box sx={{px: 2}}>
                <TrainingStage step={step} />
            </Box>
        </Box>
    );
};

TrainingHeader.propTypes = {
    progress: PropTypes.object.isRequired,
    step: PropTypes.string,
};

TrainingHeader.defaultProps = {
    step: '',
};

export default TrainingHeader;
