export const GET_USERS = 'users-list/GET_USERS';
export const STORE_USERS = 'users-list/STORE_USERS';
export const STORE_USERS_NUMBER = 'users-list/STORE_USERS_NUMBER';
export const SET_IS_LOADING_USERS = 'users-list/SET_IS_LOADING_USERS';
export const GET_USERS_SEARCH = 'users-list/GET_USERS_SEARCH';

export const getUsers = () => ({
    type: GET_USERS,
});

const getUsersSearch = payload => ({
    type: GET_USERS_SEARCH,
    payload,
});

const storeUsers = users => ({
    type: STORE_USERS,
    payload: users,
});

const storeUsersNumber = number => ({
    type: STORE_USERS_NUMBER,
    payload: number,
});

const setIsLoadingUserList = isLoading => ({
    type: SET_IS_LOADING_USERS,
    payload: isLoading,
});

export const UsersListActions = {
    getUsers,
    getUsersSearch,
    storeUsers,
    storeUsersNumber,
    setIsLoadingUserList,
};
