export const formElements = {
    'amount': 'amount',
    'currency': 'currency',
    'mccKey': 'mccKey',
    'label': 'label',
    'iban': 'iban',
    'fileList': 'fileList',
    'period': 'period',
    'description': 'description',
    'beneficiaryRadio': 'beneficiaryRadio',
    'shouldSaveBeneficiary': 'shouldSaveBeneficiary',
    'firstExecutionDate': 'firstExecutionDate',
    'lastExecutionDate': 'lastExecutionDate',
};
