import {Stack} from '@mui/joy';
import {DocumentUploadSection} from './DocumentUploadSection';
import {InputFieldsSection} from './InputFieldsSection';

export const VatDeclarationManualEdit = () => {
    return (
        <Stack spacing={2} pb={10} maxWidth="600px" margin="0 auto">
            <DocumentUploadSection />
            <InputFieldsSection />
        </Stack>
    );
};
