import {Box, Button, Chip} from '@mui/joy';
import {Alert as MuiAlert, Box as MuiBox, Button as MuiButton} from '@mui/material';
import React from 'react';
import JoyUIThemeProvider from '../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import JoyUiTestComponent from '../../components/joy-ui/test/JoyUiTestComponent';

const TestMixUi = () => {
    return (
        <MuiBox sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        }}
        >
            <Box component="section">
                <JoyUiTestComponent />
            </Box>

            <JoyUIThemeProvider>
                <Box
                    component="section"
                    sx={{
                        p: 2,
                        border: '1px dashed grey',
                    }}
                >
                    This Box renders as an HTML section element.

                    <Chip
                        variant="solid"
                        color="primary"
                        size="lg"
                    >
                        Test
                    </Chip>

                    <Button
                        variant="solid"
                        sx={{
                            // backgroundColor: 'warning.700',
                        }}
                        size="lg"
                    >
                        Test 2
                    </Button>
                </Box>
            </JoyUIThemeProvider>

            <MuiBox>
                <MuiAlert severity="success" variant="filled">
                    This is MUI Alert
                </MuiAlert>
            </MuiBox>

            <MuiBox>
                <MuiButton variant="contained">Mui Button</MuiButton>
            </MuiBox>
        </MuiBox>
    );
};

export default TestMixUi;
