import {saveAs} from 'file-saver';
import {all, call, delay, put, takeEvery} from 'redux-saga/effects';
import {RibAndDocumentsActionType} from './ribAndDocuments.action-type';
import {Toast} from '../../../../../lib/toast';
import {Debug} from '../../../../../utils/debug';
import {isMobileSafari, isSafari} from '../../../../../v1/app/app.helpers';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {BankApi} from '../../../api/bank.api';

const downloadAllStatementsAsZipFlow = function* (payload) {
    try {
        const {bankAccountId, bankAccountHolderId} = payload;
        const response = yield call(BankApi.downloadAllStatementsAsZip, {bankAccountId, bankAccountHolderId});
        const signedUrl = response.url;

        if (!signedUrl) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('The document URL is missing.');
        }

        if ((isSafari || isMobileSafari)) {
            // TODO:HIGH: It's ugly but it works.
            fetch(signedUrl).then(response => {
                return response.blob();
            }).then(blob => {
                const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
                const filename = matchedGroups[2];

                saveAs(blob, decodeURI(filename));
            });

            yield delay(400);
            yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_ALL_STATEMENTS_AS_ZIP, false));

            return;
        }

        window.open(signedUrl, '_blank');

        yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_ALL_STATEMENTS_AS_ZIP, false));
    } catch (e) {
        Debug.error('downloadAllStatementsAsZipFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_ALL_STATEMENTS_AS_ZIP, false));
    }
};

export const downloadAllStatementsAsZipWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_ALL_STATEMENTS_AS_ZIP, true));

    yield call(downloadAllStatementsAsZipFlow, payload);
};

export const watchBankRibAndDocumentsSaga = function* () {
    yield all([
        takeEvery(RibAndDocumentsActionType.DOWNLOAD_ALL_STATEMENTS_AS_ZIP, downloadAllStatementsAsZipWorker),
    ]);
};
