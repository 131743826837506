import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import EuroIcon from '@mui/icons-material/Euro';
import RestoreIcon from '@mui/icons-material/Restore';
import VerifiedIcon from '@mui/icons-material/Verified';
import {LoadingButton} from '@mui/lab';
import {Box, Chip, CircularProgress, Grow, Paper, TextField, Typography} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {useDrag} from 'react-dnd';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import ReimbursementTransactionInfoRow from './ReimbursementTransactionInfoRow';
import {ConditionalTooltip} from '../../../../../../../../components/tooltip/ConditionalTooltip';
import {roundHalfUpToTwoDecimalPlaces} from '../../../../../../../../utils/number';
import {LoadingTypes, useLoadingState} from '../../../../../../../loading';
import {DRAG_ITEMS} from '../../utils/constants';
import CategoryName from '../categories/CategoryName';

const totalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mb: 1,
    p: 1,
    borderRadius: 2,
    backgroundColor: 'v2.light.primary.shades8',
};

const allocatedStyles = {
    ...totalStyles,
    backgroundColor: '#E4F3FA',
};

const ReinbursementTransaction = ({
    transaction,
    onSelectTransaction,
    isTheBest,
    isCategorised,
    hasDnd,
    setActiveTransaction,
    activeTransaction,
    unpaidAmount,
}) => {
    const {t} = useTranslation('bank');
    const wrapperRef = useRef();

    const isLoading = useLoadingState(LoadingTypes.BANK_TRANSACTION_FIELD);
    const isLoadingDnd = useLoadingState(LoadingTypes.BANK_TRANSACTION_ADDITIONAL_FIELD);

    const {
        amount,
        executionDate,
        id,
        selected: isSelected,
        label,
        reimbursedAmount,
        totalReimbursedAmount,
        category,
    } = transaction;

    const totalRemainingAmount = amount - totalReimbursedAmount;

    const maxPartialAllocatedValue = isSelected
        ? Number(reimbursedAmount)
        : Math.min(unpaidAmount, Number(totalRemainingAmount));

    const [partialAllocatedValue, setPartialAllocatedValue] = useState(maxPartialAllocatedValue);

    const [{isDragging}, drag] = useDrag(() => ({
        type: DRAG_ITEMS.REIMBURSEMENT,
        item: {...transaction, partialAllocatedValue},
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    }), [partialAllocatedValue]);

    const isActive = (id === activeTransaction) && !isSelected;

    const allocatedAmount = isActive
        ? Number(partialAllocatedValue)
        : maxPartialAllocatedValue;

    const handleTransactionClick = _ => {
        if (!isActive) {
            setPartialAllocatedValue(maxPartialAllocatedValue);
        }

        if (activeTransaction === id) {
            setActiveTransaction('');
        } else {
            setActiveTransaction(id);
        }
    };

    const handleSelect = e => {
        e.stopPropagation();

        onSelectTransaction(id, allocatedAmount);
    };

    const handleUnselect = e => {
        e.stopPropagation();
        onSelectTransaction(id);
        return false;
    };

    const isFullReimbursed = !Number(totalRemainingAmount);
    const isUpdating = useLoadingState(LoadingTypes.BANK_TRANSACTION_FIELD);
    const invoiceStartingValue = isSelected ? Number(reimbursedAmount) : 0;

    // Partial paying amount exceeds remaining amount of the transaction
    const isAllocatedAmountExceeds = allocatedAmount - unpaidAmount > invoiceStartingValue;

    // Partial paying amount exceeds total amount or the invoice
    const isInvoiceAmountExceeds = allocatedAmount > Number(amount);

    const allocatedFieldDisabled = isCategorised && !isSelected;

    const isValidationError = !allocatedAmount
        || isAllocatedAmountExceeds
        || isInvoiceAmountExceeds
        || allocatedFieldDisabled;

    const isSaveAfterDrag = isLoading && isLoadingDnd && isLoadingDnd === transaction?.id;

    return (
        <div
            ref={hasDnd ? drag : null}
            style={{
                // visibility: isDragging ? 'hidden' : 'inherit',
                // background: isDragging ? '#F5F5F5' : 'inherit',
            }}
        >
            {isDragging && (
                <Box sx={{
                    borderRadius: '16px',
                    borderColor: isSelected ? 'primary_v2.main' : '#fff',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    backgroundColor: '#F5F5F5',
                    p: 2,
                    display: 'flex',
                    alignSelf: 'stretch',
                    flexDirection: 'column',
                    gap: '8px',
                    height: wrapperRef?.current?.clientHeight ?? '100%',
                }}
                />
            )}

            {isSaveAfterDrag && (
                <Box sx={{
                    borderRadius: '16px',
                    borderColor: '#fff',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    backgroundColor: 'v2.gray.100',
                    p: 2,
                    display: 'flex',
                    alignSelf: 'stretch',
                    flexDirection: 'column',
                    gap: '8px',
                    height: wrapperRef?.current?.clientHeight ?? '220px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                    <CircularProgress />
                </Box>
            )}

            {!isDragging && !isSaveAfterDrag && (
            <Grow in={true} timeout={100}>
                <div
                    ref={wrapperRef}
                >
                    <Paper
                        sx={{
                            borderRadius: '16px',
                            borderColor: isSelected ? 'primary_v2.main' : '#fff',
                            borderWidth: '2px',
                            borderStyle: 'solid',
                            backgroundColor: (isSelected ? 'primary_v2.main' : '#fff'),
                            p: 2,
                            display: 'flex',
                            alignSelf: 'stretch',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                        onClick={allocatedFieldDisabled
                            ? () => {}
                            : handleTransactionClick}
                        elevation={2}
                    >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignSelf: 'stretch',
                            alignItems: 'flex-start',
                            gap: '8px',
                        }}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                flex: '1 0 0',
                            }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: 2,
                                    mb: 1,
                                }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '16px',
                                            lineHeight: '22px',
                                            textTransform: 'uppercase',
                                            color: isSelected ? '#fff' : 'text_v2.primary',
                                            overflowWrap: 'anywhere',
                                        }}
                                    >
                                        {label}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '16px',
                                            lineHeight: '22px',
                                            color: isSelected ? '#fff' : 'v2.light.secondary.main',
                                            letterSpacing: '0.1px',
                                        }}
                                    >
                                        {roundHalfUpToTwoDecimalPlaces(amount)}&nbsp;€&nbsp;
                                    </Typography>
                                </Box>

                                {isTheBest && !isSelected && (
                                <Chip
                                    sx={{
                                        'mb': 1,
                                        'py': '3px',
                                        'fontSize': theme => theme.typography.pxToRem(13),
                                        'lineHeight': '18px',
                                        'borderRadius': '16px',
                                        'fontWeight': 400,
                                        'color': '#133213',
                                        'backgroundColor': 'v2.light.success.shades12',
                                        'alignSelf': 'flex-start',
                                        '& .MuiChip-icon': {
                                            color: 'v2.light.success.light',
                                        },
                                    }}
                                    size="small"
                                    variant="filled"
                                    label={t(`invoices.match`)}
                                    icon={(
                                        <VerifiedIcon sx={{ml: 0}} />
                            )}
                                />
                                )}

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: category ? 'space-between' : 'flex-end',
                                    alignItems: 'center',
                                    gap: 2,
                                    mb: 1,
                                }}
                                >
                                    {category && (
                                    <CategoryName category={category} isSelected={isSelected} />
                                    )}
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            lineHeight: '22px',
                                            color: isSelected ? '#fff' : 'v2.light.text.disabled',
                                        }}
                                    >
                                        {moment(executionDate).format('ll')}
                                    </Typography>
                                </Box>

                                {totalReimbursedAmount > 0 && !isSelected && !isActive && (
                                <Box sx={totalStyles}>
                                    <ReimbursementTransactionInfoRow
                                        title={t('transactions.alreadyReimbursed')}
                                        amount={totalReimbursedAmount}
                                        Icon={RestoreIcon}
                                    />
                                </Box>
                                )}

                                {reimbursedAmount > 0 && isSelected && (
                                <Grow in={true}>
                                    <Box sx={allocatedStyles}>
                                        <ReimbursementTransactionInfoRow
                                            title={t('transactions.allocatedAmount')}
                                            amount={reimbursedAmount}
                                            Icon={DataUsageIcon}
                                            iconColor="primary_v2.main"
                                        />
                                    </Box>
                                </Grow>
                                )}

                                {totalReimbursedAmount > 0 && !isFullReimbursed && isSelected && (
                                <Grow in={true}>
                                    <Box sx={{my: 0.5, display: 'flex', alignItems: 'center'}}>
                                        <ReimbursementTransactionInfoRow
                                            title={t('transactions.alreadyReimbursed')}
                                            amount={totalReimbursedAmount}
                                            isSelected
                                        />
                                    </Box>
                                </Grow>
                                )}

                                {isFullReimbursed && isSelected && (
                                <Grow in={true}>
                                    <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                                        <VerifiedIcon sx={{
                                            color: '#fff',
                                            mr: 0.5,
                                        }}
                                        />
                                        <Typography sx={{
                                            fontWeight: 500,
                                            fontSize: '16px',
                                            lineHeight: '26px',
                                            color: '#fff',
                                            letterSpacing: '0.1px',
                                        }}
                                        >
                                            {t(`transactions.reimbursedFull`)}
                                        </Typography>
                                    </Box>
                                </Grow>
                                )}
                            </Box>
                        </Box>

                        {isActive && (
                        <Box
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            sx={{
                                backgroundColor: 'gray_v2.50',
                                justifyContent: 'space-between',
                                margin: '0 -16px 8px -16px',
                                p: 2,
                                alignSelf: 'stretch',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                            }}
                        >
                            {totalReimbursedAmount > 0 && (
                            <Box sx={totalStyles}>
                                <ReimbursementTransactionInfoRow
                                    title={t('transactions.alreadyReimbursed')}
                                    amount={totalReimbursedAmount}
                                    Icon={RestoreIcon}
                                />
                            </Box>
                            )}
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                            >
                                <NumberFormat
                                    customInput={TextField}
                                    decimalSeparator="."
                                    thousandSeparator={false}
                                    allowNegative={false}
                                    decimalScale={2}
                                    error={isValidationError && !allocatedFieldDisabled}
                                    disabled={allocatedFieldDisabled}
                                    sx={{
                                        background: 'white',
                                    }}
                                    fullWidth
                                    size="small"
                                    label={t('transactions.allocatedField')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: <EuroIcon sx={{color: 'v2.light.action.active'}} />,
                                    }}
                                    onValueChange={({value}) => {
                                        setPartialAllocatedValue(value);
                                    }}
                                    value={partialAllocatedValue}
                                />
                                <Typography
                                    component="span"
                                    sx={{
                                        fontWeight: 400,
                                        color: 'text_v2.secondary',
                                        fontSize: theme => theme.typography.pxToRem(16),
                                    }}
                                >
                                    /&nbsp;
                                    {roundHalfUpToTwoDecimalPlaces(maxPartialAllocatedValue)}€
                                </Typography>
                            </Box>
                        </Box>
                        )}

                        <ConditionalTooltip
                            tooltip={isValidationError ? t('transactions.insufficient') : ''}
                            placement="bottom"
                        >
                            <Box>
                                {isSelected && isActive ? (
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 2,
                                        alignItems: 'center',
                                    }}
                                    >
                                        <LoadingButton
                                            fullWidth
                                            loading={isUpdating}
                                            disabled={isValidationError || !Number(amount)}
                                            loadingPosition="start"
                                            startIcon={<VerifiedIcon />}
                                            variant="contained"
                                            size="medium"
                                            onClick={
                                        handleSelect
                                    }
                                            sx={{
                                                'color': 'fff',
                                                'borderRadius': '8px',
                                                'borderColor': '#fff',
                                                'lineHeight': '24px',
                                                'letterSpacing': '0.4px',
                                                'padding': '6px 16px',
                                                'fontWeight': '500',
                                                '&:hover': {
                                                    borderColor: '#fff',
                                                },
                                                '&.Mui-disabled': {
                                                    color: 'rgba(255, 255, 255, 0.5)',
                                                    borderColor: 'rgba(255, 255, 255, 0.4)',
                                                },
                                            }}
                                        >
                                            sélectionnée
                                        </LoadingButton>

                                        <CloseIcon
                                            sx={{
                                                color: 'white',
                                                cursor: 'pointer',
                                            }}
                                            onClick={e => {
                                                e.stopPropagation();
                                                e.preventDefault();

                                                setActiveTransaction('');
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    <LoadingButton
                                        fullWidth
                                        loading={isUpdating}
                                        disabled={isValidationError || !Number(amount)}
                                        loadingPosition={isSelected ? 'start' : 'end'}
                                        startIcon={isSelected ? <CloseIcon /> : null}
                                        endIcon={isSelected ? null : <ArrowForwardIcon />}
                                        variant={isActive ? 'contained' : 'outlined'}
                                        size="medium"
                                        onClick={
                                    isActive
                                        ? handleSelect
                                        : (isSelected
                                            ? handleUnselect
                                            : handleTransactionClick
                                        )
                                        }
                                        sx={{
                                            'color': isSelected || isActive ? '#fff' : 'primary_v2.main',
                                            'borderRadius': '8px',
                                            'borderColor': isSelected || isActive ? '#fff' : 'v2.light.primary.shades50',
                                            'lineHeight': '24px',
                                            'letterSpacing': '0.4px',
                                            'padding': '6px 16px',
                                            'fontWeight': '500',
                                            '&:hover': isSelected || isActive ? {
                                                borderColor: '#fff',
                                            } : {},
                                            '&.Mui-disabled': isSelected ? {
                                                color: 'rgba(255, 255, 255, 0.5)',
                                                borderColor: 'rgba(255, 255, 255, 0.4)',
                                            } : {},
                                        }}
                                    >
                                        {isActive
                                            ? t('transactions.confirm')
                                            : (isSelected
                                                ? t('transactions.unselect')
                                                : t('invoices.select')
                                            )
                                                }

                                    </LoadingButton>
                                )}
                            </Box>
                        </ConditionalTooltip>
                    </Paper>
                </div>
            </Grow>
            )}
        </div>
    );
};

ReinbursementTransaction.propTypes = {
    transaction: PropTypes.object.isRequired,
    onSelectTransaction: PropTypes.func.isRequired,
    isTheBest: PropTypes.bool,
    isCategorised: PropTypes.bool.isRequired,
    setActiveTransaction: PropTypes.func.isRequired,
    activeTransaction: PropTypes.string.isRequired,
    unpaidAmount: PropTypes.number.isRequired,
    hasDnd: PropTypes.bool,
};

ReinbursementTransaction.defaultProps = {
    isTheBest: false,
    hasDnd: true,
};

export default ReinbursementTransaction;
