import AddBoxIcon from '@mui/icons-material/AddBox';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const AddArticle = ({
    onClick,
    t,
}) => {
    return (
        <Box
            sx={{
                cursor: 'pointer',
                borderRadius: '16px',
                backgroundColor: 'v2.light.primary.shades4',
                width: '100%',
                height: '100%',
                minHeight: '300px',
            }}
            onClick={onClick}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}
            >
                <AddBoxIcon
                    sx={{
                        fontSize: '44px',
                        color: 'primary_v2.main',
                    }}
                />
                <Typography sx={{
                    fontWeight: 500,
                    fontSize: theme => theme.typography.pxToRem(15),
                    color: 'primary_v2.main',
                    textTransform: 'uppercase',
                    letterSpacing: '0.46px',
                }}
                >
                    {t('categorisation.articles.addArticle')}
                </Typography>
            </Box>
        </Box>
    );
};

AddArticle.propTypes = {
    onClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default AddArticle;
