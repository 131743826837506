import {Slide} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import smileyEmoji from '../../../../../assets/png/smiley-emoji.png';
import capitalDepositInitalMobile from '../../../../../assets/svg/capital-deposit-initial-mobile.svg';
import capitalDepositInitial from '../../../../../assets/svg/capitalDepositInitial.svg';
import TextEmoji from '../../../../../components/text-emoji/TextEmoji';
import Banner from '../../../../../layout/ContainerWithBigImage/Banner';
import {isUserCare} from '../../../../../utils/user-roles';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {BankActions} from '../../../../bank/store/bank.action';
import {BankSelector} from '../../../../bank/store/bank.selector';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {CompaniesActions} from '../../companies';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../onboarding/utils/onboadingStepsConstant';
import {CapitalDepositInternalSubSteps, CapitalDepositStatus, KycIdentificationStatus} from '../utils/constants';

export const InitialStep = ({hasIntegrations}) => {
    const dispatch = useDispatch();

    const capitalDeposit = useSelector(BankSelector.selectCapitalDeposit);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('freelancerOnboarding');

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const bankIntegrationDataList = useSelector(BankSelector.selectIntegrations);
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const isUserCareRole = isUserCare(loggedInUser);

    const progress = useSelector(OnboardingSelectors.selectProgress);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.REGISTRATION_NOT_PREPARED),
    );

    const subStepInitialScreenNextClick = () => {
        if (hasIntegrations) {
            if (!capitalDeposit) {
                const status = bankIntegrationDataList?.[0]?.kyc?.identificationStatus;
                const kycNotValidated = status !== KycIdentificationStatus.VERIFIED;

                if (kycNotValidated) {
                    dispatch(AnimationActions.storeNextDispatch(
                        OnboardingActions.setInternalSubStep(CapitalDepositInternalSubSteps.BANK_KYC_VALIDATION),
                    ));
                    dispatch(OnboardingActions.setSubStep(CapitalDepositInternalSubSteps.BANK_KYC_VALIDATION));
                    dispatch(AnimationActions.setIsSubAnimationActive(false));
                } else {
                    dispatch(OnboardingActions.setSubStep(CapitalDepositInternalSubSteps.BANK_SEND_INFORMATION));
                    dispatch(AnimationActions.storeNextDispatch(
                        OnboardingActions.setInternalSubStep(CapitalDepositInternalSubSteps.BANK_SEND_INFORMATION),
                    ));
                    dispatch(AnimationActions.setIsSubAnimationActive(false));
                    dispatch(BankActions.startCapitalDeposit());

                    dispatch(OnboardingActions.setProgress({
                        ...progress,
                        [OnboardingSteps.CAPITAL_DEPOSIT]: {
                            ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
                            subStepProgress: {
                                ...progress[OnboardingSteps.CAPITAL_DEPOSIT].subStepProgress,
                                [CapitalDepositInternalSubSteps.BANK_KYC_VALIDATION]: {
                                    isCompleted: true,
                                },
                            },
                        },
                    }));
                }
            } else {
                // Go to screen based on status
                if (capitalDeposit?.status === CapitalDepositStatus.WAITING_FOR_SHAREHOLDERS_FUND_DEPOSIT) {
                    dispatch(OnboardingActions.setSubStep(CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER));
                    dispatch(OnboardingActions.setInternalSubStep(
                        CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER,
                    ));
                } else if (
                    capitalDeposit?.status === CapitalDepositStatus.WAITING_FOR_REQUIREMENTS_APPROVAL
                    || capitalDeposit?.status === CapitalDepositStatus.UPLOADED_DOCUMENTS_REFUSED
                    || capitalDeposit?.status === CapitalDepositStatus.WAITING_FOR_NOTARY_DEPOSIT_CERTIFICATE
                    || capitalDeposit?.status === CapitalDepositStatus.NOTARY_DEPOSIT_CERTIFICATE_UPLOADED
                    || capitalDeposit?.status === CapitalDepositStatus.WAITING_FOR_NOTARY_TRANSFER
                ) {
                    dispatch(OnboardingActions.setSubStep(
                        CapitalDepositInternalSubSteps.BANK_CAPITOL_DEPOSIT_VALIDATION,
                    ));
                    dispatch(OnboardingActions.setInternalSubStep(
                        CapitalDepositInternalSubSteps.BANK_CAPITOL_DEPOSIT_VALIDATION,
                    ));
                } else if (capitalDeposit?.status
                    === CapitalDepositStatus.WAITING_FOR_INDIVIDUAL_ACCOUNT_HOLDER_VERIFICATION) {
                    dispatch(OnboardingActions.setSubStep(CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION));
                    dispatch(OnboardingActions.setInternalSubStep(
                        CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION,
                    ));
                } else {
                    // Something unexpected happen, perhaps reloading is the best
                    dispatch(OnboardingActions.determineStepSubStep());
                }
                dispatch(AnimationActions.setIsSubAnimationActive(false));
            }
        } else {
            dispatch(CompaniesActions.registrationNotPrepared());
        }
    };

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    if (!isMobileSize) {
        return (
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <div>
                    <Banner
                        header={(
                            <>
                                <Trans
                                    t={t}
                                    i18nKey={
                                        isUserCareRole
                                            ? 'capitalDepositStep1.headlineCare'
                                            : 'capitalDepositStep1.headline'
                                    }
                                    components={{bold: <Box component="span" sx={{color: '#E84C4C'}} />}}
                                />
                                <TextEmoji src={smileyEmoji} alt="smiley emoji" />
                            </>
                        )}
                        text={t('capitalDepositStep1.text')}
                        Illustration={(
                            <Box
                                component="div"
                                sx={{
                                    mt: 10,
                                    mb: 1,
                                    position: 'relative',
                                    height: '400px',
                                    left: '48px',
                                }}
                            >
                                <img src={capitalDepositInitial} alt="Capital deposit inital" />
                            </Box>
                        )}
                        nextButtonText={t('capitalDepositStep1.continueButton')}
                        nextButtonProps={
                            isLoading
                        }
                        onNextClick={subStepInitialScreenNextClick}
                    />
                </div>
            </Slide>
        );
    }

    return (
        <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
            <div>
                <Banner
                    header={(
                        <>
                            <Trans
                                t={t}
                                i18nKey={
                                    isUserCareRole
                                        ? 'capitalDepositStep1.headlineCare'
                                        : 'capitalDepositStep1.headline'
                                }
                                components={{bold: <Box component="span" sx={{color: '#E84C4C'}} />}}
                            />
                            <TextEmoji src={smileyEmoji} alt="smiley emoji" />
                        </>
                    )}
                    text={t('capitalDepositStep1.text')}
                    Illustration={(
                        <Box
                            component="div"
                            sx={{
                                mt: 1,
                                height: '400px',
                            }}
                        >
                            <img src={capitalDepositInitalMobile} alt="Capital deposit inital" />
                        </Box>
                    )}
                    nextButtonText={t('capitalDepositStep1.continueButton')}
                    nextButtonProps={
                        isLoading
                    }
                    onNextClick={subStepInitialScreenNextClick}
                />
            </div>
        </Slide>
    );
};

InitialStep.propTypes = {
    hasIntegrations: PropTypes.bool.isRequired,
};
