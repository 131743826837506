import {Freelancer} from './freelancer.dto';
import {hiwayApiInstance} from '../../../lib/hiway-api-instance';

const getAccount = ({freelancerId}) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}`)
        .then(result => new Freelancer(result.data));
};

export const FreelancerApi = {
    getAccount,
};
