import {faRotateRight} from '@fortawesome/free-solid-svg-icons';
import {Link, Stack, Typography} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ErrPageWrapper} from './common/ErrPageWrapper';
import withJoyUI from '../../../components/joy-ui/joyui-theme-provider/JoyUiThemePRoviderWrapper';

const MaintenanceOverlay = () => {
    const {t} = useTranslation('common');

    const onSubmit = event => {
        event.preventDefault();
        window.location.reload();
    };

    const link = (
        <Link
            sx={{
                color: '#0A7BC4',
                textDecoration: 'none',
            }}
            href="mailto:support@hiway.fr"
        >
            support@hiway.fr
        </Link>
    );

    return (
        <ErrPageWrapper
            onSubmit={onSubmit}
            title={t('maintenanceOverlay.message')}
            buttonLabel={t('maintenanceOverlay.buttonTitle')}
            buttonIcon={faRotateRight}
        >
            <Typography
                level="h1"
                sx={{
                    color: 'text.primary',
                    fontSize: {wide: '140px', desktop: '100px', mobile: '50px'},
                }}
            >
                {t('maintenanceOverlay.title')}
            </Typography>

            <Stack
                direction="column"
                alignItems="center"
            >
                <Typography level="body-lg" sx={{color: 'text.secondary'}}>
                    {t('maintenanceOverlay.firstMessage')}
                </Typography>

                <Typography level="body-lg" sx={{color: 'text.secondary'}}>
                    {t('maintenanceOverlay.secondMessage')} {link}
                </Typography>
            </Stack>
        </ErrPageWrapper>
    );
};

export default withJoyUI(MaintenanceOverlay);
