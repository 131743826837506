export const GET_AVAILABLE_FREELANCERS = 'GET_AVAILABLE_FREELANCERS';
export const STORE_AVAILABLE_FREELANCERS = 'STORE_AVAILABLE_FREELANCERS';
export const SET_IS_LOADING_AVAILABLE_FREELANCERS = 'SET_IS_LOADING_AVAILABLE_FREELANCERS';

export const getAvailableFreelancers = userId => ({
    type: GET_AVAILABLE_FREELANCERS,
    payload: {
        userId,
    },
});

export const storeAvailableFreelancers = freelancers => ({
    type: STORE_AVAILABLE_FREELANCERS,
    payload: freelancers,
});

export const setIsLoadingAvailableFreelancers = isLoading => ({
    type: SET_IS_LOADING_AVAILABLE_FREELANCERS,
    payload: isLoading,
});
