import {Box, Typography} from '@mui/joy';
import {Box as MuiBox} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {ConditionalTooltip} from '../../../../../../../components/tooltip/ConditionalTooltip';

const AccountBalanceTableDataRowDate = ({
    data,
    isNewDate,
    isV3,
}) => {
    if (isV3) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    minWidth: '35px',
                    height: '100%',
                    minHeight: '54px',
                    backgroundColor: 'white !important',
                }}
            >
                {isNewDate ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        alignItems: 'center',
                        minWidth: '35px',
                        minHeight: '54px',
                        justifyContent: 'center',
                    }}
                    >
                        <Typography
                            level="h4"
                            sx={{
                                fontSize: 'xl',
                                lineHeight: 'lg',
                                fontWeight: 'xl',
                                fontStyle: 'italic',
                                color: 'text.secondary',
                            }}
                        >
                            {data.date.format('D')}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 'xs',
                                lineHeight: 'xs',
                                fontStyle: 'italic',
                                fontWeight: 'xl',
                                textTransform: 'uppercase',
                                color: 'text.tertiary',
                            }}
                        >
                            {data.date.format('MMM')}
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        alignItems: 'center',
                        minWidth: '35px',
                        height: '100%',
                        minHeight: '56px',
                        position: 'relative',
                        my: '-1px',
                    }}
                    >
                        <Box sx={{
                            borderRight: '1px solid',
                            borderColor: 'primary.100',
                            display: 'flex',
                            flexGrow: 1,
                            zIndex: 4,
                        }}
                        />
                        <ConditionalTooltip
                            tooltip={
                                data.date.format('DD MMMM YYYY')
                            }
                            placement="left"
                            isV3
                        >
                            <Box sx={{
                                backgroundColor: 'primary.100',
                                position: 'absolute',
                                top: `calc(50% - 7px)`,
                                left: `calc(50% - 7px)`,
                                borderRadius: '50%',
                                border: '4px solid white',
                                padding: '3px',
                                zIndex: 5,
                            }}
                            />
                        </ConditionalTooltip>
                    </Box>
                )}
            </Box>
        );
    }

    return (
        <MuiBox
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
                color: 'blueGray.400',
                fontFamily: 'Barlow',
                minWidth: '35px',
                height: '100%',
                minHeight: '80px',
                backgroundColor: 'white !important',
                pl: 2,
            }}
        >
            {isNewDate ? (
                <MuiBox sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    minWidth: '35px',
                    minHeight: '80px',
                    justifyContent: 'center',
                    color: 'v2.blueGray.400',
                }}
                >
                    <MuiBox sx={{
                        fontSize: theme => theme.typography.pxToRem(28),
                        fontWeight: 500,
                        lineHeight: '31px',
                    }}
                    >
                        {data.date.format('D')}
                    </MuiBox>
                    <MuiBox sx={{
                        fontSize: theme => theme.typography.pxToRem(10),
                        fontWeight: 500,
                        textTransform: 'uppercase',
                    }}
                    >
                        {data.date.format('MMM')}
                    </MuiBox>
                </MuiBox>
            ) : (
                <MuiBox sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    minWidth: '35px',
                    height: '100%',
                    minHeight: '80px',
                    position: 'relative',
                }}
                >
                    <MuiBox sx={{
                        borderRight: '1px solid #333',
                        // borderStyle: 'solid',
                        borderColor: 'v2.blueGray.200',
                        display: 'flex',
                        flexGrow: 1,
                        zIndex: 4,
                    }}
                    />
                    <ConditionalTooltip
                        tooltip={
                            data.date.format('DD MMMM YYYY')
                        }
                        placement="left"
                    >
                        <MuiBox sx={{
                            backgroundColor: 'v2.blueGray.200',
                            position: 'absolute',
                            top: `calc(50% - 7px)`,
                            left: `calc(50% - 7px)`,
                            borderRadius: '50%',
                            border: '4px solid white',
                            padding: '3px',
                            zIndex: 5,
                        }}
                        />
                    </ConditionalTooltip>
                </MuiBox>
            )}
        </MuiBox>
    );
};

AccountBalanceTableDataRowDate.propTypes = {
    data: PropTypes.object.isRequired,
    isNewDate: PropTypes.bool.isRequired,
    isV3: PropTypes.bool, // TODO: make true by default and delete the code, related to false value
};

AccountBalanceTableDataRowDate.defaultProps = {
    isV3: false,
};

export default AccountBalanceTableDataRowDate;
