import {TransfersActionType} from './transfers.action-type';

export const TransfersActions = {
    storeList: data => ({
        type: TransfersActionType.STORE_LIST,
        payload: data,
    }),
    getTransfer: data => ({
        type: TransfersActionType.GET_TRANSFER,
        payload: data,
    }),
    storeTransfer: data => ({
        type: TransfersActionType.STORE_TRANSFER,
        payload: data,
    }),
    create: data => ({
        type: TransfersActionType.CREATE,
        payload: data,
    }),
    update: data => ({
        type: TransfersActionType.UPDATE,
        payload: data,
    }),
    cancel: data => ({
        type: TransfersActionType.CANCEL,
        payload: data,
    }),
    uploadTransferFile: params => ({
        type: TransfersActionType.UPLOAD_TRANSFER_FILE,
        payload: params,
    }),
    deleteTransferFile: params => ({
        type: TransfersActionType.DELETE_TRANSFER_FILE,
        payload: params,
    }),
    storeRemuneration: params => ({
        type: TransfersActionType.STORE_REMUNERATION,
        payload: params,
    }),
};
