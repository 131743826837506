export const TaxIncomeFrom = function (data) {
    this.numberOfTaxShare = data.double_of_parts;
    this.numberOfAdults = data.number_of_adults;

    this.joinedSalaries = data.companion_salary_or_unemployment_revenue;
    this.joinedMicroEnterprise = data.companion_microcompany_turnover;

    this.employmentCenter = data.unemployment_revenue;
    this.employeeIncome = data.salary_revenue;
    this.microEnterprise = data.microcompany_turnover;
};

export const TaxIncomeTo = function (data) {
    if (data.numberOfAdults === 2) {
        return {
            double_of_parts: data.numberOfTaxShare,
            number_of_adults: data.numberOfAdults,

            companion_salary_or_unemployment_revenue: data.joinedSalaries,
            companion_microcompany_turnover: data.joinedMicroEnterprise,

            salary_revenue: data.employeeIncome,
            unemployment_revenue: data.employmentCenter,
            microcompany_turnover: data.microEnterprise,

            year: data.year,
        };
    }
    return {
        double_of_parts: data.numberOfTaxShare,
        number_of_adults: data.numberOfAdults,

        companion_salary_or_unemployment_revenue: 0,
        companion_microcompany_turnover: 0,

        salary_revenue: data.employeeIncome,
        unemployment_revenue: data.employmentCenter,
        microcompany_turnover: data.microEnterprise,

        year: data.year,
    };
};
