import LaunchIcon from '@mui/icons-material/Launch';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {resolveRoute} from '../../../../../v1/navigation/resolveRoute';

export const ViewResultsButton = ({rowData}) => {
    const {t} = useTranslation('revenueSimulator');
    const {isExpired} = rowData;

    return (
        <Box
            component={isExpired ? Box : Link}
            to={resolveRoute(RoutePaths.REVENUE_SIMULATOR_RESULTS, {simulationId: rowData.id})}
        >
            <Button
                variant="outlined"
                endIcon={<LaunchIcon />}
                disabled={isExpired}
                sx={{
                    color: 'primary_v2.main',
                    px: 2,
                    my: 1,
                    borderRadius: '8px',
                    height: theme => theme.typography.pxToRem(36),
                }}
            >
                {t('simulationsList.seeTheResults')}
            </Button>
        </Box>
    );
};

ViewResultsButton.propTypes = {
    rowData: PropTypes.object.isRequired,
};
