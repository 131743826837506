import {combineReducers} from 'redux';

import * as actions from './companyList.actions';
import {createReducer} from '../../createReducer';

export const companyListReducer = combineReducers({
    companies: createReducer({}, actions.STORE_COMPANIES, true),
    hiwayCompanies: createReducer(0, actions.STORE_HIWAY_COMPANIES, true),
    total: createReducer({}, actions.STORE_COMPANIES_TOTAL, true),
    isLoading: createReducer(true, actions.SET_IS_LOADING_COMPANIES, true),
    trainingCompanies: createReducer({}, actions.STORE_TRAINING_COMPANIES, true),
});
