import CloudOffIcon from '@mui/icons-material/CloudOff';
import DownloadIcon from '@mui/icons-material/Download';
import {Box, Button, CircularProgress, Paper, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Document, Page} from 'react-pdf';
import {useSelector} from 'react-redux';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {RibAndDocumentsSelector} from '../store/ribAndDocuments.selector';
import {
    DOWNLOADED_RIB_FILE_NAME,
    LG_RIB_PREVIEW_WIDTH,
    lm,
    pdfPageAspectRatio,
    pdfPageWidth,
    pixelDensity,
    tm,
} from '../utils/constants';

const RibPreviewAndDownload = () => {
    const {t} = useTranslation('bankRibAndDocuments');
    const [pageData, setPageData] = useState({width: 0, height: 0, canvasUrl: ''});

    const isMobileSize = useMediaQuery(`(max-width:1280px)`);

    const ribDocument = useSelector(RibAndDocumentsSelector.selectRibData);
    const dataUrl = ribDocument?.documentDataUrl;

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GET_RIB_DOCUMENT));
    const hasError = !isLoading && null === dataUrl;

    const onRenderSuccess = () => {
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        const pdfPageCanvas = document.querySelector('.import-pdf-page canvas');

        const pageWidth = pdfPageWidth;

        const leftMargin = lm * pixelDensity;
        const cardWidth = (pageWidth - 2 * lm) * pixelDensity;

        const pageHeight = pdfPageWidth / pdfPageAspectRatio;

        const topMargin = tm * pixelDensity;
        const cardHeight = (pageHeight - 2 * tm) * pixelDensity;


        canvas.width = cardWidth;
        canvas.height = cardHeight;
        canvasContext.drawImage(
            pdfPageCanvas,
            leftMargin,
            topMargin,
            cardWidth,
            cardHeight,
            0,
            0,
            cardWidth,
            cardHeight,
        );

        setPageData({
            width: cardWidth,
            height: cardHeight,
            canvasUrl: canvas.toDataURL(),
        });
    };

    const downloadRibDocument = () => {
        window.saveAs(dataUrl, DOWNLOADED_RIB_FILE_NAME);
    };

    return (
        <Paper
            sx={{
                height: {
                    xs: (window.innerWidth - 2 * 16) / LG_RIB_PREVIEW_WIDTH * 300, // 2 * x margin
                    md: (window.innerWidth - 2 * 32 - 240) / LG_RIB_PREVIEW_WIDTH * 300, // 2 * x margin - 240 (sidebar)
                    lg: 300,
                },
                borderRadius: 4,
                overflow: 'hidden',
                bgcolor: 'whitePallete.default',
                minWidth: {
                    xs: 'fit-content',
                    md: LG_RIB_PREVIEW_WIDTH,
                },
                maxWidth: isMobileSize ? 'auto' : LG_RIB_PREVIEW_WIDTH,
            }}
            elevation={4}
        >
            {hasError && (
                <Box
                    component="div"
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <CloudOffIcon sx={{fontSize: '72px', color: 'v2.blueGray.300'}} />
                    <Typography
                        sx={{
                            my: 1.25,
                            fontSize: theme => theme.typography.pxToRem(16),
                            fontWeight: 500,
                            color: 'v2.light.text.secondary',
                        }}
                    >
                        {t('errorFetchingRibDocument')}
                    </Typography>
                </Box>
            )}
            {!pageData.canvasUrl && (
                <>
                    <Box component="div" sx={{position: 'fixed', left: '100vw', top: '100vw'}}>
                        <Document
                            file={dataUrl}
                            sx={{width: pdfPageWidth, height: 'auto'}}
                            externalLinkTarget="_blank"
                            options={{workerSrc: 'pdf.worker.min.js'}}
                            width={pdfPageWidth}
                        >
                            <Page
                                pageNumber={1}
                                className="import-pdf-page"
                                onRenderSuccess={onRenderSuccess}
                                width={pdfPageWidth}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        </Document>
                    </Box>
                </>
            )}
            {!pageData.canvasUrl && !hasError && (
                <Box
                    sx={{
                        display: 'flex',
                        width: 'inherit',
                        height: 'inherit',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={50} />
                </Box>
            )}
            {!!pageData.canvasUrl && (
                <Paper
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <img
                        src={pageData.canvasUrl}
                        style={{
                            height: isMobileSize ? 'auto' : '300px',
                            width: 'inherit',
                        }}
                        alt="PDF Page"
                    />
                    <Box
                        component="div"
                        sx={{
                            position: 'absolute',
                            bottom: '0',
                            height: '20%',
                            width: 'inherit',
                            bgcolor: 'rgba(144, 164, 174, 0.1)',
                            backdropFilter: 'blur(2px)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                height: '100%',
                                justifyContent: 'end',
                                p: 2,
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="error"
                                size="small"
                                startIcon={<DownloadIcon sx={{width: '16px'}} />}
                                sx={{
                                    bgcolor: 'v2.light.secondary.main',
                                    borderRadius: 1.5,
                                    maxHeight: 30,
                                }}
                                onClick={downloadRibDocument}
                            >
                                {t('downloadPdf')}
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            )}
        </Paper>
    );
};

export default RibPreviewAndDownload;
