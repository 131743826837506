/* eslint-disable react/prop-types */
import {
    faCalendarDay,
    faClock,
    faPen,
} from '@fortawesome/free-solid-svg-icons';
import composeClasses from '@mui/base/composeClasses';
import {Grid, IconButton, Typography} from '@mui/joy';
import {styled, useThemeProps} from '@mui/system';
import clsx from 'clsx';
import * as React from 'react';
import {
    getPickersToolbarUtilityClass,
    pickersToolbarClasses,
} from './pickersToolbarClasses';
import {InputIcon} from '../../../../../features/auth/components/common/InputIcon';
import {useLocaleText} from '../hooks/useUtils';

const useUtilityClasses = ownerState => {
    const {classes, isLandscape} = ownerState;
    const slots = {
        root: ['root'],
        content: ['content'],
        penIconButton: ['penIconButton', isLandscape && 'penIconButtonLandscape'],
    };

    return composeClasses(slots, getPickersToolbarUtilityClass, classes);
};

const PickersToolbarRoot = styled('div', {
    name: 'MuiPickersToolbar',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})(({theme, ownerState}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
    ...(ownerState.isLandscape && {
        height: 'auto',
        maxWidth: 160,
        padding: 16,
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    }),
}));

const PickersToolbarContent = styled(Grid, {
    name: 'MuiPickersToolbar',
    slot: 'Content',
    overridesResolver: (_, styles) => styles.content,
})(({ownerState}) => ({
    flex: 1,
    ...(!ownerState.isLandscape && {
        alignItems: 'center',
    }),
}));

const PickersToolbarPenIconButton = styled(IconButton, {
    name: 'MuiPickersToolbar',
    slot: 'PenIconButton',
    overridesResolver: (_, styles) => [
        {
            [`&.${pickersToolbarClasses.penIconButtonLandscape}`]: styles.penIconButtonLandscape,
        },
        styles.penIconButton,
    ],
})({});

const getViewTypeIcon = viewType => (viewType === 'clock' ? (
    <InputIcon icon={faClock} />
) : (
    <InputIcon icon={faCalendarDay} />
));

export const PickersToolbar = React.forwardRef((
    inProps,
    ref,
) => {
    const props = useThemeProps({props: inProps, name: 'MuiPickersToolbar'});
    const {
        children,
        className,
        getMobileKeyboardInputViewButtonText,
        isLandscape,
        isMobileKeyboardViewOpen,
        landscapeDirection = 'column',
        toggleMobileKeyboardView,
        toolbarTitle,
        viewType = 'calendar',
    } = props;

    const ownerState = props;
    const localeText = useLocaleText();
    const classes = useUtilityClasses(ownerState);

    return (
        <PickersToolbarRoot
            ref={ref}
            data-mui-test="picker-toolbar"
            className={clsx(classes.root, className)}
            ownerState={ownerState}
        >
            <Typography
                data-mui-test="picker-toolbar-title"
                color="text.secondary"
                level="title-lg"
            >
                {toolbarTitle}
            </Typography>
            <PickersToolbarContent
                container
                justifyContent="space-between"
                className={classes.content}
                ownerState={ownerState}
                direction={isLandscape ? landscapeDirection : 'row'}
                alignItems={isLandscape ? 'flex-start' : 'flex-end'}
                alignSelf="stretch"
            >
                {children}
                <PickersToolbarPenIconButton
                    onClick={toggleMobileKeyboardView}
                    className={classes.penIconButton}
                    ownerState={ownerState}
                    color="inherit"
                    variant="plain"
                    data-mui-test="toggle-mobile-keyboard-view"
                    aria-label={
                        getMobileKeyboardInputViewButtonText
                            ? getMobileKeyboardInputViewButtonText(
                                isMobileKeyboardViewOpen,
                                viewType,
                            )
                            : localeText.inputModeToggleButtonAriaLabel(
                                isMobileKeyboardViewOpen,
                                viewType,
                            )
                    }
                >
                    {isMobileKeyboardViewOpen ? (
                        getViewTypeIcon(viewType)
                    ) : (
                        <InputIcon icon={faPen} />
                    )}
                </PickersToolbarPenIconButton>
            </PickersToolbarContent>
        </PickersToolbarRoot>
    );
});
