export const Training = function (dto) {
    this.startDate = dto?.start_date;
    this.endDate = dto?.end_date;
    this.status = dto?.status;
    this.isEligible = dto?.is_eligible;
    this.hideAlertUntil = dto?.hide_training_alert_notification_until;
};

const PayInfo = function (dto) {
    this.status = dto?.status;
    this.payrollStartDate = dto?.payroll_configuration?.start_date || null;
};

export const Company = function (dto) {
    this.id = dto.id;
    this.name = dto.name;
    this.companyType = dto.company_type;
    this.legalForm = dto.legal_form;
    this.status = dto.status;
    this.type = dto.company_type;
    this.iban = dto.company_info?.iban;
    this.bic = dto.company_info?.bic;

    this.training = new Training(dto.training_info);
    this.payInfo = new PayInfo(dto.pay_info);
    this.registrationType = dto?.enterprise_info?.registration_type;
    this.enterpriseInformation = dto.enterprise_info;
    this.companyInfo = dto.company_info;
    this.contactInfo = dto.contact_info;
};
