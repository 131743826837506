
import {Stack} from '@mui/joy';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet} from 'react-router-dom';
import VatDeclarationNotifications from './components/VatDeclarationNotifications';
import PageHeader from '../../components/joy-ui/headers/PageHeader';
import JoyUIThemeProvider from '../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {FormalitiesTabMenu} from '../../features/company-profile/modules/formalities/components/tabs/FormalitiesTabMenu';
import {FORMALITIES_TABS} from '../../features/company-profile/modules/formalities/utils/constants';
import ProcessMultiDeclarationsModal from '../../features/formalities/modules/vat-declaration/components/modals/ProcessMultiDeclarationModal/ProcessMultiDeclarationModal';
import ProcessSingleVatDeclaration from '../../features/formalities/modules/vat-declaration/components/modals/ProcessSingleDeclarationModal';
import SendDeclarationsModal from '../../features/formalities/modules/vat-declaration/components/modals/SendDeclarationsModal';
import {UiSelectors} from '../../features/ui/store/ui.selector';
import {ModalsKeys} from '../../features/ui/utils/constants';
import {useMemoizedCreateSelector} from '../../hooks/use-memoized-create-selector';
import {importLocaleBundle} from '../../lib/i18next';
import {replace, selectRouterLocation} from '../../lib/router/connected-router-saga';
import {RoutePaths} from '../../lib/router/route-paths';

// TODO: HPD-5014 - uncomment after BE be ready
// importLocaleBundle('vatDeclaration');
importLocaleBundle('financialStatements');

export const FormalitiesScreen = () => {
    const {t: tSidebar} = useTranslation('sidebar');
    // const {t: tVatDeclaration} = useTranslation('vatDeclaration');

    const isProcessSingleModalOpen = useMemoizedCreateSelector(
        UiSelectors.createIsModalActiveSelector, ModalsKeys.PROCESS_SINGLE_VAT_DECLARATION,
    );
    const isProcessMultiModalOpen = useMemoizedCreateSelector(
        UiSelectors.createIsModalActiveSelector, ModalsKeys.PROCESS_MULTIPLE_VAT_DECLARATION,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const pathToTabName = {
        [RoutePaths.VAT_DECLARATION]: FORMALITIES_TABS.VAT_DECLARATION,
        [RoutePaths.FINANCIAL_STATEMENTS]: FORMALITIES_TABS.DEPOSIT_OF_ACCOUNTS,
    };

    const tabNameToPath = {
        [FORMALITIES_TABS.VAT_DECLARATION]: RoutePaths.VAT_DECLARATION,
        [FORMALITIES_TABS.DEPOSIT_OF_ACCOUNTS]: RoutePaths.FINANCIAL_STATEMENTS,
    };

    const location = useSelector(selectRouterLocation);
    const [activeTab, setActiveTab] = useState(pathToTabName[location.pathname]);
    const dispatch = useDispatch();

    const handleChange = newValue => {
        if (!newValue) return;
        setActiveTab(newValue);
        dispatch(replace(tabNameToPath[newValue]));
    };

    useEffect(() => {
        setActiveTab(pathToTabName[location.pathname]);
    }, [location.pathname, pathToTabName]);

    return (
        <JoyUIThemeProvider>
            <Stack spacing={3}>
                <VatDeclarationNotifications />
                <PageHeader title={tSidebar('administratorSidebar.formalities')} />

                <FormalitiesTabMenu
                    activeTab={activeTab}
                    onChange={handleChange}
                />

                <Outlet />

                {/* Modals */}
                <SendDeclarationsModal />
                {isProcessSingleModalOpen && <ProcessSingleVatDeclaration />}
                {isProcessMultiModalOpen && <ProcessMultiDeclarationsModal />}
            </Stack>
        </JoyUIThemeProvider>
    );
};
