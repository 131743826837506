import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getDateTimePickerTabsUtilityClass = slot => {
    return generateUtilityClass('MuiDateTimePickerTabs', slot);
};

export const dateTimePickerTabsClasses = generateUtilityClasses(
    'MuiDateTimePickerTabs',
    ['root'],
);
