import {Box} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import Dossier from './dossier/Dossier';
import DossierCompleted from './dossier/DossierCompleted';
import Finalization from './finalization/Finalization';
import FinalizationCompleted from './finalization/FinalizationCompleted';
import FundingApproval from './funding-approval/FundingApproval';
import FundingApprovalCompleted from './funding-approval/FundingApprovalCompleted';
import TrainingFeatureSkeleton from './general/TrainingFeatureSkeleton';
import MyAtlas from './my-atlas/MyAtlas';
import MyAtlasCompleted from './my-atlas/MyAtlasCompleted';
import OpcoRegistration from './opco-registration/OpcoRegistration';
import OpcoRegistrationCompleted from './opco-registration/OpcoRegistrationCompleted';
import TrainingHeader from './TrainingHeader';
import {importLocaleBundle} from '../../../lib/i18next';
import {LoadingSelectors, LoadingTypes} from '../../loading';
import {TrainingSelector} from '../store/training.selector';
import {TRAINING_CONSTANTS} from '../utils/constants';

importLocaleBundle('training');

export const TrainingFeature = () => {
    const progress = useSelector(TrainingSelector.getTrainingProgress);
    const step = useSelector(TrainingSelector.getTrainingStep);

    const isLoadingTrainingData = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.DETERMINE_TRAINING_STEP),
    );

    let content = null;
    let completed = false;

    switch (step) {
        case TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION:
            completed = progress[TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION].completed;
            content = !completed
                ? <OpcoRegistration />
                : <OpcoRegistrationCompleted />;
            break;
        case TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS:
            completed = progress[TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS].completed;
            content = !completed
                ? <MyAtlas />
                : <MyAtlasCompleted />;
            break;
        case TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER:
            completed = progress[TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER].completed;
            content = !completed
                ? <Dossier />
                : <DossierCompleted />;
            break;
        case TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL:
            completed = progress[TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL].completed;
            content = !completed
                ? <FundingApproval />
                : <FundingApprovalCompleted />;
            break;
        case TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION:
            completed = progress[TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION].completed;
            content = !completed
                ? <Finalization />
                : <FinalizationCompleted />;
            break;
    }

    if (isLoadingTrainingData) {
        return (
            <TrainingFeatureSkeleton />
        );
    }

    return (
        <Box>
            <TrainingHeader progress={progress} step={step} />
            <Box>
                {content}
            </Box>
        </Box>
    );
};
