import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ModalWrapper} from '../../../components/modal-wrapper/ModalWrapper';
import {UiActions} from '../../ui/store/ui.action';
import {ModalsKeys} from '../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {InsuranceActions} from '../store/insurance.action';

const SignModal = ({freelancerId, companyId}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('insurance');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const navigateTo = useNavigate();

    const {isFullyActive} = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const handleCloseModal = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.INSURANCE_SIGN_MODAL, false));
    };

    const handleConfirm = () => {
        dispatch(InsuranceActions.generateInsuranceDocument({freelancerId, companyId, isFullyActive, navigateTo}));

        dispatch(UiActions.setActiveModal(ModalsKeys.INSURANCE_SIGN_MODAL, false));
    };

    return (
        <ModalWrapper
            modalKey={ModalsKeys.INSURANCE_SIGN_MODAL}
            handleCloseCB={handleCloseModal}
            hasShowClose={true}
            containerSx={{width: '608px'}}
            title={(
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(34),
                    fontWeight: 700,
                    lineHeight: '116.7%',
                    pb: 1,
                }}
                >
                    {t(`signModal.title`)}
                </Typography>
            )}
        >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 6}}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2}}>
                    <Typography>{t(`signModal.info`)}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: isMobileSize ? 'column' : 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: isMobileSize ? 2 : 3,
                    flex: '1 0 0',
                    alignSelf: 'stretch',
                }}
                >
                    <Button
                        fullWidth={isMobileSize}
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        onClick={handleCloseModal}
                    >
                        {t('signModal.back')}
                    </Button>
                    <Button
                        fullWidth={isMobileSize}
                        variant="contained"
                        color="secondary"
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleConfirm}
                    >
                        {t('signModal.confirm')}
                    </Button>
                </Box>
            </Box>
        </ModalWrapper>
    );
};

SignModal.propTypes = {
    freelancerId: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
};

export default SignModal;
