export const SET_SELECTED_COMPANY_ID = 'company/currentCompany/SET_SELECTED_COMPANY';
export const STORE_SELECTED_COMPANY_ID = 'company/currentCompany/STORE_SELECTED_COMPANY_ID';

export const setSelectedCompanyId = (companyId, isManualAction = false) => ({
    type: SET_SELECTED_COMPANY_ID,
    payload: {
        companyId,
        isManualAction,
    },
});

export const storeSelectedCompanyId = companyId => ({
    type: STORE_SELECTED_COMPANY_ID,
    payload: companyId,
});
