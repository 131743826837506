import DeleteIcon from '@mui/icons-material/Delete';
import {Grid, IconButton} from '@mui/material';
import PropTypes from 'prop-types';

const RemoveJobProfessionButton = props => {
    const {isEnabled, selectedJobProfession, onRowDelete} = props;
    return (
        <Grid item xs={2} sm={1} sx={{textAlign: 'center'}}>
            <IconButton
                disabled={!isEnabled}
                sx={{color: 'error.main', mt: 2, p: 0}}
                onClick={() => onRowDelete(selectedJobProfession)}
            >
                <DeleteIcon />
            </IconButton>
        </Grid>
    );
};

RemoveJobProfessionButton.propTypes = {
    isEnabled: PropTypes.bool.isRequired,
    selectedJobProfession: PropTypes.string.isRequired,
    onRowDelete: PropTypes.func.isRequired,
};

export default RemoveJobProfessionButton;
