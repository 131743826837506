import moment from 'moment';

import {isDate, isNumber, isString} from './index';

export const createDataTableSortCallback = (sortBy, sortDirection) => (previousItem, nextItem) => {
    const previousValue = previousItem[sortBy];
    const nextValue = nextItem[sortBy];

    if (previousValue === null || previousValue === undefined) {
        // The value is missing, so we put these after
        return 1;
    }

    if (nextValue === null || nextValue === undefined) {
        // The value is missing, so we put these after
        return -1;
    }

    if (isNumber(previousValue) || isNumber(nextValue)) {
        return sortDirection === 'asc' ? previousValue - nextValue : nextValue - previousValue;
    }

    /**
     * We get dates as strings, so its important that we check if the value is date
     * before we check if the value is string
     */
    if (isDate(previousValue) || isDate(nextValue)) {
        const dateFormat = 'DD/MM/YYYY';

        const previousDate = moment(previousValue, dateFormat);
        const nextDate = moment(nextValue, dateFormat);

        if (previousDate.isBefore(nextDate)) {
            return sortDirection === 'asc' ? -1 : 1;
        } else if (previousDate.isAfter(nextDate)) {
            return sortDirection === 'asc' ? 1 : -1;
        }

        return 0;
    }

    if (isString(previousValue) || isString(nextValue)) {
        return sortDirection === 'asc' ? previousValue.localeCompare(nextValue) : nextValue.localeCompare(previousValue);
    }

    if (previousValue < nextValue) {
        return sortDirection === 'asc' ? -1 : 1;
    } else if (previousValue > nextValue) {
        return sortDirection === 'asc' ? 1 : -1;
    }

    return 0;
};

export const createDataTableFilterCallback = (filterKeys, filter, columnConfig, language) => item => {
    return filterKeys.every(filterKey => {
        if (!filter[filterKey]) {
            return true;
        }

        if (!item[filterKey]) {
            return false;
        }

        const thisColumnConfig = columnConfig
            .find(config => (config.columnFilterConfig?.filterKey || config.key) === filterKey);

        // Check the type of the column filter, if type === 'select', match fully
        if (thisColumnConfig && thisColumnConfig?.columnFilterConfig?.type === 'select') {
            if (thisColumnConfig?.columnFilterConfig?.filterKey) {
                const key = thisColumnConfig?.columnFilterConfig?.filterKey;

                return item[key] === filter[key];
            }

            return item[filterKey].toString() === filter[filterKey].toString();
        }

        // Check the type of the column filter, if type === 'number', we need to do some transformations
        if (thisColumnConfig && thisColumnConfig?.columnFilterConfig?.type === 'number') {
            return parseFloat(item[filterKey]).toLocaleString(language)
                .startsWith(filter[filterKey].toString().toLowerCase().trim());
        }

        return item[filterKey]
            .toString()
            .toLowerCase()
            .trim()
            .includes(filter[filterKey].toString().toLowerCase().trim());
    });
};

export const createDataTableSearchCallback = (searchBy, searchTerm) => item => {
    return searchBy.some(searchKey => {
        if (!item[searchKey]) {
            return false;
        }

        return item[searchKey]
            .toString()
            .toLowerCase()
            .trim()
            .includes(searchTerm.toString().toLowerCase().trim());
    });
};

export const createDataTableTabFilterCallback = (tabFilterKey, tabFilterValue) => item => {
    return item.hasOwnProperty(tabFilterKey) && item[tabFilterKey] === tabFilterValue;
};

export const extractUsedFilters = filters => {
    return Object.keys(filters).reduce((result, current) => {
        if (filters[current] && filters[current] !== 'SHOW_ALL') {
            result[current] = filters[current];
        }

        return result;
    }, {});
};

export const getPaginatedResult = (data, pagination) => {
    const totalLength = data.length;

    // Slice the data based on the pagination data
    const offset = pagination.page * pagination.rowsPerPage;

    data = data.slice(offset, offset + pagination.rowsPerPage);

    return {data, count: totalLength};
};
