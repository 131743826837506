import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const MainHeadlineLayout = ({headline, cta, text, isMobileSize, children}) => {
    return (
        <Box
            display="grid"
            gap={2}
            gridTemplateRows="minmax(min-content, 0)"
            sx={{p: isMobileSize ? 2 : 0}}
        >
            {headline && (
                <Box sx={{
                    color: 'text_v2.primary',
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                }}
                >
                    <Typography variant="h4">{headline}</Typography>
                    {cta}
                </Box>
            )}

            {text && (
                <Box sx={{color: 'v2.light.text.disabled'}}>
                    {text}
                </Box>
            )}

            <Box>
                {children}
            </Box>
        </Box>
    );
};

MainHeadlineLayout.propTypes = {
    headline: PropTypes.string,
    cta: PropTypes.node,
    text: PropTypes.string,
    isMobileSize: PropTypes.bool,
};

MainHeadlineLayout.defaultProps = {
    text: '',
    cta: null,
    headline: '',
    isMobileSize: false,
};
