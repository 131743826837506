export const SUBMIT_EMAIL_VERIFICATION_FORM = 'SUBMIT_EMAIL_VERIFICATION_FORM';
export const RESEND_EMAIL_VERIFICATION_CODE = 'RESEND_EMAIL_VERIFICATION_CODE';
export const SET_IS_LOADING_EMAIL_VERIFICATION_FORM = 'SET_IS_LOADING_EMAIL_VERIFICATION_FORM';
export const SET_IS_VERIFICATION_CODE_RESENT = 'SET_IS_VERIFICATION_CODE_RESENT';
export const SET_RESEND_VERIFICATION_CODE_TIME_LEFT = 'SET_RESEND_VERIFICATION_CODE_TIME_LEFT';

export const submitEmailVerificationForm = verificationCode => ({
    type: SUBMIT_EMAIL_VERIFICATION_FORM,
    payload: verificationCode,
});

export const resendEmailVerificationCode = () => ({
    type: RESEND_EMAIL_VERIFICATION_CODE,
});

export const setIsLoadingEmailVerificationForm = isLoading => ({
    type: SET_IS_LOADING_EMAIL_VERIFICATION_FORM,
    payload: isLoading,
});

export const setIsVerificationCodeResent = isVerificationCodeResent => ({
    type: SET_IS_VERIFICATION_CODE_RESENT,
    payload: isVerificationCodeResent,
});

export const setResendVerificationCodeTimeLeft = timeLeft => ({
    type: SET_RESEND_VERIFICATION_CODE_TIME_LEFT,
    payload: timeLeft,
});
