import {all, call, takeEvery} from 'redux-saga/effects';
import {bankSSE} from './bank/sse-bank';
import {coreSSE} from './core/sse-core';
import {invoiceSSE} from './invoice/sse-invoice';
import {SseActionTypes} from './sse.action-types';

export const runAuthSSETokens = function* () {
    yield all([
        call(bankSSE.getSseToken),
        call(invoiceSSE.getSseToken),
        call(coreSSE.getSseToken),
    ]);
};

const getBankSseToken = function* () {
    yield call(bankSSE.getSseToken);
};

const getInvoiceSseToken = function* () {
    yield call(invoiceSSE.getSseToken);
};

const getCoreSSEToken = function* ({payload}) {
    yield call(coreSSE.getSseToken, payload?.forceNewToken, payload?.ignoreNoUser);
};

const disconnectTokens = function* () {
    yield call(invoiceSSE.dcSseToken);
    yield call(bankSSE.dcSseToken);
};

export const watchStoreSaga = function* () {
    yield all([
        takeEvery(SseActionTypes.GET_BANK_TOKEN_AND_RECONNECT, getBankSseToken),
        takeEvery(SseActionTypes.GET_INVOICE_TOKEN_AND_RECONNECT, getInvoiceSseToken),
        takeEvery(SseActionTypes.GET_CORE_TOKEN_AND_RECONNECT, getCoreSSEToken),

        takeEvery(SseActionTypes.DISCONNECT_TOKENS, disconnectTokens),
    ]);
};
