export const roundHalfUpToTwoDecimalPlaces = value => Math.round((Number(value) + Number.EPSILON) * 100) / 100;

export const formatNumberWithComma = number => {
    return number.toLocaleString('fr-FR');
};

export const formatNumberWithSpacesAndComma = (number, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
    return number.toLocaleString('fr-FR', {
        useGrouping: true,
        minimumFractionDigits,
        maximumFractionDigits,
    });
};

export const formatNumberWithSpaces = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const splitNumber = number => {
    const numberStr = number.toString();
    const [integer, decimal] = numberStr.split('.');

    return {
        integer: formatNumberWithSpaces(integer),
        decimal: decimal ? decimal.padEnd(2, '0').slice(0, 2) : '00',
    };
};

export const numberWithSpaces = number => {
    const {integer, decimal} = splitNumber(number);
    return `${integer}.${decimal}`;
};

export const formatToStandardNumber = (input, decimalPlaces) => {
    let result = input.replace(/\s+/g, '').replace(/,/g, '.');

    const firstPeriodIndex = result.indexOf('.');
    if (firstPeriodIndex !== -1) {
        const secondPeriodIndex = result.indexOf('.', firstPeriodIndex + 1);
        if (secondPeriodIndex !== -1) {
            result = result.substring(0, secondPeriodIndex);
        }
    }

    if (result[0] === '.') {
        result = '0' + result;
    }

    return result;
};

/**
 * Creates an numeric array from start to stop
 * @param {number} start
 * @param {number} stop
 * @param {number} step
 * @returns {number[]}
 */
export const range = (start, stop, step = 1) => Array.from({
    length: (stop - start) / step + 1,
}, (_, i) => start + (i * step));
