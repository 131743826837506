import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, Checkbox, FormControlLabel, FormGroup, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';
import {InsuranceActions} from '../../store/insurance.action';
import {InsuranceStatus, InsuranceType} from '../../utils/constants';

const IsSubscribeModal = ({insuranceAlertOnCloseHandler, freelancerId, companyId}) => {
    const [checked, setChecked] = useState(false);

    const dispatch = useDispatch();
    const {t} = useTranslation('insurance');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const handleCheckboxChange = event => {
        setChecked(event.target.checked);
    };

    const handleCloseModal = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.INSURANCE_SUBSCRIBE_MODAL, false));
        setChecked(false);
    };

    const handleConfirmIS = () => {
        dispatch(InsuranceActions.setInsurance({
            freelancerId,
            companyId,
            data: {
                status: InsuranceStatus.SIGNED,
                insurance_type: InsuranceType.RC,
            },
        }));

        if (insuranceAlertOnCloseHandler) {
            insuranceAlertOnCloseHandler();
        }

        handleCloseModal();
    };

    return (
        <ModalWrapper
            modalKey={ModalsKeys.INSURANCE_SUBSCRIBE_MODAL}
            handleCloseCB={handleCloseModal}
            hasShowClose={true}
            containerSx={{width: '608px'}}
            title={(
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(34),
                    fontWeight: 700,
                    lineHeight: '116.7%',
                    pb: 1,
                }}
                >
                    {t(`subscribeModal.IS.title`)}
                </Typography>
            )}
        >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 6}}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2}}>
                    <Typography>{t(`subscribeModal.IS.info`)}</Typography>
                    <FormGroup sx={{px: 1}}>
                        <FormControlLabel
                            control={<Checkbox size="medium" checked={checked} onChange={handleCheckboxChange} />}
                            label={(<Typography>{t(`subscribeModal.IS.label`)}</Typography>)}
                        />
                    </FormGroup>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: isMobileSize ? 'column' : 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: isMobileSize ? 2 : 3,
                    flex: '1 0 0',
                    alignSelf: 'stretch',
                }}
                >
                    <Button
                        fullWidth={isMobileSize}
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        onClick={handleCloseModal}
                    >
                        {t('subscribeModal.IS.back')}
                    </Button>
                    <Button
                        fullWidth={isMobileSize}
                        variant="contained"
                        color="secondary"
                        disabled={!checked}
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleConfirmIS}
                    >
                        {t('subscribeModal.confirm')}
                    </Button>
                </Box>
            </Box>
        </ModalWrapper>
    );
};

IsSubscribeModal.propTypes = {
    insuranceAlertOnCloseHandler: PropTypes.func,
    freelancerId: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
};

IsSubscribeModal.defaultProps = {
    insuranceAlertOnCloseHandler: null,
};

export default IsSubscribeModal;
