import {Paper, Skeleton} from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import {ADMIN_OPPORTUNITY_GRID_TEMPLATES} from '../../utls/constants';

export const AdminOpportunityListItemSkeleton = () => {
    return (
        <Paper sx={{borderRadius: 2, pt: 2, px: 0, mb: 2, pb: 2}} elevation={1}>
            <Box sx={{
                display: 'grid',
                textAlign: 'center',
                alignItems: 'center',
                gridTemplateColumns: ADMIN_OPPORTUNITY_GRID_TEMPLATES.ELEMENT,
                fontSize: theme => theme.typography.pxToRem(14),
                wordBreak: 'break-all',
            }}
            >
                <Box sx={{pl: 3}}>
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                </Box>

                <Box sx={{pl: 3}}>
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                </Box>

                <Box sx={{pl: 3}}>
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                </Box>

                <Box sx={{pl: 3}}>
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                </Box>

                <Box sx={{pl: 3}}>
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                </Box>

                <Box sx={{pl: 3}}>
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                </Box>

                <Box sx={{pl: 3}}>
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                </Box>

                <Box sx={{pl: 3}}>
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                </Box>

                <Box sx={{pl: 3}}>
                    <Skeleton variant="circular" width={20} height={20} />
                </Box>
            </Box>
        </Paper>
    );
};
