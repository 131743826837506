import {all, call, put, takeEvery} from 'redux-saga/effects';
import {BoTrainingActionTypes} from './bo-training.action-type';
import {BoTrainingActions} from './bo-training.actions';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {LoadingActions, LoadingTypes} from '../../loading';
import {BoTrainingApi} from '../api/bo-training.api';

const getTrainingInfoFlow = function* ({companyId, freelancerId}) {
    try {
        const data = yield call(BoTrainingApi.getBoTrainingInfo, {companyId, freelancerId});
        yield put(BoTrainingActions.storeTrainingInfo(data));
    } catch (error) {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_BO_TRAINING_INFO, false));
        Debug.error('boTrainingInfo/GET_TRAINING_INFO', 'Error', {error});
    }
};

const saveTrainingInfoFlow = function* ({companyId, freelancerId, data}) {
    try {
        const response = yield call(BoTrainingApi.saveBoTrainingInfo, {companyId, freelancerId, data});
        yield put(BoTrainingActions.storeTrainingInfo(response));
        Toast.success('genericSuccessSave');
    } catch (error) {
        Toast.error('genericError');
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_BO_TRAINING_INFO, false));
        Debug.error('boTrainingInfo/SAVE_TRAINING_INFO', 'Error', {error});
    }
};

export const getTrainingInfoWorker = function* (action) {
    yield put(LoadingActions.setLoading(LoadingTypes.GET_BO_TRAINING_INFO, true));
    const {params: {companyId, freelancerId}} = action.payload;
    yield call(getTrainingInfoFlow, {companyId, freelancerId});
    yield put(LoadingActions.setLoading(LoadingTypes.GET_BO_TRAINING_INFO, false));
};

const saveTrainingInfoWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.SAVE_BO_TRAINING_INFO, true));
    const {freelancerId, companyId, data} = payload;
    yield call(saveTrainingInfoFlow, {freelancerId, companyId, data});
    yield put(LoadingActions.setLoading(LoadingTypes.SAVE_BO_TRAINING_INFO, false));
};

export const boTrainingSaga = function* () {
    yield all([
        takeEvery(BoTrainingActionTypes.GET_TRAINING_INFO, getTrainingInfoWorker),
        takeEvery(BoTrainingActionTypes.SAVE_TRAINING_INFO, saveTrainingInfoWorker),
    ]);
};
