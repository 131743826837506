import InfoIcon from '@mui/icons-material/Info';
import {Box, IconButton} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ConditionalTooltip} from '../../../../../../components/tooltip/ConditionalTooltip';

const AccountStatementsTooltip = () => {
    const {t} = useTranslation('bankRibAndDocuments');

    return (
        <ConditionalTooltip
            tooltip={(
                <Box
                    sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                    }}
                >
                    {t('titleTooltipText')}
                </Box>
                )}
            placement="top"
            arrow={false}
        >
            <IconButton
                sx={{color: 'v2.blueGray.900', ml: -1.5}}
            >
                <InfoIcon sx={{color: 'v2.blueGray.300'}} />
            </IconButton>
        </ConditionalTooltip>
    );
};

export default AccountStatementsTooltip;
