import {transformDashboardData} from './dashboard.dto';
import axiosInstance, {safeTransformData} from '../../axios';

export const getDashboardDataRequest = ({freelancerId, companyId, year}) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/financial-data`,
        params: {
            year,
        },
    }).then(response => safeTransformData(response, transformDashboardData));
};

export const putIncomeCalculationRequest = ({freelancerId}) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/send-income-calculation-request`,
    }).then(response => response.data);
};
