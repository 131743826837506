import {FormLabel} from '@mui/joy';
import PropTypes from 'prop-types';

export const FormFieldLabel = ({label, color, sx}) => {
    return (
        <FormLabel sx={{color, ...sx}}>
            {label}
        </FormLabel>
    );
};

FormFieldLabel.propTypes = {
    label: PropTypes.string.isRequired,
    color: PropTypes.string,
    sx: PropTypes.object,

};

FormFieldLabel.defaultProps = {
    color: 'text.primary',
    sx: {},
};
