import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';
import {Company} from '../../companies/api/companies.dto';

const resetCompanySetupFlow = ({freelancerId, companyId}) => {
    return hiwayApiInstance
        .put(`/v1/freelancers/${freelancerId}/companies/${companyId}/request-edits`)
        .then(result => new Company(result.data));
};

const activateCompany = ({freelancerId, companyId}) => {
    return hiwayApiInstance
        .put(`/v1/freelancers/${freelancerId}/companies/${companyId}/on-boarding-launched`)
        .then(result => new Company(result.data));
};

const checkOnboardingFinalStatus = ({freelancerId, companyId}) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}/companies/${companyId}/check-onboarding-final-status`)
        .then(result => result.data);
};

export const OnboardingApi = {
    resetCompanySetupFlow,
    activateCompany,
    checkOnboardingFinalStatus,
};
