import {all, call, takeEvery} from 'redux-saga/effects';
import {LOCATION_CHANGED} from '../../../lib/router/connected-router-saga';
import {AppEventApi} from '../api/app-event.api';
import {EventTypes} from '../utils/constants';

export const sendEvent = function* (eventType, eventPayload) {
    try {
        yield call(AppEventApi.sendEvent, eventType, eventPayload);
    } catch (error) {
        // no-op
    }
};

// const sendEventWorker = function* ({payload}) {
//     yield call(sendEvent, payload.eventType, payload.eventPayload);
// };

const onLocationChangedWorker = function* ({payload}) {
    yield call(sendEvent, EventTypes.ROUTE_CHANGE, {
        path: payload.location.pathname + payload.location.search,
    });
};

export const appEventSaga = function* () {
    yield all([
        takeEvery(LOCATION_CHANGED, onLocationChangedWorker),
        // Duplicate event listener // TODO Remove in v1 and enable this one
        // takeEvery(EventActionTypes.SEND_EVENT, sendEventWorker),
    ]);
};
