/**
 * @typedef {Object} PersonalExpenseDTO
 * @property {String} amount_ttc
 * @property {String} category
 * @property {String} company_id
 * @property {String} date
 * @property {String} freelancer_id
 * @property {String} id
 * @property {String} receipt_id
 * @property {Boolean} reimbursed
 */

/**
 * @typedef {Object} ProfessionalExpenseDTO
 * @property {String} amount_ttc
 * @property {String} category
 * @property {String} company_id
 * @property {String} date
 * @property {String} freelancer_id
 * @property {String} id
 * @property {String} receipt_id
 */

/**
 * @typedef {Object} PersonalExpense
 * @property {String} amount
 * @property {String} category
 * @property {String} companyId
 * @property {String} date
 * @property {String} freelancerId
 * @property {String} id
 * @property {String} receiptId
 * @property {Boolean} isReimbursed
 */

/**
 * @typedef {Object} ProfessionalExpense
 * @property {String} amount
 * @property {String} category
 * @property {String} companyId
 * @property {String} date
 * @property {String} freelancerId
 * @property {String} id
 * @property {String} receiptId
 */

/**
 * @typedef {Object} BusinessAllowanceTravelDTO
 * @property {Number} amount
 * @property {TRAVEL_TYPES} type
 * @property {String} company_id
 * @property {String} created_at
 * @property {String} date
 * @property {Number} distance
 * @property {String} fiscal_power
 * @property {String} freelancer_id
 * @property {String} id
 * @property {Number} number_of_days
 * @property {String} recap_id
 * @property {Array<String>} routes
 * @property {String} reason
 * @property {String} updated_at
 * @property {VEHICLE_TYPES} vehicle_type
 * @property {ENGINE_TYPES} engine_type
 */

/**
 * @typedef {Object} BusinessAllowanceTravel
 * @property {Number} amount
 * @property {TRAVEL_TYPES} type
 * @property {String} companyId
 * @property {String} date
 * @property {String} createdAt
 * @property {Number} distance
 * @property {String} fiscalPower
 * @property {String} freelancerId
 * @property {String} id
 * @property {Number} numberOfDays
 * @property {String} reason
 * @property {String} recapId
 * @property {Array<String>} routes
 * @property {String} updatedAt
 * @property {VEHICLE_TYPES} vehicleType
 * @property {ENGINE_TYPES} engineType
 */

/**
 * @typedef {Object} BusinessAllowanceRecapDTO
 * @property {Number} amount
 * @property {String} company_id
 * @property {String} created_at
 * @property {String} date
 * @property {String} freelancer_id
 * @property {String} id
 * @property {String} reason
 * @property {String} recap_doc_id
 * @property {Boolean} reimbursed
 * @property {String} updated_at
 */

/**
 * @typedef {Object} BusinessAllowanceRecap
 * @property {Number} amount
 * @property {String} companyId
 * @property {String} createdAt
 * @property {String} date
 * @property {String} freelancerId
 * @property {String} id
 * @property {String} documentId
 * @property {Boolean} isReimbursed
 * @property {String} reason
 * @property {String} updatedAt
 */

import {TRAVEL_TYPES, VEHICLE_TYPES} from '../../../expenses/expenses.consts';

/**
 * Returns transformed expense data object
 * @param {PersonalExpenseDTO} personalExpenseDTO What we get from API
 * @returns {PersonalExpense} Our data
 */
export const fromPersonalExpenseDTO = personalExpenseDTO => ({
    amount: personalExpenseDTO.amount_ttc,
    category: personalExpenseDTO.category,
    companyId: personalExpenseDTO.company_id,
    date: personalExpenseDTO.date,
    freelancerId: personalExpenseDTO.freelancer_id,
    id: personalExpenseDTO.id,
    receiptId: personalExpenseDTO.receipt_id,
    isReimbursed: personalExpenseDTO.reimbursed,
});

/**
 * Returns transformed expense data object
 * @param {PersonalExpense} personalExpense What we get from API
 * @returns {PersonalExpenseDTO} Our data
 */
export const toPersonalExpenseDTO = personalExpense => ({
    amount_ttc: personalExpense.amount,
    category: personalExpense.category,
    company_id: personalExpense.companyId,
    date: personalExpense.date,
    freelancer_id: personalExpense.freelancerId,
    id: personalExpense.id,
    receipt_id: personalExpense.receiptId,
    reimbursed: personalExpense.isReimbursed,
});

/**
 * Returns transformed expense data object
 * @param {ProfessionalExpenseDTO} professionalExpenseDTO What we get from API
 * @returns {ProfessionalExpense} Our data
 */
export const fromProfessionalExpenseDTO = professionalExpenseDTO => ({
    amount: professionalExpenseDTO.amount_ttc,
    category: professionalExpenseDTO.category,
    companyId: professionalExpenseDTO.company_id,
    date: professionalExpenseDTO.date,
    freelancerId: professionalExpenseDTO.freelancer_id,
    id: professionalExpenseDTO.id,
    receiptId: professionalExpenseDTO.receipt_id,
});

/**
 * Returns transformed expense data object
 * @param {ProfessionalExpense} professionalExpenseDTO What we get from API
 * @returns {ProfessionalExpenseDTO} Our data
 */
export const toProfessionalExpenseDTO = professionalExpenseDTO => ({
    amount_ttc: professionalExpenseDTO.amount,
    category: professionalExpenseDTO.category,
    company_id: professionalExpenseDTO.companyId,
    date: professionalExpenseDTO.date,
    freelancer_id: professionalExpenseDTO.freelancerId,
    id: professionalExpenseDTO.id,
    receipt_id: professionalExpenseDTO.receiptId,
});

/**
 * Transforms an array of personal expenses to an object
 * @param {PersonalExpenseDTO[]} personalExpenseDTOs What we get from API
 * @returns {{}} Object of personal expenses where key is the expense ID and value is the expense
 */
export const transformPersonalExpenseDTOs = personalExpenseDTOs => {
    if (!personalExpenseDTOs) {
        return {};
    }

    const personalExpenses = {};

    personalExpenseDTOs.forEach(personalExpenseDTO => {
        personalExpenses[personalExpenseDTO.id] = fromPersonalExpenseDTO(personalExpenseDTO);
    });

    return personalExpenses;
};

/**
 * Transforms an array of professional expenses to an object
 * @param {ProfessionalExpenseDTO[]} professionalExpenseDTOs What we get from API
 * @returns {{}} Object of professional expenses where key is the expense ID and value is the expense
 */
export const transformProfessionalExpenseDTOs = professionalExpenseDTOs => {
    if (!professionalExpenseDTOs) {
        return {};
    }

    const professionalExpenses = {};

    professionalExpenseDTOs.forEach(professionalExpenseDTO => {
        professionalExpenses[professionalExpenseDTO.id] = fromProfessionalExpenseDTO(professionalExpenseDTO);
    });

    return professionalExpenses;
};

/**
 * Returns transformed travel data object
 * @param {BusinessAllowanceTravelDTO} businessAllowanceTravelDTO What we get from API
 * @returns {BusinessAllowanceTravel} Our data
 */
export const fromBusinessAllowanceTravelDTO = businessAllowanceTravelDTO => ({
    amount: businessAllowanceTravelDTO.amount,
    companyId: businessAllowanceTravelDTO.company_id,
    dailyAmount: businessAllowanceTravelDTO.type === TRAVEL_TYPES.HOME_OFFICE
        ? businessAllowanceTravelDTO.amount / businessAllowanceTravelDTO.number_of_days
        : businessAllowanceTravelDTO.amount,
    date: businessAllowanceTravelDTO.date,
    distance: businessAllowanceTravelDTO.distance,
    fiscalPower: businessAllowanceTravelDTO.fiscal_power,
    freelancerId: businessAllowanceTravelDTO.freelancer_id,
    id: businessAllowanceTravelDTO.id,
    numberOfDays: businessAllowanceTravelDTO.number_of_days,
    reason: businessAllowanceTravelDTO.reason,
    recapId: businessAllowanceTravelDTO.recap_id,
    routes: businessAllowanceTravelDTO.routes,
    type: businessAllowanceTravelDTO.type,
    vehicleType: businessAllowanceTravelDTO.vehicle_type,
    engineType: businessAllowanceTravelDTO.engine_type,
    createdAt: businessAllowanceTravelDTO.created_at,
    updatedAt: businessAllowanceTravelDTO.updated_at,
});

/**
 * Transforms an array of professional travels to an object
 * @param {{travels: BusinessAllowanceTravelDTO[]}} businessAllowanceTravelDTOs What we get from API
 * @returns {{}} Object of business allowance travel where key is the travel ID and value is the travel
 */
export const transformBusinessAllowanceTravelDTOs = businessAllowanceTravelDTOs => {
    if (!businessAllowanceTravelDTOs.travels) {
        return {};
    }

    const travels = {};

    businessAllowanceTravelDTOs.travels.forEach(businessAllowanceTravelDTO => {
        travels[businessAllowanceTravelDTO.id] = fromBusinessAllowanceTravelDTO(businessAllowanceTravelDTO);
    });

    return travels;
};

export const fromTotalDistanceDto = totalDistanceDto => {
    const totalDistance = {};

    Object.values(VEHICLE_TYPES).forEach(vehicleType => {
        if (totalDistanceDto.hasOwnProperty(vehicleType)) {
            totalDistance[vehicleType] = totalDistanceDto[vehicleType];
        } else {
            totalDistance[vehicleType] = 0;
        }
    });

    return totalDistance;
};

/**
 * Returns transformed business allowance travel data object
 * @param {BusinessAllowanceRecapDTO} businessAllowanceRecapDTO What we get from API
 * @returns {BusinessAllowanceRecap} Our data
 */
export const fromBusinessAllowanceRecapDTO = businessAllowanceRecapDTO => ({
    amount: businessAllowanceRecapDTO.amount,
    companyId: businessAllowanceRecapDTO.company_id,
    createdAt: businessAllowanceRecapDTO.created_at,
    date: businessAllowanceRecapDTO.date,
    freelancerId: businessAllowanceRecapDTO.freelancer_id,
    id: businessAllowanceRecapDTO.id,
    documentId: businessAllowanceRecapDTO.recap_doc_id,
    isReimbursed: businessAllowanceRecapDTO.reimbursed,
    updatedAt: businessAllowanceRecapDTO.updated_at,
    reason: businessAllowanceRecapDTO.reason,
});

/**
 * Transforms an array of business allowance recaps to an object
 * @param {BusinessAllowanceRecapDTO[]} businessAllowanceRecapDTOs What we get from API
 * @returns {Object.<string, BusinessAllowanceRecap>} Dictionary
 */
export const transformBusinessAllowanceRecapDTOs = businessAllowanceRecapDTOs => {
    if (!businessAllowanceRecapDTOs) {
        return {};
    }

    const recaps = {};

    businessAllowanceRecapDTOs.forEach(businessAllowanceRecapDTO => {
        recaps[businessAllowanceRecapDTO.id] = fromBusinessAllowanceRecapDTO(businessAllowanceRecapDTO);
    });

    return recaps;
};

