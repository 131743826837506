import {Typography} from '@mui/joy';
import PropTypes from 'prop-types';

export const Title = ({children, isPublic}) => {
    return (
        <Typography
            level={isPublic ? 'h2' : 'h1'}
            sx={{
                mt: isPublic ? 4.5 : 1,
                mb: isPublic ? 2 : 5,
            }}
        >
            {children}
        </Typography>
    );
};

Title.propTypes = {
    isPublic: PropTypes.bool.isRequired,
};
