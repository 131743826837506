import makeStyles from '@mui/styles/makeStyles';

export const useDataItemRowStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2, 3),
    },
    hasBorderDivider: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[300],
    },
    gridContainer: {
        minHeight: 30,
        flexWrap: 'unset',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'start',
        },
    },
    labelContainer: {
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(1, 0),
            textAlign: 'left',
        },
    },
    valueContainer: {
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(1, 0),
            textAlign: 'left',
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(1, 0),
            textAlign: 'left',
        },
    },
    divider: {
        margin: theme.spacing(0, -3),
    },
}));
