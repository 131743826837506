import {VerificationStatus} from '../../../utils/constants';
import {getIntegrationInfo} from '../../bridge-api/utils/bridge-util';
import {BANK_TYPES, BankAccountStatus, BankIntegrationStatus} from '../../bridge-api/utils/constants';

export const hasRibAndDocumentsPageAccess = integrations => {
    return !!getActiveHiwayIntegration(integrations);
};

export const getActiveHiwayIntegration = integrations => {
    const activeHiwayIntegrations = integrations.filter(
        integration => integration.type === BANK_TYPES.hiway && integration.status === BankIntegrationStatus.ACTIVE,
    );

    return activeHiwayIntegrations.find(hiwayIntegration => {
        const {account, bankAccountHolder} = getIntegrationInfo(hiwayIntegration);
        return account?.status === BankAccountStatus.ACTIVE
            && !!account?.iban
            && !!account?.bic
            && VerificationStatus.VERIFIED === bankAccountHolder.verificationStatus;
    });
};
