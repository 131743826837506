import {faMessage, faRankingStar} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Typography} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {InfoSheet} from '../../../../../components/joy-ui/info-sheet/InfoSheet';
import {useSimulationData} from '../utils/useSimulationData';

export const ContactSection = () => {
    const simulation = useSimulationData();
    const {t} = useTranslation('revenueSimulator');

    return (
        <Box>
            <Typography level="title-lg" mb={1}>
                {t('contactSection.heading')}
            </Typography>
            <InfoSheet
                icon={faRankingStar}
                title={t('contactSection.title')}
                titleProps={{level: 'title-md', textColor: 'text.secondary'}}
                description={t('contactSection.description')}
                sx={{bgcolor: 'neutral.200'}}
                descriptionProps={{level: 'body-sm', textColor: 'text.secondary'}}
                cta={(
                    <Button
                        component="a"
                        href={`mailto:${simulation.coachEmail}`}
                        variant="solid"
                        color="neutral"
                        startDecorator={<FontAwesomeIcon icon={faMessage} />}
                        size="sm"
                        sx={{width: {mobile: '100%', desktop: 'auto'}}}
                    >
                        {t('contactSection.buttonText')}
                    </Button>
                  )}
            />
        </Box>
    );
};
