import {combineReducers} from 'redux';
import {
    SET_IS_EDITING_DECLARATION,
    STORE_BATCH_PROGRESS,
    STORE_DECLARATIONS,
    STORE_DECLARATION_HISTORY,
    STORE_HISTORY_OVERVIEW,
    STORE_VAT_DECLARATION,
    STORE_VAT_SUPPORTED_MONTHS,
} from './vat-declaration.action-type';
import {createReducer} from '../../../utils/create-reducer';

export const vatDeclarationReducer = combineReducers({
    declarations: createReducer(null, STORE_DECLARATIONS),
    batchProgress: createReducer({}, STORE_BATCH_PROGRESS),
    supportedMonths: createReducer(null, STORE_VAT_SUPPORTED_MONTHS, true),
    vatDeclarationDetails: createReducer(null, STORE_VAT_DECLARATION, true),
    isEditingDeclaration: createReducer(null, SET_IS_EDITING_DECLARATION),
    historyOverview: createReducer(null, STORE_HISTORY_OVERVIEW),
    declarationHistory: createReducer(null, STORE_DECLARATION_HISTORY),
});
