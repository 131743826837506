/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import {
    faCalendarDays,
    faClock,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import composeClasses from '@mui/base/composeClasses';
import {Tab, TabList, Tabs, tabsClasses} from '@mui/joy';
import {styled, useThemeProps} from '@mui/system';
import PropTypes from 'prop-types';
import * as React from 'react';
import {getDateTimePickerTabsUtilityClass} from './dateTimePickerTabsClasses';
import {WrapperVariantContext} from '../internals/components/wrappers/WrapperVariantContext';
import {useLocaleText} from '../internals/hooks/useUtils';

const viewToTab = openView => {
    if (['day', 'month', 'year'].includes(openView)) {
        return 'date';
    }

    return 'time';
};

const tabToView = tab => {
    if (tab === 'date') {
        return 'day';
    }

    return 'hours';
};

const useUtilityClasses = ownerState => {
    const {classes} = ownerState;
    const slots = {
        root: ['root'],
    };

    return composeClasses(slots, getDateTimePickerTabsUtilityClass, classes);
};

const DateTimePickerTabsRoot = styled(Tabs, {
    name: 'MuiDateTimePickerTabs',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})(({ownerState, theme}) => ({
    boxShadow: `0 -1px 0 0 inset ${theme.palette.divider}`,
    ...(ownerState.wrapperVariant === 'desktop' && {
        order: 1,
        boxShadow: `0 1px 0 0 inset ${theme.palette.divider}`,
        [`& .${tabsClasses.indicator}`]: {
            bottom: 'auto',
            top: 0,
        },
    }),
}));

const DateTimePickerTabs = function DateTimePickerTabs(inProps) {
    const props = useThemeProps({props: inProps, name: 'MuiDateTimePickerTabs'});
    const {
        dateRangeIcon = <FontAwesomeIcon icon={faCalendarDays} />,
        onChange,
        timeIcon = <FontAwesomeIcon icon={faClock} />,
        view,
    } = props;

    const localeText = useLocaleText();
    const wrapperVariant = React.useContext(WrapperVariantContext);
    const ownerState = {...props, wrapperVariant};
    const classes = useUtilityClasses(ownerState);

    const handleChange = (event, value) => {
        onChange(tabToView(value));
    };

    return (
        <DateTimePickerTabsRoot
            ownerState={ownerState}
            variant="soft"
            value={viewToTab(view)}
            onChange={handleChange}
            className={classes.root}
        >
            <TabList>
                <Tab
                    value="date"
                    variant="plain"
                    color="neutral"
                    aria-label={localeText.dateTableLabel}
                    sx={{flex: 1}}
                >
                    {dateRangeIcon}
                </Tab>
                <Tab
                    value="time"
                    variant="plain"
                    color="neutral"
                    aria-label={localeText.timeTableLabel}
                    sx={{flex: 1}}
                >
                    {timeIcon}
                </Tab>
            </TabList>
        </DateTimePickerTabsRoot>
    );
};

DateTimePickerTabs.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // | To update them edit the TypeScript types and run "yarn proptypes"  |
    // ----------------------------------------------------------------------
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,

    /**
     * Date tab icon.
     * @default DateRange
     */
    dateRangeIcon: PropTypes.node,

    /**
     * Callback called when tab is clicked
     * @param {CalendarOrClockPickerView} view Picker view that was clicked
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Time tab icon.
     * @default Time
     */
    timeIcon: PropTypes.node,

    /**
     * Open picker view
     */
    view: PropTypes.oneOf(['day', 'hours', 'minutes', 'month', 'seconds', 'year'])
        .isRequired,
};

export {DateTimePickerTabs};
