import {combineReducers} from 'redux';

import {contractDataReducer} from './contract/contract.reducer';
import * as actions from './freelancer.actions';
import {signatureReducer} from './signature/signature.reducer';
import {createReducer} from '../createReducer';

export const v1FreelancerReducer = combineReducers({
    account: createReducer(null, actions.STORE_FREELANCER_ACCOUNT),
    companies: createReducer({}, actions.STORE_FREELANCER_COMPANIES),
    contractData: contractDataReducer,
    isLoadingAccount: createReducer(true, actions.SET_IS_LOADING_FREELANCER_ACCOUNT, true),
    isLoadingCompanies: createReducer(true, actions.SET_IS_LOADING_FREELANCER_COMPANIES, true),
    signature: signatureReducer,
});
