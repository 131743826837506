import {faArrowRight, faCheck, faCheckCircle, faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Stack, Typography} from '@mui/joy';
import moment from 'moment';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import BatchSendProgress from './components/BatchSendProgress';
import InfoRow from './components/InfoRow';
import {LoadingSkeleton, LoadingSkeletonProvider} from '../../../../../../../components/joy-ui/loading-skeleton/LoadingSkeleton';
import {ModalWrapper} from '../../../../../../../components/modal-wrapper/ModalWrapper';
import {BE_DATE_FORMAT} from '../../../../../../../utils/constants';
import {UiActions} from '../../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../../ui/utils/constants';
import {VatDeclarationSelectors} from '../../../../../../vat-declaration/store/vat-declaration.selector';
import useVATDeclarationSendProgress from '../../../hooks/useVATDeclarationSendProgress';
import {VatDeclarationSendUIState, VatDeclarationStatus} from '../../../utils/consts';

const modalKey = ModalsKeys.PROCESS_MULTIPLE_VAT_DECLARATION;

const ProcessMultiDeclarationsModal = () => {
    const {t} = useTranslation('formalities');
    const dispatch = useDispatch();
    const {batchProgress, uiState} = useVATDeclarationSendProgress();
    const [, setSearchParams] = useSearchParams();

    const {
        batchSize,
        submitted,
        pendingVerification,
        refused,
        failed = 0,
    } = batchProgress;

    const totalProcessed = useSelector(VatDeclarationSelectors.selectProcessedDeclarationsCount);

    const handleOnClickCTA = () => {
        if (uiState === VatDeclarationSendUIState.COMPLETED_WITH_ERRORS) {
            setSearchParams(searchParams => {
                const failedStatuses = [
                    VatDeclarationStatus.REFUSED,
                    VatDeclarationStatus.ERROR,
                    VatDeclarationStatus.PENDING_VERIFICATION,
                ];
                searchParams.set('vatDeclaration-statusFilter', failedStatuses.join());

                return searchParams;
            });
        }

        dispatch(UiActions.setActiveModal(modalKey, false));
    };

    return (
        <LoadingSkeletonProvider isLoading={uiState === VatDeclarationSendUIState.NONE}>
            <ModalWrapper
                isV3
                modalKey={modalKey}
                hasShowClose
                title={(
                    <LoadingSkeleton variant="rectangular" sx={{width: '200px'}}>
                        <Typography level="h1">
                            {t(`vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.statusData.${uiState}.title`)}
                        </Typography>
                    </LoadingSkeleton>
                )}
                containerSx={{
                    width: '680px',
                }}
            >
                <Stack spacing={2}>
                    <LoadingSkeleton variant="rectangular" sx={{minHeight: '280px'}}>
                        {uiState === VatDeclarationSendUIState.IN_PROGRESS && (
                            <>
                                <InfoRow
                                    label={t('vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.statistics.totalDeclarations')}
                                    value={batchSize}
                                />
                                <BatchSendProgress
                                    label={t(`vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.statusData.${uiState}.title`)}
                                    processedCount={totalProcessed}
                                    totalCount={batchSize}
                                />
                            </>
                        )}
                        {uiState === VatDeclarationSendUIState.COMPLETED_WITH_ERRORS && (
                            <>
                                <InfoRow
                                    label={t('vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.statistics.totalDeclarations')}
                                    value={batchSize}
                                />
                                <InfoRow
                                    color="success"
                                    label={t('vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.statistics.totalSent')}
                                    value={submitted}
                                />
                                <InfoRow
                                    color="warning"
                                    label={t('vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.statistics.totalPending')}
                                    value={pendingVerification}
                                />
                                <InfoRow
                                    color="danger"
                                    label={t('vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.statistics.totalFailed')}
                                    value={failed}
                                />
                                <InfoRow
                                    color="danger"
                                    label={t('vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.statistics.totalRefused')}
                                    value={refused}
                                />
                            </>
                        )}
                        {uiState === VatDeclarationSendUIState.COMPLETED && (
                            <>
                                <InfoRow
                                    label={t('vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.statistics.totalDeclarations')}
                                    value={batchSize}
                                />
                                <InfoRow
                                    color="success"
                                    label={t('vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.statistics.totalSent')}
                                    value={submitted}
                                />
                            </>
                        )}
                        <Alert
                            sx={{alignItems: 'baseline', verticalAlign: 'middle'}}
                            color={uiData[uiState].alert.color}
                            variant={uiData[uiState].alert.variant}
                            size="md"
                            startDecorator={(
                                <Typography sx={{color: 'neutral.plainColor'}}>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </Typography>
                            )}
                        >
                            <Typography
                                level="body-sm"
                                sx={{color: 'text.secondary', textAlign: 'justify', whiteSpace: 'pre-line'}}
                            >
                                <Trans
                                    t={t}
                                    i18nKey={`vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.statusData.${uiState}.alertMessage`}
                                    components={{b: <Typography level="body-sm" sx={{fontWeight: 700, color: 'text.secondary'}} />}}
                                    values={{
                                        date: moment().format(BE_DATE_FORMAT),
                                    }}
                                />
                            </Typography>
                        </Alert>
                        <Button
                            sx={{flex: 1, ...uiData[uiState].ctaPrimary.sx}}
                            color={uiData[uiState].ctaPrimary.color}
                            variant={uiData[uiState].ctaPrimary.variant}
                            startDecorator={uiData[uiState].ctaPrimary.startDecorator}
                            endDecorator={uiData[uiState].ctaPrimary.endDecorator}
                            onClick={handleOnClickCTA}
                        >
                            {t(uiData[uiState].ctaPrimary.text)}
                        </Button>
                    </LoadingSkeleton>
                </Stack>
            </ModalWrapper>
        </LoadingSkeletonProvider>
    );
};

export default ProcessMultiDeclarationsModal;

const uiData = {
    [VatDeclarationSendUIState.NONE]: {
        alert: {},
        ctaPrimary: {},
    },
    [VatDeclarationSendUIState.IN_PROGRESS]: {
        alert: {
            variant: 'outlined',
            color: 'neutral',
        },
        ctaPrimary: {
            text: 'vatDeclaration.sendDeclarationsModal.ctaClose',
            startDecorator: <FontAwesomeIcon icon={faCircleXmark} />,
            color: 'danger',
            variant: 'soft',
        },
    },
    [VatDeclarationSendUIState.COMPLETED]: {
        alert: {
            variant: 'soft',
            color: 'success',
        },
        ctaPrimary: {
            text: 'vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.ctaToValidate',
            startDecorator: <FontAwesomeIcon icon={faCheck} />,
            color: 'success',
            variant: 'soft',
            sx: {
                color: 'neutral.plainColor',
            },
        },
    },
    [VatDeclarationSendUIState.COMPLETED_WITH_ERRORS]: {
        alert: {
            variant: 'outlined',
            color: 'neutral',
        },
        ctaPrimary: {
            text: 'vatDeclaration.sendDeclarationsModal.sendMultipleDeclarations.ctaVerify',
            endDecorator: <FontAwesomeIcon icon={faArrowRight} />,
            color: 'primary',
            variant: 'solid',
        },
    },
};
