export const selectIsVerificationCodeResent = state => state
    .auth
    .emailVerificationForm
    .isVerificationCodeResent;

export const selectIsLoadingEmailVerificationForm = state => state
    .auth
    .emailVerificationForm
    .isLoading;

export const selectResendVerificationCodeTimeLeft = state => state
    .auth
    .emailVerificationForm
    .resendVerificationCodeTimeLeft;
