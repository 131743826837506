import {combineReducers} from 'redux';
import {ProfessionAndSpecializationActionTypes} from './profession-and-specialization.action-type';
import {createReducer} from '../../../utils/create-reducer';

export const professionAndSpecializationReducer = combineReducers({
    freelancerData: createReducer(
        [],
        ProfessionAndSpecializationActionTypes.STORE_FREELANCER_DATA,
    ),
    allValues: createReducer(
        [],
        ProfessionAndSpecializationActionTypes.STORE_ALL_VALUES,
    ),
});
