import {all, call, put, takeLatest} from 'redux-saga/effects';

import * as actions from './event.actions';
import {EVENT_TYPES} from './event.constants';
import {sendEventRequest} from '../api/providers/event/event.provider';

export const sendEventSaga = function* ({payload}) {
    try {
        yield call(sendEventRequest, payload);
    } catch (error) {
        // no-op
    }
};

export const notifyAboutRouteChangeSaga = function* (payload) {
    yield put(actions.sendEvent(EVENT_TYPES.ROUTE_CHANGE, {
        path: payload.location.pathname,
    }));
};

export const watchEventSagas = function* () {
    yield all([
        takeLatest(actions.SEND_EVENT, sendEventSaga),
    ]);
};
