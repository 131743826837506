import momentTz from 'moment-timezone';
import moment from 'moment/moment';
import {filterOutServiceParamsForInvoice} from '../util/functions';

const dtoToClientUpdate = data => {
    return {
        address: data?.address ?? undefined,
        city: data?.city ?? undefined,
        countryCode: data?.countryCode ?? undefined,
        emails: data?.emails ?? undefined,
        phone: data?.phone ?? undefined,
        name: data?.name ?? undefined,
        paymentTerm: data?.paymentTerm ?? undefined,
        vatIntraCommunity: data?.vatIntraCommunity ?? undefined,
        vatType: data?.vatType ?? undefined,
        zipCode: data?.zipCode ?? undefined,
    };
};

const dtoToInvoiceServiceUpdate = data => {
    return {
        name: data?.name,
        type: data?.type,
        rate: data?.rate,
        discountPercentage: data?.discountInputType
                            && data?.discountPercentage
                            && parseFloat(data?.discountPercentage)
            ? data?.discountPercentage
            : undefined,
        // discountPercentage can be null or undefined, input type should match that
        discountInputType: data?.discountPercentage
            ? data?.discountInputType
            : (data?.discountPercentage === null ? null : undefined),
        rateUnit: data?.rateUnit,
        contractReference: data?.contractReference,
    };
};

const dtoToSaveInvoice = data => {
    return {
        date: data?.date ?? momentTz.tz().format(),
        dueDate: data?.dueDate ?? undefined,
        contractReference: data?.contractReference ?? null,
        client: data?.client?.id ? {id: data.client.id} : undefined,
        services: data?.services
            ? data.services.map(service => filterOutServiceParamsForInvoice(service))
            : [],
    };
};

const dtoToCreateInvoice = data => {
    return {
        client: data?.client ?? undefined,
        date: data?.date ?? moment().toDate(),
        dueDate: data?.dueDate ?? undefined,
        contractReference: data?.contractReference ?? null,
        services: data?.services
            ? data.services.map(service => filterOutServiceParamsForInvoice(service))
            : [],
    };
};

export const InvoiceDto = {
    dtoToClientUpdate,
    dtoToInvoiceServiceUpdate,
    dtoToSaveInvoice,
    dtoToCreateInvoice,
};
