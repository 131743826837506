import moment from 'moment';
import {hiwayApiInstance} from '../../../lib/hiway-api-instance';
import {BE_DATE_FORMAT} from '../../../utils/constants';
import {UserRoles} from '../../../utils/user-roles';
import axiosInstance, {safeTransformData} from '../../../v1/app/api/axios';
import {fromSingleCompanyDTO} from '../../../v1/app/api/providers/company/company.dto';
import {FULL_COMPANY_STATUSES} from '../../../v1/app/company/setupCompany/setupCompany.constants';
import {EmailReminderType, STAGES, TIMELINE_INFO_STAGES} from '../utils/constants';

const BackofficeCompany = function (dto) {
    this.companyId = dto.company_id;
    this.companyName = dto.company_name;
    this.companyStatus = dto.company_status;

    this.freelancerId = dto.freelancer_id;
    this.freelancerLastName = dto.freelancer_last_name ?? '';
    this.freelancerFirstName = dto.freelancer_first_name ?? '';
    this.freelancerRole = dto.freelancer_role ?? UserRoles.FREELANCER;
    this.coachLastName = dto.coach_last_name ?? '';
    this.coachFirstName = dto.coach_first_name ?? '';
    this.coachInitials = this.coachFirstName.charAt(0) + this.coachLastName.charAt(0);

    this.activityStartDate = dto.activity_start_date ? moment(dto.activity_start_date, BE_DATE_FORMAT) : null;
    this.coachId = dto.coach_id;
    this.capitalDepositDocumentId = dto.capital_deposit_doc_id;

    this.coachingInfo = dto.coaching_info;

    this.reminderInfo = dto.reminder_info;
    this.reminderInfoDeposit = this.reminderInfo
        ? this.reminderInfo.filter(info => info.reminder_type === EmailReminderType.CAPITAL_DEPOSIT_REMINDER
            || info.reminder_type === EmailReminderType.AUTOMATIC_CAPITAL_DEPOSIT_REMINDER)
        : [];
    this.reminderInfoFinalSigs = this.reminderInfo
        ? this.reminderInfo.filter(info => info.reminder_type === EmailReminderType.PENDING_FINAL_SIGS_REMINDER)
        : [];

    this.companyQuickFilter = dto.company_statuses_quick_filter;
    this.registrationWithExistingCompany = dto.registration_with_existing_company;
    this.registrationType = dto.registration_type;

    this.companyStages = {
        [STAGES.PENDING_CONTRACT]:
            dto.timeline_info?.[TIMELINE_INFO_STAGES.contract_state]
            ?? null,
        [STAGES.PENDING_REGISTRATION]:
            dto.timeline_info?.[TIMELINE_INFO_STAGES.company_setup_state]
            ?? null,
        [STAGES.PENDING_CAPITAL_DEPOSIT]:
            dto.timeline_info?.[TIMELINE_INFO_STAGES.capital_deposit_state]
            ?? null,
        [STAGES.PENDING_REGISTRATION_FILE]:
            dto.timeline_info?.[TIMELINE_INFO_STAGES.company_registration_state]
            ?? null,
        [STAGES.PENDING_FINAL_DOCUMENTS]:
            dto.timeline_info?.[TIMELINE_INFO_STAGES.final_documents_signed_state]
            ?? null,
    };

    if (dto.company_status !== FULL_COMPANY_STATUSES.PENDING_FINAL_SIGS) {
        // Add capital deposit stages
        if (dto.timeline_info?.[TIMELINE_INFO_STAGES.individual_shareholder_account_closed_state]
                && dto.timeline_info?.[TIMELINE_INFO_STAGES.individual_shareholder_account_closed_state] !== 'COMPLETED') {
            // eslint-disable-next-line max-len
            this.companyStages[STAGES.PENDING_FINAL_DOCUMENTS] = dto.timeline_info?.[TIMELINE_INFO_STAGES.individual_shareholder_account_closed_state];
        }

        if (dto.timeline_info?.[TIMELINE_INFO_STAGES.capital_deposit_transfer_state]
                && dto.timeline_info?.[TIMELINE_INFO_STAGES.capital_deposit_transfer_state] !== 'COMPLETED') {
            // eslint-disable-next-line max-len
            this.companyStages[STAGES.PENDING_FINAL_DOCUMENTS] = dto.timeline_info?.[TIMELINE_INFO_STAGES.capital_deposit_transfer_state];
        }
    }
};

const getCompaniesInCreationRequest = params => {
    return hiwayApiInstance({
        method: 'GET',
        url: `/v1/freelancers/onboarding-timeline-info`,
        params,
    }).then(result => {
        if (result?.data?.data) {
            return {
                meta: result?.data?.meta,
                totalRecords: result?.data?.totalRecords,
                entities: result.data.data.reduce((accumulator, current) => {
                    accumulator[current.company_id] = new BackofficeCompany(current);

                    return accumulator;
                }, {}),
            };
        }

        return result.data.reduce((accumulator, current) => {
            accumulator[current.company_id] = new BackofficeCompany(current);

            return accumulator;
        }, {});
    });
};

const sendReminderRequest = ({to, type, message, subject}) => {
    return hiwayApiInstance({
        method: 'POST',
        url: `/v1/freelancers/${to.freelancerId}/companies/${to.companyId}/send-reminder-email`,
        data: {
            message,
            subject,
            reminder_type: type,
        },
    }).then(result => result);
};

// TODO Removed
const saveRegistrationStatus = ({to, status, data}) => {
    const formData = new FormData();

    formData.append('note', data?.note || '');
    formData.append('status', status);

    if (data?.kbis_incomplete_file) {
        formData.append('kbis_incomplete_file', data.kbis_incomplete_file[0]);
    }

    return hiwayApiInstance({
        method: 'PUT',
        url: `/v1/freelancers/${to.freelancerId}/companies/${to.companyId}/registration-status`,
        data: formData,
    }).then(result => result);
};

// complete-registration
const saveRegistrationPerformed = ({to, data}) => {
    const formData = new FormData();

    formData.append('siren', data.siren);
    formData.append('siret', data.siret);
    formData.append('ape_naf_code', data.ape_naf_code);
    formData.append('vat', data.vat);
    if (data.shouldSendFile) {
        formData.append('file', data.file[0]);
    }

    return hiwayApiInstance({
        method: 'POST',
        url: `/v1/freelancers/${to.freelancerId}/companies/${to.companyId}/complete-registration`,
        data: formData,
    }).then(result => result);
};

// TODO: This needs to be checked and refactored, this endpoint returns not just KBIS document,
//  but any document from the following array [ KBIS, VALIDATED_SYNTHESIS, LEGAL_NOTICE ]
//  I have added a copy of this endpoint with more appropriate name below (getCompanyRegistrationDocuments),
//  during refactor, the one below should replace this one.
const getKbisDocuments = (freelancerId, companyId) => {
    return hiwayApiInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents?doc_context=company_registraion`,
    });
};

// TODO: This can also be replaced with getDocumentsByCategory
const getCompanyRegistrationDocuments = (freelancerId, companyId) => {
    return hiwayApiInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents?doc_context=company_registraion`,
    });
};

const editRegistrationDate = (freelancerId, companyId, date) => {
    return hiwayApiInstance({
        method: 'POST',
        data: {
            activity_start_date: date,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/change-registration-date`,
    });
};

const startCompany = (freelancerId, companyId, data) => {
    return hiwayApiInstance({
        method: 'POST',
        data: {
            draft: data.draft,
            registration_method: data.registrationMethod,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/start-registration`,
    }).then(response => safeTransformData(response, fromSingleCompanyDTO));
};

const companyRegistrationCreateDraft = (freelancerId, companyId) => {
    return hiwayApiInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/company-registration-draft`,
    }).then(response => safeTransformData(response, fromSingleCompanyDTO));
};

const companyRegistsrationRestart = (freelancerId, companyId) => {
    return hiwayApiInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/reset-registration`,
    }).then(response => safeTransformData(response, fromSingleCompanyDTO));
};

const companyRegistsrationRetry = (freelancerId, companyId, ignoreWarnings) => {
    return hiwayApiInstance({
        method: 'POST',
        data: {
            ignoreWarnings: ignoreWarnings,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/retry-registration`,
    }).then(response => safeTransformData(response, fromSingleCompanyDTO));
};

const companyRegistsrationRefresh = (freelancerId, companyId) => {
    return hiwayApiInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/refresh-registration-status`,
    }).then(response => safeTransformData(response, fromSingleCompanyDTO));
};

const companyRegistrationManualLaunch = (freelancerId, companyId) => {
    return hiwayApiInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/manual-confirm-registration-launch`,
    }).then(response => safeTransformData(response, fromSingleCompanyDTO));
};

const companyregistrationManualValidate = (freelancerId, companyId, inpiIntegrationId) => {
    return hiwayApiInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/link-registration-with-inpi`,
        data: {
            inpi_integration_id: inpiIntegrationId.toString(),
        },
    }).then(response => safeTransformData(response, fromSingleCompanyDTO));
};

const companyRegistrationFinalization = (freelancerId, companyId) => {
    return hiwayApiInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/registration-finalization`,
    }).then(response => safeTransformData(response, fromSingleCompanyDTO));
};

export const updateEnterpriseInformationNote = (freelancerId, companyId, note) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/enterprise-info`,
        data: {
            note,
        },
    }).then(response => safeTransformData(response));
};


export const BackofficeApi = {
    getCompaniesInCreationRequest,
    sendReminderRequest,
    saveRegistrationStatus,
    saveRegistrationPerformed,
    getKbisDocuments,
    getCompanyRegistrationDocuments,
    editRegistrationDate,
    startCompany,
    companyRegistrationCreateDraft,
    companyRegistsrationRestart,
    companyRegistsrationRetry,
    companyRegistsrationRefresh,
    companyRegistrationManualLaunch,
    companyRegistrationFinalization,
    companyregistrationManualValidate,
    updateEnterpriseInformationNote,
};
