export const findCategoryByEnum = (categoryList, categoryEnum) => {
    let foundCategory;
    categoryList.forEach(categoryParent => {
        categoryParent.mccGroupItems.forEach(category => {
            if (category?.mccKey === categoryEnum) {
                foundCategory = category;
            }
        });
    });

    return foundCategory;
};

export const findCategoryNameInList = (categoryList, categoryEnum, language, defaultValue = '') => {
    if (!categoryEnum) {
        return '';
    }

    let categoryName = defaultValue;
    categoryList.forEach(categoryParent => {
        categoryParent.mccGroupItems.forEach(category => {
            if (category?.mccKey === categoryEnum) {
                categoryName = category[language].categoryName;
            }
        });
    });

    return categoryName;
};
