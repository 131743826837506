export const transformDashboardData = dashboardDataDto => {
    const {
        cash_management: cashManagement,
        income,
        income_tax: incomeTax,
        turnover,
    } = dashboardDataDto;
    const {
        vat_balance: vatDebt,
        csg_balance: csgDebt,
        csg_n1_debt: csgN1Debt,
        minimum_cash_threshold: minimumCashThreshold,
    } = cashManagement;
    const {
        current_year_total_paid_remuneration: paid,
        last_month_expected_remuneration: total,
        current_year_total_remuneration: plannedTotal,
        monthly_salary: monthlySalary,
        monthly_non_commercial_profits: monthlyProfits,
    } = income;
    const {
        personal_tax: personalTax,
        household_tax: householdTax,
    } = incomeTax;
    const {
        by_month: balance,
        current_year_total: currentYearTotal,
    } = turnover;

    let totalIncome = total;
    let isValidationPending = false;

    if (totalIncome === 0) {
        isValidationPending = true;
    }

    // Rounding
    if (!total || total < 0) {
        totalIncome = 0;
    } else {
        totalIncome = Math.floor(totalIncome / 10000) * 100;
    }

    return {
        cacheManagement: {
            vatDebt: vatDebt ? vatDebt < 0 ? 0 : vatDebt / 100 : 0,
            csgDebt: csgDebt ? csgDebt < 0 ? 0 : csgDebt / 100 : 0,
            csgN1Debt: csgN1Debt ? csgN1Debt < 0 ? 0 : csgN1Debt / 100 : 0,
            minimumCashThreshold: minimumCashThreshold ? minimumCashThreshold < 0 ? 0 : minimumCashThreshold / 100 : 0,
        },
        income: {
            paid: paid ? paid < 0 ? 0 : paid / 100 : 0,
            isTotalZero: total === 0,
            total: totalIncome,
            plannedTotal: plannedTotal ? plannedTotal < 0 ? 0 : plannedTotal / 100 : 0,
            chartData: Array(12).fill(null).map((value, index) => {
                const salary = monthlySalary[index] / 100 ?? 0;
                const balance = monthlyProfits[index] / 100 ?? 0;

                return {
                    salary: salary < 0 ? 0 : salary,
                    balance: balance < 0 ? 0 : balance,
                };
            }),
        },
        taxIncome: {
            personalTax: personalTax ? personalTax < 0 ? 0 : personalTax / 100 : 0,
            householdTax: householdTax ? householdTax < 0 ? 0 : householdTax / 100 : 0,
        },
        turnover: {
            balance: balance.map(amount => ({
                amount: amount ? amount < 0 ? 0 : amount / 100 : 0,
            })),
            currentYearTotal: currentYearTotal ? currentYearTotal < 0 ? 0 : currentYearTotal / 100 : 0,
        },
        month: dashboardDataDto.current_remuneration_month,
        isValidationPending,
    };
};
