import {DocumentActionTypes} from './document.action-type';

export const DocumentActions = {
    getDocument: (documentId, isDownloading) => ({
        type: DocumentActionTypes.GET_DOCUMENT,
        payload: {
            documentId,
            isDownloading,
        },
    }),
    getDocumentFullData: (documentId, isDownloading, freelancerId, companyId) => ({
        type: DocumentActionTypes.GET_DOCUMENT_FULL_DATA,
        payload: {
            documentId,
            isDownloading,
            freelancerId,
            companyId,
        },
    }),
    getQonto: () => ({
        type: DocumentActionTypes.GET_QONTO,
    }),
    getStatuses: () => ({
        type: DocumentActionTypes.GET_STATUSES,
    }),
    uploadDocument: data => ({
        type: DocumentActionTypes.UPLOAD_DOCUMENT,
        payload: data,
    }),
    getKbis: () => ({
        type: DocumentActionTypes.GET_KBIS,
    }),
    storeDepositDocuments: documents => ({
        type: DocumentActionTypes.STORE_DEPOSIT_DOCUMENTS,
        payload: documents,
    }),
    regenerateSelectedBatchDocuments: (companyId, freelancerId, batchNumber) => ({
        type: DocumentActionTypes.REGENERATE_SELECTED_BATCH_DOCUMENTS,
        payload: {
            companyId,
            freelancerId,
            batchNumber,
        },
    }),
    getClusterDocument: (documentId, isDownloading, freelancerId) => ({
        type: DocumentActionTypes.GET_CLUSTER_DOCUMENT,
        payload: {
            documentId,
            isDownloading,
            freelancerId,
        },
    }),
};
