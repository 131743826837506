import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CalculateIcon from '@mui/icons-material/Calculate';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {Box, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

const HiwayContent = () => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:1200px)`);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isMobileSize ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                gap: isMobileSize ? 3 : 2,
            }}
        >
            <InfoBox
                Icon={AccountBalanceWalletIcon}
                text={t('connectProPage.hiway.text1')}
                isMobileSize={isMobileSize}
            />
            <InfoBox
                Icon={RocketLaunchIcon}
                text={t('connectProPage.hiway.text2')}
                isMobileSize={isMobileSize}
            />
            <InfoBox
                Icon={CalculateIcon}
                text={t('connectProPage.hiway.text3')}
                isMobileSize={isMobileSize}
            />
            <InfoBox
                Icon={CreditCardIcon}
                text={t('connectProPage.hiway.text4')}
                isMobileSize={isMobileSize}
            />
        </Box>
    );
};

const InfoBox = ({Icon, text, isMobileSize}) => (
    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        py: isMobileSize ? 0 : 2,
        pr: 4,
        flex: '1 0 0',
    }}
    >
        <Box sx={{
            backgroundColor: 'v2.light.primary.shades12',
            borderRadius: '50%',
            p: 2,
            display: 'flex',
            justifyContent: 'center',
            color: 'primary_v2.main',
        }}
        >
            <Icon sx={{fontSize: 40}} />
        </Box>
        <Box sx={{
            fontWeight: 600,
            color: 'text_v2.secondary',
            fontSize: '16px',
            lineHeight: '20px',
        }}
        >
            {text}
        </Box>
    </Box>
);

InfoBox.propTypes = {
    Icon: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
};

export default HiwayContent;
