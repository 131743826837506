import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {CARD_TYPES, CardPhysicalStatus} from '../../util/constants';

const BankCardSelectorToggler = ({
    setSelectedCard,
    selectedCard,
    card,
}) => {
    const isPhysLeft = card?.physicalCard?.status !== CardPhysicalStatus.TO_ACTIVATE
                    && card?.physicalCard?.status !== CardPhysicalStatus.PROCESSING;
    const isPhysSelected = selectedCard === CARD_TYPES.PHYS;

    const isSelectedLeft = (isPhysLeft && isPhysSelected) || (!isPhysLeft && !isPhysSelected);

    return (
        <Box sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            gap: 1,
            zIndex: 4,
        }}
        >
            <Box
                onClick={() => {
                    setSelectedCard(!isPhysLeft ? CARD_TYPES.PHYS : CARD_TYPES.VIRTUAL);
                }}
                sx={{
                    backgroundColor: !isSelectedLeft ? 'v2.blueGray.500' : 'v2.blueGray.100',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    cursor: !isSelectedLeft ? 'default' : 'pointer',
                }}
            />
            <Box
                onClick={() => {
                    setSelectedCard(isPhysLeft ? CARD_TYPES.PHYS : CARD_TYPES.VIRTUAL);
                }}
                sx={{
                    backgroundColor: isSelectedLeft ? 'v2.blueGray.500' : 'v2.blueGray.100',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    cursor: !isSelectedLeft ? 'pointer' : 'default',
                }}
            />
        </Box>
    );
};

BankCardSelectorToggler.propTypes = {
    setSelectedCard: PropTypes.func.isRequired,
    selectedCard: PropTypes.string.isRequired,
    card: PropTypes.object.isRequired,
};

export default BankCardSelectorToggler;
