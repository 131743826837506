import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useNoInputStyles} from './styles';

const NoInput = ({noInputText}) => {
    const {t} = useTranslation();
    const styles = useNoInputStyles();

    return (
        <Typography variant="body2" className={styles.root}>
            {noInputText ?? t('common:noInput')}
        </Typography>
    );
};

NoInput.propTypes = {
    noInputText: PropTypes.string,
};

NoInput.defaultProps = {
    noInputText: null,
};

export default NoInput;
