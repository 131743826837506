import {Box, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import InfoSidebarAlert from './InfoSidebarAlert';
import InfoSidebarHeader from './InfoSidebarHeader';
import InfoSidebarOther from './InfoSidebarOther';

const InfoSidebar = ({details}) => {
    const {t} = useTranslation('bank');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={isMobileSize ? {} : {
            p: 2,
            pb: 3,
        }}
        >
            <Typography sx={{
                fontSize: theme => theme.typography.pxToRem(21),
                lineHeight: theme => theme.typography.pxToRem(26),
                letterSpacing: '0.25px',
                fontWeight: 700,
                color: 'text_v2.secondary',
                mb: 1,
            }}
            >
                {t('categorisation.sidebar.title')}
            </Typography>

            <Box>
                <InfoSidebarHeader data={details} />

                <InfoSidebarAlert data={details} unallocatedAmount={0} />

                <InfoSidebarOther data={details} />
            </Box>
        </Box>
    );
};

InfoSidebar.propTypes = {
    details: PropTypes.object.isRequired,
};

export default InfoSidebar;
