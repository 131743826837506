import {Button, CircularProgress} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import {useButtonWithCircularProgressStyles} from './styles';

const ButtonWithCircularProgress = React.forwardRef((props, ref) => {
    const styles = useButtonWithCircularProgressStyles();

    const {
        buttonProps,
        circularProgressProps,
        isLoading,
        isFullWidth,
        children,
        isInline,
    } = props;

    return (
        <div
            className={clsx(styles.submitButtonContainer, props.className, {
                [styles.fullWidth]: isFullWidth,
                [styles.inline]: isInline,
            })}
            ref={ref}
        >
            <Button
                {...buttonProps}
                className={clsx(styles.submitButton, buttonProps.className, {
                    [styles.fullWidth]: isFullWidth,
                })}
            >
                {children}
            </Button>

            {isLoading && (
                <CircularProgress
                    {...circularProgressProps}
                    className={clsx(styles.submitButtonProgress, circularProgressProps.className)}
                />
            )}
        </div>
    );
});

ButtonWithCircularProgress.propTypes = {
    buttonProps: PropTypes.object,
    circularProgressProps: PropTypes.object,
    isLoading: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    isInline: PropTypes.bool,
    className: PropTypes.string,
};

ButtonWithCircularProgress.defaultProps = {
    buttonProps: {
        className: '',
    },
    circularProgressProps: {
        size: 24,
        className: '',
    },
    isLoading: false,
    isFullWidth: false,
    isInline: false,
    className: '',
};

export default ButtonWithCircularProgress;
