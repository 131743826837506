import {Box, Paper, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import SectionStatus from '../common/SectionStatus';

const CategorisationSection = ({
    isActive,
    title,
    status,
    isFocused,
    sx,
    hasNoElevation,
    SectionStatusComponent,
    children,
}) => {
    const Status = SectionStatusComponent ?? SectionStatus;

    return (
        <Paper
            elevation={hasNoElevation
                ? 0
                : (isFocused ? 8 : (isActive ? 1 : 0))
            }
            sx={{
                pt: isActive ? 2 : 1,
                pr: isActive ? 4 : 1,
                pb: isActive ? 3 : 1,
                pl: isActive ? 4 : 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: isActive ? 4 : 2,
                ...sx,
            }}
        >
            <Box sx={{
                display: 'flex',
                backgroundColor: '#fff',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
            }}
            >
                <Typography sx={{
                    color: 'v2.blueGray.300',
                    fontSize: theme => theme.typography.pxToRem(12),
                    fontWeight: 500,
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                    width: '100%',
                }}
                >
                    {title}
                </Typography>

                {status && (
                    <Status status={status} />
                )}
            </Box>

            {isActive && children}
        </Paper>
    );
};

CategorisationSection.propTypes = {
    isActive: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    status: PropTypes.string,
    sx: PropTypes.object,
    isFocused: PropTypes.bool,
    hasNoElevation: PropTypes.bool,
    SectionStatusComponent: PropTypes.any,
};

CategorisationSection.defaultProps = {
    isActive: false,
    status: null,
    sx: {},
    isFocused: false,
    hasNoElevation: false,
    SectionStatusComponent: null,
};

export default CategorisationSection;
