import {MenuItem, Select} from '@mui/material';
import PropTypes from 'prop-types';
import {useController} from 'react-hook-form';


export const SelectField = ({name, options, label, required}) => {
    const {field} = useController({name, defaultValue: '', rules: {required}});
    return (
        <Select
            {...field}
            displayEmpty
            renderValue={value => options.find(option => option.value === value)?.label || label}
            fullWidth
            sx={{
                'height': theme => theme.typography.pxToRem(60),
                'fontSize': theme => theme.typography.pxToRem(18),
                'fontWeight': 700,
                'textAlign': 'left',
                '& .MuiOutlinedInput-notchedOutline': {
                    border: '4px solid',
                    borderColor: 'v2.blueGray.50',
                    borderRadius: '16px',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'v2.blueGray.100',
                },
            }}
        >
            {options.map(option => (<MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>))}
        </Select>
    );
};

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    // eslint-disable-next-line react/boolean-prop-naming
    required: PropTypes.bool.isRequired,
};
