import {combineReducers} from 'redux';
import {DatabaseActionTypes} from './database.action-type';
import {createReducer} from '../../../../../utils/create-reducer';
import {DocumentActionTypes} from '../../../store/document.action-type';

export const databaseReducer = combineReducers({
    documents: (state = [], {type, payload}) => {
        if (type === DatabaseActionTypes.STORE_DOCUMENTS) {
            return payload;
        }

        if (type === DatabaseActionTypes.CLEAR_DOCUMENTS) {
            return [];
        }

        return state;
    },

    depositDocuments: createReducer([], DocumentActionTypes.STORE_DEPOSIT_DOCUMENTS),
});
