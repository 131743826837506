import {LoggedInUserAccount, toUserDTO} from './logged-in-user.dto';
import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';

export const getLoggedInUserAccount = () => {
    // TODO Remove
    // hiwayApiInstance.post('https://api.dev.hiway-freelance.com/v1/test/freelancers/91b4e0a2-fdaa-430a-936b-0a609a2eaecd/companies/58729d64-01f5-459b-a3de-ef94a23e929f/manual-validate-registration');
    return hiwayApiInstance
        .get(`/v1/users/me`)
        .then(result => new LoggedInUserAccount(result.data));
};

export const updateLoggedInUserAccount = (user, propagateAddressChange) => {
    return hiwayApiInstance
        .put(`/v1/users/me`, {
            ...toUserDTO(user),
            propagate_address_change: propagateAddressChange,
        })
        .then(result => new LoggedInUserAccount(result.data));
};
