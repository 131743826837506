import {UiActionTypes} from './ui.action-type';

export const UiActions = {
    setActiveModal: (modalKey, state) => ({
        type: UiActionTypes.SET_ACTIVE_MODAL,
        payload: {
            modalKey,
            state,
        },
    }),
    setModalData: (modalKey, data) => ({
        type: UiActionTypes.SET_MODAL_DATA,
        payload: {
            modalKey,
            data,
        },
    }),
    setIsMaintenanceActive: isActive => ({
        type: UiActionTypes.SET_IS_MAINTENANCE_ACTIVE,
        payload: isActive,
    }),
};
