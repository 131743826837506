export const DELETE_DOCUMENT = 'company/companyDocuments/DELETE_DOCUMENT';
export const GENERATE_COMPANY_DOCUMENTS = 'company/companyDocuments/GENERATE_COMPANY_DOCUMENTS';
export const GET_COMPANY_DOCUMENTS = 'company/companyDocuments/GET_COMPANY_DOCUMENTS';
export const REGENERATE_COMPANY_DOCUMENTS = 'company/companyDocuments/REGENERATE_COMPANY_DOCUMENTS';
export const SET_HAS_DOCUMENT_GENERATION_ERROR = 'company/companyDocuments/SET_HAS_DOCUMENT_GENERATION_ERROR';
export const SET_HAS_UNSIGNED_DOCUMENTS = 'company/companyDocuments/SET_HAS_UNSIGNED_DOCUMENTS';
export const SET_IS_ALL_DOCUMENTS_SIGNED_ALERT_OPEN = 'company/companyDocuments/SET_IS_ALL_DOCUMENTS_SIGNED_ALERT_OPEN';
export const SET_IS_LOADING_COMPANY_DOCUMENTS = 'company/companyDocuments/SET_IS_LOADING_COMPANY_DOCUMENTS';
export const STORE_COMPANY_DOCUMENTS = 'company/companyDocuments/STORE_COMPANY_DOCUMENTS';
export const STORE_AT_LEAST_ONE_PAY_DOCUMENT_EXISTS = 'company/companyDocuments/STORE_AT_LEAST_ONE_PAY_DOCUMENT_EXISTS';
export const GET_HIWAY_DOCUMENTS = 'company/GET_HIWAY_DOCUMENTS';
export const STORE_HIWAY_DOCUMENTS = 'company/STORE_HIWAY_DOCUMENTS';

export const getCompanyDocuments = (freelancerId, companyId, documentContext) => ({
    type: GET_COMPANY_DOCUMENTS,
    payload: {
        companyId,
        freelancerId,
        documentContext,
    },
});

export const getHiwayDocuments = () => ({
    type: GET_HIWAY_DOCUMENTS,
});

export const storeHiwayDocuments = documents => ({
    type: STORE_HIWAY_DOCUMENTS,
    payload: documents,
});

export const storeAtLastOnePayCategoryDocumentExists = documentsExist => ({
    type: STORE_AT_LEAST_ONE_PAY_DOCUMENT_EXISTS,
    payload: documentsExist,
});

export const storeCompanyDocuments = documents => ({
    type: STORE_COMPANY_DOCUMENTS,
    payload: documents,
});

export const setIsCompanyDocumentsLoading = isLoading => ({
    type: SET_IS_LOADING_COMPANY_DOCUMENTS,
    payload: isLoading,
});

export const generateCompanyDocuments = () => ({
    type: GENERATE_COMPANY_DOCUMENTS,
});

export const regenerateCompanyDocuments = () => ({
    type: REGENERATE_COMPANY_DOCUMENTS,
});

export const setHasDocumentGenerationError = hasError => ({
    type: SET_HAS_DOCUMENT_GENERATION_ERROR,
    payload: hasError,
});

export const deleteDocument = (documentId, freelancerId, companyId) => ({
    type: DELETE_DOCUMENT,
    payload: {documentId, freelancerId, companyId},
});

export const setIsAllDocumentsSignedAlertOpen = isOpen => ({
    type: SET_IS_ALL_DOCUMENTS_SIGNED_ALERT_OPEN,
    payload: isOpen,
});

export const setHasUnsignedDocuments = hasUnsignedDocuments => ({
    type: SET_HAS_UNSIGNED_DOCUMENTS,
    payload: hasUnsignedDocuments,
});
