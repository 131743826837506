export const ZIP_CODE_REGEXES = {
    'uk': /^([A-Z])([0-9][0-9]|[0-9]|[A-Z][0-9][A-Z]|[A-Z][0-9][0-9]|[A-Z][0-9]|[0-9][A-Z])([ ])?([0-9][A-z][A-z])$/i,
    'gb': /^([A-Z])([0-9][0-9]|[0-9]|[A-Z][0-9][A-Z]|[A-Z][0-9][0-9]|[A-Z][0-9]|[0-9][A-Z])([ ])?([0-9][A-z][A-z])$/i,
    'je': /^JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
    'gg': /^GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
    'im': /^IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
    'us': /^([0-9]{5})(?:-([0-9]{4}))?$/,
    'ca': /^([ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ])\s*([0-9][ABCEGHJKLMNPRSTVWXYZ][0-9])$/i,
    'ie': /^([AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/,
    'de': /^\d{5}$/,
    'jp': /^\d{3}-\d{4}$/,
    'fr': /^\d{2}[ ]?\d{3}$/,
    'au': /^\d{4}$/,
    'it': /^\d{5}$/,
    'ch': /^\d{4}$/,
    'at': /^(?!0)\d{4}$/,
    'es': /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/,
    'nl': /^\d{4}[ ]?[A-Z]{2}$/,
    'be': /^\d{4}$/,
    'dk': /^\d{4}$/,
    'se': /^(SE-)?\d{3}[ ]?\d{2}$/,
    'no': /^\d{4}$/,
    'br': /^\d{5}[-]?\d{3}$/,
    'pt': /^\d{4}([-]\d{3})?$/,
    'fi': /^(FI-|AX-)?\d{5}$/,
    'ax': /^22\d{3}$/,
    'kr': /^\d{5}$/,
    'cn': /^\d{6}$/,
    'tw': /^\d{3}(\d{2})?$/,
    'sg': /^\d{6}$/,
    'dz': /^\d{5}$/,
    'ad': /^AD\d{3}$/,
    'ar': /^([A-HJ-NP-Z])?\d{4}([A-Z]{3})?$/,
    'am': /^(37)?\d{4}$/,
    'az': /^\d{4}$/,
    'bh': /^((1[0-2]|[2-9])\d{2})?$/,
    'bd': /^\d{4}$/,
    'bb': /^(BB\d{5})?$/,
    'by': /^\d{6}$/,
    'bm': /^[A-Z]{2}[ ]?[A-Z0-9]{2}$/,
    'ba': /^\d{5}$/,
    'io': /^BBND 1ZZ$/,
    'bn': /^[A-Z]{2}[ ]?\d{4}$/,
    'bg': /^\d{4}$/,
    'kh': /^\d{5}$/,
    'cv': /^\d{4}$/,
    'cl': /^\d{7}$/,
    'cr': /^(\d{4,5}|\d{3}-\d{4})$/,
    'hr': /^(HR-)?\d{5}$/,
    'cy': /^\d{4}$/,
    'cz': /^\d{3}[ ]?\d{2}$/,
    'do': /^\d{5}$/,
    'ec': /^([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?$/,
    'eg': /^\d{5}$/,
    'ee': /^\d{5}$/,
    'fo': /^\d{3}$/,
    'ge': /^\d{4}$/,
    'gr': /^\d{3}[ ]?\d{2}$/,
    'gl': /^39\d{2}$/,
    'gt': /^\d{5}$/,
    'ht': /^\d{4}$/,
    'hn': /^(?:\d{5})?$/,
    'hu': /^\d{4}$/,
    'is': /^\d{3}$/,
    'in': /^\d{6}$/,
    'id': /^\d{5}$/,
    'il': /^\d{5,7}$/,
    'jo': /^\d{5}$/,
    'kz': /^\d{6}$/,
    'ke': /^\d{5}$/,
    'kw': /^\d{5}$/,
    'la': /^\d{5}$/,
    'lv': /^(LV-)?\d{4}$/,
    'lb': /^(\d{4}([ ]?\d{4})?)?$/,
    'li': /^(948[5-9])|(949[0-7])$/,
    'lt': /^(LT-)?\d{5}$/,
    'lu': /^(L-)?\d{4}$/,
    'mk': /^\d{4}$/,
    'my': /^\d{5}$/,
    'mv': /^\d{5}$/,
    'mt': /^[A-Z]{3}[ ]?\d{2,4}$/,
    'mu': /^((\d|[A-Z])\d{4})?$/,
    'mx': /^\d{5}$/,
    'md': /^\d{4}$/,
    'mc': /^980\d{2}$/,
    'ma': /^\d{5}$/,
    'np': /^\d{5}$/,
    'nz': /^\d{4}$/,
    'ni': /^((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?$/,
    'ng': /^(\d{6})?$/,
    'om': /^(PC )?\d{3}$/,
    'pk': /^\d{5}$/,
    'py': /^\d{4}$/,
    'ph': /^\d{4}$/,
    'pl': /^\d{2}-\d{3}$/,
    'pr': /^00[679]\d{2}([ -]\d{4})?$/,
    'ro': /^\d{6}$/,
    'ru': /^\d{6}$/,
    'sm': /^4789\d$/,
    'sa': /^\d{5}$/,
    'sn': /^\d{5}$/,
    'sk': /^\d{3}[ ]?\d{2}$/,
    'si': /^(SI-)?\d{4}$/,
    'za': /^\d{4}$/,
    'lk': /^\d{5}$/,
    'tj': /^\d{6}$/,
    'th': /^\d{5}$/,
    'tn': /^\d{4}$/,
    'tr': /^\d{5}$/,
    'tm': /^\d{6}$/,
    'ua': /^\d{5}$/,
    'uy': /^\d{5}$/,
    'uz': /^\d{6}$/,
    'va': /^00120$/,
    've': /^\d{4}$/,
    'zm': /^\d{5}$/,
    'as': /^96799$/,
    'cc': /^6799$/,
    'ck': /^\d{4}$/,
    'rs': /^\d{5,6}$/,
    'me': /^8\d{4}$/,
    'cs': /^\d{5}$/,
    'yu': /^\d{5}$/,
    'cx': /^6798$/,
    'et': /^\d{4}$/,
    'fk': /^FIQQ 1ZZ$/,
    'nf': /^2899$/,
    'fm': /^(9694[1-4])([ -]\d{4})?$/,
    'gf': /^9[78]3\d{2}$/,
    'gn': /^\d{3}$/,
    'gp': /^9[78][01]\d{2}$/,
    'gs': /^SIQQ 1ZZ$/,
    'gu': /^969[123]\d([ -]\d{4})?$/,
    'gw': /^\d{4}$/,
    'hm': /^\d{4}$/,
    'iq': /^\d{5}$/,
    'kg': /^\d{6}$/,
    'lr': /^\d{4}$/,
    'ls': /^\d{3}$/,
    'mg': /^\d{3}$/,
    'mh': /^969[67]\d([ -]\d{4})?$/,
    'mn': /^\d{6}$/,
    'mp': /^9695[012]([ -]\d{4})?$/,
    'mq': /^9[78]2\d{2}$/,
    'nc': /^988\d{2}$/,
    'ne': /^\d{4}$/,
    'vi': /^008(([0-4]\d)|(5[01]))([ -]\d{4})?$/,
    'pf': /^987\d{2}$/,
    'pg': /^\d{3}$/,
    'pm': /^9[78]5\d{2}$/,
    'pn': /^PCRN 1ZZ$/,
    'pw': /^96940$/,
    're': /^9[78]4\d{2}$/,
    'sh': /^(ASCN|STHL) 1ZZ$/,
    'sj': /^\d{4}$/,
    'so': /^\d{5}$/,
    'sz': /^[HLMS]\d{3}$/,
    'tc': /^TKCA 1ZZ$/,
    'wf': /^986\d{2}$/,
    'xk': /^\d{5}$/,
    'yt': /^976\d{2}$/,
    'international': /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/i,
};
