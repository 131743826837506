import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {importLocaleBundle} from '../../../../../../../../lib/i18next';

importLocaleBundle('dropzone');

export const ExternalInvoiceDropZone = ({
    fileTypes,
    maxFiles,
    maxSize,
    onChange,
    onDropRejectedHandler,
    isDisabled,
    fileListLength,
    children,
}) => {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        maxFiles: maxFiles,
        accept: fileTypes,
        maxSize: maxSize,
        onDropRejected: onDropRejectedHandler,
    });

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            onChange(acceptedFiles);
        }
    }, [acceptedFiles, onChange]);

    useEffect(() => {
        // TODO: fix this for maxFiles > 1
        // use something like isChanging instead of the fileListLength
        if (acceptedFiles.length > 0 && !fileListLength) {
            acceptedFiles.splice(0, acceptedFiles.length);
        }
        // eslint-disable-next-line
    }, [fileListLength]);

    if (isDisabled) {
        return (
            <Box
                sx={{
                    width: '100%',
                }}
            >
                {children}
            </Box>
        );
    }

    return (
        <Box
            sx={{
                width: '100%',
            }}
            {...getRootProps()}
        >
            <input {...getInputProps()} />

            {children}
        </Box>
    );
};

ExternalInvoiceDropZone.propTypes = {
    fileTypes: PropTypes.string.isRequired,
    maxFiles: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onDropRejectedHandler: PropTypes.func,
    maxSize: PropTypes.number,
    files: PropTypes.array,
    isDisabled: PropTypes.bool,
    fileListLength: PropTypes.number.isRequired,
};

ExternalInvoiceDropZone.defaultProps = {
    maxSize: 10485760, // 100 MB
    files: [],
    isDisabled: false,
    onDropRejectedHandler: null,
};
