export const CompanySetupSubSteps = {
    PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
    ADDRESS_INFORMATION: 'ADDRESS_INFORMATION',
    DECLARATION_OF_NON_CONVICTION: 'DECLARATION_OF_NON_CONVICTION',
    COMPANY_INFORMATION: 'COMPANY_INFORMATION',
    BANK: 'BANK',
    DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
    DOCUMENT_GENERATION: 'DOCUMENT_GENERATION',
    DATA_VALIDATED: 'DATA_VALIDATED',
};

export const CompanySetupInternalSubSteps = {
    DOCUMENT_SIGNING: 'DOCUMENT_SIGNING',
    AWAITING_DOCUMENT_VERIFICATION: 'AWAITING_DOCUMENT_VERIFICATION',
    DOCUMENTS_VERIFIED: 'DOCUMENTS_VERIFIED',
    BANK: 'BANK',
    DATA_VALIDATED: 'DATA_VALIDATED',
};
