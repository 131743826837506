import {
    faWarning,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Typography} from '@mui/joy';
import PropTypes from 'prop-types';

export const AlertWarning = ({
    title,
    text,
    children,
    sx,
}) => (
    <Alert
        color="warning"
        variant="soft"
        sx={{
            '& > .MuiAlert-startDecorator': {
                pt: 1,
            },
            ...sx,
        }}
        startDecorator={(
            <FontAwesomeIcon icon={faWarning} />
        )}
    >
        <div>
            {title && (
                <Typography
                    level="title-lg"
                    sx={{
                        color: 'warning.softColor',
                    }}
                >
                    {title}
                </Typography>
            )}
            {text && (
                <Typography
                    level="body-md"
                    sx={{
                        color: 'warning.softColor',
                        mb: children ? 1.5 : 0,
                    }}
                >
                    {text}
                </Typography>
            )}
            {children}
        </div>
    </Alert>
);

AlertWarning.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    sx: PropTypes.object,
};

AlertWarning.defaultProps = {
    title: '',
    text: '',
    sx: {},
};
