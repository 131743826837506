import AttachmentIcon from '@mui/icons-material/Attachment';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import LaunchIcon from '@mui/icons-material/Launch';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {UiActions} from '../../../../features/ui/store/ui.action';
import {ModalsKeys} from '../../../../features/ui/utils/constants';
import {isUserCareOrFreelancer} from '../../../../utils/user-roles';
import {getCompanyDocument} from '../../../app/company/company.actions';
import {deleteDocument} from '../../../app/company/companyDocuments/companyDocuments.actions';
import {
    storeUploadDocumentsData,
    uploadDocuments,
} from '../../../app/company/companyDocuments/uploadDocuments/uploadDocuments.actions';
import {
    selectIsUploadingDocuments,
    selectUploadDocumentsData,
} from '../../../app/company/companyDocuments/uploadDocuments/uploadDocuments.selectors';
import {selectLoggedInUser} from '../../../app/user/user.selectors';
import {DOCUMENT_TYPES, GLOBAL_DOCUMENT_TYPES} from '../../../config/constants/documentConstants';
import AttachDocuments from '../../ui-kit/AttachDocuments/AttachDocuments';
import ConfirmationDialog from '../../ui-kit/ConfirmationDialog/ConfirmationDialog';
import DataTableRowActions from '../../ui-kit/DataTableRowActions/DataTableRowActions';

const getMainActionsConfig = (
    translate,
    dispatch,
    rowData,
    isReplaceButtonDisabled,
    isDeleteButtonDisabled,
    onReplace,
    onDelete,
) => ([
    {
        title: translate('documentsDatabaseTable.actionButtons.viewDocument'),
        icon: <LaunchIcon />,
        buttonProps: {
            onClick: () => {
                dispatch(getCompanyDocument(rowData.freelancerId, rowData.companyId, rowData.id));
            },
        },
    },
    {
        title: translate('documentsDatabaseTable.actionButtons.downloadDocument'),
        icon: <GetAppIcon />,
        buttonProps: {
            onClick: () => {
                dispatch(getCompanyDocument(rowData.freelancerId, rowData.companyId, rowData.id, true));
            },
        },
    },
    {
        title: translate('documentsDatabaseTable.actionButtons.updateDocument'),
        icon: <AttachmentIcon />,
        disabled: isReplaceButtonDisabled,
        buttonProps: {
            onClick: onReplace,
        },
    },
    {
        title: translate('documentsDatabaseTable.actionButtons.deleteDocument'),
        icon: <DeleteIcon />,
        disabled: isDeleteButtonDisabled,
        buttonProps: {
            onClick: onDelete,
        },
    },
]);

const DocumentRowActions = ({rowData}) => {
    const {t} = useTranslation('documents');

    const dispatch = useDispatch();

    const user = useSelector(selectLoggedInUser);
    const files = useSelector(selectUploadDocumentsData);
    const isUploadingDocuments = useSelector(selectIsUploadingDocuments);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    // Maybe we can write rowData.id to the selector, used in ModalWrapper
    // to avoid multuple modals opening, when we have several of them in one page
    const [isReplaceDialogOpen, setIsReplaceDialogOpen] = useState(false);

    const params = useParams();

    const isReplaceButtonDisabled = useMemo(() => {
        if (isUserCareOrFreelancer(user)) {
            return user?.id !== rowData.uploaderId;
        }

        return GLOBAL_DOCUMENT_TYPES.includes(rowData.type);
    }, [rowData.type, rowData.uploaderId, user]);

    const isDeleteButtonDisabled = useMemo(() => {
        if (isUserCareOrFreelancer(user)) {
            return user?.id !== rowData.uploaderId;
        }

        return GLOBAL_DOCUMENT_TYPES.includes(rowData.type);
    }, [rowData.type, rowData.uploaderId, user]);

    const handleDeleteButtonClick = useCallback(() => {
        setIsDeleteDialogOpen(true);
    }, []);

    const handleReplaceButtonClick = useCallback(() => {
        setIsReplaceDialogOpen(true);
        dispatch(UiActions.setActiveModal(ModalsKeys.ATTACH_DOCUMENTS, true));
    }, [dispatch]);

    const handleDeleteConfirmButtonClick = useCallback(() => {
        dispatch(deleteDocument(rowData.id, rowData.freelancerId, rowData.companyId));
    }, [dispatch, rowData.companyId, rowData.freelancerId, rowData.id]);

    const handleDeleteCancelButtonClick = useCallback(() => {
        setIsDeleteDialogOpen(false);
    }, []);

    const handleAttachDocumentsClose = useCallback(() => {
        setIsReplaceDialogOpen(false);
        dispatch(storeUploadDocumentsData([]));
    }, [dispatch]);

    const handleReplaceConfirmButtonClick = useCallback(() => {
        dispatch(uploadDocuments(true, params.freelancerId, params.companyId));
        setIsReplaceDialogOpen(false);
        dispatch(UiActions.setActiveModal(ModalsKeys.ATTACH_DOCUMENTS, false));
    }, [dispatch, params.companyId, params.freelancerId]);

    const handleAddFiles = useCallback(files => {
        dispatch(storeUploadDocumentsData([{
            file: files[0],
            id: rowData.id,
            type: rowData.type,
            subType: rowData.subType,
            category: rowData.category,
        }]));
    }, [dispatch, rowData.category, rowData.id, rowData.subType, rowData.type]);

    const handleRemoveFile = useCallback(fileName => {
        dispatch(storeUploadDocumentsData(files.filter(item => item.file.name !== fileName)));
    }, [dispatch, files]);

    const config = useMemo(() => {
        return getMainActionsConfig(
            t,
            dispatch,
            rowData,
            isReplaceButtonDisabled,
            isDeleteButtonDisabled,
            handleReplaceButtonClick,
            handleDeleteButtonClick,
        );
    }, [
        dispatch,
        handleDeleteButtonClick,
        handleReplaceButtonClick,
        isDeleteButtonDisabled,
        isReplaceButtonDisabled,
        rowData,
        t,
    ]);

    return (
        <>
            <DataTableRowActions config={config} />

            {isReplaceDialogOpen && (
                <AttachDocuments
                    onAddFiles={handleAddFiles}
                    onRemoveFile={handleRemoveFile}
                    onClose={handleAttachDocumentsClose}
                    files={files.map(item => item.file)}
                    onSubmit={handleReplaceConfirmButtonClick}
                    title={t('replaceDocumentTitle')}
                    message={t('replaceDocumentMessage')}
                    isLoading={isUploadingDocuments}
                />
            )}

            <ConfirmationDialog
                onCancel={handleDeleteCancelButtonClick}
                message={t('confirmFileDelete')}
                onConfirm={handleDeleteConfirmButtonClick}
                title={t('areYouSure')}
                isOpen={isDeleteDialogOpen}
            />
        </>
    );
};

DocumentRowActions.propTypes = {
    rowData: PropTypes.shape({
        id: PropTypes.string.isRequired,
        fileName: PropTypes.string.isRequired,
        docName: PropTypes.string.isRequired,
        subType: PropTypes.string,
        category: PropTypes.string.isRequired,
        uploaderId: PropTypes.string,
        freelancerId: PropTypes.string.isRequired,
        companyId: PropTypes.string.isRequired,
        type: PropTypes.oneOf(Object.values(DOCUMENT_TYPES)).isRequired,
    }).isRequired,
};

export default DocumentRowActions;
