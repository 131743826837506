import makeStyles from '@mui/styles/makeStyles';


export const useContractInformationStyles = makeStyles(theme => ({
    informationContainer: {
        marginTop: theme.spacing(1),
    },
    sectionTitle: {
        padding: theme.spacing(3, 3, 0, 3),
    },
    footerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
    },
    singleButtonFooter: {
        justifyContent: 'flex-end',
    },
    pdfIcon: {
        marginRight: theme.spacing(1),
    },
    fileName: {
        display: 'inline-flex',
        alignItems: 'center',
    },
}));
