import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, Grow, Paper} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import FinalizationAnimation from './FinalizationAnimation';
import {RoutePaths} from '../../../../lib/router/route-paths';
import {resolveRoute} from '../../../../v1/navigation/resolveRoute';
import {AnimationActions} from '../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../animations/utils/constants';

const FinalizationCompleted = () => {
    const dispatch = useDispatch();
    const {t: tTraining} = useTranslation('training');
    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const onEndedAnimation = () => { dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN)); };
    const isMobileSize = useMediaQuery(`(max-width:599px)`);
    const minHeight = isMobileSize ? 0 : 530;
    const dashboardLink = resolveRoute(RoutePaths.DASHBOARD);

    return (
        <Box sx={{px: {xs: 2, lg: 0}, display: 'flex', flexDirection: 'column'}}>
            <Grow in={!!isAnimationActive} onExited={onEndedAnimation}>
                <Paper
                    elevation={1}
                    sx={{
                        borderRadius: 2,
                        minHeight: minHeight,
                        height: 'calc(100vh - 290px)',
                        overflowY: 'auto',
                        p: {xs: 2, md: 5},
                        mb: 2,
                    }}
                >
                    <Paper
                        elevation={0}
                        sx={{
                            bgcolor: '#FAFAFA',
                            borderRadius: 2,
                            height: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: 3,
                            px: 2,
                        }}
                    >
                        <FinalizationAnimation />
                    </Paper>
                </Paper>
            </Grow>
            <Link
                to={dashboardLink}
                style={{
                    textDecoration: 'none',
                    alignSelf: 'flex-end',
                    width: isMobileSize ? '100%' : '',
                }}
            >
                <Button
                    size="large"
                    variant="contained"
                    sx={{bgcolor: 'primary_v2.main'}}
                    endIcon={<ArrowForwardIcon />}
                    fullWidth
                >
                    {tTraining('finalization.returnToDashboard')}
                </Button>
            </Link>
        </Box>
    );
};

export default FinalizationCompleted;
