import {SvgIcon} from '@mui/material';

export const WalletThinIcon = props => (
    <SvgIcon viewBox="0 0 49 48" {...props}>
        <path
            d="M9.66797 36.35V39V9V36.35ZM9.66797 42C8.84297 42 8.13672 41.7062 7.54922 41.1188C6.96172 40.5312 6.66797 39.825 6.66797 39V9C6.66797 8.175 6.96172 7.46875 7.54922 6.88125C8.13672 6.29375 8.84297 6 9.66797 6H39.668C40.493 6 41.1992 6.29375 41.7867 6.88125C42.3742 7.46875 42.668 8.175 42.668 9V15.7H39.668V9H9.66797V39H39.668V32.35H42.668V39C42.668 39.825 42.3742 40.5312 41.7867 41.1188C41.1992 41.7062 40.493 42 39.668 42H9.66797ZM27.568 33.35C26.5505 33.35 25.6794 32.9926 24.9548 32.2778C24.2303 31.563 23.868 30.7037 23.868 29.7V18.35C23.868 17.3463 24.2303 16.487 24.9548 15.7722C25.6794 15.0574 26.5505 14.7 27.568 14.7H41.068C42.0855 14.7 42.9565 15.0574 43.6811 15.7722C44.4057 16.487 44.768 17.3463 44.768 18.35V29.7C44.768 30.7037 44.4057 31.563 43.6811 32.2778C42.9565 32.9926 42.0855 33.35 41.068 33.35H27.568ZM41.768 30.35V17.7H26.868V30.35H41.768ZM33.2797 27.2C34.1719 27.2 34.9263 26.884 35.543 26.2521C36.1596 25.6201 36.468 24.8528 36.468 23.95C36.468 23.075 36.1513 22.3313 35.518 21.7188C34.8846 21.1062 34.1346 20.8 33.268 20.8C32.4013 20.8 31.6513 21.1062 31.018 21.7188C30.3846 22.3313 30.068 23.075 30.068 23.95C30.068 24.8528 30.3802 25.6201 31.0047 26.2521C31.6293 26.884 32.3876 27.2 33.2797 27.2Z"
            fill="currentColor"
        />
    </SvgIcon>
);
