const selectCurrentDocumentUrl = state => state.document.signing.currentDocumentUrl;
const selectCurrentDocumentId = state => state.document.signing.currentDocumentId;
const selectCurrentDocumentIsSigned = state => state.document.signing.currentDocumentIsSigned;
const selectIsLoadingCurrentDocument = state => state.document.signing.isLoading;
const selectIsSigningCurrentDocument = state => state.document.signing.isSigning;
const selectIsSigningAdditionalDocuments = state => state.document.signing.isSigningAdditionalDocuments;

export const SigningSelectors = {
    selectCurrentDocumentUrl,
    selectCurrentDocumentId,
    selectCurrentDocumentIsSigned,
    selectIsLoadingCurrentDocument,
    selectIsSigningCurrentDocument,
    selectIsSigningAdditionalDocuments,
};
