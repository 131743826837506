/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {Typography} from '@mui/joy';
import {styled} from '@mui/system';
import clsx from 'clsx';
import * as React from 'react';
import {
    getPickersToolbarTextUtilityClass,
    pickersToolbarTextClasses,
} from './pickersToolbarTextClasses';

const useUtilityClasses = ownerState => {
    const {classes, selected} = ownerState;
    const slots = {
        root: ['root', selected && 'selected'],
    };

    return composeClasses(slots, getPickersToolbarTextUtilityClass, classes);
};

const PickersToolbarTextRoot = styled(Typography, {
    name: 'PrivatePickersToolbarText',
    slot: 'Root',
    overridesResolver: (_, styles) => [
        styles.root,
        {[`&.${pickersToolbarTextClasses.selected}`]: styles.selected},
    ],
})(({theme}) => ({
    transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    color: theme.palette.text.secondary,
    [`&.${pickersToolbarTextClasses.selected}`]: {
        color: theme.palette.text.primary,
    },
}));

export const PickersToolbarText = React.forwardRef((
    props,
    ref,
) => {
    // TODO v6: add 'useThemeProps' once the component class names are aligned
    const {className, selected, value, ...other} = props;
    const classes = useUtilityClasses(props);

    return (
        <PickersToolbarTextRoot
            ref={ref}
            className={clsx(className, classes.root)}
            component="span"
            {...other}
        >
            {value}
        </PickersToolbarTextRoot>
    );
});
