import {BoTrainingInfoFrom, BoTrainingInfoTo} from './bo-training.dto';
import {hiwayApiInstance} from '../../../lib/hiway-api-instance';

const getBoTrainingInfo = ({freelancerId, companyId}) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}/companies/${companyId}/training-info`)
        .then(result => new BoTrainingInfoFrom(result.data));
};

const saveBoTrainingInfo = ({freelancerId, companyId, data}) => {
    return hiwayApiInstance
        .put(`/v1/freelancers/${freelancerId}/companies/${companyId}/training-info`, new BoTrainingInfoTo(data))
        .then(result => new BoTrainingInfoFrom(result.data));
};

export const BoTrainingApi = {
    getBoTrainingInfo,
    saveBoTrainingInfo,
};
