export const GET_ENTERPRISE_INFORMATION = 'GET_ENTERPRISE_INFORMATION';
export const STORE_ENTERPRISE_INFORMATION = 'STORE_ENTERPRISE_INFORMATION';
export const UPDATE_ENTERPRISE_INFORMATION = 'UPDATE_ENTERPRISE_INFORMATION';
export const SET_IS_LOADING_ENTERPRISE_INFORMATION = 'SET_IS_LOADING_ENTERPRISE_INFORMATION';
export const SET_IS_UPDATING_ENTERPRISE_INFORMATION = 'SET_IS_UPDATING_ENTERPRISE_INFORMATION';

export const storeEnterpriseInformation = enterpriseInformation => ({
    type: STORE_ENTERPRISE_INFORMATION,
    payload: enterpriseInformation,
});

export const updateEnterpriseInformation = (
    freelancerId,
    companyId,
    formData,
    onSuccess,
    propagateAddressChange = false,
) => ({
    type: UPDATE_ENTERPRISE_INFORMATION,
    payload: {
        freelancerId,
        companyId,
        formData,
        onSuccess,
        propagateAddressChange,
    },
});

export const setIsLoadingEnterpriseInformation = isLoading => ({
    type: SET_IS_LOADING_ENTERPRISE_INFORMATION,
    payload: isLoading,
});

export const setIsUpdatingEnterpriseInformation = isUpdating => ({
    type: SET_IS_UPDATING_ENTERPRISE_INFORMATION,
    payload: isUpdating,
});

export const getEnterpriseInformation = (freelancerId, companyId) => ({
    type: GET_ENTERPRISE_INFORMATION,
    payload: {
        freelancerId,
        companyId,
    },
});
