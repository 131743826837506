import {all, call, put, takeLatest} from 'redux-saga/effects';

import * as actions from './additionalCompanyData.actions';
import {Toast} from '../../../../lib/toast';
import {createEnterpirseAddress} from '../../../components/company/company-profile/EnterpriseInformationForm/util';
import {
    getEnterpriseInformationRequest,
    updateEnterpriseInformationRequest,
} from '../../api/providers/company/company.provider';
import {getCompany} from '../company.actions';

const getEnterpriseInformationSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingEnterpriseInformation(true));

        const {freelancerId, companyId} = payload;

        const enterpriseInformation = yield call(getEnterpriseInformationRequest, freelancerId, companyId);

        // TODO:HIGH Move data transformation to DTO layer.
        yield put(actions.storeEnterpriseInformation({
            companyName: enterpriseInformation.company_name,
            legalStatus: enterpriseInformation.legal_status,
            headquartersAddress: createEnterpirseAddress(enterpriseInformation),
            headquartersStreet: enterpriseInformation.headquarters_street,
            headquartersStreetNumber: enterpriseInformation.headquarters_street_number,
            headquartersZip: enterpriseInformation.headquarters_zip,
            headquartersCity: enterpriseInformation.headquarters_city,
            headquartersCountry: enterpriseInformation.headquarters_country,
            activityStartDate: enterpriseInformation.activity_start_date,
            siren: enterpriseInformation.siren,
            siret: enterpriseInformation.siret,
            apeNafCode: enterpriseInformation.ape_naf_code,
            vat: enterpriseInformation.vat,
            sie: enterpriseInformation.sie,
            tvaFrequencyPayment: enterpriseInformation.tva_frequency_payment,
            dateOfHire: enterpriseInformation.date_of_hire,
            taxSystem: enterpriseInformation.tax_system,
            taxCollector: enterpriseInformation.tax_collector,
            note: enterpriseInformation.note,
            docType: enterpriseInformation.doc_type,
            useCustomCompanyAddress: enterpriseInformation.use_custom_company_address,
        }));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        if (error?.response?.status === 404) {
            return;
        }

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingEnterpriseInformation(false));
    }
};

const updateEnterpriseInformationSaga = function* ({payload}) {
    try {
        yield put(actions.setIsUpdatingEnterpriseInformation(true));

        const {freelancerId, companyId, formData, onSuccess, propagateAddressChange} = payload;

        const response = yield call(
            updateEnterpriseInformationRequest,
            freelancerId,
            companyId,
            formData,
            propagateAddressChange,
        );
        yield put(actions.storeEnterpriseInformation({
            ...formData,
            useCustomCompanyAddress: response.use_custom_company_address,
        }));
        yield put(getCompany(freelancerId, companyId));

        Toast.success('genericSuccessSave');

        if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsUpdatingEnterpriseInformation(false));
    }
};

export const loadAdditionalCompanyData = function* (freelancerId, companyId) {
    if (!freelancerId || !companyId) {
        return;
    }

    yield call(getEnterpriseInformationSaga, {
        payload: {
            freelancerId,
            companyId,
        },
    });
};

export const watchAdditionalCompanyDataSagas = function* () {
    yield all([
        takeLatest(actions.GET_ENTERPRISE_INFORMATION, getEnterpriseInformationSaga),
        takeLatest(actions.UPDATE_ENTERPRISE_INFORMATION, updateEnterpriseInformationSaga),
    ]);
};
