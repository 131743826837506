import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import {useController} from 'react-hook-form';

export const EurButtonsField = ({name, buttonAmounts, defaultValue}) => {
    const {field} = useController({name});

    const disabled = buttonAmounts === 'disabled';

    const amountsArray = disabled ? [0, null, defaultValue, null] : [0, ...buttonAmounts];

    return (
        <Box sx={{display: 'flex', gap: 2, width: '100%', height: theme => theme.typography.pxToRem(30)}}>
            {amountsArray.map((amount, index) => (
                <Button
                    key={index}
                    onClick={() => field.onChange(amount)}
                    variant={field.value !== null && field.value === amount ? 'contained' : 'outlined'}
                    fullWidth
                    size="small"
                    disabled={disabled}
                    disableElevation
                    sx={{
                        px: 0,
                        whiteSpace: 'nowrap',
                        minWidth: 0,
                        flex: 1,
                    }}
                >
                    {'€'.repeat(index) || '-'}
                </Button>
            ))}
        </Box>
    );
};

EurButtonsField.propTypes = {
    name: PropTypes.string.isRequired,
    buttonAmounts: PropTypes.oneOfType([
        PropTypes.string, // "disabled"
        PropTypes.arrayOf(PropTypes.number),
    ]).isRequired,
    defaultValue: PropTypes.number,
};

EurButtonsField.defaultProps = {
    defaultValue: 0,
};
