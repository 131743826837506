import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReplayIcon from '@mui/icons-material/Replay';
import {LoadingButton} from '@mui/lab';
import {Slide} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {PageHeader} from '../../../../../components/page-header/PageHeader';
import {Toast} from '../../../../../lib/toast';
import {FULL_COMPANY_STATUSES} from '../../../../../v1/app/company/setupCompany/setupCompany.constants';
import DocumentGenerationTable
    from '../../../../../v1/components/company/DocumentGenerationTable/DocumentGenerationTable';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE, FORM_ANIMATION_DELAY} from '../../../../animations/utils/constants';
import {DatabaseSelectors} from '../../../../document/modules/database/store/database.selector';
import {DocumentActions} from '../../../../document/store/document.action';
import {InsuranceSelectors} from '../../../../insurance/store/insurance.selector';
import {InsuranceStatus} from '../../../../insurance/utils/constants';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {FreelancerSelectors} from '../../../store/freelancer.selector';
import {FinalizationSubsteps} from '../../capital-deposit/utils/constants';
import {CompaniesSelectors} from '../../companies';
import {CompanySetupInternalSubSteps} from '../../company-setup/utils/constants';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';
import {OnboardingSteps} from '../../onboarding/utils/onboadingStepsConstant';
import {CompanyRegistrationInternalSubSteps} from '../utils/constants';

export const CompanyRegistrationFileList = ({hasIntegration}) => {
    const {t} = useTranslation('freelancerOnboarding');

    const dispatch = useDispatch();

    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));
    const isGeneratingDocuments = useLoadingState(LoadingTypes.GENERATING_DOCUMENTS);
    const insurance = useSelector(InsuranceSelectors.selectInsurance);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const generatedDocuments = useSelector(DatabaseSelectors.selectDocuments);
    const generatedDocumentsContainError = generatedDocuments && typeof generatedDocuments === 'object'
        ? Object.values(generatedDocuments).some(param => param?.status === 'ERROR')
        : false;

    let batchNumber;
    switch (company?.status) {
        case FULL_COMPANY_STATUSES.PENDING_DATA_INPUT:
        case FULL_COMPANY_STATUSES.PENDING_EDITS:
            batchNumber = 1;
            break;
        case FULL_COMPANY_STATUSES.REGISTRATION_COMPLETED:
            batchNumber = 2;
            break;
        default:
            batchNumber = null;
    }

    const canRegenerateDocuments = !!(batchNumber && generatedDocumentsContainError);

    const regenerateDocuments = () => {
        if (canRegenerateDocuments) {
            dispatch(DocumentActions.regenerateSelectedBatchDocuments(company?.id, freelancer?.id, batchNumber));
        } else {
            Toast.error('genericError');
        }
    };

    if (!isMobileSize) {
        return (
            <div>
                <PageHeader>
                    {t('companyDocumentGeneration.header')}
                </PageHeader>

                <Slide
                    direction="left"
                    in={isAnimationActive}
                    timeout={FORM_ANIMATION_DELAY}
                    onExited={() => {
                        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
                    }}
                >
                    <div>
                        <DocumentGenerationTable
                            hasSearch={false}
                            hasPagination={false}
                        />
                    </div>
                </Slide>

                <Box display="flex" justifyContent="space-between" sx={{mt: 3}}>
                    <Slide
                        direction="right"
                        in={isAnimationActive && canRegenerateDocuments}
                        timeout={FORM_ANIMATION_DELAY * 4}
                    >
                        <LoadingButton
                            endIcon={<ReplayIcon />}
                            color="primary"
                            variant="contained"
                            size="large"
                            onClick={regenerateDocuments}
                            loading={isGeneratingDocuments}
                        >
                            {t('companyDocumentGeneration.regenerateDocuments')}
                        </LoadingButton>
                    </Slide>
                    <Slide
                        direction="left"
                        in={isAnimationActive}
                        timeout={FORM_ANIMATION_DELAY * (canRegenerateDocuments ? 5 : 4)}
                    >
                        {company.status === 'ACTIVE' ? (
                            <LoadingButton
                                endIcon={<ArrowForwardIcon />}
                                color="secondary"
                                variant="contained"
                                size="large"
                                onClick={() => {
                                    if (hasIntegration) {
                                        dispatch(AnimationActions.storeNextDispatch(
                                            OnboardingActions.setInternalSubStep(
                                                CompanySetupInternalSubSteps.DOCUMENT_SIGNING,
                                            ),
                                        ));
                                        dispatch(AnimationActions.setIsAnimationActive(false));
                                    } else {
                                        dispatch(OnboardingActions.setStep(OnboardingSteps.FINAL_POINTS));
                                        // Handle for non Hiway PRO
                                        // eslint-disable-next-line no-lonely-if
                                        if (insurance?.status === InsuranceStatus.SIGNED) {
                                            dispatch(OnboardingActions.setSubStep(
                                                FinalizationSubsteps.ACCESS_PLATFORM,
                                            ));
                                            dispatch(OnboardingActions.setInternalSubStep(
                                                FinalizationSubsteps.ACCESS_PLATFORM,
                                            ));
                                        } else {
                                            dispatch(OnboardingActions.setSubStep(FinalizationSubsteps.INSURANCE));
                                            dispatch(OnboardingActions.setInternalSubStep(
                                                FinalizationSubsteps.INSURANCE,
                                            ));
                                        }
                                        // TODO animation inactive is not working here, we need to see why at some point
                                    }
                                }}
                                disabled={isGeneratingDocuments}
                            >
                                {t('capitalDepositStep1.continueButton')}
                            </LoadingButton>
                        )
                            : (
                                <LoadingButton
                                    endIcon={<ArrowForwardIcon />}
                                    color="secondary"
                                    variant="contained"
                                    size="large"
                                    onClick={() => {
                                        dispatch(AnimationActions.storeNextDispatch(
                                            OnboardingActions.setInternalSubStep(
                                                CompanySetupInternalSubSteps.DOCUMENT_SIGNING,
                                            ),
                                        ));
                                        dispatch(AnimationActions.setIsAnimationActive(false));
                                    }}
                                    disabled={isGeneratingDocuments || canRegenerateDocuments}
                                >
                                    {t('companyDocumentGeneration.signDocuments')}
                                </LoadingButton>
                            )}

                    </Slide>
                </Box>
            </div>
        );
    }

    return (
        <div>
            <PageHeader isMobile={true}>
                {t('companyDocumentGeneration.header')}
            </PageHeader>

            <div>
                <DocumentGenerationTable />
            </div>

            <Box display="flex" justifyContent="center" flexDirection="column" sx={{mt: 3}}>
                {canRegenerateDocuments && (
                    <LoadingButton
                        sx={{mx: 2, mb: 2}}
                        endIcon={<ReplayIcon />}
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={regenerateDocuments}
                        loading={isGeneratingDocuments}
                    >
                        {t('companyDocumentGeneration.regenerateDocuments')}
                    </LoadingButton>
                )}
                {company.status === 'ACTIVE'
                    ? (
                        <LoadingButton
                            sx={{mx: 2}}
                            endIcon={<ArrowForwardIcon />}
                            color="secondary"
                            variant="contained"
                            size="large"
                            onClick={() => {
                                if (hasIntegration) {
                                    dispatch(AnimationActions.storeNextDispatch(
                                        OnboardingActions.setInternalSubStep(
                                            CompanySetupInternalSubSteps.DOCUMENT_SIGNING,
                                        ),
                                    ));
                                    dispatch(AnimationActions.setIsAnimationActive(false));
                                } else {
                                    dispatch(OnboardingActions.setStep(OnboardingSteps.FINAL_POINTS));
                                    // Handle for non Hiway PRO
                                    // eslint-disable-next-line no-lonely-if
                                    if (insurance?.status === InsuranceStatus.SIGNED) {
                                        dispatch(OnboardingActions.setSubStep(
                                            FinalizationSubsteps.ACCESS_PLATFORM,
                                        ));
                                        dispatch(OnboardingActions.setInternalSubStep(
                                            FinalizationSubsteps.ACCESS_PLATFORM,
                                        ));
                                    } else {
                                        dispatch(OnboardingActions.setSubStep(FinalizationSubsteps.INSURANCE));
                                        dispatch(OnboardingActions.setInternalSubStep(
                                            FinalizationSubsteps.INSURANCE,
                                        ));
                                    }
                                    // TODO animation inactive is not working here, we need to see why at some point
                                }
                            }}
                            disabled={isGeneratingDocuments}
                        >
                            {t('capitalDepositStep1.continueButton')}
                        </LoadingButton>
                    )
                    : (
                        <LoadingButton
                            sx={{mx: 2}}
                            endIcon={<ArrowForwardIcon />}
                            color="secondary"
                            variant="contained"
                            size="large"
                            onClick={() => {
                                dispatch(AnimationActions.storeNextDispatch(
                                    OnboardingActions.setInternalSubStep(
                                        CompanyRegistrationInternalSubSteps.DOCUMENT_SIGNING,
                                    ),
                                ));
                                dispatch(AnimationActions.setIsAnimationActive(false));
                            }}
                            disabled={isGeneratingDocuments || canRegenerateDocuments}
                        >
                            {t('companyDocumentGeneration.signDocuments')}
                        </LoadingButton>
                    )}
            </Box>
        </div>
    );
};

CompanyRegistrationFileList.propTypes = {
    hasIntegration: PropTypes.bool,
};

CompanyRegistrationFileList.defaultProps = {
    hasIntegration: false,
};
