/* eslint-disable react/prop-types */
import {unstable_useEventCallback as useEventCallback} from '@mui/utils';
import * as React from 'react';
import {useLocaleText, useUtils} from '../hooks/useUtils';
import {getDisplayDate} from '../utils/text-field-helper';
import {onSpaceOrEnter} from '../utils/utils';

// TODO: why is this called "Pure*" when it's not memoized? Does "Pure" mean "readonly"?
export const PureDateInput = React.forwardRef((
    props,
    ref,
) => {
    const {
        disabled,
        getOpenDialogAriaText: getOpenDialogAriaTextProp,
        inputFormat,
        InputProps,
        inputRef,
        label,
        openPicker: onOpen,
        rawValue,
        renderInput,
        TextFieldProps = {},
        validationError,
        className,
    } = props;

    const localeText = useLocaleText();

    // The prop can not be deprecated
    // Default is "Choose date, ...", but time pickers override it with "Choose time, ..."
    const getOpenDialogAriaText = getOpenDialogAriaTextProp ?? localeText.openDatePickerDialogue;

    const utils = useUtils();
    const PureDateInputProps = React.useMemo(
        () => ({
            ...InputProps,
            readOnly: true,
        }),
        [InputProps],
    );

    const inputValue = getDisplayDate(utils, rawValue, inputFormat);

    const handleOnClick = useEventCallback(event => {
        event.stopPropagation();
        onOpen();
    });

    return renderInput({
        label,
        disabled,
        ref,
        slotProps: {
            input: {
                ref: inputRef,
            },
        },
        error: validationError,
        ...(PureDateInputProps),
        className,
        // registering `onClick` listener on the root element as well to correctly
        // handle cases where user is clicking on `label`
        // which has `pointer-events: none` and due to DOM structure the `input`
        // does not catch the click event
        ...(!props.readOnly && !props.disabled && {onClick: handleOnClick}),
        inputProps: {
            disabled,
            'readOnly': true,
            'aria-readonly': true,
            'aria-label': getOpenDialogAriaText(rawValue, utils),
            'value': inputValue,
            ...(!props.readOnly && {onClick: handleOnClick}),
            'onKeyDown': onSpaceOrEnter(onOpen),
        },
        ...TextFieldProps,
    });
});
