import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Fab, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AnimationActions} from '../../../animations/store/animations.action';
import {DatabaseSelectors} from '../../../document/modules/database/store/database.selector';
import {DocumentSigning} from '../../../document/modules/signing/components/DocumentSigning';
import {SigningActions} from '../../../document/modules/signing/store/signing.action';
import {SigningSelectors} from '../../../document/modules/signing/store/signing.selector';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {UploadSignatureModal} from '../../../signature/components/UploadSignatureModal';

export const DossierDocumentSigning = () => {
    const dispatch = useDispatch();

    const isLoadingNextStep = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.TRAINING_STEP_SUBMIT),
    );

    const documents = useSelector(DatabaseSelectors.selectSignableDocuments);

    const currentDocumentId = useSelector(SigningSelectors.selectCurrentDocumentId);
    const numberOfDocuments = useSelector(DatabaseSelectors.selectNumberOfSignableDocuments());
    const currentDocumentIndex = useSelector(DatabaseSelectors.selectDocumentIndex(currentDocumentId));

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const {t} = useTranslation('document');

    const onPreviousDocumentClick = () => {
        if (!currentDocumentIndex || currentDocumentIndex - 2 < 0) {
            return;
        }
        const document = documents.sort((previous, next) => {
            const previousType = previous.type.toUpperCase();
            const nextType = next.type.toUpperCase();

            return (previousType < nextType) ? -1 : (previousType > nextType) ? 1 : 0;
        })[currentDocumentIndex - 2];

        dispatch(AnimationActions.storeNextDispatch(
            SigningActions.setCurrentCompanyDocument(document.id),
        ));
        dispatch(AnimationActions.setIsAnimationActive(false));
    };

    const onNextDocumentClick = () => {
        if (!currentDocumentIndex || currentDocumentIndex >= numberOfDocuments) {
            return;
        }
        const document = documents.sort((previous, next) => {
            const previousType = previous.type.toUpperCase();
            const nextType = next.type.toUpperCase();

            return (previousType < nextType) ? -1 : (previousType > nextType) ? 1 : 0;
        })[currentDocumentIndex];

        dispatch(AnimationActions.storeNextDispatch(
            SigningActions.setCurrentCompanyDocument(document.id),
        ));
        dispatch(AnimationActions.setIsAnimationActive(false));
    };

    useEffect(() => {
        if (!currentDocumentId && documents && documents.length > 0 && !isLoadingNextStep) {
            dispatch(SigningActions.openNextTrainingDocument());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDocumentId, dispatch, documents]);

    return (
        <Box sx={{
            position: 'relative',
        }}
        >
            <Box sx={!isMobileSize
                ? {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    mt: -1,
                    width: '238px',
                    position: 'absolute',
                    right: '0px',
                    top: '-53px',
                }
                : {
                    display: 'none',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    mt: -2,
                }
            }
            >
                <Fab
                    size="medium"
                    color="default"
                    disabled={currentDocumentIndex < 2}
                    aria-label="previous-document"
                    onClick={onPreviousDocumentClick}
                    sx={{
                        background: '#fff',
                        boxShadow: '0px 3px 1px -2px rgba(11, 35, 51, 0.05), 0px 2px 2px rgba(11, 35, 51, 0.07), 0px 1px 5px rgba(11, 35, 51, 0.09);',
                    }}
                >
                    <ChevronLeftIcon />
                </Fab>

                <Typography variant="h5">{currentDocumentIndex} {t('signing.of')} {numberOfDocuments}</Typography>

                <Fab
                    size="medium"
                    color="default"
                    aria-label="next-document"
                    disabled={currentDocumentIndex === numberOfDocuments}
                    onClick={onNextDocumentClick}
                    sx={{
                        background: '#fff',
                        boxShadow: '0px 3px 1px -2px rgba(11, 35, 51, 0.05), 0px 2px 2px rgba(11, 35, 51, 0.07), 0px 1px 5px rgba(11, 35, 51, 0.09);',
                    }}
                >
                    <ChevronRightIcon />
                </Fab>
            </Box>

            <DocumentSigning
                type="training"
                isLoading={isLoadingNextStep}
            />

            <UploadSignatureModal />
        </Box>
    );
};
