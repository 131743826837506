import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const Rate = ({rowData}) => {
    const {dailyRateLow, dailyRateHigh} = rowData;

    if (!dailyRateLow && !dailyRateHigh) {
        return '-';
    }

    if (dailyRateLow && dailyRateLow !== '0') {
        return (
            <Box>{dailyRateLow} - {dailyRateHigh}€</Box>
        );
    }

    return (
        <Box>{dailyRateHigh}€</Box>
    );
};

Rate.propTypes = {
    rowData: PropTypes.any.isRequired,
};

export default React.memo(Rate);
