import makeStyles from '@mui/styles/makeStyles';

export const useUserCompaniesStyles = makeStyles(theme => ({
    noCompaniesLabel: {
        fontFamily: 'Barlow',
        color: 'rgba(0, 0, 0, 0.54)',
        opacity: 0.5,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(18),
        letterSpacing: '1.6px',
        textAlign: 'center',
        padding: theme.spacing(7, 0),
    },
}));
