export const GET_COMPANY = 'GET_COMPANY';
export const STORE_COMPANY = 'STORE_COMPANY';
export const SET_IS_LOADING_COMPANY = 'SET_IS_LOADING_COMPANY';
export const UPDATE_PERSONAL_INFORMATION = 'UPDATE_PERSONAL_INFORMATION';
export const UPDATE_NON_CONVICTION_DECLARATION = 'UPDATE_NON_CONVICTION_DECLARATION';
export const UPDATE_COMPANY_INFORMATION = 'UPDATE_COMPANY_INFORMATION';
export const SET_IS_UPDATING_PERSONAL_INFORMATION = 'SET_IS_UPDATING_PERSONAL_INFORMATION';
export const SET_IS_UPDATING_NON_CONVICTION_DECLARATION = 'SET_IS_UPDATING_NON_CONVICTION_DECLARATION';
export const SET_IS_UPDATING_COMPANY_INFORMATION = 'SET_IS_UPDATING_COMPANY_INFORMATION';
export const APPROVE_COMPANY = 'APPROVE_COMPANY';
export const SET_IS_APPROVING_COMPANY = 'SET_IS_APPROVING_COMPANY';
export const REQUEST_EDITS = 'REQUEST_EDITS';
export const SET_IS_REQUESTING_EDITS = 'SET_IS_REQUESTING_EDITS';
export const GET_PERSONAL_COMPANY_DOCUMENTS = 'GET_PERSONAL_COMPANY_DOCUMENTS';
export const STORE_PERSONAL_COMPANY_DOCUMENTS = 'STORE_PERSONAL_COMPANY_DOCUMENTS';
export const SET_IS_LOADING_PERSONAL_COMPANY_DOCUMENTS = 'SET_IS_LOADING_PERSONAL_COMPANY_DOCUMENTS';
export const GET_COMPANY_DOCUMENT = 'GET_COMPANY_DOCUMENT';
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const SET_UPLOADING_DOCUMENT_IDS = 'SET_UPLOADING_DOCUMENT_IDS';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const SET_IS_LOADING_COMPANY_PROFILE_VIEW = 'SET_IS_LOADING_COMPANY_PROFILE_VIEW';
export const UPDATE_COMPANY_INFORMATION_WITH_ACTIVITY_START_DATE = 'UPDATE_COMPANY_INFORMATION_WITH_ACTIVITY_START_DATE';
export const SET_REGISTRATION_PERFORMED = 'SET_REGISTRATION_PERFORMED';

export const getCompany = (freelancerId, companyId) => ({
    type: GET_COMPANY,
    payload: {
        companyId,
        freelancerId,
    },
});

export const storeCompany = company => ({
    type: STORE_COMPANY,
    payload: company,
});

export const setRegistrationPerformed = (freelancerId, companyId) => ({
    type: SET_REGISTRATION_PERFORMED,
    payload: {
        freelancerId,
        companyId,
    },
});

export const setIsLoadingCompany = isLoading => ({
    type: SET_IS_LOADING_COMPANY,
    payload: isLoading,
});

export const updatePersonalInformation = (personalInformation, onSuccess) => ({
    type: UPDATE_PERSONAL_INFORMATION,
    payload: {
        personalInformation,
        onSuccess,
    },
});

export const updateNonConvictionDeclaration = (nonConvictionDeclaration, onSuccess) => ({
    type: UPDATE_NON_CONVICTION_DECLARATION,
    payload: {
        nonConvictionDeclaration,
        onSuccess,
    },
});

export const updateCompanyInformation = (companyInformation, onSuccess) => ({
    type: UPDATE_COMPANY_INFORMATION,
    payload: {
        companyInformation,
        onSuccess,
    },
});

export const setIsUpdatingPersonalInformation = isUpdating => ({
    type: SET_IS_UPDATING_PERSONAL_INFORMATION,
    payload: isUpdating,
});

export const setIsUpdatingNonConvictionDeclaration = isUpdating => ({
    type: SET_IS_UPDATING_NON_CONVICTION_DECLARATION,
    payload: isUpdating,
});

export const setIsUpdatingCompanyInformation = isUpdating => ({
    type: SET_IS_UPDATING_COMPANY_INFORMATION,
    payload: isUpdating,
});

export const approveCompany = onSuccess => ({
    type: APPROVE_COMPANY,
    payload: {
        onSuccess,
    },
});

export const setIsApprovingCompany = isApprovingCompany => ({
    type: SET_IS_APPROVING_COMPANY,
    payload: isApprovingCompany,
});

export const requestEdits = (message, onSuccess) => ({
    type: REQUEST_EDITS,
    payload: {
        message,
        onSuccess,
    },
});

export const setIsRequestingEdits = isRequestingEdits => ({
    type: SET_IS_REQUESTING_EDITS,
    payload: isRequestingEdits,
});

export const storeCompanyDocuments = companyDocuments => ({
    type: STORE_PERSONAL_COMPANY_DOCUMENTS,
    payload: companyDocuments,
});

export const getCompanyDocuments = (freelancerId, companyId) => ({
    type: GET_PERSONAL_COMPANY_DOCUMENTS,
    payload: {
        freelancerId,
        companyId,
    },
});

export const setIsLoadingCompanyDocuments = isLoading => ({
    type: SET_IS_LOADING_PERSONAL_COMPANY_DOCUMENTS,
    payload: isLoading,
});

export const getCompanyDocument = (
    freelancerId,
    companyId,
    documentId,
    isDownload = false,
    isView = false,
) => ({
    type: GET_COMPANY_DOCUMENT,
    payload: {
        freelancerId,
        companyId,
        documentId,
        isDownload,
        isView,
    },
});

export const uploadDocument = (companyId, documentId, file, onSuccess, hasNotification = true) => ({
    type: UPLOAD_DOCUMENT,
    payload: {
        companyId,
        documentId,
        file,
        onSuccess,
        hasNotification,
    },
});

export const createDocument = (companyId, documentId, file, onSuccess, subType) => ({
    type: CREATE_DOCUMENT,
    payload: {
        companyId,
        documentId,
        file,
        onSuccess,
        subType,
    },
});

export const setUploadingDocumentIds = documentIds => ({
    type: SET_UPLOADING_DOCUMENT_IDS,
    payload: documentIds,
});

export const deleteDocument = (companyId, documentId) => ({
    type: DELETE_DOCUMENT,
    payload: {
        companyId,
        documentId,
    },
});

export const setIsLoadingCompanyProfileView = isLoading => ({
    type: SET_IS_LOADING_COMPANY_PROFILE_VIEW,
    payload: isLoading,
});

export const updateCompanyInformationWithActivityStartDate = (
    freelancerId,
    companyId,
    companyInformation,
    activityStartDate,
    onSuccess,
) => ({
    type: UPDATE_COMPANY_INFORMATION_WITH_ACTIVITY_START_DATE,
    payload: {
        freelancerId,
        companyId,
        companyInformation,
        activityStartDate,
        onSuccess,
    },
});
