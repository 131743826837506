import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, Grid, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, generatePath} from 'react-router-dom';
import {ButtonAction} from '../../../../components/buttons/ButtonAction';
import {RoutePaths} from '../../../../lib/router/route-paths';

export const SubmitOpportunityButtonGroup = ({isValid, isDirty, isLoading, isSaving}) => {
    const {t} = useTranslation('opportunity');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    if (!isMobileSize) {
        return (
            <Grid
                item
                sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    component={Link}
                    to={generatePath(RoutePaths.CLUSTER)}
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    disabled={isSaving}
                >
                    {t('submitOpportunity.cancel')}
                </Button>

                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    {isSaving && (
                        <Typography sx={{mr: 2}}>
                            {t('submitOpportunity.savingText')}
                        </Typography>
                    )}

                    <ButtonAction
                        isLoading={isSaving}
                        color="error"
                        type="submit"
                        endIcon={<ArrowForwardIcon />}
                        disabled={!isValid || !isDirty || isLoading || isSaving}
                    >
                        {t('submitOpportunity.submit')}
                    </ButtonAction>
                </Box>
            </Grid>
        );
    }

    return (
        <Grid
            item
            sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {isSaving && (
            <Typography sx={{mb: 2}}>
                {t('submitOpportunity.savingText')}
            </Typography>
            )}
            <ButtonAction
                isLoading={isSaving}
                color="error"
                type="submit"
                endIcon={<ArrowForwardIcon />}
                disabled={!isValid || !isDirty || isLoading || isSaving}
            >
                {t('submitOpportunity.submit')}
            </ButtonAction>

            <Button
                sx={{mt: 2}}
                component={Link}
                to={generatePath(RoutePaths.CLUSTER)}
                variant="outlined"
                startIcon={<CloseIcon />}
                disabled={isSaving}
            >
                {t('submitOpportunity.cancel')}
            </Button>
        </Grid>
    );
};

SubmitOpportunityButtonGroup.propTypes = {
    isValid: PropTypes.bool.isRequired,
    isDirty: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    isSaving: PropTypes.bool,
};

SubmitOpportunityButtonGroup.defaultProps = {
    isLoading: false,
    isSaving: false,
};
