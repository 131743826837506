import {Auth} from 'aws-amplify';
import {call, put} from 'redux-saga/effects';
import {COOKIE_NAMES, getCookie} from '../../../utils/cookie';
import {AnimationActions} from '../../animations/store/animations.action';

export const isUserLoggedIn = function* () {
    let isUserLoggedIn = false;

    try {
        const cookieToken = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);
        if (!cookieToken) {
            const session = yield call([Auth, Auth.currentSession]);

            isUserLoggedIn = session && !!session.getIdToken().getJwtToken();
        } else {
            isUserLoggedIn = true;
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        // Cancel animation for non logged in users
        yield put(AnimationActions.setInitialAnimation(false));
    }

    return isUserLoggedIn;
};
