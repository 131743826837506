import makeStyles from '@mui/styles/makeStyles';

export const useCompanyInformationFormStyles = makeStyles(theme => ({
    container: {
        width: '100%',
    },
    containerLoading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(4),
    },
    formLabel: {
        marginBottom: theme.spacing(2),
        color: '#0B2333',
        fontWeight: 500,
        letterSpacing: '0.1px',
        lineHeight: '26px',
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    bankFormControl: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    bankCheckbox: {
        'color': 'rgba(0, 0, 0, 0.87)',
        'marginRight': theme.spacing(6),
        '& .MuiFormControlLabel-label': {
            marginLeft: theme.spacing(1),
        },
        '&:last-of-type': {
            marginRight: 0,
        },
    },
}));
