import {AnimationActionTypes} from './animations.action-type';

export const AnimationActions = {
    setIsAnimationActive: status => ({
        type: AnimationActionTypes.SET_IS_ANIMATION_ACTIVE,
        payload: status,
    }),
    setIsSubAnimationActive: (status = true) => ({
        type: AnimationActionTypes.SET_IS_SUB_ANIMATION_ACTIVE,
        payload: status,
    }),
    storeNextDispatch: action => ({
        type: AnimationActionTypes.STORE_NEXT_DISPATCH,
        payload: action,
    }),
    animationEnded: animationType => ({
        type: AnimationActionTypes.ANIMATION_ENDED,
        payload: animationType,
    }),
    setIsMobile: status => ({
        type: AnimationActionTypes.SET_IS_MOBILE,
        payload: status,
    }),
    setInitialAnimation: status => ({
        type: AnimationActionTypes.SET_INITIAL_ANIMATION,
        payload: status,
    }),
};
