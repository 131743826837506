import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EuroTwoToneIcon from '@mui/icons-material/EuroTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Box, Typography} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {useSelector} from 'react-redux';
import {roundHalfUpToTwoDecimalPlaces} from '../../../../../../utils/number';
import {SettingsSelectors} from '../../../../../settings/store/settings.selectors';
import {TransactionsSelector} from '../../store/transactions.selector';
import {TVA_ENUM, TVA_OPTIONS} from '../../util/constants';
import {AccountBalanceHelperFunctions} from '../../util/functions';
import {createIconFromName} from '../../util/icon';

const nonSelectedStyles = isEditable => ({
    'mt': 2,
    'textTransform': 'uppercase',
    'fontWeight': 500,
    'fontSize': theme => theme.typography.pxToRem(13),
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'cursor': isEditable ? 'pointer' : 'auto',
    'color': isEditable ? 'inherit' : 'v2.light.text.disabled',
    'backgroundColor': isEditable ? 'inherit' : 'gray_v2.50',
    'p': 1,
    'mx': -1,
    '&:hover': {
        background: isEditable ? '#f2f2f2' : 'inherit',
        borderRadius: '16px',
    },
});

const selectedStyles = (isEditable, isSuggested) => ({
    'mt': 2,
    'fontSize': theme => theme.typography.pxToRem(13),
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'p': 1,
    'cursor': isEditable ? 'pointer' : 'auto',
    'color': isEditable ? isSuggested ? '#0B2333' : 'white' : 'v2.light.text.disabled',
    'backgroundColor': isEditable ? isSuggested ? 'rgba(25, 118, 210, 0.08)' : '#3779BE' : 'gray_v2.50',
    'borderRadius': '8px',
});

export const TVA = ({tva, value, articleAmount, changeTva, changeTvaValue, isEditable}) => {
    const {t} = useTranslation('bank');
    let newValue = '';
    if (value || Number(value) === 0) {
        if (Number(value) === 0) {
            newValue = 0;
        } else if (Number(value)) {
            newValue = roundHalfUpToTwoDecimalPlaces(value).toFixed(2);
        }
    }

    const [tvaAmount, setTvaAmount] = useState(newValue);
    const hasTvaAmountError = useMemo(
        () => !isNaN(parseFloat(articleAmount))
            && !isNaN(parseFloat(tvaAmount))
            && Number(articleAmount) < Number(tvaAmount),
        [articleAmount, tvaAmount],
    );

    useEffect(() => {
        if (tvaAmount !== newValue) {
            setTvaAmount(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newValue]);

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 2,
        }}
        >
            <Box sx={{mr: 1, width: '50%'}}>
                <TextField
                    sx={{
                        background: 'white',
                    }}
                    size="small"
                    label={t('translationDetails.vatLabel')}
                    select
                    fullWidth
                    defaultValue={tva || ''}
                    onChange={e => {
                        changeTva(e.target.value);
                    }}
                    disabled={tva === TVA_ENUM.NO_VAT || !isEditable}
                >
                    {tva === 'NO_TVA' && (
                        <MenuItem
                            value={TVA_ENUM.NO_VAT}
                        >
                            {t(`vat.${TVA_ENUM.NO_VAT}`)}
                        </MenuItem>
                    )}
                    {tva !== 'NO_TVA' && TVA_OPTIONS.map(tva => {
                        return (
                            <MenuItem
                                key={tva.value}
                                value={tva.value}
                            >
                                {t(`vat.${tva.value}`)}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Box>

            <Box sx={{ml: 1, width: '50%'}}>
                <NumberFormat
                    customInput={TextField}
                    decimalSeparator="."
                    thousandSeparator={false}
                    allowNegative={false}
                    decimalScale={2}
                    allowedDecimalSeparators={[',', '.']}
                    fixedDecimalScale={true}
                    sx={tva !== TVA_ENUM.TVA_CUSTOM || !isEditable ? {} : {
                        background: 'white',
                    }}
                    size="small"
                    fullWidth
                    label={t('translationDetails.amountLabel')}
                    disabled={tva !== TVA_ENUM.TVA_CUSTOM || !isEditable}
                    onChange={e => {
                        setTvaAmount(e.target.value);
                        changeTvaValue(e.target.value);
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: <EuroTwoToneIcon />,
                    }}
                    value={tvaAmount}
                    error={hasTvaAmountError}
                />
            </Box>
        </Box>
    );
};

TVA.propTypes = {
    tva: PropTypes.string,
    changeTva: PropTypes.func,
    changeTvaValue: PropTypes.func,
    value: PropTypes.string,
    isEditable: PropTypes.bool,
    articleAmount: PropTypes.string.isRequired,
};

TVA.defaultProps = {
    tva: 'TVA_0',
    changeTva: () => {},
    changeTvaValue: () => {},
    value: '',
    isEditable: false,
};

export const TransactionDetailsNonSelectedCategory = ({
    onOpenCategory,
    suggestedCategory,
    onSelectCategory,
    isEditable,
}) => {
    const {t} = useTranslation('bank');

    const onSelectCategoryClick = () => {
        onSelectCategory({mccKey: suggestedCategory});
    };

    return (
        <Box>
            <Box
                sx={nonSelectedStyles(isEditable)}
                onClick={isEditable ? onOpenCategory : () => {}}
            >
                <Box sx={{
                    'display': 'flex',
                    'alignItems': 'center',
                    'gap': 2,
                }}
                >
                    <CategoryTwoToneIcon
                        sx={{color: isEditable ? 'primary_v2.main' : 'v2.light.text.disabled'}}
                    />
                    {t('translationDetails.selectACategory')}
                </Box>
                <KeyboardArrowDownIcon />
            </Box>
            {suggestedCategory && isEditable && (
                <Box sx={{mt: 2}}>
                    <Typography sx={{
                        fontSize: theme => theme.typography.pxToRem(14),
                        mb: 1,
                    }}
                    >
                        {t('translationDetails.suggestedCategory')}
                    </Typography>
                    <TransactionDetailsSelectedCategory
                        onOpenCategory={onSelectCategoryClick}
                        selectedCategory={suggestedCategory}
                        isEditable={isEditable}
                        isSuggested={true}
                    />
                </Box>
            )}
        </Box>
    );
};

TransactionDetailsNonSelectedCategory.propTypes = {
    onOpenCategory: PropTypes.func.isRequired,
    suggestedCategory: PropTypes.string,
    onSelectCategory: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
};

TransactionDetailsNonSelectedCategory.defaultProps = {
    suggestedCategory: '',
};

export const TransactionDetailsSelectedCategory = ({
    onOpenCategory,
    selectedCategory,
    isEditable,
    isSuggested,
}) => {
    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    const selectCategoriesObject = useSelector(TransactionsSelector.selectCategoriesObject);

    const {iconName} = AccountBalanceHelperFunctions.getColorAndIconName({
        selectCategoriesObject,
        category: selectedCategory,
    });

    const selectedName = Array.isArray(selectedCategory)
        ? selectedCategory?.[0]
        : selectedCategory;

    return (
        <Box
            sx={selectedStyles(isEditable, isSuggested)}
            onClick={isEditable ? onOpenCategory : () => {}}
        >
            <Box sx={{'display': 'flex', 'alignItems': 'center'}}>
                {createIconFromName(iconName)}
                <Typography sx={{
                    ml: 1.2,
                    color: isEditable ? isSuggested ? '#0B2333' : '#fff' : 'v2.light.text.disabled',
                }}
                >
                    {selectCategoriesObject[selectedName]?.[language] ?? selectedName}
                </Typography>
            </Box>
            {isSuggested ? (
                <ChevronRightIcon />
            ) : (
                <KeyboardArrowDownIcon />
            )}
        </Box>
    );
};

TransactionDetailsSelectedCategory.propTypes = {
    onOpenCategory: PropTypes.func.isRequired,
    selectedCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    isEditable: PropTypes.bool.isRequired,
    isSuggested: PropTypes.bool,
};

TransactionDetailsSelectedCategory.defaultProps = {
    selectedCategory: null,
    isSuggested: false,
};

export const TransactionDetailsNonSelectedSource = ({
    onOpen,
    isEditable,
}) => {
    const {t} = useTranslation('bank');

    return (
        <Box>
            <Box
                sx={nonSelectedStyles(isEditable)}
                onClick={isEditable ? onOpen : () => {}}
            >
                <Box sx={{
                    'display': 'flex',
                    'alignItems': 'center',
                    'gap': 2,
                }}
                >
                    <CategoryTwoToneIcon
                        sx={{color: isEditable ? 'primary_v2.main' : 'v2.light.text.disabled'}}
                    />
                    {t('translationDetails.selectACategory')}
                </Box>
                <KeyboardArrowDownIcon />
            </Box>
        </Box>
    );
};

TransactionDetailsNonSelectedSource.propTypes = {
    onOpen: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
};

export const TransactionDetailsSelectedSource = ({
    onOpen,
    selected,
    isEditable,
}) => {
    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    const selectSourcesObject = useSelector(TransactionsSelector.selectSourcesObject);

    const {iconName} = AccountBalanceHelperFunctions.getColorAndIconName({
        selectSourcesObject,
        sourceOfRevenue: selected,
    });

    return (
        <Box
            sx={selectedStyles(isEditable, false)}
            onClick={isEditable ? onOpen : () => {}}
        >
            <Box sx={{'display': 'flex', 'alignItems': 'center'}}>
                {createIconFromName(iconName)}
                <Typography sx={{
                    ml: 1.2,
                    color: isEditable ? '#fff' : 'v2.light.text.disabled',
                }}
                >
                    {selectSourcesObject[selected]?.[language] ?? selected}
                </Typography>
            </Box>
            <KeyboardArrowDownIcon />
        </Box>
    );
};

TransactionDetailsSelectedSource.propTypes = {
    onOpen: PropTypes.func.isRequired,
    selected: PropTypes.string,
    isEditable: PropTypes.bool.isRequired,
};

TransactionDetailsSelectedSource.defaultProps = {
    selected: null,
};
