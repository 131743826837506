export const selectCompany = state => state.v1Company.singleCompany;

export const selectIsLoadingSingleCompany = state => state.v1Company.isLoadingSingleCompany;

export const selectIsUpdatingPersonalInformation = state => state.v1Company.isUpdatingPersonalInformation;

export const selectIsUpdatingNonConvictionDeclaration = state => state.v1Company.isUpdatingNonConvictionDeclaration;

export const selectIsUpdatingCompanyInformation = state => state.v1Company.isUpdatingCompanyInformation;

export const selectIsApprovingCompanyInProgress = state => state.v1Company.isApprovingCompanyInProgress;

export const selectIsRequestingEditsInProgress = state => state.v1Company.isRequestingEditsInProgress;

export const selectCompanyDocuments = state => state.v1Company.companyPersonalDocuments;

export const selectIsLoadingCompanyDocuments = state => state.v1Company.isLoadingPersonalCompanyDocuments;

export const selectUploadingDocumentIds = state => state.v1Company.uploadingDocumentIds;

export const selectIsLoadingCompanyProfileView = state => state.v1Company.isLoadingCompanyProfileView;
