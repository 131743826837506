import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
    getDocumentDownloadTrainingTableConfig,
    getDocumentGenerationTrainingTableConfig,
} from '../../../../v1/components/company/document-generation-table-config';
import DataTable from '../../../../v1/components/ui-kit/DataTable/DataTable';
import {DatabaseSelectors} from '../../../document/modules/database/store/database.selector';
import MobileTrainingDocuments from '../general/MobileTrainingDocuments';

export const DossierDocuments = ({hasActions}) => {
    const {t: tDocuments} = useTranslation('documents');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const columnConfig = useMemo(() => {
        if (hasActions) {
            return getDocumentDownloadTrainingTableConfig(tDocuments);
        }
        return getDocumentGenerationTrainingTableConfig(tDocuments);
    }, [tDocuments, hasActions]);

    if (isMobileSize) {
        return <MobileTrainingDocuments hasActions={hasActions} />;
    }

    return (
        <DataTable
            tableId="documentGeneration"
            columnConfig={columnConfig}
            missingDataMessage={tDocuments('missingDocuments')}
            dataSelector={DatabaseSelectors.createDocumentsDataTableSelector}
            isLoading={false}
            defaultRowsPerPage={25}
            searchPlaceholder={tDocuments('documentsDatabaseTable.searchDocuments')}
            hasSearch={false}
            hasPagination={false}
        />
    );
};

DossierDocuments.propTypes = {
    hasActions: PropTypes.bool,
};

DossierDocuments.defaultProps = {
    hasActions: false,
};
