/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {styled, useThemeProps} from '@mui/system';
import clsx from 'clsx';
import * as React from 'react';
import {
    clockNumberClasses,
    getClockNumberUtilityClass,
} from './clockNumberClasses';
import {CLOCK_HOUR_WIDTH, CLOCK_WIDTH} from './shared';

const useUtilityClasses = ownerState => {
    const {classes, selected, disabled} = ownerState;
    const slots = {
        root: ['root', selected && 'selected', disabled && 'disabled'],
    };

    return composeClasses(slots, getClockNumberUtilityClass, classes);
};

const ClockNumberRoot = styled('span', {
    name: 'MuiClockNumber',
    slot: 'Root',
    overridesResolver: (_, styles) => [
        styles.root,
        {[`&.${clockNumberClasses.disabled}`]: styles.disabled},
        {[`&.${clockNumberClasses.selected}`]: styles.selected},
    ],
})(({theme, ownerState}) => ({
    'height': CLOCK_HOUR_WIDTH,
    'width': CLOCK_HOUR_WIDTH,
    'position': 'absolute',
    'left': `calc((100% - ${CLOCK_HOUR_WIDTH}px) / 2)`,
    'display': 'inline-flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'borderRadius': '50%',
    'color': theme.palette.text.secondary,
    'fontFamily': theme.typography.fontFamily,
    '&:focused': {
        backgroundColor: theme.palette.common.white,
    },
    [`&.${clockNumberClasses.selected}`]: {
        color: theme.palette.primary.solidColor,
    },
    [`&.${clockNumberClasses.disabled}`]: {
        pointerEvents: 'none',
        color: theme.palette.text.tertiaty,
    },
    ...(ownerState.inner && {
        ...theme.typography['body-sm'],
        color: theme.palette.text.secondary,
        fontWeight: 400,
    }),
}));

export const ClockNumber = inProps => {
    const props = useThemeProps({props: inProps, name: 'MuiClockNumber'});
    const {className, disabled, index, inner, label, selected, ...other} = props;
    const ownerState = props;
    const classes = useUtilityClasses(ownerState);

    const angle = ((index % 12) / 12) * Math.PI * 2 - Math.PI / 2;
    const length = ((CLOCK_WIDTH - CLOCK_HOUR_WIDTH - 2) / 2) * (inner ? 0.65 : 1);
    const x = Math.round(Math.cos(angle) * length);
    const y = Math.round(Math.sin(angle) * length);

    return (
        <ClockNumberRoot
            className={clsx(className, classes.root)}
            aria-disabled={disabled ? true : undefined}
            aria-selected={selected ? true : undefined}
            role="option"
            style={{
                transform: `translate(${x}px, ${y + (CLOCK_WIDTH - CLOCK_HOUR_WIDTH) / 2}px`,
            }}
            ownerState={ownerState}
            {...other}
        >
            {label}
        </ClockNumberRoot>
    );
};
