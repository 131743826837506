import {Autocomplete, FormControl} from '@mui/joy';
import {Autocomplete as MuiAutocomplete, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import {FormErrorText} from '../../../../../components/joy-ui/forms/FormErrorText';
import {FormFieldLabel} from '../../../../../components/joy-ui/forms/FormFieldLabel';
import {useMemoizedCreateSelector} from '../../../../../hooks/use-memoized-create-selector';
import {requiredFieldProps} from '../../create-user-form/components/helper';
import {UserSelectSelectors} from '../store/user-select.selector';

export const UserSelect = ({control, name, label, isV3, isDisabled, hasError, ...rest}) => {
    const users = useMemoizedCreateSelector(UserSelectSelectors.createSelectOptions);

    if (isV3) {
        return (
            <Controller
                id={name}
                name={name}
                control={control}
                render={({field}) => (
                    <FormControl error={hasError}>
                        <FormFieldLabel label={label} />
                        <Autocomplete
                            key={field?.value ?? 'autocomplete'}
                            {...rest}
                            {...field}
                            placeholder={label}
                            error={hasError}
                            disabled={isDisabled}
                            options={users}
                            getOptionLabel={option => option.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(_, value) => {
                                field.onChange(value);
                            }}
                            {...requiredFieldProps}
                            handleHomeEndKeys
                            clearOnBlur
                            disablePortal
                            selectOnFocus
                        />
                        <FormErrorText errorMessage={rest.helperText} />
                    </FormControl>
                )}
            />
        );
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({field}) => (
                <MuiAutocomplete
                    key={field?.value ?? 'autocomplete'}
                    {...field}
                    {...rest}
                    disabled={isDisabled}
                    error={hasError}
                    onChange={(_, value) => {
                        field.onChange(value);
                    }}
                    getOptionLabel={option => option.name}
                    options={users}
                    renderInput={params => {
                        return (
                            <TextField
                                error={hasError}
                                helperText={rest.helperText}
                                {...params}
                                variant="filled"
                                label={label}
                                fullWidth
                            />
                        );
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id} value={option.id}>
                                <div>
                                    {option.name}
                                </div>
                            </li>
                        );
                    }}
                    handleHomeEndKeys
                    clearOnBlur
                    disablePortal
                    selectOnFocus
                />
            )}
        />
    );
};

UserSelect.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isV3: PropTypes.string, // TODO: make true by default and delete the code, related to false value
    isDisabled: PropTypes.bool,
    hasError: PropTypes.bool,
};

UserSelect.defaultProps = {
    isV3: false,
    isDisabled: false,
    hasError: false,
};
