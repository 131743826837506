import * as React from 'react';
import {useNow, useUtils} from '../internals/hooks/useUtils';
import {useIsDayDisabled} from '../internals/hooks/validation/useDateValidation';

export const createCalendarStateReducer = (
    reduceAnimations,
    disableSwitchToMonthOnDayFocus,
    utils,
) => (state, action) => {
    switch (action.type) {
        case 'changeMonth':
            return {
                ...state,
                slideDirection: action.direction,
                currentMonth: action.newMonth,
                isMonthSwitchingAnimating: !reduceAnimations,
            };

        case 'finishMonthSwitchingAnimation':
            return {
                ...state,
                isMonthSwitchingAnimating: false,
            };

        case 'changeFocusedDay': {
            if (
                state.focusedDay != null
        && action.focusedDay != null
        && utils.isSameDay(action.focusedDay, state.focusedDay)
            ) {
                return state;
            }

            const needMonthSwitch = action.focusedDay != null
        && !disableSwitchToMonthOnDayFocus
        && !utils.isSameMonth(state.currentMonth, action.focusedDay);

            return {
                ...state,
                focusedDay: action.focusedDay,
                isMonthSwitchingAnimating:
          needMonthSwitch
          && !reduceAnimations
          && !action.withoutMonthSwitchingAnimation,
                currentMonth: needMonthSwitch
                    ? utils.startOfMonth(action.focusedDay)
                    : state.currentMonth,
                slideDirection:
          action.focusedDay != null
          && utils.isAfterDay(action.focusedDay, state.currentMonth)
              ? 'left'
              : 'right',
            };
        }

        default:
            throw new Error('missing support');
    }
};

export const useCalendarState = ({
    date,
    defaultCalendarMonth,
    disableFuture,
    disablePast,
    disableSwitchToMonthOnDayFocus = false,
    maxDate,
    minDate,
    onMonthChange,
    reduceAnimations,
    shouldDisableDate,
}) => {
    const now = useNow();
    const utils = useUtils();

    const reducerFn = React.useRef(
        createCalendarStateReducer(
            Boolean(reduceAnimations),
            disableSwitchToMonthOnDayFocus,
            utils,
        ),
    ).current;

    const [calendarState, dispatch] = React.useReducer(reducerFn, {
        isMonthSwitchingAnimating: false,
        focusedDay: date || now,
        currentMonth: utils.startOfMonth(date ?? defaultCalendarMonth ?? now),
        slideDirection: 'left',
    });

    const handleChangeMonth = React.useCallback(
        payload => {
            dispatch({
                type: 'changeMonth',
                ...payload,
            });

            if (onMonthChange) {
                onMonthChange(payload.newMonth);
            }
        },
        [onMonthChange],
    );

    const changeMonth = React.useCallback(
        newDate => {
            const newDateRequested = newDate ?? now;
            if (utils.isSameMonth(newDateRequested, calendarState.currentMonth)) {
                return;
            }

            handleChangeMonth({
                newMonth: utils.startOfMonth(newDateRequested),
                direction: utils.isAfterDay(
                    newDateRequested,
                    calendarState.currentMonth,
                )
                    ? 'left'
                    : 'right',
            });
        },
        [calendarState.currentMonth, handleChangeMonth, now, utils],
    );

    const isDateDisabled = useIsDayDisabled({
        shouldDisableDate,
        minDate,
        maxDate,
        disableFuture,
        disablePast,
    });

    const onMonthSwitchingAnimationEnd = React.useCallback(() => {
        dispatch({type: 'finishMonthSwitchingAnimation'});
    }, []);

    const changeFocusedDay = React.useCallback(
        (newFocusedDate, withoutMonthSwitchingAnimation) => {
            if (!isDateDisabled(newFocusedDate)) {
                dispatch({
                    type: 'changeFocusedDay',
                    focusedDay: newFocusedDate,
                    withoutMonthSwitchingAnimation,
                });
            }
        },
        [isDateDisabled],
    );

    return {
        calendarState,
        changeMonth,
        changeFocusedDay,
        isDateDisabled,
        onMonthSwitchingAnimationEnd,
        handleChangeMonth,
    };
};
