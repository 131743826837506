import {Box, Slide, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import CapitalDepositWaitingApprovalSvg from '../../../../../../../assets/svg/bank/capital-deposit-waiting-approval.svg';
import BankInformationLayout from '../../../../../../../layout/BankInformationLayout/BankInformationLayout';
import {AnimationActions} from '../../../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../../../animations/utils/constants';

const CapitalDepositWaitingApproval = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation('freelancerOnboarding');

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    return (
        <BankInformationLayout
            header={t('capitalDepositWaitingApproval.headline')}
            subTitle={t('capitalDepositWaitingApproval.subtitle')}
        >
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '600px',
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    >
                        <Box>
                            <img src={CapitalDepositWaitingApprovalSvg} alt="capital deposit waiting approval" />
                        </Box>

                        <Typography sx={{mt: 2}} variant="h4">
                            {t('capitalDepositWaitingApproval.title')}
                        </Typography>

                        <Typography sx={{mt: 2, color: 'text_v2.secondary'}}>
                            {t('capitalDepositWaitingApproval.text1')}
                        </Typography>

                        <Typography sx={{mt: 1, color: 'text_v2.secondary'}}>
                            {t('capitalDepositWaitingApproval.text2')}
                        </Typography>
                    </Box>
                </Box>
            </Slide>
        </BankInformationLayout>
    );
};

export default CapitalDepositWaitingApproval;
