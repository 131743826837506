import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentSharpIcon from '@mui/icons-material/AttachmentSharp';
import ErrorIcon from '@mui/icons-material/Error';
import {Button, Chip, CircularProgress, Grid, Typography} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {default as ReactDropZone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';

import {useDropZoneStyles} from './styles';
import {Toast} from '../../../../lib/toast';

export const MaxFileSize = {
    IDDocumentsMaxFileSize: 6291456, // 6MB // (6 * 1024 * 1024)
    IDDocumentsMaxFileSizeMb: 6,
};

const DropZone = props => {
    const {t} = useTranslation('common');
    const styles = useDropZoneStyles();

    const handleDropAccepted = useCallback((acceptedFiles, event) => {
        if (!props.isUploadInProgress && props.onDropAccepted && typeof props.onDropAccepted === 'function') {
            props.onDropAccepted(acceptedFiles, event);
        }
    }, [props]);

    const handleDropRejected = useCallback((rejectedFiles, event) => {
        if (props.onDropRejected && typeof props.onDropRejected === 'function') {
            props.onDropRejected(rejectedFiles, event);
        }

        if (rejectedFiles.length) {
            // TODO Use constants from src/utils/constants.js
            const hasTooManyFiles = rejectedFiles[0]?.errors[0]?.code === 'too-many-files';
            const fileTooLarge = rejectedFiles[0]?.errors[0]?.code === 'file-too-large';

            if (hasTooManyFiles) {
                Toast.error('tooManyFiles');

                return;
            }

            if (fileTooLarge) {
                Toast.error('fileTooLargeCustom', {
                    translationValues: {
                        limit: MaxFileSize.IDDocumentsMaxFileSizeMb,
                    },
                });

                return;
            }
        }

        Toast.error('fileRejected');
    }, [props]);

    const handleDeleteFile = useCallback(fileName => event => {
        event.stopPropagation();

        if (props.isFileRemovalDisabled) {
            Toast.info('fileRemovalDisabled');
            return;
        }

        if (props.onDeleteFile && typeof props.onDeleteFile === 'function') {
            props.onDeleteFile(fileName, event);
        }
    }, [props]);

    const additionalProps = {};

    if (props.isMultiple && props.fileLimit > 0) {
        additionalProps.maxFiles = props.fileLimit;
    }

    if (props.maxSize) {
        additionalProps.maxSize = props.maxSize;
    }

    return (
        <>
            <ReactDropZone
                accept={props.acceptFiles}
                onDropAccepted={handleDropAccepted}
                onDropRejected={handleDropRejected}
                multiple={props.isMultiple}
                disabled={props.isDisabled || props.isUploadInProgress}
                {...additionalProps}
                validator={value => {
                    const length = value.length ?? 1;

                    return value && additionalProps.maxFiles
                    && (props.files.length + length) > additionalProps.maxFiles
                        ? {code: 'too-many-files', message: 'Max number of files exceeded'} : null;
                }}
            >
                {({getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept}) => {
                    return (
                        <div
                            {...getRootProps({
                                className: clsx(
                                    styles.container,
                                    isDragActive && styles.containerActive,
                                    isDragReject && styles.containerReject,
                                    isDragAccept && styles.containerAccept,
                                    !!props.errorMessage && styles.errorMessage,
                                    props.className,
                                    props.isDisabled && styles.containerDisabled,
                                ),
                            })}
                            data-cypress={props.name}
                        >
                            {props.isUploadInProgress && (
                                <div className={styles.contentContainer}><CircularProgress size={32} /></div>
                            )}
                            {!props.isUploadInProgress && (
                                <>
                                    <input {...getInputProps()} />
                                    <div className={styles.contentContainer}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            startIcon={<AttachFileIcon />}
                                            color="primary"
                                            disabled={props.isDisabled}
                                            sx={{
                                                px: 1.5,
                                                py: 0.5,
                                                borderRadius: 1.5,
                                                mb: 1,
                                            }}
                                        >
                                            {props.buttonText || t('dropZone.attachFile')}
                                        </Button>
                                        {props.dropZoneCustomText ?? (
                                            <>
                                                {props.dropZoneText !== '' && (
                                                <Typography className={styles.dropZoneText}>
                                                    {props.dropZoneText || t('dropZone.dragAndDrop')}
                                                </Typography>
                                                )}
                                                {(
                                                    (!props.isMultiple
                                                        || (props.fileLimit !== 1 && props.fileLimit !== 0)
                                                    )
                                                    || props.hasMaxFileInfoForced
                                                ) && (
                                                <Typography className={styles.maxFilesText}>
                                                    {props.fileLimit !== 1
                                                        ? t('dropZone.limitedNumberOfFiles', {numberOfFiles: props.fileLimit})
                                                        : t('dropZone.maxFiles')}
                                                    {props.readableFileExtension ? ', ' + props.readableFileExtension : ''}
                                                </Typography>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    );
                }}
            </ReactDropZone>

            {props.hasChips && !props.isUploadInProgress && (
                <Grid spacing={1} direction="row" container>
                    {!!props.files && props.files.map((fileObject, i) => {
                        return (
                            <Grid key={`${fileObject.name ?? 'file'}-${i}`} item>
                                <Chip
                                    className={styles.chip}
                                    label={fileObject.name}
                                    onDelete={props.isFileRemovalDisabled ? null : handleDeleteFile(fileObject.name)}
                                    avatar={<AttachmentSharpIcon className={styles.chipAvatar} />}
                                    disabled={props.isDisabled}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}

            {!!props.errorMessage && (
            <Typography color="error" className={styles.errorContainer}>
                <ErrorIcon className={styles.errorIcon} />

                {props.errorMessage}
            </Typography>
            )}
        </>
    );
};

DropZone.propTypes = {
    name: PropTypes.string.isRequired,
    onDropRejected: PropTypes.func,
    onDropAccepted: PropTypes.func,
    onDeleteFile: PropTypes.func,
    acceptFiles: PropTypes.string,
    buttonText: PropTypes.string,
    dropZoneText: PropTypes.string,
    errorMessage: PropTypes.string,
    readableFileExtension: PropTypes.string,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    hasChips: PropTypes.bool,
    isMultiple: PropTypes.bool,
    isFileRemovalDisabled: PropTypes.bool,
    fileLimit: PropTypes.number,
    files: PropTypes.arrayOf(PropTypes.instanceOf(File)),
    hasMaxFileInfoForced: PropTypes.bool,
    dropZoneCustomText: PropTypes.object,
    isUploadInProgress: PropTypes.bool,
    maxSize: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

DropZone.defaultProps = {
    onDropRejected: null,
    onDropAccepted: null,
    onDeleteFile: null,
    acceptFiles: null,
    buttonText: null,
    dropZoneText: null,
    errorMessage: null,
    className: '',
    readableFileExtension: '',
    isDisabled: false,
    hasChips: true,
    isMultiple: false,
    isFileRemovalDisabled: false,
    fileLimit: 1,
    files: [],
    hasMaxFileInfoForced: false,
    dropZoneCustomText: null,
    isUploadInProgress: false,
    maxSize: null,
};

export default DropZone;
