import makeStyles from '@mui/styles/makeStyles';

export const useDataTableHeaderStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3, 2, 0),
    },
    withCheckboxes: {
        padding: theme.spacing(3, 3, 0, 2),
    },
    title: {
        fontSize: theme.typography.pxToRem(21),
        fontWeight: theme.typography.fontWeightBold,
        color: '#0B2333',
    },
    actionContainer: {
        display: 'grid',
        alignItems: 'center',
        alignContent: 'center',
        gridAutoFlow: 'column',
        gridColumnGap: theme.spacing(4),
    },
}));
