import makeStyles from '@mui/styles/makeStyles';

export const useUploadDocumentStyles = makeStyles(theme => ({
    containerResponsive: {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'flexDirection': 'column',
        'maxHeight': 300,
        'borderRadius': 12,
        'border': '1px dashed #E0E0E0',
        'marginTop': theme.spacing(1),
        'marginBottom': theme.spacing(1),
        'backgroundColor': '#fafafa',
        '&:focus': {
            outline: 0,
        },
        [theme.breakpoints.down('lg')]: {
            minHeight: 270,
        },
        [theme.breakpoints.down('md')]: {
            minHeight: 150,
        },
    },
    containerActive: {
        borderColor: theme.palette.secondary.main,
    },
    containerReject: {
        backgroundColor: theme.palette.error.main,
    },
    containerAccept: {
        backgroundColor: theme.palette.success.main,
    },
    dropZone: {
        width: '100%',
        minHeight: 132,
    },
    contentContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
    },
    errorContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    errorIcon: {
        width: 16,
        height: 16,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    errorText: {
        fontSize: theme.typography.pxToRem(14),
        letterSpacing: 0.1,
    },
    dropZoneText: {
        color: 'rgba(0, 0, 0, 0.54)',
        letterSpacing: 0.1,
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(16),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    additionalText: {
        'color': 'rgba(0, 0, 0, 0.54)',
        'letterSpacing': 0.1,
        'fontSize': theme.typography.pxToRem(13),
        'textTransform': 'uppercase',
        'margin': theme.spacing(2, 'auto', 0, 'auto'),
        '&>span': {
            'color': 'rgba(0, 0, 0, 0.87)',
            'margin': theme.spacing(0, 0.5),
        },
    },
    chip: {
        borderRadius: 16,
        backgroundColor: '#f5f5f5',
    },
    chipAvatar: {
        fill: '#000',
    },
    signature: {
        maxWidth: '100%',
        maxHeight: '370px',
        padding: theme.spacing(3),
    },
}));
