export const DocumentActionTypes = {
    GET_DOCUMENT: 'document/GET_DOCUMENT',
    GET_DOCUMENT_FULL_DATA: 'document/GET_DOCUMENT_FULL_DATA',
    GET_QONTO: 'document/GET_QONTO',
    GET_STATUSES: 'document/GET_STATUSES',
    UPLOAD_DOCUMENT: 'document/UPLOAD_DOCUMENT',
    GET_KBIS: 'document/GET_KBIS',
    STORE_DEPOSIT_DOCUMENTS: 'document/STORE_DEPOSIT_DOCUMENTS',
    REGENERATE_SELECTED_BATCH_DOCUMENTS: 'document/REGENERATE_SELECTED_BATCH_DOCUMENTS',
    GET_CLUSTER_DOCUMENT: 'document/GET_CLUSTER_DOCUMENT',
};
