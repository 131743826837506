import {TrainingActionTypes} from './training.action-type';

export const TrainingActions = {
    storeTrainingInfo: data => ({
        type: TrainingActionTypes.STORE_TRAINING_INFO,
        payload: data,
    }),
    getTrainingProgress: () => ({
        type: TrainingActionTypes.GET_TRAINING_PROGRESS,
    }),
    setTrainingProgress: progress => ({
        type: TrainingActionTypes.SET_TRAINING_PROGRESS,
        payload: progress,
    }),
    getTrainingStep: () => ({
        type: TrainingActionTypes.GET_TRAINING_STEP,
    }),
    setTrainingStep: step => ({
        type: TrainingActionTypes.SET_TRAINING_STEP,
        payload: step,
    }),
    submitStep: step => ({
        type: TrainingActionTypes.SUBMIT_STEP,
        payload: step,
    }),
    setSubStep: step => ({
        type: TrainingActionTypes.SET_SUBSTEP,
        payload: step,
    }),
    saveAtlasNumber: data => ({
        type: TrainingActionTypes.SAVE_ATLAS_NUMBER,
        payload: data,
    }),
    saveRejectedForm: data => ({
        type: TrainingActionTypes.SAVE_REJECTED_FORM,
        payload: data,
    }),
    resetDates: () => ({
        type: TrainingActionTypes.RESET_DATES,
    }),
    downloadZip: step => ({
        type: TrainingActionTypes.DOWNLOAD_ZIP,
        payload: step,
    }),
    closeTrainingAlertNotification: () => ({
        type: TrainingActionTypes.CLOSE_TRAINING_ALERT_NOTIFICATION,
    }),
};
