import {Box, Paper, Slide} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PageHeader} from '../../components/page-header/PageHeader';
import {AnimationActions} from '../../features/animations/store/animations.action';
import {AnimationsSelectors} from '../../features/animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../features/animations/utils/constants';

const BankInformationLayout = ({image, header, subTitle, Button, containerMaxWidth, children}) => {
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const hasButton = !!Button;

    const buttonHeight = hasButton ? 0 : 68;

    if (!isMobileSize) {
        return (
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <Box>
                    <Box display="grid" gridTemplateRows="min-content 1fr">
                        <PageHeader subTitle={subTitle} isMobile={false}>
                            {header}
                        </PageHeader>
                    </Box>

                    <Paper
                        elevation={1}
                        sx={{borderRadius: '8px', p: 5}}
                    >
                        <Box sx={{
                            background: '#FAFAFA',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                maxWidth: containerMaxWidth,
                                height: `calc(100vh - ${300 - buttonHeight}px)`,
                            }}
                            >
                                <Box sx={{mb: 2}}>{image}</Box>
                                <Box>{children}</Box>
                            </Box>
                        </Box>
                    </Paper>

                    {hasButton && (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            mt: 3,
                        }}
                        >
                            {Button}
                        </Box>
                    )}
                </Box>
            </Slide>
        );
    }

    return (
        <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
            <Box>
                <Box display="grid" gridTemplateRows="min-content 1fr">
                    <PageHeader subTitle={subTitle} isMobile>
                        {header}
                    </PageHeader>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    maxWidth: '100%',
                    pt: 1,
                    px: 4,
                }}
                >
                    <Box sx={{mb: 2}}>{image}</Box>
                    <Box>{children}</Box>
                </Box>

                {hasButton && (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 3,
                    }}
                    >
                        {Button}
                    </Box>
                )}
            </Box>
        </Slide>
    );
};

BankInformationLayout.propTypes = {
    image: PropTypes.object,
    header: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    Button: PropTypes.object,
    containerMaxWidth: PropTypes.string,
};

BankInformationLayout.defaultProps = {
    image: null,
    containerMaxWidth: '600px',
    Button: null,
};

export default BankInformationLayout;
