import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import {LoadingButton} from '@mui/lab';
import {Alert, Box, Typography, useMediaQuery} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../../../../../../../../components/modal-wrapper/ModalWrapper';
import {ModalsKeys} from '../../../../../../../ui/utils/constants';

const modalKey = ModalsKeys.CATEGORISATION_INVALID_ARTICLES;
const QuitWithInvalidArticlesModal = ({onCloseArticles}) => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h5">
                    {t('categorisation.quitArticlesModal.title')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        disableElevation
                        fullWidth={isMobileSize}
                        startIcon={<ArrowBackIcon />}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        {t('categorisation.quitArticlesModal.cancel')}
                    </Button>

                    <LoadingButton
                        color="secondary"
                        fullWidth={isMobileSize}
                        endIcon={<CancelIcon />}
                        onClick={() => {
                            handleClose();
                            onCloseArticles();
                        }}
                        variant="contained"
                    >
                        {t('categorisation.quitArticlesModal.confirm')}
                    </LoadingButton>
                </>
            )}
        >
            <Box>
                <Alert
                    severity="info"
                    icon={(
                        <WarningIcon
                            sx={{
                                color: 'v2.light.info.shades160',
                            }}
                        />
)}
                >
                    <Typography sx={{
                        fontSize: theme => theme.typography.pxToRem(14),
                    }}
                    >
                        {t('categorisation.quitArticlesModal.alert_paragraph1')}
                    </Typography>
                    <Typography sx={{
                        fontSize: theme => theme.typography.pxToRem(14),
                        mt: 2,
                    }}
                    >
                        {t('categorisation.quitArticlesModal.alert_paragraph2')}
                    </Typography>
                </Alert>
            </Box>
        </ModalWrapper>
    );
};

QuitWithInvalidArticlesModal.propTypes = {
    onCloseArticles: PropTypes.func.isRequired,
};

export default QuitWithInvalidArticlesModal;
