import {hiwayApiInstance} from '../../../lib/hiway-api-instance';

export const fromTrainingDTO = dto => ({
    status: dto.status, // TODO Remove mock
    currentStep: dto.current_step,
    isEligible: dto.is_eligible,
    startDate: dto.start_date,
    endDate: dto.end_date,
    completedDate: dto.completed_date,
    atlasFileNumber: dto.atlas_file_number,
    isAtlasValidationEmailSent: dto.is_atlas_validation_rejected_email_sent,
});

const getTrainingInfo = ({freelancerId, companyId}) => {
    return hiwayApiInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/training-info`,
    }).then(result => {
        return fromTrainingDTO(result?.data);
    });
};

const updateStatus = ({freelancerId, companyId, status}) => {
    return hiwayApiInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/training-info/update-status`,
        data: {status},
    }).then(result => {
        return fromTrainingDTO(result?.data);
    });
};

const updateAtlasNumber = ({freelancerId, companyId, atlasFileNumber}) => {
    return hiwayApiInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/training-info`,
        data: {atlas_file_number: atlasFileNumber},
    }).then(result => {
        return result.data;
    });
};

const saveRejectForm = ({freelancerId, companyId, file, message}) => {
    const formData = new FormData();

    formData.append('file', file[0]);

    if (message) {
        formData.append('message', message);
    }

    return hiwayApiInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/training-info/validation-rejected`,
        data: formData,
    }).then(result => {
        return result.data;
    });
};

const closeTrainingAlertNotification = ({freelancerId, companyId}) => {
    return hiwayApiInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/training-info/hide-training`,
    }).then(result => {
        return result.data;
    });
};

export const TrainingApi = {
    getTrainingInfo,
    updateStatus,
    updateAtlasNumber,
    saveRejectForm,
    closeTrainingAlertNotification,
};
