import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TimerIcon from '@mui/icons-material/Timer';
import {Box, Drawer, Grid, Grow, Slide, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonAction} from '../../components/buttons/ButtonAction';
import {AnimationActions} from '../../features/animations/store/animations.action';
import {AnimationsSelectors} from '../../features/animations/store/animations.selector';
import {ANIMATION_TYPE, BANNER_ANIMATION_DELAY} from '../../features/animations/utils/constants';

const styles = {
    card: {
        width: '100%',
        minHeight: '300px',
        borderRadius: '16px',
        background: 'linear-gradient(270deg, #E3F2FD 0%, #FFFFFF 55.73%)',
        boxShadow: '0 1px 1px 0 rgb(11 35 51 / 5%), 0 1px 1px -1px rgb(11 35 51 / 5%), 0 1px 3px 0 rgb(11 35 51 / 17%)',
    },
    leftContainer: {
        pl: 5,
        pt: 5,
    },
    header: {
        fontSize: '48px',
        lineHeight: '120%',
        letterSpacing: '-0.5px',
        fontFamily: 'Red Hat Text',
        fontWeight: 700,
        width: '90%',
    },
    subtext: {
        mt: 2,
        color: 'rgba(0, 0, 0, 0.38)',
    },
    estimate: {
        ml: 5,
        mb: 3,
        fontWeight: 500,
    },
    iconConatiner: {
        marginTop: '-36px',
        mr: 6,
    },
    icon: {
        width: '171px',
        height: '217px',
    },
    button: {
        pl: 3,
        pr: 3,
    },
};

const mobileStyles = {
    mainContainer: {
        minHeight: '300px',
        display: 'flex',
    },
    card: {
        width: '100%',
        minHeight: 'calc(100vh - 90px)',
        background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 41%, rgba(234,242,255,1) 82%)',
        p: 3,
    },
    header: {
        fontSize: '34px',
        lineHeight: '120%',
        letterSpacing: '-0.2px',
        fontFamily: 'Red Hat Text',
        fontWeight: 700,
        width: '90%',
    },
    subtext: {
        fontWeight: 500,
        fontSize: '15px',
        color: 'rgba(0, 0, 0, 0.38)',
        mt: 2,
    },
    estimate: {
        mt: 1,
        mb: 3,
        fontWeight: 500,
    },
    buttonContainer: {
        pt: 2,
        pl: 2,
        pr: 2,
    },
    buttonText: {
        fontSize: '16px',
        fontWeight: 400,
        letterSpacing: '0.15px',
    },
};

const Banner = ({
    header,
    text,
    estimate,
    Illustration,
    nextText,
    nextButtonText,
    onNextClick,
    content,
    cardSx,
    textSize,
    NextButtonProps,
    isButtonDisabled,
}) => {
    const dispatch = useDispatch();

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
    };

    if (!isMobileSize) {
        return (
            <>
                <Grid sx={{...styles.card, ...cardSx}} container flexDirection="row" justifyContent="space-between" flexWrap="nowrap">
                    <Grid container item>
                        <Grid container flexDirection="column" justifyContent="space-between">
                            <Grid container item sx={styles.leftContainer} flexDirection="column">
                                <Grow
                                    in={isAnimationActive}
                                    timeout={0}
                                >
                                    <Grid item sx={styles.header}>
                                        {header}
                                    </Grid>
                                </Grow>
                                <Grid item sx={styles.subtext}>
                                    <Grow
                                        in={isAnimationActive}
                                        timeout={BANNER_ANIMATION_DELAY}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: theme => theme.typography.pxToRem(textSize),
                                                letterSpacing: '0.25px',
                                            }}
                                        >
                                            {text}
                                        </Typography>
                                    </Grow>
                                </Grid>
                                {content && <Grid item>{content}</Grid>}
                            </Grid>
                            {estimate && (
                                <Grid container item alignItems="center" sx={styles.estimate}>
                                    <TimerIcon sx={{color: 'rgba(225, 163, 72, 1)'}} />
                                    <Box sx={{color: 'rgba(0, 0, 0, 0.38)', fontSize: '12px', ml: 1}}>{estimate}</Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    <Grow
                        in={isAnimationActive}
                        timeout={BANNER_ANIMATION_DELAY * 2}
                        onExited={onEndedAnimation}
                    >
                        <Grid item sx={styles.iconConatiner}>
                            {Illustration}
                        </Grid>
                    </Grow>
                </Grid>

                {!!onNextClick && (
                    <Slide direction="left" in={isAnimationActive} timeout={BANNER_ANIMATION_DELAY * 3}>
                        <Grid container item justifyContent="flex-end" sx={{mt: 3}} alignItems="center">
                            <Box component="span" sx={{color: 'rgba(11, 35, 51, 1)', mr: 4}}>{nextText}</Box>
                            <ButtonAction
                                endIcon={<ArrowForwardIcon />}
                                sx={styles.button}
                                onClick={onNextClick}
                                color="secondary"
                                {...NextButtonProps}
                                disabled={isButtonDisabled}
                            >
                                {nextButtonText}
                            </ButtonAction>
                        </Grid>
                    </Slide>
                )}
            </>
        );
    }

    return (
        <Grid container flexDirection="column">
            <Grid item sx={mobileStyles.mainContainer}>
                <Grid sx={{...mobileStyles.card, ...cardSx}} container flexDirection="column" flexWrap="nowrap">
                    <Grid item sx={mobileStyles.header}>
                        {header}
                    </Grid>
                    <Grid item sx={mobileStyles.subtext}>{text}</Grid>
                    {content && <Grid item>{content}</Grid>}
                    {estimate && (
                        <Grid container item alignItems="center" sx={mobileStyles.estimate}>
                            <TimerIcon sx={{color: 'rgba(225, 163, 72, 1)'}} />
                            <Box sx={{color: 'rgba(0, 0, 0, 0.38)', fontSize: '12px', ml: 1}}>{estimate}</Box>
                        </Grid>
                    )}
                    <Grid item container sx={mobileStyles.iconConatiner} justifyContent="center">
                        {Illustration}
                    </Grid>
                </Grid>
            </Grid>

            <Drawer
                variant="permanent"
                anchor="bottom"
                PaperProps={{elevation: 12, style: {backgroundColor: 'transparent', boxShadow: 'none', borderTop: 0}}}
            >
                <Paper sx={{
                    borderRadius: '16px 16px 0px 0px',
                    boxShadow: '0px 7px 8px -4px rgba(11, 35, 51, 0.08), 0px 12px 17px 2px rgba(11, 35, 51, 0.08), 0px 5px 22px 4px rgba(11, 35, 51, 0.1)',
                }}
                >
                    {!!onNextClick && (
                        <Grid container sx={mobileStyles.buttonContainer} justifyContent="center">
                            <Box sx={mobileStyles.buttonText}>{nextText}</Box>
                            <ButtonAction
                                endIcon={<ArrowForwardIcon />}
                                sx={{
                                    width: '100%',
                                    mb: 2,
                                }}
                                onClick={onNextClick}
                                color="secondary"
                                disabled={isButtonDisabled}
                            >
                                {nextButtonText}
                            </ButtonAction>
                        </Grid>
                    )}
                </Paper>
            </Drawer>
        </Grid>
    );
};

Banner.propTypes = {
    header: PropTypes.object.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    estimate: PropTypes.string,
    Illustration: PropTypes.object,
    nextText: PropTypes.string,
    nextButtonText: PropTypes.string,
    onNextClick: PropTypes.func,
    content: PropTypes.func,
    cardSx: PropTypes.object,
    NextButtonProps: PropTypes.object,
    textSize: PropTypes.number,
    isButtonDisabled: PropTypes.bool,
};

Banner.defaultProps = {
    estimate: '',
    Illustration: null,
    nextText: '',
    content: null,
    cardSx: {},
    nextButtonText: '',
    onNextClick: null,
    NextButtonProps: {},
    textSize: 16,
    isButtonDisabled: false,
};

export default Banner;

