import {SvgIcon} from '@mui/material';
import React from 'react';

const ImageIcon = props => {
    return (
        <SvgIcon {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path d="M12 21V4h11a1 1 0 011 1v15a1 1 0 01-1 1H12z" opacity=".154" fill="#9452F0" fillRule="nonzero" />
                <path d="M12.5.356a1.5 1.5 0 011.5 1.5v20.289a1.5 1.5 0 01-1.814 1.466L.79 21.17a1 1 0 01-.79-.977V3.809a1 1 0 01.79-.978L12.186.389A1.5 1.5 0 0112.5.356zM16.5 17a.5.5 0 110 1h-1a.5.5 0 110-1h1zm5 0a.5.5 0 110 1h-3a.5.5 0 110-1h3zm-2-2a.5.5 0 110 1h-4a.5.5 0 110-1h4zm2 0a.5.5 0 110 1 .5.5 0 010-1zm-6-2a.5.5 0 110 1 .5.5 0 010-1zm6 0a.5.5 0 110 1h-4a.5.5 0 110-1h4zm-3-2a.5.5 0 110 1h-3a.5.5 0 110-1h3zm3 0a.5.5 0 110 1h-1a.5.5 0 110-1h1zm0-2a.5.5 0 110 1h-6a.5.5 0 110-1h6zm-3-2a.5.5 0 110 1h-3a.5.5 0 110-1h3z" fill="#9452F0" />
                <path d="M0 18.068l2.833-4.155a.8.8 0 011.272-.067l.064.088 1.081 1.7 4.583-6.721a.8.8 0 011.272-.067l.064.088L14 13.383v2.235l-3.354-5.272a.2.2 0 00-.334-.005l-4.36 6.394 4.083 6.415-1.648-.353-4.741-7.45a.2.2 0 00-.334-.006l-3.312 4.856v-2.129zM4.5 6a1.5 1.5 0 110 3 1.5 1.5 0 010-3z" fill="#FFF" fillRule="nonzero" />
            </g>
        </SvgIcon>
    );
};

export default ImageIcon;
