import {Box, GlobalStyles, Skeleton, useMediaQuery} from '@mui/material';
import '@react-pdf-viewer/core/lib/styles/index.css';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import {useSelector} from 'react-redux';
import {Scrollbar} from 'smooth-scrollbar-react';
import {OnboardingSelectors} from '../../../../freelancer/modules/onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../../../freelancer/modules/onboarding/utils/onboadingStepsConstant';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {SigningSelectors} from '../store/signing.selector';
import {DOCUMENT_SIGNING_TYPES} from '../utils/constants';

export const PdfViewer = ({scrollRef, onLoadSuccess, type}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const [pageNumbers, setPageNumbers] = useState(null);
    const [outerWidth, setOuterWidth] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const containerRef = useRef();
    const internalScrollRef = useRef();

    const url = useSelector(SigningSelectors.selectCurrentDocumentUrl);
    const isSigningDocument = useSelector(SigningSelectors.selectIsSigningCurrentDocument);
    const isSigningContract = useLoadingState(LoadingTypes.SIGN_CONTRACT);
    const step = useSelector(OnboardingSelectors.selectStep);

    const handleDocumentLoadSuccess = ({numPages}) => {
        const allPageNumbers = [];

        for (let p = 1; p < numPages + 1; p += 1) {
            allPageNumbers.push(p);
        }

        setPageNumbers(allPageNumbers);
        setOuterWidth(containerRef.current.offsetWidth);
        setIsLoading(false);

        if (onLoadSuccess && typeof onLoadSuccess === 'function') {
            onLoadSuccess();
        }
    };

    useEffect(() => {
        setIsLoading(true);

        if (internalScrollRef.current) {
            internalScrollRef.current.scrollTo(0, 0);
            if (scrollRef) {
                scrollRef.current = internalScrollRef.current;
            }
        }
    }, [scrollRef, url]);

    return (
        <>
            <GlobalStyles
                styles={{
                    '.scrollbar-track': {
                        backgroundColor: 'transparent !important',
                        width: '7px !important',
                    },
                    '.scrollbar-track-y': {
                        right: '1px !important',
                    },
                    '.scrollbar-thumb': {
                        backgroundColor: '#7F7F7F !important',
                        borderRadius: '4px !important',
                        width: '7px !important',
                    },
                    '.react-pdf__Document': {
                        backgroundColor: '#fff',
                    },
                }}
            />

            <div ref={containerRef}>
                {(isLoading || isSigningContract || isSigningDocument) && (
                    <Box
                        sx={{
                            height: type === DOCUMENT_SIGNING_TYPES.GLOBAL || type === DOCUMENT_SIGNING_TYPES.TRAINING
                                ? 'calc(100vh - 160px)'
                                : `calc(100vh - ${step === OnboardingSteps.CONTRACT_SIGNING ? '120px' : '180px'})`,
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: '#fafafa',
                            boxShadow: '0px 5px 5px -3px rgba(11, 35, 51, 0.08), 0px 8px 10px 1px rgba(11, 35, 51, 0.08), 0px 3px 14px 2px rgba(11, 35, 51, 0.1)',
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                            padding: 5,
                            overflow: 'hidden',
                            marginBottom: isMobileSize ? 0 : '-24px',
                        }}
                    >
                        <Skeleton width="10vw" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', mb: '10%'}} />
                        <Skeleton width="20vw" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                        <Skeleton width="13vw" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '9%'}} />
                        <Skeleton width="100%" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                        <Skeleton width="100%" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                        <Skeleton width="100%" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                        <Skeleton width="23vw" height={12} sx={{transform: 'scale(1, 1)', mb: '8%'}} />
                        <Skeleton width="28vw" height={12} sx={{transform: 'scale(1, 1)', mb: '2.2%'}} />
                        <Skeleton width="39vw" height={12} sx={{transform: 'scale(1, 1)', mb: '2.2%'}} />
                        <Skeleton width="33vw" height={12} sx={{transform: 'scale(1, 1)', mb: '2.2%'}} />
                        <Skeleton width="46vw" height={12} sx={{transform: 'scale(1, 1)', mb: '8%'}} />
                        <Skeleton width="100%" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                        <Skeleton width="100%" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                        <Skeleton width="100%" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                        <Skeleton width="23vw" height={12} sx={{transform: 'scale(1, 1)'}} />
                    </Box>
                )}

                <Box sx={{display: isLoading || isSigningContract || isSigningDocument ? 'none' : 'block'}}>
                    <Scrollbar
                        damping={0.2}
                        alwaysShowTracks
                        ref={internalScrollRef}
                    >
                        <Box
                            sx={{
                                boxShadow: '0px 5px 5px -3px rgba(11, 35, 51, 0.08), 0px 8px 10px 1px rgba(11, 35, 51, 0.08), 0px 3px 14px 2px rgba(11, 35, 51, 0.1)',
                                borderTopLeftRadius: type === DOCUMENT_SIGNING_TYPES.ONBOARDING ? '4px' : '8px',
                                borderTopRightRadius: type === DOCUMENT_SIGNING_TYPES.ONBOARDING ? '4px' : '8px',
                                pt: type === DOCUMENT_SIGNING_TYPES.ONBOARDING ? 0 : 1,
                                background: 'white',
                                marginBottom: isMobileSize ? 0 : '-24px',
                                height: type === DOCUMENT_SIGNING_TYPES.GLOBAL
                                    || type === DOCUMENT_SIGNING_TYPES.TRAINING
                                    ? 'calc(100vh - 160px)'
                                    : type === DOCUMENT_SIGNING_TYPES.ONBOARDING
                                        ? `calc(100vh - ${step === OnboardingSteps.CONTRACT_SIGNING ? '120px' : '180px'})`
                                        : 'calc(100vh - 180px)',
                            }}
                        >
                            <Document
                                file={url}
                                onLoadStart
                                onLoadSuccess={handleDocumentLoadSuccess}
                                loading={() => null}
                            >
                                {pageNumbers && pageNumbers.map(pageNumber => (
                                    <Page
                                        key={pageNumber}
                                        width={outerWidth ? outerWidth - 17 : containerRef.current.offsetWidth}
                                        pageNumber={pageNumber}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={true}
                                        loading={() => null}
                                    />
                                ))}
                            </Document>
                        </Box>
                    </Scrollbar>
                </Box>
            </div>
        </>
    );
};

PdfViewer.propTypes = {
    scrollRef: PropTypes.object,
    onLoadSuccess: PropTypes.func,
    type: PropTypes.string,
};

PdfViewer.defaultProps = {
    scrollRef: undefined,
    onLoadSuccess: null,
    type: DOCUMENT_SIGNING_TYPES.ONBOARDING,
};
