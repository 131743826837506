import {combineReducers} from 'redux';
import {BoTrainingActionTypes} from './bo-training.action-type';
import {createReducer} from '../../../utils/create-reducer';
import {BO_TRAINING_INFO_DEFAULTS} from '../utils/constants';

export const boTrainingReducer = combineReducers({
    trainingInfo: createReducer(
        BO_TRAINING_INFO_DEFAULTS,
        BoTrainingActionTypes.STORE_TRAINING_INFO,
    ),
});
