import PropTypes from 'prop-types';
import {RevenueSimulatorResults} from '../../features/revenue-simulator/modules/results/RevenueSimulatorResults';

const RevenueSimulatorResultsScreen = ({isPublic}) => {
    return <RevenueSimulatorResults isPublic={isPublic} />;
};

RevenueSimulatorResultsScreen.propTypes = {
    isPublic: PropTypes.bool,
};

RevenueSimulatorResultsScreen.defaultProps = {
    isPublic: false,
};

export default RevenueSimulatorResultsScreen;
