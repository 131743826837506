import {Box} from '@mui/material';
import PropTypes from 'prop-types';

export const FormSectionRow = ({children, isHidden, chip}) => {
    return (
        <Box sx={{
            display: isHidden ? 'none' : 'box',
            mt: 2,
        }}
        >
            {chip && (
                <Box sx={{
                    display: 'inline-block',
                    mb: 1,
                    fontWeight: 500,
                    borderRadius: '4px',
                    background: '#f5fafd',
                    color: 'v2.light.info.main',
                    fontSize: theme => theme.typography.pxToRem(10),
                    lineHeight: 1.4,
                    px: 1,
                }}
                >
                    {chip}
                </Box>
            )}
            <Box sx={{display: 'flex', gap: 2}}>
                {children}
            </Box>
        </Box>
    );
};

FormSectionRow.propTypes = {
    isHidden: PropTypes.bool,
    chip: PropTypes.any,
};

FormSectionRow.defaultProps = {
    isHidden: false,
    chip: undefined,
};
