import {LoggedInUserActionTypes} from './logged-in-user.action-type';
import {LOCATION_CHANGED} from '../../../../../lib/router/connected-router-saga';
import {createReducer} from '../../../../../utils/create-reducer';

export const loggedInUserReducer = createReducer(null, LoggedInUserActionTypes.STORE_ACCOUNT);
export const loggedInUserDashboardReducer = createReducer(null, LoggedInUserActionTypes.STORE_ACCOUNT_FOR_DASHBOARD);

export const isUserUpdatingReducer = (state = false, action) => {
    if (action.type === LoggedInUserActionTypes.SET_IS_UPDATING_USER) {
        return action.payload;
    }

    // Reset to initial state when user leaves the view.
    if (action.type === LOCATION_CHANGED) {
        return false;
    }

    return state;
};
