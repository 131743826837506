import {hiwayApiInstance} from '../../../lib/hiway-api-instance';

const sendEvent = (eventType, eventPayload) => {
    return hiwayApiInstance
        .post('/v1/events', {
            event_type: eventType,
            payload: eventPayload,
        });
};

export const AppEventApi = {
    sendEvent,
};
