import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {Box, Paper, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {importLocaleBundle} from '../../../../lib/i18next';

importLocaleBundle('opportunity');

export const OpportunityApply = () => {
    const {t} = useTranslation('opportunity');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={isMobileSize ? {px: 3, py: 2} : {}}>
            <Typography variant="h4" sx={{mb: 0.5}}>
                <Box component="span" sx={{mr: 1}}>
                    {t('apply.header')}
                </Box>
            </Typography>

            <Typography sx={{mb: isMobileSize ? 1 : 2, fontWeight: 500, color: 'v2.light.text.disabled'}}>
                <Box component="span">
                    {t('apply.subHeader')}
                </Box>
            </Typography>

            <Paper sx={isMobileSize ? {px: 2, pt: 5, pb: 7} : {p: 9, borderRadius: 2}} elevation={1}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
                >
                    <Box sx={{mb: {xs: 1, md: 0}}}>
                        <CheckCircleOutlineOutlinedIcon
                            sx={{
                                color: 'rgba(46, 125, 50, 0.5)',
                                fontSize: isMobileSize ? '126px' : '180px',
                            }}
                        />
                    </Box>
                    <Box sx={{mb: 1}}>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: theme => theme.typography.pxToRem(48),
                            }}
                        >
                            {t('apply.main')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: theme => theme.typography.pxToRem(21),
                                color: 'rgba(0, 0, 0, 0.38)',
                                maxWidth: '500px',
                            }}
                        >
                            {t('apply.subText')}
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};
