export const COUNTRIES = [
    {
        code: 'af',
        name: {
            en: 'Afghanistan',
            fr: 'Afghanistan',
        },
    },
    {
        code: 'al',
        name: {
            en: 'Albania',
            fr: 'Albanie',
        },
    },
    {
        code: 'dz',
        name: {
            en: 'Algeria',
            fr: 'Algérie',
        },
    },
    {
        code: 'ad',
        name: {
            en: 'Andorra',
            fr: 'Andorre',
        },
    },
    {
        code: 'ao',
        name: {
            en: 'Angola',
            fr: 'Angola',
        },
    },
    {
        code: 'ag',
        name: {
            en: 'Antigua and Barbuda',
            fr: 'Antigua-et-Barbuda',
        },
    },
    {
        code: 'ar',
        name: {
            en: 'Argentina',
            fr: 'Argentine',
        },
    },
    {
        code: 'am',
        name: {
            en: 'Armenia',
            fr: 'Arménie',
        },
    },
    {
        code: 'au',
        name: {
            en: 'Australia',
            fr: 'Australie',
        },
    },
    {
        code: 'at',
        name: {
            en: 'Austria',
            fr: 'Autriche',
        },
    },
    {
        code: 'az',
        name: {
            en: 'Azerbaijan',
            fr: 'Azerbaïdjan',
        },
    },
    {
        code: 'bs',
        name: {
            en: 'Bahamas',
            fr: 'Bahamas',
        },
    },
    {
        code: 'bh',
        name: {
            en: 'Bahrain',
            fr: 'Bahreïn',
        },
    },
    {
        code: 'bd',
        name: {
            en: 'Bangladesh',
            fr: 'Bangladesh',
        },
    },
    {
        code: 'bb',
        name: {
            en: 'Barbados',
            fr: 'Barbade',
        },
    },
    {
        code: 'by',
        name: {
            en: 'Belarus',
            fr: 'Biélorussie',
        },
    },
    {
        code: 'be',
        name: {
            en: 'Belgium',
            fr: 'Belgique',
        },
    },
    {
        code: 'bz',
        name: {
            en: 'Belize',
            fr: 'Belize',
        },
    },
    {
        code: 'bj',
        name: {
            en: 'Benin',
            fr: 'Bénin',
        },
    },
    {
        code: 'bt',
        name: {
            en: 'Bhutan',
            fr: 'Bhoutan',
        },
    },
    {
        code: 'bo',
        name: {
            en: 'Bolivia (Plurinational State of)',
            fr: 'Bolivie',
        },
    },
    {
        code: 'ba',
        name: {
            en: 'Bosnia and Herzegovina',
            fr: 'Bosnie-Herzégovine',
        },
    },
    {
        code: 'bw',
        name: {
            en: 'Botswana',
            fr: 'Botswana',
        },
    },
    {
        code: 'br',
        name: {
            en: 'Brazil',
            fr: 'Brésil',
        },
    },
    {
        code: 'bn',
        name: {
            en: 'Brunei Darussalam',
            fr: 'Brunei',
        },
    },
    {
        code: 'bg',
        name: {
            en: 'Bulgaria',
            fr: 'Bulgarie',
        },
    },
    {
        code: 'bf',
        name: {
            en: 'Burkina Faso',
            fr: 'Burkina Faso',
        },
    },
    {
        code: 'bi',
        name: {
            en: 'Burundi',
            fr: 'Burundi',
        },
    },
    {
        code: 'cv',
        name: {
            en: 'Cabo Verde',
            fr: 'Cap-Vert',
        },
    },
    {
        code: 'kh',
        name: {
            en: 'Cambodia',
            fr: 'Cambodge',
        },
    },
    {
        code: 'cm',
        name: {
            en: 'Cameroon',
            fr: 'Cameroun',
        },
    },
    {
        code: 'ca',
        name: {
            en: 'Canada',
            fr: 'Canada',
        },
    },
    {
        code: 'cf',
        name: {
            en: 'Central African Republic',
            fr: 'République centrafricaine',
        },
    },
    {
        code: 'td',
        name: {
            en: 'Chad',
            fr: 'Tchad',
        },
    },
    {
        code: 'cl',
        name: {
            en: 'Chile',
            fr: 'Chili',
        },
    },
    {
        code: 'cn',
        name: {
            en: 'China',
            fr: 'Chine',
        },
    },
    {
        code: 'co',
        name: {
            en: 'Colombia',
            fr: 'Colombie',
        },
    },
    {
        code: 'km',
        name: {
            en: 'Comoros',
            fr: 'Comores (pays)',
        },
    },
    {
        code: 'cg',
        name: {
            en: 'Congo',
            fr: 'République du Congo',
        },
    },
    {
        code: 'cd',
        name: {
            en: 'Congo, Democratic Republic of the',
            fr: 'République démocratique du Congo',
        },
    },
    {
        code: 'cr',
        name: {
            en: 'Costa Rica',
            fr: 'Costa Rica',
        },
    },
    {
        code: 'ci',
        name: {
            en: 'Côte d\'Ivoire',
            fr: 'Côte d\'Ivoire',
        },
    },
    {
        code: 'hr',
        name: {
            en: 'Croatia',
            fr: 'Croatie',
        },
    },
    {
        code: 'cu',
        name: {
            en: 'Cuba',
            fr: 'Cuba',
        },
    },
    {
        code: 'cy',
        name: {
            en: 'Cyprus',
            fr: 'Chypre (pays)',
        },
    },
    {
        code: 'cz',
        name: {
            en: 'Czechia',
            fr: 'Tchéquie',
        },
    },
    {
        code: 'dk',
        name: {
            en: 'Denmark',
            fr: 'Danemark',
        },
    },
    {
        code: 'dj',
        name: {
            en: 'Djibouti',
            fr: 'Djibouti',
        },
    },
    {
        code: 'dm',
        name: {
            en: 'Dominica',
            fr: 'Dominique',
        },
    },
    {
        code: 'do',
        name: {
            en: 'Dominican Republic',
            fr: 'République dominicaine',
        },
    },
    {
        code: 'ec',
        name: {
            en: 'Ecuador',
            fr: 'Équateur (pays)',
        },
    },
    {
        code: 'eg',
        name: {
            en: 'Egypt',
            fr: 'Égypte',
        },
    },
    {
        code: 'sv',
        name: {
            en: 'El Salvador',
            fr: 'Salvador',
        },
    },
    {
        code: 'gq',
        name: {
            en: 'Equatorial Guinea',
            fr: 'Guinée équatoriale',
        },
    },
    {
        code: 'er',
        name: {
            en: 'Eritrea',
            fr: 'Érythrée',
        },
    },
    {
        code: 'ee',
        name: {
            en: 'Estonia',
            fr: 'Estonie',
        },
    },
    {
        code: 'sz',
        name: {
            en: 'Eswatini',
            fr: 'Eswatini',
        },
    },
    {
        code: 'et',
        name: {
            en: 'Ethiopia',
            fr: 'Éthiopie',
        },
    },
    {
        code: 'fj',
        name: {
            en: 'Fiji',
            fr: 'Fidji',
        },
    },
    {
        code: 'fi',
        name: {
            en: 'Finland',
            fr: 'Finlande',
        },
    },
    {
        code: 'fr',
        name: {
            en: 'France',
            fr: 'France',
        },
    },
    {
        code: 'ga',
        name: {
            en: 'Gabon',
            fr: 'Gabon',
        },
    },
    {
        code: 'gm',
        name: {
            en: 'Gambia',
            fr: 'Gambie',
        },
    },
    {
        code: 'ge',
        name: {
            en: 'Georgia',
            fr: 'Géorgie (pays)',
        },
    },
    {
        code: 'de',
        name: {
            en: 'Germany',
            fr: 'Allemagne',
        },
    },
    {
        code: 'gh',
        name: {
            en: 'Ghana',
            fr: 'Ghana',
        },
    },
    {
        code: 'gr',
        name: {
            en: 'Greece',
            fr: 'Grèce',
        },
    },
    {
        code: 'gd',
        name: {
            en: 'Grenada',
            fr: 'Grenade (pays)',
        },
    },
    {
        code: 'gt',
        name: {
            en: 'Guatemala',
            fr: 'Guatemala',
        },
    },
    {
        code: 'gn',
        name: {
            en: 'Guinea',
            fr: 'Guinée',
        },
    },
    {
        code: 'gw',
        name: {
            en: 'Guinea-Bissau',
            fr: 'Guinée-Bissau',
        },
    },
    {
        code: 'gy',
        name: {
            en: 'Guyana',
            fr: 'Guyana',
        },
    },
    {
        code: 'ht',
        name: {
            en: 'Haiti',
            fr: 'Haïti',
        },
    },
    {
        code: 'hn',
        name: {
            en: 'Honduras',
            fr: 'Honduras',
        },
    },
    {
        code: 'hu',
        name: {
            en: 'Hungary',
            fr: 'Hongrie',
        },
    },
    {
        code: 'is',
        name: {
            en: 'Iceland',
            fr: 'Islande',
        },
    },
    {
        code: 'in',
        name: {
            en: 'India',
            fr: 'Inde',
        },
    },
    {
        code: 'id',
        name: {
            en: 'Indonesia',
            fr: 'Indonésie',
        },
    },
    {
        code: 'ir',
        name: {
            en: 'Iran (Islamic Republic of)',
            fr: 'Iran',
        },
    },
    {
        code: 'iq',
        name: {
            en: 'Iraq',
            fr: 'Irak',
        },
    },
    {
        code: 'ie',
        name: {
            en: 'Ireland',
            fr: 'Irlande (pays)',
        },
    },
    {
        code: 'il',
        name: {
            en: 'Israel',
            fr: 'Israël',
        },
    },
    {
        code: 'it',
        name: {
            en: 'Italy',
            fr: 'Italie',
        },
    },
    {
        code: 'jm',
        name: {
            en: 'Jamaica',
            fr: 'Jamaïque',
        },
    },
    {
        code: 'jp',
        name: {
            en: 'Japan',
            fr: 'Japon',
        },
    },
    {
        code: 'jo',
        name: {
            en: 'Jordan',
            fr: 'Jordanie',
        },
    },
    {
        code: 'kz',
        name: {
            en: 'Kazakhstan',
            fr: 'Kazakhstan',
        },
    },
    {
        code: 'ke',
        name: {
            en: 'Kenya',
            fr: 'Kenya',
        },
    },
    {
        code: 'ki',
        name: {
            en: 'Kiribati',
            fr: 'Kiribati',
        },
    },
    {
        code: 'kp',
        name: {
            en: 'Korea (Democratic People\'s Republic of)',
            fr: 'Corée du Nord',
        },
    },
    {
        code: 'kr',
        name: {
            en: 'Korea, Republic of',
            fr: 'Corée du Sud',
        },
    },
    {
        code: 'kw',
        name: {
            en: 'Kuwait',
            fr: 'Koweït',
        },
    },
    {
        code: 'kg',
        name: {
            en: 'Kyrgyzstan',
            fr: 'Kirghizistan',
        },
    },
    {
        code: 'la',
        name: {
            en: 'Lao People\'s Democratic Republic',
            fr: 'Laos',
        },
    },
    {
        code: 'lv',
        name: {
            en: 'Latvia',
            fr: 'Lettonie',
        },
    },
    {
        code: 'lb',
        name: {
            en: 'Lebanon',
            fr: 'Liban',
        },
    },
    {
        code: 'ls',
        name: {
            en: 'Lesotho',
            fr: 'Lesotho',
        },
    },
    {
        code: 'lr',
        name: {
            en: 'Liberia',
            fr: 'Liberia',
        },
    },
    {
        code: 'ly',
        name: {
            en: 'Libya',
            fr: 'Libye',
        },
    },
    {
        code: 'li',
        name: {
            en: 'Liechtenstein',
            fr: 'Liechtenstein',
        },
    },
    {
        code: 'lt',
        name: {
            en: 'Lithuania',
            fr: 'Lituanie',
        },
    },
    {
        code: 'lu',
        name: {
            en: 'Luxembourg',
            fr: 'Luxembourg (pays)',
        },
    },
    {
        code: 'mg',
        name: {
            en: 'Madagascar',
            fr: 'Madagascar',
        },
    },
    {
        code: 'mw',
        name: {
            en: 'Malawi',
            fr: 'Malawi',
        },
    },
    {
        code: 'my',
        name: {
            en: 'Malaysia',
            fr: 'Malaisie',
        },
    },
    {
        code: 'mv',
        name: {
            en: 'Maldives',
            fr: 'Maldives',
        },
    },
    {
        code: 'ml',
        name: {
            en: 'Mali',
            fr: 'Mali',
        },
    },
    {
        code: 'mt',
        name: {
            en: 'Malta',
            fr: 'Malte',
        },
    },
    {
        code: 'mh',
        name: {
            en: 'Marshall Islands',
            fr: 'Îles Marshall (pays)',
        },
    },
    {
        code: 'mr',
        name: {
            en: 'Mauritania',
            fr: 'Mauritanie',
        },
    },
    {
        code: 'mu',
        name: {
            en: 'Mauritius',
            fr: 'Maurice (pays)',
        },
    },
    {
        code: 'mx',
        name: {
            en: 'Mexico',
            fr: 'Mexique',
        },
    },
    {
        code: 'fm',
        name: {
            en: 'Micronesia (Federated States of)',
            fr: 'États fédérés de Micronésie (pays)',
        },
    },
    {
        code: 'md',
        name: {
            en: 'Moldova, Republic of',
            fr: 'Moldavie',
        },
    },
    {
        code: 'mc',
        name: {
            en: 'Monaco',
            fr: 'Monaco',
        },
    },
    {
        code: 'mn',
        name: {
            en: 'Mongolia',
            fr: 'Mongolie',
        },
    },
    {
        code: 'me',
        name: {
            en: 'Montenegro',
            fr: 'Monténégro',
        },
    },
    {
        code: 'ma',
        name: {
            en: 'Morocco',
            fr: 'Maroc',
        },
    },
    {
        code: 'mz',
        name: {
            en: 'Mozambique',
            fr: 'Mozambique',
        },
    },
    {
        code: 'mm',
        name: {
            en: 'Myanmar',
            fr: 'Birmanie',
        },
    },
    {
        code: 'na',
        name: {
            en: 'Namibia',
            fr: 'Namibie',
        },
    },
    {
        code: 'nr',
        name: {
            en: 'Nauru',
            fr: 'Nauru',
        },
    },
    {
        code: 'np',
        name: {
            en: 'Nepal',
            fr: 'Népal',
        },
    },
    {
        code: 'nl',
        name: {
            en: 'Netherlands',
            fr: 'Pays-Bas',
        },
    },
    {
        code: 'nz',
        name: {
            en: 'New Zealand',
            fr: 'Nouvelle-Zélande',
        },
    },
    {
        code: 'ni',
        name: {
            en: 'Nicaragua',
            fr: 'Nicaragua',
        },
    },
    {
        code: 'ne',
        name: {
            en: 'Niger',
            fr: 'Niger',
        },
    },
    {
        code: 'ng',
        name: {
            en: 'Nigeria',
            fr: 'Nigeria',
        },
    },
    {
        code: 'mk',
        name: {
            en: 'North Macedonia',
            fr: 'Macédoine du Nord',
        },
    },
    {
        code: 'no',
        name: {
            en: 'Norway',
            fr: 'Norvège',
        },
    },
    {
        code: 'om',
        name: {
            en: 'Oman',
            fr: 'Oman',
        },
    },
    {
        code: 'pk',
        name: {
            en: 'Pakistan',
            fr: 'Pakistan',
        },
    },
    {
        code: 'pw',
        name: {
            en: 'Palau',
            fr: 'Palaos',
        },
    },
    {
        code: 'pa',
        name: {
            en: 'Panama',
            fr: 'Panama',
        },
    },
    {
        code: 'pg',
        name: {
            en: 'Papua New Guinea',
            fr: 'Papouasie-Nouvelle-Guinée',
        },
    },
    {
        code: 'py',
        name: {
            en: 'Paraguay',
            fr: 'Paraguay',
        },
    },
    {
        code: 'pe',
        name: {
            en: 'Peru',
            fr: 'Pérou',
        },
    },
    {
        code: 'ph',
        name: {
            en: 'Philippines',
            fr: 'Philippines',
        },
    },
    {
        code: 'pl',
        name: {
            en: 'Poland',
            fr: 'Pologne',
        },
    },
    {
        code: 'pt',
        name: {
            en: 'Portugal',
            fr: 'Portugal',
        },
    },
    {
        code: 'qa',
        name: {
            en: 'Qatar',
            fr: 'Qatar',
        },
    },
    {
        code: 'ro',
        name: {
            en: 'Romania',
            fr: 'Roumanie',
        },
    },
    {
        code: 'ru',
        name: {
            en: 'Russian Federation',
            fr: 'Russie',
        },
    },
    {
        code: 'rw',
        name: {
            en: 'Rwanda',
            fr: 'Rwanda',
        },
    },
    {
        code: 'kn',
        name: {
            en: 'Saint Kitts and Nevis',
            fr: 'Saint-Christophe-et-Niévès',
        },
    },
    {
        code: 'lc',
        name: {
            en: 'Saint Lucia',
            fr: 'Sainte-Lucie',
        },
    },
    {
        code: 'vc',
        name: {
            en: 'Saint Vincent and the Grenadines',
            fr: 'Saint-Vincent-et-les-Grenadines',
        },
    },
    {
        code: 'ws',
        name: {
            en: 'Samoa',
            fr: 'Samoa',
        },
    },
    {
        code: 'sm',
        name: {
            en: 'San Marino',
            fr: 'Saint-Marin',
        },
    },
    {
        code: 'st',
        name: {
            en: 'Sao Tome and Principe',
            fr: 'Sao Tomé-et-Principe',
        },
    },
    {
        code: 'sa',
        name: {
            en: 'Saudi Arabia',
            fr: 'Arabie saoudite',
        },
    },
    {
        code: 'sn',
        name: {
            en: 'Senegal',
            fr: 'Sénégal',
        },
    },
    {
        code: 'rs',
        name: {
            en: 'Serbia',
            fr: 'Serbie',
        },
    },
    {
        code: 'sc',
        name: {
            en: 'Seychelles',
            fr: 'Seychelles',
        },
    },
    {
        code: 'sl',
        name: {
            en: 'Sierra Leone',
            fr: 'Sierra Leone',
        },
    },
    {
        code: 'sg',
        name: {
            en: 'Singapore',
            fr: 'Singapour',
        },
    },
    {
        code: 'sk',
        name: {
            en: 'Slovakia',
            fr: 'Slovaquie',
        },
    },
    {
        code: 'si',
        name: {
            en: 'Slovenia',
            fr: 'Slovénie',
        },
    },
    {
        code: 'sb',
        name: {
            en: 'Solomon Islands',
            fr: 'Îles Salomon',
        },
    },
    {
        code: 'so',
        name: {
            en: 'Somalia',
            fr: 'Somalie',
        },
    },
    {
        code: 'za',
        name: {
            en: 'South Africa',
            fr: 'Afrique du Sud',
        },
    },
    {
        code: 'ss',
        name: {
            en: 'South Sudan',
            fr: 'Soudan du Sud',
        },
    },
    {
        code: 'es',
        name: {
            en: 'Spain',
            fr: 'Espagne',
        },
    },
    {
        code: 'lk',
        name: {
            en: 'Sri Lanka',
            fr: 'Sri Lanka',
        },
    },
    {
        code: 'sd',
        name: {
            en: 'Sudan',
            fr: 'Soudan',
        },
    },
    {
        code: 'sr',
        name: {
            en: 'Suriname',
            fr: 'Suriname',
        },
    },
    {
        code: 'se',
        name: {
            en: 'Sweden',
            fr: 'Suède',
        },
    },
    {
        code: 'ch',
        name: {
            en: 'Switzerland',
            fr: 'Suisse',
        },
    },
    {
        code: 'sy',
        name: {
            en: 'Syrian Arab Republic',
            fr: 'Syrie',
        },
    },
    {
        code: 'tj',
        name: {
            en: 'Tajikistan',
            fr: 'Tadjikistan',
        },
    },
    {
        code: 'tz',
        name: {
            en: 'Tanzania, United Republic of',
            fr: 'Tanzanie',
        },
    },
    {
        code: 'th',
        name: {
            en: 'Thailand',
            fr: 'Thaïlande',
        },
    },
    {
        code: 'tl',
        name: {
            en: 'Timor-Leste',
            fr: 'Timor oriental',
        },
    },
    {
        code: 'tg',
        name: {
            en: 'Togo',
            fr: 'Togo',
        },
    },
    {
        code: 'to',
        name: {
            en: 'Tonga',
            fr: 'Tonga',
        },
    },
    {
        code: 'tt',
        name: {
            en: 'Trinidad and Tobago',
            fr: 'Trinité-et-Tobago',
        },
    },
    {
        code: 'tn',
        name: {
            en: 'Tunisia',
            fr: 'Tunisie',
        },
    },
    {
        code: 'tr',
        name: {
            en: 'Turkey',
            fr: 'Turquie',
        },
    },
    {
        code: 'tm',
        name: {
            en: 'Turkmenistan',
            fr: 'Turkménistan',
        },
    },
    {
        code: 'tv',
        name: {
            en: 'Tuvalu',
            fr: 'Tuvalu',
        },
    },
    {
        code: 'ug',
        name: {
            en: 'Uganda',
            fr: 'Ouganda',
        },
    },
    {
        code: 'ua',
        name: {
            en: 'Ukraine',
            fr: 'Ukraine',
        },
    },
    {
        code: 'ae',
        name: {
            en: 'United Arab Emirates',
            fr: 'Émirats arabes unis',
        },
    },
    {
        code: 'gb',
        name: {
            en: 'United Kingdom of Great Britain and Northern Ireland',
            fr: 'Royaume-Uni',
        },
    },
    {
        code: 'us',
        name: {
            en: 'United States of America',
            fr: 'États-Unis',
        },
    },
    {
        code: 'uy',
        name: {
            en: 'Uruguay',
            fr: 'Uruguay',
        },
    },
    {
        code: 'uz',
        name: {
            en: 'Uzbekistan',
            fr: 'Ouzbékistan',
        },
    },
    {
        code: 'vu',
        name: {
            en: 'Vanuatu',
            fr: 'Vanuatu',
        },
    },
    {
        code: 've',
        name: {
            en: 'Venezuela (Bolivarian Republic of)',
            fr: 'Venezuela',
        },
    },
    {
        code: 'vn',
        name: {
            en: 'Viet Nam',
            fr: 'Viêt Nam',
        },
    },
    {
        code: 'ye',
        name: {
            en: 'Yemen',
            fr: 'Yémen',
        },
    },
    {
        code: 'zm',
        name: {
            en: 'Zambia',
            fr: 'Zambie',
        },
    },
    {
        code: 'zw',
        name: {
            en: 'Zimbabwe',
            fr: 'Zimbabwe',
        },
    },
];
