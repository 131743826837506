import {yupResolver} from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import {LoadingButton} from '@mui/lab';
import {Box, Button, Divider, FormLabel, Grid, Grow, Paper, Slide, TextField} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';

import {useDeclarationOfNonConvictionFormStyles} from './styles';
import {PageHeader} from '../../../../components/page-header/PageHeader';
import {AnimationActions} from '../../../../features/animations/store/animations.action';
import {AnimationsSelectors} from '../../../../features/animations/store/animations.selector';
import {ANIMATION_TYPE, FORM_ANIMATION_DELAY} from '../../../../features/animations/utils/constants';
import {CompanySetupSubSteps} from '../../../../features/freelancer/modules/company-setup/utils/constants';
import {OnboardingActions} from '../../../../features/freelancer/modules/onboarding/store/onboarding.action';
import {getNonConvictionDeclarationData} from '../../../app/company/setupCompany/nonConvictionDeclaration/nonConvictionDeclaration.selectors';
import {submitActiveStepForm} from '../../../app/company/setupCompany/setupCompany.actions';
import {getIsLoadingSetupCompanyForm} from '../../../app/company/setupCompany/setupCompany.selectors';

export const NonConvictionDeclarationForm = ({hasSubmitModificationsButton}) => {
    const styles = useDeclarationOfNonConvictionFormStyles();
    const {t} = useTranslation('setup');
    const dispatch = useDispatch();

    const nonConvictionDeclarationData = useSelector(getNonConvictionDeclarationData);
    const isLoadingForm = useSelector(getIsLoadingSetupCompanyForm);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const validationSchema = Yup.object().shape({
        fathersFirstName: Yup.string().min(2, t('validations.minMessage')).max(26, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('fathersFirstName')})),
        fathersLastName: Yup.string().min(2, t('validations.minMessage')).max(26, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('fathersLastName')})),
        mothersFirstName: Yup.string().min(2, t('validations.minMessage')).max(26, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('motherFirstName')})),
        mothersLastName: Yup.string().min(2, t('validations.minMessage')).max(26, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('motherLastName')})),
    });

    const {
        setValue,
        formState: {errors, isDirty, isValid},
        control,
        reset,
        handleSubmit: createSubmitHandler,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            fathersFirstName: nonConvictionDeclarationData?.fathersFirstName || '',
            fathersLastName: nonConvictionDeclarationData?.fathersLastName || '',
            mothersFirstName: nonConvictionDeclarationData?.mothersFirstName || '',
            mothersLastName: nonConvictionDeclarationData?.mothersLastName || '',
        },
        resolver: yupResolver(validationSchema),
    });

    const formRef = useRef(null);
    const isSubmittingForApproval = useRef(false);

    const handleSubmit = useCallback(data => {
        dispatch(submitActiveStepForm(data, isDirty, null, isSubmittingForApproval.current));

        isSubmittingForApproval.current = false;
    }, [dispatch, isDirty, isSubmittingForApproval]);

    const handleSubmitModifications = useCallback(() => {
        isSubmittingForApproval.current = true;

        // Trigger form submit
        formRef.current.dispatchEvent(new Event('submit', {
            cancelable: true,
            bubbles: true,
        }));
    }, []);

    useEffect(() => {
        if (!nonConvictionDeclarationData) {
            return;
        }

        reset({
            fathersFirstName: nonConvictionDeclarationData?.fathersFirstName || '',
            fathersLastName: nonConvictionDeclarationData?.fathersLastName || '',
            mothersFirstName: nonConvictionDeclarationData?.mothersFirstName || '',
            mothersLastName: nonConvictionDeclarationData?.mothersLastName || '',
        });

        return () => {
        };
    }, [nonConvictionDeclarationData, reset, setValue]);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    if (!isMobileSize) {
        return (
            <form ref={formRef} onSubmit={createSubmitHandler(handleSubmit)} noValidate>
                <PageHeader subTitle={t('stepSubtitles.nonConviction')}>
                    {t('stepTitles.nonConviction')}
                </PageHeader>

                <Slide
                    direction="left"
                    in={isAnimationActive}
                    timeout={FORM_ANIMATION_DELAY}
                    onEntered={onEndedAnimation}
                    onExited={onEndedAnimation}
                >
                    <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                        {/* <SetupCompanyFormTitle */}
                        {/*    title={t('stepTitles.nonConviction')} */}
                        {/*    subTitle={t('stepSubtitles.nonConviction')} */}
                        {/* /> */}

                        <FormLabel component="legend" className={styles.formLabel}>
                            {t('fathersName')}
                        </FormLabel>

                        <Grow
                            in={isSubAnimationActive}
                            timeout={FORM_ANIMATION_DELAY}
                            onExited={() => {
                                dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                            }}
                        >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="fathersFirstName"
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                label={t('fathersFirstName')}
                                                variant="filled"
                                                error={!!errors.fathersFirstName}
                                                helperText={errors.fathersFirstName?.message}
                                                required
                                                fullWidth
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="fathersLastName"
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                label={t('fathersLastName')}
                                                variant="filled"
                                                error={!!errors.fathersLastName}
                                                helperText={errors.fathersLastName?.message}
                                                required
                                                fullWidth
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grow>

                        <Divider className={styles.divider} />

                        <FormLabel component="legend" className={styles.formLabel}>
                            {t('motherName')}
                        </FormLabel>

                        <Grow
                            in={isSubAnimationActive}
                            timeout={FORM_ANIMATION_DELAY * 2}
                        >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="mothersFirstName"
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                label={t('motherFirstName')}
                                                variant="filled"
                                                error={!!errors.mothersFirstName}
                                                helperText={errors.mothersFirstName?.message}
                                                required
                                                fullWidth
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name="mothersLastName"
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                label={t('motherLastName')}
                                                variant="filled"
                                                error={!!errors.mothersLastName}
                                                helperText={errors.mothersLastName?.message}
                                                required
                                                fullWidth
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grow>

                        {/* <SetupCompanyFormFooter */}
                        {/*    onBackButtonClick={handleBackButtonClick} */}
                        {/*    isSubmitButtonDisabled={isSubmitButtonDisabled} */}
                        {/*    areBothButtonsDisabled={isLoading} */}
                        {/*    onSubmitForApprovalButtonClick={handleSubmitForApprovalButtonClick} */}
                        {/* /> */}
                    </Paper>
                </Slide>

                <Box display="flex" justifyContent="space-between" sx={{mt: 3}}>
                    <Slide
                        direction="right"
                        in={isAnimationActive}
                        timeout={FORM_ANIMATION_DELAY * 4}
                    >
                        <Button
                            size="large"
                            variant="outlined"
                            color="primary"
                            startIcon={<ArrowBackIcon />}
                            onClick={() => {
                                dispatch(OnboardingActions.setSubStep(CompanySetupSubSteps.ADDRESS_INFORMATION));
                            }}
                        >
                            {t('back')}
                        </Button>
                    </Slide>

                    <Slide
                        direction="left"
                        in={isAnimationActive}
                        timeout={FORM_ANIMATION_DELAY * 4}
                    >
                        <Box display="flex" justifyContent="space-between">
                            <Grow in={hasSubmitModificationsButton} mountOnEnter unmountOnExit>
                                <Box sx={{mr: 2}}>
                                    <LoadingButton
                                        loading={isLoadingForm}
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SaveIcon />}
                                        onClick={handleSubmitModifications}
                                        disabled={!isValid}
                                    >
                                        {t('submitForApproval')}
                                    </LoadingButton>
                                </Box>
                            </Grow>

                            <LoadingButton
                                loading={isLoadingForm}
                                size="large"
                                variant="contained"
                                color="secondary"
                                endIcon={<ArrowForwardIcon />}
                                disabled={!isValid}
                                type="submit"
                            >
                                {t('saveAndProceed')}
                            </LoadingButton>
                        </Box>
                    </Slide>
                </Box>
            </form>
        );
    }

    return (
        <form ref={formRef} onSubmit={createSubmitHandler(handleSubmit)} noValidate>
            <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                {/* <SetupCompanyFormTitle */}
                {/*    title={t('stepTitles.nonConviction')} */}
                {/*    subTitle={t('stepSubtitles.nonConviction')} */}
                {/* /> */}

                <FormLabel component="legend" className={styles.formLabel}>
                    {t('fathersName')}
                </FormLabel>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="fathersFirstName"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    label={t('fathersFirstName')}
                                    variant="filled"
                                    error={!!errors.fathersFirstName}
                                    helperText={errors.fathersFirstName?.message}
                                    required
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="fathersLastName"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    label={t('fathersLastName')}
                                    variant="filled"
                                    error={!!errors.fathersLastName}
                                    helperText={errors.fathersLastName?.message}
                                    required
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Divider className={styles.divider} />

                <FormLabel component="legend" className={styles.formLabel}>
                    {t('motherName')}
                </FormLabel>


                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="mothersFirstName"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    label={t('motherFirstName')}
                                    variant="filled"
                                    error={!!errors.mothersFirstName}
                                    helperText={errors.mothersFirstName?.message}
                                    required
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="mothersLastName"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    label={t('motherLastName')}
                                    variant="filled"
                                    error={!!errors.mothersLastName}
                                    helperText={errors.mothersLastName?.message}
                                    required
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>


                {/* <SetupCompanyFormFooter */}
                {/*    onBackButtonClick={handleBackButtonClick} */}
                {/*    isSubmitButtonDisabled={isSubmitButtonDisabled} */}
                {/*    areBothButtonsDisabled={isLoading} */}
                {/*    onSubmitForApprovalButtonClick={handleSubmitForApprovalButtonClick} */}
                {/* /> */}
            </Paper>


            <Box display="flex" flexDirection="column" justifyContent="center" sx={{mt: 3}}>

                <Button
                    sx={{mx: 2}}
                    size="large"
                    variant="outlined"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                        dispatch(OnboardingActions.setSubStep(CompanySetupSubSteps.ADDRESS_INFORMATION));
                    }}
                >
                    {t('back')}
                </Button>

                <LoadingButton
                    sx={{mt: 3, mx: 2}}
                    loading={isLoadingForm}
                    size="large"
                    variant="contained"
                    color="secondary"
                    endIcon={<ArrowForwardIcon />}
                    disabled={!isValid}
                    type="submit"
                >
                    {t('saveAndProceed')}
                </LoadingButton>

                {hasSubmitModificationsButton && (
                    <LoadingButton
                        sx={{mt: 3, mx: 2}}
                        loading={isLoadingForm}
                        size="large"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={handleSubmitModifications}
                        disabled={!isValid}
                    >
                        {t('submitForApproval')}
                    </LoadingButton>
                )}
            </Box>
        </form>
    );
};

NonConvictionDeclarationForm.propTypes = {
    hasSubmitModificationsButton: PropTypes.bool,
};

NonConvictionDeclarationForm.defaultProps = {
    hasSubmitModificationsButton: false,
};
