import {DocumentationActionType} from './documentation.action-type';
import {EMPTY_SEARCH_RESULTS} from '../util/constants';

export const DocumentationActions = {
    storeCategories: data => ({
        type: DocumentationActionType.STORE_CATEGORIES,
        payload: data,
    }),
    storeSubCategories: data => ({
        type: DocumentationActionType.STORE_SUBCATEGORIES,
        payload: data,
    }),
    storeArticles: data => ({
        type: DocumentationActionType.STORE_ARTICLES,
        payload: data,
    }),
    storeVideos: data => ({
        type: DocumentationActionType.STORE_VIDEOS,
        payload: data,
    }),
    storeFaqs: data => ({
        type: DocumentationActionType.STORE_FAQS,
        payload: data,
    }),
    storeDetails: data => ({
        type: DocumentationActionType.STORE_DETAILS,
        payload: data,
    }),
    search: data => ({
        type: DocumentationActionType.SEARCH,
        payload: data,
    }),
    storeSearchResults: data => ({
        type: DocumentationActionType.STORE_SEARCH_RESULTS,
        payload: data,
    }),
    clearSearchResults: () => ({
        type: DocumentationActionType.STORE_SEARCH_RESULTS,
        payload: EMPTY_SEARCH_RESULTS,
    }),
    storeVoteResult: data => ({
        type: DocumentationActionType.STORE_VOTE_RESULT,
        payload: data,
    }),
    vote: data => ({
        type: DocumentationActionType.VOTE,
        payload: data,
    }),
    storePopularArticles: data => ({
        type: DocumentationActionType.STORE_POPULAR_ARTICLES,
        payload: data,
    }),
    getGlobalPopularArticles: data => ({
        type: DocumentationActionType.GET_GLOBAL_POPULAR_ARTICLES,
        payload: data,
    }),
    storeGlobalPopularArticles: data => ({
        type: DocumentationActionType.STORE_GLOBAL_POPULAR_ARTICLES,
        payload: data,
    }),
    storeOtherItems: data => ({
        type: DocumentationActionType.STORE_OTHER_ITEMS,
        payload: data,
    }),
};
