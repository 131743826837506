export const DOWNLOAD_EXPENSES_AS_ARCHIVE = 'DOWNLOAD_EXPENSES_AS_ARCHIVE';
export const SET_IS_DOWNLOAD_IN_PROGRESS = 'SET_IS_DOWNLOAD_IN_PROGRESS';

export const downloadExpensesAsArchive = (freelancerId, companyId, documentIds, archivingStrategy, onSuccess) => ({
    type: DOWNLOAD_EXPENSES_AS_ARCHIVE,
    payload: {
        freelancerId,
        companyId,
        documentIds,
        archivingStrategy,
        onSuccess,
    },
});

export const setIsDownloadInProgress = isDownloadInProgress => ({
    type: SET_IS_DOWNLOAD_IN_PROGRESS,
    payload: isDownloadInProgress,
});
