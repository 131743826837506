import {Skeleton} from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {useUserCompaniesStyles} from './styles';
import {CompaniesSelectors} from '../../../../features/freelancer/modules/companies';
import {COMPANY_CARD_BUTTONS} from '../../../app/company/company.consts';
import {
    selectIsLoadingFreelancerCompanies,
} from '../../../app/freelancer/freelancer.selectors';
import CompanyCard from '../CompanyCard';

// TODO Move component to v2
const UserCompanies = ({user, visibleButtons, hasCardElevation, md, lg}) => {
    const {t} = useTranslation('companies');

    const styles = useUserCompaniesStyles();

    const freelancerCompanyEntities = useSelector(CompaniesSelectors.selectEntities);

    let isLoadingFreelancerCompanies = useSelector(selectIsLoadingFreelancerCompanies);

    if (!user) {
        // TODO:HIGH Add a load flag for this view.
        isLoadingFreelancerCompanies = true;
    }

    if (isLoadingFreelancerCompanies && !freelancerCompanyEntities) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={md} lg={lg}>
                    <Skeleton height={120} variant="rectangular" />
                </Grid>

                <Grid item xs={12} md={md} lg={lg}>
                    <Skeleton height={120} variant="rectangular" />
                </Grid>

                <Grid item xs={12} md={md} lg={lg}>
                    <Skeleton height={120} variant="rectangular" />
                </Grid>

                <Grid item xs={12} md={md} lg={lg}>
                    <Skeleton height={120} variant="rectangular" />
                </Grid>
            </Grid>
        );
    }

    if (!freelancerCompanyEntities) {
        return (
            <Typography className={styles.noCompaniesLabel} variant="body1" color="textPrimary" component="p">
                {t('noCompanies')}
            </Typography>
        );
    }

    const freelancerCompanies = Object.values(freelancerCompanyEntities);

    return (
        <Grid container spacing={3}>
            {freelancerCompanies.map((company, index) => (
                <Grid item xs={12} md={md} lg={lg} key={index}>
                    <CompanyCard
                        companyId={company.id}
                        status={company.status}
                        companyName={company.name}
                        companyType={company.type}
                        isUserActive={user.active}
                        userId={user.id}
                        visibleButtons={visibleButtons}
                        hasElevation={hasCardElevation}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

UserCompanies.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
    }),
    visibleButtons: PropTypes.arrayOf(PropTypes.string),
    hasCardElevation: PropTypes.bool,
    md: PropTypes.number,
    lg: PropTypes.number,
};

UserCompanies.defaultProps = {
    user: null,
    visibleButtons: Object.values(COMPANY_CARD_BUTTONS),
    hasCardElevation: false,
    md: 4,
    lg: 4,
};

export default UserCompanies;
