/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {Typography} from '@mui/joy';
import {styled, useThemeProps} from '@mui/system';
import * as React from 'react';
import {getDatePickerToolbarUtilityClass} from './datePickerToolbarClasses';
import {isYearAndMonthViews, isYearOnlyView} from './shared';
import {PickersToolbar} from '../internals/components/PickersToolbar';
import {useLocaleText, useUtils} from '../internals/hooks/useUtils';

const useUtilityClasses = ownerState => {
    const {classes} = ownerState;
    const slots = {
        root: ['root'],
        title: ['title'],
    };

    return composeClasses(slots, getDatePickerToolbarUtilityClass, classes);
};

const DatePickerToolbarRoot = styled(PickersToolbar, {
    name: 'MuiDatePickerToolbar',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})({});

const DatePickerToolbarTitle = styled(Typography, {
    name: 'MuiDatePickerToolbar',
    slot: 'Title',
    overridesResolver: (_, styles) => styles.title,
})(({ownerState}) => ({
    ...(ownerState.isLandscape && {
        margin: 'auto 16px auto auto',
    }),
}));

export const DatePickerToolbar = React.forwardRef((
    inProps,
    ref,
) => {
    const props = useThemeProps({props: inProps, name: 'MuiDatePickerToolbar'});
    const {
        parsedValue,
        isLandscape,
        isMobileKeyboardViewOpen,
        onChange,
        toggleMobileKeyboardView,
        toolbarFormat,
        toolbarPlaceholder = '––',
        toolbarTitle: toolbarTitleProp,
        views,
        ...other
    } = props;
    const utils = useUtils();
    const localeText = useLocaleText();
    const classes = useUtilityClasses(props);

    const toolbarTitle = toolbarTitleProp ?? localeText.datePickerDefaultToolbarTitle;

    const dateText = React.useMemo(() => {
        if (!parsedValue) {
            return toolbarPlaceholder;
        }

        if (toolbarFormat) {
            return utils.formatByString(parsedValue, toolbarFormat);
        }

        if (isYearOnlyView(views)) {
            return utils.format(parsedValue, 'year');
        }

        if (isYearAndMonthViews(views)) {
            return utils.format(parsedValue, 'month');
        }

        // Little localization hack (Google is doing the same for android native pickers):
        // For english localization it is convenient to include weekday into the date "Mon, Jun 1".
        // For other locales using strings like "June 1", without weekday.
        return /en/.test(utils.getCurrentLocaleCode())
            ? utils.format(parsedValue, 'normalDateWithWeekday')
            : utils.format(parsedValue, 'normalDate');
    }, [parsedValue, toolbarFormat, toolbarPlaceholder, utils, views]);

    const ownerState = props;

    return (
        <DatePickerToolbarRoot
            ref={ref}
            toolbarTitle={toolbarTitle}
            isMobileKeyboardViewOpen={isMobileKeyboardViewOpen}
            toggleMobileKeyboardView={toggleMobileKeyboardView}
            isLandscape={isLandscape}
            className={classes.root}
            {...other}
        >
            <DatePickerToolbarTitle
                level="title-lg"
                data-mui-test="datepicker-toolbar-date"
                align={isLandscape ? 'left' : 'center'}
                ownerState={ownerState}
                className={classes.title}
            >
                {dateText}
            </DatePickerToolbarTitle>
        </DatePickerToolbarRoot>
    );
});
