import makeStyles from '@mui/styles/makeStyles';

export const useDataTableLoaderStyles = makeStyles(theme => ({
    tableCell: {
        padding: theme.spacing(2, 3),
    },
    checkboxTableCell: {
        padding: theme.spacing(2),
    },
    skeleton: {
        display: 'inline-flex',
        verticalAlign: 'middle',
        maxWidth: 'max-content',
    },
}));
