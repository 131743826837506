import CloseIcon from '@mui/icons-material/Close';
import {IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const CloseDrawerButton = ({onClose, isDisabled, buttonStyles}) => (
    <IconButton
        size="small"
        sx={{
            backgroundColor: 'blueGray.main',
            position: 'absolute',
            top: '24px',
            right: '24px',
            zIndex: 1,
            ...buttonStyles,
        }}
        disabled={isDisabled}
    >
        <CloseIcon onClick={onClose} />
    </IconButton>
);

CloseDrawerButton.propTypes = {
    onClose: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    buttonStyles: PropTypes.objectOf(PropTypes.any),
};

CloseDrawerButton.defaultProps = {
    isDisabled: false,
    buttonStyles: {},
};

export default CloseDrawerButton;
