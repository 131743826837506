const LOCAL_STORAGE_KEY_PREFIX = 'HIWAY_PORTAL/';

const HiwayLocalStorage = {
    has: key => {
        return !!window.localStorage.getItem(`${LOCAL_STORAGE_KEY_PREFIX}${key}`);
    },
    get: key => {
        const value = window.localStorage.getItem(`${LOCAL_STORAGE_KEY_PREFIX}${key}`);

        try {
            return JSON.parse(value);
        } catch (error) {
            return value;
        }
    },
    delete: key => {
        window.localStorage.removeItem(`${LOCAL_STORAGE_KEY_PREFIX}${key}`);
    },
    set: (key, value) => {
        window.localStorage.setItem(`${LOCAL_STORAGE_KEY_PREFIX}${key}`, JSON.stringify(value));
    },
};

export default HiwayLocalStorage;
