import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {Alert, Box, Button, Grow, Typography} from '@mui/material';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {roundHalfUpToTwoDecimalPlaces} from '../../../../../../../../utils/number';
import useInvoiceCategorisation from '../../../hooks/useInvoiceCategorisation';
import {SECTION_STATUS} from '../../utils/constants';
import CategorisationSection from '../header/CategorisationSection';

const CreditFacturePanel = ({
    onOpenInvoiceMatching,
    isEditable,
    details,
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const {
        selectedInvoicesObj,
        amount,
        selectedInvoicesForPaidInfo,
        allocated,
        onSelectInvoice,
    } = useInvoiceCategorisation({details, isEditable, dispatch});

    const hasPaid = selectedInvoicesObj?.length > 0 || allocated;
    const hasUnpaid = Number(amount) - Number(allocated) > 1;

    return (
        <Box>
            <CategorisationSection
                title={t(`categorisation.facturePanel.title`)}
                status={SECTION_STATUS.REQUIRED}
                sx={{
                    flex: '2 0 0',
                    p: 2,
                    pt: 1,
                    justifyContent: 'space-between',
                    gap: 1.5,
                }}
                isActive
            >
                {!hasPaid ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                        >
                            <ReceiptIcon sx={{
                                color: 'v2.light.action.active',
                            }}
                            />
                            <Typography sx={{
                                fontSize: theme => theme.typography.pxToRem(14),
                                fontWeight: 500,
                                letterSpacing: '0.1px',
                                fontFeatureSettings: `'clig' off, 'liga' off`,
                                color: 'text_v2.secondary',
                            }}
                            >
                                {t(`categorisation.facturePanel.addFacture`)}
                            </Typography>
                        </Box>

                        <Button
                            startIcon={<AddBoxIcon />}
                            onClick={onOpenInvoiceMatching}
                            fullWidth={false}
                        >
                            {t(`categorisation.facturePanel.addButton`)}
                        </Button>
                    </Box>
                ) : (
                    <Box>
                        <CreditInvoicePanelInfo
                            amount={amount}
                            allocated={allocated}
                            selectedInvoicesForPaidInfo={selectedInvoicesForPaidInfo}
                            onOpenInvoiceMatching={onOpenInvoiceMatching}
                            onSelectInvoice={onSelectInvoice}
                        />

                        {hasUnpaid && (
                        <Box sx={{
                            mt: 1,
                        }}
                        >
                            <Alert
                                severity="warning"
                            >
                                {t(`categorisation.facturePanel.alertWarning`)}
                            </Alert>
                        </Box>
                        )}
                    </Box>
                )}

            </CategorisationSection>
        </Box>
    );
};

CreditFacturePanel.propTypes = {
    onOpenInvoiceMatching: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    details: PropTypes.object.isRequired,
};

const CreditInvoicePanelInfo = ({
    amount,
    allocated,
    selectedInvoicesForPaidInfo,
    onOpenInvoiceMatching,
    onSelectInvoice,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('bank');
    const percents = !amount
        ? 100
        : Math.round(100 * allocated / amount);

    const isCompleted = percents >= 100 || (Number(amount) - Number(allocated) <= 1);

    return (
        <Grow in={true}>
            <Box sx={{
                backgroundColor: 'gray_v2.50',
                borderRadius: '16px',
                padding: '16px 24px 24px',
                mb: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}
            >
                <Box sx={{letterSpacing: '0.15px'}}>
                    {isMobileSize && (
                    <CreditFactureButton
                        isMobileSize={isMobileSize}
                        isCompleted={isCompleted}
                        onOpenInvoiceMatching={onOpenInvoiceMatching}
                        t={t}
                    />
                    )}

                    <Typography
                        component="span"
                        sx={{
                            fontWeight: 400,
                            color: 'text_v2.secondary',
                        }}
                    >
                        {t('transactions.allocatedAmount')}&nbsp;&nbsp;
                    </Typography>

                    <Typography
                        component="span"
                        sx={{
                            fontWeight: 500,
                            color: 'text_v2.secondary',
                        }}
                    >
                        {roundHalfUpToTwoDecimalPlaces(allocated)}€
                    &nbsp;/&nbsp;
                        {roundHalfUpToTwoDecimalPlaces(amount)}€
                    </Typography>

                    {!isMobileSize && (
                        <CreditFactureButton
                            isCompleted={isCompleted}
                            onOpenInvoiceMatching={onOpenInvoiceMatching}
                            t={t}
                            isMobileSize={isMobileSize}
                        />
                    )}

                    <Box sx={{
                        mt: 1,
                        height: '4px',
                        borderRadius: '2px',
                        backgroundColor: 'primary_v2.shades60',
                        position: 'relative',
                    }}
                    >
                        <Box sx={{
                            height: '4px',
                            borderRadius: '2px',
                            backgroundColor: isCompleted ? 'success_v2.light' : 'primary_v2.main',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: `${percents}%`,
                        }}
                        />
                    </Box>

                    {selectedInvoicesForPaidInfo?.length > 0 && (
                    <Box sx={{
                        mt: 2,
                        display: 'flex',
                        maxWidth: '525px',
                        gap: 1,
                        flexWrap: 'wrap',
                    }}
                    >
                        {selectedInvoicesForPaidInfo.map(invoice => {
                            return (
                                <Chip
                                    key={invoice?.id}
                                    onDelete={() => onSelectInvoice(invoice.invoiceId)}
                                    color="primary"
                                    label={`${invoice?.label}`}
                                />
                            );
                        })}
                    </Box>
                    )}
                </Box>
            </Box>
        </Grow>
    );
};

CreditInvoicePanelInfo.propTypes = {
    amount: PropTypes.number.isRequired,
    allocated: PropTypes.number.isRequired,
    onOpenInvoiceMatching: PropTypes.func.isRequired,
    selectedInvoicesForPaidInfo: PropTypes.array,
    onSelectInvoice: PropTypes.func.isRequired,
};

CreditInvoicePanelInfo.defaultProps = {
    selectedInvoicesForPaidInfo: [],
};

const CreditFactureButton = ({
    isCompleted,
    onOpenInvoiceMatching,
    isMobileSize,
    t,
}) => {
    return (
        <Box sx={isMobileSize ? {
            display: 'flex',
            mb: 2.5,
            justifyContent: 'flex-end',
        } : {
            display: 'inline',
            float: 'right',
            position: 'relative',
            bottom: '10px',
        }}
        >
            {isCompleted ? (
                <Button
                    onClick={onOpenInvoiceMatching}
                    startIcon={<EditIcon />}
                >
                    {t(`categorisation.facturePanel.editButton`)}
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color="info"
                    size="small"
                    sx={{
                        py: 0.5,
                        mb: -1.5,
                    }}
                    onClick={onOpenInvoiceMatching}
                    startIcon={<AddBoxIcon />}
                >
                    {t(`categorisation.facturePanel.addButton`)}
                </Button>
            )}
        </Box>
    );
};

CreditFactureButton.propTypes = {
    isCompleted: PropTypes.bool.isRequired,
    onOpenInvoiceMatching: PropTypes.func.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

export default CreditFacturePanel;
