import {coreApiInstance} from '../../../lib/api-providers/core-api-instance';

const putSignature = ({freelancerId, file, eventId}) => {
    const formData = new FormData();

    formData.append('file', file);

    if (eventId) {
        formData.append('eventId', eventId);
    }

    return coreApiInstance
        .post(`/api/v2/users/${freelancerId}/signatures`, formData, {
            header: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(result => result.data);
};

const getSignature = ({freelancerId}) => {
    return coreApiInstance
        .get(`/api/v2/users/${freelancerId}/signatures/latest`)
        .then(result => result.data);
};

export const SignatureApi = {
    putSignature,
    getSignature,
};
