export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const GO_TO_PREVIOUS_STEP = 'GO_TO_PREVIOUS_STEP';
export const SET_IS_LOADING_SETUP_COMPANY_FORM = 'SET_IS_LOADING_SETUP_COMPANY_FORM';
export const SUBMIT_ACTIVE_STEP_FORM = 'SUBMIT_ACTIVE_STEP_FORM';
export const SUBMIT_IBAN_BIC = 'SUBMIT_IBAN_BIC';
export const SUBMIT_REG_BANK_INFO_AND_KBIS = 'SUBMIT_REG_BANK_INFO_AND_KBIS';
export const CAPITAL_DEPOSIT_GENERATE_SECOND_BATCH_DOCUMENTS = 'CAPITAL_DEPOSIT_GENERATE_SECOND_BATCH_DOCUMENTS';

export const setActiveStep = activeStep => ({
    type: SET_ACTIVE_STEP,
    payload: activeStep,
});

export const goToPreviousStep = () => ({
    type: GO_TO_PREVIOUS_STEP,
});

export const setIsLoadingSetupCompanyForm = isLoading => ({
    type: SET_IS_LOADING_SETUP_COMPANY_FORM,
    payload: isLoading,
});

export const submitActiveStepForm = (formData, isDirty, dirtyFields, isSubmittingForApproval) => ({
    type: SUBMIT_ACTIVE_STEP_FORM,
    payload: {
        formData,
        isDirty,
        dirtyFields,
        isSubmittingForApproval,
    },
});

export const submitIbanBic = (formData, to) => ({
    type: SUBMIT_IBAN_BIC,
    payload: {
        formData,
        to,
    },
});

export const submitRegistrationBankInfoAndKbis = (formData, to) => ({
    type: SUBMIT_REG_BANK_INFO_AND_KBIS,
    payload: {
        formData,
        to,
    },
});

export const capitalDepositGenerateSecondBatchDocuments = () => ({
    type: CAPITAL_DEPOSIT_GENERATE_SECOND_BATCH_DOCUMENTS,
});
