import FilterListIcon from '@mui/icons-material/FilterList';
import GetAppIcon from '@mui/icons-material/GetApp';
import {Badge, Box, IconButton, Typography} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useDataTableHeaderStyles} from './styles';
import {requiredIf} from '../../../utils/conditional-prop-type';
import ButtonWithCircularProgress from '../ButtonWithCircularProgress';

const DataTableHeader = props => {
    const {
        hasColumnFilters,
        onColumnFilterToggleClick,
        children,
        hasDownloadAsZipButton,
        isDownloadAsZipButtonDisabled,
        isDownloadingAsZip,
        onDownloadAsZipButtonClick,
        additionalFilters,
        hasSearch,
        hasCheckboxes,
        areColumnFiltersOpen,
        title,
        titleCount,
    } = props;

    const styles = useDataTableHeaderStyles();
    const {t} = useTranslation('invoicing');

    const handleColumnFilterToggleClick = () => {
        onColumnFilterToggleClick();
    };

    const hasFilters = hasDownloadAsZipButton || !!additionalFilters || hasColumnFilters;

    return (
        <section
            className={clsx(styles.root, {
                [styles.withCheckboxes]: hasCheckboxes,
            })}
        >
            {title && (
                <Typography className={styles.title}>
                    {title}
                    {titleCount !== 0 && (
                        <Badge
                            badgeContent={titleCount}
                            color="v2Grey300Palette"
                            sx={{
                                color: 'white !important',
                                ml: 2.5,
                                fontWeight: 500,
                            }}
                        />

                    )}
                </Typography>
            )}

            {hasSearch && (
                <Box sx={{
                    width: hasFilters ? '25%' : '40%',
                    textAlign: title ? 'right' : 'left',
                }}
                >
                    {children}
                </Box>
            )}

            {hasFilters && (
                <div className={styles.actionContainer}>
                    {hasDownloadAsZipButton && (
                        <ButtonWithCircularProgress
                            buttonProps={{
                                variant: 'contained',
                                size: 'small',
                                onClick: onDownloadAsZipButtonClick,
                                startIcon: <GetAppIcon />,
                                disabled: isDownloadingAsZip || isDownloadAsZipButtonDisabled,
                            }}
                            isLoading={isDownloadingAsZip}
                        >
                            {t('common:downloadAsZipButtonLabel')}
                        </ButtonWithCircularProgress>
                    )}

                    {!!additionalFilters && additionalFilters}

                    {hasColumnFilters && (
                        <IconButton
                            aria-label="display-filters"
                            onClick={handleColumnFilterToggleClick}
                            size="large"
                        >
                            <FilterListIcon htmlColor={areColumnFiltersOpen ? '#3779BE' : undefined} />
                        </IconButton>
                    )}
                </div>
            )}
        </section>
    );
};

DataTableHeader.propTypes = {
    hasColumnFilters: PropTypes.bool,
    hasDownloadAsZipButton: PropTypes.bool,
    isDownloadAsZipButtonDisabled: PropTypes.bool,
    isDownloadingAsZip: PropTypes.bool,
    hasSearch: PropTypes.bool,
    hasCheckboxes: PropTypes.bool,
    areColumnFiltersOpen: PropTypes.bool,
    additionalFilters: PropTypes.node,
    // eslint-disable-next-line react/require-default-props
    onColumnFilterToggleClick: requiredIf(
        props => props.hasColumnFilters && typeof props.onColumnFilterToggleClick !== 'function',
        '"onColumnFilterToggleClick" must be a function if "hasColumnFilters" is true"',
    ),
    // eslint-disable-next-line react/require-default-props
    onDownloadAsZipButtonClick: requiredIf(
        props => props.hasDownloadAsZipButton && typeof props.onDownloadAsZipButtonClick !== 'function',
        '"onDownloadAsZipButtonClick" must be a function if "hasDownloadAsZipButton" is true"',
    ),
    title: PropTypes.string.isRequired,
    titleCount: PropTypes.number.isRequired,
};

DataTableHeader.defaultProps = {
    hasColumnFilters: false,
    hasDownloadAsZipButton: false,
    isDownloadAsZipButtonDisabled: true,
    isDownloadingAsZip: false,
    hasCheckboxes: false,
    areColumnFiltersOpen: false,
    hasSearch: false,
    additionalFilters: null,
};

export default DataTableHeader;
