import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EuroTwoToneIcon from '@mui/icons-material/EuroTwoTone';
import {
    Box, Button,
    Divider,
    FormControlLabel,
    Paper, Select,
    Switch,
    Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {ButtonAction} from '../../../../../../components/buttons/ButtonAction';
import Yup from '../../../../../../lib/yup/yup';

export const spendingLimitPeriodEnum = {
    monthly: 'MONTH',
    weekly: 'WEEK',
    daily: 'DAY',
    always: 'ALWAYS',
};

export const formElements = {
    'spendingLimitAmount': 'spendingLimitAmount',
    'spendingLimitCurrency': 'spendingLimitCurrency',
    'spendingLimitPeriod': 'spendingLimitPeriod',
    'eCommerce': 'eCommerce',
    'withdrawal': 'withdrawal',
    'international': 'international',
    'nonMainCurrencyTransactions': 'nonMainCurrencyTransactions',
};

export const bankCardSettingsDefaultValues = {
    spendingLimitAmount: '',
    spendingLimitCurrency: 'EUR',
    spendingLimitPeriod: spendingLimitPeriodEnum.monthly,
    eCommerce: true,
    withdrawal: true,
    international: true,
    nonMainCurrencyTransactions: true,
};

export const createBankCardSettingsValidation = t => Yup.object().shape({
    [formElements.spendingLimitAmount]: Yup.number().typeError(t('form:validation.required', {
        fieldName: t('cardCreate.limitLabel'),
    })).positive(t('form:validation/v2.number/positive', {
        label: t('cardCreate.limitLabel'),
    })),
});

const BankCardSettings = ({
    t,
    setCardSettings,
    setShowCardCreation,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const onCancel = () => {
        setShowCardCreation(false);
    };

    const {
        control,
        handleSubmit: createSubmitHandler,
        formState: {isValid, errors},
    } = useForm({
        resolver: yupResolver(createBankCardSettingsValidation(t)),
        defaultValues: bankCardSettingsDefaultValues,
        mode: 'onChange',
    });

    const handleSubmit = data => {
        setCardSettings(data);
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: isMobileSize ? 'flex-start' : 'center',
            pt: 2,
            height: isMobileSize ? '100%' : '100vh',
        }}
        >
            <form onSubmit={createSubmitHandler(handleSubmit)}>
                <Paper
                    sx={{
                        width: isMobileSize ? 'calc(100vw - 32px)' : '580px',
                        pt: 2,
                        px: isMobileSize ? 2 : 4,
                        pb: isMobileSize ? 2 : 4,
                        borderRadius: '16px',
                    }}
                    elevation={0}
                >
                    <Typography
                        sx={{
                            textAlign: 'center',
                            mb: 3,
                        }}
                        variant={isMobileSize ? 'h5' : 'h4'}
                    >
                        {t('cardCreate.createParamsTitle')}
                    </Typography>

                    <BankCardForm
                        t={t}
                        control={control}
                        onCancel={onCancel}
                        errors={errors}
                        isMobileSize={isMobileSize}
                        isValid={isValid}
                    />
                </Paper>
            </form>
        </Box>
    );
};

BankCardSettings.propTypes = {
    t: PropTypes.func.isRequired,
    setShowCardCreation: PropTypes.func.isRequired,
    setCardSettings: PropTypes.func.isRequired,
};

export const BankCardForm = ({
    t,
    control,
    onCancel,
    errors,
    isValid,
    isMobileSize,
}) => {
    return (
        <Box>
            <Typography sx={{
                fontWeight: isMobileSize ? 500 : 700,
                fontSize: theme => theme.typography.pxToRem(isMobileSize ? 16 : 21),
                mb: 2,
            }}
            >
                {t('cardCreate.paymentLimits')}
            </Typography>

            <Box sx={{
                display: 'flex',
                gap: isMobileSize ? 0 : 2,
                flexDirection: !isMobileSize ? 'row' : 'column',
                mb: isMobileSize ? 3 : 0,
            }}
            >
                <Box sx={{
                    width: '100%',
                }}
                >
                    <Controller
                        control={control}
                        id={formElements.spendingLimitAmount}
                        name={formElements.spendingLimitAmount}
                        render={({field}) => (
                            <NumberFormat
                                {...field}
                                customInput={TextField}
                                error={!!errors[formElements.spendingLimitAmount]}
                                helperText={errors[formElements.spendingLimitAmount]?.message}
                                decimalSeparator="."
                                thousandSeparator={false}
                                allowNegative={false}
                                decimalScale={2}
                                sx={{
                                    mb: 2,
                                    background: 'white',
                                }}
                                fullWidth
                                label={t('cardCreate.limitLabel')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <EuroTwoToneIcon sx={{color: 'v2.light.action.active'}} />,
                                }}
                            />
                        )}
                    />
                </Box>

                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Controller
                        control={control}
                        name={formElements.spendingLimitPeriod}
                        render={({field}) => {
                            return (
                                <Select
                                    {...field}
                                    variant="outlined"
                                    error={!!errors[formElements.spendingLimitPeriod]}
                                    fullWidth
                                    MenuProps={{
                                        disableScrollLock: true,
                                    }}
                                >
                                    <MenuItem
                                        value={spendingLimitPeriodEnum.daily}
                                        key={spendingLimitPeriodEnum.daily}
                                    >
                                        {t(`cardCreate.${spendingLimitPeriodEnum.daily}`)}
                                    </MenuItem>
                                    <MenuItem
                                        value={spendingLimitPeriodEnum.weekly}
                                        key={spendingLimitPeriodEnum.weekly}
                                    >
                                        {t(`cardCreate.${spendingLimitPeriodEnum.weekly}`)}
                                    </MenuItem>
                                    <MenuItem
                                        value={spendingLimitPeriodEnum.monthly}
                                        key={spendingLimitPeriodEnum.monthly}
                                    >
                                        {t(`cardCreate.${spendingLimitPeriodEnum.monthly}`)}
                                    </MenuItem>
                                    <MenuItem
                                        value={spendingLimitPeriodEnum.always}
                                        key={spendingLimitPeriodEnum.always}
                                    >
                                        {t(`cardCreate.${spendingLimitPeriodEnum.always}`)}
                                    </MenuItem>
                                </Select>
                            );
                        }}
                    />
                </Box>
            </Box>

            <Divider
                sx={{
                    my: 2,
                }}
            />

            <Box sx={{mt: isMobileSize ? 3 : 4}}>
                <Typography sx={{
                    fontWeight: isMobileSize ? 500 : 700,
                    fontSize: theme => theme.typography.pxToRem(isMobileSize ? 16 : 21),
                    mb: 2,
                }}
                >
                    {t('cardCreate.paymentTypeLimitsHeader')}
                </Typography>
                <Box sx={{mt: 0}}>
                    <Controller
                        control={control}
                        name={formElements.eCommerce}
                        render={({field}) => (
                            <FormControlLabel
                                sx={{
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}
                                labelPlacement="start"
                                control={<Switch {...field} checked={field.value} />}
                                label={t('cardCreate.eCommerce')}
                            />
                        )}
                    />
                </Box>

                <Box sx={{mt: 1}}>
                    <Controller
                        control={control}
                        name={formElements.withdrawal}
                        render={({field}) => (
                            <FormControlLabel
                                sx={{
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}
                                labelPlacement="start"
                                control={<Switch {...field} checked={field.value} />}
                                label={t('cardCreate.withdrawal')}
                            />
                        )}
                    />
                </Box>

                <Box sx={{mt: 1}}>
                    <Controller
                        control={control}
                        name={formElements.international}
                        render={({field}) => (
                            <FormControlLabel
                                sx={{
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}
                                labelPlacement="start"
                                control={<Switch {...field} checked={field.value} />}
                                label={t('cardCreate.international')}
                            />
                        )}
                    />
                </Box>

                <Box sx={{mt: 1}}>
                    <Controller
                        control={control}
                        name={formElements.nonMainCurrencyTransactions}
                        render={({field}) => (
                            <FormControlLabel
                                sx={{
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}
                                labelPlacement="start"
                                control={<Switch {...field} checked={field.value} />}
                                label={t('cardCreate.nonMainCurrency')}
                            />
                        )}
                    />
                </Box>

                <Divider
                    sx={{
                        mt: 2,
                    }}
                />
            </Box>

            <Box sx={{
                display: 'flex',
                width: '100%',
                gap: isMobileSize ? 2 : 3,
                mt: 3,
                flexDirection: isMobileSize ? 'column-reverse' : 'row',
            }}
            >
                <Button
                    type="button"
                    variant="contained"
                    color="grey"
                    fullWidth
                    startIcon={<ArrowBackIcon />}
                    onClick={onCancel}
                >
                    {t('cardCreate.cancel')}
                </Button>

                <ButtonAction
                    color="secondary"
                    type="submit"
                    fullWidth
                    endIcon={<ArrowForwardIcon />}
                    disabled={!isValid}
                >
                    {t('cardCreate.confirm')}
                </ButtonAction>
            </Box>
        </Box>
    );
};

BankCardForm.propTypes = {
    t: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
};

export default BankCardSettings;
