import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import {Box, IconButton, Paper, Typography} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import QRCode from 'react-qr-code';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import {CONFIG} from '../../../config';
import TransactionDetailsCloseSection
    from '../modules/account-balance/components/transaction-details/TransactionDetailsCloseSection';
import {BankActions} from '../store/bank.action';
import {BankSelector} from '../store/bank.selector';

const BankQr = ({id, bankAccountId, type, onClose}) => {
    const dispatch = useDispatch();

    const [waitingEvent, setWaitingEvent] = useState(null);

    const {t: tBankTransfer} = useTranslation('bankTransfer');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const baseUrl = CONFIG.WEB_BASE_URL;

    const eventId = useSelector(BankSelector.selectQrCodeEvent);

    useEffect(() => {
        const eventId = uuidv4();
        dispatch(BankActions.addUploadFileQrCodeEvent(eventId));
        setWaitingEvent(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!eventId && waitingEvent) {
            onClose();
        }
        // eslint-disable-next-line
    }, [eventId]);

    if (isMobileSize) {
        return (
            <Drawer
                anchor="bottom"
                open={true}
                onClose={onClose}
                sx={{
                    '.MuiDrawer-paperAnchorBottom': {
                        borderTopRightRadius: '32px',
                        borderTopLeftRadius: '32px',
                    },
                }}
            >
                <Box sx={{
                    minHeight: '300px',
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <Paper
                        elevation={1}
                        sx={{
                            height: !isMobileSize ? '100vh' : '100%',
                            width: isMobileSize ? '100%' : '400px',
                        }}
                    >
                        <Box sx={{display: 'flex', flexDirection: 'column', px: 3}}>
                            <Box sx={{
                                position: 'absolute',
                                right: '20px',
                                top: '20px',
                            }}
                            >
                                <IconButton aria-label="close" onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                py: 2,
                                pt: 4,
                            }}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{
                                        mb: 1,
                                        width: '262px',
                                    }}
                                >
                                    {tBankTransfer('qr.title')}
                                </Typography>
                                <Typography sx={{
                                    width: '262px',
                                    fontSize: theme => theme.typography.pxToRem(14),
                                    color: 'v2.light.text.disabled',
                                }}
                                >
                                    {tBankTransfer('qr.text')}
                                </Typography>
                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 2,
                                }}
                                >
                                    {bankAccountId && baseUrl && eventId && (
                                        <Box>
                                            <QRCode
                                                size={180}
                                                style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                                                value={type === 'transfer'
                                                    ? `${baseUrl}account/${bankAccountId}/transfer/${id}?eventId=${eventId}`
                                                    : `${baseUrl}account/${bankAccountId}/transaction/${id}?eventId=${eventId}`}
                                                viewBox="0 0 180 180"
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Drawer>
        );
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0px 11px 15px -7px rgba(11, 35, 51, 0.08), 0px 24px 38px 3px rgba(11, 35, 51, 0.08), 0px 9px 46px 8px rgba(11, 35, 51, 0.1);',
            zIndex: 222,
        }}
        >
            <TransactionDetailsCloseSection
                isMobileSize={isMobileSize}
                onClose={onClose}
                isOpen
            />
            <Paper
                elevation={1}
                sx={{
                    height: '100vh',
                    width: '400px',
                    textAlign: 'center',
                }}
            >
                <Box
                    sx={{
                        mb: 6,
                        p: 2,
                        pt: 3,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        cursor: 'pointer',
                    }}
                    onClick={onClose}
                >
                    <ArrowBackIcon />
                    <Typography>{tBankTransfer(`qr.${type}`)}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            mb: 1,
                            width: '262px',
                        }}
                    >
                        {tBankTransfer('qr.title')}
                    </Typography>
                    <Typography sx={{
                        width: '262px',
                        fontSize: theme => theme.typography.pxToRem(14),
                        color: 'v2.light.text.disabled',
                    }}
                    >
                        {tBankTransfer('qr.text')}
                    </Typography>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                    }}
                    >
                        {bankAccountId && baseUrl && eventId && (
                            <Box>
                                <QRCode
                                    size={180}
                                    style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                                    value={type === 'transfer'
                                        ? `${baseUrl}account/${bankAccountId}/transfer/${id}?eventId=${eventId}`
                                        : `${baseUrl}account/${bankAccountId}/transaction/${id}?eventId=${eventId}`}
                                    viewBox="0 0 180 180"
                                />
                            </Box>
                        )}
                    </Box>
                </Box>

            </Paper>
        </Box>
    );
};

BankQr.propTypes = {
    id: PropTypes.string.isRequired,
    bankAccountId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default BankQr;
