import {actionChannel, all, fork, put, select, take, takeEvery} from 'redux-saga/effects';
import {AnimationActions} from './animations.action';
import {AnimationActionTypes} from './animations.action-type';
import {AnimationsSelectors} from './animations.selector';
import {ANIMATION_TYPE} from '../utils/constants';

const animationEndedWorker = function* () {
    const channel = yield actionChannel(AnimationActionTypes.ANIMATION_ENDED);

    while (true) {
        const {payload} = yield take(channel);

        const isAnimationActive = yield select(AnimationsSelectors.selectIsAnimationActive);
        if (payload === ANIMATION_TYPE.MAIN) {
            if (!isAnimationActive) {
                const nextDispatch = yield select(AnimationsSelectors.selectNextDispatch);

                if (nextDispatch) {
                    yield put(nextDispatch);
                    yield put(AnimationActions.storeNextDispatch(null));
                }

                yield put(AnimationActions.setIsAnimationActive(true));
            } else {
                yield put(AnimationActions.setIsSubAnimationActive(true));
            }
        }

        if (payload === ANIMATION_TYPE.SECONDARY) {
            const isSubAnimationActive = yield select(AnimationsSelectors.selectIsSubAnimationActive);

            if (!isSubAnimationActive) {
                yield put(AnimationActions.setIsAnimationActive(false));
            }
        }
    }
};

// TODO This is mobile quickfix
const animationNextDispatchWorker = function* ({payload}) {
    const isMobile = yield select(AnimationsSelectors.selectIsMobile);

    if (isMobile && payload && payload.type.indexOf('training') === -1) {
        yield put(payload);
    }
};

export const watchAnimationsSaga = function* () {
    yield all([
        // takeEvery(AnimationActionTypes.ANIMATION_ENDED, animationEndedWorker),
        takeEvery(AnimationActionTypes.STORE_NEXT_DISPATCH, animationNextDispatchWorker),
        fork(animationEndedWorker),
    ]);
};
