import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans} from 'react-i18next';

export const CategorisationFooterTitle = ({nonCategorized, t, isMobileSize}) => (
    <Typography sx={{
        fontWeight: 400,
        letterSpacing: '0.15px',
        fontSize: theme => theme.typography.pxToRem(isMobileSize ? 14 : 16),
        lineHeight: theme => theme.typography.pxToRem(isMobileSize ? 16 : 24),
        color: 'text_v2.secondary',
        mt: isMobileSize ? -1.5 : 0,
    }}
    >
        <Trans
            t={t}
            i18nKey={isMobileSize
                ? 'categorisation.footer.titleMobile'
                : 'categorisation.footer.title'
            }
            values={{
                nonCategorized,
            }}
            components={{
                bold: (
                    <Box component="span" sx={{fontWeight: 500}} />
                ),
            }}
        />
    </Typography>
);

CategorisationFooterTitle.propTypes = {
    nonCategorized: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    isMobileSize: PropTypes.bool,
};

CategorisationFooterTitle.defaultProps = {
    isMobileSize: false,
};
