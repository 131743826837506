import {SvgIcon} from '@mui/material';
import PropTypes from 'prop-types';

export const DailyIcon = props => {
    return (
        <SvgIcon width="25" height="28" viewBox="0 0 25 28" {...props}>
            <path d="M8.26667 19.6667C7.35556 19.6667 6.58889 19.3556 5.96667 18.7334C5.34444 18.1112 5.03333 17.3445 5.03333 16.4334C5.03333 15.5223 5.34444 14.7556 5.96667 14.1334C6.58889 13.5112 7.35556 13.2001 8.26667 13.2001C9.17778 13.2001 9.94444 13.5112 10.5667 14.1334C11.1889 14.7556 11.5 15.5223 11.5 16.4334C11.5 17.3445 11.1889 18.1112 10.5667 18.7334C9.94444 19.3556 9.17778 19.6667 8.26667 19.6667ZM2.5 27.3334C1.96667 27.3334 1.5 27.1334 1.1 26.7334C0.7 26.3334 0.5 25.8667 0.5 25.3334V4.66675C0.5 4.13341 0.7 3.66675 1.1 3.26675C1.5 2.86675 1.96667 2.66675 2.5 2.66675H4.66667V0.666748H6.83333V2.66675H18.1667V0.666748H20.3333V2.66675H22.5C23.0333 2.66675 23.5 2.86675 23.9 3.26675C24.3 3.66675 24.5 4.13341 24.5 4.66675V25.3334C24.5 25.8667 24.3 26.3334 23.9 26.7334C23.5 27.1334 23.0333 27.3334 22.5 27.3334H2.5ZM2.5 25.3334H22.5V11.0001H2.5V25.3334ZM2.5 9.00008H22.5V4.66675H2.5V9.00008ZM2.5 9.00008V4.66675V9.00008Z" fill={props?.sx?.fill ?? '#607D8B'} />
        </SvgIcon>
    );
};

DailyIcon.propTypes = {
    sx: PropTypes.object,
};

DailyIcon.defaultProps = {
    sx: {},
};
