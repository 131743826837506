import Edit from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const EditButtonSmall = ({onClick}) => {
    const {t: tUsers} = useTranslation('users');
    return (
        <Box component="div" sx={{display: 'flex', justifyContent: 'flex-end', px: 3, py: 2}}>
            <Button
                color="primary"
                variant="contained"
                size="small"
                startIcon={<Edit />}
                onClick={onClick}
                sx={{
                    borderRadius: '6px',
                    fontWeight: '500',
                    fontSize: theme => theme.typography.pxToRem(13),
                    lineHeight: theme => theme.typography.pxToRem(22),
                    px: 1,
                    py: 0.5,
                }}
            >
                {tUsers('common:edit')}
            </Button>
        </Box>
    );
};

EditButtonSmall.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default EditButtonSmall;
