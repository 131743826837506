import {
    faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Button, ButtonGroup, FormControl, Stack, Typography} from '@mui/joy';
import isEmpty from 'lodash.isempty';
import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AdditionalAdministratorFields} from './AdditionalAdministratorFields';
import {AdditionalCareFields} from './AdditionalCareFields';
import {AdditionalFreelancerFields} from './AdditionalFreelancerFields';
import {RequiredFields} from './RequiredFields';
import withJoyUI from '../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemePRoviderWrapper';
import Collapse from '../../../../../components/joy-ui/mui-legacy/Collapse';
import StatusSection from '../../../../../components/joy-ui/sections/StatusSection';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';
import Yup from '../../../../../lib/yup/yup';
import {UserRoleColors, UserRoleContrastColors, UserRoleIcons, UserRoles} from '../../../../../utils/user-roles';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {SidebarSelectors} from '../../../../sidebar/store/sidebar.selector';
import {CreateUserFormActions} from '../store/create-user-form.action';

const buttonStyles = {
    py: 2,
    px: 4,
    justifyContent: 'center',
    borderColor: 'primary.outlinedBorder',
    flex: 1,
};

const createValidationSchema = t => Yup.object().shape({
    role: Yup.string().required().label(t('role')),
    firstName: Yup.string()
        .noSpecialCharactersInUserName(t('form:validation/v2.string/noSpecialCharacters'))
        .required()
        .label(t('firstName')),
    lastName: Yup.string()
        .noSpecialCharactersInUserName(t('form:validation/v2.string/noSpecialCharacters'))
        .required()
        .label(t('lastName')),
    email: Yup.string().required().email().label(t('email')),
    phone: Yup.string().required().phone().label(t('phone')),
    coach: Yup.object().shape({
        id: Yup.string().uuid().required(),
        name: Yup.string().required(),
    }).when('role', {
        is: role => role !== UserRoles.ADMINISTRATOR,
        then: Yup.object().required(),
        otherwise: Yup.object().nullable(),
    })
        .label('Coach'),
    taxSystem: Yup.string().when('role', {
        is: role => role === UserRoles.CARE,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable(),
    }),
    jobType: Yup.string().when('role', {
        is: role => role === UserRoles.CARE,
        then: Yup.string().required().label(t('jobType')),
        otherwise: Yup.string().nullable(),
    }),
    contactSource: Yup.string().nullable().label(t('contactSource')),
    startContractType: Yup.string().nullable().label(t('startContractType')),
    lastDayOfWork: Yup.string().nullable().label(t('lastDayOfWork')),
    contractStatus: Yup.string().label(t('contractStatus')),
    estimatedRegistrationDate: Yup.string().nullable().label(t('estimatedRegistrationDate')),
    registrationDate: Yup.string().nullable().label(t('registrationDate')),
    city: Yup.string().max(50).nullable().label(t('city')),
    note: Yup.string().max(1000).label(t('note')),
    confirmAdministratorCreation: Yup.string().when('role', {
        is: role => role === UserRoles.ADMINISTRATOR,
        then: Yup.string().required().matches(/ADMIN/).label(t('confirmAdministratorCreation')),
    }),
});

export const CreateUserForm = withJoyUI(() => {
    const {t} = useTranslation('createUser');
    const isMobileSize = useIsMobileSize();
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);

    const sidebarRole = useSelector(SidebarSelectors.selectSelectedRole);

    const {
        control,
        handleSubmit: createSubmitHandler,
        formState: {errors},
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(createValidationSchema(t)),
        defaultValues: {
            role: !Array.isArray(sidebarRole) ? sidebarRole : UserRoles.FREELANCER,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            coach: null,
            taxSystem: '',
            jobType: '',
            contactSource: null,
            startContractType: null,
            lastDayOfWork: null,
            contractStatus: '',
            estimatedRegistrationDate: null,
            registrationDate: null,
            city: null,
            note: '',
            confirmAdministratorCreation: '',
        },
        mode: 'onTouched',
    });

    const role = watch('role');
    const startContractType = watch('startContractType');

    useEffect(() => {
        setValue('taxSystem', null);
        setValue('jobType', null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);

    const handleSubmit = data => {
        setSubmitted(true);
        dispatch(CreateUserFormActions.submit(data));
    };

    const isLoading = useLoadingState(LoadingTypes.CREATE_USER_FORM);

    return (
        <form onSubmit={createSubmitHandler(handleSubmit)} noValidate>
            <Stack
                direction="column"
                spacing={2}
            >
                <Typography level="h1">
                    {t('pageTitle')}
                </Typography>

                <Stack
                    direction={isMobileSize ? 'column' : 'row'}
                    spacing={isMobileSize ? 1 : 2}
                    alignItems={isMobileSize ? 'flex-start' : 'center'}
                    borderRadius="xl"
                    sx={{
                        p: 2,
                        backgroundColor: 'neutral.softBg',
                    }}
                >
                    <Typography level="h4">
                        {t('role')}
                    </Typography>
                    <FormControl sx={{flex: 1}}>
                        <Controller
                            control={control}
                            name="role"
                            render={({field}) => (
                                <ButtonGroup
                                    orientation="horizontal"
                                    variant="outlined"
                                    size="md"
                                    color="primary"
                                    row
                                    {...field}
                                    onClick={e => {
                                        setValue('role', e.target.value);
                                    }}
                                >
                                    {Object.values(UserRoles).map(role => (
                                        <Button
                                            key={role}
                                            value={role}
                                            variant="outlined"
                                            color="neutral"
                                            startDecorator={role === field.value && UserRoleIcons[role] && (
                                                <Box
                                                    color={UserRoleContrastColors[role]}
                                                    fontSize="md"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={UserRoleIcons[role]}
                                                        fixedWidth
                                                    />
                                                </Box>
                                            )}
                                            sx={{
                                                ...buttonStyles,
                                                ...(role === field.value
                                                    ? {
                                                        'backgroundColor': UserRoleColors[role],
                                                        'color': UserRoleContrastColors[role],
                                                        '&:hover': {
                                                            'backgroundColor': UserRoleColors[role],
                                                            'color': UserRoleContrastColors[role],
                                                        },
                                                    } : {}),
                                            }}
                                            disabled={isLoading}
                                        >
                                            {t(`roles.${role}`)}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            )}
                        />
                    </FormControl>
                </Stack>

                <StatusSection title={t('userInformation')} isRequired>
                    <RequiredFields
                        control={control}
                        isLoading={isLoading}
                        errors={errors}
                        role={role}
                        isSubmitted={submitted}
                    />
                </StatusSection>

                <StatusSection title={t('additionalInformation')}>
                    <Collapse in={role === UserRoles.FREELANCER} mountOnEnter unmountOnExit>
                        <AdditionalFreelancerFields
                            control={control}
                            isLoading={isLoading}
                            errors={errors}
                            startContractType={startContractType}
                        />
                    </Collapse>

                    <Collapse in={role === UserRoles.CARE} mountOnEnter unmountOnExit>
                        <AdditionalCareFields
                            control={control}
                            isLoading={isLoading}
                            startContractType={startContractType}
                            errors={errors}
                        />
                    </Collapse>

                    <Collapse in={role === UserRoles.ADMINISTRATOR} mountOnEnter unmountOnExit>
                        <AdditionalAdministratorFields
                            control={control}
                            isLoading={isLoading}
                            errors={errors}
                        />
                    </Collapse>
                </StatusSection>

                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        size="lg"
                        color="primary"
                        type="submit"
                        variant="solid"
                        loading={isLoading}
                        disabled={!isEmpty(errors)}
                        loadingPosition="end"
                        endDecorator={(
                            <FontAwesomeIcon icon={faArrowRight} />
                        )}
                    >
                        {t('submitButton')}
                    </Button>
                </Box>
            </Stack>
        </form>
    );
});
