import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {FinancialStatementsActionType} from './financial-statements.action-type';
import {FinancialStatementsActions} from './financial-statements.actions';
import {selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import hiwayLocalStorage from '../../../v1/config/storage';
import {LoadingActions, LoadingTypes} from '../../loading';
import {SidebarSelectors} from '../../sidebar/store/sidebar.selector';
import {FinancialStatementsApi} from '../api/financial-statements.api';
import {SORT_EXCEPTION_COLUMNS} from '../components/columnConfig';
import {FINANCIAL_STATEMENTS_QUERY_PARAMS} from '../utils/consts';

export const getFinancialStatementsFlow = function* () {
    try {
        const location = yield select(selectRouterLocation);
        const selectedRole = yield select(SidebarSelectors.selectSelectedRole);

        const search = location?.query;

        if (!search?.[FINANCIAL_STATEMENTS_QUERY_PARAMS.YEAR]) {
            return;
        }

        const storageKey = 'financialStatements-savedRowsPerPage';
        const limit = search?.['financialStatements-rowsPerPage']
            ?? (hiwayLocalStorage.has(storageKey) ? parseInt(hiwayLocalStorage.get(storageKey), 10) : 10);

        const hasSearchTerm = !!search?.[FINANCIAL_STATEMENTS_QUERY_PARAMS.SEARCH_TERM];
        const shouldIncludeSorting = search?.[FINANCIAL_STATEMENTS_QUERY_PARAMS.SORT_BY]
            !== SORT_EXCEPTION_COLUMNS.finalFecStatus
            && search?.[FINANCIAL_STATEMENTS_QUERY_PARAMS.SORT_BY]
            !== SORT_EXCEPTION_COLUMNS.annualAccountStatus;

        const params = {
            limit,
            roles: [selectedRole],

            // Add search queries
            ...(search && {
                year: search[FINANCIAL_STATEMENTS_QUERY_PARAMS.YEAR],
                searchQuery: search[FINANCIAL_STATEMENTS_QUERY_PARAMS.SEARCH_TERM],
                quickFilter: search[FINANCIAL_STATEMENTS_QUERY_PARAMS.QUICK_FILTER],
                offset: search[FINANCIAL_STATEMENTS_QUERY_PARAMS.PAGE]
                    ? parseInt(search[FINANCIAL_STATEMENTS_QUERY_PARAMS.PAGE], 10) * limit
                    : 0,
                ...(hasSearchTerm ? {
                    // We exclude sorting by these columns in case when we have
                    // serach term as well due to current BE limitation.
                    ...(shouldIncludeSorting && {
                        sortBy: search[FINANCIAL_STATEMENTS_QUERY_PARAMS.SORT_BY]
                            ? search[FINANCIAL_STATEMENTS_QUERY_PARAMS.SORT_BY]
                            : undefined,
                        sortOrder: search?.[FINANCIAL_STATEMENTS_QUERY_PARAMS.SORT_BY]
                            ? search[FINANCIAL_STATEMENTS_QUERY_PARAMS.SORT_DIRECTION]
                                ? search[FINANCIAL_STATEMENTS_QUERY_PARAMS.SORT_DIRECTION].toUpperCase()
                                : 'DESC'
                            : undefined,
                    }),
                } : {
                    sortBy: search[FINANCIAL_STATEMENTS_QUERY_PARAMS.SORT_BY]
                        ? search[FINANCIAL_STATEMENTS_QUERY_PARAMS.SORT_BY]
                        : undefined,
                    sortOrder: search?.[FINANCIAL_STATEMENTS_QUERY_PARAMS.SORT_BY]
                        ? search[FINANCIAL_STATEMENTS_QUERY_PARAMS.SORT_DIRECTION]
                            ? search[FINANCIAL_STATEMENTS_QUERY_PARAMS.SORT_DIRECTION].toUpperCase()
                            : 'DESC'
                        : undefined,
                }),
            }),
        };

        yield put(LoadingActions.setLoading(LoadingTypes.GET_ANNUAL_ACCOUNTS, true));

        const response = yield call(FinancialStatementsApi.getFinancialStatements, params);

        yield put(FinancialStatementsActions.storeFinancialStatements(response));
    } catch (e) {
        Debug.error('financial statements getFinancialStatementsFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_ANNUAL_ACCOUNTS, false));
    }
};

export const getFinancialStatementsWorker = function* () {
    yield call(getFinancialStatementsFlow);
};

export const watchFinancialStatementsSaga = function* () {
    yield all([
        takeEvery(FinancialStatementsActionType.GET_FINANCIAL_STATEMENTS, getFinancialStatementsWorker),
    ]);
};
