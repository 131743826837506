import {SvgIcon} from '@mui/material';

export const CakeIcon = props => (
    <SvgIcon viewBox="0 0 49 48" {...props}>
        <path
            d="M8.33301 44C7.76634 44 7.29134 43.8083 6.90801 43.425C6.52467 43.0417 6.33301 42.5667 6.33301 42V31.05C6.33301 30.225 6.62676 29.5187 7.21426 28.9312C7.80176 28.3438 8.50801 28.05 9.33301 28.05H10.683V18.95C10.683 18.125 10.9768 17.4187 11.5643 16.8313C12.1518 16.2438 12.858 15.95 13.683 15.95H22.833V12.75C22.1663 12.2833 21.658 11.7744 21.308 11.2233C20.958 10.6722 20.783 10.0075 20.783 9.22945C20.783 8.74315 20.8747 8.275 21.058 7.825C21.2413 7.375 21.4997 6.98333 21.833 6.65L24.333 4L26.833 6.65C27.1663 6.98333 27.433 7.375 27.633 7.825C27.833 8.275 27.933 8.74315 27.933 9.22945C27.933 10.0075 27.7497 10.6722 27.383 11.2233C27.0163 11.7744 26.4997 12.2833 25.833 12.75V15.95H34.983C35.808 15.95 36.5143 16.2438 37.1018 16.8313C37.6893 17.4187 37.983 18.125 37.983 18.95V28.05H39.333C40.158 28.05 40.8643 28.3438 41.4518 28.9312C42.0393 29.5187 42.333 30.225 42.333 31.05V42C42.333 42.5667 42.1413 43.0417 41.758 43.425C41.3747 43.8083 40.8997 44 40.333 44H8.33301ZM13.683 28.05H34.983V18.95H13.683V28.05ZM9.33301 41H39.333V31.05H9.33301V41Z"
            fill="currentColor"
        />
    </SvgIcon>
);
