/* eslint-disable react/prop-types */
import {faCalendarDay} from '@fortawesome/free-solid-svg-icons';
import {IconButton} from '@mui/joy';
import * as React from 'react';
import {InputIcon} from '../../../../../features/auth/components/common/InputIcon';
import {useMaskedInput} from '../hooks/useMaskedInput';
import {useLocaleText, useUtils} from '../hooks/useUtils';

export const KeyboardDateInput = React.forwardRef((
    props,
    ref,
) => {
    const {
        className,
        components = {},
        disableOpenPicker,
        getOpenDialogAriaText: getOpenDialogAriaTextProp,
        InputAdornmentProps,
        InputProps,
        inputRef,
        openPicker,
        OpenPickerButtonProps,
        renderInput,
        ...other
    } = props;

    const localeText = useLocaleText();

    const getOpenDialogAriaText = getOpenDialogAriaTextProp ?? localeText.openDatePickerDialogue;

    const utils = useUtils();
    const textFieldProps = useMaskedInput(other);
    const adornmentPosition = InputAdornmentProps?.position || 'end';
    const openPickerIcon = components.OpenPickerIcon || faCalendarDay;

    return renderInput({
        ref,
        slotProps: {
            input: {
                ref: inputRef,
            },
        },
        className,
        ...textFieldProps,
        ...InputProps,
        [`${adornmentPosition}Decorator`]: disableOpenPicker ? (
            undefined
        ) : (
            <IconButton
                edge={adornmentPosition}
                data-mui-test="open-picker-from-keyboard"
                disabled={other.disabled || other.readOnly}
                aria-label={getOpenDialogAriaText(other.rawValue, utils)}
                {...OpenPickerButtonProps}
                onClick={openPicker}
            >
                <InputIcon icon={openPickerIcon} />
            </IconButton>
        ),
    });
});
