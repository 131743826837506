import {Box, SvgIcon} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {ReactComponent as Axa} from '../../../assets/svg/axa.svg';

const AxaLogo = () => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: isMobileSize ? '60px' : '72px',
            height: isMobileSize ? '60px' : '72px',
            borderRadius: isMobileSize ? 3 : 4,
            backgroundColor: 'v2.light.whiteSmoke',
            flexShrink: 0,
        }}
        >
            <SvgIcon
                component={Axa}
                inheritViewBox
                sx={{
                    width: isMobileSize ? '34px' : '38px',
                    height: isMobileSize ? '34px' : '38px',
                }}
            />
        </Box>
    );
};

export default AxaLogo;
