import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ConditionalTooltip} from '../../../../components/tooltip/ConditionalTooltip';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {OpportunitiesActions} from '../../store/opportunity.action';

const Actions = ({rowData, isMobile}) => {
    const {t} = useTranslation('opportunity');
    const dispatch = useDispatch();
    const {id, accepted, freelancerBid} = rowData;

    const isApplyInProgress = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.APPLY_OPPORTUNITY),
    );

    const tooltip = accepted ? t('freelancerOpportunity.canNotApply') : '';

    const handleApply = () => {
        dispatch(OpportunitiesActions.applyFromClusterPage({
            opportunityId: id,
        }));
    };

    const handleUnapply = () => {
        dispatch(OpportunitiesActions.unapply({
            opportunityId: id,
        }));
    };

    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <ConditionalTooltip tooltip={tooltip} placement="top">
                <Box>
                    {freelancerBid && !accepted ? (
                        <LoadingButton
                            color="warning"
                            variant="outlined"
                            size={isMobile ? 'small' : 'medium'}
                            startIcon={<RemoveCircleOutlineIcon />}
                            loadingPosition="start"
                            onClick={handleUnapply}
                            loading={isApplyInProgress}
                            sx={{
                                borderRadius: '6px',
                                padding: '4px 10px',
                                textAlign: 'left',
                                width: 'min-content',
                            }}
                        >
                            {t('freelancerOpportunity.unapply')}
                        </LoadingButton>
                    ) : (
                        <LoadingButton
                            color="primary"
                            variant="outlined"
                            size={isMobile ? 'small' : 'medium'}
                            endIcon={<ArrowForwardIcon />}
                            loadingPosition="end"
                            onClick={handleApply}
                            disabled={accepted}
                            loading={isApplyInProgress}
                            sx={{
                                borderRadius: '6px',
                                padding: '4px 10px',
                            }}
                        >
                            {t('freelancerOpportunity.apply')}
                        </LoadingButton>
                    )}

                </Box>
            </ConditionalTooltip>
        </Box>
    );
};

Actions.propTypes = {
    rowData: PropTypes.any.isRequired,
    isMobile: PropTypes.bool,
};

Actions.defaultProps = {
    isMobile: false,
};

export default React.memo(Actions);
