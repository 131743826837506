import {all, call, put, takeLatest} from 'redux-saga/effects';
import * as actions from './coachingInformation.actions';
import {coachInformationGetRequest, coachInformationSubmitRequest} from '../../../../features/user-list/api/user.api';
import {Toast} from '../../../../lib/toast';

export const submitCoachingInformationSaga = function* (action) {
    yield put(actions.setIsLoadingSubmitCoachingInformationForm(true));

    try {
        const {payload} = action;

        const coachingInfo = yield call(coachInformationSubmitRequest, payload.form, payload.userId);

        yield put(actions.setCoachingInformationForm(coachingInfo));

        Toast.success('genericSuccessSave');
    } catch (error) {
        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingSubmitCoachingInformationForm(false));
    }
};

export const getCoachingInformationSaga = function* (action) {
    yield put(actions.setIsLoadingGetCoachingInformationForm(true));

    try {
        const {payload} = action;

        const coachingInfo = yield call(coachInformationGetRequest, payload.userId);

        yield put(actions.setCoachingInformationForm(coachingInfo));
    } catch (error) {
        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingGetCoachingInformationForm(false));
    }
};

export const watchCoachingInformationSagas = function* () {
    yield all([
        takeLatest(actions.SUBMIT_COACHING_INFORMATION_FORM, submitCoachingInformationSaga),
        takeLatest(actions.GET_COACHING_INFORMATION_FORM, getCoachingInformationSaga),
    ]);
};
