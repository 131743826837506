import {Auth} from 'aws-amplify';
import axios from 'axios';
import {CONFIG} from '../config';
import {COOKIE_NAMES, getCookie} from '../utils/cookie';

export const bankApiInstance = axios.create({
    baseURL: CONFIG.API.BANK_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

bankApiInstance.interceptors.request.use(async config => {
    const cookieToken = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);

    if (cookieToken) {
        config.headers.Authorization = `Bearer ${cookieToken}`;
        return config;
    }

    const session = await Auth.currentSession();

    if (!session) {
        return config;
    }

    const accessToken = session.getIdToken().getJwtToken();

    if (!accessToken) {
        return config;
    }

    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
}, error => Promise.reject(error));

bankApiInstance.interceptors.response.use(
    response => {
        // Check if the status code is 200 or 201
        if (response.status === 200 || response.status === 201) {
            // Handle the response as if it's successful
            return response;
        }

        // If the status is not 200 or 201, return the response as it is
        return response;
    },
    error => {
        // Handle the error
        return Promise.reject(error);
    },
);
