import AttachmentIcon from '@mui/icons-material/Attachment';
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {ExternalInvoiceDropZone} from './ExternalInvoiceDropZone';
import {ExternalInvoiceFile} from './ExternalInvoiceFile';
import {Toast} from '../../../../../../../../lib/toast';

// TODO: this component accepts only maxFiles = 1
// update onFileSelect and onFileUnselect if we need it
const maxFiles = 1; // should be 1 only for now
const ExternalInvoiceFileDetails = ({
    fileList,
    setFileList,
    chooseFileLabel,
}) => {
    const [fileMenuOpen, setFileMenuOpen] = useState(null);

    const onFileSelect = files => {
        setFileList(files);
    };

    const onFileUnselect = e => {
        e.stopPropagation();
        setFileList([]);
        setFileMenuOpen(null);
        return false;
    };

    const isEditableWithFileLimit = fileList?.length < maxFiles;

    const onDropRejectedHandler = rejectedFiles => {
        const maxRemainingFiles = maxFiles - (fileList?.length ?? 0);
        // TODO Rework this to match new system
        if (rejectedFiles.length > maxRemainingFiles) {
            Toast.error('tooManyFilesNumber', {translationValues: {number: maxFiles}});
        }
    };

    return (
        <Box>
            <Box>
                {fileList && fileList.map(file => {
                    if (!file || typeof file === 'string') {
                        return null;
                    }

                    return (
                        <ExternalInvoiceFile
                            key={file.name}
                            file={file}
                            fileMenuOpen={fileMenuOpen}
                            setFileMenuOpen={setFileMenuOpen}
                            onDeleteFile={onFileUnselect}
                        />
                    );
                })}
            </Box>


            <Box sx={{
                display: 'flex',
                gap: 2,
            }}
            >
                <ScannerSection />

                <Box sx={{
                    flex: 1,
                    backgroundColor: 'v2.light.primary.shades8',
                    borderRadius: '16px',
                    py: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
                >
                    <Box sx={{textTransform: 'uppercase',
                        fontWeight: 500,
                        fontSize: theme => theme.typography.pxToRem(13)}}
                    >
                        <ExternalInvoiceDropZone
                            fileTypes="application/pdf"
                            maxFiles={maxFiles - (fileList?.length ?? 0)}
                            onChange={onFileSelect}
                            isDisabled={!isEditableWithFileLimit}
                            fileListLength={fileList.length}
                            onDropRejectedHandler={onDropRejectedHandler}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    gap: 0.5,
                                    cursor: isEditableWithFileLimit ? 'pointer' : 'auto',
                                    color: isEditableWithFileLimit ? 'inherit' : 'v2.light.text.disabled',
                                }}
                            >
                                <AttachmentIcon
                                    sx={{
                                        fontSize: '24px',
                                        color: isEditableWithFileLimit ? 'primary_v2.main' : 'v2.light.text.disabled',
                                        mr: 2,
                                    }}
                                />
                                <Typography sx={{
                                    fontSize: theme => theme.typography.pxToRem(12),
                                    fontWeight: 500,
                                    color: '#546E7A',
                                }}
                                >
                                    {chooseFileLabel}
                                </Typography>
                            </Box>
                        </ExternalInvoiceDropZone>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

ExternalInvoiceFileDetails.propTypes = {
    fileList: PropTypes.array.isRequired,
    setFileList: PropTypes.func.isRequired,
    chooseFileLabel: PropTypes.string.isRequired,
};

const ScannerSection = () => {
    return (
        <Box sx={{
            flex: 1,
        }}
        >
            <Box sx={{
                backgroundColor: 'v2.light.primary.shades8',
                borderRadius: '16px',
                px: 3,
                py: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
            >
                <Box sx={{textTransform: 'uppercase',
                    fontWeight: 500,
                    fontSize: theme => theme.typography.pxToRem(13)}}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: 0.5,
                            color: 'v2.light.text.disabled',
                        }}
                    >
                        <CameraAltTwoToneIcon
                            sx={{
                                fontSize: '20px',
                                color: 'v2.light.text.disabled',
                                mr: 2,
                            }}
                        />
                        <Typography sx={{
                            fontSize: theme => theme.typography.pxToRem(12),
                            fontWeight: 500,
                            color: 'v2.blueGray.600',
                        }}
                        >
                            Scanner depuis l’app
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ExternalInvoiceFileDetails;
