import {combineReducers} from 'redux';

import {companyInformationReducer} from './companyInformation/companyInformation.reducer';
import {nonConvictionDeclarationReducer} from './nonConvictionDeclaration/nonConvictionDeclaration.reducer';
import {personalInformationReducer} from './personalInformation/personalInformation.reducer';
import * as actions from './setupCompany.actions';
import {SETUP_COMPANY_STEPS} from './setupCompany.constants';
import {createReducer} from '../../createReducer';

export const setupCompanyReducer = combineReducers({
    activeStep: createReducer(SETUP_COMPANY_STEPS.PERSONAL_INFORMATION, actions.SET_ACTIVE_STEP),
    isLoadingForm: createReducer(false, actions.SET_IS_LOADING_SETUP_COMPANY_FORM),
    personalInformation: personalInformationReducer,
    nonConvictionDeclaration: nonConvictionDeclarationReducer,
    companyInformation: companyInformationReducer,
});
