export const GET_FREELANCER_ACCOUNT = 'GET_FREELANCER_ACCOUNT';
export const STORE_FREELANCER_ACCOUNT = 'STORE_FREELANCER_ACCOUNT';
export const SET_IS_LOADING_FREELANCER_ACCOUNT = 'SET_IS_LOADING_FREELANCER_ACCOUNT';
export const GET_FREELANCER_COMPANIES = 'GET_FREELANCER_COMPANIES';
export const STORE_FREELANCER_COMPANIES = 'STORE_FREELANCER_COMPANIES';
export const SET_IS_LOADING_FREELANCER_COMPANIES = 'SET_IS_LOADING_FREELANCER_COMPANIES';

export const getFreelancerAccount = freelancerId => ({
    type: GET_FREELANCER_ACCOUNT,
    payload: freelancerId,
});

export const storeFreelancerAccount = freelancerAccount => ({
    type: STORE_FREELANCER_ACCOUNT,
    payload: freelancerAccount,
});

export const setIsLoadingFreelancerAccount = isLoading => ({
    type: SET_IS_LOADING_FREELANCER_ACCOUNT,
    payload: isLoading,
});

export const getFreelancerCompanies = freelancerId => ({
    type: GET_FREELANCER_COMPANIES,
    payload: {
        freelancerId,
    },
});

export const storeFreelancerCompanies = freelancerCompanies => ({
    type: STORE_FREELANCER_COMPANIES,
    payload: freelancerCompanies,
});

export const setIsLoadingFreelancerCompanies = isLoading => ({
    type: SET_IS_LOADING_FREELANCER_COMPANIES,
    payload: isLoading,
});
