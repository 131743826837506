import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingButton} from '@mui/lab';
import {Box, Slide, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import CloseCapitalDepositSvg from '../../../../../assets/svg/bank/close-capital-deposit.svg';
import BankInformationLayout from '../../../../../layout/BankInformationLayout/BankInformationLayout';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {BankActions} from '../../../../bank/store/bank.action';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';

const CapitalDepositClosing = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('freelancerOnboarding');

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GENERIC_CRUD_LOADER),
    );

    const onNextClick = () => {

    };

    const closeBankAccount = () => {
        dispatch(BankActions.closeBankAccount());
    };

    return (
        <BankInformationLayout
            containerMaxWidth="720px"
            header={t('finalCapitalDeposit.header')}
            subTitle={t('finalCapitalDeposit.subtitle')}
            Button={(
                <Button
                    color="secondary"
                    variant="contained"
                    disabled={true}
                    endIcon={<ArrowForwardIcon />}
                    onClick={onNextClick}
                >
                    {t('finalCapitalDeposit.continue')}
                </Button>
            )}
        >
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                    <Box>
                        <img src={CloseCapitalDepositSvg} alt="pending" />
                    </Box>
                    <Typography sx={{my: 2}} variant="h4">{t('finalCapitalDeposit.capitalDepositClosingText1')}</Typography>
                    <Typography sx={{
                        maxWidth: '485px',
                        color: 'text_v2.secondary',
                    }}
                    >
                        {t('finalCapitalDeposit.capitalDepositClosingText2')}
                    </Typography>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        color="secondary"
                        endIcon={<ArrowForwardIcon />}
                        sx={{
                            mt: 3,
                        }}
                        onClick={closeBankAccount}
                    >
                        {t('finalCapitalDeposit.closeButton')}
                    </LoadingButton>
                </Box>
            </Slide>
        </BankInformationLayout>
    );
};

export default CapitalDepositClosing;
