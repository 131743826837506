export const SET_IS_LOADING_HOME_OFFICE_TRAVELS_FORM = 'SET_IS_LOADING_HOME_OFFICE_TRAVELS_FORM';
export const SUBMIT_HOME_OFFICE_TRAVELS_FORM = 'SUBMIT_HOME_OFFICE_TRAVELS_FORM';

export const setIsLoadingHomeOfficeTravelsForm = isLoading => ({
    type: SET_IS_LOADING_HOME_OFFICE_TRAVELS_FORM,
    payload: isLoading,
});

export const submitHomeOfficeTravelsForm = (data, travelId, isEdit, onSuccess) => ({
    type: SUBMIT_HOME_OFFICE_TRAVELS_FORM,
    payload: {
        data,
        travelId,
        isEdit,
        onSuccess,
    },
});
