import moment from 'moment/moment';
import {v4} from 'uuid';
import {createUnaccentedString} from '../../../utils/string';
import {
    COLORS_PALETTE,
    SOURCE_ICONS,
    categorizationState,
} from '../modules/account-balance/util/constants';
import {getIntegrationInfo} from '../modules/bridge-api/utils/bridge-util';
import {BankAccountStatus, BankIntegrationStatus} from '../modules/bridge-api/utils/constants';

const transformKeyword = keyword => createUnaccentedString(keyword).toUpperCase();

export const transformCategoriesFromDto = dto => {
    const categoriesObject = {};
    dto.forEach(parentCategories => {
        if (parentCategories.mccGroupItems) {
            const color = COLORS_PALETTE[parentCategories.mccGroupKey];
            parentCategories.mccGroupItems.forEach(category => {
                categoriesObject[category.mccKey] = {
                    fr: category?.fr?.categoryName,
                    en: category?.en?.categoryName,
                    color,
                    iconName: category?.icon.slice(0, -4),
                };
            });
        }
    });

    const categoriesList = dto.map(category => ({
        ...category,
        mccGroupItems: category.mccGroupItems.map(item => ({
            ...item,
            uppercase: {
                fr: {
                    categoryName: createUnaccentedString(item?.fr?.categoryName).toUpperCase(),
                    keywords: item?.fr?.keywords.map(transformKeyword),
                },
                en: {
                    categoryName: createUnaccentedString(item?.en?.categoryName).toUpperCase(),
                    keywords: item?.en?.keywords.map(transformKeyword),
                },
            },
        })),
    }));

    return {categoriesList, categoriesObject};
};

export const transformSourcesFromDto = dtoInitial => {
    // TODO: remove this dirty hack after BE is ready. HPD-4033
    const dto = [...dtoInitial];
    if (dto.length > 3) {
        const idkItem = dto[3].sourceOfRevenueGroupItems[0];
        dto[2].sourceOfRevenueGroupItems.push(idkItem);
        dto.pop(); // removing the last element
    }

    const sourcesObject = {};
    dto.forEach(parentSources => {
        if (parentSources.sourceOfRevenueGroupItems) {
            const color = COLORS_PALETTE[parentSources.sourceOfRevenueGroupKey];
            parentSources.sourceOfRevenueGroupItems.forEach(category => {
                sourcesObject[category.sourceOfRevenue] = {
                    fr: category?.fr?.categoryName,
                    en: category?.en?.categoryName,
                    color,
                    iconName: SOURCE_ICONS[category.sourceOfRevenue],
                };
            });
        }
    });

    const sourcesList = dto.map(group => ({
        ...group,
        sourceOfRevenueGroupItems: group.sourceOfRevenueGroupItems.map(item => ({
            ...item,
            uppercase: {
                fr: {
                    categoryName: createUnaccentedString(item?.fr?.categoryName).toUpperCase(),
                    keywords: item?.fr?.keywords.map(transformKeyword),
                },
                en: {
                    categoryName: createUnaccentedString(item?.en?.categoryName).toUpperCase(),
                    keywords: item?.en?.keywords.map(transformKeyword),
                },
            },
        })),
    }));

    return {sourcesList, sourcesObject};
};

export const transformTransactionsFromDTO = dto => {
    return {
        count: dto.count,
        maxItems: dto.maxItems,
        nonCategorizedCount: dto.nonCategorizedCount,
        ids: dto.transactions.map(transaction => transaction.id),
        transactionsObject: transformListToArray(dto.transactions),
    };
};

export const transformCombinedTransactionsFromDTO = dto => {
    return {
        count: dto?.count,
        maxItems: dto?.maxItems,
        nonCategorizedCount: dto?.nonCategorizedCount,
        ids: dto?.transactions.map(transaction => transaction.id),
        transactionsObject: transformListToArray(dto?.transactions),
    };
};

export const transformListToArray = transactionList => {
    const transactionObject = {};

    transactionList.forEach(transaction => {
        transactionObject[transaction.id] = transfromTransactionFromDTO(transaction);
    });

    return transactionObject;
};

export const transfromTransactionFromDTO = transaction => {
    const categorySet = new Set();
    const vatSet = new Set();
    transaction?.articles?.forEach(article => {
        if (article.mccKey) {
            categorySet.add(article.mccKey);
        }

        if (article.tva) {
            vatSet.add(article.tva);
        }
    });

    const category = [...categorySet];
    const vat = [...vatSet];

    return {
        ...transaction,
        // TODO Change name since logic from showing the chip evolved to a lot more
        hasChip: transaction.categorizationState === categorizationState.NOT_CATEGORIZED,
        isCategorized: transaction.categorizationState === categorizationState.CATEGORIZED,
        date: transaction?.executionDate ? moment(transaction.executionDate) : moment(),
        amount: parseFloat(transaction.amount).toFixed(2),
        category: category,
        vat: vat,
        articles: transaction?.articles?.map(article => {
            article.id = Math.random();
            return article;
        }),
        reimbursementTransactions: {
            ...transaction?.reimbursementTransactions,
            fakeReimbursedTransactions: transaction?.reimbursementTransactions?.fakeReimbursedTransactions?.length > 0
                ? transaction?.reimbursementTransactions?.fakeReimbursedTransactions.map(fakeReimbursement => {
                    return {
                        ...fakeReimbursement,
                        id: v4(),
                        selected: true,
                        reimbursedAmount: fakeReimbursement?.amount,
                        isFakeReimbursement: true,
                    };
                }) : [],
        },
    };
};

export const transformFromIntegrationDTO = (dto, withArchived) => {
    if (!withArchived) {
        return dto.filter(integration => {
            const {account} = getIntegrationInfo(integration);

            return integration?.status !== BankIntegrationStatus.ARCHIVED
            || account?.status !== BankAccountStatus.ARCHIVED;
        });
    }

    const nonArchived = dto.filter(integration => {
        const {account} = getIntegrationInfo(integration);

        return integration?.status !== BankIntegrationStatus.ARCHIVED
        || account?.status !== BankAccountStatus.ARCHIVED;
    });

    const archived = dto.filter(integration => {
        const {account} = getIntegrationInfo(integration);

        return integration?.status === BankIntegrationStatus.ARCHIVED
            && account?.status === BankAccountStatus.ARCHIVED;
    });

    return {
        nonArchived,
        archived,
    };
};

export const transformBankStatementsFromDto = dto => {
    const items = dto.items.map(item => {
        const openingDate = moment(item.openingDate).format('MMMM YYYY');
        return {
            ...item,
            openingDate,
        };
    });

    return {
        count: dto.count,
        items,
    };
};
