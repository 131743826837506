import {
    faMagnifyingGlass,
    faMoneyBillTransfer,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, CircularProgress, Stack, Typography} from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import AccountBalanceTableDataRow from './AccountBalanceTableDataRow';
import Collapse from '../../../../../../components/joy-ui/mui-legacy/Collapse';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {SettingsSelectors} from '../../../../../settings/store/settings.selectors';
import {TransactionsSelector} from '../../store/transactions.selector';
import {MAX_TRANSACTIONS_ANIMATION} from '../../util/constants';
import {AccountBalanceHelperFunctions} from '../../util/functions';

const iconStyles = {
    color: 'text.icon',
    backgroundColor: 'background.surface',
    border: '2px solid',
    borderColor: 'neutral.outlinedBorder',
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: '50px',
    mb: 2,
};

export const AccountBalanceTable = ({
    filter,
    transactionIds,
    loadNextPage,
    hasAlert,
    onOpenClick,
    isUserHasInvoiceAccess,
    combinedTabIcons,
    isSidebarOpened,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('bank');

    const endOfTable = useRef();

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_TRANSACTIONS,
    ));

    const isLoadingFilter = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_TRANSACTIONS_FILTERS,
    ));

    const isLoadingOffset = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_TRANSACTIONS_PAGE_LOADER,
    ));

    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    const selectCategoriesObject = useSelector(TransactionsSelector.selectCategoriesObject);
    const selectSourcesObject = useSelector(TransactionsSelector.selectSourcesObject);

    useEffect(() => {
        const handleObserver = entries => {
            if (isLoadingFilter || isLoading) {
                return;
            }

            const target = entries[0];

            if (target.isIntersecting) {
                loadNextPage();
            }
        };

        const option = {
            root: null,
            rootMargin: '20px',
            threshold: 0,
        };
        const observer = new IntersectionObserver(handleObserver, option);

        if (endOfTable.current) {
            observer.observe(endOfTable.current);
        }

        const endOfTableCurrent = endOfTable?.current;

        return () => {
            if (observer && endOfTableCurrent) {
                observer.unobserve(endOfTableCurrent);
            }
        };
        // eslint-disable-next-line
    }, [loadNextPage, isLoadingFilter, isLoading]);

    const shouldFieldsCollapseFnc = useCallback(
        () => {
            return AccountBalanceHelperFunctions.calculateShouldFieldDissapearOnOpen();
        },
        [],
    );
    const shouldFieldsCollapse = shouldFieldsCollapseFnc();

    const tableSize = isMobileSize ? {
        firstSegment: '1 1 70%',
        fourthSegment: '1 1 30%',
    } : {
        firstSegment: '1 1 5%',
        secondSegment: '1 1 15%',
        thirdSegment: '1 1 35%',
        fourthSegment: isSidebarOpened && shouldFieldsCollapse ? '0%' : '35%',
        fifthSegment: '1 1 10%',
    };

    const totalLoadedIds = transactionIds?.length;
    const Element = totalLoadedIds > MAX_TRANSACTIONS_ANIMATION ? Box : Collapse;

    return (
        <Box sx={{
            'position': 'relative',
        }}
        >
            {!isMobileSize && (
                <Box sx={{
                    'mb': -1,
                    'mt': 1,
                    'height': '40px',
                    'pr': 1,
                }}
                >
                    <Box sx={{
                        fontSize: 'sm',
                        color: 'rgba(0, 0, 0, 0.38)',
                        alignItems: 'center',
                        display: 'flex',
                        pr: 1,
                        height: '40px',
                        textTransform: 'uppercase',
                        fontStyle: 'italic',
                    }}
                    >
                        <Box sx={{
                            flex: tableSize.firstSegment,
                        }}
                        />

                        <Typography
                            component="div"
                            level="body-sm"
                            color="text.tertiary"
                            sx={{
                                flex: tableSize.secondSegment,
                                transition: totalLoadedIds > MAX_TRANSACTIONS_ANIMATION ? 'none' : 'flex-basis 0.4s 0.1s ease-in-out',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                mb: 1,
                                justifyContent: 'flex-end',
                                pl: 0.1,
                                fontWeight: 'xl',
                            }}
                        >
                            {t('accountBalanceTableHeader.status')}
                        </Typography>

                        <Typography
                            component="div"
                            level="body-sm"
                            color="text.tertiary"
                            sx={{
                                flex: tableSize.thirdSegment,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                mb: 1,
                                justifyContent: 'flex-end',
                                pl: 2.1,
                                fontWeight: 'xl',
                            }}
                        >
                            {t('accountBalanceTableHeader.name')}
                        </Typography>

                        <Element
                            collapsedSize="0px"
                            in={!isSidebarOpened || !shouldFieldsCollapse}
                            sx={{
                                width: tableSize.fourthSegment,
                                height: '40px',
                                display: 'flex',
                                mb: 1,
                                transition: totalLoadedIds > MAX_TRANSACTIONS_ANIMATION ? 'none' : 'width 0.6s ease-in-out',
                            }}
                        >
                            <Typography
                                component="div"
                                level="body-sm"
                                color="text.tertiary"
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '40px',
                                    justifyContent: 'flex-end',
                                    flexDirection: 'column',
                                    ml: -2,
                                    pl: 2,
                                    fontWeight: 'xl',
                                }}
                            >
                                {t('accountBalanceTableHeader.category')}
                            </Typography>
                        </Element>

                        <Typography
                            component="div"
                            level="body-sm"
                            color="text.tertiary"
                            sx={{
                                flex: tableSize.fifthSegment,
                                transition: 'none',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                mb: 1,
                                pr: 1.2,
                                justifyContent: 'flex-end',
                                textAlign: 'right',
                                fontWeight: 'xl',
                            }}
                        >
                            {t('accountBalanceTableHeader.amount')}
                        </Typography>
                    </Box>
                </Box>
            )}

            {transactionIds.length === 0 && !isLoading && !isLoadingFilter && (
                <Box sx={{
                    py: 5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
                >
                    {(filter.startDate
                        || filter.isNotCategorized
                        || filter.search
                        || filter.transactionTypes?.length > 0) ? (
                            <>
                                <Box sx={iconStyles}>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </Box>
                                <Typography
                                    level="title-sm"
                                    sx={{color: 'text.secondary'}}
                                >
                                    {t('accountBalanceTableHeader.emptyStateFilter')}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Box sx={iconStyles}>
                                    <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                </Box>
                                <Typography
                                    level="title-sm"
                                    sx={{color: 'text.secondary', mb: 0.5}}
                                >
                                    {t('accountBalanceTableHeader.emptyState')}
                                </Typography>
                                <Typography
                                    level="body-sm"
                                    sx={{color: 'text.tertiary'}}
                                >
                                    {t('accountBalanceTableHeader.emptyStateSub1')}
                                </Typography>
                                <Typography
                                    level="body-sm"
                                    sx={{color: 'text.tertiary'}}
                                >
                                    {t('accountBalanceTableHeader.emptyStateSub2')}
                                </Typography>
                            </>
                        )}
                </Box>
            )}
            {isLoadingFilter && (
                <Box sx={{
                    backgroundColor: 'background.surface',
                    mb: 0,
                    mt: 2,
                    height: '540px',
                    width: '100%',
                }}
                >
                    <Box sx={{
                        fontWeight: 'md',
                        fontSize: 'sm',
                        alignItems: 'center',
                        display: 'flex',
                        height: '340px',
                        width: '100%',
                    }}
                    >
                        <Box sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        >
                            <CircularProgress />
                        </Box>
                    </Box>
                </Box>
            )}
            <Box sx={
                !isMobileSize ? {
                    'opacity': isLoadingFilter ? 0.2 : 1,
                } : {
                    'opacity': isLoadingFilter ? 0.2 : 1,
                    'maxHeight': hasAlert ? 'calc(100vh - 285px)' : 'calc(100vh - 225px)',
                    'overflowY': 'auto',
                }}
            >
                {transactionIds.map((id, index) => {
                    let prevValue = '';
                    if (index > 0) {
                        prevValue = transactionIds[index - 1];
                    }
                    return (
                        <AccountBalanceTableDataRow
                            key={id}
                            id={id}
                            prevId={prevValue}
                            isSidebarOpened={isSidebarOpened}
                            tableSize={tableSize}
                            isMobileSize={isMobileSize}
                            filter={filter}
                            onOpenClick={onOpenClick}
                            language={language}
                            selectCategoriesObject={selectCategoriesObject}
                            selectSourcesObject={selectSourcesObject}
                            t={t}
                            areFieldsCollapsed={shouldFieldsCollapse}
                            totalLoadedIds={totalLoadedIds}
                            isUserHasInvoiceAccess={isUserHasInvoiceAccess}
                            combinedTabIcons={combinedTabIcons}
                        />
                    );
                })}
                <Box ref={endOfTable} />
                {isLoadingOffset && (
                    <Stack
                        height={80}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        gap={1}
                    >
                        <CircularProgress size="sm" />
                        <Typography level="body-sm" sx={{color: 'text.secondary'}}>
                            {t('accountBalanceTableHeader.loadingMoreTransactions')}
                        </Typography>
                    </Stack>
                )}
            </Box>
        </Box>
    );
};

AccountBalanceTable.propTypes = {
    filter: PropTypes.object.isRequired,
    transactionIds: PropTypes.array,
    loadNextPage: PropTypes.func.isRequired,
    hasAlert: PropTypes.bool.isRequired,
    onOpenClick: PropTypes.func.isRequired,
    isUserHasInvoiceAccess: PropTypes.bool,
    combinedTabIcons: PropTypes.object,
    isSidebarOpened: PropTypes.bool,
};

AccountBalanceTable.defaultProps = {
    transactionIds: [],
    isUserHasInvoiceAccess: false,
    combinedTabIcons: null,
    isSidebarOpened: false,
};
