export const OpportunityActionTypes = {
    GET_FREELANCER: 'opportunity/GET_FREELANCER',
    GET_OTHER: 'opportunity/GET_OTHER',
    GET_MY_BIDS: 'opportunity/GET_MY_BIDS',
    STORE_CLUSTER: 'opportunity/STORE_CLUSTER',
    SUBMIT: 'opportunity/SUBMIT',
    STORE_SPECIALISATIONS: 'opportunity/STORE_SPECIALISATIONS',
    APPLY_AS_ADMIN: 'opportunity/APPLY_AS_ADMIN',
    APPLY_FROM_CLUSTER_PAGE: 'opportunity/APPLY_FROM_CLUSTER_PAGE',
    UNAPPLY: 'opportunity/UNAPPLY',
    SEARCH_FREELANCER: 'opportunity/SEARCH_FREELANCER',
    STORE_SEARCH: 'opportunity/STORE_SEARCH',
    TOGGLE_ACCEPT: 'opportunity/TOGGLE_ACCEPT',
    STORE_LIST: 'opportunity/STORE_LIST',
    STORE_OTHER_LIST: 'opportunity/STORE_OTHER_LIST',
    STORE_MY_BIDS_LIST: 'opportunity/STORE_MY_BIDS_LIST',
};
