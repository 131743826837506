import {createSelector} from 'reselect';
import {selectFreelancerCompanies} from '../../freelancer/freelancer.selectors';

export const selectSelectedCompanyId = state => state.v1Company.currentCompany.id;

export const createSelectedCompanySelector = () => createSelector(
    [selectSelectedCompanyId, selectFreelancerCompanies],
    (selectedCompanyId, companies) => {
        return companies[selectedCompanyId];
    },
);
