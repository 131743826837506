import {
    faArrowRight,
    faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Skeleton, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {TransactionsSelector} from '../../store/transactions.selector';

export const AccountBalanceAlert = ({
    setFilter,
    transactionNonCategorizedCount,
}) => {
    const {t} = useTranslation('bank');

    const isMobileSize = useIsMobileSize();

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_TRANSACTIONS_FILTERS,
    ));

    const totalOverview = useSelector(TransactionsSelector.selectTransactionTotalOverview);

    const onSwitchChange = () => {
        setFilter({
            startDate: null,
            endDate: null,
            search: '',
            transactionTypes: [],
            isNotCategorized: true,
        });
    };

    if (isLoading) {
        return (
            <Box sx={{
                height: '50px',
            }}
            >
                <Skeleton
                    sx={{
                        borderRadius: '12px',
                    }}
                    variant="rectangular"
                    width="100%"
                    height={50}
                />
            </Box>
        );
    }

    if (!totalOverview?.totalTransactions) {
        return null;
    }

    const notCategorized = transactionNonCategorizedCount > 0;

    return (
        <Stack
            direction={isMobileSize ? 'column' : 'row'}
            justifyContent={isMobileSize ? 'flex-start' : 'space-between'}
            alignItems={isMobileSize ? 'flex-start' : 'center'}
            gap={2}
            borderRadius="lg"
            sx={{
                p: 2,
                backgroundColor: notCategorized ? 'background.level2' : 'background.body',
                mb: 2,
                border: '1px solid',
                borderColor: notCategorized ? 'background.level2' : 'success.outlinedBorder',
            }}
        >
            {notCategorized ? (
                <>
                    <Stack
                        direction="row"
                        justifyContent={isMobileSize ? 'flex-start' : 'space-between'}
                        alignItems="center"
                        gap={1}
                    >
                        <Box
                            borderRadius="md"
                            sx={{
                                px: 1,
                                backgroundColor: 'primary.solidBg',
                            }}
                        >
                            <Typography
                                level="h3"
                                sx={{
                                    color: 'primary.solidColor',
                                    minWidth: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                {transactionNonCategorizedCount}
                            </Typography>
                        </Box>
                        <Typography
                            level="h3"
                            color="warning"
                            sx={{
                                textTransform: 'lowercase',
                                color: 'warning.softColor',
                            }}
                        >
                            {t('accountBalance.transactionAlert')}
                        </Typography>
                    </Stack>
                    <Button
                        fullWidth={isMobileSize}
                        color="primary"
                        variant="solid"
                        size="md"
                        onClick={onSwitchChange}
                        endDecorator={(
                            <FontAwesomeIcon icon={faArrowRight} />
                        )}
                    >
                        {t('accountBalance.categorise')}
                    </Button>
                </>
            ) : (
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                >
                    <Box sx={{color: 'success.softActiveColor'}}>
                        <FontAwesomeIcon icon={faCircleCheck} />
                    </Box>
                    <Typography
                        level="h3"
                        sx={{color: 'success.softColor'}}
                    >
                        {t('accountBalance.allCategorizedText')}
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
};

/* Disabled for first version of categorisation */
/* <Button */
/*    sx={{ */
/*        fontSize: theme => theme.typography.pxToRem(13), */
/*    }} */
/*    variant="text" */
/*    size="small" */
/*    startIcon={<TuneIcon />} */
/* > */
/*    {t('accountBalance.automationRulesMobile')} */
/* </Button> */

AccountBalanceAlert.propTypes = {
    setFilter: PropTypes.func.isRequired,
    transactionNonCategorizedCount: PropTypes.number.isRequired,
};
