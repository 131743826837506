import {Box, Radio, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';

export const SelectItem = ({
    value,
    name,
    text,
    isSelected,
    isRadioButtonHidden,
    additionalContent,
}) => {
    return (
        <Box
            sx={{
                border: '1px solid',
                borderColor: isSelected
                    ? 'neutral.softColor'
                    : 'neutral.outlinedBorder',
                borderRadius: 'md',
                backgroundColor: 'background.body',
                p: 2,
                width: '100%',
            }}
        >
            <Stack
                direction="column"
                gap={2}
            >
                <Radio
                    value={value}
                    label={(
                        <Stack direction="column">
                            <Typography
                                level="title-lg"
                                sx={{
                                    color: 'neutral.softColor',
                                    wordBreak: 'break-all',
                                }}
                            >
                                {name}
                            </Typography>
                            <Typography
                                level="body-lg"
                                sx={{
                                    color: 'text.secondary',
                                }}
                            >
                                {text}
                            </Typography>
                        </Stack>
                    )}
                    color="neutral"
                    variant="soft"
                    slotProps={{input: {'aria-describedby': text}}}
                    sx={{
                        alignItems: 'center',
                        width: '100%',
                        ...(isSelected ? {
                            '& .MuiRadio-icon': {
                                backgroundColor: 'neutral.softColor',
                            },
                        } : {}),
                        ...(isRadioButtonHidden ? {
                            '& .MuiRadio-radio': {
                                display: 'none',
                            },
                        } : {}),
                    }}
                />
                {additionalContent}
            </Stack>
        </Box>
    );
};

SelectItem.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    isRadioButtonHidden: PropTypes.bool,
    additionalContent: PropTypes.node,
};

SelectItem.defaultProps = {
    isSelected: false,
    isRadioButtonHidden: false,
    additionalContent: null,
};
