
// eslint-disable-next-line
import * as icons from '@mui/icons-material'; // TODO Try dynamic import
import React from 'react';

export const getIconFromName = name => {
    if (!name) {
        return null;
    }

    const iconName = name.slice(-4) === 'Icon'
        ? name.slice(0, -4)
        : name;

    return icons[iconName];
};

export const createIconFromName = name => {
    if (!name) {
        return null;
    }

    const Icon = getIconFromName(name);

    if (Icon) {
        return <Icon />;
    }

    return null;
};

export const createIconStringFromName = name => {
    if (!name) {
        return null;
    }

    const Icon = getIconFromName(name);

    if (Icon) {
        return Icon;
    }

    return null;
};
