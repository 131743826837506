import {BackofficeActionTypes} from './backoffice.action-type';

export const BackofficeActions = {
    storeCompaniesInCreationIds: ids => ({
        type: BackofficeActionTypes.STORE_COMPANIES_IDS,
        payload: ids,
    }),
    storeCompaniesInCreationEntities: entities => ({
        type: BackofficeActionTypes.STORE_COMPANIES_ENTITIES,
        payload: entities,
    }),
    storeShortFilterCount: countObject => ({
        type: BackofficeActionTypes.STORE_SHORT_FILTER_COUNT,
        payload: countObject,
    }),
    storeTotalCount: countObject => ({
        type: BackofficeActionTypes.STORE_TOTAL_COUNT,
        payload: countObject,
    }),
    getKbisDocuments: (freelancerId, companyId) => ({
        type: BackofficeActionTypes.GET_KBIS_DOCUMENTS,
        payload: {freelancerId, companyId},
    }),
    storeKbisDocuments: document => ({
        type: BackofficeActionTypes.STORE_KBIS_DOCUMENTS,
        payload: document,
    }),
    getLegalNoticeDocument: (freelancerId, companyId) => ({
        type: BackofficeActionTypes.GET_LEGAL_NOTICE_DOCUMENT,
        payload: {freelancerId, companyId},
    }),
    storeLegalNoticeDocument: document => ({
        type: BackofficeActionTypes.STORE_LEGAL_NOTICE_DOCUMENT,
        payload: document,
    }),
    filterEntities: (filter, sort) => ({
        type: BackofficeActionTypes.FILTER_COMPANIES,
        payload: {
            filter,
            sort,
        },
    }),
    sendReminder: (type, to, subject, message) => ({
        type: BackofficeActionTypes.SEND_REMINDER,
        payload: {
            to,
            type,
            subject,
            message,
        },
    }),
    saveRegistrationStatus: (to, status, data) => ({
        type: BackofficeActionTypes.SAVE_REGISTRATION_STATUS,
        payload: {
            to,
            status,
            data,
        },
    }),
    setIsRegistrationStatusSaved: data => ({
        type: BackofficeActionTypes.SET_IS_REGISTRATION_STATUS_SAVED,
        payload: data,
    }),
    editRegistrationDate: ({freelancerId, companyId, date}) => ({
        type: BackofficeActionTypes.EDIT_REGISTRATION_DATE,
        payload: {freelancerId, companyId, date},
    }),
    startCompanyRegistration: ({freelancerId, companyId, data}) => ({
        type: BackofficeActionTypes.START_COMPANY_REGISTRATION,
        payload: {freelancerId, companyId, data},
    }),
    uploadCompanySetupFile: ({freelancerId, companyId, data}) => ({
        type: BackofficeActionTypes.UPLOAD_COMPANY_SETUP_FILE,
        payload: {freelancerId, companyId, data},
    }),
    companyRegistrationPolling: ({freelancerId, companyId}) => ({
        type: BackofficeActionTypes.COMPANY_REGISTRATION_START_POLLING,
        payload: {freelancerId, companyId},
    }),
    companyRegistrationRestart: ({freelancerId, companyId}) => ({
        type: BackofficeActionTypes.COMPANY_REGISTRATION_RESTART,
        payload: {freelancerId, companyId},
    }),
    companyRegistrationRetry: ({freelancerId, companyId, ignoreWarnings}) => ({
        type: BackofficeActionTypes.COMPANY_REGISTRATION_RETRY,
        payload: {freelancerId, companyId, ignoreWarnings},
    }),
    companyRegistrationRefresh: ({freelancerId, companyId}) => ({
        type: BackofficeActionTypes.COMPANY_REGISTRATION_REFRESH,
        payload: {freelancerId, companyId},
    }),
    companyRegistrationManualLaunch: ({freelancerId, companyId}) => ({
        type: BackofficeActionTypes.COMPANY_REGISTRATION_MANUAL_LAUNCH,
        payload: {freelancerId, companyId},
    }),
    companyRegistrationManualValidate: ({freelancerId, companyId, inpiIntegrationId}) => ({
        type: BackofficeActionTypes.COMPANY_REGISTRATION_MANUAL_VALIDATE,
        payload: {freelancerId, companyId, inpiIntegrationId},
    }),
    registrationFinalization: ({freelancerId, companyId}) => ({
        type: BackofficeActionTypes.COMPANY_REGISTRATION_FINALIZATION,
        payload: {freelancerId, companyId},
    }),
    companyRegistrationUpdateNote: ({freelancerId, companyId, note}) => ({
        type: BackofficeActionTypes.COMPANY_REGISTRATION_UPDATE_NOTE,
        payload: {freelancerId, companyId, note},
    }),
    globalDataExport: () => ({
        type: BackofficeActionTypes.GLOBAL_DATA_EXPORT,
    }),
};
