import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import {LoadingButton} from '@mui/lab';
import {Box, Typography, useMediaQuery} from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../../../../../components/modal-wrapper/ModalWrapper';
import {UiSelectors} from '../../../../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../../../../ui/utils/constants';

const modalKey = ModalsKeys.CATEGORISATION_CLOSE_EXTERNAL_INVOICE;

const ConfirmExternalInvoiceClose = () => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));

    const handleConfirm = () => {
        data?.setIsExternalOpen(false);
    };

    return (
        <ModalWrapper
            containerSx={{width: '652px'}}
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {t('categorisation.proofOfDocuments.title')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        disableElevation
                        fullWidth={isMobileSize}
                        startIcon={<ArrowBackIcon />}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="contained"
                        color="blueGray"
                    >
                        {t('categorisation.proofOfDocuments.cancel')}
                    </Button>

                    <LoadingButton
                        fullWidth={isMobileSize}
                        startIcon={<CancelIcon />}
                        onClick={() => {
                            handleConfirm();
                            handleClose();
                        }}
                        color="secondary"
                        variant="contained"
                    >
                        {t('categorisation.proofOfDocuments.confirm')}
                    </LoadingButton>
                </>
            )}
        >
            <Box>
                <Typography>
                    {t('categorisation.proofOfDocuments.label')}
                </Typography>
            </Box>
        </ModalWrapper>
    );
};

export default ConfirmExternalInvoiceClose;
