import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import {FormProvider, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Navigation} from './Navigation';
import {ProgressIndicator} from './ProgressIndicator';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';
import {RevenueSimulatorActions} from '../../../store/revenue-simulator.action';
import {RevenueSimulatorSelectors} from '../../../store/revenue-simulator.selector';

export const StepWrapper = ({children, hasNavigation, hasProgressIndicator, isLegacy}) => {
    const allValues = useSelector(RevenueSimulatorSelectors.selectAllValues);
    const dispatch = useDispatch();
    const isMobileSize = useIsMobileSize();
    const handleSubmit = values => dispatch(RevenueSimulatorActions.submitStep(values));

    const methods = useForm({mode: 'onChange', defaultValues: allValues});

    const form = (
        <FormProvider {...methods}>
            {hasProgressIndicator && <ProgressIndicator />}
            <form onSubmit={methods.handleSubmit(handleSubmit)}>{children}</form>
            {hasNavigation && <Navigation />}
        </FormProvider>
    );

    if (!isLegacy) {
        return form;
    }

    return (
        <Box sx={{
            backgroundColor: 'background.default',
            p: 4,
            pt: 3,
            m: -4,
            mt: -3,
            minHeight: isMobileSize ? 'auto' : '100vh',
        }}
        >
            {form}
        </Box>
    );
};

StepWrapper.propTypes = {
    hasNavigation: PropTypes.bool,
    hasProgressIndicator: PropTypes.bool,
    isLegacy: PropTypes.bool,
};

StepWrapper.defaultProps = {
    hasNavigation: true,
    hasProgressIndicator: true,
    isLegacy: true,
};
