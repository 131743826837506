import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Box, Paper, Typography} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import QRCode from 'react-qr-code';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import {CONFIG} from '../../../../../../../../config';
import {BankActions} from '../../../../../../store/bank.action';
import {BankSelector} from '../../../../../../store/bank.selector';
import TransactionDetailsCloseSection from '../../../transaction-details/TransactionDetailsCloseSection';

const DebitBankQr = ({id, onClose, accountId}) => {
    const dispatch = useDispatch();

    const [waitingEvent, setWaitingEvent] = useState(null);

    const {t: tBankTransfer} = useTranslation('bankTransfer');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const baseUrl = CONFIG.WEB_BASE_URL;

    const eventId = useSelector(BankSelector.selectQrCodeEvent);

    useEffect(() => {
        const eventId = uuidv4();
        dispatch(BankActions.addUploadFileQrCodeEvent(eventId));
        setWaitingEvent(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!eventId && waitingEvent) {
            onClose();
        }
        // eslint-disable-next-line
    }, [eventId]);

    if (isMobileSize) {
        return (
            <Drawer
                anchor="bottom"
                open={true}
                onClose={onClose}
                sx={{
                    '.MuiDrawer-paperAnchorBottom': {
                        borderTopRightRadius: '32px',
                        borderTopLeftRadius: '32px',
                    },
                }}
            >
                <Box sx={{
                    minHeight: '300px',
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <Paper
                        elevation={1}
                        sx={{
                            height: !isMobileSize ? '100vh' : '100%',
                            width: '100%',
                        }}
                    >
                        <Box sx={{display: 'flex', flexDirection: 'column', px: 3}}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                py: 2,
                                pt: 4,
                            }}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{
                                        mb: 1,
                                        width: '262px',
                                    }}
                                >
                                    {tBankTransfer('qr.title')}
                                </Typography>
                                <Typography sx={{
                                    width: '262px',
                                    fontSize: theme => theme.typography.pxToRem(14),
                                    color: 'v2.light.text.disabled',
                                }}
                                >
                                    {tBankTransfer('qr.text')}
                                </Typography>
                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 2,
                                }}
                                >
                                    {accountId && baseUrl && eventId && (
                                        <Box>
                                            <QRCode
                                                size={180}
                                                style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                                                value={`${baseUrl}account/${accountId}/transaction/${id}?eventId=${eventId}`}
                                                viewBox="0 0 180 180"
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Drawer>
        );
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            zIndex: 222,
            width: '100%',
        }}
        >
            <TransactionDetailsCloseSection
                isMobileSize={isMobileSize}
                onClose={onClose}
                isOpen
            />
            <Paper
                elevation={0}
                sx={{
                    pb: 6,
                    width: '100%',
                    textAlign: 'center',
                }}
            >
                <Box
                    sx={{
                        mb: 2,
                        p: 2,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        cursor: 'pointer',
                    }}
                    onClick={onClose}
                >
                    <ArrowBackIcon />
                    <Typography>{tBankTransfer(`qr.transaction`)}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            mb: 1,
                            width: '262px',
                        }}
                    >
                        {tBankTransfer('qr.title')}
                    </Typography>
                    <Typography sx={{
                        width: '262px',
                        fontSize: theme => theme.typography.pxToRem(14),
                        color: 'v2.light.text.disabled',
                    }}
                    >
                        {tBankTransfer('qr.text')}
                    </Typography>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                    }}
                    >
                        {accountId && baseUrl && eventId && (
                            <Box>
                                <QRCode
                                    size={180}
                                    style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                                    value={`${baseUrl}account/${accountId}/transaction/${id}?eventId=${eventId}`}
                                    viewBox="0 0 180 180"
                                />
                            </Box>
                        )}
                    </Box>
                </Box>

            </Paper>
        </Box>
    );
};

DebitBankQr.propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    accountId: PropTypes.string.isRequired,
};

export default DebitBankQr;
