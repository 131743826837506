import makeStyles from '@mui/styles/makeStyles';

export const useContactInformationFormStyles = makeStyles(theme => ({
    container: {
        width: '100%',
    },
    containerLoading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: theme.spacing(4),
    },
    formLabel: {
        marginBottom: theme.spacing(2),
        color: '#0B2333',
        fontWeight: 500,
        letterSpacing: '0.1px',
        lineHeight: '26px',
    },
    poweredByGoogle: {
        display: 'block',
        marginLeft: 'auto',
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));
