import axiosInstance from '../../axios';

export const sendEventRequest = data => {
    return axiosInstance({
        method: 'POST',
        url: `/v1/events`,
        data: {
            event_type: data.eventType,
            payload: data.payload,
        },
    });
};
