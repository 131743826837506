import React from 'react';
import Step2 from '../../assets/etape2-creation-compte-myatlas-v2.pdf';
import {TrainingViewPdf} from '../general/TrainingViewPdf';

const MyAtlasViewPdf = () => {
    return (
        <TrainingViewPdf
            file={Step2}
        />
    );
};

export default MyAtlasViewPdf;
