import makeStyles from '@mui/styles/makeStyles';

export const useExpandingContainerStyles = makeStyles(theme => ({
    paper: {
        padding: 0,
        marginTop: theme.spacing(3),
        borderRadius: '16px',
    },
    titleParent: {
        padding: theme.spacing(2),
    },
    title: {
        fontWeight: 600,
    },
    actionsParent: {
        padding: theme.spacing(2),
        textAlign: 'right',
    },
}));
