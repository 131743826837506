import {createSelector} from 'reselect';

const selectInvoices = state => state.invoice?.invoiceList;
const selectInvoicesCount = state => state.invoice?.invoiceList.count;
const selectInvoiceStats = state => state.invoice?.invoiceStats;
const selectInvoiceDraft = state => state.invoice?.invoiceDraft;
const selectInvoiceDraftError = state => state.invoice?.invoiceDraftError;
const selectClients = state => state.invoice?.clients;
const selectClientSuggestions = state => state.invoice?.clientSuggestions;
const selectClientDraft = state => state.invoice?.clientEditDraft;
const selectServices = state => state.invoice?.services;
const selectInvoiceSidebar = state => state.invoice?.invoiceSidebar;
const selectInvoiceFinalizationResult = state => state.invoice?.invoiceFinalizationResult;
const selectInvoiceHeaderData = state => state.invoice?.invoiceHeaderData;
const selectInvoiceResult = state => state.invoice?.invoiceResult;
const selectNextCreditNoteDocNumber = state => state.invoice?.nextCreditNoteDocNumber;
const selectInvoiceRemindData = state => state.invoice?.invoiceRemindData;
const selectInvoiceUrl = state => state.invoice?.invoiceUrl;
const selectShowCreateForm = state => state.invoice?.showCreateForm;
const selectInvoiceSidebarData = state => state.invoice?.invoiceSidebarData;
const selectFileLoader = state => state.invoice?.clientFileLoader;
const selectServiceDraft = state => state.invoice?.serviceEditDraft;
const selectInvoicePreview = state => state.invoice?.invoicePreview;
const selectInvoicePreviewUrl = state => state.invoice?.invoicePreviewUrl;
const selectLastInternalInvoice = state => state.invoice?.lastInternalInvoice;
const selectLastExternalInvoice = state => state.invoice?.lastExternalInvoice;
const selectClientToEdit = state => state.invoice?.clientToEdit;
const selectImportResultData = state => state?.invoice?.invoiceImportInformation;
const selectShowAriaActivationPage = state => state.invoice?.showAriaActivationPage;
const selectShowAriaParameters = state => state.invoice?.showAriaParameters;
const selectAriaStatus = state => state.invoice?.ariaStatus;

const createModalFileLoaderSelector = clientId => createSelector(
    [selectFileLoader],
    data => (clientId ? data.filter(el => el.clientId === clientId && el.inProgress) : []),
);

const createModalFileLoaderSelectorByEventId = eventId => createSelector(
    [selectFileLoader],
    data => (data.find(el => el?.eventId === eventId)),
);

const createSelectClientsFromListById = clientId => createSelector(
    [selectClients],
    clients => (clients
        ? Object.values(clients).find(client => client?.id === clientId)
        : null),
);

const createInvoiceSelector = () => () => createSelector(
    [
        selectInvoices,
    ],
    invoices => {
        if (!invoices.count) {
            return {data: [], count: 0};
        }

        return {data: invoices.items, count: Number(invoices.count)};
    },
);

export const InvoiceSelector = {
    selectInvoices,
    selectInvoicesCount,
    selectInvoiceStats,
    selectInvoiceDraft,
    selectInvoiceDraftError,
    createInvoiceSelector,
    selectClients,
    selectClientSuggestions,
    selectInvoicePreview,
    selectInvoiceFinalizationResult,
    selectInvoiceHeaderData,
    selectInvoiceResult,
    selectNextCreditNoteDocNumber,
    selectInvoiceRemindData,
    selectInvoiceUrl,
    selectShowCreateForm,
    selectClientDraft,
    selectServices,
    selectInvoiceSidebar,
    createSelectClientsFromListById,
    selectInvoiceSidebarData,
    selectFileLoader,
    createModalFileLoaderSelector,
    createModalFileLoaderSelectorByEventId,
    selectInvoicePreviewUrl,
    selectServiceDraft,
    selectClientToEdit,
    selectLastInternalInvoice,
    selectLastExternalInvoice,
    selectImportResultData,
    selectShowAriaActivationPage,
    selectShowAriaParameters,
    selectAriaStatus,
};
