import {
    CHANGE_CONFIRM_NEW_PASSWORD,
    CHANGE_NEW_PASSWORD,
    NEW_PASSWORD_FORM_ERROR,
    NEW_PASSWORD_FORM_SUCCESS,
} from './newPasswordForm.actions';
import {SUBMIT_NEW_PASSWORD_FORM} from '../auth.actions';
import {LOGIN_SUCCESS} from '../loginForm/loginForm.actions';

const initialState = {
    newPassword: '',
    confirmNewPassword: '',
    isLoading: false,
    errorCode: '',
};

export const newPasswordFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBMIT_NEW_PASSWORD_FORM:
            return {
                ...state,
                isLoading: true,
                errorCode: '',
            };

        case CHANGE_NEW_PASSWORD:
            return {
                ...state,
                newPassword: action.payload,
            };

        case CHANGE_CONFIRM_NEW_PASSWORD:
            return {
                ...state,
                confirmNewPassword: action.payload,
            };

        case NEW_PASSWORD_FORM_SUCCESS:
            return {
                ...state,
                errorCode: '',
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                errorCode: '',
            };

        case NEW_PASSWORD_FORM_ERROR:
            return {
                ...state,
                isLoading: false,
                errorCode: action.payload,
            };

        default:
            return state;
    }
};
