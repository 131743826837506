import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ButtonAction} from '../../../../components/buttons/ButtonAction';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {back} from '../../../../lib/router/connected-router-saga';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';

const modalKey = ModalsKeys.TRAINING_DISABLED;

const TrainingDisabledModal = () => {
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('training');

    const handleGoBack = () => {
        dispatch(back());
        dispatch(UiActions.setActiveModal(ModalsKeys.TRAINING_DISABLED, false));
    };

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(21),
                        fontWeight: 700,
                        letterSpacing: '0.25px',
                    }}
                >
                    {t('disabledModal.title')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <ButtonAction
                    startIcon={<ArrowBackIcon />}
                    fullWidth={isMobileSize}
                    onClick={handleGoBack}
                    color="primary"
                >
                    {t('disabledModal.button')}
                </ButtonAction>
            )}
            containerSx={{
                width: '446px',
            }}
            hasShowClose={false}
        >
            <>
                <Typography sx={{mb: 2, mt: 3}}>
                    {t('disabledModal.paragraphOne')}
                </Typography>

                <Typography>
                    {t('disabledModal.paragraphTwo')}
                </Typography>
            </>
        </ModalWrapper>
    );
};

export default TrainingDisabledModal;
