import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Box} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Toast} from '../../../../../../../../lib/toast';
import {copyToClipboard} from '../../../../../../../../utils/copy-to-clipboard';

const InfoSidebarHeader = ({data}) => {
    const date = data?.date ?? data?.createdAt;
    const id = data?.integrationId ? data.integrationId.slice(0, 7) + '...' : '';

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    color: 'grey.light',
                    my: 1.5,
                    borderRadius: '8px',
                }}
            >
                <Box>
                    {moment(date).format('DD MMM YYYY · HH.mm')} · {id}
                </Box>
                <ContentCopyIcon
                    onClick={() => {
                        copyToClipboard(data?.integrationId);
                        Toast.success('copiedToClipboard');
                    }}
                    sx={{
                        'cursor': 'pointer',
                        'ml': 0.5,
                        '&:hover': {
                            color: 'v2.blueGray.300',
                        },
                    }}
                />
            </Box>

        </>
    );
};

InfoSidebarHeader.propTypes = {
    data: PropTypes.object.isRequired,
};

export default InfoSidebarHeader;
