import {combineReducers} from 'redux';
import * as actions from './contract.actions';
import {LOCATION_CHANGED} from '../../../../lib/router/connected-router-saga';

const initialState = {
    documentId: null,
    signatureDate: null,
    documentUrl: null,
    isLoading: true,
};

const documentIdReducer = (state = initialState.documentId, action) => {
    if (action.type === actions.STORE_CONTRACT_DOCUMENT_ID) {
        return action.payload;
    }

    return state;
};

const documentUrlReducer = (state = initialState.documentUrl, action) => {
    if (action.type === actions.STORE_CONTRACT_DOCUMENT_URL) {
        return action.payload;
    }

    return state;
};

const signatureDateReducer = (state = initialState.signatureDate, action) => {
    if (action.type === actions.STORE_CONTRACT_SIGNATURE_DATE) {
        return action.payload;
    }

    return state;
};

const isLoadingReducer = (state = initialState.isLoading, action) => {
    if (action.type === actions.SET_IS_LOADING_CONTRACT_DATA) {
        return action.payload;
    }

    if (action.type === LOCATION_CHANGED) {
        return initialState.isLoading;
    }

    return state;
};

export const contractDataReducer = combineReducers({
    documentId: documentIdReducer,
    signatureDate: signatureDateReducer,
    documentUrl: documentUrlReducer,
    isLoading: isLoadingReducer,
});
