import {Auth} from 'aws-amplify';
import {all, call, put, takeLatest} from 'redux-saga/effects';

import {ChangePasswordActionTypes, ChangePasswordActions} from './changePasswordForm.actions';
import {push} from '../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';

const changePasswordWorker = function* changePasswordWorker({payload}) {
    yield put(ChangePasswordActions.setIsLoadingChangePasswordForm(true));

    try {
        const {currentPassword, newPassword} = payload;

        const user = yield call([Auth, Auth.currentAuthenticatedUser]);

        yield call([Auth, Auth.changePassword], user, currentPassword, newPassword);

        Toast.success('passwordChanged');

        yield put(push(RoutePaths.MY_PROFILE));
    } catch (error) {
        if (error?.code === 'NotAuthorizedException') {
            Toast.error('passwordIncorrect');

            return;
        }

        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(ChangePasswordActions.setIsLoadingChangePasswordForm(false));
    }
};

export const watchChangePasswordSagas = function* () {
    yield all([
        takeLatest(ChangePasswordActionTypes.SUBMIT_CHANGE_PASSWORD_FORM, changePasswordWorker),
    ]);
};
