/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {styled, useThemeProps} from '@mui/system';
import clsx from 'clsx';
import * as React from 'react';
import {TransitionGroup} from 'react-transition-group';
import {getPickersFadeTransitionGroupUtilityClass} from './pickersFadeTransitionGroupClasses';
import Fade from '../../mui-legacy/Fade';

const useUtilityClasses = ownerState => {
    const {classes} = ownerState;
    const slots = {
        root: ['root'],
    };

    return composeClasses(
        slots,
        getPickersFadeTransitionGroupUtilityClass,
        classes,
    );
};

const animationDuration = 500;

const PickersFadeTransitionGroupRoot = styled(TransitionGroup, {
    name: 'MuiPickersFadeTransitionGroup',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})({
    display: 'block',
    position: 'relative',
});

export const PickersFadeTransitionGroup = inProps => {
    const props = useThemeProps({
        props: inProps,
        name: 'MuiPickersFadeTransitionGroup',
    });
    const {children, className, reduceAnimations, transKey} = props;
    const classes = useUtilityClasses(props);
    if (reduceAnimations) {
        return children;
    }

    return (
        <PickersFadeTransitionGroupRoot className={clsx(classes.root, className)}>
            <Fade
                appear={false}
                mountOnEnter
                unmountOnExit
                key={transKey}
                timeout={{
                    appear: animationDuration,
                    enter: animationDuration / 2,
                    exit: 0,
                }}
            >
                {children}
            </Fade>
        </PickersFadeTransitionGroupRoot>
    );
};
