import {CardsActionType} from './cards.action-type';

export const
    CardsActions = {
        getCards: data => ({
            type: CardsActionType.GET_CARDS,
            payload: data,
        }),
        storeCards: data => ({
            type: CardsActionType.STORE_LIST,
            payload: data,
        }),
        create: data => ({
            type: CardsActionType.CREATE,
            payload: data,
        }),
        update: (data, cardId) => ({
            type: CardsActionType.UPDATE,
            payload: {data, cardId},
        }),
        delete: data => ({
            type: CardsActionType.DELETE,
            payload: data,
        }),
        getCountries: () => ({
            type: CardsActionType.GET_COUNTRIES,
        }),
        storeCountries: countries => ({
            type: CardsActionType.STORE_COUNTRIES,
            payload: countries,
        }),
        activatePhysCard: (id, code) => ({
            type: CardsActionType.ACTIVATE_PHYS_CARD,
            payload: {id, code},
        }),
        viewPin: id => ({
            type: CardsActionType.VIEW_PIN,
            payload: {id},
        }),
        lockCard: id => ({
            type: CardsActionType.LOCK_CARD,
            payload: {id},
        }),
        unlockCard: id => ({
            type: CardsActionType.UNLOCK_CARD,
            payload: {id},
        }),
        showNumbers: (id, isPhysical) => ({
            type: CardsActionType.SHOW_NUMBERS,
            payload: {id, isPhysical},
        }),
    };
