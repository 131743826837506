import EditIcon from '@mui/icons-material/Edit';
import EuroIcon from '@mui/icons-material/Euro';
import {Box, Button, CircularProgress, TextField, ToggleButton, ToggleButtonGroup, useMediaQuery} from '@mui/material';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {useSelector} from 'react-redux';
import {LoadingSelectors, LoadingTypes} from '../../../../../../../loading';
import {TVA_ENUM, emptyFunction} from '../../../../util/constants';

const TvaSelect = ({
    tva,
    tvaAmount,
    details,
    onTvaChange,
    onTvaAmountChange,
    handleOpenArticles,
    isEditable,
    isAmountInNewRow,
}) => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    let tvaValue = tva;
    if (tva === TVA_ENUM.TVA_16) {
        tvaValue = TVA_ENUM.TVA_CUSTOM;
    } else if (tva === TVA_ENUM.NO_VAT) {
        tvaValue = TVA_ENUM.TVA_0;
    }

    const isTvaCustom = tvaValue === TVA_ENUM.TVA_CUSTOM;
    const [cachedValue, setCachedValue] = useState(tvaAmount);

    // Multiple articles are disabled for subscription atm
    const hasMultipleArticles = details?.articles?.length > 1 && !details?.flags?.subscriptionCategorizationSupport;

    const updatingTVAInProgress = useSelector(LoadingSelectors.createLoadingSelectorByType(LoadingTypes.BANK_ARTICLES));

    const isEditableAndNotLoading = isEditable && !updatingTVAInProgress;

    const handleTvaChange = (_, newTVAValue) => {
        if (newTVAValue && newTVAValue !== tva) {
            onTvaChange(newTVAValue);
        }
    };

    const handleTvaValueChange = debounce(({value}) => {
        // It is ok for us to compare string with number in this case
        // if either of the values is number in string format.
        // eslint-disable-next-line eqeqeq
        if (value == tvaAmount || updatingTVAInProgress) {
            // Do not update if the values already match!
            return;
        }

        if (isTvaCustom) {
            onTvaAmountChange(value);
            setCachedValue(value);
        }
    },
    800);

    return (
        <Box sx={{
            display: 'flex',
            gap: 2,
            alignSelf: 'stretch',
            flexDirection: isMobileSize || isAmountInNewRow ? 'column' : 'row',
        }}
        >
            {hasMultipleArticles ? (
                <Box sx={{
                    'flex': '1 0 0',
                }}
                >
                    <Button
                        startIcon={<EditIcon />}
                        variant="outlined"
                        fullWidth
                        color="primary"
                        onClick={handleOpenArticles}
                    >
                        {t('categorisation.articles.editArticles')}
                    </Button>
                </Box>
            ) : (
                <ToggleButtonGroup
                    value={tvaValue}
                    exclusive
                    onChange={handleTvaChange}
                    sx={{
                        'height': '50px',
                        'display': 'flex',
                        'flex': '1 0 0',
                        'borderRadius': 1,
                        'backgroundColor': '#fff',
                        '& > .MuiButtonBase-root': {
                            flex: '1 0 0',
                            fontSize: theme => theme.typography.pxToRem(18),
                            color: !isEditableAndNotLoading ? 'v2.light.text.disabled' : 'inherit',
                            fontWeight: 400,
                        },
                        '& > .MuiButtonBase-root.Mui-selected': {
                            color: isEditableAndNotLoading ? 'whitePallete.main' : 'v2.blueGray.500',
                            backgroundColor: isEditableAndNotLoading ? 'v2.light.primary.main' : 'v2.blueGray.100',
                        },
                        '& > .MuiButtonBase-root.Mui-selected:hover': {
                            color: isEditableAndNotLoading ? 'whitePallete.main' : 'v2.blueGray.500',
                            backgroundColor: isEditableAndNotLoading ? 'v2.light.primary.main' : 'v2.blueGray.100',
                        },
                        '& > .MuiSvgIcon-root': {
                            color: 'v2.light.text.disabled',
                        },
                        '& > .MuiButtonBase-root.Mui-selected > .MuiSvgIcon-root': {
                            color: isEditableAndNotLoading ? 'white' : 'v2.blueGray.500',
                        },
                    }}
                >
                    <ToggleButton
                        value={TVA_ENUM.TVA_0}
                        disabled={!isEditableAndNotLoading}
                        sx={{
                            borderTopLeftRadius: 4,
                            borderBottomLeftRadius: 4,
                        }}
                    >
                        {t(`vat.TVA_0_v2`)}
                    </ToggleButton>
                    <ToggleButton value={TVA_ENUM.TVA_5} disabled={!isEditableAndNotLoading}>
                        {t(`vat.TVA_5`)}
                    </ToggleButton>
                    <ToggleButton value={TVA_ENUM.TVA_10} disabled={!isEditableAndNotLoading}>
                        {t(`vat.TVA_10`)}
                    </ToggleButton>
                    <ToggleButton value={TVA_ENUM.TVA_20} disabled={!isEditableAndNotLoading}>
                        {t(`vat.TVA_20`)}
                    </ToggleButton>
                    <ToggleButton
                        value={TVA_ENUM.TVA_CUSTOM}
                        disabled={!isEditableAndNotLoading}
                        sx={{
                            borderTopRightRadius: 4,
                            borderBottomRightRadius: 4,
                        }}
                    >
                        <EditIcon fontSize="small" />
                    </ToggleButton>
                </ToggleButtonGroup>
            )}

            <NumberFormat
                customInput={TextField}
                decimalSeparator="."
                thousandSeparator={false}
                allowNegative={false}
                decimalScale={2}
                disabled={!isEditableAndNotLoading || !isTvaCustom}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        height: '50px',
                        width: isMobileSize || isAmountInNewRow ? 'auto' : '130px',
                    },
                }}
                fullWidth={isMobileSize || isAmountInNewRow}
                size="small"
                label={t('translationDetails.amountLabel')}
                InputProps={{
                    endAdornment: updatingTVAInProgress
                        ? (<CircularProgress size="24px" style={{position: 'absolute', top: '12px', right: '12px'}} />)
                        : (
                            <EuroIcon sx={{
                                color: tva === TVA_ENUM.TVA_CUSTOM
                                    ? 'v2.blueGray.500'
                                    : 'v2.light.text.disabled',
                            }}
                            />
                        ),
                }}
                value={updatingTVAInProgress ? cachedValue : tvaAmount}
                onValueChange={handleTvaValueChange}
            />
        </Box>
    );
};

TvaSelect.propTypes = {
    tva: PropTypes.string.isRequired,
    tvaAmount: PropTypes.number.isRequired,
    onTvaChange: PropTypes.func.isRequired,
    onTvaAmountChange: PropTypes.func.isRequired,
    isEditable: PropTypes.bool,
    isAmountInNewRow: PropTypes.bool,
    details: PropTypes.object,
    handleOpenArticles: PropTypes.func,
};

TvaSelect.defaultProps = {
    isEditable: true,
    isAmountInNewRow: false,
    details: null,
    handleOpenArticles: emptyFunction,
};

export default TvaSelect;
