import {saveAs} from 'file-saver';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './businessAllowance.actions';
import {selectBusinessAllowanceRecaps} from './businessAllowance.selectors';
import {ROLES} from '../../../../features/auth/store/auth.constants';
import {selectCurrentCognitoUser} from '../../../../features/auth/store/auth.selectors';
import {FreelancerSelectors} from '../../../../features/freelancer';
import {Toast} from '../../../../lib/toast';
import {getSignedUrlForDocumentRequest} from '../../api/providers/company/company.provider';
import {
    deleteBusinessAllowanceRecapRequest,
    deleteBusinessAllowanceTravelRequest,
    generateBusinessAllowanceRecapRequest,
    getBusinessAllowanceRecapsRequest,
    getBusinessAllowanceTravelsRequest,
    updateBusinessAllowanceRecapRequest,
} from '../../api/providers/expenses/expenses.provider';
import {isMobileSafari, isSafari} from '../../app.helpers';
import {loadFreelancerAccountSaga} from '../../freelancer/freelancer.sagas';
import {selectFreelancerAccount} from '../../freelancer/freelancer.selectors';

const getBusinessAllowanceTravelsSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingBusinessAllowanceTravels(true));

        const travels = yield call(
            getBusinessAllowanceTravelsRequest,
            payload.freelancerId,
            payload.companyId,
        );

        yield put(actions.storeBusinessAllowanceTravels(travels));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingBusinessAllowanceTravels(false));
    }
};

const getBusinessAllowanceRecapsSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingBusinessAllowanceRecaps(true));

        const recaps = yield call(
            getBusinessAllowanceRecapsRequest,
            payload.freelancerId,
            payload.companyId,
        );

        yield put(actions.storeBusinessAllowanceRecaps(recaps));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingBusinessAllowanceRecaps(false));
    }
};

export const deleteBusinessAllowanceTravelSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingBusinessAllowanceTravels(true));

        yield call(
            deleteBusinessAllowanceTravelRequest,
            payload.freelancerId,
            payload.companyId,
            payload.travelId,
        );

        yield put(actions.getBusinessAllowanceTravels(payload.freelancerId, payload.companyId));

        Toast.success('businessKmTravelDeleted');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');

        yield put(actions.setIsLoadingBusinessAllowanceTravels(false));
    }
};

export const generateBusinessAllowanceRecapSaga = function* () {
    try {
        yield put(actions.setIsGeneratingRecap(true));

        const {id} = yield select(selectCurrentCognitoUser);
        const {defaultCompanyId} = yield select(selectFreelancerAccount);

        yield call(
            generateBusinessAllowanceRecapRequest,
            id,
            defaultCompanyId,
        );

        Toast.success('recapGenerated');

        yield put(actions.getBusinessAllowanceRecaps(id, defaultCompanyId));

        yield put(actions.storeBusinessAllowanceTravels({}));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsGeneratingRecap(false));
    }
};

export const deleteBusinessAllowanceRecapSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingBusinessAllowanceRecaps(true));

        yield call(
            deleteBusinessAllowanceRecapRequest,
            payload.freelancerId,
            payload.companyId,
            payload.recapId,
        );

        const recaps = yield select(selectBusinessAllowanceRecaps);

        const newRecaps = Object.keys(recaps)
            .filter(recapId => recapId !== payload.recapId)
            .reduce((result, current) => {
                result[current] = recaps[current];

                return result;
            }, {});

        yield put(actions.storeBusinessAllowanceRecaps(newRecaps));

        Toast.success('recapDeleted');

        yield put(actions.getBusinessAllowanceTravels(payload.freelancerId, payload.companyId));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingBusinessAllowanceRecaps(false));
    }
};

const updateBusinessAllowanceRecapSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingBusinessAllowanceRecaps(true));

        const recap = yield call(
            updateBusinessAllowanceRecapRequest,
            payload.freelancerId,
            payload.companyId,
            payload.recapId,
            payload.isReimbursed,
        );

        const recaps = yield select(selectBusinessAllowanceRecaps);

        yield put(actions.storeBusinessAllowanceRecaps({
            ...recaps,
            [recap.id]: recap,
        }));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingBusinessAllowanceRecaps(false));
    }
};

const downloadBusinessAllowanceRecapSaga = function* ({payload}) {
    try {
        let childWindow;

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow = window.open('', '_blank');
        }

        yield put(actions.setIsLoadingBusinessAllowanceRecaps(true));

        const {signedUrl} = yield call(
            getSignedUrlForDocumentRequest,
            payload.freelancerId,
            payload.companyId,
            payload.documentId,
            payload.isDownload,
        );

        if (!signedUrl) {
            throw new Error('The document URL is missing.');
        }

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow.location = signedUrl;

            return;
        }

        if ((isSafari || isMobileSafari) && payload.isDownload) {
            // TODO:HIGH: It's ugly but it works.
            fetch(signedUrl).then(response => {
                return response.blob();
            }).then(blob => {
                const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
                const filename = matchedGroups[2];

                saveAs(blob, decodeURI(filename));
            });

            return;
        }

        window.open(signedUrl, '_blank');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingBusinessAllowanceRecaps(false));
    }
};

export const businessAllowanceLoaderSaga = function* ({payload}) {
    const {params = {}} = payload || {};
    let {companyId, freelancerId} = params;
    const {role} = yield select(selectCurrentCognitoUser);

    if (!companyId || !freelancerId) {
        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const {id} = yield select(selectCurrentCognitoUser);

        freelancerId = id;

        companyId = freelancer.defaultCompanyId;
    }

    yield call(loadFreelancerAccountSaga, freelancerId);

    if (role === ROLES.FREELANCER || role === ROLES.CARE) {
        yield put(actions.getBusinessAllowanceTravels(freelancerId, companyId));
    }

    yield put(actions.getBusinessAllowanceRecaps(freelancerId, companyId));
};

export const watchBusinessAllowanceSagas = function* () {
    yield all([
        takeLatest(actions.GET_BUSINESS_ALLOWANCE_TRAVELS, getBusinessAllowanceTravelsSaga),
        takeLatest(actions.GET_BUSINESS_ALLOWANCE_RECAPS, getBusinessAllowanceRecapsSaga),
        takeLatest(actions.DELETE_BUSINESS_ALLOWANCE_TRAVEL, deleteBusinessAllowanceTravelSaga),
        takeLatest(actions.GENERATE_BUSINESS_ALLOWANCE_RECAP, generateBusinessAllowanceRecapSaga),
        takeLatest(actions.DELETE_BUSINESS_ALLOWANCE_RECAP, deleteBusinessAllowanceRecapSaga),
        takeLatest(actions.UPDATE_BUSINESS_ALLOWANCE_RECAP, updateBusinessAllowanceRecapSaga),
        takeLatest(actions.DOWNLOAD_BUSINESS_ALLOWANCE_RECAP, downloadBusinessAllowanceRecapSaga),
    ]);
};
