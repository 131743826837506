export const DocumentationActionType = {
    STORE_CATEGORIES: 'documentation/STORE_CATEGORIES',
    STORE_SUBCATEGORIES: 'documentation/STORE_SUBCATEGORIES',
    STORE_ARTICLES: 'documentation/STORE_ARTICLES',
    STORE_VIDEOS: 'documentation/STORE_VIDEOS',
    STORE_FAQS: 'documentation/STORE_FAQS',
    STORE_DETAILS: 'documentation/STORE_DETAILS',
    SEARCH: 'documentation/SEARCH',
    STORE_SEARCH_RESULTS: 'documentation/STORE_SEARCH_RESULTS',
    STORE_VOTE_RESULT: 'documentation/STORE_VOTE_RESULT',
    VOTE: 'documentation/VOTE',
    STORE_POPULAR_ARTICLES: 'documentation/STORE_POPULAR_ARTICLES',
    STORE_GLOBAL_POPULAR_ARTICLES: 'documentation/STORE_GLOBAL_POPULAR_ARTICLES',
    STORE_OTHER_ITEMS: 'documentation/STORE_OTHER_ITEMS',
};
