import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Fab, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import {DocumentSigning} from './DocumentSigning';
import {UploadSignatureModal} from '../../../../signature/components/UploadSignatureModal';
import {useSigningWrapper} from '../hooks/use-signing-wrapper';

export const DocumentSigningWrapper = ({
    type,
    MainActionComponent,
    DocumentsLinkComponent,
    onSignFn,
    onNextFn,
}) => {
    const {
        t,
        dt,
        isMobileSize,
        onPreviousDocumentClick,
        onNextDocumentClick,
        documents,
        currentDocumentId,
        currentDocumentIndex,
        numberOfDocuments,
    } = useSigningWrapper();

    return (
        <Box display="grid" gridTemplateRows="min-content 1fr">
            <Typography
                variant="h4"
                sx={{mb: 1}}
            >
                {t('signing.title')}
            </Typography>

            <Box
                display="grid"
                gridTemplateColumns={!isMobileSize ? '1fr 238px' : '1fr 1fr'}
                gap={2}
                sx={isMobileSize ? {mx: 2} : {}}
            >
                <Typography
                    color="text.disabled"
                    variant="subtitle2"
                    sx={{fontWeight: 'fontWeightMedium', mb: 2}}
                >
                    {!!DocumentsLinkComponent && DocumentsLinkComponent}
                    {!isMobileSize && documents[currentDocumentId] && (
                        <Box component="span" sx={{lineHeight: '30.75px'}}>
                            {dt(`documentsDatabaseNames.${documents[currentDocumentId].type}`)}
                        </Box>
                    )}
                </Typography>

                <Box sx={!isMobileSize
                    ? {display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: -1}
                    : {display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: -2}}
                >
                    <Fab
                        size="medium"
                        color="default"
                        disabled={currentDocumentIndex < 2}
                        aria-label="previous-document"
                        onClick={onPreviousDocumentClick}
                        sx={{
                            background: '#fff',
                            boxShadow: '0px 3px 1px -2px rgba(11, 35, 51, 0.05), 0px 2px 2px rgba(11, 35, 51, 0.07), 0px 1px 5px rgba(11, 35, 51, 0.09);',
                        }}
                    >
                        <ChevronLeftIcon />
                    </Fab>

                    <Typography variant="h5">{currentDocumentIndex} {t('signing.of')} {numberOfDocuments}</Typography>

                    <Fab
                        size="medium"
                        color="default"
                        aria-label="next-document"
                        disabled={currentDocumentIndex === numberOfDocuments}
                        onClick={onNextDocumentClick}
                        sx={{
                            background: '#fff',
                            boxShadow: '0px 3px 1px -2px rgba(11, 35, 51, 0.05), 0px 2px 2px rgba(11, 35, 51, 0.07), 0px 1px 5px rgba(11, 35, 51, 0.09);',
                        }}
                    >
                        <ChevronRightIcon />
                    </Fab>
                </Box>
            </Box>

            <DocumentSigning
                type={type}
                MainActionComponent={MainActionComponent}
                onSignFn={onSignFn}
                onNextFn={onNextFn}
            />

            <UploadSignatureModal />
        </Box>
    );
};

DocumentSigningWrapper.propTypes = {
    type: PropTypes.string,
    MainActionComponent: PropTypes.object,
    DocumentsLinkComponent: PropTypes.object,
    onSignFn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onNextFn: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
DocumentSigningWrapper.defaultProps = {
    type: undefined,
    MainActionComponent: null,
    DocumentsLinkComponent: null,
    onSignFn: null,
    onNextFn: null,
};
