import {faArrowRight, faCheckCircle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Stack, Typography} from '@mui/joy';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, generatePath} from 'react-router-dom';
import {LoadingSkeleton, LoadingSkeletonProvider} from '../../../../../../components/joy-ui/loading-skeleton/LoadingSkeleton';
import {ModalWrapper} from '../../../../../../components/modal-wrapper/ModalWrapper';
import {RoutePaths} from '../../../../../../lib/router/route-paths';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {VATDeclarationActions} from '../../../../../vat-declaration/store/vat-declaration.action';
import {VatDeclarationSelectors} from '../../../../../vat-declaration/store/vat-declaration.selector';
import useVATDeclarationSendProgress from '../../hooks/useVATDeclarationSendProgress';
import {VatDeclarationSendUIState} from '../../utils/consts';

const modalKey = ModalsKeys.PROCESS_SINGLE_VAT_DECLARATION;

const ProcessSingleVatDeclaration = () => {
    const {t} = useTranslation('formalities');
    const dispatch = useDispatch();

    const {uiState, batchProgress: {declarationIds}} = useVATDeclarationSendProgress();
    const declarationId = declarationIds?.[0] ?? undefined;

    const vatDeclarationDetails = useSelector(VatDeclarationSelectors.selectVATDeclaration);
    const isLoadingDeclarationDetails = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_DETAILS),
    );

    const handleViewDeclarationDetails = () => {
        dispatch(UiActions.setActiveModal(modalKey, false));
    };

    // Get declaration details
    useEffect(() => {
        if (declarationId) {
            dispatch(VATDeclarationActions.getVATDeclaration({declarationId}));
        }
    }, [dispatch, declarationId]);

    return (
        <LoadingSkeletonProvider
            isLoading={isLoadingDeclarationDetails
                || uiState === VatDeclarationSendUIState.NONE
                || uiState === VatDeclarationSendUIState.IN_PROGRESS
            }
        >
            <ModalWrapper
                isV3
                modalKey={modalKey}
                hasShowClose
                title={(
                    <LoadingSkeleton variant="rectangular">
                        <Typography level="h1">
                            {t(`vatDeclaration.sendDeclarationsModal.sendSingleDeclaration.statusData.${uiState}.title`)}
                        </Typography>
                    </LoadingSkeleton>
                )}
                containerSx={{
                    width: '580px',
                }}
            >
                <LoadingSkeleton variant="rectangular" sx={{minHeight: '280px'}}>
                    {uiState === VatDeclarationSendUIState.COMPLETED && (
                        <Stack spacing={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Stack spacing={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Typography color="success" sx={{fontSize: '96px'}}>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </Typography>
                                <Typography level="h1">
                                    {t(`vatDeclaration.sendDeclarationsModal.sendSingleDeclaration.statusData.${uiState}.title`)}
                                </Typography>
                                <Typography sx={{color: 'text.secondary'}} level="body-lg">
                                    {t(`vatDeclaration.sendDeclarationsModal.sendSingleDeclaration.statusData.${uiState}.message`)}
                                </Typography>
                            </Stack>
                            <Button
                                color="danger"
                                variant="soft"
                                onClick={() => {
                                    dispatch(UiActions.setActiveModal(modalKey, false));
                                }}
                            >
                                {t('vatDeclaration.sendDeclarationsModal.ctaClose')}
                            </Button>
                        </Stack>
                    )}
                    {uiState === VatDeclarationSendUIState.COMPLETED_WITH_ERRORS && (
                        <Stack spacing={4}>
                            <Typography sx={{color: 'text.secondary'}} level="body-lg">
                                {t(`vatDeclaration.sendDeclarationsModal.sendSingleDeclaration.statusData.${uiState}.message`)}
                            </Typography>
                            {vatDeclarationDetails?.validationResults?.errors.map(error => (
                                <Alert
                                    key={error}
                                    variant="soft"
                                    color="danger"
                                    startDecorator={<FontAwesomeIcon icon={faExclamationCircle} />}
                                >
                                    {t(`vatDeclaration.validationErrors.${error}`)}
                                </Alert>
                            ))}
                            <Button
                                sx={{flex: 1}}
                                color="primary"
                                endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                                component={Link}
                                to={generatePath(RoutePaths.VAT_DECLARATION_RESULTS, {declarationId})}
                                onClick={handleViewDeclarationDetails}
                            >
                                {t('vatDeclaration.sendDeclarationsModal.ctaVerify')}
                            </Button>
                        </Stack>
                    )}
                </LoadingSkeleton>
            </ModalWrapper>
        </LoadingSkeletonProvider>
    );
};

export default ProcessSingleVatDeclaration;
