import makeStyles from '@mui/styles/makeStyles';

export const useChangePasswordFormStyles = makeStyles(theme => ({
    label: {
        color: theme.palette.text_v2.primary,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(16),
        marginBottom: theme.spacing(2),
    },
    row: {
        marginBottom: theme.spacing(4),
    },
    form: {
        padding: theme.spacing(1, 0),
    },
    divider: {
        margin: theme.spacing(3, -3),
    },
    footerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(3, 3, 2, 3),
    },
}));

