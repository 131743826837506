import {Box, Typography, useMediaQuery} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import AccountStatementsTooltip from './AccountStatementsTooltip';
import DownloadAllAsZipButton from '../DownloadAllAsZipButton';

const AccountStatementsDataTableHeader = () => {
    const {t} = useTranslation('bankRibAndDocuments');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box
            component="div"
            sx={{
                display: 'flex',
                flexDirection: isMobileSize ? 'column' : 'row',
                alignItems: isMobileSize ? 'flex-start' : 'center',
                justifyContent: 'space-between',
                pl: 3,
                pr: 2,
                pt: 2,
                pb: 1,
            }}
        >
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <Typography
                    variant="h5"
                    color="text_v2.primary"
                >
                    {t('accountStatementsTable.tableTitle')}
                </Typography>
                <AccountStatementsTooltip />
            </Box>
            <DownloadAllAsZipButton />
        </Box>
    );
};

export default AccountStatementsDataTableHeader;
