import axiosInstance, {safeTransformData} from '../../../../../v1/app/api/axios';

const createPayslip = (freelancerId, companyId, data) => {
    const formData = new FormData();

    if (data.pension_contract_doc && Array.isArray(data.pension_contract_doc) && data.pension_contract_doc.length > 0) {
        formData.append('pension_contract_doc', data?.pension_contract_doc[0]);
    }
    if (data.dsn_doc && Array.isArray(data.dsn_doc) && data.dsn_doc.length > 0) {
        formData.append('dsn_doc', data?.dsn_doc[0]);
    }

    if (data.payroll_configuration) {
        formData.append('payroll_configuration[start_date]', data?.payroll_configuration?.start_date);
        formData.append('payroll_configuration[gross_monthly_salary]', data?.payroll_configuration?.gross_monthly_salary);
        formData.append('payroll_configuration[iban]', data?.payroll_configuration?.iban);
        formData.append('payroll_configuration[bic]', data?.payroll_configuration?.bic);
    }

    if (data.pay_history) {
        formData.append('pay_history[type]', data?.pay_history?.type);
        if (data?.pay_history?.configuration) {
            formData.append(
                'pay_history[configuration][last_month_paid_start_date]',
                data?.pay_history?.configuration?.last_month_paid_start_date,
            );
            formData.append(
                'pay_history[configuration][number_of_months_already_paid]',
                data?.pay_history?.configuration?.number_of_months_already_paid,
            );
            formData.append(
                'pay_history[configuration][gross_remuneration]',
                data?.pay_history?.configuration?.gross_remuneration,
            );
            formData.append(
                'pay_history[configuration][net_payable]',
                data?.pay_history?.configuration?.net_payable,
            );
            formData.append(
                'pay_history[configuration][net_taxable]',
                data?.pay_history?.configuration?.net_taxable,
            );
            formData.append(
                'pay_history[configuration][csg_amount]',
                data?.pay_history?.configuration?.csg_amount,
            );

            if (data?.pay_history?.configuration?.annual_gross_remuneration) {
                formData.append(
                    'pay_history[configuration][annual_gross_remuneration]',
                    data?.pay_history?.configuration?.annual_gross_remuneration,
                );
            }
            if (data?.pay_history?.configuration?.annual_net_payable) {
                formData.append(
                    'pay_history[configuration][annual_net_payable]',
                    data?.pay_history?.configuration?.annual_net_payable,
                );
            }
            if (data?.pay_history?.configuration?.annual_net_taxable) {
                formData.append(
                    'pay_history[configuration][annual_net_taxable]',
                    data?.pay_history?.configuration?.annual_net_taxable,
                );
            }
        }
    }

    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/pay-info`,
        data: formData,
    }).then(response => safeTransformData(response));
};

const getPayslip = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/pay-info`,
    }).then(response => safeTransformData(response));
};

const updatePayslip = (freelancerId, companyId, data) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/pay-info`,
        data,
    }).then(response => safeTransformData(response));
};

const reinitializePayslip = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/pay-info/reset`,
    }).then(response => safeTransformData(response));
};

export const PayslipApi = {
    createPayslip,
    getPayslip,
    updatePayslip,
    reinitializePayslip,
};
