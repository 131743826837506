import {Grid} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {useProfileChipStyles} from './styles';

const ProfileChip = ({userId}) => {
    const styles = useProfileChipStyles();
    const {t} = useTranslation('users');

    return (
        <Grid container spacing={0} sx={{mb: 2, mt: 1}}>
            <Grid item xs={12} sm="auto">
                <Box component="span" sx={{color: 'text.disabled'}} className={styles.userIdLabel}>
                    {t('myId')}:&nbsp;
                </Box>
            </Grid>
            <Grid item xs={12} sm="auto">
                <Box component="span" sx={{color: 'elephant.main'}} className={styles.userId}>
                    {userId}
                </Box>
            </Grid>
        </Grid>
    );
};

ProfileChip.propTypes = {
    userId: PropTypes.string.isRequired,
};

export default ProfileChip;
