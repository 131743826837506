import {Avatar} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ReactRouterLink from '../../../v1/components/app/Router/ReactRouterLink';
import {resolveRoute} from '../../../v1/navigation/resolveRoute';
import {ROUTE_PATHS} from '../../../v1/navigation/routePaths';

const Coach = ({rowData}) => {
    if (!rowData?.manager) {
        return null;
    }

    const {id, firstName, lastName} = rowData.manager;

    return (
        <ReactRouterLink
            to={resolveRoute(ROUTE_PATHS.USER, {userId: id})}
            sx={{'&:hover': {textDecoration: 'none'}}}
        >
            <Avatar>
                {`${(firstName ?? '').substr(0, 1).toUpperCase()}${(lastName ?? '').substr(0, 1).toUpperCase()}`}
            </Avatar>
        </ReactRouterLink>
    );
};

Coach.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default Coach;
