import {Box} from '@mui/material';
import useResizeObserver from '@react-hook/resize-observer';
import PropTypes from 'prop-types';
import {useCallback, useRef, useState} from 'react';
import OCRPreviewPdf from './OCRPreviewPdf';
import {drawRect} from '../../../../../../../../lib/tdf-canvas/renderer';
import TDFCanvas from '../../../../../../../../lib/tdf-canvas/TDFCanvas';

const OCRView = ({document, documentsData, isLoading, isDocumentPdf}) => {
    const documentContainerRef = useRef();

    const [documentSize, setDocumentSize] = useState({width: 0, height: 0});

    // TODO: Handle scroll when we start supporting multi page PDF
    // const [pdfPageScroll, setPdfPageScroll] = useState({top: 0, left: 0});
    const [pdfPageScroll] = useState({top: 0, left: 0});

    const documentHighlights = documentsData.flatMap(({elements}, pageOffset) => elements
        .filter((element, _, elements) => {
            // TODO: Probably extract type somewhere
            if (element.type === 'subtotal') {
                return !elements.find(e => e.type === 'vat' && !!e.position);
            }

            return !!element.position;
        })
        .map(element => {
            const width = element.position.width * documentSize.width;
            return drawRect({
                width,
                height: element.position.height * documentSize.height,
                x: element.position.left * documentSize.width,
                y: (documentSize.height * pageOffset) + documentSize.height * element.position.top - pdfPageScroll.top,
                customStyle: {
                    fillStyle: 'rgba(0,255,0,0.2)',
                    strokeStyle: 'rgba(0,255,0,1)',

                    // Dynamic line width so that smaller elements are not obstructed
                    // by too wide an outline
                    lineWidth: (width / documentSize.width) * 10,
                },
            });
        }))
     ?? [];

    const handleUpdateDocumentSize = useCallback((width, height) => {
        setDocumentSize({width, height});
    }, []);

    useResizeObserver(documentContainerRef, entry => {
        setDocumentSize({width: entry.contentRect.width, height: entry.contentRect.height});
    });

    // OCR: When input is 'image'
    if (!isDocumentPdf) {
        return (
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    position: 'relative',
                    flex: 1,
                    overflow: 'auto',
                }}
            >
                <img
                    alt="invoice"
                    ref={documentContainerRef}
                    src={document}
                    width="100%"
                    style={{
                        objectFit: 'contain',
                        objectPosition: 'center',
                        display: 'block',
                        width: '100%',
                    }}
                />
                {!isLoading && (
                    <TDFCanvas
                        shapes={documentHighlights}
                        width={documentSize.width}
                        height={documentSize.height}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 999,
                            pointerEvents: 'none',
                        }}
                    />
                )}
            </Box>
        );
    }

    // OCR: When input is 'pdf'
    return (
        <Box
            sx={{
                position: 'relative',
                height: '100%',
                width: '100%',
            }}
        >
            <OCRPreviewPdf
                url={document}
                onUpdateDocumentSize={handleUpdateDocumentSize}
                documentHighlights={documentHighlights}
            />
        </Box>
    );
};

OCRView.propTypes = {
    document: PropTypes.string.isRequired,
    documentsData: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isDocumentPdf: PropTypes.bool.isRequired,
};

export default OCRView;
