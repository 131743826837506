import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
    getDocumentDownloadTrainingValidatedTableConfig,
} from '../../../../v1/components/company/document-generation-table-config';
import DataTable from '../../../../v1/components/ui-kit/DataTable/DataTable';
import {DatabaseSelectors} from '../../../document/modules/database/store/database.selector';
import {TRAINING_CONSTANTS} from '../../utils/constants';
import MobileTrainingDocuments from '../general/MobileTrainingDocuments';
import MyDocumentsAlert from '../general/MyDocumentsAlert';

export const FundingApproved = () => {
    const {t} = useTranslation('training');
    const {t: tDocuments} = useTranslation('documents');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const columnConfig = useMemo(() => {
        return getDocumentDownloadTrainingValidatedTableConfig(tDocuments);
    }, [tDocuments]);

    return (
        <Box>
            {isMobileSize ? (
                <MobileTrainingDocuments hasActions={true} step={4} />
            ) : (
                <>
                    <Box sx={{mb: -1, textAlign: 'left', fontWeight: 600}}>
                        {t('fundingApproval.acceptedText')}
                    </Box>
                    <Box sx={TRAINING_CONSTANTS.TABLE_STYLE_WRAPPER}>
                        <DataTable
                            tableId="documentGeneration"
                            columnConfig={columnConfig}
                            missingDataMessage={tDocuments('missingDocuments')}
                            dataSelector={DatabaseSelectors.createDocumentsDataTableSelector}
                            isLoading={false}
                            defaultRowsPerPage={25}
                            searchBy={['docName', 'fileName']}
                            searchPlaceholder={tDocuments('documentsDatabaseTable.searchDocuments')}
                            hasSearch={false}
                            hasPagination={false}
                        />
                    </Box>
                </>
            )}

            <MyDocumentsAlert />
        </Box>
    );
};
