import moment from 'moment';
import {combineReducers} from 'redux';

import * as actions from './companyDashboard.actions';
import {createReducer} from '../../createReducer';

const initialState = {
    cacheManagement: {
        vatDebt: 0,
        csgDebt: 0,
        csgN1Debt: 0,
        minimumCashThreshold: 0,
    },
    income: {
        paid: 0,
        total: 0,
        plannedTotal: 0,
        chartData: [{
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: true,
        }],
    },
    taxIncome: {
        personalTax: 0,
        householdTax: 0,
    },
    turnover: {
        balance: [{
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: true,
        }],
        currentYearTotal: 0,
    },
    month: moment().format('MM/YYYY'),
    isValidationPending: false,
};

export const companyDashboardReducer = combineReducers({
    isLoading: createReducer(true, actions.SET_IS_LOADING_COMPANY_DASHBOARD_DATA, true),
    data: createReducer(initialState, actions.STORE_COMPANY_DASHBOARD_DATA, true),
});
