import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import PacmanGhosts from './PacmanGhosts';
import {PacmanDivider} from './PacmanGrid';
import PacmanMain from './PacmanMain';

const animationSpeed = 2000;
const animationSpeedInSeconds = animationSpeed / 1000;

const lastStep = 3;

const zIndex = {
    pacmanMain: 7,
    divider: 5,
    ghosts: 6,
    background: 9,
};

const Pacman = ({step, isMobileSize}) => {
    const containerRef = useRef();
    const [containerWidth, setWidth] = useState(isMobileSize ? 300 : 550);

    useEffect(() => {
        // Function to update the width state with the current width of the div
        const updateWidth = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.getBoundingClientRect().width - 50);
            }
        };

        setTimeout(() => {
            // Call the function to set the initial width
            updateWidth();
        }, 300);

        // Optionally, you can add an event listener to handle window resize
        window.addEventListener('resize', updateWidth);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);


    return (
        <Box
            ref={containerRef}
            sx={{
                'overflow': 'hidden',
                'borderRadius': 6,
                'backgroundColor': '#fff',
                'px': 2,
                'py': 1,
                'display': 'flex',
                'alignItems': 'center',
                'height': '32px',
                '& img': {
                    display: 'block',
                },
            }}
        >
            {/* <PacmanBackground */}
            {/*    step={step} */}
            {/*    duration={animationSpeedInSeconds} */}
            {/*    zIndex={zIndex.background} */}
            {/*    containerWidth={containerWidth} */}
            {/* /> */}

            <Box sx={{
                position: 'absolute',
                zIndex: zIndex.divider,
                height: '16px',
                visibility: step === lastStep ? 'hidden' : 'visible',
                transition: `0s linear ${animationSpeedInSeconds}s`,
            }}
            >
                <PacmanDivider
                    quantity={Math.floor((containerWidth - 16) / 18)}
                    isMobileSize={isMobileSize}
                />
            </Box>

            <Box sx={{
                position: 'absolute',
                zIndex: zIndex.pacmanMain,
            }}
            >
                <PacmanMain
                    step={step}
                    duration={animationSpeedInSeconds}
                    containerWidth={containerWidth}
                    steps={lastStep}
                />
            </Box>

            <PacmanGhosts
                zIndex={zIndex}
                animationSpeed={animationSpeed}
                step={step}
                steps={lastStep}
                containerWidth={containerWidth}
            />
        </Box>
    );
};


Pacman.propTypes = {
    step: PropTypes.number,
    isMobileSize: PropTypes.bool,
};

Pacman.defaultProps = {
    step: 0,
    isMobileSize: false,
};

export default Pacman;
