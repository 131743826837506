import PropTypes from 'prop-types';
import React from 'react';

import {useSelector} from 'react-redux';
import {LoadingSelectors, LoadingTypes} from '../../../../features/loading';
import {DOCUMENT_STATUSES} from '../../../config/constants/documentConstants';
import DocumentStatus from '../../ui-kit/DocumentStatus/DocumentStatus';

const DocumentStatusCellTraining = ({rowData}) => {
    const document = {...rowData};

    // TODO Remove once BE has GENERATED status
    if (document.status === DOCUMENT_STATUSES.DONE) {
        document.status = DOCUMENT_STATUSES.GENERATED;
    }

    const isWaitingNonSigned = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.WAITING_UNSIGNED_DOCS),
    );

    if (isWaitingNonSigned) {
        document.status = DOCUMENT_STATUSES.GENERATING;
    }

    return <DocumentStatus status={document.status} />;
};

DocumentStatusCellTraining.propTypes = {
    rowData: PropTypes.shape({
        status: PropTypes.oneOf(Object.values(DOCUMENT_STATUSES)),
    }).isRequired,
};

export default DocumentStatusCellTraining;
