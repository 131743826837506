import {Box, Collapse, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FreelancerSearch} from './FreelancerSearch';
import {ListBidItem} from './ListBidItem';
import SkeletonListBidItem from './SkeletonListBidItem';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {OpportunitiesActions} from '../../store/opportunity.action';

export const AdminOpportunityBids = ({isOpen, list, opportunityId, bidCreatorId}) => {
    const dispatch = useDispatch();

    const {t} = useTranslation('opportunity');

    const isApplyInProgress = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.APPLY_OPPORTUNITY),
    );

    const onChange = userId => {
        dispatch(OpportunitiesActions.applyAsAdmin({
            userId: userId,
            opportunityId: opportunityId,
        }));
    };

    const onToggle = (bidId, userId, isTurningOn = true) => {
        dispatch(OpportunitiesActions.toggleAccept(
            {
                freelancerId: userId,
                opportunityId: opportunityId,
                bidId: bidId,
                isTurningOn,
            },
        ));
    };

    return (
        <Box
            sx={{
                pb: '1px',
                background: isOpen ? '#fafafa' : '#fff',
                mt: '15px',
                borderRadius: 2,
            }}
        >
            <Collapse in={isOpen} mountOnEnter unmountOnExit easing="easeInOut">
                <Box sx={{
                    background: '#fafafa',
                    p: 3,
                    pt: 2,
                    pb: 2,
                    borderRadius: 2,
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: theme => theme.typography.pxToRem(16),
                                }}
                            >
                                {t('opportunityBids.title')}
                            </Typography>
                        </Box>
                        <Box>
                            <FreelancerSearch
                                onChange={onChange}
                                usedList={list}
                                bidCreatorId={bidCreatorId}
                            />
                        </Box>
                    </Box>
                    <Box sx={{mt: list?.length > 0 && list[0] ? 2 : 0}}>
                        {!list && <Box sx={{pt: 2}}>{t('opportunityBids.noBids')}</Box>}
                        {list && list.map((element, index) => {
                            if (!element) {
                                return null;
                            }
                            return (
                                <ListBidItem key={index} element={element} onToggle={onToggle} />
                            );
                        })}
                        {isApplyInProgress && <SkeletonListBidItem />}
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

AdminOpportunityBids.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    list: PropTypes.array,
    opportunityId: PropTypes.string.isRequired,
    bidCreatorId: PropTypes.string.isRequired,
};

AdminOpportunityBids.defaultProps = {
    list: [],
};
