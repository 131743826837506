import {getPickersLocalization} from './utils/getPickersLocalization';

// This object is not Partial<PickersLocaleText> because it is the default values

const enUSPickers = {
    // Calendar navigation
    previousMonth: 'Previous month',
    nextMonth: 'Next month',

    // View navigation
    openPreviousView: 'open previous view',
    openNextView: 'open next view',
    calendarViewSwitchingButtonAriaLabel: view => (view === 'year'
        ? 'year view is open, switch to calendar view'
        : 'calendar view is open, switch to year view'),
    inputModeToggleButtonAriaLabel: (isKeyboardInputOpen, viewType) => (isKeyboardInputOpen
        ? `text input view is open, go to ${viewType} view`
        : `${viewType} view is open, go to text input view`),

    // DateRange placeholders
    start: 'Start',
    end: 'End',

    // Action bar
    cancelButtonLabel: 'Cancel',
    clearButtonLabel: 'Clear',
    okButtonLabel: 'OK',
    todayButtonLabel: 'Today',

    // Toolbar titles
    datePickerDefaultToolbarTitle: 'Select date',
    dateTimePickerDefaultToolbarTitle: 'Select date & time',
    timePickerDefaultToolbarTitle: 'Select time',
    dateRangePickerDefaultToolbarTitle: 'Select date range',

    // Clock labels
    clockLabelText: (view, time, adapter) => `Select ${view}. ${
        time === null
            ? 'No time selected'
            : `Selected time is ${adapter.format(time, 'fullTime')}`
    }`,
    hoursClockNumberText: hours => `${hours} hours`,
    minutesClockNumberText: minutes => `${minutes} minutes`,
    secondsClockNumberText: seconds => `${seconds} seconds`,

    // Open picker labels
    openDatePickerDialogue: (rawValue, utils) => (rawValue && utils.isValid(utils.date(rawValue))
        ? `Choose date, selected date is ${utils.format(
            utils.date(rawValue),
            'fullDate',
        )}`
        : 'Choose date'),
    openTimePickerDialogue: (rawValue, utils) => (rawValue && utils.isValid(utils.date(rawValue))
        ? `Choose time, selected time is ${utils.format(
            utils.date(rawValue),
            'fullTime',
        )}`
        : 'Choose time'),

    // Table labels
    timeTableLabel: 'pick time',
    dateTableLabel: 'pick date',
};

export const enUS = getPickersLocalization(enUSPickers);
