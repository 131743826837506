import {
    faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Button, Chip} from '@mui/joy';
import {Chip as MuiChip} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ConditionalTooltip} from '../../../../../../../components/tooltip/ConditionalTooltip';
import {TransactionSide, convertIconsFromMUIToFA} from '../../../util/constants';
import {AccountBalanceHelperFunctions} from '../../../util/functions';
import {createIconFromName} from '../../../util/icon';

const Category = ({
    side,
    category,
    sourceOfRevenue,
    language,
    selectCategoriesObject,
    selectSourcesObject,
    isEditable,
    isV3,
}) => {
    // const ref = useRef();
    const {t} = useTranslation('bank');

    if (!isEditable) {
        if (isV3) {
            return (
                <Chip
                    color="neutral"
                    size="md"
                    variant="soft"
                    disabled
                >
                    –
                </Chip>
            );
        }
        return (
            <MuiChip
                sx={{
                    'backgroundColor': 'v2.light.info.shades4',
                    'color': '#000',
                }}
                label="–"
            />
        );
    }

    // TODO
    const onCategorizeClick = () => {};

    if ((!category || category?.length === 0) && !sourceOfRevenue) {
        if (isV3) {
            return (
                <Button
                    type="button"
                    color="primary"
                    variant="solid"
                    size="sm"
                    onClick={onCategorizeClick}
                    endDecorator={(
                        <FontAwesomeIcon icon={faArrowRight} />
                    )}
                >
                    {t('accountBalance.categorise')}
                </Button>
            );
        }

        return (
            <MuiChip
                sx={{
                    'fontWeight': 500,
                    'fontSize': theme => theme.typography.pxToRem(12),
                    '&:hover': {
                        'backgroundColor': 'v2.light.secondary.main',
                        'color': '#fff',
                        'border': '1 px solid #fff',
                        'borderColor': 'v2.light.primary.shades30',
                        'cursor': 'pointer',
                        '.MuiSvgIcon-root': {
                            color: 'white !important',
                        },
                    },
                }}
                label={t('accountBalance.categorise')}
                variant="outlined"
                color="primary"
                deleteIcon={<ArrowForwardIcon />}
                onDelete={onCategorizeClick}
            />
        );
    }

    const translated = side === TransactionSide.CREDIT
        ? selectSourcesObject[sourceOfRevenue]?.[language] ?? sourceOfRevenue
        : category.map(item => {
            return selectCategoriesObject[item]?.[language] ?? item;
        }).join(', ');

    // TODO: this approach does not work because we can't set
    // display: 'block' or 'inline-block' on Chip, so I always show tooltip for now
    // it should be tooltip={hasTooltip ? translated : null}
    // const hasTooltip = ref?.current?.offsetWidth < ref?.current?.scrollWidth;

    const {iconName} = AccountBalanceHelperFunctions.getColorAndIconName({
        selectCategoriesObject,
        selectSourcesObject,
        category,
        sourceOfRevenue,
    });

    if (isV3) {
        const iconNameCleared = iconName?.slice(-4) === 'Icon'
            ? iconName.slice(0, -4)
            : iconName;

        return (
            <ConditionalTooltip tooltip={translated} isV3>
                <Chip
                    // ref={ref}
                    color="neutral"
                    size="md"
                    variant="soft"
                    onClick={() => {}}
                    sx={{
                        maxWidth: '200px',
                        textWrap: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        gap: 0.75,
                    }}
                    startDecorator={(
                        <FontAwesomeIcon
                            size="sm"
                            fixedWidth
                            icon={convertIconsFromMUIToFA[iconNameCleared]
                                ?? convertIconsFromMUIToFA.default}
                        />
                    )}
                >
                    {translated}
                </Chip>
            </ConditionalTooltip>
        );
    }

    return (
        <ConditionalTooltip tooltip={translated}>
            <MuiChip
                sx={{
                    'maxWidth': '200px',
                    'textWrap': 'nowrap',
                    'textOverflow': 'ellipsis',
                    'overflow': 'hidden',
                    'backgroundColor': 'v2.light.info.shades4',
                    'fontSize': theme => theme.typography.pxToRem(12),
                    'color': 'black',
                    '&:hover': {
                        backgroundColor: 'v2.light.warning.shades12',
                        cursor: 'pointer',
                    },
                    '.MuiSvgIcon-root': {
                        color: 'text_v2.secondary',
                    },
                }}
                label={translated}
                avatar={createIconFromName(iconName)}
            />
        </ConditionalTooltip>
    );
};

Category.propTypes = {
    side: PropTypes.string.isRequired,
    category: PropTypes.array.isRequired,
    sourceOfRevenue: PropTypes.string,
    language: PropTypes.string.isRequired,
    selectCategoriesObject: PropTypes.object.isRequired,
    selectSourcesObject: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isV3: PropTypes.bool, // TODO: make true by default and delete the code, related to false value
};

Category.defaultProps = {
    sourceOfRevenue: '',
    isV3: false,
};

export default Category;
