import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import {AnimationActions} from '../../../features/animations/store/animations.action';
import AppCrashedView from '../../../features/err-pages/components/AppCrashedView';
import {sendEvent} from '../../app/event/event.actions';
import {EVENT_TYPES} from '../../app/event/event.constants';
import {getConfig} from '../../config';

class ErrorBoundary extends Component {
    static getDerivedStateFromError() {
        return {
            hasError: true,
        };
    }

    state = {
        hasError: false,
    };

    componentDidCatch(error, errorInfo) {
        const config = getConfig();
        if (config.environment !== 'development') {
            this.props.sendEvent(EVENT_TYPES.ERROR, {
                message: error.message,
                stackTrace: error.stack,
                errorInfo: JSON.stringify(errorInfo),
            });
        }
        this.props.setInitialAnimation(false);
    }

    render() {
        if (this.state.hasError) {
            return <AppCrashedView />;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    sendEvent: PropTypes.func.isRequired,
    setInitialAnimation: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    sendEvent,
    setInitialAnimation: AnimationActions.setInitialAnimation,
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
