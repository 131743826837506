import makeStyles from '@mui/styles/makeStyles';

export const useAttachDocumentsStyles = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    uploadIcon: {
        fontSize: '3.5rem',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xl')]: {
            marginBottom: theme.spacing(1.5),
        },
        [theme.breakpoints.down('lg')]: {
            marginBottom: theme.spacing(0.5),
        },
    },
    footerContainer: {
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
}));
