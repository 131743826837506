/* Astronomical constants */
const epoch = 2444238.5; // 1980 January 0.0

/* Constants defining the Sun's apparent orbit */
const SUN_ECLIPTIC_LONGITUDE = 278.833540; // Ecliptic longitude of the Sun at epoch 1980.0
const SUN_ECLIPTIC_LONGITUDE_PERIGEE = 282.596403; // Ecliptic longitude of the Sun at perigee
const EARTHS_ORBIT_ECCENTRICITY = 0.016718; // Eccentricity of Earth's orbit
const EARTHS_ORBIT_SEMI_MAJOR_AXIS = 1.495985e8; // Semi-major axis of Earth's orbit, km
const SUNS_ANGULAR_SIZE = 0.533128; // Sun's angular size, degrees, at semi-major axis distance

/* Elements of the Moon's orbit, epoch 1980.0 */
const MOONS_MEAN_LONGITUDE = 64.975464; // Moon's mean longitude at the epoch
const MOONS_MEAN_LONGITUDE_PERIGEE = 349.383063; // Mean longitude of the perigee at the epoch
const MOONS_ORBIT_ECCENTRICITY = 0.054900; // Eccentricity of the Moon's orbit
const MOONS_ANGULAR_SIZE = 0.5181; // Moon's angular size at distance a from Earth
const MOONS_ORBIT_SEMI_MAJOR_AXIS = 384401.0; // Semi-major axis of Moon's orbit in km
const SYNODIC_MONTH = 29.53058868; // Synodic month (new Moon to new Moon)

/*  Handy mathematical functions  */
const fixAngle = a => {
    return ((a) - 360.0 * (Math.floor((a) / 360.0)));
}; // Fix angle
const toRad = d => {
    return ((d) * (Math.PI / 180.0));
}; // Deg->Rad
const toDeg = d => {
    return ((d) * (180.0 / Math.PI));
}; // Rad->Deg

/**
 * Convert a Date to astronomica Julian time
 * (i.e. Julian date plus day fraction, expressed as a double).
 * @param {Date} date Converts a date to Julian date
 * @returns {number} Julian date
 */
const toJulianTime = date => {
    // Algorithm as given in Meeus, Astronomical Algorithms
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    const millisecond = date.getMilliseconds();

    month = month > 2 ? month : month + 12;
    year = month > 2 ? year : year - 1;
    day = day + hour / 24.0 + minute / 1440.0 + (second + millisecond / 1000.0) / 86400.0;
    const b = isJulianDate(year, month, day) ? 0 : 2 - year / 100 + year / 100 / 4;

    return Math.floor((365.25 * (year + 4716)) + Math.floor(30.6001 * (month + 1)) + day + b - 1524.5);
};

/**
 * Returns whether a given date is a Julian date or not.
 * https://stackoverflow.com/a/14554483/1837080
 * @param {number} year Year
 * @param {number} month Month
 * @param {number} day Day
 * @returns {boolean} Boolean
 */
const isJulianDate = (year, month, day) => {
    if (year < 1582) {
        // All dates prior to 1582 are in the Julian calendar
        return true;
    } else if (year > 1582) {
        // All dates after 1582 are in the Gregorian calendar
        return false;
    }

    // If 1582, check before October 4 (Julian) or after October 15 (Gregorian)
    if (month < 10) {
        return true;
    } else if (month > 10) {
        return false;
    }

    if (day < 5) {
        return true;
    } else if (day > 14) {
        return false;
    }

    return false;
};

/**
 * Solve the equation of Kepler.
 * @param {number} sunMeanAnomaly Mean anomaly of the Sun's position in degrees
 * @param {number} eccentricity Eccentricity
 * @returns {number} Number (no idea what it represents)
 */
const kepler = (sunMeanAnomaly, eccentricity) => {
    sunMeanAnomaly = toRad(sunMeanAnomaly);

    let sunMeanAnomalyDegrees = sunMeanAnomaly;
    let delta;

    do {
        delta = sunMeanAnomalyDegrees - eccentricity * Math.sin(sunMeanAnomalyDegrees) - sunMeanAnomaly;
        sunMeanAnomalyDegrees -= delta / (1 - eccentricity * Math.cos(sunMeanAnomalyDegrees));
    } while (Math.abs(delta) > Number.EPSILON);

    return sunMeanAnomalyDegrees;
};

/**
 * Calculate phase of moon as a fraction.
 * The argument is the time for which the phase is requested,
 expressed as a Julian date and fraction. Returns the terminator
 phase angle as a percentage of a full circle (i.e., 0 to 1), and
 stores into pointer arguments the illuminated fraction of the
 Moon's disc, the Moon's age in days and fraction, the distance of
 the Moon from the centre of the Earth, and the angular diameter
 subtended by the Moon as seen by an observer at the centre of the
 Earth.
 * @param {number} julianDate Date in Julian
 * @returns {object} Moon information
 */
const getMoonPhase = julianDate => {
    let Ec;

    /* Calculation of the Sun's position */
    const Day = julianDate - epoch; // Date within epoch
    const N = fixAngle((360 / 365.2422) * Day); // Mean anomaly of the Sun
    // Convert from perigee co-ordinates to epoch 1980.0
    const M = fixAngle(N + SUN_ECLIPTIC_LONGITUDE - SUN_ECLIPTIC_LONGITUDE_PERIGEE);

    Ec = kepler(M, EARTHS_ORBIT_ECCENTRICITY); // Solve equation of Kepler
    Ec = Math.sqrt((1 + EARTHS_ORBIT_ECCENTRICITY) / (1 - EARTHS_ORBIT_ECCENTRICITY)) * Math.tan(Ec / 2);
    Ec = 2 * toDeg(Math.atan(Ec)); // True anomaly
    const Lambdasun = fixAngle(Ec + SUN_ECLIPTIC_LONGITUDE_PERIGEE); // Sun's geocentric ecliptic longitude

    /* Orbital distance factor */
    const F = ((1 + EARTHS_ORBIT_ECCENTRICITY * Math.cos(toRad(Ec))) / (
        1 - EARTHS_ORBIT_ECCENTRICITY * EARTHS_ORBIT_ECCENTRICITY)
    );
    const SunDist = EARTHS_ORBIT_SEMI_MAJOR_AXIS / F; // Distance to Sun in km
    const SunAng = F * SUNS_ANGULAR_SIZE; // Sun's angular size in degrees

    /* Calculation of the Moon's position */

    /* Moon's mean longitude */
    const ml = fixAngle(13.1763966 * Day + MOONS_MEAN_LONGITUDE);

    /* Moon's mean anomaly */
    const MM = fixAngle(ml - 0.1114041 * Day - MOONS_MEAN_LONGITUDE_PERIGEE);

    /* Eviction */
    const Ev = 1.2739 * Math.sin(toRad(2 * (ml - Lambdasun) - MM));

    /* Annual equation */
    const Ae = 0.1858 * Math.sin(toRad(M));

    /* Correction term */
    const A3 = 0.37 * Math.sin(toRad(M));

    /* Corrected anomaly */
    const MmP = MM + Ev - Ae - A3;

    /* Correction for the equation of the centre */
    const mEc = 6.2886 * Math.sin(toRad(MmP));

    /* Another correction term */
    const A4 = 0.214 * Math.sin(toRad(2 * MmP));

    /* Corrected longitude */
    const lP = ml + Ev + mEc - Ae + A4;

    /* Variation */
    const V = 0.6583 * Math.sin(toRad(2 * (lP - Lambdasun)));

    /* True longitude */
    const lPP = lP + V;

    /* Calculation of the phase of the Moon */
    /* Age of the Moon in degrees */
    const MoonAge = lPP - Lambdasun;

    /* Phase of the Moon */
    const MoonPhase = (1 - Math.cos(toRad(MoonAge))) / 2;

    /* Calculate distance of moon from the centre of the Earth */

    const MoonDist = (MOONS_ORBIT_SEMI_MAJOR_AXIS * (1 - MOONS_ORBIT_ECCENTRICITY * MOONS_ORBIT_ECCENTRICITY))
        / (1 + MOONS_ORBIT_ECCENTRICITY * Math.cos(toRad(MmP + mEc)));

    /* Calculate Moon's angular diameter */
    const MoonDFrac = MoonDist / MOONS_ORBIT_SEMI_MAJOR_AXIS;
    const MoonAng = MOONS_ANGULAR_SIZE / MoonDFrac;

    return {
        moonIllumination: MoonPhase,
        moonAgeInDays: SYNODIC_MONTH * (fixAngle(MoonAge) / 360.0),
        distanceInKm: MoonDist,
        angularDiameterInDeg: MoonAng,
        distanceToSun: SunDist,
        sunAngularDiameter: SunAng,
        moonPhase: fixAngle(MoonAge) / 360.0,
    };
};

/**
 * Get moon information on a given date
 * @param {Date} date A date for which we need Moon info
 * @return {object} Object which contains Moon info
 */
export const getMoonInfo = date => {
    if (typeof date === 'undefined' || date === null) {
        return {
            moonPhase: 0,
            moonIllumination: 0,
            moonAgeInDays: 0,
            distanceInKm: 0,
            angularDiameterInDeg: 0,
            distanceToSun: 0,
            sunAngularDiameter: 0,
        };
    }

    return getMoonPhase(toJulianTime(date));
};

/**
 * Return the date of Easter for a given year
 * @param {number} year A year for which to calculate the Easter
 * @returns {Date} Exact date of Easter for the given year
 */
export const getEaster = year => {
    // Easter is on the first Sunday following a full
    // moon after the vernal equinox
    // Church recognizes the vernal equinox on March 21st;
    // js date object's month is 0-based
    // Continue to calculate moon info, until we find the
    // first full moon after the vernal equinox
    const fullMoon = new Date(year, 2, 21);
    let previousMoonInfo;
    let moonInfo;
    let gettingDarker = undefined;

    do {
        previousMoonInfo = getMoonInfo(fullMoon);
        fullMoon.setDate(fullMoon.getDate() + 1);
        moonInfo = getMoonInfo(fullMoon);

        // Initially set if we must currently wait for the moon to grow dimmer,
        // before it grows bright again for a full moon
        if (typeof gettingDarker === 'undefined') {
            gettingDarker = moonInfo.moonIllumination < previousMoonInfo.moonIllumination;
        } else if (gettingDarker && moonInfo.moonIllumination > previousMoonInfo.moonIllumination) {
            // Once the moon has finished getting darker,
            // change this variable so we can check that it continues to grow
            // brighter (so we know when we've found our full moon)
            gettingDarker = false;
        }
    } while (
        (gettingDarker && moonInfo.moonIllumination < previousMoonInfo.moonIllumination)
        || (!gettingDarker && moonInfo.moonIllumination > previousMoonInfo.moonIllumination)
    );

    // We found a full moon, go back a day since
    // we've gone 1 day too far
    fullMoon.setDate(fullMoon.getDate() - 1);

    // Find the next Sunday (Easter)
    while (fullMoon.getDay() !== 0) {
        fullMoon.setDate(fullMoon.getDate() + 1);
    }

    return fullMoon;
};
