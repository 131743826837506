import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {RevenueSimulatorActions} from '../../../store/revenue-simulator.action';
import {RevenueSimulatorSelectors} from '../../../store/revenue-simulator.selector';

export const Navigation = () => {
    const {t} = useTranslation('revenueSimulator');
    const form = useFormContext();
    const currentStepIndex = useSelector(RevenueSimulatorSelectors.selectCurrentStepIndex);
    const dispatch = useDispatch();

    const handleSubmit = values => dispatch(RevenueSimulatorActions.submitStep(values));
    const handleBackClick = () => dispatch(RevenueSimulatorActions.goPrevStep());

    return (
        <Box sx={{
            display: 'flex',
            gap: 1,
            justifyContent: 'center',
            mt: 2,
        }}
        >
            {currentStepIndex > 0 && (
            <Button
                startIcon={<ArrowBackIcon />}
                variant="outlined"
                onClick={handleBackClick}
            >
                {t('back')}
            </Button>
            )}
            <Button
                endIcon={<ArrowForwardIcon />}
                variant="contained"
                color="primary_v2"
                disabled={!form.formState.isValid}
                onClick={form.handleSubmit(handleSubmit)}
            >{t('continue')}
            </Button>
        </Box>
    );
};
