import {Box, Sheet, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {MobileRoadmapSteps} from './MobileRoadmapSteps';
import {RoadmapChart} from './RoadmapChart';
import {RoadmapSteps} from './RoadmapSteps';
import {useChartColors} from './utils';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';

const LegendItem = ({color, children}) => {
    return (
        <Stack direction="row" spacing={0.5}>
            <Box
                sx={{
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    border: '4px solid white',
                    bgcolor: color,
                }}
            />
            <Typography textColor="text.secondary" level="title-sm">
                {children}
            </Typography>
        </Stack>
    );
};

LegendItem.propTypes = {
    color: PropTypes.string.isRequired,
};

const Legend = () => {
    const colors = useChartColors();
    const {t} = useTranslation('revenueSimulator');

    return (
        <Stack
            direction="row"
            columnGap={{desktop: 5, mobile: 2}}
            rowGap={{mobile: 1}}
            alignItems="center"
            justifyContent="center"
            py={2}
            flexWrap="wrap"
        >
            <LegendItem color={colors.currentSituation}>{t('roadmap.legend.currentSituation')}</LegendItem>
            <LegendItem color={colors.withHiway}>{t('roadmap.legend.withHiway')}</LegendItem>
            <LegendItem color={colors.arce}>{t('roadmap.legend.arce')}</LegendItem>
        </Stack>
    );
};

const ScrollableContainer = ({children}) => (
    <Box sx={{position: 'relative'}}>
        <Box
            sx={{
                'overflowX': 'auto',
                'pt': 2,
                '&::before, &::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '16px',
                    height: '100%',
                    background: 'linear-gradient(to right, white, transparent)',
                    zIndex: 1,
                },
                '&::after': {
                    left: 'auto',
                    right: 0,
                    background: 'linear-gradient(to left, white, transparent)',
                },
            }}
        >
            <Box sx={{minWidth: '600px', pr: 2}}>{children}</Box>
        </Box>
    </Box>
);

export const RoadmapSubSection = () => {
    const isMobileSize = useIsMobileSize();
    const {t} = useTranslation('revenueSimulator');

    return (
        <Box flex={1} mb={2}>
            <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                    borderRadius: 'lg',
                    backgroundColor: 'common.white',
                    flexGrow: 1,
                    overflow: 'auto',
                    position: 'relative',
                }}
            >
                <Box sx={{borderBottom: '1px solid', borderBottomColor: 'neutral.outlinedBorder', px: 2, py: 1}}>
                    <Trans
                        t={t}
                        i18nKey={isMobileSize ? 'roadmap.titleMobile' : 'roadmap.title'}
                        components={{highlight: <Typography level="body-sm" textColor="text.tertiary" display="inline" />}}
                    />
                </Box>
                <Box p={1}>
                    <ScrollableContainer>
                        <RoadmapChart />
                        <RoadmapSteps />
                    </ScrollableContainer>
                    <Legend />
                    {isMobileSize && <MobileRoadmapSteps />}
                </Box>
            </Sheet>
        </Box>
    );
};
