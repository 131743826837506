import {select} from 'redux-saga/effects';

import {selectSelectedCompanyId} from '../company/currentCompany/currentCompany.selectors';
import {selectFreelancerAccount} from '../freelancer/freelancer.selectors';

export const getCompanyId = function* () {
    const companyId = yield select(selectSelectedCompanyId);

    if (!companyId) {
        const {defaultCompanyId} = yield select(selectFreelancerAccount);

        return defaultCompanyId;
    }

    return companyId;
};
