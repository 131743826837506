import AttachmentIcon from '@mui/icons-material/Attachment';
import CloseIcon from '@mui/icons-material/Close';
import DevicesTwoToneIcon from '@mui/icons-material/DevicesTwoTone';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import {Box, CircularProgress, Divider, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import TransactionDetailsNoFileModal from './TransactionDetailsNoFileModal';
import {BankDropZone} from '../../../../../../components/dropzone/BankDropZone';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {TransactionFile} from '../../../../components/TransactionFile';
import {TransfersActions} from '../../../transfers/store/transfers.actions';
import {SIDEBAR_TYPES} from '../../../transfers/utils/constants';
import {TransactionsActions} from '../../store/transactions.action';
import {TransactionsSelector} from '../../store/transactions.selector';
import {emptyFunction} from '../../util/constants';

// Used in transaction/transfer edit
const TransactionDetailsFilesSection = ({
    fileList,
    details,
    isEditable,
    setIsQROpen,
    hasNoFileOption,
}) => {
    const dispatch = useDispatch();

    const {t} = useTranslation('bank');

    const [fileMenuOpen, setIsFileMenuOpen] = useState(null);

    // If admin is accessing
    const params = useParams();
    const {freelancerId, companyId} = params;

    const isEditableWithFilesSelected = isEditable && (fileList?.length === 0);

    const fileUploadProcessingList = useSelector(TransactionsSelector.createModalFileLoaderSelector(details?.id));
    const isFileUploading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.UPLOAD_BANK_FILE,
    )) || false;

    const onFileSelect = useCallback(file => {
        if (details?.id) {
            if (details.type === SIDEBAR_TYPES.editRecurringTransfer) {
                dispatch(TransfersActions.uploadTransferFile({
                    id: details.id,
                    file,
                    accountId: details?.bankAccountId,
                }));
            } else {
                dispatch(TransactionsActions.uploadTransactionFile({
                    id: details.id,
                    file,
                    accountId: details?.bankAccountId,
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details?.id, dispatch]);

    const openNoFileModal = () => {
        dispatch(UiActions.setModalData(ModalsKeys.BANK_NO_FILE, {
            action: TransactionsActions.saveArticles({
                id: details.id,
                articles: details.articles,
                ignoreProofDocument: true,
                accountId: details?.bankAccountId,
            }),
        }));
        dispatch(UiActions.setActiveModal(ModalsKeys.BANK_NO_FILE, true));
    };

    const removeNoFile = () => {
        dispatch(TransactionsActions.saveArticles({
            id: details.id,
            articles: details.articles,
            ignoreProofDocument: false,
            accountId: details?.bankAccountId,
        }));
    };

    return (
        <>
            <Box sx={{
                backgroundColor: 'grey.50',
                borderRadius: '16px',
                padding: '16px 24px',
                mx: 2,
                display: 'flex',
                flexDirection: 'column',
                mt: 2,
                mb: 2,
            }}
            >
                <Typography sx={{fontWeight: 500}}>
                    {t('translationDetails.proof')}
                </Typography>
                <Box sx={{mt: 2,
                    textTransform: 'uppercase',
                    fontWeight: 500,
                    fontSize: theme => theme.typography.pxToRem(13)}}
                >
                    {details?.ignoreProofDocument && (
                        <Box
                            sx={{
                                'mb': 2,
                                'fontSize': theme => theme.typography.pxToRem(13),
                                'display': 'flex',
                                'justifyContent': 'space-between',
                                'alignItems': 'center',
                                'cursor': 'pointer',
                                'textTransform': 'none',
                                'p': 1,
                                'backgroundColor': '#78909C',
                                'color': 'white',
                                'borderRadius': '8px',
                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            >
                                <DoNotDisturbAltIcon sx={{mr: 1.8}} />
                                <Box component="span" sx={{wordBreak: 'break-word'}}>
                                    {t('translationDetails.noInvoice')}
                                </Box>
                            </Box>
                            <CloseIcon
                                sx={{
                                    cursor: isEditableWithFilesSelected ? 'pointer' : 'default',
                                }}
                                onClick={isEditableWithFilesSelected ? removeNoFile : emptyFunction}
                            />
                        </Box>
                    )}

                    {fileList && details && fileList.map(file => {
                        if (!file || typeof file === 'string') {
                            return null;
                        }
                        return (
                            <TransactionFile
                                key={file.id}
                                file={file}
                                parentId={details.id}
                                type={details.type === SIDEBAR_TYPES.editRecurringTransfer ? 'TRANSFERS' : 'TRANSACTION'}
                                isEditable={isEditable}
                                isFileMenuOpen={fileMenuOpen}
                                setIsFileMenuOpen={setIsFileMenuOpen}
                                companyId={companyId}
                                freelancerId={freelancerId}
                                accountId={details?.bankAccountId}
                            />
                        );
                    })}
                    {fileUploadProcessingList.map(process => {
                        return (
                            <Box
                                key={process.eventId}
                                sx={{
                                    'mb': 2,
                                    'fontSize': theme => theme.typography.pxToRem(13),
                                    'display': 'flex',
                                    'justifyContent': 'space-between',
                                    'alignItems': 'center',
                                    'cursor': isEditable ? 'pointer' : 'auto',
                                    'p': 1,
                                    'backgroundColor': 'primary_v2.dark',
                                    'color': 'white',
                                    'borderRadius': '8px',
                                }}
                            >
                                <CircularProgress size={20} sx={{mr: 2}} />
                                {process.eventId}
                            </Box>
                        );
                    })}

                    <BankDropZone
                        fileTypes="image/jpeg, image/png, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        maxFiles={50}
                        onChange={isEditable ? onFileSelect : emptyFunction}
                        isFileUploading={isFileUploading}
                        isDisabled={!isEditable}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: isEditable ? 'pointer' : 'auto',
                                color: isEditable ? 'inherit' : 'v2.light.text.disabled',
                            }}
                        >
                            {isFileUploading && <CircularProgress size={20} sx={{mr: 2}} />}
                            {!isFileUploading && (
                            <AttachmentIcon
                                sx={{
                                    color: isEditable ? 'primary_v2.main' : 'v2.light.text.disabled',
                                    mr: 2,
                                }}
                            />
                            )}
                            {t('translationDetails.chooseFile')}
                        </Box>
                    </BankDropZone>

                    <Divider sx={{my: 1.2}} />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 1,
                            color: isEditableWithFilesSelected ? 'inherit' : 'v2.light.text.disabled',
                            cursor: isEditableWithFilesSelected ? 'pointer' : 'default',
                        }}
                        onClick={isEditableWithFilesSelected ? () => setIsQROpen(true) : () => {}}
                    >
                        <DevicesTwoToneIcon sx={{
                            color: isEditableWithFilesSelected ? 'primary_v2.main' : 'v2.light.text.disabled',
                            mr: 2,
                        }}
                        />
                        {t('translationDetails.scanWithApp')}
                    </Box>

                    {hasNoFileOption && (
                        <>
                            <Divider sx={{my: 1.2}} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mb: 1,
                                    color: isEditableWithFilesSelected ? 'error_v2.main' : 'v2.light.text.disabled',
                                    cursor: isEditableWithFilesSelected ? 'pointer' : 'default',
                                }}
                                onClick={isEditableWithFilesSelected ? () => openNoFileModal() : () => {}}
                            >
                                <DisabledByDefaultIcon sx={{
                                    color: isEditableWithFilesSelected ? 'error_v2.main' : 'v2.light.text.disabled',
                                    mr: 2,
                                }}
                                />
                                {t('translationDetails.noFileAction')}
                            </Box>
                        </>
                    )}
                </Box>
            </Box>

            <TransactionDetailsNoFileModal />
        </>
    );
};

TransactionDetailsFilesSection.propTypes = {
    fileList: PropTypes.array.isRequired,
    details: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    setIsQROpen: PropTypes.func.isRequired,
    hasNoFileOption: PropTypes.bool,
};

TransactionDetailsFilesSection.defaultProps = {
    hasNoFileOption: true,
};

export default TransactionDetailsFilesSection;
