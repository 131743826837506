import {Global} from '@emotion/react';
import {CssBaseline, SwipeableDrawer} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/styles';
import PropTypes from 'prop-types';
import {useState} from 'react';

const Puller = styled(Box)(() => ({
    width: 30,
    height: 6,
    borderRadius: 3,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.3);',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const SwipeableEdgeDrawer = ({drawerContent, height, bleedingHeight}) => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = newOpen => () => {
        setOpen(newOpen);
    };

    // This is used only for the example
    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <>
            <CssBaseline />
            <Global
                styles={{
                    '#bottomDrawer.MuiDrawer-root > .MuiPaper-root': {
                        height: height ?? `calc(50% - ${bleedingHeight}px)`,
                        overflow: 'visible',
                    },
                }}
            />
            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={bleedingHeight}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                    id: 'bottomDrawer',
                }}
                hideBackdrop={false}
            >
                <Paper
                    elevation={12}
                    sx={{
                        position: 'absolute',
                        top: -bleedingHeight,
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                    }}
                >
                    <Puller />
                    <Box sx={{mt: 1}}>
                        {drawerContent}
                    </Box>
                </Paper>
            </SwipeableDrawer>
        </>
    );
};

SwipeableEdgeDrawer.propTypes = {
    drawerContent: PropTypes.object.isRequired,
    height: PropTypes.string,
    bleedingHeight: PropTypes.number,
};

SwipeableEdgeDrawer.defaultProps = {
    height: '',
    bleedingHeight: 90,
};

export default SwipeableEdgeDrawer;
