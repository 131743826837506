import {Box} from '@mui/material';
import React, {useEffect} from 'react';

const BORDER_RADIUS = '8px';
export const MissionSearch = () => {
    useEffect(() => {
        const typeformScript = document.createElement('script');
        typeformScript.src = 'https://embed.typeform.com/next/embed.js';
        typeformScript.id = 'TYPEFORM_SCRIPT';
        document.body.append(typeformScript);
        return () => {
            const removeTypeformScript = document.getElementById('TYPEFORM_SCRIPT');
            removeTypeformScript.remove();
        };
    }, []);

    return (
        <Box
            sx={{
                px: {
                    xs: 2,
                    md: 0,
                },
                pt: {md: 3},
                display: 'flex',
                direction: 'column',
                justifyContent: 'center',
            }}
        >
            <Box
                component="div"
                data-tf-widget="k9MKJ19h"
                data-tf-opacity="100"
                data-tf-iframe-props="title=Hiway : Recherche de mission"
                data-tf-transitive-search-params
                data-tf-medium="snippet"
                data-tf-hidden="hubspot_utk=,hubspot_page_name=,hubspot_page_url="
                data-tf-disable-scroll="true"
                data-tf-auto-resize="500,1000"
                data-tf-inline-on-mobile="true"
                sx={{width: 1, mt: 4, height: '840px', borderRadius: BORDER_RADIUS, background: '#FFF'}}
            />
        </Box>
    );
};
