import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {OpportunityFileItem} from '../freelancer-opportunity/OpportunityFileItem';

const Description = ({rowData}) => {
    const {missionDescriptionDoc, additionalMissionDocs} = rowData;

    return (
        <Box>
            <OpportunityFileItem file={missionDescriptionDoc} freelancerId={rowData?.freelancerId} />
            {additionalMissionDocs?.map(file => {
                return (
                    <OpportunityFileItem key={file.id} file={file} freelancerId={rowData?.freelancerId} />
                );
            })}
        </Box>

    );
};

Description.propTypes = {
    rowData: PropTypes.any.isRequired,
};

export default React.memo(Description);
