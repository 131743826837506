import CheckIcon from '@mui/icons-material/Check';
import {Typography} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import {useDocumentUploadStepTitle} from './styles';

const DocumentUploadStepTitle = ({title, stepNumber, isCompleted}) => {
    const styles = useDocumentUploadStepTitle();

    return (
        <div className={styles.container}>
            <div
                className={clsx(styles.stepNumber, {
                    [styles.completed]: isCompleted,
                })}
            >
                {isCompleted ? <CheckIcon fontSize="inherit" /> : stepNumber}
            </div>

            <Typography className={styles.title}>
                {title}
            </Typography>
        </div>
    );
};

DocumentUploadStepTitle.propTypes = {
    title: PropTypes.string.isRequired,
    stepNumber: PropTypes.string.isRequired,
    isCompleted: PropTypes.bool,
};

DocumentUploadStepTitle.defaultProps = {
    isCompleted: false,
};

export default DocumentUploadStepTitle;
