import SendIcon from '@mui/icons-material/Send';
import {Box, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../../../../lib/router/route-paths';

export const CreatePaymentPanel = () => {
    const {t} = useTranslation('bankTransfer');

    return (
        <>
            <Typography
                variant="h5"
                sx={{
                    color: 'text_v2.secondary',
                }}
            >
                {t('transfer.home.title')}
            </Typography>

            <Box sx={{'mt': 2,
                'cursor': 'pointer'}}
            >
                <Link
                    style={{
                        'textDecoration': 'none',
                    }}
                    to={RoutePaths.BANK_CREATE_TRANSFER}
                >
                    <Box
                        sx={{
                            'display': 'flex',
                            'alignItems': 'center',
                        }}
                    >
                        <SendIcon sx={{mr: 2, color: 'primary_v2.main'}} />
                        <Typography
                            sx={{
                                color: 'v2.blueGray.600',
                                textTransform: 'uppercase',
                                fontWeight: 500,
                                fontSize: theme => theme.typography.pxToRem(14),
                            }}
                        >
                            {t('transfer.home.newPayment')}
                        </Typography>
                    </Box>
                </Link>
                <Box sx={{pl: 5, mt: '2px'}}>
                    <Typography
                        sx={{
                            color: 'v2.light.text.disabled',
                            fontSize: theme => theme.typography.pxToRem(12),
                        }}
                    >
                        {t('transfer.home.newPaymentText')}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};
