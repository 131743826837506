import {InputAdornment} from '@mui/material';
import PropTypes from 'prop-types';
import {EurButtonsField} from './EurButtonsField';
import {NumberField} from './NumberField';

export const EurField = ({buttonAmounts, ...props}) => {
    return (
        <>
            <NumberField {...props} endAdornment={<InputAdornment position="end">€</InputAdornment>} renderMarkLabel={value => value + ' €'} />
            {buttonAmounts && (
                <EurButtonsField name={props.name} buttonAmounts={buttonAmounts} defaultValue={props.defaultValue} />
            )}
        </>
    );
};

EurField.propTypes = {
    buttonAmounts: PropTypes.oneOfType([
        PropTypes.string, // "disabled"
        PropTypes.arrayOf(PropTypes.number),
    ]),
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.number.isRequired,
};

EurField.defaultProps = {
    buttonAmounts: undefined,
    ...NumberField.defaultProps,
};
