import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Step, StepContent, StepLabel, Stepper} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMemoizedCreateSelector} from '../../../../../hooks/use-memoized-create-selector';
import {FreelancerSelectors} from '../../../store/freelancer.selector';
import {CompaniesSelectors} from '../../companies';
import {OnboardingActions} from '../store/onboarding.action';
import {OnboardingSelectors} from '../store/onboarding.selectors';
import {OnboardingSteps} from '../utils/onboadingStepsConstant';

const SubStepConnectorLast = () => {
    return (
        <Box sx={{
            marginTop: '-20px',
            marginBottom: '-20px',
            marginLeft: '3.5px',
            zIndex: '-2',
            height: '40px',
            borderLeft: '1px solid #90CAF9',
        }}
        />
    );
};

const SubStepConnector = () => {
    return null;
};

const SubStepStepIcon = ({active, completed}) => {
    let backgroundColor = '#90CAF9';

    if (active) {
        backgroundColor = '#3779BE';
    } else if (completed) {
        backgroundColor = '#4CAF50';
    }

    if (active) {
        return (
            <Box sx={{position: 'relative'}}>
                <ChevronRightIcon sx={{position: 'absolute', left: '-31px', top: '-8px', color: '#1976D280'}} />
                <Box sx={{width: 8, height: 8, backgroundColor, borderRadius: '50%'}} className="SubStepStepIcon-circle" />
            </Box>
        );
    }

    return (
        <Box sx={{width: 8, height: 8, backgroundColor, borderRadius: '50%'}} className="SubStepStepIcon-circle" />
    );
};

SubStepStepIcon.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    active: PropTypes.bool,
    // eslint-disable-next-line react/boolean-prop-naming
    completed: PropTypes.bool,
};

SubStepStepIcon.defaultProps = {
    active: false,
    completed: false,
};

export const FreelanceStepperSubStep = ({t, hasLabel, subStepProgress, subSteps, step}) => {
    const activeStep = useSelector(OnboardingSelectors.selectStep);
    const subStep = useSelector(OnboardingSelectors.selectSubStep);
    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useMemoizedCreateSelector(
        CompaniesSelectors.createCompanyByIdSelector,
        freelancer.defaultCompanyId,
    );

    const dispatch = useDispatch();

    const nextUncompletedStepKey = Object.keys(subStepProgress).find(stepKey => !subStepProgress[stepKey].isCompleted);

    if (!subStep) {
        return null;
    }

    return (
        <StepContent>
            {step === OnboardingSteps.FINAL_POINTS && (
                <Box sx={{
                    position: 'absolute',
                    top: '40px',
                    left: '11.5px',
                    zIndex: '-2',
                    height: '20px',
                    borderLeft: '1px solid #4CAF50',
                }}
                />
            )}
            <Stepper
                sx={{
                    position: 'relative',
                    right: '24px',
                }}
                // activeStep={1}
                orientation="vertical"
                connector={step === OnboardingSteps.FINAL_POINTS ? <SubStepConnectorLast /> : <SubStepConnector />}
            >
                {subSteps.map(subStepConfig => {
                    const isNext = subStepConfig.key === nextUncompletedStepKey
                        && subStepConfig.requiredCompanyStatuses
                    && subStepConfig.requiredCompanyStatuses.includes(company.status);
                    const isActive = subStep === subStepConfig.key && activeStep === step;
                    const isCompleted = subStepProgress[subStepConfig.key]?.isCompleted;
                    const hasClickEvent = (isCompleted || (!isCompleted && isNext))
                        && subStepConfig?.hasActionOnClick !== false;

                    return (
                        <Step
                            key={subStepConfig.key}
                            active={isActive}
                            completed={isCompleted}
                            disabled={!hasClickEvent}
                            onClick={event => {
                                event.stopPropagation();

                                if (hasClickEvent) {
                                    dispatch(OnboardingActions.setStep(step, true));
                                    dispatch(OnboardingActions.setSubStep(subStepConfig.key));
                                }
                            }}
                            sx={{
                                'cursor': hasClickEvent ? 'pointer' : 'default',
                                '& .Mui-active': {
                                    fontWeight: 'normal',
                                },
                                '& .Mui-completed': {
                                    fontWeight: 'normal',
                                },
                                '& .SubStepStepIcon-circle': {
                                    backgroundColor: isCompleted ? '#4CAF50' : isActive ? '' : '#90CAF9',
                                },
                            }}
                        >
                            <StepLabel
                                StepIconComponent={SubStepStepIcon}
                                sx={{
                                    'minHeight': 36,
                                    'whiteSpace': 'normal',
                                    '& .Mui-active': {
                                        fontWeight: 'normal',
                                    },
                                }}
                            >
                                {hasLabel ? t(subStepConfig.labelKey) : ''}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </StepContent>
    );
};

FreelanceStepperSubStep.propTypes = {
    t: PropTypes.func.isRequired,
    hasLabel: PropTypes.bool.isRequired,
    subSteps: PropTypes.array.isRequired,
    subStepProgress: PropTypes.object.isRequired,
    step: PropTypes.string.isRequired,
};
