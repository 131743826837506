import {yupResolver} from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useChangePasswordFormStyles} from './styles';
import {ButtonWithCircularProgress} from '../../../components/buttons/ButtonWithCircularProgress';
import {DataItemRow} from '../../../components/data-item-row/DataItemRow';
import {DataItemRowFormLabel} from '../../../components/data-item-row/DataItemRowFormLabel';
import {PasswordField} from '../../../components/input/PasswordField';
import Yup from '../../../lib/yup/yup';
import {ChangePasswordActions} from '../store/changePasswordForm.actions';
import {ChangePasswordSelectors} from '../store/changePasswordForm.selectors';

const ChangePasswordForm = () => {
    const styles = useChangePasswordFormStyles();
    const {t} = useTranslation('login');
    const dispatch = useDispatch();

    const isInitialRender = useRef(true);

    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string().min(8, t('minLengthOldPassword')).required(t('currentPasswordIsRequired')),
        newPassword: Yup.string()
            .required(t('passwordIsRequired'))
            .matches(/[a-z]+/, t('requireLowercaseLetter'))
            .matches(/[A-Z]+/, t('requireUppercaseLetter'))
            .matches(/[0-9]+/, t('requireNumber'))
            .matches(/[ -/:-@[-`{-~]/, t('requireSpecialCharacter'))
            .min(12, t('minLengthPassword')),
        confirmNewPassword: Yup.string().required(t('passwordConfirmationIsRequired')).oneOf([Yup.ref('newPassword')], t('passwordsDontMatch')),
    });

    const {handleSubmit, control, formState, trigger, watch} = useForm({
        defaultValues: {
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        },
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    });
    const errors = formState.errors;

    const password = watch('newPassword');
    const confirmNewPassword = watch('confirmNewPassword');

    const onSubmit = useCallback(data => {
        dispatch(ChangePasswordActions.submitChangePasswordForm(data.currentPassword, data.newPassword));
    }, [dispatch]);

    const isLoading = useSelector(ChangePasswordSelectors.selectIsLoadingChangePasswordForm);

    const isSubmitButtonDisabled = useMemo(() => {
        return !formState.isValid;
    }, [formState.isValid]);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;

            return;
        }

        if (!confirmNewPassword) {
            return;
        }

        trigger('confirmNewPassword');
    // eslint-disable-next-line
    }, [password]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form} noValidate>
            <DataItemRow
                label={(
                    <DataItemRowFormLabel htmlFor="currentPassword" id="currentPasswordLabel">
                        {t('yourCurrentPassword')}
                    </DataItemRowFormLabel>
                )}
                content={(
                    <Controller
                        render={({field}) => <PasswordField errors={errors} disabled={isLoading} {...field} />}
                        id="currentPassword"
                        name="currentPassword"
                        variant="outlined"
                        control={control}
                        inputProps={{
                            tabIndex: 1,
                        }}
                        autoComplete="current-password"
                    />
                )}
            />

            <DataItemRow
                label={(
                    <DataItemRowFormLabel>
                        {t('typeNewPassword')}
                    </DataItemRowFormLabel>
                )}
                content={(
                    <Controller
                        render={({field}) => <PasswordField errors={errors} disabled={isLoading} {...field} />}
                        id="newPassword"
                        name="newPassword"
                        variant="outlined"
                        control={control}
                        inputProps={{
                            tabIndex: 2,
                        }}
                        autoComplete="new-password"
                    />
                )}
            />

            <DataItemRow
                label={(
                    <DataItemRowFormLabel>
                        {t('confirmNewPassword')}
                    </DataItemRowFormLabel>
                )}
                content={(
                    <Controller
                        render={({field}) => <PasswordField errors={errors} disabled={isLoading} {...field} />}
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        variant="outlined"
                        control={control}
                        inputProps={{
                            tabIndex: 3,
                        }}
                        autoComplete="confirm-new-password"
                    />
                )}
            />

            <div className={styles.footerContainer}>
                <ButtonWithCircularProgress
                    isLoading={isLoading}
                    buttonProps={{
                        type: 'submit',
                        size: 'large',
                        color: 'secondary',
                        variant: 'contained',
                        disabled: isSubmitButtonDisabled,
                        startIcon: <SaveIcon />,
                        tabIndex: 4,
                    }}
                >
                    {t('common:saveChanges')}
                </ButtonWithCircularProgress>
            </div>
        </form>
    );
};

export default ChangePasswordForm;
