import {LoadingButton} from '@mui/lab';
import {Button} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const ButtonAction = ({children, isLoading, loadingPosition, sx, ...rest}) => {
    let Component = Button;
    let props = {};

    if (isLoading) {
        Component = LoadingButton;

        props = {
            loading: true,
            loadingPosition: loadingPosition ?? 'center',
        };
    }

    return (
        <Component
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            sx={{py: theme => theme.spacing(1.9), fontSize: theme => theme.typography.pxToRem(15), ...sx}}
            {...props}
            {...rest}
        >
            {children}
        </Component>
    );
};

ButtonAction.propTypes = {
    isLoading: PropTypes.bool,
    loadingPosition: PropTypes.string,
    sx: PropTypes.object,
};

ButtonAction.defaultProps = {
    isLoading: false,
    loadingPosition: null,
    sx: {},
};
