import {all, call, put, takeLatest} from 'redux-saga/effects';

import * as actions from './signature.actions';
import {downloadDocument} from '../../../utils/download-document';
import {getSignatureRequest} from '../../api/providers/freelancer/freelancer.provider';
import {actionParamMapper, safe} from '../../safeSaga';
import {getFreelancerId} from '../../utils/get-freelancer-id';

const getSignatureSaga = function* (freelancerId) {
    try {
        yield put(actions.setIsLoadingSignature(true));

        const data = yield call(getSignatureRequest, freelancerId);

        const file = yield call(downloadDocument, data);

        yield put(actions.storeSignature(window.URL.createObjectURL(file)));

        yield put(actions.setIsLoadingSignature(false));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        yield put(actions.storeSignature(null));

        yield put(actions.setIsLoadingSignature(false));

        if (error?.response?.status === 404) {
            return;
        }

        throw error;
    }
};

// const uploadSignatureSaga = function* ({payload}) {
//     try {
//         yield put(actions.storeSignature(null));
//
//         yield put(actions.setIsUploadingSignature(true));
//
//         const currentCognitoUser = yield select(selectCurrentCognitoUser);
//
//         const {signedUrl} = yield call(uploadSignatureRequest, currentCognitoUser.id, payload.file);
//
//         const file = yield call(downloadDocument, signedUrl);
//
//         yield put(actions.storeSignature(window.URL.createObjectURL(file)));
//     } catch (error) {
//         // eslint-disable-next-line no-console
//         console.error(error);
//
//         Toast.error('anErrorOccurred');
//     } finally {
//         yield put(actions.setIsUploadingSignature(false));
//     }
// };

export const loadSignature = function* () {
    const freelancerId = yield call(getFreelancerId);

    yield put(actions.getSignature(freelancerId));
};

export const watchSignatureSagas = function* () {
    yield all([
        takeLatest(actions.GET_SIGNATURE, safe(actionParamMapper(getSignatureSaga, ['freelancerId']))),
        // takeEvery(actions.UPLOAD_SIGNATURE, uploadSignatureSaga),
    ]);
};
