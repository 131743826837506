import {yupResolver} from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingButton, TabContext, TabPanel} from '@mui/lab';
import {
    Box,
    FormControlLabel, Grid,
    Grow,
    Paper,
    Radio,
    RadioGroup,
    Slide,
    Tab,
    Tabs,
    Typography, useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import MoneySvg from '../../../../assets/svg/bank/money.svg';
import OtherLogo from '../../../../assets/svg/bank/other-banks.svg';
import SuitcaseSvg from '../../../../assets/svg/bank/suitcase-time.svg';
import WritingSvg from '../../../../assets/svg/bank/wiriting-on-paper.svg';
import BNPLogo from '../../../../assets/svg/logo/bnp.svg';
import QontoLogo from '../../../../assets/svg/logo/qonto.svg';
import {PageHeader} from '../../../../components/page-header/PageHeader';
import {AnimationActions} from '../../../../features/animations/store/animations.action';
import {AnimationsSelectors} from '../../../../features/animations/store/animations.selector';
import {ANIMATION_TYPE, FORM_ANIMATION_DELAY} from '../../../../features/animations/utils/constants';
import HiwayBankPromoPage from '../../../../features/bank/components/HiwayBankPromoPage';
import {BankActions} from '../../../../features/bank/store/bank.action';
import {FreelancerSelectors} from '../../../../features/freelancer';
import {CompaniesSelectors} from '../../../../features/freelancer/modules/companies';
import {
    CompanySetupInternalSubSteps,
    CompanySetupSubSteps,
} from '../../../../features/freelancer/modules/company-setup/utils/constants';
import {OnboardingActions} from '../../../../features/freelancer/modules/onboarding/store/onboarding.action';
import {LoggedInUserSelectors} from '../../../../features/user/modules/logged-in-user';
import Yup from '../../../../lib/yup/yup';
import {isUserCare} from '../../../../utils/user-roles';
import {submitActiveStepForm} from '../../../app/company/setupCompany/setupCompany.actions';
import {BANKS} from '../../../app/company/setupCompany/setupCompany.constants';
import {getIsLoadingSetupCompanyForm} from '../../../app/company/setupCompany/setupCompany.selectors';

const TAB_VALUES = {
    HIWAY: 'HIWAY',
    OTHER: 'OTHER',
};

const BankForm = () => {
    const {t} = useTranslation('setup');
    const [tabValue, setTabValue] = useState(TAB_VALUES.HIWAY);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const dispatch = useDispatch();
    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const onHiwayBankClick = () => {
        if (company?.companyInfo?.hiway_bank_info) {
            dispatch(AnimationActions.storeNextDispatch(
                OnboardingActions.setInternalSubStep(CompanySetupInternalSubSteps.DATA_VALIDATED),
            ));

            dispatch(AnimationActions.setIsSubAnimationActive(false));

            dispatch(OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_UPLOAD));
        } else {
            // If user is switching company we need to clear documents
            // TODO This should be improved on BE
            if (company?.companyInfo?.bank) {
                dispatch(OnboardingActions.resetCompanySetupFlow());
            }
            dispatch(BankActions.createBankAccountOnboarding());
        }
    };

    if (!loggedInUser?.hasBankAccess) {
        return (
            <>
                <Box sx={{
                    p: isMobileSize ? 2 : 0,
                }}
                >
                    <PageHeader
                        subTitle={t('bankSection.bankSubHeader')}
                    >
                        {t('bankSection.bankHeader')}
                    </PageHeader>


                    <Slide
                        direction="left"
                        in={isAnimationActive}
                        timeout={FORM_ANIMATION_DELAY}
                        onEntered={onEndedAnimation}
                        onExited={onEndedAnimation}
                    >
                        <div>
                            <BankFormOtherBanks
                                company={company}
                                onBackClick={() => {
                                    dispatch(OnboardingActions.setInternalSubStep(
                                        CompanySetupSubSteps.COMPANY_INFORMATION,
                                    ));
                                    dispatch(OnboardingActions.setSubStep(CompanySetupSubSteps.COMPANY_INFORMATION));
                                }}
                            />
                        </div>
                    </Slide>
                </Box>
            </>
        );
    }

    return (
        <>
            <Box sx={{
                p: isMobileSize ? 2 : 0,
            }}
            >
                <PageHeader
                    subTitle={t('bankSection.bankSubHeader')}
                >
                    {t('bankSection.bankHeader')}
                </PageHeader>


                <Slide
                    direction="left"
                    in={isAnimationActive}
                    timeout={FORM_ANIMATION_DELAY}
                    onEntered={onEndedAnimation}
                    onExited={onEndedAnimation}
                >
                    <div>
                        <TabContext value={tabValue}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <Tabs value={tabValue} onChange={(e, value) => setTabValue(value)} centered>
                                    <Tab
                                        sx={{
                                            width: isMobileSize ? '45%' : '264px',
                                        }}
                                        label={t('bankSection.tabHiway')}
                                        value={TAB_VALUES.HIWAY}
                                    />
                                    <Tab
                                        sx={{
                                            width: isMobileSize ? '45%' : '264px',
                                        }}
                                        disabled={company?.companyInfo?.hiway_bank_info}
                                        label={t('bankSection.tabOther')}
                                        value={TAB_VALUES.OTHER}
                                    />
                                </Tabs>
                            </Box>

                            <TabPanel value={TAB_VALUES.HIWAY}>
                                <Grow
                                    in={isSubAnimationActive}
                                    timeout={FORM_ANIMATION_DELAY}
                                    onExited={() => {
                                        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                                    }}
                                >
                                    <div>
                                        <HiwayBankPromoPage
                                            onCreationStartClickFn={onHiwayBankClick}
                                            creationButtonTranslation="activateServiceOnboarding"
                                        />
                                    </div>
                                </Grow>
                            </TabPanel>

                            <TabPanel value={TAB_VALUES.OTHER}>
                                <BankFormOtherBanks
                                    company={company}
                                    onBackClick={() => {
                                        setTabValue(TAB_VALUES.HIWAY);
                                    }}
                                />
                            </TabPanel>
                        </TabContext>
                    </div>
                </Slide>
            </Box>
        </>
    );
};


const defaultValues = {
    bank: BANKS.QONTO,
    bankName: '',
    bankAddress: '',
};

const BankFormOtherBanks = ({onBackClick, company}) => {
    const {t} = useTranslation('setup');

    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoadingForm = useSelector(getIsLoadingSetupCompanyForm);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const hasBankAccess = loggedInUser?.hasBankAccess;
    const isCareUserRole = isUserCare(loggedInUser);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const validationSchema = Yup.object().shape({
        bank: Yup.string().oneOf(Object.values(BANKS))
            .required(t('validations.isRequired', {what: t('bank')})),
        bankName: Yup.string().when('bank', {
            is: BANKS.OTHER,
            then: Yup.string().max(80, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('bankName')})),
        }),
        bankAddress: isCareUserRole ? Yup.mixed() : Yup.string().when('bank', {
            is: BANKS.OTHER,
            then: Yup.string().max(400, t('validations.maxMessage')).required(t('validations.isRequired', {what: t('bankAddress')})),
        }),
    });

    const {
        formState: {errors, isDirty, isValid},
        control,
        watch,
        handleSubmit: createSubmitHandler,
        setValue,
    } = useForm({
        mode: 'onChange',
        defaultValues: company?.companyInfo?.bank ? {
            bank: company?.companyInfo?.bank,
            bankName: company?.companyInfo?.name,
            bankAddress: company?.companyInfo?.bank_address,
        }
            : defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const bank = watch('bank');

    const handleSubmit = useCallback(data => {
        dispatch(submitActiveStepForm(data, true, null, false));
        // eslint-disable-next-line
    }, [dispatch, isDirty]);

    return (
        <Box>
            <Slide
                direction="left"
                in={isAnimationActive}
                timeout={FORM_ANIMATION_DELAY}
                onEntered={onEndedAnimation}
                onExited={onEndedAnimation}
            >
                <div>
                    <form
                        onSubmit={createSubmitHandler(handleSubmit)}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '32px 40px',
                                borderRadius: '24px',
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    mb: 1,
                                }}
                            >
                                {t(hasBankAccess ? 'bankSection.header' : 'bankSection.headerCanNotAccessBankFeature')}
                            </Typography>

                            <Typography>
                                {t(hasBankAccess ? 'bankSection.subHeader' : 'bankSection.subHeaderCanNotAccessBankFeature')}
                            </Typography>

                            <Controller
                                control={control}
                                name="bank"
                                render={({field}) => (
                                    <RadioGroup
                                        {...field}
                                    >
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: isMobileSize ? 'column' : 'row',
                                            gap: 2,
                                            mt: 3,
                                            mb: 3,
                                        }}
                                        >
                                            <Box
                                                onClick={() => {
                                                    setValue('bank', BANKS.QONTO);
                                                }}
                                                sx={{
                                                    border: '2px solid rgba(0, 0, 0, 0.12)',
                                                    borderRadius: '16px',
                                                    p: 1,
                                                    pr: 3,
                                                    display: 'flex',
                                                    gap: 3,
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <img src={QontoLogo} alt="qonto logo" />
                                                <FormControlLabel
                                                    value={BANKS.QONTO}
                                                    control={<Radio />}
                                                    label={t(`companies:bankNames.Qonto`)}
                                                />
                                            </Box>

                                            <Box
                                                onClick={() => {
                                                    setValue('bank', BANKS.BNP);
                                                }}
                                                sx={{
                                                    border: '2px solid rgba(0, 0, 0, 0.12)',
                                                    borderRadius: '16px',
                                                    p: 1,
                                                    pr: 3,
                                                    display: 'flex',
                                                    gap: 3,
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <img src={BNPLogo} alt="qonto logo" />
                                                <FormControlLabel
                                                    value={BANKS.BNP}
                                                    control={<Radio />}
                                                    label={t(`companies:bankNames.BNP`)}
                                                />
                                            </Box>

                                            <Box
                                                onClick={() => {
                                                    setValue('bank', BANKS.OTHER);
                                                }}
                                                sx={{
                                                    border: '2px solid rgba(0, 0, 0, 0.12)',
                                                    borderRadius: '16px',
                                                    p: 1,
                                                    pr: 3,
                                                    display: 'flex',
                                                    gap: 3,
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <img src={OtherLogo} alt="qonto logo" />
                                                <FormControlLabel
                                                    value={BANKS.OTHER}
                                                    control={<Radio />}
                                                    label={t(`companies:bankNames.OTHER`)}
                                                />
                                            </Box>
                                        </Box>
                                    </RadioGroup>
                                )
                            }
                            />


                            <>
                                {bank === BANKS.OTHER && (
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={isCareUserRole ? 12 : 6}>
                                        <Controller
                                            name="bankName"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    label={t('enterBankName')}
                                                    variant="filled"
                                                    error={!!errors.bankName}
                                                    helperText={errors.bankName?.message}
                                                    required
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {!isCareUserRole && (
                                        <Grid item xs={12} md={6}>
                                            <Controller
                                                name="bankAddress"
                                                variant="filled"
                                                control={control}
                                                render={({field}) => (
                                                    <TextField
                                                        {...field}
                                                        label={t('enterBankAddress')}
                                                        variant="filled"
                                                        error={!!errors.bankAddress}
                                                        helperText={errors.bankAddress?.message}
                                                        required
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                )}
                            </>
                        </Paper>

                        <Grow
                            in={isSubAnimationActive}
                            timeout={FORM_ANIMATION_DELAY}
                            onExited={() => {
                                dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                            }}
                        >
                            {
                                !hasBankAccess
                                    ? (<Box />)
                                    : (
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: isMobileSize ? 'column' : 'row',
                                            gap: 3,
                                            mt: 3,
                                        }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    padding: '32px 40px',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: 1,
                                                    flex: '1 0 0',
                                                    alignSelf: 'stretch',
                                                    borderRadius: '24px',
                                                    background: 'radial-gradient(43.23% 43.23% at 26.52% 24.92%, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%), #173244',
                                                    color: 'white',
                                                    width: '284px',
                                                }}
                                            >
                                                <img src={MoneySvg} alt="Money" />
                                                <Typography
                                                    variant="h5"
                                                >
                                                    {t('bankSection.flaw1Title')}
                                                </Typography>

                                                <Typography>
                                                    {t('bankSection.flaw1Text')}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    padding: '32px 40px',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: 1,
                                                    flex: '1 0 0',
                                                    alignSelf: 'stretch',
                                                    borderRadius: '24px',
                                                    background: 'radial-gradient(43.23% 43.23% at 26.52% 24.92%, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%), #173244',
                                                    color: 'white',
                                                    width: '284px',
                                                }}
                                            >
                                                <img src={SuitcaseSvg} alt="Suitcase" />
                                                <Typography
                                                    variant="h5"
                                                >
                                                    {t('bankSection.flaw2Title')}
                                                </Typography>

                                                <Typography>
                                                    {t('bankSection.flaw2Text')}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    padding: '32px 40px',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: 1,
                                                    flex: '1 0 0',
                                                    alignSelf: 'stretch',
                                                    borderRadius: '24px',
                                                    background: 'radial-gradient(43.23% 43.23% at 26.52% 24.92%, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%), #173244',
                                                    color: 'white',
                                                    width: '284px',
                                                }}
                                            >
                                                <img src={WritingSvg} alt="Writing" />
                                                <Typography
                                                    variant="h5"
                                                >
                                                    {t('bankSection.flaw3Title')}
                                                </Typography>

                                                <Typography>
                                                    {t('bankSection.flaw3Text')}
                                                </Typography>
                                            </Box>

                                        </Box>
                                    )
                            }
                        </Grow>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: 3,
                        }}
                        >
                            <Button
                                variant="outlined"
                                startIcon={<ArrowBackIcon />}
                                onClick={onBackClick}
                            >
                                {t('bankSection.back')}
                            </Button>

                            <LoadingButton
                                loading={isLoadingForm}
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={bank === BANKS.OTHER && (!isValid || !isDirty)}
                                endIcon={<ArrowForwardIcon />}
                            >
                                {t('bankSection.continue')}
                            </LoadingButton>
                        </Box>
                    </form>
                </div>
            </Slide>
        </Box>
    );
};

BankFormOtherBanks.propTypes = {
    onBackClick: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
};

export default BankForm;
