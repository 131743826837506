import axiosInstance, {safeTransformData} from '../../axios';

export const generateDocumentsRequest = (freelancerId, companyId, stage = 1) => {
    return axiosInstance({
        method: 'POST',
        params: {
            stage,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/generate-documents`,
    }).then(response => safeTransformData(response));
};

export const submitForApprovalRequest = (freelancerId, companyId) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/submit-edits`,
    }).then(response => safeTransformData(response));
};

export const downloadAsArchiveRequest = (freelancerId, companyId, documentIds, archivingStrategy) => {
    return axiosInstance({
        method: 'POST',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents/archive`,
        data: {
            documents: documentIds,
            archiving_strategy: archivingStrategy,
        },
    }).then(response => safeTransformData(response));
};
