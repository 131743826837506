import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import {Button, Slide, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, generatePath} from 'react-router-dom';
import documentsValidatedMobile from '../../../../../assets/png/documents-validated-mobile.png';
import documentsValidated from '../../../../../assets/svg/documentsValidated.svg';
import Banner from '../../../../../layout/ContainerWithBigImage/Banner';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {isUserCare} from '../../../../../utils/user-roles';
import {getMyCompany} from '../../../../../v1/app/my-companies/myCompanies.actions';
import {selectMyCompany} from '../../../../../v1/app/my-companies/myCompanies.selectors';
import {getIsRegistrationWithExistingCompany} from '../../../../../v1/utils';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {FreelancerSelectors} from '../../../store/freelancer.selector';
import {CompaniesActions} from '../../companies';
import {CompanyRegistrationInternalSubSteps} from '../../company-registration/utils/constants';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';
import {OnboardingSteps} from '../../onboarding/utils/onboadingStepsConstant';

export const DataValidatedScreen = ({isCareIbanBicReplacementScreen}) => {
    const {t} = useTranslation('freelancerOnboarding');

    const dispatch = useDispatch();

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.REGISTRATION_NOT_PREPARED),
    );

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useSelector(selectMyCompany);

    const isUserCareRole = isUserCare(loggedInUser);

    useEffect(() => {
        if (!company && freelancer?.defaultCompanyId) {
            dispatch(getMyCompany(freelancer.defaultCompanyId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freelancer]);
    const registrationType = company?.enterpriseInformation?.registrationType;
    const registrationWithExistingCompany = getIsRegistrationWithExistingCompany(registrationType);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const onNextClick = () => {
        if (isCareIbanBicReplacementScreen) {
            dispatch(OnboardingActions.determineCareLastStep());
            return;
        }
        if (registrationWithExistingCompany) {
            dispatch(OnboardingActions.setInternalSubStep(CompanyRegistrationInternalSubSteps.IBAN_BIC_FORM));
            dispatch(AnimationActions.storeNextDispatch(
                OnboardingActions.setStep(OnboardingSteps.COMPANY_REGISTRATION),
            ));
            dispatch(AnimationActions.setIsSubAnimationActive(false));
        } else if (isUserCare(loggedInUser)) {
            dispatch(CompaniesActions.registrationNotPrepared({isFromUser: true}));
        } else {
            dispatch(AnimationActions.storeNextDispatch(
                OnboardingActions.setStep(OnboardingSteps.CAPITAL_DEPOSIT),
            ));
            dispatch(AnimationActions.setIsSubAnimationActive(false));
        }
    };

    if (!isMobileSize) {
        return (
            <Box display="grid" gridTemplateRows="min-content 1fr">
                <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                    <div>
                        <Banner
                            header={(
                                <Trans t={t} i18nKey="companyCreateEnd.headline" components={{bold: <Box component="span" sx={{color: '#4CAF50'}} />}} />
                            )}
                            text={isUserCareRole ? t('companyCreateEnd.textCare') : t('companyCreateEnd.text')}
                            Illustration={(
                                <Box sx={{mt: 1}}>
                                    <img src={documentsValidated} alt="documents validated" />
                                </Box>
                            )}
                            nextButtonText={t('companyCreateEnd.next')}
                            isButtonDisabled={isLoading}
                            onNextClick={onNextClick}
                            cardSx={{
                                background: 'linear-gradient(270deg, #F1F8E9 0%, #FFFFFF 55.73%)',
                            }}
                            content={(
                                <Box sx={{mt: 3}}>
                                    <Box>
                                        <Button
                                            startIcon={<DocumentScannerIcon />}
                                            variant="contained"
                                            component={Link}
                                            size="large"
                                            sx={{
                                                py: theme => theme.spacing(1.9),
                                                fontSize: theme => theme.typography.pxToRem(15),
                                                mr: 2,
                                            }}
                                            to={`${generatePath(RoutePaths.DOCUMENTS, {
                                                freelancerId: loggedInUser.id,
                                                companyId: freelancer.defaultCompanyId,
                                            })}?documents-tabFilter=COMPANY_SETUP`}
                                        >
                                            {t('documentsValidated.viewDocumentsValidated')}
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        />
                    </div>
                </Slide>
            </Box>
        );
    }

    return (
        <Box display="grid" gridTemplateRows="min-content 1fr">
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <div>
                    <Banner
                        header={(
                            <Trans t={t} i18nKey="companyCreateEnd.headline" components={{bold: <Box component="span" sx={{color: '#4CAF50'}} />}} />
                        )}
                        text={isUserCareRole ? t('companyCreateEnd.textCare') : t('companyCreateEnd.text')}
                        Illustration={(
                            <Box sx={{mt: 1}}>
                                <img src={documentsValidatedMobile} alt="documents validated" />
                            </Box>
                        )}
                        nextButtonText={t('companyCreateEnd.next')}
                        onNextClick={onNextClick}
                        cardSx={{
                            background: 'linear-gradient(270deg, #F1F8E9 0%, #FFFFFF 55.73%)',
                        }}
                        content={(
                            <Box sx={{mt: 3}}>
                                <Box>
                                    <Button
                                        startIcon={<DocumentScannerIcon />}
                                        variant="contained"
                                        component={Link}
                                        size="large"
                                        sx={{
                                            py: theme => theme.spacing(1.9),
                                            fontSize: theme => theme.typography.pxToRem(15),
                                            mr: 2,
                                        }}
                                        to={generatePath(`${RoutePaths.DOCUMENTS}?documents-tabFilter=COMPANY_SETUP`, {
                                            freelancerId: loggedInUser.id,
                                            companyId: freelancer.defaultCompanyId,
                                        })}
                                    >
                                        {t('documentsValidated.viewDocumentsValidated')}
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    />
                </div>
            </Slide>
        </Box>
    );
};

DataValidatedScreen.propTypes = {
    isCareIbanBicReplacementScreen: PropTypes.bool,
};

DataValidatedScreen.defaultProps = {
    isCareIbanBicReplacementScreen: false,
};
