import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import {Box, Button, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Warranty from '../../../../assets/pdf/protocole-rc-pro-v-3.pdf';

const RcRightCard = () => {
    const {t} = useTranslation('insurance');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{
            display: 'flex',
            padding: isMobileSize ? 3 : '32px 40px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            flex: '1 0 0',
            borderRadius: 4,
            backgroundColor: 'primary_v2.main',
            boxShadow: '0px 2px 12px 0px rgba(20, 20, 43, 0.08)',
            gap: isMobileSize ? 3 : null,
        }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 2,
            }}
            >
                <Typography
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(34),
                        fontWeight: 700,
                        color: 'whitePallete.main',
                    }}
                >
                    {t('tabs.rc.benefits')}
                </Typography>
                <List sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    width: '100%',
                    color: 'whitePallete.main',
                }}
                >
                    <ListItem sx={{p: 0}}>
                        <ListItemIcon>
                            <CheckCircleIcon sx={{color: 'whitePallete.main'}} />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('tabs.rc.cyberLiability')}
                            primaryTypographyProps={{
                                fontSize: theme => theme.typography.pxToRem(isMobileSize ? 16 : 18),
                                lineHeight: '20px',
                            }}
                        />
                    </ListItem>
                    <ListItem sx={{p: 0}}>
                        <ListItemIcon>
                            <CheckCircleIcon sx={{color: 'whitePallete.main'}} />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('tabs.rc.advisor')}
                            primaryTypographyProps={{
                                fontSize: theme => theme.typography.pxToRem(isMobileSize ? 16 : 18),
                                lineHeight: '20px',
                            }}
                        />
                    </ListItem>
                    <ListItem sx={{p: 0}}>
                        <ListItemIcon>
                            <CheckCircleIcon sx={{color: 'whitePallete.main'}} />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('tabs.rc.coverage')}
                            primaryTypographyProps={{
                                fontSize: theme => theme.typography.pxToRem(isMobileSize ? 16 : 18),
                                lineHeight: '20px',
                            }}
                        />
                    </ListItem>
                    <ListItem sx={{p: 0}}>
                        <ListItemIcon>
                            <CheckCircleIcon sx={{color: 'whitePallete.main'}} />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('tabs.rc.disaster')}
                            primaryTypographyProps={{
                                fontSize: theme => theme.typography.pxToRem(isMobileSize ? 16 : 18),
                                lineHeight: '20px',
                            }}
                        />
                    </ListItem>
                </List>
            </Box>
            <Button
                href={Warranty}
                download
                fullWidth
                variant="outlined"
                color="whitePallete"
                size="medium"
                startIcon={<DownloadForOfflineIcon />}
                sx={{height: isMobileSize ? 'auto' : '36px'}}
            >
                {t('tabs.rc.download')}
            </Button>
        </Box>
    );
};

export default RcRightCard;
