import makeStyles from '@mui/styles/makeStyles';

export const useDataTableCellStyles = makeStyles(theme => ({
    root: ({isJoyTheme}) => ({
        fontFamily: isJoyTheme ? 'Avantt' : 'Red Hat Text',
        fontSize: theme.typography.pxToRem(isJoyTheme ? 16 : 14),
        color: theme.palette.v2.light.text.main,
        fontWeight: isJoyTheme ? 500 : 'inherit',
    }),
    link: {
        'fontFamily': 'Red Hat Text',
        'fontSize': theme.typography.pxToRem(14),
        'color': 'rgba(0, 0, 0, 0.87)',
        '&:hover': {
            textDecoration: 'none',
            color: '#0a7bc4',
        },
    },
}));
