import {SvgIcon} from '@mui/material';

export const EventIcon = props => (
    <SvgIcon viewBox="0 0 49 48" {...props}>
        <path
            d="M30.5088 37C29.1483 37 28.0013 36.5303 27.068 35.5909C26.1346 34.6514 25.668 33.5014 25.668 32.1409C25.668 30.7803 26.1377 29.6333 27.0771 28.7C28.0166 27.7667 29.1666 27.3 30.5271 27.3C31.8877 27.3 33.0346 27.7697 33.968 28.7092C34.9013 29.6486 35.368 30.7986 35.368 32.1591C35.368 33.5197 34.8983 34.6667 33.9588 35.6C33.0194 36.5333 31.8694 37 30.5088 37ZM9.66797 44C8.86797 44 8.16797 43.7 7.56797 43.1C6.96797 42.5 6.66797 41.8 6.66797 41V10C6.66797 9.2 6.96797 8.5 7.56797 7.9C8.16797 7.3 8.86797 7 9.66797 7H12.918V4H16.168V7H33.168V4H36.418V7H39.668C40.468 7 41.168 7.3 41.768 7.9C42.368 8.5 42.668 9.2 42.668 10V41C42.668 41.8 42.368 42.5 41.768 43.1C41.168 43.7 40.468 44 39.668 44H9.66797ZM9.66797 41H39.668V19.5H9.66797V41ZM9.66797 16.5H39.668V10H9.66797V16.5Z"
            fill="currentColor"
        />
    </SvgIcon>
);
