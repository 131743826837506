import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Switch,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import RadioAccordion from '../../../../../../../../components/radio/RadioAccordion';
import {UiActions} from '../../../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../../../ui/utils/constants';
import {TransactionsActions} from '../../../../store/transactions.action';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {CATEGORY_MAGIC_SUBSCRIPTION_TYPE} from '../../../../util/constants';
import PreviewSubscriptionSidebar from '../debit/PreviewSubscriptionSidebar';

const SubscriptionSidebar = ({details, isTransactionsOpenedDefault}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const isSubscribed = details?.hasActiveSubscription;

    const correspondingMatchingTransactions = useSelector(TransactionsSelector.selectMatchingSubcription);
    const elligibleTransactions = useSelector(TransactionsSelector.selectEligibleMatchingSubcription);

    const [isSubscribeChecked, setIsSubscribedChecked] = useState(isSubscribed);
    const [radioValue, setRadioValue] = useState(null);
    const [isTransactionsOpen, setIsTransactionsOpen] = useState(isTransactionsOpenedDefault);

    const hasDocuments = details?.documents?.length > 0;
    const isDocumentRequired = details?.flags?.documentsRequired;
    const hasAlert = !hasDocuments
        && isDocumentRequired
        && !details?.ignoreProofDocument
        && !details?.refuseToShareProofDocuments;

    useEffect(() => {
        if (!isTransactionsOpenedDefault) {
            dispatch(TransactionsActions.getEligibleTransactionMatchingSubscription({
                id: details?.id,
                accountId: details?.bankAccountId,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isSubscribeChecked && isSubscribed) {
            dispatch(UiActions.setModalData(ModalsKeys.CATEGORIZATION_DEACTIVATE_SUBSCRIPTION, {
                id: details?.id,
                accountId: details?.bankAccountId,
                setIsSubscribedChecked,
            }));
            dispatch(UiActions.setActiveModal(ModalsKeys.CATEGORIZATION_DEACTIVATE_SUBSCRIPTION, true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubscribeChecked]);

    const onSubscriptionSubmit = () => {
        dispatch(TransactionsActions.activateSubscription({
            id: details?.id,
            accountId: details?.bankAccountId,
            type: radioValue,
        }));
    };

    if (isTransactionsOpen) {
        return (
            <PreviewSubscriptionSidebar
                correspondingTransactions={
                isTransactionsOpenedDefault
                    ? correspondingMatchingTransactions
                    : elligibleTransactions
                }
                onClose={() => setIsTransactionsOpen(false)}
            />
        );
    }

    return (
        <Box sx={{
            p: 2,
            pt: 6,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        }}
        >
            <Typography variant="h5">
                {t('categorisation.subscription.sidebarTitle')}
            </Typography>

            <Typography sx={{
                fontSize: theme => theme.typography.pxToRem(14),
            }}
            >
                {t('categorisation.subscription.sidebarText')}
            </Typography>

            {!details?.hasActiveSubscription
                && elligibleTransactions && elligibleTransactions?.length > 0 && (
                <Box
                    sx={{
                        'display': 'flex',
                        'justifyContent': 'space-between',
                        'alignItems': 'center',
                        'px': 2,
                        'py': 1.5,
                        'backgroundColor': 'v2.light.primary.shades8',
                        'fontSize': theme => theme.typography.pxToRem(14),
                        'borderRadius': '8px',
                        'cursor': 'pointer',
                        '&:hover': {
                            '.MuiSvgIcon-root': {
                                color: 'black',
                            },
                        },
                    }}
                    onClick={() => setIsTransactionsOpen(true)}
                >
                    <Box>
                        {
                        t(
                            'categorisation.subscription.correspondingTransactions',
                            {number: elligibleTransactions?.length},
                        )
                    }
                    </Box>
                    <Box>
                        <ArrowForwardIosIcon sx={{
                            color: 'v2.blueGray.500',
                            fontSize: '22px',
                        }}
                        />
                    </Box>
                </Box>
            )}

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 1,
                backgroundColor: 'v2.light.primary.shades4',
                fontSize: theme => theme.typography.pxToRem(14),
                borderRadius: '8px',
            }}
            >
                <FormControlLabel
                    sx={{
                        fontWeight: '700',
                    }}
                    control={(
                        <Switch
                            checked={isSubscribeChecked}
                            onChange={(_, value) => setIsSubscribedChecked(value)}
                        />
                    )}
                    label={t('categorisation.subscription.enableAuto')}
                />
            </Box>

            {isSubscribeChecked && !isSubscribed && (
                <Box>
                    <FormControl>
                        <FormLabel sx={{
                            fontWeight: 700,
                        }}
                        >
                            {t('categorisation.subscription.applyTo')}
                        </FormLabel>
                        <RadioGroup
                            value={radioValue}
                            onChange={(_, value) => setRadioValue(value)}
                        >
                            <RadioAccordion
                                value={CATEGORY_MAGIC_SUBSCRIPTION_TYPE.THIS_AND_FUTURE}
                                text={t('categorisation.subscription.thisAndFutureText')}
                                label={t('categorisation.subscription.thisAndFuture')}
                            />
                            <RadioAccordion
                                isDisabled={!elligibleTransactions || elligibleTransactions?.length === 0}
                                value={CATEGORY_MAGIC_SUBSCRIPTION_TYPE.ALL}
                                text={t('categorisation.subscription.thisAndAllText')}
                                label={t('categorisation.subscription.thisAndAll')}
                            />

                        </RadioGroup>
                    </FormControl>

                    {hasAlert && (
                        <Box sx={{
                            mt: 2,
                        }}
                        >
                            <Alert
                                severity="warning"
                            >
                                <Typography>
                                    {t('categorisation.subscription.warningTitle')}
                                </Typography>

                                <Typography>
                                    {t('categorisation.subscription.warningText')}
                                </Typography>
                            </Alert>
                        </Box>
                    )}

                    {!isSubscribed && (
                    <Button
                        sx={{
                            mt: 2,
                        }}
                        startIcon={<AutoFixHighIcon />}
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={!radioValue || hasAlert}
                        onClick={onSubscriptionSubmit}
                    >
                        {t('categorisation.subscription.activate')}
                    </Button>
                    )}
                </Box>
            )}
        </Box>
    );
};

SubscriptionSidebar.propTypes = {
    details: PropTypes.object.isRequired,
    isTransactionsOpenedDefault: PropTypes.bool,
};

SubscriptionSidebar.defaultProps = {
    isTransactionsOpenedDefault: false,
};

export default SubscriptionSidebar;
