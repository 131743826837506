import {UserSelectActionTypes} from './user-select.action-type';

export const UserSelectActions = {
    getUsers: () => ({
        type: UserSelectActionTypes.GET_USERS,
    }),
    storeUserIds: ids => ({
        type: UserSelectActionTypes.STORE_USER_IDS,
        payload: ids,
    }),
    storeUserEntities: entities => ({
        type: UserSelectActionTypes.STORE_USER_ENTITIES,
        payload: entities,
    }),
};
