import {
    faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert} from '@mui/joy';
import PropTypes from 'prop-types';

export const AlertInfo = ({
    children,
    sx,
}) => (
    <Alert
        color="neutral"
        variant="outlined"
        sx={{
            ...sx,
        }}
        startDecorator={(
            <FontAwesomeIcon icon={faCircleInfo} />
        )}
    >
        <div>
            {children}
        </div>
    </Alert>
);

AlertInfo.propTypes = {
    sx: PropTypes.object,
};

AlertInfo.defaultProps = {
    sx: {},
};
