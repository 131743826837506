import PropTypes from 'prop-types';
import React from 'react';
import {ErrPageButton} from './ErrPageButton';
import {ErrPageComponent} from './ErrPageComponent';
import {ErrPageContainer} from './ErrPageContainer';

export const ErrPageWrapper = ({
    title,
    onSubmit,
    buttonLabel,
    additionalButton,
    buttonIcon,
    buttonIconPosition,
    children,
}) => {
    return (
        <ErrPageContainer>
            <ErrPageComponent
                onSubmit={onSubmit}
                title={title}
            >
                {children}

                <ErrPageButton
                    label={buttonLabel}
                    icon={buttonIcon}
                    iconPosition={buttonIconPosition}
                />

                {additionalButton}
            </ErrPageComponent>
        </ErrPageContainer>
    );
};

ErrPageWrapper.propTypes = {
    title: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    buttonIcon: PropTypes.any,
    additionalButton: PropTypes.node,
    buttonIconPosition: PropTypes.oneOf(['start', 'end']),
};

ErrPageWrapper.defaultProps = {
    title: null,
    buttonIcon: null,
    additionalButton: null,
    buttonIconPosition: 'start',
};
