import {Box, Paper, Skeleton} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import {Document, Page} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

export const TrainingViewPdf = ({file}) => {
    const [pageNumbers, setPageNumbers] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [outerWidth, setOuterWidth] = useState(0);

    const containerRef = useRef();

    const handleDocumentLoadSuccess = ({numPages}) => {
        const allPageNumbers = [];

        for (let p = 1; p < numPages + 1; p += 1) {
            allPageNumbers.push(p);
        }

        setPageNumbers(allPageNumbers);
        setOuterWidth(containerRef.current.offsetWidth);
        setIsLoading(false);

        setTimeout(() => {
            setOuterWidth(containerRef.current.offsetWidth - 5);
        }, 70);
    };

    return (
        <Box sx={{borderRadius: '8px'}}>
            {(isLoading) && (
                <Box
                    sx={{
                        height: `calc(100vh - '192px'})`,
                        display: 'flex',
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#fafafa',
                        boxShadow: '0px 5px 5px -3px rgba(11, 35, 51, 0.08), 0px 8px 10px 1px rgba(11, 35, 51, 0.08), 0px 3px 14px 2px rgba(11, 35, 51, 0.1)',
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                        padding: 5,
                        overflow: 'hidden',
                        marginBottom: -24,
                    }}
                >
                    <Skeleton variant="circular" width={70} height={70} sx={{transform: 'scale(1, 1)', marginLeft: '13%', mt: '7%'}} />
                    <Skeleton width="20vw" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                    <Skeleton width="13vw" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '9%'}} />
                    <Skeleton width="100%" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                    <Skeleton width="100%" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                    <Skeleton width="100%" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                    <Skeleton width="23vw" height={12} sx={{transform: 'scale(1, 1)', mb: '8%'}} />
                    <Skeleton width="28vw" height={12} sx={{transform: 'scale(1, 1)', mb: '2.2%'}} />
                    <Skeleton width="39vw" height={12} sx={{transform: 'scale(1, 1)', mb: '2.2%'}} />
                    <Skeleton width="33vw" height={12} sx={{transform: 'scale(1, 1)', mb: '2.2%'}} />
                    <Skeleton width="46vw" height={12} sx={{transform: 'scale(1, 1)', mb: '8%'}} />
                    <Skeleton width="100%" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                    <Skeleton width="100%" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                    <Skeleton width="100%" height={12} sx={{transform: 'scale(1, 1)', marginLeft: 'auto', marginRight: 'auto', mb: '2%'}} />
                    <Skeleton width="23vw" height={12} sx={{transform: 'scale(1, 1)'}} />
                </Box>
            )}
            <Paper sx={{borderRadius: '8px', py: 2}} elevation={1}>
                <Box ref={containerRef}>
                    <Document
                        file={file}
                        onLoadSuccess={handleDocumentLoadSuccess}
                        options={{workerSrc: 'pdf.worker.min.js'}}
                        externalLinkTarget="_blank"
                    >
                        {pageNumbers && pageNumbers.map(pageNumber => (
                            <React.Fragment key={pageNumber}>
                                <Page
                                    pageNumber={pageNumber}
                                    width={outerWidth}
                                />
                            </React.Fragment>
                        ))}
                    </Document>
                </Box>
            </Paper>
        </Box>
    );
};

TrainingViewPdf.propTypes = {
    file: PropTypes.string.isRequired,
};
