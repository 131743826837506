const TRAINING_STEPS = {
    OPCO_REGISTRATION: 'OPCO_REGISTRATION',
    MY_ATLAS: 'MY_ATLAS',
    DOSSIER: 'DOSSIER',
    FUNDING_APPROVAL: 'FUNDING_APPROVAL',
    FINALIZATION: 'FINALIZATION',
};

const DEFAULT_TRAINING_PROGRESS = {
    [TRAINING_STEPS.OPCO_REGISTRATION]: {
        completed: false,
    },
    [TRAINING_STEPS.MY_ATLAS]: {
        completed: false,
    },
    [TRAINING_STEPS.DOSSIER]: {
        completed: false,
    },
    [TRAINING_STEPS.FUNDING_APPROVAL]: {
        completed: false,
    },
    [TRAINING_STEPS.FINALIZATION]: {
        completed: false,
    },
};

const DOSSIER_STEPS = {
    DOCUMENT_GENERATION: 'DOSSIER_DOCUMENT_GENERATION',
    DOCUMENT_SIGNING: 'DOSSIER_DOCUMENT_SIGNING',
    DOCUMENT_DOWNLOAD: 'DOSSIER_DOCUMENT_DOWNLOAD',
    DOCUMENT_VIEW: 'DOSSIER_DOCUMENT_VIEW',
};

const TRAINING_STATUS = {
    // Init status:
    PENDING: 'PENDING',

    // STEP 1
    ATLAS_APPLICATION: 'ATLAS_APPLICATION',
    // STEP 2
    ATLAS_ACCOUNT_CREATION: 'ATLAS_ACCOUNT_CREATION',
    // STEP 3
    ATLAS_SUPPORT_PENDING_SIGNATURE: 'ATLAS_SUPPORT_PENDING_SIGNATURE',
    ATLAS_SUPPORT_DOCUMENTS_PAGE: 'ATLAS_SUPPORT_DOCUMENTS_PAGE',
    ATLAS_SUPPORT_PENDING_FILE_NUMBER: 'ATLAS_SUPPORT_PENDING_FILE_NUMBER',
    // STEP 4
    ATLAS_VALIDATION_PENDING: 'ATLAS_VALIDATION_PENDING',
    ATLAS_VALIDATION_REJECTED: 'ATLAS_VALIDATION_REJECTED',
    ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE: 'ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE',
    ATLAS_VALIDATION_SUCCESS_CHECKLIST: 'ATLAS_VALIDATION_SUCCESS_CHECKLIST',
    // STEP 5
    ATLAS_FINALIZATION_PENDING_PAYMENT: 'ATLAS_FINALIZATION_PENDING_PAYMENT',

    // Final status:
    COMPLETED: 'COMPLETED',
};

const ZIP_VARIANTS = {
    TRAINING_STEP_3: 'TRAINING_STEP_3',
    TRAINING_STEP_4: 'TRAINING_STEP_4',
};

// TODO Do it in the table at some point once we completely go on this design
const TABLE_STYLE_WRAPPER = {
    '.MuiTableBody-root .MuiTableRow-root:last-child > .MuiTableCell-root': {
        borderBottom: 'none',
    },
    '.MuiPaper-rounded': {
        boxShadow: 'none',
        border: '1px solid rgb(224, 224, 224)',
        mt: 3,
    },
    '.MuiPaper-rounded .MuiTableContainer-root': {
        mt: 0,
    },
    '.MuiPaper-rounded .MuiTableContainer-root th': {
        py: 1.7,
    },
    '.MuiPaper-rounded .MuiTableContainer-root td': {
        py: 1,
    },
};

export const TRAINING_CONSTANTS = {
    TRAINING_STEPS,
    DEFAULT_TRAINING_PROGRESS,
    DOSSIER_STEPS,
    TRAINING_STATUS,
    ZIP_VARIANTS,
    TABLE_STYLE_WRAPPER,
};
