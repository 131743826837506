import BlockIcon from '@mui/icons-material/Block';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import PhysicalCardSvgWrapped from '../../../../../../assets/wrapped-svg/physical-card';
import {CARD_TYPES, CardPhysicalStatus, SuspendReason} from '../../util/constants';

export const PhysicalCard = ({
    t,
    user,
    card,
    activateCardModal,
    unlockBlockedModal,
    unlockCard,
    setSelectedCard,
}) => {
    const cardNumber = card?.physicalCard?.cardMaskedNumber
        ? card.physicalCard.cardMaskedNumber.replaceAll('X', '•')
        : '••••••••••••••••';
    const expieryDate = card?.physicalCard?.expiryDate ?? '••/••';

    const numberOne = cardNumber.substring(0, 4);
    const numberTwo = cardNumber.substring(4, 8);
    const numberThree = cardNumber.substring(8, 12);
    const numberFour = cardNumber.substring(12, 16);

    const status = card?.physicalCard?.status;
    const isInactive = status === CardPhysicalStatus.TO_ACTIVATE;
    const isProccessing = status === CardPhysicalStatus.PROCESSING;
    const isSuspended = status === CardPhysicalStatus.SUSPENDED;

    const isBlur = isInactive || isSuspended || isProccessing;

    const fullName = `${user.firstName} ${user.lastName}`;

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    height: '224px',
                }}
                onClick={() => {
                    setSelectedCard(CARD_TYPES.PHYS);
                }}
            >
                {isProccessing && (
                    <Box sx={{
                        position: 'absolute',
                        top: '-20px',
                        zIndex: 2,

                        mt: '26px',
                        left: '34px',
                        backdropFilter: isBlur ? 'blur(5.4908px)' : 'none',
                        background: 'rgba(39, 41, 42, 0.4)',
                        height: '206px',
                        borderRadius: '16px',
                        width: '330px',
                    }}
                    >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            pt: '54.5px',
                        }}
                        >
                            <Box>
                                <Box sx={{
                                    width: '64px',
                                    height: '64px',
                                    backgroundColor: 'primary_v2.main',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                >
                                    <HourglassTopIcon sx={{color: 'white'}} />
                                </Box>
                            </Box>
                            <Box sx={{
                                fontWeight: 500,
                                color: 'white',
                                mt: 1,
                                mb: 2,
                            }}
                            >
                                {t('cardDetails.processingText')}
                            </Box>
                        </Box>
                    </Box>
                )}
                {isInactive && (
                    <Box sx={{
                        position: 'absolute',
                        top: '-20px',
                        zIndex: 2,

                        mt: '26px',
                        left: '34px',
                        backdropFilter: isBlur ? 'blur(5.4908px)' : 'none',
                        background: 'rgba(39, 41, 42, 0.4)',
                        height: '206px',
                        borderRadius: '16px',
                        width: '330px',
                    }}
                    >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            pt: '27.5px',
                        }}
                        >
                            <Box>
                                <Box sx={{
                                    width: '64px',
                                    height: '64px',
                                    backgroundColor: 'primary_v2.main',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                >
                                    <HourglassTopIcon sx={{color: 'white'}} />
                                </Box>
                            </Box>
                            <Box sx={{
                                fontWeight: 500,
                                color: 'white',
                                mt: 1,
                                mb: 2,
                            }}
                            >
                                {t('cardDetails.activateCardText')}
                            </Box>
                            <Box>
                                <Button
                                    startIcon={<CreditScoreIcon />}
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        py: 0.6,
                                    }}
                                    onClick={activateCardModal}
                                >
                                    {t('cardDetails.activateCardButton')}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                )}

                {isSuspended
                && (
                    <>
                        {card?.physicalCard?.suspendReason === SuspendReason.USER_ACTION ? (
                            <Box sx={{
                                position: 'absolute',
                                top: '-20px',
                                zIndex: 2,

                                mt: '26px',
                                left: '34px',
                                backdropFilter: isBlur ? 'blur(5.4908px)' : 'none',
                                background: 'rgba(39, 41, 42, 0.4)',
                                height: '206px',
                                borderRadius: '16px',
                                width: '330px',
                            }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    pt: '17.5px',
                                }}
                                >
                                    <Box>
                                        <Box sx={{
                                            width: '64px',
                                            height: '64px',
                                            backgroundColor: 'v2.light.error.dark',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        >
                                            <BlockIcon sx={{color: 'white'}} />
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        fontWeight: 500,
                                        color: 'white',
                                        mt: 1,
                                        mb: 2,
                                        maxWidth: '300px',
                                        textAlign: 'center',
                                    }}
                                    >
                                        {t('cardDetails.suspendReason3PinsText')}
                                    </Box>
                                    <Box>
                                        <Button
                                            startIcon={<LockOpenIcon />}
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                py: 0.6,
                                                borderRadius: '8px',
                                            }}
                                            onClick={unlockBlockedModal}
                                        >
                                            {t('cardDetails.suspendReason3PinsButton')}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{
                                position: 'absolute',
                                top: '-20px',
                                zIndex: 2,

                                mt: '26px',
                                left: '34px',
                                backdropFilter: isBlur ? 'blur(5.4908px)' : 'none',
                                background: 'rgba(39, 41, 42, 0.4)',
                                height: '206px',
                                borderRadius: '16px',
                                width: '330px',
                            }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    pt: '27.5px',
                                }}
                                >
                                    <Box>
                                        <Box sx={{
                                            width: '64px',
                                            height: '64px',
                                            backgroundColor: 'primary_v2.main',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        >
                                            <LockIcon sx={{color: 'white'}} />
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        fontWeight: 500,
                                        color: 'white',
                                        mt: 1,
                                        mb: 2,
                                    }}
                                    >
                                        {t('cardDetails.suspendedLockText')}
                                    </Box>
                                    <Box>
                                        <Button
                                            startIcon={<LockIcon />}
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            sx={{
                                                py: 0.6,
                                                borderRadius: '8px',
                                            }}
                                            onClick={unlockCard}
                                        >
                                            {t('cardDetails.suspendedLockButton')}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </>
                )
            }

                <Box sx={{
                    position: 'relative',
                    top: '-22px',
                    userSelect: 'none',
                }}
                >
                    <PhysicalCardSvgWrapped sx={{
                        width: '396px',
                        height: '275px',
                    }}
                    />

                    <Box sx={{
                        position: 'absolute',
                        top: '45px',
                        left: '60px',
                        color: 'white',
                        fontWeight: 500,
                        fontSize: theme => theme.typography.pxToRem(fullName.length <= 22 ? 17.57 : 14),
                        lineHeight: fullName.length <= 22 ? '27.59px' : '19.6px',
                        letterSpacing: '0.11px',
                        textShadow: '0px 2.19632px 1.09816px rgba(11, 35, 51, 0.05), 0px 1.09816px 1.09816px rgba(11, 35, 51, 0.05), 0px 1.09816px 3.29448px rgba(11, 35, 51, 0.07)',
                        maxWidth: '210px',
                        overflowX: 'auto',
                    }}
                    >
                        <Box sx={fullName.length <= 55 ? {} : {
                            'overflow': 'hidden',
                            'textOverflow': 'ellipsis',
                            'display': '-webkit-box',
                            '-webkit-line-clamp': '2',
                            '-webkit-box-orient': 'vertical',
                        }}
                        >
                            {fullName}
                        </Box>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        top: '130px',
                        left: '70px',
                        color: 'white',
                        fontWeight: 400,
                        fontSize: theme => theme.typography.pxToRem(24.1595),
                        lineHeight: '116.7%',
                        letterSpacing: '3px',
                        display: 'flex',
                        gap: 1,
                        textShadow: '0px 2.19632px 1.09816px rgba(11, 35, 51, 0.05), 0px 1.09816px 1.09816px rgba(11, 35, 51, 0.05), 0px 1.09816px 3.29448px rgba(11, 35, 51, 0.07)',
                    }}
                    >
                        <Box>{numberOne}</Box>
                        <Box>{numberTwo}</Box>
                        <Box>{numberThree}</Box>
                        <Box>{numberFour}</Box>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        top: '190px',
                        left: '60px',
                        color: 'white',
                        fontWeight: 400,
                        fontSize: theme => theme.typography.pxToRem(14.57),
                        lineHeight: '22.87px',
                        letterSpacing: '0.09px',
                        display: 'flex',
                        gap: '35px',
                        textShadow: '0px 2.19632px 1.09816px rgba(11, 35, 51, 0.05), 0px 1.09816px 1.09816px rgba(11, 35, 51, 0.05), 0px 1.09816px 3.29448px rgba(11, 35, 51, 0.07)',
                    }}
                    >
                        <Box>{expieryDate}</Box>
                        <Box>CVC •••</Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

PhysicalCard.propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    card: PropTypes.object,
    activateCardModal: PropTypes.func.isRequired,
    unlockBlockedModal: PropTypes.func.isRequired,
    unlockCard: PropTypes.func.isRequired,
    setSelectedCard: PropTypes.func,
};

PhysicalCard.defaultProps = {
    setSelectedCard: () => {},
    card: {},
};
