export const getMeridiem = (date, utils) => {
    if (!date) {
        return null;
    }

    return utils.getHours(date) >= 12 ? 'pm' : 'am';
};

export const convertValueToMeridiem = (value, meridiem, ampm) => {
    if (ampm) {
        const currentMeridiem = value >= 12 ? 'pm' : 'am';
        if (currentMeridiem !== meridiem) {
            return meridiem === 'am' ? value - 12 : value + 12;
        }
    }

    return value;
};

export const convertToMeridiem = (time, meridiem, ampm, utils) => {
    const newHoursAmount = convertValueToMeridiem(
        utils.getHours(time),
        meridiem,
        ampm,
    );
    return utils.setHours(time, newHoursAmount);
};

export const getSecondsInDay = (date, utils) => {
    return (
        utils.getHours(date) * 3600
    + utils.getMinutes(date) * 60
    + utils.getSeconds(date)
    );
};

export const createIsAfterIgnoreDatePart = (
    disableIgnoringDatePartForTimeValidation = false,
    utils,
) => (dateLeft, dateRight) => {
    if (disableIgnoringDatePartForTimeValidation) {
        return utils.isAfter(dateLeft, dateRight);
    }

    return getSecondsInDay(dateLeft, utils) > getSecondsInDay(dateRight, utils);
};
