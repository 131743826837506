import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';

const TDFCanvas = ({shapes, ...props}) => {
    const canvasRef = useRef(null);
    const size = props.width ?? 0;

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        context.clearRect(0, 0, canvas.width, canvas.height);
        shapes.forEach(shape => shape(context));
    }, [shapes, size]);

    return <canvas ref={canvasRef} {...props} />;
};

TDFCanvas.propTypes = {
    shapes: PropTypes.arrayOf(PropTypes.func).isRequired,
    width: PropTypes.number.isRequired,
};

export default TDFCanvas;
