import {User} from './user-select.dto';
import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';

export const UserSelectApi = {
    getUsers: ({role}) => hiwayApiInstance
        .get(`/v1/users`, {params: {roles: role || undefined}})
        .then(result => {
            return {
                ids: result.data.map(userDto => userDto.id),
                entities: result.data.reduce((accumulator, current) => {
                    accumulator[current.id] = new User(current);

                    return accumulator;
                }, {}),
            };
        }),
};
