import {createSelector} from 'reselect';

const selectEntities = state => state.freelancer.companies.entities;

const createIdsSelector = createSelector([selectEntities], entities => Object.keys(entities));

const createArraySelector = createSelector([selectEntities], entities => Object.values(entities));

const createCompanyByIdSelector = companyId => createSelector(
    [selectEntities],
    entities => entities[companyId],
);

export const CompaniesSelectors = {
    selectEntities,
    createIdsSelector,
    createArraySelector,
    createCompanyByIdSelector,
};
