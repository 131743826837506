export const ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const DISPATCH_UNHANDLED_ERROR = 'DISPATCH_UNHANDLED_ERROR';

const getDefaultOptions = () => ({
    key: new Date().getTime() + Math.random(),
    variant: 'success',
    dismissed: false,
});

export const enqueueNotification = (message, options = {}) => ({
    type: ENQUEUE_NOTIFICATION,
    payload: {
        message,
        options: {
            ...getDefaultOptions(),
            ...options,
        },
    },
});

export const closeNotification = key => ({
    type: CLOSE_NOTIFICATION,
    payload: key,
});

export const removeNotification = key => ({
    type: REMOVE_NOTIFICATION,
    payload: key,
});

export const dispatchUnhandledError = () => ({
    type: DISPATCH_UNHANDLED_ERROR,
});
