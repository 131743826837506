import {Grid, MenuItem, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const SelectedJobProfession = props => {
    const {selectedJobProfession, onJobProfessionChange, availableJobProfessions} = props;
    const {t} = useTranslation('createUser');
    const handleJobProfessionChange = event => {
        onJobProfessionChange(selectedJobProfession, event.target.value);
    };
    return (
        <Grid item md={6} sm={11} xs={10}>
            <TextField
                variant="outlined"
                label={t('jobType')}
                fullWidth
                sx={{pr: {xs: 1, md: 2}}}
                select
                value={selectedJobProfession}
                onChange={event => handleJobProfessionChange(event)}
            >
                {availableJobProfessions
                    .filter(item => !item.selected || item.jobProfession === selectedJobProfession)
                    .map((item, id) => (
                        <MenuItem key={id} value={item.jobProfession}>
                            {t(`jobTypes.${item.jobProfession}`)}
                        </MenuItem>
                    ))}
            </TextField>
        </Grid>
    );
};

SelectedJobProfession.propTypes = {
    selectedJobProfession: PropTypes.string.isRequired,
    onJobProfessionChange: PropTypes.func.isRequired,
    availableJobProfessions: PropTypes.array.isRequired,
};

export default SelectedJobProfession;
