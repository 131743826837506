import makeStyles from '@mui/styles/makeStyles';

export const useDocumentStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        fontSize: theme.typography.pxToRem(14),
        color: 'rgba(0, 0, 0, 0.87)',
    },
    icon: {
        marginRight: theme.spacing(2),
    },
}));
