import {Typography} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {usePageHeaderStyles} from './styles';

const PageHeader = ({title, subTitle, titleVariant, hasMargin, buttonComponent}) => {
    const styles = usePageHeaderStyles();
    const {t} = useTranslation();

    return (
        <div className={clsx(styles.container, hasMargin && styles.hasMargin)}>
            <div className={styles.titleAndSubTitleContainer}>
                {title ? (
                    <Typography variant={titleVariant} className={styles.title}>
                        {title}
                    </Typography>
                ) : (
                    <Typography variant={titleVariant} className={styles.missingTitle}>
                        {t('noInput')}
                    </Typography>
                )}

                {!!subTitle && (
                    <Typography variant="body1" className={styles.subTitle}>
                        {subTitle}
                    </Typography>
                )}
            </div>

            {!!buttonComponent && buttonComponent}
        </div>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    buttonComponent: PropTypes.node,
    subTitle: PropTypes.string,
    hasMargin: PropTypes.bool,
    titleVariant: PropTypes.oneOf([
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'subtitle1',
        'subtitle2',
        'body1',
        'body2',
        'caption',
        'button',
        'overline',
        'srOnly',
    ]),
};

PageHeader.defaultProps = {
    buttonComponent: null,
    subTitle: null,
    titleVariant: 'h4',
    hasMargin: true,
};

export default PageHeader;
