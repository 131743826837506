import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Stack} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const iconStyles = {
    fontSize: theme => theme.typography.pxToRem(20),
};

const icons = {
    UPLOADED: <CheckCircleIcon sx={{...iconStyles, color: 'success_v2.light'}} />,
    MISSING: <ErrorOutlineIcon sx={{...iconStyles, color: 'error_v2.main'}} />,
};

const FecStatus = ({rowData}) => {
    const {finalFecSubmitted} = rowData;
    const {t} = useTranslation('financialStatements');
    return (
        <Stack direction="row" spacing={1} whiteSpace="nowrap">
            <span>{finalFecSubmitted ? icons.UPLOADED : icons.MISSING}</span>
            <span>
                {finalFecSubmitted
                    ? t('submittedFecStatus.uploaded')
                    : t('submittedFecStatus.missing')
                }
            </span>
        </Stack>
    );
};

FecStatus.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default FecStatus;
