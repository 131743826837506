import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';

const getVigilanceCert = (freelancerId, companyId) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}/companies/${companyId}/documents/vigilance-cert`)
        .then(result => result?.data);
};

const getLatestBalanceSheet = (freelancerId, companyId) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}/companies/${companyId}/documents/latest-balance-sheet`)
        .then(result => result?.data);
};

const getKbis = (freelancerId, companyId) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}/companies/${companyId}/documents/kbis`)
        .then(result => result?.data);
};

const reissueVigilanceCert = (freelancerId, companyId) => {
    return hiwayApiInstance
        .post(`/v1/freelancers/${freelancerId}/companies/${companyId}/documents/reissue-vigilance-cert`)
        .then(result => result?.data);
};

export const QuickAccessApi = {
    getVigilanceCert,
    getLatestBalanceSheet,
    getKbis,
    reissueVigilanceCert,
};
