import {yupResolver} from '@hookform/resolvers/yup';
import BlockIcon from '@mui/icons-material/Block';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CreditScoreTwoToneIcon from '@mui/icons-material/CreditScoreTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PinIcon from '@mui/icons-material/Pin';
import {LoadingButton} from '@mui/lab';
import {Box, TextField, Typography} from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import PhysicalCardActivateSvgWrapped from '../../../../../../assets/wrapped-svg/physical-card-activate';
import {ModalWrapper} from '../../../../../../components/modal-wrapper/ModalWrapper';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {UiSelectors} from '../../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {CardsActions} from '../../store/cards.actions';
import {MODAL_TYPES} from '../../util/constants';

const modalKey = ModalsKeys.BANK_CARD_MODAL;

export const BankCardModal = ({card}) => {
    const {t} = useTranslation('bankCards');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));

    return (
        <ModalWrapper
            modalKey={modalKey}
            containerSx={{
                width: data?.type === MODAL_TYPES.ACTIVATE_CARD ? '700px' : '583px',
            }}
        >
            {({handleClose}) => (
                <Box sx={!isMobileSize ? {} : {p: 2, pt: 0}}>
                    {data?.type === MODAL_TYPES.VIEW_PIN && (
                        <CardDetailsModalViewPinContent
                            isDrawer={false}
                            card={card}
                            t={t}
                            handleClose={handleClose}
                        />
                    )}

                    {data?.type === MODAL_TYPES.ACTIVATE_CARD && (
                        <CardDetailsModalActivateCard
                            isDrawer={false}
                            card={card}
                            t={t}
                            handleClose={handleClose}
                        />
                    )}

                    {data?.type === MODAL_TYPES.PHYS_INFO && (
                        <CardDetailsModalPhysInfo
                            isDrawer={false}
                            card={card}
                            t={t}
                            handleClose={handleClose}
                        />
                    )}

                    {data?.type === MODAL_TYPES.VIRTUAL_INFO && (
                        <CardDetailsModalVirtualInfo
                            isDrawer={false}
                            card={card}
                            t={t}
                            handleClose={handleClose}
                        />
                    )}

                    {data?.type === MODAL_TYPES.UNLOCK_BLOCKED && (
                        <CardDetailsModalUnblockCard
                            isDrawer={false}
                            card={card}
                            t={t}
                            handleClose={handleClose}
                        />
                    )}
                </Box>
            )}
        </ModalWrapper>
    );
};

BankCardModal.propTypes = {
    card: PropTypes.object,
};

BankCardModal.defaultProps = {
    card: {},
};

export const CardDetailsModalViewPinContent = ({card, t, isDrawer, handleClose}) => {
    const dispatch = useDispatch();

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GENERIC_CRUD_LOADER),
    );
    const onSubmit = () => {
        dispatch(CardsActions.viewPin(card?.id));
    };

    return (
        <>
            {isDrawer && (
                <Box sx={{
                    pt: 4,
                    mb: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
                >
                    <Box sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        backgroundColor: 'v2.blueGray.50',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        <PinIcon sx={{
                            color: '#607D8B',
                            fontSize: '40px',
                        }}
                        />
                    </Box>
                </Box>
            )}
            <Typography
                sx={{
                    textAlign: isDrawer ? 'center' : 'left',
                }}
                variant={isDrawer ? 'h5' : 'h4'}
            >
                {t('pinModal.title')}
            </Typography>
            <Box>
                {card?.physicalCard?.isPinReady === false && (
                    <Box sx={{mt: 3}}>
                        <Alert
                            severity="warning"
                            variant="filled"
                            sx={{
                                backgroundColor: 'warning_v2.main',
                            }}
                        >
                            {t('pinModal.alert')}
                        </Alert>
                    </Box>
                )}
                <Box sx={{
                    display: 'flex',
                    mt: 3,
                    gap: 2,
                }}
                >
                    {!isDrawer && (
                    <Box>
                        <Box sx={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '50%',
                            backgroundColor: 'v2.blueGray.50',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        >
                            <PinIcon sx={{
                                color: '#607D8B',
                                fontSize: '40px',
                            }}
                            />
                        </Box>
                    </Box>
                    )}
                    <Box>
                        {t('pinModal.text')}
                    </Box>
                </Box>

                <Box sx={{
                    mt: isDrawer ? 3 : 7,
                    mb: isDrawer ? 2 : 0,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    gap: 3,
                }}
                >
                    {!isDrawer && (
                    <Button
                        color="grey"
                        variant="contained"
                        onClick={handleClose}
                    >
                        {t('pinModal.cancel')}
                    </Button>
                    )}
                    <LoadingButton
                        fullWidth={isDrawer}
                        startIcon={<OpenInNewIcon />}
                        color="secondary"
                        disabled={card?.physicalCard?.isPinReady === false}
                        variant="contained"
                        isLoading={isLoading}
                        onClick={onSubmit}
                    >
                        {t('pinModal.confirm')}
                    </LoadingButton>
                </Box>
            </Box>
        </>
    );
};

CardDetailsModalViewPinContent.propTypes = {
    card: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    isDrawer: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export const CardDetailsModalActivateCard = ({t, card, isDrawer, handleClose}) => {
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        code: Yup.string().test(
            'code-length',
            t('activateCardModal.labelError'),
            val => val.length === 10,
        ).required(t('setup:validations.isRequired', {what: t('activateCardModal.label')})),
    });

    const {control, formState, handleSubmit} = useForm({
        defaultValues: {
            code: '',
        },
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    });

    const {errors, isValid} = formState;

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GENERIC_CRUD_LOADER),
    );

    const onSubmit = useCallback(data => {
        dispatch(CardsActions.activatePhysCard(card?.id, data.code));
        // eslint-disable-next-line
    }, [card, dispatch]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {isDrawer && (
                <Box sx={{
                    pt: 4,
                    mb: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
                >
                    <Box sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        backgroundColor: 'v2.blueGray.50',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        <CreditScoreTwoToneIcon sx={{
                            color: '#607D8B',
                            fontSize: '40px',
                        }}
                        />
                    </Box>
                </Box>
            )}
            <Typography
                sx={{
                    textAlign: isDrawer ? 'center' : 'left',
                }}
                variant={isDrawer ? 'h5' : 'h4'}
            >
                {t('activateCardModal.title')}
            </Typography>
            <Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: isDrawer ? 'column' : 'row',
                    alignItems: isDrawer ? 'center' : 'flex-start',
                }}
                >
                    <Box sx={{mt: 3}}>
                        {t('activateCardModal.text')}

                        {!isDrawer && (
                            <Box sx={{mt: 3}}>
                                <Controller
                                    control={control}
                                    name="code"
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            variant="outlined"
                                            label={t('activateCardModal.label')}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#fff',
                                                },
                                            }}
                                            error={!!errors.code}
                                            helperText={errors.code?.message}
                                        />
                                    )}
                                />
                            </Box>
                        )}
                    </Box>

                    <PhysicalCardActivateSvgWrapped sx={{
                        width: '396px',
                        height: '275px',
                        mr: -4,
                        ml: isDrawer ? -4 : 0,
                        mb: -4,
                    }}
                    />
                </Box>

                {isDrawer && (
                    <Box sx={{mt: 2, mb: 3}}>
                        <Controller
                            control={control}
                            name="code"
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    label={t('activateCardModal.label')}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: '#fff',
                                        },
                                    }}
                                    error={!!errors.code}
                                    helperText={errors.code?.message}
                                />
                            )}
                        />
                    </Box>
                )}

                <Box sx={{
                    mt: isDrawer ? 0 : 4,
                    mb: isDrawer ? 2 : 0,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    gap: 3,
                }}
                >
                    {!isDrawer
                    && (
                    <Button
                        color="grey"
                        variant="contained"
                        onClick={handleClose}
                    >
                        {t('activateCardModal.cancel')}
                    </Button>
                    )}
                    <LoadingButton
                        fullWidth={isDrawer}
                        startIcon={<CreditScoreIcon />}
                        color="secondary"
                        variant="contained"
                        type="submit"
                        disabled={!isValid}
                        isLoading={isLoading}
                    >
                        {t('activateCardModal.confirm')}
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    );
};

CardDetailsModalActivateCard.propTypes = {
    card: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    isDrawer: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export const CardDetailsModalPhysInfo = ({t, isDrawer}) => {
    return (
        <>
            {isDrawer && (
                <Box sx={{
                    pt: 4,
                    mb: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
                >
                    <Box sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        backgroundColor: 'v2.blueGray.50',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        <InfoIcon sx={{
                            color: '#607D8B',
                            fontSize: '40px',
                        }}
                        />
                    </Box>
                </Box>
            )}
            <Typography
                sx={{
                    textAlign: isDrawer ? 'center' : 'left',
                }}
                variant={isDrawer ? 'h5' : 'h4'}
            >
                {t('physInfoModal.title')}
            </Typography>
            <Box>
                <Box sx={{
                    display: 'flex',
                    mt: 3,
                    mb: 2,
                    gap: 2,
                }}
                >
                    {!isDrawer && (
                    <Box>
                        <Box sx={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '50%',
                            backgroundColor: 'v2.blueGray.50',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        >
                            <InfoIcon sx={{
                                color: '#607D8B',
                                fontSize: '40px',
                            }}
                            />
                        </Box>
                    </Box>
                    )}
                    <Box>
                        {t('physInfoModal.text')}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

CardDetailsModalPhysInfo.propTypes = {
    t: PropTypes.func.isRequired,
    isDrawer: PropTypes.bool.isRequired,
};

export const CardDetailsModalVirtualInfo = ({t, isDrawer}) => {
    return (
        <>
            {isDrawer && (
                <Box sx={{
                    pt: 4,
                    mb: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
                >
                    <Box sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        backgroundColor: 'v2.blueGray.50',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        <InfoIcon sx={{
                            color: '#607D8B',
                            fontSize: '40px',
                        }}
                        />
                    </Box>
                </Box>
            )}
            <Typography
                sx={{
                    textAlign: isDrawer ? 'center' : 'left',
                }}
                variant={isDrawer ? 'h5' : 'h4'}
            >
                {t('virtualInfoModal.title')}
            </Typography>
            <Box>
                <Box sx={{
                    display: 'flex',
                    mt: 3,
                    mb: 2,
                    gap: 2,
                }}
                >
                    {!isDrawer && (
                    <Box>
                        <Box sx={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '50%',
                            backgroundColor: 'v2.blueGray.50',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        >
                            <InfoIcon sx={{
                                color: '#607D8B',
                                fontSize: '40px',
                            }}
                            />
                        </Box>
                    </Box>
                    )}
                    <Box>
                        {t('virtualInfoModal.text')}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

CardDetailsModalVirtualInfo.propTypes = {
    t: PropTypes.func.isRequired,
    isDrawer: PropTypes.bool.isRequired,
};

export const CardDetailsModalUnblockCard = ({t, card, isDrawer, handleClose}) => {
    const now = moment();
    const dispatch = useDispatch();

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GENERIC_CRUD_LOADER),
    );
    const onSubmit = () => {
        dispatch(CardsActions.unlockCard(card?.id));
    };

    const isWaiting = !moment(card?.physicalCard?.suspendDate).add(1, 'd').isBefore(now);

    return (
        <>
            {isDrawer && (
                <Box sx={{
                    pt: 4,
                    mb: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
                >
                    <Box sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        backgroundColor: 'v2.light.error.dark',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        <BlockIcon sx={{
                            color: 'white',
                            fontSize: '40px',
                        }}
                        />
                    </Box>
                </Box>
            )}
            <Typography
                sx={{
                    textAlign: isDrawer ? 'center' : 'left',
                }}
                variant={isDrawer ? 'h5' : 'h4'}
            >
                {t('unblockModal.title')}
            </Typography>
            <Box>
                <Box sx={{
                    display: 'flex',
                    mt: 3,
                    gap: 2,
                }}
                >
                    {!isDrawer && (
                    <Box>
                        <Box sx={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '50%',
                            backgroundColor: 'v2.light.error.dark',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        >
                            <BlockIcon sx={{
                                color: 'white',
                                fontSize: '40px',
                            }}
                            />
                        </Box>
                    </Box>
                    )}
                    <Box>
                        <Typography sx={{color: 'secondary.main'}}>
                            {t('unblockModal.info')}
                        </Typography>
                        {isWaiting && (
                            <Alert
                                sx={{
                                    backgroundColor: '#E1A348',
                                    mt: 2,
                                    mb: 2,
                                }}
                                severity="warning"
                                variant="filled"
                            >
                                {t('unblockModal.waitingText')}
                            </Alert>
                        )}
                        <Typography>
                            {t('unblockModal.text')}
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{
                    mt: isDrawer ? 3 : 7,
                    mb: isDrawer ? 2 : 0,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    gap: 3,
                }}
                >
                    {!isDrawer && (
                    <Button
                        color="grey"
                        variant="contained"
                        onClick={handleClose}
                    >
                        {t('unblockModal.cancel')}
                    </Button>
                    )}
                    <LoadingButton
                        fullWidth={isDrawer}
                        startIcon={<CreditScoreIcon />}
                        color="secondary"
                        disabled={isWaiting}
                        variant="contained"
                        isLoading={isLoading}
                        onClick={isWaiting ? () => {} : onSubmit}
                    >
                        {t('unblockModal.confirm')}
                    </LoadingButton>
                </Box>
            </Box>
        </>
    );
};

CardDetailsModalUnblockCard.propTypes = {
    card: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    isDrawer: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default BankCardModal;
