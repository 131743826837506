import {ButtonGroup} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import {useController} from 'react-hook-form';

export const ToggleField = ({name, defaultValue, required, validate, options, ...other}) => {
    const {field} = useController({name, defaultValue, rules: {required, validate}});
    return (
        <ButtonGroup sx={{minHeight: theme => theme.typography.pxToRem(60)}} {...other}>
            {options.map(option => (
                <Button
                    variant={field.value === option.value ? 'contained' : 'outlined'}
                    key={option.value}
                    value={option.value}
                    aria-label={option.label}
                    onClick={() => field.onChange(option.value)}
                    disableElevation
                    color="primary_v2"
                    sx={{
                        px: 3,
                        py: 1,
                    }}
                    startIcon={<option.icon />}
                >
                    {option.label}
                </Button>
            ))}
        </ButtonGroup>
    );
};

ToggleField.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    // eslint-disable-next-line react/boolean-prop-naming
    required: PropTypes.bool,
    validate: PropTypes.func,
    options: PropTypes.array.isRequired,
};

ToggleField.defaultProps = {
    defaultValue: undefined,
    required: false,
    validate: undefined,
};
