import {OnboardingActionTypes} from './onboarding.action-type';

export const OnboardingActions = {
    setStep: (step, redirectToOnboarding) => ({
        type: OnboardingActionTypes.SET_STEP,
        payload: step,
        redirectToOnboarding,
    }),
    setSubStep: subStep => ({
        type: OnboardingActionTypes.SET_SUB_STEP,
        payload: subStep,
    }),
    setInternalSubStep: subStep => ({
        type: OnboardingActionTypes.SET_INTERNAL_SUB_STEP,
        payload: subStep,
    }),
    setProgress: progress => ({
        type: OnboardingActionTypes.SET_PROGRESS,
        payload: progress,
    }),
    resetCompanySetupFlow: () => ({
        type: OnboardingActionTypes.RESET_COMPANY_SETUP_FLOW,
    }),
    loadCompany: (freelancerId, companyId) => ({
        type: OnboardingActionTypes.LOAD_COMPANY,
        payload: {
            freelancerId,
            companyId,
        },
    }),
    activateCompany: (freelancerId, companyId) => ({
        type: OnboardingActionTypes.ACTIVATE_COMPANY,
        payload: {
            freelancerId,
            companyId,
        },
    }),
    setOnboardingFinalStatus: data => ({
        type: OnboardingActionTypes.SET_ONBOARDING_FINAL_STATUS,
        payload: {
            data,
        },
    }),
    determineStepSubStep: () => ({
        type: OnboardingActionTypes.DETERMINE_STEP_SUBSTEP,
    }),
    determineCareLastStep: () => ({
        type: OnboardingActionTypes.DETERMINE_CARE_LAST_STEP,
    }),
};
