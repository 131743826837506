export const CARD_TYPES = {
    PHYS: 'phys',
    VIRTUAL: 'virtual',
};

export const MODAL_TYPES = {
    VIEW_PIN: 'viewPin',
    ACTIVATE_CARD: 'activateCard',
    PHYS_INFO: 'physInfo',
    VIRTUAL_INFO: ' virtualInfo',
    UNLOCK_BLOCKED: 'unlockBloxked',
};

export const CardStatus = {
    CONSENT_PENDING: 'CONSENT_PENDING',
    PROCESSING: 'PROCESSING',
    CANCELED: 'CANCELED',
    CANCELING: 'CANCELING',
};

export const CardVirtualStatus = {
    CONSENT_PENDING: 'CONSENT_PENDING',
    PROCESSING: 'PROCESSING',
    ENABLED: 'ENABLED',
    CANCELED: 'CANCELED',
    CANCELING: 'CANCELING',
};

export const CardPhysicalStatus = {
    CONSENT_PENDING: 'CONSENT_PENDING',
    PROCESSING: 'PROCESSING',
    ACTIVATED: 'ACTIVATED',
    SUSPENDED: 'SUSPENDED',
    CANCELED: 'CANCELED',
    CANCELING: 'CANCELING',
    TO_ACTIVATE: 'TO_ACTIVATE',
    RENEWED: 'RENEWED',
};

export const SuspendReason = {
    USER_ACTION: 'USER_ACTION',
    INVALID_PIN_ATTEMPTS: 'INVALID_PIN_ATTEMPTS',
};

export const ConsentCardOperation = {
    AddCard: 'ADD_CARD',
    ActivateCard: 'ACTIVATE_CARD',
    ViewPin: 'VIEW_PIN',
    ViewCardNumbers: 'VIEW_CARD_NUMBERS',
    ViewPhysicalCardNumbers: 'VIEW_PHYSICAL_CARD_NUMBERS',
    UnlockCard: 'UNLOCK_CARD',
    UpdateCard: 'UPDATE_CARD',
};

export const ConsentResult = {
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
};
