export const swapArrayElements = (myArray, index1, index2) => {
    const temp = myArray[index1];
    myArray[index1] = myArray[index2];
    myArray[index2] = temp;

    return myArray;
};

export const arrayDiff = (array1, array2, comparator = 'id') => {
    return array1.filter(item1 => !array2.some(item2 => item2[comparator] === item1[comparator])).concat(
        array2.filter(item2 => !array1.some(item1 => item1[comparator] === item2[comparator])),
    );
};

export const hasAtLeastTwoDifferentValues = (array, property) => {
    const uniqueValues = new Set();

    for (const item of array) {
        if (item.hasOwnProperty(property)) {
            uniqueValues.add(item[property]);
        }

        // Return true as soon as we find at least two different values
        if (uniqueValues.size >= 2) {
            return true;
        }
    }

    // If loop completes without finding at least two different values
    return false;
};

export const countDifferentValuesInArray = (array, property) => {
    if (array?.length === 0) {
        return 0;
    }

    const uniqueValues = new Set();

    for (const item of array) {
        if (item.hasOwnProperty(property)) {
            uniqueValues.add(item[property]);
        }
    }

    return uniqueValues.size;
};
