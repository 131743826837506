import {Box, Divider, List, ListSubheader, Typography} from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {CollapsedItemWithSubmenu} from './CollapsedItemWithSubmenu';
import {CollapsedSingleItem} from './CollapsedSingleItem';
import {ExpandedItemWithSubmenu} from './ExpandedItemWithSubmenu';
import {ExpandedSingleItem} from './ExpandedSingleItem';
import {ProfileItemSection} from './ProfileItemSection';
import {importLocaleBundle} from '../../../../lib/i18next';
import {selectCollapsedSidebarLists} from '../../../../v1/app/app.selectors';
import {SidebarSelectors} from '../../store/sidebar.selector';

importLocaleBundle('sidebar');

const CommonSidebar = ({
    user,
    sidebarData,
    createCollapsedListClickHandler,
    sx,
    SearchComponent,
    SegmentSwitchComponent,
}) => {
    const {t} = useTranslation('sidebar');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const collapsedLists = useSelector(selectCollapsedSidebarLists);
    const isSidebarExpandedSelector = useSelector(SidebarSelectors.selectIsSidebarExpanded);
    const isSidebarExpanded = isMobileSize ? true : isSidebarExpandedSelector;
    const isShrunken = isMobileSize ? false : !isSidebarExpanded;

    return (
        <Box
            sx={{
                mt: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
            }}
            data-cy="admin-sidebar"
        >
            <Box sx={{
                pb: 4,
                backgroundColor: 'background.level1',
                flex: 1,
                ...sx,
            }}
            >
                {!isMobileSize && (
                    <ProfileItemSection
                        user={user}
                        isSidebarExpanded={isSidebarExpanded}
                    />
                )}

                {sidebarData.map((list, index) => (
                    <List
                        sx={{
                            mt: isShrunken
                                ? 1
                                : !index
                                    ? 2
                                    : 3,
                            px: 1,
                            py: 0,
                        }}
                        key={list.id}
                        disablePadding
                    >
                        {isSidebarExpanded && !index && SearchComponent}

                        {isSidebarExpanded && (
                            <ListSubheader sx={{p: 0}}>
                                <Typography
                                    level="title-md"
                                    sx={{p: 0, pl: 1}}
                                >
                                    {t(list.listTitle)}
                                </Typography>
                            </ListSubheader>
                        )}

                        {!isSidebarExpanded && <Divider />}

                        {list.listItems.map(listItem => {
                            const hasSubItems = !!listItem.subItems && listItem.subItems.length;
                            const SingleItem = isShrunken ? CollapsedSingleItem : ExpandedSingleItem;
                            const ItemWithSubmenu = isShrunken ? CollapsedItemWithSubmenu : ExpandedItemWithSubmenu;

                            return (
                                <Box
                                    sx={{position: 'relative'}}
                                    key={listItem.id}
                                    data-cy={listItem.dataCy}
                                >
                                    {hasSubItems ? (
                                        <ItemWithSubmenu
                                            listItem={listItem}
                                            collapsedLists={collapsedLists}
                                            createCollapsedListClickHandler={createCollapsedListClickHandler}
                                        />
                                    ) : (
                                        <SingleItem listItem={listItem} userRole={user?.role} />
                                    )}
                                </Box>
                            );
                        })}

                        {isSidebarExpanded && !index && SegmentSwitchComponent}
                    </List>
                ))}
            </Box>
        </Box>
    );
};

CommonSidebar.propTypes = {
    user: PropTypes.object.isRequired,
    sidebarData: PropTypes.arrayOf(PropTypes.object).isRequired,
    createCollapsedListClickHandler: PropTypes.func,
    sx: PropTypes.object,
    SearchComponent: PropTypes.node,
    SegmentSwitchComponent: PropTypes.node,
};

CommonSidebar.defaultProps = {
    createCollapsedListClickHandler: () => {},
    sx: {},
    SearchComponent: null,
    SegmentSwitchComponent: null,
};

export default CommonSidebar;
