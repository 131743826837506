import {Box, Paper, Skeleton} from '@mui/material';
import React from 'react';

const SkeletonListBidItem = () => {
    return (
        <Paper
            elevation={1}
            sx={{
                borderRadius: 4,
                height: '36px',
                py: 0.4,
                px: 2,
                display: 'inline-flex',
            }}
        >
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box sx={{mr: 1, display: 'flex', alignItems: 'center'}}>
                    <Skeleton variant="text" sx={{fontSize: '16px', width: '100px'}} />
                </Box>

                <Box sx={{mr: 1, display: 'flex', alignItems: 'center'}}>
                    <Skeleton variant="circular" width={16} height={16} />
                </Box>

                <Box sx={{mr: 1, display: 'flex', alignItems: 'center'}}>
                    <Skeleton variant="circular" width={16} height={16} />
                </Box>

                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Skeleton variant="circular" width={16} height={16} />
                </Box>
            </Box>
        </Paper>
    );
};

export default SkeletonListBidItem;
