/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/require-default-props */
import composeClasses from '@mui/base/composeClasses';
import {styled, useThemeProps} from '@mui/system';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';
import {getStaticWrapperUtilityClass} from './pickerStaticWrapperClasses';
import {PickersActionBar} from '../../../PickersActionBar';
import {DIALOG_WIDTH} from '../../constants/dimensions';
import {WrapperVariantContext} from '../wrappers/WrapperVariantContext';

const useUtilityClasses = ownerState => {
    const {classes} = ownerState;
    const slots = {
        root: ['root'],
        content: ['content'],
    };

    return composeClasses(slots, getStaticWrapperUtilityClass, classes);
};

const PickerStaticWrapperRoot = styled('div', {
    name: 'MuiPickerStaticWrapper',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})({
    display: 'flex',
    flexDirection: 'column',
});

const PickerStaticWrapperContent = styled('div', {
    name: 'MuiPickerStaticWrapper',
    slot: 'Content',
    overridesResolver: (_, styles) => styles.content,
})(({theme}) => ({
    overflow: 'hidden',
    minWidth: DIALOG_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
}));

const PickerStaticWrapper = inProps => {
    const props = useThemeProps({
        props: inProps,
        name: 'MuiPickerStaticWrapper',
    });
    const {
        displayStaticWrapperAs,
        onAccept,
        onClear,
        onCancel,
        onDismiss,
        onSetToday,
        open,
        children,
        components,
        componentsProps,
        className,
        ...other
    } = props;

    const classes = useUtilityClasses(props);
    const ActionBar = components?.ActionBar ?? PickersActionBar;
    const PaperContent = components?.PaperContent || React.Fragment;

    return (
        <WrapperVariantContext.Provider value={displayStaticWrapperAs}>
            <PickerStaticWrapperRoot
                className={clsx(classes.root, className)}
                {...other}
            >
                <PickerStaticWrapperContent className={classes.content}>
                    <PaperContent {...componentsProps?.paperContent}>
                        {children}
                    </PaperContent>
                </PickerStaticWrapperContent>
                <ActionBar
                    onAccept={onAccept}
                    onClear={onClear}
                    onCancel={onCancel}
                    onSetToday={onSetToday}
                    actions={
            displayStaticWrapperAs === 'desktop' ? [] : ['cancel', 'accept']
          }
                    {...componentsProps?.actionBar}
                />
            </PickerStaticWrapperRoot>
        </WrapperVariantContext.Provider>
    );
};

PickerStaticWrapper.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // | To update them edit the TypeScript types and run "yarn proptypes"  |
    // ----------------------------------------------------------------------
    children: PropTypes.node,

    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,

    className: PropTypes.string,

    /**
     * Overrideable components.
     * @default {}
     */
    components: PropTypes.object,

    /**
     * The props used for each component slot.
     * @default {}
     */
    componentsProps: PropTypes.object,

    /**
     * Force static wrapper inner components to be rendered in mobile or desktop mode.
     */
    displayStaticWrapperAs: PropTypes.oneOf(['desktop', 'mobile']).isRequired,

    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    onSetToday: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export {PickerStaticWrapper};
