import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import BankHelperPagesWrapper from './BankHelperPagesWrapper';
import BankKycPendingImage from '../../../../assets/svg/bank/kyc-pending.svg';
import BankKycImage from '../../../../assets/svg/bank/kyc.svg';
import {BankActions} from '../../store/bank.action';
import {BankSelector} from '../../store/bank.selector';
import {VerificationStatus} from '../../utils/constants';

const BankKyc = () => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('bank');

    const dispatch = useDispatch();

    const onFinishCreationClick = () => {
        dispatch(BankActions.startKyc());
    };

    const bankAccountHolders = useSelector(BankSelector.createSelectBankAccountHolders());

    const isWaitingForVerification = bankAccountHolders?.verificationStatus
        === VerificationStatus.WAITING_FOR_VERIFICATION;
    const isPending = bankAccountHolders?.verificationStatus === VerificationStatus.PENDING;

    if (isWaitingForVerification) {
        return (
            <BankHelperPagesWrapper
                image={<img src={BankKycImage} alt="waiting verification kyc" />}
                header={(
                    <Box sx={{
                        maxWidth: '400px',
                        letterSpacing: '-0.2px',
                    }}
                    >
                        {t('kycPage.headerWaitingVerification')}
                    </Box>
                )}
                text={(
                    <Box sx={{
                        maxWidth: '380px',
                        mt: 0.5,
                    }}
                    >
                        {t('kycPage.textWaitingVerification')}
                    </Box>
                  )}
                Button={(
                    <Button
                        onClick={onFinishCreationClick}
                        variant="contained"
                        color="secondary"
                        fullWidth={isMobileSize}
                        endIcon={<ArrowForwardIcon />}
                    >
                        {t('kycPage.buttonWaitingVerification')}
                    </Button>
                )}
            />
        );
    }

    if (isPending) {
        return (
            <BankHelperPagesWrapper
                image={<img src={BankKycPendingImage} alt="pending kyc" />}
                header={(
                    <Box sx={{
                        maxWidth: '340px',
                        letterSpacing: '-0.2px',
                    }}
                    >
                        {t('kycPage.headerPending')}
                    </Box>
                )}
                text={(
                    <Box sx={{
                        maxWidth: '410px',
                        mt: 0.5,
                    }}
                    >
                        {t('kycPage.textPending')}
                    </Box>
              )}
                Button={null}
            />
        );
    }

    // TODO Check if needed
    // if (isVerified) {
    //     return <Navigate replace to={RoutePaths.DASHBOARD} />;
    // }

    // If not started
    return (
        <BankHelperPagesWrapper
            image={<img src={BankKycImage} alt="not started kyc" />}
            header={(
                <Box sx={{
                    maxWidth: '340px',
                    letterSpacing: '-0.2px',
                }}
                >
                    {t('kycPage.headerNotStarted')}
                </Box>
          )}
            text={(
                <Box sx={{
                    maxWidth: '410px',
                    mt: 0.5,
                }}
                >
                    {t('kycPage.textNotStarted')}
                </Box>
              )}
            Button={(
                <Button
                    onClick={onFinishCreationClick}
                    variant="contained"
                    color="secondary"
                    fullWidth={isMobileSize}
                    endIcon={<ArrowForwardIcon />}
                >
                    {t('kycPage.buttonNotStarted')}
                </Button>
            )}
        />
    );
};

export default BankKyc;
