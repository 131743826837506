export const SUBMIT_ASSIGN_FREELANCERS_FORM = 'SUBMIT_ASSIGN_FREELANCERS_FORM';
export const STORE_ASSIGN_FREELANCERS_FORM_ERROR = 'STORE_ASSIGN_FREELANCERS_FORM_ERROR';
export const SET_IS_LOADING_ASSIGN_FREELANCERS_FORM = 'SET_IS_LOADING_ASSIGN_FREELANCERS_FORM';

export const submitAssignFreelancersForm = (userId, freelancers) => ({
    type: SUBMIT_ASSIGN_FREELANCERS_FORM,
    payload: {
        userId,
        freelancers,
    },
});

export const storeAssignFreelancersFormError = formError => ({
    type: STORE_ASSIGN_FREELANCERS_FORM_ERROR,
    payload: formError,
});

export const setIsLoadingAssignFreelancersForm = isLoading => ({
    type: SET_IS_LOADING_ASSIGN_FREELANCERS_FORM,
    payload: isLoading,
});
