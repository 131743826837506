import {
    faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {Box, Chip, Stack} from '@mui/joy';
import {Box as MuiBox, Chip as MuiChip} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {ConditionalTooltip} from '../../../../../../../components/tooltip/ConditionalTooltip';

const ChipSection = ({
    t,
    isRejected,
    isNotCategorized,
    isCancelled,
    isCategorized,
    isAutoCategorized,
    isV3,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    if (isV3) {
        return (
            <Box sx={isMobileSize ? {
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 1,
            } : {
                display: 'flex',
                justifyContent: 'flex-start',
                gap: 1,
            }}
            >
                {isNotCategorized && !isRejected && !isCancelled && (
                    <Chip
                        size="md"
                        variant="soft"
                        color="warning"
                        startDecorator={(
                            <Box sx={{
                                m: 1,
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                backgroundColor: 'warning.softColor',
                            }}
                            />
                        )}
                    >
                        {t('accountBalanceTableHeader.toCategorize')}
                    </Chip>
                )}

                {isCategorized && !isRejected && !isCancelled && (
                    <ConditionalTooltip
                        tooltip={isAutoCategorized ? t('accountBalance.autoCategorised') : null}
                        isV3
                    >
                        <Chip
                            size="md"
                            variant="outlined"
                            color="success"
                            sx={{
                                backgroundColor: 'background.body',
                            }}
                            startDecorator={(
                                <Box sx={{
                                    m: 1,
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    backgroundColor: 'success.outlinedBorder',
                                }}
                                />
                            )}
                            endDecorator={isAutoCategorized ? (
                                <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        width: '24px',
                                        height: '24px',
                                        color: 'success.softActiveColor',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        fixedWidth
                                        size="md"
                                        icon={faWandMagicSparkles}
                                    />
                                </Stack>
                            ) : null}
                        >
                            {t('accountBalanceTableHeader.categorized')}
                        </Chip>
                    </ConditionalTooltip>
                )}

                {isRejected && (
                    <Chip
                        size="md"
                        variant="soft"
                        color="danger"
                        startDecorator={(
                            <Box sx={{
                                m: 1,
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                backgroundColor: 'danger.softColor',
                            }}
                            />
                        )}
                    >
                        {t('accountBalanceTableHeader.rejected')}
                    </Chip>
                )}

                {isCancelled && (
                    <Chip
                        size="md"
                        variant="soft"
                        color="neutral"
                        disabled
                        startDecorator={(
                            <Box sx={{
                                m: 1,
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                backgroundColor: 'neutral.softDisabledColor',
                            }}
                            />
                        )}
                    >
                        {t('accountBalanceTableHeader.cancelled')}
                    </Chip>
                )}
            </Box>
        );
    }

    return (
        <MuiBox sx={isMobileSize ? {
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            gap: 1,
        } : {
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 1,
        }}
        >
            {isNotCategorized && !isRejected && !isCancelled && (
                <MuiChip
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(12),
                        lineHeight: '14px',
                        borderRadius: '4px',
                        fontWeight: 400,
                        pl: 0.5,
                        borderColor: 'v2.light.secondary.shades12',
                    }}
                    size="small"
                    variant="outlined"
                    color="secondary"
                    label={t('accountBalanceTableHeader.toCategorize')}
                    icon={(
                        <MuiBox sx={{
                            width: '6px',
                            height: '6px',
                            borderRadius: '50%',
                            backgroundColor: 'red',
                        }}
                        />
                    )}
                />
            )}

            {isCategorized && !isRejected && !isCancelled && (
                <MuiBox sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
                >
                    <MuiChip
                        sx={{
                            fontSize: theme => theme.typography.pxToRem(12),
                            lineHeight: '14px',
                            borderRadius: '4px',
                            fontWeight: 400,
                            pl: 0.5,
                            color: 'success_v2.main',
                            backgroundColor: 'v2.light.success.shades4',
                            borderColor: 'v2.light.success.shades12',
                        }}
                        size="small"
                        variant="outlined"
                        color="success"
                        label={t('accountBalanceTableHeader.categorized')}
                    />

                    {isAutoCategorized && (
                        <ConditionalTooltip tooltip={t('accountBalance.autoCategorised')}>
                            <AutoFixHighIcon sx={{
                                color: 'warning_v2.main',
                                ml: 1,
                            }}
                            />
                        </ConditionalTooltip>
                    )}
                </MuiBox>
            )}

            {isRejected && (
                <MuiChip
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(12),
                        lineHeight: '14px',
                        borderRadius: '4px',
                        fontWeight: 400,
                        borderColor: 'v2.light.secondary.shades12',
                    }}
                    size="small"
                    variant="outlined"
                    color="error"
                    label={t('accountBalanceTableHeader.rejected')}
                    icon={<ErrorIcon />}
                />
            )}

            {isCancelled && (
                <MuiChip
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(12),
                        lineHeight: '14px',
                        borderRadius: '4px',
                        fontWeight: 400,
                        backgroundColor: 'white',
                        color: 'v2.blueGray.300',
                        border: '1px solid gray',
                        borderColor: 'v2.light.secondary.shades12',
                    }}
                    size="small"
                    variant="contained"
                    color="error"
                    label={t('accountBalanceTableHeader.cancelled')}
                    icon={<ErrorOutlineIcon />}
                />
            )}
        </MuiBox>
    );
};

ChipSection.propTypes = {
    t: PropTypes.func.isRequired,
    isRejected: PropTypes.bool.isRequired,
    isNotCategorized: PropTypes.bool.isRequired,
    isCancelled: PropTypes.bool.isRequired,
    isCategorized: PropTypes.bool.isRequired,
    isAutoCategorized: PropTypes.bool.isRequired,
    isV3: PropTypes.bool, // TODO: make true by default and delete the code, related to false value
};

ChipSection.defaultProps = {
    isV3: false,
};

export default ChipSection;
