import {SvgIcon} from '@mui/material';
import React from 'react';

const PdfIcon = props => {
    return (
        <SvgIcon {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path d="M12 21V4h11a1 1 0 011 1v15a1 1 0 01-1 1H12z" fill="#f40f02" fillRule="nonzero" opacity=".15" />
                <path d="M16.5 17a.5.5 0 110 1h-5a.5.5 0 110-1h5zm5 0a.5.5 0 110 1h-3a.5.5 0 110-1h3zm-3-2a.5.5 0 110 1h-7a.5.5 0 110-1h7zm3 0a.5.5 0 110 1h-1a.5.5 0 110-1h1zm-6.1-8a.6.6 0 01.6.6v5.8a.6.6 0 01-.6.6h-3.8a.6.6 0 01-.6-.6V7.6a.6.6 0 01.6-.6h3.8zm6.1 6a.5.5 0 110 1h-4a.5.5 0 110-1h4zm-4-2a.5.5 0 110 1 .5.5 0 010-1zm4 0a.5.5 0 110 1h-2a.5.5 0 110-1h2zm0-2a.5.5 0 110 1h-4a.5.5 0 110-1h4zm-2-2a.5.5 0 110 1h-2a.5.5 0 110-1h2z" fill="#f40f02" />
                <path d="M0 3.809v16.383a1 1 0 00.79.977l11.396 2.442A1.5 1.5 0 0014 22.145V1.855A1.5 1.5 0 0012.186.39L.79 2.83a1 1 0 00-.79.978z" fill="#f40f02" fillRule="nonzero" />
                <path d="M2.908 17.944a.696.696 0 01-.419-.14c-.451-.34-.512-.716-.483-.972.079-.706.95-1.444 2.593-2.196A29.081 29.081 0 006.24 9.98c-.432-.941-.853-2.162-.546-2.878.107-.25.241-.443.49-.526.1-.033.35-.075.441-.075.218 0 .41.281.546.454.128.163.418.509-.161 2.947.584 1.206 1.411 2.435 2.204 3.276a8.429 8.429 0 011.455-.155c.678 0 1.09.158 1.257.484.139.27.082.585-.169.936-.241.338-.574.516-.962.516-.526 0-1.14-.333-1.824-.99-1.229.257-2.664.715-3.825 1.223-.362.768-.709 1.387-1.032 1.841-.444.622-.827.912-1.206.912zm1.153-2.22c-.926.52-1.303.947-1.33 1.188-.005.04-.016.145.186.3.065-.02.442-.192 1.144-1.488zm5.908-1.925c.353.272.44.41.67.41.102 0 .39-.005.525-.192a.686.686 0 00.1-.18c-.054-.028-.125-.085-.51-.085-.219 0-.494.01-.785.047zm-3.236-2.851a30.886 30.886 0 01-1.158 3.277c.905-.352 1.89-.659 2.814-.876a14.83 14.83 0 01-1.656-2.401zM6.47 7.282c-.042.014-.576.76.042 1.393.41-.916-.023-1.4-.042-1.393z" fill="#FFF" fillRule="nonzero" />
            </g>
        </SvgIcon>
    );
};

export default PdfIcon;
