import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import UploadIcon from '@mui/icons-material/Upload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {Alert, Badge, Box, Button, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import AlreadyPaidInfo from './AlreadyPaidInfo';
import CreditInvoicePanel from './CreditInvoicePanel';
import {ConditionalTooltip} from '../../../../../../../../components/tooltip/ConditionalTooltip';
import {RoutePaths} from '../../../../../../../../lib/router/route-paths';
import ReactRouterButtonLink from '../../../../../../../../v1/components/app/Router/ReactRouterButtonLink';
import {TransactionMatchType} from '../../../../util/constants';

export const InvoiceMainSection = ({
    bestMatchedInvoices,
    details,
    unallocatedAmount,
    onSelectInvoice,
    setActiveInvoice,
    activeInvoice,
}) => {
    return (
        <>
            {bestMatchedInvoices.map(invoice => {
                if (invoice?.selected) {
                    return null;
                }

                return (
                    <CreditInvoicePanel
                        key={invoice?.id}
                        details={details}
                        invoice={invoice}
                        matchType={TransactionMatchType.BEST_MATCH}
                        unallocatedAmount={unallocatedAmount}
                        onSelectInvoice={onSelectInvoice}
                        setActiveInvoice={setActiveInvoice}
                        activeInvoice={activeInvoice}
                    />
                );
            })}
        </>
    );
};

InvoiceMainSection.propTypes = {
    bestMatchedInvoices: PropTypes.array.isRequired,
    details: PropTypes.object.isRequired,
    unallocatedAmount: PropTypes.string.isRequired,
    onSelectInvoice: PropTypes.func.isRequired,
    setActiveInvoice: PropTypes.func.isRequired,
    activeInvoice: PropTypes.string.isRequired,
};

export const InvoiceMainSectionTitle = () => {
    const {t} = useTranslation('bank');

    return (
        <Box sx={{
            display: 'flex',
            gap: 0.5,
            alignItems: 'center',
        }}
        >
            {t('categorisation.invoiceMatching.mainSectionTitleIfNoMatching')}

            <ConditionalTooltip
                tooltip={t('categorisation.invoiceMatching.mainSecitonTitleTooltip')}
            >
                <HelpOutlineIcon
                    fontSize="small"
                    sx={{
                        color: 'v2.blueGray.300',
                    }}
                />
            </ConditionalTooltip>
        </Box>
    );
};

export const InvoiceAdditionalSection = ({
    allOtherInvoices,
    details,
    unallocatedAmount,
    onSelectInvoice,
    setActiveInvoice,
    activeInvoice,
}) => {
    return (
        <>
            {allOtherInvoices.map(invoice => {
                if (invoice?.selected) {
                    return null;
                }

                return (
                    <CreditInvoicePanel
                        key={invoice?.invoiceId}
                        details={details}
                        invoice={invoice}
                        matchType={TransactionMatchType.OTHER_MATCH}
                        unallocatedAmount={unallocatedAmount}
                        onSelectInvoice={onSelectInvoice}
                        setActiveInvoice={setActiveInvoice}
                        activeInvoice={activeInvoice}
                    />
                );
            })}
        </>
    );
};

InvoiceAdditionalSection.propTypes = {
    allOtherInvoices: PropTypes.array.isRequired,
    details: PropTypes.object.isRequired,
    unallocatedAmount: PropTypes.string.isRequired,
    onSelectInvoice: PropTypes.func.isRequired,
    setActiveInvoice: PropTypes.func.isRequired,
    activeInvoice: PropTypes.string.isRequired,
};

export const InvoiceResult = ({
    amount,
    allocated,
    selectedInvoicesForPaidInfo,
    selectedInvoicesObj,
    unallocatedAmount,
    onSelectInvoice,
    setActiveInvoice,
    activeInvoice,
    details,
}) => {
    const {t} = useTranslation('bank');
    const selectedCount = selectedInvoicesObj.length;

    return (
        <>
            <AlreadyPaidInfo
                amount={amount}
                allocated={allocated}
                selectedInvoices={selectedInvoicesForPaidInfo}
                onUnselectInvoice={onSelectInvoice}
            />

            <Box sx={{
                display: 'flex',
                gap: 1.5,
                alignItems: 'center',
            }}
            >
                <Typography sx={{
                    fontWeight: 500,
                    letterSpacing: '0.1px',
                }}
                >
                    {t('invoices.numberOfPaid')}
                </Typography>
                <Badge
                    showZero
                    badgeContent={selectedCount}
                    color={!selectedCount ? 'warning_v2' : 'v2PrimaryShades50'}
                    sx={{
                        color: 'white !important',
                        ml: 0.5,
                        fontWeight: 500,
                        fontSize: theme => theme.typography.pxToRem(12),
                    }}
                />
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: selectedCount === 0 ? -1 : 1,
            }}
            >
                {selectedInvoicesObj.map(invoice => {
                    return (
                        <CreditInvoicePanel
                            key={invoice?.id}
                            details={details}
                            invoice={invoice}
                            hasDnd={false}
                            matchType={TransactionMatchType.BEST_MATCH}
                            unallocatedAmount={unallocatedAmount}
                            onSelectInvoice={onSelectInvoice}
                            setActiveInvoice={setActiveInvoice}
                            activeInvoice={activeInvoice}
                        />
                    );
                })}
            </Box>
        </>
    );
};

InvoiceResult.propTypes = {
    details: PropTypes.object.isRequired,
    unallocatedAmount: PropTypes.string.isRequired,
    onSelectInvoice: PropTypes.func.isRequired,
    amount: PropTypes.string.isRequired,
    allocated: PropTypes.string.isRequired,
    selectedInvoicesForPaidInfo: PropTypes.object.isRequired,
    selectedInvoicesObj: PropTypes.array.isRequired,
    setActiveInvoice: PropTypes.func.isRequired,
    activeInvoice: PropTypes.string.isRequired,
};

export const InvoiceBottomAlert = ({setIsExternalOpen}) => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Alert
            icon={<UploadFileIcon />}
            action={!isMobileSize ? (
                <Button
                    onClick={() => setIsExternalOpen(true)}
                    sx={{
                        width: '264px',
                        color: 'v2.light.info.main',
                    }}
                    color="inherit"
                    size="small"
                    startIcon={<UploadIcon />}
                >
                    {t('categorisation.invoiceMatching.externalButton')}
                </Button>
            ) : null}
            sx={{
                borderRadius: '16px',
                display: 'flex',
                alignItems: 'center',
                mb: isMobileSize ? 2 : 0,
                fontSize: theme => theme.typography.pxToRem(14),
            }}
            variant="outlined"
            severity="info"
        >
            {t('categorisation.invoiceMatching.externalAlertText')}
            {isMobileSize && (
                <Button
                    onClick={() => setIsExternalOpen(true)}
                    sx={{
                        width: '264px',
                        color: 'v2.light.info.main',
                    }}
                    color="inherit"
                    size="small"
                    startIcon={<UploadIcon />}
                >
                    {t('categorisation.invoiceMatching.externalButton')}
                </Button>
            )}
        </Alert>
    );
};

InvoiceBottomAlert.propTypes = {
    setIsExternalOpen: PropTypes.func.isRequired,
};

export const InvoiceNoMatching = ({setIsExternalOpen}) => {
    const {t} = useTranslation('bank');

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            border: '1px solid black',
            borderColor: 'v2.blueGray.50',
            borderRadius: '8px',
            pt: 10,
            pb: 20,
        }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxWidth: '540px',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
            }}
            >
                <ReceiptLongIcon sx={{
                    color: 'primary.main',
                    fontSize: '108px',
                }}
                />
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(34),
                    fontWeight: 700,
                }}
                >
                    {t('categorisation.invoiceMatching.noInvoiceFound')}
                </Typography>

                <Typography>
                    {t('categorisation.invoiceMatching.externalInformation')}
                </Typography>

                <Box sx={{
                    display: 'flex',
                    gap: 2,
                }}
                >
                    <ReactRouterButtonLink
                        to={RoutePaths.INVOICING}
                        variant="contained"
                        color="primary"
                        endIcon={<OpenInNewIcon />}
                    >
                        <span>
                            {t('categorisation.invoiceMatching.addFactureButton')}
                        </span>
                    </ReactRouterButtonLink>

                    <Button
                        startIcon={<UploadFileIcon />}
                        onClick={() => setIsExternalOpen(true)}
                        variant="outlined"
                        color="primary"
                    >
                        {t('categorisation.invoiceMatching.addExternalButton')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

InvoiceNoMatching.propTypes = {
    setIsExternalOpen: PropTypes.func.isRequired,
};

export const InvoiceResultAlert = ({data}) => {
    const {t} = useTranslation('bank');

    const hasAlertOnUnallocated = (data?.unallocatedAmount > 1
        && data?.unallocatedAmount !== 0
        && data?.allocated !== 0);

    const hasAlertOnPaid = (
        (data?.paidAmount && data?.paidAmount !== 0)
        && data?.amount !== data?.paidAmount
    );

    if (hasAlertOnUnallocated || hasAlertOnPaid) {
        return (
            <Alert
                sx={{
                    'mb': 2,
                    '.MuiAlert-icon': {
                        color: 'inherit',
                        alignSelf: 'center',
                    },
                }}
                severity="warning"
            >
                <Typography sx={{
                    color: 'v2.light.shades.160',
                    fontSize: theme => theme.typography.pxToRem(14),
                }}
                >
                    {t('categorisation.facturePanel.alertWarning')}
                </Typography>
            </Alert>
        );
    }

    return null;
};

InvoiceResultAlert.propTypes = {
    data: PropTypes.object.isRequired,
};
