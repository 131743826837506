import {PayslipActionTypes} from './payslip.action-types';

export const PayslipActions = {
    getPayslip: (companyId, freelancerId) => ({
        type: PayslipActionTypes.GET,
        payload: {companyId, freelancerId},
    }),
    storePayslip: (data, freelancerId, companyId) => ({
        type: PayslipActionTypes.STORE,
        payload: {companyId, freelancerId, data},
    }),
    createPayslip: (data, companyId, freelancerId) => ({
        type: PayslipActionTypes.CREATE,
        payload: {data, companyId, freelancerId},
    }),
    updatePayslip: (data, companyId, freelancerId) => ({
        type: PayslipActionTypes.UPDATE,
        payload: {data, companyId, freelancerId},
    }),
    reinitializePayslip: (companyId, freelancerId) => ({
        type: PayslipActionTypes.REINIT,
        payload: {companyId, freelancerId},
    }),
};
