import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

const ReactRouterButtonLink = React.forwardRef(({children, ButtonComponent, ...rest}, ref) => {
    return (
        <ButtonComponent component={RouterLink} ref={ref} {...rest}>
            {children}
        </ButtonComponent>
    );
});

ReactRouterButtonLink.propTypes = {
    ButtonComponent: PropTypes.elementType,
};

ReactRouterButtonLink.defaultProps = {
    ButtonComponent: Button,
};

export default ReactRouterButtonLink;
