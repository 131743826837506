import {combineReducers} from 'redux';
import {RevenueSimulatorActionTypes} from './revenue-simulator.action-type';
import {createReducer} from '../../../utils/create-reducer';

const {
    SET_CURRENT_STEP_INDEX,
    SET_ALL_VALUES,
    GO_NEXT_STEP,
    GO_PREV_STEP,
    UPDATE_VALUES,
    SET_IS_SUBMITTING,
    RESET_INPUTS,
} = RevenueSimulatorActionTypes;

const initialInputsState = {
    currentStepIndex: 0,
    allValues: {},
    stepGroups: [],
    visibleSteps: [],
    isSubmitting: false,
};

export const revenueSimulatorReducer = combineReducers({
    simulation: createReducer(null, RevenueSimulatorActionTypes.STORE_SIMULATION),
    allSimulations: createReducer({simulations: [], count: 0}, RevenueSimulatorActionTypes.STORE_ALL_SIMULATIONS),
    isLoadingSimulations: createReducer(false, RevenueSimulatorActionTypes.SET_SIMULATIONS_LOADING),
    inputs: (state = initialInputsState, action) => {
        switch (action.type) {
            case RESET_INPUTS: {
                return initialInputsState;
            }
            case SET_CURRENT_STEP_INDEX:
                return {
                    ...state,
                    currentStepIndex: action.payload,
                };
            case SET_ALL_VALUES:
                return {
                    ...state,
                    allValues: {
                        ...state.allValues,
                        ...action.payload,
                    },
                };
            case GO_NEXT_STEP:
                return {
                    ...state,
                    currentStepIndex: state.currentStepIndex + 1,
                };
            case GO_PREV_STEP:
                return {
                    ...state,
                    currentStepIndex: state.currentStepIndex - 1,
                };
            case UPDATE_VALUES:
                return {
                    ...state,
                    allValues: {
                        ...state.allValues,
                        ...action.payload,
                    },
                };
            case SET_IS_SUBMITTING: {
                return {
                    ...state,
                    isSubmitting: action.payload,
                };
            }
            default:
                return state;
        }
    },
});
