import {
    faBoxArchive,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Typography} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AlertInfo} from '../../../../components/joy-ui/alerts/AlertInfo';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {LoadingTypes, useLoadingState} from '../../../loading';
import {UiSelectors} from '../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../ui/utils/constants';
import {BANK_TYPES} from '../../modules/bridge-api/utils/constants';
import {BankSelector} from '../../store/bank.selector';

const modalKey = ModalsKeys.CONFIRM_HIWAY_DELETE_MODAL;

const DeleteHiwayIntegrationModal = () => {
    const {t} = useTranslation('bank');

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));
    const isDeleting = useLoadingState(LoadingTypes.DELETE_BANK);

    const integrations = useSelector(BankSelector.selectIntegrations);
    const archivedIntegrations = useSelector(BankSelector.selectIntegrationsWithArchived);

    return (
        <ModalWrapper
            isV3
            containerSx={{width: '600px'}}
            modalKey={modalKey}
            title={(
                <Typography level="h1">
                    {t('accountArchiving.deleteSuccessTitle')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        loading={isDeleting}
                        loadingPosition="start"
                        disabled={archivedIntegrations?.length === 0}
                        fullWidth
                        startDecorator={(
                            <FontAwesomeIcon icon={faBoxArchive} />
                        )}
                        onClick={() => {
                            data?.setArchiveActive();
                            handleClose();
                        }}
                        variant="outlined"
                        color="primary"
                        size="lg"
                    >
                        {t('accountArchiving.deleteSuccessArchive')}
                    </Button>

                    <Button
                        loading={isDeleting}
                        fullWidth
                        loadingPosition="start"
                        onClick={() => {
                            const bridgeIntegration = integrations.find(
                                integration => integration?.type === BANK_TYPES.bridge,
                            );

                            // Set default tab value
                            if (bridgeIntegration?.id) {
                                data.setTabValue(bridgeIntegration.id);
                            } else if (archivedIntegrations?.length > 0) {
                                data.setTabValue(BANK_TYPES.archived);
                            }

                            handleClose();
                        }}
                        color="danger"
                        variant="soft"
                        size="lg"
                    >
                        {t('accountArchiving.deleteSuccessConfirm')}
                    </Button>
                </>
            )}
        >
            <Typography sx={{color: 'text.secondary'}}>
                {t('accountArchiving.deleteSuccessText')}
            </Typography>

            {data?.isArchive && (
                <AlertInfo sx={{mt: 2}}>
                    {t('accountArchiving.deleteSuccessInfo')}
                </AlertInfo>
            )}
        </ModalWrapper>
    );
};

export default DeleteHiwayIntegrationModal;
