import {CARE_COMPANY_LEGAL_STATUSES} from '../company.consts';

export const SETUP_COMPANY_STEPS = {
    PERSONAL_INFORMATION: 'personal-information',
    CONTACT_INFORMATION: 'contact-information',
    NON_CONVICTION_DECLARATION: 'declaration-of-non-conviction-and-filiation',
    COMPANY_INFORMATION: 'company-information',
    UPLOAD_DOCUMENTS: 'upload-documents',
};

// TODO Check where it is used and update the list
export const COMPANY_STATUSES = {
    PENDING_ENABLE_SETUP: 'PENDING_ENABLE_SETUP',
    PENDING_DATA_INPUT: 'PENDING_DATA_INPUT',
    PENDING_INIT_SIGS: 'PENDING_INIT_SIGS',
    READY_FOR_REVIEW: 'READY_FOR_REVIEW',
    PENDING_EDITS: 'PENDING_EDITS',
    READY_TO_REGISTER: 'READY_TO_REGISTER',
    PENDING_COMPANY_ID: 'PENDING_COMPANY_ID',
    PENDING_FINAL_SIGS: 'PENDING_FINAL_SIGS',
    ACTIVE: 'ACTIVE',
    USER_DEACTIVATED: 'USER_DEACTIVATED',
    REGISTRATION_NOT_PREPARED: 'REGISTRATION_NOT_PREPARED',
    REGISTRATION_VALIDATED: 'REGISTRATION_VALIDATED',
    REGISTRATION_FINALIZATION: 'REGISTRATION_FINALIZATION',
    REGISTRATION_COMPLETED: 'REGISTRATION_COMPLETED',
};

export const FULL_COMPANY_STATUSES = {
    PENDING_ENABLE_SETUP: 'PENDING_ENABLE_SETUP',
    PENDING_SETUP_START: 'PENDING_SETUP_START',
    PENDING_DATA_INPUT: 'PENDING_DATA_INPUT',
    PENDING_INIT_SIGS: 'PENDING_INIT_SIGS',

    READY_FOR_REVIEW: 'READY_FOR_REVIEW',
    PENDING_EDITS: 'PENDING_EDITS',

    REGISTRATION_NOT_PREPARED: 'REGISTRATION_NOT_PREPARED',
    REGISTRATION_PARTIALLY_PREPARED: 'REGISTRATION_PARTIALLY_PREPARED',
    REGISTRATION_LAUNCHED: 'REGISTRATION_LAUNCHED',

    // KBIS_INCOMPLETE: 'KBIS_INCOMPLETE',
    REGISTRATION_FINALIZATION: 'REGISTRATION_FINALIZATION',
    REGISTRATION_COMPLETED: 'REGISTRATION_COMPLETED',

    DATA_VALIDATED: 'DATA_VALIDATED',
    PENDING_FINAL_SIGS: 'PENDING_FINAL_SIGS',

    ACTIVE: 'ACTIVE',
};

export const HAS_AVAILABLE_COMPANY_EDIT_ADDRESS_STATUSES = [
    FULL_COMPANY_STATUSES.PENDING_ENABLE_SETUP,
    FULL_COMPANY_STATUSES.PENDING_SETUP_START,
    FULL_COMPANY_STATUSES.PENDING_DATA_INPUT,
    FULL_COMPANY_STATUSES.PENDING_EDITS,
];

export const COMPANY_STATUS_FOR_EDIT_DATE = [
    FULL_COMPANY_STATUSES.PENDING_ENABLE_SETUP,
    FULL_COMPANY_STATUSES.PENDING_SETUP_START,
    FULL_COMPANY_STATUSES.PENDING_DATA_INPUT,
    FULL_COMPANY_STATUSES.PENDING_INIT_SIGS,
    FULL_COMPANY_STATUSES.READY_FOR_REVIEW,
    FULL_COMPANY_STATUSES.PENDING_EDITS,
    FULL_COMPANY_STATUSES.DATA_VALIDATED,
    FULL_COMPANY_STATUSES.REGISTRATION_NOT_PREPARED,
    FULL_COMPANY_STATUSES.REGISTRATION_PARTIALLY_PREPARED,
];

export const COMPANY_STATUS_FOR_EDIT_DATE_MESSAGE = [
    FULL_COMPANY_STATUSES.PENDING_FINAL_SIGS,
    FULL_COMPANY_STATUSES.READY_FOR_REVIEW,
    FULL_COMPANY_STATUSES.DATA_VALIDATED,
    FULL_COMPANY_STATUSES.REGISTRATION_NOT_PREPARED,
    FULL_COMPANY_STATUSES.REGISTRATION_PARTIALLY_PREPARED,
    FULL_COMPANY_STATUSES.PENDING_EDITS,
    FULL_COMPANY_STATUSES.PENDING_INIT_SIGS,
];

export const TITLES = {
    MR: 'mr',
    MS: 'ms',
};

export const BANKS = {
    QONTO: 'Qonto',
    BNP: 'BNP',
    OTHER: 'OTHER',
};

export const HIWAY_BANKS = {
    HIWAY_PRO: 'HIWAY_PRO',
};

export const PROFESSIONAL_ACTIVITIES = {
    CODING_DEVELOPER: 'CODING_DEVELOPER',
    PROJECT_MANAGER: 'PROJECT_MANAGER',
    SCRUM_MASTER: 'SCRUM_MASTER',
    DEV_OPS: 'DEV_OPS',
    AGILE_COACH: 'AGILE_COACH',
    UX_UI_DESIGNER: 'UX_UI_DESIGNER',
    OTHER: 'OTHER',
};

export const PROFESSIONAL_ACTIVIES_FOR_HOLDING_COMPANY = {
    SASU_PATRIMONIALE: 'SASU_PATRIMONIALE',
};

export const IDENTIFICATION_DOCUMENT_TYPES = {
    ID_CARD: 'ID_CARD',
    PASSPORT: 'PASSPORT',
};

export const INSURANCE_DOCUMENT_TYPES = {
    NATIONAL_INSURANCE_CARD: 'NATIONAL_INSURANCE_CARD',
    SOCIAL_SECURITY_RIGHTS_CERTIFICATE: 'SOCIAL_SECURITY_RIGHTS_CERTIFICATE',
};

export const SHARE_CAPITALS = {
    HUNDRED: '100',
    FIVE_HUNDRED: '500',
    THOUSAND: '1000',
    OTHER: 'OTHER',
};

const ORDERED_COMPANY_STATUSES = [
    COMPANY_STATUSES.PENDING_ENABLE_SETUP,
    COMPANY_STATUSES.PENDING_DATA_INPUT,
    COMPANY_STATUSES.PENDING_INIT_SIGS,
    COMPANY_STATUSES.READY_FOR_REVIEW,
    COMPANY_STATUSES.PENDING_EDITS,
    COMPANY_STATUSES.READY_TO_REGISTER,
    COMPANY_STATUSES.PENDING_COMPANY_ID,
    COMPANY_STATUSES.PENDING_FINAL_SIGS,
    COMPANY_STATUSES.ACTIVE,
];

export const isCompanyStatusGreaterOrEqual = (first, second) => {
    return ORDERED_COMPANY_STATUSES.indexOf(first) >= ORDERED_COMPANY_STATUSES.indexOf(second);
};

export const isCompanyStatusLessOrEqual = (first, second) => {
    return ORDERED_COMPANY_STATUSES.indexOf(first) <= ORDERED_COMPANY_STATUSES.indexOf(second);
};

export const isCompanyEIFnc = company => {
    return company?.legalForm === CARE_COMPANY_LEGAL_STATUSES.EI;
};
