import {Box, Chip, Radio, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';

export const AccountItem = ({
    value,
    name,
    accountNumber,
    balance,
    isSelected,
    isDefault,
    defaultBadgeTitle,
    isRadioButtonHidden,
}) => {
    return (
        <Box
            sx={{
                border: '1px solid',
                borderColor: isSelected
                    ? 'neutral.softColor'
                    : 'neutral.outlinedBorder',
                borderRadius: 'md',
                backgroundColor: 'background.body',
                p: 2,
                width: '100%',
            }}
        >
            <Radio
                value={value}
                label={(
                    <Stack sx={{
                        justifyContent: 'space-between',
                        alignItems: {desktop: 'center', mobile: 'flex-start'},
                        flexDirection: {desktop: 'row', mobile: 'column'},
                        gap: {desktop: 2},
                    }}
                    >
                        <Stack direction="column">
                            <Stack
                                direction="row"
                                alignItems="baseline"
                                gap={1}
                            >
                                <Typography
                                    level="title-lg"
                                    sx={{
                                        color: 'neutral.softColor',
                                        wordBreak: 'break-all',
                                    }}
                                >
                                    {name}
                                </Typography>
                                {isDefault && (
                                    <Chip
                                        color="success"
                                        variant="soft"
                                        size="md"
                                    >
                                        {defaultBadgeTitle}
                                    </Chip>
                                )}
                            </Stack>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'text.secondary',
                                }}
                            >
                                {accountNumber}
                            </Typography>
                        </Stack>
                        <Typography
                            level="body-lg"
                            sx={{
                                color: 'neutral.softColor',
                                fontWeight: 'xl',
                                textWrap: 'nowrap',
                            }}
                        >
                            {balance} €
                        </Typography>
                    </Stack>
                )}
                color="neutral"
                variant="soft"
                slotProps={{input: {'aria-describedby': accountNumber}}}
                sx={{
                    alignItems: 'center',
                    width: '100%',
                    ...(isSelected ? {
                        '& .MuiRadio-icon': {
                            backgroundColor: 'neutral.softColor',
                        },
                    } : {}),
                    ...(isRadioButtonHidden ? {
                        '& .MuiRadio-radio': {
                            display: 'none',
                        },
                    } : {}),
                }}
            />
        </Box>
    );
};

AccountItem.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isSelected: PropTypes.bool,
    isDefault: PropTypes.bool,
    defaultBadgeTitle: PropTypes.string,
    isRadioButtonHidden: PropTypes.bool,
};

AccountItem.defaultProps = {
    balance: 0,
    isSelected: false,
    isDefault: false,
    defaultBadgeTitle: 'Default',
    isRadioButtonHidden: false,
};
