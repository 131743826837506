/**
 * This action type will be dispatched when your history
 * receives a location change.
 */
export const LOCATION_CHANGED = '@@connected-router/LOCATION_CHANGED';

/**
 * This action type will be dispatched by the history actions below.
 * If you're writing a middleware to watch for navigation events, be sure to
 * look for actions of this type.
 */
export const ROUTER_CALL_HISTORY_METHOD = '@@connected-router/CALL_HISTORY_METHOD';

/**
 * This action type will be dispatched when side effects start running.
 */
export const LOCATION_CHANGE_SIDE_EFFECTS = '@@connected-router/LOCATION_CHANGE_SIDE_EFFECTS';

/**
 * This action type will be dispatched when side effects should stop running.
 */
export const END_SIDE_EFFECTS_RUNNING = '@@connected-router/END_SIDE_EFFECTS_RUNNING';
