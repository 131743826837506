import {Box, Skeleton} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {TrainingSelector} from '../../store/training.selector';
import TrainingHeader from '../TrainingHeader';

const TrainingFeatureSkeleton = () => {
    const progress = useSelector(TrainingSelector.getTrainingProgress);

    return (
        <Box>
            <TrainingHeader progress={progress} step={null} />
            <Box
                sx={{
                    px: {
                        xs: 2,
                        md: 0,
                    },
                }}
            >
                <Skeleton variant="rectangular" width={260} height={30} />

                <Skeleton sx={{mt: 2}} variant="rectangular" width="100%" height="60vh" />
            </Box>
        </Box>
    );
};

export default TrainingFeatureSkeleton;
