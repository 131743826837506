import {Link as JoyLink} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

const RouterLink = React.forwardRef(({to, children, sx}, ref) => (
    <JoyLink
        component={Link}
        sx={sx}
        to={to}
        ref={ref}
    >
        {children}
    </JoyLink>
));

RouterLink.propTypes = {
    to: PropTypes.string.isRequired,
    sx: PropTypes.object,
};

RouterLink.defaultProps = {
    sx: {},
};

export default RouterLink;
