import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, FormControlLabel, FormGroup} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '../../../../components/checkbox/Checkbox';

export const DossierDocumentViewButtons = ({onButtonClick, onCheckboxClick, isChecked, isDisabled}) => {
    const {t} = useTranslation('training');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <>
            <Box sx={{
                mb: {
                    xs: 2,
                    md: 0,
                },
            }}
            >
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox defaultChecked={isChecked} onClick={onCheckboxClick} />
                        }
                        disabled={isDisabled}
                        label={t('dossier.checkboxDocument')}
                    />
                </FormGroup>
            </Box>
            <Box>
                <Button
                    endIcon={<ArrowForwardIcon />}
                    onClick={onButtonClick}
                    color="secondary"
                    variant="contained"
                    disableElevation
                    disabled={!isChecked}
                    fullWidth={isMobileSize}
                >
                    {t('dossier.submit')}
                </Button>
            </Box>
        </>
    );
};

DossierDocumentViewButtons.propTypes = {
    onButtonClick: PropTypes.func.isRequired,
    onCheckboxClick: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
};

DossierDocumentViewButtons.defaultProps = {
    isDisabled: false,
};
