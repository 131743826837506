export const infoSectionStyles = {
    backgroundColor: 'gray_v2.50',
    borderRadius: '16px',
    padding: '16px 24px 24px',
    mx: 0,
    mb: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
};

export const warningAlert = {
    backgroundColor: 'warning_v2.shades',
    borderRadius: '12px',
    mx: 2,
    mb: 2,
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    padding: '6px 16px',
};

export const warningText = {
    fontWeight: 400,
    letterSpacing: '0.17px',
    lineHeight: '20px',
    fontSize: '14px',
    color: 'warning_v2.text',
};
