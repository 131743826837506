import React from 'react';
import {OpportunityApply} from '../../features/opportunity/components/freelancer-opportunity/OpportunityApply';

const OpportunityApplyScreen = () => {
    return (
        <OpportunityApply />
    );
};

export default OpportunityApplyScreen;
