import {SvgIcon} from '@mui/material';

export const SavingsOutlinedIcon = props => (
    <SvgIcon viewBox="0 0 48 48" {...props}>
        <path
            d="M32 21.769C32.4897 21.769 32.907 21.5967 33.252 21.252C33.5967 20.907 33.769 20.4897 33.769 20C33.769 19.5103 33.5967 19.093 33.252 18.748C32.907 18.4033 32.4897 18.231 32 18.231C31.5103 18.231 31.093 18.4033 30.748 18.748C30.4033 19.093 30.231 19.5103 30.231 20C30.231 20.4897 30.4033 20.907 30.748 21.252C31.093 21.5967 31.5103 21.769 32 21.769ZM16 16.769H26V14.5H16V16.769ZM9.75 41C8.69367 37.3537 7.638 33.7255 6.583 30.1155C5.52767 26.5052 5 22.8 5 19C5 16.2153 5.9705 13.8525 7.9115 11.9115C9.8525 9.9705 12.2153 9 15 9H25.5C26.4153 7.79733 27.5135 6.83 28.7945 6.098C30.0752 5.366 31.477 5 33 5C33.5513 5 34.0225 5.1955 34.4135 5.5865C34.8045 5.9775 35 6.44867 35 7C35 7.136 34.9813 7.27183 34.944 7.4075C34.907 7.5435 34.868 7.66917 34.827 7.7845C34.6423 8.2665 34.4852 8.76017 34.3555 9.2655C34.2262 9.7705 34.1153 10.2807 34.023 10.796L39.227 16H43V28.219L37.542 30.0115L34.25 41H25V37H19V41H9.75ZM11.4615 38.7305H16.7305V34.7305H27.2695V38.7305H32.5575L35.6885 28.2885L40.7305 26.5575V18.2695H38.2845L31.7305 11.7155C31.7512 10.9205 31.8403 10.1538 31.998 9.4155C32.1557 8.67683 32.341 7.93967 32.554 7.204C31.377 7.51167 30.2795 7.99683 29.2615 8.6595C28.2435 9.3225 27.4473 10.1925 26.873 11.2695H15C12.8547 11.2695 11.0298 12.0215 9.5255 13.5255C8.0215 15.0298 7.2695 16.8547 7.2695 19C7.2695 22.3947 7.74317 25.7112 8.6905 28.9495C9.63783 32.1882 10.5615 35.4485 11.4615 38.7305Z"
            fill="currentColor"
        />
    </SvgIcon>
);
