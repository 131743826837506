export const ContractSigningInternalSubSteps = {
    INITIAL_SCREEN: 'contract-signing/INITIAL_SCREEN',
    PERSONAL_ADDRESS: 'contract-signing/PERSONAL_ADDRESS',
    DOCUMENT_SIGNING: 'contract-signing/DOCUMENT_SIGNING',
    FINAL_SCREEN: 'contract-signing/FINAL_SCREEN',
};

export const ContractSigningSubSteps = {
    ENTER_PERSONAL_ADDRESS: 'ENTER_PERSONAL_ADDRESS',
    SIGN_CONTRACT: 'SIGN_CONTRACT',
};
