import {createSelector} from 'reselect';

const selectIds = state => state.user.userSelect.ids;

const selectEntities = state => state.user.userSelect.entities;

const createSelectOptions = () => createSelector(
    [
        selectIds,
        selectEntities,
    ],
    (ids, entities) => {
        return ids.map(id => ({
            name: entities[id].getName(),
            id: entities[id].id,
        })).sort((a, b) => b.name.localeCompare(a.name));
    },
);

export const UserSelectSelectors = {
    selectIds,
    selectEntities,
    createSelectOptions,
};
