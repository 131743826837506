import {SidebarActionTypes} from './sidebar.action-type';

export const SidebarActions = {
    setIsSidebarExpanded: isExpanded => ({
        type: SidebarActionTypes.SET_IS_SIDEBAR_EXPANDED,
        payload: isExpanded,
    }),

    setIsProfileMenuExpanded: isExpanded => ({
        type: SidebarActionTypes.SET_IS_PROFILE_MENU_EXPANDED,
        payload: isExpanded,
    }),

    setRoleSelected: role => ({
        type: SidebarActionTypes.SET_ROLE_SELECTED,
        payload: role,
    }),

    setSearchText: text => ({
        type: SidebarActionTypes.SET_SEARCH_TEXT,
        payload: text,
    }),
};
