import InfoIcon from '@mui/icons-material/Info';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import JobProfessionContent from './JobProfessionContent';
import JobProfessionForm from './JobProfessionForm/JobProfessionForm';
import {ConditionalTooltip} from '../../../components/tooltip/ConditionalTooltip';
import {importLocaleBundle} from '../../../lib/i18next';
import {ProfessionAndSpecializationActions} from '../store/profession-and-specialization.action';
import {ProfessionAndSpecializationSelectors} from '../store/profession-and-specialization.selector';

importLocaleBundle('jobProfessions');

export const JobProfession = () => {
    const {t} = useTranslation('users');
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const dispatch = useDispatch();
    const data = useSelector(ProfessionAndSpecializationSelectors.selectFreelancerData);

    const handleEditButtonClick = () => {
        setIsEditModeActive(true);
    };

    const onItemsUpdate = newData => {
        dispatch(ProfessionAndSpecializationActions.saveFreelancerData(newData));
        setIsEditModeActive(false);
    };

    const onCancel = () => {
        setIsEditModeActive(false);
    };

    return (
        <>
            <Typography sx={{px: 3, pt: 2.5, pb: 1.5, color: '#060506'}} variant="h5">
                {t('professionAndSpecialization.title')}
                <Box sx={{ml: 0.5, position: 'relative', top: '-2px'}} component="span">
                    <ConditionalTooltip tooltip={t('professionAndSpecialization.help')}>
                        <IconButton sx={{'p': 0.5, 'color': 'blueGray.300', '&:hover': {color: 'primary.light'}}}>
                            <InfoIcon />
                        </IconButton>
                    </ConditionalTooltip>
                </Box>
            </Typography>

            {isEditModeActive && (
                <JobProfessionForm
                    data={data}
                    onSave={onItemsUpdate}
                    onCancel={onCancel}
                />
            )}

            {!isEditModeActive && (
                <JobProfessionContent
                    data={data}
                    onEditButtonClick={handleEditButtonClick}
                />
            )}
        </>
    );
};
