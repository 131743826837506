import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {LoggedInUserActions} from './logged-in-user.action';
import {LoggedInUserActionTypes} from './logged-in-user.action-type';
import {LoggedInUserSelectors} from './logged-in-user.selector';
import {push} from '../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {Toast} from '../../../../../lib/toast';
import {COOKIE_NAMES, deleteCookie, getCookie} from '../../../../../utils/cookie';
import {getConfig} from '../../../../../v1/config';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {getLoggedInUserAccount, updateLoggedInUserAccount} from '../api/logged-in-user.api';

export const getLoggedInUserAccountFlow = function* () {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.MY_ACCOUNT, true));

        const myAccount = yield call(getLoggedInUserAccount);

        yield put(LoggedInUserActions.storeLoggedInUserAccount(myAccount));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});

        // If cookie is used for auth and response is 403 force logout user
        if (error?.response?.status === 403) {
            const cookieToken = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);
            if (cookieToken) {
                deleteCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);

                yield put(push({
                    pathname: getConfig().ROUTE_PATHS.LOGIN,
                    state: null,
                }));

                yield put({
                    type: 'LOGOUT_SUCCESS',
                });
            }
        }
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.MY_ACCOUNT, false));
        yield put(AnimationActions.setInitialAnimation(false));
    }
};

export const updateLoggedInUserFlow = function* ({payload}) {
    try {
        const {user, propagateAddressChange} = payload;
        yield put(LoggedInUserActions.setIsUpdatingUser(true));

        yield call(updateLoggedInUserAccount, user, propagateAddressChange);

        const oldUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield put(LoggedInUserActions.storeLoggedInUserAccount({
            ...oldUser,
            ...user,
        }));

        yield put(push(RoutePaths.MY_PROFILE));

        Toast.success('profileUpdated');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(LoggedInUserActions.setIsUpdatingUser(false));
    }
};

export const loadLoggedInUserAccount = function* () {
    yield call(getLoggedInUserAccountFlow);
};

export const loggedInUserSaga = function* () {
    yield all([
        takeLatest(LoggedInUserActionTypes.UPDATE_USER, updateLoggedInUserFlow),
        takeLatest(LoggedInUserActionTypes.LOAD_USER, loadLoggedInUserAccount),
    ]);
};
