export const fromContactInfoDTO = contactInfoDTO => {
    if (!contactInfoDTO || !Object.keys(contactInfoDTO).length) {
        return {};
    }
    return {
        address: contactInfoDTO?.address,
        streetNumber: contactInfoDTO?.street_number,
        streetName: contactInfoDTO?.street_name,
        city: contactInfoDTO?.city,
        country: contactInfoDTO?.country,
        zipCode: contactInfoDTO?.zip_code,
    };
};

export const toContactInfoDTO = contactInfoData => {
    if (!contactInfoData) {
        return {};
    }

    return {
        address: contactInfoData.address,
        street_number: contactInfoData.streetNumber,
        street_name: contactInfoData.streetName,
        city: contactInfoData.city,
        zip_code: contactInfoData.zipCode,
        country: contactInfoData.country,
    };
};
