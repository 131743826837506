import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, Divider, FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {ButtonAction} from '../../../../../../components/buttons/ButtonAction';
import {
    createContactInfoAddress,
} from '../../../../../../v1/components/company/company-profile/EnterpriseInformationForm/util';

export const BankCardAddressChoose = ({
    t,
    setRadioValue,
    radioValue,
    onSubmit,
    onCancel,
    freelancer,
    company,
    isLoading,
    isMobileSize,
}) => {
    return (
        <>
            <Typography
                variant={isMobileSize ? 'h5' : 'h4'}
                sx={{textAlign: 'center'}}
            >
                {t('cardCreate.titleAddressRecieveType')}
            </Typography>

            <Box>
                <RadioGroup
                    row
                    name="position"
                    value={radioValue}
                >
                    <Box
                        sx={{
                            border: radioValue === 'self' ? '2px solid #3779BE' : '1px solid rgba(0, 0, 0, 0.12)',
                            borderRadius: '16px',
                            px: 2.5,
                            py: 2,
                            mt: 3,
                            width: '100%',
                            cursor: 'pointer',
                        }}
                        onClick={() => setRadioValue('self')}
                    >
                        <FormControlLabel
                            sx={{alignItems: 'flex-start'}}
                            value="self"
                            control={(
                                <Radio sx={{
                                    height: '92px',
                                    alignItems: 'flex-start',
                                }}
                                />
                            )}
                            label={(
                                <Box>
                                    <Box sx={{
                                        fontWeight: 600,
                                        mb: 1,
                                    }}
                                    >
                                        {t('cardCreate.yourAddress')}
                                    </Box>

                                    <Box sx={{
                                        fontSize: theme => theme.typography.pxToRem(14),
                                    }}
                                    >
                                        {freelancer.firstName} {freelancer.lastName} - {company.name} <br />
                                        {createContactInfoAddress(freelancer.contactInfo)}
                                    </Box>
                                </Box>
                            )}
                            labelPlacement="end"
                        />
                    </Box>

                    <Box
                        sx={{
                            border: radioValue === 'new' ? '2px solid #3779BE' : '1px solid rgba(0, 0, 0, 0.12)',
                            borderRadius: '16px',
                            px: 2.5,
                            py: 2,
                            mt: 2,
                            width: '100%',
                            cursor: 'pointer',
                        }}
                        onClick={() => setRadioValue('new')}
                    >
                        <FormControlLabel
                            sx={{alignItems: 'flex-start'}}
                            value="new"
                            control={(
                                <Radio sx={{
                                    height: '70px',
                                    alignItems: 'flex-start',
                                }}
                                />
                            )}
                            label={(
                                <Box>
                                    <Box sx={{
                                        fontWeight: 600,
                                        mb: 1,
                                    }}
                                    >
                                        {t('cardCreate.changeDeliveryAddress')}
                                    </Box>

                                    <Box sx={{
                                        fontSize: theme => theme.typography.pxToRem(14),
                                    }}
                                    >
                                        {t('cardCreate.cardAtDifferentAddress')}
                                    </Box>
                                </Box>
                            )}
                            labelPlacement="end"
                        />
                    </Box>
                </RadioGroup>

                <Divider
                    sx={{
                        mt: 3,
                    }}
                />

                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    gap: isMobileSize ? 2 : 3,
                    mt: 3,
                    flexDirection: isMobileSize ? 'column-reverse' : 'row',
                }}
                >
                    <Button
                        type="button"
                        variant="contained"
                        color="grey"
                        fullWidth
                        startIcon={<ArrowBackIcon />}
                        onClick={onCancel}
                    >
                        {t('cardCreate.cancelAddressReceiveType')}
                    </Button>

                    <ButtonAction
                        color="secondary"
                        isLoading={isLoading}
                        fullWidth
                        endIcon={<ArrowForwardIcon />}
                        onClick={onSubmit}
                    >
                        {t('cardCreate.confirmAddressReceiveType')}
                    </ButtonAction>
                </Box>
            </Box>
        </>
    );
};

BankCardAddressChoose.propTypes = {
    t: PropTypes.func.isRequired,
    setRadioValue: PropTypes.func.isRequired,
    radioValue: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    freelancer: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    isMobileSize: PropTypes.bool.isRequired,
};

BankCardAddressChoose.defaultProps = {
    isLoading: false,
};
