import {faExclamationTriangle, faThumbsUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Chip, Radio, RadioGroup, Switch, Tab, TabList, TabPanel, Tabs, Typography} from '@mui/joy';
import React from 'react';
import withJoyUI from '../../components/joy-ui/joyui-theme-provider/JoyUiThemePRoviderWrapper';
import IndicatorBox from '../../components/joy-ui/sections/IndicatorBox';
import Section from '../../components/joy-ui/sections/Section';

// TODO Remove when done with testing
const TestJoyUi = () => {
    const [index, setIndex] = React.useState(0);
    const [radioVaule, setRadioVaule] = React.useState(0);
    const [switchVaule, setSwitchVaule] = React.useState(false);

    return (
        <Box
            component="section"
            sx={{
                p: 2,
                border: '1px dashed grey',
            }}
        >
            This Box renders as an HTML section element.

            <p>
                <Switch
                    checked={switchVaule}
                    onChange={e => setSwitchVaule(e.target.checked)}
                    color={switchVaule ? 'primary' : 'neutral'}
                    variant={switchVaule ? 'solid' : 'outlined'}
                    endDecorator="Switch"
                />
            </p>

            <p>
                <Switch
                    checked={switchVaule}
                    onChange={e => setSwitchVaule(e.target.checked)}
                    endDecorator="Switch"
                />
            </p>

            <p>
                <Chip
                    variant="solid"
                    color="primary"
                    size="lg"
                >
                    Test
                </Chip>
            </p>

            <p>
                <RadioGroup value={radioVaule} name="radio" onChange={e => setRadioVaule(e.target.value)}>
                    <Radio value={0} label="Value1" />
                    <Radio value={1} label="Value2" />
                    <Radio value={2} label="Value3" />
                </RadioGroup>
            </p>

            <p>
                <Tabs
                    aria-label="tabs"
                    value={index}
                    onChange={(_, value) => setIndex(value)}
                >
                    <TabList>
                        <Tab color={index === 0 ? 'primary' : 'neutral'}>First tab</Tab>
                        <Tab color={index === 1 ? 'primary' : 'neutral'}>Second tab</Tab>
                        <Tab color={index === 2 ? 'primary' : 'neutral'}>Third tab</Tab>
                    </TabList>
                    <TabPanel value={0}>
                        <b>First</b> tab panel
                    </TabPanel>
                    <TabPanel value={1}>
                        <b>Second</b> tab panel
                    </TabPanel>
                    <TabPanel value={2}>
                        <b>Third</b> tab panel
                    </TabPanel>
                </Tabs>
            </p>

            <p>
                <Typography level="h1">Secondary info</Typography><br />
                <Typography level="h2">Secondary info</Typography><br />
                <Typography level="h3">Secondary info</Typography><br />
                <Typography level="h4">Secondary info</Typography><br />
                <Typography level="title-lg">Secondary info</Typography><br />
                <Typography level="title-md">Secondary info</Typography><br />
                <Typography level="title-sm">Secondary info</Typography><br />
                <Typography level="body-lg">Secondary info</Typography><br />
                <Typography level="body-md">Secondary info</Typography><br />
                <Typography level="body-sm">Secondary info</Typography><br />
                <Typography level="body-xs">Secondary info</Typography><br />
            </p>

            <hr />

            <Section title="Section title example">
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    gap: 2,
                }}
                >
                    <IndicatorBox
                        title="IndicatorBox title"
                        icon={(
                            <Box color="neutral.900" fontSize="sm">
                                <FontAwesomeIcon icon={faThumbsUp} />
                            </Box>
                        )}
                    />
                    <IndicatorBox
                        title="IndicatorBox title"
                        icon={(
                            <Box color="neutral.900" fontSize="sm">
                                <FontAwesomeIcon icon={faThumbsUp} />
                            </Box>
                        )}
                    />
                    <IndicatorBox
                        title="IndicatorBox title"
                        icon={(
                            <Box color="danger.softColor" fontSize="sm">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                            </Box>
                        )}
                        isDanger
                    />
                </Box>

            </Section>
        </Box>
    );
};

export default withJoyUI(TestJoyUi);
