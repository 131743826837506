import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';
import {i18n} from '../../../../../lib/i18next';
import {safeTransformData} from '../../../../../v1/app/api/axios';
import {transformTrainingCompanyDocuments} from '../../../../../v1/app/api/providers/company/company.dto';

const t = i18n.t.bind(i18n);

const Document = function (dto, freelancerId) {
    this.companyId = dto.company_id;
    this.freelancerId = freelancerId;
    this.category = dto.doc_category;
    this.subType = dto.doc_sub_type;
    this.type = dto.doc_type;
    this.id = dto.id;
    this.fileName = dto.original_filename;
    this.status = dto.doc_status;
    this.uploaderId = dto.uploader_id;
    this.signable = dto.signable;
    this.docName = dto.original_filename.includes(dto.doc_type) ? dto.original_filename : t(`documents:documentsDatabaseNames.${dto.doc_type}`);
};

const getDocuments = ({freelancerId, companyId, context}) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}/companies/${companyId}/documents`, {
            params: {
                doc_context: context,
                // doc_category: 'ADMINISTRATIVE',
            },
        })
        .then(result => {
            const dtos = result.data;

            if (!dtos) {
                return {};
            }

            const documents = {};

            dtos.forEach(dto => {
                documents[dto.id] = new Document(dto, freelancerId);
            });

            return documents;
        });
};

const getDocumentsByCategory = ({freelancerId, companyId, category}) => {
    return hiwayApiInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents`,
        params: {
            doc_category: [
                category,
            ],
        },
    }).then(response => safeTransformData(response, transformTrainingCompanyDocuments, {}));
};

const getDocumentsByDocType = ({freelancerId, companyId, type}) => {
    return hiwayApiInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents`,
        params: {
            doc_type: [
                type,
            ],
        },
    }).then(response => safeTransformData(response, transformTrainingCompanyDocuments, {}));
};

export const DatabaseApi = {
    getDocuments,
    getDocumentsByCategory,
    getDocumentsByDocType,
};
