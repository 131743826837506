import {combineReducers} from 'redux';

import * as actions from './emailVerificationForm.actions';

const initialState = {
    isVerificationCodeResent: false,
    isLoading: false,
    resendVerificationCodeTimeLeft: null,
};

const isVerificationCodeResentReducer = (state = initialState.isVerificationCodeResent, action) => {
    if (action.type === actions.SET_IS_VERIFICATION_CODE_RESENT) {
        return action.payload;
    }

    return state;
};

const isLoadingReducer = (state = initialState.isLoading, action) => {
    if (action.type === actions.SET_IS_LOADING_EMAIL_VERIFICATION_FORM) {
        return action.payload;
    }

    return state;
};

const resendVerificationCodeTimeLeftReducer = (state = initialState.resendVerificationCodeTimeLeft, action) => {
    if (action.type === actions.SET_RESEND_VERIFICATION_CODE_TIME_LEFT) {
        return action.payload;
    }

    return state;
};

export const emailVerificationFormReducer = combineReducers({
    isVerificationCodeResent: isVerificationCodeResentReducer,
    isLoading: isLoadingReducer,
    resendVerificationCodeTimeLeft: resendVerificationCodeTimeLeftReducer,
});
