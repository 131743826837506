import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import {Avatar, Box, Paper, Typography} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link, generatePath} from 'react-router-dom';
import {AdminOpportunityBids} from './AdminOpportunityBids';
import {importLocaleBundle} from '../../../../lib/i18next';
import {RoutePaths} from '../../../../lib/router/route-paths';
import {OpportunitySelector} from '../../store/opportunity.selector';
import {ADMIN_OPPORTUNITY_GRID_TEMPLATES} from '../../utls/constants';
import {OpportunityFileItem} from '../freelancer-opportunity/OpportunityFileItem';

importLocaleBundle('jobProfessions');

export const AdminOpportunityListItem = ({id}) => {
    const [detailsOpen, setDetailsOpen] = useState(false);

    const {t} = useTranslation('opportunity');
    const {t: userT} = useTranslation('createUser');
    const {t: jobT} = useTranslation('jobProfessions');

    const item = useSelector(OpportunitySelector.selectOpportunityById(id));

    if (!item) {
        return null;
    }

    const onDetailsToggle = () => {
        setDetailsOpen(prevValue => !prevValue);
    };

    const hasBids = item.bids?.length && item.bids[0];
    const hasSelected = hasBids && item.bids.find(bid => bid.accepted);

    return (
        <Paper sx={{borderRadius: 2, pt: 2, px: 0, mb: 2, pb: 0}} elevation={1}>
            <Box sx={{
                display: 'grid',
                textAlign: 'center',
                alignItems: 'center',
                gridTemplateColumns: ADMIN_OPPORTUNITY_GRID_TEMPLATES.ELEMENT,
                fontSize: theme => theme.typography.pxToRem(14),
                wordBreak: 'break-word',
            }}
            >
                <Box sx={{
                    pl: 3,
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        <PersonIcon sx={{
                            color: '#CFD8DC',
                            fontSize: theme => theme.typography.pxToRem(16),
                            mr: 1,
                        }}
                        />
                        <Link
                            style={{
                                textDecoration: 'none',
                                fontWeight: 600,
                                fontSize: theme => theme.typography.pxToRem(14),
                                color: 'primary_v2.main',
                            }}
                            to={generatePath(RoutePaths.USER, {userId: item.freelancerId})}
                        >
                            <Typography sx={{
                                'textDecoration': 'none',
                                'fontWeight': 600,
                                'fontSize': theme => theme.typography.pxToRem(14),
                                'color': 'primary_v2.main',
                                '&:hover': {
                                    color: 'primary_v2.main',
                                    textDecoration: 'none',
                                    opacity: 0.8,
                                },
                            }}
                            >
                                {item.creatorFirstName} {item.creatorLastName}
                            </Typography>
                        </Link>
                    </Box>
                    <Box sx={{
                        color: 'v2.light.text.disabled',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        <BusinessIcon sx={{
                            color: 'v2.blueGray.100',
                            fontSize: theme => theme.typography.pxToRem(16),
                            mr: 1,
                        }}
                        />
                        {item.clientName}
                    </Box>
                </Box>

                <Box>
                    {moment(item.createdAt).format('DD/MM/YYYY')}
                </Box>

                <Box>
                    <Typography sx={{
                        fontSize: theme => theme.typography.pxToRem(14),
                    }}
                    >
                        {item.jobType.map(job => userT(`jobTypes.${job}`)).join(', ')}
                    </Typography>
                    <Typography sx={{
                        fontSize: theme => theme.typography.pxToRem(14),
                        color: 'v2.light.text.disabled',
                    }}
                    >
                        {item.jobSpecializations.map(
                            specialisation => jobT(`specializations.${specialisation}`),
                        ).join(', ')}
                    </Typography>
                </Box>

                <Box>
                    {!item.dailyRateLow && !item.dailyRateHigh
                        ? ('-')
                        : item.dailyRateLow && item.dailyRateLow !== '0' ? (
                            <Box>{item.dailyRateLow} - {item.dailyRateHigh}€</Box>
                        )
                            : (
                                <Box>{item.dailyRateHigh}€</Box>
                            )
                    }
                </Box>

                <Box>
                    {!item.startDate ? '-' : moment(item.startDate, 'DD/MM/YYYY').format('MMMM YYYY')}
                </Box>

                <Box sx={{textAlign: 'left', pl: 2}}>
                    {item.jobLocation && (
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <LocationOnIcon sx={{color: '#CFD8DC', fontSize: '14px', mr: 0.5}} /> {item.jobLocation}
                    </Box>
                    )}
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <TimelapseIcon sx={{color: '#CFD8DC', fontSize: '14px', mr: 0.5}} /> {t(`submitOpportunity.${item.remoteJobType?.toLowerCase()}`)}
                    </Box>
                </Box>

                <Box sx={{
                    'minWidth': 0,
                }}
                >
                    <OpportunityFileItem file={item.missionDescriptionDoc} freelancerId={item.freelancerId} />
                    {item.additionalMissionDocs?.map(file => {
                        return (
                            <OpportunityFileItem key={file.id} file={file} freelancerId={item.freelancerId} />
                        );
                    })}
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
                >
                    <Avatar
                        onClick={onDetailsToggle}
                        sx={{
                            'cursor': 'pointer',
                            '&:hover': {
                                opacity: 0.8,
                            },
                            'bgcolor': hasSelected
                                ? 'success_v2.light' : hasBids
                                    ? 'v2.blueGray.500' : 'v2.light.text.disabledLight',
                        }}
                    >
                        {hasBids ? item.bids.length : 0}
                    </Avatar>
                </Box>
            </Box>

            <AdminOpportunityBids
                bidCreatorId={item.freelancerId}
                isOpen={detailsOpen}
                list={item.bids}
                opportunityId={id}
            />
        </Paper>
    );
};

AdminOpportunityListItem.propTypes = {
    id: PropTypes.string.isRequired,
};
