import moment from 'moment';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {SettingsActionTypes, SettingsActions} from './settings.actions';
import {i18n} from '../../../lib/i18next';
import {Toast} from '../../../lib/toast';
import {createYupLocale} from '../../../lib/yup/yup';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {getSettingsRequest, updateSettingsRequest} from '../api/settings.provider';

const getUserSettingsSaga = function* () {
    try {
        yield put(SettingsActions.setIsLoadingUserSettings(true));

        const currentCognitoUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        const settings = yield call(getSettingsRequest, currentCognitoUser.id);

        yield call([i18n, i18n.changeLanguage], settings.language);

        yield call(createYupLocale, i18n.t);

        moment.locale(settings.language);

        // Fixes race condition between setting saved language and active month initialization
        // yield put(DashboardActions.setActiveMonth(moment()));

        yield put(SettingsActions.storeUserSettings(settings));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn({error});

        yield put(SettingsActions.storeUserSettings(null));

        if (error?.response?.status === 404) {
            return;
        }

        Toast.error('anErrorOccurred');
    } finally {
        yield put(SettingsActions.setIsLoadingUserSettings(false));
    }
};

const updateUserSettingsSaga = function* ({payload}) {
    try {
        yield put(SettingsActions.setIsUpdatingUserSettings(true));

        const currentCognitoUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        yield call(updateSettingsRequest, currentCognitoUser.id, payload);

        yield call([i18n, i18n.changeLanguage], payload.language);

        yield call(createYupLocale, i18n.t);

        moment.locale(payload.language.toLowerCase());

        // Fixes race condition between setting saved language and active month initialization
        // yield put(setActiveMonth(moment()));

        yield put(SettingsActions.storeUserSettings(payload));

        Toast.success('settingsSaved');
    } catch (error) {
        Toast.error('anErrorOccurred');
    } finally {
        yield put(SettingsActions.setIsUpdatingUserSettings(false));
    }
};

export const settingsLoaderSaga = function* () {
    yield put(SettingsActions.getUserSettings());
};

export const watchSettingsSaga = function* () {
    yield all([
        takeLatest(SettingsActionTypes.GET_USER_SETTINGS, getUserSettingsSaga),
        takeLatest(SettingsActionTypes.UPDATE_USER_SETTINGS, updateUserSettingsSaga),
    ]);
};
