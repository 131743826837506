import {SvgIcon} from '@mui/material';
import React from 'react';

const ExcelIcon = props => {
    return (
        <SvgIcon {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path d="M12 21V4h11a1 1 0 011 1v15a1 1 0 01-1 1H12z" opacity=".153" fill="#007533" />
                <path d="M14 7h2.5a.5.5 0 110 1H14V7zm0 2h2.5a.5.5 0 110 1H14V9zm0 2h2.5a.5.5 0 110 1H14v-1zm0 2h2.5a.5.5 0 110 1H14v-1zm0 2h2.5a.5.5 0 110 1H14v-1zm0 2h2.5a.5.5 0 110 1H14v-1zm4.5-10h3a.5.5 0 110 1h-3a.5.5 0 110-1zm0 2h3a.5.5 0 110 1h-3a.5.5 0 110-1zm0 2h3a.5.5 0 110 1h-3a.5.5 0 110-1zm0 2h3a.5.5 0 110 1h-3a.5.5 0 110-1zm0 2h3a.5.5 0 110 1h-3a.5.5 0 110-1zM12.5.356a1.5 1.5 0 011.5 1.5v20.289a1.5 1.5 0 01-1.814 1.466L.79 21.17a1 1 0 01-.79-.977V3.809a1 1 0 01.79-.978L12.186.389A1.5 1.5 0 0112.5.356zM21.5 17a.5.5 0 110 1h-3a.5.5 0 110-1h3z" fill="#007533" />
                <path d="M5.658 15c.088 0 .15-.035.185-.106l1.126-1.962c.012-.018.025-.026.04-.026.014 0 .025.008.03.026l1.118 1.962c.035.07.097.106.185.106h1.012a.158.158 0 00.097-.026.084.084 0 00.035-.07.129.129 0 00-.026-.08l-1.672-2.869a.143.143 0 010-.07L9.46 9.016a.158.158 0 00.026-.08.084.084 0 00-.035-.07.158.158 0 00-.097-.026H8.342c-.088 0-.15.035-.185.106l-1.117 1.97c-.006.018-.017.027-.031.027-.015 0-.028-.009-.04-.026L5.852 8.946c-.036-.07-.097-.106-.185-.106H4.646a.158.158 0 00-.097.026.084.084 0 00-.035.07c0 .024.009.05.026.08l1.672 2.869a.143.143 0 010 .07L4.54 14.824a.129.129 0 00-.026.08c0 .029.012.052.035.07a.158.158 0 00.097.026h1.012z" fill="#FFF" fillRule="nonzero" />
            </g>
        </SvgIcon>
    );
};

export default ExcelIcon;
