import {faLink, faRankingStar} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mui/joy';
import {useTranslation} from 'react-i18next';
import {InfoSheet} from '../../../../../components/joy-ui/info-sheet/InfoSheet';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {Toast} from '../../../../../lib/toast';
import {resolveRoute} from '../../../../../v1/navigation/resolveRoute';
import {useSimulationData} from '../utils/useSimulationData';

export const LinkSection = () => {
    const simulation = useSimulationData();
    const {t} = useTranslation('revenueSimulator');

    const handleCopyClick = () => {
        const relativeURL = resolveRoute(RoutePaths.REVENUE_SIMULATOR_RESULTS_PUBLIC, {simulationId: simulation.id});
        const absoluteURL = window.location.origin + relativeURL;
        navigator.clipboard
            .writeText(absoluteURL)
            .then(() => {
                Toast.success('copiedToClipboard');
            })
            .catch(err => {
                // eslint-disable-next-line no-console
                console.error('Failed to copy text: ', err);
            });
    };

    return (
        <InfoSheet
            icon={faRankingStar}
            title={t('linkSection.title')}
            titleProps={{level: 'title-md', textColor: 'text.secondary'}}
            description={t('linkSection.description')}
            sx={{bgcolor: 'neutral.200'}}
            descriptionProps={{level: 'body-sm', textColor: 'text.secondary'}}
            cta={(
                <Button
                    variant="solid"
                    color="neutral"
                    onClick={handleCopyClick}
                    startDecorator={<FontAwesomeIcon icon={faLink} />}
                    size="sm"
                    sx={{width: {mobile: '100%', desktop: 'auto'}}}
                >
                    {t('linkSection.buttonText')}
                </Button>
              )}
        />
    );
};
