import merge from 'lodash.merge';
import moment from 'moment/moment';
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {RevenueSimulatorActions} from './revenue-simulator.action';
import {RevenueSimulatorActionTypes} from './revenue-simulator.action-type';
import {selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {Toast} from '../../../lib/toast';
import {BE_DATE_FORMAT} from '../../../utils/constants';
import {Debug} from '../../../utils/debug';
import hiwayLocalStorage from '../../../v1/config/storage';
import {RevenueSimulatorApi} from '../api/revenue-simulator.api';

const setAllEntries = (object, value) => Object.keys(object || {}).forEach(key => (object[key] = value));

const transformValues = payload => {
    const data = merge({}, payload);

    // Backend requires `numberOfDependents` which is `numberOfParts` excluding adults
    data.personalInfo.numberOfDependents = data.personalInfo.numberOfParts - data.personalInfo.numberOfAdults;

    data.projection.targetStartingDate = moment(data.projection.targetStartingDate).format(BE_DATE_FORMAT);

    if (!data.investments.wantsToInvest) {
        data.investments.monthlyRetirementSavingsPlanAmount = null;
    }

    // Make sure to reset values if user goes back and edits yes/no toggles
    if (!data.professionalInfo.hasOtherRevenue) {
        setAllEntries(data.professionalInfo.otherRevenue, 0);
    }
    if (!data.professionalInfo.spouseHasOtherRevenue) {
        setAllEntries(data.professionalInfo.spouseOtherRevenue, 0);
    }
    if (data.professionalInfo.eligibleTravelSubvention) {
        delete data.professionalInfo.monthsToRestoreTravelSubvention;
    }

    // Edge case if number of adults changed to 1 after already entering spouse details
    if (data.personalInfo.numberOfAdults === 1) {
        delete data.professionalInfo.spouseEmploymentSituation;
        delete data.professionalInfo.spouseMonthlyNetIncome;
        delete data.professionalInfo.spouseOtherRevenue;
    }

    // Delete helper fields
    delete data.personalInfo.numberOfParts;
    delete data.investments.wantsToInvest;
    delete data.professionalInfo.hasOtherRevenue;
    delete data.professionalInfo.spouseHasOtherRevenue;

    return data;
};

const submitInputsFlow = function* ({payload}) {
    try {
        const data = transformValues(payload);
        yield put(RevenueSimulatorActions.setIsSubmitting(true));
        const simulation = yield call(RevenueSimulatorApi.submitInputs, data);
        yield put(RevenueSimulatorActions.storeSimulation({simulation}));
        yield put(RevenueSimulatorActions.setIsSubmitting(false));
    } catch (error) {
        Debug.error('revenue-simulator', error.message, {error});
        Toast.error('genericError');
        yield put(RevenueSimulatorActions.goPrevStep());
    } finally {
        yield put(RevenueSimulatorActions.setIsSubmitting(false));
    }
};

const getSimulationFlow = function* ({payload}) {
    try {
        const simulation = yield call(RevenueSimulatorApi.getSimulation, payload);
        yield put(RevenueSimulatorActions.storeSimulation(simulation));
    } catch (error) {
        Debug.error('revenue-simulator', error.message, {error});
    }
};

const submitStepFlow = function* (action) {
    yield put(RevenueSimulatorActions.updateValues(action.payload));
    yield put(RevenueSimulatorActions.goNextStep());
};

const simulationsSortValuesEnum = {
    dateOfSimulation: 'createdAt',
    coachEmail: 'coachEmail',
    status: 'expiresAt',
};

export const resetSimulationResults = function* () {
    yield put(RevenueSimulatorActions.resetResults());
};

export const resetSimulationInputs = function* () {
    yield put(RevenueSimulatorActions.resetInputs());
};

export const simulationsListLoaderSaga = function* () {
    yield put(RevenueSimulatorActions.getAllSimulations());
};

const getAllSimulationsFlow = function* () {
    const location = yield select(selectRouterLocation);

    const search = location?.query;

    const storageKey = `revenueSimulations-savedRowsPerPage`;
    const limit = search?.['revenueSimulations-rowsPerPage']
        ?? (hiwayLocalStorage.has(storageKey) ? parseInt(hiwayLocalStorage.get(storageKey), 10) : 10);

    const params = {
        limit: limit,
        offset: search?.['revenueSimulations-page'] ? parseInt(search['revenueSimulations-page'], 10) * limit : 0,
        sortBy: search?.['revenueSimulations-sortBy']
            ? simulationsSortValuesEnum[search['revenueSimulations-sortBy']]
            : undefined,
        sortOrder: search?.['revenueSimulations-sortBy']
            ? search?.['revenueSimulations-sortDirection']
                ? search?.['revenueSimulations-sortDirection'].toUpperCase()
                : 'DESC'
            : undefined,
        searchQuery: search?.['revenueSimulations-searchTerm'],
    };

    yield put(RevenueSimulatorActions.setSimulationsLoading(true));
    try {
        const simulations = yield call(RevenueSimulatorApi.getAllSimulations, params);
        yield put(RevenueSimulatorActions.storeAllSimulations(simulations));
    } catch (error) {
        Debug.error('revenue-simulator', error.message, {error});
    } finally {
        yield put(RevenueSimulatorActions.setSimulationsLoading(false));
    }
};

const refreshSimulationFlow = function* ({payload}) {
    try {
        yield put(RevenueSimulatorActions.setSimulationsLoading(true));
        yield call(RevenueSimulatorApi.refreshSimulation, payload);
        yield put(RevenueSimulatorActions.getAllSimulations());
        Toast.success('simulationRefreshed', payload);
    } catch (error) {
        yield put(RevenueSimulatorActions.setSimulationsLoading(false));
        Toast.error('genericError');
        Debug.error('revenue-simulator', error.message, {error});
    }
};

export const revenueSimulatorSaga = function* () {
    yield all([
        takeEvery(RevenueSimulatorActionTypes.SUBMIT_INPUTS, submitInputsFlow),
        takeEvery(RevenueSimulatorActionTypes.GET_SIMULATION, getSimulationFlow),
        takeEvery(RevenueSimulatorActionTypes.SUBMIT_STEP, submitStepFlow),
        takeEvery(RevenueSimulatorActionTypes.GET_ALL_SIMULATIONS, getAllSimulationsFlow),
        takeEvery(RevenueSimulatorActionTypes.REFRESH_SIMULATION, refreshSimulationFlow),
    ]);
};
