import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import {Button, Grid, Skeleton, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import EditButtonSmall from '../../../components/buttons/EditButtonSmall';
import {DataItemRow} from '../../../components/data-item-row/DataItemRow';
import {importLocaleBundle} from '../../../lib/i18next';
import {push} from '../../../lib/router/connected-router-saga';
import {ROUTE_PATHS} from '../../../v1/navigation/routePaths';
import {LoadingSelectors, LoadingTypes} from '../../loading';

importLocaleBundle('create-user');

const JobProfessionContent = props => {
    const {t: tUsers} = useTranslation('users');
    const {t: tCreateUser} = useTranslation('createUser');
    const {t: tSpec} = useTranslation('jobProfessions');
    const {onEditButtonClick, data} = props;
    const isMobileSize = useMediaQuery(`(max-width:899px)`);
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GET_PROFESSION_AND_SPECIALIZATION),
    ) ?? true;
    const isSaving = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.SAVE_PROFESSION_AND_SPECIALIZATION),
    ) ?? false;

    const dispatch = useDispatch();
    const onSettingsLinkClickHandler = () => {
        dispatch(push(ROUTE_PATHS.SETTINGS));
    };

    return (
        <>
            {!isMobileSize && (
                <DataItemRow
                    label={(
                        <Typography
                            component="span"
                            sx={{
                                color: 'grey.light',
                                fontWeight: '500',
                                fontSize: theme => theme.typography.pxToRem(14),
                                lineHeight: theme => theme.typography.pxToRem(24),
                            }}
                        >
                            {tUsers('professionAndSpecialization.jobProfession')}
                        </Typography>
                    )}
                    content={(
                        <Typography
                            component="span"
                            sx={{
                                color: 'grey.light',
                                fontWeight: '500',
                                fontSize: theme => theme.typography.pxToRem(14),
                                lineHeight: theme => theme.typography.pxToRem(24),
                            }}
                        >
                            {tUsers('professionAndSpecialization.specialities')}
                        </Typography>
                    )}
                />
            )}
            { !isLoading && !isSaving && data.map(rowData => (
                <Grid container sx={{px: 3, py: 2, borderBottom: 1, borderColor: 'grey.300'}} key={rowData.jobProfession}>
                    <Grid item xs={12} md={6}>
                        <Typography sx={{color: {xs: 'elephant.main', md: 'text.primary'}, fontWeight: 500}}>
                            {tCreateUser(`jobTypes.${rowData.jobProfession}`)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography sx={{color: {xs: 'elephant.main', md: 'text.primary'}, fontWeight: 400}}>
                            {rowData
                                .specialization
                                .map(item => tSpec(`specializations.${item}`))
                                .join(', ')}
                        </Typography>
                    </Grid>
                </Grid>
            ))}
            {(!!isLoading || !!isSaving) && (
                <>
                    <Grid container sx={{px: 3, py: 2, borderBottom: 1, borderColor: 'grey.300'}}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: {xs: 'elephant.main', md: 'text.primary'}, fontWeight: 500}}>
                                <Skeleton variant="text" width={200} />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: {xs: 'elephant.main', md: 'text.primary'}, fontWeight: 400}}>
                                <Skeleton variant="text" width={200} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{px: 3, py: 2, borderBottom: 1, borderColor: 'grey.300'}}>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: {xs: 'elephant.main', md: 'text.primary'}, fontWeight: 500}}>
                                <Skeleton variant="text" width={200} />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography sx={{color: {xs: 'elephant.main', md: 'text.primary'}, fontWeight: 400}}>
                                <Skeleton variant="text" width={200} />
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}
            <Grid container sx={{display: 'flex', alignItems: 'center'}}>
                <Grid item xs={6} sx={{pl: 3}}>
                    <Button
                        disableRipple
                        size="small"
                        sx={{color: 'primary_v2.main', p: 0.5}}
                        onClick={onSettingsLinkClickHandler}
                        startIcon={<MarkEmailUnreadIcon />}
                    >
                        {(
                            isMobileSize
                                ? tUsers('professionAndSpecialization.linkToSettingsMobile')
                                : tUsers('professionAndSpecialization.linkToSettingsDesktop')
                        ).toUpperCase()}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <EditButtonSmall onClick={onEditButtonClick} />
                </Grid>
            </Grid>
        </>
    );
};

JobProfessionContent.propTypes = {
    onEditButtonClick: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
};

export default JobProfessionContent;
