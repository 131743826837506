import makeStyles from '@mui/styles/makeStyles';

export const iframeStyles = makeStyles(theme => ({
    iframe: {
        width: '100%',
        border: 0,
        overflow: 'visible',
        margin: theme.spacing(2, 0),
    },
    loader: {
        margin: theme.spacing(5, 'auto'),
        display: 'block',
    },
    skeleton: {
        margin: theme.spacing(2, 0),
        height: '36px',
    },
}));
