import CloseIcon from '@mui/icons-material/Close';
import {Box} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import React from 'react';
import {
    CardDetailsModalActivateCard,
    CardDetailsModalPhysInfo,
    CardDetailsModalUnblockCard,
    CardDetailsModalViewPinContent,
    CardDetailsModalVirtualInfo,
} from './BankCardModal';
import {ButtonIcon} from '../../../../../../components/buttons/ButtonIcon';
import {MODAL_TYPES} from '../../util/constants';

const BankCardDrawer = ({t, isOpen, setIsOpen, card, drawerType}) => {
    const handleClose = () => {
        setIsOpen(false);
    };
    return (
        <Drawer
            anchor="bottom"
            open={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}
            sx={{
                'position': 'relative',
                '.MuiDrawer-paperAnchorBottom': {
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                },
            }}
        >
            <Box>
                <ButtonIcon
                    icon={<CloseIcon fontSize="medium" />}
                    onClick={() => setIsOpen(false)}
                    sx={{
                        color: 'text_v2.primary',
                        position: 'absolute',
                        right: '15px',
                        top: '15px',
                    }}
                />
            </Box>
            <Box sx={{
                minHeight: '100px',
                borderTopRightRadius: '32px',
                borderTopLeftRadius: '32px',
                display: 'flex',
                flexDirection: 'column',
                px: 3,
                pb: 2,
            }}
            >
                {drawerType === MODAL_TYPES.VIEW_PIN && (
                    <CardDetailsModalViewPinContent handleClose={handleClose} isDrawer={true} card={card} t={t} />
                )}

                {drawerType === MODAL_TYPES.ACTIVATE_CARD && (
                    <CardDetailsModalActivateCard handleClose={handleClose} isDrawer={true} card={card} t={t} />
                )}

                {drawerType === MODAL_TYPES.PHYS_INFO && (
                    <CardDetailsModalPhysInfo handleClose={handleClose} isDrawer={true} card={card} t={t} />
                )}

                {drawerType === MODAL_TYPES.VIRTUAL_INFO && (
                    <CardDetailsModalVirtualInfo handleClose={handleClose} isDrawer={true} card={card} t={t} />
                )}

                {drawerType === MODAL_TYPES.UNLOCK_BLOCKED && (
                    <CardDetailsModalUnblockCard handleClose={handleClose} isDrawer={true} card={card} t={t} />
                )}
            </Box>
        </Drawer>
    );
};

BankCardDrawer.propTypes = {
    t: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    card: PropTypes.object,
    drawerType: PropTypes.string.isRequired,
};

BankCardDrawer.defaultProps = {
    card: {},
};

export default BankCardDrawer;
