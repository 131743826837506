import {hiwayApiInstance} from '../../../lib/hiway-api-instance';

const getSignedUrlForDocumentRequest = ({freelancerId, companyId, documentId, isDownloading = false}) => {
    return hiwayApiInstance({
        method: 'GET',
        params: {
            content_disposition: isDownloading ? 'attachment' : 'inline',
            preview: isDownloading ? undefined : true,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents/${documentId}/sign-url-get-document`,
    }).then(result => result.data);
};

const getDepositDocumentsRequest = ({freelancerId, companyId}) => {
    return hiwayApiInstance({
        method: 'GET',
        params: {
            preview: undefined,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents?doc_context=deposit`,
    });
};

const getKbisDocumentsRequest = ({freelancerId, companyId}) => {
    return hiwayApiInstance({
        method: 'GET',
        params: {
            preview: undefined,
        },
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents?doc_context=company_registraion`,
    });
};

export const generateContractDocumentRequest = userId => {
    return hiwayApiInstance({
        method: 'PUT',
        url: `/v1/users/${userId}/generate-contract`,
    }).then(result => result.data);
};

const getClusterDocument = ({freelancerId, documentId, isDownloading = false}) => {
    return hiwayApiInstance({
        method: 'GET',
        params: {
            content_disposition: isDownloading ? 'attachment' : 'inline',
            preview: isDownloading ? undefined : true,
        },
        url: `/v1/freelancers/${freelancerId}/other-freelancers-opportunities/documents/${documentId}`,
    }).then(result => result.data);
};

export const DocumentApi = {
    getSignedUrlForDocumentRequest,
    getDepositDocumentsRequest,
    getKbisDocumentsRequest,
    generateContractDocumentRequest,
    getClusterDocument,
};
