import {all, call, put, takeLatest} from 'redux-saga/effects';
import {UserSelectActions} from './user-select.action';
import {UserSelectActionTypes} from './user-select.action-type';
import {UserSelectApi} from '../api/user-select.api';

const getUsersFlow = function* ({role}) {
    if (!Array.isArray(role)) {
        role = [role];
    }

    try {
        const {ids, entities} = yield call(UserSelectApi.getUsers, {role});

        yield all([
            put(UserSelectActions.storeUserEntities(entities)),
            put(UserSelectActions.storeUserIds(ids)),
        ]);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});
    }
};

const getUsersWorker = function* ({payload}) {
    const {role} = payload;

    yield call(getUsersFlow, {role});
};

export const loadUsers = function* ({payload}) {
    const {role} = payload;

    yield call(getUsersFlow, {role});
};

export const userSelectSaga = function* () {
    yield all([
        takeLatest(UserSelectActionTypes.GET_USERS, getUsersWorker),
    ]);
};
