import React from 'react';
import TrainingDisabledModal from '../../features/training/components/disabled-modal/TrainingDisabledModal';
import {TrainingFeature} from '../../features/training/components/TrainingFeature';

const TrainingScreen = () => {
    return (
        <>
            <TrainingFeature />
            <TrainingDisabledModal />
        </>
    );
};

export default TrainingScreen;
