import {
    Box,
    Paper,
    useMediaQuery,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BankCardAddressChoose} from './BankCardAddressChoose';
import {BankCardCreateNewAddress} from './BankCardCreateNewAddress';
import {countryCodes} from '../../../../../../v1/utils/country-codes';
import {CompaniesSelectors} from '../../../../../freelancer/modules/companies';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {LoggedInUserSelectors} from '../../../../../user/modules/logged-in-user';
import {CardsActions} from '../../store/cards.actions';

const BankCardCreateAddress = ({
    t,
    setCardSettings,
    cardSettings,
}) => {
    const dispatch = useDispatch();

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const freelancer = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GENERIC_CRUD_LOADER),
    );

    const [radioValue, setRadioValue] = useState('self');
    const [showNewAddressForm, setShowNewAddressForm] = useState(false);

    const submitData = data => {
        dispatch(CardsActions.create(data));
    };

    const onCancel = () => {
        setCardSettings(null);
    };

    const onSubmitPicker = () => {
        if (radioValue === 'self') {
            const address = freelancer.contactInfo;

            const country = countryCodes.find(country => country.englishShortName === address.country);

            const deliveryAddress = {
                'country': country?.alpha3Code ?? 'FRA',
                'addressLine1': address.address,
                'addressLine2': undefined,
                'city': address.city,
                'state': undefined,
                'postalCode': address.zipCode,
            };

            submitData({
                settings: cardSettings,
                deliveryAddress,
            });
        } else {
            setShowNewAddressForm(true);
        }
    };

    const onSubmitAddress = data => {
        submitData({
            settings: cardSettings,
            deliveryAddress: data,
        });
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: isMobileSize ? 'flex-start' : 'center',
            minHeight: isMobileSize ? '100%' : '100vh',
        }}
        >
            <Paper
                sx={{
                    width: isMobileSize ? 'calc(100vw - 32px)' : '580px',
                    pt: 2,
                    px: isMobileSize ? 3 : 4,
                    pb: isMobileSize ? 3 : 4,
                    mt: isMobileSize ? 2 : 0,
                    borderRadius: '16px',
                }}
                elevation={0}
            >
                {showNewAddressForm ? (
                    <BankCardCreateNewAddress
                        t={t}
                        onSubmitAddress={onSubmitAddress}
                        isLoading={isLoading}
                        onCancel={() => setShowNewAddressForm(false)}
                        isMobileSize={isMobileSize}
                    />
                ) : (
                    <BankCardAddressChoose
                        t={t}
                        setRadioValue={setRadioValue}
                        radioValue={radioValue}
                        setCardSettings={setCardSettings}
                        onSubmit={onSubmitPicker}
                        onCancel={onCancel}
                        freelancer={freelancer}
                        company={company}
                        isLoading={isLoading}
                        isMobileSize={isMobileSize}
                    />
                )}
            </Paper>
        </Box>
    );
};

BankCardCreateAddress.propTypes = {
    t: PropTypes.func.isRequired,
    setCardSettings: PropTypes.func.isRequired,
    cardSettings: PropTypes.object.isRequired,
};

export default BankCardCreateAddress;
