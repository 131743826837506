export const fromFreelancerAccountDTO = freelancerAccountDTO => ({
    id: freelancerAccountDTO.id,
    defaultCompanyId: freelancerAccountDTO.default_company_id,
    canSetupCompany: freelancerAccountDTO.can_setup_company,
    canSetupHoldingCompany: freelancerAccountDTO.can_setup_holding_company,
});

export const fromFreelancerCompanyDTO = freelancerCompanyDTO => ({
    id: freelancerCompanyDTO.id,
    name: freelancerCompanyDTO.name || '',
    status: freelancerCompanyDTO.status || '',
    legalForm: freelancerCompanyDTO.legal_form || '',
    taxSystem: freelancerCompanyDTO?.enterprise_info?.tax_system || '',
    type: freelancerCompanyDTO.company_type || '',
    activityStartDate: freelancerCompanyDTO?.enterprise_info?.activity_start_date || null,
});

export const transformFreelancerCompanies = companyDTOs => {
    const companies = {};

    companyDTOs.forEach(companyDTO => {
        companies[companyDTO.id] = fromFreelancerCompanyDTO(companyDTO);
    });

    return companies;
};
