import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {LocalizationProvider} from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useController} from 'react-hook-form';
import {TextInput} from './TextInput';
import {FR_DATE_FORMAT_SHORT} from '../../../../../utils/constants';


const firstDayOfNextMonth = moment().add(1, 'month').startOf('month').toDate();

export const DateField = ({name, defaultValue, required, ...other}) => {
    const {field, fieldState} = useController({name, defaultValue, rules: {required}});

    return (
        <LocalizationProvider
            dateLibInstance={moment}
            dateAdapter={AdapterMoment}
        >
            <DatePicker
                {...field}
                mask="__/__/__"
                inputFormat={FR_DATE_FORMAT_SHORT}
                InputAdornmentProps={{
                    sx: {mr: 1},
                }}
                components={{
                    OpenPickerIcon: () => <CalendarMonthIcon sx={{color: 'v2.blueGray.300', fontSize: '2.2rem'}} />,
                }}
                onChange={date => {
                    if (date?.isValid()) {
                        field.onChange(date.toDate());
                    } else {
                        field.onChange(undefined);
                    }
                }}
                renderInput={({inputRef, inputProps, InputProps}) => (
                    <TextInput
                        inputRef={inputRef}
                        inputProps={inputProps}
                        endAdornment={InputProps.endAdornment}
                        error={fieldState.invalid}
                        {...other}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

DateField.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    // eslint-disable-next-line react/boolean-prop-naming
    required: PropTypes.bool.isRequired,
};

DateField.defaultProps = {
    defaultValue: firstDayOfNextMonth,
};
