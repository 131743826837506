import {faArrowRight, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, IconButton, Stack, Typography} from '@mui/joy';
import useVATDeclarationSendProgress from '../../../features/formalities/modules/vat-declaration/hooks/useVATDeclarationSendProgress';
import {UiSelectors} from '../../../features/ui/store/ui.selector';
import {ModalsKeys} from '../../../features/ui/utils/constants';
import {useMemoizedCreateSelector} from '../../../hooks/use-memoized-create-selector';

const VatDeclarationNotifications = () => {
    const {notifications} = useVATDeclarationSendProgress();

    const isProcessSingleModalOpen = useMemoizedCreateSelector(
        UiSelectors.createIsModalActiveSelector, ModalsKeys.PROCESS_SINGLE_VAT_DECLARATION,
    );

    const isProcessMultiModalOpen = useMemoizedCreateSelector(
        UiSelectors.createIsModalActiveSelector, ModalsKeys.PROCESS_MULTIPLE_VAT_DECLARATION,
    );

    if (isProcessSingleModalOpen || isProcessMultiModalOpen || notifications?.length === 0) {
        return null;
    }

    return (notifications.map(info => (
        <Alert
            key={info.title}
            sx={{gap: 1.25, fontSize: '16px', py: 1, px: 1.25}}
            size="sm"
            color={info.color}
            variant={info.variant}
            startDecorator={info.startDecorator}
            endDecorator={(
                <Stack flexDirection="row" gap={1}>
                    {info.cta && (
                        <Button
                            size="sm"
                            variant={info.cta.variant}
                            color={info.cta.color}
                            endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                            onClick={info.action}
                        >
                            {info.cta.label}
                        </Button>
                    )}
                    {info.showDismissCTA && (
                        <IconButton onClick={info.onDismiss}>
                            <FontAwesomeIcon icon={faXmark} />
                        </IconButton>
                    )}
                </Stack>
            )}
        >
            <Typography level="body-md">
                {info.title}
            </Typography>
        </Alert>
    )));
};

export default VatDeclarationNotifications;
