import moment from 'moment/moment';
import {combineReducers} from 'redux';
import {DashboardActionTypes} from './dashboard.action-type';
import {createReducer} from '../../../utils/create-reducer';
import {getDashboardDatepickerCurrentYear} from '../util/dashboard.util';

const dashboardDataInitialState = {
    cacheManagement: {
        vatDebt: 0,
        csgDebt: 0,
        csgN1Debt: 0,
        minimumCashThreshold: 0,
    },
    income: {
        paid: 0,
        total: 0,
        plannedTotal: 0,
        chartData: [{
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: false,
        }, {
            salary: 0,
            balance: 0,
            isLast: true,
        }],
    },
    taxIncome: {
        personalTax: 0,
        householdTax: 0,
    },
    turnover: {
        balance: [{
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: false,
        }, {
            amount: 0,
            isLast: true,
        }],
        currentYearTotal: 0,
    },
    month: moment().format('MM/YYYY'),
    isValidationPending: false,
};

export const dashboardReducer = combineReducers({
    isLoading: createReducer(true, DashboardActionTypes.SET_IS_LOADING_DASHBOARD_DATA, true),
    data: createReducer(dashboardDataInitialState, DashboardActionTypes.STORE_DASHBOARD_DATA, true),
    selectedYear: createReducer(getDashboardDatepickerCurrentYear(), DashboardActionTypes.SET_DASHBOARD_YEAR, true),
    isLoadingIncomeCalculation: createReducer(
        false,
        DashboardActionTypes.SET_IS_LOADING_INCOME_CALCULATION_REQUEST,
        true,
    ),
    isFinishedIncomeCalculation: createReducer(
        false,
        DashboardActionTypes.SET_IS_FINISHED_INCOME_CALCULATION_REQUEST,
        false,
    ),
    taxInfo: createReducer(null, DashboardActionTypes.STORE_TAX_INFO),
    resetDashboardIframe: createReducer(0, DashboardActionTypes.DASHBOARD_IFRAME_KEY),
});
