import StarIcon from '@mui/icons-material/Star';
import {Box, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import DesktopPopularCategory from './DesktopPopularCategory';
import PopularCategory from './PopularCategory';
import {SettingsSelectors} from '../../../../../../../settings/store/settings.selectors';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {TransactionSide} from '../../../../util/constants';
import {createIconStringFromName} from '../../../../util/icon';

const PopularCategoriesSection = ({
    onCategorySelect,
    side,
    isSubscribed,
    hasMultipleArticles,
}) => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    const mostUsedCategoryList = useSelector(TransactionsSelector.selectMostUsedDebitCategories);
    const mostUsedCreditCategoryList = useSelector(TransactionsSelector.selectMostUsedCreditCategories);

    if (isMobileSize) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}
                >
                    <StarIcon sx={{color: 'v2.blueGray.500'}} />
                    <Typography sx={{
                        color: 'v2.blueGray.300',
                        fontSize: theme => theme.typography.pxToRem(12),
                        fontWeight: 500,
                        letterSpacing: '1px',
                        textTransform: 'uppercase',
                    }}
                    >
                        {t('categorisation.popular')}
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'stretch',
                    justifyContent: 'space-between',
                    width: '100%',
                    overflowX: 'auto',
                }}
                >
                    {side === TransactionSide.DEBIT && mostUsedCategoryList && (
                        <>
                            {mostUsedCategoryList?.categoriesList.map(categoryParent => {
                                return (
                                    <>
                                        {categoryParent?.mccGroupItems.map(category => {
                                            if (isSubscribed && !category?.subscriptionCategorizationSupport) {
                                                return null;
                                            }

                                            if (hasMultipleArticles
                                                && (!category?.multipleArticles
                                                || category?.subscriptionCategorizationSupport)
                                            ) {
                                                return null;
                                            }

                                            const iconName = mostUsedCategoryList
                                                .categoriesObject?.[category.mccKey]?.iconName;

                                            const Icon = createIconStringFromName(iconName);

                                            return (
                                                <PopularCategory
                                                    key={iconName}
                                                    Icon={Icon}
                                                    title={category?.[language]?.categoryName}
                                                    onClick={() => {
                                                        onCategorySelect(category);
                                                    }}
                                                />
                                            );
                                        })}
                                    </>
                                );
                            })}
                        </>
                    )}

                    {side === TransactionSide.CREDIT && mostUsedCategoryList && (
                        <>
                            {mostUsedCategoryList?.sourcesList.map(category => {
                                const revenue = category?.sourceOfRevenueGroupItems?.[0];

                                const iconName = mostUsedCategoryList
                                    .sourcesObject?.[revenue.sourceOfRevenue]?.iconName;

                                const Icon = createIconStringFromName(iconName);

                                return (
                                    <PopularCategory
                                        key={iconName}
                                        Icon={Icon}
                                        title={revenue?.[language]?.categoryName}
                                        onClick={() => {
                                            onCategorySelect(revenue?.sourceOfRevenue);
                                        }}
                                    />
                                );
                            })}
                        </>
                    )}
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
        }}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
            }}
            >
                <StarIcon sx={{color: 'v2.blueGray.500'}} />
                <Typography sx={{
                    color: 'v2.blueGray.300',
                    fontSize: theme => theme.typography.pxToRem(12),
                    fontWeight: 500,
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                }}
                >
                    {t('categorisation.popular')}
                </Typography>
            </Box>

            {side === TransactionSide.DEBIT && mostUsedCategoryList && (
                <>
                    {mostUsedCategoryList?.categoriesList.map(categoryParent => {
                        return (
                            <>
                                {categoryParent?.mccGroupItems.map(category => {
                                    const iconName = mostUsedCategoryList
                                        .categoriesObject?.[category.mccKey]?.iconName;

                                    const Icon = createIconStringFromName(iconName);

                                    if (isSubscribed && !category?.subscriptionCategorizationSupport) {
                                        return null;
                                    }

                                    if (hasMultipleArticles
                                        && (!category?.multipleArticles
                                            || category?.subscriptionCategorizationSupport)
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <DesktopPopularCategory
                                            key={iconName}
                                            Icon={Icon}
                                            name={category?.[language]?.categoryName}
                                            onClick={() => {
                                                onCategorySelect(category);
                                            }}
                                        />
                                    );
                                })}
                            </>
                        );
                    })}
                </>
            )}

            {side === TransactionSide.CREDIT && mostUsedCreditCategoryList && (
                <>
                    {mostUsedCreditCategoryList?.sourcesList?.map(category => {
                        const revenue = category?.sourceOfRevenueGroupItems?.[0];

                        const iconName = mostUsedCreditCategoryList
                            .sourcesObject?.[revenue.sourceOfRevenue]?.iconName;

                        const Icon = createIconStringFromName(iconName);

                        return (
                            <DesktopPopularCategory
                                key={iconName}
                                Icon={Icon}
                                name={revenue?.[language]?.categoryName}
                                onClick={() => {
                                    onCategorySelect(revenue?.sourceOfRevenue);
                                }}
                            />
                        );
                    })}
                </>
            )}
        </Box>
    );
};

PopularCategoriesSection.propTypes = {
    onCategorySelect: PropTypes.func.isRequired,
    side: PropTypes.string.isRequired,
    isSubscribed: PropTypes.bool,
    hasMultipleArticles: PropTypes.bool,
};

PopularCategoriesSection.defaultProps = {
    isSubscribed: false,
    hasMultipleArticles: false,
};

export default PopularCategoriesSection;
