import {combineReducers} from 'redux';
import {BackofficeActionTypes} from './backoffice.action-type';
import {createReducer} from '../../../v1/app/createReducer';
import {QUICK_FILTERS_DEFAULT} from '../utils/constants';

export const backofficeReducer = combineReducers({
    ids: createReducer([], BackofficeActionTypes.STORE_COMPANIES_IDS, false),
    entities: createReducer({}, BackofficeActionTypes.STORE_COMPANIES_ENTITIES, false),
    shortFilterCount: createReducer(QUICK_FILTERS_DEFAULT, BackofficeActionTypes.STORE_SHORT_FILTER_COUNT, false),
    totalCount: createReducer(0, BackofficeActionTypes.STORE_TOTAL_COUNT, false),
    kbisDocument: createReducer({}, BackofficeActionTypes.STORE_KBIS_DOCUMENTS, true),
    legalNoticeDocument: createReducer({}, BackofficeActionTypes.STORE_LEGAL_NOTICE_DOCUMENT, true),
    isRegistrationSaved: createReducer(false, BackofficeActionTypes.SET_IS_REGISTRATION_STATUS_SAVED, true),
});
