import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import PdfIcon from '../../../../../../v1/components/icons/PdfIcon';

const StatementsName = ({rowData}) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'row', py: 2}}>
            <PdfIcon sx={{width: 16}} />
            <Typography sx={{ml: 1}} variant="body2">
                {rowData.statementName}
            </Typography>
        </Box>
    );
};

StatementsName.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default StatementsName;
