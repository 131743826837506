import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';
import {useRoutes} from 'react-router-dom';
import InitialAnimation from './features/animations/components/InitialAnimation';
import {NotificationsContainer} from './features/notifications/components/NotificationProvider';
import {LoggedInUserSelectors} from './features/user/modules/logged-in-user';
import {Routes} from './lib/router/routes';
import ScrollToTop from './utils/scroll-to-top';
import {UserRoles, isUserCareOrFreelancer} from './utils/user-roles';
import AppContent from './v1/components/app/AppContent/AppContent';
import ErrorBoundary from './v1/components/app/ErrorBoundary';
import './assets/fonts/avantt/avantt.css';

const RouteElements = () => {
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    return useRoutes([
        ...Routes.PublicRoutes,
        ...(isUserCareOrFreelancer(loggedInUser) ? Routes.FreelancerRoutes(loggedInUser) : []),
        ...(loggedInUser?.role === UserRoles.ADMINISTRATOR
            ? [
                ...Routes.AdministratorRoutes,
                ...Routes.DepositOfAccountsRoutes,
            ]
            : []
        ),
    ]);
};

export const App = () => {
    return (
        <>
            <Helmet>
                <title>HIWAY Platform</title>
            </Helmet>
            <ScrollToTop />
            <NotificationsContainer />
            <ErrorBoundary>
                <InitialAnimation>
                    <AppContent>
                        <RouteElements />
                    </AppContent>
                </InitialAnimation>
            </ErrorBoundary>
        </>
    );
};
