import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getPickersArrowSwitcherUtilityClass = slot => {
    return generateUtilityClass('MuiPickersArrowSwitcher', slot);
};

export const pickersArrowSwitcherClasses = generateUtilityClasses(
    'MuiPickersArrowSwitcher',
    ['root', 'spacer', 'button'],
);
