import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {importLocaleBundle} from '../../lib/i18next';

importLocaleBundle('dropzone');

export const fileErrorCodes = {
    'file-too-large': 'file-too-large',
};

export const BANK_DROPZONE_MAX_FILES = {
    TRANSACTION: 50,
};

export const BANK_DROPZONE_FILE_SIZES = {
    TRANSACTION: 10485760, // 10 Mb
};

export const BankDropZone = ({
    fileTypes,
    maxFiles,
    maxSize,
    onChange,
    isFileUploading,
    onDropRejectedHandler,
    isDisabled,
    isInCategorisation,
    children,
}) => {
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
    } = useDropzone({
        maxFiles: maxFiles,
        accept: fileTypes,
        maxSize: maxSize,
        onDropRejected: onDropRejectedHandler,
    });

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            onChange(acceptedFiles);
        }
    }, [acceptedFiles, onChange]);

    useEffect(() => {
        if (acceptedFiles.length > 0 && !isFileUploading) {
            acceptedFiles.splice(0, acceptedFiles.length);
        }
        // eslint-disable-next-line
    }, [isFileUploading]);

    if (isDisabled) {
        if (isInCategorisation) {
            return (
                <>
                    {children}
                </>
            );
        }

        return (
            <Box sx={{width: '100%'}}>
                {children}
            </Box>
        );
    }

    return (
        <Box
            sx={isInCategorisation ? {flex: '1 0 0'} : {width: '100%'}}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            {children}
        </Box>
    );
};

BankDropZone.propTypes = {
    fileTypes: PropTypes.string.isRequired,
    maxFiles: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    isUploadInProgress: PropTypes.bool,
    maxSize: PropTypes.number,
    isFileUploading: PropTypes.bool,
    isInfoTextVisible: PropTypes.bool,
    files: PropTypes.array,
    isDisabled: PropTypes.bool,
    onDropRejectedHandler: PropTypes.func,
    isInCategorisation: PropTypes.bool,
};

BankDropZone.defaultProps = {
    isUploadInProgress: false,
    maxSize: 10485760, // 10 MB
    isInfoTextVisible: false,
    files: [],
    isDisabled: false,
    onDropRejectedHandler: () => {},
    isFileUploading: false,
    isInCategorisation: false, // TODO: delete this param, it should be true always
};
