import {OpportunityActionTypes} from './opportunity.action-type';

export const OpportunitiesActions = {
    getFreelancerOpportunityCluster: () => ({
        type: OpportunityActionTypes.GET_FREELANCER,
    }),
    storeOpportunityCluster: data => ({
        type: OpportunityActionTypes.STORE_CLUSTER,
        payload: data,
    }),
    submitOpportunityCluster: ({freelancerId, data}) => ({
        type: OpportunityActionTypes.SUBMIT,
        payload: {freelancerId, data},
    }),
    storeOpportunityList: data => ({
        type: OpportunityActionTypes.STORE_LIST,
        payload: data,
    }),
    storeOtherOpportunityList: data => ({
        type: OpportunityActionTypes.STORE_OTHER_LIST,
        payload: data,
    }),
    storeMyBidsList: data => ({
        type: OpportunityActionTypes.STORE_MY_BIDS_LIST,
        payload: data,
    }),
    storeFreelancerSpecialisations: data => ({
        type: OpportunityActionTypes.STORE_SPECIALISATIONS,
        payload: data,
    }),
    searchFreelancer: (text, limit) => ({
        type: OpportunityActionTypes.SEARCH_FREELANCER,
        payload: {text, limit},
    }),
    storeSearch: data => ({
        type: OpportunityActionTypes.STORE_SEARCH,
        payload: data,
    }),
    applyAsAdmin: ({userId, opportunityId}) => ({
        type: OpportunityActionTypes.APPLY_AS_ADMIN,
        payload: {userId, opportunityId},
    }),
    applyFromClusterPage: ({opportunityId}) => ({
        type: OpportunityActionTypes.APPLY_FROM_CLUSTER_PAGE,
        payload: {opportunityId},
    }),
    unapply: ({opportunityId}) => ({
        type: OpportunityActionTypes.UNAPPLY,
        payload: {opportunityId},
    }),
    toggleAccept: data => ({
        type: OpportunityActionTypes.TOGGLE_ACCEPT,
        payload: {
            freelancerId: data.freelancerId,
            opportunityId: data.opportunityId,
            bidId: data.bidId,
            isTurningOn: data.isTurningOn,
        },
    }),
};
