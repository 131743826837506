import {
    faPaperclip,
    faWarning,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    Box,
    Button,
    Chip,
    ChipDelete,
    CircularProgress,
    Stack,
    Typography,
} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {default as ReactDropZone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {Toast} from '../../../lib/toast';

export const MaxFileSize = {
    IDDocumentsMaxFileSize: 6291456, // 6MB // (6 * 1024 * 1024)
    IDDocumentsMaxFileSizeMb: 6,
};

const DropZone = props => {
    const {t} = useTranslation('common');

    const handleDropAccepted = useCallback((acceptedFiles, event) => {
        if (!props.isUploadInProgress && props.onDropAccepted && typeof props.onDropAccepted === 'function') {
            props.onDropAccepted(acceptedFiles, event);
        }
    }, [props]);

    const handleDropRejected = useCallback((rejectedFiles, event) => {
        if (props.onDropRejected && typeof props.onDropRejected === 'function') {
            props.onDropRejected(rejectedFiles, event);
        }

        if (rejectedFiles.length) {
            // TODO Use constants from src/utils/constants.js
            const hasTooManyFiles = rejectedFiles[0]?.errors[0]?.code === 'too-many-files';
            const fileTooLarge = rejectedFiles[0]?.errors[0]?.code === 'file-too-large';

            if (hasTooManyFiles) {
                Toast.error('tooManyFiles');

                return;
            }

            if (fileTooLarge) {
                Toast.error('fileTooLargeCustom', {
                    translationValues: {
                        limit: MaxFileSize.IDDocumentsMaxFileSizeMb,
                    },
                });

                return;
            }
        }

        Toast.error('fileRejected');
    }, [props]);

    const handleDeleteFile = useCallback(fileName => event => {
        event.stopPropagation();

        if (props.isFileRemovalDisabled) {
            Toast.info('fileRemovalDisabled');
            return;
        }

        if (props.onDeleteFile && typeof props.onDeleteFile === 'function') {
            props.onDeleteFile(fileName, event);
        }
    }, [props]);

    const additionalProps = {};

    if (props.isMultiple && props.fileLimit > 0) {
        additionalProps.maxFiles = props.fileLimit;
    }

    if (props.maxSize) {
        additionalProps.maxSize = props.maxSize;
    }

    return (
        <>
            <ReactDropZone
                accept={props.acceptFiles}
                onDropAccepted={handleDropAccepted}
                onDropRejected={handleDropRejected}
                multiple={props.isMultiple}
                disabled={props.isDisabled || props.isUploadInProgress}
                {...additionalProps}
                validator={value => {
                    const length = value.length ?? 1;

                    return value
                        && additionalProps.maxFiles
                        && (props.files.length + length) > additionalProps.maxFiles
                        ? {code: 'too-many-files', message: 'Max number of files exceeded'} : null;
                }}
            >
                {({getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept}) => {
                    return (
                        <Box
                            {...getRootProps()}
                            sx={{
                                p: 2,
                                backgroundColor: 'neutral.softBg',
                                borderRadius: 'sm',
                                border: '1px dashed',
                                borderColor: (isDragReject || !!props.errorMessage)
                                    ? 'danger.50'
                                    : isDragAccept
                                        ? 'success.softBg'
                                        : isDragActive
                                            ? 'neutral.softActiveBg'
                                            : 'divider',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                ...(props.isDisabled ? {
                                    opacity: 0.5,
                                } : {}),

                            }}
                            data-cypress={props.name}
                        >
                            {props.isUploadInProgress ? (
                                <Stack alignItems="center" spacing={0.5}>
                                    <CircularProgress variant="solid" color="neutral" sx={{'--_root-size': '48px'}} />
                                </Stack>
                            ) : (
                                <>
                                    <input {...getInputProps()} disabled={props.isDisabled} />

                                    <Button
                                        variant="solid"
                                        color="neutral"
                                        size="sm"
                                        disabled={props.isDisabled}
                                        startDecorator={(
                                            <FontAwesomeIcon icon={faPaperclip} />
                                        )}
                                    >
                                        {props.buttonText || t('dropZone.attachFile')}
                                    </Button>

                                    {props.dropZoneCustomText ?? (
                                        <>
                                            {props.dropZoneText !== '' && (
                                                <Typography level="body-md" textColor="text.tertiary" mt={1}>
                                                    {props.dropZoneText || t('dropZone.dragAndDrop')}
                                                </Typography>
                                            )}
                                            {((!props.isMultiple || (props.fileLimit !== 1 && props.fileLimit !== 0))
                                                || props.hasMaxFileInfoForced)
                                            && (
                                                <Typography level="body-md" textColor="text.tertiary">
                                                    {props.fileLimit !== 1
                                                        ? t('dropZone.limitedNumberOfFiles', {numberOfFiles: props.fileLimit})
                                                        : t('dropZone.maxFiles')}
                                                    {props.readableFileExtension ? ', ' + props.readableFileExtension : ''}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Box>
                    );
                }}
            </ReactDropZone>

            {props.hasChips && !props.isUploadInProgress && (
                <Stack direction="row" gap={1} flexWrap="wrap">
                    {!!props.files && props.files.map(fileObject => (
                        <Chip
                            key={fileObject.id}
                            color="neutral"
                            size="lg"
                            variant="soft"
                            disabled={props.isDisabled}
                            startDecorator={(
                                <FontAwesomeIcon icon={faPaperclip} />
                            )}
                            endDecorator={props.isFileRemovalDisabled ? null : (
                                <ChipDelete onDelete={handleDeleteFile(fileObject.name)} />
                            )}
                            sx={{maxWidth: '220px'}}
                        >
                            {fileObject.name}
                        </Chip>
                    ))}
                </Stack>
            )}

            {!!props.errorMessage && (
                <Stack alignItems="center" direction="row" spacing={0.5}>
                    <Box fontSize="md" color="text.icon">
                        <FontAwesomeIcon icon={faWarning} />
                    </Box>
                    <Typography level="body-md" textColor="danger.plainColor" textAlign="center" mt={1}>
                        {props.errorMessage}
                    </Typography>
                </Stack>
            )}
        </>
    );
};

DropZone.propTypes = {
    name: PropTypes.string.isRequired,
    onDropRejected: PropTypes.func,
    onDropAccepted: PropTypes.func,
    onDeleteFile: PropTypes.func,
    acceptFiles: PropTypes.string,
    buttonText: PropTypes.string,
    dropZoneText: PropTypes.string,
    errorMessage: PropTypes.string,
    readableFileExtension: PropTypes.string,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    hasChips: PropTypes.bool,
    isMultiple: PropTypes.bool,
    isFileRemovalDisabled: PropTypes.bool,
    fileLimit: PropTypes.number,
    files: PropTypes.arrayOf(PropTypes.instanceOf(File)),
    hasMaxFileInfoForced: PropTypes.bool,
    dropZoneCustomText: PropTypes.object,
    isUploadInProgress: PropTypes.bool,
    maxSize: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

DropZone.defaultProps = {
    onDropRejected: null,
    onDropAccepted: null,
    onDeleteFile: null,
    acceptFiles: null,
    buttonText: null,
    dropZoneText: null,
    errorMessage: null,
    className: '',
    readableFileExtension: '',
    isDisabled: false,
    hasChips: true,
    isMultiple: false,
    isFileRemovalDisabled: false,
    fileLimit: 1,
    files: [],
    hasMaxFileInfoForced: false,
    dropZoneCustomText: null,
    isUploadInProgress: false,
    maxSize: null,
};

export default DropZone;
