import {
    faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';

export const ErrPageButton = ({
    label,
    icon,
    iconPosition,
}) => (
    <Button
        type="submit"
        color="primary"
        variant="outlined"
        size="md"
        tabIndex={1}
        startDecorator={iconPosition === 'start' ? (
            <FontAwesomeIcon icon={icon ?? faRotateLeft} />
        ) : null}
        endDecorator={iconPosition === 'end' ? (
            <FontAwesomeIcon icon={icon ?? faRotateLeft} />
        ) : null}
        sx={{
            m: {desktop: 8, mobile: 4},
            mb: {desktop: 12, mobile: 8},
        }}
    >
        {label}
    </Button>
);

ErrPageButton.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.any,
    iconPosition: PropTypes.oneOf(['start', 'end']),
};

ErrPageButton.defaultProps = {
    icon: null,
    iconPosition: 'start',
};
