import {Pagination} from '@mui/lab';
import {Box, Paper, TableSortLabel} from '@mui/material';
import PropTypes from 'prop-types';
import {stringify} from 'query-string';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AdminOpportunityListItem} from './AdminOpportunityListItem';
import {AdminOpportunityListItemSkeleton} from './AdminOpportunityListItemSkeleton';
import {replace} from '../../../../lib/router/connected-router-saga';
import {getSearchParameters} from '../../../invoicing/util/functions';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {OpportunitySelector} from '../../store/opportunity.selector';
import {
    ADMIN_OPPORTUNITY_GRID_TEMPLATES,
    CLUSTER_SORT_KEYS,
    CLUSTER_TABLE_DEFAULT,
    CLUSTER_TABLE_DEFAULT_SORT_ORDER,
} from '../../utls/constants';

export const AdminOpportunityList = () => {
    const queryParams = getSearchParameters();
    const page = Number(queryParams['cluster-page']) || 1;
    const sortBy = decodeURIComponent(queryParams['cluster-sortBy'] || '');
    const sortDirection = decodeURIComponent(queryParams['cluster-sortDirection'] || '');

    const {t} = useTranslation('opportunity');
    const dispatch = useDispatch();

    const isGetInProgress = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GET_OPPORTUNITY),
    );

    const opportunityList = useSelector(OpportunitySelector.getOpportunityIds);

    const {totalRecords} = useSelector(OpportunitySelector.getClusters);

    const onPaginationChange = newPage => {
        const params = {'cluster-page': newPage};

        if (sortBy) {
            params['cluster-sortBy'] = sortBy;
        }

        if (sortDirection) {
            params['cluster-sortDirection'] = sortDirection;
        }

        dispatch(replace({
            search: stringify(params),
        }));
    };

    const onSortChange = newSortBy => {
        let newSortDirection = '';
        let params = {};

        if (newSortBy === sortBy) {
            if (sortDirection === 'desc') {
                newSortDirection = CLUSTER_TABLE_DEFAULT_SORT_ORDER[newSortBy] === 'asc' ? '' : 'asc';
            } else if (sortDirection === 'asc') {
                newSortDirection = CLUSTER_TABLE_DEFAULT_SORT_ORDER[newSortBy] === 'asc' ? 'desc' : '';
            } else {
                newSortDirection = CLUSTER_TABLE_DEFAULT_SORT_ORDER[newSortBy] === 'asc' ? 'asc' : 'desc';
            }
        } else {
            newSortDirection = CLUSTER_TABLE_DEFAULT_SORT_ORDER[newSortBy];
        }

        if (newSortDirection) {
            params = {
                'cluster-sortBy': newSortBy,
                'cluster-sortDirection': newSortDirection,
            };
        }

        if (page && newSortBy === sortBy) {
            params['cluster-page'] = page;
        }

        dispatch(replace({
            search: stringify(params),
        }));
    };

    return (
        <Box>
            <Header
                sortBy={sortBy}
                sortDirection={sortDirection}
                onSortChange={onSortChange}
            />
            {isGetInProgress && (
                <>
                    <AdminOpportunityListItemSkeleton />
                    <AdminOpportunityListItemSkeleton />
                    <AdminOpportunityListItemSkeleton />
                </>
            )}
            {!isGetInProgress && opportunityList.map(item => {
                return (
                    <AdminOpportunityListItem
                        key={item}
                        id={item}
                    />
                );
            })}
            {!isGetInProgress && opportunityList.length === 0
            && (
                <Paper
                    sx={{
                        borderRadius: 2,
                        p: 4,
                        fontSize: theme => theme.typography.pxToRem(20),
                        mb: 2,
                    }}
                    elevation={1}
                >
                    {t('noData')}
                </Paper>
            )
            }
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Pagination
                    color="primary"
                    count={Math.ceil(totalRecords / CLUSTER_TABLE_DEFAULT.limit)}
                    page={page}
                    onChange={(_, page) => {
                        onPaginationChange(page);
                    }}
                />
            </Box>
        </Box>
    );
};

const Header = ({sortBy, sortDirection, onSortChange}) => {
    const {t} = useTranslation('opportunity');
    const columnStyles = {
        lineHeight: '18px',
    };

    return (
        <Box sx={{
            display: 'grid',
            textAlign: 'center',
            gridTemplateColumns: ADMIN_OPPORTUNITY_GRID_TEMPLATES.HEAD,
            alignItems: 'end',
            mb: 1,
            fontWeight: 600,
            fontSize: theme => theme.typography.pxToRem(11),
            textTransform: 'uppercase',
            color: 'v2.light.text.disabled',
        }}
        >
            <SortLabel
                sx={{textAlign: 'left', pl: 3}}
                columnName={CLUSTER_SORT_KEYS.client_name}
                label={t('element.header.resume')}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onSortChange={onSortChange}
            />
            <SortLabel
                sx={{mx: 'auto'}}
                columnName={CLUSTER_SORT_KEYS.created_at}
                label={t('element.header.date')}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onSortChange={onSortChange}
            />
            <Box sx={columnStyles}>{t('element.header.profile')}</Box>
            <SortLabel
                sx={{mx: 'auto'}}
                columnName={CLUSTER_SORT_KEYS.daily_rate_high}
                label={t('element.header.tjm')}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onSortChange={onSortChange}
            />
            <SortLabel
                sx={{mx: 'auto'}}
                columnName={CLUSTER_SORT_KEYS.start_date}
                label={t('element.header.mission')}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onSortChange={onSortChange}
            />
            <Box sx={columnStyles}>{t('element.header.location')} & {t('element.header.remote')}</Box>
            <Box sx={columnStyles}>{t('element.header.files')}</Box>
            <Box sx={columnStyles}>{t('element.header.number')}</Box>
        </Box>
    );
};

Header.propTypes = {
    sortBy: PropTypes.string.isRequired,
    sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
    onSortChange: PropTypes.func.isRequired,
};

const SortLabel = ({columnName, label, sortBy, sortDirection, onSortChange, sx}) => {
    const direction = sortBy === columnName && sortDirection
        ? sortDirection
        : CLUSTER_TABLE_DEFAULT_SORT_ORDER[columnName];

    const handleSortChange = () => {
        onSortChange(columnName);
    };

    return (
        <TableSortLabel
            sx={sx}
            direction={direction}
            active={sortBy === columnName}
            onClick={handleSortChange}
        >
            {label}
        </TableSortLabel>
    );
};

SortLabel.propTypes = {
    label: PropTypes.func.isRequired,
    columnName: PropTypes.string.isRequired,
    sortBy: PropTypes.string.isRequired,
    sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
    onSortChange: PropTypes.func.isRequired,
    sx: PropTypes.object,
};

SortLabel.defaultProps = {
    sx: {},
};
