import TextField from '@mui/material/TextField';
import React, {useState} from 'react';
import NumberFormat from 'react-number-format';
import {formatToStandardNumber} from '../../utils/number';

export const StandardNumberField = props => {
    const [value, setValue] = useState(props.value || '');
    const handlePaste = event => {
        event.preventDefault();
        const input = (event.clipboardData || window.clipboardData).getData('text');
        const formatted = formatToStandardNumber(input);
        setValue(formatted);
    };

    const handleChange = event => {
        setValue(event.target.value);
    };

    const handleValueChange = values => {
        props.onChange?.(values.value);
    };

    return (
        <NumberFormat
            customInput={props.customInput}
            decimalSeparator="."
            thousandSeparator={false}
            allowedDecimalSeparators={[',']}
            {...props}
            value={value}
            onPaste={handlePaste}
            onChange={handleChange}
            onValueChange={handleValueChange}
        />
    );
};

StandardNumberField.propTypes = {
    ...TextField.propTypes,
    ...NumberFormat.propTypes,

};
StandardNumberField.defaultProps = {
    ...TextField.defaultProps,
    ...NumberFormat.defaultProps,
    customInput: TextField,
};
