import {BoTrainingActionTypes} from './bo-training.action-type';

export const BoTrainingActions = {
    storeTrainingInfo: data => ({
        type: BoTrainingActionTypes.STORE_TRAINING_INFO,
        payload: data,
    }),
    saveTrainingInfo: data => ({
        type: BoTrainingActionTypes.SAVE_TRAINING_INFO,
        payload: data,
    }),
};
