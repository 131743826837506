import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import {Box, IconButton, Paper} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {ConditionalTooltip} from '../../../../components/tooltip/ConditionalTooltip';

export const ListBidItem = ({element, onToggle}) => {
    const additionalProps = element.accepted ? {bgcolor: 'rgba(46, 125, 50, 0.12)'} : {};

    return (
        <Paper
            elevation={1}
            sx={{
                borderRadius: 4,
                height: '36px',
                py: 0.4,
                px: 2,
                display: 'inline-flex',
                mr: 1,
                mb: 1,
                ...additionalProps,
            }}
        >
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box sx={{mr: 1}}>{element.firstName} {element.lastName}</Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <IconButton
                        component="a"
                        variant="text"
                        href={`tel: ${element.phone}`}
                    >
                        <ConditionalTooltip tooltip={element.phone}>
                            <PhoneIcon sx={{
                                'color': 'v2.light.action.active',
                                'cursor': 'pointer',
                                'fontSize': '16px',
                                '&:hover': {
                                    color: 'primary_v2.main',
                                },
                            }}
                            />
                        </ConditionalTooltip>
                    </IconButton>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <IconButton
                        component="a"
                        variant="text"
                        href={`mailto: ${element.email}`}
                    >
                        <ConditionalTooltip tooltip={element.email}>
                            <EmailIcon
                                sx={{
                                    'color': 'v2.light.action.active',
                                    'cursor': 'pointer',
                                    'fontSize': '16px',
                                    '&:hover': {
                                        color: 'primary_v2.main',
                                    },
                                }}
                            />
                        </ConditionalTooltip>
                    </IconButton>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <IconButton
                        variant="text"
                        onClick={
                            () => {
                                onToggle(element.bidId, element.freelancerId, !element.accepted);
                            }
                        }
                    >
                        <ConditionalTooltip tooltip="Attribuer">
                            <ThumbUpAltIcon sx={{
                                'color': element.accepted ? 'success_v2.light' : 'v2.light.action.active',
                                'cursor': 'pointer',
                                'fontSize': '16px',
                                '&:hover': {
                                    color: element.accepted ? 'success_v2.light' : 'primary_v2.main',
                                },
                            }}
                            />
                        </ConditionalTooltip>
                    </IconButton>
                </Box>
            </Box>
        </Paper>
    );
};

ListBidItem.propTypes = {
    element: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
};
