/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/require-default-props */
import {generateUtilityClass} from '@mui/base';
import composeClasses from '@mui/base/composeClasses';
import {useTheme} from '@mui/styles';
import {styled, useThemeProps} from '@mui/system';
import {
    elementTypeAcceptingRef,
    unstable_useForkRef as useForkRef,
} from '@mui/utils';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';
import {Transition} from 'react-transition-group';

import {getTransitionProps} from './utils';

const getCollapseUtilityClass = slot => {
    return generateUtilityClass('MuiCollapse', slot);
};

const useUtilityClasses = ownerState => {
    const {orientation, classes} = ownerState;

    const slots = {
        root: ['root', `${orientation}`],
        entered: ['entered'],
        hidden: ['hidden'],
        wrapper: ['wrapper', `${orientation}`],
        wrapperInner: ['wrapperInner', `${orientation}`],
    };

    return composeClasses(slots, getCollapseUtilityClass, classes);
};

const CollapseRoot = styled('div', {
    name: 'MuiCollapse',
    slot: 'Root',
    overridesResolver: (props, styles) => {
        const {ownerState} = props;

        return [
            styles.root,
            styles[ownerState.orientation],
            ownerState.state === 'entered' && styles.entered,
            ownerState.state === 'exited'
                && !ownerState.in
                && ownerState.collapsedSize === '0px'
                && styles.hidden,
        ];
    },
})(({theme, ownerState}) => ({
    height: 0,
    overflow: 'hidden',
    transition: theme.transitions.create('height'),
    ...(ownerState.orientation === 'horizontal' && {
        height: 'auto',
        width: 0,
        transition: theme.transitions.create('width'),
    }),
    ...(ownerState.state === 'entered' && {
        height: 'auto',
        overflow: 'visible',
        ...(ownerState.orientation === 'horizontal' && {
            width: 'auto',
        }),
    }),
    ...(ownerState.state === 'exited'
        && !ownerState.in
        && ownerState.collapsedSize === '0px'
        && {
            visibility: 'hidden',
        }),
}));

const CollapseWrapper = styled('div', {
    name: 'MuiCollapse',
    slot: 'Wrapper',
    overridesResolver: (_, styles) => styles.wrapper,
})(({ownerState}) => ({
    // Hack to get children with a negative margin to not falsify the height computation.
    display: 'flex',
    width: '100%',
    ...(ownerState.orientation === 'horizontal' && {
        width: 'auto',
        height: '100%',
    }),
}));

const CollapseWrapperInner = styled('div', {
    name: 'MuiCollapse',
    slot: 'WrapperInner',
    overridesResolver: (_, styles) => styles.wrapperInner,
})(({ownerState}) => ({
    width: '100%',
    ...(ownerState.orientation === 'horizontal' && {
        width: 'auto',
        height: '100%',
    }),
}));

/**
 * The Collapse transition is used by the
 * [Vertical Stepper](/components/steppers/#vertical-stepper) StepContent component.
 * It uses [react-transition-group](https://github.com/reactjs/react-transition-group) internally.
 */
const Collapse = React.forwardRef((inProps, ref) => {
    const props = useThemeProps({props: inProps, name: 'MuiCollapse'});
    const theme = useTheme();
    const {
        addEndListener,
        children,
        className,
        collapsedSize: collapsedSizeProp = '0px',
        component,
        easing,
        in: inProp,
        onEnter,
        onEntered,
        onEntering,
        onExit,
        onExited,
        onExiting,
        orientation = 'vertical',
        style,
        timeout = theme.transitions.duration.standard,
        // eslint-disable-next-line react/prop-types
        TransitionComponent = Transition,
        ...other
    } = props;

    const ownerState = {
        ...props,
        orientation,
        collapsedSize: collapsedSizeProp,
    };

    const classes = useUtilityClasses(ownerState);
    const timer = React.useRef();
    const wrapperRef = React.useRef(null);
    const autoTransitionDuration = React.useRef();
    const collapsedSize = typeof collapsedSizeProp === 'number'
        ? `${collapsedSizeProp}px`
        : collapsedSizeProp;
    const isHorizontal = orientation === 'horizontal';
    const size = isHorizontal ? 'width' : 'height';

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const nodeRef = React.useRef(null);
    const handleRef = useForkRef(ref, nodeRef);

    const normalizedTransitionCallback = callback => maybeIsAppearing => {
        if (callback) {
            const node = nodeRef.current;

            // onEnterXxx and onExitXxx callbacks have a different arguments.length value.
            if (maybeIsAppearing === undefined) {
                callback(node);
            } else {
                callback(node, maybeIsAppearing);
            }
        }
    };

    const getWrapperSize = () => (wrapperRef.current
        ? wrapperRef.current[isHorizontal ? 'clientWidth' : 'clientHeight']
        : 0);

    const handleEnter = normalizedTransitionCallback((node, isAppearing) => {
        if (wrapperRef.current && isHorizontal) {
        // Set absolute position to get the size of collapsed content
            wrapperRef.current.style.position = 'absolute';
        }
        node.style[size] = collapsedSize;

        if (onEnter) {
            onEnter(node, isAppearing);
        }
    });

    const handleEntering = normalizedTransitionCallback((node, isAppearing) => {
        const wrapperSize = getWrapperSize();

        if (wrapperRef.current && isHorizontal) {
        // After the size is read reset the position back to default
            wrapperRef.current.style.position = '';
        }

        const {duration: transitionDuration, easing: transitionTimingFunction} = getTransitionProps(
            {style, timeout, easing},
            {
                mode: 'enter',
            },
        );

        if (timeout === 'auto') {
            const duration2 = theme.transitions.getAutoHeightDuration(wrapperSize);
            node.style.transitionDuration = `${duration2}ms`;
            autoTransitionDuration.current = duration2;
        } else {
            node.style.transitionDuration = typeof transitionDuration === 'string'
                ? transitionDuration
                : `${transitionDuration}ms`;
        }

        node.style[size] = `${wrapperSize}px`;
        node.style.transitionTimingFunction = transitionTimingFunction;

        if (onEntering) {
            onEntering(node, isAppearing);
        }
    });

    const handleEntered = normalizedTransitionCallback((node, isAppearing) => {
        node.style[size] = 'auto';

        if (onEntered) {
            onEntered(node, isAppearing);
        }
    });

    const handleExit = normalizedTransitionCallback(node => {
        node.style[size] = `${getWrapperSize()}px`;

        if (onExit) {
            onExit(node);
        }
    });

    const handleExited = normalizedTransitionCallback(onExited);

    const handleExiting = normalizedTransitionCallback(node => {
        const wrapperSize = getWrapperSize();
        const {duration: transitionDuration, easing: transitionTimingFunction} = getTransitionProps(
            {style, timeout, easing},
            {
                mode: 'exit',
            },
        );

        if (timeout === 'auto') {
            // TODO: rename getAutoHeightDuration to something more generic (width support)
            // Actually it just calculates animation duration based on size
            const duration2 = theme.transitions.getAutoHeightDuration(wrapperSize);
            node.style.transitionDuration = `${duration2}ms`;
            autoTransitionDuration.current = duration2;
        } else {
            node.style.transitionDuration = typeof transitionDuration === 'string'
                ? transitionDuration
                : `${transitionDuration}ms`;
        }

        node.style[size] = collapsedSize;
        node.style.transitionTimingFunction = transitionTimingFunction;

        if (onExiting) {
            onExiting(node);
        }
    });

    const handleAddEndListener = next => {
        if (timeout === 'auto') {
            timer.current = setTimeout(next, autoTransitionDuration.current || 0);
        }
        if (addEndListener) {
            // Old call signature before `react-transition-group` implemented `nodeRef`
            addEndListener(nodeRef.current, next);
        }
    };

    return (
        <TransitionComponent
            in={inProp}
            onEnter={handleEnter}
            onEntered={handleEntered}
            onEntering={handleEntering}
            onExit={handleExit}
            onExited={handleExited}
            onExiting={handleExiting}
            addEndListener={handleAddEndListener}
            nodeRef={nodeRef}
            timeout={timeout === 'auto' ? null : timeout}
            {...other}
        >
            {(state, childProps) => (
                <CollapseRoot
                    as={component}
                    className={clsx(
                        classes.root,
                        {
                            [classes.entered]: state === 'entered',
                            [classes.hidden]: state === 'exited' && !inProp && collapsedSize === '0px',
                        },
                        className,
                    )}
                    style={{
                        [isHorizontal ? 'minWidth' : 'minHeight']: collapsedSize,
                        ...style,
                    }}
                    ownerState={{...ownerState, state}}
                    ref={handleRef}
                    {...childProps}
                >
                    <CollapseWrapper
                        ownerState={{...ownerState, state}}
                        className={classes.wrapper}
                        ref={wrapperRef}
                    >
                        <CollapseWrapperInner
                            ownerState={{...ownerState, state}}
                            className={classes.wrapperInner}
                        >
                            {children}
                        </CollapseWrapperInner>
                    </CollapseWrapper>
                </CollapseRoot>
            )}
        </TransitionComponent>
    );
});

Collapse.propTypes /* remove-proptypes */ = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * Add a custom transition end trigger. Called with the transitioning DOM
     * node and a done callback. Allows for more fine grained transition end
     * logic. Note: Timeouts are still used as a fallback if provided.
     */
    addEndListener: PropTypes.func,
    /**
     * The content node to be collapsed.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The width (horizontal) or height (vertical) of the container when collapsed.
     * @default '0px'
     */
    collapsedSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: elementTypeAcceptingRef,
    /**
     * The transition timing function.
     * You may specify a single easing or a object containing enter and exit values.
     */
    easing: PropTypes.oneOfType([
        PropTypes.shape({
            enter: PropTypes.string,
            exit: PropTypes.string,
        }),
        PropTypes.string,
    ]),
    /**
     * If `true`, the component will transition in.
     */
    in: PropTypes.bool,
    /**
     * @ignore
     */
    onEnter: PropTypes.func,
    /**
     * @ignore
     */
    onEntered: PropTypes.func,
    /**
     * @ignore
     */
    onEntering: PropTypes.func,
    /**
     * @ignore
     */
    onExit: PropTypes.func,
    /**
     * @ignore
     */
    onExited: PropTypes.func,
    /**
     * @ignore
     */
    onExiting: PropTypes.func,
    /**
     * The transition orientation.
     * @default 'vertical'
     */
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    /**
     * @ignore
     */
    style: PropTypes.object,
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
        PropTypes.func,
        PropTypes.object,
    ]),
    /**
     * The duration for the transition, in milliseconds.
     * You may specify a single timeout for all transitions, or individually with an object.
     *
     * Set to 'auto' to automatically calculate transition time based on height.
     * @default duration.standard
     */
    timeout: PropTypes.oneOfType([
        PropTypes.oneOf(['auto']),
        PropTypes.number,
        PropTypes.shape({
            appear: PropTypes.number,
            enter: PropTypes.number,
            exit: PropTypes.number,
        }),
    ]),
};

Collapse.muiSupportAuto = true;

export default Collapse;
