import * as Yup from 'yup';
import globalLocale from 'yup/lib/locale';

const ibantools = require('ibantools');

// TODO: Inject custom validation functions here
Yup.addMethod(Yup.string, 'phone', function () {
    return this.test('phone', globalLocale.string.phone.invalidPhone, value => {
        return /\d{2} \d{2} \d{2} \d{2} \d{2}/.test(value);
    });
});

Yup.addMethod(Yup.string, 'email', function () {
    return this.test('email', globalLocale.string.email.invalidEmail, value => {
        return /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(value);
    });
});

Yup.addMethod(Yup.boolean, 'onlyTrue', function () {
    return this.test('onlyTrue', '', value => {
        return value === true;
    });
});

Yup.addMethod(Yup.string, 'iban', function (message) {
    return this.test('iban', message, value => {
        if (value === null || value === '') {
            return true;
        }

        const ibanObject = ibantools.extractIBAN(value);

        if (!ibanObject?.valid) {
            return false;
        }

        return ibanObject.valid;
    });
});

Yup.addMethod(Yup.string, 'bic', function (message) {
    return this.test('bic', message, value => {
        if (value === null || value === '') {
            return true;
        }
        return ibantools.isValidBIC(value);
        // return /^[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3})?$/.test(value);
    });
});

Yup.addMethod(Yup.string, 'hiwayAddress', function (message) {
    return this.test('hiwayAddress', message, value => {
        return value.trim().toLowerCase() !== 'paris';
    });
});

Yup.addMethod(Yup.number, 'nullOrPositive', function (message) {
    return this.test('nullOrPositive', message, value => {
        return !value || value > 0;
    });
});

Yup.addMethod(Yup.string, 'noSpecialCharactersInUserName', function (message) {
    return this.test(
        'noSpecialCharactersInUserName',
        message,
        value => !/[():;,/!?*_"'&@#%[\]{}\\+^$=|<>]/.test(value),
    );
});

Yup.addMethod(Yup.string, 'vat', function (regexp, message, vatLabel = '', t = null, len = 0) {
    const vatSchema = this.matches(regexp, {
        excludeEmptyString: true,
        message,
    });

    if (len) {
        return vatSchema.length(len, t('form:validation/v2.string/length', {
            label: vatLabel,
            length: len,
        }));
    }
    return vatSchema;
});

Yup.addMethod(Yup.string, 'siret', function (regexp, message) {
    return this.matches(regexp, message);
});

// Add support for localized yup errors
export const createYupLocale = t => {
    Yup.setLocale({
        array: {
            max: params => t('form:validation/v2.array/max', params),
            min: params => t('form:validation/v2.array/min', params),
            length: params => t('form:validation/v2.array/length', params),
        },
        date: {
            max: params => t('form:validation/v2.date/max', params),
            min: params => t('form:validation/v2.date/min', params),
        },
        boolean: {
            isValue: params => t('form:validation/v2.boolean/isValue', params),
        },
        mixed: {
            default: params => t('form:validation/v2.mixed/default', params),
            defined: params => t('form:validation/v2.mixed/defined', params),
            notOneOf: params => t('form:validation/v2.mixed/notOneOf', params),
            notType: params => t('form:validation/v2.mixed/notType', params),
            oneOf: params => t('form:validation/v2.mixed/oneOf', params),
            required: params => t('form:validation/v2.mixed/required', params),
        },
        number: {
            max: params => t('form:validation/v2.number/max', params),
            min: params => t('form:validation/v2.number/min', params),
            integer: params => t('form:validation/v2.number/integer', params),
            lessThan: params => t('form:validation/v2.number/lessThan', params),
            moreThan: params => t('form:validation/v2.number/moreThan', params),
            negative: params => t('form:validation/v2.number/negative', params),
            positive: params => t('form:validation/v2.number/positive', params),
            nullOrPositive: params => t('form:validation/v2.number/positive', params),
        },
        object: {
            noUnknown: params => t('form:validation/v2.object/noUnknown', params),
        },
        string: {
            max: params => t('form:validation/v2.string/max', params),
            min: params => t('form:validation/v2.string/min', params),
            length: params => t('form:validation/v2.string/length', params),
            email: params => t('form:validation/v2.string/email', params),
            lowercase: params => t('form:validation/v2.string/lowercase', params),
            trim: params => t('form:validation/v2.string/trim', params),
            url: params => t('form:validation/v2.string/url', params),
            matches: params => t('form:validation/v2.string/matches', params),
            uuid: params => t('form:validation/v2.string/uuid', params),
            uppercase: params => t('form:validation/v2.string/uppercase', params),
            noSpecialCharactersInUserName: params => t('form:validation/v2.string/noSpecialCharacters', params),
        },
    });

    // TODO: This is going to be supported by yup eventually, but until it does...
    globalLocale.string.phone = {};
    globalLocale.string.phone.invalidPhone = ({label}) => t('form:validation/v2.phone/invalid', {label});

    globalLocale.string.email = {};
    globalLocale.string.email.invalidEmail = ({label}) => t('form:validation/v2.email/invalid', {label});
};

export const yupNumberTransformFunction = lang => (o, v) => {
    let value = v;

    if (typeof value === 'string') {
        if (lang === 'en') {
            // Numbers ie 3,233.22
            if (value.indexOf(',') !== -1 && value.indexOf('.') !== -1) {
                value = value.replace(/,/g, '');
            }

            // Numbers ie 123,233
            if (value.indexOf(',') !== -1) {
                value = value.replace(/,/g, '');
            }
        }

        if (lang === 'fr') {
            // Numbers ie. 333 333
            value = value.replace(/\s/g, '');

            // Numbers ie. 33,23
            if (value.indexOf(',') !== -1) {
                value = value.replace(/,/g, '.');
            }
        }

        value = parseFloat(value);
    }

    return value;
};

export default Yup;
