export const BANK_TYPES = {
    combined: 'combined',
    hiway: 'SWAN_IO',
    bridge: 'BRIDGE_API',
    new_bridge: 'new_bridge',
    archived: 'archived',
};

export const BankIntegrationStatus = {
    IN_PROGRESS: 'IN_PROGRESS',
    ACTIVE: 'ACTIVE',
    ERROR: 'ERROR',
    USER_ACTION_REQUIRED: 'USER_ACTION_REQUIRED',
    COMPANY_VALIDATION_FORM_REQUIRED: 'COMPANY_VALIDATION_FORM_REQUIRED',
    MULTI_FACTOR_AUTHENTICATION_REQUIRED: 'MULTI_FACTOR_AUTHENTICATION_REQUIRED',
    ARCHIVED: 'ARCHIVED',

    // TODO: Cover these under ERROR ?
    // TOKEN_EXPIRED: "TOKEN_EXPIRED",
    // WRONG_CREDENTIALS: "WRONG_CREDENTIALS",
    // INTEGRATION_SERVICE_UNAVAILABLE: "INTEGRATION_SERVICE_UNAVAILABLE",
};

export const BankIntegrationErrorStatuses = [
    BankIntegrationStatus.COMPANY_VALIDATION_FORM_REQUIRED,
    BankIntegrationStatus.MULTI_FACTOR_AUTHENTICATION_REQUIRED,
    BankIntegrationStatus.USER_ACTION_REQUIRED,
    BankIntegrationStatus.ERROR,
    BankIntegrationStatus.IN_PROGRESS,
];

export const BankAccountStatus = {
    ACTIVE: 'ACTIVE',
    SUSPENDED: 'SUSPENDED',
    CLOSING: 'CLOSING',
    CLOSED: 'CLOSED',
    ARCHIVED: 'ARCHIVED',
};

export const BankAccountSyncStatus = {
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    ERROR: 'ERROR',
};

export const BankAccountVerificationStatus = {
    NOT_STARTED: 'NOT_STARTED',
    WAITING_FOR_VERIFICATION: 'WAITING_FOR_VERIFICATION',
    PENDING: 'PENDING',
    VERIFIED: 'VERIFIED',
    REFUSED: 'REFUSED',
};

export const MAX_BRIDGE_CONNECTIONS = 5;

export const BankAccountInitialTransactionSyncStatus = {
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    ERROR: 'ERROR',
};

export const BankConnectionModalType = {
    CONNECT_ANOTHER_BANK: 'CONNECT_ANOTHER_BANK',
    ALL_BANKS_CONNECTED: 'ALL_BANKS_CONNECTED',
};
