export const GET_ASSIGNED_FREELANCERS = 'GET_ASSIGNED_FREELANCERS';
export const STORE_ASSIGNED_FREELANCERS = 'STORE_ASSIGNED_FREELANCERS';
export const SET_IS_LOADING_ASSIGNED_FREELANCERS = 'SET_IS_LOADING_ASSIGNED_FREELANCERS';

export const getAssignedFreelancers = userId => ({
    type: GET_ASSIGNED_FREELANCERS,
    payload: {
        userId,
    },
});

export const storeAssignedFreelancers = assignedFreelancers => ({
    type: STORE_ASSIGNED_FREELANCERS,
    payload: assignedFreelancers,
});

export const setIsLoadingAssignedFreelancers = isLoading => ({
    type: SET_IS_LOADING_ASSIGNED_FREELANCERS,
    payload: isLoading,
});
