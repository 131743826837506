import AddCardIcon from '@mui/icons-material/AddCard';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import {Box, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import GoogleAppleImage from '../../../../assets/svg/google-apple.svg';
import MasterCardImage from '../../../../assets/svg/master-card.svg';

const BoxesSection = () => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    if (!isMobileSize) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <LandingPageInfoBox
                    image={<img src={MasterCardImage} alt="Master card" style={{width: '40px'}} />}
                    text={t('landing.masterCardCreditCard')}
                />
                <LandingPageInfoBox
                    image={(
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <AddCardIcon sx={{fontSize: '33px', color: 'white'}} />
                        </Box>
                    )}
                    text={t('landing.virtualCardsCreation')}
                />
                <LandingPageInfoBox
                    image={<img src={GoogleAppleImage} alt="Google and apple" style={{width: '37px'}} />}
                    text={t('landing.googleAndApple')}
                    maxWidth="100px"
                />
                <LandingPageInfoBox
                    image={(
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <PlaylistAddCheckIcon sx={{fontSize: '38px', color: 'white'}} />
                        </Box>
                    )}
                    text={t('landing.ibanCreation')}
                />
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                <LandingPageInfoBox
                    image={<img src={MasterCardImage} alt="Master card" style={{width: '40px'}} />}
                    text={t('landing.masterCardCreditCard')}
                />
                <LandingPageInfoBox
                    image={(
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <AddCardIcon sx={{fontSize: '33px', color: 'white'}} />
                        </Box>
                )}
                    text={t('landing.virtualCardsCreation')}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    mt: 5,
                }}
            >
                <LandingPageInfoBox
                    image={<img src={GoogleAppleImage} alt="Google and apple" style={{width: '37px'}} />}
                    text={t('landing.googleAndApple')}
                    maxWidth="100px"
                />
                <LandingPageInfoBox
                    image={(
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <PlaylistAddCheckIcon sx={{fontSize: '38px', color: 'white'}} />
                        </Box>
                )}
                    text={t('landing.ibanCreation')}
                />
            </Box>
        </>
    );
};

export const LandingPageInfoBox = ({image, text, maxWidth}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: isMobileSize ? 'column' : 'row',
            textAlign: isMobileSize ? 'center' : 'left',
            minWidth: isMobileSize ? '169px' : 0,
        }}
        >
            <Box sx={{
                background: '#B0BEC5',
                borderRadius: '24px',
                width: '72px',
                height: '72px',
                display: 'flex',
                justifyContent: 'center',
            }}
            >
                {image}
            </Box>
            <Box sx={{
                maxWidth: maxWidth,
                ml: isMobileSize ? 0 : 2,
                mt: isMobileSize ? 1 : 0,
                fontWeight: 600,
                color: 'v2.blueGray.600',
                lineHeight: '21.34px',
            }}
            >
                {text}
            </Box>
        </Box>
    );
};

LandingPageInfoBox.propTypes = {
    image: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    maxWidth: PropTypes.string,
};

LandingPageInfoBox.defaultProps = {
    maxWidth: '130px',
};

export default BoxesSection;
