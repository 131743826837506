import {Box, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch} from 'react-redux';
import PdfIcon from '../../../../assets/svg/pdf.svg';
import {DocumentActions} from '../../../document/store/document.action';

export const OpportunityFileItem = ({file, freelancerId}) => {
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    if (!file) {
        return null;
    }

    const onFileClick = () => {
        if (freelancerId) {
            dispatch(DocumentActions.getClusterDocument(file.id, true, freelancerId, file.company_id));
        } else {
            dispatch(DocumentActions.getDocument(file.id, true));
        }
    };

    const filename = file.filename === 'opportunity_mission_description.pdf' ? 'description.pdf' : file.filename;

    return (
        <Box
            onClick={onFileClick}
            sx={{
                'display': 'flex',
                'textAlign': 'left',
                'cursor': 'pointer',
                'color': '#3779BE',
                'maxWidth': isMobileSize ? '40vw' : '12vw',
                '&:hover': {
                    color: '#7998b9',
                },
            }}
        >
            <img src={PdfIcon} alt="Pdf icon" />

            <Typography
                sx={{
                    'ml': 1,
                    'fontSize': theme => theme.typography.pxToRem(14),
                    'overflow': 'hidden',
                    'whiteSpace': 'nowrap',
                    'textOverflow': 'ellipsis',
                }}
            >
                {filename}
            </Typography>
        </Box>
    );
};

OpportunityFileItem.propTypes = {
    file: PropTypes.object,
    freelancerId: PropTypes.string,
};

OpportunityFileItem.defaultProps = {
    file: null,
    freelancerId: '',
};
