import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../../../components/modal-wrapper/ModalWrapper';

const AreYouSureModal = ({
    onBack,
    onClose,
}) => {
    const {t} = useTranslation('signature');

    const handleBack = () => {
        onBack();
    };

    const handleConfirm = () => {
        onBack();
        onClose();
    };

    return (
        <ModalWrapper
            isForceOpen={true}
            modalKey="are-you-sure-modal"
            handleCloseFnc={handleBack}
            title={(
                <Typography variant="h4">
                    {t('areYouSureModal.title')}
                </Typography>
            )}
            buttons={() => (
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    gap: 3,
                }}
                >
                    <Button
                        sx={{
                            flexGrow: 1,
                        }}
                        disableElevation
                        startIcon={<CloseIcon />}
                        onClick={handleConfirm}
                        variant="contained"
                        color="secondary"
                    >
                        {t('areYouSureModal.confirm')}
                    </Button>

                    <Button
                        sx={{
                            flexGrow: 1,
                        }}
                        onClick={handleBack}
                        endIcon={<ArrowForwardIcon />}
                        color="primary"
                        variant="outlined"
                    >
                        {t('areYouSureModal.cancel')}
                    </Button>
                </Box>
            )}
            containerSx={{
                width: '469px',
                padding: '0 !important',
            }}
            bodySx={{
                p: 5,
            }}
            buttonsSx={{
                px: 0,
            }}
        >
            <Typography>
                {t('areYouSureModal.text')}
            </Typography>
        </ModalWrapper>
    );
};

AreYouSureModal.propTypes = {
    onBack: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default AreYouSureModal;
