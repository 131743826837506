export const TransactionsActionTypes = {
    GET_CATEGORY_LIST: 'transactions/GET_CATEGORY_LIST',
    GET_SOURCES_LIST: 'transactions/GET_SOURCES_LIST',
    GET_TRANSACTION_LIST: 'transactions/GET_TRANSACTION_LIST',
    LOAD_NEXT_TRANSACTION_PAGE: 'transactions/LOAD_NEXT_TRANSACTION_PAGE',
    GET_COMBINED_TRANSACTION_LIST: 'transactions/GET_COMBINED_TRANSACTION_LIST',
    LOAD_NEXT_COMBINED_TRANSACTION_PAGE: 'transactions/LOAD_NEXT_COMBINED_TRANSACTION_PAGE',
    GET_BANK_TRANSACTION: 'transactions/GET_BANK_TRANSACTION',
    SAVE_BANK_ARTICLES: 'transactions/SAVE_BANK_ARTICLES',
    SAVE_BANK_TRANSACTION_FIELD: 'transactions/SAVE_BANK_TRANSACTION_FIELD',
    UPLOAD_TRANSACTION_FILE: 'transactions/UPLOAD_TRANSACTION_FILE',
    DELETE_BANK_FILE: 'transactions/DELETE_BANK_FILE',
    STORE_CATEGORY_LIST: 'transactions/STORE_CATEGORY_LIST',
    STORE_CATEGORY_OBJECT: 'transactions/STORE_CATEGORY_OBJECT',
    STORE_SOURCES_LIST: 'transactions/STORE_SOURCES_LIST',
    STORE_SOURCES_OBJECT: 'transactions/STORE_SOURCES_OBJECT',
    STORE_TRANSACTION_LIST: 'transactions/STORE_TRANSACTION_LIST',
    STORE_COMBINED_TRANSACTION_LIST: 'transactions/STORE_COMBINED_TRANSACTION_LIST',
    STORE_NON_CATEGORIZED_AMOUNT: 'transactions/STORE_NON_CATEGORIZED_AMOUNT',
    SET_TRANSACTION_ALERT: 'transactions/SET_TRANSACTION_ALERT',
    STORE_TRANSACTION_IN_OBJECT: 'transactions/STORE_TRANSACTION_IN_OBJECT',
    UPLOAD_TRANSACTION_FILE_LOADER: 'transactions/UPLOAD_TRANSACTION_FILE_LOADER',
    REPLACE_TRANSACTION: 'transactions/REPLACE_TRANSACTION',
    GET_TOTAL_OVERVEIW: 'transactions/GET_TOTAL_OVERVEIW',
    STORE_TOTAL_OVERVEIW: 'transactions/STORE_TOTAL_OVERVEIW',
    SET_CATEGORIZATION_SCREEN_INFO: 'categorization/SET_CATEGORIZATION_SCREEN_INFO',
    SET_CATEGORIZATION_LIST: 'categorization/SET_CATEGORIZATION_LIST',
    COUNT_CATEGORIZED_IN_SESSION: 'categorizatoin/COUNT_CATEGORIZED_IN_SESSION',
    SET_TOTAL_CATEGORIZED_IN_SESSION: 'categorization/SET_TOTAL_CATEGORIZED_IN_SESSION',
    GET_MORE_UNCATEGORIZED_TRANSACTIONS: 'categorization/GET_MORE_UNCATEGORIZED_TRANSACTIONS',
    GET_MOST_USED_CATEGORIES: 'categories/GET_MOST_USED_CATEGORIES',
    STORE_MOST_USED_CATEGORIES: 'categories/STORE_MOST_USED_CATEGORIES',
    REFUSE_CATEGORIZATION_PROOF_OF_DOCUMENTS: 'categories/REFUSE_CATEGORIZATION_PROOF_OF_DOCUMENTS',
    SET_CATEGORIZATION_SIDEBAR: 'categories/SET_CATEGORIZATION_SIDEBAR',
    SET_CATEGORIZATION_OVERLAY: 'categories/SET_CATEGORIZATION_OVERLAY',
    GET_TRANSACTIONS_MATCHING_SUBSCRIPTION: 'categories/GET_TRANSACTIONS_MATCHING_SUBSCRIPTION',
    STORE_TRANSACTIONS_MATCHING_SUBSCRIPTION: 'categories/STORE_TRANSACTIONS_MATCHING_SUBSCRIPTION',
    GET_ELIGIBLE_TRANSACTIONS_MATCHING_SUBSCRIPTION: 'categories/GET_ELIGIBLE_TRANSACTIONS_MATCHING_SUBSCRIPTION',
    STORE_ELIGIBLE_TRANSACTIONS_MATCHING_SUBSCRIPTION: 'categories/STORE_ELIGIBLE_TRANSACTIONS_MATCHING_SUBSCRIPTION',
    ADD_TO_CATEGORISATION_LIST: 'categorisation/ADD_TO_CATEGORISATION_LIST',
    ADD_SPECIAL_TO_CATEGORISATION_LIST: 'categorisation/ADD_SPECIAL_TO_CATEGORISATION_LIST',
    ACTIVATE_SUBSCRIPTION: 'category/ACTIVATE_SUBSCRIPTION',
    DEACTIVATE_SUBSCRIPTION: 'category/DEACTIVATE_SUBSCRIPTION',
    GET_LAST_EXTERNAL_INVOICES: '',
    VALIDATE_VAT: 'transactions/VALIDATE_VAT',
    REFRESH_TRANSACTIONS_LIST: 'transactions/REFRESH_TRANSACTIONS_LIST',
};
