import {bankApiInstance} from '../../../../../lib/bank-api-instance';
import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';

const createCards = ({accountId, data}) => {
    return bankApiInstance({
        method: 'POST',
        url: `/v3/bank/accounts/${accountId}/cards`,
        data,
    }).then(result => result?.data);
};

const updateCards = ({accountId, cardId, data}) => {
    return bankApiInstance({
        method: 'PUT',
        url: `/v3/bank/accounts/${accountId}/cards/${cardId}`,
        data,
    }).then(result => result?.data);
};

const getCountries = () => {
    return hiwayApiInstance({
        method: 'GET',
        url: `/v1/geo/countries`,
    }).then(result => result?.data);
};

const getCards = ({accountId}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/accounts/${accountId}/cards`,
    }).then(result => result?.data);
};

const viewPinFlow = ({accountId, id}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/accounts/${accountId}/cards/${id}/pin`,
    }).then(result => result?.data);
};

const lockCard = ({accountId, id}) => {
    return bankApiInstance({
        method: 'POST',
        url: `/v3/bank/accounts/${accountId}/cards/${id}/lock`,
    }).then(result => result?.data);
};

const unlockCard = ({accountId, id}) => {
    return bankApiInstance({
        method: 'POST',
        url: `/v3/bank/accounts/${accountId}/cards/${id}/unlock`,
    }).then(result => result?.data);
};

const activatePhysCard = ({accountId, id, code}) => {
    return bankApiInstance({
        method: 'POST',
        data: {
            identifier: code,
        },
        url: `/v3/bank/accounts/${accountId}/cards/${id}/activate`,
    }).then(result => result?.data);
};

const showNumbers = ({accountId, id}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/accounts/${accountId}/cards/${id}/card-numbers`,
    }).then(result => result?.data);
};

const showPhysicalNumbers = ({accountId, id}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/accounts/${accountId}/cards/${id}/physical-card-numbers`,
    }).then(result => result?.data);
};

const deleteCards = ({accountId, id}) => {
    return bankApiInstance({
        method: 'DELETE',
        url: `/v3/bank/accounts/${accountId}/cards/${id}`,
    }).then(result => result?.data);
};

export const CardsApi = {
    createCards,
    updateCards,
    getCountries,
    getCards,
    activatePhysCard,
    viewPinFlow,
    lockCard,
    unlockCard,
    showNumbers,
    showPhysicalNumbers,
    deleteCards,
};
