const selectStep = state => state.freelancer.onboarding.currentlyOpenStep;

const selectSubStep = state => state.freelancer.onboarding.currentlyOpenSubStep;

const selectInternalSubStep = state => state.freelancer.onboarding.currentlyOpenInternalSubStep;

const selectProgress = state => state.freelancer.onboarding.progress;

const selectOnboardingFinalStatus = state => state.freelancer.onboarding.onboardingFinalStatus;

export const OnboardingSelectors = {
    selectStep,
    selectSubStep,
    selectInternalSubStep,
    selectProgress,
    selectOnboardingFinalStatus,
};
