import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import CommonForMobile from './CommonForMobile';
import NumberOfBids from './NumberOfBids';
import {styles} from './styles';

const OpportunityForMobile = ({rowData}) => {
    const {t} = useTranslation('opportunity');

    return (
        <Box sx={styles.container}>
            <CommonForMobile rowData={rowData} />
            <Box sx={styles.row}>
                <Typography variant="body2" sx={styles.label}>
                    {t('element.header.number')}
                </Typography>
                <Box component="span" sx={styles.value}>
                    <NumberOfBids rowData={rowData} isMobile />
                </Box>
            </Box>
        </Box>
    );
};

OpportunityForMobile.propTypes = {
    rowData: PropTypes.any.isRequired,
};

export default React.memo(OpportunityForMobile);
