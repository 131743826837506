import CssBaseline from '@mui/joy/CssBaseline';
import {CssVarsProvider, useColorScheme} from '@mui/joy/styles';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {ThemeSelectors} from '../../../features/theme/store/theme.selector';
import JoyUiTheme from '../../../lib/theme-joy-ui';

// TODO: dark mode switcher: https://mui.com/joy-ui/customization/dark-mode/#creating-a-mode-toggle-component

const JoyUIThemeProvider = ({
    children,
}) => {
    return (
        <CssVarsProvider
            theme={JoyUiTheme}
        >
            <CssBaseline />
            <ThemeHandler />
            {children}
        </CssVarsProvider>
    );
};

const ThemeHandler = () => {
    const selectedThemeType = useSelector(ThemeSelectors.selectTheme);
    const {mode, setMode} = useColorScheme();

    useEffect(() => {
        if (selectedThemeType !== mode) {
            setMode(selectedThemeType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedThemeType]);

    return null;
};

export default JoyUIThemeProvider;
