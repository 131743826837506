import Coach from './Coach';
import CompanyAndUser from './CompanyAndUser';
import DetailsLink from './DetailsLink';
import FecStatus from './FecStatus';
import LegalStatusAndTaxSystem from './LegalStatusAndTaxSystem';
import Status from './Status';

export const SORT_EXCEPTION_COLUMNS = {
    finalFecStatus: 'finalFecSubmitted',
    annualAccountStatus: 'annualAccountingStatus',
};

export const getColumnConfig = (t, hasSearchParam) => {
    return [
        {
            key: 'company.name',
            width: '30%',
            title: t('table.company'),
            component: CompanyAndUser,
            isSortable: true,
        },
        {
            key: 'manager.lastName',
            width: '5%',
            title: t('table.coach'),
            component: Coach,
            isSortable: true,
        },
        {
            key: 'company.legalForm',
            width: '18%',
            title: t('table.type'),
            component: LegalStatusAndTaxSystem,
            componentProps: {
                translate: t,
            },
            isSortable: true,
        },
        {
            key: 'company.activityStartDate',
            width: '10%',
            title: t('table.date'),
            isSortable: true,
            componentProps: {
                isDate: true,
            },
        },
        {
            key: SORT_EXCEPTION_COLUMNS.finalFecStatus,
            width: '12%',
            title: t('table.fec'),
            component: FecStatus,
            isSortable: !hasSearchParam,
        },
        {
            key: SORT_EXCEPTION_COLUMNS.annualAccountStatus,
            width: '20%',
            title: t('table.status'),
            component: Status,
            isSortable: !hasSearchParam,
        },
        {
            key: 'actions',
            width: '5%',
            title: '',
            component: DetailsLink,
        },
    ];
};
