import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useSelector} from 'react-redux';
import {useAppContentStyles} from './styles';

import CompanyRegisteredOverlay from '../../../../features/err-pages/components/CompanyRegisteredOverlay';
import MaintenanceOverlay from '../../../../features/err-pages/components/MaintenanceOverlay';
import {InvoiceSelector} from '../../../../features/invoicing/store/invoice.selector';
import {UiSelectors} from '../../../../features/ui/store/ui.selector';
import {useMemoizedCreateSelector} from '../../../../hooks/use-memoized-create-selector';
import {
    selectIsMaintenanceModeActive,
    selectShouldRenderCompanyRegisteredOverlay,
} from '../../../app/app.selectors';

const AppContent = ({children}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const styles = useAppContentStyles();

    const shouldRenderCompanyRegisteredOverlay = useSelector(selectShouldRenderCompanyRegisteredOverlay);
    const isMaintenanceModeActive = useSelector(selectIsMaintenanceModeActive);
    const hasMuiActiveModal = useMemoizedCreateSelector(UiSelectors.createHasMuiActiveModal);

    const invoiceUrl = useSelector(InvoiceSelector.selectInvoiceUrl);

    if (isMaintenanceModeActive) {
        return (
            <MaintenanceOverlay />
        );
    } else if (shouldRenderCompanyRegisteredOverlay) {
        return (
            <CompanyRegisteredOverlay />
        );
    }

    return (
        <Box
            id="app-content"
            className={styles.container}
            sx={{
                ...(hasMuiActiveModal && !invoiceUrl ? {filter: 'blur(12px)'} : {}), // TODO: remove after deleting MUI version
                ...(isMobileSize ? {paddingBottom: 0} : {}),
            }}
        >
            {children}
        </Box>
    );
};

export default AppContent;
