export const BackofficeActionTypes = {
    STORE_COMPANIES_IDS: 'backoffice/STORE_COMPANIES_IDS',
    STORE_COMPANIES_ENTITIES: 'backoffice/STORE_COMPANIES_ENTITIES',
    STORE_SHORT_FILTER_COUNT: 'backoffice/STORE_SHORT_FILTER_COUNT',
    STORE_TOTAL_COUNT: 'backoffice/STORE_TOTAL_COUNT',
    FILTER_COMPANIES: 'backoffice/FILTER_COMPANIES',
    SEND_REMINDER: 'backoffice/SEND_REMINDER',
    SAVE_REGISTRATION_STATUS: 'backoffice/SAVE_REGISTRATION_STATUS',
    SET_IS_REGISTRATION_STATUS_SAVED: 'backoffice/SET_IS_REGISTRATION_STATUS_SAVED',
    GET_KBIS_DOCUMENTS: 'backoffice/GET_KBIS_DOCUMENTS',
    STORE_KBIS_DOCUMENTS: 'backoffice/STORE_KBIS_DOCUMENTS',
    GET_LEGAL_NOTICE_DOCUMENT: 'backoffice/GET_LEGAL_NOTICE_DOCUMENT',
    STORE_LEGAL_NOTICE_DOCUMENT: 'backoffice/STORE_LEGAL_NOTICE_DOCUMENT',
    EDIT_REGISTRATION_DATE: 'backoffice/EDIT_REGISTRATION_DATE',
    UPLOAD_COMPANY_SETUP_FILE: 'backoffice/UPLOAD_COMPANY_SETUP_FILE',
    START_COMPANY_REGISTRATION: 'backoffice/START_COMPANY_REGISTRATION',
    COMPANY_REGISTRATION_START_POLLING: 'backoffice/COMPANY_REGISTRATION_START_POLLING',
    COMPANY_REGISTRATION_CREATE_DRAFT: 'backoffice/COMPANY_REGISTRATION_CREATE_DRAFT',
    COMPANY_REGISTRATION_RESTART: 'backoffice/COMPANY_REGISTRATION_RESTART',
    COMPANY_REGISTRATION_RETRY: 'backoffice/COMPANY_REGISTRATION_RETRY',
    COMPANY_REGISTRATION_REFRESH: 'backoffice/COMPANY_REGISTRATION_REFRESH',
    COMPANY_REGISTRATION_MANUAL_LAUNCH: 'backoffice/COMPANY_REGISTRATION_MANUAL_LAUNCH',
    COMPANY_REGISTRATION_MANUAL_VALIDATE: 'backoffice/COMPANY_REGISTRATION_MANUAL_VALIDATE',
    COMPANY_REGISTRATION_FINALIZATION: 'backoffice/COMPANY_REGISTRATION_FINALIZATION',
    COMPANY_REGISTRATION_UPDATE_NOTE: 'backoffice/COMPANY_REGISTRATION_UPDATE_NOTE',
    GLOBAL_DATA_EXPORT: 'backoffice/GLOBAL_DATA_EXPORT',
};
