import {
    faCheckToSlot,
    faCircleCheck,
    faCircleExclamation,
    faCircleXmark,
    faClock,
    faEnvelopeCircleCheck,
    faHourglassHalf,
} from '@fortawesome/free-solid-svg-icons';

export const VatDeclarationStatus = {
    TODO: 'TODO',
    SUBMITTED: 'SUBMITTED',
    PENDING_VERIFICATION: 'PENDING_VERIFICATION',
    OVERDUE: 'OVERDUE',
    REFUSED: 'REFUSED',
    VALIDATED: 'VALIDATED',
    ERROR: 'ERROR',
};

export const VatDeclarationStatusIcons = {
    [VatDeclarationStatus.TODO]: faHourglassHalf,
    [VatDeclarationStatus.SUBMITTED]: faEnvelopeCircleCheck,
    [VatDeclarationStatus.PENDING_VERIFICATION]: faCheckToSlot,
    [VatDeclarationStatus.OVERDUE]: faClock,
    [VatDeclarationStatus.REFUSED]: faCircleXmark,
    [VatDeclarationStatus.VALIDATED]: faCircleCheck,
    [VatDeclarationStatus.ERROR]: faCircleExclamation,
};

export const VatDeclarationType = {
    Monthly: 'MONTHLY',
    BiAnnual: 'BI_ANNUALLY',
};
