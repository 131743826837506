export const SET_IS_LOADING_PROFESSIONAL_EXPENSES = 'SET_IS_LOADING_PROFESSIONAL_EXPENSES';
export const GET_PROFESSIONAL_EXPENSES = 'GET_PROFESSIONAL_EXPENSES';
export const STORE_PROFESSIONAL_EXPENSES = 'STORE_PROFESSIONAL_EXPENSES';
export const DELETE_PROFESSIONAL_EXPENSE = 'DELETE_PROFESSIONAL_EXPENSE';
export const DOWNLOAD_PROFESSIONAL_EXPENSE = 'DOWNLOAD_PROFESSIONAL_EXPENSE';

export const setIsLoadingProfessionalExpenses = isLoading => ({
    type: SET_IS_LOADING_PROFESSIONAL_EXPENSES,
    payload: isLoading,
});

export const getProfessionalExpenses = (freelancerId, companyId) => ({
    type: GET_PROFESSIONAL_EXPENSES,
    payload: {
        freelancerId,
        companyId,
    },
});

export const deleteProfessionalExpense = (freelancerId, companyId, expenseId) => ({
    type: DELETE_PROFESSIONAL_EXPENSE,
    payload: {
        freelancerId,
        companyId,
        expenseId,
    },
});

export const downloadProfessionalExpense = (freelancerId, companyId, receiptId, isDownload = true) => ({
    type: DOWNLOAD_PROFESSIONAL_EXPENSE,
    payload: {
        freelancerId,
        companyId,
        receiptId,
        isDownload,
    },
});

export const storeProfessionalExpenses = professionalExpenses => ({
    type: STORE_PROFESSIONAL_EXPENSES,
    payload: professionalExpenses,
});
