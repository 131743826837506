import {createTheme, responsiveFontSizes} from '@mui/material';

import '@fontsource/red-hat-text/latin.css';
import '@fontsource/barlow/latin.css';
import '@fontsource/prompt/latin.css';

const theme = responsiveFontSizes(createTheme({
    palette: {
        background: {
            default: '#F1F4F6FF',
        },
        primary: {
            main: '#0A7BC4',
            light: '#3779BE',
        },
        secondary: {
            main: '#e84c4c',
        },
        success: {
            main: '#00C804',
        },
        buttonSuccess: {
            main: '#4CAF50',
        },
        error: {
            main: '#DB100D',
        },
        warning: {
            main: '#F57C00',
        },
        grey: {
            'main': '#F1F4F6FF',
            '300': '#F1F4F6FF',
            'light': 'rgba(0, 0, 0, 0.38)',
        },
        whitePallete: {
            default: '#FFFFFF',
            main: '#FFFFFF',
            light: '#FFFFFF',
            dark: '#FFFFFF',
        },
        elephant: {
            main: '#0B2333',
        },
        bigStone: {
            main: '#173244',
        },
        blueGray: {
            300: '#90A4AE',
            400: '#78909C',
            500: '#607D8B',
            main: '#ECEFF1',
            light: 'rgba(236, 239, 241, 0.8)',
        },
        lightTextSecondary: {
            main: '#0B2333',
        },
        text_v2: {
            primary: '#060506',
            secondary: '#0B2333',
            disabled: '#00000061',
        },
        primary_v2: {
            main: '#3779BE',
            contrastText: '#ffffff',
            dark: '#30679E',
            shades60: 'rgba(55, 121, 190, 0.6)',
        },
        other_v2: {
            divider: '#0000001F',
        },
        error_v2: {
            main: '#D54942',
        },
        warning_v2: {
            main: '#E1A348',
            light: '#ED6C02',
            dark: '#E65100',
            text: '#5E2C01',
            shades: '#FDF0E5',
        },
        success_v2: {
            main: '#2E7D32',
            light: '#4CAF50',
        },
        info_v2: {
            light: '#03A9F4',
            main: '#0288D1',
            default: '#0288D1',
        },
        gray_v2: {
            '50': '#FAFAFA',
            '100': '#F5F5F5',
        },
        v2Grey300Palette: {
            'main': '#90A4AE',
            '300': '#90A4AE',
            'light': '#FFF',
        },
        v2PrimaryShades50: {
            main: 'rgba(25, 118, 210, 0.50)',
        },
        gray: {
            main: '#90A4AE',
            light: '#90A4AE',
            dark: '#90A4AE',
            contrastText: '#FFF',
        },
        v2: {
            blueGray: {
                50: '#ECEFF1',
                100: '#CFD8DC',
                200: '#B0BEC5',
                300: '#90A4AE',
                400: '#78909C',
                500: '#607D8B',
                600: '#546E7A',
                700: '#455A64',
                900: '#263238',
            },
            gray: {
                50: '#FAFAFA',
                100: '#F5F5F5',
                300: '#E0E0E0',
                400: '#BDBDBD',
                500: '#9E9E9E',
                900: '#101828',
            },
            amber: {
                50: '#FFF8E1',
                300: '#FFD54F',
            },
            light: {
                primary: {
                    main: '#3779BE',
                    light: '#05A7D0',
                    shades: 'rgba(25, 118, 210, 0.04)',
                    shades4: 'rgba(25, 118, 210, 0.04)',
                    shades8: 'rgba(25, 118, 210, 0.08)',
                    shades12: 'rgba(25, 118, 210, 0.12)',
                    shades30: 'rgba(25, 118, 210, 0.30)',
                    shades50: 'rgba(25, 118, 210, 0.50)',
                },
                text: {
                    main: '#060506',
                    disabled: 'rgba(0, 0, 0, 0.38)',
                    disabledLight: 'rgba(0, 0, 0, 0.26)',
                    secondary: 'rgba(11, 35, 51, 1)',
                },
                info: {
                    main: '#0288D1',
                    light: '#03A9F4',
                    shades4: 'rgba(2, 136, 209, 0.04)',
                    shades10: 'rgba(2, 136, 209, 0.10)',
                    shades12: 'rgba(2, 136, 209, 0.12)',
                    shades160: '#023653',
                },
                error: {
                    main: '#D54942',
                    dark: '#C62828',
                    shades4: 'rgba(211, 47, 47, 0.04)',
                    shades12: 'rgba(211, 47, 47, 0.12)',
                    shades160: '#D32F2F',
                },
                warning: {
                    main: '#E1A348',
                    light: '#FF9800',
                    saturated: '#E65100',
                    dark: '#5F2A01',
                    shades4: 'rgba(237, 108, 2, 0.04)',
                    shades12: 'rgba(237, 108, 2, 0.12)',
                    shades30: 'rgba(237, 108, 2, 0.3)',
                    shades160: 'rgb(98, 60, 20)',
                },
                success: {
                    main: '#2E7D32',
                    light: '#4CAF50',
                    shades4: 'rgba(46, 125, 50, 0.04)',
                    shades12: 'rgba(46, 125, 50, 0.12)',
                    shades30: 'rgba(46, 125, 50, 0.3)',
                },
                action: {
                    hover: 'rgba(0, 0, 0, 0.04)',
                    active: 'rgba(0, 0, 0, 0.54)',
                    disabled: 'rgba(0, 0, 0, 0.12)',
                },
                secondary: {
                    main: '#E84C4C',
                    dark: '#C62828',
                    shades4: 'rgba(232, 76, 76, 0.04)',
                    shades8: 'rgba(232, 76, 76, 0.08)',
                    shades12: 'rgba(232, 76, 76, 0.12)',
                    shades30: 'rgba(232, 76, 76, 0.3)',
                },
                other: {
                    rating: {
                        active: '#FFB400',
                    },
                    outlined_border: 'rgba(0, 0, 0, 0.23)',
                    disabled: 'rgba(0, 0, 0, 0.1)',
                    divider: 'rgba(0, 0, 0, 0.12)',
                    aria: '#3B48D0',
                },
                shadow: {
                    main: 'rgba(11, 35, 51, 0.60)',
                },
                whiteSmoke: '#F2F2F2',
                ghostWhite: '#F9FAFB',
                trolleyGrey: '#808080',
                amber: '#FEC106',
                darkCerulean: '#0F4D71',
                black: '#000000',
            },
            dark: {
                info: {
                    shades12: 'rgba(2, 136, 209, 0.12)',
                    shades30: 'rgba(2, 136, 209, 0.3)',
                },
            },
        },
        text: {
            primary: '#0B2333', // Default color override per Figma design
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1669, // TODO:MEDIUM: See what value works the best here, 1536px is xl in MUI v5, it was 1920px in v4
        },
    },
    typography: {
        fontFamily: [
            'Red Hat Text',
        ].join(','),
    },
    components: {
        MuiFormLabel: {
            variants: [
                {
                    props: {
                        variant: 'black',
                    },
                    style: {
                        fontWeight: 600,
                        color: '#000',
                    },
                },
            ],
        },
        MuiDivider: {
            variants: [
                {
                    props: {variant: 'dashed'},
                    style: {
                        borderStyle: 'dashed',
                    },
                },
            ],
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    'backgroundColor': 'rgba(10, 123, 196, 0.05)',
                    '&:hover:not(.Mui-disabled)': {
                        backgroundColor: 'rgba(10, 123, 196, 0.09)',
                    },
                    '&.Mui-focused:not(.Mui-disabled)': {
                        backgroundColor: 'rgba(10, 123, 196, 0.09)',
                    },
                },
            },
        },
        MuiPaper: {
            variants: [
                {
                    props: {radius: 16},
                    style: {
                        borderRadius: 16,
                    },
                },
                {
                    props: {radius: 8},
                    style: {
                        borderRadius: 8,
                    },
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    paddingTop: 12,
                    paddingBottom: 12,
                },
                defaultProps: {
                    disableElevation: true,
                },
                sizeSmall: {
                    borderRadius: 8,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 32,
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                vertical: {
                    '& .MuiStepIcon-root': {
                        color: '#1976D21F',
                    },
                    '& .MuiStepIcon-text': {
                        fill: '#03A9F4',
                    },
                    '& .Mui-active .MuiStepIcon-text': {
                        fill: '#fff',
                    },
                    '& .Mui-completed': {
                        color: '#4CAF50 !important',
                    },
                    // TODO Check if there is better way for this
                    '& .Mui-completed .MuiStepConnector-lineVertical': {
                        borderColor: '#4CAF50',
                    },
                    '& .Mui-active .MuiStepConnector-lineVertical': {
                        borderColor: '#3779BE',
                    },
                    '& .custom-substep': {
                        position: 'relative',
                        right: '9px',
                    },
                },
            },
        },
        // To get the correct value on the first page render the noSsr option
        // in the useMediaQuery hook needs to be true.
        // https://stackoverflow.com/questions/68497916/material-ui-usemediaquery-not-initially-recognising-correct-breakpoint
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },
    },
}));

// Responsive typography corrections
theme.typography.h5 = {
    ...theme.typography.h5,
    'fontWeight': theme.typography.fontWeightBold,
    'fontSize': theme.typography.pxToRem(17),
    '@media (max-width: 959.95px)': {
        fontSize: theme.typography.pxToRem(19),
    },
    '@media (min-width: 1280px)': {
        'fontSize': theme.typography.pxToRem(21),
    },
};

// Responsive typography corrections
theme.typography.h4 = {
    ...theme.typography.h4,
    'fontWeight': theme.typography.fontWeightBold,
    'fontSize': '1.5rem',
    '@media (min-width: 1280px)': {
        fontSize: '2.125rem',
    },
    '@media (max-width: 959.95px)': {
        fontSize: '2.125rem', // theme.typography.pxToRem(21),
    },
};

// Responsive typography corrections
theme.typography.h3 = {
    ...theme.typography.h3,
    fontWeight: theme.typography.fontWeightBold,
};

// Responsive typography corrections
theme.typography.h2 = {
    ...theme.typography.h2,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '2.125rem',
};

theme.typography.subtitle2_v2 = {
    fontFamily: 'Red Hat Text',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(25),
    letterSpacing: theme.typography.pxToRem(0.1),
};

theme.typography.documentation_h4 = {
    ...theme.typography.h4,
    'fontSize': theme.typography.pxToRem(34),
    'lineHeight': theme.typography.pxToRem(40),
    'fontWeight': 700,
    'letterSpacing': '-0.2px',
    'color': 'text_v2.primary',
    '@media (max-width: 959.95px)': {
        fontSize: theme.typography.pxToRem(21),
        lineHeight: theme.typography.pxToRem(26),
        letterSpacing: '0.25px',
    },
};

theme.typography.alt_h4 = {
    ...theme.typography.h4,
    'display': 'inline-block',
    'fontSize': theme.typography.pxToRem(34),
    'lineHeight': theme.typography.pxToRem(40),
    'fontWeight': 700,
    'letterSpacing': '-0.2px',
    'color': theme.palette.text_v2.primary,
    '@media (max-width: 959.95px)': {
        fontSize: theme.typography.pxToRem(21),
        lineHeight: theme.typography.pxToRem(26),
        letterSpacing: '0.25px',
    },
};

theme.typography.italic_quote = {
    ...theme.typography.body1,
    'fontSize': theme.typography.pxToRem(16),
    'lineHeight': theme.typography.pxToRem(24),
    'fontWeight': 400,
    'letterSpacing': '0.15px',
    'fontStyle': 'italic',
    'color': theme.palette.text_v2.secondary,
    '@media (max-width: 959.95px)': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: '0.17px',
    },
};

theme.typography.paper_card_title = {
    ...theme.typography.h4,
    fontWeight: '500',
    fontSize: `${theme.typography.pxToRem(14)} !important`,
    textTransform: 'uppercase',
    color: theme.palette.blueGray['300'],
    letterSpacing: '1px',
};

// Shadow overrides
theme.shadows[2] = '0 3px 1px -2px rgba(11, 35, 51, 0.05), 0 2px 2px rgba(11, 35, 51, 0.07), 0 1px 5px rgba(11, 35, 51, 0.09)';
theme.shadows[24] = '0px 11px 15px -7px rgba(11, 35, 51, 0.08), 0px 24px 38px 3px rgba(11, 35, 51, 0.08), 0px 9px 46px 8px rgba(11, 35, 51, 0.1)';

export {theme};
