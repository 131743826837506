import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Box, CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const BankCardMenuItem = ({text, icon, color, isDisabled, isLoading, onClick}) => {
    return (
        <Box
            onClick={isDisabled ? () => {} : onClick}
            sx={{
                'p': 2,
                'cursor': isDisabled ? 'default' : 'pointer',
                'display': 'flex',
                'justifyContent': 'space-between',
                '&:hover': isDisabled ? {} : {
                    backgroundColor: 'v2.blueGray.50',
                },
            }}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
            }}
            >
                {isLoading ? (<CircularProgress />) : (
                    <Box sx={{
                        color: color,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        {icon}
                    </Box>
                )}
                <Box sx={{
                    color: color ?? '#546E7A',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                }}
                >
                    {text}
                </Box>
            </Box>
            <Box sx={isDisabled ? {color: 'v2.light.text.disabledLight'} : {}}>
                <ChevronRightIcon />
            </Box>
        </Box>
    );
};

BankCardMenuItem.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    color: PropTypes.string,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

BankCardMenuItem.defaultProps = {
    isLoading: false,
    color: '#3779BE',
    isDisabled: false,
};
