import {combineReducers} from 'redux';
import {DocumentationActionType} from './documentation.action-type';
import {createReducer} from '../../../v1/app/createReducer';
import {EMPTY_SEARCH_RESULTS} from '../util/constants';

export const documentationReducer = combineReducers({
    categoriesList: createReducer([], DocumentationActionType.STORE_CATEGORIES, false),
    subCategoriesList: createReducer([], DocumentationActionType.STORE_SUBCATEGORIES, false),
    articlesList: createReducer([], DocumentationActionType.STORE_ARTICLES, false),
    videosList: createReducer([], DocumentationActionType.STORE_VIDEOS, false),
    faqsList: createReducer([], DocumentationActionType.STORE_FAQS, false),
    detailsPage: createReducer([], DocumentationActionType.STORE_DETAILS, false),
    searchResults: createReducer(EMPTY_SEARCH_RESULTS, DocumentationActionType.STORE_SEARCH_RESULTS, false),
    voteResult: createReducer(null, DocumentationActionType.STORE_VOTE_RESULT, false),
    popularArticlesList: createReducer([], DocumentationActionType.STORE_POPULAR_ARTICLES, true),
    globalPopularArticlesList: createReducer([], DocumentationActionType.STORE_GLOBAL_POPULAR_ARTICLES, false),
    otherItemsList: createReducer([], DocumentationActionType.STORE_OTHER_ITEMS, true),
});
