import {Auth} from 'aws-amplify';
import {generatePath, matchPath} from 'react-router-dom';
import {all, call, cancel, delay, fork, put, select, take} from 'redux-saga/effects';
import {watchAnimationsSaga} from './features/animations/store/animations.saga';
import {appEventSaga} from './features/app-event/store/app-event.saga';
import {initializeAuth, watchAuthSagas} from './features/auth/store/auth.sagas';
import {saveRedirectLocation} from './features/auth/store/loginForm/loginForm.actions';
import {watchBackofficeSaga} from './features/backoffice/store/backoffice.saga';
import {watchCardsSaga} from './features/bank/modules/cards/store/cards.saga';
import {bankFreelancerLoginAuthFlow} from './features/bank/store/bank.loader.saga';
import {watchBankSaga} from './features/bank/store/bank.saga';
import {watchChangePasswordSagas} from './features/change-password/store/changePasswordForm.sagas';
import {watchAccountingSaga} from './features/company-profile/modules/accounting/store/accounting.saga';
import {watchPayslipSaga} from './features/company-profile/modules/payslip/store/payslip.saga';
import {watchDashboardV3Saga} from './features/dashboard-v3/store/dashboard-v3.saga';
import {watchDashboardSaga} from './features/dashboard/store/dashboard.saga';
import {documentSaga} from './features/document/store/document.saga';
import {watchDocumentationSaga} from './features/documentation/store/documentation.saga';
import {watchFinancialStatementsSaga} from './features/financial-statements/store/financial-statements.saga';
import {FreelancerSelectors, freelancerSaga} from './features/freelancer';
import {loadFreelancerCompanies} from './features/freelancer/modules/companies/store/companies.saga';
import {
    checkOnboardingFinalStatus,
    loadOnboardingData,
} from './features/freelancer/modules/onboarding/store/onboarding.saga';
import {loadFreelancerAccount} from './features/freelancer/store/freelancer.saga';
import {watchInsuranceSaga} from './features/insurance/store/insurance.saga';
import {watchInvoiceSaga} from './features/invoicing/store/invoice.saga';
import {professionAndSpecializationSaga} from './features/job-profession-and-specilties/store/profession-and-specialization.saga';
import {LoadingActions, LoadingTypes} from './features/loading';
import {watchOpportunitiesSaga} from './features/opportunity/store/opportunity.saga';
import {revenueSimulatorSaga} from './features/revenue-simulator/store/revenue-simulator.saga';
import {runAuthSSETokens, watchStoreSaga} from './features/server-side-events/store/sse.saga';
import {watchSettingsSaga} from './features/settings/store/settings.sagas';
import {signatureSaga} from './features/signature/store/signature.saga';
import {boTrainingSaga} from './features/training-bo/store/bo-training.saga';
import {watchTrainingSaga} from './features/training/store/training.saga';
import {
    LoggedInUserActionTypes,
    LoggedInUserSelectors,
    loadLoggedInUserAccount,
} from './features/user/modules/logged-in-user';
import {userSaga} from './features/user/store/user.saga';
import {vatDeclarationsSaga} from './features/vat-declaration/store/vat-declaration.saga';
import {
    LOCATION_CHANGED,
    connectedRouteWatcher,
    push,
    replace,
    selectRouterLocation,
} from './lib/router/connected-router-saga';
import {PublicRoutePaths, RoutePaths} from './lib/router/route-paths';
import {Routes} from './lib/router/routes';
import {COOKIE_NAMES, getCookie} from './utils/cookie';
import {Debug} from './utils/debug';
import {UserRoles, isUserCareOrFreelancer} from './utils/user-roles';
import {watchCompanySagas} from './v1/app/company/company.sagas';
import {watchEventSagas} from './v1/app/event/event.sagas';
import {watchExpensesSagas} from './v1/app/expenses/expenses.sagas';
import {watchFreelancerSagas} from './v1/app/freelancer/freelancer.sagas';
import {watchMyCompaniesSaga} from './v1/app/my-companies/myCompanies.sagas';
import {watchSignContractSagas} from './v1/app/sign-contract/signContract.saga';
import {watchUserSagas} from './v1/app/user/user.sagas';
import {watchQuickAccess} from './v1/components/company/documents-quick-access/store/quick-access.saga';

const getIsLoggedIn = function* () {
    let isUserLoggedIn = false;

    try {
        const cookieToken = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);
        if (!cookieToken) {
            const session = yield call([Auth, Auth.currentSession]);

            isUserLoggedIn = session && !!session.getIdToken().getJwtToken();
        } else {
            isUserLoggedIn = true;
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return isUserLoggedIn;
};

const ARIA_INVOICE_VALIDATION = 'aria-invoice-validation';

const loadAppData = function* () {
    const isLoggedIn = yield call(getIsLoggedIn);

    Debug.debug('loadAppData', `User is ${!isLoggedIn ? 'not ' : ''}logged in.`);

    if (!isLoggedIn) {
        yield put(LoadingActions.setLoading(LoadingTypes.APPLICATION_DATA, false));

        const location = yield select(selectRouterLocation);

        if (
            location.pathname.includes(ARIA_INVOICE_VALIDATION)
            || matchPath(RoutePaths.REVENUE_SIMULATOR_RESULTS_PUBLIC, location.pathname)
            || location.pathname.includes(RoutePaths.PUBLIC_FILE_UPLOAD)
        ) {
            return;
        }

        yield put(saveRedirectLocation(location));

        yield put(push(RoutePaths.LOGIN));

        return;
    }

    Debug.debug('loadAppData', 'Fetching user\'s account.');

    yield call(loadLoggedInUserAccount);

    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    if (!loggedInUser) {
        Debug.error('loadAppData', 'The logged in user data is missing.');

        yield put(LoadingActions.setLoading(LoadingTypes.APPLICATION_DATA, false));

        return;
    }

    Debug.debug('loadAppData', 'Logged in user\'s account: ', loggedInUser);

    if (isUserCareOrFreelancer(loggedInUser)) {
        Debug.debug('loadAppData', 'The logged in user is a freelancer. Loading freelancer account and companies...');

        yield all([
            call(loadFreelancerAccount, {freelancerId: loggedInUser.id}),
            call(loadFreelancerCompanies, {freelancerId: loggedInUser.id}),
        ]);
    }
};

const freelancerAccessControl = function* () {
    while (true) {
        yield take(LOCATION_CHANGED);

        // Note - loggedInUser is taken before it is cleared on logout
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const location = yield select(selectRouterLocation);

        if (!loggedInUser || !freelancer) {
            continue;
        }

        if (location.pathname === RoutePaths.LOGIN) {
            continue;
        }

        if (!loggedInUser.onboardingStatus) {
            yield call(checkOnboardingFinalStatus,
                {freelancerId: loggedInUser.id, companyId: loggedInUser.defaultCompanyId});
        }

        const isNotPublicRoute = !Object.values(PublicRoutePaths).includes(location.pathname);

        // ONBOARDING SIDEBAR LOCATION CHANGE - Shorthand to find all places we need to change when adding new item
        if (((loggedInUser.status !== 'ACTIVE')
                || (loggedInUser.onboardingStatus === 'PENDING'
                    || loggedInUser.onboardingStatus === 'READY_FOR_LAUNCH'))
            && location.pathname !== RoutePaths.ONBOARDING) {
            if (
                isNotPublicRoute
                && location.pathname !== RoutePaths.ADP
                && location.pathname !== RoutePaths.CGVS
                && location.pathname !== RoutePaths.SETTINGS
                && location.pathname !== RoutePaths.MY_PROFILE
                && location.pathname !== RoutePaths.MY_PROFILE_EDIT
                && location.pathname !== RoutePaths.MY_COMPANIES
                && location.pathname !== RoutePaths.MISSION_SEARCH
                && (location.pathname.indexOf(RoutePaths.CLUSTER) !== 0 || loggedInUser.status === 'CONTRACT_PENDING')
                && location.pathname.indexOf(generatePath(RoutePaths.DOCUMENTS, {
                    companyId: freelancer?.defaultCompanyId,
                })) !== 0
                && location.pathname !== generatePath(RoutePaths.MY_COMPANY, {
                    companyId: freelancer?.defaultCompanyId,
                })
                && location.pathname !== RoutePaths.CHANGE_PASSWORD
                && location.pathname.indexOf(RoutePaths.DOCUMENTATION) === -1
            ) {
                yield delay(20);
                yield put(push(RoutePaths.ONBOARDING));
            } else if (loggedInUser) {
                yield call(loadOnboardingData);
            }
        }

        if (
            location.pathname === RoutePaths.ONBOARDING
            && loggedInUser?.isFullyActive
        ) {
            yield delay(20);
            yield put(push(RoutePaths.DASHBOARD));
        }
    }
};

const freelancerFinalStepControl = function* () {
    while (true) {
        yield take(LoggedInUserActionTypes.STORE_ACCOUNT);

        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const location = yield select(selectRouterLocation);

        if (!loggedInUser || !freelancer
            || (loggedInUser.role !== UserRoles.FREELANCER
            && loggedInUser.role !== UserRoles.CARE)) {
            continue;
        }

        if (location.pathname === RoutePaths.ONBOARDING
            && loggedInUser.onboardingStatus
            && loggedInUser.onboardingStatus !== 'PENDING'
            && loggedInUser.onboardingStatus !== 'READY_FOR_LAUNCH') {
            yield put(push(RoutePaths.DASHBOARD));
        }
    }
};

const routerFn = function* () {
    let task = null;

    while (true) {
        let routes = [...Routes.PublicRoutes];

        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        if (loggedInUser?.role === UserRoles.ADMINISTRATOR) {
            routes = [
                ...routes,
                ...Routes.AdministratorRoutes,
            ];
        } else if (isUserCareOrFreelancer(loggedInUser)) {
            routes = [
                ...routes,
                ...Routes.FreelancerRoutes(loggedInUser),
            ];
        }

        task = yield fork(connectedRouteWatcher, routes);

        yield take(LoggedInUserActionTypes.STORE_ACCOUNT);

        if (task) {
            const location = yield select(selectRouterLocation);

            // We need to delay cancel on onboarding because some parts have delay
            if (location.pathname === RoutePaths.ONBOARDING && loggedInUser) {
                yield delay(20000);
            }

            // TODO Why do we have and need this?
            // This is cancelling side effect tasks
            yield cancel(task);
        }
    }
};

export const rootSaga = function* () {
    yield fork(routerFn);
    yield call(initializeAuth);

    yield all([
        // v1
        fork(watchAuthSagas),
        fork(watchUserSagas),
        fork(watchCompanySagas),
        fork(watchFreelancerSagas),
        fork(watchMyCompaniesSaga),
        fork(watchSignContractSagas),
        fork(watchEventSagas),
        fork(watchExpensesSagas),

        // v2
        fork(userSaga),
        fork(freelancerSaga),
        fork(signatureSaga),
        fork(documentSaga),
        fork(watchChangePasswordSagas),
        fork(watchSettingsSaga),
        fork(watchAnimationsSaga),
        fork(watchBackofficeSaga),
        fork(appEventSaga),
        fork(watchDashboardSaga),
        fork(watchOpportunitiesSaga),
        fork(professionAndSpecializationSaga),
        fork(watchTrainingSaga),
        fork(boTrainingSaga),
        fork(watchPayslipSaga),
        fork(watchBankSaga),
        fork(watchCardsSaga),
        fork(watchStoreSaga),
        fork(watchInvoiceSaga),
        fork(watchAccountingSaga),
        fork(watchInsuranceSaga),
        fork(watchDocumentationSaga),
        fork(watchQuickAccess),
        fork(revenueSimulatorSaga),
        fork(watchDashboardV3Saga),
        fork(watchFinancialStatementsSaga),
        fork(vatDeclarationsSaga),
    ]);

    // If it is called before forks and some API call get stuck
    // it will run components and their useEffects before saga listeners are forked
    yield call(loadAppData);

    yield fork(freelancerAccessControl);
    yield fork(freelancerFinalStepControl);
    yield fork(bankFreelancerLoginAuthFlow);
    yield delay(300);

    yield fork(runAuthSSETokens);

    // TODO Check why it does not load freelancer-onboarding
    // Probably sagas working on location change that does not work on initial load
    // Workaround for initial load
    const location = yield select(selectRouterLocation);
    yield put(replace(location.pathname + location.search));

    yield put(LoadingActions.setLoading(LoadingTypes.APPLICATION_DATA, false));
};
