import {faCircleChevronDown, faCircleChevronUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, IconButton, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';

export const CollapsableSection = ({isInitiallyCollapsed, sx, title, icon, children}) => {
    const [isCollapsed, setIsCollapsed] = useState(isInitiallyCollapsed);
    const isMobileSize = useIsMobileSize();
    const {t} = useTranslation('revenueSimulator');

    const toggleCollapse = event => {
        event.stopPropagation();
        setIsCollapsed(prevState => !prevState);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 'xl',
                border: '1px solid',
                borderColor: 'neutral.outlinedBorder',
                overflow: 'hidden',
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'common.black',
                    py: 1,
                    px: 2,
                    gap: 1,
                }}
            >
                <Box color="neutral.solidColor" fontSize="md">
                    <FontAwesomeIcon icon={icon} />
                </Box>
                <Typography level="title-md" textColor="neutral.solidColor" flex={1}>
                    {title}
                </Typography>
                {isMobileSize ? (
                    <IconButton
                        sx={{
                            color: 'common.white',
                            fontSize: 'xl',
                        }}
                        size="sm"
                        onClick={toggleCollapse}
                    >
                        <FontAwesomeIcon icon={isCollapsed ? faCircleChevronDown : faCircleChevronUp} />
                    </IconButton>
                ) : (
                    <Button
                        variant="soft"
                        size="sm"
                        color="neutral"
                        endDecorator={<FontAwesomeIcon icon={isCollapsed ? faCircleChevronDown : faCircleChevronUp} />}
                        onClick={toggleCollapse}
                    >
                        {t('viewInformations')}
                    </Button>
                )}
            </Box>
            {!isCollapsed && (
                <Box
                    sx={{
                        backgroundColor: 'background.body',
                        p: 2,
                    }}
                >
                    {children}
                </Box>
            )}
        </Box>
    );
};

CollapsableSection.propTypes = {
    isInitiallyCollapsed: PropTypes.bool,
    title: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired,
    sx: PropTypes.object,
};

CollapsableSection.defaultProps = {
    isInitiallyCollapsed: false,
    sx: {},
};
