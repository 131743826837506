export const TrainingActionTypes = {
    STORE_TRAINING_INFO: 'training/STORE_TRAINING_INFO',
    GET_TRAINING_PROGRESS: 'training/GET_TRAINING_PROGRESS',
    SET_TRAINING_PROGRESS: 'training/SET_TRAINING_PROGRESS',
    SET_TRAINING_STEP: 'training/SET_TRAINING_STEP',
    GET_TRAINING_STEP: 'training/GET_TRAINING_STEP',
    SUBMIT_STEP: 'training/SUBMIT_STEP',
    SET_SUBSTEP: 'training/SET_SUBSTEP',
    SAVE_ATLAS_NUMBER: 'training/SAVE_ATLAS_NUMBER',
    SAVE_REJECTED_FORM: 'training/SAVE_REJECTED_FORM',
    RESET_DATES: 'training/RESET_DATES',
    DOWNLOAD_ZIP: 'training/DOWNLOAD_ZIP',
    CLOSE_TRAINING_ALERT_NOTIFICATION: 'training/CLOSE_TRAINING_ALERT_NOTIFICATION',
};
