export const createEnterpirseAddress = enterpriseInfo => {
    // 146 Rue des Terres de Borde, 33800 Bordeaux, France
    let address = '';
    if (enterpriseInfo?.headquarters_street) {
        address = `${enterpriseInfo.headquarters_street_number} `;
        address += `${enterpriseInfo.headquarters_street}, ${enterpriseInfo.headquarters_zip} ${enterpriseInfo.headquarters_city}, ${enterpriseInfo.headquarters_country}`;
    }

    if (!address) {
        return ''; // TODO Check whether to return '14 Rue Bausset, 75015 Paris, France' ?
    }

    return address;
};

export const createEnterpirseAddressCamelCase = enterpriseInfo => {
    // 146 Rue des Terres de Borde, 33800 Bordeaux, France
    let address = '';
    if (enterpriseInfo?.headquartersStreet) {
        address = `${enterpriseInfo.headquartersStreetNumber} `;
        address += `${enterpriseInfo.headquartersStreet}, ${enterpriseInfo.headquartersZip} ${enterpriseInfo.headquartersCity}, ${enterpriseInfo.headquartersCountry}`;
    }

    if (!address) {
        return '';
    }

    return address;
};

export const createContactInfoAddress = contactInfo => {
    let address = '';
    if (contactInfo?.address) {
        address = `${contactInfo?.address}, ${contactInfo?.zipCode ?? ''} ${contactInfo?.city ?? ''}, ${contactInfo?.country ?? 'France'}`;
    }

    return address;
};
