import {call, put, select} from 'redux-saga/effects';

import {setIsLoadingApp} from './app.actions';
import {selectIsLoadingApp} from './app.selectors';
import {getCompany} from './company/company.actions';
import {loadFreelancerAccountSaga} from './freelancer/freelancer.sagas';
import {selectFreelancerCompanies} from './freelancer/freelancer.selectors';
import {getUser} from './user/user.actions';
import {selectCurrentCognitoUser} from '../../features/auth/store/auth.selectors';
import {getAndStoreCurrentUserInfo} from '../../features/auth/store/getAndStoreCurrentUserInfo';
import {SettingsActions} from '../../features/settings/store/settings.actions';
import {SettingsSelectors} from '../../features/settings/store/settings.selectors';
import {USER_ROLES} from '../../utils/user-roles';

const loadUserSettings = function* () {
    const settings = yield select(SettingsSelectors.selectSettings);

    if (!settings || typeof settings.shouldReceiveOpportunityNotifications === 'undefined') {
        yield put(SettingsActions.getUserSettings());
    } else {
        yield put(SettingsActions.setIsLoadingUserSettings(false));
    }
};

const loadAdditionalDataForAdministrators = function* (freelancerId, companyId) {
    if (freelancerId) {
        yield put(getUser(freelancerId));

        if (companyId) {
            yield put(getCompany(freelancerId, companyId));
        }
    }

    const isLoadingApp = yield select(selectIsLoadingApp);

    if (isLoadingApp) {
        yield put(setIsLoadingApp(false));
    }
};

export const appLoaderSaga = function* ({payload}) {
    const {params: {freelancerId, userId, companyId}} = payload;
    let currentCognitoUser = yield select(selectCurrentCognitoUser);

    if (!currentCognitoUser) {
        yield call(getAndStoreCurrentUserInfo);
    }

    currentCognitoUser = yield select(selectCurrentCognitoUser);

    yield call(loadUserSettings);

    if (currentCognitoUser && currentCognitoUser.role !== USER_ROLES.FREELANCER) {
        yield call(loadAdditionalDataForAdministrators, freelancerId || userId, companyId);

        return;
    }

    yield call(loadFreelancerAccountSaga, currentCognitoUser.id);

    const companies = yield select(selectFreelancerCompanies);

    if (!companies || !Object.keys(companies).length) {
        yield put(setIsLoadingApp(false));

        return;
    }

    yield put(setIsLoadingApp(false));
};
