import {faArrowRight, faEye, faInfoCircle, faThumbsUp, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Divider, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import {flexCenter} from '../../../utils/styles';
import JoyUIThemeProvider from '../joyui-theme-provider/JoyUiThemeProvider';
import {LoadingSkeleton, LoadingSkeletonProvider} from '../loading-skeleton/LoadingSkeleton';

const variantsConfig = {
    success: {
        icon: faThumbsUp,
        iconSx: {
            color: 'success.solidColor',
        },
        headlineColor: 'success.softActiveColor',
        buttonProps: {
            variant: 'plain',
            color: 'neutral',
        },
    },
    warning: {
        icon: faEye,
        iconSx: {
            color: 'warning.softColor',
        },
        headlineColor: 'warning.softColor',
        buttonProps: {
            variant: 'plain',
            color: 'warning',
        },
        buttonSx: {
            color: 'warning.softColor',
        },
    },
    danger: {
        icon: faTriangleExclamation,
        iconSx: {
            color: 'danger.softColor',
            backgroundColor: 'danger.softBg',
        },
        headlineColor: 'common.red',
        buttonProps: {
            variant: 'soft',
            color: 'danger',
        },
    },
    info: {
        icon: faInfoCircle,
        iconSx: {
            color: 'neutral.600',
        },
        headlineColor: 'success.softActiveColor',
        buttonProps: {
            variant: 'plain',
            color: 'neutral',
        },
    },
};

export const IndicatorBox = ({title, variant, status, headline, description, ctaText, ctaProps, isLoading}) => {
    const {icon, iconSx, headlineColor, buttonProps, buttonSx} = variantsConfig[variant];

    return (
        <JoyUIThemeProvider>
            <LoadingSkeletonProvider isLoading={isLoading}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 'lg',
                        border: '1px solid var(--joy-palette-neutral-outlinedBorder)',
                        p: 2,
                        pt: 1,
                        flex: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: 'background.body',
                            mb: 1,
                            gap: 1,
                        }}
                    >
                        <Typography level="title-sm" textColor="text.tertiary">
                            {title}
                        </Typography>
                        {!isLoading && (
                            <Box
                                sx={{
                                    borderRadius: 'sm',
                                    fontSize: 'xs',
                                    width: '32px',
                                    height: '32px',
                                    ...flexCenter,
                                    ...iconSx,
                                }}
                            >
                                <FontAwesomeIcon icon={icon} />
                            </Box>
                        )}
                    </Box>
                    <Typography level="h4" textColor="neutral.outlinedColor">
                        <LoadingSkeleton placeholder="-----------">{status}</LoadingSkeleton>
                    </Typography>
                    <Typography level="h2" textColor={headlineColor}>
                        <LoadingSkeleton placeholder="-----------">{headline}</LoadingSkeleton>
                    </Typography>
                    <Typography level="body-xs" textColor="text.tertiary" sx={{flexGrow: 1}}>
                        <LoadingSkeleton placeholder="--------------------------------">{description}</LoadingSkeleton>
                    </Typography>
                    <Divider sx={{my: 2}} />
                    <LoadingSkeleton>
                        <Button
                            size="sm"
                            fullWidth
                            {...buttonProps}
                            sx={buttonSx}
                            endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                            component={ctaProps.to ? Link : 'button'}
                            {...ctaProps}
                        >
                            {ctaText}
                        </Button>
                    </LoadingSkeleton>
                </Box>
            </LoadingSkeletonProvider>
        </JoyUIThemeProvider>
    );
};

IndicatorBox.propTypes = {
    title: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['danger', 'warning', 'info', 'success']),
    status: PropTypes.node,
    headline: PropTypes.node,
    description: PropTypes.node,
    ctaText: PropTypes.string,
    ctaProps: PropTypes.object,
    isLoading: PropTypes.bool,
};

IndicatorBox.defaultProps = {
    variant: 'info',
    status: undefined,
    headline: undefined,
    description: undefined,
    ctaText: undefined,
    ctaProps: {},
    isLoading: undefined,
};

export default IndicatorBox;
