import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PersonIcon from '@mui/icons-material/Person';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const UserListCompanyAndUser = ({rowData}) => {
    const typographySx = {
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        color: 'v2.light.primary.main',
        textDecoration: 'none',
        fontSize: theme => theme.typography.pxToRem(14),
    };
    const iconSx = {
        color: 'v2.blueGray.100',
        fontSize: theme => theme.typography.pxToRem(16),
    };

    const companyName = rowData?.companyName ?? rowData?.name;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 0.5,
        }}
        >
            {companyName && (
            <Typography sx={{...typographySx, fontWeight: 700}} className="company">
                <BusinessCenterIcon sx={iconSx} />
                {companyName}
            </Typography>
            )}
            <Typography sx={typographySx}>
                <PersonIcon sx={iconSx} />
                {rowData?.userFullName}
            </Typography>
        </Box>

    );
};

UserListCompanyAndUser.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default UserListCompanyAndUser;
