import {Box, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AdminOpportunityList} from './admin-opportunity/AdminOpportunityList';
import {importLocaleBundle} from '../../../lib/i18next';

importLocaleBundle('opportunity');

export const AdminOpportunity = () => {
    const {t} = useTranslation('opportunity');

    return (
        <Box>
            <Typography variant="h4" sx={{mb: 2}}>
                {t('header')}
            </Typography>

            <AdminOpportunityList />
        </Box>
    );
};
