export const CHANGE_NEW_PASSWORD = 'CHANGE_NEW_PASSWORD';
export const CHANGE_CONFIRM_NEW_PASSWORD = 'CHANGE_CONFIRM_NEW_PASSWORD';
export const NEW_PASSWORD_FORM_SUCCESS = 'NEW_PASSWORD_FORM_SUCCESS';
export const NEW_PASSWORD_FORM_ERROR = 'NEW_PASSWORD_FORM_ERROR';

export const changeConfirmNewPassword = confirmNewPassword => ({
    type: CHANGE_CONFIRM_NEW_PASSWORD,
    payload: confirmNewPassword,
});

export const storeNewPasswordFormError = newPasswordFormError => ({
    type: NEW_PASSWORD_FORM_ERROR,
    payload: newPasswordFormError,
});

export const storeNewPasswordFormSuccess = () => ({
    type: NEW_PASSWORD_FORM_SUCCESS,
});
