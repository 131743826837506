import moment from 'moment';
import {convertBoTrainingStatus} from '../utils/helper';

/**
 * @typedef {Object} trainingDTO
 * @property {String} status
 * @property {String} current_step
 * @property {boolean} is_eligible
 * @property {String} start_date
 * @property {String} end_date
 * @property {String} completed_date
 * @property {String} atlas_file_number
 * @property {boolean} is_atlas_validation_rejected_email_sent
 */

export const BoTrainingInfoFrom = function (trainingDTO) {
    return {
        isEligibleForTraining: trainingDTO.is_eligible,
        trainingStatus: convertBoTrainingStatus(trainingDTO.status),
        startDate: trainingDTO.start_date,
        endDate: trainingDTO.end_date,
        completedDate: trainingDTO.completed_date,
        fileNumber: trainingDTO.atlas_file_number,
        isRejectedEmailSent: trainingDTO.is_atlas_validation_rejected_email_sent,
    };
};

/**
 * @typedef {Object} TrainingInfoPayload
 * @property {boolean} isEligibleForTraining
 * @property {date} startDate
 * @property {string} fileNumber
 */

export const BoTrainingInfoTo = function (trainingInfo) {
    const {eligibleForTraining, trainingStartDate, fileNumber} = trainingInfo;
    return {
        is_eligible: eligibleForTraining,
        ...(trainingStartDate) && {start_date: moment(trainingInfo.trainingStartDate).format('DD/MM/YYYY')},
        ...(fileNumber) && {atlas_file_number: fileNumber},
    };
};
