import {Box, Typography} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ClientName from './ClientName';
import Description from './Description';
import Location from './Location';
import Rate from './Rate';
import RequiredProfile from './RequiredProfile';
import {styles} from './styles';

const CommonForMobile = ({rowData}) => {
    const {t} = useTranslation('opportunity');

    return (
        <Box sx={styles.container}>
            <Box sx={styles.row}>
                <Typography variant="body2" sx={styles.label}>
                    {t('element.header.clientName')}
                </Typography>
                <Box component="span" sx={styles.value}>
                    <ClientName rowData={rowData} />
                </Box>
            </Box>
            <Box sx={styles.row}>
                <Typography variant="body2" sx={styles.label}>
                    {t('element.header.date')}
                </Typography>
                <Box component="span" sx={styles.value}>
                    {moment(rowData.createdAt).format('DD/MM/YYYY')}
                </Box>
            </Box>
            <Box sx={styles.row}>
                <Typography variant="body2" sx={styles.label}>
                    {t('element.header.profile')}
                </Typography>
                <Box component="span" sx={styles.value}>
                    <RequiredProfile rowData={rowData} />
                </Box>
            </Box>
            <Box sx={styles.row}>
                <Typography variant="body2" sx={styles.label}>
                    {t('element.header.tjm')}
                </Typography>
                <Box component="span" sx={styles.value}>
                    <Rate rowData={rowData} />
                </Box>
            </Box>
            <Box sx={styles.row}>
                <Typography variant="body2" sx={styles.label}>
                    {t('element.header.mission')}
                </Typography>
                <Box component="span" sx={styles.value}>
                    {!rowData.startDate ? '-' : moment(rowData.startDate, 'DD/MM/YYYY').format('MMMM YYYY')}
                </Box>
            </Box>
            <Box sx={styles.row}>
                <Typography variant="body2" sx={styles.label}>
                    {t('element.header.location')}
                </Typography>
                <Box component="span" sx={styles.value}>
                    <Location rowData={rowData} />
                </Box>
            </Box>
            <Box sx={styles.row}>
                <Typography variant="body2" sx={styles.label}>
                    {t('element.header.files')}
                </Typography>
                <Box component="span" sx={styles.value}>
                    <Description rowData={rowData} />
                </Box>
            </Box>
        </Box>
    );
};

CommonForMobile.propTypes = {
    rowData: PropTypes.any.isRequired,
};

export default React.memo(CommonForMobile);
