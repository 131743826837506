import makeStyles from '@mui/styles/makeStyles';

export const useCompanyChipStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1, 0, 3),
        minHeight: 24,
    },
    companyIdLabel: {
        fontSize: theme.typography.pxToRem(14),
        letterSpacing: '0.1px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    companyId: {
        fontSize: theme.typography.pxToRem(14),
        letterSpacing: '0.1px',
        color: 'rgba(0, 0, 0, 0.87)',
    },
}));
