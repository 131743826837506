import {Box} from '@mui/joy';
import PropTypes from 'prop-types';

export const BoxRoleButton = props => {
    const {onPress, sx, ...other} = props;

    const handleKeyDown = event => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onPress?.(event);
        }
    };

    return (
        <Box
            role="button"
            tabIndex={0}
            onClick={onPress}
            onKeyDown={handleKeyDown}
            sx={{
                cursor: 'pointer',
                ...sx,
            }}
            {...other}
        />
    );
};

BoxRoleButton.propTypes = {
    onPress: PropTypes.func.isRequired,
    sx: PropTypes.object,
};

BoxRoleButton.defaultProps = {
    sx: {},
};
