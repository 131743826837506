import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import SchoolIcon from '@mui/icons-material/School';
import React from 'react';

export const BO_TRAINING_STATUS = {
    PENDING: 'PENDING',
    ONGOING_STAGE_1: 'ONGOING_STAGE_1',
    ONGOING_STAGE_2: 'ONGOING_STAGE_2',
    ONGOING_STAGE_3_NOT_SIGNED: 'ONGOING_STAGE_3_NOT_SIGNED',
    ONGOING_STAGE_3_SIGNED: 'ONGOING_STAGE_3_SIGNED',
    ONGOING_STAGE_4_PENDING: 'ONGOING_STAGE_4_PENDING',
    ONGOING_STAGE_4_REFUSED: 'ONGOING_STAGE_4_REFUSED',
    ONGOING_STAGE_4_VERIFIED: 'ONGOING_STAGE_4_VERIFIED',
    ONGOING_STAGE_5_PENDING: 'ONGOING_STAGE_5_PENDING',
    VALIDATED: 'VALIDATED',
};

const ongoingBase = {
    statusIcon: <SchoolIcon sx={{mr: 1, color: 'primary_v2.main'}} />,
    statusTranslationKey: 'ONGOING',
};

export const BO_TRAINING_STATUS_DATA = {
    PENDING: {
        statusIcon: <HourglassTopIcon sx={{mr: 1, color: 'warning_v2.main'}} />,
        statusTranslationKey: 'PENDING',
    },
    ONGOING_STAGE_1: {
        ...ongoingBase,
        stageNumber: 1,
    },
    ONGOING_STAGE_2: {
        ...ongoingBase,
        stageNumber: 2,
    },
    ONGOING_STAGE_3_NOT_SIGNED: {
        ...ongoingBase,
        stageNumber: 3,
        stageStatusTranslationKey: 'pendingSignature',
        stageStatusColor: 'warning_v2.main',
    },
    ONGOING_STAGE_3_SIGNED: {
        ...ongoingBase,
        stageNumber: 3,
        stageStatusTranslationKey: 'signed',
        stageStatusColor: 'success_v2.main',
    },
    ONGOING_STAGE_4_PENDING: {
        ...ongoingBase,
        stageNumber: 4,
        stageStatusTranslationKey: 'PENDING',
        stageStatusColor: 'warning_v2.main',
    },
    ONGOING_STAGE_4_REFUSED: {
        ...ongoingBase,
        stageNumber: 4,
        stageStatusTranslationKey: 'refused',
        stageStatusColor: 'error_v2.main',
    },
    ONGOING_STAGE_4_REFUSED_WITH_EMAIL: {
        ...ongoingBase,
        stageNumber: 4,
        stageStatusTranslationKey: 'refused',
        stageStatusColor: 'error_v2.main',
    },
    ONGOING_STAGE_4_VERIFIED: {
        ...ongoingBase,
        stageNumber: 4,
        stageStatusTranslationKey: 'valid',
        stageStatusColor: 'success_v2.main',
    },
    ONGOING_STAGE_5_PENDING: {
        ...ongoingBase,
        stageNumber: 5,
        stageStatusColor: 'warning_v2.main',
    },
    VALIDATED: {
        statusIcon: <CheckCircleIcon sx={{mr: 1, color: 'success_v2.light'}} />,
        statusTranslationKey: 'COMPLETED',
    },
};

export const BE_TRAINING_STATUS = {
    // Init status:
    PENDING: 'PENDING',
    // STEP 1
    ATLAS_APPLICATION: 'ATLAS_APPLICATION',
    // STEP 2
    ATLAS_ACCOUNT_CREATION: 'ATLAS_ACCOUNT_CREATION',
    // STEP 3
    ATLAS_SUPPORT_PENDING_SIGNATURE: 'ATLAS_SUPPORT_PENDING_SIGNATURE',
    ATLAS_SUPPORT_DOCUMENTS_PAGE: 'ATLAS_SUPPORT_DOCUMENTS_PAGE',
    ATLAS_SUPPORT_PENDING_FILE_NUMBER: 'ATLAS_SUPPORT_PENDING_FILE_NUMBER',
    // STEP 4
    ATLAS_VALIDATION_PENDING: 'ATLAS_VALIDATION_PENDING',
    ATLAS_VALIDATION_REJECTED: 'ATLAS_VALIDATION_REJECTED',
    ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE: 'ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE',
    ATLAS_VALIDATION_SUCCESS_CHECKLIST: 'ATLAS_VALIDATION_SUCCESS_CHECKLIST',
    // STEP 5
    ATLAS_FINALIZATION_PENDING_PAYMENT: 'ATLAS_FINALIZATION_PENDING_PAYMENT',
    // Final status:
    COMPLETED: 'COMPLETED',
};

export const BO_TRAINING_INFO_DEFAULTS = {
    isEligibleForTraining: false,
    trainingStatus: BO_TRAINING_STATUS.PENDING,
    startDate: null,
    endDate: null,
    completedDate: null,
    fileNumber: null,
    isAtlasValidationRejectedEmailSent: false,
};
