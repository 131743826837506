import {QuickAccessActionType} from './quick-access.action-type';

export const QuickAccessAction = {
    getQuickAccessDocs: payload => ({
        type: QuickAccessActionType.GET_QUICK_ACCESS_DOCS,
        payload,
    }),
    reissueVigilanceCert: payload => ({
        type: QuickAccessActionType.REISSUE_VIGILANCE_CERT,
        payload,
    }),
    storeVigilanceCert: payload => ({
        type: QuickAccessActionType.STORE_VIGILANCE_CERT,
        payload,
    }),
    storeBalanceSheet: payload => ({
        type: QuickAccessActionType.STORE_BALANCE_SHEET,
        payload,
    }),
    storeKbis: payload => ({
        type: QuickAccessActionType.STORE_KBIS,
        payload,
    }),
};
