import {Box, Stack, Typography, useTheme} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {ReactComponent as HiwayText} from '../../../../assets/svg/logo/hiway.svg';

export const ErrPageComponent = ({onSubmit, title, children}) => {
    const theme = useTheme();
    const color = theme.vars.palette.text.cocoa;

    return (
        <Box
            component="form"
            onSubmit={onSubmit}
            sx={{
                minWidth: {
                    mobile: 'min(calc(100vw - 100px), 600px)',
                    desktop: 'min(calc(50vw - 240px), 800px)',
                    wide: 'min(calc(50vw - 300px), 800px)',
                },
                maxWidth: {
                    mobile: '600px',
                    desktop: '800px',
                },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                flex: 1,
            }}
            noValidate
        >
            <Stack
                direction="column"
                alignItems="center"
            >
                <HiwayText
                    height="49px"
                    width="217px"
                    fill={color}
                />

                {!!title && (
                    <Typography
                        level="body-lg"
                        sx={{
                            color: 'text.secondary',
                            m: {desktop: 5, mobile: 3},
                            fontSize: {wide: 'xl4', desktop: 'xl3', mobile: 'xl'},
                            textAlign: 'center',
                        }}
                    >
                        {title}
                    </Typography>
                )}
            </Stack>

            {children}
        </Box>
    );
};

ErrPageComponent.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    title: PropTypes.string,
};

ErrPageComponent.defaultProps = {
    title: null,
};
