import {Box} from '@mui/joy';
import PropTypes from 'prop-types';
import Grow from '../../../../../../components/joy-ui/mui-legacy/Grow';

const FlowStepWrapper = ({children}) => {
    return (
        <Grow in={true}>
            <Box component="div" sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {children}
            </Box>
        </Grow>
    );
};

FlowStepWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default FlowStepWrapper;
