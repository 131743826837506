import {bankApiInstance} from '../../../../../lib/bank-api-instance';

const getRecurringTransfersList = ({accountId}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/accounts/${accountId}/transfer-orders/recurring`,
    }).then(result => result?.data);
};

const createSingleTransfers = ({accountId, data}) => {
    return bankApiInstance({
        method: 'POST',
        url: `/v3/bank/accounts/${accountId}/transfer-orders/one-time`,
        data,
    }).then(result => result?.data);
};

const createRecurringTransfers = ({accountId, data}) => {
    return bankApiInstance({
        method: 'POST',
        url: `/v3/bank/accounts/${accountId}/transfer-orders/recurring`,
        data,
    }).then(result => result?.data);
};

const updateTransfers = ({accountId, data, id}) => {
    return bankApiInstance({
        method: 'PUT',
        url: `/v3/bank/accounts/${accountId}/transfer-orders/${id}`,
        data,
    }).then(result => result?.data);
};

const cancelTransfers = ({accountId, id}) => {
    return bankApiInstance({
        method: 'PUT',
        url: `/v3/bank/accounts/${accountId}/transfer-orders/${id}/cancel`,
    }).then(result => result?.data);
};

const uploadTransferFile = ({accountId, id, files}) => {
    const formData = new FormData();

    files.forEach(file => {
        formData.append('files', file);
    });

    return bankApiInstance({
        method: 'POST',
        url: `/v3/bank/accounts/${accountId}/transfer-orders/${id}/documents/upload`,
        data: formData,
    }).then(result => result?.data);
};

const getTransferDetails = ({accountId, id}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/accounts/${accountId}/transfer-orders/${id}`,
    }).then(result => result?.data);
};

const deleteTransferFile = ({
    accountId,
    fileId,
    transferId,
}) => {
    return bankApiInstance({
        method: 'DELETE',
        url: `/v3/bank/accounts/${accountId}/transfer-orders/${transferId}/documents/${fileId}`,
    }).then(result => result?.data);
};

const getRemuneration = ({
    accountId,
}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/accounts/${accountId}/transfer-orders/remunerations`,
    }).then(result => result?.data);
};


export const TransfersApi = {
    getRecurringTransfersList,
    createSingleTransfers,
    getTransferDetails,
    createRecurringTransfers,
    uploadTransferFile,
    updateTransfers,
    cancelTransfers,
    deleteTransferFile,
    getRemuneration,
};
