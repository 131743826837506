import makeStyles from '@mui/styles/makeStyles';

export const useDocumentUploadStepTitle = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    stepNumber: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        padding: 12,
        borderRadius: '100%',
        backgroundColor: 'rgba(11, 35, 51, 0.38)',
        color: '#FFFFFF',
        marginRight: theme.spacing(2),
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightBold,
    },
    completed: {
        backgroundColor: 'rgba(46, 125, 50, 0.12)',
        color: '#4CAF50',
        fontSize: theme.typography.pxToRem(20),
    },
    title: {
        fontSize: theme.typography.pxToRem(21),
        fontWeight: theme.typography.fontWeightBold,
        letterSpacing: '0.1px',
        color: '#0B2333',
        [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.pxToRem(18),
        },
    },
}));
