import AddIcon from '@mui/icons-material/Add';
import {Box, Button, Paper, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, generatePath} from 'react-router-dom';
import CreateInvoiceSvg from '../../../../assets/svg/invoicing/create-invoice.svg';
import {RoutePaths} from '../../../../lib/router/route-paths';

export const NewOpportunityCard = () => {
    const {t} = useTranslation('opportunity');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Paper
            sx={{
                width: isMobileSize ? '100%' : '33%',
                borderRadius: 4,
                p: isMobileSize ? 2 : 3,
                pt: 2,
                color: 'text_v2.secondary',
                alignSelf: isMobileSize ? 'flex-start' : 'stretch',
            }}
            elevation={1}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%',
            }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        alignSelf: 'flex-start',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {t('freelancerOpportunity.newOpportunity')}
                </Typography>

                {!isMobileSize && (
                    <Box><img src={CreateInvoiceSvg} alt="Take an opportunity" /></Box>
                )}

                <Typography sx={{textAlign: 'center'}}>
                    {t('freelancerOpportunity.newMissingDescription')}
                </Typography>

                <Button
                    component={Link}
                    to={generatePath(RoutePaths.CLUSTER_SUBMIT)}
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                    fullWidth
                >
                    {t('freelancerOpportunity.submitMissing')}
                </Button>
            </Box>
        </Paper>
    );
};
