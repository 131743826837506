import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getPickersPopperUtilityClass = slot => {
    return generateUtilityClass('MuiPickersPopper', slot);
};

export const pickersPopperClasses = generateUtilityClasses('MuiPickersPopper', [
    'root',
    'paper',
]);
