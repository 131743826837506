import makeStyles from '@mui/styles/makeStyles';

export const useCompanyCardStyles = makeStyles(theme => ({
    root: {
        borderRadius: 16,
        border: '1px solid #E0E0E0',
        padding: theme.spacing(2),
    },
    noBorder: {
        border: 0,
    },
    header: {
        'display': 'inline-flex',
        'alignItems': 'center',
        'marginBottom': theme.spacing(2),
        '&:hover': {
            textDecoration: 'none',
        },
    },
    imageContainer: {
        marginRight: theme.spacing(1),
    },
    image: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        backgroundColor: '#A5D6A7',
        borderRadius: 40,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
        color: '#fff',
    },
    name: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
        color: '#0b2333',
        lineHeight: '20px',
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: '',
    },
}));
