import {createSelector} from 'reselect';

export const selectIsLoadingUsers = state => state.v1User.userList.isLoading;

export const selectUsers = state => state.v1User.userList.users;

export const selectUsersNumber = state => state.v1User.userList.usersNumber;

export const createUsersSelector = () => createSelector(
    [selectUsers, selectUsersNumber],
    (users, totalLength) => {
        if (!Object.keys(users).length) {
            return {data: [], count: 0};
        }

        const data = Object.values(users);

        return {data, count: totalLength};
    },
);
