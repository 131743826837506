import {createSelector} from 'reselect';

const selectIds = state => state.backoffice.ids;
const selectEntities = state => state.backoffice.entities;
const createCompanyByIdSelector = id => createSelector(
    [selectEntities],
    entities => entities[id],
);
const selectShortFilterCount = state => state.backoffice.shortFilterCount;
const selectTotalCount = state => state.backoffice.totalCount;
const selectKbisDocument = state => state.backoffice.kbisDocument;
const selectLegalNoticeDocument = state => state.backoffice.legalNoticeDocument;
const selectIsRegistrationSaved = state => state.backoffice.isRegistrationSaved;

export const BackofficeSelector = {
    selectIds,
    selectEntities,
    createCompanyByIdSelector,
    selectShortFilterCount,
    selectTotalCount,
    selectKbisDocument,
    selectLegalNoticeDocument,
    selectIsRegistrationSaved,
};
