import makeStyles from '@mui/styles/makeStyles';

export const useCompanyStatusStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    icon: {
        fontSize: theme.typography.pxToRem(20),
    },
    activeIcon: {
        color: theme.palette.success.main,
    },
    pendingCompanyIdIcon: {
        color: theme.palette.warning.main,
    },
    pendingInitialSignaturesIcon: {
        color: theme.palette.error.main,
    },
    pendingFinalSignaturesIcon: {
        color: theme.palette.error.main,
    },
    pendingDataInputIcon: {
        color: theme.palette.primary.main,
    },
    userDeactivatedIcon: {
        color: theme.palette.error.main,
    },
    readyForReview: {
        color: theme.palette.primary.main,
    },
    trainingIcon: {
        fontSize: theme.typography.pxToRem(24),
        color: '#1F1F1F',
        opacity: 0.3,
    },
    label: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        color: 'rgba(0, 0, 0, 0.87)',
    },
}));
