import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WarningIcon from '@mui/icons-material/Warning';
import {Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ModalWrapper} from '../../../components/modal-wrapper/ModalWrapper';
import {UiActions} from '../../ui/store/ui.action';
import {ModalsKeys} from '../../ui/utils/constants';
import {InsuranceActions} from '../store/insurance.action';
import {InsuranceStatus} from '../utils/constants';

const modalKey = ModalsKeys.INSURANCE_SKIP_MODAL;

const SkipModal = ({taxSystem, freelancerId, companyId, insuranceAlertOnCloseHandler}) => {
    const [value, setValue] = useState(null);

    const dispatch = useDispatch();

    const {t} = useTranslation('insurance');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const handleChange = event => {
        setValue(event.target.value);
    };

    const handleCloseSkipModal = () => {
        dispatch(UiActions.setActiveModal(modalKey, false));
        setValue(null);
    };

    const handleConfirm = () => {
        dispatch(InsuranceActions.skipInsurance({
            freelancerId,
            companyId,
            data: {status: InsuranceStatus.NOT_SIGNED, set_reminder: value === 'true'},
        }));

        if (insuranceAlertOnCloseHandler) {
            insuranceAlertOnCloseHandler();
        }

        handleCloseSkipModal();
    };

    return (
        <ModalWrapper
            modalKey={modalKey}
            handleCloseCB={handleCloseSkipModal}
            hasShowClose={true}
            containerSx={{width: '608px'}}
            title={(
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(34),
                    fontWeight: 700,
                    lineHeight: '116.7%',
                    pb: 1,
                }}
                >
                    {t(insuranceAlertOnCloseHandler ? `skipModal.${taxSystem}.titleAlt` : `skipModal.${taxSystem}.title`)}
                </Typography>
            )}
        >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 6}}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2}}>
                    <Box sx={{
                        display: 'flex',
                        padding: 2,
                        alignItems: 'center',
                        gap: 1.5,
                        alignSelf: 'stretch',
                        borderRadius: 3,
                        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #0288D1',
                    }}
                    >
                        <WarningIcon />
                        <Typography
                            sx={{fontSize: theme => theme.typography.pxToRem(14)}}
                        >
                            {t(insuranceAlertOnCloseHandler ? `skipModal.${taxSystem}.warningAlt` : `skipModal.${taxSystem}.warning`)}
                        </Typography>
                    </Box>
                    <FormControl>
                        <RadioGroup
                            value={value}
                            onChange={handleChange}
                            sx={{gap: 2}}
                        >
                            <FormControlLabel
                                sx={{
                                    display: 'flex',
                                    padding: '12px 16px 12px 8px',
                                    alignItems: 'center',
                                    gap: 0.5,
                                    alignSelf: 'stretch',
                                    borderRadius: 4,
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    backgroundColor: 'whitePallete.main',
                                    m: 0,
                                }}
                                value="false"
                                control={<Radio size="medium" />}
                                label={(
                                    <Box>
                                        <Typography sx={{fontWeight: 500}}>
                                            {t(`skipModal.${taxSystem}.noSubscribe`)}
                                        </Typography>
                                        <Typography sx={{fontSize: theme => theme.typography.pxToRem(14)}}>
                                            {t(`skipModal.${taxSystem}.noSubscribeDesc`)}
                                        </Typography>
                                    </Box>
                                )}
                            />
                            <FormControlLabel
                                sx={{
                                    display: 'flex',
                                    padding: '12px 16px 12px 8px',
                                    alignItems: 'center',
                                    gap: 0.5,
                                    alignSelf: 'stretch',
                                    borderRadius: 4,
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    backgroundColor: 'whitePallete.main',
                                    m: 0,
                                }}
                                value="true"
                                control={<Radio size="medium" />}
                                label={(
                                    <Box>
                                        <Typography sx={{fontWeight: 500}}>
                                            {t(`skipModal.${taxSystem}.remindLater`)}
                                        </Typography>
                                        <Typography sx={{fontSize: theme => theme.typography.pxToRem(14)}}>
                                            {t(`skipModal.${taxSystem}.remindLaterDesc`)}
                                        </Typography>
                                    </Box>
                                )}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: isMobileSize ? 'column' : 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: isMobileSize ? 2 : 3,
                    flex: '1 0 0',
                    alignSelf: 'stretch',
                }}
                >
                    <Button
                        fullWidth={isMobileSize}
                        variant="outlined"
                        startIcon={<ArrowBackIcon />}
                        onClick={handleCloseSkipModal}
                    >
                        {t('skipModal.back')}
                    </Button>
                    <Button
                        fullWidth={isMobileSize}
                        variant="contained"
                        color="secondary"
                        disabled={!value}
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleConfirm}
                    >
                        {t('skipModal.confirm')}
                    </Button>
                </Box>
            </Box>
        </ModalWrapper>
    );
};

SkipModal.propTypes = {
    taxSystem: PropTypes.string.isRequired,
    freelancerId: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    insuranceAlertOnCloseHandler: PropTypes.func,
};

SkipModal.defaultProps = {
    insuranceAlertOnCloseHandler: null,
};

export default SkipModal;
