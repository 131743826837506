import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {splitNumber} from '../../../../../../../../utils/number';

const CategorisationBalance = ({amount, currency, isCredit}) => {
    const {integer, decimal} = splitNumber(amount);

    const sign = isCredit ? '+' : '-';

    return (
        <Box sx={{
            position: 'relative',
            textWrap: 'nowrap',
        }}
        >
            <Typography sx={{
                display: 'inline',
                fontSize: theme => theme.typography.pxToRem(28),
                lineHeight: theme => theme.typography.pxToRem(32),
                color: isCredit ? 'v2.light.success.light' : 'text_v2.secondary',
                letterSpacing: '-0.1px',
                fontWeight: '500',
            }}
            >
                {sign}{integer}
            </Typography>
            <Typography sx={{
                display: 'inline',
                fontSize: theme => theme.typography.pxToRem(20),
                lineHeight: theme => theme.typography.pxToRem(24),
                color: isCredit ? 'v2.light.success.light' : 'text_v2.secondary',
                letterSpacing: '-0.1px',
                fontWeight: '500',
            }}
            >
                ,{decimal} {currency}
            </Typography>
        </Box>
    );
};

CategorisationBalance.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currency: PropTypes.string,
    isCredit: PropTypes.bool.isRequired,
};

CategorisationBalance.defaultProps = {
    currency: '€',
};

export default CategorisationBalance;
