import {Box} from '@mui/material';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {FORMALITY_SECTION_STATUS} from '../../utils/constants';

const chipStyles = {
    '& .MuiChip-label': {
        lineHeight: '1em',
        px: 1,
        fontSize: theme => theme.typography.pxToRem(10),
    },
    '&.MuiChip-root': {
        borderRadius: 1,
        height: '18px',
    },
};

const SectionStatus = ({status}) => {
    const {t} = useTranslation('companies');

    const chip = useMemo(() => {
        switch (status) {
            case FORMALITY_SECTION_STATUS.REQUIRED:
                return (
                    <Chip
                        sx={{
                            'backgroundColor': 'v2.light.warning.shades4',
                            'color': 'v2.light.warning.main',
                            ...chipStyles,
                        }}
                        label={t(`accounting.uploadFEC.statuses.${FORMALITY_SECTION_STATUS.REQUIRED}`)}
                    />
                );

            case FORMALITY_SECTION_STATUS.WAITING_FOR_REPORT_UPLOAD:
                return (
                    <Chip
                        sx={{
                            'backgroundColor': 'gray_v2.100',
                            'color': 'v2.blueGray.300',
                            ...chipStyles,
                        }}
                        label={t(`accounting.uploadFEC.statuses.${FORMALITY_SECTION_STATUS.WAITING_FOR_REPORT_UPLOAD}`)}
                    />
                );

            case FORMALITY_SECTION_STATUS.WAITING_FOR_FINAL_FEC_UPLOAD:
                return (
                    <Chip
                        sx={{
                            'backgroundColor': 'gray_v2.100',
                            'color': 'v2.blueGray.300',
                            ...chipStyles,
                        }}
                        label={t(`accounting.uploadFEC.statuses.${FORMALITY_SECTION_STATUS.WAITING_FOR_FINAL_FEC_UPLOAD}`)}
                    />
                );

            case FORMALITY_SECTION_STATUS.WAITING_FOR_SIGNATURE:
                return (
                    <Chip
                        sx={{
                            'backgroundColor': 'gray_v2.100',
                            'color': 'v2.blueGray.300',
                            ...chipStyles,
                        }}
                        label={t(`accounting.uploadFEC.statuses.${FORMALITY_SECTION_STATUS.WAITING_FOR_SIGNATURE}`)}
                    />
                );

            case FORMALITY_SECTION_STATUS.WAITING_FOR_PAYMENT:
                return (
                    <Chip
                        sx={{
                            'backgroundColor': 'gray_v2.100',
                            'color': 'v2.blueGray.300',
                            ...chipStyles,
                        }}
                        label={t(`accounting.uploadFEC.statuses.${FORMALITY_SECTION_STATUS.WAITING_FOR_PAYMENT}`)}
                    />
                );

            default:
                return '';
        }
    }, [status, t]);

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            fontWeight: 500,
        }}
        >
            {chip}
        </Box>
    );
};

SectionStatus.propTypes = {
    status: PropTypes.string.isRequired,
};

export default React.memo(SectionStatus);
