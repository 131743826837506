/* eslint-disable react/require-default-props */
/* eslint-disable react/boolean-prop-naming */
import composeClasses from '@mui/base/composeClasses';
import {styled, useTheme} from '@mui/styles';
import {useThemeProps} from '@mui/system';
import {
    unstable_useControlled as useControlled,
    unstable_useEventCallback as useEventCallback,
} from '@mui/utils';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';
import {getMonthPickerUtilityClass} from './monthPickerClasses';
import {PickersMonth} from './PickersMonth';
import {useDefaultDates, useNow, useUtils} from '../internals/hooks/useUtils';
import {parseNonNullablePickerDate} from '../internals/utils/date-utils';

const useUtilityClasses = ownerState => {
    const {classes} = ownerState;

    const slots = {
        root: ['root'],
    };

    return composeClasses(slots, getMonthPickerUtilityClass, classes);
};

export const useMonthPickerDefaultizedProps = (props, name) => {
    const utils = useUtils();
    const defaultDates = useDefaultDates();
    const themeProps = useThemeProps({
        props,
        name,
    });

    return {
        disableFuture: false,
        disablePast: false,
        ...themeProps,
        minDate: parseNonNullablePickerDate(
            utils,
            themeProps.minDate,
            defaultDates.minDate,
        ),
        maxDate: parseNonNullablePickerDate(
            utils,
            themeProps.maxDate,
            defaultDates.maxDate,
        ),
    };
};

const MonthPickerRoot = styled('div', {
    name: 'MuiMonthPicker',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})({
    width: 310,
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'stretch',
    margin: '0 4px',
});

export const MonthPicker = React.forwardRef((inProps, ref) => {
    const utils = useUtils();
    const now = useNow();
    const props = useMonthPickerDefaultizedProps(inProps, 'MuiMonthPicker');

    const {
        className,
        date,
        disabled,
        disableFuture,
        disablePast,
        maxDate,
        minDate,
        onChange,
        shouldDisableMonth,
        readOnly,
        disableHighlightToday,
        autoFocus = false,
        onMonthFocus,
        hasFocus,
        onFocusedViewChange,
        ...other
    } = props;
    const ownerState = props;
    const classes = useUtilityClasses(ownerState);
    const theme = useTheme();
    const selectedDateOrStartOfMonth = React.useMemo(
        () => date ?? utils.startOfMonth(now),
        [now, utils, date],
    );

    const selectedMonth = React.useMemo(() => {
        if (date != null) {
            return utils.getMonth(date);
        }

        if (disableHighlightToday) {
            return null;
        }

        return utils.getMonth(now);
    }, [now, date, utils, disableHighlightToday]);
    const [focusedMonth, setFocusedMonth] = React.useState(
        () => selectedMonth || utils.getMonth(now),
    );

    const isMonthDisabled = React.useCallback(
        month => {
            const firstEnabledMonth = utils.startOfMonth(
                disablePast && utils.isAfter(now, minDate) ? now : minDate,
            );

            const lastEnabledMonth = utils.startOfMonth(
                disableFuture && utils.isBefore(now, maxDate) ? now : maxDate,
            );

            if (utils.isBefore(month, firstEnabledMonth)) {
                return true;
            }

            if (utils.isAfter(month, lastEnabledMonth)) {
                return true;
            }

            if (!shouldDisableMonth) {
                return false;
            }

            return shouldDisableMonth(month);
        },
        [
            disableFuture,
            disablePast,
            maxDate,
            minDate,
            now,
            shouldDisableMonth,
            utils,
        ],
    );

    const onMonthSelect = month => {
        if (readOnly) {
            return;
        }

        const newDate = utils.setMonth(selectedDateOrStartOfMonth, month);
        onChange(newDate, 'finish');
    };

    const [internalHasFocus, setInternalHasFocus] = useControlled({
        name: 'MonthPicker',
        state: 'hasFocus',
        controlled: hasFocus,
        default: autoFocus,
    });

    const changeHasFocus = React.useCallback(
        newHasFocus => {
            setInternalHasFocus(newHasFocus);

            if (onFocusedViewChange) {
                onFocusedViewChange(newHasFocus);
            }
        },
        [setInternalHasFocus, onFocusedViewChange],
    );

    const focusMonth = React.useCallback(
        month => {
            if (!isMonthDisabled(utils.setMonth(selectedDateOrStartOfMonth, month))) {
                setFocusedMonth(month);
                changeHasFocus(true);
                if (onMonthFocus) {
                    onMonthFocus(month);
                }
            }
        },
        [
            isMonthDisabled,
            utils,
            selectedDateOrStartOfMonth,
            changeHasFocus,
            onMonthFocus,
        ],
    );

    React.useEffect(() => {
        setFocusedMonth(prevFocusedMonth => (selectedMonth !== null && prevFocusedMonth !== selectedMonth
            ? selectedMonth
            : prevFocusedMonth));
    }, [selectedMonth]);

    const handleKeyDown = useEventCallback(event => {
        const monthsInYear = 12;
        const monthsInRow = 3;

        switch (event.key) {
            case 'ArrowUp':
                focusMonth((monthsInYear + focusedMonth - monthsInRow) % monthsInYear);
                event.preventDefault();
                break;
            case 'ArrowDown':
                focusMonth((monthsInYear + focusedMonth + monthsInRow) % monthsInYear);
                event.preventDefault();
                break;
            case 'ArrowLeft':
                focusMonth(
                    (monthsInYear + focusedMonth + (theme.direction === 'ltr' ? -1 : 1))
                        % monthsInYear,
                );

                event.preventDefault();
                break;
            case 'ArrowRight':
                focusMonth(
                    (monthsInYear + focusedMonth + (theme.direction === 'ltr' ? 1 : -1))
                        % monthsInYear,
                );

                event.preventDefault();
                break;
            default:
                break;
        }
    });

    const handleMonthFocus = React.useCallback(
        (_, month) => {
            focusMonth(month);
        },
        [focusMonth],
    );

    const handleMonthBlur = React.useCallback(() => {
        changeHasFocus(false);
    }, [changeHasFocus]);

    const currentMonthNumber = utils.getMonth(now);

    return (
        <MonthPickerRoot
            ref={ref}
            className={clsx(classes.root, className)}
            ownerState={ownerState}
            onKeyDown={handleKeyDown}
            {...other}
        >
            {utils.getMonthArray(selectedDateOrStartOfMonth).map(month => {
                const monthNumber = utils.getMonth(month);
                const monthText = utils.format(month, 'monthShort');
                const isDisabled = disabled || isMonthDisabled(month);

                return (
                    <PickersMonth
                        key={monthText}
                        value={monthNumber}
                        selected={monthNumber === selectedMonth}
                        tabIndex={monthNumber === focusedMonth && !isDisabled ? 0 : -1}
                        hasFocus={internalHasFocus && monthNumber === focusedMonth}
                        onSelect={onMonthSelect}
                        onFocus={handleMonthFocus}
                        onBlur={handleMonthBlur}
                        disabled={isDisabled}
                        aria-current={
                            currentMonthNumber === monthNumber ? 'date' : undefined
                        }
                    >
                        {monthText}
                    </PickersMonth>
                );
            })}
        </MonthPickerRoot>
    );
});

MonthPicker.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // | To update them edit the TypeScript types and run "yarn proptypes"  |
    // ----------------------------------------------------------------------
    autoFocus: PropTypes.bool,

    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,

    /**
     * className applied to the root element.
     */
    className: PropTypes.string,

    /**
     * Date value for the MonthPicker
     */
    date: PropTypes.any,

    /**
     * If `true` picker is disabled
     */
    disabled: PropTypes.bool,

    /**
     * If `true` future days are disabled.
     * @default false
     */
    disableFuture: PropTypes.bool,

    /**
     * If `true`, today's date is rendering without highlighting with circle.
     * @default false
     */
    disableHighlightToday: PropTypes.bool,

    /**
     * If `true` past days are disabled.
     * @default false
     */
    disablePast: PropTypes.bool,

    hasFocus: PropTypes.bool,

    /**
     * Maximal selectable date. @DateIOType
     */
    maxDate: PropTypes.any,

    /**
     * Minimal selectable date. @DateIOType
     */
    minDate: PropTypes.any,

    /**
     * Callback fired on date change.
     */
    onChange: PropTypes.func.isRequired,

    onFocusedViewChange: PropTypes.func,
    onMonthFocus: PropTypes.func,

    /**
     * If `true` picker is readonly
     */
    readOnly: PropTypes.bool,

    /**
     * Disable specific months dynamically.
     * Works like `shouldDisableDate` but for month selection view @DateIOType.
     * @template TDate
     * @param {TDate} month The month to check.
     * @returns {boolean} If `true` the month will be disabled.
     */
    shouldDisableMonth: PropTypes.func,

    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};
