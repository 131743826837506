import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {AlertTitle, Box, List, ListItem, Typography} from '@mui/material';
import Alert from '@mui/material/Alert';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {roundHalfUpToTwoDecimalPlaces} from '../../../../../../../../utils/number';
import {TransactionStatus} from '../../../../util/constants';

const InfoTitle = ({text}) => (
    <Typography sx={{
        fontSize: theme => theme.typography.pxToRem(16),
        lineHeight: theme => theme.typography.pxToRem(24),
        letterSpacing: '0.1px',
        fontWeight: 500,
        color: 'v2.light.secondary.main',
        mt: 0.5,
        mb: 1,
        px: 2,
        backgroundColor: 'transparent',
    }}
    >
        {text}
    </Typography>
);

InfoTitle.propTypes = {
    text: PropTypes.string.isRequired,
};

const InfoItem = ({text}) => (
    <ListItem sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'flex-start',
        fontSize: theme => theme.typography.pxToRem(14),
        lineHeight: theme => theme.typography.pxToRem(20),
        letterSpacing: '0.17px',
        py: 0.25,
    }}
    >
        <Typography sx={{
            color: 'v2.light.secondary.main',
            fontSize: theme => theme.typography.pxToRem(24),
            lineHeight: theme => theme.typography.pxToRem(20),
        }}
        >
            •
        </Typography>
        {text}
    </ListItem>
);

InfoItem.propTypes = {
    text: PropTypes.string.isRequired,
};

const InfoSidebarAlert = ({data, unallocatedAmount}) => {
    const {t} = useTranslation('bank');

    const isRejected = data?.status === TransactionStatus.REJECTED;
    const isCancelled = data?.status === TransactionStatus.CANCELED;
    // const isUpcoming = data?.status === TransactionStatus.UPCOMING;
    const isOnHold = data?.status === TransactionStatus.PENDING;
    const isNotCategorized = data?.hasChip;
    const isCategorized = data?.isCategorized;
    const isAutoCategorized = data.autoCategorized;

    const CategorizedIcon = isAutoCategorized ? AutoFixHighIcon : CheckIcon;

    // TODO: remove this when we aware, that we will get the data from BE
    const warningsData = isEmpty(data?.categorizationWarnings)
        ? ['MISSING_SOURCE_OF_REVENUE_CATEGORY']
        : data?.categorizationWarnings;

    return (
        <Box>
            {isRejected && (
                <Alert
                    sx={{
                        backgroundColor: 'v2.light.error.main',
                        mb: 2,
                    }}
                    variant="filled"
                    severity="error"
                >
                    <AlertTitle>{t('accountBalanceTableHeader.rejectedAlertHeader')}</AlertTitle>
                    {data.rejectionReason && (
                        <Typography sx={{
                            fontSize: theme => theme.typography.pxToRem(14),
                        }}
                        >
                            {t('accountBalanceTableHeader.rejectedAlertTextStart')}: {t(`rejectedReason.${data.rejectionReason}`)}
                        </Typography>
                    )}
                </Alert>
            )}

            {isCancelled && (
                <Box sx={{
                    height: '33px',
                    borderRadius: '8px',
                    backgroundColor: '#F5F5F5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#9E9E9E',
                    fontWeight: 500,
                    mb: 2,
                }}
                >
                    <ErrorOutlineIcon sx={{
                        fontSize: '19px',
                        mr: 0.5,
                    }}
                    />
                    {t('accountBalanceTableHeader.cancelledAlertHeader')}
                </Box>
            )}

            {isOnHold && (
                <Box sx={{
                    height: '33px',
                    borderRadius: '8px',
                    backgroundColor: 'rgba(237, 108, 2, 0.04)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#E1A348',
                    fontWeight: 500,
                    mb: 2,
                }}
                >
                    <AccessTimeIcon sx={{
                        fontSize: '19px',
                        mr: 0.5,
                    }}
                    />
                    {t('accountBalanceTableHeader.onHoldAlertHeader')}
                </Box>
            )}

            {isCategorized && !isCancelled && !isRejected && (
                <Box sx={{
                    height: '33px',
                    borderRadius: '8px',
                    backgroundColor: 'rgba(46, 125, 50, 0.04)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#4CAF50',
                    fontWeight: 500,
                    mb: 2,
                }}
                >
                    <CategorizedIcon sx={{
                        fontSize: '19px',
                        mr: 0.5,
                    }}
                    />
                    <Typography>
                        {t('accountBalanceTableHeader.categorizedAlertHeader')}
                    </Typography>
                </Box>
            )}

            {isNotCategorized && !isCancelled && !isRejected && (
                <Box sx={{
                    borderRadius: 4,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'v2.light.secondary.shades30',
                    backgroundColor: 'gray_v2.50',
                    mb: 2,
                    pb: 1,
                }}
                >
                    <List sx={{listStyleType: 'disc'}}>
                        <InfoTitle text={t('accountBalanceTableHeader.toCategorizeAlertTitle')} />
                        {warningsData?.map(warning => {
                            const text = t(
                                `transactionCategoryWarning.${warning}`,
                                {amount: roundHalfUpToTwoDecimalPlaces(unallocatedAmount)},
                            );
                            return (
                                <InfoItem key={warning} text={text} />
                            );
                        })}
                    </List>
                </Box>
            )}
        </Box>
    );
};

InfoSidebarAlert.propTypes = {
    data: PropTypes.object,
    unallocatedAmount: PropTypes.number.isRequired,
};

InfoSidebarAlert.defaultProps = {
    data: {},
};

export default InfoSidebarAlert;
