import {combineReducers} from 'redux';

import * as actions from './signature.actions';
import {createReducer} from '../../createReducer';

export const signatureReducer = combineReducers({
    signature: createReducer(null, actions.STORE_SIGNATURE, true),
    isUploading: createReducer(false, actions.SET_IS_UPLOADING_SIGNATURE, true),
    isLoading: createReducer(false, actions.SET_IS_LOADING_SIGNATURE, true),
});
