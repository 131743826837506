const round = number => Math.round(number * 100) / 100;

export const monitorReducerEnhancer = createStore => (reducer, initialState, enhancer) => {
    const monitoredReducer = (state, action) => {
        const start = performance.now();
        const newState = reducer(state, action);
        const end = performance.now();
        const difference = round(end - start);

        // eslint-disable-next-line no-console
        console.debug(`[MonitorReducerEnhancer] Reducer processing time: ${difference}ms.`);

        return newState;
    };

    return createStore(monitoredReducer, initialState, enhancer);
};
