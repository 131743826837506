export const PERIODS = {
    MONTHLY: 'MONTHLY',
    YEARLY: 'YEARLY',
    CUSTOM: 'CUSTOM',
};

export const FILE_FORMAT = {
    CSV: 'csv',
    TXT: 'txt',
};

const JOURNAL_CODE_PREFIX = 'BQ';

export const JOURNAL_CODE_OPTIONS = [...Array(100).keys()]
    .map(number => `${JOURNAL_CODE_PREFIX}${number}`)
    .slice(1);

const From51290000To51290099 = [...Array(100).keys()]
    .map(number => `512900${(number.toString().padStart(2, '0'))}`);

export const ACCOUNT_NUMBER_OPTIONS = [
    '51200000',
    '51210000',
    '51220000',
    '51230000',
    '51240000',
    '51250000',
    '51260000',
    '51270000',
    '51280000',
    ...From51290000To51290099,
];

export const ACCOUNT_STATUSES = {
    ACTIVE: 'ACTIVE',
    SUSPENDED: 'SUSPENDED',
    CLOSING: 'CLOSING',
    CLOSED: 'CLOSED',
    ARCHIVED: 'ARCHIVED',
};

export const CURRENT_YEAR = new Date().getFullYear();
export const PAST_YEAR = CURRENT_YEAR - 1;

export const START_YEAR = 2020;
