import React from 'react';
import {RoutePaths} from '../../../lib/router/route-paths';

// eslint-disable-next-line import/no-restricted-paths
const AriaInvoiceValid = React.lazy(() => import('../components/aria/validation-pages/AriaInvoiceValid'));
// eslint-disable-next-line import/no-restricted-paths
const AriaInvoiceInvalid = React.lazy(() => import('../components/aria/validation-pages/AriaInvoiceInvalid'));
// eslint-disable-next-line import/no-restricted-paths
const AriaInvoiceExpired = React.lazy(() => import('../components/aria/validation-pages/AriaInvoiceExpired'));

export const AriaValidationRoutes = [
    {
        element: <AriaInvoiceValid />,
        path: RoutePaths.ARIA_INVOICE_VALID,
        exact: true,
    },
    {
        element: <AriaInvoiceInvalid />,
        path: RoutePaths.ARIA_INVOICE_INVALID,
        exact: true,
    },
    {
        element: <AriaInvoiceExpired />,
        path: RoutePaths.ARIA_INVOICE_EXPIRED,
        exact: true,
    },
];
