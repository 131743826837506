import {forwardRef} from 'react';
import {Pagination} from 'swiper/modules';
import {Swiper as OriginalSwiper, SwiperSlide as OriginalSwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

/**
 * @type React.FC<import('swiper/react').SwiperProps>
 */
export const Swiper = forwardRef((props, ref) => {
    return (
        <OriginalSwiper
            ref={ref}
            spaceBetween={16}
            slidesPerView={1}
            pagination={{clickable: true}}
            modules={[Pagination]}
            {...props}
            style={{
                'width': '100%',
                '--swiper-pagination-color': 'var(--joy-palette-common-black, #182123)',
                '--swiper-pagination-bullet-inactive-color': 'var(--joy-palette-neutral-200, #DFE4E5)',
                '--swiper-pagination-bullet-inactive-opacity': '1',
                '--swiper-pagination-bullet-size': '8px',
                '--swiper-pagination-bullet-horizontal-gap': '4px',
                '--swiper-pagination-bottom': '8px',
                'zIndex': 0,
                'boxShadow': 'none',
                'paddingBottom': '32px',
                // eslint-disable-next-line react/prop-types
                ...props.style,
            }}
        />
    );
});

export const SwiperSlide = forwardRef((props, ref) => (
    <OriginalSwiperSlide ref={ref} style={{boxSizing: 'border-box'}} {...props} />
));

SwiperSlide.displayName = 'SwiperSlide';
