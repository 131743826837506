import {Auth} from 'aws-amplify';
import axios from 'axios';
import {CONFIG} from '../../config';
import {COOKIE_NAMES, getCookie} from '../../utils/cookie';

/* NOTE: Introducing this instance due to incorrect and confusing naming of the
 * previous one (invoiceApiInstance in invoice-api-instance.js).
 * */
export const accountingApiInstance = axios.create({
    baseURL: `${CONFIG.API.INVOICE_API_BASE_URL}/api`,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

accountingApiInstance.interceptors.request.use(async config => {
    const cookieToken = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);

    if (cookieToken) {
        config.headers.Authorization = `Bearer ${cookieToken}`;
        return config;
    }

    const session = await Auth.currentSession();

    if (!session) {
        return config;
    }

    const accessToken = session.getIdToken().getJwtToken();

    if (!accessToken) {
        return config;
    }

    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
}, error => Promise.reject(error));
