import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getPickersToolbarUtilityClass = slot => {
    return generateUtilityClass('MuiPickersToolbar', slot);
};

export const pickersToolbarClasses = generateUtilityClasses(
    'MuiPickersToolbar',
    ['root', 'content', 'penIconButton', 'penIconButtonLandscape'],
);
