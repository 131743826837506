import {RibAndDocumentsActionType} from './ribAndDocuments.action-type';

export const RibAndDocumentsActions = {
    storeRibDocumentUrl: data => ({
        type: RibAndDocumentsActionType.STORE_SWANIO_RIB_URL,
        payload: data,
    }),

    storeBankAccountStatements: data => ({
        type: RibAndDocumentsActionType.STORE_BANK_ACCOUNT_STATEMENTS,
        payload: data,
    }),

    downloadAllStatementsAsZip: data => ({
        type: RibAndDocumentsActionType.DOWNLOAD_ALL_STATEMENTS_AS_ZIP,
        payload: data,
    }),
};
