import {combineReducers} from 'redux';

import {SettingsActionTypes} from './settings.actions';
import {createReducer} from '../../../utils/create-reducer';

export const settingsReducer = combineReducers({
    isLoading: createReducer(true, SettingsActionTypes.SET_IS_LOADING_USER_SETTINGS, true),
    isUpdating: createReducer(false, SettingsActionTypes.SET_IS_UPDATING_USER_SETTINGS),
    data: createReducer(null, SettingsActionTypes.STORE_USER_SETTINGS),
});
