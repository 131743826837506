import {adaptV4Theme, createTheme, responsiveFontSizes} from '@mui/material/styles';
import '@fontsource/red-hat-text/latin.css';
import '@fontsource/barlow/latin.css';
import '@fontsource/prompt/latin.css';

const theme = responsiveFontSizes(createTheme({
    ...adaptV4Theme({
        palette: {
            primary: {
                main: '#0a7bc4',
            },
            secondary: {
                main: '#e84c4c',
            },
            success: {
                main: '#00C804',
            },
            error: {
                main: '#DB100D',
            },
            warning: {
                main: '#F57C00',
            },
            grey: {
                '300': '#E0E0E0',
            },
        },
        typography: {
            fontFamily: 'Red Hat Text',
        },
    }),
    components: {
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiButton: {
            styleOverrides: {
                sizeLarge: {
                    'padding': '15px',
                    'borderRadius': '12px',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                containedSecondary: {
                    'boxShadow': 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                contained: {
                    'boxShadow': 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                root: {
                    'paddingLeft': '16px',
                    'paddingRight': '16px',
                    'borderRadius': '8px',
                    'boxShadow': 'none',
                    /**
                     * Safari 14.1 color fix
                     * @see https://github.com/mui-org/material-ui/issues/26251
                     */
                    'transition': 'color .001s',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    borderColor: '#E0E0E0',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: '12px',
                },
                elevation1: {
                    boxShadow:
                        '0 1px 1px 0 rgba(11,35,51,0.05), 0 1px 1px -1px rgba(11,35,51,0.05), 0 1px 3px 0 rgba(11,35,51,0.07)',
                },
                elevation8: {
                    boxShadow:
                        '0 8px 10px 1px rgba(11,35,51,0.08), 0 3px 14px 2px rgba(11,35,51,0.08), 0 5px 5px -3px rgba(11,35,51,0.1)',
                },
                elevation9: {
                    boxShadow:
                        '0 16px 24px 2px rgba(11,35,51,0.08), 0 6px 30px 5px rgba(11,35,51,0.08), 0 8px 10px -5px rgba(11,35,51,0.1)',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: 'rgba(11, 35, 51, 0.3)',
                },
                root: {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1px',
                        borderColor: '#0B2333',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'rgba(11, 35, 51, 0.04)',
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    'backgroundColor': 'rgba(10, 123, 196, 0.05)',
                    '&:hover': {
                        backgroundColor: 'rgba(10, 123, 196, 0.09)',
                    },
                    '&.Mui-focused': {
                        backgroundColor: 'rgba(10, 123, 196, 0.09)',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                filled: {
                    letterSpacing: '1.6px',
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: '#66788A',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    lineHeight: '24px',
                },
            },
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                },
                message: {
                    '& .MuiSvgIcon-root': {
                        fontSize: '24px !important',
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                arrow: {
                    color: 'rgba(11, 35, 51, 0.75)',
                },
                tooltip: {
                    backgroundColor: 'rgba(11, 35, 51, 0.75)',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                },
                standardInfo: {
                    '& .MuiAlert-icon': {
                        color: '#0A7BC4',
                    },
                },
            },
        },
        MuiStepper: {
            styleOverrides: {
                vertical: {
                    '& .MuiStepIcon-root': {
                        color: '#1976D21F',
                    },
                    '& .MuiStepIcon-text': {
                        fill: '#03A9F4',
                    },
                    '& .Mui-active .MuiStepIcon-text': {
                        fill: '#fff',
                    },
                    '& .Mui-completed': {
                        color: '#4CAF50 !important',
                    },
                    // TODO Check if there is better way for this
                    '& .Mui-completed .MuiStepConnector-lineVertical': {
                        borderColor: '#4CAF50',
                    },
                    '& .Mui-active .MuiStepConnector-lineVertical': {
                        borderColor: '#3779BE',
                    },
                    '& .custom-substep': {
                        position: 'relative',
                        right: '9px',
                    },
                },
            },
        },
    },
}));

// Responsive typography corrections
theme.typography.h5 = {
    ...theme.typography.h5,
    'fontWeight': theme.typography.fontWeightBold,
    'fontSize': theme.typography.pxToRem(17),
    '@media (max-width: 959.95px)': {
        fontSize: theme.typography.pxToRem(19),
    },
    '@media (min-width: 1280px)': {
        'fontSize': theme.typography.pxToRem(21),
    },
};

// Responsive typography corrections
theme.typography.h4 = {
    ...theme.typography.h4,
    'fontWeight': theme.typography.fontWeightBold,
    'fontSize': '1.5rem',
    '@media (min-width: 1280px)': {
        fontSize: '2.125rem',
    },
    '@media (max-width: 959.95px)': {
        fontSize: theme.typography.pxToRem(21),
    },
};

// Responsive typography corrections
theme.typography.h3 = {
    ...theme.typography.h3,
    fontWeight: theme.typography.fontWeightBold,
};

// Responsive typography corrections
theme.typography.h2 = {
    ...theme.typography.h2,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '2.125rem',
};

export default theme;
