import {SignatureActionTypes} from './signature.action-type';

export const SignatureActions = {
    uploadSignature: (file, type) => ({
        type: SignatureActionTypes.UPLOAD_SIGNATURE,
        payload: {
            file,
            type,
        },
    }),
    storeSignatureUrl: url => ({
        type: SignatureActionTypes.STORE_SIGNATURE_URL,
        payload: url,
    }),
    storeSignatureInfo: url => ({
        type: SignatureActionTypes.STORE_SIGNATURE_INFO,
        payload: url,
    }),
    putEventId: id => ({
        type: SignatureActionTypes.PUT_EVENT_ID,
        payload: id,
    }),
    hasSignatureUploadSucced: isUploaded => ({
        type: SignatureActionTypes.IS_UPLOADED,
        payload: isUploaded,
    }),
};
