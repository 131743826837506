import {Box} from '@mui/joy';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {AccountBalanceTotal} from './AccountBalanceTotal';
import {MAX_TRANSACTIONS_ANIMATION, TOP_OFFSET} from '../../util/constants';
import {AccountBalanceHelperFunctions} from '../../util/functions';

export const AccountBalanceScrollHeader = ({
    isSidebarOpened,
    balance,
    totalLoadedIds,
    topOffset,
    accountBalanceFilter,
}) => {
    const [width, setWidth] = useState('100%');

    const scrollFunction = debounce(() => {
        if (document.body.scrollTop > topOffset || document.documentElement.scrollTop > topOffset) {
            document.getElementById('navbar-transactions').style.top = '0';
        } else {
            document.getElementById('navbar-transactions').style.top = `-${topOffset}px`;
        }
    }, 50);

    useEffect(() => {
        window.addEventListener('scroll', scrollFunction);
        return () => {
            window.removeEventListener('scroll', scrollFunction);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setWidth(!isSidebarOpened
            ? '100%'
            : AccountBalanceHelperFunctions.calculateScrollableContainerOpenWidth());
    }, [isSidebarOpened]);

    return (
        <Box
            id="navbar-transactions"
            sx={{
                position: 'fixed',
                display: 'inline',
                width: 'calc(100% - 240px)',
                left: '0px',
                marginLeft: '240px',
                top: '-54px',
                height: '54px',
                zIndex: 'pageStickyHeader',
                transition: totalLoadedIds > MAX_TRANSACTIONS_ANIMATION ? 'none' : 'all 300ms ease-in-out',
            }}
        >
            <Box sx={{
                backgroundColor: 'common.black',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '54px',
                maxWidth: width,
                transition: totalLoadedIds > MAX_TRANSACTIONS_ANIMATION ? 'none' : 'all 300ms ease-in-out 100ms',
                px: 3,
            }}
            >
                <AccountBalanceTotal
                    amount={balance}
                    isInHeader
                    sx={{pr: 2}}
                />
                {accountBalanceFilter}
            </Box>
        </Box>
    );
};

AccountBalanceScrollHeader.propTypes = {
    isSidebarOpened: PropTypes.bool,
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    totalLoadedIds: PropTypes.number,
    topOffset: PropTypes.number,
    accountBalanceFilter: PropTypes.node.isRequired,
};

AccountBalanceScrollHeader.defaultProps = {
    isSidebarOpened: false,
    totalLoadedIds: 0,
    topOffset: TOP_OFFSET,
};
