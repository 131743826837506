import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PinIcon from '@mui/icons-material/Pin';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {Box, Divider} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {BankCardMenuItem} from './BankCardMenuItem';
import {CARD_TYPES, CardPhysicalStatus, CardVirtualStatus} from '../../util/constants';

const BankCardSidebarActions = ({
    viewPin,
    t,
    isCardLockLoading,
    showNumbers,
    unlockCard,
    lockCard,
    selectedCard,
    card,
}) => {
    const canPhysCardRequestShowNumber = card?.physicalCard?.status === CardPhysicalStatus.ACTIVATED
        || card?.physicalCard?.status === CardPhysicalStatus.SUSPENDED
        || card?.physicalCard?.status === CardPhysicalStatus.RENEWED;

    const canPhysCardRequestLock = card?.physicalCard?.status === CardPhysicalStatus.ACTIVATED
        || card?.physicalCard?.status === CardPhysicalStatus.RENEWED;


    const canVirtualCardRequestShowNumber = card?.status === CardVirtualStatus.ENABLED;

    const isPinReady = card?.physicalCard?.isPinReady;

    return (
        <>
            {selectedCard === CARD_TYPES.PHYS ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <BankCardMenuItem
                        text={t('cardDetails.showNum')}
                        icon={<RemoveRedEyeIcon />}
                        onClick={() => showNumbers(true)}
                        color={!canPhysCardRequestShowNumber
                            ? 'v2.light.text.disabledLight' : undefined}
                        isDisabled={!canPhysCardRequestShowNumber}
                    />

                    <Box sx={{px: 2}}>
                        <Divider />
                    </Box>

                    <BankCardMenuItem
                        onClick={() => viewPin()}
                        text={t('cardDetails.viewPin')}
                        icon={<PinIcon />}
                        color={!isPinReady
                            ? 'v2.light.text.disabledLight' : undefined}
                        isDisabled={!isPinReady}
                    />

                    <Box sx={{px: 2}}>
                        <Divider />
                    </Box>

                    {card?.physicalCard?.status !== CardPhysicalStatus.SUSPENDED ? (
                        <BankCardMenuItem
                            onClick={() => lockCard()}
                            text={t('cardDetails.lockCard')}
                            icon={<LockOpenIcon />}
                            isDisabled={!canPhysCardRequestLock}
                            color={!canPhysCardRequestLock ? 'v2.light.text.disabledLight' : 'v2.light.error.main'}
                            isLoading={isCardLockLoading}
                        />
                    ) : (
                        <BankCardMenuItem
                            onClick={() => unlockCard()}
                            text={t('cardDetails.unlockCard')}
                            icon={<LockIcon />}
                            color="v2.light.error.main"
                            isLoading={isCardLockLoading}
                        />
                    )}

                </Box>
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <BankCardMenuItem
                        text={t('cardDetails.showNum')}
                        icon={<RemoveRedEyeIcon />}
                        onClick={() => showNumbers(false)}
                        color={!canVirtualCardRequestShowNumber
                            ? 'v2.light.text.disabledLight' : undefined}
                        isDisabled={!canVirtualCardRequestShowNumber}
                    />
                </Box>
            )}
        </>
    );
};

BankCardSidebarActions.propTypes = {
    selectedCard: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    card: PropTypes.object,
    lockCard: PropTypes.func.isRequired,
    isCardLockLoading: PropTypes.bool,
    unlockCard: PropTypes.func.isRequired,
    showNumbers: PropTypes.func.isRequired,
    viewPin: PropTypes.func.isRequired,
};

BankCardSidebarActions.defaultProps = {
    isCardLockLoading: false,
    card: {},
};

export default BankCardSidebarActions;
