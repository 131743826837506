import {combineReducers} from 'redux';
import {BankActionTypes} from './bank.action-type';
import {createReducer} from '../../../v1/app/createReducer';
import {transactionsReducer} from '../modules/account-balance/store/transactions.reducer';
import {cardsReducer} from '../modules/cards/store/cards.reducer';
import {ribAndDocumentsReducer} from '../modules/rib-and-documents/store/ribAndDocuments.reducer';
import {beneficiariesReducer} from '../modules/transfers/store/beneficiaries.reducer';
import {transfersReducer} from '../modules/transfers/store/transfers.reducer';

export const bankReducer = combineReducers({
    integrations: createReducer([], BankActionTypes.STORE_INTEGRATION_DATA, false),
    integrationsWithArchived: createReducer([], BankActionTypes.STORE_INTEGRATION_DATA_WITH_ARCHIVED, false),
    isBankMenuFullyActive: createReducer(false, BankActionTypes.STORE_BANK_MENU_IS_FULLY_ACTIVE, false),
    qrCodeEvent: createReducer(null, BankActionTypes.UPLOAD_FILE_QR_CODE_EVENT, true),
    capitalDepositError: createReducer(false, BankActionTypes.SET_CAPITAL_DEPOSIT_ERROR, true),
    capitalDeposit: createReducer(null, BankActionTypes.STORE_CAPITAL_DEPOSIT, false),
    beneficiaries: beneficiariesReducer,
    transfers: transfersReducer,
    cards: cardsReducer,
    ribAndDocuments: ribAndDocumentsReducer,
    transactions: transactionsReducer,
});
