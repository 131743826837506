import {createSelector} from 'reselect';
import {selectFreelancerAccount, selectFreelancerCompanies} from '../../freelancer/freelancer.selectors';

export const selectCompanies = state => state.v1Company.companyList.companies;

export const selectCompaniesTotal = state => state.v1Company.companyList.total;

export const selectIsLoadingCompanies = state => state.v1Company.companyList.isLoading;

export const selectTrainingCompanies = state => state.v1Company.companyList.trainingCompanies;

export const selectHiwayBankComopanies = state => state.v1Company.companyList.hiwayCompanies;

export const createNumberOfTrainingCompanies = () => createSelector(
    selectTrainingCompanies,
    companies => Object.keys(companies).length,
);

export const createDefaultCompanySelector = () => createSelector(
    [
        selectFreelancerAccount,
        selectFreelancerCompanies,
    ],
    (freelancerAccount, companies) => {
        return companies[freelancerAccount?.defaultCompanyId];
    },
);

export const createCompaniesSelector = () => () => createSelector(
    [
        selectCompanies,
        selectCompaniesTotal,
    ],
    (companies, total) => {
        if (!Object.keys(companies).length) {
            return {data: [], count: 0};
        }

        const data = Object.values(companies);

        return {data, count: total};
    },
);
