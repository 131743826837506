import {Box} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import empty from '../../../assets/svg/signing/empty.svg';

const EmptySignature = () => {
    const {t} = useTranslation('signature');

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        }}
        >
            <Box>
                <img src={empty} alt="empty placeholder" />
            </Box>
            <Box sx={{
                maxWidth: '250px',
                textAlign: 'center',
                letterSpacing: '0.15px',
                color: 'text_v2.secondary',
            }}
            >
                {t('uploadSignatureModal.emptySignatureText')}
            </Box>
        </Box>
    );
};

export default EmptySignature;
