import {Box, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import PcLeftCard from './PcLeftCard';
import PcRightCard from './PcRightCard';

const PcTab = ({handleOpenSubscribeModal}) => {
    const {t} = useTranslation('insurance');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
            width: '100%',
        }}
        >
            <Typography sx={{textAlign: 'center'}}>{t('tabs.pc.subtitle')}</Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: isMobileSize ? 'column' : 'row',
                gap: 3,
                alignSelf: 'stretch',
            }}
            >
                <PcLeftCard handleOpenSubscribeModal={handleOpenSubscribeModal} />
                <PcRightCard />
            </Box>
        </Box>
    );
};

PcTab.propTypes = {
    handleOpenSubscribeModal: PropTypes.func.isRequired,
};

export default PcTab;
