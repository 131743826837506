/**
 * @enum
 */
export const TRAVEL_TYPES = {
    HOME_OFFICE: 'HOME_OFFICE',
    PROFESSIONAL: 'PROFESSIONAL',
};

/**
 * @enum
 */
export const CAR_FISCAL_POWER_OPTIONS = {
    HP3: 'HP3',
    HP4: 'HP4',
    HP5: 'HP5',
    HP6: 'HP6',
    HP7: 'HP7',
};

/**
 * @enum
 */
export const MOTORCYCLE_FISCAL_POWER_OPTIONS = {
    HP1_2: 'HP1_2',
    HP3_4_5: 'HP3_4_5',
    HP5_PLUS: 'HP5_PLUS',
};

/**
 * @enum
 */
export const VEHICLE_TYPES = {
    CAR: 'CAR',
    MOTORCYCLE: 'MOTORCYCLE',
};

/**
 * @enum
 */
export const ENGINE_TYPES = {
    ELECTRIC: 'ELECTRIC',
    PETROL: 'PETROL',
};

/**
 * @enum
 */
export const FISCAL_POWER_DATE_CHANGE_2022 = '2022-06-01';
export const FISCAL_POWER_DATE_CHANGE_2023 = '2023-01-01';

/**
 * @enum
 */
export const CAR_FISCAL_POWER_COEFFICIENT_BY_DISTANCE_2023 = {
    BOUND_1: {
        HP3: 0.529,
        HP4: 0.606,
        HP5: 0.636,
        HP6: 0.665,
        HP7: 0.697,
    },
    BOUND_2: {
        HP3: 0.316,
        HP4: 0.34,
        HP5: 0.357,
        HP6: 0.374,
        HP7: 0.394,
    },
    BOUND_3: {
        HP3: 0.37,
        HP4: 0.407,
        HP5: 0.427,
        HP6: 0.447,
        HP7: 0.47,
    },
};

export const CAR_FISCAL_POWER_COEFFICIENT_BY_DISTANCE_OLD = {
    BOUND_1: {
        HP3: 0.456,
        HP4: 0.523,
        HP5: 0.548,
        HP6: 0.574,
        HP7: 0.601,
    },
    BOUND_2: {
        HP3: 0.273,
        HP4: 0.294,
        HP5: 0.308,
        HP6: 0.323,
        HP7: 0.34,
    },
    BOUND_3: {
        HP3: 0.318,
        HP4: 0.352,
        HP5: 0.368,
        HP6: 0.386,
        HP7: 0.405,
    },
};

export const CAR_FISCAL_POWER_COEFFICIENT_BY_DISTANCE_2022 = {
    BOUND_1: {
        HP3: 0.502,
        HP4: 0.575,
        HP5: 0.603,
        HP6: 0.631,
        HP7: 0.661,
    },
    BOUND_2: {
        HP3: 0.3,
        HP4: 0.323,
        HP5: 0.339,
        HP6: 0.355,
        HP7: 0.374,
    },
    BOUND_3: {
        HP3: 0.35,
        HP4: 0.387,
        HP5: 0.405,
        HP6: 0.425,
        HP7: 0.466,
    },
};

/**
 * @enum
 */
export const MOTORCYCLE_FISCAL_POWER_COEFFICIENT_BY_DISTANCE_2023 = {
    BOUND_1: {
        HP1_2: 0.395,
        HP3_4_5: 0.468,
        HP5_PLUS: 0.606,
    },
    BOUND_2: {
        HP1_2: 0.099,
        HP3_4_5: 0.082,
        HP5_PLUS: 0.079,
    },
    BOUND_3: {
        HP1_2: 0.248,
        HP3_4_5: 0.275,
        HP5_PLUS: 0.343,
    },
};

export const MOTORCYCLE_FISCAL_POWER_COEFFICIENT_BY_DISTANCE_OLD = {
    BOUND_1: {
        HP1_2: 0.341,
        HP3_4_5: 0.404,
        HP5_PLUS: 0.523,
    },
    BOUND_2: {
        HP1_2: 0.085,
        HP3_4_5: 0.071,
        HP5_PLUS: 0.068,
    },
    BOUND_3: {
        HP1_2: 0.213,
        HP3_4_5: 0.237,
        HP5_PLUS: 0.295,
    },
};

export const MOTORCYCLE_FISCAL_POWER_COEFFICIENT_BY_DISTANCE_2022 = {
    BOUND_1: {
        HP1_2: 0.375,
        HP3_4_5: 0.444,
        HP5_PLUS: 0.575,
    },
    BOUND_2: {
        HP1_2: 0.094,
        HP3_4_5: 0.078,
        HP5_PLUS: 0.075,
    },
    BOUND_3: {
        HP1_2: 0.234,
        HP3_4_5: 0.261,
        HP5_PLUS: 0.325,
    },
};

export const CAR_FISCAL_POWER_DISTANCE_BOUNDS = [{
    key: 'BOUND_1',
    min: 0,
    max: 5000,
}, {
    key: 'BOUND_2',
    min: 5001,
    max: 20000,
}, {
    key: 'BOUND_3',
    min: 20001,
    max: null,
}];

export const MOTORCYCLE_FISCAL_POWER_DISTANCE_BOUNDS = [{
    key: 'BOUND_1',
    min: 0,
    max: 3000,
}, {
    key: 'BOUND_2',
    min: 3001,
    max: 6000,
}, {
    key: 'BOUND_3',
    min: 6001,
    max: null,
}];

/**
 * @enum
 */
export const EXPENSE_CATEGORIES = {
    MANAGERS_MEAL: 'MANAGERS_MEAL',
    DISPLACEMENT: 'DISPLACEMENT',
    BUSINESS_INVITATION: 'BUSINESS_INVITATION',
    HARDWARE: 'HARDWARE',
    FURNITURE: 'FURNITURE',
    INTERNET_MOBILE_SUBSCRIPTIONS: 'INTERNET_MOBILE_SUBSCRIPTIONS',
    SOFTWARE: 'SOFTWARE',
    PROVISION_OF_SERVICES: 'PROVISION_OF_SERVICES',
    DOCUMENTATION: 'DOCUMENTATION',
    SALON_CONFERENCE: 'SALON_CONFERENCE',
    INSURANCE_AND_SOCIAL: 'INSURANCE_AND_SOCIAL',
    OTHER: 'OTHER',
};
