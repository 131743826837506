import {useMemo} from 'react';
import {roundHalfUpToTwoDecimalPlaces} from '../../../../../../utils/number';
import {TransactionsActions} from '../../store/transactions.action';

const useReimbursement = ({details, dispatch, isEditable}) => {
    const {
        perfectMatches = [],
        suggestedMatches = [],
        fakeReimbursedTransactions = [],
    } = details?.reimbursementTransactions ?? {};
    const amount = Number(details?.amount ?? 0);

    // TODO Rename it to selectedReimbursementTransactionsArray since it is Array
    const selectedReimbursementTransactionsObj = useMemo(() => [
        ...perfectMatches,
        ...suggestedMatches,
        ...fakeReimbursedTransactions,
    ].filter(({selected}) => !!selected), [perfectMatches, suggestedMatches, fakeReimbursedTransactions]);

    const selectedReimbursementTransactions = selectedReimbursementTransactionsObj.map(({id, reimbursedAmount}) => {
        return {id, reimbursedAmount};
    });

    const selectedReimbursementTransactionsIDs = selectedReimbursementTransactions
        .map(({id}) => id);

    const paidAmount = useMemo(() => {
        const totalReimbursed = selectedReimbursementTransactions
            .reduce((sum, {reimbursedAmount}) => sum + Number(reimbursedAmount), 0);
        return roundHalfUpToTwoDecimalPlaces(totalReimbursed);
    }, [selectedReimbursementTransactions]);

    const unpaidAmount = amount - paidAmount;

    const onSelectTransaction = (id, reimbursedAmount = 0, isDnd = false) => {
        if (!isEditable) {
            return;
        }

        let updatedSelectedTransactions;

        if (selectedReimbursementTransactionsIDs.includes(id)) {
            if (!reimbursedAmount) {
                updatedSelectedTransactions = selectedReimbursementTransactions.filter(
                    transaction => transaction.id !== id,
                );
            } else {
                updatedSelectedTransactions = selectedReimbursementTransactions.map(transaction => {
                    if (transaction.id === id) {
                        return {
                            ...transaction,
                            reimbursedAmount: Number.isFinite(reimbursedAmount)
                                ? reimbursedAmount.toFixed(2)
                                : reimbursedAmount,
                        };
                    }
                    return transaction;
                });
            }
        } else {
            updatedSelectedTransactions = [
                ...selectedReimbursementTransactions,
                {
                    id,
                    reimbursedAmount: Number.isFinite(reimbursedAmount)
                        ? reimbursedAmount.toFixed(2)
                        : reimbursedAmount,
                },
            ];
        }

        updatedSelectedTransactions = updatedSelectedTransactions.map(({id, reimbursedAmount}) => ({
            id,
            allocatedAmount: reimbursedAmount,
        }));

        dispatch(TransactionsActions.saveTransactionField({
            id: details.id,
            fieldKey: 'reimbursementTransactions',
            fieldValue: updatedSelectedTransactions,
            accountId: details?.bankAccountId,
            additionalData: isDnd ? {
                isDnd: true,
                id: id,
            } : null,
        }));
    };

    return {
        perfectMatches,
        suggestedMatches,
        fakeReimbursedTransactions,
        amount,
        selectedReimbursementTransactionsObj,
        selectedReimbursementTransactions,
        selectedReimbursementTransactionsIDs,
        paidAmount,
        unpaidAmount,
        onSelectTransaction,
    };
};

export default useReimbursement;
