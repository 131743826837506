import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {CreateUserFormActionTypes} from './create-user-form.action-type';
import {push} from '../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {Toast} from '../../../../../lib/toast';
import {UserRoles} from '../../../../../utils/user-roles';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {SidebarActions} from '../../../../sidebar/store/sidebar.action';
import {SidebarSelectors} from '../../../../sidebar/store/sidebar.selector';
import {CreateUserApi} from '../api/create-user-form.api';

const createUserFlow = function* (data) {
    try {
        yield call(CreateUserApi.createUser, data);

        const selectedRole = yield select(SidebarSelectors.selectSelectedRole);

        if (data?.role && data.role !== UserRoles.ADMINISTRATOR && selectedRole !== data.role) {
            yield put(SidebarActions.setRoleSelected(data.role));
        }

        yield put(push(RoutePaths.USERS));

        // TODO:RELEASE: Display notification that the user has been created
        Toast.success('userCreated');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});

        if (error?.response?.data?.error_type === 'HTTP_CONFLICT_ERROR') {
            Toast.error('userExists');

            return;
        }

        // TODO:LOW Better error handling.
        Toast.error('anErrorOccurred');
    }
};

const handleFormSubmitWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.CREATE_USER_FORM, true));

    yield call(createUserFlow, payload);

    yield put(LoadingActions.setLoading(LoadingTypes.CREATE_USER_FORM, false));
};

export const createUserFormSaga = function* () {
    yield all([
        takeEvery(CreateUserFormActionTypes.SUBMIT, handleFormSubmitWorker),
    ]);
};
