import {createSelector} from 'reselect';

const selectSimulation = state => state.revenueSimulator.simulation;
const selectCurrentStepIndex = state => state.revenueSimulator.inputs.currentStepIndex;
const selectAllValues = state => state.revenueSimulator.inputs.allValues;
const selectNumberOfAdults = state => state.revenueSimulator.inputs.allValues.personalInfo?.numberOfAdults;
const selectAllSimulations = state => state.revenueSimulator.allSimulations;
const selectIsLoadingSimulations = state => state.revenueSimulator.isLoadingSimulations;
const selectIsSubmitting = state => state.revenueSimulator.inputs.isSubmitting;

const createSimulationsListSelector = () => createSelector([selectAllSimulations], allSimulations => ({
    data: allSimulations.simulations,
    count: allSimulations.count,
}));

export const RevenueSimulatorSelectors = {
    selectSimulation,
    selectCurrentStepIndex,
    selectAllValues,
    selectNumberOfAdults,
    createSimulationsListSelector,
    selectIsLoadingSimulations,
    selectIsSubmitting,
};
