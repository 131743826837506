export const BE_DATE_FORMAT = 'DD/MM/YYYY';
export const FR_DATE_FORMAT_SHORT = 'DD/MM/YY';
export const FR_DATE_FORMAT_LONG = 'DD MMMM YYYY';
export const FR_DATE_FORMAT_WITH_TIME = 'DD/MM/YYYY, HH:mm';
export const DEFAULT_TIME_ZONE = 'Europe/Paris';

export const PayCalculationType = {
    Instant: 'INSTANT',
    Delayed: 'DELAYED',
};

export const dropzoneErrorCodes = {
    'file-too-large': 'file-too-large',
    'too-many-files': 'too-many-files',
};
