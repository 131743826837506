import {Stack} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AccountsHeaderPanel} from './AccountsHeaderPanel';
import MobileTabs from './MobileTabs';
import TransactionAccountsModal from './TransactionAccountsModal';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';
import {USER_ROLES} from '../../../../../../utils/user-roles';
import {selectUser} from '../../../../../../v1/app/user/user.selectors';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../../user/modules/logged-in-user';
import {BankActions} from '../../../../store/bank.action';
import {BridgeActions} from '../../../bridge-api/store/bridge-api.action';
import {TransactionsActions} from '../../store/transactions.action';

const AccountsHeader = ({
    tabValue,
    handleTabChange,
    bankNumber,
    fullBankAccountList,
    hasHiwayPro,
    combinedTotal,
    combinedUncategorized,
    setHasBankSidebarOpen,
    isAdminInterface,
    hasArchived,
    isTabValueAuto,
}) => {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isAdmin = loggedInUser.role === USER_ROLES.ADMINISTRATOR;

    const [isMobileTabsOpen, setIsMobileTabsOpen] = useState(false);

    const user = useSelector(selectUser);

    const isMobileSize = useIsMobileSize();

    const hasBridgeAccess = loggedInUser.hasBridgeAccess;

    const onClickAdd = () => {
        if (hasBridgeAccess) {
            dispatch(BridgeActions.connectToBridge());
        }
    };

    const onClickHiwayAdd = () => {
        dispatch(BankActions.createBankAccount());
    };

    const handleChangeTab = (_, value) => {
        setIsMobileTabsOpen(false);
        dispatch(UiActions.setActiveModal(ModalsKeys.TRANSACTION_ACCOUNTS_MODAL, false));

        if (tabValue === value) {
            return;
        }

        // Clean transactions before switching tabs
        dispatch(TransactionsActions.storeTransactionList({
            count: 0,
            maxItems: 0,
            nonCategorizedCount: 0,
            ids: [],
            transactionsObject: {},
        }));

        handleTabChange(value);
        setHasBankSidebarOpen(false);
        if (!isAdminInterface && !isTabValueAuto) {
            dispatch(BankActions.getUserIntegrationData(user?.id ?? loggedInUser.id));
        }
    };

    const hasCombined = bankNumber > 1;
    const hasHiwayAdd = !hasHiwayPro && !isAdmin;
    const hasAdd = !isAdmin
        && ((hasHiwayPro && bankNumber < 5) || (!hasHiwayPro && bankNumber < 4))
        && hasBridgeAccess;

    return (
        <>
            <Stack
                direction={isMobileSize ? 'column' : 'row'}
                gap={2}
                alignItems={isMobileSize ? 'flex-start' : 'center'}
                flexGrow={1}
            >
                <AccountsHeaderPanel
                    tabValue={tabValue}
                    setIsMobileTabsOpen={setIsMobileTabsOpen}
                    combinedTotal={combinedTotal}
                    fullBankAccountList={fullBankAccountList}
                    setHasBankSidebarOpen={setHasBankSidebarOpen}
                />
            </Stack>

            {isMobileSize && (
                <MobileTabs
                    isOpen={isMobileTabsOpen}
                    setIsOpen={setIsMobileTabsOpen}
                    fullBankAccountList={fullBankAccountList}
                    onChangeTab={handleChangeTab}
                    hasCombined={hasCombined}
                    combinedUncategorized={combinedUncategorized}
                    combinedTotal={combinedTotal}
                    hasHiwayAdd={hasHiwayAdd}
                    onClickHiwayAdd={onClickHiwayAdd}
                    hasAdd={hasAdd}
                    hasArchived={hasArchived}
                    onClickAdd={onClickAdd}
                    tabValue={tabValue}
                />
            )}

            <TransactionAccountsModal
                fullBankAccountList={fullBankAccountList}
                onChangeTab={handleChangeTab}
                hasCombined={hasCombined}
                combinedUncategorized={combinedUncategorized}
                combinedTotal={combinedTotal}
                hasHiwayAdd={hasHiwayAdd}
                onClickHiwayAdd={onClickHiwayAdd}
                hasAdd={hasAdd}
                hasArchived={hasArchived}
                onClickAdd={onClickAdd}
                tabValue={tabValue}
            />
        </>
    );
};

AccountsHeader.propTypes = {
    tabValue: PropTypes.string,
    handleTabChange: PropTypes.func.isRequired,
    bankNumber: PropTypes.number.isRequired,
    combinedTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    combinedUncategorized: PropTypes.number.isRequired,
    fullBankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasHiwayPro: PropTypes.bool,
    setHasBankSidebarOpen: PropTypes.func.isRequired,
    isAdminInterface: PropTypes.bool.isRequired,
    hasArchived: PropTypes.bool,
    isTabValueAuto: PropTypes.bool.isRequired,
};

AccountsHeader.defaultProps = {
    hasHiwayPro: false,
    tabValue: '',
    hasArchived: false,
};

export default AccountsHeader;
