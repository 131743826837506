import {all, call, put, select} from 'redux-saga/effects';
import {InvoiceActions} from './invoice.actions';
import {InvoiceSelector} from './invoice.selector';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {getDocumentSignedUrlFlow} from '../../document/store/document.saga';
import {LoadingActions, LoadingTypes} from '../../loading';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';

export const saveClientFileSuccess = function* (data) {
    try {
        const clientDraft = yield select(InvoiceSelector.selectClientDraft);

        const message = JSON.parse(data.data);

        const event = yield select(InvoiceSelector.createModalFileLoaderSelectorByEventId(message.id));

        // If it is connected to invoice
        if (event?.inInvoice) {
        // Set in progress to false
            yield put(InvoiceActions.updateFileUploadLoader({
                eventId: event.eventId,
                inProgress: false,
            }));

            const invoiceDraft = yield select(InvoiceSelector.selectInvoiceDraft);

            if (invoiceDraft?.client?.id) {
                yield put(InvoiceActions.getClientDetails(invoiceDraft.client.id));
            } else {
                yield put(InvoiceActions.saveInvoiceDraft({
                    ...invoiceDraft,
                    client: event?.client ?? clientDraft,
                }));

                yield put(InvoiceActions.storeInvoiceClientEditDraft(null));

                // Close sidebar
                yield put(InvoiceActions.setInvoiceSidebar(null));
            }
        } else {
        // Set in progress to false
            yield put(InvoiceActions.updateFileUploadLoader({
                eventId: event.eventId,
                inProgress: false,
            }));

            // If create
            if (event?.client) {
                Toast.success('genericSuccessSave');

                yield all([
                    put(InvoiceActions.storeInvoiceClientEditDraft(null)),
                    put(InvoiceActions.setInvoiceSidebarData(null)),
                    put(InvoiceActions.setInvoiceSidebar(null)),
                ]);
            } else {
                yield put(InvoiceActions.getClientDetails(event?.clientId));
            }
        }
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_CLIENTS, false));
    } catch (e) {
        Debug.error('invoice SSE saveClientFileSuccess', 'Error: ', {e});
    }
};

export const saveClientFileError = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_CLIENTS, false));
};

export const invoceGenerationSuccess = function* (data) {
    try {
        const message = JSON.parse(data.data);

        const event = yield select(InvoiceSelector.createModalFileLoaderSelectorByEventId(message.id));

        yield put(LoadingActions.setLoading(LoadingTypes.INVOICE_PREVIEW_GENERATION, false));

        yield put(InvoiceActions.storeInvoicePreview(message));

        // Set in progress to false
        yield put(InvoiceActions.updateFileUploadLoader({
            eventId: event.eventId,
            inProgress: false,
        }));

        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        const {signedUrl} = yield call(
            getDocumentSignedUrlFlow,
            {
                freelancerId: loggedInUser.id,
                companyId: loggedInUser.defaultCompanyId,
                documentId: message.data.documentId,
                isDownloading: false,
            },
        );

        yield put(InvoiceActions.storePreviewFileUrl(signedUrl));
    } catch (e) {
        Debug.error('invoice SSE invoceGenerationSuccess', 'Error: ', {e});
    }
};

export const invoiceGenerationError = function* () {
    Toast.error('genericError');

    yield put(LoadingActions.setLoading(LoadingTypes.INVOICE_PREVIEW_GENERATION, false));
};

export const saveAnnexFileSuccess = function* (data) {
    try {
        const message = JSON.parse(data.data);

        const event = yield select(InvoiceSelector.createModalFileLoaderSelectorByEventId(message.id));

        // Set in progress to false
        yield put(InvoiceActions.updateFileUploadLoader({
            eventId: event.eventId,
            inProgress: false,
        }));

        //  TODO Get invoice to display file
        yield put(InvoiceActions.getInvoiceDraft({useNoLoader: true}));

        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_ANNEX_FILE, false));
    } catch (e) {
        Debug.error('invoice SSE saveAnnexFileSuccess', 'Error: ', {e});
    }
};

export const saveAnnexFileError = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_ANNEX_FILE, false));

    Toast.error('genericError');
};
