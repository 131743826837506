export const SUBMIT_CHANGE_PASSWORD_FORM = 'SUBMIT_CHANGE_PASSWORD_FORM';
export const SET_IS_LOADING_CHANGE_PASSWORD_FORM = 'SET_IS_LOADING_CHANGE_PASSWORD_FORM';

export const submitChangePasswordForm = (currentPassword, newPassword) => ({
    type: SUBMIT_CHANGE_PASSWORD_FORM,
    payload: {
        currentPassword,
        newPassword,
    },
});

export const setIsLoadingChangePasswordForm = isLoading => ({
    type: SET_IS_LOADING_CHANGE_PASSWORD_FORM,
    payload: isLoading,
});
