import PropTypes from 'prop-types';
import React from 'react';

import {USER_STATUSES} from '../../../app/user/user.constants';
import UserStatus from '../UserStatus/UserStatus';

const UserTableStatus = ({rowData}) => {
    return <UserStatus isUserActive={rowData.active} status={rowData.status} />;
};

UserTableStatus.propTypes = {
    rowData: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        status: PropTypes.oneOf(Object.values(USER_STATUSES)).isRequired,
    }).isRequired,
};

export default UserTableStatus;
