import {LOCATION_CHANGED} from '../../lib/router/connected-router-saga';

/**
 * @typedef {Object} ReducerOptions
 * @property {boolean} locationChangeReset
 */

/**
 * Creates a basic reducer which just stores the action payload when an action is matched.
 * @param {*} initialState Initial state of the reducer
 * @param {string} actionType Action type which must be matched
 * @param {boolean} locationChangeReset Reset state to initial state on LOCATION_CHANGED
 * @returns {function(*, object): (string|number|boolean)} A reducer function
 */
export const createReducer = (initialState, actionType, locationChangeReset = false) => {
    return (state = initialState, action) => {
        if (action.type === actionType) {
            return action.payload;
        } else if (action.type === LOCATION_CHANGED && locationChangeReset) {
            return initialState;
        }

        return state;
    };
};
