import HelpIcon from '@mui/icons-material/Help';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';

export const FormSection = ({title, Icon, children}) => {
    return (
        <Box
            sx={{
                background: 'white',
                pt: 2,
                mt: 2,
                pb: 5,
                px: 5,
                borderRadius: '32px',
            }}
        >
            <Box display="flex" alignItems="center" gap={1} mb={2}>
                <Icon sx={{
                    fontSize: theme => theme.typography.pxToRem(24),
                    color: 'v2.blueGray.300',
                }}
                />
                <Typography sx={{
                    fontFamily: 'Red Hat Text',
                    textTransform: 'uppercase',
                    fontSize: theme => theme.typography.pxToRem(12),
                    fontWeight: 500,
                    color: 'lightTextSecondary.main',
                    letterSpacing: '1px',
                }}
                >{title}
                </Typography>
            </Box>
            {children}
        </Box>
    );
};

FormSection.propTypes = {
    title: PropTypes.string.isRequired,
    Icon: PropTypes.elementType,
};

FormSection.defaultProps = {
    Icon: HelpIcon,
};
