export const SET_IS_LOADING_PROFESSIONAL_TRAVELS_FORM = 'SET_IS_LOADING_PROFESSIONAL_TRAVELS_FORM';
export const SUBMIT_PROFESSIONAL_TRAVELS_FORM = 'SUBMIT_PROFESSIONAL_TRAVELS_FORM';

export const setIsLoadingProfessionalTravelsForm = isLoading => ({
    type: SET_IS_LOADING_PROFESSIONAL_TRAVELS_FORM,
    payload: isLoading,
});

export const submitProfessionalTravelsForm = (data, travelId, isEdit, onSuccess) => ({
    type: SUBMIT_PROFESSIONAL_TRAVELS_FORM,
    payload: {
        data,
        travelId,
        isEdit,
        onSuccess,
    },
});
