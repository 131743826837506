import {fromFreelancerAccountDTO, transformFreelancerCompanies} from './freelancer.dto';
import axiosInstance, {safeTransformData} from '../../axios';

export const getFreelancerAccountRequest = freelancerId => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}`,
        transformResponse: data => {
            let response;

            try {
                response = JSON.parse(data);
            } catch (error) {
                return [];
            }

            if (response?.id) {
                return fromFreelancerAccountDTO(response);
            }

            throw Error();
        },
    }).then(response => safeTransformData(response));
};

export const getSignatureRequest = freelancerId => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/signature`,
    }).then(response => safeTransformData(response));
};

export const uploadSignatureRequest = (freelancerId, file) => {
    const formData = new FormData();

    formData.append('file', file);

    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/signature`,
        header: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    }).then(response => safeTransformData(response));
};

export const getFreelancerCompaniesRequest = freelancerId => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/companies`,
    }).then(response => {
        if (!response.data) {
            return {};
        }

        return safeTransformData(response, transformFreelancerCompanies);
    });
};

export const getContractUrlRequest = freelancerId => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/documents/hiway-contract`,
    }).then(response => safeTransformData(response));
};

export const signContractRequest = (freelancerId, documentId) => {
    return axiosInstance({
        method: 'PUT',
        url: `/v1/freelancers/${freelancerId}/documents/${documentId}/sign`,
        data:
        // TODO -- MOVE TERM_IDS
            {
                term_ids: [
                    '2a9c114c-6fba-4025-bc84-5bc96fc499ee',
                    'b04326a2-2545-485d-8bf0-ec583cffd04f',
                ],
            },
    }).then(response => safeTransformData(response));
};

export const getContractDataRequest = freelancerId => {
    return axiosInstance({
        method: 'GET',
        url: `/v1/freelancers/${freelancerId}/documents/hiway-contract`,
    }).then(response => safeTransformData(response));
};
