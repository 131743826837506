import {bankApiInstance} from '../../../../../lib/bank-api-instance';
import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';

const getBridgeAuth = () => {
    const redirectUri = `${window.location.origin}`;
    return bankApiInstance({
        method: 'GET',
        params: {
            redirectUri,
        },
        url: `/v3/bank/bank-integrations/bridge-api/auth`,
    }).then(result => result?.data);
};

const fixValidationRequired = bankIntegrationId => {
    const redirectUri = `${window.location.origin}`;
    return bankApiInstance({
        method: 'GET',
        params: {
            bankIntegrationId,
            redirectUri,
        },
        url: `/v3/bank/bank-integrations/bridge-api/auth/pro`,
    }).then(result => result?.data);
};

const fixAuthRequired = bankIntegrationId => {
    const redirectUri = `${window.location.origin}`;
    return bankApiInstance({
        method: 'GET',
        params: {
            bankIntegrationId,
            redirectUri,
        },
        url: `/v3/bank/bank-integrations/bridge-api/auth/mfa`,
    }).then(result => result?.data);
};

const fixUserActionRequired = bankIntegrationId => {
    const redirectUri = `${window.location.origin}`;
    return bankApiInstance({
        method: 'GET',
        params: {
            bankIntegrationId,
            redirectUri,
        },
        url: `/v3/bank/bank-integrations/bridge-api/auth/refresh`,
    }).then(result => result?.data);
};

const setChosenBankConnectionType = (userId, value) => {
    return hiwayApiInstance({
        method: 'PUT',
        url: `/v1/users/${userId}/additional-info`,
        data: {chosen_bank_connection_modal_type: value},
    }).then(result => result?.data);
};

const setDefaultBank = ({
    bankAccountHolderId,
    id,
}) => {
    return bankApiInstance({
        method: 'PUT',
        url: `/v3/bank/account-holders/${bankAccountHolderId}/accounts/${id}/set-default`,
    }).then(result => result?.data);
};

export const BridgeApi = {
    getBridgeAuth,
    fixValidationRequired,
    fixAuthRequired,
    fixUserActionRequired,
    setChosenBankConnectionType,
    setDefaultBank,
};
