import makeStyles from '@mui/styles/makeStyles';

export const useDropZoneStyles = makeStyles(theme => ({
    container: {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'minHeight': 100,
        'borderRadius': 8,
        'border': '1px dashed #2196F3',
        'marginTop': theme.spacing(1),
        'marginBottom': theme.spacing(1),
        '&:focus': {
            outline: 0,
        },
        [theme.breakpoints.down('md')]: {
            minHeight: 75,
        },
    },
    containerActive: {
        borderColor: theme.palette.secondary.main,
    },
    containerReject: {
        borderColor: theme.palette.error.main,
    },
    errorMessage: {
        borderColor: theme.palette.error.main,
    },
    containerAccept: {
        borderColor: theme.palette.success.main,
    },
    containerDisabled: {
        backgroundColor: theme.palette.grey['300'],
        borderColor: theme.palette.grey['500'],
        opacity: 0.5,
    },
    contentContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    errorContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    errorIcon: {
        width: 16,
        height: 16,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    errorText: {
        fontSize: theme.typography.pxToRem(14),
        letterSpacing: 0.1,
    },
    dropZoneText: {
        color: 'rgba(11, 35, 51, 0.65)',
        letterSpacing: 0.1,
        marginTop: theme.spacing(1),
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
        // [theme.breakpoints.down('md')]: {
        //     display: 'none',
        // },
    },
    maxFilesText: {
        color: 'rgba(11, 35, 51, 0.65)',
        letterSpacing: 0.1,
        fontSize: theme.typography.pxToRem(14),
    },
    chip: {
        borderRadius: 16,
        backgroundColor: '#f5f5f5',
    },
    chipAvatar: {
        fill: '#000',
    },
}));
