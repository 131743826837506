import {select} from 'redux-saga/effects';
import {selectCurrentCognitoUser} from '../../../features/auth/store/auth.selectors';
import {isUserCareOrFreelancer} from '../../../utils/user-roles';
import {selectFreelancerAccount} from '../freelancer/freelancer.selectors';

export const getFreelancerId = function* () {
    const user = yield select(selectCurrentCognitoUser);

    if (isUserCareOrFreelancer(user)) {
        return user.id;
    }

    const freelancer = yield select(selectFreelancerAccount);

    return freelancer.id;
};
