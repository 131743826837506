import React from 'react';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {appLoaderSaga} from '../../../../../v1/app/appLoader.sagas';
import {bankFlagAccessFlow, bankIntegrationMainCheckFlow} from '../../../store/bank.loader.saga';
import BankCards from '../components/BankCards';
import CardsConsent from '../components/CardsConsent';
import {getCardWorker, startBankCardLoader} from '../store/cards.saga';

export const BankCardRoutes = [
    {
        path: RoutePaths.BANK_CARDS,
        element: <BankCards />,
        exact: true,
        locationChangeSideEffects: [
            [startBankCardLoader],
            [appLoaderSaga],
            [bankFlagAccessFlow],
            [bankIntegrationMainCheckFlow],
            [getCardWorker],
        ],
    },
    {
        path: RoutePaths.CARDS_CONSENT,
        element: <CardsConsent />,
        exact: true,
        locationChangeSideEffects: [
            [appLoaderSaga],
            [bankFlagAccessFlow],
            [bankIntegrationMainCheckFlow],
        ],
    },
];
