/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {styled, useTheme} from '@mui/styles';
import {useThemeProps} from '@mui/system';
import * as React from 'react';
import {
    getTimePickerToolbarUtilityClass,
    timePickerToolbarClasses,
} from './timePickerToolbarClasses';
import {PickersToolbar} from '../internals/components/PickersToolbar';
import {PickersToolbarButton} from '../internals/components/PickersToolbarButton';
import {pickersToolbarClasses} from '../internals/components/pickersToolbarClasses';
import {PickersToolbarText} from '../internals/components/PickersToolbarText';
import {useMeridiemMode} from '../internals/hooks/date-helpers-hooks';
import {useLocaleText, useUtils} from '../internals/hooks/useUtils';
import {arrayIncludes} from '../internals/utils/utils';

const useUtilityClasses = ownerState => {
    const {theme, isLandscape, classes} = ownerState;

    const slots = {
        root: ['root'],
        separator: ['separator'],
        hourMinuteLabel: [
            'hourMinuteLabel',
            isLandscape && 'hourMinuteLabelLandscape',
            theme.direction === 'rtl' && 'hourMinuteLabelReverse',
        ],
        ampmSelection: ['ampmSelection', isLandscape && 'ampmLandscape'],
        ampmLabel: ['ampmLabel'],
    };

    return composeClasses(slots, getTimePickerToolbarUtilityClass, classes);
};

const TimePickerToolbarRoot = styled(PickersToolbar, {
    name: 'MuiTimePickerToolbar',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})({
    [`& .${pickersToolbarClasses.penIconButtonLandscape}`]: {
        marginTop: 'auto',
    },
});

const TimePickerToolbarSeparator = styled(PickersToolbarText, {
    name: 'MuiTimePickerToolbar',
    slot: 'Separator',
    overridesResolver: (_, styles) => styles.separator,
})({
    outline: 0,
    margin: '0 4px 0 2px',
    cursor: 'default',
});

const TimePickerToolbarHourMinuteLabel = styled('div', {
    name: 'MuiTimePickerToolbar',
    slot: 'HourMinuteLabel',
    overridesResolver: (_, styles) => [
        {
            [`&.${timePickerToolbarClasses.hourMinuteLabelLandscape}`]: styles.hourMinuteLabelLandscape,
            [`&.${timePickerToolbarClasses.hourMinuteLabelReverse}`]: styles.hourMinuteLabelReverse,
        },
        styles.hourMinuteLabel,
    ],
})(({theme, ownerState}) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    ...(ownerState.isLandscape && {
        marginTop: 'auto',
    }),
    ...(theme.direction === 'rtl' && {
        flexDirection: 'row-reverse',
    }),
}));

const TimePickerToolbarAmPmSelection = styled('div', {
    name: 'MuiTimePickerToolbar',
    slot: 'AmPmSelection',
    overridesResolver: (_, styles) => [
        {[`.${timePickerToolbarClasses.ampmLabel}`]: styles.ampmLabel},
        {[`&.${timePickerToolbarClasses.ampmLandscape}`]: styles.ampmLandscape},
        styles.ampmSelection,
    ],
})(({ownerState}) => ({
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
    marginLeft: 12,
    ...(ownerState.isLandscape && {
        margin: '4px 0 auto',
        flexDirection: 'row',
        justifyContent: 'space-around',
        flexBasis: '100%',
    }),
    [`& .${timePickerToolbarClasses.ampmLabel}`]: {
        fontSize: 17,
    },
}));

export const TimePickerToolbar = inProps => {
    const props = useThemeProps({props: inProps, name: 'MuiTimePickerToolbar'});
    const {
        ampm,
        ampmInClock,
        parsedValue,
        isLandscape,
        isMobileKeyboardViewOpen,
        onChange,
        openView,
        setOpenView,
        toggleMobileKeyboardView,
        toolbarTitle: toolbarTitleProp,
        views,
        disabled,
        readOnly,
        ...other
    } = props;
    const utils = useUtils();
    const localeText = useLocaleText();

    const toolbarTitle = toolbarTitleProp ?? localeText.timePickerDefaultToolbarTitle;
    const theme = useTheme();
    const showAmPmControl = Boolean(ampm && !ampmInClock);
    const {meridiemMode, handleMeridiemChange} = useMeridiemMode(
        parsedValue,
        ampm,
        onChange,
    );

    const formatHours = time => (ampm ? utils.format(time, 'hours12h') : utils.format(time, 'hours24h'));

    const ownerState = props;
    const classes = useUtilityClasses({...ownerState, theme});

    const separator = (
        <TimePickerToolbarSeparator
            tabIndex={-1}
            value=":"
            variant="h3"
            selected={false}
            className={classes.separator}
        />
    );

    return (
        <TimePickerToolbarRoot
            viewType="clock"
            landscapeDirection="row"
            toolbarTitle={toolbarTitle}
            isLandscape={isLandscape}
            isMobileKeyboardViewOpen={isMobileKeyboardViewOpen}
            toggleMobileKeyboardView={toggleMobileKeyboardView}
            ownerState={ownerState}
            className={classes.root}
            {...other}
        >
            <TimePickerToolbarHourMinuteLabel
                className={classes.hourMinuteLabel}
                ownerState={ownerState}
            >
                {arrayIncludes(views, 'hours') && (
                    <PickersToolbarButton
                        data-mui-test="hours"
                        tabIndex={-1}
                        variant="h3"
                        onClick={() => setOpenView('hours')}
                        selected={openView === 'hours'}
                        value={parsedValue ? formatHours(parsedValue) : '--'}
                    />
                )}
                {arrayIncludes(views, ['hours', 'minutes']) && separator}
                {arrayIncludes(views, 'minutes') && (
                    <PickersToolbarButton
                        data-mui-test="minutes"
                        tabIndex={-1}
                        variant="h3"
                        onClick={() => setOpenView('minutes')}
                        selected={openView === 'minutes'}
                        value={parsedValue ? utils.format(parsedValue, 'minutes') : '--'}
                    />
                )}
                {arrayIncludes(views, ['minutes', 'seconds']) && separator}
                {arrayIncludes(views, 'seconds') && (
                    <PickersToolbarButton
                        data-mui-test="seconds"
                        variant="h3"
                        onClick={() => setOpenView('seconds')}
                        selected={openView === 'seconds'}
                        value={parsedValue ? utils.format(parsedValue, 'seconds') : '--'}
                    />
                )}
            </TimePickerToolbarHourMinuteLabel>
            {showAmPmControl && (
                <TimePickerToolbarAmPmSelection
                    className={classes.ampmSelection}
                    ownerState={ownerState}
                >
                    <PickersToolbarButton
                        disableRipple
                        variant="subtitle2"
                        data-mui-test="toolbar-am-btn"
                        selected={meridiemMode === 'am'}
                        typographyClassName={classes.ampmLabel}
                        value={utils.getMeridiemText('am')}
                        onClick={readOnly ? undefined : () => handleMeridiemChange('am')}
                        disabled={disabled}
                    />
                    <PickersToolbarButton
                        disableRipple
                        variant="subtitle2"
                        data-mui-test="toolbar-pm-btn"
                        selected={meridiemMode === 'pm'}
                        typographyClassName={classes.ampmLabel}
                        value={utils.getMeridiemText('pm')}
                        onClick={readOnly ? undefined : () => handleMeridiemChange('pm')}
                        disabled={disabled}
                    />
                </TimePickerToolbarAmPmSelection>
            )}
        </TimePickerToolbarRoot>
    );
};
