import {Link} from '@mui/joy';
import {Link as MuiLink} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

const ReactRouterLink = React.forwardRef(({to, className, isV3, children, ...rest}, ref) => {
    const LinkComponent = isV3 ? Link : MuiLink;

    return (
        <LinkComponent component={RouterLink} {...rest} to={to} className={className} ref={ref}>
            {children}
        </LinkComponent>
    );
});

ReactRouterLink.propTypes = {
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    isV3: PropTypes.bool, // TODO: make true by default and delete the code, related to false value
};

ReactRouterLink.defaultProps = {
    className: '',
    isV3: false,
};

export default ReactRouterLink;
