import {ProfessionAndSpecializationActionTypes} from './profession-and-specialization.action-type';

export const ProfessionAndSpecializationActions = {
    storeAllValues: data => ({
        type: ProfessionAndSpecializationActionTypes.STORE_ALL_VALUES,
        payload: data,
    }),
    storeFreelancerData: data => ({
        type: ProfessionAndSpecializationActionTypes.STORE_FREELANCER_DATA,
        payload: data,
    }),
    saveFreelancerData: data => ({
        type: ProfessionAndSpecializationActionTypes.SAVE_FREELANCER_DATA,
        payload: data,
    }),
};
