/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {Skeleton} from '@mui/joy';
import {styled, useThemeProps} from '@mui/system';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';
import {getCalendarPickerSkeletonUtilityClass} from './calendarPickerSkeletonClasses';
import {DAY_MARGIN, DAY_SIZE} from '../internals/constants/dimensions';

const useUtilityClasses = ownerState => {
    const {classes} = ownerState;
    const slots = {
        root: ['root'],
        week: ['week'],
        daySkeleton: ['daySkeleton'],
    };

    return composeClasses(slots, getCalendarPickerSkeletonUtilityClass, classes);
};

const CalendarPickerSkeletonRoot = styled('div', {
    name: 'MuiCalendarPickerSkeleton',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})({
    alignSelf: 'start',
});

const CalendarPickerSkeletonWeek = styled('div', {
    name: 'MuiCalendarPickerSkeleton',
    slot: 'Week',
    overridesResolver: (_, styles) => styles.week,
})({
    margin: `${DAY_MARGIN}px 0`,
    display: 'flex',
    justifyContent: 'center',
});

const CalendarPickerSkeletonDay = styled(Skeleton, {
    name: 'MuiCalendarPickerSkeleton',
    slot: 'DaySkeleton',
    overridesResolver: (_, styles) => styles.daySkeleton,
})(({ownerState}) => ({
    margin: `0 ${DAY_MARGIN}px`,
    ...(ownerState.day === 0 && {
        visibility: 'hidden',
    }),
}));

CalendarPickerSkeletonDay.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // | To update them edit the TypeScript types and run "yarn proptypes"  |
    // ----------------------------------------------------------------------
    ownerState: PropTypes.shape({
        day: PropTypes.number.isRequired,
    }).isRequired,
};

const monthMap = [
    [0, 1, 1, 1, 1, 1, 1],
    [1, 1, 1, 1, 1, 1, 1],
    [1, 1, 1, 1, 1, 1, 1],
    [1, 1, 1, 1, 1, 1, 1],
    [1, 1, 1, 1, 0, 0, 0],
];

/**
 *
 * Demos:
 *
 * - [Date Picker](https://mui.com/x/react-date-pickers/date-picker/)
 *
 * API:
 *
 * - [CalendarPickerSkeleton API](https://mui.com/x/api/date-pickers/calendar-picker-skeleton/)
 */
const CalendarPickerSkeleton = inProps => {
    const props = useThemeProps({
        props: inProps,
        name: 'MuiCalendarPickerSkeleton',
    });

    const {className, ...other} = props;

    const classes = useUtilityClasses(other);

    return (
        <CalendarPickerSkeletonRoot
            className={clsx(classes.root, className)}
            {...other}
        >
            {monthMap.map((week, index) => (
                <CalendarPickerSkeletonWeek key={index} className={classes.week}>
                    {week.map((day, index2) => (
                        <CalendarPickerSkeletonDay
                            key={index2}
                            variant="circular"
                            width={DAY_SIZE}
                            height={DAY_SIZE}
                            className={classes.daySkeleton}
                            ownerState={{day}}
                        />
                    ))}
                </CalendarPickerSkeletonWeek>
            ))}
        </CalendarPickerSkeletonRoot>
    );
};

CalendarPickerSkeleton.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // | To update them edit the TypeScript types and run "yarn proptypes"  |
    // ----------------------------------------------------------------------
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object,

    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};

export {CalendarPickerSkeleton};
