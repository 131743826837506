import {SvgIcon} from '@mui/material';
import React from 'react';

const QuickBooksIcon = props => { // TODO STUPAR: Do not remove this, should be renamed
    return (
        <SvgIcon {...props}>
            <path d="M11.2319523,6.66028784 L7.38386974,6.66028784 C4.41043526,6.66028784 2,9.05105347 2,11.9995423 C2,14.9483364 4.41043526,17.3387969 7.38386974,17.3387969 L8.15299409,17.3387969 L8.15299409,15.3556452 L7.38386974,15.3556452 C5.51797423,15.3556452 3.99972288,13.8502805 3.99972288,11.9995423 C3.99972288,10.1491093 5.51797423,8.64343952 7.38386974,8.64343952 L9.2322294,8.64343952 L9.2322294,19.0168483 C9.2322294,20.1121582 10.1274898,21 11.2319523,21 L11.2319523,6.66028784 Z M12.7680474,3 L12.7680474,17.3397122 L16.6161299,17.3397122 C19.5895644,17.3397122 22,14.9492517 22,12.0004577 C22,9.05166367 19.5895644,6.66120311 16.6161299,6.66120311 L15.8470059,6.66120311 L15.8470059,8.64435479 L16.6161299,8.64435479 C18.4820254,8.64435479 20.0002768,10.1500246 20.0002768,12.0004577 C20.0002768,13.8508907 18.4820254,15.3565605 16.6161299,15.3565605 L14.7677706,15.3565605 L14.7677706,4.98315172 C14.7677706,3.88784177 13.8725098,3 12.7680474,3 Z" />
        </SvgIcon>
    );
};

export default QuickBooksIcon; // TODO STUPAR: Do not remove this, should be renamed
