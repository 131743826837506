import {DEPOSIT_OF_ACCOUNTS_DOCUMENTS} from '../../../../company-profile/modules/formalities/utils/constants';

export const DOCUMENT_SIGNING_TYPES = {
    ONBOARDING: 'onboarding',
    GLOBAL: 'global',
    TRAINING: 'training',
    ACCOUNTING: 'accounting',
};

export const ACCOUNTING_DOCUMENTS = [...Object.values(DEPOSIT_OF_ACCOUNTS_DOCUMENTS)];
