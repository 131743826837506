import {combineReducers} from 'redux';
import {loggedInUserReducer} from '../modules/logged-in-user';
import {
    isUserUpdatingReducer,
    loggedInUserDashboardReducer,
} from '../modules/logged-in-user/store/logged-in-user.reducer';
import {userSelectReducer} from '../modules/user-select/store/user-select.reducer';

export const userReducer = combineReducers({
    userSelect: userSelectReducer,
    loggedInUser: loggedInUserReducer,
    loggedInUserDashboard: loggedInUserDashboardReducer,
    isUserUpdating: isUserUpdatingReducer,
});
