import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {roundHalfUpToTwoDecimalPlaces} from '../../../../../../../../utils/number';

const InvoiceInfoRow = ({title, Icon, amount, sign, currency, isSelected, isFee}) => {
    const styles = useMemo(() => {
        const amountStyles = {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            color: isSelected ? '#fff' : 'text_v2.secondary',
            letterSpacing: '0.4px',
            whiteSpace: 'nowrap',
        };

        const amountValueStyles = {
            ...amountStyles,
            fontWeight: 500,
            mr: 2,
        };

        const feeValueStyles = {
            ...amountStyles,
            color: isSelected ? '#fff' : 'v2.light.secondary.main',
            fontWeight: 500,
        };

        const iconStyles = {
            color: isSelected ? '#fff' : 'v2.blueGray.300',
            mr: 0.5,
        };

        return {
            amountStyles,
            valueStyles: isFee ? feeValueStyles : amountValueStyles,
            iconStyles,
        };
    }, [isFee, isSelected]);

    return (
        <>
            <Icon sx={styles.iconStyles} />
            <Typography sx={styles.amountStyles}>
                {title}&nbsp;
            </Typography>
            {!!amount && (
                <Typography sx={styles.valueStyles}>
                    {sign}
                    {roundHalfUpToTwoDecimalPlaces(amount)}
                    {currency}
                </Typography>
            )}
        </>
    );
};

InvoiceInfoRow.propTypes = {
    title: PropTypes.string.isRequired,
    Icon: PropTypes.any.isRequired,
    isSelected: PropTypes.bool,
    isFee: PropTypes.bool,
    amount: PropTypes.string,
    sign: PropTypes.string,
    currency: PropTypes.string,
};

InvoiceInfoRow.defaultProps = {
    sign: '',
    amount: null,
    currency: '€',
    isFee: false,
    isSelected: false,
};

export default InvoiceInfoRow;
