export const DashboardActionTypes = {
    SET_IS_LOADING_DASHBOARD_DATA: 'dashboard/SET_IS_LOADING_DASHBOARD_DATA',
    STORE_DASHBOARD_DATA: 'dashboard/STORE_DASHBOARD_DATA',
    SET_DASHBOARD_YEAR: 'dashboard/SET_DASHBOARD_YEAR',
    INCOME_CALCULATION_REQUEST: 'dashboard/INCOME_CALCULATION_REQUEST',
    SET_IS_LOADING_INCOME_CALCULATION_REQUEST: 'dashboard/SET_IS_LOADING_INCOME_CALCULATION_REQUEST',
    SET_IS_FINISHED_INCOME_CALCULATION_REQUEST: 'dashboard/SET_IS_FINISHED_INCOME_CALCULATION_REQUEST',
    GET_TAX_INCOME: 'dashboard/GET_TAX_INCOME',
    SAVE_TAX_INCOME: 'dashboard/SAVE_TAX_INCOME',
    STORE_TAX_INFO: 'dashboard/STORE_TAX_INFO',
    DASHBOARD_IFRAME_KEY: 'dashboard/DASHBOARD_IFRAME_KEY',
    REQUEST_PAY_CALCULATION_INFO: 'dashboard/REQUEST_PAY_CALCULATION_INFO',
};
