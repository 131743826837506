import {
    faClose,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import {IconButton} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';

import {ButtonIcon} from './ButtonIcon';

const CloseButton = ({onClose, isDisabled, buttonStyles, isV3}) => (isV3 ? (
    <IconButton
        onClick={onClose}
        variant="plain"
        color="primary"
        size="sm"
        sx={{color: 'text.icon', ...buttonStyles}}
    >
        <FontAwesomeIcon size="xl" icon={faClose} />
    </IconButton>
) : (
    <ButtonIcon
        icon={<CloseIcon fontSize="large" />}
        onClick={onClose}
        sx={{
            'color': 'text_v2.primary',
            'backgroundColor': '#fff',
            'borderRadius': '8px',
            'zIndex': 1,
            '&:hover': {
                backgroundColor: '#fff',
                color: 'primary.main',
            },
            ...buttonStyles,
        }}
        disabled={isDisabled}
    />
));

CloseButton.propTypes = {
    onClose: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    buttonStyles: PropTypes.objectOf(PropTypes.any),
    isV3: PropTypes.bool, // TODO: make true by default and delete the code, related to false value
};

CloseButton.defaultProps = {
    isDisabled: false,
    buttonStyles: {},
    isV3: false,
};

export default CloseButton;
