import {all, call, put, takeEvery} from 'redux-saga/effects';
import {PayslipActionTypes} from './payslip.action-types';
import {PayslipActions} from './payslip.actions';
import {Toast} from '../../../../../lib/toast';
import {Debug} from '../../../../../utils/debug';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {PayslipApi} from '../api/payslip.api';

const payslipCreateFlow = function* (payload) {
    try {
        const response = yield call(PayslipApi.createPayslip, payload.freelancerId, payload.companyId, payload.data);

        yield put(PayslipActions.storePayslip(response));

        Toast.success('genericSuccessSave');
    } catch (error) {
        yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
        Debug.error('payslip', 'Error: ', {error});
        Toast.error('genericError');
    }
};

const payslipGetFlow = function* (companyId, freelancerId) {
    try {
        const data = yield call(PayslipApi.getPayslip, freelancerId, companyId);

        yield put(PayslipActions.storePayslip(data, freelancerId, companyId));
    } catch (error) {
        yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
        Debug.error('payslip', 'Error: ', {error});
        Toast.error('genericError');
    }
};

const payslipUpdateFlow = function* (payload) {
    try {
        const response = yield call(PayslipApi.updatePayslip, payload.freelancerId, payload.companyId, payload.data);

        yield put(PayslipActions.storePayslip(response));

        Toast.success('genericSuccessSave');
    } catch (error) {
        yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
        Debug.error('payslip', 'Error: ', {error});
        Toast.error('genericError');
    }
};

const reinitializeUpdateFlow = function* (payload) {
    try {
        yield call(PayslipApi.reinitializePayslip, payload.freelancerId, payload.companyId);

        yield put(PayslipActions.storePayslip(null));

        Toast.success('payslipReinitialized');
    } catch (error) {
        yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
        Debug.error('payslip', 'Error: ', {error});
        Toast.error('genericError');
    }
};

export const payslipGetWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, true));

    yield call(payslipGetFlow, payload.companyId, payload.freelancerId);

    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
};

const payslipCreateWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, true));

    yield call(payslipCreateFlow, payload);

    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
};

const payslipUpdateWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, true));

    yield call(payslipUpdateFlow, payload);

    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
};

const reinitializeUpdateWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, true));

    yield call(reinitializeUpdateFlow, payload);

    yield put(LoadingActions.setLoading(LoadingTypes.BO_CRUD_LOADER, false));
};

export const watchPayslipSaga = function* () {
    yield all([
        takeEvery(PayslipActionTypes.GET, payslipGetWorker),
        takeEvery(PayslipActionTypes.CREATE, payslipCreateWorker),
        takeEvery(PayslipActionTypes.UPDATE, payslipUpdateWorker),
        takeEvery(PayslipActionTypes.REINIT, reinitializeUpdateWorker),
    ]);
};
