import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import BankCardSidebarActions from './BankCardSidebarActions';

export const BankCardMobileDetails = ({
    selectedCard,
    t,
    card,
    setOpenSettings,
    lockCard,
    isCardLockLoading,
    unlockCard,
    showNumbers,
    viewPin,
}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
        }}
        >
            <BankCardSidebarActions
                setOpenSettings={setOpenSettings}
                viewPin={viewPin}
                t={t}
                isCardLockLoading={isCardLockLoading}
                showNumbers={showNumbers}
                unlockCard={unlockCard}
                lockCard={lockCard}
                selectedCard={selectedCard}
                card={card}
            />
        </Box>
    );
};

BankCardMobileDetails.propTypes = {
    selectedCard: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    card: PropTypes.object,
    setOpenSettings: PropTypes.func.isRequired,
    lockCard: PropTypes.func.isRequired,
    isCardLockLoading: PropTypes.bool,
    unlockCard: PropTypes.func.isRequired,
    showNumbers: PropTypes.func.isRequired,
    viewPin: PropTypes.func.isRequired,
};

BankCardMobileDetails.defaultProps = {
    card: {},
    isCardLockLoading: false,
};
