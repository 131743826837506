import {DashboardActionTypes} from './dashboard.action-type';

export const DashboardActions = {
    setIsLoadingDashboardData: isLoading => ({
        type: DashboardActionTypes.SET_IS_LOADING_DASHBOARD_DATA,
        payload: isLoading,
    }),
    storeDashboardData: dashboardData => ({
        type: DashboardActionTypes.STORE_DASHBOARD_DATA,
        payload: dashboardData,
    }),
    setDashboardYear: year => ({
        type: DashboardActionTypes.SET_DASHBOARD_YEAR,
        payload: year,
    }),
    incomeCalculationRequest: () => ({
        type: DashboardActionTypes.INCOME_CALCULATION_REQUEST,
    }),
    setIsLoadingIncomeCalculationRequest: isLoading => ({
        type: DashboardActionTypes.SET_IS_LOADING_INCOME_CALCULATION_REQUEST,
        payload: isLoading,
    }),
    setIsFinishedIncomeCalculationRequest: finished => ({
        type: DashboardActionTypes.SET_IS_FINISHED_INCOME_CALCULATION_REQUEST,
        payload: finished,
    }),
    getTaxIncome: ({freelancerId, companyId, year}) => ({
        type: DashboardActionTypes.GET_TAX_INCOME,
        payload: {freelancerId, companyId, year},
    }),
    saveTaxIncome: ({freelancerId, companyId, data}) => ({
        type: DashboardActionTypes.SAVE_TAX_INCOME,
        payload: {data, freelancerId, companyId},
    }),
    storeTaxIncome: data => ({
        type: DashboardActionTypes.STORE_TAX_INFO,
        payload: data,
    }),
    setDashboardIframeKey: data => ({
        type: DashboardActionTypes.DASHBOARD_IFRAME_KEY,
        payload: data,
    }),
    requestPayCalculationInfo: ({companyId, calculationType}) => ({
        type: DashboardActionTypes.REQUEST_PAY_CALCULATION_INFO,
        payload: {companyId, calculationType},
    }),
};
