import {LOCATION_CHANGED} from '../lib/router/connected-router-saga';

export const createReducer = (
    initialState,
    targetType,
    clearWhenRouteChanged = false,
) => (state = initialState, {type, payload}) => {
    if (type === targetType) {
        return payload;
    }

    if (clearWhenRouteChanged && type === LOCATION_CHANGED) {
        return initialState;
    }

    return state;
};
