import {Auth} from 'aws-amplify';
import axios from 'axios';
import {COOKIE_NAMES, getCookie} from '../../../utils/cookie';
import {getConfig} from '../../config';

const axiosInstance = axios.create({
    baseURL: getConfig().baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(async config => {
    const cookieToken = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);

    if (cookieToken) {
        config.headers.Authorization = `Bearer ${cookieToken}`;
        return config;
    }

    const session = await Auth.currentSession();

    if (!session) {
        return config;
    }

    const accessToken = session.getIdToken().getJwtToken();

    if (!accessToken) {
        return config;
    }

    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
}, error => Promise.reject(error));

export const safeTransformData = (response, transformer = null, transformerParams) => {
    if (!response || !response?.data) {
        return null;
    }

    if (!transformer) {
        return response.data;
    }

    return transformer(response.data, transformerParams);
};

export default axiosInstance;
