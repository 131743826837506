export const UPLOAD_SIGNATURE = 'signature/UPLOAD_SIGNATURE';
export const SET_IS_UPLOADING_SIGNATURE = 'signature/SET_IS_UPLOADING_SIGNATURE';
export const STORE_SIGNATURE = 'signature/STORE_SIGNATURE';
export const GET_SIGNATURE = 'signature/GET_SIGNATURE';
export const SET_IS_LOADING_SIGNATURE = 'signature/SET_IS_LOADING_SIGNATURE';

export const getSignature = freelancerId => ({
    type: GET_SIGNATURE,
    payload: {
        freelancerId,
    },
});

export const setIsLoadingSignature = isLoading => ({
    type: SET_IS_LOADING_SIGNATURE,
    payload: isLoading,
});

export const uploadSignature = file => ({
    type: UPLOAD_SIGNATURE,
    payload: {
        file,
    },
});

export const setIsUploadingSignature = isUploading => ({
    type: SET_IS_UPLOADING_SIGNATURE,
    payload: isUploading,
});

export const storeSignature = signature => ({
    type: STORE_SIGNATURE,
    payload: signature,
});
