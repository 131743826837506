export const NATIONALITIES = [
    {
        code: 'af',
        name: {
            en: 'Afghan',
            fr: 'Afghane',
        },
    },
    {
        code: 'al',
        name: {
            en: 'Albanian',
            fr: 'Albanaise',
        },
    },
    {
        code: 'dz',
        name: {
            en: 'Algerian',
            fr: 'Algerienne',
        },
    },
    {
        code: 'us',
        name: {
            en: 'American',
            fr: 'Americaine',
        },
    },
    {
        code: 'ad',
        name: {
            en: 'Andorran',
            fr: 'Andorrane',
        },
    },
    {
        code: 'ao',
        name: {
            en: 'Angolan',
            fr: 'Angolaise',
        },
    },
    {
        code: 'ag',
        name: {
            en: 'Antiguan, Barbudan',
            fr: 'Antiguaise et barbudienne',
        },
    },
    {
        code: 'ar',
        name: {
            en: 'Argentine',
            fr: 'Argentine',
        },
    },
    {
        code: 'am',
        name: {
            en: 'Armenian',
            fr: 'Armenienne',
        },
    },
    {
        code: 'au',
        name: {
            en: 'Australian',
            fr: 'Australienne',
        },
    },
    {
        code: 'at',
        name: {
            en: 'Austrian',
            fr: 'Autrichienne',
        },
    },
    {
        code: 'az',
        name: {
            en: 'Azerbaijani, Azeri',
            fr: 'Azerbaïdjanaise',
        },
    },
    {
        code: 'bs',
        name: {
            en: 'Bahamian',
            fr: 'Bahamienne',
        },
    },
    {
        code: 'bh',
        name: {
            en: 'Bahraini',
            fr: 'Bahreinienne',
        },
    },
    {
        code: 'bd',
        name: {
            en: 'Bangladeshi',
            fr: 'Bangladaise',
        },
    },
    {
        code: 'bb',
        name: {
            en: 'Barbadian',
            fr: 'Barbadienne',
        },
    },
    {
        code: 'ls',
        name: {
            en: 'Basotho',
            fr: 'Lesothane',
        },
    },
    {
        code: 'by',
        name: {
            en: 'Belarusian',
            fr: 'Bielorusse',
        },
    },
    {
        code: 'be',
        name: {
            en: 'Belgian',
            fr: 'Belge',
        },
    },
    {
        code: 'bz',
        name: {
            en: 'Belizean',
            fr: 'Belizienne',
        },
    },
    {
        code: 'bj',
        name: {
            en: 'Beninese',
            fr: 'Beninoise',
        },
    },
    {
        code: 'bt',
        name: {
            en: 'Bhutanese',
            fr: 'Bhoutanaise',
        },
    },
    {
        code: 'gw',
        name: {
            en: 'Bissau-Guinean',
            fr: 'Bissau-Guinéenne',
        },
    },
    {
        code: 'bo',
        name: {
            en: 'Bolivian',
            fr: 'Bolivienne',
        },
    },
    {
        code: 'ba',
        name: {
            en: 'Bosnian, Herzegovinian',
            fr: 'Bosnienne',
        },
    },
    {
        code: 'br',
        name: {
            en: 'Brazilian',
            fr: 'Bresilienne',
        },
    },
    {
        code: 'uk',
        name: {
            en: 'British',
            fr: 'Britannique',
        },
    },
    {
        code: 'bn',
        name: {
            en: 'Bruneian',
            fr: 'Bruneienne',
        },
    },
    {
        code: 'bg',
        name: {
            en: 'Bulgarian',
            fr: 'Bulgare',
        },
    },
    {
        code: 'bf',
        name: {
            en: 'Burkinabé',
            fr: 'Burkinabe',
        },
    },
    {
        code: 'mm',
        name: {
            en: 'Burmese',
            fr: 'Birmane',
        },
    },
    {
        code: 'bi',
        name: {
            en: 'Burundian',
            fr: 'Burundaise',
        },
    },
    {
        code: 'cv',
        name: {
            en: 'Cabo Verdean',
            fr: 'Cap-verdienne',
        },
    },
    {
        code: 'kh',
        name: {
            en: 'Cambodian',
            fr: 'Cambodgienne',
        },
    },
    {
        code: 'cm',
        name: {
            en: 'Cameroonian',
            fr: 'Camerounaise',
        },
    },
    {
        code: 'ca',
        name: {
            en: 'Canadian',
            fr: 'Canadienne',
        },
    },
    {
        code: 'cf',
        name: {
            en: 'Central African',
            fr: 'Centrafricaine',
        },
    },
    {
        code: 'td',
        name: {
            en: 'Chadian',
            fr: 'Tchadienne',
        },
    },
    {
        code: 'cl',
        name: {
            en: 'Chilean',
            fr: 'Chilienne',
        },
    },
    {
        code: 'cn',
        name: {
            en: 'Chinese',
            fr: 'Chinoise',
        },
    },
    {
        code: 'co',
        name: {
            en: 'Colombian',
            fr: 'Colombienne',
        },
    },
    {
        code: 'km',
        name: {
            en: 'Comoran',
            fr: 'Comorienne',
        },
    },
    {
        code: 'cg',
        name: {
            en: 'Congolese',
            fr: 'Congolaise',
        },
    },
    {
        code: 'cr',
        name: {
            en: 'Costa Rican',
            fr: 'Costaricaine',
        },
    },
    {
        code: 'hr',
        name: {
            en: 'Croatian',
            fr: 'Croate',
        },
    },
    {
        code: 'cu',
        name: {
            en: 'Cuban',
            fr: 'Cubaine',
        },
    },
    {
        code: 'cy',
        name: {
            en: 'Cypriot',
            fr: 'Chypriote',
        },
    },
    {
        code: 'cz',
        name: {
            en: 'Czech',
            fr: 'Tcheque',
        },
    },
    {
        code: 'dk',
        name: {
            en: 'Danish',
            fr: 'Danoise',
        },
    },
    {
        code: 'dj',
        name: {
            en: 'Djiboutian',
            fr: 'Djiboutienne',
        },
    },
    {
        code: 'do',
        name: {
            en: 'Dominican',
            fr: 'Dominicaine',
        },
    },
    {
        code: 'dm',
        name: {
            en: 'Dominican',
            fr: 'Dominiquaise',
        },
    },
    {
        code: 'nl',
        name: {
            en: 'Dutch, Netherlandic',
            fr: 'Neerlandaise',
        },
    },
    {
        code: 'ec',
        name: {
            en: 'Ecuadorian',
            fr: 'Equatorienne',
        },
    },
    {
        code: 'eg',
        name: {
            en: 'Egyptian',
            fr: 'Egyptienne',
        },
    },
    {
        code: 'ae',
        name: {
            en: 'Emirian',
            fr: 'Emirienne',
        },
    },
    {
        code: 'gq',
        name: {
            en: 'Equatoguinean',
            fr: 'Equato-guineenne',
        },
    },
    {
        code: 'er',
        name: {
            en: 'Eritrean',
            fr: 'Erythreenne',
        },
    },
    {
        code: 'ee',
        name: {
            en: 'Estonian',
            fr: 'Estonienne',
        },
    },
    {
        code: 'et',
        name: {
            en: 'Ethiopian',
            fr: 'Ethiopienne',
        },
    },
    {
        code: 'fj',
        name: {
            en: 'Fijian',
            fr: 'Fidjienne',
        },
    },
    {
        code: 'fi',
        name: {
            en: 'Finnish',
            fr: 'Finlandaise',
        },
    },
    {
        code: 'fr',
        name: {
            en: 'French',
            fr: 'Française',
        },
    },
    {
        code: 'ga',
        name: {
            en: 'Gabonese',
            fr: 'Gabonaise',
        },
    },
    {
        code: 'gm',
        name: {
            en: 'Gambian',
            fr: 'Gambienne',
        },
    },
    {
        code: 'ge',
        name: {
            en: 'Georgian',
            fr: 'Georgienne',
        },
    },
    {
        code: 'de',
        name: {
            en: 'German',
            fr: 'Allemande',
        },
    },
    {
        code: 'gh',
        name: {
            en: 'Ghanaian',
            fr: 'Ghaneenne',
        },
    },
    {
        code: 'gr',
        name: {
            en: 'Greek',
            fr: 'Hellenique',
        },
    },
    {
        code: 'gd',
        name: {
            en: 'Grenadian',
            fr: 'Grenadienne',
        },
    },
    {
        code: 'gt',
        name: {
            en: 'Guatemalan',
            fr: 'Guatemalteque',
        },
    },
    {
        code: 'gn',
        name: {
            en: 'Guinean',
            fr: 'Guineenne',
        },
    },
    {
        code: 'gf',
        name: {
            en: 'Guyanese',
            fr: 'Guyanienne',
        },
    },
    {
        code: 'ht',
        name: {
            en: 'Haitian',
            fr: 'Haïtienne',
        },
    },
    {
        code: 'hn',
        name: {
            en: 'Honduran',
            fr: 'Hondurienne',
        },
    },
    {
        code: 'hu',
        name: {
            en: 'Hungarian',
            fr: 'Hongroise',
        },
    },
    {
        code: 'is',
        name: {
            en: 'Icelandic',
            fr: 'Islandaise',
        },
    },
    {
        code: 'in',
        name: {
            en: 'Indian',
            fr: 'Indienne',
        },
    },
    {
        code: 'id',
        name: {
            en: 'Indonesian',
            fr: 'Indonesienne',
        },
    },
    {
        code: 'iq',
        name: {
            en: 'Iraqi',
            fr: 'Irakienne',
        },
    },
    {
        code: 'ie',
        name: {
            en: 'Irish',
            fr: 'Irlandaise',
        },
    },
    {
        code: 'il',
        name: {
            en: 'Israeli',
            fr: 'Israélienne',
        },
    },
    {
        code: 'it',
        name: {
            en: 'Italian',
            fr: 'Italienne',
        },
    },
    {
        code: 'ci',
        name: {
            en: 'Ivorian',
            fr: 'Ivoirienne',
        },
    },
    {
        code: 'jm',
        name: {
            en: 'Jamaican',
            fr: 'Jamaïcaine',
        },
    },
    {
        code: 'jp',
        name: {
            en: 'Japanese',
            fr: 'Japonaise',
        },
    },
    {
        code: 'jo',
        name: {
            en: 'Jordanian',
            fr: 'Jordanienne',
        },
    },
    {
        code: 'kz',
        name: {
            en: 'Kazakhstani',
            fr: 'Kazakhstanaise',
        },
    },
    {
        code: 'ke',
        name: {
            en: 'Kenyan',
            fr: 'Kenyane',
        },
    },
    {
        code: 'kg',
        name: {
            en: 'Kirghiz',
            fr: 'Kirghize',
        },
    },
    {
        code: 'ki',
        name: {
            en: 'Kiribati',
            fr: 'Kiribatienne',
        },
    },
    {
        code: 'kn',
        name: {
            en: 'Kittitian, Nevisian',
            fr: 'Kittitienne-et-nevicienne',
        },
    },
    {
        code: 'xk',
        name: {
            en: 'Kosovar',
            fr: 'Kossovienne',
        },
    },
    {
        code: 'kw',
        name: {
            en: 'Kuwaiti',
            fr: 'Koweitienne',
        },
    },
    {
        code: 'la',
        name: {
            en: 'Laotian',
            fr: 'Laotienne',
        },
    },
    {
        code: 'lv',
        name: {
            en: 'Latvian',
            fr: 'Lettone',
        },
    },
    {
        code: 'lb',
        name: {
            en: 'Lebanese',
            fr: 'Libanaise',
        },
    },
    {
        code: 'lr',
        name: {
            en: 'Liberian',
            fr: 'Liberienne',
        },
    },
    {
        code: 'ly',
        name: {
            en: 'Libyan',
            fr: 'Libyenne',
        },
    },
    {
        code: 'li',
        name: {
            en: 'Liechtensteiner',
            fr: 'Liechtensteinoise',
        },
    },
    {
        code: 'lt',
        name: {
            en: 'Lithuanian',
            fr: 'Lituanienne',
        },
    },
    {
        code: 'lu',
        name: {
            en: 'Luxembourgish',
            fr: 'Luxembourgeoise',
        },
    },
    {
        code: 'mk',
        name: {
            en: 'Macedonian',
            fr: 'Macedonienne',
        },
    },
    {
        code: 'mg',
        name: {
            en: 'Malagasy',
            fr: 'Malgache',
        },
    },
    {
        code: 'mw',
        name: {
            en: 'Malawian',
            fr: 'Malawienne',
        },
    },
    {
        code: 'my',
        name: {
            en: 'Malaysian',
            fr: 'Malaisienne',
        },
    },
    {
        code: 'mv',
        name: {
            en: 'Maldivian',
            fr: 'Maldivienne',
        },
    },
    {
        code: 'ml',
        name: {
            en: 'Malian',
            fr: 'Malienne',
        },
    },
    {
        code: 'mt',
        name: {
            en: 'Maltese',
            fr: 'Maltaise',
        },
    },
    {
        code: 'mh',
        name: {
            en: 'Marshallese',
            fr: 'Marshallaise',
        },
    },
    {
        code: 'mu',
        name: {
            en: 'Mauritanian',
            fr: 'Mauricienne',
        },
    },
    {
        code: 'mr',
        name: {
            en: 'Mauritanian',
            fr: 'Mauritanienne',
        },
    },
    {
        code: 'mx',
        name: {
            en: 'Mexican',
            fr: 'Mexicaine',
        },
    },
    {
        code: 'fm',
        name: {
            en: 'Micronesian',
            fr: 'Micronesienne',
        },
    },
    {
        code: 'md',
        name: {
            en: 'Moldovan',
            fr: 'Moldave',
        },
    },
    {
        code: 'mn',
        name: {
            en: 'Mongolian',
            fr: 'Mongole',
        },
    },
    {
        code: 'me',
        name: {
            en: 'Montenegrin',
            fr: 'Montenegrine',
        },
    },
    {
        code: 'mc',
        name: {
            en: 'Monégasque',
            fr: 'Monegasque',
        },
    },
    {
        code: 'ma',
        name: {
            en: 'Moroccan',
            fr: 'Marocaine',
        },
    },
    {
        code: 'bw',
        name: {
            en: 'Motswana, Botswanan',
            fr: 'Botswanaise',
        },
    },
    {
        code: 'mz',
        name: {
            en: 'Mozambican',
            fr: 'Mozambicaine',
        },
    },
    {
        code: 'na',
        name: {
            en: 'Namibian',
            fr: 'Namibienne',
        },
    },
    {
        code: 'nr',
        name: {
            en: 'Nauruan',
            fr: 'Nauruane',
        },
    },
    {
        code: 'np',
        name: {
            en: 'Nepali',
            fr: 'Nepalaise',
        },
    },
    {
        code: 'nz',
        name: {
            en: 'New Zealandic',
            fr: 'Neo-zelandaise',
        },
    },
    {
        code: 'ni',
        name: {
            en: 'Nicaraguan',
            fr: 'Nicaraguayenne',
        },
    },
    {
        code: 'ng',
        name: {
            en: 'Nigerian',
            fr: 'Nigeriane',
        },
    },
    {
        code: 'ne',
        name: {
            en: 'Nigerien',
            fr: 'Nigerienne',
        },
    },
    {
        code: 'kp',
        name: {
            en: 'North Korean',
            fr: 'Nord-coréenne',
        },
    },
    {
        code: 'no',
        name: {
            en: 'Norwegian',
            fr: 'Norvegienne',
        },
    },
    {
        code: 'om',
        name: {
            en: 'Omani',
            fr: 'Omanaise',
        },
    },
    {
        code: 'pk',
        name: {
            en: 'Pakistani',
            fr: 'Pakistanaise',
        },
    },
    {
        code: 'pw',
        name: {
            en: 'Palauan',
            fr: 'Palau',
        },
    },
    {
        code: 'ps',
        name: {
            en: 'Palestinian',
            fr: 'Palestinienne',
        },
    },
    {
        code: 'pa',
        name: {
            en: 'Panamanian',
            fr: 'Panameenne',
        },
    },
    {
        code: 'pg',
        name: {
            en: 'Papua New Guinean',
            fr: 'Papouane-neoguineenne',
        },
    },
    {
        code: 'py',
        name: {
            en: 'Paraguayan',
            fr: 'Paraguayenne',
        },
    },
    {
        code: 'pe',
        name: {
            en: 'Peruvian',
            fr: 'Peruvienne',
        },
    },
    {
        code: 'ph',
        name: {
            en: 'Philippine',
            fr: 'Philippine',
        },
    },
    {
        code: 'pl',
        name: {
            en: 'Polish',
            fr: 'Polonaise',
        },
    },
    {
        code: 'pt',
        name: {
            en: 'Portuguese',
            fr: 'Portugaise',
        },
    },
    {
        code: 'pr',
        name: {
            en: 'Puerto Rican',
            fr: 'Portoricaine',
        },
    },
    {
        code: 'qa',
        name: {
            en: 'Qatari',
            fr: 'Qatarienne',
        },
    },
    {
        code: 'ro',
        name: {
            en: 'Romanian',
            fr: 'Roumaine',
        },
    },
    {
        code: 'ru',
        name: {
            en: 'Russian',
            fr: 'Russe',
        },
    },
    {
        code: 'rw',
        name: {
            en: 'Rwandan',
            fr: 'Rwandaise',
        },
    },
    {
        code: 'lc',
        name: {
            en: 'Saint Lucian',
            fr: 'Saint-Lucienne',
        },
    },
    {
        code: 'vc',
        name: {
            en: 'Saint Vincentian',
            fr: 'Saint-Vincentaise-et-Grenadine',
        },
    },
    {
        code: 'sm',
        name: {
            en: 'Saint-Martinoise',
            fr: 'Saint-Marinaise',
        },
    },
    {
        code: 'sv',
        name: {
            en: 'Salvadoran',
            fr: 'Salvadorienne',
        },
    },
    {
        code: 'ws',
        name: {
            en: 'Samoan',
            fr: 'Samoane',
        },
    },
    {
        code: 'sa',
        name: {
            en: 'Saudi Arabian',
            fr: 'Saoudienne',
        },
    },
    {
        code: 'sn',
        name: {
            en: 'Senegalese',
            fr: 'Senegalaise',
        },
    },
    {
        code: 'rs',
        name: {
            en: 'Serbian',
            fr: 'Serbe',
        },
    },
    {
        code: 'sc',
        name: {
            en: 'Seychellois',
            fr: 'Seychelloise',
        },
    },
    {
        code: 'sl',
        name: {
            en: 'Sierra Leonean',
            fr: 'Sierra-leonaise',
        },
    },
    {
        code: 'sg',
        name: {
            en: 'Singapore',
            fr: 'Singapourienne',
        },
    },
    {
        code: 'sk',
        name: {
            en: 'Slovak',
            fr: 'Slovaque',
        },
    },
    {
        code: 'si',
        name: {
            en: 'Slovenian',
            fr: 'Slovene',
        },
    },
    {
        code: 'sb',
        name: {
            en: 'Solomon Island',
            fr: 'Salomonaise',
        },
    },
    {
        code: 'so',
        name: {
            en: 'Somali',
            fr: 'Somalienne',
        },
    },
    {
        code: 'za',
        name: {
            en: 'South African',
            fr: 'Sud-africaine',
        },
    },
    {
        code: 'kr',
        name: {
            en: 'South Korean',
            fr: 'Sud-coréenne',
        },
    },
    {
        code: 'es',
        name: {
            en: 'Spanish',
            fr: 'Espagnole',
        },
    },
    {
        code: 'lk',
        name: {
            en: 'Sri Lankan',
            fr: 'Sri-lankaise',
        },
    },
    {
        code: 'sd',
        name: {
            en: 'Sudanese',
            fr: 'Soudanaise',
        },
    },
    {
        code: 'sr',
        name: {
            en: 'Surinamese',
            fr: 'Surinamaise',
        },
    },
    {
        code: 'se',
        name: {
            en: 'Swedish',
            fr: 'Suedoise',
        },
    },
    {
        code: 'ch',
        name: {
            en: 'Swiss',
            fr: 'Suisse',
        },
    },
    {
        code: 'sy',
        name: {
            en: 'Syrian',
            fr: 'Syrienne',
        },
    },
    {
        code: 'st',
        name: {
            en: 'São Toméan',
            fr: 'Santomeenne',
        },
    },
    {
        code: 'tw',
        name: {
            en: 'Taiwanese',
            fr: 'Taiwanaise',
        },
    },
    {
        code: 'tj',
        name: {
            en: 'Tajikistani',
            fr: 'Tadjike',
        },
    },
    {
        code: 'tz',
        name: {
            en: 'Tanzanian',
            fr: 'Tanzanienne',
        },
    },
    {
        code: 'th',
        name: {
            en: 'Thai',
            fr: 'Thaïlandaise',
        },
    },
    {
        code: 'tl',
        name: {
            en: 'Timorese',
            fr: 'Est-timoraise',
        },
    },
    {
        code: 'tg',
        name: {
            en: 'Togolese',
            fr: 'Togolaise',
        },
    },
    {
        code: 'tt',
        name: {
            en: 'Trinidadian, Tobagonian',
            fr: 'Trinidadienne',
        },
    },
    {
        code: 'tn',
        name: {
            en: 'Tunisian',
            fr: 'Tunisienne',
        },
    },
    {
        code: 'tr',
        name: {
            en: 'Turkish',
            fr: 'Turque',
        },
    },
    {
        code: 'tm',
        name: {
            en: 'Turkmen',
            fr: 'Turkmene',
        },
    },
    {
        code: 'tv',
        name: {
            en: 'Tuvaluan',
            fr: 'Tuvaluane',
        },
    },
    {
        code: 'ug',
        name: {
            en: 'Ugandan',
            fr: 'Ougandaise',
        },
    },
    {
        code: 'ua',
        name: {
            en: 'Ukrainian',
            fr: 'Ukrainienne',
        },
    },
    {
        code: 'uy',
        name: {
            en: 'Uruguayan',
            fr: 'Uruguayenne',
        },
    },
    {
        code: 'uz',
        name: {
            en: 'Uzbekistani',
            fr: 'Ouzbeke',
        },
    },
    {
        code: 'vu',
        name: {
            en: 'Vanuatuan',
            fr: 'Vanuatuane',
        },
    },
    {
        code: 've',
        name: {
            en: 'Venezuelan',
            fr: 'Venezuelienne',
        },
    },
    {
        code: 'vn',
        name: {
            en: 'Vietnamese',
            fr: 'Vietnamienne',
        },
    },
    {
        code: 'ye',
        name: {
            en: 'Yemeni',
            fr: 'Yemenite',
        },
    },
    {
        code: 'zm',
        name: {
            en: 'Zambian',
            fr: 'Zambienne',
        },
    },
    {
        code: 'zw',
        name: {
            en: 'Zimbabwean',
            fr: 'Zimbabweenne',
        },
    },
];
