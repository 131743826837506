export const GET_CONTRACT_URL = 'GET_CONTRACT_URL';
export const STORE_CONTRACT_URL = 'STORE_CONTRACT_URL';
export const STORE_CONTRACT_DOCUMENT_ID = 'STORE_CONTRACT_DOCUMENT_ID';
export const SET_IS_LOADING_CONTRACT_URL = 'SET_IS_LOADING_CONTRACT_URL';
export const SIGN_CONTRACT = 'SIGN_CONTRACT';
export const SET_IS_SIGNING_CONTRACT = 'SET_IS_SIGNING_CONTRACT';

export const getContractUrl = () => ({
    type: GET_CONTRACT_URL,
});

export const storeContractUrl = contractUrl => ({
    type: STORE_CONTRACT_URL,
    payload: contractUrl,
});

export const storeContractDocumentId = documentId => ({
    type: STORE_CONTRACT_DOCUMENT_ID,
    payload: documentId,
});

export const setIsLoadingContractUrl = isLoading => ({
    type: SET_IS_LOADING_CONTRACT_URL,
    payload: isLoading,
});

export const signContract = () => ({
    type: SIGN_CONTRACT,
});

export const setIsSigningContractUrl = isSigning => ({
    type: SET_IS_SIGNING_CONTRACT,
    payload: isSigning,
});
