import {Contract} from './contract.dto';
import {hiwayApiInstance} from '../../../lib/hiway-api-instance';

const getContract = ({freelancerId}) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}/documents/hiway-contract`)
        .then(result => new Contract(result.data));
};

export const ContractApi = {
    getContract,
};
