import {UserDto} from './create-user-form.dto';
import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';

const createUser = data => {
    return hiwayApiInstance
        .post(`/v1/users`, new UserDto(data))
        .then(result => result.data);
};

export const CreateUserApi = {
    createUser,
};
