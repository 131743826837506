const DEFAULT_LANGUAGE = 'en';

/**
 * @typedef {Object} SettingsDTO
 * @property {boolean} email_notification_enabled
 * @property {boolean} opportunity_notification_enabled
 * @property {String} language
 */

/**
 * @typedef {Object} Settings
 * @property {boolean} shouldReceiveEmailNotifications
 * @property {boolean} shouldReceiveOpportunityNotifications
 * @property {String} language
 */

/**
 * Transforms settings DTO to settings
 * @param {SettingsDTO} settingsDTO What we send to API
 * @returns {Settings} What we use internally
 */
export const fromSettingsDTO = settingsDTO => ({
    shouldReceiveEmailNotifications: settingsDTO.email_notification_enabled,
    language: settingsDTO.language ? settingsDTO.language.toLowerCase() : DEFAULT_LANGUAGE,
    shouldReceiveOpportunityNotifications: settingsDTO.opportunity_notification_enabled,
});

/**
 * Transforms settings to settings DTO
 * @param {Settings} settings What we use internally
 * @returns {SettingsDTO} What we send to API
 */
export const toSettingsDTO = settings => ({
    email_notification_enabled: settings.shouldReceiveEmailNotifications,
    language: settings.language ? settings.language.toUpperCase() : DEFAULT_LANGUAGE.toUpperCase(),
    opportunity_notification_enabled: settings.shouldReceiveOpportunityNotifications,
});
