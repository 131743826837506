import {eventChannel} from 'redux-saga';
import {call, delay, put, select, take} from 'redux-saga/effects';
import {CONFIG} from '../../../../config';
import {Debug} from '../../../../utils/debug';
import {UserRoles} from '../../../../utils/user-roles';
import {
    handleSSETransferUploadFileFail,
    handleSSETransferUploadFileSuccess,
} from '../../../bank/modules/transfers/store/transfers.saga';
import {handleSSEUploadFileFail, handleSSEUploadFileSuccess} from '../../../bank/store/bank.saga';
import {LoadingActions, LoadingSelectors, LoadingTypes} from '../../../loading';
import {LoggedInUserSelectors} from '../../../user/modules/logged-in-user';
import {SseApi} from '../../api/sse.api';
import {SSEEmitterActionTypes} from '../sse.action-types';
import {SseActions} from '../sse.actions';
import {SSESelector} from '../sse.selector';

let reconnectDelay = 0;
const delayIncrement = 2000;

const SSE_EVENTS_ENUM = {
    TRANSACTION_DOCUMENTS_UPLOAD_SUCCESS: 'TRANSACTION_DOCUMENTS_UPLOAD_SUCCESS',
    TRANSACTION_DOCUMENTS_UPLOAD_FAILED: 'TRANSACTION_DOCUMENTS_UPLOAD_FAILED',
    TRANSFER_DOCUMENTS_UPLOAD_SUCCESS: 'TRANSFER_DOCUMENTS_UPLOAD_SUCCESS',
    TRANSFER_DOCUMENTS_UPLOAD_FAILED: 'TRANSFER_DOCUMENTS_UPLOAD_FAILED',
};

const SSE_EVENTS = [{
    key: SSE_EVENTS_ENUM.TRANSACTION_DOCUMENTS_UPLOAD_FAILED,
    handler: handleSSEUploadFileFail,
}, {
    key: SSE_EVENTS_ENUM.TRANSACTION_DOCUMENTS_UPLOAD_SUCCESS,
    handler: handleSSEUploadFileSuccess,
}, {
    key: SSE_EVENTS_ENUM.TRANSFER_DOCUMENTS_UPLOAD_FAILED,
    handler: handleSSETransferUploadFileFail,
}, {
    key: SSE_EVENTS_ENUM.TRANSFER_DOCUMENTS_UPLOAD_SUCCESS,
    handler: handleSSETransferUploadFileSuccess,
}];


const subSSE = eventSrc => {
    return eventChannel(emitter => {
        eventSrc.onerror = error => {
            if (error?.data === 'Invalid sse token') {
                // Close old one event to prevent browser auto reconnect
                eventSrc.close();
                emitter({type: SSEEmitterActionTypes.RECONNECT_WITH_NEW_TOKEN});
            } else if (error.target.readyState === EventSource.CLOSED) {
                emitter({type: SSEEmitterActionTypes.RECONNECT});
            }

            emitter({type: SSEEmitterActionTypes.STORE_SSE_STATUS, payload: error.target.readyState});
        };

        eventSrc.onopen = event => {
            if (reconnectDelay !== 0) {
                reconnectDelay = 0;
            }

            emitter({type: SSEEmitterActionTypes.STORE_SSE_STATUS, payload: event.target.readyState});
        };

        SSE_EVENTS.forEach(event => {
            eventSrc.addEventListener(event.key, data => {
                emitter({
                    type: SSEEmitterActionTypes.SERVER_SIDE_EVENT,
                    data,
                    handler: event.handler,
                });
            });
        });

        return () => {
            emitter({type: SSEEmitterActionTypes.STORE_SSE_STATUS, payload: EventSource.CLOSED});

            eventSrc.close();
        };
    });
};

const getSseToken = function* (forceNewToken = false) {
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);

    // Prevent logged out users to reconnect to SSE
    if (!user) {
        return;
    }

    const isSSETokenGetInProgress = yield select(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.SSE_TOKEN_GET,
    ));

    if (isSSETokenGetInProgress) {
        return;
    }

    const SSEEventSource = yield select(SSESelector.selectBankSSEEventSource);

    if (forceNewToken) {
        if (
            SSEEventSource
            && SSEEventSource.readyState
            && SSEEventSource?.readyState !== EventSource.CLOSED
        ) {
            SSEEventSource.close();
        }
    } else if (
        SSEEventSource?.readyState === EventSource.CONNECTING
        || SSEEventSource?.readyState === EventSource.OPEN
    ) {
        return;
    }

    try {
        // Prevent users who can't access bank feature to receieve SSE
        if (!user?.hasBankAccess && user.role === UserRoles.FREELANCER) {
            return;
        }

        yield put(LoadingActions.setLoading(LoadingTypes.SSE_TOKEN_GET, true));

        const token = yield call(SseApi.getBankSSEToken);

        yield put(LoadingActions.setLoading(LoadingTypes.SSE_TOKEN_GET, false));

        const SSEEventSource = yield select(SSESelector.selectBankSSEEventSource);
        // If we still have old event connected before requesting new connection disconnect it
        if (SSEEventSource && SSEEventSource?.readyState) {
            SSEEventSource.close();
        }

        const eventSource = new EventSource(`${CONFIG.API.BANK_API_BASE_URL}/v3/sse?token=${token}`, {});

        yield put(SseActions.storeBankSSEEventSource(eventSource));

        const channel = yield call(subSSE, eventSource);

        while (true) {
            const action = yield take(channel);

            // Handle custom actions
            if (action.type) {
                if (action.type === SSEEmitterActionTypes.RECONNECT_WITH_NEW_TOKEN) {
                    yield delay(reconnectDelay);
                    reconnectDelay += delayIncrement;
                    yield call(getSseToken, true);
                } else if (action.type === SSEEmitterActionTypes.RECONNECT) {
                    yield delay(reconnectDelay);
                    reconnectDelay += delayIncrement;
                    yield call(getSseToken);
                }

                if (action.type === SSEEmitterActionTypes.STORE_SSE_STATUS) {
                    yield put(SseActions.storeBankSSEStatus(action.payload));
                }

                if (action.type === SSEEmitterActionTypes.SERVER_SIDE_EVENT) {
                    yield call(action.handler, action.data);
                }
            }
        }
    } catch (e) {
        Debug.error('SSE', 'SSE error: ', {e});

        yield put(LoadingActions.setLoading(LoadingTypes.SSE_TOKEN_GET, false));

        yield delay(reconnectDelay);
        reconnectDelay += delayIncrement;
        yield call(getSseToken);
    }
};

const dcSseToken = function* () {
    const SSEEventSource = yield select(SSESelector.selectBankSSEEventSource);

    if (
        SSEEventSource
        && SSEEventSource.readyState
        && SSEEventSource?.readyState !== EventSource.CLOSED
    ) {
        SSEEventSource.close();
    }
};

export const bankSSE = {
    getSseToken,
    dcSseToken,
};
