const selectUrl = state => state.signature.url;
const selectSignatureInfo = state => state.signature.info;
const selectEventId = state => state.signature.eventId;
const selectIsUploaded = state => state.signature.isUploaded;

export const SignatureSelectors = {
    selectUrl,
    selectEventId,
    selectIsUploaded,
    selectSignatureInfo,
};
