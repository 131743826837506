export const BANK_ACCOUNT_STATEMENTS_SORT_VALUES_ENUM = {
    statementName: 'statementName',
    openingDate: 'openingDate',
};

export const DOWNLOADED_RIB_FILE_NAME = 'Relevé d\'identité bancaire';
export const DEFAULT_ACCOUNT_STATEMENTS_ROWS_PER_PAGE = 10;

export const RIB_PREVIEW_SCALING_FACTOR = 1;

export const LG_RIB_PREVIEW_WIDTH = 439.78;

export const BANK_ACCOUNT_STATEMENTS_DATA_TABLE_ID = 'bankAccountStatements';

export const pdfPageWidth = 1000 * RIB_PREVIEW_SCALING_FACTOR;

export const pixelDensity = window.devicePixelRatio;

export const lm = 220 * RIB_PREVIEW_SCALING_FACTOR;

export const tm = 456 * RIB_PREVIEW_SCALING_FACTOR;

export const pdfPageAspectRatio = 1000 / 1294;
