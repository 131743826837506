import {bankApiInstance} from '../../../../../lib/bank-api-instance';

const getBeneficiariesList = ({accountId}) => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/bank/accounts/${accountId}/beneficiaries`,
    }).then(result => result?.data);
};

const createBeneficiaries = ({accountId, data}) => {
    return bankApiInstance({
        method: 'POST',
        url: `/v3/bank/accounts/${accountId}/beneficiaries`,
        data,
    }).then(result => result?.data);
};

const updateBeneficiaries = ({accountId, data}) => {
    return bankApiInstance({
        method: 'PUT',
        url: `/v3/bank/accounts/${accountId}/beneficiaries/${data.id}`,
        data: {
            label: data.label,
            iban: data.iban,
            isPersonal: data.isPersonal,
        },
    }).then(result => result?.data);
};

const deleteBeneficiaries = ({accountId, data}) => {
    return bankApiInstance({
        method: 'DELETE',
        url: `/v3/bank/accounts/${accountId}/beneficiaries/${data.id}`,
    }).then(result => result?.data);
};

export const BeneficiariesApi = {
    getBeneficiariesList,
    createBeneficiaries,
    updateBeneficiaries,
    deleteBeneficiaries,
};
