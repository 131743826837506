import {
    faClose,
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Input} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';

export const AccountBalanceSearch = ({
    filter,
    setFilter,
    searchPlaceholder,
    isMobile,
}) => {
    const onSearchTextChange = text => {
        setFilter(filter => {
            return {
                ...filter,
                search: text,
            };
        });
    };

    return (
        <Input
            size={!isMobile ? 'sm' : 'md'}
            fullWidth={isMobile}
            value={filter.search}
            onChange={event => {
                onSearchTextChange(event.target.value);
            }}
            variant="outlined"
            placeholder={searchPlaceholder}
            sx={{
                backgroundColor: 'background.body',
                py: 0.75,
                px: 1,
                color: 'text.secondary',
                borderRadius: 'xxl',
                ...(!isMobile ? {
                    minWidth: '230px',
                    mr: 'auto',
                } : {}),
            }}
            startDecorator={(
                <Box color="neutral.outlinedColor">
                    <FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth />
                </Box>
            )}
            endDecorator={filter.search ? (
                <Box
                    color="neutral.outlinedColor"
                    onClick={() => {
                        onSearchTextChange('');
                    }}
                    sx={{cursor: 'pointer'}}
                >
                    <FontAwesomeIcon icon={faClose} fixedWidth />
                </Box>
            ) : null}
        />
    );
};

AccountBalanceSearch.propTypes = {
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
    isMobile: PropTypes.bool,
};

AccountBalanceSearch.defaultProps = {
    isMobile: false,
};
