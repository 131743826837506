import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';

export const InputIcon = ({isError, icon}) => {
    return (
        <Box
            fontSize="md"
            color={isError ? 'danger' : 'neutral.outlinedColor'}
        >
            <FontAwesomeIcon icon={icon} />
        </Box>
    );
};

InputIcon.propTypes = {
    icon: PropTypes.any.isRequired,
    isError: PropTypes.bool,
};

InputIcon.defaultProps = {
    isError: false,
};
