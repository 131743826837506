/* eslint-disable react/prop-types */
import {DialogContent, Modal, ModalDialog, modalDialogClasses} from '@mui/joy';
import {styled} from '@mui/system';
import * as React from 'react';
import {PickersActionBar} from '../../PickersActionBar';
import {DIALOG_WIDTH} from '../constants/dimensions';

const PickersModalDialogRoot = styled(Modal)({
    [`& .${modalDialogClasses.root}`]: {
        outline: 0,
        background: '#fff',
        minWidth: DIALOG_WIDTH,
    },
});

const PickersModalDialogContent = styled(DialogContent)({
    '&:first-of-type': {
        padding: 0,
    },
});

export const PickersModalDialog = props => {
    const {
        children,
        DialogProps = {},
        onAccept,
        onClear,
        onDismiss,
        onCancel,
        onSetToday,
        open,
        components,
        componentsProps,
    } = props;

    const ActionBar = components?.ActionBar ?? PickersActionBar;

    return (
        <PickersModalDialogRoot open={open} onClose={onDismiss} {...DialogProps}>
            <ModalDialog>
                <PickersModalDialogContent>{children}</PickersModalDialogContent>
                <ActionBar
                    onAccept={onAccept}
                    onClear={onClear}
                    onCancel={onCancel}
                    onSetToday={onSetToday}
                    actions={['cancel', 'accept']}
                    {...componentsProps?.actionBar}
                />
            </ModalDialog>
        </PickersModalDialogRoot>
    );
};
