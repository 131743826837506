import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import {Box, Button, Divider} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {CardsActions} from '../../store/cards.actions';
import {
    BankCardForm,
    bankCardSettingsDefaultValues,
    createBankCardSettingsValidation,
} from '../create/BankCardSettings';

const BankCardUpdate = ({t, card, onCancel, onDeleteOpen}) => {
    const dispatch = useDispatch();

    const {
        control,
        handleSubmit: createSubmitHandler,
        formState: {isValid, errors},
    } = useForm({
        resolver: yupResolver(createBankCardSettingsValidation(t)),
        defaultValues: {...bankCardSettingsDefaultValues, ...card?.settings},
        mode: 'onChange',
    });

    const handleSubmit = data => {
        dispatch(CardsActions.update(data, card.id));
    };

    return (
        <Box sx={{px: 3}}>
            <form onSubmit={createSubmitHandler(handleSubmit)}>
                <BankCardForm
                    isMobileSize={true}
                    t={t}
                    control={control}
                    onCancel={onCancel}
                    errors={errors}
                    isValid={isValid}
                />

                <Divider
                    sx={{
                        mt: 2,
                        mb: 2,
                    }}
                />

                <Button
                    sx={{
                        mb: 2,
                    }}
                    type="button"
                    variant="outlined"
                    color="error"
                    fullWidth
                    startIcon={<CreditCardOffIcon />}
                    onClick={onDeleteOpen}
                >
                    {t('cardDetails.deleteCard')}
                </Button>
            </form>
        </Box>
    );
};

BankCardUpdate.propTypes = {
    t: PropTypes.func.isRequired,
    card: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDeleteOpen: PropTypes.func.isRequired,
};

export default BankCardUpdate;
