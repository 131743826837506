import makeStyles from '@mui/styles/makeStyles';

export const useAppContentStyles = makeStyles(() => ({
    container: {
        transition: 'filter 100ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    maintenanceModeContainer: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: 2000,
        willChange: 'transform',
    },
}));
