import {Box, Stack} from '@mui/joy';
import {Rive} from '@rive-app/react-canvas';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import withJoyUI from '../../../components/joy-ui/joyui-theme-provider/JoyUiThemePRoviderWrapper';
import Fade from '../../../components/joy-ui/mui-legacy/Fade';
import useMediaQuery from '../../../components/joy-ui/mui-legacy/useMediaQuery';
import {selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {ROUTE_PATHS} from '../../../v1/navigation/routePaths';
import {selectUser} from '../../auth/store/auth.selectors';
import animation from '../riv/hiway-splash-screen.riv';
import {AnimationsSelectors} from '../store/animations.selector';

const stateMachineInputName = 'app_loading_completed';
const stateMachineName = 'State Machine 1';

const reloadEndAnimationTime = 2500;
const loginEndAnimationTime = 1700;
const slideTimeout = 300;

const InitialAnimation = ({children}) => {
    const location = useSelector(selectRouterLocation);
    const loggedInUser = useSelector(selectUser);

    const isLoginPage = location.pathname === ROUTE_PATHS.LOGIN;
    const endAnimationTime = isLoginPage ? loginEndAnimationTime : reloadEndAnimationTime;
    const isIgnoringOnInitialLoad = isLoginPage && !loggedInUser;

    const [isAnimationActive, setIsAnimationActive] = useState(!isIgnoringOnInitialLoad);
    const [isPostAnimationTime, setPostAnimationTime] = useState(isIgnoringOnInitialLoad);

    const isInitAnimationActive = useSelector(AnimationsSelectors.selectIsInitialAnimationActive);

    useEffect(() => {
        let timeout;
        if (!isIgnoringOnInitialLoad) {
            if (isInitAnimationActive) {
                setIsAnimationActive(true);
            } else {
                // Set timeout to run slider after rive animation is finished
                // Once animation is false, slider starts running
                timeout = setTimeout(() => {
                    setIsAnimationActive(false);
                }, endAnimationTime);
            }
        } else {
            setIsAnimationActive(false);
        }

        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitAnimationActive]);

    useEffect(() => {
        if (!isIgnoringOnInitialLoad) {
            if (!isAnimationActive) {
                // Once animation is no longer running we need to completely remove screen related to animation
                setTimeout(() => {
                    setPostAnimationTime(true);
                }, endAnimationTime);
            } else {
                setPostAnimationTime(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnimationActive]);


    // Needed to reset component for logout/login
    // const onSlideAnimationEnd = () => {
    //     if (!isAnimationActive) {
    //         if (endAnimationInput) {
    //             rive.stop(); // Stop any ongoing animations
    //             rive.cleanup(); // Clean up resources after stopping animations
    //         }
    //     }
    // };

    return (
        <Box
            component="div"
        >
            <Fade
                in={isAnimationActive}
                appear={isLoginPage}
                timeout={slideTimeout - 100}
            >
                <div>
                    <RiveBoxWithJOYUI
                        isPostAnimationTime={isPostAnimationTime}
                        isIgnoringOnInitialLoad={isIgnoringOnInitialLoad}
                        isInitAnimationActive={isInitAnimationActive}
                    />
                </div>
            </Fade>

            <Fade
                in={!isAnimationActive}
                timeout={slideTimeout + 100}
            >
                <div>
                    {children}
                </div>
            </Fade>
        </Box>
    );
};


const RiveBox = ({
    isPostAnimationTime,
    isIgnoringOnInitialLoad,
    isInitAnimationActive,
}) => {
    return (
        <Box
            component="div"
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                justifyContent: 'center',
                alignItems: 'center',
                width: isPostAnimationTime ? 0 : '100vw',
                height: isPostAnimationTime ? 0 : '100vh',
                display: isPostAnimationTime ? 'none' : 'flex',
                backgroundColor: 'neutral.900',
            }}
        >
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: '100vh',
                    width: '100vw',
                }}
            >
                <RiveAnimationStatic
                    isIgnoringOnInitialLoad={isIgnoringOnInitialLoad}
                    isInitAnimationActive={isInitAnimationActive}
                />
            </Stack>
        </Box>
    );
};

RiveBox.propTypes = {
    isPostAnimationTime: PropTypes.bool.isRequired,
    isIgnoringOnInitialLoad: PropTypes.bool.isRequired,
    isInitAnimationActive: PropTypes.bool.isRequired,
};

const RiveBoxWithJOYUI = withJoyUI(RiveBox);


// TODO Not working with Hooks
// const RiveAnimation = ({
//     isIgnoringOnInitialLoad,
//     isInitAnimationActive,
// }) => {
//     const loggedInUser = useSelector(selectUser);
//
//     const {rive, RiveComponent} = useRive({
//         src: animation,
//         stateMachines: stateMachineName,
//         autoplay: true, // Ensures the first animation plays automatically
//     });
//
//     const endAnimationInput = useStateMachineInput(rive, stateMachineName, stateMachineInputName);
//
//     useEffect(() => {
//         if (!isIgnoringOnInitialLoad) {
//             if (isInitAnimationActive) {
//                 rive && rive.play();
//             } else {
//                 // Run rive end animation
//                 if (endAnimationInput && rive) {
//                     endAnimationInput.value = true;
//                 }
//             }
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [isInitAnimationActive]);
//
//     useEffect(() => {
//         if (endAnimationInput && rive && !loggedInUser) {
//             console.log('rive false?');
//
//             setTimeout(() => {
//                 if (rive) {
//                     console.log('rive reset');
//                     if (endAnimationInput.value) {
//                         endAnimationInput.value = false;
//                     }
//
//                     rive.reset({
//                         src: animation,
//                         stateMachines: stateMachineName,
//                     });
//                 }
//             }, 100);
//         }
//     }, [loggedInUser]);
//
//
//     // Clean up Rive instance on component unmount, prevents memory out of bounds error
//     useEffect(() => {
//         return () => {
//             if (rive) {
//                 console.log('rive cleanup');
//
//                 rive.cleanup(); // Ensure Rive releases any resources
//             }
//         };
//     }, [rive]);
//
//     return (
//         <RiveComponent />
//     );
// };

const RiveAnimationStatic = ({
    isIgnoringOnInitialLoad,
    isInitAnimationActive,
}) => {
    const canvasRef = useRef();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const [riveInstance, setRiveInstance] = useState(null);

    useEffect(() => {
        if (canvasRef.current) {
            const riveInstance = new Rive({
                src: animation,
                autoplay: true,
                canvas: canvasRef.current,
                stateMachines: stateMachineName,
            });

            if (riveInstance) {
                setRiveInstance(riveInstance);

                const inputs = riveInstance.stateMachineInputs(stateMachineName);

                if (!inputs) {
                    return;
                }

                const endAnimationInput = inputs.find(input => input.name === stateMachineInputName);

                endAnimationInput.value = false;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasRef.current]);

    useEffect(() => {
        let timeout;
        if (!isIgnoringOnInitialLoad) {
            if (isInitAnimationActive) {
                if (riveInstance && !riveInstance.isPlaying) {
                    riveInstance.play(stateMachineName);
                }
            } else {
                const inputs = riveInstance.stateMachineInputs(stateMachineName);

                if (!inputs) {
                    return;
                }

                const endAnimationInput = inputs.find(input => input.name === stateMachineInputName);

                // Run rive end animation
                if (endAnimationInput) {
                    endAnimationInput.value = true;

                    // Setup new instance for next round
                    timeout = setTimeout(() => {
                        endAnimationInput.value = false;
                        riveInstance.cleanup();

                        const newRiveInstance = new Rive({
                            src: animation,
                            autoplay: false,
                            canvas: canvasRef.current,
                            stateMachines: stateMachineName,
                        });

                        if (newRiveInstance) {
                            setRiveInstance(newRiveInstance);
                        }
                    }, 2500);
                }
            }
        }

        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitAnimationActive]);

    // TODO Check why it is running smoother in timeout than here....
    // Setup new instance on logout so we can have running animation
    // useEffect(() => {
    //     console.log('rive cleanup loggedInUser', loggedInUser, riveInstance);
    //
    //     if (!loggedInUser && riveInstance) {
    //         riveInstance.cleanup();
    //
    //         const newRiveInstance = new Rive({
    //             src: animation,
    //             autoplay: true,
    //             canvas: canvasRef.current,
    //             stateMachines: stateMachineName,
    //         });
    //
    //         if (newRiveInstance) {
    //             setRiveInstance(newRiveInstance);
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [loggedInUser]);

    return (
        <canvas
            width="600px"
            style={{
                width: isMobileSize ? '66vw' : '550px',
            }}
            ref={canvasRef}
        />
    );
};

RiveAnimationStatic.propTypes = {
    isIgnoringOnInitialLoad: PropTypes.bool.isRequired,
    isInitAnimationActive: PropTypes.bool.isRequired,
};

export default InitialAnimation;
