import React from 'react';
import {RoutePaths} from '../../../../../lib/router/route-paths';
// eslint-disable-next-line import/no-restricted-paths
import BankTransferScreen from '../../../../../screens/bank/BankTransferScreen';
import {appLoaderSaga} from '../../../../../v1/app/appLoader.sagas';
import {bankFlagAccessFlow, bankIntegrationMainCheckFlow} from '../../../store/bank.loader.saga';
import CreateNewTransfer from '../components/transfers/CreateNewTransfer';
import TransferCreated from '../components/transfers/TransferCreated';
import {getBeneficiariesListWorker} from '../store/beneficiaries.saga';
import {getRecurringTransfersListWorker, startRecurringTransferLoader} from '../store/transfers.saga';

export const TransferRoutes = [
    {
        path: RoutePaths.BANK_TRANSFER_LIST,
        element: <BankTransferScreen />,
        exact: true,
        locationChangeSideEffects: [
            [startRecurringTransferLoader],
            [appLoaderSaga],
            [bankFlagAccessFlow],
            [bankIntegrationMainCheckFlow],
            [getBeneficiariesListWorker],
            [getRecurringTransfersListWorker],
        ],
    },
    {
        path: RoutePaths.BANK_CREATE_TRANSFER,
        element: <CreateNewTransfer />,
        exact: true,
        locationChangeSideEffects: [
            [appLoaderSaga],
            [bankFlagAccessFlow],
            [bankIntegrationMainCheckFlow],
            [getBeneficiariesListWorker],
        ],
    },
    {
        path: RoutePaths.TRANSFER_CREATED_REDIRECT,
        exact: true,
        element: <TransferCreated />,
        locationChangeSideEffects: [
            [appLoaderSaga],
            [bankFlagAccessFlow],
            [bankIntegrationMainCheckFlow],
        ],
    },
];
