import {
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Chip, Divider, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AccountsMenuItem} from './AccountsMenuItem';
import {ModalWrapper} from '../../../../../../components/modal-wrapper/ModalWrapper';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {BANK_TYPES, BankIntegrationErrorStatuses} from '../../../bridge-api/utils/constants';

const modalKey = ModalsKeys.TRANSACTION_ACCOUNTS_MODAL;

const TransactionAccountsModal = ({
    fullBankAccountList,
    onChangeTab,
    hasCombined,
    combinedUncategorized,
    combinedTotal,
    hasHiwayAdd,
    onClickHiwayAdd,
    hasAdd,
    hasArchived,
    onClickAdd,
    tabValue,
}) => {
    const {t} = useTranslation('bank');

    return (
        <ModalWrapper
            isV3
            containerSx={{width: '600px'}}
            buttonsSx={{
                flexDirection: 'column',
                gap: 3,
            }}
            modalKey={modalKey}
            title={(
                <Typography level="h1">
                    {t('creationProPage.mobileTabsTitle')}
                </Typography>
            )}
            buttons={({handleClose}) => ((!hasHiwayAdd && !hasAdd) ? null : (
                <>
                    <Divider sx={{mb: '8px !important'}} />

                    {!hasHiwayAdd && (
                        <Button
                            fullWidth
                            startDecorator={(
                                <FontAwesomeIcon icon={faPlus} />
                            )}
                            endDecorator={(
                                <Chip
                                    color="danger"
                                    size="sm"
                                    variant="solid"
                                >
                                    {t('connectProPage.recommended')}
                                </Chip>
                            )}
                            onClick={() => {
                                onClickHiwayAdd();
                                handleClose();
                            }}
                            variant="soft"
                            size="lg"
                            color="primary"
                        >
                            {t('creationProPage.addHiwayPro')}
                        </Button>
                    )}

                    {hasAdd && (
                        <Button
                            fullWidth
                            startDecorator={(
                                <FontAwesomeIcon icon={faPlus} />
                            )}
                            onClick={() => {
                                onClickAdd();
                                handleClose();
                            }}
                            color="primary"
                            size="lg"
                            variant="outlined"
                        >
                            {t('creationProPage.addButtonTitle')}
                        </Button>
                    )}
                </>
            ))}
        >
            <Typography level="body-lg" sx={{color: 'text.secondary'}}>
                {t('transactionAccountsModal.text')}
            </Typography>

            <Stack
                direction="column"
                alignItems="center"
                gap={3}
                sx={{
                    pt: 2,
                }}
            >
                {hasCombined && (
                    <AccountsMenuItem
                        label={t('creationProPage.combined')}
                        value={BANK_TYPES.combined}
                        tabValue={tabValue}
                        onChangeTab={onChangeTab}
                        uncategorized={combinedUncategorized}
                        amount={combinedTotal}
                    />
                )}

                {fullBankAccountList.map(account => (
                    <AccountsMenuItem
                        key={account.id}
                        label={account.name}
                        value={account.id}
                        onChangeTab={onChangeTab}
                        uncategorized={account.uncategorized}
                        amount={account.amount}
                        color={account?.color}
                        hasError={BankIntegrationErrorStatuses.includes(account?.status)}
                        tabValue={tabValue}
                        isHiway={account.type === BANK_TYPES.hiway}
                    />
                ))}

                {hasArchived && (
                    <AccountsMenuItem
                        label={t('creationProPage.archived')}
                        value={BANK_TYPES.archived}
                        tabValue={tabValue}
                        onChangeTab={onChangeTab}
                    />
                )}
            </Stack>
        </ModalWrapper>
    );
};

TransactionAccountsModal.propTypes = {
    fullBankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangeTab: PropTypes.func.isRequired,
    hasCombined: PropTypes.bool.isRequired,
    combinedUncategorized: PropTypes.number.isRequired,
    combinedTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    hasHiwayAdd: PropTypes.bool.isRequired,
    onClickHiwayAdd: PropTypes.func.isRequired,
    hasAdd: PropTypes.bool.isRequired,
    hasArchived: PropTypes.bool.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    tabValue: PropTypes.string,
};

TransactionAccountsModal.defaultProps = {
    tabValue: null,
};

export default TransactionAccountsModal;
