export const DELETE_BUSINESS_ALLOWANCE_RECAP = 'DELETE_BUSINESS_ALLOWANCE_RECAP';
export const DELETE_BUSINESS_ALLOWANCE_TRAVEL = 'DELETE_BUSINESS_ALLOWANCE_TRAVEL';
export const GENERATE_BUSINESS_ALLOWANCE_RECAP = 'GENERATE_BUSINESS_ALLOWANCE_RECAP';
export const GET_BUSINESS_ALLOWANCE_TRAVELS = 'GET_BUSINESS_ALLOWANCE_TRAVELS';
export const GET_BUSINESS_ALLOWANCE_RECAPS = 'GET_BUSINESS_ALLOWANCE_RECAPS';
export const SET_IS_LOADING_BUSINESS_ALLOWANCE_TRAVELS = 'SET_IS_LOADING_BUSINESS_ALLOWANCE_TRAVELS';
export const SET_IS_LOADING_BUSINESS_ALLOWANCE_RECAPS = 'SET_IS_LOADING_BUSINESS_ALLOWANCE_RECAPS';
export const SET_BUSINESS_ALLOWANCE_TRAVEL_TAB = 'SET_BUSINESS_ALLOWANCE_TRAVEL_TAB';
export const STORE_BUSINESS_ALLOWANCE_RECAPS = 'STORE_BUSINESS_ALLOWANCE_RECAPS';
export const STORE_BUSINESS_ALLOWANCE_TRAVELS = 'STORE_BUSINESS_ALLOWANCE_TRAVELS';
export const SET_IS_GENERATING_RECAP = 'SET_IS_GENERATING_RECAP';
export const UPDATE_BUSINESS_ALLOWANCE_RECAP = 'UPDATE_BUSINESS_ALLOWANCE_RECAP';
export const DOWNLOAD_BUSINESS_ALLOWANCE_RECAP = 'DOWNLOAD_BUSINESS_ALLOWANCE_RECAP';
export const SET_CURRENTLY_EDITING_TRAVEL = 'SET_CURRENTLY_EDITING_TRAVEL';

export const setIsLoadingBusinessAllowanceTravels = isLoadingTravels => ({
    type: SET_IS_LOADING_BUSINESS_ALLOWANCE_TRAVELS,
    payload: isLoadingTravels,
});

export const setIsLoadingBusinessAllowanceRecaps = isLoadingRecaps => ({
    type: SET_IS_LOADING_BUSINESS_ALLOWANCE_RECAPS,
    payload: isLoadingRecaps,
});

export const setIsGeneratingRecap = isGeneratingRecap => ({
    type: SET_IS_GENERATING_RECAP,
    payload: isGeneratingRecap,
});

export const getBusinessAllowanceTravels = (freelancerId, companyId) => ({
    type: GET_BUSINESS_ALLOWANCE_TRAVELS,
    payload: {
        freelancerId,
        companyId,
    },
});

export const deleteBusinessAllowanceTravel = (freelancerId, companyId, travelId) => ({
    type: DELETE_BUSINESS_ALLOWANCE_TRAVEL,
    payload: {
        freelancerId,
        companyId,
        travelId,
    },
});

export const storeBusinessAllowanceTravels = businessAllowanceTravels => ({
    type: STORE_BUSINESS_ALLOWANCE_TRAVELS,
    payload: businessAllowanceTravels,
});

export const generateBusinessAllowanceRecap = () => ({
    type: GENERATE_BUSINESS_ALLOWANCE_RECAP,
});

export const getBusinessAllowanceRecaps = (freelancerId, companyId) => ({
    type: GET_BUSINESS_ALLOWANCE_RECAPS,
    payload: {
        freelancerId,
        companyId,
    },
});

export const storeBusinessAllowanceRecaps = businessAllowanceTravels => ({
    type: STORE_BUSINESS_ALLOWANCE_RECAPS,
    payload: businessAllowanceTravels,
});

export const deleteBusinessAllowanceRecap = (freelancerId, companyId, recapId) => ({
    type: DELETE_BUSINESS_ALLOWANCE_RECAP,
    payload: {
        freelancerId,
        companyId,
        recapId,
    },
});

export const setBusinessAllowanceTravelTab = tab => ({
    type: SET_BUSINESS_ALLOWANCE_TRAVEL_TAB,
    payload: tab,
});

export const updateBusinessAllowanceRecap = (freelancerId, companyId, recapId, isReimbursed) => ({
    type: UPDATE_BUSINESS_ALLOWANCE_RECAP,
    payload: {
        freelancerId,
        companyId,
        recapId,
        isReimbursed,
    },
});

export const downloadBusinessAllowanceRecap = (freelancerId, companyId, documentId, isDownload = true) => ({
    type: DOWNLOAD_BUSINESS_ALLOWANCE_RECAP,
    payload: {
        freelancerId,
        companyId,
        documentId,
        isDownload,
    },
});

export const setCurrentlyEditingTravel = travel => ({
    type: SET_CURRENTLY_EDITING_TRAVEL,
    payload: travel,
});
