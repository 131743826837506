import CloseIcon from '@mui/icons-material/Close';
import {Box, CircularProgress, Divider, IconButton, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AccountingSelector} from '../../../accounting/store/accounting.selector';

const BalanceSheetSidebar = ({isOpen, onClose}) => {
    const {t} = useTranslation('companies');

    const balanceSheetDetails = useSelector(AccountingSelector.selectBalanceSheetDetails);

    return (
        <Box
            sx={{
                display: 'block',
                flexDirection: 'column',
                background: 'white',
                width: isOpen ? '360px' : 0,
                transition: 'all 0.3s ease-in-out',
                whiteSpace: 'nowrap',
            }}
        >
            {isOpen && (
                <>
                    <Box sx={{px: 2, py: 3, display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <Typography sx={{fontSize: '22px', textOverflow: 'clip', overflow: 'hidden', width: '100%'}}>
                            {t(`companies:formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.calculatedData.sidebarTitle`)}
                        </Typography>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={onClose}
                        >
                            <CloseIcon sx={{color: 'v2.blueGray.600'}} />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            px: 2,
                            py: 3,
                            gap: 1,
                            flex: 1,
                        }}
                    >
                        <Typography sx={{fontSize: '21px', fontWeight: 700, display: 'flex', gap: 1, alignItems: 'center', overflow: 'hidden'}}>
                            {t(`companies:formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.calculatedData.title`)}
                            <Box
                                component="span"
                                sx={{
                                    display: 'flex',
                                    height: '20px',
                                    padding: '0px 6.5px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '64px',
                                    backgroundColor: 'v2.blueGray.300',
                                    color: 'white',
                                    fontSize: '14px',
                                }}
                            >
                                {Object.keys(balanceSheetDetails).length}
                            </Box>
                        </Typography>
                        {!Object.keys(balanceSheetDetails).length && (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )}
                        {Object.keys(balanceSheetDetails).map(dataKey => {
                            return (
                                <Box
                                    key={dataKey}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            py: 2,
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <Typography>
                                            {t(`companies:formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.calculatedData.${dataKey}`)}
                                        </Typography>
                                        <Typography sx={{fontWeight: 600}}>
                                            {/* TODO: Make a reusable component */}
                                            {/* and figure out how to get the current language  */}
                                            {Number(balanceSheetDetails[dataKey])
                                                .toLocaleString('fr-FR',
                                                    {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                </Box>
                            );
                        })}
                    </Box>
                </>
            )}
        </Box>
    );
};

BalanceSheetSidebar.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default BalanceSheetSidebar;
