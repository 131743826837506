import {combineReducers} from 'redux';
import {SigningActionTypes} from './signing.action-type';
import {createReducer} from '../../../../../utils/create-reducer';

export const signingReducer = combineReducers({
    currentDocumentUrl: createReducer(null, SigningActionTypes.SET_CURRENT_DOCUMENT_URL),
    currentDocumentId: createReducer(null, SigningActionTypes.SET_CURRENT_DOCUMENT_ID),
    currentDocumentIsSigned: createReducer(null, SigningActionTypes.SET_CURRENT_DOCUMENT_IS_SIGNED),
    isLoading: createReducer(true, SigningActionTypes.SET_IS_LOADING_CURRENT_DOCUMENT, true),
    isSigning: createReducer(false, SigningActionTypes.SET_IS_SIGNING_CURRENT_DOCUMENT, true),
    isSigningAdditionalDocuments: createReducer(false, SigningActionTypes.SET_IS_SIGNING_ADDITIONAL_DOCUMENTS, false),
});
