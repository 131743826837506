export const LoadingTypes = {
    BO_CRUD_LOADER: 'BO_CRUD_LOADER',
    BO_NOTE_UPDATE: 'BO_NOTE_UPDATE',
    GENERIC_CRUD_LOADER: 'GENERIC_CREATE_UPDATE',
    CREATE_USER_FORM: 'CREATE_USER_FORM',
    MY_ACCOUNT: 'MY_ACCOUNT',
    APPLICATION_DATA: 'APPLICATION_DATA',
    FREELANCER_ACCOUNT: 'FREELANCER_ACCOUNT',
    FREELANCER_COMPANIES: 'FREELANCER_COMPANIES',
    CONTRACT_DATA: 'CONTRACT_DATA',
    UPLOAD_SIGNATURE: 'UPLOAD_SIGNATURE',
    SIGNATURE: 'SIGNATURE',
    SIGN_CONTRACT: 'SIGN_CONTRACT',
    DOWNLOAD_DOCUMENT: 'DOWNLOAD_DOCUMENT',
    VIEW_DOCUMENT: 'VIEW_DOCUMENT',
    UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
    DOWNLOAD_QONTO_DOCUMENT: 'DOWNLOAD_QONTO_DOCUMENT',
    DOWNLOAD_STATUSES_DOCUMENT: 'DOWNLOAD_STATUSES_DOCUMENT',
    GO_TO_WORKSHOP_STEP: 'GO_TO_WORKSHOP_STEP',
    GO_TO_COMPANY_SETUP: 'GO_TO_COMPANY_SETUP',
    DOCUMENT_DATABASE: 'DOCUMENT_DATABASE',
    GENERATING_DOCUMENTS: 'GENERATING_DOCUMENTS',
    ONBOARDING: 'ONBOARDING',
    SIGN_DOCUMENT: 'SIGN_DOCUMENT',
    SAVE_COMPANY_INFORMATION: 'SAVE_COMPANY_INFORMATION',
    REGISTRATION_NOT_PREPARED: 'REGISTRATION_NOT_PREPARED',
    COMPANIES_IN_CREATION_FILTER: 'COMPANIES_IN_CREATION_FILTER',
    SENDING_REMINDER: 'SENDING_REMINDER',
    SENDING_REMINDER_SENT: 'SENDING_REMINDER_SENT',
    SAVING_REGISTRATION_STATUS: 'SAVING_REGISTRATION_STATUS',
    GET_TAX_INCOME: 'dashboard/GET_TAX_INCOME',
    SAVE_TAX_INCOME: 'dashboard/SAVE_TAX_INCOME',
    GET_PROFESSION_AND_SPECIALIZATION: 'professionAndSpecialization/GET_PROFESSION_AND_SPECIALIZATION',
    SAVE_PROFESSION_AND_SPECIALIZATION: 'professionAndSpecialization/SAVE_PROFESSION_AND_SPECIALIZATION',
    GET_PROFESSION_VALUE: 'professionAndSpecialization/GET_PROFESSION_VALUE',
    GET_OPPORTUNITY: 'opportunity/GET_OPPORTUNITY',
    GET_OTHER_OPPORTUNITY: 'opportunity/GET_OTHER_OPPORTUNITY',
    GET_MY_BIDS: 'opportunity/GET_MY_BIDS',
    SUBMIT_OPPORTUNITY: 'opportunity/SUBMIT_OPPORTUNITY',
    GET_SPECIALISATIONS: 'opportunity/GET_SPECIALISATIONS',
    APPLY_OPPORTUNITY: 'opportunity/APPLY_OPPORTUNITY',
    SEARCH_FREELANCER: 'opportunity/SEARCH_FREELANCER',
    TOGGLE_ACCEPT: 'opportunity/TOGGLE_ACCEPT',
    DETERMINE_TRAINING_STEP: 'training/DETERMINE_TRAINING_STEP',
    TRAINING_STEP_SUBMIT: 'training/TRAINING_STEP_SUBMIT',
    TRAINING_REJECT_FORM_SUBMIT: 'training/TRAINING_REJECT_FORM_SUBMIT',
    GET_BO_TRAINING_INFO: 'boTraining/GET_TRAINING_INFO',
    SAVE_BO_TRAINING_INFO: 'boTraining/SAVE_BO_TRAINING_INFO',
    TRAINING_ZIP_DOWNLOAD: 'training/TRAINING_ZIP_DOWNLOAD',
    WAITING_UNSIGNED_DOCS: 'training/WAITING_UNSIGNED_DOCS',
    EDIT_REGISTRATION_DATE: 'backoffice/EDIT_REGISTRATION_DATE',
    BANK_GET_INTEGRATIONS: 'bank/BANK_GET_INTEGRATIONS',
    BANK_CREATION: 'bank/BANK_CREATION',
    BANK_CATEGORIES: 'bank/BANK_CATEGORIES',
    BANK_SOURCES: 'bank/BANK_SOURCES',
    BANK_TRANSACTION_FIELD: 'bank/BANK_TRANSACTION_FIELD',
    BANK_TRANSACTION_ADDITIONAL_FIELD: 'bank/BANK_TRANSACTION_ADDITIONAL_FIELD',
    BANK_TRANSACTIONS: 'bank/BANK_TRANSACTIONS',
    BANK_TRANSACTIONS_FILTERS: 'bank/BANK_TRANSACTIONS_FILTERS',
    BANK_TRANSACTIONS_PAGE_LOADER: 'bank/BANK_TRANSACTIONS_PAGE_LOADER',
    BANK_TRANSACTION: 'bank/BANK_TRANSACTION',
    BANK_ARTICLES: 'bank/BANK_ARTICLES',
    DELETE_BANK_FILE: 'bank/DELETE_BANK_FILE',
    UPLOAD_BANK_FILE: 'bank/UPLOAD_BANK_FILE',
    GET_BENEFICIARIES_LIST: 'bank/GET_BENEFICIARIES_LIST',
    BENEFICIARIES_UPDATE: 'bank/BENEFICIARIES_UPDATE',
    BENEFICIARIES_DELETE: 'bank/BENEFICIARIES_DELETE',
    LOAD_TRANSFERS: 'transfers/LOAD_TRANSFERS',
    PAYSLIP_CRUD: 'payslip/PAYSLIP_CRUD',
    LOCK_CARD: 'bank/LOCK_CARD',
    GET_CARD: 'bank/GET_CARD',
    SSE_TOKEN_GET: 'bank/SSE_TOKEN_GET',
    SSE_INVOICE_TOKEN_GET: 'bank/SSE_INVOICE_TOKEN_GET',
    SSE_CORE_TOKEN_GET: 'bank/SSE_CORE_TOKEN_GET',
    GET_BANK_ACCOUNT_STATEMENTS: 'bank/GET_BANK_ACCOUNT_STATEMENTS',
    GET_RIB_DOCUMENT: 'bank/GET_RIB_DOCUMENT',
    DOWNLOAD_ALL_STATEMENTS_AS_ZIP: 'bank/DOWNLOAD_ALL_STATEMENTS_AS_ZIP',
    GET_CAPITAL_DEPOSIT: 'bank/GET_CAPITAL_DEPOSIT',
    GET_INVOICE_DATA: 'invoice/GET_INVOICE_DATA',
    GET_INVOICE_STATS: 'invoice/GET_INVOICE_STATS',
    GET_INVOICE_DRAFT: 'invoice/GET_INVOICE_DRAFT',
    GET_INVOICE_CLIENTS: 'invoice/GET_INVOICE_CLIENTS',
    GET_INVOICE_SERVICES: 'invoice/GET_INVOICE_SERVICES',
    SAVE_INVOICE_CLIENTS: 'invoice/SAVE_INVOICE_CLIENTS',
    SAVE_INVOICE_SERVICE: 'invoice/SAVE_INVOICE_SERVICE',
    UPLOAD_CLIENT_FILE: 'invoice/UPLOAD_CLIENT_FILE',
    SAVE_INVOICE: 'invoice/SAVE_INVOICE',
    DOWNLOAD_FEC_FILE: 'accounting/DOWNLOAD_FEC_FILE',
    DOWNLOAD_BALANCE_SHEET_FILE: 'accounting/DOWNLOAD_FEC_FILE',
    UPLOAD_BALANCE_SHEET_FILE: 'accounting/UPLOAD_BALANE_SHEET_FILE',
    GET_ANNUAL_ACCOUNTS: 'accounting/GET_FINANCIAL_STATEMENTS',
    UPLOAD_FINAL_FEC_FILE: 'accounting/UPLOAD_FINAL_FEC_FILE',
    INVOICE_PREVIEW_GENERATION: 'invoice/INVOICE_PREVIEW_GENERATION',
    INVOICE_FINALIZATION: 'invoice/INVOICE_FINALIZATION',
    UPLOAD_ANNEX_FILE: 'invoice/UPLOAD_ANNEX_FILE',
    DELETE_ALL_ANNEX_FILES: 'invoice/DELETE_ALL_ANNEX_FILES',
    CREATE_EXTERNAL_INVOICE: 'invoice/CREATE_EXTERNAL_INVOICE',
    GET_CLIENT_SUGGESTIONS: 'client/GET_CLIENT_SUGGESTIONS',
    REQUEST_PAY_CALCULATION_INFO: 'client/REQUEST_PAY_CALCULATION_INFO',
    GLOBAL_DATA_EXPORT: 'users/GLOBAL_DATA_EXPORT',
    SET_DEFAULT_BANK: 'bridge/SET_DEFAULT_BANK',
    DELETE_BANK: 'bank/DELETE_BANK',
    JOURNAL_ACCOUNTS: 'accounting/JOURNAL_ACCOUNTS',
    AUTOMATIC_REGISTRATION_REFRESH: 'automaticRegistration/AUTOMATIC_REGISTRATION_REFRESH',
    UPDATE_REFUSED_DOCUMENTS: 'bank/UPDATE_REFUSED_DOCUMENTS',
    DOCUMENTATION_LOADING: 'documentation/DOCUMENTATION_LOADING',
    GET_INSURANCE: 'insurance/GET_INSURANCE',
    SET_INSURANCE: 'insurance/SET_INSURANCE',
    DOCUMENTATION_SEARCH_LOADING: 'documentation/DOCUMENTATION_SEARCH_LOADING',
    DOCUMENTATION_VOTING: 'documentation/DOCUMENTATION_VOTING',
    QUICK_ACCESS_DOCS: 'documents/QUICK_ACCESS_DOCS',
    REISSUE_VIGILANCE_CERT: 'documents/REISSUE_VIGILANCE_CERT',
    ARIA_LOADER: 'aria/ARIA_LOADER',
    ARIA_DOCUMENT_LOADER: 'aria/ARIA_DOCUMENT_LOADER',
    CONNECT_BANK_INTEGRATIONS_WITH_COMPANIES: 'companyList/CONNECT_BANK_INTEGRATIONS_WITH_COMPANIES',
    CONNECT_BANK_INTEGRATIONS_WITH_DECLARATIONS: 'vatDeclaration/CONNECT_BANK_INTEGRATIONS_WITH_DECLARATIONS',
    VAT_DECLARATIONS: 'vatDeclaration/VAT_DECLARATIONS',
    FETCH_DASHBOARD_V3_DATA: 'dashboard-v3/FETCH_DASHBOARD_V3_DATA',
    SENDING_PAYMENT_EMAIL: 'accounting/SENDING_PAYMENT_EMAIL',
    VAT_GET_BATCH_PROGRESS: 'vat-declaration/VAT_GET_BATCH_PROGRESS',
    VAT_DECLARATIONS_SEND_IN_PROGRESS: 'vat-declaration/VAT_DECLARATIONS_SEND_IN_PROGRESS',
    VAT_DECLARATION_DOCUMENTS: 'vatDeclaration/DOCUMENTS',
    VAT_SUPPORTED_MONTHS: 'accounting/VAT_SUPPORTED_MONTHS',
    VAT_DECLARATION_DETAILS: 'accounting/VAT_DECLARATION_DETAILS',
    VAT_DECLARATION_RESEND: 'vatDeclaration/VAT_DECLARATION_RESEND',
    VAT_DECLARATION_ACTIVATE_MANUAL_MODE: 'VAT_DECLARATION_ACTIVATE_MANUAL_MODE',
    VAT_DECLARATION_VALIDATE_MANUAL: 'vatDeclaration/VAT_DECLARATION_VALIDATE_MANUAL',
    VAT_DECLARATION_DOWNLOAD_DOCUMENT: 'vatDeclaration/VAT_DECLARATION_DOWNLOAD_DOCUMENT',
    VAT_DECLARATION_HISTORY_OVERVIEW: 'vatDeclaration/VAT_DECLARATION_HISTORY_OVERVIEW',
    VAT_DECLARATION_HISTORY: 'vatDeclaration/VAT_DECLARATION_HISTORY',
};
