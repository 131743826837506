import {BE_TRAINING_STATUS, BO_TRAINING_STATUS} from './constants';

export const convertBoTrainingStatus = inputStatus => {
    switch (inputStatus) {
        case BE_TRAINING_STATUS.PENDING:
            return BO_TRAINING_STATUS.PENDING;
        case BE_TRAINING_STATUS.ATLAS_APPLICATION:
            return BO_TRAINING_STATUS.ONGOING_STAGE_1;
        case BE_TRAINING_STATUS.ATLAS_ACCOUNT_CREATION:
            return BO_TRAINING_STATUS.ONGOING_STAGE_2;
        case BE_TRAINING_STATUS.ATLAS_SUPPORT_PENDING_SIGNATURE:
            return BO_TRAINING_STATUS.ONGOING_STAGE_3_NOT_SIGNED;
        case BE_TRAINING_STATUS.ATLAS_SUPPORT_DOCUMENTS_PAGE:
            return BO_TRAINING_STATUS.ONGOING_STAGE_3_SIGNED;
        case BE_TRAINING_STATUS.ATLAS_SUPPORT_PENDING_FILE_NUMBER:
            return BO_TRAINING_STATUS.ONGOING_STAGE_3_SIGNED;
        case BE_TRAINING_STATUS.ATLAS_VALIDATION_PENDING:
            return BO_TRAINING_STATUS.ONGOING_STAGE_4_PENDING;
        case BE_TRAINING_STATUS.ATLAS_VALIDATION_REJECTED:
            return BO_TRAINING_STATUS.ONGOING_STAGE_4_REFUSED;
        case BE_TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE:
            return BO_TRAINING_STATUS.ONGOING_STAGE_4_VERIFIED;
        case BE_TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_CHECKLIST:
            return BO_TRAINING_STATUS.ONGOING_STAGE_4_VERIFIED;
        case BE_TRAINING_STATUS.ATLAS_FINALIZATION_PENDING_PAYMENT:
            return BO_TRAINING_STATUS.ONGOING_STAGE_5_PENDING;
        case BE_TRAINING_STATUS.COMPLETED:
            return BO_TRAINING_STATUS.VALIDATED;
    }
};

export const dateIsOnWeekend = date => 6 === date.isoWeekday() || 7 === date.isoWeekday();
