import {Box, Grow, Paper, Slide} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DossierDocumentViewButtons} from './DossierDocumentViewButtons';
import DossierViewPdf from './DossierViewPdf';
import SwipeableEdgeDrawer from '../../../../components/swipable-edge/SwipableEdge';
import {AnimationActions} from '../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../animations/utils/constants';
import {TrainingActions} from '../../store/training.action';
import {TRAINING_CONSTANTS} from '../../utils/constants';

const DossierCompleted = () => {
    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(true);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onButtonClick = () => {
        if (!isChecked) {
            return;
        }

        dispatch(TrainingActions.submitStep(TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER));
    };

    const onCheckboxClick = e => {
        const checked = e.target.checked;
        setIsChecked(checked);
    };

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    return (
        <Box sx={{
            px: {
                xs: 2,
                md: 0,
            },
        }}
        >
            {isMobileSize && (
                <>
                    <Paper elevation={1} sx={{borderRadius: 2}}>
                        <Box sx={{height: 'calc(100vh - 290px)', overflowY: 'auto'}}>
                            <DossierViewPdf />
                        </Box>
                    </Paper>

                    <SwipeableEdgeDrawer
                        height="78px"
                        bleedingHeight={80}
                        drawerContent={(
                            <Grow in={isAnimationActive} onExited={onEndedAnimation}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'column',
                                    px: 3,
                                    pt: 1.5,
                                    pb: 3,
                                }}
                                >
                                    <DossierDocumentViewButtons
                                        onCheckboxClick={onCheckboxClick}
                                        onButtonClick={onButtonClick}
                                        isChecked={isChecked}
                                        isDisabled={true}
                                    />
                                </Box>
                            </Grow>
                        )}
                    />
                </>
            )}

            {!isMobileSize && (
                <>
                    <Grow in={isAnimationActive} onExited={onEndedAnimation}>
                        <Paper elevation={1} sx={{borderRadius: 2}}>
                            <Box sx={{height: 'calc(100vh - 290px)', overflowY: 'auto'}}>
                                <DossierViewPdf />
                            </Box>
                        </Paper>
                    </Grow>

                    <Slide in={isAnimationActive} direction="left" timeout={200}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                            <DossierDocumentViewButtons
                                onCheckboxClick={onCheckboxClick}
                                onButtonClick={onButtonClick}
                                isChecked={isChecked}
                                isDisabled={true}
                            />
                        </Box>
                    </Slide>
                </>
            )}
        </Box>
    );
};

export default DossierCompleted;
