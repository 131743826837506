import moment from 'moment';
import {all, call, delay, put, select, takeEvery} from 'redux-saga/effects';
import {InvoiceActionType} from './invoice.action-type';
import {InvoiceActions} from './invoice.actions';
import {InvoiceSelector} from './invoice.selector';
import {push} from '../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {API_TYPE_ERRORS} from '../../../utils/api-type-errors';
import {Debug} from '../../../utils/debug';
import {USER_ROLES} from '../../../utils/user-roles';
import {getCompanyDocumentsByTypeRequest} from '../../../v1/app/api/providers/company/company.provider';
import {getCompanyDocument} from '../../../v1/app/company/company.actions';
import {loadCompany} from '../../../v1/app/company/company.sagas';
import {selectCompany} from '../../../v1/app/company/company.selectors';
import {COMPANY_STATUSES} from '../../../v1/app/company/setupCompany/setupCompany.constants';
import {selectFreelancerAccount} from '../../../v1/app/freelancer/freelancer.selectors';
import {TransactionsActions} from '../../bank/modules/account-balance/store/transactions.action';
import {TransactionMatchType} from '../../bank/modules/account-balance/util/constants';
import {setDefaultBankFlow} from '../../bank/modules/bridge-api/store/brdige-api.saga';
import {LoadingActions, LoadingTypes} from '../../loading';
import {UiActions} from '../../ui/store/ui.action';
import {UiSelectors} from '../../ui/store/ui.selector';
import {ModalsKeys} from '../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {InvoiceApi} from '../api/invoice.api';
import {ARIA_DEBTOR_IDENTIFIER_TYPE, ARIA_VAT_COUNTRIES} from '../components/aria/constants';
import {
    ClientPaymentTerm,
    INVOICE_IMPORT_RESULT,
    INVOICE_TABLE_DEFAULT,
    INVOICE_TYPES,
    PAYMENT_TERMS,
    ServiceRateUnit,
    invoiceResultTypes,
    invoiceSidebarType,
} from '../util/constants';
import {getSearchParameters} from '../util/functions';

const getInvoiceFlow = function* ({params, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_DATA, true));

        // Call api
        const result = yield call(InvoiceApi.getInvoiceList, {params, companyId});

        // Set data
        yield put(InvoiceActions.storeInvoiceList(result));

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_DATA, false));
    } catch (e) {
        Debug.error('invoice getInvoiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_DATA, false));
    }
};

const getInvoiceStatsFlow = function* ({companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_STATS, true));

        // Call api
        const result = yield call(InvoiceApi.getInvoiceStats, {companyId});

        // Set data
        yield put(InvoiceActions.storeInvoiceStats(result));

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_STATS, false));
    } catch (e) {
        Debug.error('invoice getInvoiceStatsFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_STATS, false));
    }
};

const getInvoiceDraftFlow = function* ({companyId, createIfDoesNotExist, useNoLoader}) {
    try {
        // Set loader
        if (!useNoLoader) {
            yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_DRAFT, true));
        }

        // Call api
        const draftResult = yield call(InvoiceApi.getInvoiceDraft, {companyId});

        // Handle if some data is missing
        if (
            draftResult?.missingData === true
        ) {
            yield put(InvoiceActions.setImportData(
                {type: INVOICE_IMPORT_RESULT.MISSING_DRAFT_ITEM},
            ));
        }

        // Set data
        yield put(InvoiceActions.storeInvoiceDraft(draftResult));

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_DRAFT, false));
    } catch (e) {
        Debug.error('invoice getInvoiceDraftFlow', 'Error: ', {e});

        if (e?.response?.status === 404) {
            if (createIfDoesNotExist) {
                yield put(InvoiceActions.saveInvoiceDraft({clients: [], services: []}));
                return;
            }
        } else {
            Toast.error('genericError');
        }

        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_DRAFT, false));
    }
};

const finalizeInvoiceFlow = function* ({data, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.INVOICE_FINALIZATION, true));

        // Call api
        const result = yield call(InvoiceApi.finalizeInvoice, {
            data: {
                ...data,
                accountInfo: {
                    iban: data?.selectedIntegration?.iban,
                    bic: data?.selectedIntegration?.bic ?? null,
                },
                id: undefined,
                hasNewDefault: undefined,
                selectedIntegration: undefined,
            },
            companyId,
            id: data.id,
        });

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.INVOICE_FINALIZATION, false));

        // Put result info
        yield put(UiActions.setActiveModal(ModalsKeys.INVOICE_FINALIZATION_MODAL, false));
        yield put(InvoiceActions.storeFinalizationResult({...result, sendInvoice: data.sendInvoice}));
        yield put(InvoiceActions.storeInvoiceDraft(null));
    } catch (e) {
        Debug.error('invoice finalizeInvoiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.INVOICE_FINALIZATION, false));
    }
};

const getInvoicePreviewFlow = function* ({data, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.INVOICE_PREVIEW_GENERATION, true));

        // Call api
        const result = yield call(InvoiceApi.getInvoicePreview, {
            data: {
                isAriaPartner: data?.isAriaPartner ?? false,
            },
            companyId,
            id: data.id,
        });

        yield put(InvoiceActions.updateFileUploadLoader({
            invoiceId: data.id,
            invoice: data,
            eventId: result.pendingEventId,
            inProgress: true,
        }));
    } catch (e) {
        Debug.error('invoice getInvoicePreviewFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.INVOICE_PREVIEW_GENERATION, false));
    }
};

const uploadAnnexFileFlow = function* ({data, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_ANNEX_FILE, true));

        // Call api
        const result = yield call(InvoiceApi.saveAnnexFile,
            {fileList: data.file, companyId, invoiceId: data.id});


        yield put(InvoiceActions.updateFileUploadLoader({
            eventId: result.pendingEventId,
            inProgress: true,
        }));
    } catch (e) {
        Debug.error('invoice uploadAnnexFileFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_ANNEX_FILE, false));
    }
};

const deleteAnnexFileFlow = function* ({data, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_ANNEX_FILE, true));

        // Call api
        const result = yield call(InvoiceApi.deleteAnnexFile,
            {fileId: data.fileId, companyId, invoiceId: data.invoiceId});


        // Update invoice draft
        yield put(InvoiceActions.storeInvoiceDraft(result));

        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_ANNEX_FILE, false));
    } catch (e) {
        Debug.error('invoice deleteAnnexFileFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_ANNEX_FILE, false));
    }
};

const deleteAllAnnexFilesFlow = function* ({data, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_ALL_ANNEX_FILES, true));
        const {fileIds, invoiceId} = data;

        let result;
        for (const fileId of fileIds) {
            // Call api
            result = yield call(InvoiceApi.deleteAnnexFile, {fileId, companyId, invoiceId});
        }

        // Update invoice draft
        yield put(InvoiceActions.storeInvoiceDraft(result));

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_ALL_ANNEX_FILES, false));
    } catch (e) {
        Debug.error('invoice deleteAllAnnexFilesFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_ALL_ANNEX_FILES, false));
    }
};

const getLastInternalInvoiceFlow = function* ({companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        // Call api
        const result = yield call(InvoiceApi.getInvoiceList, {
            params: {
                limit: 5,
                offset: 0,
                filterQuery: JSON.stringify({
                    isExternal: false,
                    type: INVOICE_TYPES.INVOICE,
                }),
                sortBy: 'docNumber',
                sortOrder: 'DESC',
            },
            companyId,
        });

        // Store needed data
        if (result?.items?.[0]) {
            yield put(InvoiceActions.storeLastInternalInvoice(result.items[0]));
        }

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('invoice getLastInternalInvoiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const importInvoiceFlow = function* ({data, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        // Call api
        const result = yield call(InvoiceApi.importInvoice, {
            companyId,
            id: data.invoiceId,
            sourceInvoiceId: data.sourceInvoiceId,
        });

        // Handle if some data is missing
        if (
            (
                !result?.client
                || !result?.services
                || result?.services?.length === 0
            )
            || (
                result?.missingData === true
            )
        ) {
            yield put(InvoiceActions.setImportData(
                {
                    type: INVOICE_IMPORT_RESULT.MISSING_IMPORT_ITEM,
                    docNumber: result?.docNumber,
                },
            ));
        } else {
            yield put(InvoiceActions.setImportData(
                {
                    type: INVOICE_IMPORT_RESULT.COPY_SUCCESS,
                    docNumber: result?.docNumber,
                },
            ));
        }

        // Store needed data
        yield put(InvoiceActions.storeInvoiceDraft(result));

        if (result?.document?.length > 0) {
            yield put(InvoiceActions.getInvoiceDraft({useNoLoader: true}));
        }

        if (result?.client?.paymentTerm === ClientPaymentTerm.SpecificDatePayment) {
            yield put(UiActions.setActiveModal(ModalsKeys.INVOICE_DUE_DATE_MODAL, true));
        } else if (result?.services?.[0]?.rateUnit === ServiceRateUnit.DAY) {
            yield put(InvoiceActions.getServiceDetails(result.services[0].id));
            yield put(InvoiceActions.setInvoiceSidebarData(
                {
                    id: result.services[0].id,
                    name: result.services[0]?.name,
                    selectedDates: result.services[0]?.selectedDates,
                },
            ));
            yield put(InvoiceActions.setInvoiceSidebar(invoiceSidebarType.serviceDates));
        }

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('invoice importInvoiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const checkIfDraftIsValid = function* ({id, companyId}) {
    try {
        const draft = yield call(InvoiceApi.getInvoiceDraft, {companyId});

        return draft?.id === id;
    } catch (e) {
        if (e?.response?.status === 404) {
            return false;
        }

        return false;
    }
};

const deleteInvoiceFlow = function* ({id, isDraft, companyId, sendInvoice}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        // Do not delete draft if it is already created/deleted in another tab
        if (isDraft) {
            const isValid = yield call(checkIfDraftIsValid, {id, companyId});

            if (!isValid) {
                yield all([
                    put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false)),
                    put(InvoiceActions.storeInvoiceDraft(null)),
                    put(InvoiceActions.setInvoiceSidebar(null)),
                    put(InvoiceActions.setInvoiceSidebarData(null)),
                    put(UiActions.setActiveModal(ModalsKeys.INVOICE_CREATE_CANCEL_MODAL, false)),
                ]);

                return;
            }
        }

        // Call api
        // TODO Check whether we need docNumber when deleting draft
        const {docNumber} = yield call(InvoiceApi.getNextCreditNoteDocNumber, {companyId});
        const result = yield call(InvoiceApi.deleteInvoice, {id, companyId, isDraft});

        if (isDraft) {
            Toast.success('invoiceSuccessfullyCancelled');

            // Store needed data
            yield all([
                put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false)),
                put(InvoiceActions.storeInvoiceDraft(null)),
                put(InvoiceActions.setInvoiceSidebar(null)),
                put(InvoiceActions.setInvoiceSidebarData(null)),
                put(UiActions.setActiveModal(ModalsKeys.INVOICE_CREATE_CANCEL_MODAL, false)),
            ]);
            return;
        }
        // Store needed data
        yield all([
            put(InvoiceActions.storeInvoiceDraft(null)),
            put(InvoiceActions.setInvoiceSidebar(null)),
            put(InvoiceActions.setInvoiceSidebarData(null)),
            put(UiActions.setActiveModal(ModalsKeys.INVOICE_CANCEL_MODAL, false)),
        ]);

        // Store result
        yield put(InvoiceActions.storeInvoiceResult({
            ...result,
            resultType: invoiceResultTypes.CANCEL,
            sendInvoice,
            creditNoteDocNumber: docNumber,
        }));
        yield put(InvoiceActions.storeNextCreditNoteDocNumber(null));

        // TODO Update invoice status
        yield all([
            call(getInvoiceWorker),
            call(getInvoiceStatsWorker), // Get latest stats section information
        ]);

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('invoice deleteInvoiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const createExternalInvoiceFlow = function* (payload) {
    const {transactionId, accountId, autoAttachDetails} = payload;

    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.CREATE_EXTERNAL_INVOICE, true));

        // Call api
        const result = yield call(InvoiceApi.createExternalInvoice, {
            ...payload,
            autoAttachDetails: undefined,
            accountId: undefined,
        });

        const {docNumber} = result;

        if (!autoAttachDetails) {
            Toast.success('createExternalInvoiceSuccess', {
                translationValues: {docNumber},
            });
        }

        if (transactionId) {
            yield all([
                put(TransactionsActions.getTransaction({id: transactionId, accountId})),
                put(InvoiceActions.setShowCreateForm(false)),
            ]);
        } else {
            yield all([
                // Store needed data
                put(InvoiceActions.setInvoiceSidebar(null)),
                put(InvoiceActions.setInvoiceSidebarData(null)),
                // TODO Update invoice status
                call(getInvoiceWorker),
                call(getInvoiceStatsWorker),
            ]);
        }

        // TODO Do I need entire logic here
        if (autoAttachDetails) {
            const {bestMatchedInvoices = [], allOtherInvoices = []} = autoAttachDetails?.invoices ?? {};

            const allInvoices = [
                ...(
                    bestMatchedInvoices.map(invoice => ({
                        ...invoice,
                        matchType: TransactionMatchType.BEST_MATCH,
                    }))
                ),
                ...(
                    allOtherInvoices.map(invoice => ({
                        ...invoice,
                        matchType: TransactionMatchType.OTHER_MATCH,
                    }))
                ),
            ];

            const selectedInvoicesObj = allInvoices.filter(({selected}) => !!selected);

            const selectedInvoices = selectedInvoicesObj.map(({invoiceId, allocatedAmount, matchType}) => {
                return {invoiceId, allocatedAmount, matchType};
            });

            const allocated = selectedInvoices.reduce((sum, {allocatedAmount}) => sum + Number(allocatedAmount), 0);
            const remaining = Number(autoAttachDetails?.amount) - allocated;

            let totalAmount = Number(result?.totalAmount);
            if (result?.totalFeeAmount) {
                totalAmount = Number(result?.totalAmount) - Number(result?.totalFeeAmount);
            }

            const toBeAllocated = Math.min(totalAmount, remaining);

            const updatedSelectedInvoices = [
                ...selectedInvoices,
                {
                    invoiceId: result?.id,
                    allocatedAmount: Number.isFinite(toBeAllocated)
                        ? toBeAllocated.toFixed(2)
                        : toBeAllocated,
                    matchType: TransactionMatchType.OTHER_MATCH,
                },
            ];

            yield put(TransactionsActions.saveTransactionField({
                id: autoAttachDetails.id,
                fieldKey: 'selectedInvoices',
                fieldValue: updatedSelectedInvoices,
                accountId: autoAttachDetails?.bankAccountId,
            }));
        }

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.CREATE_EXTERNAL_INVOICE, false));
    } catch (e) {
        if (e?.response?.status === 409) {
            // TODO Add code handling here once BE adds
            if (e?.response.data?.message === 'Invoice with provided data already exists.') {
                Toast.error('invoiceAlreadyExists');
                yield put(LoadingActions.setLoading(LoadingTypes.CREATE_EXTERNAL_INVOICE, false));
                // eslint-disable-next-line no-throw-literal
                throw 1; // Throw something to know to stop try/catch propagation
            }
        }

        Debug.error('invoice createExternalInvoiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.CREATE_EXTERNAL_INVOICE, false));
    }
};

const deleteExternalInvoiceFlow = function* ({id, companyId, file, docNumber, date, invoiceDocNumber}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        // Call api
        const result = yield call(InvoiceApi.deleteExternalInvoice, {id, companyId, file, docNumber, date});

        // Store needed data
        yield all([
            put(InvoiceActions.storeInvoiceDraft(null)),
            put(InvoiceActions.setInvoiceSidebar(null)),
            put(InvoiceActions.setInvoiceSidebarData(null)),
            put(UiActions.setActiveModal(ModalsKeys.INVOICE_CANCEL_EXTERNAL_MODAL, false)),
        ]);

        // Store result
        yield put(InvoiceActions.storeInvoiceResult({
            ...result,
            resultType: invoiceResultTypes.CANCEL_EXTERNAL,
            sendInvoice: false,
            invoiceDocNumber,
        }));

        // TODO Update invoice status
        yield all([
            call(getInvoiceWorker),
            call(getInvoiceStatsWorker), // Get latest stats section information
        ]);

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('invoice deleteExternalInvoiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const saveInvoiceDraftFlow = function* ({data, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE, true));

        // Call api
        let result;
        if (data?.id) {
            result = yield call(InvoiceApi.saveInvoice, {
                data,
                companyId,
                id: data.id,
            });
        } else {
            result = yield call(InvoiceApi.createInvoice, {
                data,
                companyId,
            });

            // It is needed due to the case where we call create right after get
            yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_DRAFT, false));
        }

        // Store results
        yield put(InvoiceActions.storeInvoiceDraft({...data, ...result}));

        // TODO Remove when BE fixes
        // Get results
        yield delay(200);
        const getResult = yield call(InvoiceApi.getInvoiceDetails, {companyId, id: result?.id});
        yield put(InvoiceActions.storeInvoiceDraft(getResult));

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE, false));

        // If client payment term is specific and due date is after invoice date show modal for changing due date
        if (
            data?.id
            && result?.client?.paymentTerm === PAYMENT_TERMS.SPECIFIC_DATE_PAYMENT
            && result?.date && result?.dueDate
            && moment(result.date).isAfter(result.dueDate, 'day')
        ) {
            yield put(UiActions.setModalData(ModalsKeys.INVOICE_DUE_DATE_MODAL, null));

            return {openModal: true};
        }
    } catch (e) {
        Debug.error('invoice saveInvoiceDraftFlow', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE, false));
        if (
            e?.response?.data?.error === API_TYPE_ERRORS.InvoiceUpdateError
            && e?.response?.data?.message === 'Due date is before invoicing date' // TODO Type should be added on BE
        ) {
            return {openModal: true};
        }
        Toast.error('genericError');
    }
};

const getInvoiceClientsFlow = function* ({companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_CLIENTS, true));

        // Call api
        const result = yield call(InvoiceApi.getInvoiceClients, {companyId});

        // Set data
        yield put(InvoiceActions.storeInvoiceClients(result));

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_CLIENTS, false));
    } catch (e) {
        Debug.error('invoice getInvoiceClientsFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_CLIENTS, false));
    }
};

const getInvoiceClientFlow = function* ({companyId, id}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_CLIENTS, true));

        // Call api
        const result = yield call(InvoiceApi.getInvoiceClient, {companyId, id});

        // Set data
        yield put(InvoiceActions.storeInvoiceClientEditDraft(result));

        // Store in the invoice draft if it exists
        const invoiceDraft = yield select(InvoiceSelector.selectInvoiceDraft);

        if (invoiceDraft?.id) {
            yield put(InvoiceActions.storeInvoiceDraft({
                ...invoiceDraft,
                client: {...invoiceDraft.client, ...result},
            }));
        }


        const clients = yield select(InvoiceSelector.selectClients);

        const newClients = clients.map(client => (client.id !== result.id ? client : result));

        yield put(InvoiceActions.storeInvoiceClients(newClients));


        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_CLIENTS, false));
    } catch (e) {
        Debug.error('invoice getInvoiceClientFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_CLIENTS, false));
    }
};

const saveClientFlow = function* ({data: dataWithSiret, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_CLIENTS, true));

        const {siret, ...data} = dataWithSiret;

        // Call api
        const result = yield call(InvoiceApi.saveInvoiceClient, {
            data: {...data, fileList: undefined},
            companyId,
        });

        const {countryCode, vatIntraCommunity} = data;

        // Check the client lending eligibility
        if (siret) {
            yield call(InvoiceApi.getLendingEligibility, {
                id: result.id,
                companyId,
                debtorIdentifier: {
                    type: countryCode === 'CH'
                        ? ARIA_DEBTOR_IDENTIFIER_TYPE.SWISS_SINGLE_REG_NUM
                        // : countryCode === 'GB'
                        //     ? ARIA_DEBTOR_IDENTIFIER_TYPE.COMPANY_REGISTRATION_ORG
                        : ARIA_DEBTOR_IDENTIFIER_TYPE.SIRET,
                    value: siret,
                    country: countryCode,
                },
            });
        } else if (ARIA_VAT_COUNTRIES.includes(countryCode)) {
            yield call(InvoiceApi.getLendingEligibility, {
                id: result.id,
                companyId,
                debtorIdentifier: {
                    type: ARIA_DEBTOR_IDENTIFIER_TYPE.VAT_NUMBER,
                    value: vatIntraCommunity,
                    country: countryCode,
                },
            });
        }

        // TODO:[STUPAR] We get all clients because of client ordering on BE
        yield put(InvoiceActions.getInvoiceClients());

        // Save files
        if (data.fileList?.length > 0) {
            const fileResult = yield call(InvoiceApi.saveInvoiceClientFiles,
                {fileList: data.fileList, companyId, id: result.id});

            return {result, fileResult};
        }

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_CLIENTS, false));

        // Close sidebar
        yield put(InvoiceActions.setInvoiceSidebar(null));

        return result;
    } catch (e) {
        Debug.error('invoice saveClientFlow', 'Error: ', {e});

        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_CLIENTS, false));

        if (e?.response?.status === 400 && e?.response?.data?.error === API_TYPE_ERRORS.ClientEmailAlreadyExists) {
            Toast.error('clientEmailAlreadyExists');

            throw new Error(API_TYPE_ERRORS.ClientEmailAlreadyExists);
        }

        Toast.error('genericError');
    }
};

const saveClientInInvoiceFlow = function* ({data: dataWithSiret, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_CLIENTS, true));

        const {siret, ...data} = dataWithSiret;

        // On update
        if (data?.id) {
            // Call update client function
            const updateClientResult = yield call(updateClientFlow, {
                data: dataWithSiret,
                companyId,
            });

            const invoiceDraft = yield select(InvoiceSelector.selectInvoiceDraft);

            yield put(InvoiceActions.saveInvoiceDraft({
                ...invoiceDraft,
                client: updateClientResult,
            }));

            // Clear client draft data
            yield put(InvoiceActions.storeInvoiceClientEditDraft(null));
        } else {
            // Call save client function
            const saveClientResult = yield call(saveClientFlow, {
                data: dataWithSiret,
                companyId,
            });

            Toast.success('clientSuccessfullyCreatedInvoice', {
                translationValues: {
                    name: saveClientResult.name,
                },
            });

            if (data?.fileList) {
                yield put(InvoiceActions.updateFileUploadLoader({
                    clientId: saveClientResult?.result.id,
                    client: saveClientResult?.result,
                    eventId: saveClientResult?.fileResult.pendingEventId,
                    inInvoice: true,
                    inProgress: true,
                }));
            } else {
                const invoiceDraft = yield select(InvoiceSelector.selectInvoiceDraft);

                yield put(InvoiceActions.saveInvoiceDraft({
                    ...invoiceDraft,
                    client: saveClientResult,
                }));

                // Unset loader
                yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_CLIENTS, false));

                // Close sidebar
                yield put(InvoiceActions.setInvoiceSidebar(null));
            }
        }
    } catch (e) {
        Debug.error('invoice saveClientFlow', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_CLIENTS, false));

        if (e?.message === API_TYPE_ERRORS.ClientEmailAlreadyExists) {
            throw new Error(API_TYPE_ERRORS.ClientEmailAlreadyExists);
        }

        Toast.error('genericError');
    }
};

const updateClientFlow = function* ({data: dataWithSiret, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_CLIENTS, true));

        const {siret, ...data} = dataWithSiret;

        // Call api
        const result = yield call(InvoiceApi.updateInvoiceClient, {
            data,
            id: data.id,
            companyId,
        });

        const {countryCode, vatIntraCommunity} = data;

        // Check the client lending eligibility
        if (siret) {
            yield call(InvoiceApi.getLendingEligibility, {
                id: result.id,
                companyId,
                debtorIdentifier: {
                    type: countryCode === 'CH'
                        ? ARIA_DEBTOR_IDENTIFIER_TYPE.SWISS_SINGLE_REG_NUM
                        // : countryCode === 'GB'
                        //     ? ARIA_DEBTOR_IDENTIFIER_TYPE.COMPANY_REGISTRATION_ORG
                        : ARIA_DEBTOR_IDENTIFIER_TYPE.SIRET,
                    value: siret,
                    country: countryCode,
                },
            });
        } else if (ARIA_VAT_COUNTRIES.includes(countryCode)) {
            yield call(InvoiceApi.getLendingEligibility, {
                id: result.id,
                companyId,
                debtorIdentifier: {
                    type: ARIA_DEBTOR_IDENTIFIER_TYPE.VAT_NUMBER,
                    value: vatIntraCommunity,
                    country: countryCode,
                },
            });
        }

        // TODO:[STUPAR] We get all clients because of client ordering on BE
        yield put(InvoiceActions.getInvoiceClients());

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_CLIENTS, false));

        // Close sidebar
        yield all([
            put(InvoiceActions.storeInvoiceClientEditDraft(null)),
            put(InvoiceActions.setInvoiceSidebarData(null)),
            put(InvoiceActions.setInvoiceSidebar(null)),
            put(UiActions.setActiveModal(ModalsKeys.ARIA_VERIFICATION_MODAL, false)),
            put(UiActions.setModalData(ModalsKeys.ARIA_VERIFICATION_MODAL, null)),
        ]);

        Toast.success('clientSuccessfullyUpdated', {
            translationValues: {
                name: result.name,
            },
        });

        return result;
    } catch (e) {
        Debug.error('invoice updateClientFlow', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_CLIENTS, false));

        if (e?.response?.status === 400 && e?.response?.data?.error === API_TYPE_ERRORS.ClientEmailAlreadyExists) {
            Toast.error('clientEmailAlreadyExists');

            throw new Error(API_TYPE_ERRORS.ClientEmailAlreadyExists);
        }

        Toast.error('genericError');
    }
};

const uploadClientFileFlow = function* ({data, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_CLIENT_FILE, true));

        // Call api
        const result = yield call(InvoiceApi.saveInvoiceClientFiles,
            {fileList: data.file, companyId, id: data.id});

        yield put(InvoiceActions.updateFileUploadLoader({
            clientId: data.id,
            eventId: result.pendingEventId,
            inInvoice: data.isInInvoice,
            inProgress: true,
        }));
    } catch (e) {
        Debug.error('invoice uploadClientFileFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_CLIENTS, false));
    }
};

const deleteClientFileFlow = function* ({data, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK_FILE, true));

        // Call api
        const result = yield call(InvoiceApi.deleteInvoiceClientFile,
            {documentId: data.fileId, companyId, id: data.clientId});

        if (data.isInInvoice) {
            const invoiceDraft = yield select(InvoiceSelector.selectInvoiceDraft);

            const index = invoiceDraft.client.documents.findIndex(document => document.id === data.fileId);

            const clientDocuments = [...invoiceDraft.client.documents];
            clientDocuments.splice(index, 1);

            const newClient = {...result, documents: clientDocuments};

            yield put(InvoiceActions.storeInvoiceDraft({...invoiceDraft, client: newClient}));

            Toast.success('genericSuccessDelete');
        } else {
            const clientList = yield select(InvoiceSelector.selectClients);
            const clientIndex = clientList.findIndex(client => client.id === result?.id);
            const newClient = {...result};
            const newClientsList = [...clientList];
            newClientsList.splice(clientIndex, 1, newClient);

            yield put(InvoiceActions.storeInvoiceClients(newClientsList));

            Toast.success('genericSuccessDelete');
        }
    } catch (e) {
        Debug.error('invoice deleteClientFileFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.DELETE_BANK_FILE, false));
    }
};

const deleteClientFlow = function* ({id, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        // Call API
        yield call(InvoiceApi.deleteClient, {id, companyId});

        // Update services list
        const clients = yield select(InvoiceSelector.selectClients);

        const index = clients.findIndex(client => client.id === id);

        const newClients = [...clients];

        const name = newClients[index].name;

        newClients.splice(index, 1);

        yield put(InvoiceActions.storeInvoiceClients(newClients));

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        // Show success message
        Toast.success('clientSuccessfullyDeleted', {
            translationValues: {
                name: name,
            },
        });

        // Close sidebar and modal
        yield all([
            put(InvoiceActions.storeInvoiceClientEditDraft(null)),
            put(InvoiceActions.setInvoiceSidebar(null)),
            put(InvoiceActions.setInvoiceSidebarData(null)),
            put(UiActions.setActiveModal(ModalsKeys.DELETE_CLIENT_MODAL, false)),
        ]);
    } catch (e) {
        Debug.error('invoice deleteServiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const getClientSuggestionsFlow = function* ({clientNamePartial}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_CLIENT_SUGGESTIONS, true));

        const rawSuggestionsArray = yield call(InvoiceApi.getClientSuggestions, {clientNamePartial});

        const suggestionsArray = rawSuggestionsArray.map((suggestion, index) => {
            const {
                adresse: fullAddress,
                numero_voie: streetNumber,
                type_voie: streetType,
                libelle_voie: StreetName,
                code_postal: zipCode,
                libelle_commune: city,
                siret,
            } = suggestion?.siege || {};
            const address = `${streetNumber ?? ''} ${streetType ?? ''} ${StreetName ?? ''}`;
            return {
                id: index,
                name: suggestion?.nom_complet,
                fullAddress,
                address: address.trim(),
                zipCode,
                city,
                siren: suggestion?.siren,
                siret,
            };
        });

        yield put(InvoiceActions.storeClientSuggestions(suggestionsArray));

        yield put(LoadingActions.setLoading(LoadingTypes.GET_CLIENT_SUGGESTIONS, false));
    } catch (e) {
        Debug.error('invoice getClientSuggestionsFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GET_CLIENT_SUGGESTIONS, false));
    }
};

const getInvoiceServicesFlow = function* ({companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_SERVICES, true));

        // Call api
        const result = yield call(InvoiceApi.getInvoiceServices, {companyId});

        // Set data
        yield put(InvoiceActions.storeInvoiceServices(result));

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_SERVICES, false));
    } catch (e) {
        Debug.error('invoice getInvoiceServicesFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_SERVICES, false));
    }
};

// TODO Refactor - this function handles too many responsibilities
// We need to separate create/update as well as update of quantity & period
const saveServiceInInvoiceFlow = function* ({data, companyId}) {
    try {
        const invoiceSidebar = yield select(InvoiceSelector.selectInvoiceSidebar);
        const isInvoiceSidebarOpened = invoiceSidebar === invoiceSidebarType.serviceDates;

        let result = data;
        if (!data?.id) {
            result = yield call(saveServiceFlow, {
                data,
                companyId,
            });

            // Add newly added service to service list
            const serviceList = yield select(InvoiceSelector.selectServices);
            yield put(InvoiceActions.storeInvoiceServices([
                ...serviceList,
                result,
            ]));

            Toast.success('serviceSuccessfullyCreatedInvoice', {
                translationValues: {
                    name: result.name,
                },
            });
        } else if (data?.name && data?.type && data?.rate && data?.rateUnit) {
            result = yield call(saveServiceFlow, {
                data,
                companyId,
            });

            // Update service list
            const serviceList = yield select(InvoiceSelector.selectServices);
            const newServiceList = [...serviceList];
            const index = newServiceList.findIndex(service => service?.id === data.id);

            if (index !== -1) {
                newServiceList[index] = {...newServiceList[index], ...result};

                yield put(InvoiceActions.storeInvoiceServices(newServiceList));
            }
        }

        // After save remove draft and close sidebar
        yield put(InvoiceActions.setInvoiceSidebar(null));
        yield put(InvoiceActions.storeInvoiceServiceEditDraft(null));

        // Set invoice
        const invoiceDraft = yield select(InvoiceSelector.selectInvoiceDraft);

        const invoiceDraftServiceIndex = invoiceDraft?.services?.length > 0
            ? invoiceDraft?.services.findIndex(service => service.id === result?.id)
            : -1;

        const hasService = invoiceDraftServiceIndex !== -1;

        // Quantity should be reset if rateUnit is changed
        const shouldChangePeriodAndQuantity = hasService
            && data?.rateUnit !== invoiceDraft.services[invoiceDraftServiceIndex].rateUnit;

        // If there is service with given ID in invoice draft update it
        const period = data?.period
            ?? (!shouldChangePeriodAndQuantity && hasService
                ? invoiceDraft.services[invoiceDraftServiceIndex].period
                : [moment().format('MM/YYYY')]
            );
        const quantity = data?.quantity
            ?? (!shouldChangePeriodAndQuantity && hasService
                ? invoiceDraft.services[invoiceDraftServiceIndex].quantity
                : 0
            );

        let newServices;
        const newService = {
            'id': result.id,
            'quantity': quantity,
            'period': period,
            'selectedDates': data?.selectedDates ?? undefined,
        };

        if (!invoiceDraft.services) {
            newServices = [newService];
        } else if (hasService) {
            newServices = [...invoiceDraft.services];
            newServices.splice(
                invoiceDraftServiceIndex,
                1,
                newService,
            );
        } else {
            // If there is NO service with given ID in invoice draft add it
            newServices = [...invoiceDraft.services, newService];
        }

        yield put(InvoiceActions.saveInvoiceDraft({
            ...invoiceDraft,
            services: newServices,
        }));

        // If it is a day rateUnit and sidebar is not for selecting dates
        if (data?.rateUnit === ServiceRateUnit.DAY && !isInvoiceSidebarOpened) {
            const invoiceDraft = yield select(InvoiceSelector.selectInvoiceDraft);
            const serviceInInvoice = data?.id
                ? invoiceDraft.services?.find(service => service.id === data.id)
                : result;

            yield put(InvoiceActions.setInvoiceSidebarData({
                id: result?.id,
                name: result?.name,
                selectedDates: serviceInInvoice?.selectedDates ?? data?.selectedDates,
            }));
            yield put(InvoiceActions.setInvoiceSidebar(invoiceSidebarType.serviceDates));
        }
    } catch (e) {
        // Stopping try/catch propagation
        if (e === 1) {
            return;
        }

        Debug.error('invoice saveServiceInInvoiceFlow', 'Error: ', {e});
        Toast.error('genericError');
    }
};

const getServiceDetailsFlow = function* ({data, companyId}) {
    if (!data) {
        Debug.error('invoice getServiceDetailsFlow', 'Error: No ID for getServiceDetailsFlow', {data});
        Toast.error('genericError');
        return;
    }

    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_SERVICES, true));

        // Call api
        const result = yield call(InvoiceApi.getServiceDetails, {companyId, id: data});

        // TODO BE is sending discountPercentage "0.0000000" even when there is no discount
        if (result.discountPercentage && !Number(result?.discountPercentage)) {
            result.discountPercentage = null;
        }

        // Set data
        yield put(InvoiceActions.storeInvoiceServiceEditDraft(result));

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_SERVICES, false));
    } catch (e) {
        Debug.error('invoice getServiceDetailsFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GET_INVOICE_SERVICES, false));
    }
};

const updateServiceFlow = function* ({data, companyId}) {
    try {
        // Call api
        return yield call(InvoiceApi.updateInvoiceService, {
            data,
            companyId,
            id: data.id,
        });
    } catch (e) {
        Debug.error('invoice updateServiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_SERVICE, false));
    }
};

export const saveServiceFlow = function* ({data, companyId, inInvoice = true}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_SERVICE, true));

        let result;
        if (data?.id) {
            result = yield call(updateServiceFlow, {data, companyId});
        } else {
            // Call api
            result = yield call(InvoiceApi.saveInvoiceService, {
                data,
                companyId,
            });
        }

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_SERVICE, false));

        return result;
    } catch (e) {
        if (e?.response?.status === 409) {
            if (e?.response?.data?.error === API_TYPE_ERRORS.ServiceAlreadyExists) {
                Toast.error('serviceAlreadyExists');
                yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_SERVICE, false));

                if (inInvoice) {
                    // eslint-disable-next-line no-throw-literal
                    throw 1; // Throw something to know to stop try/catch propagation
                }

                return;
            }
        }

        Debug.error('invoice saveServiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_INVOICE_SERVICE, false));
    }
};

const sendReminderFlow = function* ({id, companyId, emails, subject, message}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.SENDING_REMINDER, true));

        // Call api
        const result = yield call(InvoiceApi.sendReminder, {id, companyId, emails, subject, message});

        // Close sidebar
        yield all([
            put(InvoiceActions.setInvoiceSidebar(null)),
            put(InvoiceActions.setInvoiceSidebarData(null)),
            put(UiActions.setActiveModal(ModalsKeys.INVOICE_SEND_REMINDER_MODAL, false)),
        ]);

        // Store result
        yield put(InvoiceActions.storeInvoiceResult({
            ...result,
            resultType: invoiceResultTypes.REMIND,
            sendInvoice: true,
        }));

        // TODO Update invoice status
        yield all([
            call(getInvoiceWorker),
            call(getInvoiceStatsWorker), // Get latest stats section information
        ]);

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.SENDING_REMINDER, false));
        yield put(InvoiceActions.storeInvoiceRemindData(null));
    } catch (e) {
        Debug.error('invoice sendReminderFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.SENDING_REMINDER, false));
        yield put(UiActions.setActiveModal(ModalsKeys.INVOICE_SEND_REMINDER_MODAL, false));
        yield put(InvoiceActions.storeInvoiceRemindData({id, emails, subject, message}));
        yield put(UiActions.setActiveModal(ModalsKeys.INVOICE_SEND_REMINDER_ERROR_MODAL, true));
    }
};

const sendInvoiceFlow = function* ({id, companyId, resend}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        // Call api
        const result = yield call(InvoiceApi.sendInvoice, {id, companyId});

        // Close sidebar
        yield all([
            put(InvoiceActions.setInvoiceSidebar(null)),
            put(InvoiceActions.setInvoiceSidebarData(null)),
            put(UiActions.setActiveModal(ModalsKeys.INVOICE_SEND_MODAL, false)),
        ]);

        // Store result
        yield put(InvoiceActions.storeInvoiceResult({
            ...result,
            resultType: resend ? invoiceResultTypes.RESEND : invoiceResultTypes.SEND,
            sendInvoice: true,
        }));

        // TODO Update invoice status
        yield all([
            call(getInvoiceWorker),
            call(getInvoiceStatsWorker), // Get latest stats section information
        ]);

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('invoice sendInvoiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const reCreateInvoiceFlow = function* ({id, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        // Call api
        const result = yield call(InvoiceApi.reCreateInvoice, {id, companyId});

        yield put(push({
            pathname: RoutePaths.CREATE_INVOICE,
        }));

        // Handle if some data is missing
        if (
            (
                !result?.client
                || !result?.services
                || result?.services?.length === 0
            )
            || (
                result?.missingData
            )
        ) {
            yield put(InvoiceActions.setImportData(
                {
                    type: INVOICE_IMPORT_RESULT.MISSING_IMPORT_ITEM,
                    docNumber: result?.docNumber,
                },
            ));
        } else {
            yield put(InvoiceActions.setImportData(
                {
                    type: INVOICE_IMPORT_RESULT.COPY_SUCCESS,
                    docNumber: result?.docNumber,
                },
            ));
        }

        // Close sidebar
        yield all([
            put(InvoiceActions.setInvoiceSidebar(null)),
            put(InvoiceActions.setInvoiceSidebarData(null)),
            // TODO Get in the InvoiceCreate should not be connected with documentId
            // Annuling documentId so we can get newest draft after redirect
            put(InvoiceActions.storeInvoiceDraft(result)),
        ]);

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('invoice reCreateInvoiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const getNextCreditNoteDocNumberFlow = function* ({companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        // Call api
        const result = yield call(InvoiceApi.getNextCreditNoteDocNumber, {companyId});

        // Set data
        yield put(InvoiceActions.storeNextCreditNoteDocNumber(result));

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('invoice getNextCreditNoteDocNumberFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const deleteServiceFlow = function* ({id, companyId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        // Call API
        yield call(InvoiceApi.deleteService, {id, companyId});

        // Update services list
        const services = yield select(InvoiceSelector.selectServices);

        const index = services.findIndex(service => service.id === id);

        const name = services[index].name;

        const newServices = [...services];

        newServices.splice(index, 1);

        yield put(InvoiceActions.storeInvoiceServices(newServices));

        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));

        // Show success message
        Toast.success('serviceSuccessfullyDeleted', {
            translationValues: {
                name: name,
            },
        });

        // Close sidebar and modal
        yield all([
            put(InvoiceActions.storeInvoiceServiceEditDraft(null)),
            put(InvoiceActions.setInvoiceSidebar(null)),
            put(InvoiceActions.setInvoiceSidebarData(null)),
            put(UiActions.setActiveModal(ModalsKeys.DELETE_SERVICE_MODAL, false)),
        ]);
    } catch (e) {
        Debug.error('invoice deleteServiceFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
        yield put(UiActions.setActiveModal(ModalsKeys.DELETE_SERVICE_MODAL, false));
    }
};

const getAriaInfoFlow = function* ({freelancerId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.ARIA_LOADER, true));

        // Call api
        const result = yield call(InvoiceApi.getAriaInfo, {freelancerId});
        yield put(InvoiceActions.storeAriaStatus(result));
    } catch (e) {
        Debug.error('aria activateAriaFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.ARIA_LOADER, false));
    }
};

const activateAriaFlow = function* ({fileList, idDocType, freelancerId}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.ARIA_LOADER, true));

        // Call api
        const result = yield call(InvoiceApi.activateAria, {fileList, idDocType, freelancerId});

        yield put(InvoiceActions.storeAriaStatus(result));
        yield put(UiActions.setActiveModal(ModalsKeys.ARIA_DOCUMENTS, false));
        yield put(InvoiceActions.setShowAriaParameters(false));
        yield put(InvoiceActions.setShowAriaActivationPage(true));
    } catch (e) {
        Debug.error('aria activateAriaFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.ARIA_LOADER, false));
    }
};

const getUserPersonalDocumentFlow = function* ({freelancerId, companyId, docType}) {
    try {
        // Set loader
        yield put(LoadingActions.setLoading(LoadingTypes.ARIA_DOCUMENT_LOADER, true));

        // Call api
        const result = yield call(getCompanyDocumentsByTypeRequest, freelancerId, companyId, docType);

        const sortedDesc = result.sort((a, b) => {
            const dateA = new Date(a.dateCreated);
            const dateB = new Date(b.dateCreated);

            if (dateA < dateB) {
                return 1;
            } else if (dateA > dateB) {
                return -1;
            }
            return 0;
        });

        const docId = sortedDesc?.[0]?.id;

        if (docId) {
            yield put(getCompanyDocument(freelancerId, companyId, docId, false, true));
        }
    } catch (e) {
        Debug.error('aria getUserPersonalDocumentFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        // Unset loader
        yield put(LoadingActions.setLoading(LoadingTypes.ARIA_DOCUMENT_LOADER, false));
    }
};

const getlastExternalInvoicesFlow = function* ({companyId}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, true));

        // Call api
        const result = yield call(InvoiceApi.getLastExternalInvoices, {
            companyId,
        });

        if (result?.items?.[0]) {
            yield put(InvoiceActions.storeLastExternalInvoice(result.items[0]));
        }

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    } catch (e) {
        Debug.error('invoice getlastExternalInvoicesFlow', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GENERIC_CRUD_LOADER, false));
    }
};

const prepareParams = () => {
    const queryParams = getSearchParameters();
    const params = {
        limit: INVOICE_TABLE_DEFAULT.limit,
    };

    if (queryParams['invoicing-rowsPerPage']) {
        params.limit = queryParams['invoicing-rowsPerPage'];
    }

    if (queryParams['invoicing-page']) {
        params.offset = queryParams['invoicing-page'] * (params.limit ?? INVOICE_TABLE_DEFAULT.limit);
    }

    if (queryParams['invoicing-searchTerm']) {
        params.searchQuery = queryParams['invoicing-searchTerm'];
    }

    if (queryParams['invoicing-sortBy']) {
        params.sortBy = queryParams['invoicing-sortBy'];
        params.sortOrder = INVOICE_TABLE_DEFAULT.sortOrder;
    }

    if (queryParams['invoicing-sortDirection']) {
        params.sortOrder = queryParams['invoicing-sortDirection'];
    }

    return params;
};

// Workers section
export const getInvoiceWorker = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    const params = prepareParams();

    yield call(getInvoiceFlow, {params, companyId: loggedInUser.defaultCompanyId});
};

export const getAdminInvoiceWorker = function* ({payload}) {
    const {params: {companyId, freelancerId}} = payload ?? {};

    yield call(loadCompany, freelancerId, companyId);

    const {status} = yield select(selectCompany) ?? {};

    if (status !== COMPANY_STATUSES.ACTIVE) {
        return;
    }

    const params = prepareParams();

    yield call(getInvoiceFlow, {params, companyId});
};

export const getInvoiceDraftWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(getInvoiceDraftFlow, {
        companyId: loggedInUser.defaultCompanyId,
        createIfDoesNotExist: !!payload?.createIfDoesNotExist,
        useNoLoader: payload?.useNoLoader,
    });
};

export const getInvoiceStatsWorker = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(getInvoiceStatsFlow, {companyId: loggedInUser.defaultCompanyId});
};

export const finalizeInvoiceWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    // If there is new default set new default
    if (payload?.hasNewDefault && payload?.selectedIntegration) {
        if (payload.selectedIntegration?.id && payload.selectedIntegration?.bankAccountHolderId) {
            yield call(setDefaultBankFlow, {
                id: payload.selectedIntegration.id,
                bankAccountHolderId: payload.selectedIntegration.bankAccountHolderId,
            });
        }
    }

    yield call(finalizeInvoiceFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});
};

const getInvoicePreviewWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(getInvoicePreviewFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});
};

const uploadAnnexFileWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(uploadAnnexFileFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});
};

const deleteAnnexFileWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(deleteAnnexFileFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});
};

const deleteAllAnnexFilesWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(deleteAllAnnexFilesFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});
};

const getLastInternalInvoiceWorker = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(getLastInternalInvoiceFlow, {companyId: loggedInUser.defaultCompanyId});
};

const importInvoiceWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(importInvoiceFlow, {
        data: payload,
        companyId: loggedInUser.defaultCompanyId,
    });
};

const deleteInvoiceWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(deleteInvoiceFlow, {
        id: payload?.id,
        companyId: loggedInUser.defaultCompanyId,
        isDraft: payload?.isDraft ?? false,
        sendInvoice: payload?.sendInvoice,
    });
};

const createExternalInvoiceWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(createExternalInvoiceFlow, {
        ...payload,
        companyId: loggedInUser.defaultCompanyId ?? payload?.companyId,
    });
};

const deleteExternalInvoiceWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(deleteExternalInvoiceFlow, {
        id: payload?.id,
        companyId: loggedInUser.defaultCompanyId,
        file: payload?.file,
        docNumber: payload?.docNumber,
        date: payload?.date,
        invoiceDocNumber: payload?.invoiceDocNumber,
    });
};

const saveInvoiceDraftWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    const result = yield call(saveInvoiceDraftFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});

    // If it is opened from due date modal close it
    const isModalOpen = yield select(UiSelectors.createModalDataSelector(ModalsKeys.INVOICE_DUE_DATE_MODAL));

    if (result?.openModal) {
        yield put(UiActions.setActiveModal(ModalsKeys.INVOICE_DUE_DATE_MODAL, true));
    } else if (isModalOpen) {
        yield put(UiActions.setActiveModal(ModalsKeys.INVOICE_DUE_DATE_MODAL, false));
    }
};

export const getInvoiceClientsWorker = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(getInvoiceClientsFlow, {companyId: loggedInUser.defaultCompanyId});
};

const getInvoiceClientWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(getInvoiceClientFlow, {companyId: loggedInUser.defaultCompanyId, id: payload});
};

const saveClientWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    const saveClientResult = yield call(saveClientFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});

    if (payload?.fileList && payload?.fileList.length) {
        yield put(InvoiceActions.updateFileUploadLoader({
            clientId: saveClientResult?.result.id,
            client: saveClientResult?.result,
            eventId: saveClientResult?.fileResult.pendingEventId,
            inProgress: true,
        }));
    } else {
        Toast.success('clientSuccessfullyCreated', {
            translationValues: {
                name: saveClientResult.name,
            },
        });

        yield all([
            put(InvoiceActions.storeInvoiceClientEditDraft(null)),
            put(InvoiceActions.setInvoiceSidebarData(null)),
            put(InvoiceActions.setInvoiceSidebar(null)),
        ]);
    }
};

const saveClientInInvoiceWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    try {
        yield call(saveClientInInvoiceFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});

        yield all([
            put(InvoiceActions.storeInvoiceClientEditDraft(null)),
            put(InvoiceActions.setInvoiceSidebarData(null)),
            put(InvoiceActions.setInvoiceSidebar(null)),
        ]);
    } catch (e) {
        Debug.error('invoice saveClientInInvoiceWorker', 'Error: ', {e});
    }
};

const updateClientWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(updateClientFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});
};

const uploadClientFileWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(uploadClientFileFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});
};

const deleteClientFileWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(deleteClientFileFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});
};

const deleteClientWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(deleteClientFlow, {id: payload.id, companyId: loggedInUser.defaultCompanyId});

    // Close sidebar
    yield all([
        put(InvoiceActions.storeInvoiceClientEditDraft(null)),
        put(InvoiceActions.setInvoiceSidebarData(null)),
        put(InvoiceActions.setInvoiceSidebar(null)),
        put(InvoiceActions.storeInvoiceServiceEditDraft(null)),
    ]);
};

const getClientSuggestionsWorker = function* ({payload}) {
    yield call(getClientSuggestionsFlow, {clientNamePartial: payload});
};

export const getInvoiceServicesWorker = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(getInvoiceServicesFlow, {companyId: loggedInUser.defaultCompanyId});
};

export const saveServiceWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    const result = yield call(
        saveServiceFlow,
        {
            data: payload,
            companyId: loggedInUser.defaultCompanyId,
            inInvoice: false,
        },
    );

    if (!result?.name) {
        return;
    }

    if (payload?.id) {
        Toast.success('serviceSuccessfullyUpdated', {
            translationValues: {
                name: result.name,
            },
        });
    } else {
        Toast.success('serviceSuccessfullyCreated', {
            translationValues: {
                name: result.name,
            },
        });
    }

    yield put(InvoiceActions.getInvoiceServices());

    yield put(InvoiceActions.setInvoiceSidebar(null));
    yield put(InvoiceActions.storeInvoiceServiceEditDraft(null));
};

const deleteServiceWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(deleteServiceFlow, {id: payload.id, companyId: loggedInUser.defaultCompanyId});

    // Close sidebar
    yield all([
        put(InvoiceActions.storeInvoiceClientEditDraft(null)),
        put(InvoiceActions.setInvoiceSidebarData(null)),
        put(InvoiceActions.setInvoiceSidebar(null)),
        put(InvoiceActions.storeInvoiceServiceEditDraft(null)),
    ]);
};

export const saveServiceInInvoiceWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(saveServiceInInvoiceFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});
};

export const getServiceDetailsWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(getServiceDetailsFlow, {data: payload, companyId: loggedInUser.defaultCompanyId});
};

const sendReminderWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(sendReminderFlow, {
        id: payload?.id,
        companyId: loggedInUser.defaultCompanyId,
        emails: payload?.emails,
        subject: payload?.subject,
        message: payload?.message,
    });
};

const sendInvoiceWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(sendInvoiceFlow, {
        id: payload?.id,
        companyId: loggedInUser.defaultCompanyId,
        resend: payload?.resend,
    });
};

const reCreateInvoiceWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(reCreateInvoiceFlow, {
        id: payload?.id,
        companyId: loggedInUser.defaultCompanyId,
    });
};

const getNextCreditNoteDocNumberWorker = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(getNextCreditNoteDocNumberFlow, {
        companyId: loggedInUser.defaultCompanyId,
    });
};

export const getAriaInfoWorker = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(getAriaInfoFlow, {
        freelancerId: loggedInUser.id,
    });
};

const activateAriaWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(activateAriaFlow, {
        freelancerId: loggedInUser.id,
        fileList: payload?.fileList,
        idDocType: payload?.idDocType,
    });
};

const getlastExternalInvoicesWorker = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    if (loggedInUser?.role === USER_ROLES.ADMINISTRATOR) {
        const user = yield select(selectFreelancerAccount);
        yield call(getlastExternalInvoicesFlow, {
            companyId: user.defaultCompanyId,
        });
        return;
    }

    yield call(getlastExternalInvoicesFlow, {
        companyId: loggedInUser.defaultCompanyId,
    });
};

const getUserPersonalDocumentWorker = function* ({payload}) {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(getUserPersonalDocumentFlow, {
        freelancerId: loggedInUser.id,
        companyId: loggedInUser.defaultCompanyId,
        docType: payload?.docType,
    });
};

export const watchInvoiceSaga = function* () {
    yield all([
        takeEvery(InvoiceActionType.GET_INVOICE_STATS, getInvoiceStatsWorker),
        takeEvery(InvoiceActionType.FINALIZE_INVOICE, finalizeInvoiceWorker),
        takeEvery(InvoiceActionType.DELETE_INVOICE, deleteInvoiceWorker),
        takeEvery(InvoiceActionType.CREATE_EXTERNAL_INVOICE, createExternalInvoiceWorker),
        takeEvery(InvoiceActionType.DELETE_EXTERNAL_INVOICE, deleteExternalInvoiceWorker),
        takeEvery(InvoiceActionType.GET_INVOICE_DRAFT, getInvoiceDraftWorker),
        takeEvery(InvoiceActionType.SAVE_INVOICE_DRAFT, saveInvoiceDraftWorker),
        takeEvery(InvoiceActionType.GET_INVOICE_PREVIEW, getInvoicePreviewWorker),
        takeEvery(InvoiceActionType.UPLOAD_ANNEX_FILE, uploadAnnexFileWorker),
        takeEvery(InvoiceActionType.DELETE_ANNEX_FILE, deleteAnnexFileWorker),
        takeEvery(InvoiceActionType.DELETE_ALL_ANNEX_FILES, deleteAllAnnexFilesWorker),
        takeEvery(InvoiceActionType.GET_LAST_INTERNAL_INVOICE, getLastInternalInvoiceWorker),
        takeEvery(InvoiceActionType.IMPORT_INVOICE, importInvoiceWorker),
        takeEvery(InvoiceActionType.SEND_REMINDER, sendReminderWorker),
        takeEvery(InvoiceActionType.SEND_INVOICE, sendInvoiceWorker),
        takeEvery(InvoiceActionType.RE_CREATE_INVOICE, reCreateInvoiceWorker),
        takeEvery(InvoiceActionType.GET_NEXT_CREDIT_NOTE_DOC_NUMBER, getNextCreditNoteDocNumberWorker),
        takeEvery(InvoiceActionType.GET_LAST_EXTERNAL_INVOICES, getlastExternalInvoicesWorker),
        takeEvery(InvoiceActionType.GET_ADMIN_INVOICE, getAdminInvoiceWorker),

        takeEvery(InvoiceActionType.GET_CLIENTS, getInvoiceClientsWorker),
        takeEvery(InvoiceActionType.GET_CLIENT, getInvoiceClientWorker),
        takeEvery(InvoiceActionType.SAVE_CLIENT, saveClientWorker),
        takeEvery(InvoiceActionType.SAVE_CLIENT_IN_INVOICE, saveClientInInvoiceWorker),
        takeEvery(InvoiceActionType.UPDATE_CLIENT, updateClientWorker),
        takeEvery(InvoiceActionType.UPLOAD_CLIENT_FILE, uploadClientFileWorker),
        takeEvery(InvoiceActionType.DELETE_CLIENT_FILE, deleteClientFileWorker),
        takeEvery(InvoiceActionType.DELETE_CLIENT, deleteClientWorker),
        takeEvery(InvoiceActionType.GET_CLIENT_SUGGESTIONS, getClientSuggestionsWorker),

        takeEvery(InvoiceActionType.GET_SERVICES, getInvoiceServicesWorker),
        takeEvery(InvoiceActionType.SAVE_SERVICES, saveServiceWorker),
        takeEvery(InvoiceActionType.SAVE_SERVICE_IN_INVOICE, saveServiceInInvoiceWorker),
        takeEvery(InvoiceActionType.GET_SERVICE_DETAILS, getServiceDetailsWorker),
        takeEvery(InvoiceActionType.DELETE_SERVICE, deleteServiceWorker),

        takeEvery(InvoiceActionType.GET_ARIA_INFO, getAriaInfoWorker),
        takeEvery(InvoiceActionType.ACTIVATE_ARIA, activateAriaWorker),
        takeEvery(InvoiceActionType.GET_USER_PERSONAL_DOCUMENT, getUserPersonalDocumentWorker),
    ]);
};
