import {
    faHourglassStart,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Typography} from '@mui/joy';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import Category from './account-balance-data-row/AccountBalanceTableDataRowCategory';
import ChipSection from './account-balance-data-row/AccountBalanceTableDataRowChip';
import AccountBalanceTableDataRowDate from './account-balance-data-row/AccountBalanceTableDataRowDate';
import Vat from './account-balance-data-row/AccountBalanceTableDataRowVat';
import AccountBalanceIcon from './AccountBalanceIcon';
import Collapse from '../../../../../../components/joy-ui/mui-legacy/Collapse';
import {ConditionalTooltip} from '../../../../../../components/tooltip/ConditionalTooltip';
import {currencyToSign, getNumberSegments, numberWithSpaces} from '../../../../../../v1/app/app.helpers';
import {TransactionsSelector} from '../../store/transactions.selector';
import {
    MAX_TRANSACTIONS_ANIMATION,
    TransactionSide,
} from '../../util/constants';
import {AccountBalanceHelperFunctions} from '../../util/functions';

const currentYear = moment().format('YYYY');
const AccountBalanceTableDataRow = ({
    id,
    prevId,
    isSidebarOpened,
    tableSize,
    isMobileSize,
    filter,
    onOpenClick,
    language,
    selectCategoriesObject,
    selectSourcesObject,
    t,
    areFieldsCollapsed,
    totalLoadedIds,
    isUserHasInvoiceAccess,
    combinedTabIcons,
}) => {
    const data = useSelector(TransactionsSelector.createSelectTransactionById(id));
    const prevData = useSelector(TransactionsSelector.createSelectTransactionById(prevId));

    if (!data) {
        return null;
    }

    // Do not display if filter for not categorized is active, but it has no chip flag
    if (!data.hasChip && filter.isNotCategorized) {
        return null;
    }

    const isDebit = data.side === 'DEBIT';
    let AdditionalContent = null;
    let isEditable = true;

    const {
        isRejected,
        isCancelled,
        isOnHold,
        isUpcoming,
        isNotCategorized,
        isCategorized,
        isAutoCategorized,
    } = AccountBalanceHelperFunctions.getCategorizationState(data);

    const isRejectedOrCanceled = isCancelled || isRejected;

    const isChipVisible = isUserHasInvoiceAccess || data?.side !== TransactionSide.CREDIT;

    const hasChips = isChipVisible
        && (
            isNotCategorized
            || isCategorized
            || isRejected
            || isCancelled
        );

    if (currentYear !== data.date.format('YYYY')) {
        isEditable = false;
    } else if (isRejectedOrCanceled) {
        isEditable = false;
    }

    let isNewDate = false;

    if (!prevData || (!prevData.date.isSame(data.date, 'day'))) {
        isNewDate = true;
    }

    if (prevData && (prevData.date.format('M') !== data.date.format('M'))) {
        let displayYear = '';
        if (currentYear !== data.date.format('YYYY')) {
            displayYear = data.date.format('YYYY');
        }

        AdditionalContent = (
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-end',
                mt: '-2px',
                mb: 0,
                textTransform: 'capitalize',
                pl: 1,
            }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    minWidth: '19px',
                    height: '100%',
                    minHeight: '70px',
                    position: 'relative',
                }}
                >
                    <Box sx={{
                        borderRight: '1px solid',
                        borderColor: 'primary.100',
                        display: 'flex',
                        flexGrow: 1,
                        zIndex: 4,
                    }}
                    />
                </Box>
                <Typography
                    level="h4"
                    component="div"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        ml: 'calc(5% - 24px)',
                        position: 'relative',
                        top: '4px',
                        fontWeight: 'lg',
                        color: 'text.secondary',
                    }}
                >
                    { data.date.format('MMMM') } { displayYear }
                </Typography>
            </Box>
        );
    }

    const numberSegments = getNumberSegments(numberWithSpaces(data.amount));

    if (isMobileSize) {
        return (
            <Box
                key={data.id}
                onClick={() => onOpenClick(data.id, data.hasChip && isChipVisible, data.status)}
            >

                <Box sx={{
                    display: 'flex',
                }}
                >
                    <Box sx={{width: '50px', mr: 2}}>
                        <AccountBalanceTableDataRowDate
                            data={data}
                            isNewDate={isNewDate}
                            isV3
                        />
                    </Box>

                    <Box sx={{
                        width: 'calc(100vw - 50px)',
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        py: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                    }}
                    >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        >
                            <Box sx={{
                                width: '50%',
                            }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                >
                                    <AccountBalanceIcon
                                        paymentType={data.type}
                                        category={data.category}
                                        sourceOfRevenue={data.sourceOfRevenue}
                                        hasBackground={false}
                                        iconColor={isRejected ? 'text.tertiary' : 'neutral.400'}
                                        size="20px"
                                        isV3
                                        boxSx={{
                                            ml: 0,
                                        }}
                                    />

                                    <Typography
                                        level="body-md"
                                        sx={{
                                            fontWeight: 'lg',
                                            fontSize: 'md',
                                            lineHeight: 'md',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            color: isRejected ? 'text.tertiary' : 'neutral.plainColor',
                                        }}
                                    >
                                        {AccountBalanceHelperFunctions.getTransactionLabel(data)}
                                    </Typography>

                                    {isChipVisible && isOnHold && (
                                        <ConditionalTooltip
                                            tooltip={t('accountBalanceTableHeader.onHoldAlertHeader')}
                                            isV3
                                        >
                                            <Box
                                                fontSize="md"
                                                sx={{
                                                    color: 'warning.solidBg',
                                                    ml: 1,
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faHourglassStart}
                                                    fixedWidth
                                                />
                                            </Box>
                                        </ConditionalTooltip>
                                    )}

                                    {AccountBalanceHelperFunctions.showLabelIcon(data, combinedTabIcons)}
                                </Box>
                            </Box>

                            <Box sx={{
                                width: '50%',
                            }}
                            >
                                <Box sx={{
                                    color: isRejected ? 'text.tertiary' : !isDebit ? 'text.success' : 'text.secondary',
                                    textAlign: 'right',
                                    whiteSpace: 'nowrap',
                                    height: '24px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                }}
                                >
                                    <Typography
                                        component="span"
                                        level="title-lg"
                                        sx={{
                                            color: 'inherit',
                                            display: 'inline',
                                        }}
                                    >
                                        {parseFloat(data.amount) !== 0 && (isDebit ? '-' : '+')}{numberSegments?.integer},
                                    </Typography>
                                    <Typography
                                        component="span"
                                        sx={{
                                            fontWeight: 'smd',
                                            fontSize: 'sm',
                                            fontStyle: 'italic',
                                            color: 'inherit',
                                            display: 'inline',
                                        }}
                                    >
                                        {numberSegments?.decimal} {currencyToSign(data.currency)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                        >
                            <Box sx={{
                                width: '50%',
                            }}
                            >
                                {hasChips && (
                                    <ChipSection
                                        isNotCategorized={isNotCategorized}
                                        isRejected={isRejected}
                                        isCancelled={isCancelled}
                                        isCategorized={isCategorized}
                                        isAutoCategorized={isAutoCategorized}
                                        isUpcoming={isUpcoming}
                                        t={t}
                                        isV3
                                    />
                                )}
                            </Box>
                            <Category
                                side={data.side}
                                category={data.category}
                                sourceOfRevenue={data.sourceOfRevenue}
                                language={language}
                                selectSourcesObject={selectSourcesObject}
                                selectCategoriesObject={selectCategoriesObject}
                                isEditable={isEditable}
                                isV3
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    const CollapseElement = totalLoadedIds > MAX_TRANSACTIONS_ANIMATION ? Box : Collapse;

    return (
        <Box
            key={data.id}
            onClick={() => onOpenClick(data.id, data.hasChip && isChipVisible, data.status)}
        >
            {AdditionalContent}
            <Box sx={{
                'display': 'flex',
                'alignItems': 'center',
                'overFlowX': 'hidden',
                'borderBottom': '1px solid',
                'borderColor': 'divider',
                '&:hover': {
                    'cursor': 'pointer',
                    'backgroundColor': 'background.surface',
                    '& .vat-box': {
                        visibility: 'visible',
                    },
                },
            }}
            >
                <Box sx={{
                    flex: tableSize.firstSegment,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    mb: '-1px',
                    pb: '1px',
                    backgroundColor: 'white',
                    pr: 1,
                    mr: 1,
                }}
                >
                    <AccountBalanceTableDataRowDate
                        data={data}
                        isNewDate={isNewDate}
                        isV3
                    />
                </Box>

                <Box sx={{
                    flex: tableSize.secondSegment,
                }}
                >
                    {hasChips && (
                        <ChipSection
                            isNotCategorized={isNotCategorized}
                            isRejected={isRejected}
                            isCancelled={isCancelled}
                            isCategorized={isCategorized}
                            isAutoCategorized={isAutoCategorized}
                            isUpcoming={isUpcoming}
                            t={t}
                            isV3
                        />
                    )}
                </Box>

                <Box sx={{
                    flex: tableSize.thirdSegment,
                    maxWidth: '35%',
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        <AccountBalanceIcon
                            paymentType={data.type}
                            category={data.category}
                            sourceOfRevenue={data.sourceOfRevenue}
                            hasBackground={false}
                            iconColor={isRejected ? 'text.tertiary' : 'neutral.400'}
                            size="20px"
                            isV3
                        />

                        <Typography
                            level="body-md"
                            sx={{
                                fontWeight: 'lg',
                                fontSize: 'md',
                                lineHeight: 'md',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                color: isRejected ? 'text.tertiary' : 'neutral.plainColor',
                            }}
                        >
                            {AccountBalanceHelperFunctions.getTransactionLabel(data)}
                        </Typography>

                        {isChipVisible && isOnHold && (
                            <ConditionalTooltip
                                tooltip={t('accountBalanceTableHeader.onHoldAlertHeader')}
                                isV3
                            >
                                <Box
                                    fontSize="md"
                                    sx={{
                                        color: 'warning.solidBg',
                                        ml: 1,
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faHourglassStart}
                                        fixedWidth
                                    />
                                </Box>
                            </ConditionalTooltip>
                        )}

                        {AccountBalanceHelperFunctions.showLabelIcon(data, combinedTabIcons)}
                    </Box>
                </Box>

                <CollapseElement
                    in={!isSidebarOpened || !areFieldsCollapsed}
                    sx={{
                        flex: tableSize.fourthSegment,
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <Category
                            side={data.side}
                            category={data.category}
                            sourceOfRevenue={data.sourceOfRevenue}
                            language={language}
                            selectSourcesObject={selectSourcesObject}
                            selectCategoriesObject={selectCategoriesObject}
                            isEditable={isEditable}
                            isV3
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 2,
                                visibility: 'hidden',
                            }}
                            className="vat-box"
                        >
                            {data.category && data?.side === TransactionSide.DEBIT && (
                                <Vat data={data} isEditable={isEditable} />
                            )}
                        </Box>
                    </Box>
                </CollapseElement>

                <Box sx={{
                    flex: tableSize.fifthSegment,
                    pr: 1.2,
                }}
                >
                    <Box sx={{
                        color: isRejected ? 'text.tertiary' : !isDebit ? 'text.success' : 'text.secondary',
                        textAlign: 'right',
                        whiteSpace: 'nowrap',
                    }}
                    >
                        <Typography
                            component="span"
                            level="title-lg"
                            sx={{
                                color: 'inherit',
                                display: 'inline',
                            }}
                        >
                            {parseFloat(data.amount) !== 0 && (isDebit ? '-' : '+')} {numberSegments?.integer},
                        </Typography>
                        <Typography
                            component="span"
                            sx={{
                                fontWeight: 'smd',
                                fontSize: 'sm',
                                fontStyle: 'italic',
                                color: 'inherit',
                                display: 'inline',
                            }}
                        >
                            {numberSegments?.decimal} {currencyToSign(data.currency)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

AccountBalanceTableDataRow.propTypes = {
    id: PropTypes.string.isRequired,
    prevId: PropTypes.string,
    isSidebarOpened: PropTypes.bool.isRequired,
    tableSize: PropTypes.object.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
    filter: PropTypes.object.isRequired,
    onOpenClick: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    selectCategoriesObject: PropTypes.object.isRequired,
    selectSourcesObject: PropTypes.object.isRequired,
    areFieldsCollapsed: PropTypes.bool,
    t: PropTypes.func.isRequired,
    totalLoadedIds: PropTypes.number,
    combinedTabIcons: PropTypes.object,
    isUserHasInvoiceAccess: PropTypes.bool.isRequired,
};

AccountBalanceTableDataRow.defaultProps = {
    areFieldsCollapsed: false,
    prevId: '',
    totalLoadedIds: 0,
    combinedTabIcons: null,
};

export default AccountBalanceTableDataRow;
