export const SUBMIT_COACHING_INFORMATION_FORM = 'SUBMIT_COACHING_INFORMATION_FORM';
export const GET_COACHING_INFORMATION_FORM = 'GET_COACHING_INFORMATION_FORM';
export const SET_COACHING_INFORMATION_FORM = 'SET_COACHING_INFORMATION_FORM';

export const SET_IS_LOADING_SUBMIT_COACHING_INFORMATION_FORM = 'SET_IS_LOADING_SUBMIT_COACHING_INFORMATION_FORM';
export const SET_IS_LOADING_GET_COACHING_INFORMATION_FORM = 'SET_IS_LOADING_GET_COACHING_INFORMATION_FORM';

export const submitCoachingInformationForm = (form, userId) => ({
    type: SUBMIT_COACHING_INFORMATION_FORM,
    payload: {form, userId},
});

export const setIsLoadingSubmitCoachingInformationForm = isLoading => ({
    type: SET_IS_LOADING_SUBMIT_COACHING_INFORMATION_FORM,
    payload: isLoading,
});

export const getCoachingInformationForm = userId => ({
    type: GET_COACHING_INFORMATION_FORM,
    payload: {userId},
});

export const setCoachingInformationForm = coachingInformation => ({
    type: SET_COACHING_INFORMATION_FORM,
    payload: coachingInformation,
});

export const setIsLoadingGetCoachingInformationForm = isLoading => ({
    type: SET_IS_LOADING_GET_COACHING_INFORMATION_FORM,
    payload: isLoading,
});
