import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';
import {LoggedInUserAccount} from '../../../../user/modules/logged-in-user/api/logged-in-user.dto';

const putWorkshopStep = ({freelancerId}) => {
    return hiwayApiInstance
        .put(`/v1/freelancers/${freelancerId}/workshops-step`)
        .then(result => new LoggedInUserAccount(result.data));
};

export const ContractSigningApi = {
    putWorkshopStep,
};
