import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {Box, Button, FormControl, FormControlLabel, Link, Radio, RadioGroup, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';
import {InsuranceActions} from '../../store/insurance.action';
import {InsuranceSelectors} from '../../store/insurance.selector';
import {InsuranceStatus, InsuranceType} from '../../utils/constants';

const IrSubscribeModal = ({insuranceAlertOnCloseHandler, freelancerId, companyId}) => {
    const insurance = useSelector(InsuranceSelectors.selectInsurance);
    const insuranceType = insurance?.insuranceType ?? InsuranceType.RC_PC;
    const [value, setValue] = useState(insuranceType);
    const status = insurance?.status;

    const dispatch = useDispatch();
    const {t} = useTranslation('insurance');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);


    const handleChange = event => {
        if (event.target.value === InsuranceType.RC) {
            setValue(event.target.value);
        } else {
            setValue(event.target.value);
        }
    };

    const handleCloseModal = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.INSURANCE_SUBSCRIBE_MODAL, false));
        setValue(InsuranceType.RC_PC);
    };

    const handleOpenSignModal = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.INSURANCE_SUBSCRIBE_MODAL, false));
        dispatch(UiActions.setActiveModal(ModalsKeys.INSURANCE_SIGN_MODAL, true));
    };

    const insuranceTypeSelected = () => {
        dispatch(InsuranceActions.setInsurance({
            freelancerId,
            companyId,
            data: {
                status: value === InsuranceType.RC
                    ? InsuranceStatus.SIGNED
                    : status,
                insurance_type: value,
            },
        }));

        if (value !== InsuranceType.RC) {
            handleOpenSignModal();
        } else {
            if (insuranceAlertOnCloseHandler) {
                insuranceAlertOnCloseHandler();
            }
            handleCloseModal();
        }
    };

    return (
        <ModalWrapper
            modalKey={ModalsKeys.INSURANCE_SUBSCRIBE_MODAL}
            handleCloseCB={handleCloseModal}
            hasShowClose={true}
            containerSx={{width: '608px'}}
            title={(
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(34),
                    fontWeight: 700,
                    lineHeight: '116.7%',
                    pb: 1,
                }}
                >
                    {t(`subscribeModal.IR.title`)}
                </Typography>
            )}
        >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 6}}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2}}>
                    <Box sx={{
                        padding: 2,
                        alignSelf: 'stretch',
                        borderRadius: 3,
                        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #0288D1',
                    }}
                    >
                        <Typography>{t(`subscribeModal.IR.info`)}</Typography>
                    </Box>
                    <FormControl>
                        <RadioGroup value={value} onChange={handleChange} sx={{gap: 2}}>
                            <FormControlLabel
                                sx={{
                                    display: 'flex',
                                    padding: '12px 16px 12px 8px',
                                    alignItems: 'center',
                                    gap: 0.5,
                                    alignSelf: 'stretch',
                                    borderRadius: 4,
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    backgroundColor: 'whitePallete.main',
                                    m: 0,
                                }}
                                value={InsuranceType.RC_PC}
                                control={<Radio size="medium" />}
                                label={(
                                    <Box>
                                        <Typography sx={{fontWeight: 500}}>{t(`subscribeModal.IR.rcPc`)}</Typography>
                                        <Typography sx={{fontSize: theme => theme.typography.pxToRem(14)}}>
                                            {t(`subscribeModal.IR.rcPcDesc`)}
                                        </Typography>
                                    </Box>
                                )}
                            />
                            <FormControlLabel
                                sx={{
                                    display: 'flex',
                                    padding: '12px 16px 12px 8px',
                                    alignItems: 'center',
                                    gap: 0.5,
                                    alignSelf: 'stretch',
                                    borderRadius: 4,
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    backgroundColor: 'whitePallete.main',
                                    m: 0,
                                }}
                                value={InsuranceType.RC}
                                control={<Radio size="medium" />}
                                label={(
                                    <Box>
                                        <Typography sx={{fontWeight: 500}}>{t(`subscribeModal.IR.rc`)}</Typography>
                                        <Typography sx={{fontSize: theme => theme.typography.pxToRem(14)}}>
                                            {t(`subscribeModal.IR.rcDesc`)}
                                        </Typography>
                                    </Box>
                                )}
                            />
                            {value === InsuranceType.RC ? (
                                <Box sx={{
                                    display: 'flex',
                                    padding: 2,
                                    alignItems: 'center',
                                    gap: 1.5,
                                    alignSelf: 'stretch',
                                    borderRadius: 3,
                                    backgroundColor: 'v2.light.warning.light',
                                    color: 'whitePallete.main',
                                }}
                                >
                                    <WarningAmberOutlinedIcon color="inherit" />
                                    <Typography color="inherit" sx={{fontSize: theme => theme.typography.pxToRem(14)}}>
                                        <Trans
                                            i18nKey={t('subscribeModal.IR.warning')}
                                            components={{
                                                anchor: <Link
                                                    underline="hover"
                                                    color="inherit"
                                                    sx={{fontWeight: 600}}
                                                    rel="noopener noreferrer"
                                                    href="mailto:paie@hiway.fr"
                                                        />,
                                            }}
                                        />
                                    </Typography>
                                </Box>
                            ) : null}
                            <FormControlLabel
                                sx={{
                                    display: 'flex',
                                    padding: '12px 16px 12px 8px',
                                    alignItems: 'center',
                                    gap: 0.5,
                                    alignSelf: 'stretch',
                                    borderRadius: 4,
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    backgroundColor: 'whitePallete.main',
                                    m: 0,
                                }}
                                value={InsuranceType.PC}
                                control={<Radio size="medium" />}
                                label={(
                                    <Box>
                                        <Typography sx={{fontWeight: 500}}>{t(`subscribeModal.IR.pc`)}</Typography>
                                        <Typography sx={{fontSize: theme => theme.typography.pxToRem(14)}}>
                                            {t(`subscribeModal.IR.pcDesc`)}
                                        </Typography>
                                    </Box>
                                )}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: isMobileSize ? 'column' : 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: isMobileSize ? 2 : 3,
                    flex: '1 0 0',
                    alignSelf: 'stretch',
                }}
                >
                    <Button
                        fullWidth={isMobileSize}
                        variant="outlined"
                        startIcon={<ArrowBackIcon />}
                        onClick={handleCloseModal}
                    >
                        {t('subscribeModal.IR.back')}
                    </Button>
                    <Button
                        fullWidth={isMobileSize}
                        variant="contained"
                        color="secondary"
                        disabled={!value}
                        endIcon={<ArrowForwardIcon />}
                        onClick={insuranceTypeSelected}
                    >
                        {t('subscribeModal.confirm')}
                    </Button>
                </Box>
            </Box>
        </ModalWrapper>
    );
};

IrSubscribeModal.propTypes = {
    insuranceAlertOnCloseHandler: PropTypes.func,
    freelancerId: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
};

IrSubscribeModal.defaultProps = {
    insuranceAlertOnCloseHandler: null,
};

export default IrSubscribeModal;
