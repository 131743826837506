import {InvoiceActionType} from './invoice.action-type';

// TODO Split services/clients/invoice into separate files
export const InvoiceActions = {
    setInvoiceSidebar: data => ({
        type: InvoiceActionType.SET_INVOICE_SIDEBAR,
        payload: data,
    }),
    setInvoiceSidebarData: data => ({
        type: InvoiceActionType.SET_INVOICE_SIDEBAR_DATA,
        payload: data,
    }),

    storeInvoiceList: list => ({
        type: InvoiceActionType.STORE_INVOICE,
        payload: list,
    }),
    deleteInvoice: data => ({
        type: InvoiceActionType.DELETE_INVOICE,
        payload: data,
    }),
    createExternalInvoice: data => ({
        type: InvoiceActionType.CREATE_EXTERNAL_INVOICE,
        payload: data,
    }),
    deleteExternalInvoice: data => ({
        type: InvoiceActionType.DELETE_EXTERNAL_INVOICE,
        payload: data,
    }),
    getInvoiceStats: () => ({
        type: InvoiceActionType.GET_INVOICE_STATS,
    }),
    storeInvoiceStats: data => ({
        type: InvoiceActionType.STORE_INVOICE_STATS,
        payload: data,
    }),
    finalizeInvoice: data => ({
        type: InvoiceActionType.FINALIZE_INVOICE,
        payload: data,
    }),
    saveInvoiceDraft: data => ({
        type: InvoiceActionType.SAVE_INVOICE_DRAFT,
        payload: data,
    }),
    deleteClientFile: data => ({
        type: InvoiceActionType.DELETE_CLIENT_FILE,
        payload: data,
    }),
    getInvoiceDraft: data => ({
        type: InvoiceActionType.GET_INVOICE_DRAFT,
        payload: data,
    }),
    storeInvoiceDraft: data => ({
        type: InvoiceActionType.STORE_INVOICE_DRAFT,
        payload: data,
    }),
    storeInvoiceDraftError: data => ({
        type: InvoiceActionType.STORE_INVOICE_DRAFT_ERROR,
        payload: data,
    }),
    getInvoicePreview: data => ({
        type: InvoiceActionType.GET_INVOICE_PREVIEW,
        payload: data,
    }),
    storeInvoicePreview: data => ({
        type: InvoiceActionType.STORE_INVOICE_PREVIEW,
        payload: data,
    }),
    storePreviewFileUrl: url => ({
        type: InvoiceActionType.STORE_INVOICE_PREVIEW_FILE_URL,
        payload: url,
    }),
    // TODO Rename this entire system to store invoice action result
    // Beacuse of this TODO I've created the storeInvoiceResult below instead if using this action
    storeFinalizationResult: data => ({
        type: InvoiceActionType.STORE_FINALIZATION_RESULT,
        payload: data,
    }),
    uploadInvoiceAnnexFile: data => ({
        type: InvoiceActionType.UPLOAD_ANNEX_FILE,
        payload: data,
    }),
    deleteAnnexFile: data => ({
        type: InvoiceActionType.DELETE_ANNEX_FILE,
        payload: data,
    }),
    deleteAllAnnexFiles: data => ({
        type: InvoiceActionType.DELETE_ALL_ANNEX_FILES,
        payload: data,
    }),
    getLastInternalInvoice: () => ({
        type: InvoiceActionType.GET_LAST_INTERNAL_INVOICE,
    }),
    storeLastInternalInvoice: data => ({
        type: InvoiceActionType.STORE_LAST_INTERNAL_INVOICE,
        payload: data,
    }),
    importLastInvoice: data => ({
        type: InvoiceActionType.IMPORT_INVOICE,
        payload: data,
    }),
    getLastExternalInvoices: () => ({
        type: InvoiceActionType.GET_LAST_EXTERNAL_INVOICES,
    }),
    storeLastExternalInvoice: data => ({
        type: InvoiceActionType.STORE_LAST_EXTERNAL_INVOICES,
        payload: data,
    }),

    getInvoiceClients: () => ({
        type: InvoiceActionType.GET_CLIENTS,
    }),
    storeInvoiceClients: data => ({
        type: InvoiceActionType.STORE_CLIENTS,
        payload: data,
    }),
    saveInvoiceClient: data => ({
        type: InvoiceActionType.SAVE_CLIENT,
        payload: data,
    }),
    setClientIdToEdit: data => ({
        type: InvoiceActionType.STORE_CLIENT_ID_TO_EDIT,
        payload: data,
    }),
    updateInvoiceClient: data => ({
        type: InvoiceActionType.UPDATE_CLIENT,
        payload: data,
    }),
    saveClientInInvoice: data => ({
        type: InvoiceActionType.SAVE_CLIENT_IN_INVOICE,
        payload: data,
    }),
    uploadClientFile: data => ({
        type: InvoiceActionType.UPLOAD_CLIENT_FILE,
        payload: data,
    }),
    updateFileUploadLoader: params => ({
        type: InvoiceActionType.UPLOAD_CLIENT_FILE_LOADER,
        payload: params,
    }),
    storeInvoiceClientEditDraft: data => ({
        type: InvoiceActionType.STORE_CLIENT_EDIT_DRAFT,
        payload: data,
    }),
    getClientDetails: id => ({
        type: InvoiceActionType.GET_CLIENT,
        payload: id,
    }),
    deleteClient: data => ({
        type: InvoiceActionType.DELETE_CLIENT,
        payload: data,
    }),
    getClientSuggestions: text => ({
        type: InvoiceActionType.GET_CLIENT_SUGGESTIONS,
        payload: text,
    }),
    storeClientSuggestions: data => ({
        type: InvoiceActionType.STORE_CLIENT_SUGGESTIONS,
        payload: data,
    }),
    getInvoiceServices: () => ({
        type: InvoiceActionType.GET_SERVICES,
    }),
    storeInvoiceServices: data => ({
        type: InvoiceActionType.STORE_SERVICES,
        payload: data,
    }),
    saveInvoiceServices: data => ({
        type: InvoiceActionType.SAVE_SERVICES,
        payload: data,
    }),
    storeInvoiceServiceEditDraft: data => ({
        type: InvoiceActionType.STORE_SERVICE_EDIT_DRAFT,
        payload: data,
    }),
    getServiceDetails: data => ({
        type: InvoiceActionType.GET_SERVICE_DETAILS,
        payload: data,
    }),
    saveServiceInInvoice: data => ({
        type: InvoiceActionType.SAVE_SERVICE_IN_INVOICE,
        payload: data,
    }),
    updateInvoiceService: data => ({
        type: InvoiceActionType.UPDATE_SERVICE,
        payload: data,
    }),
    sendReminder: data => ({
        type: InvoiceActionType.SEND_REMINDER,
        payload: data,
    }),
    sendInvoice: data => ({
        type: InvoiceActionType.SEND_INVOICE,
        payload: data,
    }),
    reCreateInvoice: data => ({
        type: InvoiceActionType.RE_CREATE_INVOICE,
        payload: data,
    }),
    deleteService: data => ({
        type: InvoiceActionType.DELETE_SERVICE,
        payload: data,
    }),
    storeInvoiceHeaderData: data => ({
        type: InvoiceActionType.STORE_INVOICE_HEADER_DATA,
        payload: data,
    }),
    storeInvoiceResult: data => ({
        type: InvoiceActionType.STORE_INVOICE_RESULT,
        payload: data,
    }),
    getNextCreditNoteDocNumber: data => ({
        type: InvoiceActionType.GET_NEXT_CREDIT_NOTE_DOC_NUMBER,
        payload: data,
    }),
    storeNextCreditNoteDocNumber: data => ({
        type: InvoiceActionType.STORE_NEXT_CREDIT_NOTE_DOC_NUMBER,
        payload: data,
    }),
    storeInvoiceRemindData: data => ({
        type: InvoiceActionType.STORE_INVOICE_REMIND_DATA,
        payload: data,
    }),
    storeInvoiceUrl: data => ({
        type: InvoiceActionType.STORE_INVOICE_URL,
        payload: data,
    }),
    setShowCreateForm: data => ({
        type: InvoiceActionType.SET_SHOW_CREATE_FORM,
        payload: data,
    }),
    setImportData: data => ({
        type: InvoiceActionType.IMPORT_INVOICE_RESULT,
        payload: data,
    }),
    getAriaInfo: data => ({
        type: InvoiceActionType.GET_ARIA_INFO,
        payload: data,
    }),
    activateAria: data => ({
        type: InvoiceActionType.ACTIVATE_ARIA,
        payload: data,
    }),
    setShowAriaActivationPage: data => ({
        type: InvoiceActionType.SHOW_ARIA_ACTIVATION_PAGE,
        payload: data,
    }),
    setShowAriaParameters: data => ({
        type: InvoiceActionType.SHOW_ARIA_PARAMETERS,
        payload: data,
    }),
    storeAriaStatus: data => ({
        type: InvoiceActionType.STORE_ARIA_STATUS,
        payload: data,
    }),
    getUserPersonalDocument: data => ({
        type: InvoiceActionType.GET_USER_PERSONAL_DOCUMENT,
        payload: data,
    }),
    getAdminInvoice: data => ({
        type: InvoiceActionType.GET_ADMIN_INVOICE,
        payload: data,
    }),
};
