import {Box, Grow, Paper, Slide, Typography, Zoom} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {DossierDate} from './DossierDate';
import {DossierDocumentDownloadButtons} from './DossierDocumentDownloadButtons';
import {DossierDocuments} from './DossierDocuments';
import {AnimationActions} from '../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../animations/utils/constants';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {TrainingActions} from '../../store/training.action';
import {TRAINING_CONSTANTS} from '../../utils/constants';
import MyDocumentsAlert from '../general/MyDocumentsAlert';

export const DossierDocumentDownload = () => {
    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);

    const {t} = useTranslation('training');
    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.TRAINING_STEP_SUBMIT),
    );

    const isDownloading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.TRAINING_ZIP_DOWNLOAD),
    );

    const onButtonClick = () => {
        dispatch(TrainingActions.submitStep(TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_DOWNLOAD));
    };

    const onDownloadClick = () => {
        dispatch(TrainingActions.downloadZip(TRAINING_CONSTANTS.ZIP_VARIANTS.TRAINING_STEP_3));
    };

    const onCheckboxClick = e => {
        const checked = e.target.checked;
        setIsChecked(checked);
    };

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    return (
        <Box
            sx={{
                px: {
                    xs: 2,
                    md: 0,
                },
            }}
        >
            <Paper sx={
                isMobileSize
                    ? {px: 2, pt: 2, pb: 3, borderRadius: '8px'}
                    : {px: 5, pt: 4, pb: 6, borderRadius: '8px'}
            }
            >
                <Slide in={isAnimationActive} direction="left" onExited={onEndedAnimation}>
                    <Box sx={{mb: 3}}>
                        <Typography
                            sx={{
                                fontSize: theme => theme.typography.pxToRem(16),
                                fontWeight: 600,
                            }}
                        >
                            {t('dossier.documentGeneration.header')}
                        </Typography>
                    </Box>
                </Slide>

                <Zoom in={isAnimationActive} timeout={350}>
                    <Box>
                        <DossierDate
                            hasResetOption={true}
                        />
                    </Box>
                </Zoom>

                <Grow in={isAnimationActive}>
                    <Box
                        sx={TRAINING_CONSTANTS.TABLE_STYLE_WRAPPER}
                    >
                        <DossierDocuments hasActions={true} isMobileSize={isMobileSize} />
                    </Box>
                </Grow>

                <Slide in={isAnimationActive} direction="left">
                    <Box>
                        <MyDocumentsAlert />
                    </Box>
                </Slide>
            </Paper>

            <Slide in={isAnimationActive} direction="left" timeout={500}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                    flexDirection: isMobileSize ? 'column' : 'row',
                }}
                >
                    <DossierDocumentDownloadButtons
                        isChecked={isChecked}
                        onCheckboxClick={onCheckboxClick}
                        onDownloadClick={onDownloadClick}
                        onButtonClick={onButtonClick}
                        isLoading={isLoading}
                        isDownloading={isDownloading}
                    />
                </Box>
            </Slide>
        </Box>
    );
};
