import makeStyles from '@mui/styles/makeStyles';

export const useCheckboxStyles = makeStyles(() => ({
    icon: {
        width: '18px',
        height: '18px',
        margin: '3px',
        border: '1px solid rgba(11, 35, 51, 0.3)',
        borderRadius: '3px',
        flexShrink: 0,
        display: 'inline-block',
    },
}));
