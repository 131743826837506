import {Box, Drawer, Stack, Typography} from '@mui/joy';
import {Box as MuiBox, Drawer as MuiDrawer} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import CloseButton from '../buttons/CloseButton';
import CloseDrawerButton from '../buttons/CloseDrawerButton';

// TODO: Implement this component in all possible places instead of Drawer

const DrawerWrapper = ({
    isOpen,
    drawerSx,
    containerSx,
    onClose,
    children,
    isV3,
    title,
    text,
    size,
}) => {
    if (isV3) {
        return (
            <Drawer
                anchor="bottom"
                size={size}
                open={isOpen}
                onClose={onClose}
                sx={{
                    '.MuiDrawer-content': {
                        borderTopRightRadius: '32px',
                        borderTopLeftRadius: '32px',
                    },
                    ...drawerSx,
                }}
            >
                <Box sx={{
                    minHeight: '300px',
                    maxHeight: 'calc(100vh - 100px)',
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                    pt: 4,
                    px: 3,
                    ...containerSx,
                }}
                >
                    {(!!title || !!text || onClose) && (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3,
                            justifyContent: 'space-between',
                            mb: 3,
                        }}
                        >
                            {!!title && (
                                <Typography level="h1" sx={{pr: 4}}>
                                    {title}
                                </Typography>
                            )}
                            {!!text && (
                                <Typography level="body-md" sx={{color: 'text.secondary'}}>
                                    {text}
                                </Typography>
                            )}
                            {onClose && (
                                <CloseButton
                                    onClose={onClose}
                                    buttonStyles={{
                                        position: 'absolute',
                                        top: '16px',
                                        right: '16px',
                                        zIndex: 1,
                                    }}
                                    isV3
                                />
                            )}
                        </Box>
                    )}

                    <Stack
                        direction="column"
                        gap={2}
                        sx={{
                            pb: 4,
                        }}
                    >
                        {children}
                    </Stack>
                </Box>
            </Drawer>
        );
    }

    return (
        <MuiDrawer
            anchor="bottom"
            open={isOpen}
            onClose={onClose}
            sx={{
                '.MuiDrawer-paperAnchorBottom': {
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                },
            }}
        >
            <MuiBox sx={{
                position: 'relative',
                minHeight: '300px',
                display: 'flex',
                flexDirection: 'column',
                px: 3,
                pt: 5,
                pb: 3,
                ...containerSx,
            }}
            >
                {onClose && (
                    <CloseDrawerButton onClose={onClose} />
                )}
                {children}
            </MuiBox>
        </MuiDrawer>
    );
};

DrawerWrapper.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    drawerSx: PropTypes.object,
    containerSx: PropTypes.object,
    onClose: PropTypes.func,
    isV3: PropTypes.bool, // TODO: make true by default and delete the code, related to false value
    title: PropTypes.string,
    text: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

DrawerWrapper.defaultProps = {
    drawerSx: {},
    containerSx: {},
    onClose: null,
    isV3: false,
    title: '',
    text: '',
    size: 'lg',
};

export default DrawerWrapper;
