import {Autocomplete, Box, Chip, Grid, TextField, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {importLocaleBundle} from '../../../../lib/i18next';
import {OpportunitySelector} from '../../store/opportunity.selector';

importLocaleBundle('create-user');
importLocaleBundle('jobProfessions');

export const OpportunityMission = ({control, errors, defaultFieldProps, selectedProfile, setValue, getValues}) => {
    const {t: clusterT} = useTranslation('opportunity');
    const {t: userT} = useTranslation('createUser');
    const {t: jobT} = useTranslation('jobProfessions');

    const specialisations = useSelector(OpportunitySelector.getSpecialisations);

    const availableSpecisalisations = useMemo(() => {
        const availableSpecisalisations = [];
        if (selectedProfile?.length > 0) {
            selectedProfile.forEach(profile => {
                if (profile?.job_specializations?.length > 0) {
                    const filteredSpecialisationList = profile.job_specializations.filter(
                        specialisation => availableSpecisalisations.indexOf(specialisation) === -1,
                    );
                    availableSpecisalisations.push(...filteredSpecialisationList);
                }
            });
        }
        return availableSpecisalisations;
    }, [selectedProfile]);

    useEffect(() => {
        if (availableSpecisalisations.length === 0) {
            setValue('specialisations', []);
        }
    }, [setValue, availableSpecisalisations]);

    return (
        <Box sx={{mb: 4}}>
            <Typography sx={{
                mb: 1.5,
                fontSize: theme => theme.typography.pxToRem(16),
                fontWeight: 600,
            }}
            >
                {clusterT('submitOpportunity.missionHeader')}
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                    <Controller
                        control={control}
                        name="clientName"
                        render={({field}) => (
                            <TextField
                                label={clusterT('submitOpportunity.clientName') + '*'}
                                error={!!errors.clientName}
                                helperText={errors.clientName?.message}
                                {...field}
                                {...defaultFieldProps}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Controller
                        control={control}
                        name="requiredProfile"
                        render={({field}) => (
                            <Autocomplete
                                multiple
                                value={field.value}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);

                                    // On item remove
                                    if (field.value.length > newValue.length) {
                                        const difference = field.value.filter(item => !newValue.includes(item));
                                        // If removed item has specialisations, update specialisations field
                                        if (difference?.[0]?.job_specializations.length > 0) {
                                            const selectedSpecializations = getValues('specialisations');
                                            let newValueSpecializations = [];
                                            newValue.forEach(item => {
                                                newValueSpecializations = newValueSpecializations
                                                    .concat(item.job_specializations);
                                            });
                                            const remainingSpecializations = selectedSpecializations
                                                .filter(item => newValueSpecializations.includes(item));
                                            // Timeout is needed for validation
                                            // RequiredProfile needs to change first
                                            setTimeout(() => {
                                                setValue('specialisations', remainingSpecializations, {shouldValidate: true, shouldDirty: true});
                                            }, 50);
                                        }
                                    }
                                }}
                                options={specialisations}
                                getOptionLabel={option => {
                                    return userT(`jobTypes.${option?.job_type}`);
                                }}
                                renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                                    <Chip
                                        key={index}
                                        label={userT(`jobTypes.${option?.job_type}`)}
                                        {...getTagProps({index})}
                                    />
                                ))
                                }
                                renderInput={params => (
                                    <TextField
                                        fullWidth
                                        label={clusterT('submitOpportunity.requiredProfile') + '*'}
                                        error={!!errors.requiredProfile}
                                        helperText={errors.requiredProfile?.message}
                                        {...params}
                                        {...defaultFieldProps}
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} lg={4}>
                    <Controller
                        control={control}
                        name="specialisations"
                        render={({field}) => (
                            <Autocomplete
                                noOptionsText={clusterT('submitOpportunity.selectedJobTypesNoSpecialisation')}
                                multiple
                                value={field.value}
                                onChange={(event, newValue) => {
                                    field.onChange(newValue);
                                }}
                                options={availableSpecisalisations}
                                getOptionLabel={option => {
                                    return jobT(`specializations.${option}`);
                                }}
                                renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                                    <Chip
                                        key={index}
                                        label={jobT(`specializations.${option}`)}
                                        {...getTagProps({index})}
                                    />
                                ))
                                }
                                renderInput={params => (
                                    <TextField
                                        label={
                                            clusterT('submitOpportunity.specialisations')
                                            + (availableSpecisalisations.length !== 0 ? '*' : '')
                                        }
                                        error={!!errors.specialisations}
                                        helperText={errors.specialisations?.message}
                                        {...params}
                                        {...defaultFieldProps}
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

OpportunityMission.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    defaultFieldProps: PropTypes.object.isRequired,
    selectedProfile: PropTypes.array,
    setValue: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
};

OpportunityMission.defaultProps = {
    selectedProfile: [],
};
