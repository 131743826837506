import DownloadIcon from '@mui/icons-material/Download';
import {LoadingButton} from '@mui/lab';
import {useMediaQuery} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {selectIsDownloadInProgress} from '../../../../../v1/app/expenses/adminExpenses/adminExpenses.selectors';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {BankSelector} from '../../../store/bank.selector';
import {getIntegrationInfo} from '../../bridge-api/utils/bridge-util';
import {RibAndDocumentsActions} from '../store/ribAndDocuments.actions';
import {RibAndDocumentsSelector} from '../store/ribAndDocuments.selector';
import {getActiveHiwayIntegration} from '../utils/ribAndDocuments.util';

const DownloadAllAsZipButton = () => {
    const {t} = useTranslation('bankRibAndDocuments');
    const dispatch = useDispatch();

    const integrations = useSelector(BankSelector.selectIntegrations);
    const isDownloadInProgress = useSelector(selectIsDownloadInProgress);
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.DOWNLOAD_ALL_STATEMENTS_AS_ZIP),
    );

    const hiwayIntegration = getActiveHiwayIntegration(integrations);
    const {account, bankAccountHolder} = getIntegrationInfo(hiwayIntegration);
    const bankAccountHolderId = bankAccountHolder?.id;
    const bankAccountId = account?.id;

    const bankAccountStatements = useSelector(RibAndDocumentsSelector.selectBankAccountStatements);
    const noBankAccountStatements = !bankAccountStatements?.count;

    const downloadAllAsZip = () => {
        dispatch(RibAndDocumentsActions.downloadAllStatementsAsZip({bankAccountId, bankAccountHolderId}));
    };

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <LoadingButton
            variant="contained"
            color="primary"
            disabled={isDownloadInProgress || noBankAccountStatements}
            onClick={downloadAllAsZip}
            startIcon={<DownloadIcon />}
            loading={!!isLoading}
            loadingPosition="start"
            sx={{
                bgcolor: 'primary_v2.dark',
                mt: isMobileSize ? 2 : 0,
                width: isMobileSize ? '100%' : 'auto',
            }}
        >
            {t('accountStatementsTable.downloadAsZip')}
        </LoadingButton>
    );
};

export default DownloadAllAsZipButton;
