import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import {LoadingButton} from '@mui/lab';
import {
    Alert,
    Box,
    FormControl,
    RadioGroup,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../../../../../components/modal-wrapper/ModalWrapper';
import RadioAccordion from '../../../../../../../../components/radio/RadioAccordion';
import {LoadingTypes, useLoadingState} from '../../../../../../../loading';
import {UiSelectors} from '../../../../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../../../../ui/utils/constants';
import {TransactionsActions} from '../../../../store/transactions.action';
import {CATEGORY_MAGIC_SUBSCRIPTION_TYPE} from '../../../../util/constants';

export const deactivateSubscriptionModalType = {
    DEACTIVATE: 'DEACTIVATE',
    CHANGE_CATEGORY: 'CHANGE_CATEGORY',
};

const modalKey = ModalsKeys.CATEGORIZATION_DEACTIVATE_SUBSCRIPTION;

const DeactivateSubscriptionModal = () => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const dispatch = useDispatch();

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));

    const isInProgress = useLoadingState(LoadingTypes.BANK_ARTICLES);

    const [radioValue, setRadioValue] = useState(null);

    const handleConfirm = radioValue => {
        if (data?.type === deactivateSubscriptionModalType.CHANGE_CATEGORY) {
            dispatch({
                type: data?.action.type,
                payload: {
                    ...data?.action.payload,
                    type: radioValue,
                },
            });

            if (data?.setIsShowAllCategories) {
                data?.setIsShowAllCategories(false);
            }
        } else {
            dispatch(TransactionsActions.deactivateSubscription({
                id: data?.id,
                accountId: data?.accountId,
                type: radioValue,
            }));
        }
    };

    const isChangeCategory = data?.type === deactivateSubscriptionModalType.CHANGE_CATEGORY;

    return (
        <ModalWrapper
            handleCloseCB={() => {
                if (data?.setIsSubscribedChecked) {
                    data.setIsSubscribedChecked(true);
                }
            }}
            modalKey={modalKey}
            title={(
                <Typography variant="h5">
                    {!isChangeCategory
                        ? t('categorisation.subscriptionDeactivate.title')
                        : t('categorisation.subscriptionDeactivate.titleChangeCategory')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        disableElevation
                        fullWidth={isMobileSize}
                        startIcon={<ArrowBackIcon />}
                        onClick={() => {
                            if (data?.setIsSubscribedChecked) {
                                data.setIsSubscribedChecked(true);
                            }
                            handleClose();
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        {t('categorisation.subscriptionDeactivate.cancel')}
                    </Button>

                    <LoadingButton
                        loading={isInProgress}
                        fullWidth={isMobileSize}
                        startIcon={isChangeCategory ? <CheckIcon /> : <CancelIcon />}
                        onClick={() => handleConfirm(radioValue)}
                        disabled={!radioValue}
                        color={isChangeCategory ? 'primary' : 'secondary'}
                        variant="contained"
                    >
                        {!isChangeCategory
                            ? t('categorisation.subscriptionDeactivate.confirm')
                            : t('categorisation.subscriptionDeactivate.confirmChangeCategory')}
                    </LoadingButton>
                </>
            )}
        >
            <Box>
                <FormControl>
                    <Alert
                        sx={{
                            '.MuiAlert-icon': {
                                display: 'flex',
                                alignItems: 'center',
                            },
                        }}
                        icon={(
                            <WarningIcon sx={{
                                color: 'black',
                                opacity: '0.8',
                            }}
                            />
                        )}
                        severity="info"
                    >
                        {t('categorisation.subscriptionDeactivate.formInfo')}
                    </Alert>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={radioValue}
                        onChange={(_, value) => setRadioValue(value)}
                    >
                        <RadioAccordion
                            label={t('categorisation.subscriptionDeactivate.label1')}
                            text={t('categorisation.subscriptionDeactivate.text1')}
                            value={CATEGORY_MAGIC_SUBSCRIPTION_TYPE.ONLY_THIS}
                        />
                        <RadioAccordion
                            label={t('categorisation.subscriptionDeactivate.label2')}
                            text={t('categorisation.subscriptionDeactivate.text2')}
                            value={CATEGORY_MAGIC_SUBSCRIPTION_TYPE.THIS_AND_FUTURE}
                        />
                        <RadioAccordion
                            label={t('categorisation.subscriptionDeactivate.label3')}
                            text={t('categorisation.subscriptionDeactivate.text3')}
                            value={CATEGORY_MAGIC_SUBSCRIPTION_TYPE.ALL}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
        </ModalWrapper>
    );
};

export default DeactivateSubscriptionModal;
