import ArrowBack from '@mui/icons-material/ArrowBack';
import {Box, Paper, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import BankCardDrawer from './BankCardDrawer';
import {BankCardLimitSection} from './BankCardLimitSection';
import {BankCardMobileDetails} from './BankCardMobileDetails';
import BankCardModal from './BankCardModal';
import {BankSidebarContent} from './BankCardSidebarContent';
import BankCardSwitcher from './BankCardSwitcher';
import BankCardSwitcherMobile from './BankCardSwitcherMobile';
import BankCardUpdate from './BankCardUpdate';
import DeleteConfirmCard from './DeleteConfirmCard';
import ExpandableSidebar from '../../../../../../components/expandable-sidebar/ExpandableSidebar';
import {PageHeader} from '../../../../../../components/page-header/PageHeader';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../../user/modules/logged-in-user';
import {CardsActions} from '../../store/cards.actions';
import {CARD_TYPES, CardPhysicalStatus, MODAL_TYPES} from '../../util/constants';
import {
    BankCardsLoadingSkeletonMain,
    BankCardsLoadingSkeletonMobile,
    BankCardsLoadingSkeletonSidebar,
} from '../BankLoadingSkeleton';

const BankCardsDetails = ({cardList, t, isLoading}) => {
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isCardLockLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.LOCK_CARD),
    );

    const [selectedCard, setSelectedCard] = useState(CARD_TYPES.PHYS);
    const [isSettingsOpen, setOpenSettings] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [drawerType, setDrawerType] = useState('');

    const card = cardList?.[0];

    const showNumbers = isPhysical => {
        dispatch(CardsActions.showNumbers(card?.id, isPhysical));
    };

    const viewPin = () => {
        if (!isMobileSize) {
            // Open modal
            dispatch(UiActions.setModalData(ModalsKeys.BANK_CARD_MODAL, {
                type: MODAL_TYPES.VIEW_PIN,
            }));
            dispatch(UiActions.setActiveModal(ModalsKeys.BANK_CARD_MODAL, true));
        } else {
            setIsDrawerOpen(true);
            setDrawerType(MODAL_TYPES.VIEW_PIN);
        }
    };

    const activateCardModal = () => {
        if (!isMobileSize) {
            // Open modal
            dispatch(UiActions.setModalData(ModalsKeys.BANK_CARD_MODAL, {
                type: MODAL_TYPES.ACTIVATE_CARD,
            }));
            dispatch(UiActions.setActiveModal(ModalsKeys.BANK_CARD_MODAL, true));
        } else {
            setIsDrawerOpen(true);
            setDrawerType(MODAL_TYPES.ACTIVATE_CARD);
        }
    };

    const openPhysModal = () => {
        if (!isMobileSize) {
            // Open modal
            dispatch(UiActions.setModalData(ModalsKeys.BANK_CARD_MODAL, {
                type: MODAL_TYPES.PHYS_INFO,
            }));
            dispatch(UiActions.setActiveModal(ModalsKeys.BANK_CARD_MODAL, true));
        } else {
            setIsDrawerOpen(true);
            setDrawerType(MODAL_TYPES.PHYS_INFO);
        }
    };

    const openVirtualModal = () => {
        if (!isMobileSize) {
            // Open modal
            dispatch(UiActions.setModalData(ModalsKeys.BANK_CARD_MODAL, {
                type: MODAL_TYPES.VIRTUAL_INFO,
            }));
            dispatch(UiActions.setActiveModal(ModalsKeys.BANK_CARD_MODAL, true));
        } else {
            setIsDrawerOpen(true);
            setDrawerType(MODAL_TYPES.VIRTUAL_INFO);
        }
    };

    const unlockBlockedModal = () => {
        if (!isMobileSize) {
            // Open modal
            dispatch(UiActions.setModalData(ModalsKeys.BANK_CARD_MODAL, {
                type: MODAL_TYPES.UNLOCK_BLOCKED,
            }));
            dispatch(UiActions.setActiveModal(ModalsKeys.BANK_CARD_MODAL, true));
        } else {
            setIsDrawerOpen(true);
            setDrawerType(MODAL_TYPES.UNLOCK_BLOCKED);
        }
    };

    const lockCard = () => {
        dispatch(CardsActions.lockCard(card?.id));
    };

    const unlockCard = () => {
        dispatch(CardsActions.unlockCard(card?.id));
    };

    const deleteCard = () => {
        dispatch(CardsActions.delete(card?.id));
    };

    if (isDeleteOpen) {
        return (
            <DeleteConfirmCard
                t={t}
                onDelete={deleteCard}
                isMobileSize={isMobileSize}
            />
        );
    }

    const hasReversedCards = card?.physicalCard?.status !== CardPhysicalStatus.TO_ACTIVATE
        && card?.physicalCard?.status !== CardPhysicalStatus.PROCESSING;

    if (isMobileSize) {
        if (isLoading) {
            return (<BankCardsLoadingSkeletonMobile />);
        }

        if (isSettingsOpen) {
            return (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2.5,
                            p: '30px',
                            cursor: 'pointer',
                            fontSize: theme => theme.typography.pxToRem(22),
                        }}
                        onClick={() => { setOpenSettings(false); }}
                    >
                        <ArrowBack /> {t('cardDetails.back')}
                    </Box>
                    <Paper
                        elevation={0}
                        sx={{
                            borderRadius: '16px',
                            mx: 1,
                            pt: 3,
                            pb: 2,
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        >
                            <Typography sx={{
                                px: 3,
                                mb: 3,
                                fontWeight: 700,
                                fontSize: theme => theme.typography.pxToRem(21),
                                textAlign: 'center',
                            }}
                            >
                                {t('cardDetails.params')}
                            </Typography>

                            <BankCardUpdate
                                t={t}
                                card={card}
                                onCancel={() => setOpenSettings(false)}
                                onDeleteOpen={() => setIsDeleteOpen(true)}
                            />
                        </Box>
                    </Paper>
                </Box>
            );
        }

        return (
            <Box>

                <BankCardLimitSection
                    t={t}
                    card={card}
                    setOpenSettings={setOpenSettings}
                />

                <Paper
                    elevation={0}
                    sx={{
                        height: '100vh',
                    }}
                >
                    <BankCardSwitcherMobile
                        selectedCard={selectedCard}
                        setSelectedCard={setSelectedCard}
                        isActive={hasReversedCards}
                        openPhysModal={openPhysModal}
                        user={user}
                        card={card}
                        activateCardModal={activateCardModal}
                        unlockBlockedModal={unlockBlockedModal}
                        unlockCard={unlockCard}
                        openVirtualModal={openVirtualModal}
                    />

                    <BankCardMobileDetails
                        card={card}
                        selectedCard={selectedCard}
                        setOpenSettings={setOpenSettings}
                        t={t}
                        lockCard={lockCard}
                        isCardLockLoading={isCardLockLoading}
                        unlockCard={unlockCard}
                        showNumbers={showNumbers}
                        viewPin={viewPin}
                    />

                </Paper>

                <BankCardDrawer
                    t={t}
                    isOpen={isDrawerOpen}
                    setIsOpen={setIsDrawerOpen}
                    card={card}
                    drawerType={drawerType}
                />
            </Box>
        );
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
        }}
        >
            <Box
                sx={{
                    width: `calc(100% - 401px)`,
                }}
            >
                <PageHeader>{t('Cards')}</PageHeader>

                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '90vh',
                }}
                >
                    {isLoading && (
                        <BankCardsLoadingSkeletonMain />
                    )}
                    {!isLoading && (
                        <BankCardSwitcher
                            selectedCard={selectedCard}
                            setSelectedCard={setSelectedCard}
                            hasReversedCards={hasReversedCards}
                            openPhysModal={openPhysModal}
                            user={user}
                            card={card}
                            activateCardModal={activateCardModal}
                            unlockBlockedModal={unlockBlockedModal}
                            unlockCard={unlockCard}
                            openVirtualModal={openVirtualModal}
                        />
                    )}
                </Box>
            </Box>

            <ExpandableSidebar
                isOpen={true}
                hasClose={false}
                onClose={() => {}}
                isMobileSize={isMobileSize}
                mt={-3}
                width="332px"
            >
                {isLoading && (
                    <BankCardsLoadingSkeletonSidebar />
                )}
                {isSettingsOpen && !isLoading && (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2.5,
                                p: '30px',
                                cursor: 'pointer',
                                fontSize: theme => theme.typography.pxToRem(22),
                            }}
                            onClick={() => { setOpenSettings(false); }}
                        >
                            <ArrowBack /> {t('cardDetails.back')}
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        >
                            <Typography sx={{
                                px: 3,
                                mb: 3,
                                fontWeight: 700,
                                fontSize: theme => theme.typography.pxToRem(21),
                            }}
                            >
                                {t('cardDetails.params')}
                            </Typography>

                            <BankCardUpdate
                                t={t}
                                card={card}
                                onCancel={() => setOpenSettings(false)}
                                onDeleteOpen={() => setIsDeleteOpen(true)}
                            />
                        </Box>
                    </Box>
                )}
                {!isSettingsOpen && !isLoading && (
                    <BankSidebarContent
                        card={card}
                        selectedCard={selectedCard}
                        setOpenSettings={setOpenSettings}
                        t={t}
                        lockCard={lockCard}
                        isCardLockLoading={isCardLockLoading}
                        unlockCard={unlockCard}
                        showNumbers={showNumbers}
                        viewPin={viewPin}
                    />
                )
                }
            </ExpandableSidebar>

            <BankCardModal card={card} />
        </Box>
    );
};

BankCardsDetails.propTypes = {
    t: PropTypes.func.isRequired,
    cardList: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default BankCardsDetails;
