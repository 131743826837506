import {combineReducers} from 'redux';
import {
    SET_COACHING_INFORMATION_FORM,
    SET_IS_LOADING_GET_COACHING_INFORMATION_FORM,
    SET_IS_LOADING_SUBMIT_COACHING_INFORMATION_FORM,
} from './coachingInformation.actions';
import {LOCATION_CHANGED} from '../../../../lib/router/connected-router-saga';

const isLoadingGetReducer = (state = false, action) => {
    switch (action.type) {
        case SET_IS_LOADING_GET_COACHING_INFORMATION_FORM:
            return action.payload;

        default:
            return state;
    }
};

const isLoadingSubmitReducer = (state = false, action) => {
    switch (action.type) {
        case SET_IS_LOADING_SUBMIT_COACHING_INFORMATION_FORM:
            return action.payload;

        default:
            return state;
    }
};

const coachingInfoReducer = (state = false, action) => {
    if (action.type === SET_COACHING_INFORMATION_FORM) {
        return action.payload;
    } else if (action.type === LOCATION_CHANGED) {
        return false;
    }

    return state;
};

export const coachingInformationReducer = combineReducers({
    isLoadingGet: isLoadingGetReducer,
    isLoadingSubmit: isLoadingSubmitReducer,
    coachingInfo: coachingInfoReducer,
});
