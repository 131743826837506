import {Box, CircularProgress, Grow} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useDrag} from 'react-dnd';
import Invoice from './Invoice';
import {LoadingTypes, useLoadingState} from '../../../../../../../loading';
import {TransactionMatchType, categorizationState} from '../../../../util/constants';
import {DRAG_ITEMS} from '../../utils/constants';

const CreditInvoicePanel = ({
    details,
    invoice,
    unallocatedAmount,
    matchType,
    onSelectInvoice,
    setActiveInvoice,
    activeInvoice,
    hasDnd,
}) => {
    const wrapperRef = useRef();
    const isCategorised = details?.categorizationState === categorizationState.CATEGORIZED;

    const isLoading = useLoadingState(LoadingTypes.BANK_TRANSACTION_FIELD);
    const isLoadingDnd = useLoadingState(LoadingTypes.BANK_TRANSACTION_ADDITIONAL_FIELD);

    const maxPartialPaidValue = invoice?.selected
        ? Number(invoice?.allocatedAmount)
        : Math.min(unallocatedAmount, Number(invoice?.totalRemainingAmount));

    const [partialPaidValue, setPartialPaidValue] = useState(maxPartialPaidValue);

    useEffect(() => {
        if (partialPaidValue !== maxPartialPaidValue) {
            setPartialPaidValue(maxPartialPaidValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxPartialPaidValue]);

    // eslint-disable-next-line no-unused-vars
    const [{isDragging}, drag] = useDrag(() => ({
        // "type" is required. It is used by the "accept" specification of drop targets.
        type: DRAG_ITEMS.INVOICE,
        item: {...invoice, matchType, unallocatedAmount, partialPaidValue},
        // The collect function utilizes a "monitor" instance (see the Overview for what this is)
        // to pull important pieces of state from the DnD system.
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    }), [partialPaidValue]);

    const isSaveAfterDrag = isLoading && isLoadingDnd && isLoadingDnd === invoice?.invoiceId;

    return (
        <div>
            <div ref={hasDnd ? drag : null}>
                {isDragging && (
                    <Box sx={{
                        borderRadius: '16px',
                        borderColor: '#fff',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        backgroundColor: 'v2.gray.100',
                        p: 2,
                        display: 'flex',
                        alignSelf: 'stretch',
                        flexDirection: 'column',
                        gap: '8px',
                        height: wrapperRef?.current?.clientHeight ?? '100%',
                    }}
                    />
                )}

                {isSaveAfterDrag && (
                    <Box sx={{
                        borderRadius: '16px',
                        borderColor: '#fff',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        backgroundColor: 'v2.gray.100',
                        p: 2,
                        display: 'flex',
                        alignSelf: 'stretch',
                        flexDirection: 'column',
                        gap: '8px',
                        height: wrapperRef?.current?.clientHeight ?? '220px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        <CircularProgress />
                    </Box>
                )}

                {!isDragging && !isSaveAfterDrag && (
                <Grow in={true} timeout={100}>
                    <div
                        ref={wrapperRef}
                    >
                        <Invoice
                            key={invoice.invoiceId}
                            isCategorised={isCategorised}
                            unallocatedAmount={unallocatedAmount}
                            invoice={invoice}
                            isTheBest={false}
                            setActiveInvoice={setActiveInvoice}
                            activeInvoice={activeInvoice}
                            onSelectInvoice={onSelectInvoice}
                            matchType={TransactionMatchType.BEST_MATCH}
                            partialPaidValue={partialPaidValue}
                            setPartialPaidValue={setPartialPaidValue}
                        />
                    </div>
                </Grow>
                )}
            </div>
        </div>
    );
};

CreditInvoicePanel.propTypes = {
    invoice: PropTypes.object.isRequired,
    details: PropTypes.object.isRequired,
    unallocatedAmount: PropTypes.number.isRequired,
    onSelectInvoice: PropTypes.func.isRequired,
    matchType: PropTypes.string.isRequired,
    hasDnd: PropTypes.bool,
    setActiveInvoice: PropTypes.func.isRequired,
    activeInvoice: PropTypes.string.isRequired,
};

CreditInvoicePanel.defaultProps = {
    hasDnd: true,
};

export default CreditInvoicePanel;
