import axios from 'axios';
import {call, delay, put, select} from 'redux-saga/effects';
import {SignatureActions} from './signature.action';
import {SignatureSelectors} from './signature.selector';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {LoadingActions, LoadingSelectors, LoadingTypes} from '../../loading';
import {SignatureApi} from '../api/signature.api';

const checkSignatureSSEFallback = function* ({freelancerId, getSignatureFlow}) {
    let hasNewSignature;
    let isInProgress;

    // Since there are some occurrences when SSE fails we added synchronous check
    yield delay(15000);

    isInProgress = yield select(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.UPLOAD_SIGNATURE,
    ));

    // If it is not in progress SSE returned successfully
    if (!isInProgress) {
        return;
    }

    hasNewSignature = yield call(doesNewSignatureExists, {freelancerId});

    // We have new signature
    if (hasNewSignature) {
        yield call(uploadSignatureSuccessHandle, {freelancerId, getSignatureFlow});
        return;
    }

    // Another check after 1 minute total
    yield delay(45000);

    isInProgress = yield select(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.UPLOAD_SIGNATURE,
    ));

    // If it is not in progress SSE returned successfully
    if (!isInProgress) {
        return;
    }

    hasNewSignature = yield call(doesNewSignatureExists, {freelancerId});

    // We have new signature
    if (hasNewSignature) {
        yield call(uploadSignatureSuccessHandle, {freelancerId, getSignatureFlow});
        return;
    }

    // A minute passed and no response so some error probably occurred
    yield call(uploadSignatureFailedHandle);
    Toast.error('genericError');
};

const doesNewSignatureExists = function* ({freelancerId}) {
    try {
        const oldData = yield select(SignatureSelectors.selectSignatureInfo);
        const data = yield call(SignatureApi.getSignature, {freelancerId});

        // We have new signature
        return oldData?.id !== data?.id;
    } catch (error) {
        Debug.error('SignatureHelperSaga doesNewSignatureExists', 'Error: ', {error});

        return false;
    }
};

const uploadSignatureFailedHandle = function* () {
    yield put(SignatureActions.hasSignatureUploadSucced(false));
    yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_SIGNATURE, false));
};

const uploadSignatureSuccessHandle = function* ({freelancerId, getSignatureFlow}) {
    yield call(getSignatureFlow, {freelancerId});
    yield put(SignatureActions.hasSignatureUploadSucced(true));
    yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_SIGNATURE, false));
};

const downloadFromS3 = url => axios.get(url, {
    responseType: 'blob',
}).then(result => result.data);

export const SignatureHelperSaga = {
    checkSignatureSSEFallback,
    uploadSignatureFailedHandle,
    uploadSignatureSuccessHandle,
    downloadFromS3,
};
