import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import {Box, Divider, Skeleton, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const BankCardsLoadingSkeletonMenuItem = ({hasDivider}) => {
    return (
        <>
            {hasDivider && (
                <Divider />
            )}

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 10,
            }}
            >
                <Skeleton variant="rectangular" width={182} height={22} />
                <ChevronRightIcon sx={{color: 'v2.light.text.disabledLight'}} />
            </Box>
        </>
    );
};

BankCardsLoadingSkeletonMenuItem.propTypes = {
    hasDivider: PropTypes.bool,
};

BankCardsLoadingSkeletonMenuItem.defaultProps = {
    hasDivider: true,
};

export const BankCardsLoadingSkeletonSidebar = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
        }}
        >
            <Box sx={{
                backgroundColor: '#F1F4F6',
                display: 'flex',
                width: '332px',
                alignItems: 'center',
                gap: 1,
                py: '20px',
                px: 2,
            }}
            >
                <Box>
                    <Box sx={{
                        width: '56px',
                        height: '56px',
                        borderRadius: '50%',
                        backgroundColor: 'v2.blueGray.200',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        <DateRangeIcon sx={{color: 'white'}} />
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    gap: 1,
                    mr: 0.5,
                }}
                >
                    <Box sx={{color: 'v2.light.text.disabled'}}>
                        <Skeleton variant="rectangular" width={140} height={24} />
                    </Box>
                    <Box sx={{width: '100%'}}>
                        <Skeleton variant="rectangular" width={182} height={4} />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: 0.5,
                        justifyContent: 'flex-start',
                        width: '100%',
                        fontWeight: 600,
                    }}
                    >
                        <Typography sx={{color: 'primary_v2.main'}}>
                            <Skeleton variant="rectangular" width={64} height={24} />
                        </Typography>
                        <Typography sx={{color: 'v2.blueGray.200'}}>
                            /
                        </Typography>
                        <Typography sx={{color: 'v2.blueGray.200'}}>
                            <Skeleton variant="rectangular" width={64} height={24} />
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
                >
                    <SettingsIcon sx={{color: 'v2.blueGray.400'}} />
                </Box>
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2.5,
                px: 3,
                pt: 3,
            }}
            >
                <BankCardsLoadingSkeletonMenuItem hasDivider={false} />
                <BankCardsLoadingSkeletonMenuItem />
                <BankCardsLoadingSkeletonMenuItem />
                <BankCardsLoadingSkeletonMenuItem />
            </Box>
        </Box>
    );
};

export const BankCardsLoadingSkeletonMain = () => {
    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
        }}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                flexDirection: 'row',
                left: '30%',
                gap: 5,
            }}
            >
                <Box sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 2,
                    alignItems: 'center',
                    bottom: '24px',
                }}
                >
                    <Skeleton
                        variant="rectangular"
                        width={180}
                        height={32}
                    />
                    <Skeleton variant="rectangular" width={358} height={224} />
                </Box>
                <Box>
                    <Skeleton variant="rectangular" width={358} height={224} />
                </Box>
            </Box>
        </Box>
    );
};

export const BankCardsLoadingSkeletonMobile = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            height: '100vh',
        }}
        >
            <Box sx={{
                backgroundColor: '#F1F4F6',
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                gap: 1,
                py: '20px',
                px: 2,
            }}
            >
                <Box>
                    <Box sx={{
                        width: '56px',
                        height: '56px',
                        borderRadius: '50%',
                        backgroundColor: 'v2.blueGray.200',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        <DateRangeIcon sx={{
                            color: 'white',
                        }}
                        />
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    gap: 1,
                    mr: 0.5,
                }}
                >
                    <Box sx={{
                        color: 'v2.light.text.disabled',
                    }}
                    >
                        <Skeleton variant="rectangular" width={140} height={24} />
                    </Box>
                    <Box sx={{width: '100%'}}>
                        <Skeleton variant="rectangular" width={182} height={4} />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: 0.5,
                        justifyContent: 'flex-start',
                        width: '100%',
                        fontWeight: 600,
                    }}
                    >
                        <Typography sx={{
                            color: 'primary_v2.main',
                        }}
                        >
                            <Skeleton variant="rectangular" width={64} height={24} />
                        </Typography>
                        <Typography sx={{
                            color: 'v2.blueGray.200',
                        }}
                        >
                            /
                        </Typography>
                        <Typography sx={{
                            color: 'text_v2.secondary',
                        }}
                        >
                            <Skeleton variant="rectangular" width={64} height={24} />
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <SettingsIcon sx={{
                        color: 'v2.blueGray.400',
                    }}
                    />
                </Box>
            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                flexDirection: 'row',
                left: '10%',
                right: '10%',
                gap: 5,
            }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 2,
                    alignItems: 'center',
                    my: 3,
                }}
                >
                    <Skeleton variant="rectangular" width="50vw" height={32} />
                    <Skeleton variant="rectangular" width="80vw" height={224} />
                </Box>
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2.5,
                px: 3,
                pt: 3,
            }}
            >
                <BankCardsLoadingSkeletonMenuItem hasDivider={false} />
                <BankCardsLoadingSkeletonMenuItem />
                <BankCardsLoadingSkeletonMenuItem />
            </Box>
        </Box>
    );
};
