import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import {Box, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

const OthersContent = () => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:1200px)`);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isMobileSize ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                gap: 2,
            }}
        >
            <InfoBox
                Icon={PaymentsOutlinedIcon}
                title={t('connectProPage.others.title1')}
                text={t('connectProPage.others.text1')}
                isMobileSize={isMobileSize}
            />
            <InfoBox
                Icon={WorkHistoryOutlinedIcon}
                title={t('connectProPage.others.title2')}
                text={t('connectProPage.others.text2')}
                isMobileSize={isMobileSize}
            />
            <InfoBox
                Icon={HistoryEduIcon}
                title={t('connectProPage.others.title3')}
                text={t('connectProPage.others.text3')}
                isMobileSize={isMobileSize}
            />
        </Box>
    );
};

const InfoBox = ({Icon, title, text, isMobileSize}) => (
    <Box sx={{
        backgroundColor: 'gray_v2.50',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        gap: 3,
        py: 4,
        pt: isMobileSize ? 2 : 4,
        px: isMobileSize ? 3 : 5,
        flex: '1 0 0',
    }}
    >
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            color: 'v2.light.secondary.main',
        }}
        >
            <Icon sx={{fontSize: 96}} />
        </Box>
        <Typography
            variant="h4"
            sx={{
                fontWeight: 700,
                color: 'v2.light.secondary.main',
                fontSize: '21px !important',
                lineHeight: '26px !important',
                letterSpacing: '0.25px',
            }}
        >
            {title}
        </Typography>
        <Typography
            sx={{
                fontWeight: 400,
                color: 'text_v2.secondary',
                letterSpacing: '0.15px',
            }}
        >
            {text}
        </Typography>
    </Box>
);

InfoBox.propTypes = {
    Icon: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
};

export default OthersContent;
