export const selectSettings = state => state.settings?.data ?? {language: 'fr'};

export const selectIsUpdatingSettings = state => state.settings.isUpdating;

export const selectIsLoadingSettings = state => state.settings.isLoading;

export const SettingsSelectors = {
    selectSettings,
    selectIsUpdatingSettings,
    selectIsLoadingSettings,
};
