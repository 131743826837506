export const SseActionTypes = {
    GET_BANK_TOKEN_AND_RECONNECT: 'SSE:GET_BANK_TOKEN_AND_RECONNECT',
    STORE_BANK_SSE_STATUS: 'SSE:STORE_BANK_SSE_STATUS',
    STORE_BANK_SSE_EVENT_SOURCE: 'SSE:STORE_BANK_SSE_EVENT_SOURCE',

    GET_INVOICE_TOKEN_AND_RECONNECT: 'SSE:GET_INVOICE_TOKEN_AND_RECONNECT',
    STORE_INVOICE_SSE_STATUS: 'SSE:STORE_INVOICE_SSE_STATUS',
    STORE_INVOICE_SSE_EVENT_SOURCE: 'SSE:STORE_INVOICE_SSE_EVENT_SOURCE',

    STORE_CORE_SSE_STATUS: 'SSE:STORE_CORE_SSE_STATUS',
    STORE_CORE_SSE_EVENT_SOURCE: 'SSE:STORE_CORE_SSE_EVENT_SOURCE',
    GET_CORE_TOKEN_AND_RECONNECT: 'SSE:GET_CORE_TOKEN_AND_RECONNECT',

    DISCONNECT_TOKENS: 'SSE:DISCONNECT_TOKENS',
};

export const SSEEmitterActionTypes = {
    SERVER_SIDE_EVENT: 'Emitter:SERVER_SIDE_EVENT',
    RECONNECT: 'Emitter:RECONNECT',
    RECONNECT_WITH_NEW_TOKEN: 'Emitter:RECONNECT_WITH_NEW_TOKEN',
    STORE_SSE_STATUS: 'Emitter:STORE_SSE_STATUS',
};
