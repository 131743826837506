import moment from 'moment';
import {ServiceRateUnit} from './constants';

// Taken from https://stackoverflow.com/a/5448635
export const getSearchParameters = () => {
    const prmstr = window.location.search.substr(1);
    return prmstr !== null && prmstr !== '' ? transformToAssocArray(prmstr) : {};
};

const transformToAssocArray = prmstr => {
    const params = {};
    const prmarr = prmstr.split('&');
    for (let i = 0; i < prmarr.length; i += 1) {
        const tmparr = prmarr[i].split('=');
        params[tmparr[0]] = tmparr[1];
    }
    return params;
};

const periodMonth = moment().format('MM/YYYY');

// I believe needed are: id, quantity, period, selectedDates (if it exists)
export const filterOutServiceParamsForInvoice = service => {
    return {
        id: service?.id ?? undefined,
        quantity: service?.quantity ?? 0,
        selectedDates: service?.selectedDates ?? undefined,
        period: service?.period ?? (service?.rateUnit === ServiceRateUnit.DAY ? [] : [periodMonth]),
    };
};

export const isCountryFr = country => {
    return country?.alpha2Code === 'FR'
        || country?.alpha2Code === 'GP'
        || country?.alpha2Code === 'MQ'
        || country?.alpha2Code === 'RE';
};
