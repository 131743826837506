import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import TuneIcon from '@mui/icons-material/Tune';
import {Alert, Box, Button, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {TransactionsActions} from '../../../../store/transactions.action';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {CATEGORIZATION_SIDEBAR_TYPE} from '../../../../util/constants';

const SubscriptionPanel = ({details, isOpen, setIsOpen, hasFilesCompleted}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const hasActive = details?.hasActiveSubscription;

    useEffect(() => {
        if (details?.hasActiveSubscription) {
            dispatch(TransactionsActions.getTransactionMatchingSubscription({
                id: details?.id,
                accountId: details?.bankAccountId,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO Check if this number should be used
    const correspondingTransactions = useSelector(TransactionsSelector.selectMatchingSubcription);

    return (
        <Box sx={{
            mb: -1,
        }}
        >
            <Box sx={{
                mt: -3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}
                >
                    <EventRepeatIcon sx={{
                        color: 'v2Grey300Palette.main',
                    }}
                    />

                    <Typography sx={{
                        fontSize: theme => theme.typography.pxToRem(14),
                        fontWeight: 500,
                        color: 'text_v2.secondary',
                    }}
                    >
                        {t('categorisation.subscription.autoCategorization')}
                    </Typography>

                    {hasActive && (
                        <Box sx={{
                            ml: 1,
                            px: 0.5,
                            backgroundColor: 'v2.light.success.shades12',
                            color: 'buttonSuccess.main',
                            display: 'flex',
                            gap: 0.5,
                            alignItems: 'center',
                            fontWeight: 500,
                            letterSpacing: '0.4px',
                            borderRadius: '2px',
                        }}
                        >
                            <CheckIcon
                                fontSize="small"
                            />
                            <Typography sx={{
                                fontSize: theme => theme.typography.pxToRem(12),
                            }}
                            >
                                Active
                            </Typography>
                        </Box>
                    )}
                </Box>

                {!isOpen ? (
                    <Button
                        sx={{
                            py: 0.5,
                        }}
                        startIcon={isMobileSize ? null : (hasActive ? <TuneIcon /> : <AutoFixHighIcon />)}
                        variant="text"
                        onClick={
                            () => {
                                setIsOpen(CATEGORIZATION_SIDEBAR_TYPE.SUBSCRIPTION);
                            }
                        }
                    >
                        {hasActive
                            ? t('categorisation.subscription.edit')
                            : t('categorisation.subscription.activateButton')}
                    </Button>
                ) : (
                    <Button
                        sx={{
                            py: 0.5,
                        }}
                        startIcon={isMobileSize ? null : <CloseIcon />}
                        variant="text"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t('categorisation.subscription.close')}
                    </Button>
                )}
            </Box>

            <Box>
                {hasFilesCompleted
                    && details?.hasActiveSubscription
                    && correspondingTransactions?.length > 0 && (
                    <Alert
                        sx={{
                            mt: 0.5,
                        }}
                        iconMapping={{
                            info: <AutoFixHighIcon fontSize="inherit" />,
                        }}
                        severity="info"
                        action={(
                            <Button
                                sx={{
                                    py: 0.7,
                                    mt: -0.3,
                                    color: 'info_v2.main',
                                }}
                                endIcon={<ArrowForwardIcon sx={{color: 'info_v2.main'}} />}
                                color="inherit"
                                size="small"
                                type="text"
                                onClick={() => {
                                    setIsOpen(CATEGORIZATION_SIDEBAR_TYPE.SUBSCRIPTION_TRANSACTIONS);
                                }}
                            >
                                {t('categorisation.subscription.see')}
                            </Button>
                          )}
                    >
                        <Typography sx={{
                            fontSize: theme => theme.typography.pxToRem(14),
                            color: 'text_v2.secondary',
                        }}
                        >
                            {t('categorisation.subscription.infoText', {number: correspondingTransactions?.length
                                ? correspondingTransactions?.length + 1
                                : 0})
                            }
                        </Typography>
                    </Alert>
                )}
            </Box>
        </Box>
    );
};

SubscriptionPanel.propTypes = {
    details: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    hasFilesCompleted: PropTypes.bool.isRequired,
};

export default SubscriptionPanel;
