import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getPickersSlideTransitionUtilityClass = slot => generateUtilityClass(
    // TODO v6: Rename 'PrivatePickersSlideTransition' to 'MuiPickersSlideTransition' to follow convention
    'PrivatePickersSlideTransition',
    slot,
);

export const pickersSlideTransitionClasses = generateUtilityClasses(
    // TODO v6: Rename 'PrivatePickersSlideTransition' to 'MuiPickersSlideTransition' to follow convention
    'PrivatePickersSlideTransition',
    [
        'root',
        'slideEnter-left',
        'slideEnter-right',
        'slideEnterActive',
        'slideExit',
        'slideExitActiveLeft-left',
        'slideExitActiveLeft-right',
    ],
);
