import {SvgIcon} from '@mui/material';

export const LaptopIcon = props => (
    <SvgIcon viewBox="0 0 45 36" {...props}>
        <path
            d="M0.333984 36V33H44.334V36H0.333984ZM5.33398 30C4.53398 30 3.83398 29.7 3.23398 29.1C2.63398 28.5 2.33398 27.8 2.33398 27V3C2.33398 2.2 2.63398 1.5 3.23398 0.9C3.83398 0.3 4.53398 0 5.33398 0H39.334C40.134 0 40.834 0.3 41.434 0.9C42.034 1.5 42.334 2.2 42.334 3V27C42.334 27.8 42.034 28.5 41.434 29.1C40.834 29.7 40.134 30 39.334 30H5.33398ZM5.33398 27H39.334V3H5.33398V27Z"
            fill="currentColor"
        />
    </SvgIcon>
);
