import {createSelector} from 'reselect';

const selectLoading = state => state.loading;

const createLoadingSelectorByType = loadingType => createSelector(
    [selectLoading],
    loading => loading[loadingType],
);

export const LoadingSelectors = {
    createLoadingSelectorByType,
};
