import {
    faBoxArchive,
    faCircleInfo,
    faCircleQuestion,
    faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, FormHelperText, Stack} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';
import {TransactionsSelector} from '../../modules/account-balance/store/transactions.selector';
import {getIntegrationInfo} from '../../modules/bridge-api/utils/bridge-util';

const ArchivingSidebarActions = ({isHiway, integration}) => {
    const {bankAccountHolder, account} = getIntegrationInfo(integration);

    const hasTransactions = useSelector(TransactionsSelector.selectHasTransactions);

    const isCloseAndArchiveDisabled = parseFloat(account?.availableBalance) !== 0
        || integration?.uncategorized !== 0;

    if (isHiway) {
        return (
            <Box sx={{
                px: 2,
                py: 3,
                borderTop: '1px solid',
                borderColor: 'neutral.outlinedBorder',
            }}
            >
                <Close
                    isDisabled={isCloseAndArchiveDisabled}
                    integration={integration}
                />
            </Box>
        );
    }

    // Bridge archive id disabled if there are uncategorized transactions,
    // or if account or bankAccountHolder are missing
    // or if it does not have transactions
    const isCloseAndArchiveBridgeDisabled = integration?.uncategorized !== 0
        || !bankAccountHolder?.id
        || !account?.id
        || !hasTransactions;

    return (
        <Stack
            direction="column"
            gap={3}
            sx={{
                px: 2,
                py: 3,
                borderTop: '1px solid',
                borderColor: 'neutral.outlinedBorder',
            }}
        >
            <Archive
                isDisabled={isCloseAndArchiveBridgeDisabled}
                integration={integration}
            />
            <Delete
                integration={integration}
            />
        </Stack>
    );
};

ArchivingSidebarActions.propTypes = {
    isHiway: PropTypes.bool.isRequired,
    integration: PropTypes.object.isRequired,
};

const Archive = ({
    isDisabled,
    integration,
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const handleArchive = () => {
        dispatch(UiActions.setModalData(ModalsKeys.ARCHIVE_INTEGRATION_MODAL, {integration}));
        dispatch(UiActions.setActiveModal(ModalsKeys.ARCHIVE_INTEGRATION_MODAL, true));
    };

    return (
        <Stack
            direction="column"
            gap={0.75}
        >
            <Button
                startDecorator={(
                    <FontAwesomeIcon icon={faBoxArchive} />
                )}
                size="lg"
                color="danger"
                variant="soft"
                fullWidth
                disabled={isDisabled}
                onClick={handleArchive}
            >
                {t('archivingActions.archive')}
            </Button>
            {isDisabled && (
                <FormHelperText>
                    <Stack direction="row" spacing={0.5} color="danger.outlinedDisabledColor">
                        <Box fontSize="md">
                            <FontAwesomeIcon icon={faCircleInfo} />
                        </Box>
                        <Box>{t('accountSettingsSidebar.closeText')}</Box>
                    </Stack>
                </FormHelperText>
            )}
        </Stack>
    );
};

Archive.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    integration: PropTypes.object.isRequired,
};

const Delete = ({integration}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const handleDelete = () => {
        dispatch(UiActions.setModalData(ModalsKeys.DELETE_INTEGRATION_MODAL, {integration}));
        dispatch(UiActions.setActiveModal(ModalsKeys.DELETE_INTEGRATION_MODAL, true));
    };

    return (
        <Stack
            direction="column"
            gap={0.75}
        >
            <Button
                startDecorator={(
                    <FontAwesomeIcon icon={faCircleXmark} />
                )}
                size="lg"
                color="danger"
                variant="outlined"
                fullWidth
                onClick={handleDelete}
            >
                {t('archivingActions.close')}
            </Button>
            <FormHelperText>
                <Stack direction="row" spacing={0.5} color="danger.outlinedColor">
                    <Box fontSize="md">
                        <FontAwesomeIcon icon={faCircleQuestion} />
                    </Box>
                    <Box>{t('archivingActions.deleteQuestion')}</Box>
                </Stack>
            </FormHelperText>
        </Stack>
    );
};

Delete.propTypes = {
    integration: PropTypes.object.isRequired,
};

const Close = ({
    isDisabled,
    integration,
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const handleClose = () => {
        if (isDisabled) {
            dispatch(UiActions.setActiveModal(ModalsKeys.CLOSE_HIWAY_INTEGRATION_UNAVAILABLE_MODAL, true));
        } else {
            dispatch(UiActions.setModalData(ModalsKeys.CLOSE_HIWAY_INTEGRATION_MODAL, {integration}));
            dispatch(UiActions.setActiveModal(ModalsKeys.CLOSE_HIWAY_INTEGRATION_MODAL, true));
        }
    };

    return (
        <Button
            startDecorator={(
                <FontAwesomeIcon icon={faCircleXmark} />
            )}
            size="lg"
            color="danger"
            variant="outlined"
            fullWidth
            onClick={handleClose}
        >
            {t('archivingActions.close')}
        </Button>
    );
};

Close.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    integration: PropTypes.object.isRequired,
};

export default ArchivingSidebarActions;
