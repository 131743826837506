import {Box} from '@mui/material';
import React, {useEffect} from 'react';
import BoxesSection from './sections/BoxesSection';
import HiwayAccountSection from './sections/HiwayAccountSection';
import {LANDING_PAGE_BORDER_RADIUS} from '../utils/constants';

const RequestHiwayProAccessLp = () => {
    useEffect(() => {
        const typeformScript = document.createElement('script');
        typeformScript.src = 'https://embed.typeform.com/next/embed.js';
        typeformScript.id = 'TYPEFORM_SCRIPT';
        document.body.append(typeformScript);
        return () => {
            const removeTypeformScript = document.getElementById('TYPEFORM_SCRIPT');
            removeTypeformScript.remove();
        };
    }, []);

    return (
        <Box sx={{
            px: {
                xs: 2,
                md: 0,
            },
        }}
        >
            <HiwayAccountSection isInRequestProAccessPage />

            <BoxesSection />

            <Box
                component="div"
                data-tf-widget="Obh7R060"
                data-tf-opacity="0"
                data-tf-iframe-props="title=Inscription : Hiway Compte Pro"
                data-tf-transitive-search-params
                data-tf-medium="snippet"
                data-tf-hide-headers="true"
                data-tf-hide-footer="true"
                data-tf-disable-scroll="true"
                data-tf-auto-resize="500,1000"
                data-tf-inline-on-mobile="true"
                sx={{
                    width: 1,
                    mt: 4,
                    height: '840px',
                    borderRadius: LANDING_PAGE_BORDER_RADIUS,
                    background: '#FFF',
                }}
            />
        </Box>
    );
};

export default RequestHiwayProAccessLp;
