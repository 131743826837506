import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import {Alert, Box, Button, CircularProgress, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {DossierResetDateModal} from './DossierResetDateModal';
import {findWorkingNonHolidayDay} from '../../../freelancer/modules/company-registration/utils/date';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';
import {TrainingSelector} from '../../store/training.selector';

export const DossierDate = ({hasResetOption, hasSingleLineDates, isDocumentGenerationInProgress}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('training');

    // For checking if date is today
    const minStartDate = useMemo(() => {
        const todayPlusOneWeek = moment().add(7, 'days');
        return findWorkingNonHolidayDay(todayPlusOneWeek, 0);
    }, []);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const trainingInfo = useSelector(TrainingSelector.getTrainingInfo);

    const isSameDay = minStartDate.isSame(moment(trainingInfo?.startDate, 'DD/MM/YYYY'), 'day');

    const isWaitingNonSigned = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.WAITING_UNSIGNED_DOCS),
    );

    const startDate = isDocumentGenerationInProgress || isWaitingNonSigned
        ? <CircularProgress size={16} />
        : trainingInfo?.startDate ?? '-';
    const endDate = isDocumentGenerationInProgress || isWaitingNonSigned
        ? <CircularProgress size={16} />
        : trainingInfo?.endDate ?? '-';

    const onDateResetClick = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.TRAINING_DOSSIER_RESET_DATE, true));
    };

    return (
        <Alert
            sx={{
                borderRadius: '12px',
                border: hasSingleLineDates ? '1px solid #FF9800' : '',
                position: 'relative',
            }}
            severity="warning"
            action={hasResetOption && !isMobileSize && !isSameDay ? (
                <Button
                    sx={{height: '32px'}}
                    startIcon={<EventRepeatIcon />}
                    variant="text"
                    onClick={onDateResetClick}
                >
                    {t('dossier.dateAlert.buttonText')}
                </Button>
            ) : null}
        >
            <Box sx={{
                fontSize: theme => theme.typography.pxToRem(16),
            }}
            >
                <Box sx={{mb: 1, display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        {!hasResetOption || isMobileSize ? t('dossier.dateAlert.instruction') : t('dossier.dateAlert.instructionShort')}
                    </Box>
                </Box>
                {!isMobileSize && (
                    <Box sx={{
                        display: hasSingleLineDates ? 'flex' : 'grid',
                        gridTemplateColumns: '210px 100px',
                        gridTemplateRows: 'auto auto',
                        gridColumnGap: '16px',
                        gridRowGap: '7px',
                        alignItems: 'center',
                    }}
                    >
                        <Box>
                            {t('dossier.dateAlert.startDateFormation')}
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: theme => theme.typography.pxToRem(18),
                                    fontWeight: 600,
                                }}
                            >
                                {startDate}
                            </Typography>
                        </Box>

                        <Box>
                            {t('dossier.dateAlert.endDateFormation')}
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: theme => theme.typography.pxToRem(18),
                                    fontWeight: 600,
                                }}
                            >
                                {endDate}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {isMobileSize && (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    >
                        <Box sx={{mt: 2}}>
                            <Box>
                                {t('dossier.dateAlert.startDateFormation')}
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: theme => theme.typography.pxToRem(18),
                                        fontWeight: 600,
                                    }}
                                >
                                    {startDate}
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{my: 2}}>
                            <Box>
                                {t('dossier.dateAlert.endDateFormation')}
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: theme => theme.typography.pxToRem(18),
                                        fontWeight: 600,
                                    }}
                                >
                                    {endDate}
                                </Typography>
                            </Box>
                        </Box>

                        {hasResetOption && !isSameDay && (
                            <Box sx={{height: '32px'}} />
                        )}
                        {hasResetOption && !isSameDay && (
                            <Box sx={{
                                position: 'absolute',
                                right: '17px',
                                bottom: '15px',
                            }}
                            >
                                <Button
                                    fullWidth
                                    sx={{height: '32px', width: '260px', borderRadius: '8px'}}
                                    startIcon={<EventRepeatIcon />}
                                    variant="outlined"
                                    onClick={onDateResetClick}
                                >
                                    {t('dossier.dateAlert.buttonText')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>

            {hasResetOption && <DossierResetDateModal />}
        </Alert>
    );
};

DossierDate.propTypes = {
    hasResetOption: PropTypes.bool,
    hasSingleLineDates: PropTypes.bool,
    isDocumentGenerationInProgress: PropTypes.bool,
};

DossierDate.defaultProps = {
    hasResetOption: false,
    hasSingleLineDates: false,
    isDocumentGenerationInProgress: false,
};
