import {
    AllProfessionAndSpecializationValuesFrom,
    FreelancerProfessionAndSpecializationValuesFrom, FreelancerProfessionAndSpecializationValuesTo,
} from './profession-and-specialization.dto';
import {hiwayApiInstance} from '../../../lib/hiway-api-instance';
import {UserRoles} from '../../../utils/user-roles';


const getAllValues = () => {
    return hiwayApiInstance
        .get('/v1/freelancers/profession-details', {
            params: {
                role: UserRoles.FREELANCER,
            },
        })
        .then(result => new AllProfessionAndSpecializationValuesFrom(result.data).convertedData);
};

const getFreelancerData = ({freelancerId}) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}/professions`)
        .then(result => new FreelancerProfessionAndSpecializationValuesFrom(result.data).convertedData);
};

const saveFreelancerData = ({freelancerId, data}) => {
    return hiwayApiInstance
        .put(`/v1/freelancers/${freelancerId}/professions`,
            new FreelancerProfessionAndSpecializationValuesTo(data))
        .then(result => result.status);
};


export const ProfessionAndSpecializationApi = {
    getAllValues,
    getFreelancerData,
    saveFreelancerData,
};
