import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import {useController} from 'react-hook-form';
import NumberFormat from 'react-number-format';

export const FixedAmount = ({name, amount}) => {
    useController({name, defaultValue: amount});
    return (
        <Box sx={{
            fontSize: theme => theme.typography.pxToRem(24),
            fontWeight: 600,
            letterSpacing: '-1px',
            color: 'v2.blueGray.300',
            mr: 1,
        }}
        >
            <NumberFormat thousandSeparator={' '} value={amount} displayType="text" suffix=" €" />
        </Box>
    );
};

FixedAmount.propTypes = {
    name: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
};
