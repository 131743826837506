import moment from 'moment';
import {UserRoles} from '../../../../../utils/user-roles';

export const UserDto = function (dto) {
    // User information

    this.role = dto.role;
    this.email = dto.email;
    this.first_name = dto.firstName;
    this.last_name = dto.lastName;
    this.phone = dto.phone;

    // Coaching information

    const coachingInfo = {
        coach_id: dto.coach?.id || undefined,
        contact_source: dto.contactSource || undefined,
        contract_start_type: dto.startContractType || undefined,
        availability_date: dto.lastDayOfWork
            ? moment(dto.lastDayOfWork).format('DD/MM/YYYY')
            : undefined,
        contract_status: dto.contractStatus || undefined,
        job_center_registration_date: dto.registrationDate && dto.startContractType !== 'DIRECT_START_WITHOUT_PE'
            ? moment(dto.registrationDate).format('DD/MM/YYYY')
            : undefined,
        expected_registration_date: dto.estimatedRegistrationDate
            ? moment(dto.estimatedRegistrationDate).format('DD/MM/YYYY')
            : undefined,
        city_of_residence: dto.city || undefined,
        comment: dto.note || undefined,
        offer_choice: dto.role !== UserRoles.CARE ? undefined : (dto.taxSystem || undefined),
    };

    if (dto.jobType) {
        coachingInfo.professions = [{
            job_type: dto.jobType,
            job_specializations: dto.role === UserRoles.CARE ? undefined : [],
        }];
    }

    if ((dto.role === UserRoles.FREELANCER || dto.role === UserRoles.CARE)
        && Object.values(coachingInfo).some(value => !!value)) {
        this.coaching_info = coachingInfo;
    }
};
