import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Stack} from '@mui/joy';
import {useMemo} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ManualInputField} from './ManualInputField';
import {ValidateManualConfirmationModal} from './ValidateManualConfirmationModal';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';
import {VATDeclarationActions} from '../../store/vat-declaration.action';
import {VatDeclarationSelectors} from '../../store/vat-declaration.selector';
import {VatDeclarationSection} from '../VatDeclarationSection';

const FIELDS = [
    'totalSalesAndServices',
    'otherTaxableOperations',
    'intraCommunityServicePurchases',
    'intraCommunityGoodsAcquisitions',
    'exportsOutsideEu',
    'otherNonTaxableTransactions',
    'standardRate20ExcludingTax',
    'standardRate20TaxDue',
    'standardRate85ExcludingTax',
    'standardRate85TaxDue',
    'vatPreviouslyDeductedToRepay',
    'totalCollectedVat',
    'deductibleVatOnFixedAssets',
    'deductibleVatOnOtherGoodsServices',
    'otherVatToDeduct',
    'vatCreditFromPreviousDeclaration',
    'totalDeductibleVat',
    'totalVatCredit',
    'vatCreditToCarryForward',
    'totalNetVatDue',
    'assimilatedTaxesFromAnnex3310a',
    'totalVatToPay',
];

export const InputFieldsSection = () => {
    const {t} = useTranslation('vatDeclaration');
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);
    const {documents} = declaration;
    const dispatch = useDispatch();
    const isSubmitting = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_VALIDATE_MANUAL),
    );
    const isDocumentsLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.VAT_DECLARATION_DOCUMENTS),
    );

    const defaultValues = useMemo(
        () => FIELDS.reduce(
            (acc, field) => ({
                ...acc,
                [field]: declaration[field],
            }),
            {},
        ),
        [declaration],
    );

    const methods = useForm({defaultValues});
    const {control} = methods;

    const values = useWatch({control});

    const isValid = useMemo(
        () => Object.values(values).some(value => !!value) && documents?.length,
        [values, documents],
    );

    const handleSubmit = formValues => {
        const data = {};
        Object.entries(formValues).forEach(([key, value]) => {
            if (value) {
                data[key] = value;
            } else {
                data[key] = undefined;
            }
        });
        dispatch(VATDeclarationActions.validateManualMode({declarationId: declaration.id, data}));
    };

    const handleValidateClick = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.VAT_DECLARATION_VALIDATE_CONFIRMATION, true));
    };

    return (
        <VatDeclarationSection title={t('accountingData')} isRequired sx={{mb: 20}}>
            <ValidateManualConfirmationModal onConfirm={methods.handleSubmit(handleSubmit)} />
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <Stack spacing={2}>
                        {FIELDS.map(fieldName => (
                            <ManualInputField
                                key={fieldName}
                                label={t(`manualModeFields.${fieldName}`)}
                                name={fieldName}
                                isDisabled={isSubmitting}
                            />
                        ))}
                    </Stack>
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            borderRadius: 'lg',
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            boxShadow: 'lg',
                            p: 2,
                            bgcolor: 'background.surface',
                            display: 'flex',
                            alignItems: 'center',
                            mr: 0.5,
                            width: '600px',
                        }}
                    >
                        <Button
                            onClick={handleValidateClick}
                            variant="solid"
                            color="primary"
                            size="sm"
                            disabled={!isValid || isDocumentsLoading}
                            endDecorator={<FontAwesomeIcon icon={faArrowRight} />}
                            loading={isSubmitting}
                            loadingPosition="end"
                            fullWidth
                        >
                            {t('validateDeclaration')}
                        </Button>
                    </Box>
                </form>
            </FormProvider>
        </VatDeclarationSection>
    );
};
