import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

export const EurAmount = ({amount, decimalScale, ...props}) => {
    return <NumberFormat decimalScale={decimalScale} thousandSeparator={' '} value={amount} displayType="text" suffix=" €" {...props} />;
};

EurAmount.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    decimalScale: PropTypes.number,
};

EurAmount.defaultProps = {
    decimalScale: 2,
};
