import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {DatabaseSelectors} from '../../database/store/database.selector';
import {SigningActions} from '../store/signing.action';
import {SigningSelectors} from '../store/signing.selector';

export const useSigningWrapper = () => {
    const dispatch = useDispatch();

    const currentDocumentId = useSelector(SigningSelectors.selectCurrentDocumentId);
    const documents = useSelector(DatabaseSelectors.selectDocuments);
    const numberOfDocuments = useSelector(DatabaseSelectors.selectNumberOfDocuments());
    const currentDocumentIndex = useSelector(DatabaseSelectors.selectDocumentIndex(currentDocumentId));

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const {t} = useTranslation('document');
    const {t: dt} = useTranslation('documents');

    const onPreviousDocumentClick = () => {
        if (!currentDocumentIndex || currentDocumentIndex - 2 < 0) {
            return;
        }
        const document = Object.values(documents).sort((previous, next) => {
            const previousType = previous.type.toUpperCase();
            const nextType = next.type.toUpperCase();

            return (previousType < nextType) ? -1 : (previousType > nextType) ? 1 : 0;
        })[currentDocumentIndex - 2];

        dispatch(AnimationActions.storeNextDispatch(
            SigningActions.setCurrentCompanyDocument(document.id),
        ));
        dispatch(AnimationActions.setIsAnimationActive(false));
    };

    const onNextDocumentClick = () => {
        if (!currentDocumentIndex || currentDocumentIndex >= numberOfDocuments) {
            return;
        }
        const document = Object.values(documents).sort((previous, next) => {
            const previousType = previous.type.toUpperCase();
            const nextType = next.type.toUpperCase();

            return (previousType < nextType) ? -1 : (previousType > nextType) ? 1 : 0;
        })[currentDocumentIndex];

        dispatch(AnimationActions.storeNextDispatch(
            SigningActions.setCurrentCompanyDocument(document.id),
        ));
        dispatch(AnimationActions.setIsAnimationActive(false));
    };

    useEffect(() => {
        if (!currentDocumentId && documents && Object.values(documents).length > 0) {
            dispatch(SigningActions.openNextDocument());
        }
    }, [currentDocumentId, dispatch, documents]);

    return {
        t,
        dt,
        isMobileSize,
        onPreviousDocumentClick,
        onNextDocumentClick,
        documents,
        currentDocumentId,
        currentDocumentIndex,
        numberOfDocuments,
    };
};
