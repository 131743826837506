import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {Box, CircularProgress, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {UiActions} from '../../../ui/store/ui.action';
import {ModalsKeys} from '../../../ui/utils/constants';
import {TrainingActions} from '../../store/training.action';
import {TrainingSelector} from '../../store/training.selector';
import {TRAINING_CONSTANTS} from '../../utils/constants';

export const FundingButtonToggle = ({onStatusChange}) => {
    const dispatch = useDispatch();
    const [clicked, setClicked] = useState(null);

    const {t} = useTranslation('training');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const trainingInfo = useSelector(TrainingSelector.getTrainingInfo);

    const subSstep = useSelector(TrainingSelector.getSubstep);
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.TRAINING_STEP_SUBMIT),
    );

    const changeStatus = value => {
        dispatch(TrainingActions.submitStep(value));
        setClicked(value);
        onStatusChange();
    };

    const showDateAlertModal = useCallback(() => {
        dispatch(UiActions.setModalData(ModalsKeys.FUNDING_APPROVAL_DATE_ALERT,
            {
                date: trainingInfo.endDate,
            }));
        dispatch(UiActions.setActiveModal(ModalsKeys.FUNDING_APPROVAL_DATE_ALERT, true));
    }, [trainingInfo, dispatch]);

    const onButtonChange = (e, value) => {
        // Alert on validate click if end date is in the future
        if (
            value === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE
            && moment().isBefore(moment(trainingInfo.endDate, 'DD/MM/YYYY'), 'day')
        ) {
            showDateAlertModal();
            return;
        }

        // Warning popups when clicking on validate and going from validate
        if (subSstep !== value && !isLoading) {
            if (value === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE) {
                // Handle validated click
                dispatch(UiActions.setModalData(ModalsKeys.FUNDING_APPROVAL,
                    {
                        toValidate: true,
                        onSubmitCb: () => {
                            changeStatus(value);
                        },
                    }));
                dispatch(UiActions.setActiveModal(ModalsKeys.FUNDING_APPROVAL, true));
            } else if (subSstep === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE) {
                // Handle click from validated
                dispatch(UiActions.setModalData(ModalsKeys.FUNDING_APPROVAL,
                    {
                        toValidate: false,
                        onSubmitCb: () => {
                            changeStatus(value);
                        },
                    }));
                dispatch(UiActions.setActiveModal(ModalsKeys.FUNDING_APPROVAL, true));
            } else {
                // Handle in progress and refused from to clicks
                changeStatus(value);
            }
        }
    };

    useEffect(() => {
        if (!isLoading) {
            setClicked(false);
        }
    }, [isLoading, setClicked]);

    return (
        <Box sx={{
            background: '#FAFAFA',
            borderRadius: '8px',
            p: {
                xs: 2,
                md: 3,
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
        >
            <Typography sx={{mb: 2, textAlign: 'center'}} variant="h5">{t('fundingApproval.confirmationCoverageText')}</Typography>
            <ToggleButtonGroup
                sx={isMobileSize
                    ? {
                        'width': '100%',
                        'position': 'relative',
                        'zIndex': 0,
                    } : {
                        'position': 'relative',
                        'button': {
                            'boxShadow': 'none',
                        },
                        'zIndex': 0,
                    }}
                value={subSstep}
                exclusive
                onChange={onButtonChange}
                aria-label="text alignment"
                orientation={isMobileSize ? 'vertical' : 'horizontal'}
            >
                <ToggleButton
                    sx={!isMobileSize ? {
                        'cursor': subSstep === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING
                            ? 'default' : 'pointer',
                        'color': '#5f2b01',
                        'background': 'white',
                        'marginLeft': 0,
                        '&.Mui-selected': {
                            'color': '#5f2b01',
                            'background': 'rgba(237, 108, 2, 0.12)',
                        },
                        'borderRight': '0px',
                    } : {
                        'cursor': subSstep === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING
                            ? 'default' : 'pointer',
                        'color': '#5f2b01',
                        'background': 'white',
                        '&.Mui-selected': {
                            'color': '#5f2b01',
                            'background': 'rgba(237, 108, 2, 0.12)',
                        },
                        'borderBottom': '0px',
                    }}
                    color="warning"
                    disabled={isLoading && clicked
                    && clicked !== TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING}
                    value={TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING}
                >
                    {clicked === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING
                        ? (<CircularProgress sx={{mr: '10px'}} size={24} />)
                        : <PendingIcon sx={{mr: '10px'}} />}

                    {t('fundingApproval.inProgress')}
                </ToggleButton>

                <ToggleButton
                    sx={!isMobileSize ? {
                        'cursor': subSstep === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE
                            ? 'default' : 'pointer',
                        'color': '#1B5E20',
                        'background': 'white',
                        'zIndex': 1,
                        '&.Mui-selected': {
                            'color': '#1B5E20',
                            'background': 'rgba(46, 125, 50, 0.12)',
                        },
                        'borderLeft': '1px solid rgba(0, 0, 0, 0.12) !important',
                        'borderRight': '1px solid rgba(0, 0, 0, 0.12) !important',
                    } : {
                        'cursor': subSstep === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE
                            ? 'default' : 'pointer',
                        'color': '#1B5E20',
                        'background': 'white',
                        'zIndex': 1,
                        '&.Mui-selected': {
                            'color': '#1B5E20',
                            'background': 'rgba(46, 125, 50, 0.12)',
                        },
                        'borderTop': '1px solid rgba(0, 0, 0, 0.12) !important',
                        'borderBottom': '1px solid rgba(0, 0, 0, 0.12) !important',
                    }}
                    color="success"
                    disabled={
                        isLoading
                        && clicked
                        && clicked !== TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE
                    }
                    value={TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE}
                >
                    {clicked === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE
                        ? (<CircularProgress sx={{mr: '10px'}} size={24} />)
                        : <TaskAltIcon sx={{mr: '10px'}} />
                    }

                    {t('fundingApproval.accepted')}
                </ToggleButton>

                <ToggleButton
                    sx={!isMobileSize ? {
                        'cursor': subSstep === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_REJECTED
                            ? 'default' : 'pointer',
                        'color': '#C62828',
                        'background': 'white',
                        '&.Mui-selected': {
                            'color': '#C62828',
                            'background': 'rgba(211, 47, 47, 0.12)',
                        },
                        'borderLeft': '0px',
                    } : {
                        'cursor': subSstep === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_REJECTED
                            ? 'default' : 'pointer',
                        'color': '#C62828',
                        'background': 'white',
                        '&.Mui-selected': {
                            'color': '#C62828',
                            'background': 'rgba(211, 47, 47, 0.12)',
                        },
                        'borderTop': '0px',
                    }}
                    color="error"
                    disabled={
                        isLoading
                        && clicked
                    && clicked !== TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_REJECTED
                    }
                    value={TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_REJECTED}
                >
                    {clicked === TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_REJECTED
                        ? (<CircularProgress sx={{mr: '10px'}} size={24} />)
                        : <HighlightOffIcon sx={{mr: '10px'}} />
                    }

                    {t('fundingApproval.rejected')}
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
    );
};

FundingButtonToggle.propTypes = {
    onStatusChange: PropTypes.func.isRequired,
};
