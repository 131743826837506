import {Button} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {ConditionalTooltip} from '../tooltip/ConditionalTooltip';

export const ButtonIcon = ({tooltip, icon, sx, isDisabled, ...rest}) => {
    return (
        <ConditionalTooltip tooltip={isDisabled ? '' : tooltip} placement="bottom">
            <Button
                disabled={isDisabled}
                variant="contained"
                disableElevation
                sx={{
                    'minWidth': '0',
                    'padding': 0,
                    'backgroundColor': 'transparent !important',
                    'color': isDisabled ? 'v2.light.text.disabledLight' : 'v2.light.active.active',
                    '&:hover': {
                        backgroundColor: 'v2.light.primary.shades8',
                        color: isDisabled ? 'v2.light.text.disabledLight' : 'primary.main',
                    },
                    ...sx,
                }}
                {...rest}
            >
                {icon}
            </Button>
        </ConditionalTooltip>
    );
};

ButtonIcon.propTypes = {
    tooltip: PropTypes.string,
    icon: PropTypes.element.isRequired,
    sx: PropTypes.object,
    isDisabled: PropTypes.bool,
};

ButtonIcon.defaultProps = {
    tooltip: '',
    sx: {},
    isDisabled: false,
};
