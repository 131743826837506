import makeStyles from '@mui/styles/makeStyles';

export const useUpdateProfileFormStyles = makeStyles(theme => ({
    footerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(3),
    },
    cancelButton: {
        marginRight: theme.spacing(3),
    },
}));
