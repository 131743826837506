import {createSelector} from 'reselect';

const selectRibData = state => state.bank?.ribAndDocuments?.bankAccountRibDocument;
const selectBankAccountStatements = state => state.bank?.ribAndDocuments?.bankAccountStatements;

const createBankAccountStatementsSelector = () => () => createSelector(
    [
        selectBankAccountStatements,
    ],
    bankAccountStatements => {
        if (!bankAccountStatements?.count) {
            return {data: [], count: 0};
        }

        return {data: bankAccountStatements.items, count: Number(bankAccountStatements.count)};
    },
);

export const RibAndDocumentsSelector = {
    selectRibData,
    selectBankAccountStatements,
    createBankAccountStatementsSelector,
};
