import {combineReducers} from 'redux';
import {UiActionTypes} from './ui.action-type';
import {createReducer} from '../../../utils/create-reducer';

const activeModalsReducer = (state = {}, {type, payload}) => {
    if (type === UiActionTypes.SET_ACTIVE_MODAL) {
        return {
            ...state,
            [payload.modalKey]: payload.state,
        };
    }

    return state;
};

const modalDataReducer = (state = {}, {type, payload}) => {
    if (type === UiActionTypes.SET_MODAL_DATA) {
        return {
            ...state,
            [payload.modalKey]: payload.data,
        };
    }

    return state;
};

export const uiReducer = combineReducers({
    activeModals: activeModalsReducer,
    modalData: modalDataReducer,
    isMaintenanceActive: createReducer(false, UiActionTypes.SET_IS_MAINTENANCE_ACTIVE),
});
