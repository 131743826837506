import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Box, Button, IconButton, Typography} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import FlowStepWrapper from './FlowStepWrapper';
import SectionStatus from './SectionStatus';
import {copyToClipboard} from '../../../../../../utils/copy-to-clipboard';
import CategorisationSection from '../../../../../bank/modules/account-balance/components/categorisation/components/header/CategorisationSection';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {AccountingActions} from '../../../accounting/store/accounting.action';
import {AccountingSelector} from '../../../accounting/store/accounting.selector';
import FormalityPaymentStatusTag from '../../components/FormalityPaymentStatusTag';
import {FORMALITY_SECTION_STATUS} from '../../utils/constants';

const FlowStepPaymentStatus = ({isActive, isFocused, annualAccount}) => {
    const {t} = useTranslation('companies');
    const dispatch = useDispatch();

    const isDocumentRegenerationInProgress = useSelector(AccountingSelector.selectIsDocumentRegenerationInProgress);
    const formalityPaid = !!annualAccount?.payLinkInfo?.paidAt;
    const formalityPaidDate = moment(annualAccount?.payLinkInfo?.paidAt).format('DD/MM/YYYY');
    const transactionId = annualAccount?.payLinkInfo?.integrationId;

    const isSendingEmail = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.SENDING_PAYMENT_EMAIL),
    );

    return (
        <FlowStepWrapper>
            <CategorisationSection
                title={t('accounting.uploadFEC.sections.payment')}
                status={isActive
                    ? (isFocused && FORMALITY_SECTION_STATUS.REQUIRED)
                    : FORMALITY_SECTION_STATUS.WAITING_FOR_SIGNATURE
            }
                SectionStatusComponent={SectionStatus}
                isActive={isActive}
                isFocused={isActive && isFocused}
                sx={{width: '100%', maxWidth: '600px'}}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'left',
                        gap: 2,
                    }}
                >
                    <Box
                        sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1}}
                    >
                        {!formalityPaid && (
                            <Button
                                variant="contained"
                                endIcon={<ArrowForwardIcon />}
                                disabled={isDocumentRegenerationInProgress || isSendingEmail}
                                onClick={() => {
                                    dispatch(AccountingActions.resendPaymentStepEmail({
                                        companyId: annualAccount.entityId,
                                        annualAccountId: annualAccount.id,
                                    }));
                                }}
                            >
                                {t('formalities.depositOfAccounts.flowSteps.formalityPayment.buttonResendLink')}
                            </Button>
                        )}
                        {formalityPaid && (
                            <Box sx={{py: 1}}>
                                <Typography sx={{fontWeight: 500}}>
                                    {t('formalities.depositOfAccounts.flowSteps.formalityPayment.formalityPaid', {date: formalityPaidDate})}
                                </Typography>
                                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
                                    <Typography sx={{fontSize: '12px'}}>
                                        {t('formalities.depositOfAccounts.flowSteps.formalityPayment.transactionId', {id: transactionId})}
                                    </Typography>
                                    <IconButton
                                        sx={{color: 'v2.blueGray.300'}}
                                        aria-label="open drawer"
                                        onClick={() => copyToClipboard(transactionId)}
                                        edge="start"
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}
                        <Box>
                            <FormalityPaymentStatusTag status={annualAccount?.payLinkInfo?.status} />
                        </Box>
                    </Box>
                </Box>
            </CategorisationSection>
        </FlowStepWrapper>
    );
};

FlowStepPaymentStatus.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool.isRequired,
    annualAccount: PropTypes.object.isRequired,
};

export default FlowStepPaymentStatus;
