import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getClockNumberUtilityClass = slot => {
    return generateUtilityClass('MuiClockNumber', slot);
};

export const clockNumberClasses = generateUtilityClasses('MuiClockNumber', [
    'root',
    'selected',
    'disabled',
]);
