import {combineReducers} from 'redux';
import {FreelancerActionTypes} from './freelancer.action-type';
import {createReducer} from '../../../utils/create-reducer';
import {companiesReducer} from '../modules/companies';
import {contractSigningReducer} from '../modules/contract-signing/store/contract-signing.reducer';
import {onboardingReducer} from '../modules/onboarding/store/onboarding.reducer';

export const freelancerReducer = combineReducers({
    companies: companiesReducer,
    onboarding: onboardingReducer,
    contractSigning: contractSigningReducer,
    account: createReducer(null, FreelancerActionTypes.STORE_ACCOUNT),
});
