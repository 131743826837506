/* eslint-disable react/prop-types */
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import composeClasses from '@mui/base/composeClasses';
import {IconButton} from '@mui/joy';
import {useTheme} from '@mui/styles';
import {styled, useThemeProps} from '@mui/system';
import clsx from 'clsx';
import * as React from 'react';
import {getPickersArrowSwitcherUtilityClass} from './pickersArrowSwitcherClasses';

const useUtilityClasses = ownerState => {
    const {classes} = ownerState;
    const slots = {
        root: ['root'],
        spacer: ['spacer'],
        button: ['button'],
    };

    return composeClasses(slots, getPickersArrowSwitcherUtilityClass, classes);
};

const PickersArrowSwitcherRoot = styled('div', {
    name: 'MuiPickersArrowSwitcher',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})({
    display: 'flex',
});

const PickersArrowSwitcherSpacer = styled('div', {
    name: 'MuiPickersArrowSwitcher',
    slot: 'Spacer',
    overridesResolver: (_, styles) => styles.spacer,
})(({theme}) => ({
    width: theme.spacing(2),
}));

const PickersArrowSwitcherButton = styled(IconButton, {
    name: 'MuiPickersArrowSwitcher',
    slot: 'Button',
    overridesResolver: (_, styles) => styles.button,
})(({ownerState}) => ({
    ...(ownerState.hidden && {
        visibility: 'hidden',
    }),
}));

export const PickersArrowSwitcher = React.forwardRef(
    (inProps, ref) => {
        const props = useThemeProps({
            props: inProps,
            name: 'MuiPickersArrowSwitcher',
        });
        const {
            children,
            className,
            components,
            componentsProps,
            isLeftDisabled,
            isLeftHidden,
            isRightDisabled,
            isRightHidden,
            leftArrowButtonText,
            onLeftClick,
            onRightClick,
            rightArrowButtonText,
            ...other
        } = props;
        const theme = useTheme();
        const isRtl = theme.direction === 'rtl';

        const leftArrowButtonProps = componentsProps?.leftArrowButton || {};

        const rightArrowButtonProps = componentsProps?.rightArrowButton || {};

        const ownerState = props;
        const classes = useUtilityClasses(ownerState);

        return (
            <PickersArrowSwitcherRoot
                ref={ref}
                className={clsx(classes.root, className)}
                ownerState={ownerState}
                {...other}
            >
                <PickersArrowSwitcherButton
                    as={components?.LeftArrowButton}
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    aria-label={leftArrowButtonText}
                    title={leftArrowButtonText}
                    disabled={isLeftDisabled}
                    edge="end"
                    onClick={onLeftClick}
                    {...leftArrowButtonProps}
                    className={clsx(classes.button, leftArrowButtonProps.className)}
                    ownerState={{
                        ...ownerState,
                        ...leftArrowButtonProps,
                        hidden: isLeftHidden,
                    }}
                >
                    {isRtl
                        ? <FontAwesomeIcon icon={faChevronRight} />
                        : <FontAwesomeIcon icon={faChevronLeft} />
                    }
                </PickersArrowSwitcherButton>

                <PickersArrowSwitcherSpacer
                    className={classes.spacer}
                    ownerState={ownerState}
                />

                <PickersArrowSwitcherButton
                    as={components?.RightArrowButton}
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    aria-label={rightArrowButtonText}
                    title={rightArrowButtonText}
                    edge="start"
                    disabled={isRightDisabled}
                    onClick={onRightClick}
                    {...rightArrowButtonProps}
                    className={clsx(classes.button, rightArrowButtonProps.className)}
                    ownerState={{
                        ...ownerState,
                        ...rightArrowButtonProps,
                        hidden: isRightHidden,
                    }}
                >
                    {isRtl
                        ? <FontAwesomeIcon icon={faChevronLeft} />
                        : <FontAwesomeIcon icon={faChevronRight} />
                    }
                </PickersArrowSwitcherButton>
            </PickersArrowSwitcherRoot>
        );
    },
);
