import {combineReducers} from 'redux';
import {
    SET_IS_LOADING_ASSIGN_FREELANCERS_FORM,
    STORE_ASSIGN_FREELANCERS_FORM_ERROR,
} from './assignFreelancersForm.actions';
import {LOCATION_CHANGED} from '../../../../lib/router/connected-router-saga';

const isLoadingReducer = (state = false, action) => {
    switch (action.type) {
        case SET_IS_LOADING_ASSIGN_FREELANCERS_FORM:
            return action.payload;

        default:
            return state;
    }
};

const errorReducer = (state = '', action) => {
    switch (action.type) {
        case STORE_ASSIGN_FREELANCERS_FORM_ERROR:
            return action.payload;

        case LOCATION_CHANGED:
            return '';

        default:
            return state;
    }
};

export const assignFreelancersFormReducer = combineReducers({
    isLoading: isLoadingReducer,
    error: errorReducer,
});
