import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

import {SettingsSelectors} from '../../../../features/settings/store/settings.selectors';
import {isSafari14} from '../../../app/app.helpers';
import {getTwoDecimals} from '../../../utils';

const currencyOptions = {
    currency: 'EUR',
    currencyDisplay: !isSafari14 ? 'symbol' : 'narrowSymbol',
    style: 'currency',
};

const FormattedNumber = ({amount, numberStyle, suffix, additionalOptions}) => {
    const userSettings = useSelector(SettingsSelectors.selectSettings);

    let number = parseFloat(getTwoDecimals(amount));

    if (!number) {
        number = 0;
    }

    const options = numberStyle === 'currency' ? {...currencyOptions, ...additionalOptions} : undefined;
    number = userSettings?.language ? number.toLocaleString(userSettings.language, options) : number;

    return `${number}${suffix ? ' ' + suffix : ''}`;
};

FormattedNumber.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    numberStyle: PropTypes.oneOf(['currency', 'default']),
    suffix: PropTypes.string,
    additionalOptions: PropTypes.shape({
        minimumFractionDigits: PropTypes.number,
        maximumFractionDigits: PropTypes.number,
    }),
};

FormattedNumber.defaultProps = {
    numberStyle: 'default',
    suffix: '',
    additionalOptions: {},
};

export default React.memo(FormattedNumber);
