import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Box, Paper} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import DeleteFillImage from '../../../../../../assets/svg/bank/delete-fill.svg';

const DeleteConfirmCard = ({t, onDelete, isMobileSize}) => {
    if (isMobileSize) {
        return (
            <Box sx={{
                backgroundColor: '#F0F4F6',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100vw',
                height: '80vh',
                p: 3,
                gap: 2,
            }}
            >
                <Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '64px',
                        height: '64px',
                        borderRadius: '50%',
                        backgroundColor: 'v2.light.error.shades12',
                    }}
                    >
                        <img width="40" height="40" src={DeleteFillImage} alt="delete" />
                    </Box>
                </Box>
                <Box sx={{
                    fontSize: theme => theme.typography.pxToRem(34),
                    lineHeight: theme => theme.typography.pxToRem(40),
                    fontWeight: 700,
                    textAlign: 'center',
                    letterSpacing: '-0.2px',
                }}
                >
                    {t('cardDelete.title')}
                </Box>
                <Box sx={{textAlign: 'center', pb: 1}}>
                    {t('cardDelete.text')}
                </Box>
                <Button
                    fullWidth
                    color="error"
                    variant="contained"
                    startIcon={<DeleteForeverIcon />}
                    onClick={() => onDelete()}
                >
                    {t('cardDelete.button')}
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{p: 3}}>
            <Paper
                elevation={1}
                sx={{
                    p: 5,
                    borderRadius: '8px',
                }}
            >
                <Box sx={{
                    borderRadius: '8px',
                    backgroundColor: '#FAFAFA',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '80vh',
                }}
                >
                    <Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '128px',
                            height: '128px',
                            borderRadius: '50%',
                            backgroundColor: 'v2.light.error.shades12',
                        }}
                        >
                            <img src={DeleteFillImage} alt="delete" />
                        </Box>
                    </Box>
                    <Box sx={{
                        fontSize: theme => theme.typography.pxToRem(34),
                        fontWeight: 700,
                        my: 2,
                        width: '500px',
                        textAlign: 'center',
                    }}
                    >
                        {t('cardDelete.title')}
                    </Box>
                    <Box sx={{
                        width: '500px',
                        textAlign: 'center',
                    }}
                    >
                        {t('cardDelete.text')}
                    </Box>
                    <Box sx={{
                        mt: 2,
                    }}
                    >
                        <Button
                            color="error"
                            variant="contained"
                            startIcon={<DeleteForeverIcon />}
                            onClick={() => onDelete()}
                        >
                            {t('cardDelete.button')}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

DeleteConfirmCard.propTypes = {
    t: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
};

export default DeleteConfirmCard;
