import {RevenueSimulatorActionTypes} from './revenue-simulator.action-type';

export const RevenueSimulatorActions = {
    resetResults: () => ({
        type: RevenueSimulatorActionTypes.STORE_SIMULATION,
        payload: null,
    }),
    resetInputs: () => ({
        type: RevenueSimulatorActionTypes.RESET_INPUTS,
    }),
    submitInputs: data => ({
        type: RevenueSimulatorActionTypes.SUBMIT_INPUTS,
        payload: data,
    }),
    storeSimulation: simulation => ({
        type: RevenueSimulatorActionTypes.STORE_SIMULATION,
        payload: simulation,
    }),
    getResults: id => ({
        type: RevenueSimulatorActionTypes.GET_SIMULATION,
        payload: id,
    }),
    setCurrentStepIndex: index => ({
        type: RevenueSimulatorActionTypes.SET_CURRENT_STEP_INDEX,
        payload: index,
    }),
    setAllValues: values => ({
        type: RevenueSimulatorActionTypes.SET_ALL_VALUES,
        payload: values,
    }),
    goNextStep: () => ({
        type: RevenueSimulatorActionTypes.GO_NEXT_STEP,
    }),
    goPrevStep: () => ({
        type: RevenueSimulatorActionTypes.GO_PREV_STEP,
    }),
    submitStep: values => ({
        type: RevenueSimulatorActionTypes.SUBMIT_STEP,
        payload: values,
    }),
    updateValues: values => ({
        type: RevenueSimulatorActionTypes.UPDATE_VALUES,
        payload: values,
    }),
    getAllSimulations: () => ({
        type: RevenueSimulatorActionTypes.GET_ALL_SIMULATIONS,
    }),
    setSimulationsLoading: value => ({
        type: RevenueSimulatorActionTypes.SET_SIMULATIONS_LOADING,
        payload: value,
    }),
    setIsSubmitting: value => ({
        type: RevenueSimulatorActionTypes.SET_IS_SUBMITTING,
        payload: value,
    }),
    storeAllSimulations: values => ({
        type: RevenueSimulatorActionTypes.STORE_ALL_SIMULATIONS,
        payload: values,
    }),
    refreshSimulation: simulationId => ({
        type: RevenueSimulatorActionTypes.REFRESH_SIMULATION,
        payload: simulationId,
    }),
};
