import axios from 'axios';
import {CONFIG} from '../../../config';
import {invoiceApiInstance} from '../../../lib/invoice-api-instance';

const submitInputs = data => {
    return invoiceApiInstance.post(`/v1/revenue-simulator/generate-simulation`, data).then(response => response.data);
};

const getSimulation = id => {
    // Can't use invoiceApiInstance because of its interceptor that requires auth
    return axios.get(`/v1/revenue-simulator/get-my-simulation/${id}`, {
        baseURL: `${CONFIG.API.INVOICE_API_BASE_URL}/api`,
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(response => response.data);
};

const getAllSimulations = params => {
    return invoiceApiInstance.get(`/v1/revenue-simulator/`, {
        params,
    }).then(response => response.data);
};

const refreshSimulation = id => {
    return invoiceApiInstance.post(`/v1/revenue-simulator/${id}/reset-expiration-date`);
};


export const RevenueSimulatorApi = {
    submitInputs,
    getSimulation,
    getAllSimulations,
    refreshSimulation,
};
