import {isPublicHoliday} from '../../../../../v1/utils';

export const calculateEstimatedDates = (companyRegistrationDate, documentUploadDate) => {
    let firstDate;
    let secondDate;
    // If company registration date is before document upload date
    if (companyRegistrationDate.isBefore(documentUploadDate, 'day')) {
        // We use the logic of min: 2 - max: 5 business days from the date Freelancer uploaded Depot de capital document
        firstDate = findWorkingNonHolidayDay(documentUploadDate, 2);
        secondDate = findWorkingNonHolidayDay(firstDate, 3);
    } else {
        // Otherwise for min we use the Company registration date  and for max wee add 3 business days to min.
        firstDate = findWorkingNonHolidayDay(companyRegistrationDate, 0);
        secondDate = findWorkingNonHolidayDay(firstDate, 3);
    }

    return [firstDate, secondDate];
};

export const findWorkingNonHolidayDay = (date, numberOfDays) => {
    const clonedDate = date.clone();

    if (numberOfDays > 0) {
        let workDayCount = 0;

        // If first date is non-working day
        if (!isWorkingDay(clonedDate)) {
            while (!isWorkingDay(clonedDate)) {
                clonedDate.add(1, 'day');
            }
            workDayCount = 1;
        }

        while (workDayCount !== numberOfDays) {
            clonedDate.add(1, 'day');
            if (isWorkingDay(clonedDate)) {
                workDayCount += 1;
            }
        }
    } else {
        // Find first next working day
        while (!isWorkingDay(clonedDate)) {
            clonedDate.add(1, 'day');
        }
    }

    return clonedDate;
};

const isWorkingDay = date => {
    const dayNum = date.day();
    if (dayNum === 6 || dayNum === 0) {
        return false;
    }
    return !isPublicHoliday(date);
};
