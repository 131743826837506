export const STORE_TOTAL_DISTANCE = 'STORE_TOTAL_DISTANCE';
export const GET_TOTAL_DISTANCE = 'GET_TOTAL_DISTANCE';

export const getTotalDistance = date => ({
    type: GET_TOTAL_DISTANCE,
    payload: {
        date,
    },
});

export const storeTotalDistance = totalDistance => ({
    type: STORE_TOTAL_DISTANCE,
    payload: totalDistance,
});
