import {OutlinedInput} from '@mui/material';
import merge from 'lodash.merge';
import PropTypes from 'prop-types';
import {forwardRef, useMemo} from 'react';

const baseStyles = {
    'fontFamily': 'Red Hat Text',
    'fontSize': theme => theme.typography.pxToRem(24),
    'fontWeight': 700,
    'height': theme => theme.typography.pxToRem(60),
    'input': {
        'px': 2,
        'py': 1,
        'textAlign': 'inherit',
        '&.Mui-disabled': {
            opacity: 0,
        },
    },
    '.MuiOutlinedInput-notchedOutline': {
        border: '4px solid',
        borderColor: 'v2.blueGray.50',
        borderRadius: '16px',
    },
    '.MuiInputAdornment-positionEnd p': {
        fontSize: theme => theme.typography.pxToRem(32),
        fontWeight: 500,
        color: 'v2.blueGray.300',
    },

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: '4px',
        borderColor: theme => theme.palette.v2.light.primary.main + ' !important',
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline, &.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'v2.light.error.main',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'v2.blueGray.100',
    },
    '&.Mui-disabled': {
        'pointerEvents': 'none',
        '& .MuiInputAdornment-root p': {
            zIndex: 1,
            color: 'v2.gray.500',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'v2.light.action.disabled',
            borderColor: theme => theme.palette.v2.gray[500] + ' !important',
        },
    },
};

export const TextInput = forwardRef(({name, sx, ...other}, ref) => {
    const styles = useMemo(() => {
        return merge({}, baseStyles, sx);
    }, [sx]);

    return (
        <OutlinedInput
            fullWidth
            sx={styles}
            ref={ref}
            {...other}
        />
    );
});

TextInput.propTypes = {
    name: PropTypes.string,
    sx: PropTypes.object,
};

TextInput.defaultProps = {
    name: '',
    sx: undefined,
};
