import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {LoadingButton} from '@mui/lab';
import {Divider, FormControlLabel, FormGroup, Switch, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment/moment';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {recurringTransferValues} from './CreateRecurringTransfer';
import {ButtonAction} from '../../../../../../components/buttons/ButtonAction';
import {ModalWrapper} from '../../../../../../components/modal-wrapper/ModalWrapper';
import {ConditionalTooltip} from '../../../../../../components/tooltip/ConditionalTooltip';
import {formatNumberWithSpacesAndComma} from '../../../../../../utils/number';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {UiSelectors} from '../../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {BeneficiariesSelector} from '../../store/beneficiaries.selector';
import {TransfersActions} from '../../store/transfers.actions';

const modalKey = ModalsKeys.INSTANT_TRANSFER;

export const InstantTransferModal = () => {
    const dispatch = useDispatch();
    const [instant, setInstant] = useState(true);
    const [beneficiary, setBeneficiary] = useState(null);
    const {t} = useTranslation('bankTransfer');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));
    const beneficiaries = useSelector(BeneficiariesSelector.selectList);

    const isSaving = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GENERIC_CRUD_LOADER),
    );

    const isRecurring = data?.firstExecutionDate;

    useEffect(() => {
        if (data) {
            if (data?.beneficiaryRadio) {
                const beneficiary = beneficiaries.find(item => item.id === data.beneficiaryRadio);

                setBeneficiary({
                    iban: beneficiary.iban,
                    label: beneficiary.label,
                });
            } else {
                setBeneficiary({
                    iban: data.iban,
                    label: data.label,
                });
            }
        }
        // eslint-disable-next-line
    }, [data]);

    const onSaveClick = () => {
        if (instant) {
            dispatch(TransfersActions.create(
                {
                    ...data,
                    additionalData: {
                        ...data.additionalData,
                        isInstant: true,
                    },
                },
            ));
        } else {
            dispatch(TransfersActions.create(data));
        }
    };

    if (!data || !beneficiary) {
        return null;
    }

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {t(`instantTransferModal.headline`)}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <ButtonAction
                        fullWidth={isMobileSize}
                        onClick={handleClose}
                        sx={{mr: 3}}
                        color="grey"
                        disabled={isSaving}
                    >
                        {t('instantTransferModal.cancel')}
                    </ButtonAction>

                    <LoadingButton
                        fullWidth={isMobileSize}
                        color="secondary"
                        type="submit"
                        endIcon={<ArrowForwardOutlinedIcon />}
                        onClick={onSaveClick}
                        loading={isSaving}
                        disabled={isSaving}
                        variant="contained"
                    >
                        {t('instantTransferModal.continue')}
                    </LoadingButton>
                </>
            )}
            containerSx={{
                width: '600px',
            }}
        >
            <Typography>
                {t(`instantTransferModal.text`)}
            </Typography>

            <Box sx={{
                mt: 4,
            }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                >
                    <Typography
                        sx={{
                            color: 'v2.light.text.disabled',
                        }}
                    >
                        {t(`instantTransferModal.beneficiary`)}
                    </Typography>
                    <Typography>
                        {beneficiary.label}
                    </Typography>
                </Box>
                <Divider sx={{borderStyle: 'dashed', mt: 2, mb: 2}} />

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                >
                    <Typography
                        sx={{
                            color: 'v2.light.text.disabled',
                        }}
                    >
                        {t(`instantTransferModal.iban`)}
                    </Typography>
                    <Typography>
                        {beneficiary.iban}
                    </Typography>
                </Box>
                <Divider sx={{borderStyle: 'dashed', mt: 2, mb: 2}} />

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                >
                    <Typography
                        sx={{
                            color: 'v2.light.text.disabled',
                        }}
                    >
                        {t(`instantTransferModal.amount`)}
                    </Typography>
                    <Typography>
                        {formatNumberWithSpacesAndComma(data.amount)} €
                    </Typography>
                </Box>
                <Divider sx={{borderStyle: 'dashed', mt: 2, mb: 2}} />

                {isRecurring ? (
                    <>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                        >
                            <Typography
                                sx={{
                                    color: 'v2.light.text.disabled',
                                }}
                            >
                                {t(`instantTransferModal.recurrence`)}
                            </Typography>
                            <Typography>
                                {data.period === recurringTransferValues.daily && t('transfer.daily')}
                                {data.period === recurringTransferValues.weekly && t('transfer.weekly')}
                                {data.period === recurringTransferValues.monthly && t('transfer.monthly')}
                            </Typography>
                        </Box>
                        <Divider sx={{borderStyle: 'dashed', mt: 2, mb: 2}} />

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                        >
                            <Typography
                                sx={{
                                    color: 'v2.light.text.disabled',
                                }}
                            >
                                {t(`instantTransferModal.startDate`)}
                            </Typography>
                            <Typography>
                                {data.firstExecutionDate
                                    ? moment(data.firstExecutionDate).format('L')
                                    : '-'}
                            </Typography>
                        </Box>
                        <Divider sx={{borderStyle: 'dashed', mt: 2, mb: 2}} />

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                        >
                            <Typography
                                sx={{
                                    color: 'v2.light.text.disabled',
                                }}
                            >
                                {t(`instantTransferModal.endDate`)}
                            </Typography>
                            <Typography>
                                {data.lastExecutionDate
                                    ? moment(data.lastExecutionDate).format('L')
                                    : '-'}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        >
                            <Typography>
                                {t(`instantTransferModal.instantLabel`)}
                            </Typography>
                            <ConditionalTooltip
                                tooltip={t(`instantTransferModal.instantDescription`)}
                            >
                                <InfoOutlinedIcon sx={{
                                    color: '#0288D1',
                                    ml: 1,
                                }}
                                />
                            </ConditionalTooltip>
                        </Box>

                        <FormGroup>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        checked={instant}
                                        onChange={(e, value) => {
                                            setInstant(value);
                                        }}
                                    />
                            )}
                                label={t(`instantTransferModal.instantText`)}
                            />
                        </FormGroup>
                    </Box>
                )}
            </Box>
        </ModalWrapper>
    );
};
