import {SvgIcon} from '@mui/material';
import React from 'react';

const WordIcon = props => {
    return (
        <SvgIcon {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z" />
                <path d="M12 21V4h11a1 1 0 011 1v15a1 1 0 01-1 1H12z" fill="#1976D2" fillRule="nonzero" opacity=".154" />
                <path d="M15.5 17a.5.5 0 110 1h-4a.5.5 0 110-1h4zm3 0a.5.5 0 110 1h-1a.5.5 0 110-1h1zm3 0a.5.5 0 110 1h-1a.5.5 0 110-1h1zm-2-2a.5.5 0 110 1h-8a.5.5 0 110-1h8zm2 0a.5.5 0 110 1 .5.5 0 010-1zm-5-2a.5.5 0 110 1h-5a.5.5 0 110-1h5zm5 0a.5.5 0 110 1h-3a.5.5 0 110-1h3zm-3-2a.5.5 0 110 1h-7a.5.5 0 110-1h7zm3 0a.5.5 0 110 1h-1a.5.5 0 110-1h1zm-6-2a.5.5 0 110 1h-4a.5.5 0 110-1h4zm6 0a.5.5 0 110 1h-4a.5.5 0 110-1h4zm-3-2a.5.5 0 110 1h-6a.5.5 0 110-1h6z" fill="#1976D2" />
                <path d="M0 3.809v16.383a1 1 0 00.79.977l11.396 2.442A1.5 1.5 0 0014 22.145V1.855A1.5 1.5 0 0012.186.39L.79 2.83a1 1 0 00-.79.978z" fill="#1976D2" fillRule="nonzero" />
                <path d="M5.638 15c.104 0 .168-.052.192-.156l1.092-3.972c.008-.024.02-.036.036-.036.016 0 .028.012.036.036l1.152 3.972c.024.104.092.156.204.156h.84a.225.225 0 00.132-.042.168.168 0 00.072-.114l1.704-5.736c.016-.064.01-.114-.018-.15-.028-.036-.074-.054-.138-.054l-.816-.012c-.104 0-.168.052-.192.156L8.77 13.344c-.008.024-.02.036-.036.036-.016 0-.028-.012-.036-.036L7.522 9.06c-.024-.104-.088-.156-.192-.156h-.744c-.104 0-.168.052-.192.156l-1.14 4.272c-.008.024-.02.036-.036.036-.016 0-.028-.012-.036-.036L4.066 9.048c-.024-.104-.088-.156-.192-.156h-.816c-.112 0-.168.048-.168.144 0 .024.004.044.012.06l1.68 5.748c.024.104.092.156.204.156h.852z" fill="#FFF" fillRule="nonzero" />
            </g>
        </SvgIcon>
    );
};

export default WordIcon;
