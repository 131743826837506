export const selectUser = state => state.v1User.singleUser;

export const selectIsLoadingUser = state => state.v1User.isLoadingUser;

export const selectIsUpdatingUser = state => state.v1User.isUpdatingUser;

export const selectIsUpdatingCanSetupCompany = state => state.v1User.isUpdatingCanSetupCompany;

export const selectAdminMustEnterDataBeforeSetupCompany = state => state.v1User.adminMustEnterDataBeforeSetupCompany;

export const selectIsUpdatingUserActiveStatus = state => state.v1User.isUpdatingUserActiveStatus;

export const selectLoggedInUser = state => state.v1User.loggedInUser;

export const selectIsLoadingLoggedInUser = state => state.v1User.isLoadingLoggedInUser;

export const selectIsUpdatingLoggedInUser = state => state.v1User.isUpdatingLoggedInUser;

export const selectCoachAccounts = state => state.v1User.coachAccounts;
