import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import BankAccountStatements from './BankAccountStatements';
import DetailsSection from './DetailsSection';
import RibPreviewAndDownload from './RibPreviewAndDownload';

const RibAndDocuments = () => {
    const isMobileSize = useMediaQuery(`(max-width:1280px)`);

    return (
        <Box sx={{px: {xs: 2, lg: 0}}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobileSize ? 'column' : 'row',
                    gap: 2,
                    mb: 2,
                }}
            >
                <DetailsSection />
                <RibPreviewAndDownload />
            </Box>
            <BankAccountStatements />
        </Box>
    );
};

export default RibAndDocuments;
