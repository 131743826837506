const selectTaxInfo = state => state.dashboard.taxInfo;
const selectDashboardIframeKey = state => state.dashboard.resetDashboardIframe;
const selectIsLoadingDashboardData = state => state.dashboard.isLoading;
const selectDashboardData = state => state.dashboard.data;
const selectSelectedYear = state => state.dashboard.selectedYear;
const selectIsLoadingIncomeCalculation = state => state.dashboard.isLoadingIncomeCalculation;
const selectIsFinishedIncomeCalculation = state => state.dashboard.isFinishedIncomeCalculation;


export const DashboardSelectors = {
    selectIsLoadingDashboardData,
    selectDashboardData,
    selectSelectedYear,
    selectIsLoadingIncomeCalculation,
    selectIsFinishedIncomeCalculation,
    selectTaxInfo,
    selectDashboardIframeKey,
};
