import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SettingsView from '../../features/settings/components/SettingsView';
import PageHeader from '../../v1/components/app/PageHeader/PageHeader';

export const SettingsScreen = () => {
    const {t} = useTranslation('users');

    return (
        <Box sx={{px: {xs: 2, md: 1}}}>
            <Box component="div" sx={{mt: {lg: 0, xs: 3}}}>
                <PageHeader title={t('settings')} hasMargin={false} />
            </Box>
            <Paper
                elevation={0}
                sx={{
                    marginTop: theme => theme.spacing(3),
                    padding: theme => theme.spacing(0),
                    borderRadius: 4,
                }}
            >
                <SettingsView />
            </Paper>
        </Box>
    );
};
