import {Stack} from '@mui/joy';
import React from 'react';

export const ErrPageContainer = ({children}) => (
    <Stack
        alignItems="center"
        sx={{
            justifyContent: {desktop: 'center', mobile: 'space-between'},
            backgroundColor: 'background.level2',
            p: {desktop: 8, mobile: 4},
            minHeight: '100vh',
        }}
    >
        {children}
    </Stack>
);
