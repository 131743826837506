import {
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Chip, Divider} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {AccountsMenuItem} from './AccountsMenuItem';
import DrawerWrapper from '../../../../../../components/drawer-wrapper/DrawerWrapper';
import {BANK_TYPES, BankIntegrationErrorStatuses} from '../../../bridge-api/utils/constants';

const MobileTabs = ({
    isOpen,
    setIsOpen,
    fullBankAccountList,
    onChangeTab,
    hasCombined,
    combinedUncategorized,
    combinedTotal,
    hasHiwayAdd,
    onClickHiwayAdd,
    hasAdd,
    hasArchived,
    onClickAdd,
    tabValue,
}) => {
    const {t} = useTranslation('bank');

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <DrawerWrapper
            isOpen={isOpen}
            onClose={handleClose}
            isV3
            title={t('creationProPage.mobileTabsTitle')}
            text={t('transactionAccountsModal.text')}
        >
            {hasCombined && (
                <AccountsMenuItem
                    label={t('creationProPage.combined')}
                    value={BANK_TYPES.combined}
                    tabValue={tabValue}
                    onChangeTab={onChangeTab}
                    uncategorized={combinedUncategorized}
                    amount={combinedTotal}
                    isMobile
                />
            )}

            {fullBankAccountList.map(account => (
                <AccountsMenuItem
                    key={account.id}
                    label={account.name}
                    value={account.id}
                    onChangeTab={onChangeTab}
                    uncategorized={account.uncategorized}
                    amount={account.amount}
                    color={account?.color}
                    hasError={BankIntegrationErrorStatuses.includes(account?.status)}
                    tabValue={tabValue}
                    isHiway={account.type === BANK_TYPES.hiway}
                    isMobile
                />
            ))}

            {hasArchived && (
                <AccountsMenuItem
                    label={t('creationProPage.archived')}
                    value={BANK_TYPES.archived}
                    tabValue={tabValue}
                    onChangeTab={onChangeTab}
                    isMobile
                />
            )}

            <Divider sx={{my: 2}} />

            {hasHiwayAdd && (
                <Button
                    fullWidth
                    startDecorator={(
                        <FontAwesomeIcon icon={faPlus} />
                    )}
                    endDecorator={(
                        <Chip
                            color="danger"
                            size="sm"
                            variant="solid"
                        >
                            {t('connectProPage.recommended')}
                        </Chip>
                    )}
                    onClick={() => {
                        onClickHiwayAdd();
                        handleClose();
                    }}
                    variant="soft"
                    size="lg"
                    color="primary"
                >
                    {t('creationProPage.addHiwayPro')}
                </Button>
            )}

            {hasAdd && (
                <Button
                    fullWidth
                    startDecorator={(
                        <FontAwesomeIcon icon={faPlus} />
                    )}
                    onClick={() => {
                        onClickAdd();
                        handleClose();
                    }}
                    color="primary"
                    size="lg"
                    variant="outlined"
                >
                    {t(`creationProPage.addButtonTitle`)}
                </Button>
            )}
        </DrawerWrapper>
    );
};

MobileTabs.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    fullBankAccountList: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangeTab: PropTypes.func.isRequired,
    hasCombined: PropTypes.bool.isRequired,
    combinedUncategorized: PropTypes.number.isRequired,
    combinedTotal: PropTypes.string.isRequired,
    hasHiwayAdd: PropTypes.bool.isRequired,
    onClickHiwayAdd: PropTypes.func.isRequired,
    hasAdd: PropTypes.bool.isRequired,
    hasArchived: PropTypes.bool.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    tabValue: PropTypes.string.isRequired,
};

export default MobileTabs;
