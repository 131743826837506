import {Box, CircularProgress, Grow, useMediaQuery} from '@mui/material';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import CreditFacturePanel from './CreditFacturePanel';
import CreditInvoiceMatchingLayout from './CreditInvoiceMatchingLayout';
import DrawerWrapper from '../../../../../../../../components/drawer-wrapper/DrawerWrapper';
import {LoadingSelectors, LoadingTypes} from '../../../../../../../loading';
import {TransactionsActions} from '../../../../store/transactions.action';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {
    SOURCE_OF_REVENUE_OPTIONS,
    TransactionSide,
    TransactionStatus,
} from '../../../../util/constants';
import useInvoiceCategorisation from '../../../hooks/useInvoiceCategorisation';
import PopularCategories from '../categories/PopularCategories';
import {SelectedSource} from '../categories/SelectedSource';
import AllCategoriesList from '../common/AllCategoriesList';
import ExternalInvoiceLayout from '../external-invoice/ExternalInvoiceLayout';
import CategorisationFooter from '../footer/CategorisationFooter';
import CategorisationSection from '../header/CategorisationSection';
import DeactivateSubscriptionModal from '../modals/DeactivateSubscriptionModal';
import PacmanCredit from '../pacman/PacmanCredit';
import CreditReimbusementPanel from '../reinbursement/CreditReimbusementPanel';
import FakeReimbursementLayout from '../reinbursement/FakeReimbursementLayout';
import ReinburnsmentLayout from '../reinbursement/ReinburnsmentLayout';

const currentYear = moment().format('YYYY');
const previousYear = moment(currentYear).subtract(1, 'year').format('YYYY');
const nextYear = moment(currentYear).add(1, 'year').format('YYYY');

const CreditCategorizationPage = ({
    isShowAllCategories,
    setIsShowAllCategories,
    isInvoiceMatchingOpen,
    setIsInvoiceMatchingOpen,
    isReimbursementOpen,
    setIsReimbursementOpen,
    isFakeReimbursementOpen,
    setIsFakeReimbursementOpen,
    isExternalOpen,
    setIsExternalOpen,
    defaultNumberOfUncategorized,
}) => {
    const {t} = useTranslation('bank');
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(
            LoadingTypes.BANK_TRANSACTION,
        ),
    );

    const categorizationInfo = useSelector(TransactionsSelector.selectCategorizationScreenInfo);
    const details = useSelector(TransactionsSelector.createSelectTransactionById(categorizationInfo?.transactionId));
    const totalOverview = useSelector(TransactionsSelector.selectTransactionTotalOverview);
    const totalCategorizedInSession = useSelector(TransactionsSelector.selectTotalCategorizedInSession);

    const mostUsedCategoryList = useSelector(TransactionsSelector.selectMostUsedCreditCategories);

    const isCategorized = details.isCategorized;
    const hasSourceOfRevenue = details.sourceOfRevenue;

    const isRejected = details.status === TransactionStatus.REJECTED;
    const isCancelled = details.status === TransactionStatus.CANCELED;

    const detailsYear = details?.date?.format('YYYY');

    const pacmanStep = isCategorized ? 2 : (hasSourceOfRevenue ? 1 : 0);

    const isEditable = !!(
        detailsYear === currentYear
            || detailsYear === previousYear
            || detailsYear === nextYear
    )
        && !isRejected && !isCancelled;

    const {
        bestMatchedInvoices,
        allOtherInvoices,
        isCategorised,
        selectedInvoicesObj,
        amount,
        selectedInvoicesForPaidInfo,
        allocated,
        unallocatedAmount,
        onSelectInvoice,
        setActiveInvoice,
        activeInvoice,
    } = useInvoiceCategorisation({details, isEditable, dispatch});


    useEffect(() => {
        if (!mostUsedCategoryList) {
            dispatch(TransactionsActions.getMostUsedCategories({type: TransactionSide.CREDIT}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelectSource = source => {
        if (!isEditable || !source) {
            return;
        }

        dispatch(TransactionsActions.saveTransactionField({
            id: details.id,
            fieldKey: 'sourceOfRevenue',
            fieldValue: source,
            accountId: details?.bankAccountId,
        }));
    };

    const handleOpenAllCategories = () => {
        setIsShowAllCategories(true);
    };

    const handleCloseAllCategories = () => {
        setIsShowAllCategories(false);
    };

    const onOpenInvoiceMatching = () => {
        setIsInvoiceMatchingOpen(true);
    };

    const onOpenRembursement = () => {
        setIsReimbursementOpen(true);
    };

    const onSelectCategory = category => {
        if (!isEditable) {
            return;
        }

        // If same is chosen do not save, just close the window for chosing categories
        if (details?.sourceOfRevenue === category) {
            setIsShowAllCategories(false);
            return;
        }

        // On select close screen for category pick
        if (isShowAllCategories) {
            setIsShowAllCategories(false);
        }

        // Close if external invoice is being chosen
        if (isExternalOpen) {
            setIsExternalOpen(false);
        }

        // Close if fake reimbursement is being chosen
        if (isFakeReimbursementOpen) {
            setIsFakeReimbursementOpen(false);
        }

        // Close invoice matching
        if (isInvoiceMatchingOpen) {
            setIsInvoiceMatchingOpen(false);

            // If we switch from invoice to reimbursement open reimbursements
            if (category === 'REIMBURSEMENT_TRANSACTIONS') {
                setIsReimbursementOpen(true);
            }
        }

        // Close invoice matching
        if (isReimbursementOpen) {
            setIsReimbursementOpen(false);

            // If we switch from reimbursement to invoice open invoice
            if (category === 'INVOICE') {
                setIsInvoiceMatchingOpen(true);
            }
        }

        onSelectSource(category);
    };

    const isInvoice = details?.sourceOfRevenue === SOURCE_OF_REVENUE_OPTIONS.INVOICE;
    const isReimbursement = details?.sourceOfRevenue === SOURCE_OF_REVENUE_OPTIONS.REIMBURSEMENT_TRANSACTIONS;

    if (isLoading) {
        return (
            <>
                <Box sx={{
                    width: isMobileSize ? '100vw' : '600px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    alignSelf: 'center',
                    p: isMobileSize ? 2 : 0,
                    pb: isMobileSize ? 3 : 0,
                    my: isMobileSize ? 0 : 4,
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        alignSelf: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '450px',
                    }}
                    >
                        <CircularProgress />
                    </Box>
                </Box>
                <CategorisationFooter
                    categorized={totalCategorizedInSession}
                    defaultNumberOfUncategorized={defaultNumberOfUncategorized}
                    nonCategorized={totalOverview?.totalUncategorizedTransactions}
                    details={details}
                />
            </>
        );
    }

    if (isShowAllCategories) {
        if (!isMobileSize) {
            return (
                <Grow in={true}>
                    <Box sx={{
                        width: '100vw',
                        p: 3,
                    }}
                    >
                        <AllCategoriesList
                            onSelectCategory={onSelectCategory}
                            side={TransactionSide.CREDIT}
                            text={isShowAllCategories}
                            setIsShowAllCategories={setIsShowAllCategories}
                            mccKey={hasSourceOfRevenue}
                        />
                    </Box>
                </Grow>
            );
        }

        return (
            <DrawerWrapper
                isOpen={isShowAllCategories}
                onClose={handleCloseAllCategories}
                containerSx={{
                    pb: 5,
                }}
            >
                <AllCategoriesList
                    onSelectCategory={onSelectCategory}
                    side={TransactionSide.CREDIT}
                    onClose={() => setIsShowAllCategories(false)}
                    setIsShowAllCategories={setIsShowAllCategories}
                    mccKey={hasSourceOfRevenue}
                />
            </DrawerWrapper>
        );
    }

    // Should be before invoice matching because it can be opened on top of it
    if (isExternalOpen) {
        return (
            <ExternalInvoiceLayout
                details={details}
                handleOpenAllCategories={handleOpenAllCategories}
                data={{
                    amount,
                    allocated,
                    selectedInvoicesForPaidInfo,
                    isCategorised,
                    selectedInvoicesObj,
                    unallocatedAmount,
                    onSelectInvoice,
                    bestMatchedInvoices,
                    allOtherInvoices,
                    setIsExternalOpen,
                }}
            />
        );
    }

    if (isFakeReimbursementOpen) {
        return (
            <FakeReimbursementLayout
                details={details}
                handleOpenAllCategories={handleOpenAllCategories}
                setIsFakeReimbursementOpen={setIsFakeReimbursementOpen}
            />
        );
    }

    if (isInvoiceMatchingOpen) {
        return (
            <CreditInvoiceMatchingLayout
                details={details}
                isEditable={isEditable}
                isExternalOpen={isExternalOpen}
                setIsExternalOpen={setIsExternalOpen}
                handleOpenAllCategories={handleOpenAllCategories}
                data={{
                    amount,
                    allocated,
                    selectedInvoicesForPaidInfo,
                    isCategorised,
                    selectedInvoicesObj,
                    unallocatedAmount,
                    onSelectInvoice,
                    bestMatchedInvoices,
                    allOtherInvoices,
                    setActiveInvoice,
                    activeInvoice,
                }}
            />
        );
    }

    if (isReimbursementOpen) {
        return (
            <ReinburnsmentLayout
                details={details}
                isEditable={isEditable}
                handleOpenAllCategories={handleOpenAllCategories}
                setIsFakeReimbursementOpen={setIsFakeReimbursementOpen}
            />
        );
    }

    return (
        <>
            <Box sx={{
                width: isMobileSize ? '100vw' : '600px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignSelf: 'center',
                p: isMobileSize ? 2 : 0,
                pb: isMobileSize ? 3 : 0,
                my: isMobileSize ? 0 : 4,
            }}
            >
                <Grow in={true}>
                    <div>
                        <PacmanCredit
                            step={pacmanStep}
                            isMobileSize={isMobileSize}
                            isReimbursement={isReimbursement}
                        />
                    </div>
                </Grow>

                {!hasSourceOfRevenue ? (
                    <Grow in={true}>
                        <div>
                            <PopularCategories
                                setIsShowAllCategories={setIsShowAllCategories}
                                onSelectCategory={onSelectCategory}
                                mostUsedCategoryList={mostUsedCategoryList}
                                side={TransactionSide.CREDIT}
                                isRejectedOrCancelled={false}
                            />
                        </div>
                    </Grow>
                ) : (
                    <Grow in={true}>
                        <div>
                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                alignSelf: 'stretch',
                            }}
                            >
                                <CategorisationSection
                                    title={t(`categorisation.section.creditCategory`)}
                                    sx={{
                                        flex: '2 0 0',
                                        p: 2,
                                        pt: 1,
                                        justifyContent: 'space-between',
                                        gap: 1.5,
                                    }}
                                    isActive
                                >
                                    <SelectedSource
                                        selected={details.sourceOfRevenue}
                                        onOpen={handleOpenAllCategories}
                                    />
                                </CategorisationSection>
                            </Box>
                        </div>
                    </Grow>
                )}

                {isInvoice && (
                    <Grow in={true}>
                        <div>
                            <CreditFacturePanel
                                onOpenInvoiceMatching={onOpenInvoiceMatching}
                                isEditable={isEditable}
                                details={details}
                            />
                        </div>
                    </Grow>
                )}

                {isReimbursement && (
                    <Grow in={true}>
                        <div>
                            <CreditReimbusementPanel
                                onOpenRembursement={onOpenRembursement}
                                isEditable={isEditable}
                                details={details}
                                setIsFakeReimbursementOpen={setIsFakeReimbursementOpen}
                            />
                        </div>
                    </Grow>
                )}

            </Box>

            <CategorisationFooter
                categorized={totalCategorizedInSession}
                nonCategorized={totalOverview?.totalUncategorizedTransactions}
                defaultNumberOfUncategorized={defaultNumberOfUncategorized}
                details={details}
            />
            <DeactivateSubscriptionModal />
        </>
    );
};

CreditCategorizationPage.propTypes = {
    isShowAllCategories: PropTypes.bool.isRequired,
    setIsShowAllCategories: PropTypes.func.isRequired,
    isInvoiceMatchingOpen: PropTypes.bool.isRequired,
    setIsInvoiceMatchingOpen: PropTypes.func.isRequired,
    isReimbursementOpen: PropTypes.bool.isRequired,
    setIsReimbursementOpen: PropTypes.func.isRequired,
    isExternalOpen: PropTypes.bool.isRequired,
    setIsExternalOpen: PropTypes.func.isRequired,
    isFakeReimbursementOpen: PropTypes.bool.isRequired,
    setIsFakeReimbursementOpen: PropTypes.func.isRequired,
    defaultNumberOfUncategorized: PropTypes.number.isRequired,
};

export default CreditCategorizationPage;
