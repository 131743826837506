import Decimal from 'decimal.js';
import {createSelector} from 'reselect';
import {isUserCareOrFreelancer} from '../../../utils/user-roles';
import {selectCompany} from '../../../v1/app/company/company.selectors';
import {createDefaultCompanySelector} from '../../../v1/app/company/companyList/companyList.selectors';
import {selectUser} from '../../../v1/app/user/user.selectors';
import {BankIntegrationStatus} from '../../bank/modules/bridge-api/utils/constants';
import {getDashboardDatepickerCurrentYear} from '../../dashboard/util/dashboard.util';
import {LoadingSelectors, LoadingTypes} from '../../loading';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {CategorizationIndicatorStates, InvoicingIndicatorStates, TreasuryIndicatorStates} from '../utils/constants';

const toSafeNumber = value => Number(value || 0);

const {selectLoggedInUser} = LoggedInUserSelectors;

export const selectIsRemunerationInstantTransferOpen = state => state.dashboardV3.isRemunerationInstantTransferOpen;

export const selectIsFetchingDashboard = LoadingSelectors.createLoadingSelectorByType(
    LoadingTypes.FETCH_DASHBOARD_V3_DATA,
);

/*
 * Select user and company for which dashboard will be displayed.
 * If logged in user is freelancer/care, use its data
 * If logged in user is admin, then use data for the selected company
 */
const selectLoggedInUserDefaultCompany = createDefaultCompanySelector();

export const selectDashboardUser = createSelector([selectLoggedInUser, selectUser], (loggedInUser, selectedUser) => {
    if (isUserCareOrFreelancer(loggedInUser)) {
        return loggedInUser;
    }
    return selectedUser;
});

export const selectDashboardCompany = createSelector(
    [selectLoggedInUser, selectCompany, selectLoggedInUserDefaultCompany],
    (loggedInUser, selectedCompany, loggedInUserDefaultCompany) => {
        if (isUserCareOrFreelancer(loggedInUser)) {
            return loggedInUserDefaultCompany;
        }
        return selectedCompany;
    },
);

export const selectBankAccounts = state => state.dashboardV3.data?.bankAccounts;
export const selectFinancialData = state => state.dashboardV3.data?.financialData;
const selectInvoiceStatsIndicators = state => state.dashboardV3.data?.invoiceStatsIndicators;
export const selectExpensesStats = state => state.dashboardV3.data?.expensesStats;
export const selectRemunerationStats = state => state.dashboardV3.data?.remunerationStats;
export const selectSocialTaxStats = state => state.dashboardV3.data?.socialTaxStats;

export const selectIsDashboardLoaded = state => !!state.dashboardV3.data;

// TODO: Implement year picker
export const selectDashboardYear = () => getDashboardDatepickerCurrentYear();

// ============================================================================
// Overview / Treasury Selectors
// ============================================================================

export const selectMinimumCashThreshold = createSelector([selectFinancialData], financialData => {
    return toSafeNumber(financialData?.cashManagement.minimumCashThreshold);
});

export const selectTotalAvailableBalance = createSelector([selectBankAccounts], bankAccounts => {
    return toSafeNumber(bankAccounts?.totalAvailableBalance);
});

export const selectTreasuryAmountToSecure = createSelector(
    [selectTotalAvailableBalance, selectMinimumCashThreshold],
    (availableBalance, minThreshold) => new Decimal(minThreshold).sub(availableBalance).toNumber(),
);

const selectHasConnectedBank = createSelector([selectBankAccounts], bankAccounts => {
    return bankAccounts?.overviewPerAccount.some(overview => overview.status === BankIntegrationStatus.ACTIVE);
});

export const selectTreasuryIndicatorState = createSelector(
    [selectIsDashboardLoaded, selectTotalAvailableBalance, selectHasConnectedBank, selectTreasuryAmountToSecure],
    (isDashboardLoaded, totalAvailableBalance, hasConnectedBank, amountToSecure) => {
        if (!isDashboardLoaded) {
            return null;
        }
        if (!hasConnectedBank) {
            return TreasuryIndicatorStates.NO_CONNECTED_BANK;
        }

        if (hasConnectedBank && totalAvailableBalance === 0) {
            return TreasuryIndicatorStates.ACCOUNT_BALANCE_ZERO;
        }

        if (amountToSecure <= 0) {
            return TreasuryIndicatorStates.OK;
        }

        if (amountToSecure > 0) {
            return amountToSecure <= 3000 ? TreasuryIndicatorStates.INTERMEDIARY : TreasuryIndicatorStates.ALERTING;
        }
    },
);

// ============================================================================
// Overview / Invoicing Selectors
// ============================================================================

const selectTotalInvoicesCount = createSelector([selectInvoiceStatsIndicators], stats => stats?.totalInvoicesCount);

export const selectMaxInvoiceOverdueDays = createSelector(
    [selectInvoiceStatsIndicators],
    stats => stats?.maximumDaysDue,
);

export const selectUnpaidInvoicesCount = createSelector(
    [selectInvoiceStatsIndicators],
    stats => stats?.overdueInvoicesCount || 0,
);

export const selectInvoicingIndicatorState = createSelector(
    [selectIsDashboardLoaded, selectTotalInvoicesCount, selectUnpaidInvoicesCount, selectMaxInvoiceOverdueDays],
    (isDashboardLoaded, totalInvoicesCount, unpaidInvoicesCount, maxOverdueDays) => {
        if (!isDashboardLoaded) {
            return null;
        }
        if (totalInvoicesCount === 0) {
            return InvoicingIndicatorStates.NO_INVOICES_YET;
        }
        if (unpaidInvoicesCount === 0 || maxOverdueDays === 0) {
            return InvoicingIndicatorStates.OK;
        }
        if (unpaidInvoicesCount === 1 && maxOverdueDays <= 3) {
            return InvoicingIndicatorStates.INTERMEDIARY;
        }
        if (unpaidInvoicesCount > 1 || maxOverdueDays > 3) {
            return InvoicingIndicatorStates.ALERTING;
        }
    },
);

// ============================================================================
// Overview / Categorization Selectors
// ============================================================================

const selectTotalTransactions = createSelector([selectBankAccounts], bankAccounts => bankAccounts?.totalTransactions);

export const selectTotalUncategorizedTransactions = createSelector(
    [selectBankAccounts],
    bankAccounts => bankAccounts?.totalUncategorizedTransactions,
);
export const selectTotalUncategorizedAmount = createSelector([selectBankAccounts], bankAccounts => {
    return toSafeNumber(bankAccounts?.totalUncategorizedAmount);
});

export const selectCategorizationIndicatorState = createSelector(
    [
        selectIsDashboardLoaded,
        selectTotalTransactions,
        selectHasConnectedBank,
        selectTotalUncategorizedAmount,
        selectTotalUncategorizedTransactions,
    ],
    (
        isDashboardLoaded,
        totalTransactions,
        hasConnectedBank,
        totalUncategorizedAmount,
        totalUncategorizedTransactions,
    ) => {
        if (!isDashboardLoaded) {
            return null;
        }
        if (!hasConnectedBank) {
            return CategorizationIndicatorStates.NO_CONNECTED_BANK;
        }
        if (hasConnectedBank && totalTransactions === 0) {
            return CategorizationIndicatorStates.NO_TRANSACTIONS_YET;
        }
        if (totalUncategorizedTransactions === 0) {
            return CategorizationIndicatorStates.OK;
        }
        if (totalUncategorizedTransactions > 0) {
            return toSafeNumber(totalUncategorizedAmount) <= 500
                ? CategorizationIndicatorStates.INTERMEDIARY
                : CategorizationIndicatorStates.ALERTING;
        }
    },
);

// ============================================================================
// Overview / At a Glance Selectors
// ============================================================================
export const selectAnnualTurnover = createSelector([selectFinancialData], financialData => {
    return toSafeNumber(financialData?.turnover.annual);
});

export const selectMonthlyExpenses = createSelector([selectExpensesStats], stats => {
    return toSafeNumber(stats?.currentMonthExpensesExcludingVat);
});

export const selectAnnualPaidRemuneration = createSelector([selectFinancialData], financialData => {
    return toSafeNumber(financialData?.income.annualPaidRemuneration);
});

// ============================================================================
// Remuneration
// ============================================================================

export const selectLastMonthExpectedRemuneration = createSelector([selectFinancialData], financialData => {
    const decimalValue = new Decimal(financialData?.income.lastMonthExpectedRemuneration || 0);
    // Round down to the nearest multiple of 100
    return decimalValue.div(100).floor().times(100).toNumber();
});
