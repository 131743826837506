export const isDDMMYYYYFormat = input => {
    const reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
    return !!input?.match(reg);
};

export const dateComparator = (dateFieldName, order = 'desc') => (a, b) => {
    const dateA = new Date(a[dateFieldName]);
    const dateB = new Date(b[dateFieldName]);

    if (order === 'asc') {
        return dateA < dateB ? -1 : (
            dateA > dateB ? 1 : 0
        );
    }

    return dateA > dateB ? -1 : (
        dateA < dateB ? 1 : 0
    );
};
