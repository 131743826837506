import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getDatePickerToolbarUtilityClass = slot => {
    return generateUtilityClass('MuiDatePickerToolbar', slot);
};

export const datePickerToolbarClasses = generateUtilityClasses(
    'MuiDatePickerToolbar',
    ['root', 'title'],
);
