import {combineReducers} from 'redux';

import * as actions from './companyDocuments.actions';
import {currentDocumentReducer} from './currentDocument/currentDocument.reducer';
import {uploadDocumentsReducer} from './uploadDocuments/uploadDocuments.reducer';
import {createReducer} from '../../createReducer';

export const companyDocumentsReducer = combineReducers({
    companyDocuments: createReducer({}, actions.STORE_COMPANY_DOCUMENTS, true),
    atLastOnePayCategoryDocumentExists: createReducer(false, actions.STORE_AT_LEAST_ONE_PAY_DOCUMENT_EXISTS, false),
    hiwayDocuments: createReducer({}, actions.STORE_HIWAY_DOCUMENTS, true),
    isLoading: createReducer(true, actions.SET_IS_LOADING_COMPANY_DOCUMENTS, true),
    currentDocument: currentDocumentReducer,
    uploadDocuments: uploadDocumentsReducer,
    hasDocumentGenerationError: createReducer(false, actions.SET_HAS_DOCUMENT_GENERATION_ERROR, true),
    isAllDocumentsSignedAlertOpen: createReducer(false, actions.SET_IS_ALL_DOCUMENTS_SIGNED_ALERT_OPEN, true),
    hasUnsignedDocuments: createReducer(true, actions.SET_HAS_UNSIGNED_DOCUMENTS, true),
});
