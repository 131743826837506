import React from 'react';
import {useSelector} from 'react-redux';
import {FundingApprovalDocument} from './FundingApprovalDocument';
import {FundingApprovalToggle} from './FundingApprovalToggle';
import {TrainingSelector} from '../../store/training.selector';
import {TRAINING_CONSTANTS} from '../../utils/constants';

const FundingApproval = () => {
    const subSstep = useSelector(TrainingSelector.getSubstep);
    let component = null;

    switch (subSstep) {
        case TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING:
        case TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_REJECTED:
        case TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE:
            component = <FundingApprovalToggle />;
            break;
        case TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_CHECKLIST:
            component = <FundingApprovalDocument />;
            break;
    }

    return component;
};

export default FundingApproval;
