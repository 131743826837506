import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {FormControlLabel, Radio, Typography} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import PropTypes from 'prop-types';
import React from 'react';

const RadioAccordion = ({value, label, isDisabled, text}) => {
    return (
        <Accordion
            sx={{
                'border': '1px solid rgba(0, 0, 0, 0.12)',
                'borderColor': 'v2.light.other.outlined_border',
                'borderRadius': '16px !important',
                '&.MuiPaper-root.MuiAccordion-root': {
                    borderRadius: '8px',
                    mt: 2,
                },
                '&:before': {
                    backgroundColor: 'transparent',
                },
            }}
            elevation={0}
        >
            <AccordionSummary
                sx={{
                    'borderRadius': '16px !important',
                    '> .Mui-expanded': {
                        m: '0 !important',
                    },
                }}
                expandIcon={<KeyboardArrowDownIcon />}
                aria-controls="panel1a-content"
            >
                <FormControlLabel
                    disabled={isDisabled}
                    sx={{
                        '> .MuiTypography-root': {
                            fontWeight: '600 !important',
                        },
                    }}
                    value={value}
                    control={<Radio />}
                    label={label}
                />
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    mt: -2,
                }}
            >
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(14),
                    pl: 3.5,
                }}
                >
                    {text}
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};

RadioAccordion.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    text: PropTypes.string.isRequired,
};

RadioAccordion.defaultProps = {
    isDisabled: false,
};

export default RadioAccordion;
