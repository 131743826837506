import {Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const TrainingHeadline = () => {
    const {t} = useTranslation('training');

    return (
        <Typography variant="h4">
            {t('header')}
        </Typography>
    );
};
