import {
    CHANGE_USERNAME_FIELD,
    CLEAR_USERNAME_ERROR,
    SET_IS_LOADING_RESET_PASSWORD_FORM,
    STORE_CODE_SENT_SUCCESS,
    STORE_RESET_PASSWORD_FORM_PASSWORD_ERROR_CODE,
    STORE_RESET_PASSWORD_FORM_VERIFICATION_CODE_ERROR_CODE,
    STORE_RESET_PASSWORD_USERNAME_ERROR_CODE,
    SUBMIT_CONFIRM_RESET_PASSWORD_FORM,
    SUBMIT_RESET_PASSWORD_FORM,
} from './resetPasswordForm.actions';

const initialState = {
    username: '',
    usernameErrorCode: '',
    verificationCodeErrorCode: '',
    passwordErrorCode: '',
    isLoading: false,
    isCodeSent: false,
};

export const resetPasswordFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_USERNAME_FIELD:
            return {
                ...state,
                username: action.payload,
            };

        case SUBMIT_RESET_PASSWORD_FORM:
            return {
                ...state,
                usernameErrorCode: '',
                isLoading: true,
            };

        case SUBMIT_CONFIRM_RESET_PASSWORD_FORM:
            return {
                ...state,
                verificationCodeErrorCode: '',
                passwordErrorCode: '',
                isLoading: true,
            };

        case STORE_RESET_PASSWORD_USERNAME_ERROR_CODE:
            return {
                ...state,
                usernameErrorCode: action.payload,
                isLoading: false,
            };

        case CLEAR_USERNAME_ERROR:
            return {
                ...state,
                usernameErrorCode: '',
            };

        case STORE_RESET_PASSWORD_FORM_PASSWORD_ERROR_CODE:
            return {
                ...state,
                passwordErrorCode: action.payload,
                verificationCodeErrorCode: '',
                isLoading: false,
            };

        case STORE_RESET_PASSWORD_FORM_VERIFICATION_CODE_ERROR_CODE:
            return {
                ...state,
                verificationCodeErrorCode: action.payload,
                passwordErrorCode: '',
                isLoading: false,
            };

        case STORE_CODE_SENT_SUCCESS:
            return {
                ...state,
                isCodeSent: true,
                isLoading: false,
                usernameErrorCode: '',
            };

        case SET_IS_LOADING_RESET_PASSWORD_FORM:
            return {
                ...state,
                isLoading: action.payload,
            };

        default:
            return state;
    }
};
