import {Box, Grow, Slide} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AnimationsSelectors} from '../../../animations/store/animations.selector';
import {getStepNumber} from '../../utils/helper';

export const TrainingStage = ({step}) => {
    const {t} = useTranslation('training');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const stepNumber = useMemo(() => {
        return getStepNumber(step);
    }, [step]);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    if (!step) {
        // TODO Add skeleton?
        return null;
    }

    if (!isMobileSize) {
        return (
            <Box sx={{
                fontSize: theme => theme.typography.pxToRem(isMobileSize ? 16 : 21),
                fontWeight: isMobileSize ? 600 : 500,
            }}
            >
                <Box
                    component="span"
                    sx={{
                        color: 'secondary.main',
                    }}
                >
                    <Grow in={isAnimationActive}>
                        <span>{t('stage.stage')} {stepNumber}:</span>
                    </Grow>
                </Box>
                <Slide in={isAnimationActive} direction="left" timeout={150}>
                    <Box sx={{display: 'inline-block', pl: 0.6}}>{t(`stage.${step}`)}</Box>
                </Slide>
            </Box>
        );
    }

    return (
        <Box sx={{
            fontSize: theme => theme.typography.pxToRem(isMobileSize ? 16 : 21),
            fontWeight: isMobileSize ? 600 : 500,
        }}
        >
            <Box
                component="span"
                sx={{
                    color: 'secondary.main',
                }}
            >
                {t('stage.stage')} {stepNumber}:
            </Box>
            <Box component="span" sx={{display: 'inline', pl: 0.6}}>{t(`stage.${step}`)}</Box>
        </Box>
    );
};

TrainingStage.propTypes = {
    step: PropTypes.string,
};

TrainingStage.defaultProps = {
    step: '',
};
