import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import {useDataItemRowStyles} from './styles';

const DataItemRow = ({label, content, actions, hasBorderDivider}) => {
    const styles = useDataItemRowStyles();

    const hasActions = !!actions;

    return (
        <div
            className={clsx(styles.container, {
                [styles.hasBorderDivider]: hasBorderDivider,
            })}
        >
            <Grid container alignItems="center" className={styles.gridContainer}>
                <Grid item md={6} xs={12} className={styles.labelContainer}>
                    {label}
                </Grid>

                <Grid item container md={hasActions ? 5 : 6} xs={12} className={styles.valueContainer}>
                    {content}
                </Grid>

                {!!hasActions && (
                    <Grid item md={1} xs={12} className={styles.actions}>
                        {actions}
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

DataItemRow.propTypes = {
    label: PropTypes.node.isRequired,
    content: PropTypes.node,
    actions: PropTypes.node,
    hasBorderDivider: PropTypes.bool,
};

DataItemRow.defaultProps = {
    content: '',
    actions: null,
    hasBorderDivider: true,
};

export default DataItemRow;
