import {Box, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import AxaLogo from '../AxaLogo';

const RcLeftCardHeader = () => {
    const {t} = useTranslation('insurance');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: isMobileSize ? 'column' : 'row',
            gap: isMobileSize ? 1 : null,
            justifyContent: 'space-between',
            alignItems: isMobileSize ? 'flex-start' : 'center',
            alignSelf: 'stretch',
            width: '100%',
        }}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'stretch',
                gap: 2.5,
            }}
            >
                <AxaLogo />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '2px',
                }}
                >
                    <Typography sx={{
                        fontSize: theme => theme.typography.pxToRem(18),
                        fontWeight: 500,
                        color: 'v2.blueGray.500',
                    }}
                    >
                        {t('tabs.rc.price')}
                    </Typography>
                    <Typography sx={{
                        fontSize: theme => theme.typography.pxToRem(24),
                        lineHeight: '116.7%',
                        fontWeight: 700,
                    }}
                    >
                        {t('tabs.rc.title')}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
            }}
            >
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(24),
                    fontWeight: 700,
                }}
                >
                    430€
                </Typography>
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(20),
                    lineHeight: '137.5%',
                    fontWeight: 500,
                    color: 'v2.blueGray.500',
                }}
                >
                    {t('tabs.rc.tax')}
                </Typography>
            </Box>
        </Box>
    );
};

export default RcLeftCardHeader;
