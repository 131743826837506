import {Box, Grow, Paper, Slide} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FundingApprovalButtons} from './FundingApprovalButtons';
import {FundingApproved} from './FundingApproved';
import {FundingApproveDateAlertModal} from './FundingApproveDateAlertModal';
import {FundingApproveWarningModal} from './FundingApproveWarningModal';
import {FundingButtonToggle} from './FundingButtonToggle';
import {FundingPending} from './FundingPending';
import {FundingRefused} from './FundingRefused';
import {FundingRefusedSent} from './FundingRefusedSent';
import {AnimationActions} from '../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../animations/utils/constants';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {TrainingActions} from '../../store/training.action';
import {TrainingSelector} from '../../store/training.selector';
import {TRAINING_CONSTANTS} from '../../utils/constants';

export const FundingApprovalToggle = () => {
    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const trainingInfo = useSelector(TrainingSelector.getTrainingInfo);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.TRAINING_STEP_SUBMIT),
    );

    const isDownloading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.TRAINING_ZIP_DOWNLOAD),
    );

    const onButtonClick = () => {
        if (!isChecked) {
            return;
        }

        dispatch(TrainingActions.submitStep(TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_CHECKLIST));
    };

    const onDownloadClick = () => {
        dispatch(TrainingActions.downloadZip(TRAINING_CONSTANTS.ZIP_VARIANTS.TRAINING_STEP_4));
    };

    const onCheckboxClick = e => {
        const checked = e.target.checked;
        setIsChecked(checked);
    };

    const subSstep = useSelector(TrainingSelector.getSubstep);
    let component = null;

    switch (subSstep) {
        case TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_PENDING:
            component = <FundingPending />;
            break;
        case TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_REJECTED:
            component = trainingInfo?.isAtlasValidationEmailSent ? <FundingRefusedSent /> : <FundingRefused />;
            break;
        case TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE:
            component = <FundingApproved />;
            break;
    }

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const onStatusChange = () => {
        if (isChecked) {
            setIsChecked(false);
        }
    };

    return (
        <Box sx={{
            px: {
                xs: 2,
                md: 0,
            },
        }}
        >
            <Paper
                elevation={1}
                sx={{borderRadius: 2,
                    p: {
                        xs: 2,
                        md: 5,
                    },
                    pb: {
                        xs: 4,
                        md: 5,
                    }}}
            >
                <Slide in={isAnimationActive} direction="left" timeout={100}>
                    <Box sx={{mb: 3}}>
                        <FundingButtonToggle isMobileSize={isMobileSize} onStatusChange={onStatusChange} />
                    </Box>
                </Slide>
                <Grow in={isAnimationActive} onExited={onEndedAnimation}>
                    <Box>{component}</Box>
                </Grow>
            </Paper>
            <Slide in={isAnimationActive} direction="left" timeout={200}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: isMobileSize ? 'column' : 'row',
                    justifyContent: 'space-between',
                    mt: 2,
                }}
                >
                    <FundingApprovalButtons
                        isChecked={isChecked}
                        onButtonClick={onButtonClick}
                        onCheckboxClick={onCheckboxClick}
                        onDownloadClick={onDownloadClick}
                        isDisabled={
                            subSstep !== TRAINING_CONSTANTS.TRAINING_STATUS.ATLAS_VALIDATION_SUCCESS_DOCUMENTS_PAGE
                        }
                        isLoading={isLoading}
                        isDownloading={isDownloading}
                    />
                </Box>
            </Slide>

            <FundingApproveWarningModal />
            <FundingApproveDateAlertModal />
        </Box>
    );
};

