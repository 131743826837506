import {useSelector} from 'react-redux';
import {StepWrapper} from './components/StepWrapper';
import {stepsConfig} from './utils/stepsConfig';
import {useInputSteps} from './utils/useInputSteps';
import {RevenueSimulatorSelectors} from '../../store/revenue-simulator.selector';

export const RevenueSimulator = () => {
    const currentStepIndex = useSelector(RevenueSimulatorSelectors.selectCurrentStepIndex);
    const inputSteps = useInputSteps();
    const stepName = inputSteps[currentStepIndex];
    const {component: Component, props} = stepsConfig[stepName] || {};

    return Component ? (
        <>
            <StepWrapper key={currentStepIndex} {...props}>
                <Component />
            </StepWrapper>
        </>
    ) : null;
};
