import {
    faFilter,
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, IconButton, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {AccountBalanceMobileFilter} from '../account-balance/AccountBalanceMobileFilter';
import {AccountBalanceSearch} from '../account-balance/AccountBalanceSearch';

const FiltersMobileWrapper = ({
    filter,
    setFilter,
    pageTitle,
    searchPlaceholder,
}) => {
    const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);

    const hasNoFilter = (filter?.transactionTypes?.length === 0)
        && !(filter.isNotCategorized)
        && !(filter.startDate || filter.endDate);

    return (
        <Stack
            direction="column"
            gap={2}
        >
            <Stack
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography
                    level="h2"
                    flexGrow={1}
                >
                    {pageTitle}
                </Typography>
                <IconButton
                    variant={isMobileSearchOpen ? 'solid' : 'outlined'}
                    size="lg"
                    color={isMobileSearchOpen ? 'primary' : 'neutral'}
                    onClick={() => {
                        setIsMobileSearchOpen(prevValue => !prevValue);
                    }}
                >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </IconButton>
                <Badge
                    color="primary"
                    invisible={hasNoFilter}
                >
                    <IconButton
                        variant={isMobileFilterOpen ? 'solid' : 'outlined'}
                        size="lg"
                        color={isMobileFilterOpen ? 'primary' : 'neutral'}
                        onClick={() => {
                            setIsMobileFilterOpen(prevValue => !prevValue);
                        }}
                    >
                        <FontAwesomeIcon icon={faFilter} />
                    </IconButton>
                </Badge>
            </Stack>

            {isMobileSearchOpen && (
                <AccountBalanceSearch
                    filter={filter}
                    setFilter={setFilter}
                    searchPlaceholder={searchPlaceholder}
                    isMobile
                />
            )}

            <AccountBalanceMobileFilter
                isOpen={isMobileFilterOpen}
                setIsOpen={setIsMobileFilterOpen}
                filter={filter}
                setFilter={setFilter}
            />
        </Stack>
    );
};

FiltersMobileWrapper.propTypes = {
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    pageTitle: PropTypes.string.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
};

export default FiltersMobileWrapper;
