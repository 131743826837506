import React from 'react';
import {SubmitOpportunity} from '../../features/opportunity/components/SubmitOpportunity';

const SubmitOpportunityScreen = () => {
    return (
        <SubmitOpportunity />
    );
};

export default SubmitOpportunityScreen;
