import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Box, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import {SettingsSelectors} from '../../../../../../../settings/store/settings.selectors';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {AccountBalanceHelperFunctions} from '../../../../util/functions';
import {createIconFromName} from '../../../../util/icon';

const selectedStyles = () => ({
    fontSize: theme => theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 1,
    cursor: 'pointer',
    color: 'white',
    backgroundColor: 'primary_v2.main',
    borderRadius: '8px',
    gap: 2,
});

export const SelectedSource = ({
    onOpen,
    selected,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    const selectSourcesObject = useSelector(TransactionsSelector.selectSourcesObject);

    const {iconName} = AccountBalanceHelperFunctions.getColorAndIconName({
        selectSourcesObject,
        sourceOfRevenue: selected,
    });

    return (
        <Box
            sx={selectedStyles(true, false)}
            onClick={onOpen}
        >
            <Box sx={{'display': 'flex', 'alignItems': 'center'}}>
                {createIconFromName(iconName)}
                <Typography sx={{
                    ml: 1.2,
                    color: '#fff',
                }}
                >
                    {selectSourcesObject[selected]?.[language] ?? selected}
                </Typography>
            </Box>
            {
                isMobileSize
                    ? (<KeyboardArrowDownIcon />)
                    : (<EditIcon />)
            }
        </Box>
    );
};

SelectedSource.propTypes = {
    onOpen: PropTypes.func.isRequired,
    selected: PropTypes.string,
};

SelectedSource.defaultProps = {
    selected: null,
};
