import {ContractActionTypes} from './contract.action-type';

export const ContractActions = {
    getContractData: () => ({
        type: ContractActionTypes.GET_CONTRACT_DATA,
    }),
    storeContractData: contractData => ({
        type: ContractActionTypes.STORE_CONTRACT_DATA,
        payload: contractData,
    }),
};
