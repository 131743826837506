import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const BankNewPageWrapper = ({returnText, onBackClick, children}) => {
    return (
        <Box sx={{px: 2}}>
            <Box
                onClick={onBackClick}
                sx={{display: 'flex', alignItems: 'center', mb: 3}}
            >
                <ArrowBackIcon sx={{mr: 2.5}} />
                {returnText}
            </Box>

            <Box>
                {children}
            </Box>
        </Box>
    );
};

BankNewPageWrapper.propTypes = {
    returnText: PropTypes.string.isRequired,
    onBackClick: PropTypes.func.isRequired,
};

export default BankNewPageWrapper;
