import {Checkbox as MaterialCheckbox} from '@mui/material';
import React from 'react';
import {useCheckboxStyles} from './styles';

const Checkbox = ({...props}) => {
    const styles = useCheckboxStyles();

    return <MaterialCheckbox icon={<span className={styles.icon} />} {...props} />;
};

export default Checkbox;
