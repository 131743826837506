export const InvoiceActionType = {
    SET_INVOICE_SIDEBAR: 'invoice/SET_INVOICE_SIDEBAR',
    SET_INVOICE_SIDEBAR_DATA: 'invoice/SET_INVOICE_SIDEBAR_DATA',

    STORE_INVOICE: 'invoice/STORE_INVOICE',
    GET_INVOICE_STATS: 'invoice/GET_INVOICE_STATS',
    STORE_INVOICE_STATS: 'invoice/STORE_INVOICE_STATS',
    FINALIZE_INVOICE: 'invoice/FINALIZE_INVOICE',
    GET_INVOICE_DRAFT: 'invoice/GET_INVOICE_DRAFT',
    SAVE_INVOICE_DRAFT: 'invoice/SAVE_INVOICE_DRAFT',
    STORE_INVOICE_DRAFT: 'invoice/STORE_INVOICE_DRAFT',
    STORE_INVOICE_DRAFT_ERROR: 'invoice/STORE_INVOICE_DRAFT_ERROR',
    DELETE_INVOICE: 'invoice/DELETE_INVOICE',
    CREATE_EXTERNAL_INVOICE: 'invoice/CREATE_EXTERNAL_INVOICE',
    DELETE_EXTERNAL_INVOICE: 'invoice/DELETE_EXTERNAL_INVOICE',
    STORE_FINALIZATION_RESULT: 'invoice/STORE_FINALIZATION_RESULT',
    GET_INVOICE_PREVIEW: 'invoice/GET_INVOICE_PREVIEW',
    STORE_INVOICE_PREVIEW: 'invoice/STORE_INVOICE_PREVIEW',
    STORE_INVOICE_PREVIEW_FILE_URL: 'invoice/STORE_INVOICE_PREVIEW_FILE_URL',
    UPLOAD_ANNEX_FILE: 'invoice/UPLOAD_ANNEX_FILE',
    DELETE_ANNEX_FILE: 'invoice/DELETE_ANNEX_FILE',
    DELETE_ALL_ANNEX_FILES: 'invoice/DELETE_ALL_ANNEX_FILES',
    GET_LAST_INTERNAL_INVOICE: 'invoice/GET_LAST_INTERNAL_INVOICE',
    STORE_LAST_INTERNAL_INVOICE: 'invoice/STORE_LAST_INTERNAL_INVOICE',
    IMPORT_INVOICE: 'invoice/IMPORT_INVOICE',
    SEND_REMINDER: 'invoice/SEND_REMINDER',
    SEND_INVOICE: 'invoice/SEND_INVOICE',
    RE_CREATE_INVOICE: 'invoice/RE_CREATE_INVOICE',
    STORE_INVOICE_HEADER_DATA: 'invoice/STORE_INVOICE_HEADER_DATA',
    STORE_INVOICE_RESULT: 'invoice/STORE_INVOICE_RESULT',
    GET_NEXT_CREDIT_NOTE_DOC_NUMBER: 'invoice/GET_NEXT_CREDIT_NOTE_DOC_NUMBER',
    STORE_NEXT_CREDIT_NOTE_DOC_NUMBER: 'invoice/STORE_NEXT_CREDIT_NOTE_DOC_NUMBER',
    STORE_INVOICE_REMIND_DATA: 'invoice/STORE_INVOICE_REMIND_DATA',
    STORE_INVOICE_URL: 'invoice/STORE_INVOICE_URL',
    SET_SHOW_CREATE_FORM: 'invoice/SET_SHOW_CREATE_FORM',
    IMPORT_INVOICE_RESULT: 'invoice/IMPORT_INVOICE_RESULT',
    GET_LAST_EXTERNAL_INVOICES: 'invoice/GET_LAST_EXTERNAL_INVOICES',
    STORE_LAST_EXTERNAL_INVOICES: 'invoice/STORE_LAST_EXTERNAL_INVOICES',
    GET_ADMIN_INVOICE: 'invoice/GET_ADMIN_INVOICE',

    GET_CLIENTS: 'client/GET_CLIENTS',
    STORE_CLIENTS: 'client/STORE_CLIENTS',
    SAVE_CLIENT: 'client/SAVE_CLIENT',
    SAVE_CLIENT_IN_INVOICE: 'client/SAVE_CLIENT_IN_INVOICE',
    UPDATE_CLIENT: 'client/UPDATE_CLIENT',
    UPLOAD_CLIENT_FILE: 'client/UPLOAD_CLIENT_FILE',
    UPLOAD_CLIENT_FILE_LOADER: 'client/UPLOAD_CLIENT_FILE_LOADER',
    STORE_CLIENT_EDIT_DRAFT: 'client/STORE_CLIENT_EDIT_DRAFT',
    DELETE_CLIENT_FILE: 'client/DELETE_CLIENT_FILE',
    GET_CLIENT: 'client/GET_CLIENT',
    DELETE_CLIENT: 'client/DELETE_CLIENT',
    STORE_CLIENT_ID_TO_EDIT: 'client/STORE_CLIENT_ID_TO_EDIT',
    GET_CLIENT_SUGGESTIONS: 'client/GET_CLIENT_SUGGESTIONS',
    STORE_CLIENT_SUGGESTIONS: 'client/STORE_CLIENT_SUGGESTIONS',

    GET_SERVICES: 'service/GET_SERVICES',
    STORE_SERVICES: 'service/STORE_SERVICES',
    SAVE_SERVICES: 'service/SAVE_SERVICES',
    GET_SERVICE_DETAILS: 'service/GET_SERVICE_DETAILS',
    STORE_SERVICE_EDIT_DRAFT: 'service/STORE_SERVICE_EDIT_DRAFT',
    UPDATE_SERVICE: 'service/UPDATE_SERVICE',
    SAVE_SERVICE_IN_INVOICE: 'service/SAVE_SERVICE_IN_INVOICE',
    DELETE_SERVICE: 'service/DELETE_SERVICE',

    GET_ARIA_INFO: 'aria/GET_ARIA_INFO',
    ACTIVATE_ARIA: 'aria/ACTIVATE_ARIA',
    SHOW_ARIA_ACTIVATION_PAGE: 'aria/SHOW_ARIA_ACTIVATION_PAGE',
    SHOW_ARIA_PARAMETERS: 'aria/SHOW_ARIA_PARAMETERS',
    STORE_ARIA_STATUS: 'aria/STORE_ARIA_STATUS',
    GET_USER_PERSONAL_DOCUMENT: 'aria/GET_USER_PERSONAL_DOCUMENT',
};
