import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SavingsFilledIcon from '@mui/icons-material/Savings';
import {useLayoutEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {EventIcon} from '../../../../../../assets/wrapped-svg/event';
import {SavingsOutlinedIcon} from '../../../../../../assets/wrapped-svg/savings-outlined';
import {EurField} from '../EurField';
import {FieldBlock} from '../FieldBlock';
import {FormSection} from '../FormSection';
import {FormSectionRow} from '../FormSectionRow';
import {ToggleField} from '../ToggleField';

const DEFAULT_SAVINGS_AMOUNT = 300;
const SAVINGS_FIELD_NAME = 'investments.monthlyRetirementSavingsPlanAmount';

export const MyInvestmentsStep = () => {
    const {t} = useTranslation('revenueSimulator');

    const methods = useFormContext();

    const wantsToInvest = useWatch({name: 'investments.wantsToInvest'});
    const savingsAmount = useWatch({name: SAVINGS_FIELD_NAME});

    // Need to explicitly set value when field gets enabled again
    useLayoutEffect(() => {
        if (wantsToInvest) {
            methods.setValue(SAVINGS_FIELD_NAME, savingsAmount || DEFAULT_SAVINGS_AMOUNT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wantsToInvest]);

    return (
        <FormSection Icon={SavingsFilledIcon} title={t('investments.myInvestments')}>
            <FormSectionRow>
                <FieldBlock
                    Icon={SavingsOutlinedIcon}
                    title={t('investments.title')}
                    subtitle={t('investments.subtitle')}
                >
                    <ToggleField
                        fullWidth
                        validate={value => value !== undefined || t('requiredFieldError')}
                        name="investments.wantsToInvest"
                        options={[
                            {label: t('yes'), value: true, icon: CheckIcon},
                            {label: t('no'), value: false, icon: CloseIcon},
                        ]}
                    />
                </FieldBlock>
                <FieldBlock
                    Icon={EventIcon}
                    title={t('investments.monthlyRetirementSavingsPlanAmount.title')}
                    subtitle={t('investments.monthlyRetirementSavingsPlanAmount.subtitle')}
                >
                    <EurField
                        disabled={!wantsToInvest}
                        required={wantsToInvest}
                        defaultValue={DEFAULT_SAVINGS_AMOUNT}
                        withSlider
                        name={SAVINGS_FIELD_NAME}
                        min={100}
                        max={1500}
                        inputStyles={{
                            width: theme => theme.typography.pxToRem(160),
                        }}
                    />
                </FieldBlock>
            </FormSectionRow>
        </FormSection>
    );
};
