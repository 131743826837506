import React from 'react';
import Step3 from '../../assets/etape3-dossier-v2.pdf';
import {TrainingViewPdf} from '../general/TrainingViewPdf';

const DossierViewPdf = () => {
    return (
        <TrainingViewPdf
            file={Step3}
        />
    );
};

export default DossierViewPdf;
