import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Slide, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import BankKycFailedImage from '../../../../../../assets/svg/bank/kyc-failed.svg';
import BankKycPendingImage from '../../../../../../assets/svg/bank/kyc-pending.svg';
import BankKycSuccessImage from '../../../../../../assets/svg/bank/kyc-success.svg';
import BankInformationLayout from '../../../../../../layout/BankInformationLayout/BankInformationLayout';
import {AnimationActions} from '../../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../../animations/utils/constants';
import {BankActions} from '../../../../../bank/store/bank.action';
import {BankSelector} from '../../../../../bank/store/bank.selector';
import {VerificationStatus} from '../../../../../bank/utils/constants';
import {OnboardingActions} from '../../../onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../../onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../../onboarding/utils/onboadingStepsConstant';
import {CapitalDepositInternalSubSteps} from '../../utils/constants';

const CapitolDepositBankIndentityValidation = () => {
    const dispatch = useDispatch();
    const {t: tOnboarding} = useTranslation('freelancerOnboarding');

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const integration = useSelector(BankSelector.selectIntegrations);
    const progress = useSelector(OnboardingSelectors.selectProgress);

    const bankAccountHolders = integration?.[0]?.bankAccountHolders;
    const individualBankAccountHolder = bankAccountHolders ? bankAccountHolders.find(
        integration => integration?.type !== 'COMPANY',
    ) : {};
    const hasIban = !!individualBankAccountHolder?.bankAccounts[0]?.iban;

    const onKycVerificationClick = () => {
        dispatch(BankActions.startKyc());
    };

    const onContinueClick = () => {
        if (!isVerified) {
            onKycVerificationClick();
        } else {
            dispatch(AnimationActions.storeNextDispatch(
                OnboardingActions.setInternalSubStep(CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER),
            ));
            dispatch(AnimationActions.setIsAnimationActive(false));
            dispatch(OnboardingActions.setSubStep(CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER));

            dispatch(BankActions.transitionCapitalDepositStatus(true));

            dispatch(OnboardingActions.setProgress({
                ...progress,
                [OnboardingSteps.CAPITAL_DEPOSIT]: {
                    ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
                    subStepProgress: {
                        ...progress[OnboardingSteps.CAPITAL_DEPOSIT].subStepProgress,
                        [CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION]: {
                            isCompleted: true,
                        },
                    },
                },
            }));
        }
    };

    const isFailed = individualBankAccountHolder?.verificationStatus === VerificationStatus.REFUSED;
    const isVerified = !isFailed && hasIban;
    const isPending = !isFailed && !isVerified;

    return (
        <BankInformationLayout
            header={tOnboarding('capitalIdentityVerification.headline')}
            subTitle={tOnboarding('capitalIdentityVerification.subtitle')}
            Button={(
                <Button
                    color="secondary"
                    variant="contained"
                    disabled={isPending || isFailed}
                    endIcon={<ArrowForwardIcon />}
                    onClick={onContinueClick}
                >
                    {isVerified && <Box>{tOnboarding('capitalDepositStep1.continueButton')}</Box>}
                    {!isVerified && <Box>{tOnboarding('capitalKYC.validateButton')}</Box>}
                </Button>
            )}
        >
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '600px',
                }}
                >
                    {isPending && (
                        <Box>
                            <img src={BankKycPendingImage} alt="pending kyc" />
                            <Box sx={{mb: 2, mt: 2}}>
                                <Typography variant="h4">
                                    {tOnboarding('capitalIdentityVerification.headerPending')}
                                </Typography>
                            </Box>
                            <Box>
                                {tOnboarding('capitalIdentityVerification.textPending')}
                            </Box>
                        </Box>
                    )}

                    {isVerified && (
                        <Box>
                            <img src={BankKycSuccessImage} alt="pending kyc" />
                            <Box sx={{mb: 2, mt: 2}}>
                                <Typography variant="h4">
                                    {tOnboarding('capitalIdentityVerification.headerSuccess')}
                                </Typography>
                            </Box>
                            <Box>
                                {tOnboarding('capitalIdentityVerification.textSuccess')}
                            </Box>
                        </Box>
                    )}

                    {isFailed && (
                        <Box>
                            <img src={BankKycFailedImage} alt="failed kyc" />
                            <Box sx={{mb: 2, mt: 2}}>
                                <Typography variant="h4">
                                    {tOnboarding('capitalIdentityVerification.headerFailed')}
                                </Typography>
                            </Box>
                            <Box>
                                {tOnboarding('capitalIdentityVerification.textFailed')}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Slide>
        </BankInformationLayout>
    );
};

export default CapitolDepositBankIndentityValidation;
