import makeStyles from '@mui/styles/makeStyles';

export const useDataTableHeaderColumns = makeStyles(theme => ({
    tableCell: ({isJoyTheme}) => ({
        padding: theme.spacing(2),
        color: theme.palette.v2.light.text.disabled,
        fontSize: theme.typography.pxToRem(11),
        fontWeight: 600,
        textTransform: 'uppercase',
        ...(isJoyTheme && {
            fontFamily: 'Avantt',
            fontSize: '14px',
            fontStyle: 'italic',
            fontWeight: 700,
            lineHeight: '142%',
            letterSpacing: '0.42px',
        }),
    }),
}));
