import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import {Box, IconButton} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

const TransactionDetailsCloseSection = ({
    isMobileSize,
    onClose,
    isOpen,
    closeText,
}) => {
    const {t} = useTranslation('bank');

    if (isMobileSize) {
        return (
            <Box sx={{
                fontSize: theme => theme.typography.pxToRem(22),
            }}
            >
                <Button
                    startIcon={<ArrowBackIcon />}
                    onClick={onClose}
                    sx={{color: '#263238'}}
                >
                    {closeText ?? t('translationDetails.account')}
                </Button>
            </Box>
        );
    }

    if (!isMobileSize && isOpen) {
        return (
            <Box sx={{
                position: 'fixed',
                right: '20px',
                top: '20px',
                zIndex: '50',
            }}
            >
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        );
    }

    return null;
};

TransactionDetailsCloseSection.propTypes = {
    isMobileSize: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    closeText: PropTypes.string,
};

TransactionDetailsCloseSection.defaultProps = {
    closeText: '',
    isOpen: false,
};

export default TransactionDetailsCloseSection;
