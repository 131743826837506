import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link, generatePath} from 'react-router-dom';
import {ReactComponent as RevenueSimulatorResultsImage} from '../../../../../../assets/svg/revenueSimulatorResults.svg';
import {RoutePaths} from '../../../../../../lib/router/route-paths';
import {RevenueSimulatorActions} from '../../../../store/revenue-simulator.action';
import {RevenueSimulatorSelectors} from '../../../../store/revenue-simulator.selector';

const titleStyles = {
    fontSize: theme => theme.typography.pxToRem(34),
    fontWeight: 700,
    letterSpacing: '-2px',
    mt: 3,
};

export const FinalStep = () => {
    const {t} = useTranslation('revenueSimulator');
    const dispatch = useDispatch();
    const allValues = useSelector(RevenueSimulatorSelectors.selectAllValues);

    const simulation = useSelector(RevenueSimulatorSelectors.selectSimulation);

    useEffect(() => {
        dispatch(RevenueSimulatorActions.submitInputs(allValues));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isSubmitting = useSelector(RevenueSimulatorSelectors.selectIsSubmitting);

    const isReady = !isSubmitting && !!simulation;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: 'calc(100vh - 200px)',
                background: 'white',
                borderRadius: '8px 8px 24px 24px',
            }}
        >
            <RevenueSimulatorResultsImage
                height="168px"
                width="166px"
                visibility={isReady ? 'visible' : 'hidden'}
            />

            <Box sx={titleStyles}>{t(isReady ? 'resultsReady' : 'resultsCalculating')}</Box>
            <Button
                component={Link}
                to={simulation ? generatePath(RoutePaths.REVENUE_SIMULATOR_RESULTS, {
                    simulationId: simulation.simulation.id,
                }) : ''}
                variant="contained"
                color="secondary"
                endIcon={<ArrowForwardIcon />}
                sx={{mt: 4, visibility: isReady ? 'visible' : 'hidden'}}
            >
                {t('discoverResults')}
            </Button>
        </Box>
    );
};
