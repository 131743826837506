import {faEuro} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FormControl, FormLabel, Input, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import {useController} from 'react-hook-form';
import {StandardNumberField} from '../../../../components/input/StandardNumberField';

export const ManualInputField = ({name, label, isDisabled}) => {
    const {field} = useController({name});
    return (
        <FormControl sx={{position: 'relative'}}>
            <StandardNumberField
                {...field}
                thousandSeparator=" "
                customInput={Input}
                color="neutral"
                size="md"
                startDecorator={<FontAwesomeIcon icon={faEuro} />}
                disabled={isDisabled}
                sx={{
                    'backgroundColor': 'transparent',
                    'py': 1,
                    'px': 1.5,
                }}
            />
            <FormLabel sx={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', mr: 1.5, pointerEvents: 'none'}}>
                <Typography level="body-sm" textColor="text.secondary">{label}</Typography>
            </FormLabel>

        </FormControl>
    );
};

ManualInputField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
};

ManualInputField.defaultProps = {
    isDisabled: false,
};
