const defaultStyle = {
    fillStyle: '#000',
    strokeStyle: '#000',
    lineWidth: 1,
};

export const drawRect = ({width, height, x, y, customStyle = defaultStyle}) => {
    const style = {...defaultStyle, ...customStyle};

    return context => {
        context.fillStyle = style.fillStyle;
        context.strokeStyle = style.strokeStyle;
        context.lineWidth = style.lineWidth;

        context.fillRect(x, y, width, height);
        context.strokeRect(x, y, width, height);
    };
};
