import {Grid} from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const JobProfessionFormActionButtons = ({onSaveButtonClick, onCancelButtonClick}) => {
    const {t} = useTranslation();

    return (
        <Grid container columnSpacing={2} sx={{p: 3, display: 'flex', justifyContent: 'flex-end'}}>
            <Grid item xs={6} md="auto">
                <Button
                    fullWidth
                    variant="contained"
                    color="blueGray"
                    size="large"
                    type="button"
                    onClick={onCancelButtonClick}
                    sx={{
                        'boxShadow': 0,
                        '&:hover': {'boxShadow': 0},
                    }}
                >
                    {t('common:cancel')}
                </Button>
            </Grid>
            <Grid item xs={6} md="auto">
                <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    size="large"
                    type="button"
                    onClick={onSaveButtonClick}
                >
                    {t('common:apply')}
                </Button>
            </Grid>
        </Grid>
    );
};

JobProfessionFormActionButtons.propTypes = {
    onSaveButtonClick: PropTypes.func.isRequired,
    onCancelButtonClick: PropTypes.func.isRequired,
};

export default JobProfessionFormActionButtons;
