import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import {EmptyState} from './EmptyState';
import {EurAmount} from '../../../../components/eur-amount/EurAmount';

export const Amount = ({amount, sx}) => {
    const numericAmount = parseFloat(amount);
    if (isNaN(numericAmount)) return <EmptyState />;
    return (
        <Box sx={sx}>
            <EurAmount amount={numericAmount} decimalScale={0} />
        </Box>
    );
};

Amount.propTypes = {
    amount: PropTypes.string.isRequired,
    sx: PropTypes.object,
};

Amount.defaultProps = {
    sx: {},
};
