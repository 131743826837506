export const STORE_USERNAME_ERROR_CODE = 'STORE_USERNAME_ERROR_CODE';
export const STORE_PASSWORD_ERROR_CODE = 'STORE_PASSWORD_ERROR_CODE';
export const STORE_IS_LOADING_LOGIN_FORM = 'STORE_IS_LOADING_LOGIN_FORM';
export const CLEAR_USERNAME_ERROR = 'CLEAR_USERNAME_ERROR';
export const CLEAR_PASSWORD_ERROR = 'CLEAR_PASSWORD_ERROR';
export const SUBMIT_LOGIN_FORM = 'SUBMIT_LOGIN_FORM';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SAVE_REDIRECT_LOCATION = 'SAVE_REDIRECT_LOCATION';

export const storeUsernameErrorCode = usernameErrorCode => ({
    type: STORE_USERNAME_ERROR_CODE,
    payload: usernameErrorCode,
});

export const storePasswordErrorCode = passwordErrorCode => ({
    type: STORE_PASSWORD_ERROR_CODE,
    payload: passwordErrorCode,
});

export const storeIsLoadingLoginForm = isLoading => ({
    type: STORE_IS_LOADING_LOGIN_FORM,
    payload: isLoading,
});

export const clearUsernameError = () => ({
    type: CLEAR_USERNAME_ERROR,
});

export const clearPasswordError = () => ({
    type: CLEAR_PASSWORD_ERROR,
});

export const submitLoginForm = (username, password) => ({
    type: SUBMIT_LOGIN_FORM,
    payload: {
        username,
        password,
    },
});

export const loginSuccess = () => ({
    type: LOGIN_SUCCESS,
});

export const saveRedirectLocation = data => ({
    type: SAVE_REDIRECT_LOCATION,
    payload: data,
});
