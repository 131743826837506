import * as React from 'react';
import {useLocalizationContext} from '../useUtils';

export const useValidation = (props, validate, isSameError) => {
    const {value, onError} = props;
    const adapter = useLocalizationContext();
    const previousValidationErrorRef = React.useRef(null);

    const validationError = validate({adapter, value, props});

    React.useEffect(() => {
        if (onError && !isSameError(validationError, previousValidationErrorRef.current)) {
            onError(validationError, value);
        }

        previousValidationErrorRef.current = validationError;
    }, [isSameError, onError, previousValidationErrorRef, validationError, value]);

    return validationError;
};
