import React from 'react';
import {useSelector} from 'react-redux';
import {DossierDocumentDownload} from './DossierDocumentDownload';
import {DossierDocumentGeneration} from './DossierDocumentGeneration';
import {DossierDocumentSigning} from './DossierDocumentSigning';
import {DossierDocumentView} from './DossierDocumentView';
import {TrainingSelector} from '../../store/training.selector';
import {TRAINING_CONSTANTS} from '../../utils/constants';

const Dossier = () => {
    const subSstep = useSelector(TrainingSelector.getSubstep);

    let component = null;

    switch (subSstep) {
        case TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_GENERATION:
            component = <DossierDocumentGeneration />;
            break;
        case TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_SIGNING:
            component = <DossierDocumentSigning />;
            break;
        case TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_DOWNLOAD:
            component = <DossierDocumentDownload />;
            break;
        case TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_VIEW:
            component = <DossierDocumentView />;
            break;
    }

    return component;
};

export default Dossier;
