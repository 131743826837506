const SUBMIT_CHANGE_PASSWORD_FORM = 'SUBMIT_CHANGE_PASSWORD_FORM';
const SET_IS_LOADING_CHANGE_PASSWORD_FORM = 'SET_IS_LOADING_CHANGE_PASSWORD_FORM';

const submitChangePasswordForm = (currentPassword, newPassword) => ({
    type: SUBMIT_CHANGE_PASSWORD_FORM,
    payload: {
        currentPassword,
        newPassword,
    },
});

const setIsLoadingChangePasswordForm = isLoading => ({
    type: SET_IS_LOADING_CHANGE_PASSWORD_FORM,
    payload: isLoading,
});

export const ChangePasswordActionTypes = {
    SUBMIT_CHANGE_PASSWORD_FORM,
    SET_IS_LOADING_CHANGE_PASSWORD_FORM,
};

export const ChangePasswordActions = {
    submitChangePasswordForm,
    setIsLoadingChangePasswordForm,
};
