export const SET_IS_LOADING_PROFESSIONAL_EXPENSES_FORM = 'SET_IS_LOADING_PROFESSIONAL_EXPENSES_FORM';
export const SUBMIT_PROFESSIONAL_EXPENSES_FORM = 'SUBMIT_PROFESSIONAL_EXPENSES_FORM';

export const setIsLoadingProfessionalExpensesForm = isLoading => ({
    type: SET_IS_LOADING_PROFESSIONAL_EXPENSES_FORM,
    payload: isLoading,
});

export const submitProfessionalExpensesForm = (formData, onSuccess) => ({
    type: SUBMIT_PROFESSIONAL_EXPENSES_FORM,
    payload: {
        formData,
        onSuccess,
    },
});
