import {Chip} from '@mui/joy';
import JoyUIThemeProvider from '../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';

export const EmptyState = () => {
    return (
        <JoyUIThemeProvider>
            <Chip variant="soft" color="neutral" size="md">
                -
            </Chip>
        </JoyUIThemeProvider>
    );
};
