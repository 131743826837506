import {
    faBriefcase,
    faLaptop,
    faStethoscope,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, ButtonGroup} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {UserRoleColors, UserRoleContrastColors, UserRoles} from '../../../../utils/user-roles';
import {SidebarActions} from '../../store/sidebar.action';
import {SidebarSelectors} from '../../store/sidebar.selector';

const segments = {
    'TECH': UserRoles.FREELANCER,
    'CARE': UserRoles.CARE,
    'PORTAGE': 'PORTAGE', // TODO
};

const buttonStyles = {
    p: 1,
    pl: 2,
    justifyContent: 'space-between',
};

export const SegmentSwitch = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('sidebar');

    const segment = useSelector(SidebarSelectors.selectSelectedRole);

    return (
        <ButtonGroup
            orientation="vertical"
            variant="outlined"
            size="md"
            color="neutral"
            sx={{
                mt: 2,
                py: 1,
            }}
        >
            <Button
                variant="soft"
                color="neutral"
                endDecorator={segment === segments.TECH ? (
                    <Box
                        component="div"
                        color={UserRoleContrastColors.FREELANCER}
                        fontSize="md"
                    >
                        <FontAwesomeIcon
                            icon={faLaptop}
                            fixedWidth
                        />
                    </Box>
                ) : null}
                onClick={() => {
                    dispatch(SidebarActions.setRoleSelected(UserRoles.FREELANCER));
                }}
                sx={{
                    ...buttonStyles,
                    ...(segment === segments.TECH
                        ? {
                            'backgroundColor': UserRoleColors.FREELANCER,
                            'color': UserRoleContrastColors.FREELANCER,
                            '&:hover': {
                                'backgroundColor': UserRoleColors.FREELANCER,
                                'color': UserRoleContrastColors.FREELANCER,
                            },
                        } : {}),
                }}
            >
                {t('administratorSidebar.segments.tech')}
            </Button>
            <Button
                variant="soft"
                color="neutral"
                endDecorator={segment === segments.CARE ? (
                    <Box
                        component="div"
                        color={UserRoleContrastColors.CARE}
                        fontSize="md"
                    >
                        <FontAwesomeIcon
                            icon={faStethoscope}
                            fixedWidth
                        />
                    </Box>
                ) : null}
                onClick={() => {
                    dispatch(SidebarActions.setRoleSelected(UserRoles.CARE));
                }}
                sx={{
                    ...buttonStyles,
                    ...(segment === segments.CARE
                        ? {
                            'backgroundColor': UserRoleColors.CARE,
                            'color': UserRoleContrastColors.CARE,
                            '&:hover': {
                                'backgroundColor': UserRoleColors.CARE,
                                'color': UserRoleContrastColors.CARE,
                            },
                        } : {}),
                }}
            >
                {t('administratorSidebar.segments.care')}
            </Button>
            <Button
                variant="soft"
                color="neutral"
                endDecorator={segment === segments.PORTAGE ? (
                    <Box
                        component="div"
                        color={UserRoleContrastColors.PORTAGE}
                        fontSize="md"
                    >
                        <FontAwesomeIcon
                            icon={faBriefcase}
                            fixedWidth
                        />
                    </Box>
                ) : null}
                onClick={() => {
                    dispatch(SidebarActions.setRoleSelected(UserRoles.PORTAGE));
                }}
                sx={{
                    ...buttonStyles,
                    ...(segment === segments.PORTAGE
                        ? {
                            'backgroundColor': UserRoleColors.PORTAGE,
                            'color': UserRoleContrastColors.PORTAGE,
                            '&:hover': {
                                'backgroundColor': UserRoleColors.PORTAGE,
                                'color': UserRoleContrastColors.PORTAGE,
                            },
                        } : {}),
                }}
            >
                {t('administratorSidebar.segments.portage')}
            </Button>
        </ButtonGroup>
    );
};
