import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const Square = () => (
    <Box sx={{
        width: '4px',
        height: '4px',
        borderRadius: '1px',
        backgroundColor: 'v2.blueGray.300',
    }}
    />
);

export const PacmanDivider = ({quantity, sx}) => {
    return (
        <Box sx={{
            position: 'absolute',
            height: '16px',
            top: 0,
            zIndex: 1,
            ...sx,
        }}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                height: '16px',
                gap: '16px',
            }}
            >
                {[...Array(quantity).keys()].map(index => (
                    <Square key={index} />
                ))}
            </Box>
        </Box>
    );
};

PacmanDivider.propTypes = {
    quantity: PropTypes.number.isRequired,
    sx: PropTypes.object,
};

PacmanDivider.defaultProps = {
    sx: {},
};
