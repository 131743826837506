import InputLabel from '@mui/material/InputLabel';
import React from 'react';

import {useDataItemRowFormLabel} from './styles';

export const DataItemRowFormLabel = ({children, ...rest}) => {
    const styles = useDataItemRowFormLabel();

    return (
        <InputLabel className={styles.root} {...rest}>
            {children}
        </InputLabel>
    );
};
