import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Button, Grid, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import JobProfessionFormActionButtons from './JobProfessionFormActionButtons';
import RemoveJobProfessionButton from './removeJobProfessionButton';
import SelectedJobProfession from './SelectedJobProfession';
import SelectedSpecializations from './SelectedSpecializations';
import {importLocaleBundle} from '../../../../lib/i18next';
import {ProfessionAndSpecializationSelectors} from '../../store/profession-and-specialization.selector';

importLocaleBundle('create-user');

const inputTransformSelectedData = (inputData, allJobAndSpecializationValues) => (
    inputData.map(returnedProfession => ({
        jobProfession: returnedProfession.jobProfession,
        specialization: allJobAndSpecializationValues
            .find(singleValue => singleValue.jobProfession === returnedProfession.jobProfession)
            .allSpecializations
            .map(item => ({
                name: item,
                selected: returnedProfession.specialization.includes(item),
            })),
    }))
);

const outputTransformSelectedData = outputData => (
    outputData.map(returnedProfession => ({
        jobProfession: returnedProfession.jobProfession,
        specialization: returnedProfession.specialization
            .filter(item => item.selected)
            .map(item => item.name),
    }))
);

const getAvailableProfession = (freelancerData, allData) => {
    const freelancerProfessions = freelancerData.map(item => item.jobProfession);
    return allData.map(item => (
        {jobProfession: item.jobProfession, selected: freelancerProfessions.includes(item.jobProfession)}
    ));
};

const JobProfessionForm = props => {
    const isMobileSize = useMediaQuery(`(max-width:899px)`);
    const {
        data,
        onCancel,
        onSave,
    } = props;
    const {t} = useTranslation('users');
    const allValues = useSelector(ProfessionAndSpecializationSelectors.selectAllValues);
    const paddedData = (0 === data.length)
        ? [{jobProfession: allValues[0].jobProfession, specialization: []}]
        : data;
    const selectedProfAndSpecData = inputTransformSelectedData(paddedData, allValues);
    const [professionAndSpecializationData, setProfessionAndSpecializationData] = useState(selectedProfAndSpecData);
    const availableProfData = getAvailableProfession(paddedData, allValues);
    const [availableJobProfessionData, setAvailableJobProfessionsData] = useState(availableProfData);

    const handleJobProfessionChange = (oldJobProfession, newJobProfession) => {
        setProfessionAndSpecializationData(prevState => {
            const jobProfession = newJobProfession;
            const specialization = allValues
                .find(item => item.jobProfession === newJobProfession)
                .allSpecializations
                .map(item => ({name: item, selected: false}));
            const newItem = {jobProfession, specialization};
            return prevState.map(item => (item.jobProfession === oldJobProfession ? newItem : item));
        });
        setAvailableJobProfessionsData(prevState => (
            prevState.map(item => {
                if (item.jobProfession === oldJobProfession) {
                    item.selected = false;
                }
                if (item.jobProfession === newJobProfession) {
                    item.selected = true;
                }
                return item;
            })
        ));
    };

    const handleSpecializationUpdate = (jobProfession, specialization) => {
        setProfessionAndSpecializationData(prevState => (
            prevState.map(item => (item.jobProfession === jobProfession ? {jobProfession, specialization} : item))
        ));
    };

    const handleRowDelete = jobProfession => {
        setProfessionAndSpecializationData(prevState => (
            prevState.filter(item => (item.jobProfession !== jobProfession))
        ));
        setAvailableJobProfessionsData(
            prevState => prevState.map(item => (
                item.jobProfession === jobProfession ? {...item, selected: false} : item)),
        );
    };

    const addRowButtonIsDisabled = useMemo(() => (
        !availableJobProfessionData.filter(item => !item.selected).length
    ), [availableJobProfessionData]);

    const handleRowAdd = () => {
        const jobProfession = availableJobProfessionData.find(item => !item.selected)?.jobProfession;
        const specialization = allValues
            .find(item => item.jobProfession === jobProfession).allSpecializations
            .map(item => ({name: item, selected: false}));
        setProfessionAndSpecializationData(prevState => [...prevState, {jobProfession, specialization}]);
        setAvailableJobProfessionsData(
            prevState => prevState.map(item => (
                item.jobProfession === jobProfession ? {...item, selected: true} : item)),
        );
    };

    const handleSave = () => {
        onSave(outputTransformSelectedData(professionAndSpecializationData));
    };

    return (
        <>
            {professionAndSpecializationData.map(({jobProfession, specialization}) => (
                <Grid container rowSpacing={2} key={jobProfession} sx={{py: 3, pl: 3, pr: {xs: 3, md: 1}, borderBottom: 1, borderColor: 'grey.300'}}>
                    <SelectedJobProfession
                        selectedJobProfession={jobProfession}
                        availableJobProfessions={availableJobProfessionData}
                        onJobProfessionChange={handleJobProfessionChange}
                    />
                    { isMobileSize && (
                    <RemoveJobProfessionButton
                        selectedJobProfession={jobProfession}
                        isEnabled={professionAndSpecializationData.length > 1}
                        onRowDelete={handleRowDelete}
                    />
                    )}
                    <SelectedSpecializations
                        jobProfession={jobProfession}
                        specialization={specialization}
                        onItemsUpdate={handleSpecializationUpdate}
                    />
                    { !isMobileSize && (
                    <RemoveJobProfessionButton
                        selectedJobProfession={jobProfession}
                        isEnabled={professionAndSpecializationData.length > 1}
                        onRowDelete={handleRowDelete}
                    />
                    )}
                </Grid>
            ))}
            <Box sx={[{display: 'flex', justifyContent: 'flex-end', p: 0, pr: 3, py: 2.5}, isMobileSize && {borderBottom: 1, borderColor: 'grey.300'}]}>
                <Button
                    disableRipple
                    size="small"
                    sx={{color: 'primary_v2.main', p: 0.5}}
                    onClick={handleRowAdd}
                    startIcon={<AddCircleIcon />}
                    disabled={addRowButtonIsDisabled}
                >
                    {t('professionAndSpecialization.addProfession')}
                </Button>
            </Box>
            <JobProfessionFormActionButtons
                onSaveButtonClick={handleSave}
                onCancelButtonClick={onCancel}
            />
        </>
    );
};

JobProfessionForm.propTypes = {
    data: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default JobProfessionForm;
