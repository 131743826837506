import {Typography} from '@mui/material';
import PropTypes from 'prop-types';

export const PageHeader = ({children, disableGutterBottom, subTitle, isMobile}) => {
    if (!isMobile) {
        return (
            <>
                <Typography
                    variant="h4"
                    sx={{mb: subTitle ? 1 : disableGutterBottom ? 0 : 3}}
                >
                    {children}
                </Typography>

                {!!subTitle && (
                    <Typography
                        color="text.disabled"
                        variant="subtitle2"
                        sx={{mb: disableGutterBottom ? 0 : 3, fontWeight: 'fontWeightMedium'}}
                    >
                        {subTitle}
                    </Typography>
                )}
            </>
        );
    }

    return (
        <>
            <Typography
                variant="h4"
                sx={{p: 3}}
            >
                {children}
            </Typography>

            {!!subTitle && (
                <Typography
                    color="text.disabled"
                    variant="subtitle2"
                    sx={{mx: 3, my: 1}}
                >
                    {subTitle}
                </Typography>
            )}
        </>
    );
};

PageHeader.propTypes = {
    // eslint-disable-next-line react/boolean-prop-naming
    disableGutterBottom: PropTypes.bool,
    subTitle: PropTypes.string,
    isMobile: PropTypes.bool,
};

PageHeader.defaultProps = {
    disableGutterBottom: false,
    subTitle: null,
    isMobile: false,
};
