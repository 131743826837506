import {Box, Divider, Paper, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import DocumentNameCell from '../../../v1/components/company/DocumentNameCell/DocumentNameCell';
import DocumentStatusCell from '../../../v1/components/company/DocumentStatusCell/DocumentStatusCell';
import {DatabaseSelectors} from '../../document/modules/database/store/database.selector';

export const DocumentGenerationMobile = () => {
    const {t} = useTranslation('documents');

    const documents = useSelector(DatabaseSelectors.selectDocuments);
    const documentsList = Object.values(documents);

    return (
        <Paper sx={{borderRadius: '16px'}}>
            {documentsList.map(document => {
                return (
                    <React.Fragment key={document.id}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: 3,
                        }}
                        >
                            <Box sx={{
                                mb: 2,
                                color: 'text_v2.primary',
                                fontWeight: 600,
                                fontSize: theme => theme.typography.pxToRem(16),
                            }}
                            >
                                <DocumentNameCell rowData={document} isMobile={true} />
                            </Box>
                            <Box sx={{display: 'flex'}}>
                                <Box sx={{width: '50%'}}>
                                    <Typography sx={{
                                        color: 'rgba(0, 0, 0, 0.38)',
                                        fontSize: theme => theme.typography.pxToRem(12),
                                    }}
                                    >
                                        {t('documentsDatabaseTable.category')}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: theme => theme.typography.pxToRem(14),
                                        }}
                                    >
                                        {t(`documentsDatabaseCategories.${document.category}`)}
                                    </Typography>
                                </Box>
                                <Box sx={{width: '50%'}}>
                                    <Typography sx={{
                                        color: 'rgba(0, 0, 0, 0.38)',
                                        fontSize: theme => theme.typography.pxToRem(12),
                                    }}
                                    >
                                        {t('documentsDatabaseTable.status')}
                                    </Typography>
                                    <DocumentStatusCell rowData={document} />
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                    </React.Fragment>
                );
            })}
        </Paper>
    );
};
