export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COACHES = 'GET_COACHES';
export const STORE_COMPANIES = 'STORE_COMPANIES';
export const STORE_HIWAY_COMPANIES = 'STORE_HIWAY_COMPANIES';
export const STORE_COMPANIES_TOTAL = 'STORE_COMPANIES_TOTAL';
export const SET_IS_LOADING_COMPANIES = 'SET_IS_LOADING_COMPANIES';
export const STORE_TRAINING_COMPANIES = 'STORE_TRAINING_COMPANIES';

export const getCompanies = () => ({
    type: GET_COMPANIES,
});

export const getCoaches = () => ({
    type: GET_COACHES,
});

export const storeCompanies = companies => ({
    type: STORE_COMPANIES,
    payload: companies,
});

export const storeHiwayCompanies = companies => ({
    type: STORE_HIWAY_COMPANIES,
    payload: companies,
});

export const storeCompaniesTotal = total => ({
    type: STORE_COMPANIES_TOTAL,
    payload: total,
});

export const setIsLoadingCompanies = isLoading => ({
    type: SET_IS_LOADING_COMPANIES,
    payload: isLoading,
});

export const storeTrainingCompanies = trainingCompanies => ({
    type: STORE_TRAINING_COMPANIES,
    payload: trainingCompanies,
});
