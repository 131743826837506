import {Typography} from '@mui/joy';
import PropTypes from 'prop-types';

const PageHeader = ({title}) => {
    return (
        <Typography level="h1">{title}</Typography>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
};

export default PageHeader;
