import makeStyles from '@mui/styles/makeStyles';

export const useProfileChipStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1, 0, 3),
    },
    userIdLabel: {
        fontSize: theme.typography.pxToRem(14),
        letterSpacing: '0.1px',
    },
    userId: {
        fontSize: theme.typography.pxToRem(14),
        letterSpacing: '0.1px',
    },
}));
