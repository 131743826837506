import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FiltersWrapper from './FiltersWrapper';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {LoggedInUserSelectors} from '../../../../../user/modules/logged-in-user';
import {TransactionsActions} from '../../store/transactions.action';
import {TransactionsSelector} from '../../store/transactions.selector';
import {TRANSACTION_CATEGORIZATION_SCREENS} from '../../util/constants';
import {AccountBalanceAlert} from '../account-balance/AccountBalanceAlert';
import {AccountBalanceTable} from '../account-balance/AccountBalanceTable';

const CombinedTransactionList = ({
    topOffset,
    isAdminInterface,
    balance,
    isOpened,
    user,
    combinedTabIcons,
    isArchived,
    isTabValueAuto,
    filter,
    setFilter,
}) => {
    const dispatch = useDispatch();
    const isMobileSize = useIsMobileSize();

    const transactionIds = useSelector(TransactionsSelector.selectTransactionIds);
    const transactionNonCategorizedCount = useSelector(TransactionsSelector.selectTransactionNotCategorizedCount);

    const loadNextPage = useCallback(() => {
        dispatch(TransactionsActions.loadNextCombinedPage(
            isAdminInterface && user?.id
                ? {...filter, userId: user.id, archivedOnly: isArchived}
                : {...filter, archivedOnly: isArchived},
        ));
        // eslint-disable-next-line
    }, [filter]);

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_TRANSACTIONS,
    ));

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isUserHasInvoiceAccess = loggedInUser?.hasInvoiceAccess;

    useEffect(() => {
        // Load on isOpened/filter change is not loading already
        // ignore is loading param on isArchived due to the case when archived is the only tab
        // TODO It could be done more elegant with different loaders for archive, but that required refactor
        if (
            isOpened
            && !isTabValueAuto
            && (isArchived || !isLoading)
        ) {
            dispatch(TransactionsActions.getCombinedTransactionList(
                isAdminInterface && user?.id
                    ? {...filter, userId: user.id, archivedOnly: isArchived, onFilterChanged: true}
                    : {...filter, archivedOnly: isArchived, onFilterChanged: true},
            ));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filter, isOpened]);

    const onOpenClick = id => {
        dispatch(TransactionsActions.setCategorizationScreenInfo({
            screen: TRANSACTION_CATEGORIZATION_SCREENS.CATEGORY,
            transactionId: id,
        }));

        dispatch(TransactionsActions.addToCategorisationList({
            transactionId: id,
            hasSpecialHandlingIfCategorised: true,
        }));
    };

    // TODO: remove this code since isLoading is always false
    // I kept this just in case
    if (isLoading) {
        return null;
    }

    return (
        <>
            {!isMobileSize && (
                <FiltersWrapper
                    topOffset={topOffset}
                    balance={balance}
                    filter={filter}
                    setFilter={setFilter}
                />
            )}

            <AccountBalanceAlert
                setFilter={setFilter}
                transactionNonCategorizedCount={transactionNonCategorizedCount}
            />

            <AccountBalanceTable
                filter={filter}
                transactionIds={transactionIds}
                loadNextPage={loadNextPage}
                isUserHasInvoiceAccess={isUserHasInvoiceAccess}
                hasAlert={transactionNonCategorizedCount !== 0}
                onOpenClick={onOpenClick}
                totalLoadedIds={transactionIds?.length}
                combinedTabIcons={combinedTabIcons}
            />
        </>
    );
};

CombinedTransactionList.propTypes = {
    topOffset: PropTypes.number.isRequired,
    isAdminInterface: PropTypes.bool.isRequired,
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isOpened: PropTypes.bool.isRequired,
    user: PropTypes.object,
    combinedTabIcons: PropTypes.object.isRequired,
    isArchived: PropTypes.bool,
    isTabValueAuto: PropTypes.bool.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};

CombinedTransactionList.defaultProps = {
    user: {},
    isArchived: false,
};

export default CombinedTransactionList;
