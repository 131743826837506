export const VatBatchSendStatus = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED_WITH_ERRORS: 'COMPLETED_WITH_ERRORS',
    COMPLETED: 'COMPLETED',
};

export const VatDeclarationStatus = {
    TODO: 'TODO',
    SUBMITTED: 'SUBMITTED',
    PENDING_VERIFICATION: 'PENDING_VERIFICATION',
    OVERDUE: 'OVERDUE',
    REFUSED: 'REFUSED',
    VALIDATED: 'VALIDATED',
    ERROR: 'ERROR',
};

export const VatDeclarationSendStatus = {
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
};

export const VatDeclarationSendUIState = {
    NONE: 'NONE',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    COMPLETED_WITH_ERRORS: 'COMPLETED_WITH_ERRORS',
};

export const VAT_LOCAL_STORAGE_KEY = 'vat-declaration';
