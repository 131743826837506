import DownloadStatementAction from '../components/datatable/DownloadStatementAction';
import StatementsName from '../components/datatable/StatementName';

export const getBankAccountStatementsDataTableConfig = (t, isMobileSize) => {
    if (isMobileSize) {
        return [
            {
                key: 'openingDate',
                title: t('accountStatementsTable.columnTitle.fileName'),
                isSortable: true,
                width: `75%`,
                component: StatementsName,
            },
            {
                key: 'actions',
                title: t('accountStatementsTable.columnTitle.actions'),
                isSortable: false,
                additionalCellProps: {
                    align: 'right',
                },
                shouldPreserveWhiteSpace: true,
                component: DownloadStatementAction,
            },
        ];
    }

    return [
        {
            key: 'statementName',
            title: t('accountStatementsTable.columnTitle.fileName'),
            isSortable: false,
            width: `50%`,
            component: StatementsName,
        },
        {
            key: 'openingDate',
            title: t('accountStatementsTable.columnTitle.statementData'),
            isSortable: true,
            width: `35%`,
        },
        {
            key: 'actions',
            title: t('accountStatementsTable.columnTitle.actions'),
            isSortable: false,
            additionalCellProps: {
                align: 'right',
            },
            shouldPreserveWhiteSpace: true,
            component: DownloadStatementAction,
        },
    ];
};
