export const GET_TRAINING_INVOICE = 'company/training/GET_TRAINING_INVOICE';
export const STORE_TRAINING_INVOICE = 'company/training/STORE_TRAINING_INVOICE';
export const SET_IS_TRAINING_EDIT_MODE_ACTIVE = 'company/training/SET_IS_TRAINING_EDIT_MODE_ACTIVE';
export const SET_TRAINING_INVOICE_STATE = 'company/training/SET_TRAINING_INVOICE_STATE';

export const getTrainingInvoice = (freelancerId, companyId) => ({
    type: GET_TRAINING_INVOICE,
    payload: {
        companyId,
        freelancerId,
    },
});

export const storeTrainingInvoice = invoice => ({
    type: STORE_TRAINING_INVOICE,
    payload: invoice,
});

export const setTrainingInvoiceState = state => ({
    type: SET_TRAINING_INVOICE_STATE,
    payload: state,
});

export const setIsTrainingEditModeActive = isActive => ({
    type: SET_IS_TRAINING_EDIT_MODE_ACTIVE,
    payload: isActive,
});
