export const FINANCIAL_STATEMENTS_QUERY_PARAMS = {
    YEAR: 'year',
    ANNUAL_ACCOUNT_ID: 'annualAccountId',
    BACK_TO_TABLE: 'backTo=table',
    SORT_BY: 'financialStatements-sortBy',
    SEARCH_TERM: 'financialStatements-searchTerm',
    QUICK_FILTER: 'financialStatements-quickFilter',
    PAGE: 'financialStatements-page',
    SORT_DIRECTION: 'financialStatements-sortDirection',
};
