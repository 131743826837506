import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import {Alert, Box, Button} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link, generatePath} from 'react-router-dom';
import {RoutePaths} from '../../../../lib/router/route-paths';
import {LoggedInUserSelectors} from '../../../user/modules/logged-in-user';

const MyDocumentsAlert = () => {
    const {t} = useTranslation('training');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const documentsLink = generatePath(RoutePaths.DOCUMENTS, {companyId: user?.defaultCompanyId});

    const alertAction = (
        <Button
            component={Link}
            sx={{height: '28px', color: '#3779BE'}}
            startIcon={<DocumentScannerIcon />}
            color="inherit"
            size="small"
            to={`${documentsLink}?documents-tabFilter=TRAINING`}
        >
            {t('documentLink.link')}
        </Button>
    );

    return (
        <Alert
            sx={{mt: 3}}
            severity="info"
            icon={isMobileSize ? false : ''}
            action={!isMobileSize && alertAction}
        >
            {t('documentLink.text')}
            { isMobileSize && (
                <Box sx={{mt: 1, display: 'flex', justifyContent: 'flex-end'}}>
                    {alertAction}
                </Box>
            )}
        </Alert>
    );
};

export default MyDocumentsAlert;
