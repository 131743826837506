import {Box, LinearProgress, Sheet, Typography} from '@mui/joy';
import PropTypes from 'prop-types';

const BatchSendProgress = ({label, processedCount = 213, totalCount = 500}) => {
    const progressValue = (processedCount / totalCount * 100) || 0;

    return (
        <Sheet variant="outlined" sx={{borderRadius: 'sm', p: 2}}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', flex: 1}}>
                <Typography level="body-xs" sx={{color: 'neutral.plainColor'}}>
                    {label}
                </Typography>
                <Typography level="body-xs" sx={{color: 'neutral.plainColor'}}>
                    {processedCount}
                </Typography>
            </Box>
            <LinearProgress
                color="success"
                variant="solid"
                size="lg"
                value={progressValue}
                determinate
            />
        </Sheet>
    );
};

BatchSendProgress.propTypes = {
    label: PropTypes.string.isRequired,
    processedCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
};

export default BatchSendProgress;
