import {OnboardingSteps} from './onboadingStepsConstant';
import {CapitalDeposit} from '../../capital-deposit/components/CapitalDeposit';
import {CapitalDepositInternalSubSteps, FinalizationSubsteps} from '../../capital-deposit/utils/constants';
import {CompanyRegistration} from '../../company-registration/components/CompanyRegistration';
import {CompanySetup} from '../../company-setup/components/CompanySetup';
import {CompanySetupSubSteps} from '../../company-setup/utils/constants';
import {ContractSigning} from '../../contract-signing/components/ContractSigning';
import {ContractSigningSubSteps} from '../../contract-signing/utils/constants';
import {Finalization} from '../../finalization/components/Finalization';
import {Workshops} from '../../workshops/components/Workshops';

export const OnboardingStepsConfig = {
    [OnboardingSteps.CONTRACT_SIGNING]: {
        id: 1,
        key: OnboardingSteps.CONTRACT_SIGNING,
        component: ContractSigning,
        labelKey: 'signContract',
        subSteps: [
            {
                key: ContractSigningSubSteps.ENTER_PERSONAL_ADDRESS,
                labelKey: 'signContractSubSteps.personalAddress',
            },
            {
                key: ContractSigningSubSteps.SIGN_CONTRACT,
                labelKey: 'signContractSubSteps.signContract',
            },
        ],
    },
    [OnboardingSteps.WORKSHOPS]: {
        id: 2,
        key: OnboardingSteps.WORKSHOPS,
        component: Workshops,
        labelKey: 'workshops',
    },
    [OnboardingSteps.COMPANY_SETUP]: {
        id: 3,
        key: OnboardingSteps.COMPANY_SETUP,
        component: CompanySetup,
        labelKey: 'createCompany',
        subSteps: [
            {
                key: CompanySetupSubSteps.PERSONAL_INFORMATION,
                labelKey: 'personalInformation',
            },
            {
                key: CompanySetupSubSteps.ADDRESS_INFORMATION,
                labelKey: 'address',
            },
            {
                key: CompanySetupSubSteps.DECLARATION_OF_NON_CONVICTION,
                labelKey: 'declarations',
            },
            {
                key: CompanySetupSubSteps.COMPANY_INFORMATION,
                labelKey: 'informationAboutCompany',
            },
            {
                key: CompanySetupSubSteps.BANK,
                labelKey: 'bank',
            },
            {
                key: CompanySetupSubSteps.DOCUMENT_UPLOAD,
                labelKey: 'uploadDocuments',
            },
            {
                key: CompanySetupSubSteps.DOCUMENT_GENERATION,
                labelKey: 'documentGeneration',
                requiredCompanyStatuses: [
                    'PENDING_INIT_SIGS',
                    'READY_FOR_REVIEW',
                ],
            },
        ],
    },
    [OnboardingSteps.CAPITAL_DEPOSIT]: {
        id: 4,
        key: OnboardingSteps.CAPITAL_DEPOSIT,
        component: CapitalDeposit,
        labelKey: 'capitalDeposit',
    },
    [OnboardingSteps.COMPANY_REGISTRATION]: {
        id: 5,
        key: OnboardingSteps.COMPANY_REGISTRATION,
        component: CompanyRegistration,
        labelKey: 'companyRegistration',
    },
    [OnboardingSteps.FINAL_POINTS]: {
        id: 6,
        key: OnboardingSteps.FINAL_POINTS,
        component: Finalization,
        labelKey: 'finalPoints',
        subSteps: [
            {
                key: FinalizationSubsteps.INSURANCE,
                labelKey: 'insuranceSubscription',
                hasActionOnClick: false,
                isCompleted: false,
            },
            {
                key: FinalizationSubsteps.ACCESS_PLATFORM,
                labelKey: 'accessPlatform',
                hasActionOnClick: false,
                isCompleted: false,
            },
        ],
    },
};

export const OnboardingCareStepsConfig = {
    [OnboardingSteps.CONTRACT_SIGNING]: {
        id: 1,
        key: OnboardingSteps.CONTRACT_SIGNING,
        component: ContractSigning,
        labelKey: 'signContract',
        subSteps: [
            {
                key: ContractSigningSubSteps.ENTER_PERSONAL_ADDRESS,
                labelKey: 'signContractSubSteps.personalAddress',
            },
            {
                key: ContractSigningSubSteps.SIGN_CONTRACT,
                labelKey: 'signContractSubSteps.signContract',
            },
        ],
    },
    [OnboardingSteps.WORKSHOPS]: {
        id: 2,
        key: OnboardingSteps.WORKSHOPS,
        component: Workshops,
        labelKey: 'workshops',
    },
    [OnboardingSteps.COMPANY_SETUP]: {
        id: 3,
        key: OnboardingSteps.COMPANY_SETUP,
        component: CompanySetup,
        labelKey: 'createCompanyCare',
        subSteps: [
            {
                key: CompanySetupSubSteps.PERSONAL_INFORMATION,
                labelKey: 'personalInformation',
            },
            {
                key: CompanySetupSubSteps.ADDRESS_INFORMATION,
                labelKey: 'address',
            },
            {
                key: CompanySetupSubSteps.COMPANY_INFORMATION,
                labelKey: 'informationAboutCompanyCare',
            },
            {
                key: CompanySetupSubSteps.BANK,
                labelKey: 'bank',
            },
            {
                key: CompanySetupSubSteps.DOCUMENT_UPLOAD,
                labelKey: 'uploadDocuments',
            },
            {
                key: CompanySetupSubSteps.DOCUMENT_GENERATION,
                labelKey: 'documentGeneration',
                requiredCompanyStatuses: [
                    'PENDING_INIT_SIGS',
                    'READY_FOR_REVIEW',
                ],
            },
        ],
    },
    [OnboardingSteps.COMPANY_REGISTRATION]: {
        id: 5,
        key: OnboardingSteps.COMPANY_REGISTRATION,
        component: CompanyRegistration,
        labelKey: 'companyRegistration',
    },
    [OnboardingSteps.FINAL_POINTS]: {
        id: 6,
        key: OnboardingSteps.FINAL_POINTS,
        component: Finalization,
        labelKey: 'finalPoints',
        subSteps: [
            {
                key: FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION,
                labelKey: 'bankDocumentSend',
                hasActionOnClick: false,
                isCompleted: false,
            },
            {
                key: FinalizationSubsteps.ACCESS_PLATFORM,
                labelKey: 'accessPlatform',
                hasActionOnClick: false,
                isCompleted: false,
            },
        ],
    },
};

export const capitalDepositSubsteps = [
    {
        key: CapitalDepositInternalSubSteps.BANK_KYC_VALIDATION,
        labelKey: 'bankKycValidation',
        hasActionOnClick: false,
    },
    {
        key: CapitalDepositInternalSubSteps.BANK_SEND_INFORMATION,
        labelKey: 'bankSendInformation',
        hasActionOnClick: false,
    },
    {
        key: CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION,
        labelKey: 'bankIdentityValidation',
        hasActionOnClick: false,
    },
    {
        key: CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER,
        labelKey: 'bankFundTransfer',
        hasActionOnClick: false,
    },
    {
        key: CapitalDepositInternalSubSteps.BANK_CAPITOL_DEPOSIT_VALIDATION,
        labelKey: 'bankCapitolDepositValidation',
        hasActionOnClick: false,
    },
];

export const finalizationSubsteps = [
    {
        key: FinalizationSubsteps.PENDING_FINALIZATION,
        labelKey: 'bankCapitolDepositValidation',
        hasActionOnClick: false,
        isCompleted: false,
    },
    {
        key: FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION,
        labelKey: 'bankDocumentSend',
        hasActionOnClick: false,
        isCompleted: false,
    },
    {
        key: FinalizationSubsteps.INSURANCE,
        labelKey: 'insuranceSubscription',
        hasActionOnClick: false,
        isCompleted: false,
    },
    {
        key: FinalizationSubsteps.ACCESS_PLATFORM,
        labelKey: 'accessPlatform',
        hasActionOnClick: false,
        isCompleted: false,
    },
];

export const careFinalizationSubsteps = [
    {
        key: FinalizationSubsteps.PENDING_FINALIZATION,
        labelKey: 'bankCapitolDepositValidation',
        hasActionOnClick: false,
        isCompleted: false,
    },
    {
        key: FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION,
        labelKey: 'bankDocumentSend',
        hasActionOnClick: false,
        isCompleted: false,
    },
    {
        key: FinalizationSubsteps.ACCESS_PLATFORM,
        labelKey: 'accessPlatform',
        hasActionOnClick: false,
        isCompleted: false,
    },
];

export const subStepProgress = {
    [CapitalDepositInternalSubSteps.BANK_SEND_INFORMATION]: {
        isCompleted: false,
    },
    [CapitalDepositInternalSubSteps.BANK_KYC_VALIDATION]: {
        isCompleted: false,
    },
    [CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER]: {
        isCompleted: false,
    },
    [CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION]: {
        isCompleted: false,
    },
    [CapitalDepositInternalSubSteps.BANK_CAPITOL_DEPOSIT_VALIDATION]: {
        isCompleted: false,
    },
};

export const OnboardingStepsConfigWithExistingCompany = {
    [OnboardingSteps.CONTRACT_SIGNING]: {
        id: 1,
        key: OnboardingSteps.CONTRACT_SIGNING,
        component: ContractSigning,
        labelKey: 'signContract',
    },
    [OnboardingSteps.COMPANY_SETUP]: {
        id: 3,
        key: OnboardingSteps.COMPANY_SETUP,
        component: CompanySetup,
        labelKey: 'companyInformation',
        subSteps: [
            {
                key: CompanySetupSubSteps.PERSONAL_INFORMATION,
                labelKey: 'personalInformation',
            },
            {
                key: CompanySetupSubSteps.ADDRESS_INFORMATION,
                labelKey: 'address',
            },
            {
                key: CompanySetupSubSteps.COMPANY_INFORMATION,
                labelKey: 'informationAboutCompany',
            },
        ],
    },
    [OnboardingSteps.COMPANY_REGISTRATION]: {
        id: 5,
        key: OnboardingSteps.COMPANY_REGISTRATION,
        component: CompanyRegistration,
        labelKey: 'companyRegistrationBank',
    },
    [OnboardingSteps.FINAL_POINTS]: {
        id: 6,
        key: OnboardingSteps.FINAL_POINTS,
        component: Finalization,
        labelKey: 'finalPoints',
    },
};

export const OnBoardingLaunchedStatus = {
    PENDING: 'PENDING',
    READY_FOR_LAUNCH: 'READY_FOR_LAUNCH',
    LAUNCHED: 'LAUNCHED',
};
