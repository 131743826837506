import {combineReducers} from 'redux';
import {OnboardingActionTypes} from './onboarding.action-type';
import {createReducer} from '../../../../../utils/create-reducer';
import {CapitalDepositInternalSubSteps} from '../../capital-deposit/utils/constants';
import {OnboardingSteps} from '../utils/onboadingStepsConstant';

const initialProgressState = {
    [OnboardingSteps.CONTRACT_SIGNING]: {
        isCompleted: false,
        subStepProgress: {},
    },
    [OnboardingSteps.WORKSHOPS]: {
        isCompleted: false,
        subStepProgress: {},
    },
    [OnboardingSteps.COMPANY_SETUP]: {
        isCompleted: false,
        subStepProgress: {},
    },
    [OnboardingSteps.CAPITAL_DEPOSIT]: {
        isCompleted: false,
        subStepProgress: {
            [CapitalDepositInternalSubSteps.BANK_SEND_INFORMATION]: {
                isCompleted: false,
            },
            [CapitalDepositInternalSubSteps.BANK_KYC_VALIDATION]: {
                isCompleted: false,
            },
            [CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER]: {
                isCompleted: false,
            },
            [CapitalDepositInternalSubSteps.BANK_CAPITOL_DEPOSIT_VALIDATION]: {
                isCompleted: false,
            },
        },
    },
    [OnboardingSteps.COMPANY_REGISTRATION]: {
        isCompleted: false,
        subStepProgress: {},
    },
    [OnboardingSteps.FINAL_POINTS]: {
        isCompleted: false,
        subStepProgress: {},
    },
};

export const onboardingReducer = combineReducers({
    currentlyOpenStep: createReducer(null, OnboardingActionTypes.SET_STEP),
    currentlyOpenSubStep: createReducer(null, OnboardingActionTypes.SET_SUB_STEP),
    currentlyOpenInternalSubStep: createReducer(null, OnboardingActionTypes.SET_INTERNAL_SUB_STEP),
    onboardingFinalStatus: createReducer(null, OnboardingActionTypes.SET_ONBOARDING_FINAL_STATUS),
    progress: createReducer(initialProgressState, OnboardingActionTypes.SET_PROGRESS),
});
