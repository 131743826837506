import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import {Box, Grow, LinearProgress, Slide, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import CapitalDepositErrorSendingSvg from '../../../../../../assets/svg/bank/capital-deposit-error-sending.svg';
import CapitalDepositFinishedSendingSvg from '../../../../../../assets/svg/bank/capital-deposit-finished-sending.svg';
import CapitalDepositSendingSvg from '../../../../../../assets/svg/bank/capital-deposit-sending.svg';
import BankInformationLayout from '../../../../../../layout/BankInformationLayout/BankInformationLayout';
import {AnimationActions} from '../../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../../animations/utils/constants';
import {TransactionsActions} from '../../../../../bank/modules/account-balance/store/transactions.action';
import {BankActions} from '../../../../../bank/store/bank.action';
import {BankSelector} from '../../../../../bank/store/bank.selector';
import {OnboardingActions} from '../../../onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../../onboarding/store/onboarding.selectors';
import {OnboardingSteps} from '../../../onboarding/utils/onboadingStepsConstant';
import {CapitalDepositInternalSubSteps} from '../../utils/constants';

let timeout;
const numberOfElements = 13;
const percentageIncrement = 100 / numberOfElements;
const animationLength = 6500;
const animationPerSegment = animationLength / numberOfElements;
const CapitolDepositBankSendInformation = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation('freelancerOnboarding');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const capitalDeposit = useSelector(BankSelector.selectCapitalDeposit);

    const [step, setStep] = useState(1);
    const [progress, setProgress] = useState(0);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const isError = useSelector(BankSelector.selectCapitalDepositError);

    const mainProgress = useSelector(OnboardingSelectors.selectProgress);

    const onNextClick = () => {
        dispatch(TransactionsActions.getTransactionList());

        dispatch(OnboardingActions.setProgress({
            ...mainProgress,
            [OnboardingSteps.CAPITAL_DEPOSIT]: {
                ...mainProgress[OnboardingSteps.CAPITAL_DEPOSIT],
                subStepProgress: {
                    [CapitalDepositInternalSubSteps.BANK_KYC_VALIDATION]: {
                        isCompleted: true,
                    },
                    [CapitalDepositInternalSubSteps.BANK_SEND_INFORMATION]: {
                        isCompleted: true,
                    },
                    [CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION]: {
                        isCompleted: false,
                    },
                    [CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER]: {
                        isCompleted: false,
                    },
                    [CapitalDepositInternalSubSteps.BANK_CAPITOL_DEPOSIT_VALIDATION]: {
                        isCompleted: false,
                    },
                },
            },
        }));

        dispatch(AnimationActions.storeNextDispatch(
            OnboardingActions.setInternalSubStep(CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION),
        ));
        dispatch(AnimationActions.setIsAnimationActive(false));
        dispatch(OnboardingActions.setSubStep(CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION));
    };

    useEffect(() => {
        if (step > numberOfElements) {
            return;
        }
        timeout = setTimeout(() => {
            setStep(step => step + 1);
            setProgress((step + 1) * percentageIncrement <= 100 ? (step + 1) * percentageIncrement : 100);
        }, animationPerSegment);
        return () => {
            clearTimeout(timeout);
        };
    }, [step]);

    return (
        <BankInformationLayout
            header={t('capitalSendingInformation.header')}
            subTitle={t('capitalSendingInformation.subtitle')}
            Button={!isError ? (
                <Button
                    fullWidth={isMobileSize}
                    color="secondary"
                    variant="contained"
                    disabled={step <= numberOfElements || !capitalDeposit}
                    endIcon={<ArrowForwardIcon />}
                    onClick={onNextClick}
                >
                    {t('capitalSendingInformation.continue')}
                </Button>
            ) : (
                <Button
                    fullWidth={isMobileSize}
                    color="secondary"
                    variant="contained"
                    disabled={step <= numberOfElements || !capitalDeposit}
                    onClick={() => {
                        dispatch(BankActions.startCapitalDeposit());
                    }}
                >
                    {t('capitalSendingInformation.resend')}
                </Button>
            )}
        >
            {((step < numberOfElements || !capitalDeposit) && !isError) && (
            <Slide in={isAnimationActive && (step < numberOfElements || !capitalDeposit)} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                    <Box>
                        <img src={CapitalDepositSendingSvg} alt="capital deposit sending" />
                    </Box>

                    <ParagraphStepper
                        step={step}
                        capitalDeposit={capitalDeposit}
                    />

                    <Box sx={{
                        width: isMobileSize ? '100%' : '400px',
                        mb: 2,
                    }}
                    >
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                    <Typography sx={{
                        color: '#607D8B',
                        textAlign: 'center',
                        fontSize: theme => theme.typography.pxToRem(12),
                    }}
                    >
                        {t('capitalSendingInformation.sendingInProgress')}
                    </Typography>
                </Box>
            </Slide>
            )}

            {step >= numberOfElements && !isError && capitalDeposit && (
            <Slide in={isAnimationActive && (step >= numberOfElements)} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <div>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    >
                        <Box>
                            <img src={CapitalDepositFinishedSendingSvg} alt="capital deposit finished sending" />
                            <Typography
                                sx={{
                                    mt: 4,
                                }}
                                variant="h4"
                            >
                                {t('capitalSendingInformation.successTitle')}
                            </Typography>
                            <Typography sx={{
                                mt: 2,
                                maxWidth: isMobileSize ? '100%' : '500px',
                                marginLeft: '50px',
                            }}
                            >
                                {t('capitalSendingInformation.successText')}
                            </Typography>
                        </Box>
                    </Box>
                </div>
            </Slide>
            )}

            {isError && (
                <Slide in={isAnimationActive && isError} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                    <div>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        >
                            <Box>
                                <img src={CapitalDepositErrorSendingSvg} alt="capital deposit error sending" />
                                <Typography
                                    sx={{
                                        mt: 4,
                                    }}
                                    variant="h4"
                                >
                                    {t('capitalSendingInformation.errorTitle')}
                                </Typography>
                                <Typography sx={{
                                    mt: 2,
                                }}
                                >
                                    {t('capitalSendingInformation.errorText')}
                                </Typography>
                            </Box>
                        </Box>
                    </div>
                </Slide>
            )}
        </BankInformationLayout>
    );
};

const ParagraphStepper = ({step, capitalDeposit}) => {
    const {t} = useTranslation('freelancerOnboarding');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    if (step === 0) {
        return null;
    }

    return (
        <Box sx={{
            mb: 4,
            mt: 2,
            width: isMobileSize ? '100%' : '470px',
        }}
        >
            <ParagraphSegment
                text={t('capitalSendingInformation.message1')}
                isShown={step > 0}
                isCompleted={step > 2}
            />

            <ParagraphSegment
                text={t('capitalSendingInformation.message2')}
                isShown={step > 3}
                isCompleted={step > 5}
            />

            <ParagraphSegment
                text={t('capitalSendingInformation.message3')}
                isShown={step > 6}
                isCompleted={step > 8}
            />

            <ParagraphSegment
                text={t('capitalSendingInformation.message4')}
                isShown={step > 9}
                isCompleted={step > 11 && !!capitalDeposit}
            />
        </Box>
    );
};

ParagraphStepper.propTypes = {
    step: PropTypes.number.isRequired,
    capitalDeposit: PropTypes.object.isRequired,
};

const ParagraphSegment = ({text, isShown, isCompleted}) => {
    return (
        <Typography sx={{
            color: isCompleted ? '#90A4AE' : '#0B2333',
            height: isShown ? '100%' : 0,
            textAlign: 'center',
            fontSize: theme => theme.typography.pxToRem(isShown ? 21 : 0),
            fontWeight: 700,
            lineHeight: '123.5%',
            letterSpacing: '0.25px',
            mt: isShown ? 2 : 0,
            display: 'flex',
            justifyContent: 'center',
            transition: 'font-size 0.5s, color 0.5s, height 0.5s, margin 0.3s',
        }}
        >
            <Grow in={isCompleted}>
                <CheckIcon sx={{
                    ml: 0.5,
                    color: '#90A4AE',
                }}
                />
            </Grow>
            {text}
            <Grow in={!isCompleted}>
                <Box>
                    ...
                </Box>
            </Grow>
        </Typography>

    );
};

ParagraphSegment.propTypes = {
    text: PropTypes.string.isRequired,
    isShown: PropTypes.bool.isRequired,
    isCompleted: PropTypes.bool.isRequired,
};

export default CapitolDepositBankSendInformation;
