import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {CompaniesActions} from './companies.action';
import {CompaniesActionTypes} from './companies.action-type';
import {CompaniesSelectors} from './companies.selector';
import {Debug} from '../../../../../utils/debug';
import * as actions from '../../../../../v1/app/freelancer/freelancer.actions';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {FreelancerSelectors} from '../../../store/freelancer.selector';
import {CapitalDepositInternalSubSteps} from '../../capital-deposit/utils/constants';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';
import {OnboardingSteps} from '../../onboarding/utils/onboadingStepsConstant';
import {FreelancerCompaniesApi} from '../api/companies.api';

export const getFreelancerCompaniesFlow = function* ({freelancerId}) {
    try {
        const companies = yield call(FreelancerCompaniesApi.getCompanies, {freelancerId});


        yield put(CompaniesActions.storeEntities(companies?.companies));
        yield put(actions.storeFreelancerCompanies(companies?.freelancerCompanies));
    } catch (error) {
        Debug.error('freelancer.companies', error.message, {error});
    }
};

export const loadFreelancerCompanies = function* ({freelancerId}) {
    yield put(LoadingActions.setLoading(LoadingTypes.FREELANCER_COMPANIES, true));

    yield call(getFreelancerCompaniesFlow, {freelancerId});

    yield put(LoadingActions.setLoading(LoadingTypes.FREELANCER_COMPANIES, false));
};

export const registrationNotPreparedFlow = function* ({freelancerId, companyId}) {
    try {
        const company = yield call(FreelancerCompaniesApi.registrationNotPrepared, freelancerId, companyId);
        const entities = yield select(CompaniesSelectors.selectEntities);

        if (company.id) {
            const newEntities = {...entities, [company.id]: company};
            yield put(CompaniesActions.storeEntities(newEntities));
        }
    } catch (error) {
        Debug.error('freelancer.companies', error.message, {error});
    }
};

export const registrationNotPreparedWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.REGISTRATION_NOT_PREPARED, true));

    const freelancer = yield select(FreelancerSelectors.selectAccount);
    const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer.defaultCompanyId));

    if (
        ![
            'REGISTRATION_NOT_PREPARED',
            'REGISTRATION_PARTIALLY_PREPARED',
            'REGISTRATION_LAUNCHED',
            'REGISTRATION_FINALIZATION',
            'REGISTRATION_COMPLETED',
        ].includes(company.status)
    ) {
        yield call(registrationNotPreparedFlow, {
            freelancerId: freelancer.id,
            companyId: company.id,
        });
    }

    yield put(LoadingActions.setLoading(LoadingTypes.REGISTRATION_NOT_PREPARED, false));

    if (payload?.isFromUser) {
        yield put(AnimationActions.storeNextDispatch(
            OnboardingActions.setStep(OnboardingSteps.COMPANY_REGISTRATION),
        ));
        yield put(AnimationActions.setIsSubAnimationActive(false));
    } else {
        yield put(AnimationActions.storeNextDispatch(
            OnboardingActions.setInternalSubStep(CapitalDepositInternalSubSteps.DEPOSIT_DOCUMENTATION_UPLOAD),
        ));
        yield put(AnimationActions.setIsSubAnimationActive(false));
    }
};

export const companiesSaga = function* () {
    yield all([
        takeLatest(CompaniesActionTypes.REGISTRATION_NOT_PREPARED, registrationNotPreparedWorker),
    ]);
};
