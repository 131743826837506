import {useMemo, useState} from 'react';
import {roundHalfUpToTwoDecimalPlaces} from '../../../../../../utils/number';
import {TransactionsActions} from '../../store/transactions.action';
import {TransactionMatchType, categorizationState} from '../../util/constants';

const useInvoiceCategorisation = ({details, isEditable, dispatch}) => {
    const {bestMatchedInvoices = [], allOtherInvoices = []} = details?.invoices ?? {};
    const isCategorised = details?.categorizationState === categorizationState.CATEGORIZED;

    const [activeInvoice, setActiveInvoice] = useState('');

    const selectedInvoicesObj = useMemo(() => {
        // TODO: get the matchType from the BE
        const allInvoices = [
            ...(
                bestMatchedInvoices.map(invoice => ({
                    ...invoice,
                    matchType: TransactionMatchType.BEST_MATCH,
                }))
            ),
            ...(
                allOtherInvoices.map(invoice => ({
                    ...invoice,
                    matchType: TransactionMatchType.OTHER_MATCH,
                }))
            ),
        ];

        return allInvoices.filter(({selected}) => !!selected);
    }, [bestMatchedInvoices, allOtherInvoices]);

    const amount = Number(details?.amount ?? 0);
    const selectedInvoicesForPaidInfo = selectedInvoicesObj.map(({
        invoiceId,
        allocatedAmount,
        docNumber,
    }) => {
        return {
            invoiceId,
            label: `N° ${docNumber} - ${roundHalfUpToTwoDecimalPlaces(allocatedAmount)} €`,
            allocatedAmount,
        };
    });
    const allocated = useMemo(() => (
        selectedInvoicesForPaidInfo.reduce((sum, {allocatedAmount}) => sum + Number(allocatedAmount), 0)
    ), [selectedInvoicesForPaidInfo]);

    const selectedInvoices = selectedInvoicesObj.map(({invoiceId, allocatedAmount, matchType}) => {
        return {invoiceId, allocatedAmount, matchType};
    });
    const selectedInvoicesIDs = selectedInvoices.map(({invoiceId}) => invoiceId);

    const unallocatedAmount = amount - allocated;

    const onSelectInvoice = (
        id,
        allocatedAmount = 0,
        matchType = '',
        isDnd = false,
    ) => {
        if (!isEditable) {
            return;
        }

        let updatedSelectedInvoices;

        if (selectedInvoicesIDs.includes(id)) {
            if (!allocatedAmount) {
                updatedSelectedInvoices = selectedInvoices.filter(({invoiceId}) => invoiceId !== id);
            } else {
                updatedSelectedInvoices = selectedInvoices.map(invoice => {
                    if (invoice.invoiceId === id) {
                        return {
                            ...invoice,
                            allocatedAmount: Number.isFinite(allocatedAmount)
                                ? allocatedAmount.toFixed(2)
                                : allocatedAmount,
                        };
                    }
                    return invoice;
                });
            }
        } else {
            updatedSelectedInvoices = [
                ...selectedInvoices,
                {
                    invoiceId: id,
                    allocatedAmount: Number.isFinite(allocatedAmount)
                        ? allocatedAmount.toFixed(2)
                        : allocatedAmount,
                    matchType,
                },
            ];
        }

        dispatch(TransactionsActions.saveTransactionField({
            id: details.id,
            fieldKey: 'selectedInvoices',
            fieldValue: updatedSelectedInvoices,
            accountId: details?.bankAccountId,
            additionalData: isDnd ? {
                isDnd: true,
                id: id,
            } : null,
        }));
    };

    return {
        bestMatchedInvoices,
        allOtherInvoices,
        isCategorised,
        selectedInvoicesObj,
        amount,
        selectedInvoicesForPaidInfo,
        allocated,
        selectedInvoicesIDs,
        unallocatedAmount,
        activeInvoice,
        setActiveInvoice,
        onSelectInvoice,
    };
};

export default useInvoiceCategorisation;
