import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Divider, Paper, Typography} from '@mui/material';
import React, {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {OpportunityDescription} from './submit-opportunity/OpportunityDescription';
import {OpportunityFiles} from './submit-opportunity/OpportunityFiles';
import {OpportunityInformations} from './submit-opportunity/OpportunityInformations';
import {OpportunityMission} from './submit-opportunity/OpportunityMission';
import {SubmitOpportunityButtonGroup} from './submit-opportunity/SubmitOpportunityButtonGroup';
import {importLocaleBundle} from '../../../lib/i18next';
import Yup from '../../../lib/yup/yup';
import {FreelancerSelectors} from '../../freelancer';
import {LoadingSelectors, LoadingTypes} from '../../loading';
import {OpportunitiesActions} from '../store/opportunity.action';
import {REMOTE_JOB_TYPE} from '../utls/constants';

importLocaleBundle('opportunity');

const defaultValues = {
    clientName: '',
    requiredProfile: [],
    specialisations: [],

    beforeTaxes: '',
    startMonth: '',
    venue: '',

    remote: REMOTE_JOB_TYPE.NONE,
    description: '',

    files: [],
};

const createValidationSchema = t => {
    return Yup.object().shape({
        clientName: Yup.string().trim()
            .min(2,
                t('form:validation/v2.number/min', {
                    label: t('submitOpportunity.clientName'),
                    min: 2,
                }))
            .max(50,
                t('form:validation/v2.number/max', {
                    label: t('submitOpportunity.clientName'),
                    max: 50,
                }))
            .required(),
        requiredProfile: Yup.array().min(1),
        specialisations: Yup.array().when('requiredProfile', {
            is: requiredProfile => {
                if (!requiredProfile || requiredProfile.length === 0) {
                    return false;
                }
                let hasSpecialisations = false;
                requiredProfile.forEach(profile => {
                    if (profile?.job_specializations?.length > 0) {
                        hasSpecialisations = true;
                    }
                });

                return hasSpecialisations;
            },
            then: Yup.array().min(1),
        }).nullable(),

        beforeTaxes: Yup.string().max(11,
            t('form:validation/v2.number/max', {
                label: t('submitOpportunity.beforeTaxes'),
                max: 11,
            }))
            .matches(/^([1-9][0-9]*(-[1-9][0-9]*)?)?$/, t('submitOpportunity.beforeTaxesErrorText'))
            .test(
                'is-tjm',
                field => {
                    const splitted = field.value.split('-');
                    return t('submitOpportunity.beforeTaxesOrder', {first: splitted[0], second: splitted[1]});
                },
                value => {
                    const splitted = value.split('-');

                    return !(splitted.length > 1
                        && parseInt(splitted[0], 10) > parseInt(splitted[1], 10));
                },
            )
            .test(
                'is-tjm-same',
                field => {
                    const splitted = field.value.split('-');
                    return t('submitOpportunity.beforeTaxesOrderSame', {value: splitted[0]});
                },
                value => {
                    const splitted = value.split('-');

                    return !(splitted.length === 2
                        && parseInt(splitted[0], 10) === parseInt(splitted[1], 10));
                },
            ),
        startMonth: Yup.string(),
        venue: Yup.string(),

        remote: Yup.string().required(),
        description: Yup.string().trim().when('files', {
            is: files => {
                return !Array.isArray(files) || files.length === 0;
            },
            then: Yup.string().required(t('form:validation.required', {fieldName: t('submitOpportunity.description')})),
        }).nullable(),

        files: Yup.array().max(3, t('form:validation/v2.array/max', {label: t('submitOpportunity.missionHeader'), max: 3})),
    });
};

export const SubmitOpportunity = () => {
    const dispatch = useDispatch();

    const freelancerAccount = useSelector(FreelancerSelectors.selectAccount);

    const isSaving = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.SUBMIT_OPPORTUNITY),
    );

    const {t} = useTranslation('opportunity');
    const validationSchema = createValidationSchema(t);

    const onSubmit = useCallback(data => {
        dispatch(OpportunitiesActions.submitOpportunityCluster({
            freelancerId: freelancerAccount.id,
            data: {
                ...data,
                requiredProfile: data.requiredProfile.map(profile => profile.job_type),
            },
        }));
    }, [dispatch, freelancerAccount?.id]);

    const {
        control,
        formState: {errors, isValid, isDirty},
        handleSubmit,
        watch,
        setValue,
        getValues,
        trigger,
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const defaultFieldProps = {
        fullWidth: true,
        variant: 'outlined',
        disabled: isSaving,
        autoComplete: 'off',
    };

    const selectedProfile = watch('requiredProfile');

    const onFilesChange = files => {
        setValue('files', files, {shouldValidate: true});
        trigger('description');
    };

    const files = watch('files');

    return (
        <Box sx={{
            p: {
                xs: 3,
                md: 0,
            },
        }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant="h4"
                    sx={{
                        mb: {
                            xs: 1,
                            md: 2,
                        },
                    }}
                >
                    <Box component="span" sx={{mr: 1}}>
                        {t('submitOpportunity.header')}
                    </Box>
                </Typography>

                <Paper sx={{px: {xs: 3, md: 3}, pb: 3, pt: 3, borderRadius: 2}} elevation={1}>
                    <OpportunityMission
                        control={control}
                        errors={errors}
                        defaultFieldProps={defaultFieldProps}
                        selectedProfile={selectedProfile}
                        setValue={setValue}
                        getValues={getValues}
                    />

                    <Divider sx={{borderStyle: 'dashed', borderColor: '#BDBDBD'}} />

                    <OpportunityInformations
                        control={control}
                        errors={errors}
                        defaultFieldProps={defaultFieldProps}
                    />

                    <Divider sx={{borderStyle: 'dashed', borderColor: '#BDBDBD'}} />

                    <OpportunityDescription
                        control={control}
                        errors={errors}
                        defaultFieldProps={defaultFieldProps}
                        hasFiles={files.length !== 0}
                    />

                    <OpportunityFiles
                        files={files}
                        setFiles={onFilesChange}
                        errors={errors}
                    />
                </Paper>

                <SubmitOpportunityButtonGroup
                    isValid={isValid}
                    isDirty={isDirty}
                    handleClose={() => {}}
                    isSaving={isSaving}
                />
            </form>
        </Box>
    );
};

