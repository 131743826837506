import {saveAs} from 'file-saver';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import * as actions from './personalExpenses.actions';
import {
    setIsLoadingPersonalExpenses,
    storePersonalExpenses,
} from './personalExpenses.actions';
import {selectPersonalExpenses} from './personalExpenses.selectors';
import {selectCurrentCognitoUser} from '../../../../features/auth/store/auth.selectors';
import {FreelancerSelectors} from '../../../../features/freelancer';
import {Toast} from '../../../../lib/toast';
import {getSignedUrlForDocumentRequest} from '../../api/providers/company/company.provider';
import {
    deletePersonalExpenseRequest,
    getPersonalExpensesRequest,
    updatePersonalExpenseRequest,
} from '../../api/providers/expenses/expenses.provider';
import {isMobileSafari, isSafari} from '../../app.helpers';
import {loadFreelancerAccountSaga} from '../../freelancer/freelancer.sagas';

const getPersonalExpensesSaga = function* ({payload}) {
    try {
        yield put(actions.setIsLoadingPersonalExpenses(true));

        const expenses = yield call(
            getPersonalExpensesRequest,
            payload.freelancerId,
            payload.companyId,
        );

        yield put(actions.storePersonalExpenses(expenses));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingPersonalExpenses(false));
    }
};

export const personalExpensesLoaderSaga = function* ({payload}) {
    const {params = {}} = payload || {};
    let {companyId, freelancerId} = params;

    if (!companyId || !freelancerId) {
        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const {id} = yield select(selectCurrentCognitoUser);

        freelancerId = id;

        companyId = freelancer.defaultCompanyId;
    }

    yield call(loadFreelancerAccountSaga, freelancerId);

    yield put(actions.getPersonalExpenses(freelancerId, companyId));
};

export const deletePersonalExpenseSaga = function* ({payload}) {
    try {
        yield put(setIsLoadingPersonalExpenses(true));

        yield call(
            deletePersonalExpenseRequest,
            payload.freelancerId,
            payload.companyId,
            payload.expenseId,
        );

        const expenses = yield select(selectPersonalExpenses);

        const newExpenses = Object.keys(expenses)
            .filter(expenseId => expenseId !== payload.expenseId)
            .reduce((result, current) => {
                result[current] = expenses[current];

                return result;
            }, {});

        yield put(storePersonalExpenses(newExpenses));

        Toast.success('expenseDeleted');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(setIsLoadingPersonalExpenses(false));
    }
};

export const updatePersonalExpenseSaga = function* ({payload}) {
    try {
        yield put(setIsLoadingPersonalExpenses(true));

        const updatedExpense = yield call(
            updatePersonalExpenseRequest,
            payload.freelancerId,
            payload.companyId,
            payload.expenseId,
            payload.isReimbursed,
        );

        const expenses = yield select(selectPersonalExpenses);

        expenses[payload.expenseId] = updatedExpense;

        yield put(storePersonalExpenses(expenses));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(setIsLoadingPersonalExpenses(false));
    }
};

export const downloadPersonalExpenseSaga = function* ({payload}) {
    try {
        let childWindow;

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow = window.open('', '_blank');
        }

        yield put(actions.setIsLoadingPersonalExpenses(true));

        const {signedUrl} = yield call(
            getSignedUrlForDocumentRequest,
            payload.freelancerId,
            payload.companyId,
            payload.receiptId,
            payload.isDownload,
        );

        if (!signedUrl) {
            throw new Error('The document URL is missing.');
        }

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow.location = signedUrl;

            return;
        }

        if ((isSafari || isMobileSafari) && payload.isDownload) {
            // TODO:HIGH: It's ugly but it works.
            fetch(signedUrl).then(response => {
                return response.blob();
            }).then(blob => {
                const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
                const filename = matchedGroups[2];

                saveAs(blob, decodeURI(filename));
            });

            return;
        }

        window.open(signedUrl, '_blank');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingPersonalExpenses(false));
    }
};

export const watchPersonalExpensesSagas = function* () {
    yield all([
        takeLatest(actions.GET_PERSONAL_EXPENSES, getPersonalExpensesSaga),
        takeLatest(actions.DELETE_PERSONAL_EXPENSE, deletePersonalExpenseSaga),
        takeLatest(actions.DOWNLOAD_PERSONAL_EXPENSE, downloadPersonalExpenseSaga),
        takeLatest(actions.UPDATE_PERSONAL_EXPENSE, updatePersonalExpenseSaga),
    ]);
};
