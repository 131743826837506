import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import SectionStatus from '../common/SectionStatus';

const CategorisationSectionDrawer = ({
    title,
    status,
    sx,
    children,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                pb: 5,
                ...sx,
            }}
        >
            <Box sx={{
                display: 'flex',
                backgroundColor: '#fff',
                alignItems: 'center',
                gap: 2,
            }}
            >
                <Typography sx={{
                    color: 'v2.blueGray.300',
                    fontSize: theme => theme.typography.pxToRem(12),
                    fontWeight: 500,
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                }}
                >
                    {title}
                </Typography>

                {status && (
                    <SectionStatus status={status} />
                )}
            </Box>

            {children}
        </Box>
    );
};

CategorisationSectionDrawer.propTypes = {
    title: PropTypes.string.isRequired,
    status: PropTypes.string,
    sx: PropTypes.object,
};

CategorisationSectionDrawer.defaultProps = {
    status: null,
    sx: {},
};

export default CategorisationSectionDrawer;
