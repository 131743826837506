import {faClose} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import {BoxRoleButton} from '../../../../components/joy-ui/helpers/BoxRoleButton';
import JoyUIThemeProvider from '../../../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {flexCenter} from '../../../../utils/styles';
import {VatDeclarationSelectors} from '../../store/vat-declaration.selector';
import {DeclarationHistoryTable} from '../DeclarationHistoryTable';

export const DeclarationHistory = ({onClose}) => {
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);

    if (!declaration) {
        return null;
    }

    return (
        <JoyUIThemeProvider>
            <Box
                sx={{
                    minHeight: 'calc(100vh - 130px)',
                    my: 5,
                    mx: 3,
                    p: 2,
                    borderRadius: 'lg',
                    bgcolor: 'background.body',
                    border: '1px solid',
                    borderColor: 'neutral.outlinedBorder',
                }}
            >
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <BoxRoleButton
                        onPress={onClose}
                        sx={{color: 'text.icon', width: '40px', height: '40px', fontSize: '20px', ...flexCenter}}
                    >
                        <FontAwesomeIcon icon={faClose} fixedWidth />
                    </BoxRoleButton>
                </Box>
                <DeclarationHistoryTable companyId={declaration.companyId} onView={onClose} />
            </Box>
        </JoyUIThemeProvider>
    );
};

DeclarationHistory.propTypes = {
    onClose: PropTypes.func.isRequired,
};
