import {ContractSigningActionTypes} from './contract-signing.action-types';

export const ContractSigningActions = {
    goToWorkshopStep: () => ({
        type: ContractSigningActionTypes.GO_TO_WORKSHOP_STEP,
    }),
    submitPersonalAddress: (formData, isDirty) => ({
        type: ContractSigningActionTypes.SUBMIT_PERSONAL_ADDRESS,
        payload: {
            formData,
            isDirty,
        },
    }),
    setIsLoadingPersonalAddressForm: isLoading => ({
        type: ContractSigningActionTypes.SET_IS_LOADING_PERSONAL_ADDRESS_FORM,
        payload: isLoading,
    }),
};
