import {createSelector} from 'reselect';

const getClusters = state => state.opportunity.clusters;
const getOpportunities = state => state.opportunity.clusters.entities;
const getSpecialisations = state => state.opportunity.specialisations;
const getOpportunityIds = state => state.opportunity.clusters.ids;
const getSearch = state => state.opportunity.search;
const selectOpportunities = state => state.opportunity.opportunityList;
const selectOpportunitiesCount = state => state.opportunity.opportunityList.count;
const selectOtherOpportunities = state => state.opportunity.otherOpportunityList;
const selectOtherOpportunitiesCount = state => state.opportunity.otherOpportunityList.count;
const selectMyBids = state => state.opportunity.myBidsList;

const selectOpportunityById = id => createSelector(
    [getOpportunities],
    opportunities => opportunities[id],
);

const createOpportunitySelector = () => () => createSelector(
    [selectOpportunities],
    opportunities => {
        if (!opportunities.count) {
            return {data: [], count: 0};
        }

        return {
            data: opportunities.items,
            count: Number(opportunities.count),
        };
    },
);

const createOtherOpportunitySelector = () => () => createSelector(
    [selectOtherOpportunities],
    opportunities => {
        if (!opportunities.count) {
            return {data: [], count: 0};
        }

        return {
            data: opportunities.items,
            count: Number(opportunities.count),
        };
    },
);

export const OpportunitySelector = {
    getClusters,
    getOpportunities,
    getSpecialisations,
    getOpportunityIds,
    selectOpportunityById,
    getSearch,
    selectOpportunities,
    selectOpportunitiesCount,
    createOpportunitySelector,
    selectOtherOpportunities,
    selectOtherOpportunitiesCount,
    selectMyBids,
    createOtherOpportunitySelector,
};
