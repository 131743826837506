/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {Box} from '@mui/joy';
import {styled, useThemeProps} from '@mui/system';
import * as React from 'react';
import {getCalendarOrClockPickerUtilityClass} from './calendarOrClockPickerClasses';
import {useFocusManagement} from './useFocusManagement';
import {CalendarPicker} from '../../../CalendarPicker/CalendarPicker';
import {ClockPicker} from '../../../ClockPicker/ClockPicker';
import {useIsLandscape} from '../../hooks/useIsLandscape';
import {useViews} from '../../hooks/useViews';
import {KeyboardDateInput} from '../KeyboardDateInput';
import {PickerViewRoot} from '../PickerViewRoot';
import {WrapperVariantContext} from '../wrappers/WrapperVariantContext';

const useUtilityClasses = ownerState => {
    const {classes} = ownerState;
    const slots = {
        root: ['root'],
    };

    return composeClasses(slots, getCalendarOrClockPickerUtilityClass, classes);
};

const PickerRoot = styled('div', {
    name: 'MuiCalendarOrClockPicker',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})(({ownerState}) => ({
    display: 'flex',
    flexDirection: 'column',
    ...(ownerState.isLandscape && {
        flexDirection: 'row',
    }),
}));

const MobileKeyboardTextFieldProps = {fullWidth: true};

const isDatePickerView = view => view === 'year' || view === 'month' || view === 'day';

const isTimePickerView = view => view === 'hours' || view === 'minutes' || view === 'seconds';

let warnedOnceNotValidOpenTo = false;

export const CalendarOrClockPicker = inProps => {
    const props = useThemeProps({
        props: inProps,
        name: 'MuiCalendarOrClockPicker',
    });
    const {
        autoFocus,
        className,
        parsedValue,
        DateInputProps,
        isMobileKeyboardViewOpen,
        onDateChange,
        onViewChange,
        openTo,
        orientation,
        showToolbar,
        toggleMobileKeyboardView,
        ToolbarComponent = () => null,
        toolbarFormat,
        toolbarPlaceholder,
        toolbarTitle,
        views,
        dateRangeIcon,
        timeIcon,
        hideTabs,
        // excluding classes from `other` to avoid passing them down to children
        classes: providedClasses,
        ...other
    } = props;
    const TabsComponent = other.components?.Tabs;
    const isLandscape = useIsLandscape(views, orientation);
    const wrapperVariant = React.useContext(WrapperVariantContext);
    const classes = useUtilityClasses(props);

    const toShowToolbar = showToolbar ?? wrapperVariant !== 'desktop';
    const showTabs = !hideTabs && typeof window !== 'undefined' && window.innerHeight > 667;

    const handleDateChange = React.useCallback(
        (newDate, selectionState) => {
            onDateChange(newDate, wrapperVariant, selectionState);
        },
        [onDateChange, wrapperVariant],
    );

    const handleViewChange = React.useCallback(
        newView => {
            if (isMobileKeyboardViewOpen) {
                toggleMobileKeyboardView();
            }
            if (onViewChange) {
                onViewChange(newView);
            }
        },
        [isMobileKeyboardViewOpen, onViewChange, toggleMobileKeyboardView],
    );

    if (process.env.NODE_ENV !== 'production') {
        if (!warnedOnceNotValidOpenTo && !views.includes(openTo)) {
            // eslint-disable-next-line no-console
            console.warn(
                `MUI: \`openTo="${openTo}"\` is not a valid prop.`,
                `It must be an element of \`views=["${views.join('", "')}"]\`.`,
            );
            warnedOnceNotValidOpenTo = true;
        }
    }

    const {openView, setOpenView, handleChangeAndOpenNext} = useViews({
        view: undefined,
        views,
        openTo,
        onChange: handleDateChange,
        onViewChange: handleViewChange,
    });

    const {focusedView, setFocusedView} = useFocusManagement({
        autoFocus,
        openView,
    });

    return (
        <PickerRoot ownerState={{isLandscape}} className={classes.root}>
            {toShowToolbar && (
                <ToolbarComponent
                    {...other}
                    views={views}
                    isLandscape={isLandscape}
                    parsedValue={parsedValue}
                    onChange={handleDateChange}
                    setOpenView={setOpenView}
                    openView={openView}
                    toolbarTitle={toolbarTitle}
                    toolbarFormat={toolbarFormat}
                    toolbarPlaceholder={toolbarPlaceholder}
                    isMobileKeyboardViewOpen={isMobileKeyboardViewOpen}
                    toggleMobileKeyboardView={toggleMobileKeyboardView}
                />
            )}
            {showTabs && !!TabsComponent && (
                <TabsComponent
                    dateRangeIcon={dateRangeIcon}
                    timeIcon={timeIcon}
                    view={openView}
                    onChange={setOpenView}
                    {...other.componentsProps?.tabs}
                />
            )}
            <PickerViewRoot>
                {isMobileKeyboardViewOpen ? (
                    <Box sx={{px: 3, py: 2}}>
                        <KeyboardDateInput
                            {...DateInputProps}
                            ignoreInvalidInputs
                            disableOpenPicker
                            TextFieldProps={MobileKeyboardTextFieldProps}
                        />
                    </Box>
                ) : (
                    <React.Fragment>
                        {isDatePickerView(openView) && (
                            <CalendarPicker
                                autoFocus={autoFocus}
                                date={parsedValue}
                                onViewChange={setOpenView}
                                onChange={handleChangeAndOpenNext}
                                view={openView}
                                // Unclear why the predicate `isDatePickerView` does not imply the casted type
                                views={views.filter(isDatePickerView)}
                                focusedView={focusedView}
                                onFocusedViewChange={setFocusedView}
                                {...other}
                            />
                        )}

                        {isTimePickerView(openView) && (
                            <ClockPicker
                                {...other}
                                autoFocus={autoFocus}
                                date={parsedValue}
                                view={openView}
                                // Unclear why the predicate `isDatePickerView` does not imply the casted type
                                views={views.filter(isTimePickerView)}
                                onChange={handleChangeAndOpenNext}
                                onViewChange={setOpenView}
                                showViewSwitcher={wrapperVariant === 'desktop'}
                            />
                        )}
                    </React.Fragment>
                )}
            </PickerViewRoot>
        </PickerRoot>
    );
};
