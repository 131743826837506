export const REGIONAL_DEPARTMENTS = [
    {
        id: '01',
        name: 'Ain',
    },
    {
        id: '02',
        name: 'Aisne',
    },
    {
        id: '03',
        name: 'Allier',
    },
    {
        id: '04',
        name: 'Alpes-de-Haute-Provence',
    },
    {
        id: '06',
        name: 'Alpes-Maritimes',
    },
    {
        id: '07',
        name: 'Ardèche',
    },
    {
        id: '08',
        name: 'Ardennes',
    },
    {
        id: '09',
        name: 'Ariège',
    },
    {
        id: '10',
        name: 'Aube',
    },
    {
        id: '11',
        name: 'Aude',
    },
    {
        id: '12',
        name: 'Aveyron',
    },
    {
        id: '67',
        name: 'Bas-Rhin',
    },
    {
        id: '13',
        name: 'Bouches-du-Rhône',
    },
    {
        id: '14',
        name: 'Calvados',
    },
    {
        id: '15',
        name: 'Cantal',
    },
    {
        id: '16',
        name: 'Charente',
    },
    {
        id: '17',
        name: 'Charente-Maritime',
    },
    {
        id: '18',
        name: 'Cher',
    },
    {
        id: '19',
        name: 'Corrèze',
    },
    {
        id: '2A',
        name: 'Corse-du-Sud',
    },
    {
        id: '21',
        name: 'Côte-d\'Or',
    },
    {
        id: '22',
        name: 'Côtes-d\'Armor',
    },
    {
        id: '23',
        name: 'Creuse',
    },
    {
        id: '79',
        name: 'Deux-Sèvres',
    },
    {
        id: '24',
        name: 'Dordogne',
    },
    {
        id: '25',
        name: 'Doubs',
    },
    {
        id: '26',
        name: 'Drôme',
    },
    {
        id: '91',
        name: 'Essonne',
    },
    {
        id: '27',
        name: 'Eure',
    },
    {
        id: '28',
        name: 'Eure-et-Loir',
    },
    {
        id: '29',
        name: 'Finistère',
    },
    {
        id: '30',
        name: 'Gard',
    },
    {
        id: '32',
        name: 'Gers',
    },
    {
        id: '33',
        name: 'Gironde',
    },
    {
        id: '971',
        name: 'Guadeloupe',
    },
    {
        id: '973',
        name: 'Guyane',
    },
    {
        id: '2B',
        name: 'Haute-Corse',
    },
    {
        id: '31',
        name: 'Haute-Garonne',
    },
    {
        id: '43',
        name: 'Haute-Loire',
    },
    {
        id: '52',
        name: 'Haute-Marne',
    },
    {
        id: '05',
        name: 'Hautes-Alpes',
    },
    {
        id: '70',
        name: 'Haute-Saône',
    },
    {
        id: '74',
        name: 'Haute-Savoie',
    },
    {
        id: '65',
        name: 'Hautes-Pyrénées',
    },
    {
        id: '87',
        name: 'Haute-Vienne',
    },
    {
        id: '68',
        name: 'Haut-Rhin',
    },
    {
        id: '92',
        name: 'Hauts-de-Seine',
    },
    {
        id: '34',
        name: 'Hérault',
    },
    {
        id: '35',
        name: 'Ille-et-Vilaine',
    },
    {
        id: '36',
        name: 'Indre',
    },
    {
        id: '37',
        name: 'Indre-et-Loire',
    },
    {
        id: '38',
        name: 'Isère',
    },
    {
        id: '39',
        name: 'Jura',
    },
    {
        id: '40',
        name: 'Landes',
    },
    {
        id: '974',
        name: 'La Réunion',
    },
    {
        id: '42',
        name: 'Loire',
    },
    {
        id: '44',
        name: 'Loire-Atlantique',
    },
    {
        id: '45',
        name: 'Loiret',
    },
    {
        id: '41',
        name: 'Loir-et-Cher',
    },
    {
        id: '46',
        name: 'Lot',
    },
    {
        id: '47',
        name: 'Lot-et-Garonne',
    },
    {
        id: '48',
        name: 'Lozère',
    },
    {
        id: '49',
        name: 'Maine-et-Loire',
    },
    {
        id: '50',
        name: 'Manche',
    },
    {
        id: '51',
        name: 'Marne',
    },
    {
        id: '972',
        name: 'Martinique',
    },
    {
        id: '53',
        name: 'Mayenne',
    },
    {
        id: '976',
        name: 'Mayotte',
    },
    {
        id: '54',
        name: 'Meurthe-et-Moselle',
    },
    {
        id: '55',
        name: 'Meuse',
    },
    {
        id: '56',
        name: 'Morbihan',
    },
    {
        id: '57',
        name: 'Moselle',
    },
    {
        id: '58',
        name: 'Nièvre',
    },
    {
        id: '59',
        name: 'Nord',
    },
    {
        id: '60',
        name: 'Oise',
    },
    {
        id: '61',
        name: 'Orne',
    },
    {
        id: '99',
        name: {
            en: 'Other',
            fr: 'Autre',
        },
    },
    {
        id: '75',
        name: 'Paris',
    },
    {
        id: '62',
        name: 'Pas-de-Calais',
    },
    {
        id: '63',
        name: 'Puy-de-Dôme',
    },
    {
        id: '64',
        name: 'Pyrénées-Atlantiques',
    },
    {
        id: '66',
        name: 'Pyrénées-Orientales',
    },
    {
        id: '69',
        name: 'Rhône',
    },
    {
        id: '71',
        name: 'Saône-et-Loire',
    },
    {
        id: '72',
        name: 'Sarthe',
    },
    {
        id: '73',
        name: 'Savoie',
    },
    {
        id: '77',
        name: 'Seine-et-Marne',
    },
    {
        id: '76',
        name: 'Seine-Maritime',
    },
    {
        id: '93',
        name: 'Seine-Saint-Denis',
    },
    {
        id: '80',
        name: 'Somme',
    },
    {
        id: '81',
        name: 'Tarn',
    },
    {
        id: '82',
        name: 'Tarn-et-Garonne',
    },
    {
        id: '90',
        name: 'Territoire de Belfort',
    },
    {
        id: '94',
        name: 'Val-de-Marne',
    },
    {
        id: '95',
        name: 'Val-d\'Oise',
    },
    {
        id: '83',
        name: 'Var',
    },
    {
        id: '84',
        name: 'Vaucluse',
    },
    {
        id: '85',
        name: 'Vendée',
    },
    {
        id: '86',
        name: 'Vienne',
    },
    {
        id: '88',
        name: 'Vosges',
    },
    {
        id: '89',
        name: 'Yonne',
    },
    {
        id: '78',
        name: 'Yvelines',
    },
    {
        id: '984',
        name: 'Terres australes et antarctiques françaises',
    },
    {
        id: '986',
        name: 'Wallis-et-Futuna',
    },
    {
        id: '987',
        name: 'Polynésie française',
    },
    {
        id: '988',
        name: 'Nouvelle-Calédonie',
    },
    {
        id: '989',
        name: 'Île de Clipperton',
    },
];
