import makeStyles from '@mui/styles/makeStyles';
import JoyUiTheme from '../../../../lib/theme-joy-ui';

window.JoyUi = JoyUiTheme;

export const useDataTableStyles = makeStyles(theme => ({
    root: ({isJoyTheme}) => ({
        ...(isJoyTheme && {padding: theme.spacing(2)}),
        paddingBottom: theme.spacing(3),
    }),
    tableCell: {
        padding: theme.spacing(1, 2, 0),
    },
    contentTableCell: ({isJoyTheme}) => ({
        padding: theme.spacing(1, 2),
        ...(isJoyTheme && {fontFamily: 'Avantt', fontWeight: 500, fontSize: '16px'}),
    }),
    preserveWhiteSpace: {
        whiteSpace: 'pre',
    },
    textWrap: {
        overflowWrap: 'anywhere',
    },
    tableContainer: ({isJoyTheme}) => ({
        marginTop: theme.spacing(3),
        ...(isJoyTheme && {padding: `0 ${theme.spacing(2)}`, marginTop: 0}),
    }),
    tableRow: ({isJoyTheme}) => ({
        '&.MuiTableRow-hover:hover': isJoyTheme ? {
            'boxShadow': JoyUiTheme.shadow.md,
            'transform': 'translateY(-1px)',
            'filter': 'drop-shadow(0px 2px 8px rgba(21, 21, 21, 0.08)) drop-shadow(0px 6px 12px rgba(21, 21, 21, 0.08))',
            'backgroundColor': 'white',
            '& > td:first-of-type': {
                borderTopLeftRadius: JoyUiTheme.radius.md,
                borderBottomLeftRadius: JoyUiTheme.radius.md,
            },
            '& > td:last-of-type': {
                borderTopRightRadius: JoyUiTheme.radius.md,
                borderBottomRightRadius: JoyUiTheme.radius.md,
            },

        } : {
            backgroundColor: theme.palette.v2.light.primary.shades,
        },
        '&:hover a': isJoyTheme ? {} : {
            color: theme.palette.primary.main,
        },
    }),
    tableHead: ({isJoyTheme}) => (isJoyTheme ? ({
        fontFamily: 'serif',
        color: 'red',
    }) : {}),
}));
