import makeStyles from '@mui/styles/makeStyles';

export const useAccountDropdownStyles = makeStyles(theme => ({
    expandMoreIcon: {
        fontSize: theme.typography.pxToRem(16),
    },
    skeleton: {
        backgroundColor: '#173244',
        margin: theme.spacing(1),
    },
    paper: {
        borderTopRightRadius: 0,
    },
    icon: {
        fontSize: theme.typography.pxToRem(20),
        color: 'rgba(11, 35, 51, 0.38)',
        marginRight: theme.spacing(),
    },
    link: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(14),
        color: 'rgba(0, 0, 0, 0.87)',
        padding: theme.spacing(1.5, 2),
    },
    activeLink: {
        'color': theme.palette.primary.main,
        '& svg': {
            fill: theme.palette.primary.main,
        },
    },
}));
