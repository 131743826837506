import {combineReducers} from 'redux';
import {
    CLEAR_PASSWORD_ERROR,
    CLEAR_USERNAME_ERROR,
    SAVE_REDIRECT_LOCATION,
    STORE_IS_LOADING_LOGIN_FORM,
    STORE_PASSWORD_ERROR_CODE,
    STORE_USERNAME_ERROR_CODE,
} from './loginForm.actions';

const initialState = {
    usernameErrorCode: '',
    passwordErrorCode: '',
    isLoading: false,
};

const usernameErrorCodeReducer = (state = initialState.usernameErrorCode, action) => {
    if (action.type === CLEAR_USERNAME_ERROR) {
        return initialState.usernameErrorCode;
    }

    if (action.type === STORE_USERNAME_ERROR_CODE) {
        return action.payload;
    }

    return state;
};

const passwordErrorCodeReducer = (state = initialState.passwordErrorCode, action) => {
    if (action.type === CLEAR_PASSWORD_ERROR) {
        return initialState.passwordErrorCode;
    }

    if (action.type === STORE_PASSWORD_ERROR_CODE) {
        return action.payload;
    }

    return state;
};
const isLoadingReducer = (state = initialState.isLoading, action) => {
    if (action.type === STORE_IS_LOADING_LOGIN_FORM) {
        return action.payload;
    }

    return state;
};

const redirectLocationReducer = (state = {}, action) => {
    if (action.type === SAVE_REDIRECT_LOCATION) {
        return action.payload;
    }

    return state;
};

export const loginFormReducer = combineReducers({
    usernameErrorCode: usernameErrorCodeReducer,
    passwordErrorCode: passwordErrorCodeReducer,
    isLoading: isLoadingReducer,
    redirectLocation: redirectLocationReducer,
});
