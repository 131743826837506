export const CapitalDepositInternalSubSteps = {
    INITIAL_SCREEN: 'INITIAL_SCREEN',
    BANK_KYC_VALIDATION: 'BANK_KYC_VALIDATION',
    BANK_SEND_INFORMATION: 'BANK_SEND_INFORMATION',
    BANK_FUND_TRANSFER: 'BANK_FUND_TRANSFER',
    BANK_IDENTITY_VALIDATION: 'BANK_IDENTITY_VALIDATION',
    BANK_CAPITOL_DEPOSIT_VALIDATION: 'BANK_CAPITOL_DEPOSIT_VALIDATION',
    FINAL_SCREEN: 'FINAL_SCREEN',

    DEPOSIT_DOCUMENTATION_UPLOAD: 'DEPOSIT_DOCUMENTATION_UPLOAD',
};

export const FinalizationSubsteps = {
    PENDING_BANK_ONBOARDING: 'PENDING_BANK_ONBOARDING',
    DOCUMENT_SIGNING_FINALIZATION: 'DOCUMENT_SIGNING_FINALIZATION',
    PENDING_FINALIZATION: 'PENDING_FINALIZATION',
    INSURANCE: 'INSURANCE',
    ACCESS_PLATFORM: 'ACCESS_PLATFORM',
};

export const capitalDepoistPaymentLevel = {
    UNLIMITED: 'UNLIMITED',
    LIMITED: 'LIMITED',
};

export const IndividualIdentificationStatus = {
    NOT_STARTED: 'NOT_STARTED',
    WAITING_FOR_VERIFICATION: 'WAITING_FOR_VERIFICATION',
    PENDING: 'PENDING',
    VERIFIED: 'VERIFIED',
    REFUSED: 'REFUSED',
};

export const KycIdentificationStatus = {
    NOT_STARTED: 'NOT_STARTED',
    WAITING_FOR_VERIFICATION: 'WAITING_FOR_VERIFICATION',
    PENDING: 'PENDING',
    VERIFIED: 'VERIFIED',
    REFUSED: 'REFUSED',
};

export const CapitalDepositStatus = {
    // Create your Pro banking account (probably never encountered, it depends mostly on kyc.identificationStatus)
    PENDING: 'PENDING',
    // Send company information to SWAN
    INITIATED: 'INITIATED',
    WAITING_FOR_ONBOARDING_FINALIZATION: 'WAITING_FOR_ONBOARDING_FINALIZATION',
    // Individual validation
    WAITING_FOR_INDIVIDUAL_ACCOUNT_HOLDER_VERIFICATION: 'WAITING_FOR_INDIVIDUAL_ACCOUNT_HOLDER_VERIFICATION',
    // Make a Capital Deposit money transfer
    WAITING_FOR_SHAREHOLDERS_FUND_DEPOSIT: 'WAITING_FOR_SHAREHOLDERS_FUND_DEPOSIT',
    // Validation of Capital Deposit
    WAITING_FOR_REQUIREMENTS_APPROVAL: 'WAITING_FOR_REQUIREMENTS_APPROVAL',
    UPLOADED_DOCUMENTS_REFUSED: 'UPLOADED_DOCUMENTS_REFUSED',
    WAITING_FOR_NOTARY_DEPOSIT_CERTIFICATE: 'WAITING_FOR_NOTARY_DEPOSIT_CERTIFICATE',
    // Validation du dépôt de capital
    NOTARY_DEPOSIT_CERTIFICATE_UPLOADED: 'NOTARY_DEPOSIT_CERTIFICATE_UPLOADED',
    // After manual change
    WAITING_FOR_PROOF_OF_REGISTRATION_UPLOAD: 'WAITING_FOR_PROOF_OF_REGISTRATION_UPLOAD',
    WAITING_FOR_NOTARY_TRANSFER: 'WAITING_FOR_NOTARY_TRANSFER',
    COMPLETED: 'COMPLETED',
};
