import AttachmentIcon from '@mui/icons-material/Attachment';
import DevicesTwoToneIcon from '@mui/icons-material/DevicesTwoTone';
import {Box, CircularProgress, Divider} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {BankDropZone} from '../../../../../../components/dropzone/BankDropZone';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {TransactionFile} from '../../../../components/TransactionFile';
import {emptyFunction} from '../../../account-balance/util/constants';

// Used in transfer create
const TransferFileSection = ({
    fileList,
    setFileList,
    details,
    isEditable,
    setIsQROpen,
}) => {
    const {t} = useTranslation('bank');

    const [fileMenuOpen, setIsFileMenuOpen] = useState(null);

    const isFileUploading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.UPLOAD_BANK_FILE,
    )) || false;

    const onFileSelect = useCallback(file => {
        if (file.length === fileList.length) {
            return;
        }
        setFileList(file);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFileList]);

    const deleteNotUploadedFile = deleteFile => {
        const index = fileList.findIndex(file => {
            return deleteFile.path === file.path;
        });

        if (index !== -1) {
            const newFiles = [...fileList];
            newFiles.splice(index, 1);
            setFileList(newFiles);
        }
    };

    return (
        <>
            <Box sx={{
                'mt': 2,
                'textTransform': 'uppercase',
                'zIndex': '333',
                'fontWeight': 500,
                'fontSize': theme => theme.typography.pxToRem(13),
            }}
            >
                {fileList && fileList.map(file => {
                    return (
                        <TransactionFile
                            key={file.name}
                            file={file}
                            type="TRANSFERS"
                            parentId={details?.id}
                            isEditable={true}
                            hasDelayedUpload={true}
                            deleteNotUploadedFile={deleteNotUploadedFile}
                            isFileMenuOpen={fileMenuOpen}
                            setIsFileMenuOpen={setIsFileMenuOpen}
                        />
                    );
                })}

                <BankDropZone
                    fileTypes="image/jpeg, image/png, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    maxFiles={50}
                    onChange={isEditable ? onFileSelect : emptyFunction}
                    isFileUploading={isFileUploading}
                    isDisabled={!isEditable}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: isEditable ? 'pointer' : 'auto',
                            color: isEditable ? 'inherit' : 'v2.light.text.disabled',
                        }}
                    >
                        {isFileUploading && <CircularProgress size={20} sx={{mr: 2}} />}
                        {!isFileUploading && (
                        <AttachmentIcon
                            sx={{
                                color: isEditable ? 'primary_v2.main' : 'v2.light.text.disabled',
                                mr: 2,
                            }}
                        />
                        )}
                        {t('translationDetails.chooseFile')}
                    </Box>
                </BankDropZone>

                <Divider sx={{my: 1}} />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        color: isEditable ? 'inherit' : 'v2.light.text.disabled',
                        cursor: isEditable ? 'pointer' : 'default',
                    }}
                    onClick={isEditable ? () => setIsQROpen(true) : () => {}}
                >
                    <DevicesTwoToneIcon sx={{
                        color: isEditable ? 'primary_v2.main' : 'v2.light.text.disabled',
                        mr: 2,
                    }}
                    />
                    {t('translationDetails.scanWithApp')}
                </Box>
            </Box>
        </>
    );
};

TransferFileSection.propTypes = {
    fileList: PropTypes.array.isRequired,
    setFileList: PropTypes.func.isRequired,
    details: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    setIsQROpen: PropTypes.func.isRequired,
};

export default TransferFileSection;
