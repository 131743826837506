import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import CreditDnDLayerWrapper from './CreditDnDLayerWrapper';
import {
    InvoiceAdditionalSection,
    InvoiceBottomAlert,
    InvoiceMainSection,
    InvoiceMainSectionTitle,
    InvoiceNoMatching,
    InvoiceResult, InvoiceResultAlert,
} from './CreditInvoiceLayoutSegments';
import {TransactionMatchType} from '../../../../util/constants';
import {DRAG_ITEMS} from '../../utils/constants';

const CreditInvoiceMatchingLayout = ({
    details,
    handleOpenAllCategories,
    setIsExternalOpen,
    data,
}) => {
    const {t} = useTranslation('bank');

    const numberOfNotSelected = {
        main: data?.bestMatchedInvoices.filter(element => element.selected === false).length ?? 0,
        other: data?.allOtherInvoices.filter(element => element.selected === false).length ?? 0,
    };

    const hasSuggestions = numberOfNotSelected.main > 0
        || numberOfNotSelected.other > 0;

    const onDrop = item => {
        data.onSelectInvoice(
            item?.invoiceId,
            item?.partialPaidValue,
            data?.details?.matchType ?? TransactionMatchType.BEST_MATCH,
            true,
        );
    };

    return (
        <CreditDnDLayerWrapper
            categorySectionTitle={t('categorisation.invoiceMatching.categorySectionTitle')}
            mainSectionTitle={<InvoiceMainSectionTitle />}
            selectedSectionTitle={t('categorisation.invoiceMatching.selectedSectionTitle')}
            mainSectionAccordionTitle={t('categorisation.invoiceMatching.mainSectionTitle')}
            otherSectionAccordionTitle={t('categorisation.invoiceMatching.otherSectionTitle')}
            hasShownDropArea={data?.unallocatedAmount !== 0}
            MainSection={hasSuggestions ? InvoiceMainSection : InvoiceNoMatching}
            AdditionalSection={hasSuggestions ? InvoiceAdditionalSection : null}
            ResultSection={InvoiceResult}
            BottomAlert={hasSuggestions ? InvoiceBottomAlert : null}
            ResultAlert={InvoiceResultAlert}
            onDrop={onDrop}
            dndDependancy={details}
            data={{
                ...data,
                details,
                numberOfNotSelected,
                handleOpenAllCategories,
                setIsExternalOpen,
                hasSuggestions,
                type: DRAG_ITEMS.INVOICE,
            }}
        />
    );
};

CreditInvoiceMatchingLayout.propTypes = {
    details: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    handleOpenAllCategories: PropTypes.func.isRequired,
    setIsExternalOpen: PropTypes.func.isRequired,
};

export default CreditInvoiceMatchingLayout;
