import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch} from 'react-redux';
import BankOfFranceSection from './sections/BankOfFranceSection';
import BoxesSection from './sections/BoxesSection';
import DownloadApplicationSection from './sections/DownloadApplicationSection';
import HiwayAccountSection from './sections/HiwayAccountSection';
import HiwayExperinceSection from './sections/HiwayExperinceSection';
import SwanSection from './sections/SwanSection';
import {BankActions} from '../store/bank.action';
import {LANDING_PAGE_MODE} from '../utils/constants';

const HiwayBankPromoPage = ({
    onCreationStartClickFn,
    creationButtonTranslation,
    mode,
    pageTitle,
}) => {
    const dispatch = useDispatch();

    const onCreationStartClick = () => {
        if (onCreationStartClickFn) {
            onCreationStartClickFn();
            return;
        }

        dispatch(BankActions.createBankAccount());
    };

    return (
        <Box sx={{
            px: {
                xs: 2,
                md: 0,
            },
        }}
        >
            {pageTitle && (
                <Box sx={{
                    mb: 2,
                    mt: {
                        xs: 1,
                        md: 1,
                        lg: 0,
                    },
                }}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            fontSize: '34px !important',
                            lineHeight: '40px !important',
                            letterSpacing: '-0.2px',
                        }}
                    >
                        {pageTitle}
                    </Typography>
                </Box>
            )}

            <HiwayAccountSection
                onCreationStartClick={onCreationStartClick}
                mode={mode}
            />

            <BoxesSection />

            <HiwayExperinceSection
                onCreationStartClick={onCreationStartClick}
                creationButtonTranslation={creationButtonTranslation}
            />

            <BankOfFranceSection onCreationStartClick={onCreationStartClick} />

            <SwanSection onCreationStartClick={onCreationStartClick} />

            <DownloadApplicationSection />
        </Box>
    );
};

HiwayBankPromoPage.propTypes = {
    onCreationStartClickFn: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    creationButtonTranslation: PropTypes.string,
    mode: PropTypes.PropTypes.oneOf(Object.values(LANDING_PAGE_MODE)),
    pageTitle: PropTypes.string,
};

HiwayBankPromoPage.defaultProps = {
    onCreationStartClickFn: null,
    creationButtonTranslation: 'activateService',
    mode: LANDING_PAGE_MODE.GENERIC,
    pageTitle: '',
};

export default HiwayBankPromoPage;
