import {faCheckCircle, faExclamationCircle, faHourglassStart} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Typography} from '@mui/joy';
import {useEffect, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {importLocaleBundle} from '../../../../../lib/i18next';
import hiwayLocalStorage from '../../../../../v1/config/storage';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {VatDeclarationSelectors} from '../../../../vat-declaration/store/vat-declaration.selector';
import {VAT_LOCAL_STORAGE_KEY, VatDeclarationSendStatus, VatDeclarationSendUIState} from '../utils/consts';

importLocaleBundle('formalities');

const useVATDeclarationSendProgress = () => {
    const {t} = useTranslation('formalities');
    const dispatch = useDispatch();
    const [storageData, setStorageData] = useState({batchId: 0, hasDismissedNotification: false});

    const batchProgress = useSelector(VatDeclarationSelectors.selectBatchProgress);
    const processedDeclarations = useSelector(VatDeclarationSelectors.selectProcessedDeclarationsCount);

    const uiState = useMemo(() => {
        if (batchProgress.status === VatDeclarationSendStatus.IN_PROGRESS) {
            return VatDeclarationSendUIState.IN_PROGRESS;
        }

        if (batchProgress.status === VatDeclarationSendStatus.COMPLETED) {
            // All sent are successfully submitted.
            if (batchProgress.batchSize - batchProgress.submitted === 0) {
                return VatDeclarationSendUIState.COMPLETED;
            }

            return VatDeclarationSendUIState.COMPLETED_WITH_ERRORS;
        }

        // Default
        return VatDeclarationSendUIState.NONE;
    }, [batchProgress]);

    const notifications = useMemo(() => {
        const notifications = [];
        const {hasDismissedNotification} = storageData;

        const handleAction = () => {
            if (batchProgress.batchSize === 1) {
                dispatch(UiActions.setActiveModal(ModalsKeys.PROCESS_SINGLE_VAT_DECLARATION, true));
            } else {
                dispatch(UiActions.setActiveModal(ModalsKeys.PROCESS_MULTIPLE_VAT_DECLARATION, true));
            }
        };

        const handleDismiss = () => {
            const {batchId} = hiwayLocalStorage.get(VAT_LOCAL_STORAGE_KEY);
            hiwayLocalStorage.set(VAT_LOCAL_STORAGE_KEY, {
                batchId,
                hasDismissedNotification: true,
            });
            setStorageData({
                batchId,
                hasDismissedNotification: true,
            });
        };

        if (uiState === VatDeclarationSendUIState.IN_PROGRESS) {
            notifications.push({
                title: (
                    <Trans
                        t={t}
                        i18nKey={`vatDeclaration.notifications.statusData.${uiState}.title`}
                        components={{
                            b: <Typography fontWeight="700" />,
                        }}
                        values={{
                            processed: processedDeclarations,
                            toProcess: batchProgress.batchSize,
                        }}
                    />
                ),
                cta: {
                    label: t(`vatDeclaration.notifications.statusData.${uiState}.cta`),
                    variant: 'soft',
                    color: 'neutral',
                },
                startDecorator: <FontAwesomeIcon icon={faHourglassStart} />,
                variant: '',
                color: '',
                action: handleAction,
                showDismissCTA: false,
            });
        }

        if (uiState === VatDeclarationSendUIState.COMPLETED && !hasDismissedNotification) {
            notifications.push({
                title: (
                    <Trans
                        t={t}
                        i18nKey={`vatDeclaration.notifications.statusData.${uiState}.title`}
                        components={{
                            b: <Typography fontWeight="700" />,
                        }}
                        values={{
                            processed: processedDeclarations,
                            toProcess: batchProgress.batchSize,
                        }}
                    />
                ),
                startDecorator: (
                    <Typography textColor="neutral.plainColor">
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </Typography>
                ),
                variant: 'soft',
                color: 'success',
                action: handleAction,
                showDismissCTA: true,
                onDismiss: handleDismiss,
            });
        }

        if (uiState === VatDeclarationSendUIState.COMPLETED_WITH_ERRORS && !hasDismissedNotification) {
            notifications.push({
                title: (
                    <Trans
                        t={t}
                        i18nKey={`vatDeclaration.notifications.statusData.${uiState}.title`}
                        components={{
                            b: <Typography fontWeight="700" />,
                        }}
                    />
                ),
                cta: {
                    label: t(`vatDeclaration.notifications.statusData.${uiState}.cta`),
                    variant: 'solid',
                    color: 'danger',
                },
                startDecorator: <FontAwesomeIcon icon={faExclamationCircle} />,
                variant: 'soft',
                color: 'danger',
                action: handleAction,
                showDismissCTA: true,
                onDismiss: handleDismiss,
            });
        }

        return notifications;
    }, [dispatch, t, batchProgress, uiState, processedDeclarations, storageData]);

    /* Initialise local storage data & sync when progress has changed */
    useEffect(() => {
        setStorageData(hiwayLocalStorage.get(VAT_LOCAL_STORAGE_KEY) || {});
    }, [batchProgress]);

    return {
        uiState,
        batchProgress,
        processedDeclarations,
        notifications,
    };
};

export default useVATDeclarationSendProgress;
