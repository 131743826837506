import {LoggedInUserActionTypes} from './logged-in-user.action-type';

// TODO This kills routes sagas and loads new in routerFn
const loadUser = () => ({
    type: LoggedInUserActionTypes.LOAD_USER,
    payload: '',
});

const updateUser = (user, propagateAddressChange = false) => ({
    type: LoggedInUserActionTypes.UPDATE_USER,
    payload: {user, propagateAddressChange},
});

const setIsUpdatingUser = isLoading => ({
    type: LoggedInUserActionTypes.SET_IS_UPDATING_USER,
    payload: isLoading,
});

// TODO Refactor - this is fast way to eliminate extra call for dashboard
//  and eliminate potential mess created by updating logged in user object
const storeLoggedInUserAccountForDashboard = myAccount => ({
    type: LoggedInUserActionTypes.STORE_ACCOUNT_FOR_DASHBOARD,
    payload: myAccount,
});

const storeLoggedInUserAccount = myAccount => ({
    type: LoggedInUserActionTypes.STORE_ACCOUNT,
    payload: myAccount,
});

export const LoggedInUserActions = {
    loadUser,
    storeLoggedInUserAccount,
    storeLoggedInUserAccountForDashboard,
    updateUser,
    setIsUpdatingUser,
};
