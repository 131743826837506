import {combineReducers} from 'redux';
import {SignatureActionTypes} from './signature.action-type';
import {createReducer} from '../../../utils/create-reducer';

export const signatureReducer = combineReducers({
    url: createReducer(null, SignatureActionTypes.STORE_SIGNATURE_URL),
    info: createReducer(null, SignatureActionTypes.STORE_SIGNATURE_INFO),
    eventId: createReducer(null, SignatureActionTypes.PUT_EVENT_ID),
    isUploaded: createReducer(null, SignatureActionTypes.IS_UPLOADED),
});
