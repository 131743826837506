import {
    Box,
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemButton,
    ListItemDecorator,
    Switch,
    Typography,
} from '@mui/joy';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Popover from '../../../../../../components/joy-ui/mui-legacy/Popover';
import {DateRangeWithPredefinedOptions} from '../../../../../backoffice/components/DateRange/DateRangeWithPredefinedOptions';
import {FILTER_LIST_MAP} from '../../util/constants';

export const AccountBalancePopovers = ({
    isOpenDrPicker,
    isOpenNonCategorized,
    isOpenType,
    anchorEl,
    handleClose,
    filter,
    setFilter,
}) => {
    const {t} = useTranslation('bank');

    const containerRef = useRef();
    const popoverDatepicker = useRef();
    const popoverType = useRef();
    const popoverDocuments = useRef();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const scrollFunction = useCallback(debounce(() => {
        if (popoverDatepicker?.current) {
            popoverDatepicker.current.updatePosition();
        }
        if (popoverType?.current) {
            popoverType.current.updatePosition();
        }
        if (popoverDocuments?.current) {
            popoverDocuments.current.updatePosition();
        }
        // has to be >300 in header (sticky header transition duration)
    }, 400), [popoverDatepicker, popoverType, popoverDocuments]);

    useEffect(() => {
        window.addEventListener('scroll', scrollFunction);
        return () => {
            window.removeEventListener('scroll', scrollFunction);
        };
        // eslint-disable-next-line
    }, []);

    const onStartDateChange = date => {
        setFilter(filter => {
            return {
                ...filter,
                startDate: date,
            };
        });
    };

    const onEndDateChange = date => {
        setFilter(filter => {
            return {
                ...filter,
                endDate: date,
            };
        });
    };

    const onSwitchChange = value => {
        setFilter(filter => {
            return {
                ...filter,
                isNotCategorized: value,
            };
        });
    };

    const onAddPaymentType = value => {
        const transactionTypes = [...filter.transactionTypes];
        transactionTypes.push(value);
        setFilter(filter => {
            return {
                ...filter,
                transactionTypes: transactionTypes,
            };
        });
    };

    const onRemovePaymentType = value => {
        const transactionTypes = [...filter.transactionTypes];
        const index = transactionTypes.indexOf(value);
        transactionTypes.splice(index, 1);
        setFilter(filter => {
            return {
                ...filter,
                transactionTypes: transactionTypes,
            };
        });
    };

    const onClearAllPaymentType = () => {
        setFilter(filter => {
            return {
                ...filter,
                transactionTypes: [],
            };
        });
    };

    return (
        <Box ref={containerRef}>
            <Popover
                action={popoverDatepicker}
                sx={{
                    '.MuiPopover-paper': {
                        borderRadius: '16px',
                    },
                }}
                id="dr-picker"
                open={isOpenDrPicker}
                anchorEl={anchorEl}
                container={containerRef.current}
                onClose={handleClose}
                disableScrollLock={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
            >
                <DateRangeWithPredefinedOptions
                    startDate={filter.startDate}
                    endDate={filter.endDate}
                    onStartDateChange={onStartDateChange}
                    onEndDateChange={onEndDateChange}
                />
            </Popover>

            <Popover
                action={popoverType}
                sx={{
                    '.MuiPopover-paper': {
                        borderRadius: '16px',
                    },
                }}
                id="type-picker"
                open={isOpenType}
                anchorEl={anchorEl}
                container={containerRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
            >
                <Box sx={{
                    width: '200px',
                    backgroundColor: 'background.surface',
                    py: 2,
                }}
                >
                    <List sx={{p: 0, maxHeight: '60vh', overflow: 'auto'}}>
                        {FILTER_LIST_MAP.map(status => (
                            <ListItem key={status} disablePadding>
                                <ListItemButton
                                    dense
                                    onClick={() => {
                                        if (filter.transactionTypes.indexOf(status) !== -1) {
                                            onRemovePaymentType(status);
                                        } else {
                                            onAddPaymentType(status);
                                        }
                                    }}
                                >
                                    <ListItemDecorator>
                                        <Checkbox
                                            color={filter.transactionTypes.indexOf(status) !== -1
                                                ? 'primary'
                                                : 'neutral'
                                            }
                                            variant={filter.transactionTypes.indexOf(status) !== -1
                                                ? 'solid'
                                                : 'outlined'
                                            }
                                            edge="start"
                                            tabIndex={-1}
                                            disableRipple
                                            checked={filter.transactionTypes.indexOf(status) !== -1}
                                        />
                                    </ListItemDecorator>
                                    {t(`paymentType.${status}`)}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Box sx={{px: 2, mt: 2}}>
                        <Button
                            color="neutral"
                            variant="outlined"
                            fullWidth
                            size="sm"
                            onClick={onClearAllPaymentType}
                        >
                            {t('accountBalancePopover.reinitialize')}
                        </Button>
                    </Box>
                </Box>
            </Popover>

            <Popover
                action={popoverDocuments}
                sx={{
                    '.MuiPopover-paper': {
                        borderRadius: '16px',
                    },
                }}
                id="nonCategorized-picker"
                open={isOpenNonCategorized}
                anchorEl={anchorEl}
                container={containerRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
            >
                <Box sx={{
                    width: '300px',
                    borderRadius: 'xl',
                    p: 2,
                    backgroundColor: 'background.surface',
                }}
                >
                    <Typography
                        level="body-md"
                        component="label"
                        sx={{color: 'text.secondary'}}
                        startDecorator={(
                            <Switch
                                color={filter.isNotCategorized ? 'primary' : 'neutral'}
                                variant={filter.isNotCategorized ? 'solid' : 'outlined'}
                                checked={filter.isNotCategorized}
                                onChange={e => {
                                    onSwitchChange(e.target.checked);
                                }}
                                sx={{mr: 1}}
                            />
                        )}
                    >
                        {t('accountBalanceFilter.supportingDocumentsNotUploaded')}
                    </Typography>

                    <Typography
                        level="body-xs"
                        sx={{
                            ml: 6,
                            mt: 1,
                            color: 'text.tertiary',
                        }}
                    >
                        {t('accountBalancePopover.showOnlySupportingDocuments')}
                    </Typography>
                </Box>
            </Popover>
        </Box>
    );
};

AccountBalancePopovers.propTypes = {
    isOpenDrPicker: PropTypes.bool.isRequired,
    isOpenNonCategorized: PropTypes.bool.isRequired,
    isOpenType: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};

AccountBalancePopovers.defaultProps = {
    anchorEl: {},
};
