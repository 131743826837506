import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import {LoadingButton} from '@mui/lab';
import {Box, Divider, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import HiwayExperince from '../../../../assets/png/bank/hiway-experience.png';
import IncludedInHiwayPackage from '../../../../assets/svg/bank/included-in-hiway-package.svg';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {LANDING_PAGE_BORDER_RADIUS} from '../../utils/constants';

const HiwayExperinceSection = ({onCreationStartClick, creationButtonTranslation}) => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.BANK_CREATION),
    );

    return (
        <>
            <Box sx={{
                mt: 12,
                mb: 5,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}
            >
                <Typography variant="h3" sx={{maxWidth: '820px'}}>
                    <Trans t={t} i18nKey="landing.ultimateHiwayExperience" components={{bold: <Box component="span" sx={{color: '#E84C4C'}} />}} />
                </Typography>
            </Box>

            <Box sx={{display: 'flex', flexDirection: isMobileSize ? 'column' : 'row'}}>
                <Box sx={{
                    borderRadius: LANDING_PAGE_BORDER_RADIUS,
                    width: isMobileSize ? '100%' : '50%',
                    mr: isMobileSize ? 0 : 1.5,
                }}
                >
                    <img style={{borderRadius: '16px', width: '100%', height: '100%'}} src={HiwayExperince} alt="Hiway experince" />
                </Box>
                <Box
                    sx={{
                        borderRadius: LANDING_PAGE_BORDER_RADIUS,
                        background: '#fff',
                        width: isMobileSize ? '100%' : '50%',
                        ml: isMobileSize ? 0 : 1.5,
                        p: 3,
                        mt: isMobileSize ? 2 : 0,
                    }}
                >
                    <Box sx={{mb: 2}}>
                        <Typography sx={{
                            fontWeight: '700',
                            fontSize: theme => theme.typography.pxToRem(isMobileSize ? 18 : 21),
                            lineHeight: '123.5%',
                            textAlign: 'center',
                            letterSpacing: '0.25px',
                            textTransform: 'uppercase',
                            color: 'grey.light',
                        }}
                        >
                            {t('landing.activeInOneClick')}
                        </Typography>
                    </Box>

                    <Box sx={{mb: 3}}><Divider /></Box>

                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        <Box>

                            <HiwayAdvantagePoints
                                text={<Trans t={t} i18nKey="landing.cashManagement" components={{bold: <Box component="span" sx={{fontWeight: 500, mr: 0.5}} />}} />}
                            />
                            <HiwayAdvantagePoints
                                text={<Trans t={t} i18nKey="landing.detailedDashboard" components={{bold: <Box component="span" sx={{fontWeight: 500, mr: 0.5}} />}} />}
                            />
                            <HiwayAdvantagePoints
                                text={<Trans t={t} i18nKey="landing.payrollManagement" components={{bold: <Box component="span" sx={{fontWeight: 500, mr: 0.5}} />}} />}
                            />
                            <HiwayAdvantagePoints
                                text={<Trans t={t} i18nKey="landing.supportingDocuments" components={{bold: <Box component="span" sx={{fontWeight: 500, mr: 0.5}} />}} />}
                            />
                            <HiwayAdvantagePoints
                                text={<Trans t={t} i18nKey="landing.centralisedDocuments" components={{bold: <Box component="span" sx={{fontWeight: 500, mr: 0.5}} />}} />}
                            />
                        </Box>

                        <Box>
                            <Box sx={{
                                mt: 3,
                                mb: 3,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(2, 136, 209, 0.04)',
                                height: '40px',
                                borderRadius: '40px',
                            }}
                            >
                                <img src={IncludedInHiwayPackage} alt="included in hiway package" />
                                <Typography sx={{
                                    fontWeight: 600,
                                    color: 'black',
                                    ml: 1,
                                }}
                                >
                                    {t('landing.includedInHiwayPackage')}
                                </Typography>
                            </Box>
                            <Box>
                                <LoadingButton
                                    loading={isLoading}
                                    fullWidth
                                    sx={{color: 'white'}}
                                    color="success"
                                    variant="contained"
                                    onClick={onCreationStartClick}
                                >
                                    {t(`landing.${creationButtonTranslation}`)}
                                    <ArrowForwardIcon sx={{fontSize: '20px', ml: 1}} />
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

HiwayExperinceSection.propTypes = {
    onCreationStartClick: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    creationButtonTranslation: PropTypes.string.isRequired,
};

const HiwayAdvantagePoints = ({text}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{display: 'flex',
            alignItems: 'center',
            mt: 1.2,
            fontSize: theme => theme.typography.pxToRem(isMobileSize ? 14 : 24)}}
        >
            <CheckIcon sx={{color: 'v2.blueGray.200', mr: 1}} />
            {text}
        </Box>
    );
};

HiwayAdvantagePoints.propTypes = {
    text: PropTypes.object.isRequired,
};

export default HiwayExperinceSection;
