import {TabPanel} from '@mui/lab';
import {ThemeProvider} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {replace, selectRouterLocation} from '../../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../../lib/router/route-paths';
import {theme} from '../../../../../../lib/theme';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import BankKyc from '../../../../components/helper-pages/BankKYC';
import BankMissingInformation from '../../../../components/helper-pages/BankMissingInformation';
import {BankActions} from '../../../../store/bank.action';
import {BANK_ONBOARDING_STATUS, VerificationStatus} from '../../../../utils/constants';
import {getIntegrationInfo} from '../../../bridge-api/utils/bridge-util';
import {BANK_TYPES, BankAccountStatus} from '../../../bridge-api/utils/constants';
import {TransactionsSelector} from '../../store/transactions.selector';
import {emptyFunction} from '../../util/constants';
import TransactionList from '../TransactionList';

const HiwayTransactionList = ({
    integrations,
    topOffset,
    isAdminInterface,
    bank,
    isTransactionModalHidden,
    isOpened,
    hasBankSidebarOpen,
    onOpenTransactionCb,
    setTabValue,
    isTabValueAuto,
    filter,
    setFilter,
}) => {
    const dispatch = useDispatch();

    const hiwayIntegration = integrations.find(integration => integration.type === BANK_TYPES.hiway);
    const location = useSelector(selectRouterLocation);

    const transactionIds = useSelector(TransactionsSelector.selectTransactionIds);
    const isLoadingTransactions = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_GET_INTEGRATIONS,
    ));

    const [isLoadingStarted, setIsLoadingStarted] = useState(false);
    const [isLoadingFinished, setIsLoadingFinished] = useState(false);

    const bankAccountHolders = hiwayIntegration.bankAccountHolders.length === 1
        ? hiwayIntegration.bankAccountHolders[0]
        : hiwayIntegration.bankAccountHolders.find(integration => integration?.type === 'COMPANY');

    const kycNotCompleted = bankAccountHolders
        && bankAccountHolders.onboardingStatus === BANK_ONBOARDING_STATUS.FINALIZED
        && (
            bankAccountHolders.verificationStatus === VerificationStatus.PENDING
            || bankAccountHolders.verificationStatus === VerificationStatus.WAITING_FOR_VERIFICATION
            || bankAccountHolders.verificationStatus === VerificationStatus.NOT_STARTED
        );

    const hasMissingInformation = bankAccountHolders
        && bankAccountHolders.onboardingStatus === BANK_ONBOARDING_STATUS.INVALID;

    // TODO This logic might belong more to sagas
    // Logic regarding closing/archiving Hiway bank
    useEffect(() => {
        // Continue if there is specific query param, has hiway integration,
        // it stopped loading transactions or it has kyc missing
        if (
            location?.query?.closeAccountId
            && hiwayIntegration
            && (
                isLoadingFinished
                || kycNotCompleted
                || hasMissingInformation
            )
        ) {
            const {account} = getIntegrationInfo(hiwayIntegration);

            // If account status is not closing/closed do nothing
            // TODO We should probably display some decent message for user for this case
            if (account?.status !== BankAccountStatus.CLOSED && account?.status !== BankAccountStatus.CLOSING) {
                return;
            }

            // If there are transactions call for archiving, otherwise delete
            const shouldArchive = transactionIds?.length > 0 && parseFloat(account?.availableBalance) === 0;
            if (shouldArchive) {
                dispatch(BankActions.archiveIntegrationAccount({integration: hiwayIntegration}));
            } else {
                dispatch(BankActions.deleteIntegrationAccount({id: hiwayIntegration?.id}));
            }

            // Display modal for closing
            // TODO We should handle case when delete fails somewhere, ask Evgenia how to adapt the flow
            dispatch(UiActions.setModalData(ModalsKeys.CONFIRM_HIWAY_DELETE_MODAL,
                {
                    setArchiveActive: () => setTabValue(BANK_TYPES.archived),
                    setTabValue: setTabValue,
                    isArchive: shouldArchive,
                }));
            dispatch(UiActions.setActiveModal(ModalsKeys.CONFIRM_HIWAY_DELETE_MODAL, true));

            // Clean URL
            dispatch(replace(RoutePaths.BANK_TRANSACTION_LIST));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingFinished]);

    // TODO Create better way than helper loader for tracking if loading transactions if finished
    useEffect(() => {
        if (location?.query?.closeAccountId) {
            if (isLoadingTransactions) {
                setIsLoadingStarted(true);
            }

            if (isLoadingTransactions === false && isLoadingStarted) {
                setIsLoadingFinished(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingTransactions]);

    if (kycNotCompleted && isOpened) {
        return (
            <ThemeProvider theme={theme}>
                <BankKyc />
            </ThemeProvider>
        );
    }

    if (hasMissingInformation && isOpened) {
        return (
            <ThemeProvider theme={theme}>
                <BankMissingInformation />
            </ThemeProvider>
        );
    }

    return (
        <TabPanel
            key={bank.id}
            value={bank.id}
            sx={{
                p: 0,
            }}
        >
            <TransactionList
                topOffset={topOffset}
                isAdminInterface={isAdminInterface}
                bank={bank}
                isTransactionModalHidden={isTransactionModalHidden}
                isOpened={isOpened}
                onOpenTransactionCb={onOpenTransactionCb}
                hasBankSidebarOpen={hasBankSidebarOpen}
                isTabValueAuto={isTabValueAuto}
                filter={filter}
                setFilter={setFilter}
            />
        </TabPanel>
    );
};

HiwayTransactionList.propTypes = {
    topOffset: PropTypes.number.isRequired,
    isAdminInterface: PropTypes.bool.isRequired,
    bank: PropTypes.object.isRequired,
    isTransactionModalHidden: PropTypes.bool,
    integrations: PropTypes.array.isRequired,
    isOpened: PropTypes.bool.isRequired,
    onOpenTransactionCb: PropTypes.func,
    hasBankSidebarOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
    setTabValue: PropTypes.func.isRequired,
    isTabValueAuto: PropTypes.bool.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};

HiwayTransactionList.defaultProps = {
    isTransactionModalHidden: false,
    onOpenTransactionCb: emptyFunction,
};

export default HiwayTransactionList;
