import {BridgeActionTypes} from './brdige-api.action-types';


export const BridgeActions = {
    connectToBridge: () => ({
        type: BridgeActionTypes.CONNECT_TO_BRIDGE,
    }),
    checkBridgeOnboarding: () => ({
        type: BridgeActionTypes.CHECK_BRIDGE_ONBOARDING,
    }),
    onboardAccount: payload => ({
        type: BridgeActionTypes.ONBOARD_CHOSEN_ACCOUNT,
        payload,
    }),
    fixBridgeAccount: payload => ({
        type: BridgeActionTypes.FIX_BRIDGE_ACCOUNT,
        payload,
    }),
    setChosenBankConnectionFlag: () => ({
        type: BridgeActionTypes.SET_CHOSEN_BANK_CONNECTION_FLAG,
    }),
    setDefaultBank: payload => ({
        type: BridgeActionTypes.SET_DEFAULT_BANK,
        payload,
    }),
};
