import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {UNCATEGORIZED_FILTER_PARAM_NAME} from '../../util/constants';

/*
 * Reads `uncategorized` query param only once on page load and then removes it
 */
export const useUncategorizedFilterFromURL = setFilter => {
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (searchParams.has(UNCATEGORIZED_FILTER_PARAM_NAME)) {
            setFilter(prevValue => ({...prevValue, isNotCategorized: true}));

            const newParams = new URLSearchParams(searchParams);
            newParams.delete(UNCATEGORIZED_FILTER_PARAM_NAME);
            setSearchParams(newParams, {replace: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
