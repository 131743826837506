import {call, put, select} from 'redux-saga/effects';

import {storeCurrentCognitoUser} from './auth.actions';
import {selectCurrentCognitoUser} from './auth.selectors';
import {getCurrentUserInfo} from './getCurrentUserInfo.saga';
import {COOKIE_NAMES, getCookie} from '../../../utils/cookie';

/**
 * Gets and stores info about currently logged in user
 * @param {Object} options Options object
 * @property {Boolean} options.shouldForce Should use cached version of Cognito user info
 * @returns {void}
 */
export const getAndStoreCurrentUserInfo = function* (options = {}) {
    const {shouldForce} = options;

    const currentCognitoUser = yield select(selectCurrentCognitoUser);

    const cookieToken = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);

    if (!cookieToken) {
        if (currentCognitoUser && !shouldForce) {
            return;
        }
    }

    /**
     * @type {object} cognitoUser
     * @property {string} cognitoUser.username
     * @property {string} cognitoUser.custom:roles
     * @property {string} cognitoUser.email
     * @property {boolean} cognitoUser.email_verified
     */
    let cognitoUser = yield call(getCurrentUserInfo);

    if (!cognitoUser) {
        return;
    }

    cognitoUser = {
        id: cognitoUser.username,
        role: cognitoUser.attributes['custom:roles'],
        email: cognitoUser.attributes.email,
        isEmailVerified: cognitoUser.attributes.email_verified,
    };

    yield put(storeCurrentCognitoUser(cognitoUser));
};
