import {accountingApiInstance} from '../../../lib/api-instances/accounting-api-instance';

const getDeclarations = params => {
    return accountingApiInstance({
        method: 'GET',
        url: 'v1/formalities/vat-declarations',
        params,
    }).then(response => response.data);
};

const getBatchProgress = () => {
    return accountingApiInstance({
        method: 'GET',
        url: 'v1/formalities/vat-declarations/batch-processing-stats',
    }).then(result => result?.data);
};

const sendAllDeclarations = ({month, year}) => {
    return accountingApiInstance({
        method: 'POST',
        url: 'v1/formalities/vat-declarations/submit-all',
        data: {
            month,
            year,
        },
    });
};

const sendDeclarations = ({declarationIds}) => {
    return accountingApiInstance({
        method: 'POST',
        url: 'v1/formalities/vat-declarations/submit',
        data: {
            declarationIds,
        },
    });
};

const getSupportedMonths = ({companyId}) => {
    return accountingApiInstance({
        method: 'GET',
        url: 'v1/formalities/vat-declarations/supported-months',
        params: {
            companyId,
        },
    }).then(response => response.data);
};

const getVATDeclaration = ({declarationId}) => {
    return accountingApiInstance({
        method: 'GET',
        url: `v1/formalities/vat-declarations/${declarationId}`,
    }).then(response => response.data);
};

const getVATDeclarationDocuments = ({declarationId}) => {
    return accountingApiInstance({
        method: 'GET',
        url: `v1/formalities/vat-declarations/${declarationId}/documents`,
    }).then(response => response.data);
};

const activateManualMode = ({declarationId}) => {
    return accountingApiInstance.post(`v1/formalities/vat-declarations/${declarationId}/activate-manual-mode`).then(response => response.data);
};

const uploadDocuments = ({declarationId, files}) => {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    return accountingApiInstance.post(`v1/formalities/vat-declarations/${declarationId}/documents/upload`, formData).then(response => response.data);
};

const deleteDocument = ({declarationId, documentId}) => {
    return accountingApiInstance.delete(`v1/formalities/vat-declarations/${declarationId}/documents/${documentId}`).then(response => response.data);
};

const validateManualMode = ({declarationId, data}) => {
    return accountingApiInstance.post(`v1/formalities/vat-declarations/${declarationId}/validate-manual-mode`, data).then(response => response.data);
};

const getDocuments = ({declarationId}) => {
    return accountingApiInstance.get(`v1/formalities/vat-declarations/${declarationId}/documents`).then(response => response.data);
};

const resendDeclaration = ({declarationId}) => {
    return accountingApiInstance.post(`v1/formalities/vat-declarations/submit`, {declarationIds: [declarationId]}).then(response => response.data);
};

const getHistoryOverview = ({year, companyId}) => {
    return accountingApiInstance
        .get(`v1/formalities/vat-declarations/history-overview`, {
            params: {
                year,
                companyId,
            },
        })
        .then(response => response.data);
};

export const VATDeclarationApi = {
    getDeclarations,
    activateManualMode,
    uploadDocuments,
    deleteDocument,
    validateManualMode,
    getDocuments,
    getSupportedMonths,
    getVATDeclaration,
    getVATDeclarationDocuments,
    resendDeclaration,
    getBatchProgress,
    sendDeclarations,
    sendAllDeclarations,
    getHistoryOverview,
};

