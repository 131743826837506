import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {roundHalfUpToTwoDecimalPlaces} from '../../../../../../../../utils/number';

const ReimbursementTransactioninfoRow = ({title, Icon, amount, sign, currency, isSelected, iconColor}) => {
    const styles = useMemo(() => {
        const amountStyles = {
            fontWeight: 400,
            fontSize: theme => theme.typography.pxToRem(14),
            lineHeight: theme => theme.typography.pxToRem(16),
            color: isSelected ? '#fff' : 'v2.light.text.secondary',
            letterSpacing: '0.4px',
            whiteSpace: 'nowrap',
        };

        const amountValueStyles = {
            ...amountStyles,
            fontSize: theme => theme.typography.pxToRem(16),
            fontWeight: 500,
            mr: 2,
        };

        const iconStyles = {
            color: iconColor ?? (isSelected ? '#fff' : 'v2.light.action.active'),
            mr: 0.5,
        };

        return {
            amountStyles,
            valueStyles: amountValueStyles,
            iconStyles,
        };
    }, [isSelected, iconColor]);

    return (
        <>
            {!!Icon && (
                <Icon fontSize="20px" sx={styles.iconStyles} />
            )}
            <Typography sx={styles.amountStyles}>
                {title}&nbsp;
            </Typography>
            {!!amount && (
                <Typography sx={styles.valueStyles}>
                    {sign}
                    {roundHalfUpToTwoDecimalPlaces(amount)}
                    {currency}
                </Typography>
            )}
        </>
    );
};

ReimbursementTransactioninfoRow.propTypes = {
    title: PropTypes.string.isRequired,
    Icon: PropTypes.any,
    isSelected: PropTypes.bool,
    amount: PropTypes.string,
    sign: PropTypes.string,
    currency: PropTypes.string,
    iconColor: PropTypes.string,
};

ReimbursementTransactioninfoRow.defaultProps = {
    sign: '',
    Icon: null,
    amount: null,
    currency: '€',
    isSelected: false,
    iconColor: null,
};

export default ReimbursementTransactioninfoRow;
