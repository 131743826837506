import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {DocumentationActionType} from './documentation.action-type';
import {DocumentationActions} from './documentation.actions';
import {Toast} from '../../../lib/toast';
import {dateComparator} from '../../../utils/date';
import {Debug} from '../../../utils/debug';
import {LoadingActions, LoadingTypes} from '../../loading';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {DocumentationApi} from '../api/documentation.api';
import {DOCS_TYPE, MAX_OTHER_AND_POPULAR_ITEMS} from '../util/constants';

const getCategoriesFlow = function* ({categorySlug}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENTATION_LOADING, true));

        const result = yield call(DocumentationApi.getCategories);

        const categories = result.filter(({parent}) => parent === 0);
        // for categories list in the home page
        // or for breadcrumbs in the category page
        yield put(DocumentationActions.storeCategories(categories));

        if (categorySlug) {
            const category = result.filter(({slug}) => slug === categorySlug);
            yield put(DocumentationActions.storeDetails(category)); // for title

            const categoryId = category[0]?.id;
            if (categoryId) {
                const subCategories = result.filter(({parent}) => parent === Number(categoryId));
                yield put(DocumentationActions.storeSubCategories(subCategories));

                const popularArticles = yield call(DocumentationApi.getPopularArticles, {categorySlug});
                const articlesToStore = popularArticles.slice(0, MAX_OTHER_AND_POPULAR_ITEMS);
                yield put(DocumentationActions.storePopularArticles(articlesToStore));
            }
        }
    } catch (e) {
        Debug.error('documentation getCategoriesFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENTATION_LOADING, false));
    }
};

const getListFlow = function* ({categorySlug}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENTATION_LOADING, true));

        const result = yield call(DocumentationApi.getList, {categorySlug});

        const articles = [];
        const videos = [];
        const faqs = [];

        result.forEach(item => {
            if (item.docsType === DOCS_TYPE.ARTICLE) {
                articles.push(item);
            } else if (item.docsType === DOCS_TYPE.VIDEO) {
                videos.push(item);
            } else if (item.docsType === DOCS_TYPE.FAQ) {
                faqs.push(item);
            }
        });

        const allCategories = yield call(DocumentationApi.getCategories);
        const categories = allCategories.filter(({parent}) => parent === 0);
        const categoryInfo = allCategories.filter(({slug}) => slug === categorySlug);
        const subCategories = categoryInfo[0]?.parent
            ? allCategories.filter(({parent}) => parent === Number(categoryInfo[0]?.parent))
            : [];

        yield put(DocumentationActions.storeCategories(categories)); // for breadcrumbs
        yield put(DocumentationActions.storeSubCategories(subCategories)); // for breadcrumbs
        yield put(DocumentationActions.storeDetails(categoryInfo)); // for title
        yield put(DocumentationActions.storeArticles(articles));
        yield put(DocumentationActions.storeVideos(videos));
        yield put(DocumentationActions.storeFaqs(faqs));

        const popularArticles = yield call(DocumentationApi.getPopularArticles, {categorySlug});
        const articlesToStore = popularArticles.slice(0, MAX_OTHER_AND_POPULAR_ITEMS);
        yield put(DocumentationActions.storePopularArticles(articlesToStore));
    } catch (e) {
        Debug.error('documentation getListFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENTATION_LOADING, false));
    }
};

const getDetailsFlow = function* ({pageSlug, userId}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENTATION_LOADING, true));

        const result = yield call(DocumentationApi.getDetails, {pageSlug});

        const allCategories = yield call(DocumentationApi.getCategories);
        const categories = allCategories.filter(({parent}) => parent === 0);
        const subCategories = allCategories.filter(
            ({parent}) => Number(parent) === Number(result[0]?.docsParentCategory),
        );

        const {docsType, docsSubCategorySlug, id} = result[0] ?? {};

        if (docsSubCategorySlug) {
            const list = yield call(DocumentationApi.getList, {
                categorySlug: docsSubCategorySlug,
            });
            const items = list
                .filter(item => item.docsType === docsType && item.id !== id)
                .sort(dateComparator('dateCreated'))
                .slice(0, MAX_OTHER_AND_POPULAR_ITEMS);

            yield put(DocumentationActions.storeOtherItems(items));
        }

        let voteResult = null;

        if (id) {
            voteResult = yield call(DocumentationApi.getVoteResult, {
                pageId: id,
                userId,
            });
        }

        yield put(DocumentationActions.storeCategories(categories)); // for breadcrumbs
        yield put(DocumentationActions.storeSubCategories(subCategories)); // for breadcrumbs
        yield put(DocumentationActions.storeDetails(result));
        yield put(DocumentationActions.storeVoteResult(voteResult));
    } catch (e) {
        Debug.error('documentation getDetailsFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENTATION_LOADING, false));
    }
};

const searchFlow = function* ({searchTerm}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENTATION_SEARCH_LOADING, true));

        const result = yield call(DocumentationApi.search, {searchTerm});

        const articles = [];
        const videos = [];
        const faqs = [];

        result.forEach(item => {
            if (item.docsType === DOCS_TYPE.ARTICLE) {
                articles.push(item);
            } else if (item.docsType === DOCS_TYPE.VIDEO) {
                videos.push(item);
            } else if (item.docsType === DOCS_TYPE.FAQ) {
                faqs.push(item);
            }
        });

        const searchResults = {
            [DOCS_TYPE.ARTICLE]: articles,
            [DOCS_TYPE.VIDEO]: videos,
            [DOCS_TYPE.FAQ]: faqs,
        };

        yield put(DocumentationActions.storeSearchResults(searchResults));
    } catch (e) {
        Debug.error('documentation searchFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENTATION_SEARCH_LOADING, false));
    }
};

const voteFlow = function* ({pageId, value, userId}) {
    try {
        yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENTATION_VOTING, true));

        const voteResult = yield call(DocumentationApi.vote, {pageId, value, userId});

        yield put(DocumentationActions.storeVoteResult(voteResult));
    } catch (e) {
        Debug.error('documentation voteFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENTATION_VOTING, false));
    }
};

export const getGlobalPopularArticlesSaga = function* () {
    try {
        const popularArticles = yield call(DocumentationApi.getGlobalPopularArticles);
        const articlesToStore = popularArticles.slice(0, MAX_OTHER_AND_POPULAR_ITEMS);
        yield put(DocumentationActions.storeGlobalPopularArticles(articlesToStore));
    } catch (e) {
        Debug.error('documentation getGlobalPopularArticlesSaga', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const getCategoriesWorker = function* ({payload}) {
    const params = payload?.params ?? payload;
    const {categorySlug} = params ?? {};
    yield call(getCategoriesFlow, {categorySlug});
};

export const getListWorker = function* ({payload}) {
    const params = payload?.params ?? payload;
    const {categorySlug} = params ?? {};
    yield call(getListFlow, {categorySlug});
};

export const getDetailsWorker = function* ({payload}) {
    const params = payload?.params ?? payload;
    const {pageSlug} = params ?? {};
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    yield call(getDetailsFlow, {pageSlug, userId: loggedInUser.id});
};

export const searchWorker = function* ({payload}) {
    const {searchTerm} = payload ?? {};
    yield call(searchFlow, {searchTerm});
};

export const voteWorker = function* ({payload}) {
    const {pageId, value} = payload ?? {};
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    yield call(voteFlow, {pageId, value, userId: loggedInUser.id});
};

export const watchDocumentationSaga = function* () {
    yield all([
        takeEvery(DocumentationActionType.SEARCH, searchWorker),
        takeEvery(DocumentationActionType.VOTE, voteWorker),
    ]);
};
