import {SvgIcon} from '@mui/material';

export const EuroIcon = props => (
    <SvgIcon viewBox="0 0 48 48" {...props}>
        <path
            d="M30 42C26.1667 42 22.625 40.875 19.375 38.625C16.125 36.375 13.9167 33.3333 12.75 29.5H6V26.5H12.15C12.0167 25.5667 11.9583 24.7333 11.975 24C11.9917 23.2667 12.05 22.4333 12.15 21.5H6V18.5H12.75C13.9167 14.6667 16.125 11.625 19.375 9.375C22.625 7.125 26.1667 6 30 6C32.3 6 34.4583 6.39167 36.475 7.175C38.4917 7.95833 40.3333 9.1 42 10.6L39.85 12.7C38.4833 11.5 36.95 10.5833 35.25 9.95C33.55 9.31667 31.8 9 30 9C26.8667 9 24.0083 9.875 21.425 11.625C18.8417 13.375 17.0167 15.6667 15.95 18.5H30V21.5H15.2C15.0333 22.4 14.95 23.2333 14.95 24C14.95 24.7667 15.0333 25.6 15.2 26.5H30V29.5H15.95C17.0167 32.3333 18.8417 34.625 21.425 36.375C24.0083 38.125 26.8667 39 30 39C31.7667 39 33.5 38.7 35.2 38.1C36.9 37.5 38.4667 36.5667 39.9 35.3L42 37.4C40.5333 38.8 38.75 39.9167 36.65 40.75C34.55 41.5833 32.3333 42 30 42Z"
            fill="currentColor"
        />
    </SvgIcon>
);
