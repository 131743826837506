import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import okEmoji from '../../../assets/png/ok-emoji.png';
import TextEmoji from '../../../components/text-emoji/TextEmoji';

export const ContainerHeaderStep2 = ({mainText, colorText, color}) => {
    return (
        <>
            <Box sx={{color}}>{colorText}</Box>
            <Box>{mainText} <TextEmoji src={okEmoji} alt="ok-emoji" /></Box>
        </>
    );
};

ContainerHeaderStep2.propTypes = {
    mainText: PropTypes.string.isRequired,
    colorText: PropTypes.string.isRequired,
    color: PropTypes.string,
};

ContainerHeaderStep2.defaultProps = {
    color: '#E84C4C',
};
