import {combineReducers} from 'redux';
import {SET_IS_LOADING_AVAILABLE_FREELANCERS, STORE_AVAILABLE_FREELANCERS} from './availableFreelancers.actions';
import {LOCATION_CHANGED} from '../../../../lib/router/connected-router-saga';

const isLoadingReducer = (state = true, action) => {
    switch (action.type) {
        case SET_IS_LOADING_AVAILABLE_FREELANCERS:
            return action.payload;

        case LOCATION_CHANGED:
            return true;

        default:
            return state;
    }
};

const freelancersReducer = (state = {}, action) => {
    switch (action.type) {
        case STORE_AVAILABLE_FREELANCERS:
            return action.payload;

        case LOCATION_CHANGED:
            return {};

        default:
            return state;
    }
};

export const availableFreelancersReducer = combineReducers({
    isLoading: isLoadingReducer,
    freelancers: freelancersReducer,
});
