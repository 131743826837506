import {combineReducers} from 'redux';
import {TrainingActionTypes} from './training.action-type';
import {createReducer} from '../../../v1/app/createReducer';
import {TRAINING_CONSTANTS} from '../utils/constants';

export const trainingReducer = combineReducers({
    trainingInfo: createReducer(
        null,
        TrainingActionTypes.STORE_TRAINING_INFO,
        true,
    ),
    progress: createReducer(
        TRAINING_CONSTANTS.DEFAULT_TRAINING_PROGRESS,
        TrainingActionTypes.SET_TRAINING_PROGRESS,
        true,
    ),
    step: createReducer(
        null,
        TrainingActionTypes.SET_TRAINING_STEP,
        true,
    ),
    subStep: createReducer(
        TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_GENERATION,
        TrainingActionTypes.SET_SUBSTEP,
        true,
    ),
});
