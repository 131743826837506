import {Box, Container} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageHeader} from '../../components/page-header/PageHeader';
import BankTransfer from '../../features/bank/modules/transfers/components/BankTransfer';
import {importLocaleBundle} from '../../lib/i18next';

importLocaleBundle('bankTransfer');

const BankTransferScreen = () => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('bankTransfer');

    return (
        <Container maxWidth="xl" disableGutters>
            {!isMobileSize && (
                <PageHeader>
                    {t('pageTitle')}
                </PageHeader>
            )}

            <Box sx={{mt: isMobileSize ? 2 : 3}}>
                <BankTransfer />
            </Box>
        </Container>
    );
};

export default BankTransferScreen;
