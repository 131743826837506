import {DatabaseActionTypes} from './database.action-type';

export const DatabaseActions = {
    storeDocuments: documents => ({
        type: DatabaseActionTypes.STORE_DOCUMENTS,
        payload: documents,
    }),
    clearDocuments: () => ({
        type: DatabaseActionTypes.CLEAR_DOCUMENTS,
    }),
};
