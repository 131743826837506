import {SvgIcon} from '@mui/material';
import PropTypes from 'prop-types';

export const WeeklyIcon = props => {
    return (
        <SvgIcon width="24" height="28" viewBox="0 0 25 28" {...props}>
            <path d="M6.2 16.8667C5.82222 16.8667 5.50556 16.739 5.25 16.4834C4.99444 16.2279 4.86667 15.9112 4.86667 15.5334C4.86667 15.1556 4.99444 14.839 5.25 14.5834C5.50556 14.3279 5.82222 14.2001 6.2 14.2001C6.57778 14.2001 6.89444 14.3279 7.15 14.5834C7.40556 14.839 7.53333 15.1556 7.53333 15.5334C7.53333 15.9112 7.40556 16.2279 7.15 16.4834C6.89444 16.739 6.57778 16.8667 6.2 16.8667ZM12.1 16.8667C11.7222 16.8667 11.4056 16.739 11.15 16.4834C10.8944 16.2279 10.7667 15.9112 10.7667 15.5334C10.7667 15.1556 10.8944 14.839 11.15 14.5834C11.4056 14.3279 11.7222 14.2001 12.1 14.2001C12.4778 14.2001 12.7944 14.3279 13.05 14.5834C13.3056 14.839 13.4333 15.1556 13.4333 15.5334C13.4333 15.9112 13.3056 16.2279 13.05 16.4834C12.7944 16.739 12.4778 16.8667 12.1 16.8667ZM17.7667 16.8667C17.3889 16.8667 17.0722 16.739 16.8167 16.4834C16.5611 16.2279 16.4333 15.9112 16.4333 15.5334C16.4333 15.1556 16.5611 14.839 16.8167 14.5834C17.0722 14.3279 17.3889 14.2001 17.7667 14.2001C18.1444 14.2001 18.4611 14.3279 18.7167 14.5834C18.9722 14.839 19.1 15.1556 19.1 15.5334C19.1 15.9112 18.9722 16.2279 18.7167 16.4834C18.4611 16.739 18.1444 16.8667 17.7667 16.8667ZM2 27.3334C1.46667 27.3334 1 27.1334 0.6 26.7334C0.2 26.3334 0 25.8667 0 25.3334V4.66675C0 4.13341 0.2 3.66675 0.6 3.26675C1 2.86675 1.46667 2.66675 2 2.66675H4.16667V0.666748H6.33333V2.66675H17.6667V0.666748H19.8333V2.66675H22C22.5333 2.66675 23 2.86675 23.4 3.26675C23.8 3.66675 24 4.13341 24 4.66675V25.3334C24 25.8667 23.8 26.3334 23.4 26.7334C23 27.1334 22.5333 27.3334 22 27.3334H2ZM2 25.3334H22V11.0001H2V25.3334ZM2 9.00008H22V4.66675H2V9.00008ZM2 9.00008V4.66675V9.00008Z" fill={props?.sx?.fill ?? '#607D8B'} />
        </SvgIcon>
    );
};

WeeklyIcon.propTypes = {
    sx: PropTypes.object,
};

WeeklyIcon.defaultProps = {
    sx: {},
};
