export const getPickersLocalization = pickersTranslations => {
    return {
        components: {
            MuiLocalizationProvider: {
                defaultProps: {
                    localeText: {...pickersTranslations},
                },
            },
        },
    };
};
