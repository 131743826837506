import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import PersonPinTwoToneIcon from '@mui/icons-material/PersonPinTwoTone';
import {Box, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import UserSvg from '../../../../../../assets/svg/bank/user.svg';
import {BeneficiariesSelector} from '../../store/beneficiaries.selector';

const BeneficiariesList = ({sidebarOpenInformation, setSidebarOpenInformation, type}) => {
    const {t} = useTranslation('bankTransfer');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const [list, setList] = useState([]);

    const beneficiariesList = useSelector(BeneficiariesSelector.selectList);

    useEffect(() => {
        if (list.length + 1 === beneficiariesList.length) {
            if (beneficiariesList[beneficiariesList.length - 1].created) {
                const item = beneficiariesList[beneficiariesList.length - 1];

                setSidebarOpenInformation({
                    type: type,
                    id: item.id,
                    label: item.label,
                    iban: item.iban,
                    isPersonal: item.isPersonal,
                });
            }
        }

        setList(beneficiariesList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiariesList]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        color: 'text_v2.secondary',
                    }}
                >
                    {t('beneficiaries.home.title')}
                </Typography>
                {list.length > 0 && !isMobileSize && (
                    <Button
                        sx={{
                            py: 0.4,
                            borderRadius: '6px',
                        }}
                        onClick={() => setSidebarOpenInformation({
                            type: type,
                        })}
                        variant="contained"
                        color="primary"
                        startIcon={(
                            <AddIcon />
                        )}
                        size="small"
                    >
                        {t('beneficiaries.home.addNew')}
                    </Button>
                )}

            </Box>
            {list.map(beneficiary => {
                const isActive = sidebarOpenInformation?.id === beneficiary.id;
                return (
                    <Box
                        key={beneficiary.id}
                        id={`beneficiary-item-${beneficiary.id}`}
                        sx={{
                            borderRadius: '16px',
                            border: isActive ? '1px solid #0288D1' : '1px solid rgba(0, 0, 0, 0.12)',
                            p: 2,
                            width: '100%',
                            my: isMobileSize ? 1.5 : 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                        }}
                        onClick={() => {
                            setSidebarOpenInformation({
                                type: type,
                                id: beneficiary.id,
                                label: beneficiary.label,
                                iban: beneficiary.iban,
                                isPersonal: beneficiary.isPersonal,
                            });
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            height: '38px',
                        }}
                        >
                            {beneficiary.isPersonal
                                ? (
                                    <PersonPinTwoToneIcon
                                        sx={{
                                            color: '#E84C4C',
                                        }}
                                    />
                                )
                                : (
                                    <PersonOutlineTwoToneIcon sx={{
                                        color: '#90A4AE',
                                    }
                                    }
                                    />
                                )
                            }
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            >
                                <Box sx={{
                                    color: 'v2.blueGray.600',
                                    fontSize: theme => theme.typography.pxToRem(13),
                                    fontWeight: 500,
                                }}
                                >
                                    {beneficiary.label}
                                </Box>
                                {beneficiary.isPersonal && (
                                    <Box sx={{
                                        color: 'secondary.main',
                                        textTransform: 'none',
                                        fontSize: theme => theme.typography.pxToRem(12),
                                    }}
                                    >
                                        {t('beneficiaries.home.personal')}
                                    </Box>
                                )}
                            </Box>
                        </Box>

                        <Box sx={{
                            color: 'v2.light.text.disabled',
                            fontSize: '12px !important',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: 1,
                        }}
                        >
                            {!isMobileSize && (
                                <Typography sx={{
                                    color: 'v2.light.text.disabled',
                                    fontSize: theme => theme.typography.pxToRem(12),
                                }}
                                >
                                    {beneficiary.iban}
                                </Typography>
                            )}
                            <ChevronRightIcon sx={{
                                color: 'v2.light.action.active',
                            }}
                            />
                        </Box>
                    </Box>
                );
            })}

            {list.length > 0 && isMobileSize && (
                <Button
                    sx={{
                        py: 0.4,
                        borderRadius: '6px',
                    }}
                    onClick={() => setSidebarOpenInformation({
                        type: type,
                    })}
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={(
                        <AddIcon />
                    )}
                    size="small"
                >
                    {t('beneficiaries.home.addNew')}
                </Button>
            )}

            {list.length === 0 && (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                    <Box sx={{
                        mt: 2,
                    }}
                    >
                        <img src={UserSvg} alt="user" />
                    </Box>
                    <Box sx={{
                        color: 'v2.light.text.disabled',
                        mb: 2,
                        mt: 1,
                    }}
                    >
                        {t('beneficiaries.home.newText')}
                    </Box>
                    <Button
                        sx={{
                            py: 0.4,
                            borderRadius: '6px',
                            mb: 2,
                        }}
                        onClick={() => setSidebarOpenInformation({
                            type: type,
                        })}
                        variant="contained"
                        color="primary"
                        startIcon={(
                            <AddIcon />
                        )}
                        size="small"
                    >
                        {t('beneficiaries.home.addNew')}
                    </Button>
                </Box>
            )}
        </>
    );
};

BeneficiariesList.propTypes = {
    sidebarOpenInformation: PropTypes.object,
    setSidebarOpenInformation: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

BeneficiariesList.defaultProps = {
    sidebarOpenInformation: null,
};

export default BeneficiariesList;
