import {TaxIncomeFrom, TaxIncomeTo} from './dashboard.dto';
import {hiwayApiInstance} from '../../../lib/hiway-api-instance';
import {invoiceApiInstance} from '../../../lib/invoice-api-instance';

const getTaxIncome = ({freelancerId, companyId, year}) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}/companies/${companyId}/tax-configuration?year=${year}`)
        .then(result => new TaxIncomeFrom(result.data));
};

const requestPayCalculation = ({companyId, calculationType}) => {
    return invoiceApiInstance
        .put(
            `/v1/companies/${companyId}/request-pay-calculation`,
            {calculationType},
        )
        .then(result => result.data);
};

const saveTaxIncome = ({freelancerId, companyId, data}) => {
    return hiwayApiInstance
        .put(
            `/v1/freelancers/${freelancerId}/companies/${companyId}/tax-configuration`,
            new TaxIncomeTo(data),
        )
        .then(result => result.data);
};

export const DashboardApi = {
    getTaxIncome,
    saveTaxIncome,
    requestPayCalculation,
};
