import makeStyles from '@mui/styles/makeStyles';

export const useDataTableFooterStyles = makeStyles(theme => ({
    root: {
        'padding': theme.spacing(1, 3, 3),
    },
    pagination: {
        borderBottom: 'none',
    },
}));
