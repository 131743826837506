import React from 'react';
import Step4 from '../../assets/etape4-facturation-v2.pdf';
import {TrainingViewPdf} from '../general/TrainingViewPdf';

export const FundingApprovalPdf = () => {
    return (
        <TrainingViewPdf
            file={Step4}
        />
    );
};
