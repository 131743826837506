import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingButton} from '@mui/lab';
import {Box, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import TopCardImage from '../../../../assets/png/bank/landing-top-card.png';
import TopCardsImageMobile from '../../../../assets/png/bank/landing-top-cards-mobile.svg';
import TopCardsImage from '../../../../assets/png/bank/landing-top-cards.svg';
import TopMobileSmallImage from '../../../../assets/png/bank/landing-top-mobile-small.png';
import TopMobileImage from '../../../../assets/png/bank/landing-top-mobile.png';
import TopTransfersImageMobile from '../../../../assets/png/bank/landing-top-transfers-mobile.svg';
import TopTransfersImage from '../../../../assets/png/bank/landing-top-transfers.svg';
import SvanImage from '../../../../assets/svg/svan.svg';
import SvanWhiteImage from '../../../../assets/svg/svan_white.svg';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {LANDING_PAGE_BORDER_RADIUS, LANDING_PAGE_MODE} from '../../utils/constants';

const HiwayAccountSection = ({
    onCreationStartClick,
    mode,
    isInRequestProAccessPage,
}) => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const content = useMemo(() => {
        return {
            [LANDING_PAGE_MODE.GENERIC]: {
                background: 'linear-gradient(259.95deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #F3C148',
                styles: {width: '50%'},
                maxWidth: '452px',
                textMaxWidth: '320px',
                title: t('landing.generic.title'),
                titleColor: 'text_v2.secondary',
                text: t('landing.generic.text'),
                color: 'text_v2.secondary',
                svanImage: SvanImage,
                imagesComponent: <GenericImagesComponent />,
                imagesComponentMobile: <GenericImagesComponentMobile />,
            },
            [LANDING_PAGE_MODE.QONTO]: {
                background: 'linear-gradient(259.95deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #F3C148',
                styles: {width: '50%'},
                maxWidth: '452px',
                textMaxWidth: '320px',
                title: t('landing.qonto.title'),
                titleColor: 'text_v2.secondary',
                text: t('landing.qonto.text'),
                color: 'text_v2.secondary',
                svanImage: SvanImage,
                imagesComponent: <QontoImagesComponent />,
                imagesComponentMobile: <QontoImagesComponentMobile />,
            },
            [LANDING_PAGE_MODE.TRANSFERS]: {
                background: 'linear-gradient(260deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%), #0B2333',
                styles: {},
                maxWidth: '50%',
                maxWidthMobile: '65%',
                textMaxWidth: '472px',
                title: t('landing.transfers.title'),
                titleColor: '#F3C148',
                text: t('landing.transfers.text'),
                color: '#FFF',
                svanImage: SvanWhiteImage,
                imagesComponent: <TransfersImagesComponent />,
                imagesComponentMobile: <TransfersImagesComponentMobile />,
                buttonName: t('landing.activateServiceOnboarding'),
            },
            [LANDING_PAGE_MODE.CARDS]: {
                background: 'linear-gradient(260deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%), #E84C4C',
                styles: {},
                maxWidth: '650px',
                maxWidthMobile: '60%',
                textMaxWidth: '472px',
                title: t('landing.cards.title'),
                titleColor: '#FFF',
                text: t('landing.cards.text'),
                color: '#FFF',
                svanImage: SvanWhiteImage,
                imagesComponent: <CardsImagesComponent />,
                imagesComponentMobile: <CardsImagesComponentMobile />,
                buttonVariant: 'outlined',
                buttonColor: 'whitePallete',
                buttonName: t('landing.activateServiceOnboarding'),
                buttonStyles: {backgroundColor: 'v2.light.secondary.main'},
            },
            [LANDING_PAGE_MODE.DIRECT_DEBIT]: {
                background: 'linear-gradient(260deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%), #EA6D4B;',
                styles: {},
                maxWidth: 'calc(100% - 72px)',
                textMaxWidth: '320px',
                title: t('landing.directDebit.title'),
                titleColor: '#FFF',
                text: t('landing.directDebit.text'),
                color: '#FFF',
                svanImage: SvanWhiteImage,
                imagesComponent: null,
                imagesComponentMobile: null,
                buttonVariant: 'outlined',
                buttonColor: 'whitePallete',
                buttonName: t('landing.activateServiceOnboarding'),
            },
        };
    }, [t]);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.BANK_CREATION),
    );

    if (!isMobileSize) {
        return (
            <Box sx={
            {
                mb: 4,
                borderRadius: LANDING_PAGE_BORDER_RADIUS,
                background: content[mode].background,
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
            }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        pt: 8,
                        pl: 9,
                        pb: isInRequestProAccessPage ? 18.1 : 12,
                        ...(content[mode].styles),
                    }}
                >
                    <Box sx={{maxWidth: content[mode].maxWidth}}>
                        <Typography
                            variant="h2"
                            sx={{color: content[mode].titleColor}}
                        >
                            {content[mode].title}
                        </Typography>
                    </Box>
                    <Box sx={{
                        mt: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    >
                        <Typography sx={{
                            fontSize: theme => theme.typography.pxToRem(12),
                            mr: 1,
                            letterSpacing: '0.4px',
                            color: content[mode].color,
                        }}
                        >
                            {t('landing.partnership')}:
                        </Typography>
                        <img src={content[mode].svanImage} alt="swan" />
                    </Box>
                    <Box sx={{mt: 2, mb: 5, maxWidth: content[mode].textMaxWidth}}>
                        <Typography sx={{
                            fontSize: theme => theme.typography.pxToRem(24),
                            lineHeihgt: '33.6px',
                            letterSpacing: '-0.2px',
                            color: content[mode].color,
                        }}
                        >
                            {content[mode].text}
                        </Typography>
                    </Box>
                    {!isInRequestProAccessPage && (
                        <Box>
                            <LoadingButton
                                onClick={onCreationStartClick}
                                color={content[mode].buttonColor ?? 'secondary'}
                                endIcon={<ArrowForwardIcon />}
                                variant={content[mode].buttonVariant ?? 'contained'}
                                loading={isLoading}
                                sx={content[mode].buttonStyles ?? {}}
                            >
                                {content[mode].buttonName ?? t('landing.activateService')}
                            </LoadingButton>
                        </Box>
                    )}
                </Box>

                {content[mode].imagesComponent}
            </Box>
        );
    }

    // If mobile size
    return (
        <Box sx={
            {
                mb: 4,
                borderRadius: LANDING_PAGE_BORDER_RADIUS,
                background: content[mode].background,
                display: 'flex',
                flexDirection: 'column',
                pt: 2,
                px: 3,
                pb: 3,
            }}
        >
            <Box sx={{mb: 2}}>
                <Typography
                    variant="h2"
                    sx={{color: content[mode].titleColor}}
                >
                    {content[mode].title}
                </Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    mb: '-33px',
                }}
            >
                <Box sx={{maxWidth: content[mode].maxWidthMobile ?? '50%'}}>
                    <Box sx={{
                        mb: 2,
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }}
                    >
                        <Typography sx={{
                            fontSize: theme => theme.typography.pxToRem(12),
                            mr: 1,
                            letterSpacing: '0.4px',
                            color: content[mode].color,
                        }}
                        >
                            {t('landing.partnership')}:
                        </Typography>
                        <img src={content[mode].svanImage} alt="swan" />
                    </Box>
                    <Typography sx={{
                        fontSize: theme => theme.typography.pxToRem(16),
                        letterSpacing: '-0.15px',
                        color: content[mode].color,
                    }}
                    >
                        {content[mode].text}
                    </Typography>
                </Box>

                {content[mode].imagesComponentMobile}
            </Box>

            {!isInRequestProAccessPage && (
                <Box>
                    <LoadingButton
                        loading={isLoading}
                        onClick={onCreationStartClick}
                        color={content[mode].buttonColor ?? 'secondary'}
                        fullWidth
                        endIcon={content[mode].buttonName ? null : <ArrowForwardIcon />}
                        variant={content[mode].buttonVariant ?? 'contained'}
                        sx={content[mode].buttonStyles ?? {}}
                    >
                        {content[mode].buttonName ?? t('landing.activateService')}
                    </LoadingButton>
                </Box>
            )}
        </Box>
    );
};

HiwayAccountSection.propTypes = {
    onCreationStartClick: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    mode: PropTypes.oneOf(Object.values(LANDING_PAGE_MODE)),
    isInRequestProAccessPage: PropTypes.bool,
};

HiwayAccountSection.defaultProps = {
    onCreationStartClick: null,
    mode: LANDING_PAGE_MODE.GENERIC,
    isInRequestProAccessPage: false,
};

export default HiwayAccountSection;

const GenericImagesComponent = () => (
    <Box sx={{position: 'relative'}}>
        <Box sx={{position: 'absolute', left: '-100px', bottom: '-7px'}}>
            <img style={{width: '519px'}} src={TopMobileImage} alt="Mobile" />
        </Box>
        <Box sx={{position: 'absolute', bottom: '90px', left: '102px'}}>
            <img style={{width: '366px'}} src={TopCardImage} alt="Card" />
        </Box>
    </Box>
);

const GenericImagesComponentMobile = () => (
    <Box sx={{position: 'relative', width: '100%', height: '257px'}}>
        <Box sx={{position: 'absolute', left: '-50px'}}>
            <img style={{height: '258px'}} src={TopMobileSmallImage} alt="Mobile" />
        </Box>
        <Box sx={{position: 'absolute', top: '70px', left: '5px'}}>
            <img style={{width: '158px'}} src={TopCardImage} alt="Card" />
        </Box>
    </Box>
);

const QontoImagesComponent = GenericImagesComponent;
const QontoImagesComponentMobile = GenericImagesComponentMobile;

const TransfersImagesComponent = () => (
    <Box sx={{position: 'absolute', right: '80px', bottom: '-7px'}}>
        <img style={{maxWidth: '309px', height: '95%'}} src={TopTransfersImage} alt="Mobile" />
    </Box>
);

const TransfersImagesComponentMobile = () => (
    <Box sx={{width: '100%', height: '280px', position: 'relative'}}>
        <Box sx={{position: 'absolute', right: '-24px', bottom: '-5px'}}>
            <img style={{height: '300px'}} src={TopTransfersImageMobile} alt="Mobile" />
        </Box>
    </Box>
);

const CardsImagesComponent = () => (
    <Box sx={{position: 'absolute', right: '40px', bottom: '-7px'}}>
        <img style={{maxWidth: '309px', height: '95%'}} src={TopCardsImage} alt="Mobile" />
    </Box>
);

const CardsImagesComponentMobile = () => (
    <Box sx={{width: '100%', height: '300px', position: 'relative'}}>
        <Box sx={{position: 'absolute', right: '-24px', bottom: '-5px'}}>
            <img style={{height: '300px'}} src={TopCardsImageMobile} alt="Mobile" />
        </Box>
    </Box>
);
