import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Box} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Toast} from '../../../../../../lib/toast';
import {copyToClipboard} from '../../../../../../utils/copy-to-clipboard';
import {TransactionSide, TransactionStatus} from '../../util/constants';
import {AccountBalanceHelperFunctions} from '../../util/functions';
import AccountBalanceIcon from '../account-balance/AccountBalanceIcon';
import {AccountDetailsTotal} from '../account-balance/AccountBalanceTotal';

const TransactionDetailsHeader = ({data}) => {
    const date = data?.date ?? data?.createdAt;
    const id = data?.integrationId ? data.integrationId.slice(0, 7) + '...' : '';

    const isRejected = data?.status === TransactionStatus.REJECTED;

    return (
        <>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <Box
                    sx={{
                        width: '48px',
                        height: '48px',
                        borderRadius: '50%',
                        background: '#E0F2F1',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 3,
                        mb: 2,
                    }}
                >
                    <AccountBalanceIcon
                        paymentType={data?.type}
                        category={data?.category}
                        sourceOfRevenue={data?.sourceOfRevenue}
                    />
                </Box>
            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 500,
                textTransform: 'uppercase',
                wordBreak: 'break-all',
                textAlign: 'center',
                maxWidth: '90%',
                pl: '10%',
            }}
            >
                {AccountBalanceHelperFunctions.getTransactionLabel(data)}
            </Box>


            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'grey.light',
                    my: 1.5,
                    mx: 2,
                    backgroundColor: 'gray_v2.50',
                    borderRadius: '8px',
                    padding: '4px 16px',
                }}
            >
                <Box>
                    {moment(date).format('DD MMM YYYY · HH.mm')} · {id}
                </Box>
                <ContentCopyIcon
                    onClick={() => {
                        copyToClipboard(data?.integrationId);
                        Toast.success('copiedToClipboard');
                    }}
                    sx={{
                        'cursor': 'pointer',
                        'ml': 0.5,
                        '&:hover': {
                            color: 'v2.blueGray.300',
                        },
                    }}
                />
            </Box>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <AccountDetailsTotal
                    amount={data.amount}
                    isDebit={data.side !== TransactionSide.CREDIT}
                    isRejected={isRejected}
                />
            </Box>
        </>
    );
};

TransactionDetailsHeader.propTypes = {
    data: PropTypes.object.isRequired,
};

export default TransactionDetailsHeader;
