import {useTheme} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ErrPageWrapper} from './common/ErrPageWrapper';
import {ReactComponent as Error404} from '../../../assets/svg/404.svg';
import withJoyUI from '../../../components/joy-ui/joyui-theme-provider/JoyUiThemePRoviderWrapper';
import {push} from '../../../lib/router/connected-router-saga';
import {getConfig} from '../../../v1/config';

const NotFoundView = () => {
    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const theme = useTheme();
    const color = theme.vars.palette.text.cocoa;

    const onSubmit = event => {
        event.preventDefault();
        dispatch(push(getConfig().ROUTE_PATHS.DASHBOARD));
    };

    return (
        <ErrPageWrapper
            onSubmit={onSubmit}
            title={t('notFoundView.message')}
            buttonLabel={t('notFoundView.buttonTitle')}
        >
            <Error404
                width="100vw"
                mihHeight="100px"
                fill={color}
            />
        </ErrPageWrapper>
    );
};

export default withJoyUI(NotFoundView);
