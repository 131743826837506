export const resetPasswordFormErrorCodes = {
    USERNAME_NOT_FOUND: 'USERNAME_NOT_FOUND',
    INVALID_USERNAME: 'INVALID_USERNAME',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
    PASSWORDS_DO_NOT_MATCH: 'PASSWORDS_DO_NOT_MATCH',
    INVALID_PASSWORD: 'INVALID_PASSWORD',
    INVALID_VERIFICATION_CODE: 'INVALID_VERIFICATION_CODE',
    MINIMUM_LENGTH: 'MINIMUM_LENGTH',
    REQUIRE_NUMBER: 'REQUIRE_NUMBER',
    REQUIRE_SPECIAL_CHARACTER: 'REQUIRE_SPECIAL_CHARACTER',
    REQUIRE_UPPERCASE_LETTER: 'REQUIRE_UPPERCASE_LETTER',
    REQUIRE_LOWERCASE_LETTER: 'REQUIRE_LOWERCASE_LETTER',
};
