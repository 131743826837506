import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Slide, Typography} from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import capitalDepositInitial from '../../../../../../assets/svg/capitalDepositInitial.svg';
import {PageHeader} from '../../../../../../components/page-header/PageHeader';
import Banner from '../../../../../../layout/ContainerWithBigImage/Banner';
import {Toast} from '../../../../../../lib/toast';
import {copyToClipboard} from '../../../../../../utils/copy-to-clipboard';
import {AnimationActions} from '../../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../../animations/utils/constants';
import {BankSelector} from '../../../../../bank/store/bank.selector';

const CapitolDepositBankFundTransfer = () => {
    const dispatch = useDispatch();

    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('freelancerOnboarding');

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const integration = useSelector(BankSelector.selectIntegrations);

    const bankAccountHolders = integration?.[0]?.bankAccountHolders;
    const individualBankAccountHolder = bankAccountHolders ? bankAccountHolders.find(
        integration => integration?.type !== 'COMPANY',
    ) : {};

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const deposit = useSelector(BankSelector.selectCapitalDeposit);

    const amount = deposit?.capitalDepositAmountExpected?.value;
    const iban = individualBankAccountHolder?.bankAccounts?.[0]?.iban;

    return (
        <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
            <div>
                <Box display="grid" gridTemplateRows="min-content 1fr">
                    {!isMobileSize && (
                        <>
                            <PageHeader
                                subTitle={t('capitalTransferFunds.subtitle')}
                                isMobile={isMobileSize}
                            >
                                {t('capitalTransferFunds.header')}
                            </PageHeader>
                        </>
                    )}
                </Box>
                <Banner
                    header={(
                        <>
                            <Trans
                                t={t}
                                values={{
                                    money: amount,
                                }}
                                i18nKey="capitalDepositTransferFunds.headline"
                                components={{bold: <Box component="span" sx={{color: '#E84C4C'}} />}}
                            />
                        </>
                    )}
                    text={(
                        <>
                            <Box sx={{
                                mt: 2,
                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                borderRadius: '16px',
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                width: isMobileSize ? '100%' : '380px',
                            }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: isMobileSize ? 'left' : 'center',
                                }}
                                >
                                    <Typography
                                        component="span"
                                        sx={{
                                            fontWeight: 500,
                                            color: '#0B2333',
                                        }}
                                    >
                                        {t('capitalTransferFunds.iban')}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: 'text_v2.primary',
                                            ml: 1,
                                        }}
                                        component="span"
                                    >
                                        {iban}
                                    </Typography>
                                    <ContentCopyIcon
                                        sx={{cursor: 'pointer', ml: 1}}
                                        onClick={() => {
                                            copyToClipboard(iban);
                                            Toast.success('copiedToClipboard');
                                        }}
                                    />
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mt: 2,
                                }}
                                >
                                    <Typography
                                        component="span"
                                        sx={{
                                            fontWeight: 500,
                                            color: '#0B2333',
                                        }}
                                    >
                                        {t('capitalTransferFunds.amount')}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: 'text_v2.primary',
                                            ml: 1,
                                        }}
                                        component="span"
                                    >
                                        {amount} €
                                    </Typography>
                                    <ContentCopyIcon
                                        sx={{cursor: 'pointer', ml: 1}}
                                        onClick={() => {
                                            copyToClipboard(amount);
                                            Toast.success('copiedToClipboard');
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Alert
                                severity="info"
                                sx={{
                                    mt: 4,
                                    mb: 6,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Trans
                                    t={t}
                                    values={{
                                        money: amount,
                                    }}
                                    i18nKey="capitalTransferFunds.alertInfo"
                                    components={{bold: <Box component="span" sx={{fontWeight: 'bold'}} />}}
                                />
                                <br />
                                <Trans
                                    t={t}
                                    values={{
                                        money: amount,
                                    }}
                                    i18nKey="capitalTransferFunds.alertInfo2"
                                    components={{bold: <Box component="span" sx={{fontWeight: 'bold'}} />}}
                                />
                            </Alert>
                        </>
                    )}
                    Illustration={(
                        <Box
                            component="div"
                            sx={{
                                mt: 10,
                                mb: 1,
                                position: 'relative',
                                height: isMobileSize ? '100px' : '400px',
                                width: isMobileSize ? '90wv' : '100%',
                                left: isMobileSize ? '0' : '48px',
                            }}
                        >
                            <img
                                style={isMobileSize ? {
                                    width: '80vw',
                                } : {}}
                                src={capitalDepositInitial}
                                alt="Capital deposit inital"
                            />
                        </Box>
                    )}
                />
            </div>
        </Slide>
    );
};

export default CapitolDepositBankFundTransfer;
