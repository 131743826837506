import {createSelector} from 'reselect';

const selectFreelancerData = state => state.professionAndSpecialization.freelancerData;
const selectAllValues = state => state.professionAndSpecialization.allValues;
const getAllJobProfessions = () => createSelector(
    [selectAllValues],
    values => (values ? values.map(value => value.jobProfession) : {}),
);

export const ProfessionAndSpecializationSelectors = {
    selectFreelancerData,
    selectAllValues,
    getAllJobProfessions,
};
