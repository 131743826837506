import EditIcon from '@mui/icons-material/Edit';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import {SettingsSelectors} from '../../../../../../../settings/store/settings.selectors';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {emptyFunction} from '../../../../util/constants';
import {AccountBalanceHelperFunctions} from '../../../../util/functions';
import {createIconFromName} from '../../../../util/icon';

export const selectedCategoryStyles = () => ({
    fontSize: theme => theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 1,
    cursor: 'pointer',
    color: 'white',
    backgroundColor: 'primary_v2.main',
    borderRadius: '8px',
    gap: 2,
});

const SelectedCategory = ({mccKey, onOpenCategories, isEditable}) => {
    const settings = useSelector(SettingsSelectors.selectSettings);

    const language = settings?.language ?? 'fr'; // getDefaultLocale();
    const selectCategoriesObject = useSelector(TransactionsSelector.selectCategoriesObject);

    const category = selectCategoriesObject[mccKey];

    const {iconName} = AccountBalanceHelperFunctions.getColorAndIconName({
        selectCategoriesObject,
        category: mccKey,
    });

    return (
        <Box
            sx={isEditable ? selectedCategoryStyles : {
                ...selectedCategoryStyles(),
                backgroundColor: 'v2.blueGray.200',
                cursor: 'default',
            }}
            onClick={isEditable ? onOpenCategories : emptyFunction}
        >
            <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                {createIconFromName(iconName)}
                {category?.[language] ?? mccKey}
            </Box>
            <EditIcon />
        </Box>
    );
};

SelectedCategory.propTypes = {
    mccKey: PropTypes.string.isRequired,
    onOpenCategories: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
};

export default SelectedCategory;
