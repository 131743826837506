import {invoiceApiInstance} from '../../../lib/invoice-api-instance';

const getFinancialStatements = params => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/formalities/annual-accounts`,
        params,
    }).then(result => result?.data);
};

export const FinancialStatementsApi = {
    getFinancialStatements,
};
