import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Badge, Box, Button, ListItem, ListItemContent, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {ItemWithDispatchAction} from './ItemWithDispatchAction';
import {ItemWithLink} from './ItemWithLink';
import {selectRouterLocation} from '../../../../lib/router/connected-router-saga';
import {
    UserRoleColors,
    UserRoleContrastColors,
    UserRoles,
    userDefaultColor,
    userDefaultContrastColor,
} from '../../../../utils/user-roles';
import {SidebarSelectors} from '../../store/sidebar.selector';
import {
    SidebarButtonActionType,
    SidebarItemActionType,
} from '../../utils/constants';

const ListItemIconButton = ({listItem, isActive, userRole}) => {
    const selectedRole = useSelector(SidebarSelectors.selectSelectedRole);
    const dispatch = useDispatch();

    const sx = listItem?.actionButton?.sx || {};
    const isActionTypeLink = SidebarButtonActionType.Link === listItem?.actionButton?.actionType;
    const isActionTypeDispatchAction = SidebarButtonActionType.DispatchAction === listItem?.actionButton?.actionType;
    const to = listItem?.actionButton?.path;
    const action = listItem?.actionButton?.action;

    const role = userRole === UserRoles.ADMINISTRATOR ? selectedRole : userRole;
    const userRoleColor = UserRoleColors[role] ?? userDefaultColor;
    const userRoleContrastColor = UserRoleContrastColors[role] ?? userDefaultContrastColor;

    const handleOnClick = event => {
        event.stopPropagation();
        dispatch(action());
    };

    return (
        <Button
            sx={{
                'width': '32px',
                'height': '32px',
                'minHeight': '32px',
                'borderRadius': 'sm',
                'p': 0,
                'backgroundColor': isActive ? 'text.icon' : userRoleColor,
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                ...(!isActive ? {
                    '&:hover > .MuiBox-root': {
                        color: 'text.icon',
                    },
                } : {}),
                ...sx,
            }}
            data-cy={listItem.actionButton.dataCy}
            variant="solid"
            {...(isActionTypeLink && {component: Link, to})}
            {...(isActionTypeDispatchAction && {onClick: handleOnClick})}
        >
            <Box
                color={isActive ? userRoleColor : userRoleContrastColor}
                fontSize="xl"
            >
                <FontAwesomeIcon icon={listItem.actionButton.icon} fixedWidth />
            </Box>
        </Button>
    );
};

ListItemIconButton.propTypes = {
    listItem: PropTypes.object.isRequired,
    userRole: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
};

const ItemContent = ({listItem, userRole}) => {
    const {t} = useTranslation('sidebar');

    const location = useSelector(selectRouterLocation);

    const isActive = listItem.path === '/'
        ? location.pathname === '/'
        : location.pathname.startsWith(listItem.path);

    return (
        <ListItem
            sx={{
                'display': 'flex',
                'alignItems': 'center',
                'width': '224px',
                'height': '36px',
                'p': 1,
                'mt': 1,
                'borderRadius': 'sm',
                ...(isActive ? {
                    backgroundColor: 'neutral.plainColor',
                } : {
                    '&:hover': {
                        backgroundColor: 'background.level2',
                    },
                }),
                '& .MuiListItem-endAction': {
                    top: '2px',
                    right: isActive ? '2px' : 0,
                    transform: 'none',
                },
            }}
            endAction={!!listItem.actionButton && (
                <ListItemIconButton listItem={listItem} isActive={isActive} userRole={userRole} />
            )}
        >
            {listItem.icon && (
                <Box
                    color={isActive ? 'primary.solidColor' : 'text.icon'}
                    fontSize="md"
                    sx={{mr: 1}}
                >
                    <FontAwesomeIcon icon={listItem.icon} fixedWidth />
                </Box>
            )}

            <ListItemContent>
                <Typography
                    level="body-sm"
                    sx={{color: isActive ? 'primary.solidColor' : 'text.primary'}}
                >
                    {t(listItem.title)}
                </Typography>
            </ListItemContent>

            {listItem.hasBadge && (
                <Badge
                    color="primary"
                    variant="solid"
                    size="sm"
                    sx={{mr: 0.5}}
                />
            )}
        </ListItem>
    );
};

ItemContent.propTypes = {
    listItem: PropTypes.object.isRequired,
    userRole: PropTypes.string.isRequired,
};

export const ExpandedSingleItem = ({listItem, userRole}) => {
    switch (listItem?.actionType) {
        case SidebarItemActionType.Link:
            return (
                <ItemWithLink to={listItem.path}>
                    <ItemContent listItem={listItem} userRole={userRole} />
                </ItemWithLink>
            );
        case SidebarItemActionType.DispatchAction:
            return (
                <ItemWithDispatchAction action={listItem.action}>
                    <ItemContent listItem={listItem} userRole={userRole} />
                </ItemWithDispatchAction>
            );
        default:
            return null;
    }
};

ExpandedSingleItem.propTypes = {
    listItem: PropTypes.object.isRequired,
    userRole: PropTypes.string.isRequired,
};
