import {FromInsuranceDTO} from './insurance.dto';
import {hiwayApiInstance} from '../../../lib/hiway-api-instance';

const getInsuranceData = (freelancerId, companyId) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}/companies/${companyId}/insurance`)
        .then(result => new FromInsuranceDTO(result?.data));
};

const updateInsuranceData = (freelancerId, companyId, data) => {
    return hiwayApiInstance
        .post(`/v1/freelancers/${freelancerId}/companies/${companyId}/insurance`, data)
        .then(result => new FromInsuranceDTO(result?.data));
};

const generateInsuranceDocument = (freelancerId, companyId) => {
    return hiwayApiInstance
        .post(`/v1/freelancers/${freelancerId}/companies/${companyId}/generate-documents?stage=7`)
        .then(result => result?.data);
};

export const InsuranceApi = {
    getInsuranceData,
    updateInsuranceData,
    generateInsuranceDocument,
};
