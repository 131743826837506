import {Skeleton as JoySkeleton} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';

const SkeletonLoadingContext = React.createContext({isLoading: false});

export const LoadingSkeletonProvider = props => {
    const parentContext = useContext(SkeletonLoadingContext);
    const isLoading = props.isLoading ?? parentContext.isLoading;
    const pulseBg = props.pulseBg ?? parentContext.pulseBg;
    return (
        <SkeletonLoadingContext.Provider value={{isLoading, pulseBg}}>{props.children}</SkeletonLoadingContext.Provider>
    );
};

LoadingSkeletonProvider.propTypes = {
    isLoading: PropTypes.bool,
    pulseBg: PropTypes.string,
    children: PropTypes.node.isRequired,
};

LoadingSkeletonProvider.defaultProps = {
    isLoading: undefined,
    pulseBg: undefined,
};

export const LoadingSkeleton = ({
    isLoading: isLoadingProp,
    pulseBg: pulseBgProp,
    placeholder,
    children,
    sx,
    ...props
}) => {
    const context = useContext(SkeletonLoadingContext);
    const isLoading = isLoadingProp ?? context.isLoading;
    const pulseBg = pulseBgProp ?? context.pulseBg;
    return (
        <JoySkeleton
            variant="inline"
            loading={isLoading}
            sx={{position: 'relative', ...(pulseBg && {'--unstable_pulse-bg': pulseBg}), ...sx}}
            {...props}
        >
            {isLoading && placeholder ? placeholder : children}
        </JoySkeleton>
    );
};

LoadingSkeleton.propTypes = {
    isLoading: PropTypes.bool,
    pulseBg: PropTypes.string,
    placeholder: PropTypes.node,
    variant: PropTypes.oneOf(['circular', 'inline', 'overlay', 'rectangular', 'text']),
    sx: PropTypes.object,
    children: PropTypes.node,
};

LoadingSkeleton.defaultProps = {
    isLoading: undefined,
    pulseBg: undefined,
    placeholder: undefined,
    variant: 'inline',
    sx: {},
    children: undefined,
};
