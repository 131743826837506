import makeStyles from '@mui/styles/makeStyles';

export const useCompanyActionButtonStyles = makeStyles(theme => ({
    root: {
        'backgroundColor': theme.palette.grey['100'],
        'borderRadius': 6,
        'padding': theme.spacing(1),
        'minWidth': 'auto',
        '& svg': {
            color: 'rgba(0, 0, 0, 0.87)',
        },
        '&:hover': {
            'backgroundColor': 'rgba(10, 123, 196, 0.1)',
            '& svg': {
                color: theme.palette.primary.main,
            },
        },
        '&.Mui-disabled': {
            'backgroundColor': theme.palette.grey['100'],
            '& svg': {
                color: '#BDBDBD',
            },
        },
    },
}));
