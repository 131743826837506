import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingButton} from '@mui/lab';
import {Box, FormControlLabel, FormGroup} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '../../../../components/checkbox/Checkbox';

export const AtlasButtonSection = ({onButtonClick, onCheckboxClick, isChecked, isDisabled, isLoading}) => {
    const {t} = useTranslation('training');

    return (
        <>
            <Box sx={{
                mb: {
                    xs: 2,
                    md: 0,
                },
            }}
            >
                <FormGroup>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                defaultChecked={isChecked}
                                onClick={onCheckboxClick}
                                disabled={isDisabled}
                            />
                          )}
                        label={t('atlas.checkbox')}
                    />
                </FormGroup>
            </Box>
            <LoadingButton
                endIcon={<ArrowForwardIcon />}
                onClick={onButtonClick}
                color="secondary"
                variant="contained"
                disableElevation
                disabled={!isChecked}
                loading={isLoading}
            >
                {t('atlas.submit')}
            </LoadingButton>
        </>
    );
};

AtlasButtonSection.propTypes = {
    onButtonClick: PropTypes.func.isRequired,
    onCheckboxClick: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
};

AtlasButtonSection.defaultProps = {
    isDisabled: false,
    isLoading: false,
};
