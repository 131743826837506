import {faListCheck} from '@fortawesome/free-solid-svg-icons';
import {Box, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {CollapsableSection} from './CollapsableSection';
import {EurAmount} from '../../../../../../components/eur-amount/EurAmount';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';
import {useSimulationData} from '../../utils/useSimulationData';

const InputRow = ({label, description, value}) => {
    const isMobileSize = useIsMobileSize();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                bgcolor: 'background.level1',
                px: 2,
                py: 1,
            }}
        >
            <Stack direction="row" spacing={0.5}>
                <Typography level={isMobileSize ? 'body-sm' : 'body-md'} textColor="text.secondary">
                    {label}
                </Typography>
                {description && (
                    <Typography level={isMobileSize ? 'body-sm' : 'body-md'} textColor="text.tertiary">
                        {description}
                    </Typography>
                )}
            </Stack>
            <Typography level={isMobileSize ? 'body-sm' : 'body-md'} textColor="text.secondary" fontWeight={700}>
                {value}
            </Typography>
        </Box>
    );
};

InputRow.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    value: PropTypes.any.isRequired,
};

InputRow.defaultProps = {
    description: undefined,
};

const InputsBlock = ({title, value, description, children}) => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                borderRadius: 'lg',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    bgcolor: 'background.level3',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 1,
                    px: 2,
                }}
            >
                <Stack direction="row" spacing={0.5}>
                    <Typography level="title-sm" textColor="text.secondary">
                        {title}
                    </Typography>
                    {description && (
                        <Typography level="body-sm" textColor="text.tertiary">
                            {description}
                        </Typography>
                    )}
                </Stack>
                <Typography level="body-lg" textColor="text.primary" fontWeight={700}>
                    {value}
                </Typography>
            </Box>
            {children}
        </Box>
    );
};

InputsBlock.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.any,
    description: PropTypes.string,
};

InputsBlock.defaultProps = {
    description: undefined,
    value: undefined,
};

const InputsGroup = ({title, children}) => {
    const isMobileSize = useIsMobileSize();
    return (
        <Box sx={{width: '100%'}}>
            <Typography level={isMobileSize ? 'body-md' : 'h4'} textColor="text.secondary" mb={1}>
                {title}
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>{children}</Box>
        </Box>
    );
};

InputsGroup.propTypes = {
    title: PropTypes.string.isRequired,
};

export const InputsSummary = () => {
    const isMobileSize = useIsMobileSize();
    const {t} = useTranslation('revenueSimulator');
    const simulation = useSimulationData();
    const inputs = simulation.simulationInput;

    const otherRevenuesSum = Object.values(inputs.professionalInfo.otherRevenue).reduce((acc, curr) => acc + curr);
    const spouseOtherRevenuesSum = Object.values(inputs.professionalInfo.spouseOtherRevenue || {}).reduce(
        (acc, curr) => acc + curr,
        0,
    );
    const turnover = inputs.projection.pricePerDay * inputs.projection.workingDaysPerYear;

    const allCharges = {...inputs.projection.fixedCharges, ...inputs.projection.variableCharges};

    const allChargesSum = Object.values(allCharges).reduce((acc, curr) => acc + curr);

    const {numberOfAdults, numberOfDependents} = inputs.personalInfo;
    const numberOfParts = numberOfAdults + numberOfDependents;

    return (
        <CollapsableSection
            isInitiallyCollapsed={true}
            title={isMobileSize ? t('inputsSummary.sectionTitleShort') : t('inputsSummary.sectionTitle')}
            icon={faListCheck}
        >
            <Stack direction={{desktop: 'row', mobile: 'column'}} spacing={{desktop: 2, mobile: 3}}>
                <InputsGroup title={t('inputsSummary.myCurrentSituation')}>
                    <InputsBlock title={t('inputsSummary.financialSituation')}>
                        <InputRow
                            label={t('inputsSummary.status')}
                            value={inputs.professionalInfo.employmentSituation}
                        />
                        <InputRow
                            label={t('inputsSummary.income')}
                            description={`(${t('inputsSummary.netYearBeforeTax')})`}
                            value={<EurAmount amount={inputs.professionalInfo.monthlyNetIncome} />}
                        />
                        <InputRow
                            label={t('inputsSummary.otherIncome')}
                            value={<EurAmount amount={otherRevenuesSum} />}
                        />
                        <InputRow
                            label={t('inputsSummary.eligibleTravelSubvention')}
                            value={inputs.professionalInfo.eligibleTravelSubvention ? t('yes') : t('no')}
                        />
                        {!inputs.professionalInfo.eligibleTravelSubvention && (
                            <InputRow
                                label={t('inputsSummary.monthsToRestoreTravelSubvention')}
                                value={
                                    inputs.professionalInfo.monthsToRestoreTravelSubvention
                                    + ' '
                                    + t('inputsSummary.months')
                                }
                            />
                        )}
                    </InputsBlock>
                    <InputsBlock title={t('inputsSummary.personalSituation')}>
                        <InputRow
                            label={t('inputsSummary.age')}
                            value={inputs.personalInfo.age + ' ' + t('inputsSummary.years')}
                        />
                        <InputRow label={t('inputsSummary.numberOfParts')} value={numberOfParts} />
                        <InputRow label={t('inputsSummary.numberOfAdults')} value={numberOfAdults} />
                        {inputs.personalInfo.numberOfAdults === 2 && (
                            <>
                                <InputRow
                                    label={t('inputsSummary.spouseEmploymentSituation')}
                                    value={inputs.professionalInfo.spouseEmploymentSituation}
                                />
                                <InputRow
                                    label={t('inputsSummary.spouseIncome')}
                                    description={`(${t('inputsSummary.netYearBeforeTax')})`}
                                    value={<EurAmount amount={inputs.professionalInfo.spouseMonthlyNetIncome} />}
                                />
                                <InputRow
                                    label={t('inputsSummary.spouseOtherIncome')}
                                    value={<EurAmount amount={spouseOtherRevenuesSum} />}
                                />
                            </>
                        )}
                    </InputsBlock>
                </InputsGroup>
                <InputsGroup title={t('inputsSummary.myFreelanceProject')}>
                    <InputsBlock
                        title={t('inputsSummary.targetStartingDate')}
                        value={inputs.projection.targetStartingDate}
                    />
                    <InputsBlock
                        title={t('inputsSummary.turnover')}
                        description={`(${t('inputsSummary.annualExcludingTax')})`}
                        value={<EurAmount amount={turnover} />}
                    >
                        <InputRow
                            label={t('inputsSummary.pricePerDay')}
                            value={<EurAmount amount={inputs.projection.pricePerDay} />}
                        />
                        <InputRow
                            label={t('inputsSummary.workingDaysPerYear')}
                            value={inputs.projection.workingDaysPerYear}
                        />
                    </InputsBlock>
                    <InputsBlock
                        title={t('inputsSummary.expenses')}
                        value={<EurAmount amount={allChargesSum} />}
                    >
                        {Object.entries(allCharges).map(([key, value]) => (
                            <InputRow key={key} label={t(`charges.${key}`)} value={<EurAmount amount={value} />} />
                        ))}
                    </InputsBlock>
                    {inputs.investments.monthlyRetirementSavingsPlanAmount && (
                        <InputsBlock title={t('inputsSummary.placements')}>
                            <InputRow
                                label={t('inputsSummary.monthlyRetirementSavingsPlanAmount')}
                                value={<EurAmount amount={inputs.investments.monthlyRetirementSavingsPlanAmount} />}
                            />
                        </InputsBlock>
                    )}
                </InputsGroup>
            </Stack>
        </CollapsableSection>
    );
};
