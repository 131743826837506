export const RevenueSimulatorActionTypes = {
    RESET_INPUTS: 'revenue-simulator/RESET_INPUTS',
    STORE_SIMULATION: 'revenue-simulator/STORE_SIMULATION',
    SUBMIT_INPUTS: 'revenue-simulator/SUBMIT_INPUTS',
    GET_SIMULATION: 'revenue-simulator/GET_SIMULATION',
    SET_CURRENT_STEP_INDEX: 'revenue-simulator/SET_CURRENT_STEP_INDEX',
    SET_ALL_VALUES: 'revenue-simulator/SET_ALL_VALUES',
    GO_NEXT_STEP: 'revenue-simulator/GO_NEXT_STEP',
    GO_PREV_STEP: 'revenue-simulator/GO_PREV_STEP',
    SUBMIT_STEP: 'revenue-simulator/SUBMIT_STEP',
    UPDATE_VALUES: 'revenue-simulator/UPDATE_VALUES',
    GET_ALL_SIMULATIONS: 'revenue-simulator/GET_ALL_SIMULATIONS',
    STORE_ALL_SIMULATIONS: 'revenue-simulator/STORE_ALL_SIMULATIONS',
    SET_SIMULATIONS_LOADING: 'revenue-simulator/SET_SIMULATIONS_LOADING',
    REFRESH_SIMULATION: 'revenue-simulator/REFRESH_SIMULATION',
    SET_IS_SUBMITTING: 'revenue-simulator/SET_IS_SUBMITTING',
};
