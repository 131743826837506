import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingButton} from '@mui/lab';
import {Box, Grow, Paper, Slide, Typography, Zoom} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {DossierDate} from './DossierDate';
import {DossierDocuments} from './DossierDocuments';
import {AnimationActions} from '../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../animations/utils/constants';
import {DatabaseSelectors} from '../../../document/modules/database/store/database.selector';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {TrainingActions} from '../../store/training.action';
import {TRAINING_CONSTANTS} from '../../utils/constants';

export const DossierDocumentGeneration = () => {
    const dispatch = useDispatch();

    const {t} = useTranslation('training');
    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const isLoadingNextStep = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.TRAINING_STEP_SUBMIT),
    );

    const isWaitingNonSigned = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.WAITING_UNSIGNED_DOCS),
    );

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const documents = useSelector(DatabaseSelectors.selectDocuments);
    const isDocumentGenerationInProgress = Object.values(documents).some(({status}) => status === 'GENERATING');

    const onButtonClick = () => {
        dispatch(TrainingActions.submitStep(TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_GENERATION));
    };

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    return (
        <Box sx={{
            px: {
                xs: 2,
                md: 0,
            },
        }}
        >
            <Paper sx={{
                px: {
                    xs: 2,
                    md: 5,
                },
                pt: {
                    xs: 2,
                    md: 4,
                },
                pb: {
                    xs: 3,
                    md: 6,
                },
                borderRadius: '8px',
            }}
            >
                <Slide in={isAnimationActive} direction="left" onExited={onEndedAnimation}>
                    <Box sx={{mb: 3}}>
                        <Typography
                            sx={{
                                fontSize: theme => theme.typography.pxToRem(16),
                                fontWeight: 600,
                            }}
                        >
                            {t('dossier.documentGeneration.header')}
                        </Typography>
                    </Box>
                </Slide>

                <Zoom in={isAnimationActive} timeout={350}>
                    <Box>
                        <DossierDate
                            hasResetOption={false}
                            isDocumentGenerationInProgress={isDocumentGenerationInProgress}
                        />
                    </Box>
                </Zoom>

                <Grow in={isAnimationActive}>
                    <Box
                        sx={TRAINING_CONSTANTS.TABLE_STYLE_WRAPPER}
                    >
                        <DossierDocuments isMobileSize={isMobileSize} />
                    </Box>
                </Grow>
            </Paper>

            <Slide in={isAnimationActive} direction="left" timeout={500}>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    <LoadingButton
                        fullWidth={isMobileSize}
                        endIcon={<ArrowForwardIcon />}
                        onClick={onButtonClick}
                        color="secondary"
                        variant="contained"
                        disableElevation
                        loading={isLoadingNextStep}
                        disabled={isDocumentGenerationInProgress || isLoadingNextStep || isWaitingNonSigned}
                    >
                        {t('dossier.documentGeneration.button')}
                    </LoadingButton>
                </Box>
            </Slide>
        </Box>
    );
};
