import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {Button, MenuItem, Popover} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink as RouterLink} from 'react-router-dom';
import {useAccountDropdownStyles} from './styles';
import {importLocaleBundle} from '../../lib/i18next';
import {RoutePaths} from '../../lib/router/route-paths';
import {USER_ROLES} from '../../utils/user-roles';
import {logout} from '../auth/store/auth.actions';

import {LoggedInUserSelectors} from '../user/modules/logged-in-user';

// TODO Remove? Not used

importLocaleBundle('common');
const AccountDropdown = () => {
    const styles = useAccountDropdownStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation('common');

    const cognitoUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const isUserFreelancer = cognitoUser && cognitoUser.role === USER_ROLES.FREELANCER;

    const [isOpen, setIsOpen] = useState(false);
    const iconButtonRef = useRef(null);

    const handleButtonClick = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleLogOutButtonClick = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    const linkProps = {
        // activeClassName: styles.activeLink, // TODO
        className: styles.link,
        component: RouterLink,
        onClick: handleClose,
    };

    return (
        <div>
            <Button
                aria-label="menu"
                onClick={handleButtonClick}
                endIcon={<ExpandMoreIcon className={styles.expandMoreIcon} />}
                ref={iconButtonRef}
                color="inherit"
                data-cypress="account-dropdown"
            >
                <PersonIcon />
            </Button>

            <Popover
                id="menu-app-bar"
                anchorEl={iconButtonRef.current}
                open={isOpen}
                onClose={handleClose}
                classes={{
                    paper: styles.paper,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem data-cypress="profile-item" {...linkProps} to={RoutePaths.MY_PROFILE}>
                    <AccountCircleOutlinedIcon className={styles.icon} />

                    {t('userMenu.profile')}
                </MenuItem>

                {isUserFreelancer && (
                    <MenuItem data-cypress="companies-item" {...linkProps} to={RoutePaths.MY_COMPANIES}>
                        <BusinessCenterOutlinedIcon className={styles.icon} />

                        {t('userMenu.companies')}
                    </MenuItem>
                )}

                <MenuItem data-cypress="settings-item" {...linkProps} to={RoutePaths.SETTINGS}>
                    <SettingsOutlinedIcon className={styles.icon} />

                    {t('userMenu.settings')}
                </MenuItem>

                <MenuItem data-cypress="change-password-item" {...linkProps} to={RoutePaths.CHANGE_PASSWORD}>
                    <LockOpenIcon className={styles.icon} />

                    {t('userMenu.changePassword')}
                </MenuItem>

                <MenuItem data-cypress="logout-item" button onClick={handleLogOutButtonClick} className={styles.link}>
                    <PowerSettingsNewIcon className={styles.icon} />

                    {t('userMenu.logout')}
                </MenuItem>
            </Popover>
        </div>
    );
};

export const AccountDropdownMobile = ({onClose}) => {
    const styles = useAccountDropdownStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation('common');

    const cognitoUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    const isUserFreelancer = cognitoUser && cognitoUser.role === USER_ROLES.FREELANCER;

    const handleLogOutButtonClick = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    const handleClose = () => {
        onClose();
    };

    const linkProps = {
        // activeClassName: styles.activeLink, // TODO
        className: styles.link,
        component: RouterLink,
        onClick: handleClose,
    };

    return (
        <div>
            <MenuItem data-cypress="profile-item" {...linkProps} to={RoutePaths.MY_PROFILE}>
                <AccountCircleOutlinedIcon className={styles.icon} />

                {t('userMenu.profile')}
            </MenuItem>

            {isUserFreelancer && (
                <MenuItem data-cypress="companies-item" {...linkProps} to={RoutePaths.MY_COMPANIES}>
                    <BusinessCenterOutlinedIcon className={styles.icon} />

                    {t('userMenu.companies')}
                </MenuItem>
            )}

            <MenuItem data-cypress="settings-item" {...linkProps} to={RoutePaths.SETTINGS}>
                <SettingsOutlinedIcon className={styles.icon} />

                {t('userMenu.settings')}
            </MenuItem>

            <MenuItem data-cypress="change-password-item" {...linkProps} to={RoutePaths.CHANGE_PASSWORD}>
                <LockOpenIcon className={styles.icon} />

                {t('userMenu.changePassword')}
            </MenuItem>

            <MenuItem data-cypress="logout-item" button onClick={handleLogOutButtonClick} className={styles.link}>
                <PowerSettingsNewIcon className={styles.icon} />

                {t('userMenu.logout')}
            </MenuItem>
        </div>
    );
};

AccountDropdownMobile.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default AccountDropdown;
