import Typography from '@mui/material/Typography';
import React from 'react';

import {useDataItemRowLabel} from './styles';

const DataItemRowLabel = ({children}) => {
    const styles = useDataItemRowLabel();

    return (
        <Typography color="textPrimary" component="span" className={styles.root} noWrap>
            {children}
        </Typography>
    );
};

export default DataItemRowLabel;
