import {Box, Paper, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';

const BankHelperPagesWrapper = ({image, Button, text, header}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box>
            <Paper
                elevation={1}
                sx={!isMobileSize ? {borderRadius: '8px', p: 5} : {}}
            >
                <Box sx={{
                    background:
                        '#FAFAFA',
                    borderRadius: isMobileSize ? 0 : '8px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
                >
                    <Box sx={{maxWidth: '500px'}}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            maxWidth: '500px',
                            height: isMobileSize ? '100vh' : '672px',
                            p: isMobileSize ? 3 : 0,
                        }}
                        >
                            <Box sx={{mb: 2}}>{image}</Box>
                            <Box sx={{mb: isMobileSize ? 2 : 0.5}}>
                                <Typography variant="h4">
                                    {header}
                                </Typography>
                            </Box>
                            <Box>{text}</Box>
                            <Box sx={isMobileSize
                                ? {mt: 3, width: '100%'}
                                : {mt: 3}}
                            >{Button}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

BankHelperPagesWrapper.propTypes = {
    image: PropTypes.object.isRequired,
    Button: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
};

export default BankHelperPagesWrapper;
