export const REMOTE_JOB_TYPE = {
    NONE: 'NONE',
    PARTIAL: 'PARTIAL',
    FULL: 'FULL',
};

export const ADMIN_OPPORTUNITY_GRID_TEMPLATES = {
    HEAD: '2fr 0.7fr repeat(6, 1fr)',
    ELEMENT: '2fr 0.7fr repeat(6, 1fr)',
};

export const CLUSTER_SORT_KEYS = {
    client_name: 'client_name',
    created_at: 'created_at',
    start_date: 'start_date',
    daily_rate_high: 'daily_rate_high',
};

export const CLUSTER_TABLE_DEFAULT = {
    limit: 5,
    offset: 0,
    sortOrder: 'desc',
    sortColumn: undefined,
};

export const CLUSTER_TABLE_DEFAULT_SORT_ORDER = {
    [CLUSTER_SORT_KEYS.client_name]: 'desc',
    [CLUSTER_SORT_KEYS.created_at]: 'desc',
    [CLUSTER_SORT_KEYS.start_date]: 'desc',
    [CLUSTER_SORT_KEYS.daily_rate_high]: 'desc',
};

export const FREELANCER_CLUSTER_TABLE_DEFAULT = {
    limit: 10,
    offset: 0,
    sortOrder: 'desc',
    sortColumn: undefined,
};

export const CLUSTER_VIDEO_LINK = 'https://www.youtube.com/embed/aGPOIIxvFQs';
export const RECRUTERS_LIST_FILE_LINK = 'https://docs.google.com/spreadsheets/d/1Y7th02iIeQTAP3SakbMUFDCg-hj5AFGJfNrXJm6YxnA/edit?usp=sharing';

export const ITEM_TYPE = {
    OTHER: 'OTHER',
    FREELANCER: 'FREELANCER',
};

export const TABS = {
    OTHER: 0,
    FREELANCER: 1,
};
