import React from 'react';
import {SubmitOpportunitySuccess} from '../../features/opportunity/components/freelancer-opportunity/SubmitOpportunitySuccess';

const SubmitOpportunitySuccessScreen = () => {
    return (
        <SubmitOpportunitySuccess />
    );
};

export default SubmitOpportunitySuccessScreen;
