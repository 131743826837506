import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getStaticWrapperUtilityClass = slot => {
    return generateUtilityClass('MuiPickerStaticWrapper', slot);
};

export const pickerStaticWrapperClasses = generateUtilityClasses(
    'MuiPickerStaticWrapper',
    ['root', 'content'],
);
