import {BANK_TYPES} from './constants';
import {ACCOUNT_STATUSES} from '../../../../company-profile/modules/accounting/utils/constants';

export const getHiwayBankIntegration = ingrations => {
    return ingrations.find(integration => integration?.type === BANK_TYPES.hiway) ?? ingrations?.[0];
};

export const getIntegrationWithAccountId = integrations => {
    let accountId;
    integrations.forEach(integration => {
        const bankAccountHolder = integration?.bankAccountHolders.find(holder => holder.type === 'COMPANY');
        const bankAccount = bankAccountHolder?.bankAccounts?.[0];
        if (bankAccount?.id && bankAccount?.status === ACCOUNT_STATUSES.ACTIVE) {
            accountId = bankAccount.id;
        }
    });
    return accountId;
};

export const getAnyIntegrationWithAccountId = integrations => {
    let accountId;
    integrations.forEach(integration => {
        const bankAccountHolder = integration?.bankAccountHolders.find(holder => holder.type === 'COMPANY');
        const bankAccount = bankAccountHolder?.bankAccounts?.[0];
        if (bankAccount?.id) {
            accountId = bankAccount.id;
        }
    });
    return accountId;
};

export const getIntegrationInfo = integration => {
    const bankAccountHolder = integration?.bankAccountHolders.find(holder => holder.type === 'COMPANY');
    const account = bankAccountHolder?.bankAccounts?.[0];

    return {
        bankAccountHolder,
        account,
    };
};
