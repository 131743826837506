import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getCalendarOrClockPickerUtilityClass = slot => {
    return generateUtilityClass('MuiCalendarOrClockPicker', slot);
};

export const calendarOrClockPickerClasses = generateUtilityClasses(
    'MuiCalendarOrClockPicker',
    ['root'],
);
