import DateRangeIcon from '@mui/icons-material/DateRange';
import SettingsIcon from '@mui/icons-material/Settings';
import {Box, LinearProgress, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {spendingLimitPeriodEnum} from '../create/BankCardSettings';

export const BankCardLimitSection = ({t, card, setOpenSettings}) => {
    const spending = card?.spending?.amount ? parseFloat(card.spending.amount) : 0;
    const limit = card?.settings?.spendingLimitAmount ? parseFloat(card.settings.spendingLimitAmount) : 0;

    const progress = spending / (limit / 100);

    return (
        <Box sx={{
            backgroundColor: '#F1F4F6',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: 1,
            py: '20px',
            px: 2,
        }}
        >
            <Box>
                <Box sx={{
                    width: '56px',
                    height: '56px',
                    borderRadius: '50%',
                    backgroundColor: 'v2.blueGray.200',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                    <DateRangeIcon sx={{
                        color: 'white',
                    }}
                    />
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                gap: 1,
                mr: 0.5,
            }}
            >
                <Box sx={{
                    color: 'v2.light.text.disabled',
                }}
                >
                    {card?.settings?.spendingLimitPeriod === spendingLimitPeriodEnum.daily && (
                        <>
                            {t('cardDetails.dailyLimit')}
                        </>
                    )}

                    {card?.settings?.spendingLimitPeriod === spendingLimitPeriodEnum.weekly && (
                        <>
                            {t('cardDetails.weeklyLimit')}
                        </>
                    )}

                    {card?.settings?.spendingLimitPeriod === spendingLimitPeriodEnum.monthly && (
                        <>
                            {t('cardDetails.monthlyLimit')}
                        </>
                    )}

                    {card?.settings?.spendingLimitPeriod === spendingLimitPeriodEnum.always && (
                        <>
                            {t('cardDetails.alwaysLimit')}
                        </>
                    )}
                </Box>
                <Box sx={{width: '100%'}}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: 0.5,
                    justifyContent: 'flex-start',
                    width: '100%',
                    fontWeight: 600,
                }}
                >
                    <Typography sx={{
                        color: 'primary_v2.main',
                    }}
                    >
                        {spending} €
                    </Typography>
                    <Typography sx={{
                        color: 'v2.blueGray.200',
                    }}
                    >
                        /
                    </Typography>
                    <Typography sx={{
                        color: 'text_v2.secondary',
                    }}
                    >
                        {limit} €
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setOpenSettings(true);
                }}
            >
                <SettingsIcon sx={{
                    color: 'v2.blueGray.400',
                }}
                />
            </Box>
        </Box>
    );
};

BankCardLimitSection.propTypes = {
    t: PropTypes.func.isRequired,
    card: PropTypes.object,
    setOpenSettings: PropTypes.func.isRequired,
};

BankCardLimitSection.defaultProps = {
    card: {},
};
