import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {TRAINING_CONSTANTS} from '../../utils/constants';
import {TrainingStep} from '../stepper/TrainingStep';

export const TrainingStepper = ({progress, step}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const refs = {
        [TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION]: useRef(),
        [TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS]: useRef(),
        [TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER]: useRef(),
        [TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL]: useRef(),
        [TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION]: useRef(),
    };

    useEffect(() => {
        if (step && refs[step] && isMobileSize) {
            refs[step].current.scrollIntoView({behavior: 'smooth', inline: 'start'});
        }
        // eslint-disable-next-line
    }, [step, isMobileSize]);

    if (!step) {
        // TODO Add Skeleton if in progress?
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: isMobileSize
                    ? '100vw'
                    : '100%',
                overflowX: 'auto',
                overflowY: 'hidden',
                pb: '2px',
                pr: isMobileSize ? 0 : 2,
            }}
        >
            <Box ref={refs[TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION]}>
                <TrainingStep
                    stepNumber={1}
                    isCompleted={progress[TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION].completed}
                    isNext={!progress[TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION].completed}
                    isSelected={step === TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION}
                    value={TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION}
                />
            </Box>

            <Box ref={refs[TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS]}>
                <TrainingStep
                    stepNumber={2}
                    isCompleted={progress[TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS].completed}
                    isNext={
                        progress[TRAINING_CONSTANTS.TRAINING_STEPS.OPCO_REGISTRATION].completed
                        && !progress[TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS].completed
                    }
                    isSelected={step === TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS}
                    value={TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS}
                />
            </Box>

            <Box ref={refs[TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER]}>
                <TrainingStep
                    stepNumber={3}
                    isCompleted={progress[TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER].completed}
                    isNext={
                        progress[TRAINING_CONSTANTS.TRAINING_STEPS.MY_ATLAS].completed
                        && !progress[TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER].completed
                    }
                    isSelected={step === TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER}
                    value={TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER}
                />
            </Box>

            <Box ref={refs[TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL]}>
                <TrainingStep
                    stepNumber={4}
                    isCompleted={progress[TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL].completed}
                    isNext={
                        progress[TRAINING_CONSTANTS.TRAINING_STEPS.DOSSIER].completed
                        && !progress[TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL].completed
                    }
                    isSelected={step === TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL}
                    value={TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL}
                />
            </Box>

            <Box ref={refs[TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION]}>
                <TrainingStep
                    stepNumber={5}
                    isCompleted={progress[TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION].completed}
                    isNext={
                        progress[TRAINING_CONSTANTS.TRAINING_STEPS.FUNDING_APPROVAL].completed
                        && !progress[TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION].completed
                    }
                    isSelected={step === TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION}
                    value={TRAINING_CONSTANTS.TRAINING_STEPS.FINALIZATION}
                />
            </Box>
        </Box>
    );
};

TrainingStepper.propTypes = {
    progress: PropTypes.object.isRequired,
    step: PropTypes.string,
};

TrainingStepper.defaultProps = {
    step: '',
};
