import {TabContext, TabPanel} from '@mui/lab';
import {Box, Paper, Tab, Tabs, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import TabLabel from '../../../../components/tabs/TabLabel';
import {replace} from '../../../../lib/router/connected-router-saga';
import DataTable from '../../../../v1/components/ui-kit/DataTable/DataTable';
import {getSearchParameters} from '../../../invoicing/util/functions';
import {LoadingSelectors, LoadingTypes} from '../../../loading';
import {OpportunitySelector} from '../../store/opportunity.selector';
import {ITEM_TYPE, TABS} from '../../utls/constants';
import {
    getOpportunityDataTableConfig,
    getOtherOpportunityDataTableConfig,
} from '../../utls/opportunity-datatable';

const FreelancerOpportunityTable = ({isScrollTimestamp, setIsScrollTimestamp}) => {
    const tableId = 'table';
    const id = `#${tableId}`;
    const {t} = useTranslation('opportunity');
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const queryParams = getSearchParameters();

    const opportunitiesCount = useSelector(OpportunitySelector.selectOpportunitiesCount);
    const otherOpportunitiesCount = useSelector(OpportunitySelector.selectOtherOpportunitiesCount);

    const tabLabels = count => ({
        [ITEM_TYPE.OTHER]: (
            <TabLabel
                count={count}
                label={t('tabs.other')}
            />
        ),
        [ITEM_TYPE.FREELANCER]: (
            <TabLabel
                count={count}
                label={t('tabs.freelancer')}
            />
        ),
    });

    const [tab, setTab] = useState(queryParams['tab'] ? Number(queryParams['tab']) : TABS.OTHER);

    const handleChangeTab = (_, value) => {
        dispatch(replace({
            search: value ? `tab=${value}` : '',
        }));
        setTab(value);
    };

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(
            LoadingTypes.GET_OPPORTUNITY,
        ),
    );
    const isLoadingOther = useSelector(
        LoadingSelectors.createLoadingSelectorByType(
            LoadingTypes.GET_OTHER_OPPORTUNITY,
        ),
    );

    useEffect(() => {
        if (isScrollTimestamp && tab !== TABS.OTHER) {
            setTab(TABS.OTHER);
        }
    }, [isScrollTimestamp, tab]);

    useEffect(() => {
        if (isScrollTimestamp && tab === TABS.OTHER && !isLoadingOther) {
            document.querySelector(id)?.scrollIntoView({behavior: 'auto', block: 'start'});
            setIsScrollTimestamp(false);
            dispatch(replace({
                search: '',
            }));
        }
    }, [isScrollTimestamp, isLoadingOther, tab, id, setIsScrollTimestamp, dispatch]);

    const columnConfig = useMemo(() => {
        return getOpportunityDataTableConfig(t, isMobileSize);
    }, [t, isMobileSize]);

    const columnConfigOther = useMemo(() => {
        return getOtherOpportunityDataTableConfig(t, isMobileSize);
    }, [t, isMobileSize]);

    return (
        <Paper
            id={tableId}
            elevation={1}
            sx={{
                position: 'relative',
                borderRadius: '16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: '1 0 0',
                alignSelf: 'stretch',
            }}
        >
            <TabContext value={tab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider', width: '100%'}}>
                    <Tabs
                        value={Number(tab)}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTab}
                    >
                        <Tab
                            label={tabLabels(otherOpportunitiesCount)[ITEM_TYPE.OTHER]}
                            sx={{width: '50%', maxWidth: 'unset'}}
                        />
                        <Tab
                            label={tabLabels(opportunitiesCount)[ITEM_TYPE.FREELANCER]}
                            sx={{width: '50%', maxWidth: 'unset'}}
                        />
                    </Tabs>
                </Box>
                <TabPanel sx={{p: 0, alignSelf: 'stretch'}} value={TABS.OTHER}>
                    <DataTable
                        tableId="other-opportunities"
                        columnConfig={columnConfigOther}
                        missingDataMessage={t('noData')}
                        dataSelector={OpportunitySelector.createOtherOpportunitySelector()}
                        isLoading={isLoadingOther}
                        isUseSavedRows={false}
                        hasPaper={false}
                        TableContainerProps={{sx: {mt: '8px !important'}}}
                    />
                </TabPanel>
                <TabPanel sx={{p: 0, alignSelf: 'stretch'}} value={TABS.FREELANCER}>
                    <DataTable
                        tableId="opportunities"
                        columnConfig={columnConfig}
                        missingDataMessage={t('noData')}
                        dataSelector={OpportunitySelector.createOpportunitySelector()}
                        isLoading={isLoading}
                        isUseSavedRows={false}
                        hasPaper={false}
                        TableContainerProps={{sx: {mt: '8px !important'}}}
                    />
                </TabPanel>
            </TabContext>
        </Paper>
    );
};

FreelancerOpportunityTable.propTypes = {
    isScrollTimestamp: PropTypes.bool.isRequired,
    setIsScrollTimestamp: PropTypes.func.isRequired,
};

export default FreelancerOpportunityTable;
