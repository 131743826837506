import makeStyles from '@mui/styles/makeStyles';

export const useDataTableTabFiltersStyles = makeStyles(theme => ({
    tabs: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    tab: {
        margin: theme.spacing(0, 1),
        minWidth: '0',
        padding: theme.spacing(0, 1),
    },
}));
