import {LoadingButton} from '@mui/lab';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {DatabaseSelectors} from '../../features/document/modules/database/store/database.selector';
import {DocumentSigningWrapper} from '../../features/document/modules/signing/components/DocumentSigningWrapper';
import {SigningSelectors} from '../../features/document/modules/signing/store/signing.selector';
import {DOCUMENT_SIGNING_TYPES} from '../../features/document/modules/signing/utils/constants';
import {RoutePaths} from '../../lib/router/route-paths';
import {DOCUMENT_STATUSES} from '../../v1/config/constants/documentConstants';
import {resolveRoute} from '../../v1/navigation/resolveRoute';

const DocumentSigningScreen = () => {
    const {t} = useTranslation('document');
    let MainToolbarComponent = null;

    const isSigningAdditionalDocuments = useSelector(SigningSelectors.selectIsSigningAdditionalDocuments);
    const isSigning = useSelector(SigningSelectors.selectIsSigningCurrentDocument);

    const documents = useSelector(DatabaseSelectors.selectDocuments);
    const areAllDocumentsSigned = useMemo(() => {
        return !!Object.values(documents).length
            && Object.values(documents).every(document => document.status === DOCUMENT_STATUSES.SIGNED);
    }, [documents]);

    // All documents signed
    if (areAllDocumentsSigned && isSigningAdditionalDocuments) {
        MainToolbarComponent = (
            <Link
                to={resolveRoute(RoutePaths.DASHBOARD)}
                style={{textDecoration: 'none', color: 'inherit', alignSelf: 'end'}}
            >
                <LoadingButton
                    sx={{py: 0.75, borderRadius: 2, mt: 1, color: '#fff'}}
                    variant="contained"
                    color="info"
                    size="small"
                    loading={isSigning}
                    loadingPosition="start"
                    fullWidth
                >
                    {t('returnToDashboard')}
                </LoadingButton>
            </Link>
        );
    }


    return (
        <DocumentSigningWrapper
            type={DOCUMENT_SIGNING_TYPES.GLOBAL}
            MainActionComponent={MainToolbarComponent}
        />
    );
};

export default DocumentSigningScreen;
