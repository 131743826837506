import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Input, Stack, Typography} from '@mui/joy';
import {useController, useFormContext} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {ReactComponent as RevenueSimulatorImage} from '../../../../../../assets/svg/revenueSimulator.svg';
import withJoyUI from '../../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemePRoviderWrapper';
import {useIsMobileSize} from '../../../../../../hooks/use-is-mobile-size';

export const FirstStep = withJoyUI(() => {
    const {t} = useTranslation('revenueSimulator');
    const isMobileSize = useIsMobileSize();
    const {formState} = useFormContext();
    const {field: firstNameField} = useController({
        name: 'firstName',
        defaultValue: '',
        rules: {required: true},
    });
    const {field: lastNameField} = useController({
        name: 'lastName',
        defaultValue: '',
        rules: {required: true},
    });
    return (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            borderRadius="xl"
            sx={{
                backgroundColor: 'background.surface',
                p: 5,
                border: '1px solid',
                borderColor: 'neutral.outlinedBorder',
                minHeight: isMobileSize ? 'auto' : 'calc(100vh - 56px)',
            }}
        >
            <RevenueSimulatorImage
                height="168px"
                width="166px"
            />

            <Typography
                level="h1"
                textAlign="center"
                sx={{
                    color: 'text.secondary',
                    fontSize: 'xl5',
                    mt: 4,
                    mb: 2,
                }}
            >
                <Trans
                    t={t}
                    i18nKey="simulateMyRevenueTitle"
                    components={{
                        mark: (
                            <Box
                                component="span"
                                sx={{
                                    color: 'primary.solidBg',
                                }}
                            />
                        ),
                    }}
                />
            </Typography>

            <Typography
                level="body-md"
                textAlign="center"
                sx={{color: 'text.secondary'}}
            >
                <Trans
                    t={t}
                    i18nKey="firstPageDescription"
                    components={{
                        br: <br />,
                    }}
                />
            </Typography>

            <Stack direction="row" justifyContent="center" spacing={2} sx={{my: 4}}>
                <Input
                    variant="outlined"
                    color="neutral"
                    size="md"
                    autoCapitalize="off"
                    spellCheck="false"
                    fullWidth
                    required
                    tabIndex={1}
                    placeholder={t('firstName')}
                    sx={{backgroundColor: 'background.body'}}
                    {...firstNameField}
                />
                <Input
                    variant="outlined"
                    color="neutral"
                    size="md"
                    autoCapitalize="off"
                    spellCheck="false"
                    fullWidth
                    required
                    tabIndex={2}
                    placeholder={t('lastName')}
                    sx={{backgroundColor: 'background.body'}}
                    {...lastNameField}
                />
            </Stack>

            <Button
                type="submit"
                color="primary"
                variant="solid"
                size="lg"
                disabled={!formState.isValid}
                tabIndex={3}
                endDecorator={(
                    <FontAwesomeIcon icon={faArrowRight} />
                )}
            >
                {t('simulateMyRevenue')}
            </Button>
        </Stack>
    );
});
