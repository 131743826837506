export const GET_MY_COMPANY = 'GET_MY_COMPANY';
export const STORE_MY_COMPANY = 'STORE_MY_COMPANY';
export const SET_IS_LOADING_MY_COMPANY = 'SET_IS_LOADING_MY_COMPANY';

export const getMyCompany = companyId => ({
    type: GET_MY_COMPANY,
    payload: {
        companyId,
    },
});

export const storeMyCompany = myCompany => ({
    type: STORE_MY_COMPANY,
    payload: myCompany,
});

export const setIsLoadingMyCompany = isLoading => ({
    type: SET_IS_LOADING_MY_COMPANY,
    payload: isLoading,
});
