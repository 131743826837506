import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getClockPointerUtilityClass = slot => {
    return generateUtilityClass('MuiClockPointer', slot);
};

export const clockPointerClasses = generateUtilityClasses('MuiClockPointer', [
    'root',
    'thumb',
]);
