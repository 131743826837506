import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

// TODO: unify this component
const LegalStatusAndTaxSystem = ({rowData, translate}) => {
    const typographySx = {
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        color: 'v2.light.text.main',
        textDecoration: 'none',
        fontSize: theme => theme.typography.pxToRem(14),
    };

    if (!rowData?.company) {
        return null;
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 0.5,
        }}
        >
            {!!rowData?.company.companyType && (
                <Typography sx={{...typographySx, fontWeight: 500}}>
                    {translate(`legalStatuses.${rowData?.company.companyType}`)}
                </Typography>
            )}
            {!!rowData?.company.taxSystem && (
                <Typography sx={typographySx}>
                    {translate(`taxSystems.${rowData?.company.taxSystem}`)}
                </Typography>
            )}
        </Box>
    );
};

LegalStatusAndTaxSystem.propTypes = {
    rowData: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

export default LegalStatusAndTaxSystem;
