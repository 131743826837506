import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import GestureIcon from '@mui/icons-material/Gesture';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import {LoadingButton} from '@mui/lab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {SIGNATURE_FILE_MAX_SIZE, SignatureUploadDropZone} from './SignatureUploadDropZone';
import {SIGNING_OPTIONS} from '../utils/constants';

export const SigningButtonSection = ({
    selectedSigningOption,
    setSelectedSigningOption,
    onChange,
    t,
    isLoading,
    handleSubmit,
    imagePreviewUrl,
    isPadEmpty,
    isMobileSize,
    isUploaded,
}) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        const newIsEnabled = (selectedSigningOption === SIGNING_OPTIONS.UPLOAD && imagePreviewUrl)
            || (selectedSigningOption === SIGNING_OPTIONS.DRAW && !isPadEmpty)
            || (selectedSigningOption === SIGNING_OPTIONS.QR && imagePreviewUrl && isMobileSize);
        setIsEnabled(newIsEnabled);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSigningOption, imagePreviewUrl, isPadEmpty]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: 1,
            width: isMobileSize ? '100%' : 'calc(100% - 400px - 12px)',
            color: '#546E7A',
            mt: isMobileSize ? 2 : 0,
            ml: isMobileSize ? 0 : 2,
        }}
        >
            <Box
                sx={{
                    cursor: 'pointer',
                    p: 2,
                    borderRadius: '8px',
                    width: '100%',
                    height: isMobileSize ? '100%' : '56px',
                    fontSize: theme => theme.typography.pxToRem(12),
                    textTransform: 'uppercase',
                    fontWeight: 500,
                    letterSpacing: '0.46px',
                    color: 'v2.blueGray.600',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    backgroundColor: selectedSigningOption === SIGNING_OPTIONS.DRAW ? 'rgba(25, 118, 210, 0.30)' : 'rgba(25, 118, 210, 0.08)',
                }}
                onClick={() => {
                    if (!isLoading) {
                        setSelectedSigningOption(SIGNING_OPTIONS.DRAW);
                    }
                }}
            >
                <GestureIcon sx={{
                    color: 'primary_v2.main',
                }}
                />
                {t('uploadSignatureModal.draw')}
            </Box>

            <SignatureUploadDropZone
                maxFiles={1}
                fileTypes=".jpg, .png, .jpeg"
                onChange={onChange}
                maxSize={SIGNATURE_FILE_MAX_SIZE.SIGNATURE}
            >
                <Box
                    sx={{
                        cursor: 'pointer',
                        p: 2,
                        borderRadius: '8px',
                        width: 'calc(100%)',
                        height: '56px',
                        fontSize: theme => theme.typography.pxToRem(12),
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        letterSpacing: '0.46px',
                        color: 'v2.blueGray.600',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        backgroundColor: selectedSigningOption === SIGNING_OPTIONS.UPLOAD ? 'rgba(25, 118, 210, 0.30)' : 'rgba(25, 118, 210, 0.08)',
                    }}
                    onClick={() => {
                        if (!isLoading) {
                            setSelectedSigningOption(SIGNING_OPTIONS.UPLOAD);
                        }
                    }}
                >
                    <UploadTwoToneIcon sx={{
                        color: 'primary_v2.main',
                    }}
                    />
                    {t('uploadSignatureModal.upload')}
                </Box>
            </SignatureUploadDropZone>

            {!isMobileSize && (
                <Box
                    disabled={true}
                    sx={{
                        cursor: 'default',
                        p: 2,
                        borderRadius: '8px',
                        width: '100%',
                        height: '56px',
                        fontSize: theme => theme.typography.pxToRem(12),
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        letterSpacing: '0.46px',
                        color: 'v2.blueGray.600',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        backgroundColor: selectedSigningOption === SIGNING_OPTIONS.QR ? 'rgba(25, 118, 210, 0.30)' : 'rgba(25, 118, 210, 0.08)',
                    }}
                    onClick={() => setSelectedSigningOption(SIGNING_OPTIONS.QR)}
                >
                    <CameraAltTwoToneIcon sx={{
                        color: 'primary_v2.main',
                    }}
                    />
                    {t('uploadSignatureModal.useQR')}
                </Box>
            )}

            {isMobileSize && (
                <Box
                    disabled={true}
                    sx={{
                        cursor: 'default',
                        p: 2,
                        borderRadius: '8px',
                        width: '100%',
                        height: '56px',
                        fontSize: theme => theme.typography.pxToRem(12),
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        letterSpacing: '0.46px',
                        color: 'v2.blueGray.600',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        backgroundColor: selectedSigningOption === SIGNING_OPTIONS.QR ? 'rgba(25, 118, 210, 0.30)' : 'rgba(25, 118, 210, 0.08)',
                    }}
                    onClick={() => {
                        setSelectedSigningOption(SIGNING_OPTIONS.QR);
                        inputRef.current.click();
                    }}
                >
                    <CameraAltTwoToneIcon sx={{
                        color: 'primary_v2.main',
                    }}
                    />
                    {t('uploadSignatureModal.useCamera')}

                    <Box sx={{
                        display: 'none',
                    }}
                    >
                        <input
                            ref={inputRef}
                            type="file"
                            accept="image/*"
                            capture="camera"
                            onChange={e => {
                                if (e.target.files && e.target.files[0]) {
                                    const files = e.target.files;
                                    onChange(files); // Handle the selected file (image)
                                }
                            }}
                        />
                    </Box>
                </Box>
            )}

            <Box>
                <LoadingButton
                    sx={{
                        mt: '14px',
                    }}
                    variant="contained"
                    fullWidth={true}
                    loading={isLoading}
                    startIcon={<CheckIcon />}
                    disabled={!isEnabled && !isUploaded}
                    color="secondary"
                    onClick={handleSubmit}
                    size="large"
                >
                    {t('uploadSignatureModal.continueButton')}
                </LoadingButton>
            </Box>
        </Box>
    );
};

SigningButtonSection.propTypes = {
    selectedSigningOption: PropTypes.string,
    setSelectedSigningOption: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    imagePreviewUrl: PropTypes.string,
    isPadEmpty: PropTypes.bool.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
    isUploaded: PropTypes.bool.isRequired,
};

SigningButtonSection.defaultProps = {
    selectedSigningOption: '',
    imagePreviewUrl: '',
};
