import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';
import {Box, Button, Divider, Paper, Typography} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import FullAddressAutocomplete from '../../../../../../components/address/FullAddressAutocomplete';
import {ButtonAction} from '../../../../../../components/buttons/ButtonAction';
import Yup from '../../../../../../lib/yup/yup';
import {
    HEADQUARTERS_DEFAULT_CITY,
    HEADQUARTERS_DEFAULT_COUNTRY_3,
    HEADQUARTERS_DEFAULT_STREET_NAME,
    HEADQUARTERS_DEFAULT_STREET_NUMBER,
    HEADQUARTERS_DEFAULT_ZIP_CODE,
} from '../../../../../../v1/utils/constants';
import {ZIP_CODE_REGEXES} from '../../../../../../v1/utils/zip-code-validation';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {SettingsSelectors} from '../../../../../settings/store/settings.selectors';
import {LoggedInUserSelectors} from '../../../../../user/modules/logged-in-user';
import {CardsActions} from '../../store/cards.actions';
import {CardsSelector} from '../../store/cards.selector';

const formElements = {
    country: 'country',
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    city: 'city',
    state: 'state',
    postalCode: 'postalCode',
};

const defaultValues = {
    [formElements.country]: 'FRA',
    [formElements.addressLine1]: '',
    [formElements.addressLine2]: '',
    [formElements.city]: '',
    [formElements.state]: '',
    [formElements.postalCode]: '',
};

const createValidationSchema = t => Yup.object().shape({
    addressLine1: Yup.string().max(38, t('setup:validations.maxMessage'))
        .required(t('setup:validations.isRequired', {what: t('cardCreate.addressLine1')}))
        .test('not-personal-address', t('cardCreate.personalAddressError'), (value, {parent: form}) => {
            // Check if address is headquarters address - 14 Rue Bausset, 75015 Paris, France
            return !((form.addressLine1.toLowerCase().trim() === `${HEADQUARTERS_DEFAULT_STREET_NUMBER} ${HEADQUARTERS_DEFAULT_STREET_NAME.toLowerCase()}`
                || form.addressLine1.toLowerCase().trim() === `${HEADQUARTERS_DEFAULT_STREET_NAME.toLowerCase()} ${HEADQUARTERS_DEFAULT_STREET_NUMBER}`)
                && form.city.trim() === HEADQUARTERS_DEFAULT_CITY
                && form.postalCode === HEADQUARTERS_DEFAULT_ZIP_CODE
                && form.country === HEADQUARTERS_DEFAULT_COUNTRY_3);
        }),
    addressLine2: Yup.string().max(38, t('setup:validations.maxMessage')),
    city: Yup.string().max(30, t('setup:validations.maxMessage'))
        .required(t('setup:validations.isRequired', {what: t('cardCreate.city')})),
    state: Yup.string().max(30, t('setup:validations.maxMessage')),
    postalCode: Yup.string().max(10, t('setup:validations.maxMessage'))
        .matches(ZIP_CODE_REGEXES.fr, t('form:validation.notValidZipCode'))
        .required(t('setup:validations.isRequired', {what: t('cardCreate.postalCode')})),
    country: Yup.string()
        .required(t('setup:validations.isRequired', {what: t('cardCreate.country')})),
});

export const BankCardCreateNewAddress = ({
    t,
    onSubmitAddress,
    onCancel,
    isMobileSize,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(CardsActions.getCountries());
    }, [dispatch]);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GENERIC_CRUD_LOADER),
    );
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const countryList = useSelector(CardsSelector.selectCountries);

    const userSettings = useSelector(SettingsSelectors.selectSettings);
    const language = userSettings?.language ?? 'fr';

    const {
        control,
        handleSubmit: createSubmitHandler,
        formState: {isValid, errors},
        setValue,
        trigger,
        watch,
    } = useForm({
        resolver: yupResolver(createValidationSchema(t)),
        defaultValues: defaultValues,
        mode: 'onChange',
    });

    const postalCode = watch(formElements.postalCode);
    const city = watch(formElements.city);

    const country = watch(formElements.country);
    const countryObject = countryList.find(item => item.alpha3 === country);

    const handleSubmit = data => {
        onSubmitAddress(
            {
                ...data,
                [formElements.addressLine2]: data.addressLine2 && data.addressLine2 !== ''
                    ? data.addressLine2 : undefined,
                [formElements.state]: data.state && data.state !== ''
                    ? data.state : undefined,
            },
        );
    };

    const onPlaceDetailsChange = data => {
        setValue(formElements.addressLine1, `${data.street} ${data.streetNumber ?? ''}`);
        setValue(formElements.city, data.city);
        setValue(formElements.postalCode, data.zipCode);
        setValue(formElements.country, country);
        setValue(formElements.state, data.region);
        setValue('search', undefined);
        trigger();
    };

    useEffect(() => {
        if (city && postalCode && country) {
            trigger(formElements.addressLine1);
        }
    }, [city, postalCode, country, trigger]);

    const onAddressChange = () => {};

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
        >
            <Paper
                sx={{
                    p: isMobileSize ? 0 : 1,
                    borderRadius: '16px',
                }}
                elevation={0}
            >
                <form onSubmit={createSubmitHandler(handleSubmit)}>
                    <Typography
                        sx={{
                            textAlign: 'center',
                        }}
                        variant={isMobileSize ? 'h5' : 'h4'}
                    >
                        {t('cardCreate.newAddressTitle')}
                    </Typography>

                    <Typography sx={{
                        fontWeight: 500,
                        mb: 1.5,
                        mt: 3,
                    }}
                    >
                        {t('cardCreate.findAnAddress')}
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        width: '100%',
                        flexDirection: 'column',
                    }}
                    >
                        <Controller
                            control={control}
                            name={formElements.country}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    label={t('cardCreate.countryLabel')}
                                    error={!!errors[formElements.country]}
                                    helperText={errors[formElements.country]?.message}
                                    fullWidth
                                    select
                                >
                                    {countryList?.length > 0
                                        ? countryList.map(country => {
                                            return (
                                                <MenuItem
                                                    value={country.alpha3}
                                                    key={country.alpha3}
                                                >
                                                    {country.translation ? country.translation[language] : country.name}
                                                </MenuItem>
                                            );
                                        })
                                        : (
                                            <MenuItem
                                                value="FRA"
                                                key="FRA"
                                            >
                                                France
                                            </MenuItem>
                                        )}
                                </TextField>
                            )}
                        />

                        <FullAddressAutocomplete
                            key={countryObject?.alpha2 ?? 'fr'}
                            onPlaceDetailsChange={onPlaceDetailsChange}
                            onAddressChange={onAddressChange}
                            componentRestrictions={countryObject ? {country: countryObject.alpha2} : {country: 'fr'}}
                            renderInput={params => (
                                <Controller
                                    name="search"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            error={!!errors.search}
                                            helperText={errors.search?.message}
                                            value={field.value}
                                            label={t('cardCreate.findAnAddress')}
                                            autoComplete="false"
                                            {...params}
                                            {...field}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: <SearchIcon />,
                                                endAdornment: null,
                                            }}
                                            disabled={false}
                                        />
                                    )}
                                />
                            )}
                            address=""
                        />
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mt: 4,
                        width: '100%',
                    }}
                    >
                        <Typography sx={{
                            fontWeight: 500,
                            mb: -1,
                        }}
                        >
                            {t('cardCreate.enterAddressLabel')}
                        </Typography>

                        <Controller
                            control={control}
                            name={formElements.addressLine1}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    label={t('cardCreate.addressLine1')}
                                    error={!!errors[formElements.addressLine1]}
                                    helperText={errors[formElements.addressLine1]?.message}
                                    fullWidth
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name={formElements.addressLine2}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    label={t('cardCreate.addressLine2')}
                                    error={!!errors[formElements.addressLine2]}
                                    helperText={errors[formElements.addressLine2]?.message}
                                    fullWidth
                                />
                            )}
                        />

                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                        }}
                        >
                            <Controller
                                control={control}
                                name={formElements.state}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        label={t('cardCreate.stateLabel')}
                                        error={!!errors[formElements.state]}
                                        helperText={errors[formElements.state]?.message}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name={formElements.city}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        label={t('cardCreate.city')}
                                        error={!!errors[formElements.city]}
                                        helperText={errors[formElements.city]?.message}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name={formElements.postalCode}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        label={t('cardCreate.postalCode')}
                                        error={!!errors[formElements.postalCode]}
                                        helperText={errors[formElements.postalCode]?.message}
                                    />
                                )}
                            />
                        </Box>

                        <TextField
                            value={`${loggedInUser.firstName} ${loggedInUser.lastName}`}
                            variant="outlined"
                            label={t('cardCreate.fullName')}
                            disabled={true}
                            fullWidth
                        />
                    </Box>

                    <Divider
                        sx={{
                            mt: 3,
                        }}
                    />

                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        gap: 2,
                        mt: 3,
                        flexDirection: isMobileSize ? 'column-reverse' : 'row',
                    }}
                    >
                        <Button
                            type="button"
                            variant="contained"
                            color="grey"
                            fullWidth
                            startIcon={<ArrowBackIcon />}
                            onClick={onCancel}
                        >
                            {t('cardCreate.cancelAddressReceiveType')}
                        </Button>

                        <ButtonAction
                            color="secondary"
                            isLoading={isLoading}
                            disabled={!isValid}
                            fullWidth
                            endIcon={<ArrowForwardIcon />}
                            type="submit"
                        >
                            {t('cardCreate.confirmAddressReceiveType')}
                        </ButtonAction>
                    </Box>
                </form>
            </Paper>

        </Box>
    );
};

BankCardCreateNewAddress.propTypes = {
    t: PropTypes.func.isRequired,
    onSubmitAddress: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
};
