import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import {useRef, useState} from 'react';
import {Document, Page} from 'react-pdf';
import TDFCanvas from '../../../../../../../../lib/tdf-canvas/TDFCanvas';

const OCRPreviewPdf = ({
    url,
    documentHighlights,
    onUpdateDocumentSize,
}) => {
    const [pageNumbers, setPageNumbers] = useState(null);
    const [outerWidth, setOuterWidth] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const containerRef = useRef();
    const [documentSize, setDocumentSize] = useState({width: 0, height: 0});

    const handleDocumentLoadSuccess = async pdfObject => {
        const {numPages} = pdfObject;
        const allPageNumbers = [];

        for (let p = 1; p < numPages + 1; p += 1) {
            allPageNumbers.push(p);
        }

        const page = await pdfObject.getPage(1);

        setPageNumbers(allPageNumbers);
        setOuterWidth(containerRef.current?.offsetWidth ?? 0);

        const [, , width, height] = page.view;
        if (onUpdateDocumentSize) {
            onUpdateDocumentSize(width, height);
        }

        setDocumentSize({width, height});
        setIsLoading(false);
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    boxShadow: '0px 5px 5px -3px rgba(11, 35, 51, 0.08), 0px 8px 10px 1px rgba(11, 35, 51, 0.08), 0px 3px 14px 2px rgba(11, 35, 51, 0.1)',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    background: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    aignItems: 'center',
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <Document
                    file={url}
                    onLoadStart
                    onLoadSuccess={handleDocumentLoadSuccess}
                    externalLinkTarget="_blank"
                    options={{workerSrc: 'pdf.worker.min.js'}}
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        {/*
                          * TODO: For the MVP we want to display first page only.
                          * Remove this wierd array transformation once we're supporting multipage documents
                        */}
                        {pageNumbers && [pageNumbers[0]].map(pageNumber => (
                            <Page
                                key={pageNumber}
                                width={outerWidth ? outerWidth - 17 : (containerRef.current?.offsetWidth ?? 0)}
                                pageNumber={pageNumber}
                                renderTextLayer={false}
                                renderAnnotationLayer={true}
                                loading={() => null}
                            />
                        ))}
                        {!isLoading && (
                            <TDFCanvas
                                shapes={documentHighlights}
                                width={documentSize.width}
                                height={documentSize.height}
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    pointerEvents: 'none',
                                    zIndex: 999,
                                }}
                            />
                        )}
                    </Box>
                </Document>
            </Box>
        </Box>
    );
};

OCRPreviewPdf.propTypes = {
    documentHighlights: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
    onUpdateDocumentSize: PropTypes.func.isRequired,
};

export default OCRPreviewPdf;
