import BusinessIcon from '@mui/icons-material/Business';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const ClientName = ({rowData}) => {
    const {clientName} = rowData;

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}
        >
            <BusinessIcon sx={{
                color: 'v2.blueGray.100',
                fontSize: theme => theme.typography.pxToRem(16),
                mr: 1,
            }}
            /> {clientName}
        </Box>
    );
};

ClientName.propTypes = {
    rowData: PropTypes.any.isRequired,
};

export default React.memo(ClientName);
