/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {styled} from '@mui/system';
import {
    unstable_capitalize as capitalize,
    unstable_useForkRef as useForkRef,
} from '@mui/utils';
import clsx from 'clsx';
import * as React from 'react';
import {
    getPickersYearUtilityClass,
    pickersYearClasses,
} from './pickersYearClasses';
import {WrapperVariantContext} from '../internals/components/wrappers/WrapperVariantContext';

const useUtilityClasses = ownerState => {
    const {wrapperVariant, disabled, selected, classes} = ownerState;

    const slots = {
        root: ['root', wrapperVariant && `mode${capitalize(wrapperVariant)}`],
        yearButton: ['yearButton', disabled && 'disabled', selected && 'selected'],
    };

    return composeClasses(slots, getPickersYearUtilityClass, classes);
};

const PickersYearRoot = styled('div', {
    name: 'PrivatePickersYear',
    slot: 'Root',
    overridesResolver: (_, styles) => [
        styles.root,
        {[`&.${pickersYearClasses.modeDesktop}`]: styles.modeDesktop},
        {[`&.${pickersYearClasses.modeMobile}`]: styles.modeMobile},
    ],
})(({ownerState}) => ({
    flexBasis: '33.3%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(ownerState?.wrapperVariant === 'desktop' && {
        flexBasis: '25%',
    }),
}));

const PickersYearButton = styled('button', {
    name: 'PrivatePickersYear',
    slot: 'Button',
    overridesResolver: (_, styles) => [
        styles.button,
        {[`&.${pickersYearClasses.disabled}`]: styles.disabled},
        {[`&.${pickersYearClasses.selected}`]: styles.selected},
    ],
})(({theme}) => ({
    'backgroundColor': 'transparent',
    'border': 0,
    'outline': 0,
    ...theme.typography['body-md'],
    'color': theme.palette.text.secondary,
    'margin': '8px 0',
    'height': 36,
    'width': 72,
    'borderRadius': 18,
    'cursor': 'pointer',
    '&:focus, &:hover': {
        backgroundColor: theme.palette.neutral.softHoverBg,
    },
    [`&.${pickersYearClasses.disabled}`]: {
        pointerEvents: 'none',
        color: theme.palette.text.tertiary,
    },
    [`&.${pickersYearClasses.selected}`]: {
        'color': theme.palette.primary.solidColor,
        'backgroundColor': theme.palette.neutral.solidActiveBg,
        '&:focus, &:hover': {
            backgroundColor: theme.palette.neutral.plainColor,
        },
    },
}));

const noop = () => {};

export const PickersYear = React.forwardRef((
    props,
    forwardedRef,
) => {
    // TODO v6: add 'useThemeProps' once the component class names are aligned
    const {
        autoFocus,
        className,
        children,
        disabled,
        onClick,
        onKeyDown,
        value,
        tabIndex,
        onFocus = noop,
        onBlur = noop,
        ...other
    } = props;
    const ref = React.useRef(null);
    const refHandle = useForkRef(ref, forwardedRef);
    const wrapperVariant = React.useContext(WrapperVariantContext);

    const ownerState = {
        ...props,
        wrapperVariant,
    };

    const classes = useUtilityClasses(ownerState);

    // We can't forward the `autoFocus` to the button because it is a native button, not a MUI Button
    React.useEffect(() => {
        if (autoFocus) {
            // `ref.current` being `null` would be a bug in MUI.
            ref.current.focus();
        }
    }, [autoFocus]);

    return (
        <PickersYearRoot
            data-mui-test="year"
            className={clsx(classes.root, className)}
            ownerState={ownerState}
        >
            <PickersYearButton
                ref={refHandle}
                disabled={disabled}
                type="button"
                data-mui-test={`year-${children}`}
                tabIndex={disabled ? -1 : tabIndex}
                onClick={event => onClick(event, value)}
                onKeyDown={event => onKeyDown(event, value)}
                onFocus={event => onFocus(event, value)}
                onBlur={event => onBlur(event, value)}
                className={classes.yearButton}
                ownerState={ownerState}
                {...other}
            >
                {children}
            </PickersYearButton>
        </PickersYearRoot>
    );
});
