import i18n from 'i18next';
import camelcase from 'lodash.camelcase';
import {initReactI18next} from 'react-i18next';
import {createYupLocale} from './yup/yup';

const SUPPORTED_LANGUAGES = [
    'en',
    'fr',
];

i18n
    .use(initReactI18next)
    .init({
        partialBundledLanguages: true,
        ns: [],
        fallbackLng: 'fr',
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    }).then(t => createYupLocale(t));

/**
 * @param namespaceKey
 * @param callback
 */
export const importLocaleBundle = (namespaceKey, callback = null) => {
    SUPPORTED_LANGUAGES.forEach(language => {
        const namespace = require(`../locales/${language}/${namespaceKey}.json`);
        i18n.addResourceBundle(language, camelcase(namespaceKey), namespace);
    });

    if (typeof callback === 'function') {
        callback(i18n.t);
    }
};

export {i18n};

