import {Paper, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const PaperWrapper = ({children, header, elevation, sx}) => {
    return (
        <Paper
            elevation={elevation}
            sx={{
                borderRadius: '16px',
                p: 3,
                pt: 2,
                ...sx,
            }}
        >
            {header && (
                <Typography sx={{fontWeight: 500, mb: 2}}>
                    {header}
                </Typography>
            )}
            {children}
        </Paper>
    );
};

PaperWrapper.propTypes = {
    sx: PropTypes.object,
    header: PropTypes.string,
    elevation: PropTypes.number,
};

PaperWrapper.defaultProps = {
    sx: {},
    header: '',
    elevation: 0,
};

export default PaperWrapper;
