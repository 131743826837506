import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import CapitolDepositBankKYCValidation from './bank/CapitalDepositBankKycValidation';
import CapitolDepositBankFundTransfer from './bank/CapitolDepositBankFundTransfer';
import CapitolDepositBankIndentityValidation from './bank/CapitolDepositBankIndentityValidation';
import CapitolDepositBankSendInformation from './bank/CapitolDepositBankSendInformation';
import CapitolDepositValidation from './bank/CapitolDepositValidation';
import CapitalDepositDocumentsRefused from './bank/validation/CapitalDepositDocumentsRefused';
import CapitalDepositWaitingApproval from './bank/validation/CapitalDepositWaitingApproval';
import CapitalDepositWaitingNotaryDeposit from './bank/validation/CapitalDepositWaitingNotaryDeposit';
import {DocumentUpload} from './DocumentUpload';
import {InitialStep} from './InitialStep';
import {PageHeader} from '../../../../../components/page-header/PageHeader';
import {BankSelector} from '../../../../bank/store/bank.selector';
import {UploadSignatureModal} from '../../../../signature/components/UploadSignatureModal';
import {OnboardingSelectors} from '../../onboarding/store/onboarding.selectors';
import {CapitalDepositInternalSubSteps, CapitalDepositStatus} from '../utils/constants';

export const CapitalDeposit = () => {
    const {t} = useTranslation('freelancerOnboarding');

    // TODO: remove this hack!
    // maybe it should be a separate internalSubStep
    const [forceShowStep, setForceShowStep] = useState('');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const internalSubStep = useSelector(OnboardingSelectors.selectInternalSubStep);
    const integrations = useSelector(BankSelector.selectIntegrations);

    const deposit = useSelector(BankSelector.selectCapitalDeposit);

    const hasIntegrations = integrations && (Array.isArray(integrations) && integrations.length > 0);

    let stepComponent = (
        <InitialStep hasIntegrations={hasIntegrations} />
    );

    if (integrations?.[0]) {
        if (internalSubStep === CapitalDepositInternalSubSteps.BANK_KYC_VALIDATION) {
            return (
                <CapitolDepositBankKYCValidation />
            );
        }

        if (internalSubStep === CapitalDepositInternalSubSteps.BANK_SEND_INFORMATION) {
            return (
                <CapitolDepositBankSendInformation />
            );
        }

        if (internalSubStep === CapitalDepositInternalSubSteps.BANK_IDENTITY_VALIDATION) {
            return (
                <CapitolDepositBankIndentityValidation />
            );
        }

        if (internalSubStep === CapitalDepositInternalSubSteps.BANK_FUND_TRANSFER) {
            return (
                <CapitolDepositBankFundTransfer />
            );
        }

        if (internalSubStep === CapitalDepositInternalSubSteps.BANK_CAPITOL_DEPOSIT_VALIDATION) {
            if (deposit?.status === CapitalDepositStatus.WAITING_FOR_REQUIREMENTS_APPROVAL
                || forceShowStep === CapitalDepositStatus.WAITING_FOR_REQUIREMENTS_APPROVAL) {
                return (
                    <CapitalDepositWaitingApproval />
                );
            }

            if (deposit?.status === CapitalDepositStatus.UPLOADED_DOCUMENTS_REFUSED) {
                return (
                    <CapitalDepositDocumentsRefused setForceShowStep={setForceShowStep} />
                );
            }

            if (deposit?.status === CapitalDepositStatus.WAITING_FOR_NOTARY_DEPOSIT_CERTIFICATE) {
                return (
                    <CapitalDepositWaitingNotaryDeposit />
                );
            }

            return (
                <CapitolDepositValidation />
            );
        }
    }

    if (internalSubStep === CapitalDepositInternalSubSteps.DEPOSIT_DOCUMENTATION_UPLOAD) {
        stepComponent = <DocumentUpload />;
    }

    if (deposit
        && (deposit?.status === CapitalDepositStatus.WAITING_FOR_PROOF_OF_REGISTRATION_UPLOAD
        || deposit?.status === CapitalDepositStatus.WAITING_FOR_NOTARY_TRANSFER
        || deposit?.status === CapitalDepositStatus.COMPLETED)
    ) {
        return (
            <CapitolDepositValidation />
        );
    }

    return (
        <Box display="grid" gridTemplateRows="min-content 1fr">
            {!isMobileSize && (
                <PageHeader>
                    {t('capitalDepositStep1.title')}
                </PageHeader>
            )}

            {stepComponent}

            <UploadSignatureModal />
        </Box>
    );
};
