export const TransfersActionType = {
    STORE_LIST: 'transfers/STORE_LIST',
    GET_TRANSFER: 'transfer/GET_TRANSFER',
    STORE_TRANSFER: 'transfer/STORE_TRANSFER',
    CREATE: 'transfers/CREATE',
    UPDATE: 'transfers/UPDATE',
    CANCEL: 'transfers/CANCEL',
    UPLOAD_TRANSFER_FILE: 'transfers/UPLOAD_TRANSFER_FILE',
    DELETE_TRANSFER_FILE: 'transfers/DELETE_TRANSFER_FILE',
    STORE_REMUNERATION: 'transfers/STORE_REMUNERATION',
};
