export const BANK_ONBOARDING_STATUS = {
    FINALIZED: 'FINALIZED',
    INVALID: 'INVALID',
    VALID: 'VALID',
};

export const VerificationStatus = {
    'NOT_STARTED': 'NOT_STARTED',
    'WAITING_FOR_VERIFICATION': 'WAITING_FOR_VERIFICATION',
    'PENDING': 'PENDING',
    'VERIFIED': 'VERIFIED',
    'REFUSED': 'REFUSED',
};

export const CapitalDepositDocumentStatus = {
    PENDING: 'PENDING',
    UPLOADED: 'UPLOADED',
    VALIDATED: 'VALIDATED',
    REFUSED: 'REFUSED',
};

export const LANDING_PAGE_MODE = {
    GENERIC: 'GENERIC',
    QONTO: 'QONTO',
    TRANSFERS: 'TRANSFERS',
    CARDS: 'CARDS',
    DIRECT_DEBIT: 'DIRECT_DEBIT',
};

export const LANDING_PAGE_BORDER_RADIUS = '16px';
