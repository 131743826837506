export const STORE_CHALLENGE_NAME = 'STORE_CHALLENGE_NAME';
export const SUBMIT_NEW_PASSWORD_FORM = 'SUBMIT_NEW_PASSWORD_FORM';
export const STORE_USER = 'STORE_USER';
export const STORE_CURRENT_COGNITO_USER = 'STORE_CURRENT_COGNITO_USER';
export const LOGOUT = 'LOGOUT';

export const submitNewPassword = newPassword => ({
    type: SUBMIT_NEW_PASSWORD_FORM,
    payload: {
        newPassword,
    },
});

export const logout = () => ({
    type: LOGOUT,
});

export const storeChallengeName = challengeName => ({
    type: STORE_CHALLENGE_NAME,
    payload: challengeName,
});

export const storeUser = user => ({
    type: STORE_USER,
    payload: user,
});

export const storeCurrentCognitoUser = cognitoUser => ({
    type: STORE_CURRENT_COGNITO_USER,
    payload: cognitoUser,
});
