export const DOCS_TYPE = {
    ARTICLE: 'article',
    VIDEO: 'video',
    FAQ: 'faq',
};

export const DOCS_LEVEL = {
    CATEGORY: 'CATEGORY',
    LIST: 'LIST',
    DETAILS: 'DETAILS',
};

export const CATEGORY_DEFAULT_ICON = 'folder';

export const EMPTY_SEARCH_RESULTS = {
    [DOCS_TYPE.ARTICLE]: [],
    [DOCS_TYPE.VIDEO]: [],
    [DOCS_TYPE.FAQ]: [],
};

export const VOTE_VALUE = {
    GOOD: 'good',
    BAD: 'bad',
};

export const MAX_OTHER_AND_POPULAR_ITEMS = 6;
