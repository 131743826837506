import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {BankCardLimitSection} from './BankCardLimitSection';
import BankCardSidebarActions from './BankCardSidebarActions';

export const BankSidebarContent = ({
    selectedCard,
    t,
    card,
    setOpenSettings,
    lockCard,
    isCardLockLoading,
    unlockCard,
    showNumbers,
    viewPin,
}) => {
    return (
        <Box key={selectedCard}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
            >
                <BankCardLimitSection
                    t={t}
                    card={card}
                    setOpenSettings={setOpenSettings}
                />

                <BankCardSidebarActions
                    setOpenSettings={setOpenSettings}
                    viewPin={viewPin}
                    t={t}
                    isCardLockLoading={isCardLockLoading}
                    showNumbers={showNumbers}
                    unlockCard={unlockCard}
                    lockCard={lockCard}
                    selectedCard={selectedCard}
                    card={card}
                />
            </Box>
        </Box>
    );
};

BankSidebarContent.propTypes = {
    selectedCard: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    card: PropTypes.object.isRequired,
    setOpenSettings: PropTypes.func.isRequired,
    lockCard: PropTypes.func.isRequired,
    isCardLockLoading: PropTypes.bool,
    unlockCard: PropTypes.func.isRequired,
    showNumbers: PropTypes.func.isRequired,
    viewPin: PropTypes.func.isRequired,
};

BankSidebarContent.defaultProps = {
    isCardLockLoading: false,
};
