/* eslint-disable react/prop-types */
import {unstable_useForkRef as useForkRef} from '@mui/utils';
import * as React from 'react';
import {WrapperVariantContext} from './WrapperVariantContext';
import {PickersPopper} from '../PickersPopper';

export const DesktopWrapper = props => {
    const {
        children,
        DateInputProps,
        KeyboardDateInputComponent,
        onClear,
        onDismiss,
        onCancel,
        onAccept,
        onSetToday,
        open,
        PopperProps,
        PaperProps,
        TransitionComponent,
        components,
        componentsProps,
    } = props;
    const ownInputRef = React.useRef(null);
    const inputRef = useForkRef(DateInputProps.inputRef, ownInputRef);

    return (
        <WrapperVariantContext.Provider value="desktop">
            <KeyboardDateInputComponent {...DateInputProps} inputRef={inputRef} />
            <PickersPopper
                role="dialog"
                open={open}
                anchorEl={ownInputRef.current}
                TransitionComponent={TransitionComponent}
                PopperProps={PopperProps}
                PaperProps={PaperProps}
                onClose={onDismiss}
                onCancel={onCancel}
                onClear={onClear}
                onAccept={onAccept}
                onSetToday={onSetToday}
                components={components}
                componentsProps={componentsProps}
            >
                {children}
            </PickersPopper>
        </WrapperVariantContext.Provider>
    );
};
