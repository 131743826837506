import {Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonAction} from '../../../../../../components/buttons/ButtonAction';
import {ModalWrapper} from '../../../../../../components/modal-wrapper/ModalWrapper';
import {UiActions} from '../../../../../ui/store/ui.action';
import {UiSelectors} from '../../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../../ui/utils/constants';

const modalKey = ModalsKeys.BANK_NO_FILE;

const TransactionDetailsNoFileModal = () => {
    const dispatch = useDispatch();

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const {t} = useTranslation('bank');

    const data = useSelector(UiSelectors.createModalDataSelector(modalKey));

    const onConfirm = () => {
        data.action();
        setTimeout(() => {
            dispatch(UiActions.setActiveModal(modalKey, false));
        }, 100);
    };

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {(t('noFileModal.title'))}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <ButtonAction
                        onClick={handleClose}
                        color="grey"
                        fullWidth={isMobileSize}
                    >
                        {t('noFileModal.close')}
                    </ButtonAction>

                    <ButtonAction
                        color="secondary"
                        onClick={onConfirm}
                        fullWidth={isMobileSize}
                    >
                        {t('noFileModal.submit')}
                    </ButtonAction>
                </>
            )}
            containerSx={{width: '583px'}}
            buttonsSx={{
                justifyContent: isMobileSize ? 'space-between' : 'flex-end',
                flexDirection: isMobileSize ? 'column' : 'row',
            }}
        >
            <Typography>
                {(t('noFileModal.text'))}
            </Typography>
        </ModalWrapper>
    );
};

export default TransactionDetailsNoFileModal;
