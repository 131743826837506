import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {LoadingButton} from '@mui/lab';
import {Box, Grid, TextField, Typography} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Checkbox} from '../../../../../../components/checkbox/Checkbox';
import Yup from '../../../../../../lib/yup/yup';
import ConfirmationDialog from '../../../../../../v1/components/ui-kit/ConfirmationDialog/ConfirmationDialog';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {BeneficiariesActions} from '../../store/beneficiaries.actions';
import {BeneficiariesSelector} from '../../store/beneficiaries.selector';

const formElements = {
    iban: 'iban',
    label: 'label',
    isPersonal: 'isPersonal',
};

const defaultValues = {
    [formElements.label]: '',
    [formElements.iban]: '',
    [formElements.isPersonal]: false,
};

const createValidationSchema = t => Yup.object().shape({
    [formElements.label]: Yup.string().label(t('beneficiaries.form.invalidName')).required(),
    [formElements.iban]: Yup.string().iban(t('beneficiaries.form.ibanInvalid')).required(),
});

const BeneficiariesSidebar = ({information, onClose}) => {
    const {t} = useTranslation('bankTransfer');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const dispatch = useDispatch();
    const timeout = useRef();

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.BENEFICIARIES_UPDATE),
    ) && !information.id;
    const hasConflict = useSelector(BeneficiariesSelector.selectIbanConflict);
    const beneficiariesList = useSelector(BeneficiariesSelector.selectList);

    const hasPersonalDisabled = beneficiariesList.find(item => item.isPersonal && item.id !== information?.id);

    const {
        control,
        handleSubmit: createSubmitHandler,
        formState: {isValid, errors, isDirty},
        watch,
        reset,
        setError,
    } = useForm({
        resolver: yupResolver(createValidationSchema(t)),
        defaultValues: information.id ? {
            [formElements.label]: information.label,
            [formElements.iban]: information.iban,
            [formElements.isPersonal]: information.isPersonal,
        } : defaultValues,
        mode: 'onChange',
    });

    const iban = watch(formElements.iban);
    const label = watch(formElements.label);
    const isPersonal = watch(formElements.isPersonal);

    useEffect(() => {
        if (information?.id && isValid && isDirty) {
            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                dispatch(BeneficiariesActions.update({
                    [formElements.iban]: iban,
                    [formElements.label]: label,
                    [formElements.isPersonal]: isPersonal,
                    id: information.id,
                }));

                // Needed to enter correct isDirty value on change to default value
                reset({
                    [formElements.label]: label,
                    [formElements.iban]: iban,
                    [formElements.isPersonal]: isPersonal,
                });
            }, 300);
        }
        return () => {
            clearTimeout(timeout.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iban, label, isPersonal, isValid, isDirty]);

    useEffect(() => {
        if (hasConflict) {
            setError('iban', {
                type: 'custom',
                message: t('beneficiaries.form.ibanConflict'),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasConflict]);

    const handleSubmit = data => {
        if (!information.id) {
            dispatch(BeneficiariesActions.create(data));
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: isMobileSize ? '100%' : 'calc(100vh - 24px)',
            px: 3,
            pt: isMobileSize ? 2 : 0,
            pb: isMobileSize ? 3 : 0,
        }}
        >
            <Box>
                {!isMobileSize ? (
                    <Typography sx={{
                        mt: 10,
                        mb: 2,
                        textAlign: 'center',
                        fontWeight: 700,
                        fontSize: theme => theme.typography.pxToRem(21),
                    }}
                    >
                        {information.id ? t('beneficiaries.form.titleUpdate') : t('beneficiaries.form.titleCreate')}
                    </Typography>
                ) : (
                    <Typography sx={{
                        fontWeight: 500,
                        mb: 1.4,
                    }}
                    >
                        {t('beneficiaries.form.titleMobile')}
                    </Typography>
                )}

                <form onSubmit={createSubmitHandler(handleSubmit)}>
                    <Grid item xs={12} lg={6}>
                        <Controller
                            control={control}
                            name="label"
                            render={({field}) => (
                                <TextField
                                    variant="outlined"
                                    label={t('beneficiaries.form.label')}
                                    disabled={isLoading}
                                    error={!!errors[formElements.label]}
                                    helperText={errors[formElements.label]?.message}
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} lg={6} sx={{mt: 2}}>
                        <Controller
                            control={control}
                            name="iban"
                            render={({field}) => (
                                <TextField
                                    variant="outlined"
                                    label={t('beneficiaries.form.iban')}
                                    disabled={isLoading}
                                    error={!!errors[formElements.iban]}
                                    helperText={errors[formElements.iban]?.message}
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} lg={6} sx={{mt: 2}}>
                        <Controller
                            control={control}
                            name={formElements.isPersonal}
                            render={({field}) => (
                                <FormControlLabel
                                    sx={{m: 0, pt: 1}}
                                    label={t('beneficiaries.form.myPersonalAccount')}
                                    control={(
                                        <Checkbox
                                            sx={{pl: 1, py: 0}}
                                            checked={field.value}
                                            color="primary"
                                        />
)}
                                    onChange={event => field.onChange(event?.target?.checked)}
                                    disabled={isLoading || hasPersonalDisabled}
                                />
                            )}
                        />
                    </Grid>

                    {!information.id && (
                    <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            color="secondary"
                            size="large"
                            fullWidth
                            startIcon={<PersonAddIcon />}
                            disabled={isLoading || !isValid}
                            loading={isLoading}
                            loadingPosition="start"
                        >
                            {t('beneficiaries.form.submit')}
                        </LoadingButton>
                    </Box>
                    )}
                </form>
            </Box>

            {information.id && !isMobileSize && (
                <BeneficiariesDelete
                    information={information}
                    onClose={onClose}
                />
            )}
        </Box>
    );
};

BeneficiariesSidebar.propTypes = {
    information: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export const BeneficiariesDelete = ({information, onClose}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bankTransfer');
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.BENEFICIARIES_DELETE),
    );

    const handleDeleteConfirmed = e => {
        e.preventDefault();
        dispatch(BeneficiariesActions.delete({id: information.id}));
        setIsConfirmationDialogOpen(false);
        setTimeout(() => {
            onClose();
        }, 300);
    };

    if (!information.id) {
        return null;
    }

    return (
        <>
            <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                <LoadingButton
                    onClick={() => setIsConfirmationDialogOpen(true)}
                    variant="outlined"
                    type="submit"
                    color="secondary"
                    size="large"
                    fullWidth
                    startIcon={<DeleteForeverIcon />}
                    disabled={!!isLoading}
                    loading={!!isLoading}
                    loadingPosition="start"
                >
                    {t('beneficiaries.form.delete')}
                </LoadingButton>

                <ConfirmationDialog
                    onCancel={() => setIsConfirmationDialogOpen(false)}
                    onConfirm={handleDeleteConfirmed}
                    isOpen={isConfirmationDialogOpen}
                    title={t('beneficiaries.form.deleteConfirmationTitle')}
                    message={t('beneficiaries.form.deleteConfirmationMessage')}
                />
            </Box>
        </>
    );
};

BeneficiariesDelete.propTypes = {
    information: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default BeneficiariesSidebar;
