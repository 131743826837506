import {Skeleton, TableCell, TableRow, Typography} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import {useDataTableLoaderStyles} from './styles';

const DataTableLoader = ({numberOfRows, hasCheckboxes, columnConfig}) => {
    const styles = useDataTableLoaderStyles();

    return Array(numberOfRows)
        .fill(null)
        .map((row, index) => {
            return (
                <TableRow key={index}>
                    {hasCheckboxes && (
                        <TableCell className={styles.checkboxTableCell} />
                    )}

                    {columnConfig.map(column => (
                        <TableCell
                            key={column.key}
                            className={styles.tableCell}
                            style={{width: column.width || 'auto'}}
                            {...(column.additionalCellProps || {})}
                        >
                            <Skeleton className={styles.skeleton}>
                                <Typography>
                                    {column?.componentProps?.isDate
                                        ? moment().format(column?.componentProps?.dateFormat || 'L')
                                        : column.title}
                                </Typography>
                            </Skeleton>
                        </TableCell>
                    ))}
                </TableRow>
            );
        });
};

DataTableLoader.propTypes = {
    columnConfig: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        title: PropTypes.string.isRequired,
        additionalCellProps: PropTypes.object,
    })).isRequired,
    numberOfRows: PropTypes.number.isRequired,
    hasCheckboxes: PropTypes.bool,
};

DataTableLoader.defaultProps = {
    hasCheckboxes: false,
};

export default DataTableLoader;
