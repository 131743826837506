import {SvgIcon} from '@mui/material';

export const FinanceViewIcon = props => (
    <SvgIcon viewBox="0 0 25 24" {...props}>
        <path
            d="M9.01172 12.9577V6.34619H11.3192V12.9577L10.1655 11.8459L9.01172 12.9577ZM13.6847 14.8229V2.34619H15.9922V12.5154L13.6847 14.8229ZM4.31947 17.6192V10.3462H6.62697V15.3114L4.31947 17.6192ZM4.26172 20.7999L10.1347 14.9269L13.6847 17.9769L19.8617 11.7999H17.9155V10.2999H22.4155V14.7999H20.9155V12.8537L13.7462 20.0229L10.1962 16.9729L6.36922 20.7999H4.26172Z"
            fill="currentColor"
        />
    </SvgIcon>
);
