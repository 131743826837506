export const SECTION_STATUS = {
    REQUIRED: 'REQUIRED',
    OPTIONAL: 'OPTIONAL',
    WAITING_FOR_CATEGORY: 'WAITING_FOR_CATEGORY',
    WAITING_FOR_FILE: 'WAITING_FOR_FILE',
    NON_EDITABLE: 'NON_EDITABLE',
    NO_INFORMATION_EXPECTED: 'NO_INFORMATION_EXPECTED',
};

export const DRAG_ITEMS = {
    REIMBURSEMENT: 'REIMBURSEMENT',
    INVOICE: 'INVOICE',
};
