/* eslint-disable react/prop-types */
import {Button, DialogActions} from '@mui/joy';
import * as React from 'react';
import {WrapperVariantContext} from '../internals/components/wrappers/WrapperVariantContext';
import {useLocaleText} from '../internals/hooks/useUtils';

export const PickersActionBar = props => {
    const {onAccept, onClear, onCancel, onSetToday, actions, ...other} = props;
    const wrapperVariant = React.useContext(WrapperVariantContext);

    const localeText = useLocaleText();

    const actionsArray = typeof actions === 'function' ? actions(wrapperVariant) : actions;

    if (actionsArray == null || actionsArray.length === 0) {
        return null;
    }

    const buttons = actionsArray?.map(actionType => {
        switch (actionType) {
            case 'clear':
                return (
                    <Button
                        data-mui-test="clear-action-button"
                        onClick={onClear}
                        key={actionType}
                        color="neutral"
                        size="sm"
                        variant="soft"
                    >
                        {localeText.clearButtonLabel}
                    </Button>
                );
            case 'cancel':
                return (
                    <Button
                        onClick={onCancel}
                        key={actionType}
                        color="neutral"
                        size="sm"
                        variant="outlined"
                    >
                        {localeText.cancelButtonLabel}
                    </Button>
                );
            case 'accept':
                return (
                    <Button onClick={onAccept} key={actionType} size="sm">
                        {localeText.okButtonLabel}
                    </Button>
                );
            case 'today':
                return (
                    <Button
                        data-mui-test="today-action-button"
                        onClick={onSetToday}
                        key={actionType}
                        size="sm"
                    >
                        {localeText.todayButtonLabel}
                    </Button>
                );
            default:
                return null;
        }
    });

    return <DialogActions {...other} sx={{p: 2, dosplay: 'flex', gap: 1}}>{buttons}</DialogActions>;
};
