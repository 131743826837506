import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {BeneficiariesActionType} from './beneficiaries.action-type';
import {BeneficiariesActions} from './beneficiaries.actions';
import {BeneficiariesSelector} from './beneficiaries.selector';
import {Toast} from '../../../../../lib/toast';
import {Debug} from '../../../../../utils/debug';
import {LoadingActions, LoadingTypes} from '../../../../loading';
// eslint-disable-next-line import/no-cycle
import {getHiwayAccountId} from '../../../store/bank.loader.saga';
import {BeneficiariesApi} from '../api/beneficiaries.api';

const getBeneficiariesListFlow = function* (accountId) {
    yield put(LoadingActions.setLoading(LoadingTypes.GET_BENEFICIARIES_LIST, true));

    try {
        const response = yield call(BeneficiariesApi.getBeneficiariesList, {
            accountId,
        });

        yield put(BeneficiariesActions.storeList(response));

        yield put(LoadingActions.setLoading(LoadingTypes.GET_BENEFICIARIES_LIST, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.GET_BENEFICIARIES_LIST, false));
    }
};

const createBeneficiariesFlow = function* (accountId, data) {
    yield put(LoadingActions.setLoading(LoadingTypes.BENEFICIARIES_UPDATE, true));

    try {
        const response = yield call(BeneficiariesApi.createBeneficiaries, {
            accountId,
            data,
        });

        const list = yield select(BeneficiariesSelector.selectList);

        let newList = [...list];
        if (response.isPersonal) {
            newList = newList.map(item => {
                return {
                    ...item,
                    isPersonal: false,
                };
            });
        }
        newList.push({...response, created: true});

        yield put(BeneficiariesActions.storeList(newList));

        yield put(LoadingActions.setLoading(LoadingTypes.BENEFICIARIES_UPDATE, false));

        Toast.success('beneficiariesAdded');
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.BENEFICIARIES_UPDATE, false));

        if (e?.response?.status === 409) {
            if (e?.response.data?.message === 'Beneficiary with provided data already exists.') {
                yield put(BeneficiariesActions.setIbanConflict(Math.random()));
                return;
            }
        }

        Toast.error('genericError');
    }
};

const updateBeneficiariesFlow = function* (accountId, data) {
    yield put(LoadingActions.setLoading(LoadingTypes.BENEFICIARIES_UPDATE, true));

    try {
        const response = yield call(BeneficiariesApi.updateBeneficiaries, {
            accountId,
            data,
        });

        const list = yield select(BeneficiariesSelector.selectList);
        const index = list.findIndex(item => item.id === data.id);

        let newList = [...list];
        if (response.isPersonal) {
            newList = newList.map(item => {
                return {
                    ...item,
                    isPersonal: false,
                };
            });
        }
        newList.splice(index, 1, response);

        yield put(BeneficiariesActions.storeList(newList));

        yield put(LoadingActions.setLoading(LoadingTypes.BENEFICIARIES_UPDATE, false));
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        yield put(LoadingActions.setLoading(LoadingTypes.BENEFICIARIES_UPDATE, false));

        if (e?.response?.status === 409) {
            if (e?.response?.data?.message === 'Beneficiary with provided data already exists.') {
                yield put(BeneficiariesActions.setIbanConflict(Math.random()));
                return;
            }
        }

        Toast.error('genericError');
    }
};

const deleteBeneficiariesFlow = function* (accountId, data) {
    yield put(LoadingActions.setLoading(LoadingTypes.BENEFICIARIES_DELETE, true));

    try {
        yield call(BeneficiariesApi.deleteBeneficiaries, {
            accountId,
            data,
        });

        yield call(getBeneficiariesListFlow, accountId);

        yield put(LoadingActions.setLoading(LoadingTypes.BENEFICIARIES_DELETE, false));

        Toast.success('beneficiariesDeleted');
    } catch (e) {
        Debug.error('bank', 'Error: ', {e});
        Toast.error('genericError');

        yield put(LoadingActions.setLoading(LoadingTypes.BENEFICIARIES_DELETE, false));
    }
};

export const getBeneficiariesListWorker = function* () {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield call(getBeneficiariesListFlow, accountId);
};

export const createBeneficiariesWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield call(createBeneficiariesFlow, accountId, payload);

    yield put(BeneficiariesActions.setIbanConflict(false));
};

export const updateBeneficiariesWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield call(updateBeneficiariesFlow, accountId, payload);

    yield put(BeneficiariesActions.setIbanConflict(false));
};

export const deleteBeneficiariesWorker = function* ({payload}) {
    const accountId = yield call(getHiwayAccountId);

    if (!accountId) {
        return;
    }

    yield call(deleteBeneficiariesFlow, accountId, payload);
};

export const watchBeneficieriesSaga = function* () {
    yield all([
        takeEvery(BeneficiariesActionType.GET, getBeneficiariesListWorker),
        takeEvery(BeneficiariesActionType.CREATE, createBeneficiariesWorker),
        takeEvery(BeneficiariesActionType.UPDATE, updateBeneficiariesWorker),
        takeEvery(BeneficiariesActionType.DELETE, deleteBeneficiariesWorker),
    ]);
};
