export const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        alignSelf: 'stretch',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    label: {
        color: 'v2.light.text.disabled',
    },
    value: {
        textAlign: 'right',
        ml: '4px',
    },
};
