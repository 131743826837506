import {all, call, put, takeEvery} from 'redux-saga/effects';

import * as actions from './companyDashboard.actions';
import {getDashboardDatepickerCurrentYear} from '../../../../features/dashboard/util/dashboard.util';
import {getDashboardDataRequest} from '../../api/providers/dashboard/dashboard.provider';
import {getCompanyId} from '../../utils/get-company-id';
import {getFreelancerId} from '../../utils/get-freelancer-id';

const getCompanyDashboardData = function* ({freelancerId, companyId, year}) {
    try {
        yield put(actions.setIsLoadingCompanyDashboardData(true));

        const data = yield call(getDashboardDataRequest, {freelancerId, companyId, year});

        yield put(actions.storeCompanyDashboardData(data));

        yield put(actions.setIsLoadingCompanyDashboardData(false));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});

        yield put(actions.setIsLoadingCompanyDashboardData(false));
    }
};

const getCompanyDashboardDataWorker = function* ({payload}) {
    const {freelancerId, companyId, year} = payload;

    yield call(getCompanyDashboardData, {
        freelancerId,
        companyId,
        year,
    });
};

export const companyDashboardLoaderSaga = function* ({payload}) {
    let {params: {companyId, freelancerId}} = payload || {};

    if (!freelancerId) {
        freelancerId = yield call(getFreelancerId);
    }

    if (!companyId) {
        companyId = yield call(getCompanyId);
    }

    // const company = yield select(createDefaultCompanySelector());

    // TODO: Add additional statements here so that we don't load the data if not necessary

    // if (company?.status !== COMPANY_STATUSES.ACTIVE) {
    //     return;
    // }

    // const currentCognitoUser = yield select(selectCurrentCognitoUser);

    yield call(getCompanyDashboardData, {
        freelancerId,
        companyId,
        year: getDashboardDatepickerCurrentYear(),
    });
};

export const watchCompanyDashboardSagas = function* () {
    yield all([
        takeEvery(actions.GET_COMPANY_DASHBOARD_DATA, getCompanyDashboardDataWorker),
    ]);
};
