import {all, call, put, takeLatest} from 'redux-saga/effects';

import * as actions from './training.actions';
import {DOCUMENT_TYPES} from '../../../config/constants/documentConstants';
import {TRAINING_INVOICE_STATES} from '../../../config/constants/trainingConstants';
import {getTrainingCompanyDocumentsRequest} from '../../api/providers/company/company.provider';
import {safe} from '../../safeSaga';

const getTrainingInvoiceSaga = function* ({payload}) {
    yield put(actions.setTrainingInvoiceState(TRAINING_INVOICE_STATES.LOADING));

    const {freelancerId, companyId} = payload;

    const documents = yield call(getTrainingCompanyDocumentsRequest, freelancerId, companyId);

    const invoice = Object.values(documents).find(document => document.type === DOCUMENT_TYPES.TRAINING_INVOICE);

    yield put(actions.storeTrainingInvoice(invoice || null));

    yield put(actions.setTrainingInvoiceState(TRAINING_INVOICE_STATES.DONE));
};

export const watchTrainingSagas = function* watchTrainingSagas() {
    yield all([
        takeLatest(actions.GET_TRAINING_INVOICE, safe(getTrainingInvoiceSaga)),
    ]);
};
