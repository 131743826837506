import makeStyles from '@mui/styles/makeStyles';

export const useCompanyDocumentsNameStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    iconMobile: {
        marginRight: theme.spacing(2),
        fontSize: theme.typography.pxToRem(18),
    },
    iconMobileTraining: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(0.3),
        fontSize: theme.typography.pxToRem(15),
    },
}));
