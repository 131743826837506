export const fieldProps = {
    fullWidth: true,
    size: 'lg',
    variant: 'outlined',
    sx: {backgroundColor: 'background.body'},
};

export const requiredFieldProps = {
    ...fieldProps,
    required: true,
};
