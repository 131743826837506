import moment from 'moment/moment';
import Yup from '../../../../../../../lib/yup/yup';
import {VALUE_ADDED_TAX_OPTIONS, docNumberRegexp} from '../../../../../../invoicing/util/constants';

export const externalInvoiceFormElements = {
    name: 'name',
    countryCode: 'countryCode',
    vatType: 'vatType',
    docNumber: 'docNumber',
    date: 'date',
    dueDate: 'dueDate',
    totalAmount: 'totalAmount',
    hasFee: 'hasFee',
    totalFeeAmount: 'totalFeeAmount',
    feeVatType: 'feeVatType',
    feePartnerName: 'feePartnerName',
    feePartnerInvoiceDocNumber: 'feePartnerInvoiceDocNumber',
};

export const createExternalInvoiceValidationSchema = t => {
    return Yup.object().shape({
        [externalInvoiceFormElements.name]: Yup.string()
            .required(t('form:validation.required', {
                fieldName: t('externalInvoice.clientName'),
            })),
        [externalInvoiceFormElements.countryCode]: Yup.string()
            .required(t('form:validation.required', {
                fieldName: t('externalInvoice.country'),
            })),
        [externalInvoiceFormElements.vatType]: Yup.string()
            .required(t('form:validation.required', {
                fieldName: t('externalInvoice.country'),
            })),
        [externalInvoiceFormElements.docNumber]: Yup.string()
            .required(t('form:validation.required', {
                fieldName: t('externalInvoice.docNumber'),
            }))
            .matches(docNumberRegexp, t('form:validation/v2.mixed/notType', {
                label: t('externalInvoice.docNumber'),
            })),
        [externalInvoiceFormElements.date]: Yup.date()
            .required(t('form:validation.required', {
                fieldName: t('externalInvoice.date'),
            }))
            .typeError(t('form:validation.notDate')),
        [externalInvoiceFormElements.dueDate]: Yup.date()
            .required(t('form:validation.required', {
                fieldName: t('externalInvoice.dueDate'),
            }))
            .when('date', {
                is: (date => moment(date).isValid()),
                then: Yup.date()
                    .min(
                        Yup.ref('date'),
                        t('externalInvoice.validationDueDateAfterDate'),
                    )
                    .typeError(t('form:validation.notDate')),
            })
            .typeError(t('form:validation.notDate')),
        [externalInvoiceFormElements.totalAmount]: Yup.number()
            .required(t('form:validation.required', {
                fieldName: t('externalInvoice.totalAmount'),
            }))
            .positive(t('form:validation/v2.number/positive', {
                label: t('externalInvoice.totalAmount'),
            }))
            .typeError(t('form:validation.notPositiveNumber')),
        [externalInvoiceFormElements.totalFeeAmount]: Yup.number()
            .when('hasFee', {
                is: true,
                then: Yup.number()
                    .required(t('form:validation.required', {
                        fieldName: t('externalInvoice.totalFeeAmount'),
                    }))
                    .positive(t('form:validation/v2.number/positive', {
                        label: t('externalInvoice.totalFeeAmount'),
                    }))
                    .lessThan(Yup.ref('totalAmount'), t('form:validation/v2.number/lessThan', {
                        label: t('externalInvoice.totalFeeAmount'),
                        less: t('externalInvoice.totalAmount'),
                    }))
                    .typeError(t('form:validation.notPositiveNumber')),
            }),
        [externalInvoiceFormElements.feeVatType]: Yup.string()
            .when('hasFee', {
                is: true,
                then: Yup.string()
                    .required(t('form:validation.required', {
                        fieldName: t('externalInvoice.feeVatType'),
                    }))
                    .oneOf(VALUE_ADDED_TAX_OPTIONS)
                    .typeError(t('form:validation.notOneOf')),
            }),
        [externalInvoiceFormElements.feePartnerName]: Yup.string()
            .when('hasFee', {
                is: true,
                then: Yup.string()
                    .required(t('form:validation.required', {
                        fieldName: t('externalInvoice.feePartnerName'),
                    })),
            }),
        [externalInvoiceFormElements.feePartnerInvoiceDocNumber]: Yup.string()
            .when('hasFee', {
                is: true,
                then: Yup.string()
                    .required(t('form:validation.required', {
                        fieldName: t('externalInvoice.feePartnerInvoiceDocNumber'),
                    }))
                    .matches(docNumberRegexp, t('form:validation/v2.mixed/notType', {
                        label: t('externalInvoice.feePartnerInvoiceDocNumber'),
                    })),
            }),
    });
};
