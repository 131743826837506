/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {Typography} from '@mui/joy';
import {styled} from '@mui/system';
import useEnhancedEffect from '@mui/utils/useEnhancedEffect';
import * as React from 'react';
import {
    getPickersMonthUtilityClass,
    pickersMonthClasses,
} from './pickersMonthClasses';
import {onSpaceOrEnter} from '../internals/utils/utils';

const useUtilityClasses = ownerState => {
    const {classes, selected} = ownerState;
    const slots = {
        root: ['root', selected && 'selected'],
    };

    return composeClasses(slots, getPickersMonthUtilityClass, classes);
};

const PickersMonthRoot = styled(Typography, {
    name: 'PrivatePickersMonth',
    slot: 'Root',
    overridesResolver: (_, styles) => [
        styles.root,
        {[`&.${pickersMonthClasses.selected}`]: styles.selected},
    ],
})(({theme}) => ({
    'flex': '1 0 33.33%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'backgroundColor': 'transparent',
    'border': 0,
    'outline': 0,
    ...theme.typography['body-md'],
    'color': theme.palette.text.secondary,
    'margin': '8px 0',
    'height': 36,
    'borderRadius': 18,
    'cursor': 'pointer',
    '&:focus, &:hover': {
        backgroundColor: theme.palette.neutral.softHoverBg,
    },
    '&:disabled': {
        pointerEvents: 'none',
        color: theme.palette.text.tertiary,
    },
    [`&.${pickersMonthClasses.selected}`]: {
        'color': theme.palette.primary.solidColor,
        'backgroundColor': theme.palette.neutral.solidActiveBg,
        '&:focus, &:hover': {
            backgroundColor: theme.palette.neutral.plainColor,
        },
    },
}));

const noop = () => {};

export const PickersMonth = props => {
    // TODO v6 add 'useThemeProps' once the component class names are aligned
    const {
        disabled,
        onSelect,
        selected,
        value,
        tabIndex,
        hasFocus,
        onFocus = noop,
        onBlur = noop,
        ...other
    } = props;
    const classes = useUtilityClasses(props);

    const handleSelection = () => {
        onSelect(value);
    };

    const ref = React.useRef(null);
    useEnhancedEffect(() => {
        if (hasFocus) {
            ref.current?.focus();
        }
    }, [hasFocus]);

    return (
        <PickersMonthRoot
            ref={ref}
            data-mui-test="month"
            component="button"
            type="button"
            className={classes.root}
            tabIndex={tabIndex}
            onClick={handleSelection}
            onKeyDown={onSpaceOrEnter(handleSelection)}
            color="neutral"
            disabled={disabled}
            onFocus={event => onFocus(event, value)}
            onBlur={event => onBlur(event, value)}
            {...other}
        />
    );
};
