import {TableFooter, TablePagination, TableRow} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import {useTranslation} from 'react-i18next';
import {JoyActionsComponent} from './JoyActionsComponent';
import {useDataTableFooterStyles} from './styles';

const DataTableFooter = ({
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    count,
    rowsPerPageOptions,
    isJoyTheme,
}) => {
    const {t} = useTranslation('common');

    const styles = useDataTableFooterStyles();

    return (
        <TableFooter className={styles.root}>
            <TableRow>
                <TablePagination
                    className={styles.pagination}
                    count={count}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    labelRowsPerPage={t('pagination.rowsPerPage')}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    {...(isJoyTheme && {ActionsComponent: JoyActionsComponent})}
                    sx={{
                        'p, div': {fontFamily: 'Avantt', fontSize: '16px', fontWeight: 500},
                        '.MuiTablePagination-spacer': {display: 'none'},
                        '.MuiTablePagination-displayedRows': {marginLeft: 'auto'},
                        '.MuiTablePagination-select': {
                            background: 'var(--joy-palette-background-surface)',
                            boxShadow: 'var(--joy-shadow-xs)',
                        },
                    }}
                />
            </TableRow>
        </TableFooter>
    );
};

DataTableFooter.propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    isJoyTheme: PropTypes.bool,
};

DataTableFooter.defaultProps = {
    rowsPerPageOptions: [10, 25, 50],
    isJoyTheme: false,
};

export default DataTableFooter;
