import {useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {TransactionSide, emptyFunction} from '../../../../util/constants';
import {SECTION_STATUS} from '../../utils/constants';
import CategoriesList from '../categories/CategoriesList';
import TransactionSourcesList from '../categories/SourcesList';
import CategorisationSection from '../header/CategorisationSection';
import CategorisationSectionDrawer from '../header/CategorisationSectionDrawer';

const AllCategoriesList = ({
    onSelectCategory,
    side,
    text,
    isSubscribed,
    onClose,
    setIsShowAllCategories,
    mccKey,
    hasMultipleArticles,
}) => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    if (side === TransactionSide.CREDIT) {
        return (
            <CategorisationSection
                title={t(`categorisation.section.category`)}
                status={SECTION_STATUS.REQUIRED}
                isActive
                isFocused
            >
                <TransactionSourcesList
                    setSelected={onSelectCategory}
                    isMobileSize={isMobileSize}
                    selected={mccKey}
                    set={setIsShowAllCategories}
                    setIsShowAllCategories={setIsShowAllCategories}
                    onClose={onClose}
                    text={text}
                />
            </CategorisationSection>
        );
    }

    if (isMobileSize) {
        return (
            <CategorisationSectionDrawer
                title={t(`categorisation.section.category`)}
                status={SECTION_STATUS.REQUIRED}
            >
                <CategoriesList
                    isSubscribed={isSubscribed}
                    text={text}
                    setSelectedCategory={onSelectCategory}
                    side={side}
                    selectedCategory={mccKey}
                    hasMultipleArticles={hasMultipleArticles}
                />
            </CategorisationSectionDrawer>
        );
    }

    return (
        <CategorisationSection
            title={t(`categorisation.section.category`)}
            status={SECTION_STATUS.REQUIRED}
            isActive
            isFocused
        >
            <CategoriesList
                isSubscribed={isSubscribed}
                text={text}
                side={side}
                selectedCategory={mccKey}
                setSelectedCategory={onSelectCategory}
                hasMultipleArticles={hasMultipleArticles}
            />
        </CategorisationSection>
    );
};

AllCategoriesList.propTypes = {
    onSelectCategory: PropTypes.func.isRequired,
    side: PropTypes.string.isRequired,
    text: PropTypes.string,
    isSubscribed: PropTypes.bool,
    hasMultipleArticles: PropTypes.bool,
    setIsShowAllCategories: PropTypes.func.isRequired,
    mccKey: PropTypes.string,
    onClose: PropTypes.func,
};

AllCategoriesList.defaultProps = {
    text: '',
    isSubscribed: false,
    hasMultipleArticles: false,
    mccKey: '',
    onClose: emptyFunction,
};

export default AllCategoriesList;
