import {faChevronDown, faChevronUp, faPaperclip} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Stack, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';

export const UploadedDocument = ({name, actions, isDisabled}) => {
    const [areActionsVisible, setAreActionsVisible] = useState(false);

    const handleToggleClick = () => {
        if (!isDisabled) {
            setAreActionsVisible(oldValue => !oldValue);
        }
    };

    const ref = useRef();

    useEffect(() => {
        const handleClick = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setAreActionsVisible(false);
            }
        };
        document.addEventListener('click', handleClick);

        return () => document.removeEventListener('click', handleClick);
    }, []);

    useEffect(() => {
        if (isDisabled) {
            setAreActionsVisible(false);
        }
    }, [isDisabled]);

    return (
        <Box sx={{position: 'relative'}} ref={ref}>
            <Stack
                spacing={1}
                direction="row"
                onClick={handleToggleClick}
                sx={{
                    px: 2,
                    py: 1,
                    alignItems: 'center',
                    borderRadius: 'md',
                    bgcolor: 'background.level2',
                    color: 'text.tertiary',
                    fontSize: '16px',
                    cursor: isDisabled ? 'forbidden' : 'pointer',
                }}
            >
                <FontAwesomeIcon icon={faPaperclip} fixedWidth />
                <Typography level="body-md" textColor="text.primary" sx={{flex: 1}}>
                    {name}
                </Typography>
                <Box sx={{color: 'text.primary', fontSize: '20px'}}>
                    <FontAwesomeIcon icon={areActionsVisible ? faChevronUp : faChevronDown} />
                </Box>
            </Stack>
            {areActionsVisible && (
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        mt: 1,
                        borderRadius: 'md',
                        border: '1px solid',
                        borderColor: 'neutral.outlinedBorder',
                        bgcolor: 'background.body',
                        p: 2,
                        boxShadow: 'md',
                        display: 'flex',
                        gap: 2,
                        zIndex: 'popup',
                    }}
                >
                    {actions}
                </Box>
            )}
        </Box>
    );
};

UploadedDocument.propTypes = {
    name: PropTypes.string.isRequired,
    actions: PropTypes.node.isRequired,
    isDisabled: PropTypes.bool,
};

UploadedDocument.defaultProps = {
    isDisabled: false,
};
