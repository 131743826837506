import {combineReducers} from 'redux';
import {adminExpensesReducer} from './adminExpenses/adminExpenses.reducer';
import {businessAllowanceReducer} from './businessAllowance/businessAllowance.reducer';
import * as actions from './expenses.actions';
import {homeOfficeTravelsFormReducer} from './homeOfficeTravelsForm/homeOfficeTravelsForm.reducer';
import {personalExpensesReducer} from './personalExpenses/personalExpenses.reducer';
import {personalExpensesFormReducer} from './personalExpensesForm/personalExpensesForm.reducer';
import {professionalExpensesReducer} from './professionalExpenses/professionalExpenses.reducer';
import {professionalExpensesFormReducer} from './professionalExpensesForm/professionalExpensesForm.reducer';
import {professionalTravelsFormReducer} from './professionalTravelsForm/professionalTravelsForm.reducer';

const totalDistancePerVehicleTypeReducer = (state = {}, action) => {
    if (action.type === actions.STORE_TOTAL_DISTANCE) {
        return action.payload;
    }

    return state;
};

export const expensesReducer = combineReducers({
    personalExpenses: personalExpensesReducer,
    personalExpensesForm: personalExpensesFormReducer,
    professionalExpenses: professionalExpensesReducer,
    professionalExpensesForm: professionalExpensesFormReducer,
    homeOfficeTravelsForm: homeOfficeTravelsFormReducer,
    professionalTravelsForm: professionalTravelsFormReducer,
    businessAllowance: businessAllowanceReducer,
    adminExpenses: adminExpensesReducer,
    totalDistancePerVehicleType: totalDistancePerVehicleTypeReducer,
});
