import makeStyles from '@mui/styles/makeStyles';

export const useDocumentStatusStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    errorIcon: {
        width: 18,
        height: 18,
        color: theme.palette.error.main,
    },
    pendingSigningIcon: {
        width: 18,
        height: 18,
        color: theme.palette.warning.main,
    },
    pendingCompanyIdIcon: {
        width: 18,
        height: 18,
        color: theme.palette.primary.main,
    },
    doneIcon: {
        width: 18,
        height: 18,
        color: theme.palette.success.main,
    },
    generatingIcon: {
        color: theme.palette.primary.main,
    },
    signedIcon: {
        width: 18,
        height: 18,
        color: theme.palette.success.main,
    },
    generatedIcon: {
        width: 18,
        height: 18,
        color: theme.palette.success.main,
    },
    label: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        color: 'rgba(0, 0, 0, 0.87)',
    },
}));
