import DownloadIcon from '@mui/icons-material/Download';
import {IconButton, Tooltip} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {DocumentActions} from '../../../../../document/store/document.action';
import {LoadingTypes, useLoadingState} from '../../../../../loading';

const DownloadStatementAction = ({rowData}) => {
    const {t} = useTranslation('bankRibAndDocuments');
    const dispatch = useDispatch();
    const isDownloadInProgress = useLoadingState(LoadingTypes.DOWNLOAD_DOCUMENT);

    const downloadStatementDocument = useCallback(() => {
        if (!isDownloadInProgress) {
            dispatch(DocumentActions.getDocument(rowData?.documentId, true));
        }
    }, [dispatch, isDownloadInProgress, rowData?.documentId]);

    return (
        <Tooltip placement="top" title={t('downloadPdf')}>
            <IconButton
                sx={{
                    'py': 0.75,
                    'borderRadius': 2,
                    'bgcolor': 'v2.blueGray.50',
                    '&:hover': {
                        bgcolor: 'v2.light.primary.shades12',
                    },
                }}
                variant="outlined"
                onClick={downloadStatementDocument}
                disabled={!!isDownloadInProgress}
            >
                <DownloadIcon sx={{color: 'text_v2.primary'}} />
            </IconButton>
        </Tooltip>
    );
};

DownloadStatementAction.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default DownloadStatementAction;
