import {coreApiInstance} from '../../../lib/api-providers/core-api-instance';
import {bankApiInstance} from '../../../lib/bank-api-instance';
import {invoiceApiInstance} from '../../../lib/invoice-api-instance';

const getBankSSEToken = () => {
    return bankApiInstance({
        method: 'GET',
        url: `/v3/sse/auth`,
    }).then(result => result?.data);
};

const getInvoiceSSEToken = () => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/sse/auth`,
    }).then(result => result?.data);
};

const getCoreSSEToken = () => {
    return coreApiInstance({
        method: 'GET',
        url: `/api/v2/sse/auth`,
    }).then(result => result?.data);
};

export const SseApi = {
    getBankSSEToken,
    getInvoiceSSEToken,
    getCoreSSEToken,
};
