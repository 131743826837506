import {
    transformCategoriesFromDTO,
    transformDetailsFromDTO,
    transformListFromDTO,
    transformSearchResultsFromDTO,
} from './documentation.dto';
import {documentationApiInstance} from '../../../lib/documentation-api-instance';
import {compareOrder} from '../util/functions';

// TODO: support pagenavigation
// /docs-category?per_page=100&page=1

const getCategories = () => {
    return documentationApiInstance({
        method: 'GET',
        url: `/docs-category`,
    }).then(result => (result?.data || []).map(transformCategoriesFromDTO).sort(compareOrder));
};

const getList = ({categorySlug}) => {
    return documentationApiInstance({
        method: 'GET',
        url: `/docs-list/${categorySlug}`,
    }).then(result => (result?.data || []).map(transformListFromDTO));
};

const getDetails = ({pageSlug}) => {
    return documentationApiInstance({
        method: 'GET',
        url: `/docs-page/${pageSlug}`,
    }).then(result => (result?.data || []).map(transformDetailsFromDTO));
};

const search = ({searchTerm}) => {
    const encodedTerm = encodeURI(searchTerm);
    return documentationApiInstance({
        method: 'GET',
        url: `/docs-search/${encodedTerm}`,
    }).then(result => (result?.data || []).map(transformSearchResultsFromDTO));
};

const getVoteResult = ({pageId, userId}) => {
    return documentationApiInstance({
        method: 'GET',
        url: `/docs-vote-permission/${pageId}/${userId}`,
    }).then(result => result?.data);
};

const vote = ({pageId, value, userId}) => {
    return documentationApiInstance({
        method: 'GET',
        url: `/docs-vote/${pageId}/${value}/${userId}`,
    }).then(result => result?.data);
};

const getPopularArticles = ({categorySlug}) => {
    return documentationApiInstance({
        method: 'GET',
        url: `/docs-popular/${categorySlug}/article`,
    }).then(result => (result?.data || []).map(transformListFromDTO));
};

const getGlobalPopularArticles = () => {
    return documentationApiInstance({
        method: 'GET',
        url: `/docs-popular-type/article`,
    }).then(result => (result?.data || []).map(transformListFromDTO));
};

export const DocumentationApi = {
    getCategories,
    getList,
    getDetails,
    search,
    getVoteResult,
    vote,
    getPopularArticles,
    getGlobalPopularArticles,
};
