export const CHANGE_USERNAME_FIELD = 'CHANGE_USERNAME_FIELD';
export const SUBMIT_RESET_PASSWORD_FORM = 'SUBMIT_RESET_PASSWORD_FORM';
export const SUBMIT_CONFIRM_RESET_PASSWORD_FORM = 'SUBMIT_CONFIRM_RESET_PASSWORD_FORM';
export const STORE_RESET_PASSWORD_USERNAME_ERROR_CODE = 'STORE_RESET_PASSWORD_USERNAME_ERROR_CODE';
export const STORE_RESET_PASSWORD_FORM_PASSWORD_ERROR_CODE = 'STORE_RESET_PASSWORD_FORM_PASSWORD_ERROR_CODE';
export const STORE_RESET_PASSWORD_FORM_VERIFICATION_CODE_ERROR_CODE = 'STORE_RESET_PASSWORD_FORM_VERIFICATION_CODE_ERROR_CODE';
export const STORE_CODE_SENT_SUCCESS = 'STORE_CODE_SENT_SUCCESS';
export const SET_IS_LOADING_RESET_PASSWORD_FORM = 'SET_IS_LOADING_RESET_PASSWORD_FORM';
export const CLEAR_USERNAME_ERROR = 'CLEAR_USERNAME_ERROR';

export const changeUsernameField = username => ({
    type: CHANGE_USERNAME_FIELD,
    payload: username,
});

export const submitResetPasswordForm = () => ({
    type: SUBMIT_RESET_PASSWORD_FORM,
});

export const submitConfirmResetPasswordForm = (verificationCode, password) => ({
    type: SUBMIT_CONFIRM_RESET_PASSWORD_FORM,
    payload: {
        verificationCode,
        password,
    },
});

export const storeResetPasswordUsernameError = usernameErrorCode => ({
    type: STORE_RESET_PASSWORD_USERNAME_ERROR_CODE,
    payload: usernameErrorCode,
});

export const storeResetPasswordFormCodeError = verificationCodeErrorCode => ({
    type: STORE_RESET_PASSWORD_FORM_VERIFICATION_CODE_ERROR_CODE,
    payload: verificationCodeErrorCode,
});

export const storeResetPasswordFormPasswordError = passwordErrorCode => ({
    type: STORE_RESET_PASSWORD_FORM_PASSWORD_ERROR_CODE,
    payload: passwordErrorCode,
});

export const storeCodeSentSuccess = () => ({
    type: STORE_CODE_SENT_SUCCESS,
});

export const setIsLoadingResetPasswordForm = isLoading => ({
    type: SET_IS_LOADING_RESET_PASSWORD_FORM,
    payload: isLoading,
});

export const clearUsernameError = () => ({
    type: CLEAR_USERNAME_ERROR,
});
