import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getDateTimePickerToolbarUtilityClass = slot => {
    return generateUtilityClass('MuiDateTimePickerToolbar', slot);
};

export const dateTimePickerToolbarClasses = generateUtilityClasses(
    'MuiDateTimePickerToolbar',
    ['root', 'dateContainer', 'timeContainer', 'separator'],
);
