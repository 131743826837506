import {generateUtilityClass, generateUtilityClasses} from '@mui/base';

export const getPickersDayUtilityClass = slot => {
    return generateUtilityClass('MuiPickersDay', slot);
};

export const pickersDayClasses = generateUtilityClasses('MuiPickersDay', [
    'root',
    'dayWithMargin',
    'dayOutsideMonth',
    'hiddenDaySpacingFiller',
    'today',
    'selected',
    'disabled',
]);
