import {FinancialStatementsActionType} from './financial-statements.action-type';

export const FinancialStatementsActions = {
    getFinancialStatements: () => ({
        type: FinancialStatementsActionType.GET_FINANCIAL_STATEMENTS,
    }),
    storeFinancialStatements: data => ({
        type: FinancialStatementsActionType.STORE_FINANCIAL_STATEMENTS,
        payload: data,
    }),
};
