import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EuroTwoToneIcon from '@mui/icons-material/EuroTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import PersonPinTwoToneIcon from '@mui/icons-material/PersonPinTwoTone';
import {LoadingButton} from '@mui/lab';
import {Box, Divider, FormControl, Grid, Radio, RadioGroup, Switch, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {formElements} from './constants';
import TransferFileSection from './TransferFIleSection';
import ExpandableSidebar from '../../../../../../components/expandable-sidebar/ExpandableSidebar';
import {StandardNumberField} from '../../../../../../components/input/StandardNumberField';
import {RoutePaths} from '../../../../../../lib/router/route-paths';
import Yup from '../../../../../../lib/yup/yup';
import ReactRouterButtonLink from '../../../../../../v1/components/app/Router/ReactRouterButtonLink';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import BankQr from '../../../../components/BankQR';
import {BankSelector} from '../../../../store/bank.selector';
import TransactionCategoryList from '../../../account-balance/components/transaction-details/TransactionCategoryList';
import {
    TransactionDetailsNonSelectedCategory, TransactionDetailsSelectedCategory,
} from '../../../account-balance/components/transaction-details/TransactionDetailsHelpers';
import {TransactionsActions} from '../../../account-balance/store/transactions.action';
import {TransactionsSelector} from '../../../account-balance/store/transactions.selector';
import {emptyFunction} from '../../../account-balance/util/constants';
import {getIntegrationInfo} from '../../../bridge-api/utils/bridge-util';
import {getActiveHiwayIntegration} from '../../../rib-and-documents/utils/ribAndDocuments.util';
import {BeneficiariesSelector} from '../../store/beneficiaries.selector';
import PaperWrapper from '../util/PaperWrapper';

const defaultValues = {
    amount: '',
    currency: 'EUR',
    mccKey: '',
    label: '',
    iban: '',
    description: '',
    fileList: [],
    beneficiaryRadio: null,
    shouldSaveBeneficiary: false,
};

const createValidationSchema = (t, beneficiariesList) => Yup.object().shape({
    [formElements.amount]: Yup.number().typeError(t('form:validation.required', {
        fieldName: t('transfer.create.amount'),
    })).positive(t('form:validation/v2.number/positive', {
        label: t('transfer.create.amount'),
    })),
    [formElements.label]: Yup.string()
        .when('beneficiaryRadio', {
            is: null,
            then: Yup.string().label(t('beneficiaries.form.invalidName'))
                .required(),
        }),

    [formElements.iban]: Yup.string()
        .when('beneficiaryRadio', {
            is: null,
            then: Yup.string().iban(t('beneficiaries.form.ibanInvalid'))
                .test(
                    'iban-exists',
                    t('beneficiaries.form.ibanDuplicate'),
                    iban => {
                        const hasIban = beneficiariesList.find(beneficiary => beneficiary.iban === iban);

                        return !hasIban;
                    },
                )
                .required(),
        }),
    // eslint-disable-next-line
    [formElements.description]: Yup.string().matches(/^[A-Za-z0-9\s]+$/, {
        excludeEmptyString: true,
        message: t('transfer.create.descriptionCharacterValidation'),
    }).max(35, t('transfer.create.descriptionMaxLengthValidation'))
        .nullable(),
});

const CreateSingleTransfer = ({
    isPredefined,
    predefinedMccKey,
    predefinedAmount,
    onClose,
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bankTransfer');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const integration = useSelector(BankSelector.selectIntegrations);

    const hiwayIntegration = getActiveHiwayIntegration(integration);
    const {account: hiwayBankAccount} = getIntegrationInfo(hiwayIntegration);

    const beneficiariesList = useSelector(BeneficiariesSelector.selectList);
    const categoryList = useSelector(TransactionsSelector.selectCategories);
    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GENERIC_CRUD_LOADER),
    );

    const predefinedBenificiaryId = useMemo(() => {
        const personalBenificiary = beneficiariesList.find(b => b.isPersonal);
        return personalBenificiary?.id ?? null;
    }, [beneficiariesList]);

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isQROpen, setIsQROpen] = useState(false);

    const {
        control,
        handleSubmit: createSubmitHandler,
        formState: {isValid, errors},
        watch,
        trigger,
        setValue,
    } = useForm({
        resolver: yupResolver(createValidationSchema(t, beneficiariesList)),
        defaultValues: isPredefined ? {
            ...defaultValues,
            mccKey: predefinedMccKey,
            amount: predefinedAmount,
            beneficiaryRadio: predefinedBenificiaryId,
        } : defaultValues,
        mode: 'onChange',
    });

    const mccKey = watch(formElements.mccKey);
    const iban = watch(formElements.iban);
    const label = watch(formElements.label);
    const fileList = watch(formElements.fileList);
    const shouldSaveBeneficiary = watch(formElements.shouldSaveBeneficiary);
    const beneficiaryRadio = watch(formElements.beneficiaryRadio);

    const handleSubmit = data => {
        if (isPredefined) {
            data = {
                ...data,
                additionalData: {
                    ...data.additionalData,
                    remunerationDate: moment().format('DD/MM/YYYY'),
                },
            };
        }
        dispatch(UiActions.setModalData(ModalsKeys.INSTANT_TRANSFER, data));
        dispatch(UiActions.setActiveModal(ModalsKeys.INSTANT_TRANSFER, true));
    };

    const onOpenCategory = () => {
        setIsSidebarOpen(true);
    };

    const onSelectCategory = value => {
        setValue('mccKey', value?.mccKey);
        setIsSidebarOpen(false);
    };

    const setFileList = useCallback(files => setValue(formElements.fileList, files), [setValue]);

    useEffect(() => {
        if (beneficiaryRadio && (iban || label || shouldSaveBeneficiary)) {
            setValue(formElements.beneficiaryRadio, null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iban, label, shouldSaveBeneficiary]);

    useEffect(() => {
        if (categoryList.length === 0 && integration?.length > 0) {
            dispatch(TransactionsActions.getCategoryList());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integration, dispatch]);

    if (isQROpen && isMobileSize) {
        return (
            <BankQr
                id={undefined}
                bankAccountId={hiwayBankAccount?.id}
                onClose={() => setIsQROpen(false)}
                type="transfer"
            />
        );
    }

    if (isSidebarOpen && isMobileSize && !isPredefined) {
        return (
            <TransactionCategoryList
                isMobileSize={isMobileSize}
                onClose={() => setIsSidebarOpen(false)}
                selectedCategory={mccKey}
                setSelectedCategory={onSelectCategory}
            />
        );
    }

    return (
        <>
            <form onSubmit={createSubmitHandler(handleSubmit)}>
                <Box>
                    <Box sx={{
                        display: 'flex',
                        width: isSidebarOpen ? 'calc(100% - 400px)' : '100%',
                        transition: 'width 0.4s ease-in-out',
                        flexDirection: isMobileSize ? 'column' : 'row',
                    }}
                    >
                        {isMobileSize && (
                            <PaperWrapper
                                header={t('transfer.create.transferHeader')}
                                elevation={8}
                                sx={{mb: 2}}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    gap: 2,
                                    position: 'relative',
                                    flexDirection: 'column',
                                }}
                                >
                                    <Box sx={{
                                        width: `100%`,
                                    }}
                                    >
                                        <Controller
                                            control={control}
                                            id={formElements.amount}
                                            name={formElements.amount}
                                            render={({field}) => (
                                                <StandardNumberField
                                                    {...field}
                                                    error={!!errors[formElements.amount]}
                                                    helperText={errors[formElements.amount]?.message}
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    sx={{
                                                        mb: 1,
                                                        background: 'white',
                                                    }}
                                                    fullWidth
                                                    label={t('transfer.create.amount')}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <EuroTwoToneIcon sx={{color: 'v2.light.action.active'}} />,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>

                                    <Box sx={{
                                        width: `100%`,
                                    }}
                                    >
                                        <Box sx={{
                                            // position: 'absolute',
                                            // top: '-40px',
                                        }}
                                        >
                                            <Typography sx={{fontWeight: 500, mb: 2}}>
                                                {t('transfer.create.additionalInfoHeader')}
                                            </Typography>
                                        </Box>
                                        <Controller
                                            control={control}
                                            name={formElements.description}
                                            render={({field}) => (
                                                <TextField
                                                    variant="outlined"
                                                    disabled={isLoading}
                                                    error={!!errors[formElements.description]}
                                                    helperText={errors[formElements.description]?.message}
                                                    fullWidth
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                            </PaperWrapper>
                        )}

                        <Box sx={{
                            width: isMobileSize ? '100%' : '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            mr: 1.5,
                        }}
                        >
                            <PaperWrapper header={t('transfer.create.newBeneficiary')}>
                                <Box>
                                    <Grid item xs={12} lg={6}>
                                        <Controller
                                            control={control}
                                            name="label"
                                            render={({field}) => (
                                                <TextField
                                                    variant="outlined"
                                                    label={t('beneficiaries.form.label')}
                                                    disabled={isLoading}
                                                    error={!!errors[formElements.label]}
                                                    helperText={errors[formElements.label]?.message}
                                                    fullWidth
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={6} sx={{mt: 2}}>
                                        <Controller
                                            control={control}
                                            name="iban"
                                            render={({field}) => (
                                                <TextField
                                                    variant="outlined"
                                                    label={t('beneficiaries.form.iban')}
                                                    disabled={isLoading}
                                                    error={!!errors[formElements.iban]}
                                                    helperText={errors[formElements.iban]?.message}
                                                    fullWidth
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} lg={6} sx={{mt: 2}}>
                                        <Controller
                                            control={control}
                                            name={formElements.shouldSaveBeneficiary}
                                            render={({field}) => (
                                                <FormControlLabel
                                                    sx={{m: 0, pt: 1}}
                                                    label={t('transfer.create.save')}
                                                    control={(
                                                        <Switch
                                                            checked={field.value}
                                                            color="primary"
                                                        />
                                                    )}
                                                    onChange={event => {
                                                        field.onChange(event?.target?.checked);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Box>

                                <Divider
                                    sx={{
                                        mt: 2,
                                        mb: 1,
                                        borderStyle: 'dotted',
                                    }}
                                >
                                    <Typography sx={{
                                        color: 'v2.light.text.disabled',
                                        textTransform: 'uppercase',
                                        fontSize: theme => theme.typography.pxToRem(12),
                                    }}
                                    >
                                        {t('transfer.create.or')}
                                    </Typography>
                                </Divider>

                                <Box sx={{mb: 2}}>
                                    <Typography sx={{fontWeight: 500, mb: 2}}>
                                        {t('transfer.create.myBeneficiaries')}
                                    </Typography>
                                    <FormControl sx={{
                                        width: '100%',
                                    }}
                                    >
                                        <Controller
                                            control={control}
                                            name="beneficiaryRadio"
                                            render={({field}) => (
                                                <RadioGroup {...field} row>
                                                    {beneficiariesList.map(beneficiary => (
                                                        <BeneficiaryOption
                                                            key={beneficiary.id}
                                                            beneficiary={beneficiary}
                                                            value={field.value}
                                                            isMobileSize={isMobileSize}
                                                            onClick={() => {
                                                                setValue(formElements.label, '');
                                                                setValue(formElements.iban, '');
                                                                setValue(
                                                                    formElements.shouldSaveBeneficiary,
                                                                    false,
                                                                );
                                                                setTimeout(() => {
                                                                    field.onChange(beneficiary.id);
                                                                    trigger('label');
                                                                    trigger('iban');
                                                                }, 50);
                                                            }}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            )}
                                        />
                                    </FormControl>
                                </Box>
                            </PaperWrapper>

                        </Box>
                        <Box sx={{
                            width: isMobileSize ? '100%' : '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            ml: isMobileSize ? 0 : 1.5,
                            mt: isMobileSize ? 1.5 : 0,
                        }}
                        >
                            {!isMobileSize && (
                                <PaperWrapper
                                    header={t('transfer.create.transferHeader')}
                                    elevation={8}
                                    sx={{mb: 3}}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 3,
                                        position: 'relative',
                                    }}
                                    >
                                        <Box sx={{
                                            width: `calc(50% - 12px)`,
                                        }}
                                        >
                                            <Controller
                                                control={control}
                                                id={formElements.amount}
                                                name={formElements.amount}
                                                render={({field}) => (
                                                    <StandardNumberField
                                                        {...field}
                                                        error={!!errors[formElements.amount]}
                                                        helperText={errors[formElements.amount]?.message}
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        sx={{
                                                            mb: 1,
                                                            background: 'white',
                                                        }}
                                                        fullWidth
                                                        label={t('transfer.create.amount')}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            endAdornment: <EuroTwoToneIcon sx={{color: 'v2.light.action.active'}} />,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>

                                        <Box sx={{
                                            width: `calc(50% - 12px)`,
                                        }}
                                        >
                                            <Box sx={{
                                                position: 'absolute',
                                                top: '-40px',
                                            }}
                                            >
                                                <Typography sx={{fontWeight: 500, mb: 2}}>
                                                    {t('transfer.create.additionalInfoHeader')}
                                                </Typography>
                                            </Box>
                                            <Controller
                                                control={control}
                                                name={formElements.description}
                                                render={({field}) => (
                                                    <TextField
                                                        variant="outlined"
                                                        disabled={isLoading}
                                                        error={!!errors[formElements.description]}
                                                        helperText={errors[formElements.description]?.message}
                                                        fullWidth
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Box>
                                </PaperWrapper>
                            )}

                            <PaperWrapper sx={{mb: 3}} header={t('transfer.create.categoryHeader')}>
                                {!mccKey
                                    && (
                                        <TransactionDetailsNonSelectedCategory
                                            onOpenCategory={onOpenCategory}
                                            suggestedCategory={undefined}
                                            onSelectCategory={onSelectCategory}
                                            isEditable={true}
                                        />
                                    )}

                                {mccKey
                                    && (
                                        <TransactionDetailsSelectedCategory
                                            onOpenCategory={onOpenCategory}
                                            selectedCategory={mccKey}
                                            isEditable={true}
                                        />
                                    )
                                }
                            </PaperWrapper>

                            <PaperWrapper sx={{mb: 2}} header={t('transfer.create.documentHeader')}>
                                <TransferFileSection
                                    fileList={fileList}
                                    setFileList={setFileList}
                                    details={{}}
                                    isEditable={true}
                                    setIsQROpen={setIsQROpen}
                                />
                            </PaperWrapper>

                        </Box>
                        {!isMobileSize && (
                        <ExpandableSidebar
                            isOpen={!!isSidebarOpen}
                            onClose={() => setIsSidebarOpen(false)}
                            isMobileSize={isMobileSize}
                            mt={-11}
                        >
                            <TransactionCategoryList
                                isMobileSize={isMobileSize}
                                onClose={() => setIsSidebarOpen(false)}
                                selectedCategory={mccKey}
                                setSelectedCategory={onSelectCategory}
                            />
                        </ExpandableSidebar>
                        )}

                        {!isMobileSize && isQROpen && (
                            <ExpandableSidebar
                                isOpen={true}
                                onClose={() => setIsQROpen(false)}
                                isMobileSize={isMobileSize}
                                mt={-11}
                            >
                                <BankQr
                                    id={undefined}
                                    bankAccountId={hiwayBankAccount?.id}
                                    onClose={() => setIsQROpen(false)}
                                    type="transfer"
                                />
                            </ExpandableSidebar>
                        )}

                    </Box>

                    <Box>
                        <Box sx={{
                            mt: 3,
                            display: 'flex',
                            justifyContent: isMobileSize ? 'normal' : 'space-around',
                        }}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: isMobileSize ? 'column-reverse' : 'row',
                                width: isMobileSize ? '100%' : (isSidebarOpen ? 'calc(50%)' : 'inherit'),
                                gap: 2,
                            }}
                            >
                                {isPredefined ? (
                                    <Button
                                        fullWidth={isMobileSize}
                                        variant="outlined"
                                        size="large"
                                        startIcon={<ArrowBackIcon />}
                                        onClick={onClose}
                                    >
                                        {t('transfer.create.back')}
                                    </Button>
                                ) : (
                                    <ReactRouterButtonLink
                                        to={RoutePaths.BANK_TRANSFER_LIST}
                                        fullWidth={isMobileSize}
                                        variant="outlined"
                                        size="large"
                                        startIcon={<ArrowBackIcon />}
                                        disabled={isLoading}
                                    >
                                        {t('transfer.create.back')}
                                    </ReactRouterButtonLink>
                                )}
                                <LoadingButton
                                    variant="contained"
                                    fullWidth={isMobileSize}
                                    type="submit"
                                    color="secondary"
                                    size="large"
                                    endIcon={<ArrowForwardIcon />}
                                    disabled={isLoading || !isValid}
                                    loading={isLoading}
                                    loadingPosition="end"
                                >
                                    {t('transfer.create.submit')}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </form>
        </>
    );
};

CreateSingleTransfer.propTypes = {
    isPredefined: PropTypes.bool,
    predefinedMccKey: PropTypes.string,
    predefinedAmount: PropTypes.number,
    onClose: PropTypes.func,
};

CreateSingleTransfer.defaultProps = {
    isPredefined: false,
    predefinedMccKey: '',
    predefinedAmount: 0,
    onClose: emptyFunction,
};

export const BeneficiaryOption = ({beneficiary, value, onClick, isMobileSize}) => {
    const {t} = useTranslation('bankTransfer');

    const isChecked = beneficiary.id === value;
    return (
        <Box
            sx={{
                borderRadius: '16px',
                border: isChecked ? '1px solid #0288D1' : '1px solid rgba(0, 0, 0, 0.12)',
                background: isChecked ? 'rgba(25, 118, 210, 0.04)' : 'inherit',
                p: 2,
                width: '100%',
                my: 1,
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
            }}
            >
                {beneficiary.isPersonal
                    ? (
                        <PersonPinTwoToneIcon
                            sx={{
                                color: '#E84C4C',
                            }}
                        />
                    )
                    : (
                        <PersonOutlineTwoToneIcon sx={{
                            color: '#90A4AE',
                        }
                    }
                        />
                    )
                }
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <Box sx={{
                        color: 'v2.blueGray.600',
                        fontSize: theme => theme.typography.pxToRem(13),
                        fontWeight: 500,
                    }}
                    >
                        {beneficiary.label}
                    </Box>
                    {beneficiary.isPersonal && (
                        <Box sx={{
                            color: 'secondary.main',
                            fontSize: theme => theme.typography.pxToRem(12),
                        }}
                        >
                            {t('beneficiaries.form.personal')}
                        </Box>
                    )}
                </Box>
            </Box>
            <Box sx={{
                color: 'v2.light.text.disabled',
                fontSize: '12px !important',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }}
            >
                {!isMobileSize && (
                    <Typography sx={{
                        color: 'v2.light.text.disabled',
                        fontSize: theme => theme.typography.pxToRem(12),
                    }}
                    >
                        {beneficiary.iban}
                    </Typography>
                )}
                <Radio
                    checkedIcon={<CheckCircleIcon />}
                    checked={beneficiary.id === value}
                    value={beneficiary.id}
                />
            </Box>
        </Box>
    );
};

BeneficiaryOption.propTypes = {
    beneficiary: PropTypes.object.isRequired,
    value: PropTypes.any,
    onClick: PropTypes.func.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
};

BeneficiaryOption.defaultProps = {
    value: null,
};

export default CreateSingleTransfer;
