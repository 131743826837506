import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import {useTranslation} from 'react-i18next';
import {CalendarMonthIcon} from '../../../../../../assets/wrapped-svg/calendar-month';
import {EventIcon} from '../../../../../../assets/wrapped-svg/event';
import {LaptopIcon} from '../../../../../../assets/wrapped-svg/laptop';
import {DateField} from '../DateField';
import {EurField} from '../EurField';
import {FieldBlock} from '../FieldBlock';
import {FormSection} from '../FormSection';
import {FormSectionRow} from '../FormSectionRow';
import {NumberField} from '../NumberField';

export const MyFreelanceProjectStep1 = () => {
    const {t} = useTranslation('revenueSimulator');

    return (
        <FormSection Icon={BusinessCenterIcon} title={t('projection.myFreelanceProject')}>
            <FormSectionRow>
                <FieldBlock
                    Icon={LaptopIcon}
                    title={t('projection.pricePerDay.title')}
                    subtitle={t('projection.pricePerDay.subtitle')}
                >
                    <EurField
                        defaultValue={500}
                        withSlider
                        required
                        name="projection.pricePerDay"
                        min={200}
                        max={1500}
                    />
                </FieldBlock>
                <FieldBlock
                    Icon={CalendarMonthIcon}
                    title={t('projection.workingDaysPerYear.title')}
                    subtitle={t('projection.workingDaysPerYear.subtitle')}
                >
                    <NumberField
                        defaultValue={218}
                        min={0}
                        max={365}
                        name="projection.workingDaysPerYear"
                        required
                        inputStyles={{
                            width: '128px',
                            textAlign: 'center',
                        }}
                    />
                </FieldBlock>
                <FieldBlock
                    Icon={EventIcon}
                    title={t('projection.targetStartingDate.title')}
                    subtitle={t('projection.targetStartingDate.subtitle')}
                >
                    <DateField name="projection.targetStartingDate" required />
                </FieldBlock>
            </FormSectionRow>
        </FormSection>
    );
};
