/* eslint-disable react/prop-types */
import composeClasses from '@mui/base/composeClasses';
import {styled, useThemeProps} from '@mui/system';
import clsx from 'clsx';
import * as React from 'react';
import {getClockPointerUtilityClass} from './clockPointerClasses';
import {CLOCK_HOUR_WIDTH, CLOCK_WIDTH} from './shared';

const useUtilityClasses = ownerState => {
    const {classes} = ownerState;
    const slots = {
        root: ['root'],
        thumb: ['thumb'],
    };

    return composeClasses(slots, getClockPointerUtilityClass, classes);
};

const ClockPointerRoot = styled('div', {
    name: 'MuiClockPointer',
    slot: 'Root',
    overridesResolver: (_, styles) => styles.root,
})(({theme, ownerState}) => ({
    width: 2,
    backgroundColor: theme.palette.background.cocoa,
    position: 'absolute',
    left: 'calc(50% - 1px)',
    bottom: '50%',
    transformOrigin: 'center bottom 0px',
    ...(ownerState.shouldAnimate && {
        transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    }),
}));

const ClockPointerThumb = styled('div', {
    name: 'MuiClockPointer',
    slot: 'Thumb',
    overridesResolver: (_, styles) => styles.thumb,
})(({theme, ownerState}) => ({
    width: 4,
    height: 4,
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    position: 'absolute',
    top: -21,
    left: `calc(50% - ${CLOCK_HOUR_WIDTH / 2}px)`,
    border: `${(CLOCK_HOUR_WIDTH - 4) / 2}px solid ${theme.palette.background.cocoa}`,
    boxSizing: 'content-box',
    ...(ownerState.hasSelected && {
        backgroundColor: theme.palette.background.cocoa,
    }),
}));

export const ClockPointer = inProps => {
    const props = useThemeProps({props: inProps, name: 'MuiClockPointer'});
    const {className, hasSelected, isInner, type, value, ...other} = props;
    const previousType = React.useRef(type);
    React.useEffect(() => {
        previousType.current = type;
    }, [type]);

    const ownerState = {...props, shouldAnimate: previousType.current !== type};
    const classes = useUtilityClasses(ownerState);

    const getAngleStyle = () => {
        const max = type === 'hours' ? 12 : 60;
        let angle = (360 / max) * value;

        if (type === 'hours' && value > 12) {
            angle -= 360; // round up angle to max 360 degrees
        }

        return {
            height: Math.round((isInner ? 0.26 : 0.4) * CLOCK_WIDTH),
            transform: `rotateZ(${angle}deg)`,
        };
    };

    return (
        <ClockPointerRoot
            style={getAngleStyle()}
            className={clsx(className, classes.root)}
            ownerState={ownerState}
            {...other}
        >
            <ClockPointerThumb ownerState={ownerState} className={classes.thumb} />
        </ClockPointerRoot>
    );
};
